import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";

import { CreateSponsor } from "components/admin-layout/pages/CreateUser/createSponsor";
import Api from "helpers/api";
import { Header, TextH3 } from "components/admin-layout/pages/DealForm/Step/styled";
import { Btn } from "components/admin-layout/buttons";
import { ReactComponent as ArrowLeft } from "assets/arrowleft.svg";

const EditSponsor = ({
  token,
  countriesOption,
  history,
  role,
  match: {
    params: { userName },
  },
}) => {
  const [sponsor, setSponsor] = useState(null);

  useEffect(() => {
    Api.getAccountDetails(token, userName).then((s) => {
      const {
        username,
        countryOfRegistration,
        userLogo,
        fullName,
        phoneNumber,
        webSite,
        slogan,
        userBackgroundLogo,
        userDescription,
        userDynamicProperties,
        additionalInformation,
      } = s.userData;
      const _sponsor = {
        email: username,
        fullName: fullName,
        description: userDescription,
        slogan: slogan,
        countryOfResidence: countryOfRegistration,
        logo: userLogo,
        backgroundLogo: userBackgroundLogo,
        registrationMethod: "EMAIL",
        phoneNumber: phoneNumber,
        webSite: webSite,
        userDynamicProperties: [...userDynamicProperties],
        additionalInformation: [...additionalInformation],
      };
      setSponsor(_sponsor);
    });
  }, []);

  const onGoBack = () => {
    try {
      history.goBack();
    } catch (_) {
      history.push("/admin/users");
    }
  };

  if (!sponsor) return null;

  return (
    <Fragment>
      <Header>
        <Btn transparent onClick={onGoBack} width={150}>
          <ArrowLeft /> back
        </Btn>
        <TextH3 marginSize="0 0 0 25px">Edit {role === "SPONSOR" ? "My Profile" : userName}</TextH3>
      </Header>
      <CreateSponsor
        history={history}
        token={token}
        onChangeRole={console.log}
        roles={["SPONSOR"]}
        countries={countriesOption}
        sponsorData={sponsor}
        role={role}
      />
    </Fragment>
  );
};

export default connect(
  ({ userReducer: { token, role }, asyncDataReducer: { countriesOption } }) => ({
    role,
    token,
    countriesOption,
  }),
  null
)(EditSponsor);
