import React, { useState, useEffect } from "react";
import { InputRow } from "../Step/Information/styled";
import { TextAreaComponent, TextEditorComponent } from "../../../forms";

import { ExpandedPanel } from "./ExpandedPanel";

export const Summary = ({ onChange, defaultValues, roleName }) => {
  const [lock, setLock] = useState(false);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <ExpandedPanel header="Summary" lock={lock} key={`render-key-if-end${lock}`}>
      <InputRow>
        <TextAreaComponent
          label={roleName === "SPONSOR" ? "Slogan" : "short information (up to 350 symbols)"}
          onChange={onChange("assetProperties", "smallDescription")}
          defaultValue={defaultValues.assetProperties.smallDescription}
        />
      </InputRow>
      <InputRow last>
        <TextEditorComponent
          scrollToTop
          key="disabledFocus"
          heightToContent
          label="Full information"
          // onChange={onChange("assetProperties", "fullDescription")}
          onChange={(...args) => {
            onChange("assetProperties", "fullDescription")(...args);
            setLock(true);
          }}
          onFocus={() => setLock(!lock)}
          defaultValue={defaultValues.assetProperties.fullDescription}
        />
      </InputRow>
    </ExpandedPanel>
  );
};
