import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";

import { ChatColumn, ChatWrapper } from "./styled";
import { requestToAssetMessage } from "../../../../modules/chatRedux/chatAction";
import {
  setCurrentChatId,
  getArchiveMessage
} from "../../../../modules/chatRedux/chatAction";

import { Loader } from "../../styled/Loader";
import Api from "../../../../helpers/api";

import { ThemesBar } from "./ThemesBar";
import { ChatBar } from "./ChatBar";
export const Chat = ({
  token,
  chatThemes,
  requestToAssetMessage,
  setCurrentChatId,
  getArchiveMessage,
  history,
  socketConnect,
  username,
  messages,
  unreadMessages,
  match: {
    params: { id }
  }
}) => {
  const getNewMessage = () => getArchiveMessage(token, id);
  const debounceRef = useRef(null);

  //sidebar
  useEffect(() => {
    if (socketConnect) {
      requestToAssetMessage(token);
    }
  }, [socketConnect]);
  //onchange chat id
  useEffect(() => {
    if (id && socketConnect) {
      setCurrentChatId(id);
      getArchiveMessage(token, id);
    }
  }, [id, socketConnect]);

  //update sidebar and unread
  useEffect(() => {
    if (unreadMessages.total !== 0) {
      const unreadCurrentChat = chatThemes.find(
        e => e.assetId === id && e.unread
      );
      Api.requestAssetMessage(token);
      if (unreadCurrentChat) {
        clearTimeout(debounceRef.current);
        debounceRef.current = setTimeout(() => {
          Api.updateChatMessages(token, 1, 10, id);
        }, 1000);
      }
    }
  }, [unreadMessages]);

  if (Object.keys(chatThemes).length) {
    return (
      <ChatWrapper>
        <ChatColumn width="440px" responsive>
          <ThemesBar chatThemes={chatThemes} token={token} history={history} />
        </ChatColumn>
        <ChatColumn width="calc(100% - 470px)" responsiveBig>
          <ChatBar
            id={id}
            messages={messages || []}
            getNewMessage={getNewMessage}
            chatThemes={chatThemes}
            token={token}
            username={username}
            socketConnect={socketConnect}
          />
        </ChatColumn>
      </ChatWrapper>
    );
  } else {
    return <Loader />;
  }
};

export default connect(
  ({
    userReducer: { token, username },
    chatReducer: {
      chatThemes,
      socketConnect,
      assetMultiChat,
      messages,
      unreadMessages
    }
  }) => ({
    token,
    chatThemes,
    socketConnect,
    assetMultiChat,
    username,
    messages,
    unreadMessages
  }),
  { requestToAssetMessage, setCurrentChatId, getArchiveMessage }
)(Chat);
