import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { ReactComponent as DashboardIcon } from "../../assets/dashboard-icon.svg";
import { ReactComponent as AssetsIcon } from "../../assets/assets-icon1.svg";
import { ReactComponent as UsersIcon } from "../../assets/users-icon.svg";
import { ReactComponent as KYCIcon } from "../../assets/kyc-icon.svg";
import { ReactComponent as ConnectIcon } from "../../assets/connect-icon.svg";
import { ReactComponent as ClearCashIcon } from "../../assets/clear-cash-icon.svg";
import { ReactComponent as FolderIcon } from "../../assets/folder.svg";
import { ReactComponent as HelpIcon } from "../../assets/help-circle-menu.svg";

export const icons = {
  Dashboard: <DashboardIcon />,
  Assets: <AssetsIcon />,
  Users: <UsersIcon />,
  KYC: <KYCIcon />,
  Connect: <ConnectIcon />,
  ["Create Asset"]: <AssetsIcon />,
  ClearCash: <ClearCashIcon />,
  ["Data Room"]: <FolderIcon />,
  ["Q&A"]: <HelpIcon />,
};

const CustomLink = styled(Link)`
  margin-bottom: 30px;
  padding-right: 15px;
  padding-left: 22px;
  border-left: 2px solid transparent;
  color: #b6b6b699;
  display: flex;
  align-items: center;
  height: 36px;
  text-decoration: none;
  box-sizing: border-box;
  font-size: 18px;
  font-weight: 600;

  &:visited {
    color: #b6b6b699;
    text-decoration: none;
  }

  &.active {
    pointer-events: none;
    color: #ffffff;
    border-left: 2px solid ${({ theme: { navLinkBorder } }) => navLinkBorder};
  }
  &.not-available {
    color: #ffffff;
    opacity: 0.3;
  }
`;

const TextButton = styled(CustomLink)`
  background: ${({ bg = "#2b56f5" }) => bg};
  cursor: pointer;
  border: 2px solid ${({ bg = "#2b56f5" }) => bg};
  color: ${({ color = "#3b49e7" }) => color};
  border-radius: 4px;
  line-height: 18px;
  box-shadow: 0px 2px 18px 2px rgba(43, 86, 245, 0.25);
  padding: 7px 18px;

  &:not(.active) {
    color: ${({ color = "#3b49e7" }) => color};
  }
  &:visited {
    color: ${({ color = "#3b49e7" }) => color};
  }
  &:first-of-type {
    margin-top: 0;
  }
`;

const CustomLinkText = styled(CustomLink)`
  width: 100%;
  text-align: left;
  user-select: none;
  font-family: "Muli", sans-serif;
  color: #b6b6b699;
  font-weight: 600;
  font-size: 14px;
  position: relative;
  &::before {
    content: "${({ count }) => count}";
    display: ${({ count }) => (count || count === null ? "flex" : "none")};
    position: absolute;
    right: 25px;
    top: 8px;
    background: ${({ theme: { primary, headerAdmin }, count }) => (count === null ? headerAdmin : primary)};
    height: 20px;
    min-width: 24px;
    padding: 0 5px;
    color: ${({ theme: { navLinkActive } }) => navLinkActive};
    box-sizing: border-box;
    border-radius: 2px;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    ${({ count }) =>
      count === null
        ? `background-image: url(/assets/clock-progress.svg);
    background-repeat: no-repeat;
    background-size: 12px 12px;
    background-position: 6px 4px;`
        : ``};
  }
`;

export const IconContainer = styled.div`
  width: 29px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  & svg {
    width: 28px;
    height: 28px;
    path {
      stroke: ${({ active }) => (active ? "#fff" : "#b6b6b699")};
    }
  }
`;

const ClearCashContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 24px 28px;
  font-family: Muli;
  font-style: normal;
  cursor: pointer;
  font-weight: 600;
  font-size: 14px;
  line-height: 135%;
  color: #b6b6b699;
`;

export const ClearCash = ({ onClick, text }) => {
  return (
    <ClearCashContainer onClick={onClick}>
      <IconContainer>
        <ClearCashIcon />
      </IconContainer>
      {text}
    </ClearCashContainer>
  );
};

export const IconLink = ({ icon, link, active, asText = false }) => {
  if (asText) {
    return <em style={{ color: "#3B49E7", marginRight: 20 }} className={icon} />;
  } else {
    return (
      <CustomLink to={link} className={active ? "active" : ""}>
        <em style={{ color: "#3B49E7" }} className={icon} />
      </CustomLink>
    );
  }
};

export const TextLink = ({ link, active, available = true, children, onClick, text, count }) => {
  return (
    <CustomLinkText
      count={count}
      to={link}
      className={active ? "active" : !available ? "not-available" : ""}
      onClick={onClick}
    >
      <IconContainer active={active}>{icons[text]}</IconContainer>
      {text}
    </CustomLinkText>
  );
};

export const BottomLinksBlock = styled.div`
  width: 100%;
  box-sizing: border-box;
  margin-top: auto;
  display: flex;
  flex-direction: column;
  padding: 28px 28px;
  border-top: 2px solid ${({ theme: { sideBarSepLine } }) => sideBarSepLine};
`;

export const LinksBlockHeading = styled.h6`
  margin: 0 0 20px;
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 135%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.2);
`;

const SetupLink = styled(Link)`
  font-family: Muli;
  font-weight: 300;
  font-size: 14px;
  line-height: 135%;
  color: ${({ active }) => (active ? "#ffffff" : "rgba(255, 255, 255, 0.4)")};
  margin-bottom: 15px;
  text-decoration: none;
  cursor: pointer;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const BottomLink = ({ text, link, active }) => {
  return (
    <SetupLink to={link} active={active}>
      {text}
    </SetupLink>
  );
};

export const ButtonLink = ({ link, active, children, ...props }) => {
  return (
    <TextButton to={link} className={active ? "active" : ""} {...props}>
      {children}
    </TextButton>
  );
};
