import React, { useEffect, useState, Fragment } from "react";
import { connect } from "react-redux";

import { getQuestionAnswer } from "../../../../modules/dataRedux/dataAction";
import {
  HeaderRow,
  HeaderHeading,
  FilterBlock,
  Flex,
  RouteLink,
  DealsWrapper,
  SearchInput,
} from "./styled";
import { ReactComponent as SearchIcon } from "../../../../assets/search-icon.svg";
import { ErrorMessage } from "../../errorMessage";
import { DealCard } from "./deal";
import { Wait } from "../DashBoard/styled";

const QAMain = ({
  token,
  match: {
    params: { type },
  },
  history,
  viewQA,
  getQuestionAnswer,
}) => {
  const [search, setSearch] = useState("");
  const [error, setError] = useState(null);
  const [wasError, setWasError] = useState(false);

  const onError = (e) => {
    setWasError(true);
    setError(e);
  };

  const onSearchInput = (e) => {
    const {
      target: { value },
    } = e;
    setSearch(value);
  };

  const getTotalQuestions = (data) => {
    return data.reduce((total, e) => {
      return total + e.waitingQuestions;
    }, 0);
  };

  const renderDeals = () => {
    return viewQA
      .filter((e) => {
        if (type === "all") {
          return true;
        }
        if (type === "active") {
          return e.active;
        }
        if (type === "draft") {
          return !e.active;
        }
      })
      .filter((e) => e.assetName.toLowerCase().includes(search.toLowerCase()))
      .map((e) => (
        <DealCard
          deal={e}
          token={token}
          key={e.assetId}
          history={history}
          onUpdateDealStatus={getQuestionAnswer}
        />
      ));
  };

  useEffect(() => {
    getQuestionAnswer(token, onError);
  }, []);

  if (!viewQA && !error && !wasError) {
    return <Wait />;
  } else if (!viewQA && error) {
    return <ErrorMessage er={error} onClose={() => setError(null)} />;
  } else if (viewQA) {
    return (
      <Fragment>
        <HeaderRow>
          <FilterBlock>
            <HeaderHeading>
              {`You have 
            ${viewQA.length || "no"} 
            assets now ( ${getTotalQuestions(viewQA)} pending questions )`}
            </HeaderHeading>
            <Flex width="324px">
              <RouteLink
                width={100}
                to="/admin/question-and-answer/all"
                transparent={type !== "all"}
              >
                All
              </RouteLink>
              <RouteLink
                width={100}
                to="/admin/question-and-answer/active"
                transparent={type !== "active"}
              >
                Active
              </RouteLink>
              <RouteLink
                width={100}
                to="/admin/question-and-answer/draft"
                transparent={type !== "draft"}
              >
                Draft
              </RouteLink>
            </Flex>
          </FilterBlock>
          <SearchInput
            placeholder="Search by asset name"
            value={search}
            onChange={(e) => onSearchInput(e)}
          />
          <SearchIcon />
        </HeaderRow>
        <DealsWrapper>{renderDeals()}</DealsWrapper>
      </Fragment>
    );
  } else return null;
};

export default connect(
  ({ userReducer: { token }, dataReducer: { viewQA } }) => ({
    token,
    viewQA,
  }),
  { getQuestionAnswer }
)(QAMain);
