import React, { useEffect, useState } from "react";
import { CircularProgress } from "@material-ui/core";

import { Main, TextSub } from "../../DealForm/Step/styled";
import { FlexRow } from "../../../dashBoardCard";
import { HelpCircle } from "components/common/icons-primary";
import { LeftBlock } from "../styled";
import { Btn } from "../../../buttons";

import { EmptyInfo } from "./emptyInfo";
import Api from "helpers/api";
import { QuestionCardWaiting } from "components/admin-layout/pages/QAAsset/QuestionCardWaiting";
import { ScrollContainer } from "../../../dealTypeCard";

export const Questions = ({ token, assetId, history }) => {
  const [data, setData] = useState(null);

  const onGoToQA = () => {
    history.push(`/admin/question-and-answer/asset/${assetId}`);
  };

  useEffect(() => {
    Api.getQuestionAssetAll(token, assetId).then((q) => setData(q.filter((q) => q.isWaiting)));
  }, []);

  const renderQuestions = () =>
    data.map((q) => <QuestionCardWaiting key={q.questionId} q={q} history={history} token={token} />);

  return (
    <Main currentHeight="402px" width="100%" padding="20px 25px 25px 25px">
      <FlexRow mBottom="0" align="flex-start">
        <TextSub textAlign="left" fontWeight="bold" color="#181818" marginSize="0 0 25px 0">
          Questions Waiting for Answer (Q&A)
        </TextSub>
      </FlexRow>
      <LeftBlock>
        <Btn transparent width={180} onClick={onGoToQA}>
          OPEN Q&A
        </Btn>
      </LeftBlock>
      {!data ? (
        <CircularProgress size={24} style={{ margin: "auto" }} />
      ) : !data.length ? (
        <EmptyInfo
          icon={<HelpCircle />}
          heading="There are no questions yet"
          text="When we’ll have questions to show for you we’ll show them here"
        />
      ) : (
        <ScrollContainer style={{ padding: "0 25px 0 15px" }} minHeight={314}>
          {renderQuestions()}
        </ScrollContainer>
      )}
    </Main>
  );
};
