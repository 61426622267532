import React, { useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import { connect } from "react-redux";

//admin pages
import Main from "./data-room-layout/admin/pages/Main";
import DealDocs from "./data-room-layout/admin/pages/DealDocs";
import UserStatisctic from "./data-room-layout/admin/pages/UserStatistic";
import UploadLegalFile from "./data-room-layout/admin/pages/UploadLegalFile";

//investor pages
import MainInvestor from "./data-room-layout/investor/pages/Main";
import DealDocsInvestor from "./data-room-layout/investor/pages/DealDocs";

const DataRoomApp = () => {
  useEffect(() => () => console.log("data-room"), []);
  return (
    <Switch>
      {/* admin pages */}
      <Route exact path="/admin/data-room/:type" component={Main} />
      <Route
        exact
        path="/admin/data-room/deal/:id/:type?"
        component={DealDocs}
      />
      <Route
        exact
        path="/admin/data-room/deal/legal-document/:id/:type/:isAfterSubscription"
        component={UploadLegalFile}
      />
      <Route
        exact
        path="/admin/data-room/deal/:assetId/statistics/:userName"
        component={UserStatisctic}
      />
      {/* investor pages */}
      <Route exact path="/investor/data-room/main" component={MainInvestor} />
      <Route
        exact
        path="/investor/data-room/deal/:id"
        component={DealDocsInvestor}
      />
    </Switch>
  );
};

export default connect(
  ({ userReducer: { token } }) => ({
    token,
  }),
  null
)(DataRoomApp);
