import React, { useState, useRef, useEffect, useCallback } from 'react';

export function useDebounceState(initialValue, debounceTime = 450) {
  const [state, setState] = useState(initialValue);
  const debounceId = useRef(null);
  const _setState = useCallback((value) => {
    clearTimeout(debounceId.current);

    debounceId.current = setTimeout(() => {
      setState(value);
    }, debounceTime);
  }, []);

  useEffect(() => {
    return () => {
      clearTimeout(debounceId.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return [state, _setState];
}

export const useLocation = () => {
  const [state, setState] = useState({ protocol: '', host: '', port: '', pathname: '', href: '' });

  useEffect(() => {
    const locationChangeHandler = function () {
      try {
        const { protocol = '', host = '', port = '', pathname = '', href = '' } = window.location;
        const _state = { protocol, host, port, pathname, href };

        if (JSON.stringify(state) !== JSON.stringify(_state)) {
          setState(_state);
        }
      } catch (_) {}
    };
    locationChangeHandler();
    window.addEventListener('popstate', locationChangeHandler);

    return () => {
      window.removeEventListener('popstate', locationChangeHandler);
    };
  });

  return { ...state };
};
