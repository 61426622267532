import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";

import Api from "../../../../helpers/api";

import { Header, TextH3, Main, TextSub } from "../DealForm/Step/styled";
import { Btn } from "../../buttons";
import { ReactComponent as ArrowLeft } from "../../../../assets/arrowleft.svg";
import { ExpandedPanel } from "../DealForm/FullFormCards/ExpandedPanel";
import {
  Wrapper,
  Column,
  InputRow,
  InfoBlock,
  Footer,
  WaitingWrapper,
  Separator,
  InputRowGroups,
  GroupsBlock,
  SpanGroup,
} from "./styled";
import {
  TextFieldComponent,
  SelectComponent,
  ImageUploaderComponent,
  TextAreaComponent,
  TextEditorComponent,
} from "../../forms";
import {
  // UsersBlock,
  GreyBlock,
  UserBlock,
  SmallLogo,
  EmptyLogo,
} from "../NewGroup/styled";
import { ReactComponent as UserIcon } from "../../../../assets/user-plus.svg";
import { ReactComponent as Info } from "../../../../assets/info.svg";
import { ReactComponent as NoAvatar } from "../../../../assets/no-avatar.svg";
import { ReactComponent as Close } from "../../../../assets/x-close-red.svg";
import { CircularProgress } from "@material-ui/core";
import { ErrorMessage } from "../../errorMessage";
import { CreateSponsor } from "./createSponsor";

const CreateUser = ({ token, history, countriesOption }) => {
  const [roles, setRoles] = useState([]);
  const [role, setRole] = useState("INVESTOR");
  const [instututions, setInstututions] = useState([]);
  const [instututionsAll, setInstututionsAll] = useState(null);
  const [institution, setInstitution] = useState("none");
  const [allGroups, setAllGroups] = useState([]);
  const [groupsList, setGroupsList] = useState([]);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [country, setCountry] = useState("");
  const [gender, setGender] = useState("");
  const [email, setEmail] = useState("");
  const [sponsorLogo, setSponsorLogo] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    (async function () {
      const groups = await Api.getUserGroups(token);
      if (groups) {
        setAllGroups(groups);
      }

      const inst = await Api.getInstitutionAll(token);
      if (inst) {
        setInstututionsAll(inst);
        const arr = inst.map((e) => e.name);
        arr.unshift("none");
        setInstututions(arr);
      }

      const roles = await Api.getRoles(token);
      setRoles(roles);
    })();
  }, []);

  const onSponsorDataChange = (key) => (e) => {
    const value = e && e.target ? e.target.value : e;
    setSponsorData({ ...sponsorData, [key]: value });
  };

  const onFirstNameInput = (e) => setFirstName(e.target.value);
  const onLastNameInput = (e) => setLastName(e.target.value);
  const onCountryInput = (e) => setCountry(e.target.value);
  const onGenderInput = (e) => setGender(e.target.value);
  const onEmailInput = (e) => setEmail(e.target.value);

  const [waitResponse, setWaitResponse] = useState(false);

  const [sponsorData, setSponsorData] = useState({
    email: "",
    fullName: "",
    description: "",
    userDescriptionExtended: "",
    logo: null,
    countryOfResidence: "",
  });

  const onError = (e) => {
    setWaitResponse(false);
    setError(e);
  };

  const onAddGroups = (val = []) => setGroupsList(val);

  const renderGroups = () => {
    return groupsList.reduce((groups, _id) => {
      const group = allGroups.find((el) => el.id == _id);

      return [
        ...groups,
        <Group data={{ ...group }} key={`${group.name}`} token={token} onRemove={(id) => onRemoveGroup(id)} />,
      ];
    }, []);
  };

  const onRemoveGroup = (id) => {
    setGroupsList(groupsList.filter((_id) => _id !== `${id}`));
  };

  const registerInvestor = async () => {
    setWaitResponse(true);
    groupsList.forEach((g) => +g);
    if (institution !== "none") {
      const selInst = instututionsAll.find((el) => el.name === institution);
      const res = await Api.registerInvestorInstitution(
        token,
        selInst.id,
        email,
        firstName,
        lastName,
        country,
        gender
      ).catch((e) => onError(e));

      if (res) {
        const userId = res.replace(/"/g, "");
        const okGroup = await Api.addUserToGroups(token, groupsList, userId).catch((e) => onError(e));
        if (okGroup) history.push("/admin/users");
        else setWaitResponse(false);
      } else setWaitResponse(false);
    } else {
      const res = await Api.registerInvestor(token, email, firstName, lastName, country, gender).catch((e) =>
        onError(e)
      );

      if (res) {
        const userId = res.replace(/"/g, "");
        const okGroup = await Api.addUserToGroups(token, groupsList, userId).catch((e) => onError(e));
        if (okGroup) history.push("/admin/users");
        else setWaitResponse(false);
      } else setWaitResponse(false);
    }
  };

  const registerSponsor = async () => {
    setWaitResponse(true);
    if (sponsorLogo) {
      await Api.uploadAttachment(token, sponsorLogo).then((json) => setSponsorData({ ...sponsorData, logo: json }));
    }

    const ok = await Api.registerSponsor(token, sponsorData);
    if (ok) history.push("/admin/users");
    else setWaitResponse(false);
  };

  const registerAdmin = async () => {
    setWaitResponse(true);
    const data = {
      fullName: `${firstName} ${lastName}`,
      email: email,
    };

    const ok = await Api.registerAdmin(token, data);
    if (ok) history.push("/admin/users");
    else setWaitResponse(false);
  };

  const isDisabled = () => {
    if (role === "INVESTOR") {
      if (!firstName || !lastName || !country || !gender || !email.match(/\S+@\S+\.\S+/)) {
        return true;
      }
    } else if (role === "SPONSOR") {
      if (
        !sponsorData.countryOfResidence ||
        !sponsorData.description ||
        !sponsorData.email.match(/\S+@\S+\.\S+/) ||
        !sponsorData.userDescriptionExtended ||
        !sponsorData.fullName
      ) {
        return true;
      }
    } else if (role === "ADMIN") {
      if (!firstName || !lastName || !email.match(/\S+@\S+\.\S+/)) {
        return true;
      }
    }
  };

  return (
    <Fragment>
      {waitResponse && (
        <WaitingWrapper>
          <CircularProgress />
        </WaitingWrapper>
      )}
      <Header>
        <Btn transparent onClick={() => history.push("/admin/users")} width={150}>
          <ArrowLeft /> back to users
        </Btn>
        <TextH3 marginSize="0 0 0 25px">Add New User</TextH3>
      </Header>
      {role === "SPONSOR" ? (
        <CreateSponsor
          history={history}
          token={token}
          onChangeRole={setRole}
          roles={roles}
          countries={countriesOption}
        />
      ) : (
        <Fragment>
          <Wrapper>
            <Column width="calc(72% - 30px)">
              <Main noHidden>
                <TextSub textAlign="left" fontWeight="bold" color="#181818" marginSize="0 0 25px 0">
                  Main Information
                </TextSub>
                {role === "SPONSOR" ? (
                  <Fragment>
                    <InputRow>
                      <TextFieldComponent
                        width="calc(59% - 15px)"
                        label="company name"
                        placeholder="Company name"
                        onChange={onSponsorDataChange("fullName")}
                      />
                      <SelectComponent
                        view="filter"
                        defaultValue={""}
                        values={countriesOption}
                        onChange={onSponsorDataChange("countryOfResidence")}
                        width="calc(41% - 15px)"
                        label="Country of registration"
                        placeholder="Country of registration"
                      />
                    </InputRow>
                    <InputRow last>
                      <TextFieldComponent
                        width="calc(59% - 15px)"
                        label="web-site"
                        placeholder="Company web-site"
                        disabled={true}
                      />
                      <TextFieldComponent
                        width="calc(41% - 15px)"
                        label="e-mail"
                        placeholder="Users e-mail"
                        onChange={onSponsorDataChange("email")}
                      />
                    </InputRow>
                  </Fragment>
                ) : role === "INVESTOR" ? (
                  <Fragment>
                    <InputRow>
                      <TextFieldComponent
                        width="calc(50% - 6px)"
                        label="First name"
                        placeholder="User first name"
                        defaultValue={firstName}
                        onChange={(e) => onFirstNameInput(e)}
                      />
                      <TextFieldComponent
                        width="calc(50% - 6px)"
                        label="Last name"
                        placeholder="User last name"
                        defaultValue={lastName}
                        onChange={(e) => onLastNameInput(e)}
                      />
                    </InputRow>
                    <InputRow>
                      <SelectComponent
                        view="filter"
                        values={countriesOption}
                        onChange={(e) => onCountryInput(e)}
                        width="calc(50% - 6px)"
                        placeholder="User country of residence"
                        label="Country of residence"
                      />
                      <SelectComponent
                        values={["MALE", "FEMALE"]}
                        onChange={(e) => onGenderInput(e)}
                        width="calc(50% - 6px)"
                        placeholder="User gender"
                        label="Gender"
                      />
                    </InputRow>
                    <InputRow last>
                      <TextFieldComponent
                        width="calc(50% - 6px)"
                        placeholder="User e-mail"
                        label="E-mail"
                        defaultValue={email}
                        onChange={(e) => onEmailInput(e)}
                      />
                      <InfoBlock width="calc(50% - 6px)">
                        <Info />
                        User will get an e-mail with instructions to finish registration
                      </InfoBlock>
                    </InputRow>
                  </Fragment>
                ) : role === "ADMIN" ? (
                  <Fragment>
                    <InputRow>
                      <TextFieldComponent
                        width="calc(50% - 6px)"
                        label="First name"
                        placeholder="User first name"
                        defaultValue={firstName}
                        onChange={(e) => onFirstNameInput(e)}
                      />
                      <TextFieldComponent
                        width="calc(50% - 6px)"
                        label="Last name"
                        placeholder="User last name"
                        defaultValue={lastName}
                        onChange={(e) => onLastNameInput(e)}
                      />
                    </InputRow>
                    <InputRow last>
                      <TextFieldComponent
                        width="calc(50% - 6px)"
                        placeholder="User e-mail"
                        label="E-mail"
                        defaultValue={email}
                        onChange={(e) => onEmailInput(e)}
                      />
                      <SelectComponent
                        width="calc(50% - 6px)"
                        placeholder="Permissions"
                        label="Permissions"
                        values={["ALL"]}
                        value="ALL"
                      />
                    </InputRow>
                  </Fragment>
                ) : null}
              </Main>
              <Separator />
              {role === "SPONSOR" && (
                <Fragment>
                  <Main>
                    <TextSub textAlign="left" fontWeight="bold" color="#181818" marginSize="0 0 25px 0">
                      Additional Information
                    </TextSub>
                    <InputRow>
                      <TextFieldComponent label="Slogan" placeholder="Company slogan goes here" disabled={true} />
                    </InputRow>
                    <InputRow last>
                      <ImageUploaderComponent
                        width="calc(39% - 15px)"
                        label="Avatar image"
                        onChange={(file) => setSponsorLogo(file)}
                      />
                      <ImageUploaderComponent sponsorBg width="calc(61% - 15px)" label="Background image" />
                    </InputRow>
                  </Main>
                  <Separator />
                  <ExpandedPanel header="About Sponsor">
                    <InputRow>
                      <TextAreaComponent
                        label="short information (up to 350 symbols)"
                        placeholder="Sponsors short information"
                        onChange={onSponsorDataChange("description")}
                      />
                    </InputRow>
                    <InputRow last>
                      <TextEditorComponent
                        label="full information"
                        onChange={onSponsorDataChange("userDescriptionExtended")}
                      />
                    </InputRow>
                  </ExpandedPanel>
                </Fragment>
              )}
            </Column>
            <Column width="28%">
              <Main noHidden>
                {role === "INVESTOR" ? (
                  <Fragment>
                    <TextSub textAlign="left" fontWeight="bold" color="#181818" marginSize="0 0 25px 0">
                      Options
                    </TextSub>
                    <InputRow>
                      <SelectComponent
                        label="User type"
                        values={roles}
                        defaultValue={role}
                        onChange={(e) => {
                          setRole(e.target.value);
                        }}
                      />
                    </InputRow>
                    <InputRow last>
                      <SelectComponent
                        label="Institution"
                        values={instututions}
                        defaultValue={institution ? institution : ""}
                        onChange={(e) => {
                          setInstitution(e.target.value);
                        }}
                      />
                    </InputRow>
                  </Fragment>
                ) : (
                  <Fragment>
                    <TextSub textAlign="left" fontWeight="bold" color="#181818" marginSize="0 0 25px 0">
                      Options
                    </TextSub>
                    <InputRow last>
                      <SelectComponent
                        label="User type"
                        values={roles}
                        defaultValue={role}
                        onChange={(e) => {
                          setRole(e.target.value);
                        }}
                      />
                    </InputRow>
                  </Fragment>
                )}
              </Main>
              {role === "INVESTOR" ? (
                <Fragment>
                  <Separator />
                  <Main noHidden>
                    <TextSub textAlign="left" fontWeight="bold" color="#181818" marginSize="0 0 25px 0">
                      Groups
                    </TextSub>
                    <InputRowGroups>
                      <SelectComponent
                        multiple
                        // Массив значений которыми будет оперировать компонент
                        values={allGroups.map((e) => e.id + "")}
                        // Массив надписей которые будут отображены, должен совпадать по длинне с values
                        labels={allGroups.map((e) => e.name)}
                        // будет изменять внутренний стейт на заднный каждый раз когда они не равны
                        controlledValue={groupsList}
                        onChange={(val) => onAddGroups(val)}
                        label="assign users to group"
                      />
                      {/* <Separator /> */}
                      {groupsList.length ? (
                        <GroupsBlock>{renderGroups()}</GroupsBlock>
                      ) : (
                        <GreyBlock>
                          <UserIcon />
                          <div>Assign users to this group using the field above</div>
                        </GreyBlock>
                      )}
                    </InputRowGroups>
                  </Main>
                </Fragment>
              ) : null}
            </Column>
          </Wrapper>
          <Footer>
            <Btn hide width={180}>
              Cancel
            </Btn>
            <Btn
              disabled={isDisabled()}
              width={180}
              onClick={role === "INVESTOR" ? registerInvestor : role === "SPONSOR" ? registerSponsor : registerAdmin}
            >
              Add user
            </Btn>
          </Footer>
        </Fragment>
      )}

      {error ? <ErrorMessage er={error} onClose={() => setError(null)} /> : null}
    </Fragment>
  );
};

export default connect(
  ({ userReducer: { token }, asyncDataReducer: { countriesOption } }) => ({
    token,
    countriesOption,
  }),
  null
)(CreateUser);

const Group = ({ token, data: { logoFileName, logoHash, name, id }, onRemove }) => {
  const [avatar, setAvatar] = useState(null);
  useEffect(() => {
    if (!avatar) {
      Api.getAttachment(token, logoHash, logoFileName).then((b) => setAvatar(b));
    }
  });
  return (
    <UserBlock width="100%">
      {logoFileName && logoHash && !avatar ? (
        <EmptyLogo />
      ) : !logoHash || !logoFileName ? (
        <NoAvatar />
      ) : (
        <SmallLogo src={avatar} />
      )}
      <SpanGroup>{name}</SpanGroup>
      <Close onClick={() => onRemove(id)} />
    </UserBlock>
  );
};
