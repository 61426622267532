import moment from "moment";
import { number } from "mout";

export const uidGenerator = () => {
  return (
    Math.random()
      .toString(36)
      .replace("0.", "one") +
    Math.random()
      .toString(36)
      .replace("0.", "two") +
    Math.random()
      .toString(36)
      .replace("0.", "three") +
    Math.random()
      .toString(36)
      .replace("0.", "four") +
    Math.random()
      .toString(36)
      .replace("0.", "fife") +
    Math.random()
      .toString(36)
      .replace("0.", "six") +
    Math.random()
      .toString(36)
      .replace("0.", "seven")
  );
};

export const fileEncodeToBase64 = file => {
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => resolve();
  });
};

export const objectUrlEncodeToBase64 = url => {
  return new Promise(async resolve => {
    const res = await fetch(url);
    const blob = await res.blob();

    const reader = new FileReader();
    reader.readAsDataURL(blob);

    reader.onload = () => {
      resolve(reader.result.replace(/^[^,]+,{1,1}/, ""));
    };
    reader.onerror = error => resolve();
  });
};

export const mapCash = (data, cb) => {
  try {
    return Object.keys(data).map(key => cb(key, data[key]));
  } catch (err) {
    console.log(err);
    return [];
  }
};

export const mapMoney = obj => {
  try {
    const [[currency, money]] = Object.entries(obj);
    return `${money} ${currency}`;
  } catch (error) {
    console.log(error);
    return "";
  }
};

export const clone = data => (data ? JSON.parse(JSON.stringify(data)) : data);

export function extract(object, ...keys) {
  let name = "";
  let buffer = object;

  keys.forEach(key => {
    if (buffer && typeof buffer[key] !== "undefined") {
      buffer = buffer[key];
    }
    name = buffer;
  });
  return name;
}

export const queryToObject = str => {
  if (str) {
    str = str.replace("?", "").split("&");
    str = str.reduce((q, param) => {
      const [key, val] = param.split("=");
      q[key] = val;
      return q;
    }, {});

    return str;
  }
};

export const AmountParser = str => {
  const split = str.split(" ");
  return +split[0].replace(/\./, "");
};

export const stringToDate = str => {
  try {
    const date = str
      .replace("-", "")
      .match(/(\d{4,4})(\d{2,2})(\d{2,2})(\d{2,2})(\d{2,2})/);
    return moment().set({
      year: date[1],
      month: date[2],
      date: date[3],
      hour: date[4],
      minute: date[5]
    });
  } catch (error) {
    return moment();
  }
};

export const createEmptyDoc = (fileNameWithExtension = "") => ({
  attachmentFile: { fileNameWithExtension, secureHash: "" },
  loiRequired: null,
  ndaRequired: null,
  description: "",
  visibility: true
});

export const camelCaseToString = string => {
  let i = string.match(/[A-Z]{1,}/g);
  let newString = string;
  if (i) {
    i.forEach(letter => {
      let r = new RegExp(letter, ["g"]);
      newString = newString.replace(r, ` ${letter}`);
    });
    return newString;
  } else {
    return string;
  }
};

export const isSomeRequredEmpty = (valuesObject, requiredFildsObject) => {
  if (!valuesObject) {
    return true;
  }
  const requiredValues = Object.keys(requiredFildsObject).map(e => {
    if (requiredFildsObject[e].required) {
      return e;
    } else {
      return null;
    }
  });
  const someEmpty = requiredValues
    .map(el => {
      if (
        !valuesObject[el] ||
        (Object.entries(valuesObject[el]).length === 0 &&
          valuesObject[el].constructor === Object)
      ) {
        return true;
      } else {
        return false;
      }
    })
    .some(e => e === true);
  if (someEmpty) {
    return true;
  } else {
    return false;
  }
};

export const formatNumbers = number => {
  let newString = String(number);
  let formatString;
  if (newString.length > 3) {
    formatString =
      newString.slice(0, newString.length - 3) +
      ", " +
      newString.slice(newString.length - 3);
  }
  if (formatString) {
    return formatString;
  } else {
    return number;
  }
};

export const getTokenPrice = string => {
  return +string.match(/[0-9\.]+/)[0];
};

export const countryRule = ["All Country", "White List", "Black List"];
export const defaultCountryRule = {
  ALL: "All Country",
  WHITE_LIST: "White List",
  BLACK_LIST: "Black List",
  "All Country": "ALL",
  "White List": "WHITE_LIST",
  "Black List": "BLACK_LIST"
};
export const getRule = cb => e => {
  const key = e.target.value;
  if (cb) {
    cb(defaultCountryRule[key]);
  }
};

export const helpersFormatTime = ms => {
  const format = {
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  };

  const _hour = 60 * 60 * 1000;
  format.hours = Math.floor(ms / _hour);
  ms -= format.hours * _hour;

  const _minute = 60 * 1000;
  format.minutes = Math.floor(ms / _minute);
  ms -= format.minutes * _minute;

  format.seconds = Math.floor(ms / 1000);

  format.days = format.days < 10 ? `0${format.days}` : format.days;
  format.hours = format.hours < 10 ? `0${format.hours}` : format.hours;
  format.minutes = format.minutes < 10 ? `0${format.minutes}` : format.minutes;
  format.seconds = format.seconds < 10 ? `0${format.seconds}` : format.seconds;

  return format;
};

export const numberWithCommas = x =>
  x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const getFileExtention = fileName =>
  fileName.substring(fileName.lastIndexOf(".") + 1, fileName.length) ||
  fileName;

export const getFileName = str => str.replace(/\.[^/.]+$/, "");

export const formatCount = (number, fixed = 0) => {
  if (number < 1000) {
    return numberWithCommas(number.toFixed(fixed));
  } else if (number >= 1000 && number < 1000000) {
    return numberWithCommas((number / 1000).toFixed()) + "k";
  } else {
    return numberWithCommas((number / 1000000).toFixed(1)) + "m";
  }
};

export const formatUppercase = (str = "") => {
  const _str = str.toLowerCase();
  return _str.charAt(0).toUpperCase() + _str.slice(1);
};

export function nFormatter(num, digits) {
  var si = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" }
  ];
  var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
  var i;
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break;
    }
  }
  return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
}

export function bigNumberFormatter(num) {
  const si = [
    { value: 1, symbol: "" },
    { value: 1e3, symbol: "k" },
    { value: 1e6, symbol: "M" },
    { value: 1e9, symbol: "G" },
    { value: 1e12, symbol: "T" },
    { value: 1e15, symbol: "P" },
    { value: 1e18, symbol: "E" }
  ];
  const rx = /\.0+$|(?!\.[0-9]*[1-9])0+$/;
  let i;
  for (i = si.length - 1; i > 0; i--) {
    if (num >= si[i].value) {
      break;
    }
  }

  return (num / si[i].value)
    .toFixed(num.toString().length - 1)
    .replace(rx, si[i].symbol);
}

export function moutNumber(num) {
  if (num >= 1000) {
    return number.abbreviate(num, 1);
  } else return num;
}
