import React, { useState, useEffect } from "react";

import { BlurBg, ModalWrapper, ModalHeaderVal, ModalHeading, ValBorderBlock, ValFooter, Loading } from "./styled";
import { ReactComponent as CloseIcon } from "../../../../assets/x-circle.svg";
import { Btn } from "../../buttons";
import { TextFieldComponentPlaceholder } from "../../forms";

import Api from "../../../../helpers/api";
import { numberWithCommas } from "../../../../helpers";

export const NewAssetVal = ({
  token,
  assetId,
  onBackDropClick,
  financial: { baseCurrency, valuation, tokenQuantity },
  callBack,
  setErrorMessage,
}) => {
  const oldVal = (valuation * tokenQuantity).toFixed(2);
  const [newVal, setNewVal] = useState(numberWithCommas(oldVal));

  const oldShareVal = (oldVal / tokenQuantity).toFixed(2);
  const [newShareVal, setNewShareVal] = useState(oldShareVal);

  const [load, setLoad] = useState(false);

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      changeAssetVal();
    }
  };

  const onNewValInput = (e) => {
    const {
      target: { value },
    } = e;

    let val = value.replace(/[^0-9,\,,\.]/g, "");
    val = val.replace(/\,/g, "");
    val = (val.match(/^.+\..{0,2}/) && val.match(/^.+\..{0,2}/)[0]) || val;

    let _newShareVal = parseFloat(val.replace(",", "") || 0) / tokenQuantity;

    let fixedSymbols = 2;
    if (isNaN(_newShareVal)) {
      _newShareVal = 0;
    }

    setNewVal(numberWithCommas(val));
    setNewShareVal(_newShareVal.toFixed(2));
  };

  const changeAssetVal = async () => {
    setLoad(true);
    const res = await Api.setNetAssetVal(token, assetId, parseFloat(newShareVal.replace(",", ""))).catch((err) => {
      setErrorMessage(err);
      setLoad(false);
    });
    callBack(res ? true : false);
    setLoad(false);
    onBackDropClick();
  };

  return (
    <BlurBg onClick={onBackDropClick}>
      <ModalWrapper onClick={(e) => e.stopPropagation()} padding="0 30px" margin="auto" width="375px" height="auto">
        {load && <Loading />}
        <ModalHeaderVal>
          <ModalHeading>Net Asset Value1</ModalHeading>
          <CloseIcon onClick={onBackDropClick} />
        </ModalHeaderVal>
        <ValBorderBlock>
          <TextFieldComponentPlaceholder
            padding="0 50px 0 20px"
            width="162px"
            defaultValue={numberWithCommas(oldVal)}
            onChange={() => console.log()}
            label="OLD ASSET VALUE"
            disabled
            pseudoText={baseCurrency}
            pseudo
          />
          <TextFieldComponentPlaceholder
            padding="0 50px 0 20px"
            width="122px"
            defaultValue={numberWithCommas(valuation.toFixed(2))}
            onChange={() => console.log()}
            label="OLD share VALUE"
            disabled
            pseudoText={baseCurrency}
            pseudo
          />
        </ValBorderBlock>
        <ValBorderBlock>
          <TextFieldComponentPlaceholder
            padding="0 50px 0 20px"
            width="162px"
            value={newVal}
            // type="number"
            onChange={(e) => onNewValInput(e)}
            onKeyDown={handleKeyPress}
            label="New ASSET VALUE"
            pseudoText={baseCurrency}
            pseudo
          />
          <TextFieldComponentPlaceholder
            padding="0 50px 0 20px"
            width="122px"
            value={numberWithCommas(newShareVal)}
            label="New share VALUE"
            disabled
            pseudoText={baseCurrency}
            pseudo
          />
        </ValBorderBlock>
        <ValFooter>
          <Btn onClick={onBackDropClick} hide width={94}>
            Cancel
          </Btn>
          <Btn width={180} onClick={changeAssetVal}>
            UPDATE NET ASSET VALUE
          </Btn>
        </ValFooter>
      </ModalWrapper>
    </BlurBg>
  );
};
