import React, { useState } from "react";
import ReactTooltip from "react-tooltip";
import { PieChart } from "react-minimal-pie-chart";

function makeTooltipContent(props) {
  return props.tooltip;
}

function ToolTip(props) {
  const [hovered, setHovered] = useState(null);
  const data = props.data.map(({ title, ...entry }) => {
    return {
      ...entry,
      tooltip: title,
    };
  });

  const shiftSize = 0.5;

  return (
    <div data-tip="" data-for="chart">
      <PieChart
        data={data}
        onMouseOver={(_, index) => {
          setHovered(index);
        }}
        onMouseOut={() => {
          setHovered(null);
        }}
        radius={50 - shiftSize}
        segmentsShift={shiftSize}
      />
      <ReactTooltip
        id="chart"
        getContent={() => (typeof hovered === "number" ? makeTooltipContent(data[hovered]) : null)}
        backgroundColor="#FFFFFF"
        border
        borderColor="#EFEFEF"
        textColor="#181818"
      />
    </div>
  );
}

export default ToolTip;
