import React, { useEffect, useRef } from "react";

import { Card, CardName, Row, Col, BlueBg, Text } from "components/setup-layout/common";
import { TextFieldComponent, TextAreaComponent } from "components/admin-layout/forms";
import { Label } from "components/auhtForms/styled";
import { Btn } from "components/admin-layout/buttons";
import { CropperImg } from "components/common/cropper";

const marginSize = "0 0 24px";

export const PlatformDesign = ({
  onChange = console.log,
  onPreview,
  data: { platformLogo, slogan, description, footerText },
}) => {
  const base64Ref = useRef(null);
  const createAreaProps = (areaHeight = 84) => ({ isInvestor: true, fSize: 12, areaHeight });

  return (
    <Card
      onMouseLeave={onChange("platformLogo", base64Ref && base64Ref.current ? base64Ref.current.getCropData() : null)}
    >
      <CardName>Platform Design</CardName>
      <Row m={marginSize}>
        <TextFieldComponent
          isInvestor
          label="PLATFORM slogan (for sign in & sign up pages)"
          placeholder="Platform slogan goes here"
          value={slogan}
          onChange={(e) => onChange("slogan", e.target.value)()}
        />
      </Row>
      <Row m={marginSize}>
        <TextAreaComponent
          {...createAreaProps()}
          label="Platform description (up to 150 symbols) (for sign in & sign up pages)"
          placeholder="Platform description goes here"
          defaultValue={description}
          onChange={(e) => onChange("description", e.target.value)()}
        />
      </Row>
      <Row m={marginSize}>
        <TextAreaComponent
          {...createAreaProps(68)}
          label="text for footer (up to 150 symbols)"
          placeholder="Footer copyright goes here"
          defaultValue={footerText}
          onChange={(e) => onChange("footerText", e.target.value)()}
        />
      </Row>
      <Row>
        <Col w="calc(100% - 203px)">
          <Label>Logo Image Will be resized to fit 217x50)</Label>
          <CropperImg defaulImage={platformLogo} ref={base64Ref} />
          <Text small>Logo must be recognizable on dark background</Text>
        </Col>
        <Col w="173px">
          <Label>PREVIEW</Label>
          <BlueBg>
            <Btn width={125} margin="auto" onClick={onPreview}>
              PREVIEW
            </Btn>
          </BlueBg>
          <Text small>Welcome Page Preview</Text>
        </Col>
      </Row>
    </Card>
  );
};
