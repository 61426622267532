import React, { useEffect, useState, useRef, Fragment } from "react";
import { connect } from "react-redux";

import Api from "../../../../helpers/investorApi";
import {
  Wrapper,
  Header,
  Back,
  BorderBlock,
  DealName,
  Search,
  FileWrapper,
  Table,
  TRow,
  TCeil,
  FileViewerStickyWrapper,
  EmptyCard,
} from "./styled";
import { FileViewer } from "../../../admin/pages/DealDocs/FileViewer/";
import { ReactComponent as ArrowLeft } from "../../../../../assets/arrowleft.svg";
import { ErrorMessage, Loader } from "../../styled";
import { Folder } from "./Folder";
import { getInvDealFiles } from "../../../../../modules/dataRoomRedux/dataRoomActions";
import { EmptyCard as NoInfo } from "components/common/emptyInfo";
import { File } from "components/common/icons-primary";

let cleanSessionStore = {};

const DealDocs = ({
  token,
  match: {
    params: { id },
  },
  getInvDealFiles,
  investorDeals,
  history,
}) => {
  const [filtered, setFiltered] = useState({});
  const [errorObj, setError] = useState(null);
  const [search, setSearch] = useState("");
  const [isOpenViewer, toggleOpenViewer] = useState(false);
  const [docData, setDocData] = useState(null);
  const timerDebounceRef = useRef(null);
  const [screenSize, setScreenSize] = useState(window.innerWidth);

  const getFiles = () => {
    getInvDealFiles(token, id, setError);
  };

  useEffect(() => {
    getFiles();

    if (investorDeals[id]) {
      investorDeals[id].folders.map(({ name }) => {
        cleanSessionStore[name] = 1;
      });
    }

    const onResize = () => {
      setScreenSize(window.innerWidth);
    };

    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
      Object.keys(cleanSessionStore).forEach((name) => sessionStorage.removeItem(name));
    };
  }, []);

  useEffect(() => {
    if (!Object.keys(filtered).length && investorDeals[id]) {
      setFiltered({ ...investorDeals[id] });
    }
  }, [investorDeals[id]]);

  useEffect(() => {
    toggleOpenViewer(!!docData);
  }, [docData]);

  const onToggleOpenViewer = (data) => {
    setDocData(data);
  };

  useEffect(() => {
    clearTimeout(timerDebounceRef.current);
    timerDebounceRef.current = setTimeout(async () => {
      let searchResult = null;
      if (search) {
        searchResult = await Api.searchInDealDocuments(token, search, id);
      }
      if (searchResult) setFiltered(searchResult);
    }, 500);
  }, [search]);

  const renderFolders = () => {
    const arr = search ? filtered.folders : investorDeals[id].folders;
    const random = Math.round(Math.random() * 1000);
    return (
      <Fragment key="folders">
        {arr.map((e, i) => (
          <Folder
            history={history}
            folder={e}
            key={`${e.name}-${i}-${random}`}
            token={token}
            assetId={id}
            onOpenFile={(...args) => console.log(...args) || onToggleOpenViewer(...args)}
            isOpenViewer={isOpenViewer}
            updateFiles={getFiles}
          />
        ))}
      </Fragment>
    );
  };

  const onSetReviewStatus = async (data) => {
    const { fileName, folderName, assetId, reviewed } = data;

    const review = await Api.setReviewStatus(token, folderName, fileName, assetId, !reviewed).catch(setError);
    if (review) getFiles();
  };

  const onCloseViewer = () => {
    setDocData(null);
    getFiles();
  };

  const onSearch = (s) => {
    setSearch(s);
    if (!s) setFiltered({ ...investorDeals[id] });
  };

  const fromDeal = window.location.hash === "#deal";
  const fromTrade = window.location.hash === "#trade";

  if (investorDeals[id]) {
    const {
      dealInfo: { assetName },
      folders,
    } = investorDeals[id];
    return (
      <Wrapper>
        <Header>
          <BorderBlock>
            <Back
              to={
                fromDeal
                  ? `/investor/offerings/companies/${id}`
                  : fromTrade
                  ? `/investor/offerings/users/${id}/info`
                  : "/investor/data-room/main"
              }
            >
              <ArrowLeft />
              <span>Back to Deal{fromDeal || fromTrade ? "" : "s"}</span>
            </Back>
          </BorderBlock>
          <DealName>{assetName}</DealName>
        </Header>
        {folders.length ? (
          <Fragment>
            <Search value={search} onChange={onSearch} />
            <FileWrapper isOpen={isOpenViewer}>
              <div>
                <Table>
                  <TRow noHover>
                    <TCeil big>Description</TCeil>
                    {isOpenViewer ? null : (
                      <Fragment>
                        <TCeil fixedWidth="147px">Status</TCeil>
                        <TCeil fixedWidth="107px">Type</TCeil>
                        <TCeil fixedWidth="204px">Added</TCeil>
                        <TCeil fixedWidth="210px">User marks</TCeil>
                      </Fragment>
                    )}
                  </TRow>
                  {renderFolders()}
                </Table>
              </div>
              {isOpenViewer && docData ? (
                <FileViewerStickyWrapper>
                  <FileViewer
                    smallScreen={screenSize < 1280}
                    token={token}
                    assetId={id}
                    file={docData}
                    onClose={onCloseViewer}
                    isInvestorViewer
                    callBack={onSetReviewStatus}
                    history={history}
                  />
                </FileViewerStickyWrapper>
              ) : null}
            </FileWrapper>
          </Fragment>
        ) : (
          <EmptyCard>
            <NoInfo icon={<File />} heading="No folders or files for this deal." />
          </EmptyCard>
        )}
      </Wrapper>
    );
  } else if (errorObj) {
    return <ErrorMessage er={errorObj} onClose={() => setError(null)} />;
  } else if (!investorDeals[id] && !errorObj) {
    return <Loader />;
  } else {
    return null;
  }
};

export default connect(
  ({ userReducer: { token }, dataRoomReducer: { investorDeals } }) => ({
    token,
    investorDeals,
  }),
  { getInvDealFiles }
)(DealDocs);
