export default {
  sponsor: {
    email: "",
    fullName: "",
    description: "",
    slogan: "",
    countryOfResidence: null,
    logo: null,
    backgroundLogo: null,
    registrationMethod: "EMAIL",
    phoneNumber: "",
    webSite: "",
    userDynamicProperties: [],
    additionalInformation: [],
  },
  wait: false,
  error: false,
  cardsClose: {
    about: false,
    metrics: false,
    additional: false,
  },
};

export function reducer(state, action) {
  if (action.type === "ON_CHANGE") {
    const { key, data } = action.payload;
    return { ...state, sponsor: { ...state.sponsor, [key]: data } };
  } else if (action.type === "ON_ERROR") {
    const { error } = action.payload;
    return { ...state, wait: false, error: error };
  } else if (action.type === "ON_WAIT") {
    return { ...state, wait: true };
  } else if (action.type === "ON_EXPAND") {
    const { key } = action.payload;
    return { ...state, cardsClose: { ...state.cardsClose, [key]: !state.cardsClose[key] } };
  }
  return state;
}
