import React, { useState, Fragment, useEffect, useRef } from "react";
import styled from "styled-components";
import moment from "moment";

import { ReactComponent as ExpandIcon } from "../../../../assets/chevron-down2.svg";
import { ReactComponent as DownloadIcon } from "../../../../assets/download-doc.svg";
import { ReactComponent as ViewIcon } from "../../../../assets/view.svg";
import { ReactComponent as RemoveIcon } from "../../../../assets/cross-icon.svg";
import { ErrorMessage } from "../../../admin-layout/errorMessage";
import { shadeColor } from "helpers/increseBrightnes";

import Api from "../../../../helpers/api";
import DRApi from "data-room-panel/helpers/api";

export const Wrapper = styled.section`
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  width: 100%;
  padding: 0 30px;
  max-width: 1440px;
  box-sizing: border-box;
`;

export const Col = styled.div`
  width: ${({ width }) => (width ? width : "324px")};
  display: flex;
  flex-direction: column;
  ${({ responsive = "" }) => responsive}
`;

export const Card = styled.div`
  width: 100%;
  padding: ${({ noPadding }) => (noPadding ? 0 : "25px 30px")};
  height: ${({ height }) => (height ? `${height}px` : "auto")};
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid #f0f0f0;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  overflow: ${({ isExpandable }) => (isExpandable ? "hidden" : "visible")};
  transition: height 0.3s linear;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const Separator = styled.div`
  height: 20px;
`;

const CardHeader = styled.div`
  width: 100%;
  /* height: 70px; */
  padding: 18px 30px;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #efefef;
  cursor: pointer;
  > svg {
    cursor: pointer;
    width: 32px;
    height: 32px;
    transform: ${({ open }) => (open ? "rotate(180deg)" : "none")};
    transition: transform 0.3s linear;
    path {
      stroke: #c7c7c7;
    }
  }
`;

const Heading = styled.h1`
  margin: 0;
  font-family: Muli;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 135%;
  color: #181818;
`;

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 30px 30px;
  box-sizing: border-box;
  width: 100%;
`;

const TH = styled.div`
  height: 65px;
  width: 100%;
  display: flex;
  align-items: center;
`;

const THText = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 135%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: rgba(24, 24, 24, 0.6);
  width: ${({ w }) => (w ? w : "250px")};
  @media (max-width: 1280px) {
    font-size: 10px;
  }
  ${({ responsive = "" }) => responsive}
`;

const DocBorder = styled.div`
  width: 100%;
  height: 52px;
  display: flex;
  align-items: center;
  border: 1px solid #efefef;
  box-sizing: border-box;
  border-radius: 4px;
  margin-bottom: 10px;
  transition: all 0.1s linear;
  padding: 0 20px 0 30px;
  &:hover {
    border: 1px solid ${({ theme: { primary } }) => primary};
  }
  &:last-child {
    margin-bottom: 0;
  }
`;

export const DocsSeprateLine = styled.div`
  width: 100%;
  height: 0px;
  margin: 24px 0 20px 0;
  border-top: 1px solid #efefef;
`;

const DocType = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 150%;
  color: #333333;
  width: ${({ w }) => (w ? w : "220px")};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  @media (max-width: 1280px) {
    font-size: 12px;
    width: calc(100% - 276px);
  }
`;

const Date = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: ${({ light }) => (light ? 300 : 600)};
  font-size: 14px;
  line-height: 150%;
  color: #333333;
  @media (max-width: 1280px) {
    font-size: 12px;
  }
`;

const RowFlex = styled.div`
  display: flex;
  width: ${({ width }) => width};
  margin: ${({ margin }) => margin};
  ${({ responsive = "" }) => responsive}
`;

const Button = styled.button`
  outline: none;
  width: 125px;
  height: 32px;
  border: 1px solid ${({ error, theme: { primary, red } }) => (error ? red : primary)};
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ transparent, error, theme: { primary } }) => (transparent || error ? "transparent" : primary)};
  color: ${({ transparent, error, theme: { primary, red } }) => (transparent ? primary : error ? red : "#fff")};
  border-radius: 2px;
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 150%;
  text-transform: uppercase;
  margin-right: 12px;
  cursor: pointer;
  position: relative;
  &:last-child {
    margin-right: 0;
  }
  > svg {
    width: 12px;
    height: 12px;
    margin-right: 6px;
    path {
      stroke: ${({ transparent, error, theme: { primary, red } }) => (transparent ? primary : error ? red : "#fff")};
    }
  }
  &:disabled {
    /* opacity: 0.5; */
    background: #e3e3e3 !important;
    color: white !important;
    border: none;
    > svg {
      path {
        stroke: #fff;
      }
    }
  }
  &:hover {
    &::before {
      display: ${({ hideBorder = false }) => (hideBorder ? "none" : "block")};
    }
  }
  &:active {
    ${({ error, theme: { primary, red } }) => {
      const activeColor = shadeColor(error ? red : primary, -15);
      return `background: ${activeColor};border: 1px solid ${activeColor};color:#fff;`;
    }};
    &::before {
      display: none;
    }
    > svg {
      path {
        stroke: #fff;
      }
    }
  }
  &::before {
    transition: all 0.3s linear;
    content: "";
    position: absolute;
    display: none;
    bottom: -1px;
    left: -1px;
    height: 3px;
    background: ${({ error, theme: { primary, red } }) => (error ? red : primary)};
    width: calc(100% + 2px);
    filter: brightness(0.85);
    border-radius: 0 0 4px 4px;
  }
  @media (max-width: 1280px) {
    font-size: 10px;
    width: 32px;
    > svg {
      margin: 0;
    }
    > span {
      display: none;
    }
  }
`;

const Document = ({ doc, token, type, onUpdate = console.log }) => {
  const { documentName, date, assetName } = doc;
  const [docType] = documentName.split("~");
  const [disable, setDisable] = useState(false);
  const [disableView, setDisableView] = useState(false);
  const [error, setError] = useState(null);
  const [disableRomove, setDisableRemove] = useState(false);

  const onError = (e) => setError(e);

  const downloadDocument = (token, documentName) => async () => {
    setDisable(true);
    const blob = await Api.downloadDocumentBlob(token, assetName, documentName).catch(onError);

    if (blob) {
      const link = document.createElement("a");
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = () => {
        link.href = reader.result;
        link.download = documentName;
        link.click();
      };
    }
    setDisable(false);
  };

  const onView = (token, documentName) => async () => {
    setDisableView(true);
    const fileUrl = await Api.downloadLegalDocument(token, assetName, documentName).catch(onError);

    if (fileUrl) {
      const link = document.createElement("a");
      link.target = "_blank";
      link.href = fileUrl;
      link.click();
    }
    setDisableView(false);
  };

  const onRemove = async () => {
    setDisableRemove(true);
    const rem = await DRApi.removeUserDocument(token, assetName, documentName).catch((e) => {
      setDisableRemove(false);
      console.log(e);
    });
    if (rem) {
      setDisableRemove(false);
      onUpdate();
    }
  };

  return (
    <DocBorder>
      <DocType w="calc(100% - 510px)">{docType === "SC" ? "Share Certificate" : docType.replace(".pdf", "")}</DocType>
      <RowFlex responsive={`@media (max-width: 1280px) {width: 190px;}`}>
        <Date>{moment(date).format("dddd, DD/MM/YYYY,")}</Date>
        <Date light>{moment(date).format("hh:mm:ss")}</Date>
      </RowFlex>
      <RowFlex responsive={`@media (max-width: 1280px) {width: 76px;}`} width="262px" margin="0 0 0 auto">
        <Button disabled={disable} transparent onClick={downloadDocument(token, documentName)}>
          <DownloadIcon />
          <span>Download</span>
        </Button>
        {type !== "Uploaded Files" ? (
          <Button onClick={onView(token, documentName)} disabled={disableView}>
            <ViewIcon />
            <span>View</span>
          </Button>
        ) : (
          <Button error disabled={disableRomove} onClick={onRemove}>
            <RemoveIcon style={{ width: 8 }} />
            <span>Remove</span>
          </Button>
        )}
      </RowFlex>
      {error && <ErrorMessage er={error} onClose={() => setError(null)} />}
    </DocBorder>
  );
};

export const DocCard = ({ heading, type, documents, token, onUpdate = console.log }) => {
  const [open, setOpen] = useState(true);
  const [height, setHeight] = useState(0);
  const cardRef = useRef(null);
  const minimizeHeight = 70;

  const expandCard = () => {
    setOpen(!open);
  };

  const renderDocs = () => {
    if (type === "All documents") {
      const types = Object.keys(documents);
      return (
        <Fragment>
          <TH>
            <THText w="calc(100% - 530px)" responsive={`@media (max-width: 1280px) {width: calc(100% - 296px);}`}>
              Document
            </THText>
            <THText responsive={`@media (max-width: 1280px) {width: 190px;}`}>Signature date</THText>
            <RowFlex margin="0 0 0 auto" width="262px" responsive={`@media (max-width: 1280px) {width: 76px;}`} />
          </TH>
          {types.map((type) => {
            return (
              <div>
                {type !== "Signed documents" && documents[type].length ? <DocsSeprateLine /> : null}
                {documents[type].map((e) => {
                  return (
                    <Fragment key={`${e.documentName}-${e.date}`}>
                      <Document doc={e} token={token} type={type} onUpdate={onUpdate} />
                    </Fragment>
                  );
                })}
              </div>
            );
          })}
        </Fragment>
      );
    } else {
      return (
        <Fragment>
          {documents[type].length ? (
            <Fragment>
              <TH>
                <THText responsive={`@media (max-width: 1280px) {width: calc(100% - 296px);}`}>Document</THText>
                <THText responsive={`@media (max-width: 1280px) {width: 190px;}`}>Signature date</THText>
                <RowFlex width="302px" responsive={`@media (max-width: 1280px) {width: 76px;}`} />
              </TH>
              {documents[type].map((e) => {
                return <Document key={`${e.documentName}-${e.date}`} doc={e} token={token} />;
              })}
            </Fragment>
          ) : (
            <EmptyCard noMb small>
              No {type} for this deal.
            </EmptyCard>
          )}
        </Fragment>
      );
    }
  };

  return (
    <Card isExpandable noPadding ref={cardRef} height={open ? height : minimizeHeight}>
      <CardHeader onClick={expandCard} open={open}>
        <Heading>{heading}</Heading>
        <ExpandIcon />
      </CardHeader>
      <TableWrapper>{renderDocs()}</TableWrapper>
    </Card>
  );
};

export const ExpandCard = ({ heading, children, extraChildren, stopPropagation }) => {
  const [open, setOpen] = useState(true);
  const [height, setHeight] = useState(0);
  const cardRef = useRef(null);
  const minimizeHeight = 70;

  const expandCard = () => {
    setOpen(!open);
  };

  return (
    <Card isExpandable noPadding ref={cardRef} height={open ? height : minimizeHeight}>
      <CardHeader onClick={!stopPropagation ? expandCard : () => console.log()} open={open}>
        <Heading>{heading}</Heading>
        {extraChildren}
        <ExpandIcon onClick={stopPropagation && expandCard} />
      </CardHeader>
      {children}
    </Card>
  );
};

export const SELECT_SIZE = {
  top: 60,
  padding: "0 45px 0 16px",
  isInvestor: true,
  selectHeight: 42,
  labelFsize: 10,
  fSize: 14,
};

export const EmptyCard = styled.div`
  background: #ffffff;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 25px;
  width: ${({ small }) => (small ? "100%" : "1380px")};
  margin: 0 auto;
  font-weight: 600;
  font-size: 16px;
  line-height: 160%;
  color: #333333;
  text-align: center;
  margin-bottom: ${({ small, noMb }) => (small && !noMb ? "20px" : 0)};
`;
