import React from "react";
import styled from "styled-components";
import moment from "moment";
export const Text = styled.span`
  font-weight: ${({ fWeight = 300 }) => fWeight};
  font-size: ${({ fontSize = "12px" }) => fontSize};
  color: ${({ color = "black" }) => color};
`;

export const TextWight = styled.span`
  font-weight: ${({ fWeight = 600 }) => fWeight};
  ${({ lineHeight }) => (lineHeight ? `line-height: ${lineHeight};` : "")}
  color: #222d4b;
`;
export const TextBlock = styled.span`
  display: flex;
  flex-direction: column;
  margin: ${({ margin = 0 }) => margin};
  justify-content: ${({ justifyContent = "flex-start" }) => justifyContent};
  align-items: ${({ alignItems = "flex-start" }) => alignItems};
  color: ${({ color = "#6e81a9" }) => color};
  font-size: ${({ fontSize = "13px" }) => fontSize};

  &:hover {
    color: ${({ colorHover = "#2b56f5" }) => colorHover};
  }
`;

export const TextTime = ({ date = null }) => {
  if (date) {
    return (
      <TextBlock>
        <TextWight>{moment(new Date(date)).format("qq LLL uuuu")}</TextWight>
        <Text>{moment(new Date(date)).format("p")}</Text>
      </TextBlock>
    );
  }
  return null;
};

export const TableHead = styled.span`
  font-size: 13px;
  line-height: 20px;
  font-weight: 700;
  letter-spacing: 0.1em;
  color: #5e76a3;
  text-transform: uppercase;
`;

export const TableName = styled.span`
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.02em;
  color: #222d4b;
`;

export const TableMain = styled.span`
  color: #222d4b;
  font-size: 16px;
  font-weight: 500;
`;

export const TextOverflow = styled.p`
  white-space: nowrap;
  max-width: 200px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Label = styled.span`
  white-space: nowrap;
  color: ${({ color = "#5e76a3" }) => color};
  font-size: ${({ fontSize = "16px" }) => fontSize};
  font-weight: ${({ fontWeight = 400 }) => fontWeight};

  ${({ br = false }) => {
    if (br) {
      return `
        &::after {
          content: '';
          display: block;
        }
      `;
    }
    return "";
  }}
`;

export const VarText = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 135%;
  letter-spacing: -0.02em;
  color: #181818;
  > span {
    margin-right: 7px;
  }
`;

export const CardTitle = styled.h6`
  font-family: Muli;
  font-weight: bold;
  font-size: 14px;
  line-height: 135%;
  color: #181818;
  margin: 0 0 25px 0;
`;

export const DocType = styled.h6`
  font-family: Muli;
  font-weight: normal;
  font-size: 12px;
  line-height: 135%;
  color: #181818;
  margin: 0 0 20px 0;
`;

export const GreyText = styled.div`
  font-family: Muli;
  font-weight: bold;
  font-size: 10px;
  line-height: 135%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: rgba(24, 24, 24, 0.4);
`;

export const InputLabel = styled.div`
  white-space: nowrap;
  font-family: Muli;
  font-weight: bold;
  font-size: ${({ labelFsize }) => (labelFsize ? `${labelFsize}px` : "10px")};
  line-height: 135%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: ${({ isInvestor }) => (isInvestor ? "rgba(24, 24, 24, 0.6)" : "rgba(24, 24, 24, 0.4)")};
  margin-bottom: ${({ isInvestor }) => (isInvestor ? "8px" : "12px")};
  display: block;
  visibility: ${({ hidden }) => (hidden ? "hidden" : "visible")};

  ${({ asSelect, arrowPosition, arrow, height, width, padding, theme: { primary }, edit }) => {
    if (asSelect) {
      return `
        box-sizing: border-box;
        font-size: 12px;
        line-height: 135%;
        font-weight: normal;
        letter-spacing: -0.02em;
        padding: ${padding ? padding : "12px 20px"};
        color: #181818;
        border: 1px solid ${edit ? primary : "#efefef"};
        border-radius: 4px;
        background-position: right;
        background-size: 16px;
        background-repeat: no-repeat;
        background-image: url(${arrow});
        height: ${height ? `${height}px` : "42px"};
        width: ${width ? width : "100%"};
        background-position-x: ${arrowPosition ? `calc(100% - ${arrowPosition}px)` : "calc(100% - 24px)"};
      `;
    }
  }}
`;
