import React, { Fragment } from "react";
import ReactDom from "react-dom";

import {
  PreviewWrapper,
  PrevHeader,
  Back,
  PrevHeading,
  BottomHeader,
  PrevText,
  Empty,
  ColWrapper,
  Col,
} from "./styled";
import { icons } from "../../../../models/icons";
//components from investor
import { SubmitBlock } from "components/investor-layout/pages/Offer/SubmitBlock";
import { RaisedChart } from "components/investor-layout/pages/Offer/RaisedChart";
import { DynamicFinancial } from "components/investor-layout/pages/Offer/DynamicFinancial";
import { DataRoom } from "components/investor-layout/pages/Offer/DataRoom";
import { RequestPreview } from "components/investor-layout/pages/Offer/RequestToManager";
import Slider from "components/investor-layout/pages/Offer/Slider";
import { Description } from "components/investor-layout/pages/Offer/Description";
import { ShareInfo } from "components/investor-layout/pages/Offer/ShareInfo";
import { Sponsor } from "components/investor-layout/pages/Offer/Sponsor";
import { DynamicBlock } from "components/investor-layout/pages/Offer/DynamicBlock";
import { BottomSubmitBlock } from "components/investor-layout/pages/Offer/BottomSubmit";
import { DynamicCapital } from "components/investor-layout/pages/Offer/DynamicCapital";
import { Stepper } from "components/investor-layout/pages/Offer/Stepper";

export const AssetPreview = ({ values, onClose, token, assetId, _sponsors = [] }) => {
  const {
    assetFinancialProperty,
    assetFinancialProperty: { baseCurrency, minInvestment, maxInvestment, tokenQuantity, valuation },
    raisedVsToBeRaised,
    assetProperties,
    assetProperties: {
      images,
      dealsType,
      assetSymbol,
      documents,
      vocAssetDynamicProperties,
      dynamicCapitalStructureJson,
    },
    managerSponsors,
    subscribers,
    newWorkflow,
    roundsInfo,
  } = values;
  const newDeal = dealsType === "NEW_DEAL";

  const renderDynamic = () => {
    return vocAssetDynamicProperties
      .filter((e) => e.header && e.text.replace(/<[^>]*>/g, ""))
      .map((e) => <DynamicBlock block={e} key={e.header} />);
  };

  return ReactDom.createPortal(
    <PreviewWrapper>
      <PrevHeader>
        <Back onClick={onClose}>{icons.left} back to SETINGS</Back>
        <PrevHeading>Asset Preview</PrevHeading>
        <Empty />
      </PrevHeader>
      <BottomHeader>
        <PrevText>
          This is the page how investors see your asset in their interface. You can fill information in editing
          interface and check all changes here.
        </PrevText>
      </BottomHeader>
      <ColWrapper>
        <Col width="calc(100% - 470px)">
          {images.length ? <Slider key={assetId || "null-of-asset"} images={images} token={token} /> : null}
          <Description info={assetProperties} />
          <ShareInfo
            roundsInfo={roundsInfo}
            info={assetProperties}
            token={token}
            assetId={assetId}
            financialInfo={assetFinancialProperty}
          />
          {managerSponsors.length && _sponsors.find((s) => s.username === managerSponsors[0]) ? (
            <Sponsor token={token} manager={_sponsors.find((s) => s.username === managerSponsors[0])} isPreview />
          ) : null}
          {vocAssetDynamicProperties ? renderDynamic() : null}
          <BottomSubmitBlock
            stepName="INVEST NOW"
            onSubmit={console.log}
            dealsType={dealsType}
            toBeRaised={raisedVsToBeRaised ? raisedVsToBeRaised.toBeRaised : 0}
          />
        </Col>
        <Col width="440px">
          {newDeal ? (
            <Fragment>
              <SubmitBlock
                newWorkflow={newWorkflow}
                step={3}
                stepName="INVEST NOW"
                onSubmit={console.log}
                dealsType={dealsType}
              />
              {newWorkflow ? <Stepper isPreview /> : null}
              <RaisedChart
                baseCurrency={baseCurrency}
                raisedVsToBeRaised={raisedVsToBeRaised ? raisedVsToBeRaised : { raised: 0, toBeRaised: +tokenQuantity }}
                assetSymbol={assetSymbol}
                subscribers={subscribers || 0}
                minInvestment={minInvestment}
                maxInvestment={maxInvestment}
                valuation={valuation}
              />
              <DynamicCapital values={JSON.parse(dynamicCapitalStructureJson)} baseCurrency={baseCurrency} />
            </Fragment>
          ) : null}
          <DynamicFinancial info={assetProperties} />
          {!newWorkflow ? (
            <DataRoom isPreview docs={documents} token={token} stepName="INVEST NOW" makeSign={console.log} />
          ) : null}
          <RequestPreview />
        </Col>
      </ColWrapper>
    </PreviewWrapper>,
    document.getElementById("asset-preview")
  );
};
