import React, { useState, useRef, useEffect } from "react";

import {
  BG,
  FormBlockEmpty,
  FormEmpty,
  FormText,
  FilesModal,
  MGeader,
  HeadingM,
  MWrapper,
  Form,
  FormHeading,
  Button,
  FilesWrapper,
  Row,
  File,
  FileName_,
  Size,
  IconsBlock,
} from "../styled";
import { ReactComponent as Upload } from "../../../../../../assets/upload-big.svg";
import { ReactComponent as CloseIcon } from "../../../../../../assets/x-circle.svg";
import { ReactComponent as UploadIcon } from "../../../../../../assets/upload.svg";
import { ReactComponent as FileIcon } from "../../../../../../assets/file-11.svg";
import { ReactComponent as Replace } from "../../../../../../assets/repeat-file.svg";
import { ReactComponent as Delete } from "../../../../../../assets/delete-file.svg";
import { ErrorMessage } from "../errorMessage";
import DataRoomApi from "../../../../../helpers/api";

export const ReplaceFile = ({
  onBackdropClick,
  token,
  assetId,
  refreshDocs,
  originalFile,
  folderName,
}) => {
  const [file, setFile] = useState(null);
  const [wait, setWait] = useState(false);
  const [errorObj, setError] = useState(null);
  const inputFile = useRef(null);

  const preventDefaultCustom = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const onAddFile = async (files) => {
    setFile(files[0]);
  };

  const onUpload = (e) => {
    e.stopPropagation();
    inputFile && inputFile.current && inputFile.current.click();
  };

  const onSubmit = async () => {
    setWait(true);
    const formData = new FormData();
    formData.append(`files`, file, file.name);
    const res = await DataRoomApi.uploadFilesInFolder(
      token,
      formData,
      folderName,
      assetId
    ).catch((e) => {
      setWait(false);
      setError(e);
    });
    if (res) {
      const del = await DataRoomApi.deleteFile(
        token,
        folderName,
        assetId,
        originalFile
      ).catch((e) => {
        setWait(false);
        setError(e);
      });
      if (del) refreshDocs();
    }
  };

  return (
    <BG onClick={onBackdropClick}>
      {file ? (
        <FilesModal
          height="341px"
          onDragEnter={preventDefaultCustom}
          onDragOver={preventDefaultCustom}
          onDragLeave={preventDefaultCustom}
          onDrop={preventDefaultCustom}
          onClick={(e) => e.stopPropagation()}
        >
          <MGeader>
            <HeadingM>Replace {originalFile}</HeadingM>
            <CloseIcon onClick={onBackdropClick} />
          </MGeader>
          <MWrapper>
            <Form
              onSubmit={(e) => e.preventDefault()}
              enctype="multipart/form-data"
              action=""
              method="post"
              onDragEnter={preventDefaultCustom}
              onDragOver={preventDefaultCustom}
              onDragLeave={preventDefaultCustom}
              onDrop={(e) => {
                preventDefaultCustom(e);
                onAddFile(e.dataTransfer.files);
              }}
              onClick={(e) => onUpload(e)}
            >
              <FormHeading>Drop file here or</FormHeading>
              <Button width={165}>
                <UploadIcon /> upload FILE
              </Button>
              <input
                ref={inputFile}
                multiple={!(typeof replaced === "number")}
                type="file"
                style={{ display: "none" }}
                id="file-dataAndRoom"
                onChange={(e) => {
                  if (e.target.files.length) {
                    onAddFile(e.target.files);
                  }
                }}
              />
            </Form>
            <FilesWrapper height="70px">
              <File>
                <FileIcon />
                <FileName_>{file.name}</FileName_>
                <Size>{(file.size / 1048576).toFixed(2)} mb</Size>
                <IconsBlock>
                  <Replace onClick={(e) => onUpload(e)} />
                  <Delete onClick={() => setFile(null)} />
                </IconsBlock>
              </File>
            </FilesWrapper>
            <Row>
              <Button transparent width={94} onClick={onBackdropClick}>
                cancel
              </Button>
              <Button width={180} onClick={onSubmit} disabled={wait}>
                Replace
              </Button>
            </Row>
          </MWrapper>
        </FilesModal>
      ) : (
        <FormBlockEmpty
          onDragEnter={preventDefaultCustom}
          onDragOver={preventDefaultCustom}
          onDragLeave={preventDefaultCustom}
          onDrop={preventDefaultCustom}
        >
          <FormEmpty
            onSubmit={(e) => e.preventDefault()}
            enctype="multipart/form-data"
            action=""
            method="post"
            onDragEnter={preventDefaultCustom}
            onDragOver={preventDefaultCustom}
            onDragLeave={preventDefaultCustom}
            onDrop={(e) => {
              preventDefaultCustom(e);
              onAddFile(e.dataTransfer.files);
            }}
            onClick={(e) => onUpload(e)}
          >
            <Upload />
            <FormText>Drop new file here to replace</FormText>
            <input
              ref={inputFile}
              type="file"
              style={{ display: "none" }}
              id="file-dataAndRoom"
              onChange={(e) => {
                if (e.target.files.length) onAddFile(e.target.files);
              }}
            />
          </FormEmpty>
        </FormBlockEmpty>
      )}
      {errorObj ? (
        <ErrorMessage er={errorObj} onClose={() => setError(null)} />
      ) : null}
    </BG>
  );
};
