export default {
  primary: "#3E3D36",
  secondary: "#DBD9D4",
  tertiary: "#3E3D36",
  primaryLight: "#F8F6F3",
  primaryMiddle: "#DBD9D4",
  primaryDark: "#3E3D36",
  secondaryLight: "#DBD9D4",
  tertiaryLight: "#F8F6F3",
  tertiaryMiddle: "#DBD9D4",
  headerInvestor: "#1A1915",
  footerInvestor: "#1A1915",
  navLink: "#ffffff4d",
  navLinkActive: "#fff",
  textPrimary: "#181818",
  textSecondary: "#18181899",
  textTertiary: "#545454",
  existedLabel: "#DBD9D4",
  headerAdmin: "#1A1915",
  sideBarAdmin: "#23221E",
  navLinkAdmin: "#b6b6b699",
  mainBgAdmin: "#FAFAFA",
  mainBgInvestor: "#FAFAFA",
  green: "#55B17C",
  greenInvestor: "#019D52",
  red: "#FF4343",
  grey: "#BDBDBD",
  emptyCardBg: "#F8F6F3",
  quoteBgInvestor: "#F8F6F3",
  documentBgInvestor: "#F8F6F3",
  infoButtonBg: "#F8F6F3",
  navLinkBorder: "#DBD9D4",
  activeChatBG: "#F8F6F3",
  chartBgFrom: "rgba(230, 233, 234, 1)",
  chartBgTo: "rgba(230, 233, 234, 0.2)",
  adminTableRowGrey: "#F8F6F3",
  sideBarSepLine: "#323029",
  greyFilter: 1,
  blurBg: "#1a19151f",
  inputsAutoFillBg: "#E9E8E5",
  disabledField: "#F8F6F3",
  previewHeader: "#2d2c25",
  heatMap: "#A52525",
  uploaderBg: "#F6F8FB",
};
