import React, { Fragment, useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import { Redirect } from "react-router-dom";

import { ReactComponent as ArrowLeft } from "../../../../../assets/arrowleft.svg";
import { ReactComponent as UploadIcon } from "../../../../../assets/upload.svg";
import { ReactComponent as FileIcon } from "../../../../../assets/file-11.svg";
import { ReactComponent as Replace } from "../../../../../assets/repeat-file.svg";
import { ReactComponent as Delete } from "../../../../../assets/delete-file.svg";
import {
  Header,
  RouteLink,
  Heading,
  Type,
  Row,
  Card,
  Text,
  Image,
  ActionButton,
  LoadWrapper,
  Label,
  Var,
  DropZone,
  DropLabel,
  UploadBtn,
  FileBlock,
  FileName_,
  Size,
  FileButton,
  ButtonsBlock
} from "./styled";
import Example from "../../../../asset/legal-doc-example.png";
import Api from "../../../../helpers/api";
import { ErrorMessage } from "../DealDocs/errorMessage";
import { Approve } from "./approve";

const UploadLegalFile = ({
  id,
  type,
  isAfterSubscription,
  token,
  legalDocuments
}) => {
  const {
    name,
    size,
    automated,
    checked,
    uploaded,
    lastUpdate,
    afterSubscription
  } = legalDocuments[id][type];
  const [step, setStep] = useState(!uploaded ? 0 : 2);
  const [errorObj, setError] = useState(null);
  const [variables, setVariables] = useState(null);
  const [load, setLoad] = useState(true);
  const [file, setFile] = useState(uploaded ? { name, size } : null);
  const [wait, setWait] = useState(false);
  const [pdf, setPdf] = useState(null);
  const inputRef = useRef(null);
  const backRef = useRef(null);

  const hash = window.location.hash.replace("#", "");

  const preventDefaultCustom = e => {
    e.preventDefault();
    e.stopPropagation();
  };

  useEffect(() => {
    const getVariables = async () => {
      const variables = await Api.getDocVariables(
        token,
        isAfterSubscription
      ).catch(e => {
        setLoad(false);
        setError(e);
      });

      if (variables) {
        setLoad(false);
        setVariables(variables);
      }
    };

    getVariables();
  }, []);

  const onCloseError = () => setError(null);

  const onOpenFileDialog = e => {
    preventDefaultCustom(e);
    if (inputRef && inputRef.current) {
      inputRef.current.click();
    }
  };

  const onAddFiles = async e => {
    preventDefaultCustom(e);
    setWait(true);
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append(`file`, file, file.name);
    const res = await Api.uploadLegalDocument(token, id, formData, type).catch(
      e => {
        setError(e);
        setWait(false);
      }
    );

    if (res) {
      setFile(file);
      setStep(2);
      setWait(false);
    }
  };

  const onDropFiles = async e => {
    preventDefaultCustom(e);
    setWait(true);
    const file = e.dataTransfer.files[0];
    const formData = new FormData();
    formData.append(`file`, file, file.name);
    const res = await Api.uploadLegalDocument(token, id, formData, type).catch(
      e => {
        setError(e);
        setWait(false);
      }
    );

    if (res) {
      setFile(file);
      setStep(2);
      setWait(false);
    }
  };

  const onRemoveFile = async () => {
    setWait(true);

    const del = await Api.removeLegalDocument(token, id, type).catch(e => {
      setWait(false);
      setError(e);
    });

    if (del) {
      setWait(false);
      setStep(1);
      setFile(null);
    }
  };

  const onReplaceDocument = async e => {
    preventDefaultCustom(e);
    setWait(true);
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append(`file`, file, file.name);

    const replace = await Api.replaceLegalDocument(
      token,
      id,
      formData,
      type,
      afterSubscription
    ).catch(e => {
      setError(e);
      setWait(false);
    });

    if (replace) {
      setWait(false);
      setFile(file);
    }
  };

  const checkResult = async () => {
    setWait(true);

    const res = await Api.checkLegalDocResult(token, id, type).catch(e => {
      setError(e);
      setWait(false);
    });

    if (res) {
      setWait(false);
      setPdf(res);
      setStep(3);
    }
  };

  const onApproveDocument = async () => {
    setWait(true);
    const res = await Api.approveLegalDoc(token, id, type).catch(e => {
      setWait(false);
      setError(e);
    });

    if (res && backRef && backRef.current) {
      setWait(false);
      backRef.current.click();
    }
  };

  const renderVars = () => {
    return Object.keys(variables).map(v => (
      <Var key={v} name={v} description={variables[v]} />
    ));
  };

  const steps = [
    { name: "Done, Upload File", action: () => setStep(1) },
    { name: "Check result", action: () => console.log() },
    { name: "Check result", action: checkResult }
  ];

  if (load) {
    return (
      <LoadWrapper>
        <CircularProgress />
      </LoadWrapper>
    );
  } else if (variables) {
    return (
      <Fragment>
        {wait ? (
          <CircularProgress
            style={{ position: "fixed", top: "50%", left: "50%" }}
          />
        ) : null}
        <Header>
          <RouteLink
            ref={backRef}
            width={150}
            to={
              hash
                ? `/admin/deals/create/data-room/${id}/${hash}`
                : `/admin/data-room/deal/${id}/file-manager`
            }
            transparent="1"
          >
            <ArrowLeft />
            Cancel & BACK
          </RouteLink>
          <Heading m="0 0 0 25px">Upload File & Set Automation</Heading>
          <Type>{type}</Type>
        </Header>
        {step < 3 ? (
          <Row>
            <Card width="calc(100% - 430px)">
              {!step ? (
                <Fragment>
                  <Heading>Setting up Document Automation</Heading>
                  <Text>
                    Please,{" "}
                    <b>
                      change the information in your document to add the
                      parameters you see on a right.
                    </b>
                    <br />
                    This step helps the system to fill in the deal information
                    automatically when the document is presented to your
                    investors before signing it.
                    <br />
                    Click
                    <b> “Done, Upload file”</b> and upload your new file to
                    finalise the process.
                  </Text>
                  <Image src={Example} alt="example" />
                </Fragment>
              ) : step === 1 ? (
                <Fragment>
                  <Heading>Upload Your Edited File</Heading>
                  <Text>
                    Please <b>upload your new file</b>. After this our system
                    will put information to fields in your document and we need
                    from you to check the result.
                  </Text>
                  <DropZone
                    onDragEnter={preventDefaultCustom}
                    onDragOver={preventDefaultCustom}
                    onDragLeave={preventDefaultCustom}
                    onDrop={onDropFiles}
                  >
                    <DropLabel>Drop file here or</DropLabel>
                    <UploadBtn disabled={wait} onClick={onOpenFileDialog}>
                      <UploadIcon /> upload FILE
                    </UploadBtn>
                  </DropZone>
                </Fragment>
              ) : step === 2 && file ? (
                <Fragment>
                  <Heading>Upload Your Edited File</Heading>
                  <Text>
                    Please <b>upload your new file</b>. After this our system
                    will put information to fields in your document and we need
                    from you to check the result.
                  </Text>
                  <FileBlock>
                    <FileIcon />
                    <FileName_>{file.name}</FileName_>
                    <Size>{(file.size / 1048576).toFixed(2)} mb</Size>
                    <ButtonsBlock>
                      <FileButton disabled={wait} onClick={onOpenFileDialog}>
                        <Replace /> Replace
                      </FileButton>
                      <FileButton disabled={wait} red onClick={onRemoveFile}>
                        <Delete /> REMOVE
                      </FileButton>
                    </ButtonsBlock>
                  </FileBlock>
                </Fragment>
              ) : null}

              <ActionButton
                disabled={(step === 1 && !file) || wait}
                onClick={steps[step].action}
              >
                {steps[step].name}
              </ActionButton>

              <input
                onChange={file ? onReplaceDocument : onAddFiles}
                ref={inputRef}
                type="file"
                accept=".docx"
                style={{ display: "none" }}
              />
            </Card>
            <Card>
              <Heading>Help</Heading>
              <Label>parameters</Label>
              {renderVars()}
            </Card>
          </Row>
        ) : (
          <Approve
            token={token}
            docName={name}
            size={size}
            lastUpdate={lastUpdate}
            blob={pdf}
            onRejectDocument={onRemoveFile}
            onApproveDocument={onApproveDocument}
            wait={wait}
          />
        )}

        {errorObj ? (
          <ErrorMessage er={errorObj} onClose={onCloseError} />
        ) : null}
      </Fragment>
    );
  } else if (errorObj) {
    return <ErrorMessage er={errorObj} onClose={onCloseError} />;
  } else return null;
};

export default connect(
  (
    { userReducer: { token }, dataRoomReducer: { legalDocuments } },
    {
      match: {
        params: { id, type, isAfterSubscription }
      }
    }
  ) => ({
    token,
    legalDocuments,
    id,
    type,
    isAfterSubscription
  }),
  null
)(props => {
  if (
    props.legalDocuments[props.id] &&
    props.legalDocuments[props.id][props.type]
  ) {
    return <UploadLegalFile {...props} />;
  } else {
    return <Redirect to={`/admin/data-room/deal/${props.id}/file-manager`} />;
  }
});
