import React, { useState } from "react";

import {
  ModalBackground,
  ModalWrapper,
  ModalHeader,
  ModalHeading,
  InputsWrapper,
  ModalText,
  InputCol,
  Row,
  ConvertBlock,
  WaitingModalWrapper,
  CurrencyCol,
} from "./styled";
import { Button } from "../Offerings/styled";
import { ReactComponent as CloseIcon } from "../../../../assets/x-circle.svg";
import { ReactComponent as EqualIcon } from "../../../../assets/equal.svg";
import { ReactComponent as InfoIcon } from "../../../../assets/info.svg";
import { TextFieldComponent, SelectComponent, TextFieldComponentPlaceholder } from "../../../admin-layout/forms";

import Api from "../../../../helpers/api";
import { CircularProgress } from "@material-ui/core";
import { numberWithCommas } from "helpers";

export const SignLoi = ({
  isOpen,
  onBackDropClick,
  token,
  assetId,
  minInvestment,
  maxInvestment,
  callBack,
  baseCurrency,
  newWorkflow,
  getIntention,
  shareName,
  isSubscription = false,
}) => {
  const [intentCount, setIntentCount] = useState("");
  const [convertTokens, setConvertTokens] = useState("");
  const [waitingConvert, setWaitingConvert] = useState(false);
  const [boundId, setBoundId] = useState(null);
  const [countError, setCountError] = useState(false);

  const integerNumber = /((^[1-9]{1,1})[0-9]{0,}$)|(^.{0,0}$)/;

  const onIntentCountInput = (event) => {
    const {
      target: { value },
    } = event;
    if (countError) setCountError(false);
    if (!value.match(integerNumber)) return;
    setIntentCount(value);
    clearTimeout(boundId);
    const id = setTimeout(() => {
      setWaitingConvert(true);
      Api.convertTokens(token, assetId, value, baseCurrency)
        .then((text) => {
          if (value >= minInvestment && value <= maxInvestment) {
            setConvertTokens(text);
          } else {
            setCountError(true);
          }
          setWaitingConvert(false);
        })
        .catch(() => {
          setWaitingConvert(false);
          setConvertTokens("");
        });
    }, 1000);
    setBoundId(id);
  };

  const signIntentionLetter = () => {
    setWaitingConvert(true);
    if (isSubscription) {
      Api.subscribeIntention(token, assetId, intentCount).then((_) => onBackDropClick());
    } else {
      Api.signIntentionLetter(token, assetId, intentCount)
        .then((json) => {
          callBack(json.redirectUrl);
        })
        .catch(() => setWaitingConvert(false));
    }
  };

  const onSubmitNewIntention = (count) => () => getIntention(count);

  return (
    isOpen && (
      <ModalBackground onClick={onBackDropClick}>
        <ModalWrapper width={540} onClick={(e) => e.stopPropagation()}>
          {waitingConvert && (
            <WaitingModalWrapper>
              <CircularProgress />
            </WaitingModalWrapper>
          )}
          <ModalHeader boxShadow="0px 10px 40px rgba(89, 120, 150, 0.08)">
            <ModalHeading>{isSubscription ? "Subscription" : "Sign LOI"}</ModalHeading>
            <CloseIcon onClick={onBackDropClick} />
          </ModalHeader>
          <InputsWrapper>
            <ModalText>1. How many shares do you intend to buy?</ModalText>
            <Row margin="0 0 16px" error={countError}>
              <InputCol>
                <TextFieldComponentPlaceholder
                  type="number"
                  height={42}
                  fontSize={14}
                  defaultValue={intentCount}
                  onChange={onIntentCountInput}
                  pseudo
                  pseudoText={shareName}
                  pseudoSize={14}
                  errorWithBG={countError}
                  isInvestor
                />
              </InputCol>
              <EqualIcon style={{ marginTop: 10 }} />
              <InputCol>
                <TextFieldComponent
                  type="text"
                  height={42}
                  fontSize={14}
                  value={convertTokens.split(" ")[0]}
                  disabled
                  errorWithBG={countError}
                />
              </InputCol>
              <CurrencyCol>
                <SelectComponent
                  hiddenLabel
                  value={baseCurrency}
                  values={[baseCurrency]}
                  isInvestor
                  selectHeight={42}
                  fSize={14}
                  onChange={() => console.log()}
                  top={47}
                />
              </CurrencyCol>
            </Row>
            <ConvertBlock error={countError}>
              <InfoIcon style={{ marginRight: "8px" }} />
              <p>
                Investment should be
                <b>
                  {" "}
                  between {numberWithCommas(minInvestment)} {shareName} and {numberWithCommas(maxInvestment)}{" "}
                  {shareName}
                </b>
              </p>
            </ConvertBlock>
            <Row width="286px">
              <Button width={94} height={36} size={12} tTransform="uppercase" hide onClick={onBackDropClick}>
                Cancel
              </Button>
              <Button
                width={180}
                height={36}
                size={12}
                tTransform="uppercase"
                onClick={newWorkflow && !isSubscription ? onSubmitNewIntention(intentCount) : signIntentionLetter}
                disabled={!intentCount || intentCount < minInvestment || intentCount > maxInvestment}
              >
                Confirm signing
              </Button>
            </Row>
          </InputsWrapper>
        </ModalWrapper>
      </ModalBackground>
    )
  );
};

export const GetIntent = ({
  onBackDropClick,
  token,
  assetId,
  minInvestment,
  maxInvestment,
  baseCurrency,
  shareName,
  onSubmit,
  load = false,
}) => {
  const [intentCount, setIntentCount] = useState("");
  const [convertTokens, setConvertTokens] = useState("");
  const [waitingConvert, setWaitingConvert] = useState(false);
  const [boundId, setBoundId] = useState(null);
  const [countError, setCountError] = useState(false);

  const integerNumber = /((^[1-9]{1,1})[0-9]{0,}$)|(^.{0,0}$)/;

  const onIntentCountInput = (event) => {
    const {
      target: { value },
    } = event;
    if (countError) setCountError(false);
    if (!value.match(integerNumber)) return;
    setIntentCount(value);
    clearTimeout(boundId);
    const id = setTimeout(() => {
      if (value) {
        setWaitingConvert(true);
        Api.convertTokens(token, assetId, value, baseCurrency)
          .then((text) => {
            if (value >= minInvestment && value <= maxInvestment) {
              setConvertTokens(text);
            } else {
              setCountError(true);
            }
            setWaitingConvert(false);
          })
          .catch(() => {
            setWaitingConvert(false);
            setConvertTokens("");
          });
      }
    }, 1000);
    setBoundId(id);
  };

  const _onSubmit = () => {
    onSubmit(intentCount);
  };

  return (
    <ModalBackground>
      <ModalWrapper width={540} onClick={(e) => e.stopPropagation()}>
        {waitingConvert && (
          <WaitingModalWrapper>
            <CircularProgress />
          </WaitingModalWrapper>
        )}
        <ModalHeader boxShadow="0px 10px 40px rgba(89, 120, 150, 0.08)">
          <ModalHeading>Investment Amount</ModalHeading>
          <CloseIcon onClick={onBackDropClick} />
        </ModalHeader>
        <InputsWrapper>
          <ModalText>1. How many shares do you intend to buy?</ModalText>
          <Row margin="0 0 16px" error={countError}>
            <InputCol>
              <TextFieldComponentPlaceholder
                type="number"
                height={42}
                fontSize={14}
                defaultValue={intentCount}
                onChange={onIntentCountInput}
                pseudo
                pseudoText={shareName}
                pseudoSize={14}
                errorWithBG={countError}
                isInvestor
              />
            </InputCol>
            <EqualIcon style={{ marginTop: 10 }} />
            <InputCol>
              <TextFieldComponent
                type="text"
                height={42}
                fontSize={14}
                value={convertTokens.split(" ")[0]}
                disabled
                errorWithBG={countError}
              />
            </InputCol>
            <CurrencyCol>
              <SelectComponent
                hiddenLabel
                value={baseCurrency}
                values={[baseCurrency]}
                isInvestor
                selectHeight={42}
                fSize={14}
                onChange={console.log}
                top={47}
              />
            </CurrencyCol>
          </Row>
          <ConvertBlock error={countError}>
            <InfoIcon style={{ marginRight: "8px" }} />
            <p>
              Investment should be
              <b>
                {" "}
                between {numberWithCommas(minInvestment)} {shareName} and {numberWithCommas(maxInvestment)} {shareName}
              </b>
            </p>
          </ConvertBlock>
          <Row width="286px">
            <Button width={94} height={36} size={12} tTransform="uppercase" hide onClick={onBackDropClick}>
              Cancel
            </Button>
            <Button
              width={180}
              height={36}
              size={12}
              tTransform="uppercase"
              onClick={_onSubmit}
              disabled={!intentCount || intentCount < minInvestment || intentCount > maxInvestment || load}
            >
              Confirm
            </Button>
          </Row>
        </InputsWrapper>
      </ModalWrapper>
    </ModalBackground>
  );
};
