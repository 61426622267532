import React, { useRef, useEffect, useState, Fragment } from "react";
import {
  ContainerFlex,
  DocSelect,
  DocOptions,
  DocOptionContainer,
  DocOptionsBlock,
  DocInput,
  DocOptionsCheckbox,
} from "../containers/container";
import { InputLabel as DocInputLabel } from "../text";
import SearchIcon from "../../../assets/search-icon.svg";
import { MultiplePrewiev } from "./styled";
import theme from "models/themes";

export default ({
  onChange,
  width = "100%",
  label = "",
  placeholder,
  value,
  values = [],
  controlledValue = null,
  labels = [],
  hiddenLabel,
  multiple = false,
  top = 65,
  defaultValue,
  view = "default",
  disabled,
  labelFsize,
  fSize,
  selectHeight,
  isInvestor = false,
  padding,
  borderColor,
  lighterItem,
  pseudoContent,
  name,
  blackList = [],
  noLabel = false,
  error = false,
  markAll = false,
  edit,
}) => {
  const [_values, _setValues] = useState([...values]);
  const [filterWord, setFilterWord] = useState("");

  //
  const [_markAll, _setMarkAll] = useState(false);

  const [selectedValues, setSelectedValues] = useState(Array.isArray(defaultValue) ? [...defaultValue] : []);

  useEffect(() => {
    if (Array.isArray(controlledValue)) {
      const _controlledValue = [...controlledValue].sort();
      const _selectedValues = [...selectedValues].sort();
      if (_controlledValue.join() !== _selectedValues.join()) {
        setSelectedValues(_controlledValue);
      }
    }
  }, [controlledValue]);

  useEffect(() => {
    _setValues(values.filter((e) => e.toLowerCase().includes(filterWord.toLowerCase())));
  }, [values]);

  const onChangeFilter = (e) => setFilterWord(e.target.value || "");

  useEffect(() => {
    _setValues(values.filter((e) => e.toLowerCase().includes(filterWord.toLowerCase())));
  }, [filterWord]);

  const uniqueId = useRef(Math.random().toString(36).replace("0.", ""));
  const currentSelect = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const onToggle = (e) => {
    e.stopPropagation();
    setOpen(!isOpen);
    const event = new CustomEvent("open_new", { uniqueId });
    window.dispatchEvent(event);
  };

  useEffect(() => {
    function detectNewOpen(e) {
      if (e.uniqueId !== uniqueId.current && isOpen) {
        setOpen(false);
      }
    }

    function closeAll() {
      setOpen(false);
    }

    function _onChange(...e) {
      onChange && onChange(...e);
    }

    currentSelect.current && currentSelect.current.addEventListener("change", _onChange);

    window.addEventListener("open_new", detectNewOpen);
    window.addEventListener("click", closeAll);
    return () => {
      currentSelect.current && currentSelect.current.removeEventListener("change", _onChange);
      window.removeEventListener("open_new", detectNewOpen);
      window.removeEventListener("click", closeAll);
    };
  });

  const changeEmit = (val) => () => {
    setOpen(false);
    if (currentSelect.current) {
      currentSelect.current.value = val;
      currentSelect.current.dispatchEvent(new Event("change"), {
        bubbles: true,
      });
    }
  };

  const changeEmitMultiple = (val, selected) => (e) => {
    e.stopPropagation();
    if (selected) {
      setSelectedValues(selectedValues.filter((e) => e !== val));
    } else {
      setSelectedValues([...selectedValues, val]);
    }
  };

  const onMarkAll = (e) => {
    e.stopPropagation();
    if (_markAll) {
      _setMarkAll(false);
      setSelectedValues([]);
    } else {
      _setMarkAll(true);
      setSelectedValues([..._values]);
    }
  };

  useEffect(() => {
    multiple && onChange([...selectedValues]);

    const _selVal = [...selectedValues];
    const _allVal = [..._values];

    if (_selVal.sort().join() === _allVal.sort().join()) {
      _setMarkAll(true);
    } else _setMarkAll(false);
  }, [selectedValues]);

  const renderOptions = () => {
    return _values.map((e, i) =>
      view === "default" && !multiple ? (
        <DocOptionsBlock
          key={e}
          value={e}
          selected={e === defaultValue}
          onClick={!blackList.includes(e) ? changeEmit(e) : undefined}
          pseudo={i === lighterItem}
          pseudoContent={pseudoContent}
          disabled={blackList.includes(e)}
        >
          {(labels && labels[i]) || e}
        </DocOptionsBlock>
      ) : multiple ? (
        <DocOptionsCheckbox
          selected={selectedValues.includes(e)}
          onClick={changeEmitMultiple(e, selectedValues.includes(e))}
        >
          {(labels && labels[i]) || e}
        </DocOptionsCheckbox>
      ) : (
        <DocOptionsBlock
          key={e}
          value={e}
          selected={e === defaultValue}
          onClick={changeEmit(e)}
          radius="4px"
          bg={theme.quoteBgInvestor}
          padding="14px 20px"
        >
          {(labels && labels[i]) || e}
        </DocOptionsBlock>
      )
    );
  };

  return (
    <ContainerFlex w={width} overflow="visible" zIndex={isOpen ? 1001 : 0}>
      {!noLabel ? (
        <Fragment>
          <DocInputLabel
            edit={edit}
            isInvestor={isInvestor}
            labelFsize={labelFsize}
            asSelect={multiple}
            arrow={`/assets/chevron-down${theme.greyFilter ? "-dark" : ""}.svg`}
            hidden={hiddenLabel}
            onClick={onToggle}
          >
            {multiple && selectedValues.length ? <MultiplePrewiev>{selectedValues.join(", ")}</MultiplePrewiev> : label}
          </DocInputLabel>
        </Fragment>
      ) : null}

      <DocSelect
        edit={edit}
        name={name}
        padding={padding}
        borderColor={borderColor}
        height={selectHeight}
        fSize={fSize}
        ref={currentSelect}
        multiple={multiple}
        hidden={multiple}
        arrow={`/assets/chevron-down${theme.greyFilter ? "-dark" : ""}.svg`}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        defaultValue={defaultValue}
        onClick={onToggle}
        disabled={disabled}
        error={error}
      >
        {defaultValue ? null : (
          <DocOptions selected disabled hidden>
            none
          </DocOptions>
        )}
        {_values.map((e, i) => (
          <DocOptions
            key={e}
            value={e}
            selected={multiple && defaultValue && defaultValue.includes ? defaultValue.includes(e) : e === defaultValue}
            hidden
          >
            {(labels && labels[i]) || e}
          </DocOptions>
        ))}
      </DocSelect>
      <DocOptionContainer
        borderColor={borderColor}
        show={isOpen}
        top={multiple ? 40 : top}
        height={view === "filter" ? (_values.length ? "250px" : "fit-content") : ""}
        offScroll={view === "filter"}
      >
        {/* default TYPE */}
        {defaultValue || view !== "default" ? null : (
          <DocOptionsBlock selected disabled onClick={changeEmit("none")}>
            none
          </DocOptionsBlock>
        )}
        {view === "filter" ? (
          <Fragment>
            <DocInput
              onClick={(e) => e.stopPropagation()}
              icon={SearchIcon}
              width="100%"
              bgPos="left"
              leftPos="15px"
              padding="0 20px 0 35px"
              margin="0 0 10px 0"
              onInput={onChangeFilter}
              value={filterWord}
            />
          </Fragment>
        ) : null}
        {view === "filter" ? (
          <DocOptionContainer show={isOpen} maxHeight="180px" offBorder full={false}>
            {renderOptions()}
          </DocOptionContainer>
        ) : (
          <Fragment>
            {markAll ? (
              <DocOptionsCheckbox selected={_markAll} onClick={onMarkAll}>
                Mark All
              </DocOptionsCheckbox>
            ) : null}
            {renderOptions()}
          </Fragment>
        )}
      </DocOptionContainer>
    </ContainerFlex>
  );
};
