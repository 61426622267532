import React, { useState } from "react";

import { ExpandCard } from "../Documents/styled";
import { ContentWrapper, TableHeader, DealItem } from "./styled";
import { SellShares } from "./SellShares";

export const SharesAccount = ({
  token,
  data,
  history,
  onSuccess,
  onFailure,
  smallScreen
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [shereInfo, setShareInfo] = useState({});

  const onOpenSellShares = info => {
    setShareInfo(info);
    setModalOpen(true);
  };

  const clearData = () => {
    setShareInfo({});
    setModalOpen(false);
  };

  const renderDeals = () => {
    return data.map((e, i) => {
      return (
        <DealItem
          key={e.assetId}
          white={i % 2 !== 0}
          asset={e}
          token={token}
          history={history}
          onOpenSellShares={info => onOpenSellShares(info)}
          smallScreen={smallScreen}
        />
      );
    });
  };

  if (data.length)
    return (
      <ExpandCard heading="Shares Account">
        <ContentWrapper>
          <TableHeader />
          {renderDeals()}
        </ContentWrapper>

        <SellShares
          isOpen={modalOpen}
          info={shereInfo}
          onBackDropClick={clearData}
          token={token}
          onSuccess={mes => onSuccess(mes)}
          onFailure={e => onFailure(e)}
        />
      </ExpandCard>
    );
  else return null;
};
