import React, { useState, useEffect } from "react";
import { InputRow } from "../Step/Information/styled";
import { TextFieldComponent } from "../../../forms";

import { ExpandedPanel } from "./ExpandedPanel";
import { IbanError } from "../styled";

export const BankAccount = ({ onChangeBank, defaultValues, isEdit }) => {
  const [account, setAccount] = useState({
    ...defaultValues.assetFinancialProperty.account,
  });
  const [error, setError] = useState(false);
  const { accountName, bankName, bic, iban, accountNumber } = account;

  const ibanFormat = /[a-zA-Z]{2,2}[0-9a-zA-Z]{0,}[0-9]{14,14}/;

  const onChangeValues = (e) => {
    const { name, value } = e.target;
    setAccount({ ...account, [name]: value });
  };

  useEffect(() => {
    onChangeBank(account);
  }, [account]);

  useEffect(() => {
    if (iban.length >= 16) {
      setAccount({
        ...account,
        accountNumber: iban.substr(iban.length - 14),
      });
    } else if (iban.length < 16 && accountNumber) {
      setAccount({
        ...account,
        accountNumber: "",
      });
    }

    if (iban && !iban.match(ibanFormat)) {
      setError(true);
    } else if ((error && iban.match(ibanFormat)) || (error && !iban)) {
      setError(false);
    }
  }, [iban]);

  return (
    <ExpandedPanel header=" Bank Account">
      <InputRow>
        <TextFieldComponent
          label="Account name"
          placeholder="Account name"
          defaultValue={accountName}
          onChange={onChangeValues}
          name="accountName"
          disabled={isEdit}
        />
      </InputRow>
      <InputRow>
        <TextFieldComponent
          label="Bank name"
          placeholder="Bank of account"
          defaultValue={bankName}
          onChange={onChangeValues}
          name="bankName"
          disabled={isEdit}
        />
      </InputRow>
      <InputRow>
        <TextFieldComponent
          label="BIC"
          placeholder="XXXXX"
          defaultValue={bic}
          onChange={onChangeValues}
          name="bic"
          disabled={isEdit}
        />
      </InputRow>
      <InputRow last>
        <TextFieldComponent
          label="IBan"
          placeholder="XXXXX"
          defaultValue={iban}
          name="iban"
          onChange={onChangeValues}
          error={error}
          disabled={isEdit}
        />
      </InputRow>
      {error ? (
        <IbanError>
          Incorrect IBAN format. IBAN should contain minimum 16 characters and
          last 14 should be numbers.
        </IbanError>
      ) : null}
    </ExpandedPanel>
  );
};
