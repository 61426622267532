import React, { Fragment } from "react";

import { TRow, TCeil, DocName } from "./styled";
import { File } from "components/common/icons-primary";

export const FileComponent = ({
  file: { name: fileName, size, lastUpdate, subsection },
  section,
  onBackdropClick,
  setSelectedFile,
  folderName,
  _file,
}) => {
  const onSelectFile = (folderName, fileName) => () => {
    setSelectedFile({ folderName, fileName });
    onBackdropClick();
  };

  return (
    <TRow key={`${fileName}-${size}-${lastUpdate}`} onClick={onSelectFile(folderName, fileName)}>
      <TCeil pos="relative" full p={78} active={fileName === _file.fileName}>
        <DocName active={fileName === _file.fileName}>
          <File />
          <Fragment>
            <span>
              {section !== 0 ? `${section}.${subsection}.` : null} {fileName}
            </span>
          </Fragment>
        </DocName>
      </TCeil>
    </TRow>
  );
};
