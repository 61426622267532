import styled from "styled-components";
import React, { useEffect, useState } from "react";
import moment from "moment";

import { ReactComponent as ArrowUp } from "../../../../assets/arrow-up-right.svg";
import { ReactComponent as Clock } from "../../../../assets/clock-i.svg";
import { ReactComponent as Info } from "../../../../assets/info.svg";

import { TransactionInfoModal } from "./TransactionInfoModal";
import Api from "../../../../helpers/api";
import { CircularProgress } from "@material-ui/core";

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  padding: 0 30px;
  max-width: 1440px;
  box-sizing: border-box;
  height: 100%;
  min-height: calc(100vh - 290px);
  box-sizing: border-box;
`;

export const Block = styled.div`
  height: ${({ height }) => (height ? `${height}px` : "50px")};
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: ${({ justify }) => justify};
  margin-bottom: 25px;
  padding: ${({ padding }) => (padding ? padding : "5px 30px")};
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid #f0f0f0;
  box-sizing: border-box;
  border-radius: 4px;
  margin: ${({ margin }) => margin};
`;

export const SelectBlock = styled.div`
  width: 264px;
  margin-left: auto;
  margin-bottom: 13px;
`;

export const TH = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 135%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: rgba(24, 24, 24, 0.6);
  width: ${({ width }) => width};
  min-width: ${({ minWidth }) => minWidth};
`;

export const TableHead = () => (
  <Block padding="5px 25px" margin="0 0 15px 0" justify="space-between">
    <TH minWidth="213px" width="17%">
      Action
    </TH>
    <TH minWidth="108px" width="10%">
      Amount
    </TH>
    <TH minWidth="165px" width="10%">
      Asset
    </TH>
    <TH minWidth="125px" width="10%">
      Status
    </TH>
    <TH minWidth="132px" width="12%">
      Time
    </TH>
    <TH minWidth="32px" width="32px" />
  </Block>
);

const TransactionBlock = styled.div`
  height: 68px;
  width: 100%;
  margin-bottom: 15px;
  background: #ffffff;
  border-radius: 4px;
  box-sizing: border-box;
  border: 1px solid #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 25px;
  transition: all 0.3s linear;
  &:hover {
    border: 1px solid ${({ theme: { primary } }) => primary};
  }
  &:last-child {
    margin-bottom: 30px;
  }
`;

const Action = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  color: #333333;
  width: ${({ width }) => width};
  min-width: ${({ minWidth }) => minWidth};
`;

const Amount = styled.div`
  white-space: nowrap;
  font-weight: bold;
  font-size: 14px;
  line-height: 150%;
  width: ${({ width }) => width};
  min-width: ${({ minWidth }) => minWidth};
  color: ${({ color }) => color};
  display: flex;
  align-items: center;
  > svg {
    transform: ${({ type }) => (type === "OUTCOME" ? "rotate(90deg)" : "none")};
    width: 14px;
    height: 14px;
    path {
      stroke: ${({ color }) => color};
    }
  }
`;

const AssetName = styled.div`
  width: ${({ width }) => width};
  min-width: ${({ minWidth }) => minWidth};
  font-family: Muli;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  color: ${({ theme: { primary } }) => primary};
  text-decoration: underline;
  text-underline-position: under;
  cursor: pointer;
`;

const StatusButton = styled.div`
  width: 125px;
  height: 32px;
  border-radius: 2px;
  background: ${({ bg }) => bg};
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 150%;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  > svg {
    margin-right: 6px;
    width: 12px;
    height: 12px;
  }
`;

const txTypes = {
  INCOME: {
    color: "#019D52",
    icon: <ArrowUp />,
    statusName: "Completed",
    statusBg: "#019D52",
    statusIcon: null
  },
  OUTCOME: {
    color: "#FF4343",
    icon: <ArrowUp />,
    statusName: "Completed",
    statusBg: "#019D52",
    statusIcon: null
  },
  PENDING: {
    color: "#C6C6C6",
    icon: null,
    statusName: "Pending",
    statusBg: "#C6C6C6",
    statusIcon: <Clock />
  },
  PERSONAL: {
    color: "#181818",
    icon: null,
    statusName: "Completed",
    statusBg: "#019D52",
    statusIcon: null
  },
  CANCELED: {
    color: "#181818",
    icon: null,
    statusName: "Canceled",
    statusBg: "#FF4343",
    statusIcon: <Clock />
  },
  OTHER: {
    color: "#C6C6C6",
    icon: null,
    statusName: "Other",
    statusBg: "#C6C6C6",
    statusIcon: null
  },
  REJECTED: {
    color: "#181818",
    icon: null,
    statusName: "Rejected",
    statusBg: "#FF4343",
    statusIcon: null
  }
};

export const Date = styled.div`
  white-space: nowrap;
  font-family: Muli;
  font-style: normal;
  font-weight: ${({ light }) => (light ? 300 : 600)};
  font-size: ${({ fSize }) => (fSize ? `${fSize}px` : "14px")};
  line-height: 150%;
  color: #333333;
  margin: ${({ margin }) => margin};
  ${({ responsive = "" }) => responsive};
`;

const DateCol = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => width};
  min-width: ${({ minWidth }) => minWidth};
`;

const InfoButton = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme: { infoButtonBg } }) => infoButtonBg};
  transition: all 0.3s linear;
  cursor: pointer;
  > svg {
    path {
      stroke: ${({ theme: { primary } }) => primary};
    }
  }
  &:hover {
    background: ${({ theme: { primary } }) => primary};
    > svg {
      path {
        stroke: ${({ theme: { infoButtonBg } }) => infoButtonBg};
      }
    }
  }
`;

export const StatusName = styled.div`
  padding: 0 20px;
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 135%;
  letter-spacing: -0.02em;
  text-transform: uppercase;
  color: #ffffff;
`;

export const Transaction = ({
  tx: {
    txHash,
    tokenIdentifier,
    quantity,
    action,
    time,
    movementType,
    assetNameMovementFor,
    paymentMethod,
    paymentId,
    bankAccount,
    soldShares,
    sharePrice,
    vocTokenQuantity,
    cashTx
  },
  history,
  assets,
  token
}) => {
  const [open, setOpen] = useState(
    window.location.hash.includes("info") &&
      window.location.hash.includes(txHash)
  );
  const openModal = () => setOpen(!open);
  const [assetId, setAssetId] = useState("");
  const [assetLogo, setAssetLogo] = useState(null);

  useEffect(() => {
    const asset = assets.find(
      e => e.assetProperties.assetName === assetNameMovementFor
    );
    if (asset) {
      setAssetId(asset.linearId);
      if (asset.assetProperties.images.length) {
        const image = asset.assetProperties.images[0];
        Api.getAttachment(
          token,
          image.secureHash,
          image.fileNameWithExtension
        ).then(blob => setAssetLogo(blob));
      }
    }
  }, []);

  return (
    <TransactionBlock>
      <Action minWidth="213px" width="17%">
        {action}
      </Action>
      <Amount
        minWidth="108px"
        width="10%"
        color={txTypes[movementType].color}
        type={movementType}
      >
        {movementType === "INCOME"
          ? "+"
          : movementType === "OUTCOME"
          ? "-"
          : null}{" "}
        {quantity.toFixed(cashTx ? 2 : 0) + " " + tokenIdentifier}{" "}
        {txTypes[movementType].icon}
      </Amount>
      <AssetName
        minWidth="165px"
        width="10%"
        onClick={() => history.push(`/investor/offerings/companies/${assetId}`)}
      >
        {assetNameMovementFor}
      </AssetName>
      <TH minWidth="125px" width="10%">
        <StatusButton bg={txTypes[movementType].statusBg}>
          {txTypes[movementType].statusIcon}
          {txTypes[movementType].statusName}
        </StatusButton>
      </TH>
      <DateCol minWidth="132px" width="12%">
        <Date responsive={`@media (max-width: 1279px) {font-size: 13px;}`}>
          {moment(time).format("dddd, DD/MM/YYYY")}
        </Date>
        <Date
          responsive={`@media (max-width: 1279px) {font-size: 13px;}`}
          light
        >
          {moment(time).format("hh:mm:ss A")}
        </Date>
      </DateCol>
      <TH minWidth="32px" width="32px">
        <InfoButton onClick={openModal}>
          <Info />
          <TransactionInfoModal
            isOpen={open}
            onBackDropClick={openModal}
            action={action}
            time={time}
            completed={movementType !== "PENDING"}
            statusName={txTypes[movementType].statusName}
            assets={assets}
            assetLogo={assetLogo}
            assetNameMovementFor={assetNameMovementFor}
            paymentMethod={paymentMethod}
            paymentId={paymentId}
            bankAccount={bankAccount}
            quantity={quantity}
            soldShares={soldShares}
            sharePrice={sharePrice}
            movementType={movementType}
            tokenIdentifier={tokenIdentifier}
            vocTokenQuantity={vocTokenQuantity}
          />
        </InfoButton>
      </TH>
    </TransactionBlock>
  );
};

export const DataWrapper = styled.div`
  width: 100%;
  padding: 20px 30px 30px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${({ justify = "space-between" }) => justify};
  margin: ${({ margin }) => margin};
`;

const DataCol = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => width};
`;

const DataHeading = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 135%;
  margin-bottom: 10px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: rgba(24, 24, 24, 0.6);
`;

const InfoBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 42px;
  background: ${({
    green,
    asset,
    grey,
    red,
    theme: { documentBgInvestor, disabledField }
  }) =>
    green
      ? "#019D52"
      : asset
      ? documentBgInvestor
      : grey
      ? "#C6C6C6"
      : red
      ? "#FF4343"
      : disabledField};
  border: 1px solid
    ${({ green, asset, grey }) =>
      green || asset || grey ? "transparent" : "#EFEFEF"};
  box-sizing: border-box;
  border-radius: 4px;
  > svg {
    margin-right: 20px;
    path {
      stroke: ${({ theme: { primary } }) => primary};
    }
  }
`;

export const AssetLogo = styled.img`
  width: 72px;
  height: 42px;
  border-radius: 2px 0px 0px 2px;
`;

export const DealName = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: ${({ size }) => (size ? `${size}px` : "14px")};
  line-height: 150%;
  margin: ${({ margin }) => (margin ? margin : "0 auto 0 18px")};
  text-transform: none;
  color: #333333;
`;

export const DataBlock = ({
  heading,
  width,
  children,
  green,
  asset,
  grey,
  red
}) => (
  <DataCol width={width}>
    <DataHeading>{heading}</DataHeading>
    <InfoBlock green={green} asset={asset} grey={grey} red={red}>
      {children}
    </InfoBlock>
  </DataCol>
);

const TransactionData = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  height: 21px;
  line-height: 150%;
  color: ${({ green, red }) =>
    green ? "#019D52" : red ? "#FF4343" : "#333333"};
  display: flex;
  align-items: center;
  text-transform: none;
  > svg {
    transform: ${({ red }) => (red ? "rotate(90deg)" : "none")};
    width: 14px;
    height: 14px;
    path {
      stroke: ${({ red, green }) =>
        red ? "#FF4343" : green ? "#019D52" : "#000"};
    }
  }
`;

export const DataBlockTransaction = ({ heading, width, green, red, data }) => (
  <DataCol width={width}>
    <DataHeading>{heading}</DataHeading>
    <TransactionData red={red} green={green}>
      {data}
    </TransactionData>
  </DataCol>
);

export const EmptyCard = styled.div`
  background: #ffffff;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 25px;
  width: 100%;
  margin: 0 auto;
  font-weight: 600;
  font-size: 16px;
  line-height: 160%;
  color: #333333;
  text-align: center;
  max-width: 1380px;
`;
