import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import { CircularProgress } from "@material-ui/core";

import {
  Header,
  BackLink,
  Heading,
  Flex,
  Card,
  Separator,
  GreyBlock,
  UserBlock,
  UsersBlock,
  SmallLogo,
  EmptyLogo,
} from "../NewGroup/styled";
import { Btn } from "../../buttons";
import { ReactComponent as ArrowLeft } from "../../../../assets/arrowleft.svg";
import { ReactComponent as UserIcon } from "../../../../assets/user-plus.svg";
import { ReactComponent as NoAvatar } from "../../../../assets/no-avatar.svg";
import { ReactComponent as Close } from "../../../../assets/x-close-red.svg";
import {
  TextFieldComponent,
  SelectComponent,
  GroupsLogoUploader,
} from "../../forms";
import Api from "../../../../helpers/api";
import { ErrorMessage, SuccessMessage } from "../../errorMessage";

const EditGroup = ({
  token,
  history,
  match: {
    params: { id },
  },
}) => {
  const [name, setName] = useState("");
  const [usersList, setUsersList] = useState([]);
  const [allUsers, setAllUsers] = useState(null);
  const [logo, setLogo] = useState(null);
  const [load, setLoad] = useState(true);
  const [wait, setWait] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState("");
  const [groupFullInfo, setGroupFullInfo] = useState({});
  const [editUsersList, setEditUsersList] = useState([]);
  const [wasChanged, setWasChanged] = useState(false);

  useEffect(() => {
    const getInitial = async () => {
      const res = await Api.getUsersList(token);
      const group = await Api.getGroupById(token, id);

      if (res && group) {
        setGroupFullInfo(group);
        setName(group.name);
        const arr = group.users.map((u) => u.id);
        setEditUsersList(arr);
        setAllUsers(res.content);
        Api.getAttachment(token, group.logoHash, group.logoFileName)
          .then((b) => setLogo(b))
          .then(() => {
            setLoad(false);
          });
      }
    };
    getInitial();
  }, []);

  const onAddInvestors = (val = []) => setUsersList(val);

  useEffect(() => {
    if (!!groupFullInfo.users && usersList.length) {
      const oldUsersList = groupFullInfo.users
        .map((u) => u.id)
        .sort()
        .join();
      const newUsersList = usersList.sort().join();

      oldUsersList === newUsersList
        ? setWasChanged(false)
        : setWasChanged(true);
    } else if (usersList.length) {
      setWasChanged(false);
    }
  }, [usersList]);

  const renderUsers = () => {
    return usersList.reduce((users, _id) => {
      const user = allUsers.find((el) => el.id === _id);
      return [
        ...users,
        <User
          data={{ ...user }}
          key={`${user.username}-${user.fullName}`}
          token={token}
          onRemove={(id) => onRemoveInvestor(id)}
        />,
      ];
    }, []);
  };

  const onRemoveInvestor = (id) => {
    setEditUsersList([]);
    setUsersList(usersList.filter((_id) => _id !== id));
  };

  const onSubmit = async () => {
    setWait(true);
    let upload;

    if (!(typeof logo === "string")) {
      const _upload = await Api.uploadAttachment(token, logo).catch((e) => {
        setWait(false);
        setError(e);
      });
      upload = { ..._upload };
    } else {
      setWait(false);
      upload = {
        secureHash: groupFullInfo.logoHash,
        fileNameWithExtension: groupFullInfo.logoFileName,
      };
    }

    if (!upload) return;

    const { fileNameWithExtension, secureHash } = upload;

    const data = {
      name,
      logoHash: secureHash,
      logoFileName: fileNameWithExtension,
      users: usersList,
    };

    const res = await Api.updateGroup(token, data, id).catch((e) => {
      setWait(false);
      setError(e);
    });

    if (res) {
      setWait(false);
      setSuccess("Group successfully updated.");
    }
  };

  if (load) {
    return <CircularProgress style={{ margin: "auto" }} />;
  } else {
    return (
      <Fragment>
        <Header>
          <BackLink to="/admin/users/groups">
            <ArrowLeft /> back to groups
          </BackLink>
          <Heading>{`Edit group «${name}» `}</Heading>
        </Header>
        <Flex>
          <Card>
            <Heading>Main Information</Heading>
            <TextFieldComponent
              label="Group Name"
              placeholder="Group Name"
              defaultValue={name}
              onChange={(e) => {
                setName(e.target.value);
                setWasChanged(true);
              }}
            />
            <Separator />
            <GroupsLogoUploader
              label="Group Image"
              onChange={(file) => {
                setLogo(file);
                setWasChanged(true);
              }}
              defaultAvatar={logo}
              defaultFileName={groupFullInfo.logoFileName}
            />
          </Card>
          <Card big>
            <Heading>
              Users {usersList.length ? `(${usersList.length})` : null}
            </Heading>
            <SelectComponent
              multiple
              values={allUsers.map((e) => e.id)}
              labels={allUsers.map((e) => e.fullName)}
              controlledValue={usersList.length ? usersList : editUsersList}
              onChange={(val) => onAddInvestors(val)}
              label="assign users to group"
            />
            <Separator />
            {usersList.length ? (
              <UsersBlock>{renderUsers()}</UsersBlock>
            ) : (
              <GreyBlock>
                <UserIcon />
                <div>Assign users to this group using the field above</div>
              </GreyBlock>
            )}
          </Card>
        </Flex>
        <Header isFooter>
          <Btn width={180} hide>
            Cancel
          </Btn>
          <Btn
            margin="0 0 0 auto"
            width={180}
            onClick={onSubmit}
            disabled={wait || !wasChanged || !name}
          >
            Save Changes
          </Btn>
        </Header>

        {error ? (
          <ErrorMessage er={error} onClose={() => setError(null)} />
        ) : null}
        {success ? (
          <SuccessMessage
            message={success}
            onClose={() => history.push("/admin/users/groups")}
          />
        ) : null}
      </Fragment>
    );
  }
};

export default connect(
  ({ userReducer: { token } }) => ({ token }),
  null
)(EditGroup);

const User = ({
  token,
  data: { userLogoFileName, userLogoHash, fullName, username, id },
  onRemove,
}) => {
  const [avatar, setAvatar] = useState(null);

  useEffect(() => {
    if (!avatar) {
      Api.getAttachment(token, userLogoHash, userLogoFileName).then((b) =>
        setAvatar(b)
      );
    }
  });

  return (
    <UserBlock>
      {userLogoFileName && userLogoHash && !avatar ? (
        <EmptyLogo />
      ) : !userLogoHash || !userLogoFileName ? (
        <NoAvatar />
      ) : (
        <SmallLogo src={avatar} />
      )}
      <span>{fullName}</span>
      <Close onClick={() => onRemove(id)} />
    </UserBlock>
  );
};
