import Api from "../helpers/api";
import io from "socket.io-client";

export const getTrackerHTML = (
  token,
  fileNameWithExtension,
  hash,
  docusign = false,
  isDataRoom = false,
  isDirectLink = false
) => {
  try {
    const { username, fullName, role } = JSON.parse(
      localStorage.getItem("global_user_flag")
    );
    window.open(
      `${window.location.origin}/file-viewer.html#${JSON.stringify({
        username,
        fullName,
        role,
        fileNameWithExtension,
        hash,
        docusign,
        domain: Api.url,
        fileServerDomain: Api.url,
        originVoc: Api.origin,
        isDataRoom,
        origin: window.location.origin,
        isDirectLink,
      })}`,
      "_blank"
    );
  } catch (e) {
    console.log(e);
  }
};

export const visitAsset = (assetId, userInfo) => {
  // Api.url
  const socket = io(Api.url);
  socket.emit("onViewAsset", assetId, userInfo, window.location.origin);
  return () => {
    console.log(socket);
    socket.close();
  };
};
