import styled from "styled-components";

export const Main = styled.section`
  position: relative;
  height: ${({ isClosed, currentHeight }) =>
    isClosed ? "62px" : currentHeight};
  min-height: ${({ minHeight }) => (minHeight ? `${minHeight}px` : 0)};
  width: ${({ width }) => (width ? width : "100%")};
  overflow: ${({ noHidden }) => (noHidden ? "visible" : "hidden")};
  box-sizing: border-box;
  background: white;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  padding: ${({ padding }) => (padding ? padding : "20px 25px 25px 25px")};
  transition: 0.2s height linear;
  margin: ${({ margin = 0 }) => margin};
`;

export const CloseIconBlock = styled.div`
  position: absolute;
  right: 25px;
  top: 18px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  > svg {
    cursor: pointer;
    transform: ${({ isClosed }) => (isClosed ? "rotate(180deg)" : "none")};
    transition: 0.1s transform linear;
    path {
      stroke: ${({ theme: { primary } }) => primary};
    }
  }
`;

export const Header = styled.div`
  width: 100%;
  height: ${({ height = 60 }) => height}px;
  border-radius: 4px;
  background: white;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: ${({ padding }) => (padding ? padding : "0 25px")};

  ${({ isFooter = false }) => {
    if (isFooter) return `margin-top: 30px;`;
    else return `margin-bottom: 30px;`;
  }}
  ${({ between = false }) =>
    between ? "justify-content: space-between;" : ""}
  > a {
    text-decoration: none;
  }
  > span {
    font-size: 14px;
    line-height: 135%;
    color: #8a8a8a;
  }
`;

export const TextH3 = styled.h3`
  text-align: ${({ textAlign = "center" }) => textAlign};
  font-family: "Muli", sans-serif;
  font-size: ${({ size }) => (size ? `${size}px` : "14px")};
  font-weight: bold;
  ${({ marginSize = "" }) => (marginSize ? `margin: ${marginSize};` : "")}
`;

export const TextSub = styled.p`
  width: ${({ fullWidth }) => (fullWidth ? "100%" : "auto")};
  cursor: ${({ fullWidth }) => (fullWidth ? "pointer" : "auto")};
  text-align: ${({ textAlign = "center" }) => textAlign};
  font-family: "Muli", sans-serif;
  font-size: ${({ fSize }) => (fSize ? `${fSize}px` : "14px")};
  display: inline-block;
  font-weight: ${({ fontWeight = "normal" }) => fontWeight};
  color: ${({ color = "#CACACA" }) => color};
  ${({ marginSize = "" }) => (marginSize ? `margin: ${marginSize};` : "")}
  >span {
    color: ${({ theme: { primary } }) => primary};
  }
`;

export const HeaderCircle = styled.div`
  height: 24px;
  width: 24px;
  ${({ marginLeft }) =>
    marginLeft ? `margin-left: ${marginLeft};` : "margin-left:auto;"}
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme: { primary } }) => primary};
  cursor: pointer;
`;

export const BtnContainer = styled.div`
  display: flex;
  & :first-child {
    margin-right: 10px;
  }
`;

export const Separator = styled.div`
  height: 30px;
`;
