import Api from "../../helpers/api";
import store from "../../index";
import { setCash } from "../../modules/cashRedux/cashAction";

export default class DataRoomApi {
  static url = Api.url;

  static origin = Api.origin;

  static defaultAvatar = Api.defaultAvatar;

  static getAllFiles(token) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${DataRoomApi.url}/api/dataroom/assetsDataroom`, {
        headers: {
          OriginVoC: DataRoomApi.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });

      if (result.status === 200) res(await result.json());
      else {
        rej(await result.json());
      }
    });
  }

  static toggleAssetStatus(token, id, status) {
    return new Promise(async (res) => {
      const result = await fetch(`${DataRoomApi.url}/api/asset/${status ? "deactivate" : "activate"}/${id}`, {
        method: "put",
        headers: {
          OriginVoC: DataRoomApi.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });
      res(result.status === 200);
      await result.text();
    });
  }

  static getAttachment = Api.getAttachment;

  static getDealsFiles(token, id) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${DataRoomApi.url}/api/dataroom/getFoldersView/${id}`, {
        headers: {
          OriginVoC: DataRoomApi.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });

      if (result.status === 200) res(await result.json());
      else {
        rej(await result.json());
      }
    });
  }

  static createNewFolder(token, folderName, assetId) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${DataRoomApi.url}/api/dataroom/create/folder/${folderName}?assetId=${assetId}`, {
        method: "post",
        headers: {
          OriginVoC: DataRoomApi.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });

      if (result.status === 200) res(await result.json());
      else {
        rej(await result.json());
      }
    });
  }

  static deleteFolder(token, folderName, assetId) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${DataRoomApi.url}/api/dataroom/delete/folder/${folderName}?assetId=${assetId}`, {
        method: "delete",
        headers: {
          OriginVoC: DataRoomApi.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });

      if (result.status === 200) res(await result.text());
      else {
        rej(await result.json());
      }
    });
  }

  static moveFile(token, fileName, fromFolderName, newFolderName, newSubsection, assetId) {
    return new Promise(async (res, rej) => {
      const result = await fetch(
        `${DataRoomApi.url}/api/dataroom/move/file/${fileName}/${fromFolderName}/${newFolderName}/${newSubsection}/?assetId=${assetId}`,
        {
          method: "put",
          headers: {
            OriginVoC: DataRoomApi.origin,
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );

      if (result.status === 200) res(await result.text());
      else {
        rej(await result.json());
      }
    });
  }

  static uploadFiles(token, formData, assetId) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${DataRoomApi.url}/api/dataroom/uploadFileToUnsorted/?assetId=${assetId}`, {
        method: "post",
        headers: {
          OriginVoC: DataRoomApi.origin,
          Authorization: token,
        },
        body: formData,
      });
      if (result.status === 200) {
        await result.text();
        res("success");
      } else rej(await result.json());
    });
  }

  static uploadFilesToFolder(token, formData, assetId, folderName) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${DataRoomApi.url}/api/dataroom/uploadFiles/${folderName}?assetId=${assetId}`, {
        method: "post",
        headers: {
          OriginVoC: DataRoomApi.origin,
          Authorization: token,
        },
        body: formData,
      });
      if (result.status === 200) {
        await result.text();
        res("success");
      } else rej(await result.json());
    });
  }

  static uploadFilesInFolder(token, formData, folderName, assetId) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${DataRoomApi.url}/api/dataroom/upload/file/${folderName}?assetId=${assetId}`, {
        method: "post",
        headers: {
          OriginVoC: DataRoomApi.origin,
          Authorization: token,
        },
        body: formData,
      });
      if (result.status === 200) {
        await result.text();
        res("success");
      } else rej(await result.json());
    });
  }

  static deleteFile(token, folderName, assetId, file) {
    return new Promise(async (res, rej) => {
      const result = await fetch(
        `${DataRoomApi.url}/api/dataroom/delete/file/${folderName}?assetId=${assetId}&files=${file}`,
        {
          method: "delete",
          headers: {
            OriginVoC: DataRoomApi.origin,
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );

      if (result.status === 200) res(await result.text());
      else {
        rej(await result.text());
      }
    });
  }

  static renameFolder(token, folderName, newFolderName, assetId) {
    return new Promise(async (res, rej) => {
      const result = await fetch(
        `${DataRoomApi.url}/api/dataroom/rename/folder/${folderName}/${newFolderName}/?assetId=${assetId}`,
        {
          method: "put",
          headers: {
            OriginVoC: DataRoomApi.origin,
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );

      if (result.status === 200) res(await result.text());
      else {
        rej(await result.json());
      }
    });
  }

  static renameFile(token, fileName, newFileName, assetId, folderName) {
    return new Promise(async (res, rej) => {
      const result = await fetch(
        `${DataRoomApi.url}/api/dataroom/rename/file/${fileName}/${newFileName}?assetId=${assetId}&folderName=${folderName}`,
        {
          method: "put",
          headers: {
            OriginVoC: DataRoomApi.origin,
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );

      if (result.status === 200) res(await result.text());
      else {
        rej(await result.json());
      }
    });
  }

  static searchFileOrFolder(token, assetId, searchWord = "") {
    return new Promise(async (res, rej) => {
      console.log(searchWord);
      const result = await fetch(
        `${DataRoomApi.url}/api/dataroom/searchFileAndFolder/${searchWord}?assetId=${assetId}`,
        {
          method: "get",
          headers: {
            OriginVoC: DataRoomApi.origin,
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );

      if (result.status === 200) res(await result.json());
      else {
        rej(await result.json());
      }
    });
  }

  static downloadFile = async (token, folderName, fileName, assetId) => {
    const requestFile = await fetch(
      `${DataRoomApi.url}/api/dataroom/get/file/${folderName}/${fileName}?assetId=${assetId}`,
      {
        headers: {
          OriginVoC: DataRoomApi.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    return await requestFile.blob();
  };

  static downloadFileFromModal(token, folderName, fileName, assetId) {
    return new Promise(async (res, rej) => {
      const result = await fetch(
        `${DataRoomApi.url}/api/dataroom/get/file/${folderName}/${fileName}?assetId=${assetId}`,
        {
          headers: {
            OriginVoC: DataRoomApi.origin,
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );

      if (result.status === 200) res(await result.blob());
      else {
        rej(await result.json());
      }
    });
  }

  static getAssetFilesStatistic(token, assetId) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${DataRoomApi.url}/api/dataroom/admin/statistics/getFileViewStatistics/${assetId}`, {
        headers: {
          OriginVoC: DataRoomApi.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });

      if (result.status === 200) res(await result.json());
      else {
        rej(await result.json());
      }
    });
  }

  static getChartData(token, assetId, period, type) {
    return new Promise(async (res, rej) => {
      const result = await fetch(
        `${DataRoomApi.url}/api/dataroom/admin/statistics/getViewGraph/${assetId}/${period}?type=${type}`,
        {
          headers: {
            OriginVoC: DataRoomApi.origin,
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );

      if (result.status === 200) res(await result.json());
      else {
        rej(await result.json());
      }
    });
  }

  static getHeatMap(token, assetId, period, type) {
    return new Promise(async (res, rej) => {
      const result = await fetch(
        `${DataRoomApi.url}/api/dataroom/admin/statistics/getHeatMap/${assetId}/${period}?type=${type}`,
        {
          headers: {
            OriginVoC: DataRoomApi.origin,
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );

      if (result.status === 200) res(await result.json());
      else {
        rej(await result.json());
      }
    });
  }

  static getRulesList(token, assetId) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${DataRoomApi.url}/api/dataroom/rules/getFileList/${assetId}`, {
        headers: {
          OriginVoC: DataRoomApi.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });

      if (result.status === 200) res(await result.json());
      else {
        rej(await result.json());
      }
    });
  }

  static getDocumentRules(token, assetId, docType) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${DataRoomApi.url}/api/dataroom/rules/getFile/${assetId}/${docType}`, {
        headers: {
          OriginVoC: DataRoomApi.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });

      if (result.status === 200) res(await result.json());
      else {
        rej(await result.json());
      }
    });
  }

  static setDocumentRules(token, assetId, docType, data) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${DataRoomApi.url}/api/admin/workflow/documents/automate/${assetId}/${docType}`, {
        method: "post",
        headers: {
          OriginVoC: DataRoomApi.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (result.status === 200) res(await result.json());
      else {
        rej(await result.json());
      }
    });
  }

  static setRulesList(token, assetId, data) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${DataRoomApi.url}/api/dataroom/rules/setRules/${assetId}`, {
        method: "post",
        headers: {
          OriginVoC: DataRoomApi.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (result.status === 200) res(await result.text());
      else {
        rej(await result.json());
      }
    });
  }

  static searchHeatMap(token, assetId, days, type, value) {
    return new Promise(async (res, rej) => {
      const result = await fetch(
        `${DataRoomApi.url}/api/dataroom/admin/statistics/searchInHeatMap/${assetId}/${days}?type=${type}&query=${value}`,
        {
          headers: {
            OriginVoC: DataRoomApi.origin,
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );

      if (result.status === 200) res(await result.json());
      else {
        rej(await result.json());
      }
    });
  }

  static getUserViewStatistic(token, assetId, username) {
    return new Promise(async (res, rej) => {
      const result = await fetch(
        `${DataRoomApi.url}/api/dataroom/admin/statistics/getFileViewStatisticsForOneUser/${assetId}/${username}`,
        {
          headers: {
            OriginVoC: DataRoomApi.origin,
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );

      if (result.status === 200) res(await result.json());
      else {
        rej(await result.json());
      }
    });
  }

  static getDocVariables(token, isAfterSubscription) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${DataRoomApi.url}/api/dataroom/legalDocument/placeholders/${isAfterSubscription}`, {
        headers: {
          OriginVoC: DataRoomApi.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });

      if (result.status === 200) res(await result.json());
      else {
        rej(await result.json());
      }
    });
  }

  static uploadLegalDocument(token, assetId, formData, type) {
    return new Promise(async (res, rej) => {
      const result = await fetch(
        `${DataRoomApi.url}/api/admin/workflow/documents/uploadLegalDocument/${assetId}/${type}`,
        {
          method: "post",
          headers: {
            OriginVoC: DataRoomApi.origin,
            Authorization: token,
          },
          body: formData,
        }
      );
      if (result.status === 200) {
        await result.json();
        res("success");
      } else rej(await result.json());
    });
  }

  static removeLegalDocument(token, assetId, type) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${DataRoomApi.url}/api/admin/workflow/delete/${assetId}/${type}`, {
        method: "delete",
        headers: {
          OriginVoC: DataRoomApi.origin,
          Authorization: token,
        },
      });
      if (result.status === 200) {
        await result.text();
        res("success");
      } else rej(await result.json());
    });
  }

  static replaceLegalDocument(token, assetId, formData, type) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${DataRoomApi.url}/api/admin/workflow/documents/replace/${assetId}/${type}`, {
        method: "post",
        headers: {
          OriginVoC: DataRoomApi.origin,
          Authorization: token,
        },
        body: formData,
      });
      if (result.status === 200) {
        res(true);
      } else rej(await result.json());
    });
  }

  static checkLegalDocResult(token, assetId, type) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${DataRoomApi.url}/api/dataroom/legalDocument/check/${assetId}/${type}`, {
        headers: {
          OriginVoC: DataRoomApi.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });

      if (result.status === 200) {
        res(await result.blob());
      } else {
        rej(await result.json());
      }
    });
  }

  static approveLegalDoc(token, assetId, type) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${DataRoomApi.url}/api/admin/workflow/documents/approve/${assetId}/${type}`, {
        method: "post",
        headers: {
          OriginVoC: DataRoomApi.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });

      if (result.status === 200) {
        res(await result.json());
      } else {
        rej(await result.json());
      }
    });
  }

  static staringDoc(token, assetId, fileName, folderName) {
    return new Promise(async (res, rej) => {
      const result = await fetch(
        `${DataRoomApi.url}/api/dataroom/changeStarredFileStatus/${assetId}/${folderName}/${fileName}`,
        {
          method: "post",
          headers: {
            OriginVoC: DataRoomApi.origin,
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );

      if (result.status === 200) {
        res(true);
      } else {
        rej(await result.json());
      }
    });
  }

  static watermarkingDoc(token, assetId, fileName, folderName) {
    return new Promise(async (res, rej) => {
      const result = await fetch(
        `${DataRoomApi.url}/api/dataroom/api/pdfProcessing/setWatermark/${folderName}/${fileName}?assetId=${assetId}`,
        {
          method: "post",
          headers: {
            OriginVoC: DataRoomApi.origin,
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );

      if (result.status === 200) {
        res(true);
      } else {
        rej(await result.json());
      }
    });
  }

  static watermarkingFolder(token, assetId, folderName) {
    return new Promise(async (res, rej) => {
      const result = await fetch(
        `${DataRoomApi.url}/api/dataroom/api/pdfProcessing/setWatermarkForFolder/${folderName}?assetId=${assetId}`,
        {
          method: "post",
          headers: {
            OriginVoC: DataRoomApi.origin,
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );

      if (result.status === 200) {
        res(true);
      } else {
        rej(await result.json());
      }
    });
  }

  static getInvestorDocuments(token, userId) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${DataRoomApi.url}/api/dataroom/investorDocuments/${userId}`, {
        headers: {
          OriginVoC: DataRoomApi.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });
      if (result.status === 200) res(await result.json());
      else rej(await result.json());
    });
  }

  static downloadDocumentBlob = async (token, assetName, fileName, userId) => {
    return new Promise(async (res, rej) => {
      const requestFile = await fetch(
        `${DataRoomApi.url}/api/dataroom/investorDocument/file/${userId}/${assetName}/${fileName}`,
        {
          headers: {
            OriginVoC: DataRoomApi.origin,
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );

      if (requestFile.status === 200) res(await requestFile.blob());
      else rej(await requestFile.json());
    });
  };

  static downloadInvestorLD = async (token, assetName, fileName, userId) => {
    return new Promise(async (res, rej) => {
      const requestFile = await fetch(
        `${Api.url}/api/dataroom/investorDocument/fileLink/${userId}/${assetName}/${fileName}`,
        {
          headers: {
            OriginVoC: Api.origin,
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );

      if (requestFile.status === 200) res(await requestFile.text());
      else rej(await requestFile.json());
    });
  };

  static uploadUserDocument(token, assetId, formData) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${DataRoomApi.url}/api/attachment/uploadToUploadedFiles/${assetId}`, {
        method: "post",
        headers: {
          OriginVoC: DataRoomApi.origin,
          Authorization: token,
        },
        body: formData,
      });
      if (result.status === 200) {
        res("success");
      } else rej(await result.json());
    });
  }

  static removeUserDocument(token, assetId, fileName) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${DataRoomApi.url}/api/attachment/deleteFromUploadedFiles/${assetId}/${fileName}`, {
        method: "delete",
        headers: {
          OriginVoC: DataRoomApi.origin,
          Authorization: token,
        },
      });
      if (result.status === 200) {
        res("success");
      } else rej(await result.json());
    });
  }

  static unWatermarkingFolder(token, assetId, folderName) {
    return new Promise(async (res, rej) => {
      const result = await fetch(
        `${DataRoomApi.url}/api/dataroom/api/pdfProcessing/unSetWatermarkForFolder/${folderName}?assetId=${assetId}`,
        {
          method: "post",
          headers: {
            OriginVoC: DataRoomApi.origin,
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );

      if (result.status === 200) {
        res(true);
      } else {
        rej(await result.json());
      }
    });
  }
}
