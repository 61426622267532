import React from "react";
import styled from "styled-components";

import { Btn } from "../../buttons";
import { icons } from "../../../../models/icons";
import { EmptyInfo } from "../AssetDetails/common/emptyInfo";
import { ReactComponent as List } from "assets/list-big.svg";
import { TextFieldComponent } from "components/admin-layout/forms";

const Header = styled.div`
  height: 60px;
  background: #ffffff;
  border-radius: 4px;
  width: 100%;
  margin-bottom: 30px;
  padding: 0 25px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  &:last-child {
    margin-bottom: 0;
    margin-top: 30px;
  }
`;

const Heading = styled.h3`
  font-size: 14px;
  line-height: 135%;
  color: #181818;
  margin: ${({ m = 0 }) => m};
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ w = "calc(100% - 353px)" }) => w};
  height: fit-content;
  box-sizing: border-box;
`;

const Card = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 20px 25px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  background: #ffffff;
  border-radius: 4px;
  &:last-child {
    margin-bottom: 0;
  }
`;

const Line = styled.div`
  width: 100%;
  margin: 25px 0 20px;
  border-top: 1px solid #efefef;
`;

const Label = styled.h6`
  font-size: 10px;
  line-height: 135%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: rgba(24, 24, 24, 0.6);
  margin: 0 0 4px;
`;

const Val = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  color: #333333;
  margin: 0;
`;

const Block = styled.div`
  height: 60px;
  background: ${({ theme: { emptyCardBg } }) => emptyCardBg};

  border-radius: 2px;
  width: 100%;
  display: flex;
`;

export const HeaderRow = ({ history }) => {
  return (
    <Header>
      <Btn width={150} transparent onClick={() => history.push("/admin/kyc/form-settings/forms")}>
        {icons.left} back to TEMPLATES
      </Btn>
      <Heading m="0 0 0 25px">New Template</Heading>
    </Header>
  );
};

export const Footer = ({ onSubmit, onCancel, disabled }) => {
  return (
    <Header>
      <Btn width={140} hide onClick={onCancel}>
        DISCARD CHANGES
      </Btn>
      <Btn width={180} margin="0 0 0 auto" onClick={onSubmit} disabled={disabled}>
        Save changes
      </Btn>
    </Header>
  );
};

export const Statistic = ({ sections, fields }) => {
  return (
    <Card>
      <Heading>Statistics</Heading>
      <Line />
      <Row>
        <Column w="120px">
          <Label>SECTIONS</Label>
          <Val>{sections}</Val>
        </Column>
        <Column w="120px">
          <Label>Fields</Label>
          <Val>{fields}</Val>
        </Column>
      </Row>
    </Card>
  );
};

export const General = ({ onChange, name, error }) => {
  const onChangeName = (e) => {
    onChange(e.target.value);
  };
  return (
    <Card>
      <Heading>General Settings</Heading>
      <Line />
      <TextFieldComponent
        label="Template Name"
        placeholder="Template Name"
        onChange={onChangeName}
        defaultValue={name}
        error={error}
      />
    </Card>
  );
};

export const Empty = ({ onAdd }) => {
  return (
    <EmptyInfo
      heading="You have no added sections and questions yet"
      text="Start filling the form by adding a new section, you can add later on the questions"
      icon={<List />}
    >
      <Btn width={200} margin="40px 0 0" onClick={onAdd}>
        {icons.plusCircle}
        ADD NEW section
      </Btn>
    </EmptyInfo>
  );
};

export const AddSection = ({ onAdd }) => {
  return (
    <Block>
      <Btn transparent width={200} margin="auto" onClick={onAdd}>
        {icons.plusCircle}
        ADD NEW section
      </Btn>
    </Block>
  );
};
