import styled from "styled-components";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import Api from "../../../../helpers/api";
import { ReactComponent as GroupIcon } from "../../../../assets/users-grey.svg";

const GreyBlock = styled.div`
  width: 74px;
  height: 165px;
  background: #e2e3e8;
  border-radius: 4px 0 0 4px;
  position: relative;
  > svg {
    width: 102px;
    height: 102px;
    position: absolute;
    z-index: 20;
    top: 30px;
    left: 25px;
  }
`;

const Avatar = styled.img`
  width: 102px;
  height: 102px;
  border-radius: 50%;
  position: absolute;
  z-index: 20;
  top: 30px;
  left: 25px;
  object-fit: cover;
`;

export const Header = styled.div`
  height: 60px;
  background: #ffffff;
  border-radius: 4px 0px 4px 4px;
  margin-bottom: ${({ isFooter = false }) => (isFooter ? 0 : "30px")};
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 5px 25px;
  width: 100%;
`;

export const BackLink = styled(Link)`
  width: 150px;
  height: 32px;
  border: 1px solid ${({ theme: { primary } }) => primary};
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 135%;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  text-decoration: none;
  color: ${({ theme: { primary } }) => primary};
  margin-right: 25px;
  > svg {
    margin-right: 7px;
  }
`;

export const AddUserButton = styled.div`
  width: 140px;
  height: 32px;
  box-sizing: border-box;
  border: 1px solid ${({ theme: { primary } }) => primary};
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 135%;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  text-decoration: none;
  color: ${({ theme: { primary } }) => primary};
  cursor: pointer;
  margin-left: auto;
`;

export const EditGroupButton = styled(Link)`
  text-decoration: none;
  width: 195px;
  height: 32px;
  box-sizing: border-box;
  background: ${({ theme: { primary } }) => primary};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 135%;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: #ffffff;
  margin-left: 12px;
`;

export const Flex = styled.div`
  width: 100%;
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
`;

export const Card = styled.div`
  width: ${({ big = false }) => (big ? "calc(100% - 353px)" : "323px")};
  padding: ${({ big = false }) => (big ? "0" : "22px")};
  box-sizing: border-box;
  min-height: 165px;
  box-sizing: border-box;
  background: #ffffff;
  border-radius: 4px;
  display: flex;
  align-items: center;
  flex-direction: ${({ big = false }) => (big ? null : "column")};
  > section {
    height: 84px;
    margin-left: 95px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    > div {
      display: flex;
      justify-content: space-between;
    }
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 214px;
  margin-right: 20px;
`;

export const UsersInGroup = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: -0.02em;
  color: #181818;
`;

export const LastEdition = styled.div`
  text-transform: uppercase;
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: -0.02em;
  color: #333333;
`;

export const GroupName = styled.div`
  margin: 0;
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 135%;
  letter-spacing: -0.02em;
  color: #192036;
`;

export const Title = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 135%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: rgba(24, 24, 24, 0.6);
  margin-bottom: 5px;
`;

export const Btn = styled.button`
  position: relative;
  white-space: nowrap;
  padding-bottom: 0;
  padding-top: 0;
  width: ${({ width, fullWidth }) => (width ? `${width}px` : fullWidth ? "100%" : "94px")};
  border: 1px solid
    ${({ black, hide, error, green, theme: { primary } }) =>
      black ? "#20263C" : hide ? "#CACACA" : error ? "#FF4343" : green ? "#55B17C" : primary};
  height: ${({ height }) => (height ? `${height}px` : "32px")};
  background: ${({ black, white, hide, transparent, error, green, theme: { primary } }) =>
    black ? "#20263C" : white || hide ? "#fff" : transparent || error || green ? "transparent" : primary};
  color: ${({ white, hide, transparent, error, green, theme: { primary } }) =>
    white || transparent ? primary : hide ? "#CACACA" : error ? "#FF4343" : green ? "#55B17C" : "#fff"};
  font-size: 10px;
  line-height: 135%;
  text-align: center;
  font-weight: bold;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  border-radius: 4px;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: ${({ margin }) => (margin ? margin : "0")};
  transition: all 0.3s linear;
  &:disabled {
    background: #e3e3e3 !important;
    color: white !important;
    border: none;
    > svg {
      path {
        stroke: #fff;
      }
    }
  }
  &:focus {
    background: ${({ black, white, hide, transparent, error, green, theme: { primary } }) =>
      black ? "#20263C" : white || hide ? "#fff" : transparent || error || green ? "transparent" : primary};
  }
  > svg {
    margin: ${({ rightIcon }) => (rightIcon ? "0 0 0 6px " : "0 6px 0 0")};
    transform: ${({ transform }) => (transform ? "rotate(180deg)" : "none")};
    transition: all 0.3s linear;

    path {
      stroke: ${({ transparent, error, green, theme: { primary } }) =>
        transparent ? primary : error ? "#FF4343" : green ? "#55B17C" : "#fff"};
    }
  }
`;

export const AvatarBlock = ({ token, file, hash }) => {
  const [avatar, setAvatar] = useState(null);

  useEffect(() => {
    if (!avatar && file && hash) {
      Api.getAttachment(token, hash, file).then((blob) => setAvatar(blob || Api.defaultAvatar));
    }
  }, []);
  return (
    <GreyBlock>
      {!avatar && file && hash ? null : avatar ? <Avatar src={avatar} alt="avatar" /> : <GroupIcon />}
    </GreyBlock>
  );
};

export const TableHeader = styled.div`
  height: 60px;
  width: 100%;
  background: #ffffff;
  border-radius: 4px;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  justify-content: ${({ justify }) => (justify ? justify : "flex-start")};
`;

export const HeaderTD = styled.div`
  transform: ${({ translate }) => (translate ? `translateX(${translate}px)` : "none")};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 135%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  box-sizing: ${({ boxSizing }) => boxSizing};
  padding: ${({ padding }) => (padding ? padding : "0")};
  color: ${({ blue, theme: { primary } }) => (blue ? primary : "rgba(24, 24, 24, 0.4)")};

  cursor: ${({ blue }) => (blue ? "pointer" : "")};
  width: ${({ width }) => (width ? width : "10%")};
  white-space: nowrap;
`;
