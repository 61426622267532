export const Navigation = [
  {
    text: "Offerings",
    link: "/investor/offerings/all",
    re: /(offerings\/.+)/,
  },
  {
    text: "Transactions",
    link: "/investor/transactions/all/all",
    re: /(transactions)(.+|)/,
  },
  {
    text: "Documents",
    link: "/investor/documents",
    re: /\/documents/,
  },
  {
    text: "Wallet",
    link: "/investor/wallet",
    re: /\/wallet/,
  },
  {
    text: "Data Room",
    link: "/investor/data-room/main",
    re: /\/investor\/data-room.+$/,
  },
  {
    text: "Q&A",
    link: "/investor/question-and-answer/main",
    re: /\/investor\/question-and-answer.+$/,
  },
];

export const FacilitatorNav = [
  {
    text: "Offerings",
    link: "/investor/offerings/all",
    re: /(offerings\/.+)/,
  },
  {
    text: "Data Room",
    link: "/investor/data-room/main",
    re: /\/investor\/data-room.+$/,
  },
  {
    text: "Q&A",
    link: "/investor/question-and-answer/main",
    re: /\/investor\/question-and-answer.+$/,
  },
];
