import React, { useState, useEffect } from "react";
import moment from "moment";

import Api from "../../../../helpers/api";
import {
  Card,
  ImageBlock,
  InfoBlock,
  DealName,
  CardHeader,
  DataCol,
  DataName,
  DataRow,
  ButtonsRow,
  RouteLink,
  StickName
} from "./styled";
import { ReactComponent as Help } from "../../../../assets/help-circle.svg";
import { ReactComponent as Folder } from "../../../../assets/folder-dr.svg";
import { ReactComponent as Calendar } from "../../../../assets/calendar-dr.svg";

export const DealCardVertical = ({
  deal: {
    dealsType,
    assetId,
    assetName,
    image,
    lastUpdate,
    totalQuestions,
    waitingQuestions
  },
  token
}) => {
  const [logo, setLogo] = useState(null);

  const isNew = dealsType === "NEW_DEAL";

  useEffect(() => {
    if (image) {
      const { fileNameWithExtension, secureHash } = image;
      Api.getAttachment(token, secureHash, fileNameWithExtension).then(b =>
        setLogo(b)
      );
    }
  }, []);

  return (
    <Card column>
      <ImageBlock fullW height={190}>
        {logo && <img src={logo} alt={assetName} />}
        <StickName vertical active={isNew}>
          {isNew ? "NEW DEAL" : "EXISTED DEAL"}
        </StickName>
      </ImageBlock>
      <InfoBlock width="100%">
        <CardHeader>
          <DealName>{assetName}</DealName>
        </CardHeader>
        <DataCol vertical>
          <DataRow>
            <Help />
            <DataName>
              Questions: <span>{totalQuestions}</span>
            </DataName>
          </DataRow>
          <DataRow>
            <Folder />
            <DataName>
              Waiting for Answers: <span>{waitingQuestions}</span>
            </DataName>
          </DataRow>
          <DataRow>
            <Calendar />
            <DataName>
              Last Update:{" "}
              <span>
                {lastUpdate
                  ? moment(lastUpdate).format("DD.MM.YYYY, hh:mm A")
                  : "no activity"}
              </span>
            </DataName>
          </DataRow>
        </DataCol>
        <ButtonsRow>
          <RouteLink
            transparent
            blue={isNew}
            grey={!isNew}
            to={`/investor/question-and-answer/${assetId}`}
            fullWidth
          >
            OPEN ASSET Q&A
          </RouteLink>
        </ButtonsRow>
      </InfoBlock>
    </Card>
  );
};
