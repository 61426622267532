import React, { useState, Fragment, useEffect } from "react";
import { ReactComponent as Plus } from "../../../../../assets/plus-circle.svg";
import { ReactComponent as Edit } from "../../../../../assets/edit-2.svg";
import { ReactComponent as Remove } from "../../../../../assets/x.svg";
import { ReactComponent as Icon } from "../../../../../assets/list-icon.svg";
import {
  AddRound,
  RoundBorder,
  RoundName,
  RoundCol,
  Icons,
  NoIcon,
  SettingsBg,
  SettingsHeading,
  SettingsText,
  SettingsInfo
} from "../styled";

import { SimpleCard, ExpandedPanel } from "./ExpandedPanel";
import { AddRound as Modal } from "./AddRound";
import { numberWithCommas } from "helpers";

export const PreviousRound = ({ onChange, defaultValues }) => {
  const [rounds, setRounds] = useState(defaultValues.roundsInfo || []);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    onChange && onChange([...rounds]);
  }, [rounds]);

  const onChangeModal = () => {
    setModalOpen(!modalOpen);
  };

  const onAddRound = data => {
    const _rounds = [...rounds];
    _rounds.push(data);
    setRounds(_rounds);
  };

  const onEditRound = obj => {
    const { data, index } = obj;
    const _rounds = [...rounds];
    _rounds[index] = data;
    setRounds(_rounds);
  };

  const onRemoveRound = index => {
    const _rounds = [...rounds];
    _rounds.splice(index, 1);
    setRounds(_rounds);
  };

  const renderRounds = () =>
    rounds
      .sort((a, b) => {
        if (a.roundFrom < b.roundFrom) return -1;
      })
      .map((e, i) => (
        <Round
          round={e}
          key={`${e.fundingName}-${e.roundFrom}`}
          onEdit={obj => onEditRound(obj)}
          onRemove={index => onRemoveRound(index)}
          index={i}
        />
      ));

  return (
    <Fragment>
      <ExpandedPanel header="Previous Rounds">
        {rounds.length ? (
          <Fragment>
            <AddRound onClick={onChangeModal} m="0 0 15px auto">
              <Plus />
              <span>ADD ROUND</span>
            </AddRound>
            {renderRounds()}
          </Fragment>
        ) : (
          <SettingsBg p="20px 30px" m="0 0 5px" dir="row" justify="flex-start">
            <Icon />
            <SettingsInfo>
              <SettingsHeading m="0 0 8px">
                You have not yet added a round
              </SettingsHeading>
              <SettingsText m="0" align="left">
                You can add rounds by using button «Add Round», don’t forget to
                fill the date for correct sorting.
              </SettingsText>
            </SettingsInfo>
            <AddRound m="0 0 0 auto" onClick={onChangeModal} position="static">
              <Plus />
              <span>ADD ROUND</span>
            </AddRound>
          </SettingsBg>
        )}
      </ExpandedPanel>

      {modalOpen ? (
        <Modal
          callBack={data => onAddRound(data)}
          onBackdropClick={onChangeModal}
          curency={
            defaultValues.baseCurrency ||
            defaultValues.assetFinancialProperty.baseCurrency
          }
        />
      ) : null}
    </Fragment>
  );
};

const Round = ({
  round,
  round: {
    fundingName,
    sharePrice,
    roundSize,
    newSharesQuantity,
    companyValuation,
    currency,
    roundId
  },
  index,
  onEdit,
  onRemove
}) => {
  const [editOpen, setEditOpen] = useState(false);

  const onChangeEdit = () => setEditOpen(!editOpen);

  const editRound = data => {
    onEdit({ data, index });
  };
  return (
    <Fragment>
      <RoundBorder>
        <RoundName>{fundingName}</RoundName>
        <RoundCol>
          Raised Amount:{" "}
          <span>
            {numberWithCommas(roundSize || newSharesQuantity * sharePrice)}{" "}
            {currency}
          </span>{" "}
        </RoundCol>
        <RoundCol>
          Share Price:{" "}
          <span>
            {numberWithCommas(sharePrice)} {currency}
          </span>{" "}
        </RoundCol>
        <RoundCol>
          Valuation:{" "}
          <span>
            {numberWithCommas(companyValuation)} {currency}
          </span>{" "}
        </RoundCol>
        <Icons>
          <Edit onClick={onChangeEdit} />
          {roundId ? <NoIcon /> : <Remove onClick={() => onRemove(index)} />}
        </Icons>
      </RoundBorder>

      {editOpen ? (
        <Modal
          isEdit
          onBackdropClick={onChangeEdit}
          defaultRound={round}
          callBack={data => editRound(data)}
        />
      ) : null}
    </Fragment>
  );
};
