import React from "react";

import { AssetsTabs, AssetTypeTab } from "../../dealTypeCard";
import { Btn } from "../../buttons";
import { ReactComponent as SettingsIcon } from "../../../../assets/KYC-sett.svg";
import { ReactComponent as ListIcon } from "../../../../assets/KYC-list.svg";
import { icons } from "models/icons";

export const Tabs = ({ type, history }) => {
  return (
    <AssetsTabs>
      <AssetTypeTab to={"/admin/kyc/list"} active={type === "list"}>
        <ListIcon />
        KYC Lists
      </AssetTypeTab>
      <AssetTypeTab
        to={"/admin/kyc/form-settings/forms"}
        active={type.includes("form-settings")}
      >
        <SettingsIcon />
        KYC Form Settings
      </AssetTypeTab>
      <Btn
        margin="0 0 0 auto"
        width={215}
        onClick={() => history.push("/admin/kyc-form")}
      >
        {icons.plusCircle} add new TEMPLATE
      </Btn>
    </AssetsTabs>
  );
};
