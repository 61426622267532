import styled from "styled-components";
import React, { useState } from "react";

import { ReactComponent as Clock } from "../../../../assets/clock-i.svg";
import { ReactComponent as InfoIcon } from "../../../../assets/info.svg";

export const TEXT_FIELD_SIZE = {
  height: 42,
  labelFontSize: 10,
  fontSize: 12,
};

export const Wrapper = styled.section`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 auto;
  width: 100%;
  padding: 0 30px;
  max-width: 1440px;
  box-sizing: border-box;
`;

export const Header = styled.div`
  height: 60px;
  width: 100%;
  background: #ffffff;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 0 30px 0 0;
  display: flex;
  align-items: center;
  margin-bottom: 25px;
`;

export const BorderBlock = styled.div`
  padding: 14px 25px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #f2f2f2;
  margin-right: 24px;
`;

export const Heading = styled.h3`
  font-family: Muli;
  font-style: normal;
  font-weight: ${({ fWeight = 600 }) => fWeight};
  font-size: 18px;
  line-height: 135%;
  color: #181818;
  margin: ${({ margin = 0 }) => margin};
`;

export const Col = styled.div`
  width: ${({ width = "440px" }) => width};
  height: ${({ height = "auto" }) => height};
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-items: ${({ align = "flex-start" }) => align};
`;

export const Card = styled.div`
  width: 100%;
  padding: ${({ noPadding, padding }) => (noPadding ? 0 : padding ? padding : "25px 30px")};
  height: ${({ height, fullHeight }) => (fullHeight ? "100%" : height ? `${height}px` : "auto")};
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid #f0f0f0;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const BorderCont = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 30px;
  border-bottom: 1px solid #f2f2f2;
`;

export const CurrencyBlock = styled.div`
  width: 105px;
  height: 42px;
  border: 1px solid #efefef;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 15px 20px;
  display: flex;
  align-items: center;
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 135%;
  letter-spacing: -0.02em;
  color: #181818;
  margin-top: auto;
`;

export const Method = ({ active, type, onChangeType, cashType = false, cashBalance, currency }) => {
  return (
    <Border active={active}>
      <Circle active={active} onClick={onChangeType} />
      <TypeText active={active}>{type}</TypeText>
      {cashType && (
        <TypeText active={active} mLeft="auto">
          {cashBalance.toFixed(2)} {currency}
        </TypeText>
      )}
    </Border>
  );
};

export const Row = styled.div`
  display: flex;
  padding: ${({ padding }) => padding};
  margin: ${({ margin }) => (margin ? margin : 0)};
  box-sizing: border-box;
  align-items: center;
  justify-content: ${({ justify }) => justify};
  width: ${({ width }) => width};
  svg {
    path {
      stroke: ${({ defaultStroke, theme: { primary } }) => (defaultStroke ? "" : primary)};
    }
  }
`;

export const Text = styled.p`
  font-family: Muli;
  margin: ${({ margin }) => (margin ? margin : 0)};
  color: ${({ color }) => (color ? color : "rgba(24, 24, 24, 0.6)")};
  font-size: ${({ fSize }) => (fSize ? `${fSize}px` : "20px")};
  text-transform: ${({ upper }) => (upper ? "uppercase" : "none")};
  line-height: ${({ lineH }) => lineH};
  text-align: ${({ align }) => align};
  > span {
    font-family: Muli;
    color: #181818;
    font-weight: 600;
  }
`;

const Border = styled.div`
  width: 287px;
  height: 42px;
  padding: 13px 15px;
  display: flex;
  align-items: center;
  border: 1px solid ${({ theme: { primary } }) => primary};
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid ${({ active, theme: { primary } }) => (active ? primary : "#EFEFEF")};
  background: ${({ active, theme: { disabledField } }) => (active ? "transparent" : disabledField)};
  margin-right: 10px;
  transition: all 0.3s linear;
  &:last-child {
    margin-right: 0;
  }
`;

const Circle = styled.div`
  position: relative;
  width: 20px;
  height: 20px;
  border: 1px solid ${({ active, theme: { primary } }) => (active ? primary : "#EFEFEF")};
  box-sizing: border-box;
  border-radius: 50%;
  display: flex;
  margin-right: 12px;
  cursor: pointer;
  &::before {
    content: "";
    display: block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin: auto;
    background: ${({ active, theme: { primary } }) => (active ? primary : "#EFEFEF")};
  }
`;

const TypeText = styled.span`
  font-family: Muli;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 135%;
  letter-spacing: -0.02em;
  color: ${({ active, theme: { primary } }) => (active ? primary : "rgba(24, 24, 24, 0.4)")};
  margin-left: ${({ mLeft }) => mLeft};
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: ${({ mRight }) => (mRight ? `${mRight}px` : "60px")};
  width: ${({ width }) => width};
  align-items: flex-start;
`;

const ColHeading = styled.h6`
  font-family: Muli;
  font-weight: bold;
  font-size: 10px;
  line-height: 135%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  margin: 0 0 3px 0;
  color: rgba(24, 24, 24, 0.6);
`;

const Data = styled.div`
  font-family: Muli;
  font-weight: bold;
  font-size: 14px;
  line-height: 135%;
  letter-spacing: 0.05em;
  color: #181818;
  display: flex;
  align-items: center;
  width: ${({ width }) => width};
`;

const Cirlcle = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: ${({ color }) => color};
  margin-left: 5px;
  margin-right: ${({ mRight }) => (mRight ? `${mRight}px` : "0")};
`;

export const DataColumn = ({ mRight, heading, data, circleColor = false, width, childrenWidth }) => {
  return (
    <Column mRight={mRight} width={width}>
      <ColHeading>{heading}</ColHeading>
      <Data width={childrenWidth}>
        {data}
        {circleColor && <Cirlcle color={circleColor} />}
      </Data>
    </Column>
  );
};

const ProgressContainer = styled.div`
  width: 100%;
  height: 8px;
  background: ${({ isTrade, theme: { secondaryLight, primaryLight } }) => (isTrade ? secondaryLight : primaryLight)};
  display: flex;
`;

export const Progress = styled.div`
  height: 8px;
  background: ${({ isTrade, theme: { secondary, primaryMiddle } }) => (isTrade ? secondary : primaryMiddle)};
  width: ${({ width }) => `${width}%`};
  transition: ${({ noTransition }) => (noTransition ? "none" : "width 1.5s cubic-bezier(0.22, 0.61, 0.36, 1)")};
`;

export const ProgressBar = ({ sold, unsold, transition = 1000, noTransition, isTrade = false }) => {
  const [progressWidth, setProgressWidth] = useState(0);

  setTimeout(() => {
    setProgressWidth((sold / (sold + unsold)) * 100);
  }, transition);
  return (
    <ProgressContainer isTrade={isTrade}>
      <Progress isTrade={isTrade} noTransition={noTransition} width={progressWidth} />
    </ProgressContainer>
  );
};

export const Line = styled.div`
  width: calc(100% + 60px);
  transform: translateX(-30px);
  height: 1px;
  background: #f2f2f2;
  margin: 20px 0;
`;

export const Label = styled.span`
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 135%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: rgba(24, 24, 24, 0.6);
  margin-bottom: 8px;
`;

export const TextInfo = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  color: ${({ grey }) => (grey ? "#6A6A6A" : "#333333")};
`;

export const InfoBlock = styled.div`
  height: 70px;
  width: 100%;
  background: ${({ theme: { disabledField } }) => disabledField};
  border: 1px solid #efefef;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 15px 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${({ smallScreen = false }) => {
    if (smallScreen) {
      return `height:135px;flex-direction: column;align-items: flex-start;`;
    } else return "";
  }}
`;

export const Link = styled.span`
  cursor: pointer;
  font-family: Muli;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: ${({ theme: { primary } }) => primary};
`;

const Btn = styled.div`
  width: ${({ width = false }) => (width ? width : "125px")};
  height: 32px;
  background: #c6c6c6;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 150%;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  > svg {
    margin-right: 8px;
  }
`;

export const Status = ({ width }) => (
  <Btn width={width}>
    <Clock /> Pending
  </Btn>
);

const InfoBtn = styled.div`
  cursor: pointer;
  width: 32px;
  height: 32px;
  background: ${({ theme: { infoButtonBg } }) => infoButtonBg};
  > svg {
    path {
      stroke: ${({ theme: { primary } }) => primary};
    }
  }
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MoreInfo = ({ onClick }) => (
  <InfoBtn onClick={onClick}>
    <InfoIcon />
  </InfoBtn>
);

export const Counter = styled.div`
  display: flex;
  width: 100%;
  height: 56px;
  align-items: center;
  justify-content: center;
  margin-bottom: 6px;
  background: ${({ theme: { quoteBgInvestor } }) => quoteBgInvestor};
  border-radius: 4px;
  font-family: Muli;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 135%;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.05em;
  color: #181818;
`;

export const DocuSign = styled.iframe`
  min-height: 100%;
  width: 100vw;
  position: absolute;
  top: 0;
  border: none;
  margin: 0 auto;
`;

export const FrameBg = styled.section`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  z-index: 101;
`;
