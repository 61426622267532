import React from "react";

import { Wrapper } from "components/admin-layout/main";
import { FlexRow } from "components/admin-layout/dashBoardCard";
import { EmptyInfo } from "components/admin-layout/pages/AssetDetails/common/emptyInfo";
import { Main, TextSub } from "components/admin-layout/pages/DealForm/Step/styled";
import { icons } from "models/icons";
import { Btn } from "components/admin-layout/buttons";
import { Calendar } from "components/common/calendar";

export const Dashboard = () => {
  return (
    <Wrapper bg="transparent">
      <FlexRow>
        <Main currentHeight="415px" padding="20px 25px 25px">
          <FlexRow mBottom="0" align="flex-start">
            <TextSub textAlign="left" fontWeight="bold" color="#181818" marginSize="0 0 25px 0">
              Assets Preview
            </TextSub>
          </FlexRow>
          <EmptyInfo heading="There are no assets yet" text="You can add your first asset with our asset master">
            <Btn width={180} margin="25px 0 0">
              {icons.plusCircle} ADD NEW ASSET
            </Btn>
          </EmptyInfo>
        </Main>
      </FlexRow>
      <FlexRow>
        <Main currentHeight="415px" padding="20px 25px 25px">
          <FlexRow mBottom="0" align="flex-start">
            <TextSub textAlign="left" fontWeight="bold" color="#181818" marginSize="0 0 25px 0">
              Transactions
            </TextSub>
          </FlexRow>
          <EmptyInfo
            heading="There are no transactions yet"
            text="When we’ll have transactions to show for you we’ll show them here"
          />
        </Main>
      </FlexRow>
      <FlexRow>
        <Main currentHeight="415px" width="calc(100% - 325px)" padding="20px 25px 25px">
          <FlexRow mBottom="0" align="flex-start">
            <TextSub textAlign="left" fontWeight="bold" color="#181818" marginSize="0 0 25px 0">
              Chat with Investors
            </TextSub>
          </FlexRow>
          <EmptyInfo
            icon={icons.messageThin}
            heading="There are no chats yet"
            text="When we’ll have chats to show for you we’ll show them here"
          />
        </Main>
        <div style={{ width: "calc(310px - 15px)" }}>
          <Calendar isPreview />
        </div>
      </FlexRow>
    </Wrapper>
  );
};
