import React, { useState, useEffect, useRef, Fragment } from "react";
import ReactDOM from "react-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import { Btn } from "../../../buttons";

import {
  Card,
  HeadingCard,
  RowCard,
  Background,
  Wrapper,
  HeaderModal,
  Heading,
  Content,
  ContentScroll,
  FoooterModal,
  RowInputs,
  DynamicBtn,
  DynamicFielsWrapper,
  FieldBlock,
  EditableBlock,
  Icons,
  IconContainer,
  PaddingBox,
  Hidden,
  ModalText,
  TemplateControls,
  TemplateInfo,
  TemplateBtn,
  BtnBlock
} from "../styled";
import { TextFieldComponent } from "../../../forms";
import { icons } from "../../../../../models/icons";
import { getUniqName } from "../../../../../helpers/getUniqName";
import { ExpandedPanel } from "./ExpandedPanel";

export const DinamicFinancial = ({ _data, onChange }) => {
  const [data, setData] = useState(
    _data
      ? [...JSON.parse(_data)]
      : [
          { name: "Target Investor IRR (%)", value: "" },
          { name: "Target Equity Multiple (x)", value: "" },
          { name: "Target Average Cash Yield (%)", value: "" }
        ]
  );
  const [modalOpen, setModalOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);

  const equityData = [
    { name: "Target Investor IRR (%)", value: "" },
    { name: "Target Equity Multiple (x)", value: "" },
    { name: "Target Average Cash Yield (%)", value: "" },
    { name: "Target Investment Period (years)", value: "" },
    { name: "Distribution Commencement", value: "" },
    { name: "Distribution Period", value: "" },
    { name: "Acquisition Date", value: "" },
    { name: "Purchase Price", value: "" },
    { name: "Preferred Return Accrual Date", value: "" },
    { name: "Offers Due", value: "" },
    { name: "Preferred Return Accrual Date", value: "" },
    { name: "Funds Due", value: "" },
    { name: "Acquisition Cost", value: "" },
    { name: "Target Project Level IRR (%)", value: "" },
    { name: "Sponsor Co-Investment", value: "" }
  ];

  const debtData = [
    { name: "Rank", value: "" },
    { name: "Use of funds", value: "" },
    { name: "Format", value: "" },
    { name: "Country", value: "" },
    { name: "Term (month)", value: "" },
    { name: "Date of issuance", value: "" },
    { name: "Initial Maturity", value: "" },
    { name: "Extended Maturity", value: "" },
    { name: "Interest rate format", value: "" },
    { name: "Interest rate", value: "" },
    { name: "Spread (bps)", value: "" },
    { name: "Payment Frequency", value: "" },
    { name: "Minimum Investment", value: "" },
    { name: "LtC / LtV at closing", value: "" },
    { name: "Leverage (Net debt / EBITDA)", value: "" },
    { name: "Covenants", value: "" }
  ];

  const ownData = [
    { name: "Target Investor IRR (%)", value: "" },
    { name: "Target Equity Multiple (x)", value: "" },
    { name: "Target Average Cash Yield (%)", value: "" }
  ];

  const onChangeTemplate = data => () => {
    setData(data);
  };

  const checkTemplate = type => {
    const currentFields = data.map(el => el.name);
    const equityFields = equityData.map(el => el.name);
    const debtFields = debtData.map(el => el.name);

    if (type === "equity") {
      return (
        JSON.stringify(equityFields.sort()) ===
        JSON.stringify(currentFields.sort())
      );
    } else if (type === "debt") {
      return (
        JSON.stringify(debtFields.sort()) ===
        JSON.stringify(currentFields.sort())
      );
    } else if (type === "own") {
      return (
        JSON.stringify(currentFields.sort()) !==
          JSON.stringify(equityFields.sort()) &&
        JSON.stringify(currentFields.sort()) !==
          JSON.stringify(debtFields.sort())
      );
    }
  };

  const onChangeModal = () => setModalOpen(!modalOpen);
  const onChangeEdit = () => setEditOpen(!editOpen);

  const onAddField = name => {
    const _data = [...data];
    _data.push({ name, value: "" });
    setData(_data);
  };

  const onChangeField = (e, i) => {
    const _data = [...data];
    _data[i].value = e.target.value;
    setData(_data);
  };

  useEffect(() => {
    onChange(data);
  }, [data]);

  const renderFields = () => {
    if (data.length) {
      return data.map(({ name, value }, i) => (
        <FieldBlock key={`${name}-${i}`}>
          <TextFieldComponent
            label={name}
            placeholder={name}
            defaultValue={value}
            onChange={e => onChangeField(e, i)}
          />
        </FieldBlock>
      ));
    }
  };

  return (
    <ExpandedPanel
      header="Financials"
      extraHeader={
        <Fragment>
          <DynamicBtn small light onClick={onChangeEdit} top="13px">
            {icons.edit}
            <span>Edit</span>
          </DynamicBtn>
          <DynamicBtn onClick={onChangeModal} top="13px">
            {icons.add}
            <span>Add New Field</span>
          </DynamicBtn>
        </Fragment>
      }
    >
      <TemplateControls>
        <h1>Template</h1>
        <div>
          <BtnBlock>
            <TemplateBtn
              onClick={onChangeTemplate(equityData)}
              active={checkTemplate("equity")}
            >
              Equity
            </TemplateBtn>
            <TemplateBtn
              onClick={onChangeTemplate(debtData)}
              active={checkTemplate("debt")}
            >
              Debt
            </TemplateBtn>
            <TemplateBtn
              onClick={onChangeTemplate(ownData)}
              active={checkTemplate("own")}
            >
              Own Template
            </TemplateBtn>
          </BtnBlock>
          <TemplateInfo>
            You can choose and edit one of the templates provided by the
            platform
          </TemplateInfo>
        </div>
      </TemplateControls>
      <DynamicFielsWrapper margin="0">
        {renderFields()}
        <FieldBlock />
      </DynamicFielsWrapper>
      {modalOpen ? (
        <AddField
          callBack={name => onAddField(name)}
          onBackdropClick={onChangeModal}
        />
      ) : null}
      {editOpen ? (
        <EditFields
          originalData={data}
          onBackdropClick={onChangeEdit}
          onSubmit={data => setData(data)}
        />
      ) : null}
    </ExpandedPanel>
  );
};

const AddField = ({ onBackdropClick, callBack }) => {
  const [name, setName] = useState("");

  const onAddField = () => {
    callBack(name);
    onBackdropClick();
  };

  return ReactDOM.createPortal(
    <Background onClick={onBackdropClick}>
      <Wrapper width={365} onClick={e => e.stopPropagation()}>
        <HeaderModal>
          <Heading>Add new field</Heading>
          <span onClick={onBackdropClick}>{icons.close}</span>
        </HeaderModal>
        <Content p="25px">
          <RowInputs>
            <TextFieldComponent
              label="Field Name"
              placeholder="Field Name"
              defaultValue={name}
              onChange={e => setName(e.target.value)}
            />
          </RowInputs>
        </Content>
        <FoooterModal>
          <Btn hide onClick={onBackdropClick}>
            cancel
          </Btn>
          <Btn width={180} onClick={onAddField} disabled={!name}>
            ADD FIELD nAME
          </Btn>
        </FoooterModal>
      </Wrapper>
    </Background>,
    document.getElementById("round-modal")
  );
};

const EditFields = ({ onBackdropClick, originalData, onSubmit }) => {
  const [data, setData] = useState(
    JSON.parse(JSON.stringify([...originalData]))
  );
  const scrollRef = useRef(null);

  const onRename = obj => {
    const { val, i } = obj;
    const _data = [...data];
    _data[i].name = val;
    delete _data[i].isNew;
    setData(_data);
  };

  const onCreateNewField = () => {
    const _data = [...data];
    _data.push({
      name: `${getUniqName(data, "New field")}`,
      val: "",
      isNew: true
    });
    new Promise(res => {
      setData(_data);
      res();
    }).then(() => {
      if (scrollRef && scrollRef.current) {
        scrollRef.current.scroll({
          left: 0,
          top: 1000000000,
          behavior: "smooth"
        });
      }
    });
  };

  const onRemove = index => {
    const _data = [...data];
    _data.splice(index, 1);
    setData(_data);
  };

  const onSave = () => {
    onSubmit(data);
    onBackdropClick();
  };

  const onAbort = () => {
    setData([...originalData]);
    onBackdropClick();
  };

  const renderFields = () => {
    if (data.length) {
      return data.map(({ name, value, isNew }, i) => {
        const random = Math.round(Math.random() * 1000) + "";
        return (
          <Field
            i={i}
            key={`${name}-${value}-${random}-${i}`}
            name={name}
            onRemove={index => onRemove(index)}
            onRename={obj => onRename(obj)}
            isNew={isNew}
          />
        );
      });
    }
  };

  const onDragEnd = data => async result => {
    const {
      source: { index: from },
      destination: { index: target }
    } = result;
    const _data = [...data];
    const draggableItem = _data[from];

    _data.splice(from, 1);
    _data.splice(target, 0, draggableItem);

    setData(_data);
  };

  return ReactDOM.createPortal(
    <Background onClick={onBackdropClick}>
      <Wrapper onClick={e => e.stopPropagation()}>
        <HeaderModal>
          <Heading>Edit fields</Heading>
          <span onClick={onBackdropClick}>{icons.close}</span>
        </HeaderModal>
        <ModalText>First 4 fields will be shown on asset preview</ModalText>
        <PaddingBox>
          <ContentScroll
            id="scrollRef"
            ref={scrollRef}
            isScroll={data.length > 8}
          >
            <Content>
              <DragDropContext onDragEnd={onDragEnd(data)}>
                <Droppable
                  droppableId={`dropable-container`}
                  key={`dropable-container`}
                >
                  {provided => (
                    <div
                      style={{ width: "100%" }}
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      {renderFields()}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </Content>
          </ContentScroll>
        </PaddingBox>
        <FoooterModal>
          <Btn hide onClick={onAbort}>
            cancel
          </Btn>
          <Btn transparent margin="0 12px 0 auto" onClick={onCreateNewField}>
            New field
          </Btn>
          <Btn width={180} onClick={onSave}>
            Save
          </Btn>
        </FoooterModal>
      </Wrapper>
    </Background>,
    document.getElementById("round-modal")
  );
};

const Field = ({ name, onRemove, onRename, i, isNew = false }) => {
  const [edit, setEdit] = useState(false);
  const [val, setVal] = useState(name);
  const inputRef = useRef(null);

  useEffect(() => {
    if (edit) {
      inputRef.current.focus();
    }
  }, [edit]);

  const onSaveNewName = () => {
    onRename({ val, i });
    setEdit(!edit);
  };

  const onRemoveField = () => {
    onRemove(i);
  };

  useEffect(() => {
    if (isNew) setEdit(true);
  }, []);

  return (
    <Draggable key={`${name}-${i}`} draggableId={i + ""} index={i}>
      {provided => (
        <EditableBlock
          edit={edit}
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          key={`${name}-${i}`}
        >
          <Hidden />
          <input
            ref={inputRef}
            value={val}
            onChange={e => setVal(e.target.value)}
            disabled={!edit}
            onBlur={onSaveNewName}
          />
          <Icons>
            {edit ? (
              <IconContainer onClick={onSaveNewName}>
                {icons.check}
              </IconContainer>
            ) : (
              <IconContainer onClick={() => setEdit(!edit)}>
                {icons.edit}
              </IconContainer>
            )}
            <IconContainer onClick={onRemoveField}>
              {icons.remove}
            </IconContainer>
          </Icons>
        </EditableBlock>
      )}
    </Draggable>
  );
};
