import React from "react";
import ReactDOM from "react-dom";

import styled from "styled-components";
import { Loader } from "./loader";

import {
  border4,
  borderBox,
  shadow,
  fullVwVh,
  flexCenter
} from "components/common/mixins";

const Background = styled.section`
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(5px);
  display: flex;
  z-index: 1001;
  ${fullVwVh};
  ${flexCenter};
`;

const Wrapper = styled.div`
  width: ${({ width = 410 }) => width}px;
  min-height: 100px;
  max-width: 800px;
  background: white;

  padding: ${({ padding }) => (padding ? padding : "20px")};

  ${border4};
  ${borderBox};
  ${shadow};
`;

export const ModalWrapper = ({
  children,
  onBackgroundClick,
  width = 410,
  padding
}) => {
  return ReactDOM.createPortal(
    <Background bg="#fff" onClick={onBackgroundClick}>
      <Wrapper
        width={width}
        onClick={e => e.stopPropagation()}
        padding={padding}
      >
        {children}
      </Wrapper>
    </Background>,
    document.getElementById("globalModal500Error")
  );
};

export const ModalLoader = () => {
  return ReactDOM.createPortal(
    <Background bg="#fff">
      <Loader />
    </Background>,
    document.getElementById("globalModal500Error")
  );
};
