import React, { Fragment, useState, useEffect } from "react";
import styled from "styled-components";
import { connect } from "react-redux";

//components
import { HeaderRow, Footer, Statistic, General, Actions, Section } from "./components";
import { ConfirmMessagePortal, ErrorMessagePortal } from "components/admin-layout/errorMessage.js";
import Api from "../CreateKycForm/api";
import { CircularProgress } from "@material-ui/core";
import { countFields } from "./helpers";

const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ w = "calc(100% - 353px)" }) => w};
  height: fit-content;
  box-sizing: border-box;
`;

const ViewKycForm = ({
  token,
  history,
  match: {
    params: { id },
  },
}) => {
  const [load, setLoad] = useState(true);
  const [form, setForm] = useState(null);
  const [originalForm, setOriginal] = useState(null);
  const [rightAnswers, setRightAnswers] = useState(null);
  const [confirm, setConfirm] = useState(false);
  const [wait, setWait] = useState(false);
  const [error, setError] = useState(null);
  const [noTemplate, setNoTemplate] = useState(null);

  useEffect(() => {
    Api.getForm(token, id)
      .then((res) => {
        const { jsonKYCForm, rightAnswers } = res;

        setRightAnswers(rightAnswers);
        setForm(JSON.parse(jsonKYCForm));
        setOriginal(JSON.parse(jsonKYCForm));
        setLoad(false);
      })
      .catch((e) => {
        setNoTemplate(e);
        setLoad(false);
      });
  }, []);

  const onError = (e = null) => () => setError(e);

  const onChangeConfirm = () => {
    setConfirm(!confirm);
  };

  const onChangeRequred = (obj) => {
    console.log(obj);
    const { id, _required, sectionNumber } = obj;
    const _form = { ...form };
    const index = _form.sections[sectionNumber].fields.findIndex((f) => f.id === id);
    _form.sections[sectionNumber].fields[index].required = !_required;
    setForm(_form);
  };

  const onChangeVisibility = (obj) => {
    console.log(obj);
    const { id, _visibility, sectionNumber } = obj;
    const _form = { ...form };
    const index = _form.sections[sectionNumber].fields.findIndex((f) => f.id === id);
    _form.sections[sectionNumber].fields[index].visibility = !_visibility;
    setForm(_form);
  };

  const renderSections = () => {
    return form.sections.map((s, i) => (
      <Section i={i} section={s} key={s.id} onChangeRequred={onChangeRequred} onChangeVisibility={onChangeVisibility} />
    ));
  };

  const onSubmit = async () => {
    setWait(true);
    const data = { rightAnswers, template: JSON.stringify(form) };
    const res = await Api.updateForm(token, id, data).catch((e) => {
      setWait(false);
      onError(e)();
    });
    if (res) history.push("/admin/kyc/form-settings/forms");
  };

  const onRemove = async () => {
    setWait(true);
    const rem = await Api.removeForm(token, id).catch((e) => {
      setWait(false);
      onError(e)();
    });
    if (rem) history.push("/admin/kyc/form-settings/forms");
  };

  const modifided = JSON.stringify(form) !== JSON.stringify(originalForm);

  if (load) {
    return <CircularProgress style={{ margin: "auto" }} />;
  } else if (noTemplate) {
    return <ErrorMessagePortal er={noTemplate} onClose={() => history.push("/admin/kyc/form-settings/forms")} />;
  } else {
    return (
      <Fragment>
        <HeaderRow name={form.templateName} history={history} modifided={modifided} />
        <Row>
          <Column>
            {renderSections()}
            <Footer disabled={!modifided || wait} onSubmit={onSubmit} />
          </Column>
          <Column w="323px">
            <Actions history={history} id={id} modifided={modifided} onRemove={onChangeConfirm} />
            <General name={form.templateName} />
            <Statistic sections={form.sections.length} fields={countFields(form.sections)} />
          </Column>
        </Row>

        {confirm ? (
          <ConfirmMessagePortal
            heading="Remove template"
            message="Are you sure to remove this template?"
            onClose={onChangeConfirm}
            onSubmit={onRemove}
          />
        ) : null}
        {error && <ErrorMessagePortal er={error} onClose={onError(null)} />}
      </Fragment>
    );
  }
};

export default connect(({ userReducer: { token } }) => ({ token }))(ViewKycForm);
