import { createAction } from "redux-act";
import moment from "moment";

import Api from "../../helpers/api";
import EventApi from "components/common/calendar/api";
import { isStateUpdate } from "../../helpers/isStateUpdate";
// Если нужно будет сделать переход куда то прямо в action
// history.push('адрес')
// import history from '../history';

export const setWaitData = createAction("SET_WAIT_DATA");
export const setAssets = createAction("SET_ASSETS");
export const setSoldAsset = createAction("SET_SOLD_ACTION");
export const setTrades = createAction("SET_TRADES");
export const setSoldTrade = createAction("SET_SOLD_TRADE");
export const setAssetInfo = createAction("SET_ASSET_INFO");
export const setOrderHistory = createAction("SET_ORDER_HISTORY");
export const setRedirectInfo = createAction("SET_REDIRECT_INFO");
export const setKycInfo = createAction("SET_KYC_INFO");
export const updateAssetButton = createAction("UPDATE_ASSET_ACTION_BUTTON");
export const updateDealRaised = createAction("UPDATE_DEAL_RAISED_VS_TOBERAISED");
export const updateAssetActionButton = createAction("UPDATE_ASSET_ACTION_BUTTON_AND_INTENTION_TO_BUY");

export const setSignData = createAction("SET_SIGN_DATA");
export const setInvestData = createAction("SET INVEST DATA");
export const setWallet = createAction("SET WALLET");
export const setDocuments = createAction("SET DOCUMENTS");

export const setQuestionAnswer = createAction("SET_QUESTION_AND_ANSWER");
export const setQuestionAnswerAsset = createAction("SET_QUESTION_AND_ANSWER_ASSET");

export const setQuestionAnswerInv = createAction("SET_QUESTION_AND_ANSWER_INVESTOR");
export const setQuestionAnswerAssetInv = createAction("SET_INVESTOR_QUESTION_AND_ANSWER_ASSET");
export const setEvents = createAction("SET_EVENTS");
export const setEventsCount = createAction("SET_TODAY_EVENTS_COUNT");
export const setAMSettings = createAction("SET_AM_SETTINGS");
export const setAdminNotifications = createAction("SET_ADMIN_NOTIFICATIONS");

export const setQACount = createAction("SET_QA_COUNT");
export const setSponsorDetails = createAction("SET_SPONSOR_DETAILS");

export const getAssets = (token, cb = false) => async (dispatch) => {
  dispatch(setWaitData(true));
  const assets = await Api.getAssets(token);
  dispatch(setWaitData(false));

  dispatch(
    setAssets(
      Object.keys(assets).reduce((allAssets, type) => {
        return [
          ...allAssets,
          ...assets[type].map((asset) => {
            return {
              ...asset,
              type,
            };
          }),
        ];
      }, [])
    )
  );

  if (cb) {
    cb();
  }
};

export const getTrades = (token) => async (dispatch) => {
  dispatch(setWaitData(true));
  const trades = await Api.getTrades(token);
  trades.forEach((e) => (e.isTrade = true));
  dispatch(setWaitData(false));
  dispatch(setTrades(trades));
};

export const getAllDeals = (token, cb) => async (dispatch) => {
  const trades = await Api.getTrades(token);
  const assets = await Api.getAssets(token);
  trades.forEach((e) => (e.isTrade = true));

  dispatch(setTrades(trades));
  dispatch(
    setAssets(
      Object.keys(assets).reduce((allAssets, type) => {
        return [
          ...allAssets,
          ...assets[type].map((asset) => {
            return {
              ...asset,
              type,
            };
          }),
        ];
      }, [])
    )
  );

  cb && cb();
};

export const getSoldAsset = (data) => async (dispatch) => {
  dispatch(setSoldAsset(data));
};

export const getSoldTrade = (data) => async (dispatch) => {
  dispatch(setSoldTrade(data));
};

export const getAssetInfo = (id) => async (dispatch, store) => {
  try {
    dispatch(setAssetInfo(store().dataReducer.assets.find((e) => e.linearId === id)));
  } catch (error) {}
};

export const getOrderHistory = (token) => async (dispatch) => {
  dispatch(setWaitData(true));
  const orderHistory = await Api.getOrderHistory(token);
  dispatch(setWaitData(false));
  dispatch(setOrderHistory(orderHistory));
};

export const getAssetActionButton = (id, token) => async (dispatch) => {
  const asset = await Api.getAssetInvestor(token, id);
  if (asset) {
    dispatch(
      updateAssetActionButton({
        linearId: id,
        dealWorkflowActionButtonText: asset.dealWorkflowActionButtonText,
        intentionToBuy: asset.intentionToBuy,
      })
    );
  }
};

export const onSetSignData = (data, cb = false) => async (dispatch) => {
  await dispatch(setSignData(data));
  cb && cb();
};

export const onSetInvestData = (actionType, cb = false) => async (dispatch) => {
  await dispatch(setInvestData(actionType));
  cb && cb();
};

export const getWallet = (token, currency = "") => async (dispatch, getStrore) => {
  try {
    if (!currency) {
      currency = getStrore().dataReducer.balance.InvestmentOverview.investmentTotalOverview.currencyCode;
    }
  } catch (_) {
  } finally {
    const wallet = await Api.getWallet(token, currency);
    if (isStateUpdate("dataReducer", "balance", wallet)) dispatch(setWallet(wallet));
  }
};

export const getDocuments = (token, errorCb = console.log) => async (dispatch) => {
  const docs = await Api.getInvestorDocs(token).catch(errorCb);
  if (docs) {
    if (isStateUpdate("dataReducer", "documents", docs)) {
      dispatch(setDocuments(docs));
    }
  }
};

export const getQuestionAnswer = (token, errorCb = console.log) => async (dispatch) => {
  const view = await Api.getQuestionAnswer(token).catch(errorCb);
  if (view) {
    if (isStateUpdate("dataReducer", "viewQA", view)) {
      dispatch(setQuestionAnswer(view));
    }
  }
};

export const getQuestionAsset = (token, assetId, errorCb = console.log, ready = console.log) => async (dispatch) => {
  const data = await Api.getQuestionAssetAll(token, assetId).catch(errorCb);
  const categories = await Api.getQuestionCategories(token, assetId).catch(errorCb);
  if (data && categories) {
    const info = { data, categories };

    dispatch(setQuestionAnswerAsset({ assetId, info }));
    ready();
  }
};

export const getQuestionAnswerInv = (token, errorCb = console.log) => async (dispatch) => {
  const view = await Api.getQuestionAnswerInv(token).catch(errorCb);
  if (view) {
    if (isStateUpdate("dataReducer", "viewQAInv", view)) {
      dispatch(setQuestionAnswerInv(view));
    }
  }
};

export const getQuestionAssetInv = (token, assetId, errorCb = console.log, ready = console.log) => async (dispatch) => {
  const data = await Api.getQuestionAssetAllInv(token, assetId).catch(errorCb);
  const categories = await Api.getQuestionCategoriesInv(token, assetId).catch(errorCb);
  if (data && categories) {
    const info = { data, categories };

    dispatch(setQuestionAnswerAssetInv({ assetId, info }));
    ready();
  }
};

export const getEvents = (token) => async (dispatch) => {
  const res = await EventApi.getEventsNotification(token).catch(console.log);
  if (res) dispatch(setEvents(res.sort((a, b) => +moment(a.dateTime).format("x") - +moment(b.dateTime).format("x"))));
};

export const getSettings = (token) => async (dispatch) => {
  const res = await Api.getAMSettings(token).catch(console.log);
  if (res) dispatch(setAMSettings(res));
};

export const updateAdminNotifications = (data, field = "") => async (dispatch) => {
  dispatch(setAdminNotifications({ data, field }));
};

export const getSponsorDetails = (token, userName) => async (dispatch) => {
  const sponsor = await Api.getAccountDetails(token, userName);
  if (sponsor) dispatch(setSponsorDetails({ sponsorName: userName, data: sponsor.userData }));
};
