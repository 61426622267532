import React, { useState, useEffect, Fragment, useRef } from "react";
import { connect } from "react-redux";
import moment from "moment";

import Api from "../../../../helpers/api";
import { getAssets } from "../../../../modules/dataRedux/dataAction";

import { Header, Main, TextSub } from "../DealForm/Step/styled";
import { ConfirmMessage } from "../../actionMessage";
import { Btn } from "../../buttons";
import { ReactComponent as ArrowLeft } from "../../../../assets/arrowleft.svg";
import { ReactComponent as SharesIcon } from "../../../../assets/briefcase.svg";
import { ReactComponent as MessageIcon } from "../../../../assets/message-square.svg";
import { ReactComponent as UploadIcon } from "../../../../assets/upload.svg";
import { ReactComponent as LoginIcon } from "../../../../assets/log-in.svg";
import { ReactComponent as ResetIcon } from "../../../../assets/lock.svg";
import { ReactComponent as SuspendIcon } from "../../../../assets/slash.svg";
import { ReactComponent as Down } from "../../../../assets/chevron-down.svg";

import { Wrapper, Column, Separator } from "../CreateUser/styled";
import { TransactionsHeader, ScrollContainer, HeaderTD, TransactionsTD } from "../../dealTypeCard";
import { VeriFyStatus } from "../UserList/styled";
import { TxInfo } from "../DashBoard/styled";

import { SendEmail } from "../UserList/SendEmail";
import { AddShares } from "../UserList/AddShares";
import { ErrorMessage, SuccessMessage } from "../../errorMessage";
import { Documents } from "./documents";
import { UploadedDocuments } from "./uploadedDocuments";

import {
  InfoCol,
  FlexRow,
  AvatarBlock,
  GroupAvatarBlock,
  Amount,
  Total,
  InvestedItem,
  FullBg,
  GroupBlock,
  AnswerWrap,
  FileHolder,
} from "./styled";
import { numberWithCommas, formatCount } from "../../../../helpers";
import { CircularProgress } from "@material-ui/core";

const DynamicSection = ({ token, section, SC, CC }) => {
  const [openMore, setOpenMore] = useState(true);
  const [height, setHeight] = useState(0);
  const showRef = useRef(null);

  const onShowMore = () => {
    setOpenMore(!openMore);
  };

  useEffect(() => {
    if (showRef && showRef.current) {
      setHeight(showRef.current.offsetHeight);
      setOpenMore(false);
    }
  }, []);

  const getTotalFiles = (keys, obj) => {
    let total = 0;
    keys.forEach((key) => {
      if (obj[key]) {
        obj[key].forEach((file) => {
          if (file.fileName && file.uuid) {
            total += 1;
          }
        });
      }
    });
    return total;
  };

  if (SC && Object.keys(SC).length) {
    const keys = ["usBornProve"];
    if (getTotalFiles(keys, SC)) {
      return (
        <Fragment>
          <Separator />
          <Main key={`key-self-certification`}>
            <TextSub textAlign="left" fontWeight="bold" color="#181818" marginSize="0 0 20px 0">
              Self Certification Attachments
            </TextSub>
            <AnswerWrap
              ref={showRef}
              height={
                getTotalFiles(keys, SC) > 3
                  ? openMore && !height
                    ? "fit-content"
                    : openMore && height
                    ? `${height}px`
                    : "159px"
                  : "fit-content"
              }
              mBottom={getTotalFiles(keys, SC) > 3 ? "" : "0px"}
              mb="18px"
            >
              {keys.map((key) => {
                if (SC[key] && SC[key].length) {
                  return SC[key].map((file, i) => {
                    return file.fileName && file.uuid ? (
                      <FileHolder key={`key-${file.fileName}-${i}`} file={file} token={token} />
                    ) : null;
                  });
                }
              })}
            </AnswerWrap>
            {getTotalFiles(keys, SC) > 3 ? (
              <Btn transparent width={273} onClick={onShowMore} grey={openMore} rightIcon transform={openMore}>
                {openMore ? "hide" : "show more"}
                <Down />
              </Btn>
            ) : null}
          </Main>
        </Fragment>
      );
    } else return null;
  } else if (CC && Object.keys(CC).length) {
    const keys = [
      //professional
      "aGovernmentFiles",
      "anEntityFiles",
      "anotherInstitutionalInvestorFiles",
      "balanceOf20millionFiles",
      "netTurnoverOfEUR40MillionFiles",
      "ownFundsOfEUR2Million",
      //elective
      "relevantActivityStatementFiles",
      "financialStatementFiles",
      "cvFiles",
    ];
    if (getTotalFiles(keys, CC)) {
      return (
        <Fragment>
          <Separator />
          <Main key={`key-client-certification`}>
            <TextSub textAlign="left" fontWeight="bold" color="#181818" marginSize="0 0 20px 0">
              Client Certification Attachments
            </TextSub>
            <AnswerWrap
              ref={showRef}
              height={
                getTotalFiles(keys, CC) > 3
                  ? openMore && !height
                    ? "fit-content"
                    : openMore && height
                    ? `${height}px`
                    : "159px"
                  : "fit-content"
              }
              mBottom={getTotalFiles(keys, CC) > 3 ? "" : "0px"}
              mb="18px"
            >
              {keys.map((key) => {
                if (CC[key] && CC[key].length) {
                  return CC[key].map((file, i) => {
                    return file.fileName && file.uuid ? (
                      <FileHolder key={`key-${file.fileName}-${i}`} file={file} token={token} />
                    ) : null;
                  });
                }
              })}
            </AnswerWrap>
            {getTotalFiles(keys, CC) > 3 ? (
              <Btn transparent width={273} onClick={onShowMore} grey={openMore} rightIcon transform={openMore}>
                {openMore ? "hide" : "show more"}
                <Down />
              </Btn>
            ) : null}
          </Main>
        </Fragment>
      );
    } else return null;
  } else if (Object.keys(section).length) {
    const { sectionName, fields } = section;
    return (
      <Fragment>
        <Separator />
        <Main key={`key-${sectionName}`}>
          <TextSub textAlign="left" fontWeight="bold" color="#181818" marginSize="0 0 20px 0">
            {sectionName}
          </TextSub>
          <AnswerWrap
            ref={showRef}
            height={openMore && !height ? "fit-content" : openMore && height ? `${height}px` : "159px"}
          >
            {fields.map(({ name, value }, i) => {
              if (value) {
                return (
                  <InfoCol
                    key={`key-${name}-${i}`}
                    heading={name}
                    info={Array.isArray(value) ? value.join(", ") : value}
                  />
                );
              } else return null;
            })}
          </AnswerWrap>
          <Btn transparent width={273} onClick={onShowMore} grey={openMore} rightIcon transform={openMore}>
            {openMore ? "hide" : "show more"}
            <Down />
          </Btn>
        </Main>
      </Fragment>
    );
  } else return null;
};

const UserInfo = ({
  history,
  token,
  assets,
  getAssets,
  match: {
    params: { userName, id, groupId },
  },
}) => {
  const [userInfo, setUserInfo] = useState(null);
  const [transactions, setTransactions] = useState(null);
  const [assetsList, setAssetsList] = useState([]);
  const [sendEmailOpen, setSendEmailOpen] = useState(false);
  // const [addMoneyOpen, setAddMoneyOpen] = useState(false);
  const [addSharesOpen, setAddSharesOpen] = useState(false);
  const [possibleCurrencies, setCurrencies] = useState([]);
  const [investedIn, setInvestedIn] = useState([]);
  const [quesForm, setQuesForm] = useState(null);
  const [scAnswer, setScAnswer] = useState(null);
  const [ccAnswer, setCcAnswer] = useState(null);
  const [load, setLoad] = useState(true);
  const [wait, setWait] = useState(false);
  const [errorUpload, setErrorUpload] = useState(null);
  const [success, setSuccess] = useState("");
  const [groups, setGroups] = useState([]);
  const [totalInvested, setTotalInvested] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [uploadedDocs, setUploadedDocs] = useState(null);

  const onChangeConfirm = () => {
    setConfirmOpen(!confirmOpen);
  };

  const onRemoveInvestor = async () => {
    setWait(true);
    const del = await Api.removeInvestor(token, userInfo.id);
    if (del) {
      setWait(false);
      onChangeConfirm();
      history.push("/admin/users");
    }
  };

  const openEmailModal = () => setSendEmailOpen(!sendEmailOpen);
  // const openMoneyModal = () => setAddMoneyOpen(!addMoneyOpen);
  const openSharesModal = () => setAddSharesOpen(!addSharesOpen);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    if (!assets.length) await getAssets(token);
    if (!userInfo) {
      const user = await Api.getUserMoreInfo(token, userName);
      if (user) {
        const { roleName, investorType, id } = user;
        if (roleName === "INSTITUTION" || investorType === "INSTITUTION") {
          history.push(`/admin/users/institution/${id}`);
          return;
        }
      }
      Promise.all([
        Api.getUsersTransactions(token, userName),
        Api.getUserMoreInfo(token, userName),
        Api.getPossibleCurrencies(token),
        Api.getAssetsList(token),
        Api.getUserInvestedIn(token, userName),
        Api.getGroupByUserId(token, id),
        Api.getQuestionnaireAnswers(token, id),
        Api.getSCAnswers(token, id),
        Api.getCCAnswers(token, id),
      ])
        .then((jsons) => {
          setTransactions(jsons[0]);
          setUserInfo(jsons[1]);
          setCurrencies(jsons[2]);
          setAssetsList(jsons[3]);
          setInvestedIn(jsons[4]);
          getTotalInvested(jsons[4]);
          setGroups(jsons[5]);
          let form;
          try {
            form = JSON.parse(jsons[6].answerJson);
          } catch (_) {
            form = null;
          }
          setQuesForm(form);
          let SCform;
          try {
            SCform = JSON.parse(jsons[7].answerJson);
          } catch (_) {
            SCform = null;
          }
          setScAnswer(SCform);
          let CCform;
          try {
            if (jsons[8].formType === "PROFESSIONAL_CLIENT_CC_FORM") {
              CCform = JSON.parse(jsons[8].answerPFJson);
            } else CCform = JSON.parse(jsons[8].answerEPFJson);
          } catch (_) {
            CCform = null;
          }
          setCcAnswer(CCform);
        })
        .then(() => setLoad(false));
    }
  };

  const getAssetExtraInfo = (name) => {
    const asset = assets.find((e) => e.assetProperties.assetName === name);
    if (asset)
      return {
        assetSymbol: asset.assetProperties.assetSymbol,
        logo: asset.assetProperties.images.length ? asset.assetProperties.images[0] : null,
      };
    else return {};
  };

  const renderTransactions = (arr) => {
    if (arr && arr.length) {
      return (
        <Fragment>
          <TransactionsHeader translate={-15}>
            <HeaderTD padding="0 0 0 15px" boxSizing="border-box" width="calc(50% - 192px)">
              action
            </HeaderTD>
            <HeaderTD boxSizing="border-box" width="calc(50% - 192px)">
              Deal
            </HeaderTD>
            <HeaderTD boxSizing="border-box" width="115px">
              Amount
            </HeaderTD>
            <HeaderTD boxSizing="border-box" width="115px">
              Shares
            </HeaderTD>
            <HeaderTD boxSizing="border-box" width="115px">
              Date
            </HeaderTD>
            <HeaderTD width="39px" boxSizing="border-box"></HeaderTD>
          </TransactionsHeader>
          <ScrollContainer pr="7px">
            {arr.map((e, i) => {
              const { action, time, quantity, movementType, assetNameMovementFor, soldShares, tokenIdentifier } = e;
              return (
                <TransactionsHeader grey={i % 2 === 0} key={`${action}-${i}`}>
                  <TransactionsTD
                    padding="0 5px 0 15px"
                    boxSizing="border-box"
                    width="calc(50% - 192px)"
                    lower
                    fWeight="bold"
                  >
                    {action}
                  </TransactionsTD>
                  <TransactionsTD padding="0 5px 0 0" boxSizing="border-box" width="calc(50% - 192px)" lower>
                    {assetNameMovementFor}
                  </TransactionsTD>
                  <TransactionsTD padding="0 5px 0 0" boxSizing="border-box" width="115px">
                    <Amount movementType={movementType} quantity={quantity} tokenIdentifier={tokenIdentifier} />
                  </TransactionsTD>
                  <TransactionsTD padding="0 5px 0 0" boxSizing="border-box" width="115px">
                    {soldShares && `${soldShares.toFixed(2)} %`}
                  </TransactionsTD>
                  <TransactionsTD padding="0" boxSizing="border-box" width="115px" fWeight="bold">
                    {moment(time).format("DD/MM/YYYY")}
                  </TransactionsTD>
                  <TransactionsTD padding="0" width="39px" boxSizing="border-box">
                    <TxInfo
                      tx={e}
                      shareName={getAssetExtraInfo(assetNameMovementFor).assetSymbol}
                      image={getAssetExtraInfo(assetNameMovementFor).logo}
                      token={token}
                    />
                  </TransactionsTD>
                </TransactionsHeader>
              );
            })}
          </ScrollContainer>
        </Fragment>
      );
    }
  };

  const renderAssets = (arr) => {
    return arr.map((asset) => <InvestedItem key={`${asset.assetName}-${asset.date}`} asset={asset} token={token} />);
  };

  const getTotalInvested = async (arr) => {
    if (arr.length) {
      let total = 0;
      const baseCurrency = arr[0].currency;

      for (const { invested, currency } of arr) {
        if (currency === baseCurrency) {
          total += invested;
        } else {
          const res = await Api.exchangeCurrency(token, baseCurrency, [
            {
              quantity: invested,
              currency,
            },
          ]);
          if (res) {
            total += res;
          }
        }
      }
      setTotalInvested(total);
    }
  };

  const renderAnswers = () => {
    return quesForm.reverse().map((section) => {
      return <DynamicSection section={section} key={`section-${section.sectionName}`} />;
    });
  };

  if (load) {
    return <CircularProgress style={{ margin: "auto" }} />;
  } else {
    return (
      <Fragment>
        {/* header */}
        <Header>
          {groupId ? (
            <Btn transparent onClick={() => history.push(`/admin/users/group/${groupId}`)} width={150}>
              <ArrowLeft /> back to group
            </Btn>
          ) : (
            <Btn transparent onClick={() => history.goBack()} width={150}>
              <ArrowLeft /> back
            </Btn>
          )}
          <Btn margin="0 12px 0 auto" width={140} transparent onClick={openSharesModal}>
            <SharesIcon /> Add shares
          </Btn>
          {/* <Btn
            margin="0"
            width={140}
            transparent
            onClick={openMoneyModal}
            disabled
          >
            <MoneyIcon /> Add Money
          </Btn> */}
        </Header>
        {/* columns */}
        <Wrapper>
          <Column width="calc(100% - 353px)">
            <AvatarBlock
              token={token}
              file={userInfo.userLogoFileName}
              hash={userInfo.userLogoHash}
              gender={userInfo.gender}
            />
            <Main padding="56px 25px 25px">
              <TextSub textAlign="left" fontWeight="bold" color="#181818" fSize={20} marginSize="0 0 20px 0">
                {userInfo.fullName}
              </TextSub>
              <FlexRow>
                <InfoCol width={33} heading="Country of residence" info={userInfo.countryOfResidence} />
                <InfoCol width={33} heading="type" info={userInfo.roleName} />
                <InfoCol width={33} email={true} heading="e-mail" info={userInfo.username} />
              </FlexRow>
            </Main>
            <Separator />
            <Main>
              <TextSub textAlign="left" fontWeight="bold" color="#181818" marginSize="0 0 20px 0">
                More Information
              </TextSub>
              <FlexRow>
                <InfoCol
                  width={33}
                  heading="Joining date"
                  info={
                    userInfo.creationDate ? moment(new Date(userInfo.creationDate)).format("DD MMM YYYY hh:mm a") : ""
                  }
                />
                <InfoCol
                  width={33}
                  heading="Last login"
                  info={
                    userInfo.lastLoginDate ? moment(new Date(userInfo.lastLoginDate)).format("DD MMM YYYY hh:mm a") : ""
                  }
                />
                <InfoCol width={33} />
              </FlexRow>
            </Main>
            <Separator />

            {investedIn.length ? (
              <Fragment>
                <Main>
                  <TextSub textAlign="left" fontWeight="bold" color="#181818" marginSize="0 0 25px 0">
                    Invested in
                  </TextSub>
                  <Total>
                    Total Invested: {totalInvested ? formatCount(totalInvested) : null} {investedIn[0].currency}
                  </Total>
                  <TransactionsHeader translate={0}>
                    <HeaderTD boxSizing="border-box" width="calc(100% - 355px)" translate={0} padding="0 25px 0 0">
                      Asset
                    </HeaderTD>
                    <HeaderTD boxSizing="border-box" width="150px" padding="0 15px 0 10px">
                      Invested
                    </HeaderTD>
                    <HeaderTD boxSizing="border-box" padding="0 15px 0 10px" width="150px">
                      Date & Time
                    </HeaderTD>
                    <HeaderTD width="55px" boxSizing="border-box"></HeaderTD>
                  </TransactionsHeader>
                  {renderAssets(investedIn)}
                </Main>
                <Separator />
              </Fragment>
            ) : null}

            {userInfo.roleName !== "ADMIN" && userInfo.roleName !== "AM" ? (
              <Documents token={token} userId={id} onGetUploaded={setUploadedDocs} />
            ) : null}

            {transactions.length ? (
              <Main>
                <TextSub textAlign="left" fontWeight="bold" color="#181818" marginSize="0 0 25px 0">
                  Transactions
                </TextSub>
                {renderTransactions(transactions)}
              </Main>
            ) : null}
          </Column>

          <Column width="323px">
            <Main>
              <TextSub textAlign="left" fontWeight="bold" color="#181818" marginSize="0 0 25px 0">
                Status
              </TextSub>
              <InfoCol
                heading="portfolio"
                info={userInfo.shares !== 0 && `${numberWithCommas(userInfo.shares.toFixed(2))} GBP`}
                bold
              />
              <Separator height={23} />
              <InfoCol
                heading="Verified Status"
                info={<VeriFyStatus enabled={userInfo.enabled} kyced={userInfo.kyced} left />}
              />
            </Main>
            <Separator />
            <Main>
              <Btn margin="0 0 12px 0" fullWidth onClick={openEmailModal}>
                <MessageIcon /> Send E-mail
              </Btn>
              <Btn
                margin="0 0 12px 0"
                fullWidth
                transparent
                onClick={() => history.push(`/admin/send-report-user/${userName}`)}
                disabled={wait}
              >
                <UploadIcon /> Send Report Update
              </Btn>
              <Btn margin="0 0 12px 0" fullWidth transparent>
                <LoginIcon /> Login data
              </Btn>
              <Btn margin="0 0 12px 0" fullWidth transparent>
                <ResetIcon /> Reset password
              </Btn>
              <Btn error fullWidth onClick={onChangeConfirm} disabled={userInfo.enabled}>
                <SuspendIcon /> Suspend
              </Btn>
            </Main>
            {groups.length ? (
              <Fragment>
                <Separator />
                <Main>
                  <TextSub textAlign="left" fontWeight="bold" color="#181818" marginSize="0 0 25px 0">
                    Groups
                  </TextSub>
                  <div>
                    {groups.map((group) => {
                      return (
                        <GroupBlock>
                          <GroupAvatarBlock token={token} file={group.logoFileName} hash={group.logoHash} />
                          <span>{group.name}</span>
                        </GroupBlock>
                      );
                    })}
                  </div>
                </Main>
              </Fragment>
            ) : null}
            {quesForm ? renderAnswers() : null}
            {/* extra for SC */}
            {scAnswer && scAnswer.usBornProve ? <DynamicSection SC={scAnswer} token={token} /> : null}
            {/* extra for CC */}
            {ccAnswer ? <DynamicSection CC={ccAnswer} token={token} /> : null}
            {uploadedDocs && <UploadedDocuments data={uploadedDocs} token={token} userId={id} />}
          </Column>
        </Wrapper>
        {/* modals */}
        <SendEmail
          isOpen={sendEmailOpen}
          onBackdropClick={openEmailModal}
          token={token}
          singleUser={true}
          mailData={{
            userName: userInfo && userInfo.fullName,
            userEmail: userName,
          }}
        />
        {/* <AddMoney
          isOpen={addMoneyOpen}
          singleUser={userName}
          token={token}
          onBackdropClick={openMoneyModal}
          currencies={possibleCurrencies}
        /> */}
        <AddShares
          assets={assetsList}
          isOpen={addSharesOpen}
          onBackdropClick={openSharesModal}
          token={token}
          singleUser={userName}
        />

        {errorUpload ? <ErrorMessage er={errorUpload} onClose={() => setErrorUpload(null)} /> : null}

        {success ? <SuccessMessage message={success} onClose={() => setSuccess("")} /> : null}

        {wait ? (
          <FullBg>
            <CircularProgress />
          </FullBg>
        ) : null}

        <ConfirmMessage
          heading="Delete investor"
          isOpen={confirmOpen}
          onBackDpopClick={onChangeConfirm}
          message={`Are you sure to delete <b>${userInfo.username}</b> ?`}
          onAction={onRemoveInvestor}
          mHeight="fit-content"
          contentHeight="140px"
        />
      </Fragment>
    );
  }
};

export default connect(
  ({ userReducer: { token }, dataReducer: { assets = [] } }) => ({
    token,
    assets,
  }),
  { getAssets }
)(UserInfo);
