import styled from "styled-components";
import React, { useState, Fragment, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { CircularProgress } from "@material-ui/core";

import { ReactComponent as CloseIcon } from "../../../../assets/x-circle.svg";
import { ReactComponent as Plus } from "../../../../assets/plus-circle.svg";
import { ReactComponent as Eye } from "../../../../assets/eye.svg";
import { ReactComponent as EyeOff } from "../../../../assets/eye-off.svg";
import { ReactComponent as Mess } from "../../../../assets/message-square.svg";
import { ReactComponent as Rem } from "../../../../assets/x-red-small.svg";

import { Btn } from "../../buttons";
import { ChartDateComponent, TextAreaComponent } from "../../forms";
import { User } from "../../dealTypeCard";
import { DocuSignDoc } from "../UserKyc/styled";

import Api from "../../../../helpers/api";
import { PortalTooltipWrapper } from "../../portalTooltipWrapper";
import { ConfirmMessage } from "../../actionMessage";
import theme from "models/themes";
import { File } from "components/common/icons-primary";

const ProgressContainer = styled.div`
  height: 10px;
  background: ${({ theme: { primaryLight } }) => primaryLight};
  display: flex;
`;

export const Progress = styled.div`
  height: 10px;
  background: ${({ theme: { primaryMiddle } }) => primaryMiddle};
  width: ${({ width }) => `${width}%`};
  transition: width ${({ isDashBoard }) => (isDashBoard ? "0.9s linear" : "1.5s cubic-bezier(0.22, 0.61, 0.36, 1)")};
`;

export const ProgressBar = ({ sold, unsold, soldSharesPercent = false, isDashBoard = false }) => {
  const [progressWidth, setProgressWidth] = useState(0);

  setTimeout(
    () => {
      if (soldSharesPercent) {
        setProgressWidth(soldSharesPercent);
      } else {
        const w = (sold / (sold + unsold)) * 100;
        setProgressWidth(isNaN(w) ? 0 : w);
      }
    },
    isDashBoard ? 0 : 1000
  );
  return (
    <ProgressContainer>
      <Progress isDashBoard={isDashBoard} width={progressWidth} />
    </ProgressContainer>
  );
};

export const Row = styled.div`
  display: flex;
  margin-bottom: 15px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: ${({ mRight }) => (mRight ? `${mRight}px` : "60px")};
  width: ${({ width }) => width};
  align-items: flex-start;
`;

const UsersColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: ${({ mLeft }) => `${mLeft}px`};
`;

const Heading = styled.div`
  font-family: Muli;
  font-weight: bold;
  font-size: 10px;
  line-height: 135%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  margin-bottom: 4px;
  color: rgba(24, 24, 24, 0.6);
`;

const Data = styled.div`
  font-family: Muli;
  font-weight: bold;
  font-size: 14px;
  line-height: 135%;
  letter-spacing: 0.05em;
  color: #181818;
  display: flex;
  align-items: center;
  width: ${({ width }) => width};
  white-space: nowrap;
`;

const Cirlcle = styled.div`
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: ${({ color }) => color};
  margin-left: 6px;
  margin-right: ${({ mRight }) => (mRight ? `${mRight}px` : "0")};
`;

const ArrowContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 3px;
  > svg {
    transform: ${({ green }) => (green ? "rotate(-90deg)" : "none")};
    path {
      stroke: ${({ green }) => (green ? "#019D52" : "rgb(255, 67, 67)")};
    }
  }
`;

export const DataColumn = ({ mRight, heading, data, circleColor = false, width, childrenWidth }) => {
  return (
    <Column mRight={mRight} width={width}>
      <Heading>{heading}</Heading>
      <Data width={childrenWidth}>
        {data}
        {circleColor && <Cirlcle color={circleColor} />}
      </Data>
    </Column>
  );
};

const Period = styled.div`
  font-family: Muli;
  font-weight: bold;
  font-size: 10px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  cursor: pointer;
  color: ${({ active }) => (active ? "#181818" : "rgba(24, 24, 24, 0.4)")};
`;

const FilterRow = styled.div`
  display: flex;
  ${({ left = false }) => (left ? "margin-right: auto;" : "margin-left: auto;")};

  align-items: center;
  color: rgba(24, 24, 24, 0.4);
  height: fit-content;
  > span {
    margin: 0 3px;
  }
`;

export const Filter = ({ defaultValue = "all", callback = console.log }) => {
  const [active, setActive] = useState(defaultValue);
  const filter = (period) => {
    setActive(period);
    callback(period);
  };
  return (
    <FilterRow>
      <Period active={active === "all"} onClick={() => filter("all")}>
        all
      </Period>
      <span>/</span>
      <Period active={active === "day"} onClick={() => filter("day")}>
        day
      </Period>
      <span>/</span>
      <Period active={active === "week"} onClick={() => filter("week")}>
        week
      </Period>
      <span>/</span>
      <Period active={active === "month"} onClick={() => filter("month")}>
        month
      </Period>
    </FilterRow>
  );
};

const ChartRow = styled.div`
  display: flex;
  align-items: center;
  font-family: Muli;
  font-weight: 600;
  font-size: 10px;
  line-height: 135%;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: #181818;
  margin-bottom: 8px;
  &:last-child {
    margin-bottom: 0;
  }
`;

const ChartPercent = styled.span`
  color: #181818;
  margin-left: 3px;
`;

export const ChartData = ({ sold, unsold }) => {
  return (
    <Fragment>
      <ChartRow>
        <Cirlcle mRight={7} color={theme.primaryLight} />
        Unsold shares
        <ChartPercent>({((unsold / (sold + unsold)) * 100).toFixed(2)}%)</ChartPercent>
      </ChartRow>
      <ChartRow>
        <Cirlcle mRight={7} color={theme.primaryMiddle} />
        Sold shares <ChartPercent>({((sold / (sold + unsold)) * 100).toFixed(2)}%)</ChartPercent>
      </ChartRow>
    </Fragment>
  );
};

export const UsersData = ({ data }) => {
  const { invested, upproved, upstream, study } = data;
  return (
    <Fragment>
      <UsersColumn mLeft={6}>
        <ChartRow>
          <Cirlcle mRight={7} color="#55B17C" />
          Invested
          <ChartPercent>({invested}%)</ChartPercent>
        </ChartRow>
        <ChartRow>
          <Cirlcle mRight={7} color="#3F4B78" />
          Approved
          <ChartPercent>({upproved}%)</ChartPercent>
        </ChartRow>
      </UsersColumn>
      <UsersColumn mLeft={6}>
        <ChartRow>
          <Cirlcle mRight={7} color={theme.primaryLight} />
          Upstream
          <ChartPercent>({upstream}%)</ChartPercent>
        </ChartRow>
        <ChartRow>
          <Cirlcle mRight={7} color={theme.primaryMiddle} />
          Study
          <ChartPercent>({study}%)</ChartPercent>
        </ChartRow>
      </UsersColumn>
    </Fragment>
  );
};

const ActivityHeading = styled.div`
  font-family: Muli;
  font-weight: bold;
  font-size: 10px;
  line-height: 135%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: rgba(24, 24, 24, 0.6);
  margin-bottom: 4px;
`;

const Activity = styled.div`
  font-family: Muli;
  font-weight: bold;
  font-size: 14px;
  line-height: 135%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #181818;
`;

export const ActivityData = ({ data, existed }) => {
  const { visits, logins, avgTimeUser } = data;
  return (
    <Fragment>
      <UsersColumn>
        <ActivityHeading>visits</ActivityHeading>
        <FilterRow left>
          <Activity>{visits || "-"}</Activity>
        </FilterRow>
      </UsersColumn>
      {existed ? null : (
        <UsersColumn mLeft={35}>
          <ActivityHeading>logins</ActivityHeading>
          <FilterRow left>
            <Activity>{logins || "-"}</Activity>
          </FilterRow>
        </UsersColumn>
      )}
      <UsersColumn mLeft={existed ? 50 : 35}>
        <ActivityHeading>AVG. TIME PER USER</ActivityHeading>
        <FilterRow left>
          <Activity>{avgTimeUser !== "0 sec" ? avgTimeUser : "-"}</Activity>
        </FilterRow>
      </UsersColumn>
    </Fragment>
  );
};

const ButtonsRow = styled.div`
  display: flex;
  margin-left: 30px;
  margin-right: auto;
`;

const DatesRow = styled.div`
  display: flex;
  margin-left: auto;
  align-items: center;
  font-family: Muli;
  font-size: 12px;
  line-height: 135%;
  letter-spacing: -0.02em;
  color: #181818;
`;

const Line = styled.div`
  width: 16px;
  height: 1px;
  background: #181818;
  margin: 0 9px;
`;

export const ChartFilter = ({ periods, selected, onChangeZoom }) => {
  return (
    <ButtonsRow>
      {periods.map((el, i) => {
        return (
          <Btn margin="0 6px 0 0" width={80} transparent={i !== selected} key={el} onClick={onChangeZoom(i)}>
            {el}
          </Btn>
        );
      })}
    </ButtonsRow>
  );
};

export const DateFilter = ({ onChangeStart, onChangeEnd }) => {
  return (
    <DatesRow>
      <ChartDateComponent onChange={onChangeStart} />
      <Line />
      <ChartDateComponent onChange={onChangeStart} />
    </DatesRow>
  );
};

export const ScrollContainer = styled.div`
  height: ${({ height }) => (height ? `${height}px` : "300px")};
  padding-right: ${({ isScroll = true }) => (isScroll ? "25px" : "0px")};
  padding-left: ${({ pLeft }) => (pLeft ? `${pLeft}px` : "0")};
  box-sizing: border-box;
  transform: translateX(-15px);
  width: calc(100% + 15px);
  overflow-y: ${({ isScroll = true }) => (isScroll ? "scroll" : "hidden")};
  &&::-webkit-scrollbar-track {
    background-color: ${({ theme: { primaryLight } }) => primaryLight};
    border-radius: 2px;
  }
  &&::-webkit-scrollbar {
    width: 8px;
    border-radius: 2px;
    background-color: ${({ theme: { primaryLight } }) => primaryLight};
  }
  &&::-webkit-scrollbar-thumb {
    background-color: ${({ theme: { primaryMiddle } }) => primaryMiddle};
    border-radius: 2px;
  }
`;

const RequestWrapper = styled.div`
  background: ${({ theme: { adminTableRowGrey } }) => adminTableRowGrey};
  > svg {
    path {
      stroke: ${({ theme: { primary } }) => primary};
    }
  }
  border: 1px solid ${({ answered, theme: { primary } }) => (answered ? primary : "transparent")};
  box-sizing: border-box;
  border-radius: 2px;
  padding: 18px;

  transition: all 0.3s linear;
  margin-bottom: 10px;
`;

const RequestRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${({ noMargin }) => (noMargin ? "0" : "8px")};
`;

const Message = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  margin-bottom: 11px;
  color: #333333;
`;

const DateStyled = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 13px;
  letter-spacing: -0.02em;
  color: rgba(24, 24, 24, 0.4);
`;

const Answer = styled(Link)`
  text-decoration: none;
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 135%;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: ${({ answered, theme: { primary } }) => (answered ? primary : "rgba(24, 24, 24, 0.4)")};
  transition: all 0.3s linear;
  cursor: pointer;
`;

export const Request = ({ token, userName, logo, message, date }) => {
  const [answer, setAnswer] = useState(false);

  return (
    <RequestWrapper answered={answer}>
      <RequestRow>
        <User border token={token} userName={userName} logo={logo} />
        {answer && <Cirlcle color={theme.primary} />}
      </RequestRow>
      <Message>{message}</Message>
      <RequestRow noMargin>
        <DateStyled>{date}</DateStyled>
        <Answer to="/admin/chat">chat</Answer>
      </RequestRow>
    </RequestWrapper>
  );
};

export const CalendarWrapper = styled.div`
  border-bottom: 1px solid #efefef;
  margin-bottom: 21px;

  & > div {
    width: 100% !important;
  }

  & .react-calendar__navigation {
    button.react-calendar__navigation__label {
      pointer-events: none;
    }
  }

  & {
    .react-calendar__month-view__weekdays__weekday,
    .react-calendar__month-view__days button {
      min-width: 14.2857% !important;
      max-width: 14.2857% !important;
      margin-right: 0 !important;
      &.react-calendar__tile--now.react-calendar__tile--active {
        & > * {
          position: absolute;
          z-index: 1;
        }

        &::after {
          content: "";
          position: absolute;
          display: block;
          background: ${({ theme: { primary } }) => primary};
          width: 20px;
          height: 20px;
          border-radius: 50%;
        }
      }
    }
  }

  .react-calendar__month-view__days__day--neighboringMonth * {
    color: #e1e4e7 !important;
    font-family: Muli;
  }
`;

const EventRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  &:last-child {
    margin-bottom: 0;
  }
`;

const EventText = styled.div`
  font-family: Muli;
  font-weight: ${({ fWeight }) => (fWeight ? fWeight : "600")};
  font-size: 10px;
  line-height: 135%;
  letter-spacing: 0.05em;
  margin-right: 8px;
  color: #181818;
`;

export const EventsList = ({ events }) => {
  const renderEvents = () => {
    return events.map((e) => {
      const { type, date } = e;
      return (
        <EventRow key={date}>
          <Cirlcle mRight={7} color={type} />
          <EventText>{date}</EventText>
          <EventText fWeight={400}>Some event</EventText>
        </EventRow>
      );
    });
  };

  return <Fragment>{renderEvents()}</Fragment>;
};

const BorderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 0 25px 15px;
  border-bottom: 1px solid #efefef;
  box-sizing: border-box;
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
`;

const SignedColumn = styled.div`
  width: ${({ width }) => `${width}%`};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`;

const Logo = styled.img`
  width: 62px;
  height: 62px;
  border-radius: 50%;
  margin-bottom: 11px;
  object-fit: cover;
`;

const UseName = styled(Link)`
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  padding-right: 10px;
  padding-bottom: 4px;
  border-bottom: 0.5px solid rgba(98, 128, 218, 0.2);
  width: fit-content;
  box-sizing: border-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  letter-spacing: -0.02em;
  color: ${({ theme: { primary } }) => primary};
  text-decoration: none;
  margin-bottom: 20px;
`;

const LoiSpaBlock = styled.div`
  display: flex;
  justify-content: space-between;
  width: 140px;
`;

const DocStatus = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 135%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: rgba(24, 24, 24, 0.6);
  > div {
    font-size: 12px;
    color: #181818;
    margin-top: ${({ mTop }) => (mTop ? mTop : "9px")};
    text-transform: none;
  }
  > svg {
    margin-top: 8px;
    width: 14px;
    height: 14px;
    path {
      stroke: green;
    }
  }
`;

const NoDoc = styled.div`
  width: 18px;
  height: 1px;
  background: #181818;
  margin-top: 15px;
`;

export const Empty = styled.div`
  width: ${({ w = "100%" }) => w};
  height: ${({ h = "100%" }) => h};
  background: ${({ theme: { emptyCardBg } }) => emptyCardBg};
  margin: ${({ m = 0 }) => m};
  border-radius: 2px;
  box-sizing: border-box;
  font-size: 14px;
  line-height: 135%;
  text-align: center;
  color: #181818;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  flex-direction: column;
  > svg {
    width: 40px;
    height: 40px;
    margin-bottom: 20px;
  }
`;

export const EmptyCircle = styled.div`
  width: 62px;
  height: 62px;
  border-radius: 50%;
  background: ${({ theme: { emptyCardBg } }) => emptyCardBg};
`;

export const SignedDoc = ({ token, doc, newWorkflow, assetId, onError = console.log }) => {
  const { fullname, logo, loiValue, spaSigned, documentAndDate, userId } = doc;

  const [avatar, setAvatar] = useState(null);

  useEffect(() => {
    if (logo && !avatar && logo.fileNameWithExtension && logo.secureHash) {
      Api.getAttachment(token, logo.secureHash, logo.fileNameWithExtension).then((blob) =>
        setAvatar(blob || Api.defaultAvatar)
      );
    } else {
      setAvatar(Api.defaultAvatar);
    }
  }, []);

  const renderNewDocs = () => {
    const _docs = Object.keys(documentAndDate);
    if (_docs.length) {
      return _docs
        .map((name) => ({
          envelopeId: null,
          date: documentAndDate[name],
          documentName: name,
        }))
        .map((doc) => (
          <DocuSignDoc
            onError={onError}
            key={doc.documentName}
            doc={doc}
            isPdf
            token={token}
            newWorkflow={newWorkflow}
            assetId={assetId}
            userId={userId}
          />
        ));
    } else
      return (
        <Empty>
          <File /> <div>No signed documents yet</div>
        </Empty>
      );
  };

  return (
    <BorderContainer>
      <SignedColumn width={33}>
        {avatar && <Logo src={avatar} alt="logo" />}
        <UseName>{fullname}</UseName>
        <LoiSpaBlock>
          <DocStatus mTop={!loiValue ? "15px" : ""}>LOI {loiValue ? <div>{loiValue}</div> : <NoDoc />}</DocStatus>
          <DocStatus mTop={spaSigned ? "8px" : "15px"}>SPA {spaSigned ? <div>Signed</div> : <NoDoc />}</DocStatus>
        </LoiSpaBlock>
      </SignedColumn>
      <SignedColumn width={67}>
        {newWorkflow ? (
          <Fragment>{renderNewDocs()}</Fragment>
        ) : (
          <Fragment>
            {!doc.ndaDoc && !doc.loiDoc && !doc.spaDoc ? (
              <Empty>
                <File /> <div>No signed documents yet</div>
              </Empty>
            ) : (
              <Fragment>
                {doc.ndaDoc && <DocuSignDoc doc={doc.ndaDoc} isPdf token={token} />}
                {doc.loiDoc && <DocuSignDoc doc={doc.loiDoc} isPdf token={token} />}
                {doc.spaDoc && <DocuSignDoc doc={doc.spaDoc} isPdf token={token} />}
              </Fragment>
            )}
          </Fragment>
        )}
      </SignedColumn>
    </BorderContainer>
  );
};

export const ModalBG = styled.section`
  width: 100vw;
  height: ${({ height }) => (height ? height : "100vh")};
  position: fixed;
  top: 0;
  left: 0;
  box-sizing: border-box;
  padding: 90px;
  background: rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(5px);
  z-index: 100;
`;

export const ModalWrapper = styled.div`
  position: relative;
  width: ${({ width }) => (width ? width : "100%")};
  background: #ffffff;
  border: 1px solid #f9f9f9;
  box-sizing: border-box;
  box-shadow: 0px 10px 40px rgba(89, 120, 150, 0.06);
  border-radius: 4px;
  padding: ${({ padding }) => (padding ? padding : "25px 30px")};
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  height: ${({ height }) => (height ? height : "100%")};
  margin: ${({ margin }) => margin};
`;

export const ScrollContainerRegister = styled.div`
  height: 100%;
  padding-right: 8px;
  box-sizing: border-box;
  width: calc(100% + 15px);
  overflow-y: ${({ isScroll = true }) => (isScroll ? "scroll" : "hidden")};
  &&::-webkit-scrollbar-track {
    background-color: ${({ theme: { primaryLight } }) => primaryLight};
    border-radius: 2px;
  }
  &&::-webkit-scrollbar {
    width: 8px;
    border-radius: 2px;
    background-color: ${({ theme: { primaryLight } }) => primaryLight};
  }
  &&::-webkit-scrollbar-thumb {
    background-color: ${({ theme: { primaryMiddle } }) => primaryMiddle};
    border-radius: 2px;
  }
`;

export const ModalHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: ${({ isFooter = false }) => (isFooter ? "auto" : "0")};
  > svg {
    cursor: pointer;
    width: 24px;
    height: 24px;
  }
`;

export const ModalHeading = styled.h3`
  font-family: Muli;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 135%;
  margin: 0;
  color: #181818;
`;

export const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 0 15px 0;
  margin: 10px 0;
  border-top: 1px solid #f6f7fc;
  border-bottom: 1px solid #f6f7fc;
  z-index: 1000;
  height: calc(100% - 93px);
`;

export const DocsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ padding = false }) => (padding ? padding : "25px 0")};
  margin: 20px 0;
  border-top: 1px solid #f6f7fc;
  border-bottom: 1px solid #f6f7fc;
  z-index: 1000;
  ${({ height = "" }) => (height ? `height: ${height};` : "")}
`;

const WaitingWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1002;
  background: #00000038;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Loading = () => (
  <WaitingWrapper>
    <CircularProgress />
  </WaitingWrapper>
);

export const BorderBlock = styled.div`
  display: flex;
  border-left: 1px solid #f1f3f8;
  padding-left: 25px;
  align-items: center;
  height: 100%;
  justify-content: flex-end;
`;

export const BlurBg = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  box-sizing: border-box;
  background: rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(5px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

export const ModalHeaderVal = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #f6f7fc;
  height: 52px;
  > svg {
    cursor: pointer;
    width: 24px;
    height: 24px;
  }
`;

export const ValBorderBlock = styled.div`
  height: 108px;
  border-bottom: 1px solid #f6f7fc;
  box-sizing: border-box;
  padding: 20px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ValFooter = styled.div`
  height: 62px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const FullBg = styled.section`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: transparent;
  display: flex;
  > div {
    margin: auto;
  }
`;

export const Label = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 135%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: rgba(24, 24, 24, 0.6);
  margin-bottom: 8px;
`;

export const Text = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 135%;
  color: #181818;
`;

const NoteContainer = styled.div`
  position: relative;

  > div {
    display: block;
  }
  &:hover {
    > div {
      display: flex;
      flex-direction: column;
    }
  }
`;

const NoteFullText = styled.p`
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 135%;
  text-transform: none;
  color: #181818;
  margin: 8px 0 0;
  white-space: normal;
`;

const NoteItemContainer = styled.div`
  padding: 20px 0;
  border-bottom: 1px solid #efefef;
`;

const RemoveNote = styled.div`
  display: flex;
  width: 68px;
  height: 14px;
  cursor: pointer;
  align-items: center;
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 135%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #ff4343;
  margin-top: 12px;
  svg {
    width: 11px;
    height: 11px;
    margin-right: 6px;
    path {
      stroke: #ff4343;
    }
  }
`;

const _Modal = styled.div`
  width: 440px;
  background: #ffffff;
  border: 1px solid #f9f9f9;
  box-sizing: border-box;
  box-shadow: 0px 10px 40px rgba(89, 120, 150, 0.06);
  border-radius: 4px;
  margin: auto;
`;

const NotesWrapper = styled.div`
  width: 100%;
  height: calc(100% - 52px);
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 20px 25px;
`;

const _ScrollContainer = styled.div`
  max-height: 280px;
  padding-right: 22px;
  box-sizing: border-box;
  overflow-y: scroll;
  margin-bottom: 20px;
  &&::-webkit-scrollbar-track {
    background-color: ${({ theme: { primaryLight } }) => primaryLight};
    border-radius: 2px;
  }
  &&::-webkit-scrollbar {
    width: 8px;
    border-radius: 2px;
    background-color: ${({ theme: { primaryLight } }) => primaryLight};
  }
  &&::-webkit-scrollbar-thumb {
    background-color: ${({ theme: { primaryMiddle } }) => primaryMiddle};
    border-radius: 2px;
  }
`;

const _Heading = styled.h3`
  font-family: Muli;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 135%;
  margin: 0;
  color: #181818;
  text-transform: none;
`;

const _ModalHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 52px;
  background: #ffffff;
  border: 1px solid #f9f9f9;
  box-sizing: border-box;
  box-shadow: 0px 10px 40px rgba(89, 120, 150, 0.08);
  border-radius: 4px;
  padding: 5px 25px;
  > svg {
    cursor: pointer;
    width: 20px;
    height: 20px;
  }
`;

const RowFlex = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const _Date = styled.div`
  letter-spacing: 0.05em;
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 135%;
  text-transform: uppercase;
  color: rgba(24, 24, 24, 0.4);
  height: 14px;
  display: flex;
  align-items: center;
  svg {
    width: 12px;
    height: 12px;
    margin-right: 5px;
    path {
      stroke: rgba(24, 24, 24, 0.4);
    }
  }
`;

const NoteText = styled.p`
  font-family: Muli;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 135%;
  margin: 8px 0;
  color: #181818;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: none;
  white-space: normal;
`;

const LastUpdate = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 135%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: rgba(24, 24, 24, 0.6);
`;

const NoteButton = styled.button`
  width: 76px;
  height: 24px;
  box-sizing: border-box;
  border-radius: 2px;
  cursor: pointer;
  transition: 0.3s linear all;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 150%;
  text-transform: uppercase;
  position: relative;
  outline: none;
  > svg {
    transition: 0.3s linear all;
    margin-right: 7px;
    width: 13px;
    height: 13px;
    path {
      stroke: ${({ isNote, wasRead, theme: { primary } }) => (isNote ? (wasRead ? "#8A8A8A" : primary) : "#FFFFFF")};
    }
  }
  background: ${({ isNote, theme: { primary } }) => (isNote ? "transparent" : primary)};
  color: ${({ isNote, wasRead, theme: { primary } }) => (isNote ? (wasRead ? "#8A8A8A" : primary) : "#FFFFFF")};
  border: 1px solid ${({ isNote, wasRead, theme: { primary } }) => (isNote ? (wasRead ? "#8A8A8A" : primary) : primary)};
`;

const NoNotes = styled.div`
  text-transform: none;
  width: 100%;
  background: #f2f4f7;
  border: 1px solid #f0f0f0;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px 0;
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 135%;
  text-align: center;
  color: #181818;
  margin-bottom: 20px;
  flex-direction: column;
  > svg {
    width: 32px;
    height: 32px;
    margin-bottom: 18px;
    path {
      stroke: ${({ theme: { primary } }) => primary};
    }
  }
`;

const NoteItem = ({ note: { date, content, read }, onRemove, role, key }) => {
  return (
    <NoteItemContainer key={`note-${key}`}>
      <RowFlex>
        <_Date>
          UPDATED {!read ? "from " : null}
          {moment(date).format("DD.MM.YYYY, hh:mm")}
        </_Date>
        {read ? (
          <Fragment>
            <_Date>
              <Eye />
              READ {moment(read).format("DD.MM.YYYY, hh:mm")}
            </_Date>
          </Fragment>
        ) : null}
      </RowFlex>
      <NoteFullText dangerouslySetInnerHTML={{ __html: content }} />
      {role !== "SPONSOR" ? (
        <RemoveNote onClick={onRemove}>
          <Rem /> <span>remove</span>
        </RemoveNote>
      ) : null}
    </NoteItemContainer>
  );
};

export const Note = ({ token, notes = [], userName, assetId, role, fullName, onRefresh }) => {
  const [notesOpen, setNotesOpen] = useState(false);
  const [newNote, setNewNote] = useState("");
  const [wait, setWait] = useState(false);
  const [notesList, setNotes] = useState([...notes]);
  const [wasRead, setWasRead] = useState(notes.every((e) => e.read));
  const noteRef = useRef(null);
  const scrollRef = useRef(null);
  const noNoteRef = useRef(null);
  const debounceRef = useRef(null);

  const [show, setShow] = useState(false);
  const [showNoNotes, setShowNoNotes] = useState(false);

  const onChangeModal = () => {
    setNotesOpen(!notesOpen);
  };

  const renderNotes = () =>
    notesList.map((n, i) => <NoteItem key={n.date} note={n} onRemove={() => onRemoveNote(n, i)} role={role} />);

  const onRemoveNote = async (note, i) => {
    setWait(true);
    const del = await Api.removeNote(token, assetId, userName, note).catch(() => setWait(false));

    if (del) {
      setWait(false);
      onRefresh();
    }
  };

  const onAddNote = async () => {
    setWait(true);
    const _note = { date: Date.now(), content: newNote.replace(/\n/g, "<br>") };

    const res = await Api.createNote(token, assetId, userName, _note).catch(() => setWait(false));

    if (res) {
      setWait(false);
      setNewNote("");
      onRefresh();
    }
  };

  const overContainer = () => {
    if (notesList.length) {
      setShow(true);
    } else setShowNoNotes(true);
  };

  const onReadAllNotes = async () => {
    if (!wasRead) {
      const res = await Api.readNotes(token, assetId, userName);
      if (res) {
        onRefresh();
      }
    }
  };

  const onOpenModal = () => {
    onReadAllNotes();
    onChangeModal();
  };

  const countNotRead = () => {
    let count = 0;
    notes.forEach((e) => {
      if (!e.read) count++;
    });
    return count;
  };

  useEffect(() => {
    setNotes([...notes]);
    setWasRead(notes.every((e) => e.read));

    clearTimeout(debounceRef.current);
    debounceRef.current = setTimeout(() => {
      if (scrollRef && scrollRef.current && notes.length) {
        scrollRef.current.scrollBy(0, 10000000);
      }
    }, 350);
  }, [notes]);

  return (
    <NoteContainer>
      <NoteButton
        wasRead={wasRead}
        isNote={notes.length}
        ref={notes.length ? noteRef : noNoteRef}
        onMouseOver={overContainer}
        disabled={role === "SPONSOR" ? !notes.length : false}
      >
        {notes.length ? wasRead ? <Eye /> : <Mess /> : <Plus />}
        Note
      </NoteButton>
      {show && notesList.length && !notesOpen ? (
        <PortalTooltipWrapper noPseudo anchorRef={noteRef} onLeave={() => setShow(false)}>
          <Fragment>
            <RowFlex>
              <LastUpdate> {wasRead ? "UPDATE" : `LAST UPDATE (${countNotRead()})`}</LastUpdate>
              <_Date>
                {moment(notesList[notesList.length - 1] && notesList[notesList.length - 1].date).format(
                  "DD.MM.YYYY, hh:mm"
                )}
              </_Date>
            </RowFlex>
            <NoteText
              dangerouslySetInnerHTML={{
                __html: notesList[notesList.length - 1] && notesList[notesList.length - 1].content,
              }}
            />
            <ReadStatus>
              {wasRead ? (
                <Fragment>
                  <Eye />
                  READ,{" "}
                  {moment(notesList[notesList.length - 1] && notesList[notesList.length - 1].read).format(
                    "DD.MM.YYYY, hh:mm"
                  )}
                </Fragment>
              ) : (
                <Fragment>
                  <EyeOff />
                  NOT READ YET
                </Fragment>
              )}
            </ReadStatus>
            <NoNote>
              <NoteLink onClick={onOpenModal}>
                <Plus />
                ADD NEW NOTE
              </NoteLink>
              <NoteLink blue onClick={onOpenModal}>
                all updates
              </NoteLink>
            </NoNote>
          </Fragment>
        </PortalTooltipWrapper>
      ) : null}

      {showNoNotes && !notesOpen ? (
        <PortalTooltipWrapper noPseudo anchorRef={noNoteRef} onLeave={() => setShowNoNotes(false)}>
          <Fragment>
            <NoNote>
              <NoteLink onClick={onChangeModal}>
                <Plus />
                ADD NEW NOTE
              </NoteLink>
              <NoteLink disabled>
                <CloseIcon style={{ rotate: "45deg" }} />
                NO NOTES YET
              </NoteLink>
            </NoNote>
          </Fragment>
        </PortalTooltipWrapper>
      ) : null}

      {notesOpen ? (
        <ModalBG padding="0" display="flex" onClick={onChangeModal}>
          <_Modal onClick={(e) => e.stopPropagation()}>
            <_ModalHeader>
              <_Heading>{fullName} Notes</_Heading>
              <CloseIcon onClick={onChangeModal} />
            </_ModalHeader>

            <NotesWrapper>
              {notesList.length ? (
                <_ScrollContainer ref={scrollRef}>{renderNotes()}</_ScrollContainer>
              ) : (
                <NoNotes>
                  <Mess />
                  There are no notes yet
                </NoNotes>
              )}

              {role !== "SPONSOR" ? (
                <Fragment>
                  <TextAreaComponent
                    key={`input-${notesList.length}`}
                    label="New Note"
                    placeholder="Write new update here"
                    areaHeight={64}
                    minHeight={64}
                    value={newNote}
                    onChange={(e) => setNewNote(e.target.value)}
                  />
                  <Btn width={180} height={36} margin="15px 0 0" onClick={onAddNote} disabled={wait}>
                    {notesList.length ? "update" : "add note"}
                  </Btn>
                </Fragment>
              ) : null}
            </NotesWrapper>
          </_Modal>
        </ModalBG>
      ) : null}
    </NoteContainer>
  );
};

const NoNote = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const ReadStatus = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 135%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: rgba(24, 24, 24, 0.6);
  user-select: none;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  svg {
    width: 13px;
    height: 13px;
    margin-right: 7px;
    path {
      stroke: rgba(24, 24, 24, 0.6);
    }
  }
`;

const NoteLink = styled.div`
  display: flex;
  align-items: center;
  font-family: Muli;
  font-style: normal;
  font-size: 10px;
  line-height: 135%;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  user-select: none;
  cursor: ${({ disabled = false }) => (disabled ? "" : "pointer")};
  font-weight: ${({ disabled = false, blue }) => (disabled || blue ? "600" : "800")};
  color: ${({ disabled = false, theme: { primary } }) => (disabled ? "rgba(24, 24, 24, 0.6)" : primary)};
  > svg {
    width: 13px;
    height: 13px;
    margin-right: 7px;
    path {
      stroke: ${({ disabled = false, theme: { primary } }) => (disabled ? "rgba(24, 24, 24, 0.6)" : primary)};
    }
  }
`;

const Switch = styled.div`
  width: 32px;
  height: 16px;
  position: relative;
  background: ${({ active, theme: { primary } }) => (active ? primary : "#EFF0F3")};
  transition: background linear 0.1s;
  border-radius: 8px;
  cursor: pointer;
`;

const Circle = styled.div`
  width: 14px;
  height: 14px;
  background: ${({ active }) => (active ? "#fff" : "#616161")};
  border-radius: 8px;
  position: absolute;
  right: ${({ active }) => (active ? "1px" : "17px")};
  transition: right linear 0.1s;
  top: 1px;
`;

export const SwitcherDra = ({ value, userName, token, assetId }) => {
  const [active, setActive] = useState(value);
  const [messageOpen, setMessageOpen] = useState(false);

  const onChangeStatus = async () => {
    onOpenMessage();
    const res = await Api.setDataRoomAccess(token, assetId, userName);
    if (res) setActive(true);
  };

  const onOpenMessage = () => {
    if (active) return;
    else {
      setMessageOpen(!messageOpen);
    }
  };

  return (
    <Fragment>
      <ConfirmMessage
        isOpen={messageOpen}
        actionName="ok"
        onAction={onChangeStatus}
        onBackDpopClick={onOpenMessage}
        message={`Are you sure you want to give <b>${userName}</b> access to the Data Room? 
        Once access is granted, it can not be undone.`}
        heading="Data Room Access"
      />
      <Switch active={active} onClick={onOpenMessage}>
        <Circle active={active} />
      </Switch>
    </Fragment>
  );
};

export const Col = styled.div`
  width: ${({ w = "440px" }) => w};
`;

export const Separator = styled.div`
  height: 30px;
`;
export const LeftBlock = styled.div`
  position: absolute;
  top: 15px;
  right: 25px;
`;
