import React, { useEffect, useState, Fragment } from "react";
import { connect } from "react-redux";

import { getQuestionAnswerInv } from "../../../../modules/dataRedux/dataAction";
import {
  HeaderRow,
  HeaderHeading,
  FilterBlock,
  DealsWrapper,
  SearchInput,
  MainPage
} from "./styled";
import { ReactComponent as SearchIcon } from "../../../../assets/search-icon.svg";
import { ErrorMessage } from "../../../admin-layout/errorMessage";
import { DealCard } from "./deal";
import { DealCardVertical } from "./dealVertical";
import { Loader } from "../../../admin-layout/pages/DashBoard/styled";

const QAAll = ({ token, history, viewQAInv, getQuestionAnswerInv }) => {
  const [search, setSearch] = useState("");
  const [error, setError] = useState(null);
  const [wasError, setWasError] = useState(false);
  const [smallScreen, setSmallScreen] = useState(window.innerWidth <= 1280);

  useEffect(() => {
    const handleResize = () => {
      setSmallScreen(window.innerWidth <= 1280);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const onError = e => {
    setWasError(true);
    setError(e);
  };

  const onSearchInput = e => {
    const {
      target: { value }
    } = e;
    setSearch(value);
  };

  const getTotalAnswers = data => {
    return data.reduce((total, e) => {
      return total + e.newAnswers;
    }, 0);
  };

  const renderDeals = () => {
    return viewQAInv
      .filter(e => e.assetName.toLowerCase().includes(search.toLowerCase()))
      .map(e =>
        smallScreen ? (
          <DealCardVertical
            deal={e}
            token={token}
            key={e.assetId}
            history={history}
          />
        ) : (
          <DealCard deal={e} token={token} key={e.assetId} history={history} />
        )
      );
  };

  useEffect(() => {
    getQuestionAnswerInv(token, onError);
  }, []);

  if (!viewQAInv && !error && !wasError) {
    return <Loader />;
  } else if (!viewQAInv && error) {
    return <ErrorMessage er={error} onClose={() => setError(null)} />;
  } else if (viewQAInv) {
    return (
      <MainPage>
        <HeaderRow>
          <FilterBlock>
            <HeaderHeading>
              {`You have 
            ${viewQAInv.length || "no"} 
            assets now`}
              <span>{`${
                getTotalAnswers(viewQAInv) ? getTotalAnswers(viewQAInv) : "No"
              } new answers`}</span>
            </HeaderHeading>
          </FilterBlock>
          <SearchInput
            placeholder="Search by asset name"
            value={search}
            onChange={e => onSearchInput(e)}
          />
          <SearchIcon />
        </HeaderRow>
        <DealsWrapper>{renderDeals()}</DealsWrapper>
      </MainPage>
    );
  } else return null;
};

export default connect(
  ({ userReducer: { token }, dataReducer: { viewQAInv } }) => ({
    token,
    viewQAInv
  }),
  { getQuestionAnswerInv }
)(QAAll);
