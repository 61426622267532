import React, { useState } from "react";
import moment from "moment";

import {
  Card,
  CardHeader,
  ContentBlock,
  SubmitBlock,
  Heading,
  Status,
  Expand,
  Text,
  InfoBlock,
  AssetLink,
  LinkText,
  Info
} from "./styled";
import { Button } from "../../../investor-layout/pages/Offerings/styled";
import { ReactComponent as Clock } from "../../../../assets/clock-i.svg";
import { ReactComponent as Arrow } from "../../../../assets/chevron-up.svg";
import { ReactComponent as I } from "../../../../assets/info.svg";

import { TxInfo } from "./TxInfo";

export const PayedOrder = ({
  history,
  order: {
    assetName,
    amountToTransfer,
    quantity,
    tokenIdentifier,
    created,
    recipient: { bankName, bic, iban, accountName }
  }
}) => {
  const [infoOpen, setInfoOpen] = useState(false);

  const openInfoModal = () => setInfoOpen(!infoOpen);

  const goToChats = () => {
    const link = document.createElement("a");
    link.href = `${window.location.origin}/investor/chat/`;
    link.target = "_blank";
    link.click();
  };

  return (
    <Card>
      <CardHeader>
        <Heading>{assetName + ", " + amountToTransfer}</Heading>
        <Status>
          <Clock />
          Waiting for payment
        </Status>
        <Expand>
          <Arrow />
        </Expand>
      </CardHeader>
      <ContentBlock padding="30px 30px 25px">
        <Text margin="0 0 20px 0">
          Waiting for approval your transaction from manager.
        </Text>
        <InfoBlock>
          <div>
            <LinkText>Shares Purchase for</LinkText>{" "}
            <AssetLink>{assetName}</AssetLink>
          </div>

          <Text fSize={14} color="rgba(51, 51, 51, 0.6)">
            {amountToTransfer} = <span>{quantity + tokenIdentifier}</span>
          </Text>

          <Status width={125} height={32}>
            <Clock />
            Pending
          </Status>

          <Text fSize={14} color="rgba(51, 51, 51, 0.6)">
            <span>{moment(created * 1000).format("dddd, DD/MM/YYYY, ")}</span>
            {moment(created * 1000).format("hh:mm;ss A")}
          </Text>

          <Info
            onClick={() =>
              history.push("/investor/transactions/all/all#info-21412412421")
            }
          >
            <I />
          </Info>
        </InfoBlock>
      </ContentBlock>
      <SubmitBlock>
        <Button transparent width={248} height={42} onClick={goToChats}>
          CHAT WITH ADMIN
        </Button>
        <Button transparent width={248} height={42} onClick={openInfoModal}>
          BANK DETAILS
        </Button>
      </SubmitBlock>

      {infoOpen ? (
        <TxInfo
          name={assetName}
          onClose={openInfoModal}
          iban={iban}
          amountToTransfer={amountToTransfer}
          accountName={accountName}
          bic={bic}
          bankName={bankName}
        />
      ) : null}
    </Card>
  );
};
