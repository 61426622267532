import React, { useState } from "react";

import { Card, CardName, Row, Text, Col } from "components/setup-layout/common";
import { TypeComponent } from "components/setup-layout/common/forms";
import { Label } from "components/auhtForms/styled";

export const Privacy = () => {
  const [type, setType] = useState("free");

  const types = {
    free: {
      heading: "Free Access",
      description: "Any user can access to your sign up page and make the account to log in to your platform.",
    },
    private: {
      heading: "Private Access",
      description: "Only admin can make the accounts to log in to  platform.",
    },
  };

  return (
    <Card>
      <CardName>Privacy</CardName>
      <Label>Choose website Privacy type</Label>
      <Row m="0 0 24px">
        <Col w="calc(50% - 15px)">
          <TypeComponent active={type === "free"} value="free" label="Free access" onChange={setType} />
        </Col>
        <Col w="calc(50% - 15px)">
          <TypeComponent active={type === "private"} value="private" label="Private" onChange={setType} />
        </Col>
      </Row>
      <CardName>{types[type].heading}</CardName>

      <Text m="-10px 0 0 0">{types[type].description}</Text>
    </Card>
  );
};
