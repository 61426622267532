import styled from "styled-components";
import React from "react";

import { numberWithCommas } from "../../../../../helpers/index";
import { currenciesModel } from "../../../../../models/currenciesModel";
import { Btn } from "components/admin-layout/buttons";

export const EditBtn = styled(Btn)`
  width: 90px;
  height: 32px;
  background: #fff;
  border: 1px solid ${({ theme: { primary } }) => primary};
  box-sizing: border-box;
  border-radius: 2px;
  position: absolute;
  right: 25px;
  top: 15px;
  cursor: pointer;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
  > span {
    font-family: Muli;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 135%;
    letter-spacing: 0.06em;
    text-transform: uppercase;
    color: ${({ theme: { primary } }) => primary};
  }
  > svg {
    margin-right: 8px;
    path {
      stroke: ${({ theme: { primary } }) => primary};
    }
  }
  &:active {
    > span {
      color: #fff;
    }
  }
`;

export const ChartRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
`;

export const ChartBlock = styled.div`
  width: 120px;
`;

export const DataBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 150px);
`;

const Block = styled.div`
  width: 100%;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  &:last-child {
    margin-bottom: 0;
  }
`;

const Label = styled.h6`
  font-weight: bold;
  font-size: 10px;
  line-height: 135%;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  color: rgba(24, 24, 24, 0.6);
  margin: 0 0 4px;
`;

const DataRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

const Circle = styled.div`
  width: 12px;
  height: 12px;
  background: ${({ bg }) => bg};
  border-radius: 50%;
`;

const Val = styled.div`
  max-width: calc(100% - 52px);
  overflow: hidden;
  font-weight: bold;
  font-size: 14px;
  line-height: 135%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #181818;
  margin-right: 8px;
`;

const Percentage = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 135%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: rgba(24, 24, 24, 0.4);
  margin-left: auto;
  margin-right: 8px;
`;

export const Parameter = ({ item: { name, value, color }, baseCurrency, total, lastItem = false }) => {
  let _value = +value || 0;
  console.log(_value);
  return (
    <Block>
      <Label>{name}</Label>
      <DataRow>
        <Val
          dangerouslySetInnerHTML={{
            __html: `${numberWithCommas(_value.toFixed(2))}${
              currenciesModel[baseCurrency] ? currenciesModel[baseCurrency].symbol : baseCurrency
            }`,
          }}
        />
        <Percentage>{_value ? ((_value / total) * 100).toFixed() : 0}%</Percentage>
        {lastItem ? null : <Circle bg={color} />}
      </DataRow>
    </Block>
  );
};

export const ParamBlock = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
`;

const ColorBlock = styled.div`
  height: 42px;
  width: 42px;
  border: 1px solid #efefef;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  > input {
    margin: auto;
  }
`;

const ColorPicker = styled.input`
  width: 24px;
  height: 24px;
  border: none;
  outline: none;
  -webkit-appearance: none;
  border-radius: 100%;
  padding: 0;
  cursor: pointer;
  &::-webkit-color-swatch-wrapper {
    padding: 0;
    border-radius: 100%;
  }
  &::-webkit-color-swatch {
    border: none;
    border-radius: 100%;
  }
`;

export const ColorPickerComponent = ({ defaultValue, onChange }) => {
  return (
    <ColorBlock>
      <ColorPicker type="color" defaultValue={defaultValue} onBlur={onChange} />
    </ColorBlock>
  );
};

export const RemoveBlock = styled.div`
  height: 42px;
  width: 42px;
  border: 1px solid #efefef;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  background: #f6f8fb;
  cursor: pointer;
  > svg {
    margin: auto;
  }
`;
