import { EmptyInfo } from "components/admin-layout/pages/AssetDetails/common/emptyInfo";
import styled from "styled-components";

export const EmptyCard = EmptyInfo;

export const Card = styled.div`
  width: 100%;
  padding: 20px;
  background: #fff;
  box-sizing: border-box;
`;
