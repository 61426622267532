import React, { Fragment } from "react";
import { CircularProgress } from "@material-ui/core";
import styled from "styled-components";
import { PieChart } from "react-minimal-pie-chart";

import { Main, TextSub } from "../../DealForm/Step/styled";
import { FlexRow } from "../../../dashBoardCard";

const Empty = styled.div`
  width: calc(100% - 111px);
  margin-left: auto;
  height: ${({ h = "53px" }) => h};
  background: ${({ theme: { emptyCardBg } }) => emptyCardBg};

  border-radius: 2px;
  display: flex;
  > h6 {
    margin: auto;
    font-size: 14px;
    line-height: 135%;
    text-align: center;
    color: #181818;
  }
`;

const ChartBlock = styled.div`
  width: 86px;
  height: 86px;
  position: absolute;
  top: 20px;
  left: 25px;
`;

const EmptyChart = styled.div`
  width: 86px;
  height: 86px;
  background: ${({ theme: { emptyCardBg } }) => emptyCardBg};
  border-radius: 50%;
`;

const Rows = styled.div`
  width: calc(100% - 111px);
  margin-left: auto;
  height: 53px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
`;

const Row = styled.div`
  width: ${({ long = false }) => (long ? "100%" : "calc(50% - 15px)")};
  display: flex;
  height: 26px;
  align-items: center;
`;

const Circle = styled.div`
  width: 13px;
  height: 13px;
  background: ${({ bg }) => bg};
  margin-right: 7px;
  border-radius: 50%;
`;

const Text = styled.div`
  font-weight: 600;
  font-size: 10px;
  line-height: 135%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #181818;
  max-width: ${({ percentage = false }) =>
    percentage ? "37px" : "calc(100% - 57px)"};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: ${({ percentage = false }) => (percentage ? "7px" : 0)};
`;

const colors = [
  "#3F4B78",
  "#BFDCFC",
  "#6781D4",
  "#55B17C",
  "#3E43B4",
  "#6876A0",
  "#2F55CF",
  "#20263C",
  "#9CA2DB",
  "#3E4BC1"
];

const chartSize = {
  width: 86,
  height: 86
};

export const VievsFolders = ({ info, newDeal }) => {
  const generateColor = () =>
    "#" + (((1 << 24) * Math.random()) | 0).toString(16);

  const renderViews = () => {
    const views = info.viewsByFolders;
    const isViewsExist = views.some(folder => folder.percentage);

    const chartData = views.map(({ folderName, percentage }, i) => ({
      title: `${folderName} (${percentage.toFixed(3)}%)`,
      value: percentage,
      color: colors[i] || generateColor()
    }));

    const renderRows = () => {
      const mostViewed = [...views];
      if (mostViewed.length > 4) mostViewed.length = 4;
      return mostViewed.map(({ folderName, percentage }, i) => (
        <Row key={folderName} long={newDeal}>
          <Circle bg={colors[i]} />
          <Text>{folderName}</Text>
          <Text percentage> ({percentage.toFixed()}%)</Text>
        </Row>
      ));
    };

    return (
      <Fragment>
        <ChartBlock>
          {isViewsExist ? (
            <PieChart style={chartSize} data={chartData} />
          ) : (
            <EmptyChart />
          )}
        </ChartBlock>
        {!isViewsExist ? (
          <Empty h={newDeal ? "100px" : "53px"}>
            <h6>No Data Yet</h6>
          </Empty>
        ) : (
          <Rows>{renderRows()}</Rows>
        )}
      </Fragment>
    );
  };
  return (
    <Main
      currentHeight={newDeal ? "170px" : "126px"}
      width={newDeal ? "100%" : "55.2%"}
      padding="20px 25px"
    >
      <FlexRow mBottom="0" align="flex-start">
        <TextSub
          textAlign="left"
          fontWeight="bold"
          color="#181818"
          marginSize="0 0 12px 111px"
        >
          Data Room Views by Folders
        </TextSub>
      </FlexRow>
      {!info ? (
        <CircularProgress size={24} style={{ margin: "auto" }} />
      ) : (
        renderViews()
      )}
    </Main>
  );
};
