import React, { useState, useEffect, Fragment } from "react";
import styled from "styled-components";

import { Main, TextSub } from "../../DealForm/Step/styled";
import { FlexRow } from "../../../dashBoardCard";
import { Logo } from "../common/StatisticTable";

import { EmptyInfo } from "../common/emptyInfo";
import { CircularProgress } from "@material-ui/core";
import Api from "helpers/api";
import { Countries } from "../../../../../models/currenciesModel";
import { Link } from "react-router-dom";
import { ReactComponent as Dots } from "../../../../../assets/more-vertical.svg";
import { ReactComponent as ExpandIcon } from "../../../../../assets/chevron-down.svg";
import { Btn as Button } from "../../../buttons";
import { ErrorMessagePortal } from "components/admin-layout/errorMessage";

import theme from "models/themes";

const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  background: ${({ grey, theme: { adminTableRowGrey } }) => (grey ? adminTableRowGrey : "#fff")};
  border-radius: 2px;
  padding-left: 15px;
  ${({ pr }) =>
    pr
      ? `padding-right: 33px;
         padding-left: 0;`
      : ``};
  box-sizing: border-box;
`;

export const ScrollContainer = styled.div`
  height: ${({ open }) => (open ? "fit-content" : "245px")};
  min-height: 245px;
  padding-right: ${({ noMargin, isScroll }) => (noMargin ? "10px" : isScroll ? "25px" : "")};
  box-sizing: border-box;
  margin: 0 0 20px -15px;
  width: calc(100% + 15px);
  overflow-y: ${({ isScroll = true }) => (isScroll ? "scroll" : "hidden")};
  &&::-webkit-scrollbar-track {
    background-color: ${({ theme: { primaryLight } }) => primaryLight};
    border-radius: 2px;
  }
  &&::-webkit-scrollbar {
    width: 8px;
    border-radius: 2px;
    background-color: ${({ theme: { primaryLight } }) => primaryLight};
  }
  &&::-webkit-scrollbar-thumb {
    background-color: ${({ theme: { primaryMiddle } }) => primaryMiddle};
    border-radius: 2px;
  }
`;

const Label = styled.h6`
  margin: 0;
  font-size: 10px;
  line-height: 135%;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  color: ${({ blue, theme: { primary } }) => (blue ? primary : "rgba(24, 24, 24, 0.4)")};
  width: ${({ w }) => w};
  box-sizing: border-box;
  transform: ${({ translate = 0 }) => `translateX(${translate}px)`};
`;

const Ceil = styled.div`
  width: ${({ w }) => w};
  box-sizing: border-box;
  display: flex;
  align-items: center;
  > svg {
    width: 16px;
    height: 16px;
    margin-right: 6px;
  }
`;

const Value = styled.div`
  font-size: 12px;
  line-height: 135%;
  color: #181818;
  font-weight: ${({ weight = 600 }) => weight};
`;

const Mail = styled.div`
  cursor: pointer;
  font-size: 12px;
  line-height: 150%;
  color: ${({ theme: { primary } }) => primary};
  text-decoration: underline;
`;

const button = `
display: flex;
align-items: center;
justify-content: center;
font-weight: bold;
font-size: 10px;
line-height: 135%;
letter-spacing: 0.06em;
text-transform: uppercase;
color: ${theme.primary};
width: 100px;
height: 26px;
border: 1px solid ${theme.primary};
box-sizing: border-box;
border-radius: 2px;
margin-right: 10px;
text-decoration: none;
cursor:pointer;`;

const Btn = styled.button`
  ${button}
  background:transparent;
  width: 26px;
  height: 26px;
`;

const More = styled(Link)`
  ${button}
`;

const NoFlag = styled.div`
  width: 16px;
  height: 11px;
  margin-right: 6px;
  border: 1px solid grey;
`;

const Buttons = styled.div`
  padding: 20px 0 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  position: relative;
  &::before {
    content: "";
    display: block;
    width: calc(100% + 50px);
    border-top: 1px solid #f6f7fc;
    position: absolute;
    left: -25px;
    top: 0;
  }
`;

export const UserStatistic = ({ token, assetId, history }) => {
  const [data, setData] = useState(null);
  const [wait, setWait] = useState(false);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const getData = async () => {
      const res = await Api.getVisitorStatistic(token, assetId, 0);
      if (res) {
        setData(res.statisticsByUser);
        console.clear();
        console.log(res);
      }
    };

    getData();
  }, []);

  const downloadXlsx = async () => {
    setWait(true);
    const res = await Api.getXlsxUsersStatisticsAll(token, assetId, 0).catch((e) => {
      setWait(false);
      onError(e)();
    });
    if (res) {
      const { file, fileName } = res;
      const fileURL = URL.createObjectURL(file);
      const link = document.createElement("a");
      link.href = fileURL;
      link.download = fileName;
      link.click();
      setWait(false);
    }
  };

  const onError = (err = null) => () => setError(err);

  const renderStatistic = () => {
    const renderRows = () => {
      return data.map(({ avgTimeVisit, countryCode, email, fullName, logo, totalTime, userId, visits }, i) => (
        <Row key={email} grey={i % 2 ? 0 : 1}>
          <Ceil w="260px">
            <Logo token={token} logo={logo} />
            <Value weight="bold">{fullName}</Value>
          </Ceil>
          <Ceil w="260px">
            <Mail onClick={() => history.push(`/admin/user/${email}/${userId}`)}>{email}</Mail>
          </Ceil>
          <Ceil w="60px">
            {Countries[countryCode] ? Countries[countryCode].flag : <NoFlag />} <Value>{countryCode}</Value>
          </Ceil>
          <Ceil w="45px">
            <Value>{visits}</Value>
          </Ceil>
          <Ceil w="80px">
            <Value weight="bold">{totalTime}</Value>
          </Ceil>
          <Ceil w="80px">
            <Value weight={400}>{avgTimeVisit}</Value>
          </Ceil>
          <Ceil w="160px">
            <More to={`/admin/user/${email}/${userId}`}>More</More>
            <Btn>
              <Dots />
            </Btn>
          </Ceil>
        </Row>
      ));
    };
    return (
      <Fragment>
        <Row pr>
          <Label w="260px">User</Label>
          <Label w="260px" translate={data.length > 5 ? 0 : 4}>
            e-mail
          </Label>
          <Label w="60xp" translate={data.length > 5 ? -4 : 7}>
            Country
          </Label>
          <Label w="45px" translate={data.length > 5 ? 4 : 21}>
            Visits
          </Label>
          <Label w="80px" translate={data.length > 5 ? 2 : 25}>
            Total time
          </Label>
          <Label w="80px" translate={data.length > 5 ? 0 : 29}>
            avg per visit
          </Label>
          <Label w="160px" translate={data.length > 5 ? 0 : 32}>
            More info
          </Label>
        </Row>
        <ScrollContainer isScroll={data.length > 5} open={open}>
          {renderRows()}
        </ScrollContainer>
        <Buttons>
          <Button transparent width={180} onClick={downloadXlsx} disabled={wait}>
            download xlsx
          </Button>
          <Button width={180} onClick={onChange} transform={open} rightIcon>
            {open ? "minimize" : "expand"}
            <ExpandIcon />
          </Button>
        </Buttons>
      </Fragment>
    );
  };

  const onChange = () => {
    setOpen(!open);
  };

  return (
    <Main minHeight={440} currentHeight={open ? "fit-content" : "455px"} width="100%" padding="20px 25px 25px 25px">
      <FlexRow mBottom="0" align="flex-start">
        <TextSub textAlign="left" fontWeight="bold" color="#181818" marginSize="0 0 25px 0">
          Data Room Statistics by User
        </TextSub>
      </FlexRow>
      {!data ? (
        <CircularProgress size={24} style={{ margin: "auto" }} />
      ) : !data.length ? (
        <EmptyInfo heading="When we’ll have statistics to show for you we’ll show them here" />
      ) : (
        renderStatistic()
      )}

      {error && <ErrorMessagePortal er={error} onClose={onError(null)} />}
    </Main>
  );
};
