import styled from "styled-components";

export const AdminHeader = styled.header`
  width: calc(100% - 233px);
  height: 70px;
  box-sizing: border-box;
  background: white;
  position: fixed;
  margin: 0 0 0 233px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  box-shadow: 0px 5px 7px 0px rgba(0, 0, 0, 0.02);
  transition: width 0.4s linear;
  z-index: 100;
  border-bottom: 1px solid #f6f7fc;
`;

export const HeaderBlock = styled.div`
  height: 100%;
  padding: ${({ padding }) => (padding ? padding : "0 30px")};
  border-left: ${({ noBorder }) => (noBorder ? "none" : "1px solid #f6f7fc")};
  margin-right: ${({ leftAlign }) => (leftAlign ? "auto" : "0")};
  display: flex;
  align-items: center;
  position: relative;
  > svg {
    cursor: pointer;
    path {
      stroke: ${({ theme: { primary } }) => primary};
    }
  }
  &::before {
    content: "";
    display: ${({ active }) => (active ? "block" : "none")};
    height: 2px;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background: ${({ theme: { primary } }) => primary};
  }
  &::after {
    content: "${({ newMess }) => newMess}";
    display: ${({ newMess }) => (newMess ? "flex" : "none")};
    height: 16px;
    width: 16px;
    font-size: 10px;
    background: ${({ theme: { primary } }) => primary};
    position: absolute;
    border-radius: 50%;
    color: #fff;
    align-items: center;
    justify-content: center;
    font-family: Muli;
    font-style: normal;
    right: 23px;
    top: 18px;
  }
`;

export const SearchBar = styled.input`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 135%;
  letter-spacing: -0.02em;
  &::placeholder {
    color: #d5d7dd;
  }
  &:focus::placeholder {
    color: transparent;
  }
  color: #192036;
`;
