import React, { Fragment, useCallback } from "react";

import { MainCol, SideCol, UserName, PageLogo, Card, Row, CardName } from "components/setup-layout/common";
import { Btn } from "components/admin-layout/buttons";
import { UserFill } from "components/common/icons-primary";

export const AssetCreation = ({
  match: {
    params: { username },
  },
}) => {
  const Help = useCallback(
    () => (
      <Btn key="help" className="side-buttons" transparent width={205}>
        I NEED HELP
      </Btn>
    ),
    []
  );

  const User = useCallback(
    () => (
      <UserName key="user" className="side-buttons">
        <UserFill />
        <span>{username}</span>
      </UserName>
    ),
    []
  );
  return (
    <Fragment>
      <SideCol left>{Help()}</SideCol>
      <MainCol>
        <Row m="0 0 52px" a="space-between">
          {Help()}
          <PageLogo description="Create your first asset" /> {User()}
        </Row>
        <Card>
          <CardName>Create asset</CardName>
        </Card>
      </MainCol>
      <SideCol>{User()}</SideCol>
    </Fragment>
  );
};
