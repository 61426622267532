import React from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

import { Card, ChartBg, ReminingChart, ChartData, Row, Col, VerticalLine, ChartStatistic } from "./styled";
import { numberWithCommas } from "../../../../helpers";
import theme from "models/themes";

export const RaisedChart = ({
  raisedVsToBeRaised: { raised, toBeRaised },
  assetSymbol,
  subscribers,
  minInvestment,
  maxInvestment,
  baseCurrency,
  valuation,
}) => {
  const progress = (parseFloat(raised) / (parseFloat(toBeRaised) + parseFloat(raised))).toFixed(3) * 100;

  return (
    <Card padding="0">
      <Row>
        <Col height="100%" padding="20px 25px" width="calc(50% - 1px)">
          <ChartData
            minInv
            heading="Min. Investment"
            baseCurrency={baseCurrency}
            val={numberWithCommas((minInvestment * valuation).toFixed())}
          />
          <ChartData
            minInv
            heading="Max. Investment"
            baseCurrency={baseCurrency}
            val={numberWithCommas((maxInvestment * valuation).toFixed())}
          />
          <ChartData heading="Subscribers" val={subscribers} />
        </Col>
        <VerticalLine />
        <Col height="100%" padding="20px 25px" width="50%" align="center">
          <ChartBg>
            <CircularProgressbar
              value={progress}
              strokeWidth={8}
              styles={buildStyles({
                strokeLinecap: "butt",
                pathTransitionDuration: 1,
                // Colors
                pathColor: theme.primary,
                trailColor: theme.primaryLight,
                backgroundColor: theme.primary,
              })}
            />
            <ReminingChart
              percent={progress.toFixed(progress === 100 ? 0 : 1)}
              raised={raised}
              toBeRaised={toBeRaised}
              assetSymbol={assetSymbol}
            />
          </ChartBg>

          <Row width="fit-content">
            <ChartStatistic bold margin="0 5px 0 0">
              {raised.toFixed()} {assetSymbol}
            </ChartStatistic>{" "}
            <ChartStatistic> of {(raised + toBeRaised).toFixed()}</ChartStatistic>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};
