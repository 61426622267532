import React, { Fragment } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import ReactDOM from "react-dom";
import { CircularProgress } from "@material-ui/core";

import { ReactComponent as CloseIcon } from "../../assets/x-circle.svg";

const Background = styled.section`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: ${({ bg = "rgba(0, 0, 0, 0.15)" }) => bg};
  backdrop-filter: blur(5px);
  display: flex;
  z-index: ${({ zIndex }) => (zIndex ? zIndex : 1000)};
`;

const Wrapper = styled.div`
  width: ${({ width }) => (width ? width : "440px")};
  min-height: ${({ noMinHeight }) => (noMinHeight ? 0 : "270px")};
  max-width: ${({ maxWidth }) => (maxWidth ? `${maxWidth}px` : "800px")};
  display: flex;
  flex-direction: column;
  background: ${({ bg = "#ffffff" }) => bg};
  border: 1px solid ${({ borderColor = "#f9f9f9" }) => borderColor};
  box-sizing: border-box;
  box-shadow: ${({ boxShadow = "0px 10px 40px rgba(89, 120, 150, 0.06)" }) => boxShadow};
  border-radius: 4px;
  margin: auto;
`;

const Header = styled.div`
  height: 52px;
  width: 100%;
  padding: 0 25px;
  background: #ffffff;
  border: 1px solid #f9f9f9;
  box-sizing: border-box;
  box-shadow: 0px 10px 40px rgba(89, 120, 150, 0.08);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  > svg {
    cursor: pointer;
    width: 24px;
    height: 24px;
  }
`;

const Content = styled.div`
  width: 100%;
  padding: 25px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Heading = styled.h5`
  font-family: Muli;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 135%;
  margin: 0;
  color: #181818;
`;

const MessageHeading = styled.h6`
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 135%;
  text-align: center;
  letter-spacing: -0.02em;
  margin: ${({ margin }) => (margin ? margin : 0)};
  color: ${({ error = false }) => (error ? "#FF4343" : "#019D52")};
`;

const Text = styled.p`
  margin: ${({ noMargin, margin }) => (noMargin ? 0 : margin ? margin : "15px 0 30px")};
  font-family: Muli;
  font-style: normal;
  font-weight: ${({ bold }) => (bold ? 700 : "normal")};
  font-size: ${({ fSize }) => (fSize ? `${fSize}px` : "18px")};
  line-height: 150%;
  text-align: ${(textAlign = "center") => textAlign};
  color: #333333;
`;

const Button = styled.button`
  outline: none;
  width: ${({ width }) => (width ? `${width}px` : "253px")};
  height: ${({ height }) => (height ? `${height}px` : "42px")};
  background: ${({ grey, theme: { primary } }) => (grey ? "transparent" : primary)};
  border-radius: 4px;
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 135%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  padding: 0;
  color: ${({ grey }) => (grey ? "#CACACA" : "#ffffff")};
  box-sizing: border-box;
  border: 1px solid ${({ grey, theme: { primary } }) => (grey ? "#CACACA" : primary)};
  cursor: pointer;
  &:disabled {
    background: #e3e3e3 !important;
    color: white !important;
    border: none;
    > svg {
      path {
        stroke: #fff;
      }
    }
  }
`;

const ModalLink = styled(Link)`
  text-decoration: none;
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 160%;
  color: ${({ theme: { primary } }) => primary};
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 8px;
  &:last-child {
    margin-bottom: 0;
    margin-top: 20px;
  }
  &:first-child {
    margin-top: 20px;
  }
`;

export const PaymentMessage = ({ isError = false, isOpen, onBackDpopClick, onClose }) => {
  const type = isError ? "failure" : "success";

  const MessagesContent = {
    success: {
      heading: "Request Sent",
      messageHeading: "Your request have been sent",
      message: "",
    },
    failure: {
      heading: "Error!",
      messageHeading: "Something Went Wrong",
      message: "Operation went wrong, try to repeat or refresh your page",
    },
  };

  const { heading, messageHeading, message } = MessagesContent[type];

  return isOpen ? (
    <Background onClick={isError ? onBackDpopClick : onClose}>
      <Wrapper noMinHeight onClick={(e) => e.stopPropagation()}>
        <Header>
          <Heading>{heading}</Heading>
          <CloseIcon onClick={isError ? onBackDpopClick : onClose} />
        </Header>
        <Content>
          <MessageHeading error={isError}>{messageHeading}</MessageHeading>
          <Text fSize={12}>
            {isError ? (
              message
            ) : (
              <Fragment>
                Shares will be sent to your account after the transaction is confirmed. You can check the status on “
                <ModalLink to="/investor/transactions/pending/all">Transactions</ModalLink>” page
              </Fragment>
            )}
          </Text>
          <Button width={200} height={36} onClick={isError ? onBackDpopClick : onClose}>
            CLOSE
          </Button>
        </Content>
      </Wrapper>
    </Background>
  ) : null;
};

export const ValidateOrderByUser = ({ onClose, isOpen }) => {
  return isOpen ? (
    <Background onClick={onClose}>
      <Wrapper noMinHeight onClick={(e) => e.stopPropagation()}>
        <Header>
          <Heading>Transfer Made</Heading>
          <CloseIcon onClick={onClose} />
        </Header>
        <Content>
          <MessageHeading>You have validated your transfer</MessageHeading>
          <Text fSize={12}>
            <Fragment>
              Shares will be sent to your account once the payment is received. You can check the status on “
              <ModalLink to="/investor/transactions/pending/all">Transactions</ModalLink>” page
            </Fragment>
          </Text>
          <Button width={160} height={36} onClick={onClose}>
            CLOSE
          </Button>
        </Content>
      </Wrapper>
    </Background>
  ) : null;
};

export const PayOnlineModal = ({ onSubmit, onCancel, paymentData, price = "", baseCurrency = "GBP", wait = false }) => {
  const { cashBalance, amount, assetSymbol, valuation } = paymentData;
  return (
    <Background zIndex={98} onClick={onCancel}>
      <Wrapper onClick={(e) => e.stopPropagation()}>
        <Header>
          <Heading>Make A payment</Heading>
          <CloseIcon onClick={onCancel} />
        </Header>
        <Content>
          <MessageHeading margin="0 0 20px 0">Purchase Details</MessageHeading>
          <Row>
            <Text fSize={14} noMargin>
              Shares
            </Text>
            <Text fSize={14} bold noMargin>
              {amount} {assetSymbol}
            </Text>
          </Row>
          <Row>
            <Text fSize={14} noMargin>
              Amount to tranfser
            </Text>
            <Text fSize={14} bold noMargin>
              {(amount * (price ? price : valuation)).toFixed(2)} {baseCurrency}
            </Text>
          </Row>
          <Row>
            <Text fSize={14} noMargin>
              Cash balance
            </Text>
            <Text fSize={14} bold noMargin>
              {cashBalance[baseCurrency].toFixed(2)} {baseCurrency}
            </Text>
          </Row>
          <Row>
            <Button width={160} height={36} onClick={onCancel} grey>
              Cancel order
            </Button>
            <Button width={160} height={36} onClick={onSubmit} disabled={wait}>
              Pay now
            </Button>
          </Row>
        </Content>
      </Wrapper>
    </Background>
  );
};

export const TransactionMessage = ({ isOpen, isError = false, message, onClose, customHeading = "" }) => {
  const type = isError ? "failure" : "success";

  const MessagesContent = {
    success: {
      heading: "Success sell order",
      messageHeading: "All right!",
    },
    failure: {
      heading: "Error!",
      messageHeading: "Something Went Wrong",
    },
  };

  const { heading, messageHeading } = MessagesContent[type];

  return isOpen ? (
    <Background onClick={onClose}>
      <Wrapper noMinHeight width="fit-content" onClick={(e) => e.stopPropagation()}>
        <Header>
          <Heading>{customHeading ? customHeading : heading}</Heading>
          <CloseIcon onClick={onClose} />
        </Header>
        <Content>
          <MessageHeading error={isError}>{messageHeading}</MessageHeading>
          <Text fSize={12}>{message}</Text>
          <Button width={200} height={36} onClick={onClose}>
            CLOSE
          </Button>
        </Content>
      </Wrapper>
    </Background>
  ) : null;
};

export const PortalLoader = () =>
  ReactDOM.createPortal(
    <Background bg="#fff">
      <Wrapper
        noMinHeight
        width="fit-content"
        onClick={(e) => e.stopPropagation()}
        bg="transparent"
        boxShadow="none"
        borderColor="transparent"
      >
        <Content>
          <CircularProgress />
        </Content>
      </Wrapper>
    </Background>,
    document.getElementById("globalModal500Error")
  );

export const PortalModal = ({ message, customHeading = "", type = "failure", onClose }) => {
  const MessagesContent = {
    success: {
      heading: "Success sell order",
      messageHeading: "All right!",
    },
    failure: {
      heading: "Error!",
      messageHeading: "Something Went Wrong",
    },
  };

  const { heading, messageHeading } = MessagesContent[type];
  return ReactDOM.createPortal(
    <Background onClick={onClose}>
      <Wrapper noMinHeight width="fit-content" onClick={(e) => e.stopPropagation()}>
        <Header>
          <Heading>{customHeading ? customHeading : heading}</Heading>
          <CloseIcon onClick={onClose} />
        </Header>
        <Content>
          <MessageHeading error={true}>{messageHeading}</MessageHeading>
          <Text fSize={12}>{message}</Text>
          <Button width={200} height={36} onClick={onClose}>
            CLOSE
          </Button>
        </Content>
      </Wrapper>
    </Background>,
    document.getElementById("globalModal500Error")
  );
};

export const ErrorMessage = ({ message, heading = "", onClose, maxWidth, textAlign, margin }) => {
  return (
    <Background onClick={onClose}>
      <Wrapper noMinHeight maxWidth={maxWidth} width="fit-content" onClick={(e) => e.stopPropagation()}>
        <Header>
          <Heading>{heading}</Heading>
          <CloseIcon onClick={onClose} />
        </Header>
        <Content>
          <Text margin={margin} textAlign={textAlign} fSize={12}>
            {message.replace("java.lang.IllegalArgumentException: ", "")}
          </Text>
          <Button width={200} height={36} onClick={onClose}>
            CLOSE
          </Button>
        </Content>
      </Wrapper>
    </Background>
  );
};
