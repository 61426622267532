import React, { useState, useEffect, useRef } from "react";
import moment from "moment";

import Api from "../../../../helpers/api";
import {
  Question,
  TopWrapper,
  TextWrapper,
  QHeader,
  TextBlock,
  UserAvatar,
  AnswerWrapper,
  AvatarHolder,
  AnswerText,
  More,
  FileBlock,
  FileName,
  FileControls
} from "./styled";
import { ErrorMessage } from "../../../admin-layout/errorMessage";
import { ReactComponent as Arrow } from "../../../../assets/chevron-down.svg";
import { File } from "components/common/icons-primary";
import { ReactComponent as Eye } from "../../../../assets/eye.svg";
import { ReactComponent as Download } from "../../../../assets/download.svg";
import { getTrackerHTML } from "../../../../data-room-panel/helpers/fileViewer";

export const CardAnswered = ({
  q: {
    assetId,
    category,
    textQuestion,
    userLogo,
    dateOfQuestion,
    userInvestorType,
    userGender,
    amlogo,
    aminvestorType,
    amgender,
    textAnswer,
    username,
    size,
    filename,
    folderName,
    amsize,
    amfilename,
    amfolderName,
    questionId,
    answerWasRead
  },
  token,
  currentUser
}) => {
  const [open, setOpen] = useState(true);
  const [height, setHeight] = useState(0);
  const [error, setError] = useState(null);
  const [readFlag, setReadFlag] = useState(
    answerWasRead ? answerWasRead : false
  );

  const textRef = useRef(null);

  const onReadMore = async _ => {
    setOpen(!open);
    if (!readFlag) {
      const res = await Api.onUserReadAnswer(token, questionId).catch(onError);
      if (res) {
        setReadFlag(true);
      }
    }
  };

  const onError = e => {
    setError(e);
  };

  useEffect(() => {
    if (textRef && textRef.current) {
      setHeight(textRef.current.offsetHeight);
      setOpen(false);
    }
  }, []);

  const onView = (folderName, filename) => async () => {
    getTrackerHTML(
      token,
      filename,
      `${folderName}/${filename}?assetId=${assetId}`,
      false,
      true,
      true,
      false
    );
  };

  const downloadDocument = (folderName, filename) => async () => {
    const fileUrl = await Api.downloadQADocument(
      token,
      folderName,
      filename,
      assetId,
      true
    ).catch(onError);

    if (fileUrl) {
      const link = document.createElement("a");
      link.target = "_blank";
      link.href = fileUrl;
      link.click();
    }
  };

  return (
    <Question>
      <TopWrapper>
        <UserAvatar
          small
          token={token}
          fileName={userLogo ? userLogo.fileNameWithExtension : null}
          hash={userLogo ? userLogo.secureHash : null}
          investorType={userInvestorType}
          gender={userGender}
        />

        <TextBlock>
          <QHeader>
            <h3>{category}</h3>
            {username === currentUser ? <h4>Your question</h4> : null}
            <span>{moment(dateOfQuestion).format("DD.MM.YYYY, hh:mm A")}</span>
          </QHeader>
          <TextWrapper>{textQuestion}</TextWrapper>
          {filename && size ? (
            <FileBlock isAnswered>
              <FileName>
                <File />
                <div>{filename}</div>
                <span>{(size / 1024).toFixed(2)} mb</span>
              </FileName>
              <FileControls>
                <Eye onClick={onView(folderName, filename)} />
                <Download onClick={downloadDocument(folderName, filename)} />
              </FileControls>
            </FileBlock>
          ) : null}
        </TextBlock>
      </TopWrapper>
      <AnswerWrapper>
        <AvatarHolder>
          <UserAvatar
            small
            token={token}
            fileName={amlogo ? amlogo.fileNameWithExtension : null}
            hash={amlogo ? amlogo.secureHash : null}
            investorType={aminvestorType}
            gender={amgender}
          />
        </AvatarHolder>
        <AnswerText ref={textRef} height={height} open={open}>
          {textAnswer}
          {amfilename && amsize ? (
            <FileBlock isAnswered>
              <FileName>
                <File />
                <div>{amfilename}</div>
                <span>{(size / 1024).toFixed(2)} mb</span>
              </FileName>
              <FileControls>
                <Eye onClick={onView(amfolderName, amfilename)} />
                <Download
                  onClick={downloadDocument(amfolderName, amfilename)}
                />
              </FileControls>
            </FileBlock>
          ) : null}
        </AnswerText>

        <More onClick={onReadMore} open={open}>
          {open ? "HIDE ANSWER" : "SHOW ANSWER"}
          <Arrow />
        </More>
      </AnswerWrapper>
      {error && <ErrorMessage er={error} onClose={() => setError(null)} />}
    </Question>
  );
};
