import MainApi from "helpers/api";

export default class Api {
  static url = MainApi.url;

  static origin = MainApi.origin;

  static sendIntialMails(token, formData) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/asset/message/permittedInvestors`, {
        method: "post",
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
        },
        body: formData,
      });
      if (result.status === 200) {
        await result.text();
        res("success");
      } else rej(await result.json());
    });
  }

  static sendNewInvestorsMails(token, formData) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/asset/message/newInvestorsNotification`, {
        method: "post",
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
        },
        body: formData,
      });
      if (result.status === 200) {
        await result.text();
        res("success");
      } else rej(await result.json());
    });
  }
}
