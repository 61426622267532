import React, { Fragment, useState, useEffect } from "react";
import moment from "moment";

import {
  Col,
  Card,
  Row,
  Heading,
  Text,
  Line,
  Label,
  TextInfo,
  InfoBlock,
  Link,
  Status,
  MoreInfo,
  TEXT_FIELD_SIZE,
} from "./styled.js";
import Api from "../../../../helpers/api";
import { Button } from "../../../investor-layout/pages/Offerings/styled";
import { TextFieldComponent } from "../../../admin-layout/forms";
import { numberWithCommas } from "helpers/index.js";

export const OrderDetails = ({
  history,
  assetId,
  res: {
    tx,
    ["Swift/BIC"]: bic,
    IBAN,
    ["Company account number"]: accountNumber,
    AccountHilderName,
    BankName,
    ["Amount to pay"]: amount,
    TokenQuantity,
    TokenIdentifier,
    buyerAccountNumber,
    AmountForIssuerRequest,
    txDate,
  },
  asset: {
    assetProperties: { assetName },
  },
  token,
  onError,
}) => {
  const [wait, setWait] = useState(false);

  const makePayment = async () => {
    setWait(true);
    const res = await Api.makeTransaction(token, accountNumber, buyerAccountNumber, tx, AmountForIssuerRequest).catch(
      (e) => {
        setWait(false);
        onError(e);
      }
    );
    if (res) history.push("/investor/transactions/all/all");
  };

  const goToChat = (id) => () => {
    const link = document.createElement("a");
    link.href = `${window.location.origin}/investor/chat/${id}`;
    link.target = "_blank";
    link.click();
  };

  return (
    <Fragment>
      <Card>
        <Row margin="0 0 14px 0">
          <Heading>Waiting for Payment Approval</Heading>
          <Text fSize={18} color="rgba(24, 24, 24, 0.4)" margin="0 0 0 auto">
            3 / 3
          </Text>
        </Row>
        <Row margin="0 0 25px 0">
          <Text lineH="160%" fSize={14} color="#7C7C7C">
            When the platform admin will approve your transaction your{" "}
            <span>
              {numberWithCommas(TokenQuantity)} {TokenIdentifier}
            </span>{" "}
            shares will appear in your account.
          </Text>
        </Row>
        <Label>Your Transaction</Label>
        <InfoBlock>
          <Col width="230px">
            <TextInfo>
              Shares Purchase for
              <br />
              <Link to={`/investor/offerings/companies/${assetId}`}>{assetName}</Link>
            </TextInfo>
          </Col>
          <Col width="fit-content">
            <TextInfo grey>{amount}</TextInfo>
            <TextInfo>
              {numberWithCommas(TokenQuantity)} {TokenIdentifier}
            </TextInfo>
          </Col>
          <Status />
          <Col width="fit-content">
            <TextInfo>{moment(txDate).format("dddd, DD/MM/YYYY")}</TextInfo>
            <TextInfo grey>{moment(txDate).format("hh:mm:ss A")}</TextInfo>
          </Col>
          <MoreInfo />
        </InfoBlock>
      </Card>
      <Card>
        <Row margin="0 0 14px 0">
          <Heading>Bank Transfer to Invest</Heading>
        </Row>
        <Row margin="0 0 20px 0">
          <Text lineH="160%" fSize={14} color="#7C7C7C">
            Please use the below Bank information details to transfer the money and receive your shares.
          </Text>
        </Row>
        <Row margin="0 0 20px 0" justify="space-between" padding="0">
          <TextFieldComponent
            width="178px"
            label="Amount to tranfser"
            {...TEXT_FIELD_SIZE}
            isInvestor
            disabled
            defaultValue={numberWithCommas(amount)}
          />
          <TextFieldComponent
            width="calc(100% - 202px)"
            label="Account Holder Name"
            {...TEXT_FIELD_SIZE}
            isInvestor
            disabled
            defaultValue={AccountHilderName}
          />
        </Row>
        <Row margin="0 0 14px 0" justify="space-between" padding="0">
          <TextFieldComponent
            width="178px"
            label="BIC (SWIFT) CODE"
            {...TEXT_FIELD_SIZE}
            isInvestor
            disabled
            defaultValue={bic}
          />
          <TextFieldComponent
            width="calc(100% - 202px)"
            label="IBAN"
            {...TEXT_FIELD_SIZE}
            isInvestor
            disabled
            defaultValue={IBAN}
          />
        </Row>
        <Row>
          <TextFieldComponent
            width="100%"
            label="Beneficiary’s bank"
            {...TEXT_FIELD_SIZE}
            isInvestor
            disabled
            defaultValue={BankName}
          />
        </Row>
        <Line />
        <Row>
          <Text fSize={18}>
            Total shares: <span>{numberWithCommas(TokenQuantity) + " " + TokenIdentifier}</span>
          </Text>
        </Row>
      </Card>
      <Card noPadding height={62}>
        <Row justify="space-between">
          <Button
            transparent
            height={42}
            width={287}
            bold
            size={14}
            margin="10px 0 10px 30px"
            onClick={goToChat(assetId)}
          >
            CHAT WITH ADMIN
          </Button>
          {/* <Button
            height={42}
            width={287}
            bold
            size={14}
            margin="10px 30px 10px 0"
            onClick={makePayment}
            disabled={wait}
          >
            PAY NOW
          </Button> */}
        </Row>
      </Card>
    </Fragment>
  );
};
