import React, { useRef, useEffect } from "react";
import { Step } from "./step";
import { StepsWrapper, SlotsWrapper, Slider, Slot } from "./styled";
import { useState } from "react";

import { newDealArr, existDealArr } from "./stepper.const";

export const Stepper = ({ step, dealType }) => {
  const stepsArray = dealType === "NEW_DEAL" ? newDealArr : existDealArr;
  return (
    <StepsWrapper>
      {stepsArray.map(({ text, key }, i) => {
        return <Step key={key} stepNumber={i} step={step} text={text} />;
      })}
    </StepsWrapper>
  );
};

export const Slots = ({ step, components = [], dealType, h }) => {
  const [oneStepWidth, setOneStepWidth] = useState(0);
  const wrapper = useRef(null);

  useEffect(() => {
    if (wrapper && wrapper.current) {
      const { width } = wrapper.current.getBoundingClientRect();
      setOneStepWidth(width);
    }
  }, []);

  const stepsArray = dealType === "NEW_DEAL" ? newDealArr : existDealArr;

  return (
    <SlotsWrapper h={h} ref={wrapper}>
      <Slider
        width={stepsArray.length * oneStepWidth}
        position={oneStepWidth * step}
      >
        {components.map((child, i) => {
          return (
            <Slot
              key={stepsArray[i].key}
              width={oneStepWidth}
              active={step === i}
            >
              {child}
            </Slot>
          );
        })}
      </Slider>
    </SlotsWrapper>
  );
};
