import styled from "styled-components";

export const DashBoardCard = styled.div`
  ${({ width }) => (width ? `width: ${width};` : "")};
  height: ${({ small, height }) => (small ? "164px" : `${height}px`)};
  padding: 20px 24px;
  background: ${({ dark }) => (dark ? "#222D4B" : "#ffffff")};
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow-x: ${({ scroll }) => (scroll ? "scroll" : "auto")};
`;

export const FlexRow = styled.div`
  width: ${({ width }) => (width ? `${width}px` : "100%")};
  height: ${({ height }) => (height ? `${height}px` : "")};
  display: flex;
  align-items: ${({ align }) => (align ? align : "center")};
  justify-content: ${({ justify }) => (justify ? justify : "space-between")};
  margin-bottom: ${({ mBottom }) => (mBottom ? `${mBottom}px` : "30px")};
`;

export const SepareteLine = styled.div`
  height: 20px;
  border-top: 1px solid #f6f7fc;
  width: calc(100% + 50px);
  transform: translateX(-25px);
  margin-top: auto;
`;

export const Column = styled.div`
  display: flex;
  justify-content: ${({ justify }) => (justify ? justify : "flex-start")};
  flex-direction: column;
  ${({ width }) => (width ? `width: ${width};` : "")}
  ${({ height }) => (height ? `height: ${height};` : "")}
  ${({ mLeft }) => (mLeft ? `margin-left: ${mLeft}px;` : "")}
  ${({ extra }) => extra};
`;

export const CardText = styled.span`
  white-space: ${({ noWrap }) => (noWrap ? "nowrap" : "normal")};
  font-weight: ${({ fWeight = 300 }) => fWeight};
  font-size: ${({ fontSize = "12px" }) => fontSize};
  color: ${({ color }) => color || "#3F3F3F"};
  ${({ mBottom }) => (mBottom ? `margin-bottom: ${mBottom}px;` : "")}
  ${({ mLeft }) => (mLeft ? `margin-left: ${mLeft}px;` : "")}
`;

export const DescriptionDot = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: ${({ bg = "transparent" }) => bg};
`;

export const Avatar = styled.div`
  width: ${({ small }) => (small ? "14px" : "18px")};
  height: ${({ small }) => (small ? "14px" : "18px")};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${({ small }) => (small ? "0 4px 0 0" : "0 6px")};
  & > img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
  }
`;

export const NoAvatar = styled.div`
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: transparent;
`;

export const AvatarText = styled.div`
  text-transform: none;
  font-weight: bold;
  font-size: ${({ small }) => (small ? "12px" : "14px")};
  line-height: 135%;
  color: ${({ theme: { primary } }) => primary};
`;

export const ManagerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  > svg {
    filter: grayscale(${({ theme: { greyFilter } }) => greyFilter});
  }
`;

export const Line = styled.div`
  width: 100px;
  opacity: 0.3;
  border: 0.5px solid #ffffff;
`;

export const EditButton = styled.div`
  width: 97.62px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ active }) => (active ? "#14CF32" : "#ffffff")};
  border-radius: 4px;
  font-size: 14px;
  color: ${({ active }) => (active ? "#ffffff" : "#3f3f3f")};
  cursor: pointer;
`;
