import React, { Fragment } from "react";
import moment from "moment";

import { HeaderTD } from "../../dealTypeCard";
import { Btn } from "../../buttons";
import {
  TableRow,
  TableCol,
  UserAvatar,
  DotsButtonGroup,
  GroupLink,
} from "./styled";

export const GroupRow = ({
  id,
  name,
  users,
  logoHash,
  logoFileName,
  lastEdition,
  token,
  callback,
  toggleAssignModal,
  onRefresh,
}) => {
  const count = users.length;
  return (
    <Fragment>
      <TableRow justify="space-between">
        <HeaderTD
          width="calc(100% - 618px)"
          boxSizing="border-box"
          padding="0 0 0 25px"
        >
          <UserAvatar
            isGroup
            fileName={logoFileName}
            hash={logoHash}
            token={token}
          />
          <TableCol heading={name} />
        </HeaderTD>
        <HeaderTD boxSizing="border-box" padding="0 25px" width="150px">
          {`${!count ? "No" : count} user${!count || count > 1 ? "s" : ""}`}
        </HeaderTD>
        <HeaderTD boxSizing="border-box" padding="0 25px" width="150px">
          <TableCol
            heading={
              lastEdition
                ? moment(new Date(lastEdition)).format("DD MMM YYYY")
                : "Not Modified"
            }
            marginBottom={lastEdition ? "7px" : "0px"}
            text={
              lastEdition
                ? moment(new Date(lastEdition)).format("hh:mm a")
                : null
            }
          />
        </HeaderTD>
        <HeaderTD boxSizing="border-box" width="322px" padding="0 25px">
          <Btn
            transparent
            width={120}
            margin="0 12px 0 0"
            onClick={() => {
              toggleAssignModal(id);
            }}
          >
            assign users
          </Btn>

          <GroupLink to={`/admin/users/group/${id}`}>
            <Btn token={token}>more</Btn>
          </GroupLink>

          <DotsButtonGroup
            token={token}
            id={id}
            callback={callback}
            onRefresh={onRefresh}
          />
        </HeaderTD>
      </TableRow>
    </Fragment>
  );
};
