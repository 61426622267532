export function getRandomString(length) {
  var randomChars =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var result = "";
  for (var i = 0; i < length; i++) {
    result += randomChars.charAt(
      Math.floor(Math.random() * randomChars.length)
    );
  }
  return result + "-" + String(Date.now());
}

export function countFields(arr = []) {
  let count = 0;
  arr.forEach((s) => {
    count += s.fields.length;
  });
  return count;
}
