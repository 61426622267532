import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import { getAssets } from "../../../../modules/dataRedux/dataAction";
import { setPaymentInfo } from "../../../../modules/userRedux/userAction";

import Api from "../../../../helpers/api";

import { Wrapper, Col, Card, Header, BorderBlock, Heading } from "./styled.js";
import { Button } from "../../../investor-layout/pages/Offerings/styled";
import { ReactComponent as BackIcon } from "../../../../assets/arrowleft.svg";
import { Loader } from "../../styled/Loader";

import { Amount } from "./Amount";
import { CountDown } from "./CountDown";
import { Raised } from "./Raised";
import { Price } from "./Price";
import { BankDetails } from "./BankDetailsModal";
import { AddBankAccount } from "./AddBankAccount";

export const NewInvestment = ({
  token,
  history,
  getAssets,
  asset,
  match: {
    params: { id },
  },
  setPaymentInfo,
}) => {
  const [bankDetails, setBankDetails] = useState(null);
  const [cashBalance, setCashBalance] = useState(null);
  const [convert, setConvert] = useState("");
  const [type, setType] = useState("bank");
  const [amount, setAmount] = useState("");
  const [bankDetailsOpen, openBankDetails] = useState(false);
  const [activeAccount, setActiveAccount] = useState(null);
  const [inputError, setInputError] = useState(false);
  const [addAccountOpen, setAddAccountOpen] = useState(false);

  const onChangeAddStatus = () => setAddAccountOpen(!addAccountOpen);

  useEffect(() => {
    if (!asset) getAssets(token);
  }, []);

  useEffect(() => {
    if (asset) {
      Promise.all([
        Api.getCashBalance(token),
        Api.getBankDetails(token),
        Api.convertTokens(
          token,
          id,
          1,
          asset.assetFinancialProperty.baseCurrency
        ),
      ]).then((data) => {
        setCashBalance(data[0]);
        setBankDetails(data[1]);
        setConvert(data[2]);
      });
    }
  }, [asset]);

  useEffect(() => {
    if (bankDetails) {
      setActiveAccount(bankDetails[0].accountNumber);
    }
  }, [bankDetails]);

  const changeModalStatus = () => openBankDetails(!bankDetailsOpen);

  if (asset && cashBalance && convert) {
    const {
      intentionToBuy,
      assetProperties: { assetName, assetSymbol },
      assetFinancialProperty: {
        timeOfEnd,
        acceptedCurrency,
        minInvestment,
        maxInvestment,
        valuation,
        baseCurrency,
      },
      raisedVsToBeRaised: { raised, toBeRaised, currency },
    } = asset;

    const onSubmit = (assetData) => {
      const saveData = {
        dealType: "company",
        assetId: id,
        amount,
        assetSymbol,
        assetName,
        type,
        timeOfEnd,
        raised,
        toBeRaised,
        valuation,
        price: convert,
        cashBalance,
        activeAccount,
        baseCurrency,
      };

      if (typeof assetData === "object") {
        Object.keys(saveData).forEach((key) => {
          if (typeof assetData[key] !== "undefined") {
            saveData[key] = assetData[key];
          }
        });
      }

      setPaymentInfo(saveData);
      history.push(`/investor/offerings/sign-contract/${id}`);
    };

    const onAddNewAccount = () => {
      changeModalStatus();
      onChangeAddStatus();
    };

    const onSuccessAddAccount = (newAcc) => {
      setActiveAccount(newAcc.accountNumber);
      onSubmit({ activeAccount: newAcc.accountNumber });
    };

    return (
      <Wrapper>
        <Header>
          <BorderBlock>
            <Button
              transparent
              height={36}
              size={12}
              width={176}
              onClick={() =>
                history.push(`/investor/offerings/companies/${id}`)
              }
            >
              <BackIcon style={{ marginRight: 8 }} />
              Back to Deal
            </Button>
          </BorderBlock>
          <Heading>Invest in {assetName}</Heading>
        </Header>
        <Col width="calc(100% - 470px)">
          <Amount
            assetSymbol={assetSymbol}
            acceptedCurrency={acceptedCurrency}
            currency={currency}
            intentionToBuy={intentionToBuy}
            minInvestment={minInvestment}
            maxInvestment={maxInvestment}
            valuation={valuation}
            type={type}
            onChangeType={setType}
            cashBalance={cashBalance}
            onAmountChange={setAmount}
            onError={setInputError}
            baseCurrency={baseCurrency}
          />
          <Card noPadding height={62}>
            <Button
              disabled={amount < minInvestment || inputError}
              height={42}
              width={287}
              bold
              size={14}
              margin="10px 0 10px 30px"
              onClick={type === "bank" ? changeModalStatus : onSubmit}
            >
              NEXT STEP
            </Button>
          </Card>
        </Col>
        <Col>
          <CountDown timeOfEnd={timeOfEnd} />
          <Raised
            raised={raised}
            toBeRaised={toBeRaised}
            baseCurrency={baseCurrency}
          />
          <Price convert={convert} assetSymbol={assetSymbol} />
        </Col>

        <BankDetails
          bankDetails={bankDetails}
          isOpen={bankDetailsOpen}
          history={history}
          onSubmit={() => onSubmit()}
          onBackDropClick={changeModalStatus}
          callBack={(accountNumber) => setActiveAccount(accountNumber)}
          onAddNewAccount={onAddNewAccount}
        />
        <AddBankAccount
          isOpen={addAccountOpen}
          onBackDropClick={onChangeAddStatus}
          token={token}
          callBack={(newAcc) => onSuccessAddAccount(newAcc)}
        />
      </Wrapper>
    );
  } else {
    return <Loader />;
  }
};

export default connect(
  (
    { userReducer: { token }, dataReducer: { assets = [] } },
    {
      match: {
        params: { id },
      },
    }
  ) => {
    return { token, asset: assets.find((e) => e.linearId === id), id };
  },
  { getAssets, setPaymentInfo }
)(NewInvestment);
