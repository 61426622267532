import React from "react";

import {
  ModalBackground,
  SendEmailWrapper,
  Heading,
  ModalHeader,
  CardUser,
  CardInstitution,
  CardContainer
} from "./styled";
import { ReactComponent as CloseIcon } from "../../../../assets/x-circle.svg";

export const ChooseUserType = ({ onBackdropClick, history }) => {
  return (
    <ModalBackground onClick={onBackdropClick}>
      <SendEmailWrapper width="676px" onClick={e => e.stopPropagation()}>
        <ModalHeader>
          <Heading>Add New</Heading>
          <CloseIcon onClick={onBackdropClick} />
        </ModalHeader>
        <CardContainer padding="20px 25px">
          <CardUser history={history} />
          <CardInstitution history={history} />
        </CardContainer>
      </SendEmailWrapper>
    </ModalBackground>
  );
};
