import styled from "styled-components";

export const FileNameBlock = styled.div`
  width: fit-content;
  min-width: 200px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid ${({ theme: { primary } }) => primary};
  box-sizing: border-box;
  height: 42px;
  padding: 0 15px;
  border-radius: 4px;
`;

export const NameCont = styled.div`
  display: flex;
  align-items: center;
  > svg {
    margin-right: 6px;
    width: 14px;
  }
`;

export const Name = styled.div`
  font-family: Muli;
  font-size: 12px;
  line-height: 135%;
  letter-spacing: -0.02em;
  color: #181818;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  max-width: 270px;
`;

export const h3Style = {
  textAlign: "left",
  fontWeight: "bold",
  color: "#181818",
  marginSize: "0 0 25px 0",
};

export const Text = styled.p`
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  color: #333333;
  margin: 0;
`;
