import React, { useState, useEffect } from "react";
import { CircularProgress } from "@material-ui/core";

import { Main, TextSub } from "../../DealForm/Step/styled";
import { FlexRow } from "../../../dashBoardCard";
import { ScrollContainer, SignedDoc } from "../styled";
import { EmptyInfo } from "../common/emptyInfo";
import { ReactComponent as Mess } from "assets/message-square-thin.svg";
import { LeftBlock } from "../styled";
import { Btn } from "../../../buttons";
import { Upload } from "components/common/icons-primary";

import Api from "../../../../../helpers/api";
import { ErrorMessagePortal } from "components/admin-layout/errorMessage";

export const SignedDocuments = ({ token, newWorkflow, id }) => {
  const [docs, setDocs] = useState(null);
  const [users, setUsers] = useState([]);
  const [wait, setWait] = useState(false);
  const [error, setError] = useState(null);

  const getIitial = async () => {
    if (newWorkflow) {
      const res = await Api.getUsersProgress(token, id);
      if (res) {
        const _docs = res
          .filter((u) => u.signedDocumentsDetailed)
          .map(
            ({
              logo,
              userId,
              fullName,
              signedDocumentsDetailed: { intendedQuantityToBuy, invested, documentAndDate },
            }) => ({
              logo,
              fullname: fullName,
              loiValue: intendedQuantityToBuy,
              spaSigned: invested,
              documentAndDate,
              userId,
            })
          );
        const _users = res
          .filter((el) => el.signedDocumentsDetailed && el.signedDocuments && el.signedDocuments.length)
          .map((el) => ({ userId: el.userId, fullName: el.fullName }));
        setUsers(_users);
        setDocs(_docs);
      }
    } else {
      const _investors = await Api.getAssetInvestors(token, id);
      if (_investors) {
        const p = _investors.map((e) => {
          return Api.getInvestorSignedDocs(token, e, id);
        });
        const res = await Promise.all(p);
        setDocs(res);
      }
    }
  };

  useEffect(() => {
    getIitial();
  }, []);

  const renderDocs = () => {
    if (docs) {
      return docs.map((e) => {
        if (Object.keys(e).length) {
          return (
            <SignedDoc
              isSigned
              token={token}
              doc={e}
              assetId={id}
              newWorkflow={newWorkflow}
              onError={(e) => onError(e)()}
            />
          );
        }
      });
    }
  };

  const onError = (e = null) => () => {
    wait && setWait(false);
    setError(e);
  };

  const onDownLoadAll = async () => {
    setWait(true);
    const res = await Api.getAllSignedDocuments(token, id, users).catch((e) => onError(e)());
    if (res) {
      const link = document.createElement("a");
      link.href = URL.createObjectURL(res.file);
      link.download = res.fileName;
      link.click();
      setWait(false);
    }
  };

  return (
    <Main currentHeight="326px" padding="20px 25px 15px 25px">
      <FlexRow mBottom="0" align="flex-start">
        <TextSub textAlign="left" fontWeight="bold" color="#181818" marginSize="0 0 25px 0">
          Signed documents
        </TextSub>
      </FlexRow>
      {newWorkflow ? (
        <LeftBlock>
          <Btn width={243} transparent disabled={wait || !users.length} onClick={onDownLoadAll}>
            <Upload /> DOWNLOAD ALL SIGNED DOCS
          </Btn>
        </LeftBlock>
      ) : null}

      {!docs ? (
        <CircularProgress size={24} style={{ margin: "auto" }} />
      ) : !docs.length ? (
        <EmptyInfo
          icon={<Mess />}
          heading="There are no signed documents yet"
          text="When we’ll have documents to show for you we’ll show them here"
        />
      ) : (
        <ScrollContainer height={325}>{renderDocs()}</ScrollContainer>
      )}
      {error && <ErrorMessagePortal er={error} onClose={onError(null)} />}
    </Main>
  );
};
