import React, { useState, useEffect, Fragment } from "react";
import Flags from "country-flag-icons/react/3x2";
import { hasFlag } from "country-flag-icons";

import { TextSub, FlexRow, ChartData, CountryRow, Others, ValueField, NoFlag } from "./styled";
import { PieChart } from "react-minimal-pie-chart";
import { ReactComponent as MoreIcon } from "../../../../assets/more-horizontal.svg";
import { Column } from "../../dashBoardCard";

const extraCss = `height: 100%;
                  box-sizing: border-box;
                  padding: 18px 0;`;

export const ViewsByCountries = ({ viewsByCountries }) => {
  const [firstThreeCountries, setFirstThreeCountries] = useState([]);
  const [otherCountries, setOtherCountries] = useState([]);
  const [onlyThreeCountries, setOnlyThreeCountries] = useState([]);

  const sortData = (arr) => {
    arr.sort((a, b) => (a.percent < b.percent ? 1 : -1));
  };

  const getOtherCountriesStat = (countries) => {
    let stat = 0;
    countries.forEach(({ views }) => {
      stat += views;
    });
    return stat;
  };

  const getOtherCountriesPercent = (countries) => {
    let stat = 0;
    countries.forEach(({ percent }) => {
      stat += percent;
    });
    return stat;
  };

  const colors = (id) => {
    switch (id) {
      case 0:
        return "#4676AA";

      case 1:
        return "#BFDCFC";

      case 2:
        return "#3F4B78";

      case 3:
        return "#6371A4";
    }
  };

  const setDataForPieChart = (onlyThreeCountries, firstThreeCountries, stat) => {
    let data = [];
    if (onlyThreeCountries.length) {
      onlyThreeCountries.forEach(({ percent }, id) => {
        data = [
          ...data,
          {
            value: percent,
            color: colors(id),
          },
        ];
      });
      return data;
    } else
      firstThreeCountries.forEach(({ percent }, id) => {
        data = [
          ...data,
          {
            value: percent,
            color: colors(id),
          },
        ];
      });
    data = [
      ...data,
      {
        value: stat,
        color: colors(3),
      },
    ];
    return data;
  };

  useEffect(() => {
    sortData(viewsByCountries);
    if (viewsByCountries.length > 3) {
      setFirstThreeCountries(viewsByCountries.slice(0, 3));
      setOtherCountries(viewsByCountries.slice(3));
    } else setOnlyThreeCountries(viewsByCountries);
  }, [viewsByCountries]);

  return (
    <Fragment>
      <TextSub textAlign="left" fontWeight="bold" color="#181818" marginSize="0 0 17px 0">
        Views by Countries
      </TextSub>
      <FlexRow mBottom="17" justify="flex-start" align="flex-start">
        <Column>
          <PieChart
            data={setDataForPieChart(onlyThreeCountries, firstThreeCountries, getOtherCountriesPercent(otherCountries))}
            style={{
              width: 112,
              height: 112,
            }}
          />
        </Column>
        <Column mLeft={24} justify="flex-start" extra={extraCss}>
          <ChartData
            onlyThreeCountries={onlyThreeCountries}
            firstThreeCountries={firstThreeCountries}
            otherCountries={otherCountries}
            colors={colors}
          />
        </Column>
      </FlexRow>
      <FlexRow direction="column" align="flex-start" mBottom="0">
        {onlyThreeCountries.length
          ? onlyThreeCountries.map(({ country, percent, views, countryCode }) => {
              const v = countryCode;
              const Flag = Flags[v];
              return (
                <CountryRow key={`row-${country}`}>
                  <span>
                    {hasFlag(countryCode) ? <Flag title={country} className="flag-small" /> : <NoFlag />}
                    {`${country} (${percent}%)`}
                  </span>
                  <ValueField>{views}</ValueField>
                </CountryRow>
              );
            })
          : firstThreeCountries.map(({ country, percent, views, countryCode }) => {
              const v = countryCode;
              const Flag = Flags[v];
              return (
                <CountryRow key={`row-${country}`}>
                  <span>
                    {hasFlag(countryCode) ? <Flag title={country} className="flag" /> : <NoFlag />}
                    {`${country} (${percent}%)`}
                  </span>
                  <ValueField>{views}</ValueField>
                </CountryRow>
              );
            })}
        {otherCountries.length ? (
          <CountryRow>
            <Others>
              <div className="other-modal">
                {otherCountries.map(({ country, percent, views, countryCode }) => {
                  const v = countryCode;
                  const Flag = Flags[v];
                  return (
                    <CountryRow key={`row-${country}`}>
                      <span>
                        {hasFlag(countryCode) ? <Flag title={country} className="flag" /> : <NoFlag />}
                        {`${country} (${percent}%)`}
                      </span>
                      <ValueField>{views}</ValueField>
                    </CountryRow>
                  );
                })}
              </div>
              <MoreIcon />
              {`Other Countries (${getOtherCountriesPercent(otherCountries)}%)`}
            </Others>
            <ValueField>{getOtherCountriesStat(otherCountries)}</ValueField>
          </CountryRow>
        ) : null}
      </FlexRow>
    </Fragment>
  );
};
