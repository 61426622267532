import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";

import { ReactComponent as Check } from "../../assets/check.svg";
import { ReactComponent as CheckIcon } from "../../assets/check-icon.svg";
import { ReactComponent as XIcon } from "../../assets/x-icon.svg";
import { Button } from "components/investor-layout/pages/Offerings/styled";

export const FormCol = styled.div`
  width: 440px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  box-sizing: border-box;
  padding-bottom: 14px;
`;

export const FlexRow = styled.div`
  display: flex;
  justify-content: ${({ justify }) => justify};
  margin: ${({ margin }) => margin};
`;

export const HalfCont = styled.div`
  width: calc(50% - 15px);
`;

export const Heading = styled.h1`
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 135%;
  margin: 0 0 40px 0;
  color: ${({ error }) => (error ? "#FF4343" : "#181818")};
`;

export const InputCont = styled.div`
  width: ${({ width }) => (width ? width : "100%")};
  height: 62px;
  position: relative;
  margin-bottom: 16px;
  &:last-child {
    margin-bottom: 0;
  }
  > svg {
    position: absolute;
    top: 22px;
    left: 26px;
    z-index: 2;
    path {
      stroke: ${({ error, done, theme: { primary, red, green } }) => (error ? red : done ? green : primary)};
    }
  }
`;

export const Input = styled.input`
  width: 100%;
  height: ${({ show = true }) => (show ? "62px" : 0)};
  border: 1px solid ${({ error }) => (error ? "#FF4343" : "#efefef")};
  border-radius: 4px;
  box-sizing: border-box;
  outline: none;
  padding: 0 ${({ pRight }) => (pRight ? `${pRight}px` : "30px")} 0 65px;
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 135%;
  letter-spacing: -0.02em;
  transition: height 0.15s linear;
  color: ${({ error }) => (error ? "#FF4343" : "#181818")};
  -webkit-text-fill-color: ${({ error }) => (error ? "#FF4343" : "#181818")};

  &::placeholder {
    font-family: Muli;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 135%;
    letter-spacing: -0.02em;
    color: rgba(24, 24, 24, 0.4);
  }
`;

export const ForgetPass = styled(Link)`
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 135%;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  text-decoration: none;
  color: ${({ theme: { primary } }) => primary};
  position: absolute;
  right: 25px;
  top: 25px;
`;

export const Submit = styled(Button)`
  width: 100%;
  height: 52px;
  background: ${({ theme: { primary } }) => primary};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${({ noMargin }) => (noMargin ? 0 : "40px")};
  font-family: Muli;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 135%;
  letter-spacing: -0.02em;
  color: #ffffff;
  outline: none;
  cursor: pointer;
  transition: all 0.1s linear;
  padding: 0;
  &:disabled {
    background: #e3e3e3 !important;
    color: white !important;
    > svg {
      path {
        stroke: #fff;
      }
    }
  }
`;

export const CancelButton = styled.button`
  width: 100%;
  height: 53px;
  border: 1px solid #efefef;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  cursor: pointer;
  font-family: Muli;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 135%;
  letter-spacing: -0.02em;
  color: rgba(24, 24, 24, 0.4);
`;

export const FormBottom = styled.div`
  margin-top: auto;
  padding-top: 50px;
  border-top: 1px solid rgba(24, 24, 24, 0.1);
`;

export const LegalInfo = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 135%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  margin-bottom: 25px;
  color: rgba(24, 24, 24, 0.4);
`;

export const FooterLinks = styled(Link)`
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 135%;
  text-decoration: none;
  letter-spacing: -0.02em;
  margin-right: 40px;
  color: #181818;
`;

export const SignUpTxt = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 135%;
  text-align: ${({ left }) => (left ? "left" : "center")};
  letter-spacing: -0.02em;
  margin: ${({ margin }) => (margin ? margin : "30px 0")};
  color: ${({ color }) => (color ? color : "#333333")};
`;

export const SignUpLink = styled(Link)`
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 135%;
  text-align: center;
  letter-spacing: -0.02em;
  margin-top: 30px;
  color: ${({ theme: { primary } }) => primary};
  margin-left: 5px;
  text-decoration: none;
  padding-bottom: 3px;
`;

export const InfoBlock = styled.div`
  height: ${({ hide = false }) => (hide ? 0 : "auto")};
  background: ${({ theme: { blurBg } }) => blurBg};
  border-radius: 4px;
  padding: ${({ hide = false }) => (hide ? 0 : "16px 50px 16px 25px")};
  box-sizing: border-box;
  margin-top: ${({ hide = false }) => (hide ? 0 : "32px")};
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 135%;
  letter-spacing: -0.02em;
  color: #181818;
  display: flex;
  justify-content: space-between;
  position: relative;
  transition: all 0.2s linear;
  > div {
    width: 80%;
  }
  > svg {
    position: absolute;
    top: 16px;
    right: 25px;
    width: 20px;
    height: 20px;
    path {
      stroke: ${({ theme: { primary } }) => primary};
    }
  }
`;

export const WaitingWrapper = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.3);
  z-index: 1001;
  color: ${({ theme: { primary } }) => primary};
`;

const RequiredCheckBox = styled.div`
  overflow: hidden;
  width: 18px;
  height: 18px;
  margin-right: 14px;
  background: ${({ checked }) => (checked ? "transparent" : "#f3f3f3")};
  border: 1px solid ${({ checked, theme: { primary } }) => (checked ? primary : "#EFEFEF")};
  box-sizing: border-box;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  > svg {
    width: 14px;
    height: 14px;
    transform: ${({ checked }) => (checked ? "rotateY(0)" : "rotateY(90deg)")};
    path {
      stroke: ${({ theme: { primary } }) => primary};
    }
    transition: all linear 0.1s;
  }
`;

const ReqText = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 135%;
  letter-spacing: -0.02em;
  color: #333333;
`;

const ReqRow = styled.div`
  display: flex;
  align-items: center;
`;

export const Remember = ({ onChange, defaultVal }) => {
  const [active, setActive] = useState(defaultVal);

  const onChangeRequired = () => {
    setActive(!active);
    onChange();
  };
  return (
    <ReqRow>
      <RequiredCheckBox checked={active} onClick={onChangeRequired}>
        <Check />
      </RequiredCheckBox>
      <ReqText>Remember me</ReqText>
    </ReqRow>
  );
};

export const InputsCont = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Label = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 135%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  margin-bottom: 8px;
  color: ${({ error = false }) => (error ? "#D96666" : "rgba(24, 24, 24, 0.6)")};
`;

export const _Input = styled.input`
  width: 100%;
  height: ${({ show = true }) => (show ? "62px" : 0)};
  border: 1px solid
    ${({ error, done, empty, theme: { primary, red, green } }) =>
      error ? red : done ? green : empty ? "#EFEFEF" : primary};
  border-radius: 4px;
  box-sizing: border-box;
  outline: none;
  padding: 0 ${({ pRight }) => (pRight ? `${pRight}px` : "30px")} 0 65px;
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: ${({ isPass }) => (isPass ? "24px" : "14px")};
  line-height: 135%;
  letter-spacing: 7px;
  transition: height 0.15s linear;
  color: ${({ error, done, empty, theme: { primary, red, green } }) =>
    error ? red : done ? green : empty ? "#EFEFEF" : primary};
  &::placeholder {
    font-family: Muli;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 135%;
    letter-spacing: -0.02em;
    color: rgba(24, 24, 24, 0.4);
  }
`;

export const LabelsLine = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const PassInfo = styled.div`
  background: #f2f4f7;
  border: 1px solid #f0f0f0;
  box-sizing: border-box;
  border-radius: 4px;
  width: 100%;
  box-sizing: border-box;
  padding: ${({ p = "20px 18px" }) => p};
  display: flex;
  flex-direction: column;
  margin-top: ${({ mt = "25px" }) => mt};
`;

export const PassStreightLine = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

const Progress_ = styled.div`
  height: ${({ slim = false }) => (slim ? "12px" : "14px")};
  width: ${({ width }) => `${width}%`};
  background-color: ${({ width }) => (width < 75 ? "#FAD978" : "#55B17C")};
  border-radius: 2px;
  transition: 0.5s linear all;
`;

export const Progress = ({ password = "", arr = [], slim }) => {
  const combinationsError = arr.map((str) => password.includes(str)).some((e) => e);

  const part1 = /(?=.*[A-Z])/.test(password) && password.length > 4 ? 25 : 0;
  const part2 = /(?=.*[^0-9A-Za-z])/.test(password) && password.length > 5 ? 25 : 0;
  const part3 = password && !combinationsError && password.length > 6 ? 25 : 0;
  const part4 = /(?=.*[0-9])/.test(password) && password.length > 7 ? 25 : 0;
  const lengthPart = password.length < 3 ? 0 : password.length < 5 ? 10 : password.length < 7 ? 15 : 28;
  const progress = part1 + part2 + part3 + part4;
  return (
    <ProgressBg slim={slim}>
      <Progress_ slim={slim} width={progress} />
    </ProgressBg>
  );
};

const StepLine = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: ${({ mb = "14px" }) => mb};
  &:last-child {
    margin-bottom: 0;
  }
  > svg {
    margin-right: 10px;
    width: 12px;
    height: 12px;
    path {
      stroke: ${({ error, done }) => (error ? "#D96666" : done ? "#55B17C" : "#AEB1B4")};
    }
  }
`;

export const StepText = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: ${({ size = 14 }) => `${size}px`};
  line-height: 135%;
  letter-spacing: -0.02em;
  color: ${({ error, empty }) => (error ? "#D96666" : empty ? "#AEB1B4" : "#181818")};
`;

export const Step = ({ description, regExp, value = "", isList = false, arr = [], size, mb }) => {
  const done = isList ? value && arr.map((str) => !value.includes(str)).some((e) => e) : value && regExp.test(value);
  const error = isList ? value && arr.map((str) => value.includes(str)).some((e) => e) : value && !regExp.test(value);
  const empty = !done && !error;
  return (
    <StepLine done={done} error={error} mb={mb}>
      {error ? <XIcon /> : <CheckIcon />}
      <StepText size={size} done={done} error={error} empty={empty}>
        {description}
      </StepText>
    </StepLine>
  );
};

const ProgressBg = styled.div`
  width: calc(100% - 150px);
  height: ${({ slim = false }) => (slim ? "12px" : "14px")};
  background: #e3e6e9;
  border-radius: 2px;
  display: flex;
  overflow: hidden;
`;

export const ErrorBlock = styled.div`
  display: flex;
  align-items: center;
  height: 19px;
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 135%;
  text-align: center;
  letter-spacing: -0.02em;
  color: #ff4343;
  position: absolute;
  top: 225px;
  > svg {
    margin-right: 9px;
  }
`;

export const Wrapper = styled.section`
  min-height: calc(100vh - 140px);
  padding: 25px 0 60px 0;
  box-sizing: border-box;
  background: #f4f5f6;
  position: relative;
  .MuiCircularProgress-colorPrimary {
    color: ${({ theme: { primary } }) => primary};
  }
`;

export const WrapperPage = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  width: 100%;
  padding: 0 30px;
  max-width: 1440px;
  box-sizing: border-box;
  max-height: ${({ frameOpen }) => (frameOpen ? "calc(100vh - 330px)" : "auto")};
  overflow-y: ${({ frameOpen }) => (frameOpen ? "hidden" : "visible")};
`;

export const Col = styled.div`
  box-sizing: border-box;
  width: ${({ width }) => (width ? width : "324px")};
  padding: ${({ padding }) => padding};
  display: flex;
  flex-direction: column;
`;

export const Card = styled.div`
  cursor: ${({ pointer = false }) => (pointer ? "pointer" : "")};
  background: ${({ grey }) => (grey ? "#e8e9ea" : "#ffffff")};
  border: 1px solid ${({ grey }) => (grey ? "#F0F0F0" : "#f0f0f0")};
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  box-sizing: border-box;
  flex-direction: ${({ row }) => (row ? "row" : "column")};
  align-items: ${({ align }) => align};
  justify-content: ${({ justify }) => justify};
  padding: ${({ padding }) => padding};
  height: ${({ height }) => height};
  min-height: ${({ minHeight }) => minHeight};
  margin-bottom: 20px;
  margin: ${({ margin }) => margin};
  position: relative;
  &:last-child {
    margin-bottom: 0;
  }
  > h5 {
    margin: 48px 0 24px 0;
    font-family: Muli;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 135%;
    color: #181818;
  }
  > span {
    width: 500px;
    font-family: Muli;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 160%;
    color: #181818;
    text-align: center;
  }
  > svg {
    path {
      stroke: ${({ theme: { primary } }) => primary};
    }
  }
`;

export const HeadingNew = styled.h6`
  display: ${({ flex }) => (flex ? "flex" : "")};
  font-family: Muli;
  font-style: normal;
  font-weight: ${({ fWeight = "bold" }) => fWeight};
  font-size: ${({ fSize }) => (fSize ? `${fSize}px` : "20px")};
  letter-spacing: -0.02em;
  color: #181818;
  text-align: ${({ center }) => (center ? "center" : "left")};
  margin: ${({ margin }) => (margin ? margin : 0)};
  padding: ${({ padding }) => (padding ? padding : 0)};
  box-sizing: border-box;
  position: relative;
  > span {
    margin-left: auto;
    font-weight: 600;
    font-size: 20px;
    line-height: 135%;
    color: rgba(24, 24, 24, 0.4);
  }
`;

export const Text = styled.p`
  font-family: Muli;
  font-style: normal;
  font-weight: ${({ fWeigth }) => (fWeigth ? fWeigth : "normal")};
  font-size: ${({ fSize }) => (fSize ? `${fSize}px` : "16px")};
  line-height: 160%;
  letter-spacing: -0.021em;
  color: #181818;
  text-align: ${({ center }) => (center ? "center" : "left")};
  margin: ${({ margin }) => (margin ? margin : 0)};
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
`;

const TypeContainer = styled.div`
  height: 128px;
  width: ${({ width }) => (width ? width : "calc(33.33% - 10px)")};
  display: flex;
  border: 1px solid ${({ active, theme: { primary } }) => (active ? primary : "#F0F0F0")};
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s linear;
  flex-direction: column;
  position: relative;
`;

const TypeHeading = styled.h6`
  font-family: Muli;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 135%;
  margin: 18px 0 0;
  color: #181818;
`;

const Circle = styled.div`
  width: 22px;
  height: 22px;
  position: absolute;
  background: ${({ active, theme: { primary } }) => (active ? primary : "#FFF")};
  border-radius: 50%;
  top: 10px;
  right: 10px;
  transition: all 0.3s linear;
  display: flex;
  align-items: center;
  justify-content: center;
  > svg {
    width: 12px;
    height: 12px;
  }
`;

export const Type = ({ e: { type, label, icon }, active, onClick, width }) => {
  const onChoose = () => {
    onClick(type);
  };

  const isActive = active === type;

  return (
    <TypeContainer width={width} active={isActive} onClick={onChoose}>
      {icon}
      <TypeHeading>{label}</TypeHeading>
      <Circle active={isActive}>
        <Check />
      </Circle>
    </TypeContainer>
  );
};

export const StabilLogo = styled.img`
  width: 250px;
  height: 54px;
  margin-bottom: ${({ noMargin }) => (noMargin ? 0 : "70px")};
  margin-left: ${({ mLeft = false }) => (mLeft ? mLeft : "")};
`;

export const GlafkaLogo = styled.img`
  position: absolute;
  bottom: -18px;
`;

export const HeaderRow = styled.div`
  display: flex;
  align-items: center;
`;

export const StepContainer = styled.div`
  width: 726px;
  background: #fbfbfb;
  border-radius: 10px;
  padding: 20px 76px 20px 30px;
  margin-bottom: 30px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.15);
`;

export const RegisterHeader = styled.div`
  height: 62px;
  background: #ffffff;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  overflow: hidden;
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  height: 70px;
  background: ${({ theme: { headerInvestor } }) => headerInvestor};
  box-sizing: border-box;
  padding-right: 25px;
  z-index: 1;
  position: relative;
  > h6 {
    font-family: Muli;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 135%;
    color: #ffffff;
    margin: 0 0 0 30px;
  }
`;

export const HelpBlock = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  cursor: pointer;
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 135%;
  color: #ffffff;
  opacity: 0.6;
  > svg {
    opacity: 0.6;
    width: 32px;
    height: 32px;
    margin-left: 10px;
    path {
      stroke: #ffffff;
    }
  }
  :hover {
    opacity: 1;
    > svg {
      opacity: 1;
    }
  }
`;

export const Footer = styled.footer`
  display: flex;
  align-items: center;
  height: 70px;
  background: ${({ theme: { headerInvestor } }) => headerInvestor};
  box-sizing: border-box;
  justify-content: space-between;
  padding: 0 60px;
`;

export const CopyRightBlock = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 160%;
  color: rgba(255, 255, 255, 0.6);
  > span {
    display: inline-block;
    margin-right: 24px;
    color: rgba(255, 255, 255, 0.8);
    font-weight: bold;
  }
`;

export const FooterLink = styled(Link)`
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 160%;
  color: #ffffff;
  margin-right: 30px;
  text-decoration: none;
  &:last-child {
    margin-right: 0;
  }
`;

export const SocialLink = styled(Link)`
  margin-right: 24px;
  transition: opacity 0.3s linear;
  > svg {
    width: 20px;
    height: 20px;
  }
  &:last-child {
    margin-right: 0;
  }
  &:hover {
    opacity: 0.7;
  }
`;

export const ExternalSocialLink = styled.a`
  margin-right: 24px;
  transition: opacity 0.3s linear;
  > svg {
    width: 20px;
    height: 20px;
  }
  &:last-child {
    margin-right: 0;
  }
  &:hover {
    opacity: 0.7;
  }
`;

export const StepNew = styled.div`
  background: ${({ active }) => (active ? "#ffffff" : "#F2F4F6")};
  border: 0.5px solid #e9e9e9;
  box-sizing: border-box;
  width: 33.33%;
  height: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0 25px 0 30px;
  justify-content: space-between;
  > div {
    font-family: Muli;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 135%;
    color: ${({ active, done }) => (active || done ? "#181818" : "rgba(24, 24, 24, 0.4)")};
  }
  &:after {
    display: ${({ active }) => (active ? "block" : "none")};
    content: "";
    position: absolute;
    width: 100%;
    height: 4px;
    bottom: 0;
    left: 0;
    background: ${({ error, theme: { primary, red } }) => (error ? red : primary)};
  }
`;

const WaitingWrapperLoader = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  background: #00000045;
`;

export const Loading = () => {
  return (
    <WaitingWrapperLoader>
      <CircularProgress />
    </WaitingWrapperLoader>
  );
};

export const Welcome = styled.div`
  width: 100%;
  background: ${({ theme: { quoteBgInvestor } }) => quoteBgInvestor};
  border: 1px solid #efefef;
  box-sizing: border-box;
  border-radius: 4px;
  margin-bottom: 30px;
  color: #181818;
  line-height: 135%;
  letter-spacing: -0.02em;
  padding: 20px 30px;
  > h3 {
    margin: 0 0 10px;
    font-size: 20px;
  }
  > p {
    margin: 0;
    font-size: 14px;
  }
`;

export const Separator = styled.div`
  width: 100%;
  height: ${({ h = 20 }) => `${h}px`};
`;

export const Desc = styled.p`
  margin: 0;
  font-size: 14px;
  line-height: 135%;
  letter-spacing: -0.02em;
  color: #989898;
`;

export const InvestureLogo = styled.img`
  height: 92px;
  position: absolute;
  left: -7px;
  top: 2px;
`;
