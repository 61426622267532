import React, { useState, useEffect } from "react";

import {
  SendEmailWrapper,
  ModalBackground,
  ModalHeader,
  InputsWrapper,
  Heading,
  Separator,
  WaitingWrapper,
  Row,
  Line
} from "./styled";
import { Btn } from "../../buttons";
import { ReactComponent as CloseIcon } from "../../../../assets/x-circle.svg";
import {
  TextFieldComponent,
  DateComponent,
  SelectComponent,
  TextFieldComponentPlaceholder
} from "../../forms";
import Api from "../../../../helpers/api";
import { CircularProgress } from "@material-ui/core";

export const AddShares = ({
  token,
  isOpen,
  onBackdropClick,
  users,
  assets,
  reloadUsers,
  singleUser = false
}) => {
  const [waitingResponse, setWaitingResponse] = useState(false);

  const [user, setUser] = useState("");
  const [tokenId, setTokenId] = useState("");
  const [assetId, setAssetId] = useState("");
  const [amount, setAmount] = useState("");
  const [sharesAmount, setSharesAmount] = useState("");
  const [assetsList, setAssetsList] = useState(assets.map(e => e.assetName));
  const [date, setDate] = useState(Date.now());
  const [price, setPrice] = useState("");
  const [rounds, setRounds] = useState([]);
  const [currentRound, setCurrentRound] = useState({});
  const [activeDealRound, setActiveDealRound] = useState("");
  const [currentCurrency, setCurrentCurrency] = useState("");

  //bufer
  const [currentAmount, setCurrentAmount] = useState("");
  const [currentSharesAmount, setCurrentSharesAmount] = useState("");

  useEffect(() => {
    if (!isOpen) {
      setUser("");
      setTokenId("");
      setAssetId("");
      setAmount("");
      setSharesAmount("");
      setPrice("");
      setCurrentCurrency("");
    }
  }, [isOpen]);

  useEffect(() => {
    if (assetId) {
      Api.getAssetRounds(token, assetId).then(data => {
        setRounds(data.roundsInfo || []);
        setActiveDealRound(data.currentRound);
      });
    }
  }, [assetId]);

  useEffect(() => {
    setAssetsList(assets.map(e => e.assetName));
  }, [assets]);

  //on pay amount input
  useEffect(() => {
    if (amount && !currentSharesAmount && Number.isInteger(amount / price)) {
      setSharesAmount(amount / +price);
    } else if (amount && currentSharesAmount) {
      setPrice(+(amount / sharesAmount).toFixed(2));
    } else if (!Number.isInteger(amount / price)) {
      setSharesAmount("");
    } else if (!amount) {
      setPrice(currentRound.sharePrice);
    }
  }, [amount]);

  //on number of shares input
  useEffect(() => {
    if (sharesAmount && !currentAmount) {
      setAmount(sharesAmount * price);
    } else if (sharesAmount && currentAmount) {
      setPrice(+(amount / sharesAmount).toFixed(2));
    } else if (!sharesAmount && amount) {
      setPrice(currentRound.sharePrice);
    }
  }, [sharesAmount]);

  const findId = name => {
    const asset = assets.find(e => e.assetName === name);

    setCurrentCurrency(asset.baseCurrency);
    setTokenId(asset.tokenId);
    setAssetId(asset.assetId);
  };

  const onSetCurrentRound = name => {
    const round = rounds.find(r => r.fundingName === name);
    setCurrentRound(round);
    setPrice(round.sharePrice);
  };

  const addSharesToUser = async () => {
    setWaitingResponse(true);
    const res = await Api.addSharesManualy(token, {
      tokenId,
      quantity: sharesAmount,
      holder: singleUser ? singleUser : user,
      currency: currentCurrency,
      sharePrice: price,
      txDate: date,
      paymentFromRound: currentRound.fundingName
    });
    if (res && !singleUser) {
      reloadUsers();
    }
    setWaitingResponse(false);
    onBackdropClick();
  };

  const onUpdateBufer = () => {
    setCurrentSharesAmount(sharesAmount);
    setCurrentAmount(amount);
  };

  const findCurrentRoundIndex = () =>
    rounds.findIndex(r => r.fundingName === activeDealRound);

  findCurrentRoundIndex();

  return isOpen ? (
    <ModalBackground onClick={onBackdropClick}>
      <SendEmailWrapper
        onClick={e => {
          e.stopPropagation();
        }}
      >
        {waitingResponse && (
          <WaitingWrapper>
            <CircularProgress />
          </WaitingWrapper>
        )}
        <ModalHeader>
          <Heading>Add Shares Manually</Heading>
          <CloseIcon onClick={onBackdropClick} />
        </ModalHeader>
        <InputsWrapper padding="15px 25px">
          {singleUser ? (
            <TextFieldComponent
              label="Add to user"
              placeholder="Add to user"
              disabled={true}
              defaultValue={singleUser}
            />
          ) : (
            <SelectComponent
              label="Add to user"
              placeholder="Add to user"
              values={users.filter(u => u.enabled).map(u => u.username)}
              onChange={e => setUser(e.target.value)}
              view="filter"
            />
          )}

          <Separator height={25} />
          <Row>
            <SelectComponent
              label="Asset"
              values={assetsList}
              onChange={e => findId(e.target.value)}
              view="filter"
            />
          </Row>
          <Separator height={25} />
          <Row>
            <SelectComponent
              label="ROUND NAME"
              values={
                rounds && rounds.length ? rounds.map(e => e.fundingName) : []
              }
              onChange={e => onSetCurrentRound(e.target.value)}
              disabled={!assetId || !rounds.length}
              lighterItem={findCurrentRoundIndex()}
              pseudoContent="current round"
            />
          </Row>
          <Line />
          <Row>
            <TextFieldComponent
              key="pay-amount"
              label="Paid Amount"
              placeholder="Paid Amount"
              width="calc(100% - 178px)"
              disabled={!Object.keys(currentRound).length}
              type="number"
              value={amount}
              onChange={e => setAmount(e.target.value)}
              onFocus={onUpdateBufer}
              onBlur={onUpdateBufer}
            />
            <SelectComponent
              label="currency"
              width="148px"
              values={[currentCurrency]}
              value={currentCurrency}
              disabled={!Object.keys(currentRound).length}
            />
          </Row>
          <Separator height={25} />
          <Row last>
            <TextFieldComponent
              key="num-o-shares"
              label="Number of shares"
              placeholder="Number of shares"
              type="number"
              value={sharesAmount}
              onChange={e => setSharesAmount(e.target.value)}
              onFocus={onUpdateBufer}
              onBlur={onUpdateBufer}
              width="calc(33.33% - 20px)"
              disabled={!Object.keys(currentRound).length}
            />
            <TextFieldComponentPlaceholder
              label="share price"
              pseudoText={`${currentCurrency}`}
              pseudo
              width="calc(33.33% - 20px)"
              padding="0 50px 0 20px"
              type="number"
              defaultValue={price}
              onChange={e => setPrice(e.target.value)}
              disabled
            />
            <DateComponent
              label="transaction date"
              width="calc(33.33% - 20px)"
              padding="0 20px 0 12px"
              defaultValue={date}
              onChange={val => setDate(val)}
            />
          </Row>
        </InputsWrapper>
        <ModalHeader isFooter>
          <Btn onClick={onBackdropClick} hide width={94}>
            Cancel
          </Btn>
          <Btn
            width={180}
            onClick={addSharesToUser}
            disabled={
              (!user && !singleUser) || !tokenId || !amount || !sharesAmount
            }
          >
            Add shares
          </Btn>
        </ModalHeader>
      </SendEmailWrapper>
    </ModalBackground>
  ) : null;
};
