import React, { useEffect, useState, Fragment } from "react";
import moment from "moment";

import { ReactComponent as Settings } from "assets/settings.svg";
import { ReactComponent as Preview } from "assets/preview.svg";
import { ReactComponent as Next } from "assets/next.svg";

import { weekDays } from "./const";
import { getDateArea } from "./helpers";
import Api from "./api";

import { SettingsForm } from "./settings";

import {
  CalendarContainer,
  Title,
  Row,
  ArrowWrapper,
  SettingsWrapper,
  WeekDay,
  MonthDay,
  DateArea,
  Separator,
  Events,
  EventPoint,
  EventDate,
  EventText,
  EventRow,
  Popup,
  TitlePopup,
  TextPopup,
} from "./styled";
import EventApi from "./api";
import { EmptyInfo } from "components/admin-layout/pages/AssetDetails/common/emptyInfo";

export const Calendar = ({ token, assetName, assetNames, inAsset = false, updateAction, isPreview = false }) => {
  const [currentDate, setCurrentDate] = useState(moment());
  const [daysArea, setDaysArea] = useState([]);
  const [settingOpen, setSettingOpen] = useState(false);
  const [events, setEvents] = useState({});

  const [selectedDate, setSelectedDate] = useState("");

  useEffect(() => {
    if (token) Api.token = token;
    (async function () {
      if (!isPreview) setEvents(await Api.getEvents());
    })();
  }, []);

  useEffect(() => {
    setDaysArea(getDateArea(moment(currentDate.toISOString())));
  }, [currentDate]);

  const changeMonth = (direction) => () => {
    setCurrentDate(moment(currentDate.toISOString()).add(direction, "month"));
  };

  const onSave = async (data) => {
    if (data.id) await EventApi.updateEvents(data);
    else await EventApi.createEvents(data);
    setEvents(await Api.getEvents());
    updateAction(token);
  };

  const onDelete = async (id) => {
    await EventApi.deleteEvents(id);
    setEvents(await Api.getEvents());
    updateAction(token);
  };

  const filterEvents = (arr) =>
    arr.filter(({ dateTime }) => {
      return moment(selectedDate).format("YYYY-MM-DD") === moment(dateTime).format("YYYY-MM-DD");
    });

  return (
    <Fragment>
      {settingOpen ? (
        <SettingsForm
          events={inAsset ? { [assetName]: events[assetName] } : events}
          onClose={() => setSettingOpen(false)}
          onSave={onSave}
          onDelete={onDelete}
          assetName={assetName}
          assetNames={assetNames}
        />
      ) : null}
      <CalendarContainer>
        <Row mBottom={20}>
          <Title>Calendar</Title>
          <SettingsWrapper onClick={isPreview ? undefined : () => setSettingOpen(true)}>
            <Settings />
          </SettingsWrapper>
        </Row>
        <Row mBottom={20}>
          <Title fontSize={12}>
            {currentDate.format("MMMM")} {currentDate.format("YYYY")}
          </Title>
          <Row>
            <ArrowWrapper onClick={changeMonth(-1)}>
              <Preview />
            </ArrowWrapper>
            <ArrowWrapper onClick={changeMonth(1)}>
              <Next />
            </ArrowWrapper>
          </Row>
        </Row>
        <Row mBottom={20}>
          {weekDays.map((day, i) => (
            <WeekDay key={`day-key-${day}-${i}`}>{day}</WeekDay>
          ))}
        </Row>
        <DateArea>
          {daysArea.map((e) => {
            const date = moment(e);
            const _events = (events[assetName] || [])
              .filter(({ dateTime }) => {
                return date.format("YYYY-MM-DD") === moment(dateTime).format("YYYY-MM-DD");
              })
              .sort((a, b) => (a.dateTime > b.dateTime ? 1 : -1));

            return (
              <MonthDay
                className={e === selectedDate ? "active" : ""}
                isOffset={date.format("M") !== currentDate.format("M")}
                key={`date-key-${currentDate.format("M")}-${date.format("DD-MM-YYYY")}`}
                content={date.format("D")}
                onClick={() => setSelectedDate(e === selectedDate ? null : e)}
                onDoubleClick={() => setSelectedDate(e)}
              >
                {_events.length ? (
                  <Fragment>
                    <EventPoint
                      isSmall
                      eventColor={_events[0].color}
                      isOffset={date.format("M") !== currentDate.format("M")}
                    />
                    <Popup onClick={(e) => e.stopPropagation()}>
                      <TitlePopup fontSize={12}>{_events[0].name}</TitlePopup>
                      <TextPopup>{moment(_events[0].dateTime).format("DD.MM.YYYY, HH:mm")}</TextPopup>
                    </Popup>
                  </Fragment>
                ) : null}
              </MonthDay>
            );
          })}
        </DateArea>
        <Separator />
        <Events
          isScroll={events[assetName] && filterEvents(events[assetName]).length > 3}
          onClick={(e) => e.stopPropagation()}
        >
          {events[assetName] && filterEvents(events[assetName]).length ? (
            filterEvents(events[assetName]).map((e, i) => (
              <EventRow key={`date-key-${e.dateTime}-${i}`}>
                <EventPoint eventColor={e.color} />
                <EventDate>{moment(e.dateTime).format("HH:mm")}</EventDate>
                <EventText>{e.name}</EventText>
              </EventRow>
            ))
          ) : (
            <EmptyInfo
              heigth={daysArea.length > 35 ? "50px" : "75px"}
              icon={null}
              mTop="auto"
              headingNoMargin
              heading="No Events Yet"
              text=""
              padding={daysArea.length > 35 ? "20px" : ""}
            />
          )}
        </Events>
      </CalendarContainer>
    </Fragment>
  );
};
