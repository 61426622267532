import React, { useState, useEffect } from "react";
import { PieChart } from "react-minimal-pie-chart";
import { CircularProgress } from "@material-ui/core";

import { Main, TextSub } from "../../DealForm/Step/styled";
import { FlexRow, Column } from "../../../dashBoardCard";
import { UsersData, Empty, EmptyCircle } from "../styled";

import Api from "helpers/api";
import theme from "models/themes";

export const UserStages = ({ token, assetId }) => {
  const [usersStages, setUsersStages] = useState(null);

  useEffect(() => {
    if (!usersStages) {
      Api.getUsersStages(token, assetId).then(json => setUsersStages(json));
    }
  });

  const noStages = () =>
    Object.keys(usersStages).every(stage => !usersStages[stage]);

  return (
    <Main
      currentHeight="130px"
      width="calc(33.33% - 20px)"
      padding="20px 25px 15px 25px"
    >
      <TextSub
        textAlign="left"
        fontWeight="bold"
        color="#181818"
        marginSize="0 0 14px 0"
      >
        Users Stages
      </TextSub>
      {!usersStages ? (
        <CircularProgress size={24} style={{ margin: "auto" }} />
      ) : (
        <FlexRow mBottom="0" justify="flex-start">
          <Column>
            {noStages() ? (
              <EmptyCircle />
            ) : (
              <PieChart
                data={[
                  {
                    title: "invested",
                    value: (usersStages.INVESTED && usersStages.INVESTED) || 0,
                    color: "#55B17C"
                  },
                  {
                    title: "Approved",
                    value: (usersStages.APPROVED && usersStages.APPROVED) || 0,
                    color: "#3F4B78"
                  },
                  {
                    title: "Upstream",
                    value: (usersStages.UPSTREAM && usersStages.UPSTREAM) || 0,
                    color: theme.primaryLight
                  },
                  {
                    title: "Study",
                    value: (usersStages.STUDY && usersStages.STUDY) || 0,
                    color: theme.primaryMiddle
                  }
                ]}
                style={{
                  width: 62,
                  height: 62
                }}
              />
            )}
          </Column>
          {noStages() ? (
            <Empty h="62px" w="calc(100% - 86px)" m="0 0 0 24px">
              No Data Yet
            </Empty>
          ) : (
            <UsersData
              data={{
                invested:
                  (usersStages.INVESTED && usersStages.INVESTED.toFixed(2)) ||
                  0,
                upproved:
                  (usersStages.APPROVED && usersStages.APPROVED.toFixed(2)) ||
                  0,
                upstream:
                  (usersStages.UPSTREAM && usersStages.UPSTREAM.toFixed(2)) ||
                  0,
                study: (usersStages.STUDY && usersStages.STUDY.toFixed(2)) || 0
              }}
            />
          )}
        </FlexRow>
      )}
    </Main>
  );
};
