import React, { useState, useRef, useEffect, Fragment } from "react";

import { Separator, Main, TextSub, AnswerWrap, UserCard } from "./styled";
import { Btn } from "../../buttons";
import { icons } from "../../../../models/icons";
import { ReactComponent as Down } from "../../../../assets/chevron-down.svg";

export const DynamicRepresentatives = ({ users }) => {
  const [openMore, setOpenMore] = useState(true);
  const [height, setHeight] = useState(0);
  const showRef = useRef(null);

  const onShowMore = () => {
    setOpenMore(!openMore);
  };

  useEffect(() => {
    if (showRef && showRef.current) {
      setHeight(showRef.current.offsetHeight);
      setOpenMore(false);
    }
  }, []);

  return (
    <Fragment>
      <Separator />
      <Main>
        <TextSub
          textAlign="left"
          fontWeight="bold"
          color="#181818"
          marginSize="0 0 20px 0"
        >
          Representatives
        </TextSub>
        <AnswerWrap
          marginBottom="8px"
          ref={showRef}
          height={
            openMore && !height
              ? "fit-content"
              : openMore && height
              ? `${height}px`
              : users.length < 3
              ? "fit-content"
              : "160px"
          }
        >
          {users.map(({ fullName, lastName, firstName }) => {
            return (
              <UserCard key={`unique-${fullName}`}>
                {icons.userSmall}
                <span>{firstName + " " + lastName}</span>
              </UserCard>
            );
          })}
        </AnswerWrap>
        {height > 160 ? (
          <Btn
            transparent
            width={273}
            onClick={onShowMore}
            grey={openMore}
            rightIcon
            transform={openMore}
          >
            {openMore ? "hide" : "show more"}
            <Down />
          </Btn>
        ) : null}
      </Main>
    </Fragment>
  );
};
