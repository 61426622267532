import React, { useEffect, useState, useRef } from "react";
import ReactDOM from "react-dom";

import styled from "styled-components";

const Tooltip = styled.div`
  position: fixed;
  top: ${({ top = 0 }) => top}px;
  left: ${({ left = 0 }) => left}px;
  width: 325px;
  background: #ffffff;
  border: 1px solid #efefef;
  box-sizing: border-box;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  z-index: 10;
  padding: 15px;
  transform: translate(calc(-50% + 37px), calc(-100% - 20px));
  box-sizing: border-box;

  &::before {
    /* content: ""; */
    ${({ noPseudo = false }) => {
      if (noPseudo) {
        return null;
      } else return `content:"";`;
    }}
    position: absolute;
    display: block;

    height: 120%;
    width: 100%;

    left: 0;
    top: 0;
  }
`;

const Triangle = styled.div`
  position: absolute;
  bottom: -15px;
  left: ${({ left = "50%" }) => left};
  width: 0;
  height: 0;
  border: 16px solid transparent;
  border-bottom-color: white;
  border-right-color: white;

  z-index: 8;

  box-sizing: border-box;
  box-shadow: 0px 10px 40px rgba(89, 120, 150, 0.06);
  border-radius: 4px;
  transform: translate(-50%, 0) rotate(45deg);
`;

export const PortalTooltipWrapper = ({
  anchorRef,
  children = [],
  show,
  onLeave,
  noPseudo = false
}) => {
  const [top, setTop] = useState(0);
  const [left, setLeft] = useState(0);
  const tooltipRef = useRef(null);

  useEffect(() => {
    const mouseMove = e => {
      try {
        const {
          x,
          y,
          width,
          height
        } = anchorRef.current.getBoundingClientRect();
        setTop(y);
        setLeft(x);
        console.log(e.clientY, y, e.clientY > y + 24);
        if (
          e.clientY > y + height ||
          (e.clientY > y && (e.clientX < x || e.clientX > x + width))
        ) {
          onLeave();
        }
      } catch (_) {
        console.log(_);
      }
    };

    window.addEventListener("mousemove", mouseMove);
    window.addEventListener("mousewheel", onLeave);
    return () => {
      window.removeEventListener("mousemove", mouseMove);
      window.removeEventListener("mousewheel", onLeave);
    };
  }, []);

  return ReactDOM.createPortal(
    <Tooltip
      ref={tooltipRef}
      onMouseleave={onLeave}
      show={show}
      top={top}
      left={left}
      onMouseLeave={onLeave}
      noPseudo={noPseudo}
    >
      {children}
      <Triangle />
    </Tooltip>,
    document.getElementById("tooltip")
  );
};

export const PortalTooltipWrapperDRA = ({
  anchorRef,
  children = [],
  show,
  onLeave,
  noPseudo = false
}) => {
  const [top, setTop] = useState(0);
  const [left, setLeft] = useState(0);
  const tooltipRef = useRef(null);

  useEffect(() => {
    const mouseMove = e => {
      try {
        const {
          x,
          y,
          width,
          height
        } = anchorRef.current.getBoundingClientRect();
        setTop(y);
        setLeft(x - 80);
        if (
          e.clientY > y + height ||
          (e.clientY > y && (e.clientX < x || e.clientX > x + width))
        ) {
          onLeave();
        }
      } catch (_) {
        console.log(_);
      }
    };

    window.addEventListener("mousemove", mouseMove);
    window.addEventListener("mousewheel", onLeave);
    return () => {
      window.removeEventListener("mousemove", mouseMove);
      window.removeEventListener("mousewheel", onLeave);
    };
  }, []);

  return ReactDOM.createPortal(
    <Tooltip
      ref={tooltipRef}
      onMouseleave={onLeave}
      show={show}
      top={top}
      left={left}
      onMouseLeave={onLeave}
      noPseudo={noPseudo}
    >
      {children}
      <Triangle left="236px" />
    </Tooltip>,
    document.getElementById("tooltip")
  );
};
