import React from "react";
import { Main, TextSub } from "../styled";
import { DateTimeFieldComponent } from "../../../../forms";

import { InputRow } from "../Information/styled";

export const StartEndDates = ({ onChange, defaultValues }) => {
  return (
    <Main noHidden>
      <TextSub
        textAlign="left"
        fontWeight="bold"
        color="#181818"
        marginSize="0 0 25px 0"
      >
        Start & End Dates
      </TextSub>
      <InputRow>
        <DateTimeFieldComponent
          label="Start (MM/DD/YYYY, HH:MM)"
          onChange={console.log}
          onChange={onChange("assetFinancialProperty", "timeOfStart")}
          defaultValue={defaultValues.assetFinancialProperty.timeOfStart}
          zIndex={1}
          padding="0 14px"
          bgPos={14}
        />
      </InputRow>
      <InputRow last>
        <DateTimeFieldComponent
          label="END (MM/DD/YYYY, HH:MM)"
          onChange={onChange("assetFinancialProperty", "timeOfEnd")}
          defaultValue={defaultValues.assetFinancialProperty.timeOfEnd}
          padding="0 14px"
          bgPos={14}
        />
      </InputRow>
    </Main>
  );
};
