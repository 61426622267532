import React, { useRef, useEffect, useState, Fragment } from "react";
import moment from "moment";
import SunEditor from "suneditor-react";
import styled from "styled-components";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import PlacesAutocomplete from "react-places-autocomplete";
import { ReactComponent as SponsorBg } from "../../../assets/sponsor-bg.svg";
import { ReactComponent as Users } from "../../../assets/users-grey.svg";

import _SelectComponent from "./SelectComponent";
import _ChipsSelectComponent from "./ChipsSelectComponent";
import { PhoneInputs } from "components/auhtForms/questionaries/styled";

import { Input } from "components/common/form/index";
import theme from "models/themes";

import {
  ContainerFlex,
  DocInput,
  Cont,
  InfoBlock,
  DocSelect,
  DocOptions,
  DocOptionContainer,
  DocOptionsBlock,
  DocTextArea,
  DateWrapper,
  ImageWrapper,
  Column,
  Row,
  StatusBlock,
} from "../containers/container";

import {
  NoneInput,
  WrapperEditor,
  Text,
  ContainerDatePicker,
  LocationWrapper,
  NoAvatar,
  FileName,
  FileAction,
  Avatar,
  FooterBlock,
  FooterBolockTxt,
  CheckBox,
  Circle,
} from "./styled";
import { Label, InputLabel as DocInputLabel, VarText } from "../text";
import { VarButton } from "../buttons";

import "suneditor/dist/css/suneditor.min.css";
import { fileEncodeToBase64 } from "../../../helpers";
import Api from "helpers/api";
import { InfoSmall } from "components/common/icons-primary";

export const SelectComponent = _SelectComponent;
export const ChipsSelectComponent = _ChipsSelectComponent;

export const TextEditor = (props) => {
  return (
    <Fragment>
      <WrapperEditor>
        <Text>
          <Label>{props.label}</Label>
        </Text>
        <SunEditor
          autoFocus={false}
          setContents=""
          onChange={(e) => {
            props.onChange(e);
          }}
          setOptions={{
            fontSize: [8, 10, 14, 18, 24, 36],
          }}
        />
      </WrapperEditor>
      <NoneInput
        data-auto-focus
        onFocus={() => {
          const needFocus = document.querySelector("[data-focus]");
          if (needFocus) needFocus.focus();
        }}
      />
    </Fragment>
  );
};

const options = {
  fontSize: [8, 10, 14, 18, 24, 36],
  formats: ["p", "blockquote", "h1", "h2", "h3"],
  buttonList: [["formatBlock", "bold", "underline", "italic", "align", "list", "image", "video"]],
};

export const DateComponent = ({
  label = "",
  defaultValue = new Date(),
  onChange,
  width,
  widthPicker,
  padding,
  isInvestor,
  disabled = false,
}) => {
  const [value, setValue] = useState(defaultValue ? new Date(defaultValue) : new Date());
  const ref = useRef(null);

  const _onChange = () => {
    setValue(moment(ref.current.value));
  };

  useEffect(() => {
    if (ref.current) {
      onChange && onChange(moment(value).utc().format());
    }
  }, [value]);
  return (
    <ContainerDatePicker row w={width || "100%"} justify="space-between" widthPicker={widthPicker}>
      <DateWrapper width="100%">
        <DocInputLabel isInvestor={isInvestor}>{label}</DocInputLabel>
        <DocInput
          padding={padding}
          ref={ref}
          onChange={_onChange}
          defaultValue={moment(value).format("YYYY-MM-DD")}
          icon={`/assets/calendar${theme.greyFilter ? "-dark" : ""}.svg`}
          width="100%"
          type="date"
          disabled={disabled}
        />
      </DateWrapper>
    </ContainerDatePicker>
  );
};

export const ChartDateComponent = ({ defaultValue = new Date(), onChange, width, widthPicker }) => {
  const [value, setValue] = useState(defaultValue ? new Date(defaultValue) : new Date());
  const ref = useRef(null);

  const _onChange = () => {
    setValue(moment(ref.current.value));
  };

  useEffect(() => {
    if (ref.current) {
      onChange && onChange(moment(value).utc().format());
    }
  }, [value]);

  return (
    <ContainerDatePicker row w={width || "100%"} justify="space-between" widthPicker={widthPicker}>
      <DateWrapper width="100%">
        <DocInput
          arrowPosition={10}
          height={32}
          ref={ref}
          onChange={_onChange}
          defaultValue={moment(value).format("YYYY-MM-DD")}
          icon={`/assets/calendar${theme.greyFilter ? "-dark" : ""}.svg`}
          width="180px"
          type="date"
        />
      </DateWrapper>
    </ContainerDatePicker>
  );
};

const TIMES = [
  "12:00 AM",
  "01:00 AM",
  "02:00 AM",
  "03:00 AM",
  "04:00 AM",
  "05:00 AM",
  "06:00 AM",
  "07:00 AM",
  "08:00 AM",
  "09:00 AM",
  "10:00 AM",
  "11:00 AM",
  "12:00 PM",
  "01:00 PM",
  "02:00 PM",
  "03:00 PM",
  "04:00 PM",
  "05:00 PM",
  "06:00 PM",
  "07:00 PM",
  "08:00 PM",
  "09:00 PM",
  "10:00 PM",
  "11:00 PM",
];

export const DateTimeFieldComponent = ({
  label = "",
  defaultValue = new Date(),
  zIndex = 0,
  onChange,
  padding = false,
  bgPos = false,
}) => {
  const [value, setValue] = useState(new Date(defaultValue));
  const ref1 = useRef(null);

  useEffect(() => {
    onChange && onChange(moment(value).utc().format());
  }, [value]);

  useEffect(() => {
    console.log(value);
  }, [value]);

  const _onChange = (e) => {
    setValue(`${moment(e.target.value).format("YYYY-MM-DD")} ${ref1.current.value}`);
  };

  const _onChangeTime = (e) => {
    setValue(`${moment(value).format("YYYY-MM-DD")} ${e.target.value}`);
  };

  return (
    <ContainerDatePicker zIndex={zIndex} row w="100%" justify="space-between" align="flex-end" widthPicker="100%">
      <DateWrapper width="calc(60% - 13px)">
        <DocInputLabel>{label}</DocInputLabel>
        <DocInput
          icon={`/assets/calendar${theme.greyFilter ? "-dark" : ""}.svg`}
          width="100%"
          type="date"
          onChange={_onChange}
          defaultValue={moment(value).format("YYYY-MM-DD")}
          padding={padding}
          arrowPosition={bgPos}
        />
      </DateWrapper>
      <DateWrapper width="40%">
        <Input
          ref={ref1}
          type="time"
          width="100%"
          icon={require("../../../assets/clock.svg")}
          value={moment(value).format("HH:mm")}
          onChange={_onChangeTime}
          padding={padding}
          arrowPosition={bgPos}
        />
      </DateWrapper>
    </ContainerDatePicker>
  );
};

export const TextFieldComponent = ({
  onChange,
  width = "100%",
  label,
  placeholder,
  defaultValue = "",
  type = "text",
  disabled = false,
  height,
  fontSize,
  labelFontSize,
  error = false,
  errorWithBG,
  pseudo = "",
  isInvestor,
  autoComplite = "new-password",
  value,
  ref,
  noLabel = false,
  children,
  content,
  hiddenLabel,
  ...otherProps
}) => {
  return (
    <ContainerFlex w={width} content={content}>
      {noLabel ? null : (
        <DocInputLabel hidden={hiddenLabel} labelFsize={labelFontSize} isInvestor={isInvestor}>
          {label}
        </DocInputLabel>
      )}
      <DocInput
        data-auto-focus
        autoComplete={autoComplite}
        errorWithBG={errorWithBG}
        error={error}
        fontSize={fontSize}
        height={height}
        type={type}
        width="100%"
        disabled={disabled}
        onChange={onChange}
        placeholder={placeholder}
        defaultValue={defaultValue}
        value={value}
        pseudo={pseudo}
        ref={ref}
        {...otherProps}
      />
      {children}
    </ContainerFlex>
  );
};

export const TextFieldComponentPlaceholder = ({
  onChange,
  width = "100%",
  label,
  placeholder,
  defaultValue = "",
  type = "text",
  disabled = false,
  height,
  fontSize,
  labelFontSize,
  error = false,
  errorWithBG = false,
  pseudo = false,
  pseudoText,
  after,
  afterText,
  onKeyDown,
  pseudoSize,
  isInvestor = false,
  padding,
  margin,
  ...otherProps
}) => {
  return (
    <Cont
      labelFSize={labelFontSize}
      width={width}
      pseudo={pseudo}
      pseudoText={pseudoText}
      pseudoSize={pseudoSize}
      isInvestor={isInvestor}
      margin={margin}
      after={after}
      afterText={afterText}
    >
      <DocInputLabel isInvestor={isInvestor} labelFsize={labelFontSize}>
        {label}
      </DocInputLabel>
      <DocInput
        errorWithBG={errorWithBG}
        padding={padding}
        error={error}
        fontSize={fontSize}
        height={height}
        type={type}
        width="100%"
        disabled={disabled}
        onChange={onChange}
        placeholder={placeholder}
        value={defaultValue}
        onKeyDown={onKeyDown}
        {...otherProps}
      />
    </Cont>
  );
};

export const InfoBlockComponent = ({ width = "100%", label, children, labelFontSize, isInvestor }) => {
  return (
    <ContainerFlex w={width}>
      <DocInputLabel isInvestor={isInvestor} labelFsize={labelFontSize}>
        {label}
      </DocInputLabel>
      <InfoBlock>{children}</InfoBlock>
    </ContainerFlex>
  );
};

export const StatusComponent = ({ width = "100%", label }) => {
  return (
    <ContainerFlex w={width}>
      <DocInputLabel>{label}</DocInputLabel>
      <StatusBlock bg="#C6C6C6">Pending</StatusBlock>
    </ContainerFlex>
  );
};

export const StatusDinamicComponent = ({ width = "100%", label, movementType }) => {
  const types = {
    INCOME: "#019D52",
    OUTCOME: "#D96666",
    PERSONAL: "#BFDCFC",
    PENDING: "#3F4B78",
    CANCELED: "#D96666",
    OTHER: "#cecece",
    REJECTED: "#FF4343",
  };

  return (
    <ContainerFlex w={width}>
      <DocInputLabel>{label}</DocInputLabel>
      <StatusBlock bg={types[movementType]}>{movementType}</StatusBlock>
    </ContainerFlex>
  );
};

export const ImageUploaderComponent = ({ width = "100%", label, sponsorBg, onChange, file = null, token }) => {
  const [avatar, setAvatar] = useState(null);
  const [fileName, setFileName] = useState(file ? file.fileNameWithExtension : "");

  const inpupRef = useRef(null);

  const openFileDialog = () => inpupRef.current.click();

  const onUploadFile = async (e) => {
    const file = e.target.files[0];
    setAvatar(await fileEncodeToBase64(file));
    setFileName(file.name);
    onChange(file);
  };

  const onDeleteImage = () => {
    setAvatar(null);
    setFileName("");
    onChange(null);
  };

  useEffect(() => {
    if (file && file.secureHash && file.fileNameWithExtension && token) {
      const { secureHash, fileNameWithExtension } = file;
      Api.getAttachment(token, secureHash, fileNameWithExtension).then((b) => setAvatar(b));
    }
  }, []);

  return (
    <ContainerFlex w={width}>
      <DocInputLabel>{label}</DocInputLabel>
      <ImageWrapper>
        <input ref={inpupRef} type="file" style={{ display: "none" }} accept="image/*" onChange={onUploadFile} />
        <Column>
          {avatar ? (
            <Avatar sponsorBg={sponsorBg} src={avatar} alt="logo" />
          ) : (
            <NoAvatar sponsorBg={sponsorBg} onClick={openFileDialog} />
          )}
        </Column>
        <Column>
          <FileName>{fileName}</FileName>
          {fileName ? (
            <div>
              <FileAction onClick={openFileDialog}>Change Image</FileAction>
              <FileAction del onClick={onDeleteImage}>
                Delete
              </FileAction>
            </div>
          ) : (
            <div>
              <FileName>No {sponsorBg ? "Background" : "Avatar"} Image</FileName>
              <FileAction onClick={openFileDialog}>Upload Image</FileAction>
            </div>
          )}
        </Column>
      </ImageWrapper>
    </ContainerFlex>
  );
};

export const GroupsLogoUploader = ({ width = "100%", label, sponsorBg, onChange, defaultAvatar, defaultFileName }) => {
  const [avatar, setAvatar] = useState(null);
  const [fileName, setFileName] = useState("");

  const inpupRef = useRef(null);

  useEffect(() => {
    if (!avatar && defaultAvatar) {
      setAvatar(defaultAvatar);
      setFileName(defaultFileName);
      // setFileName(file.name);
    }
  }, [defaultAvatar]);

  const openFileDialog = () => inpupRef.current.click();

  const onUploadFile = async (e) => {
    const file = e.target.files[0];
    setAvatar(await fileEncodeToBase64(file));
    setFileName(file.name);
    onChange(file);
  };

  const onDeleteImage = () => {
    setAvatar(null);
    setFileName("");
    onChange(null);
  };

  const rand = Math.round(Math.random() * 1000);

  return (
    <ContainerFlex w={width}>
      <DocInputLabel>{label}</DocInputLabel>
      <ImageWrapper key={`something-${rand}`}>
        <input ref={inpupRef} type="file" style={{ display: "none" }} onChange={onUploadFile} />
        <Column>
          {sponsorBg ? (
            <SponsorBg />
          ) : avatar ? (
            <Avatar src={avatar} alt="logo" />
          ) : (
            <Users style={{ cursor: "pointer" }} onClick={openFileDialog} />
          )}
        </Column>
        <Column>
          {fileName ? (
            <Fragment>
              <FileName small>{fileName}</FileName>
              <div>
                <FileAction onClick={openFileDialog}>Change image</FileAction>
                <FileAction del onClick={onDeleteImage}>
                  Delete
                </FileAction>
              </div>
            </Fragment>
          ) : (
            <div>
              <Txt>No Image</Txt>
              <UploadImg onClick={openFileDialog}>Upload Image</UploadImg>
            </div>
          )}
        </Column>
      </ImageWrapper>
    </ContainerFlex>
  );
};

const Txt = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 135%;
  letter-spacing: -0.02em;
  margin-bottom: 10px;
  color: rgba(24, 24, 24, 0.4);
`;

const UploadImg = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 135%;
  letter-spacing: -0.02em;
  color: ${({ theme: { primary } }) => primary};
  cursor: pointer;
`;

export const SelectFilter = ({
  onChange,
  width,
  placeholder,
  value,
  values = [],
  multiple = false,
  defaultValue,
  margin,
}) => {
  return (
    <ContainerFlex marginSize={margin} w={width}>
      <DocSelect
        height={32}
        arrowPosition={10}
        padding="0 10px"
        multiple={multiple}
        arrow={`/assets/chevron-down${theme.greyFilter ? "-dark" : ""}.svg`}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        defaultValue={defaultValue}
      >
        {defaultValue ? null : (
          <DocOptions selected disabled>
            none
          </DocOptions>
        )}
        {values.map((e) => (
          <DocOptions key={e} value={e} selected={e === defaultValue}>
            {e}
          </DocOptions>
        ))}
      </DocSelect>
    </ContainerFlex>
  );
};

export const TextAreaComponent = ({
  onChange,
  width = "100%",
  label,
  placeholder,
  defaultValue = "",
  areaHeight,
  labelFsize,
  fSize,
  isInvestor,
  name,
  error = false,
}) => {
  return (
    <ContainerFlex w={width}>
      <DocInputLabel labelFsize={labelFsize} isInvestor={isInvestor}>
        {label}
      </DocInputLabel>
      <DocTextArea
        fSize={fSize}
        areaHeight={areaHeight}
        type="text"
        onChange={onChange}
        placeholder={placeholder}
        defaultValue={defaultValue}
        name={name}
        error={error}
      />
    </ContainerFlex>
  );
};

export const FooterComponent = ({ onChange, width = "100%", label, placeholder, defaultValue = true, name }) => {
  const [checked, setChecked] = useState(defaultValue);

  const onChangeStatus = () => {
    setChecked(!checked);
    onChange(!checked);
  };
  return (
    <ContainerFlex w={width}>
      <DocInputLabel>{label}</DocInputLabel>
      <FooterBlock active={checked}>
        <FooterBolockTxt>{name}</FooterBolockTxt>
        <CheckBox onClick={onChangeStatus} active={checked}>
          <Circle active={checked} />
        </CheckBox>
      </FooterBlock>
    </ContainerFlex>
  );
};

export const SwitcherComponent = ({
  onChange,
  width = "100%",
  label,
  fSize,
  textMargin,
  defaultValue = true,
  name,
  height,
  labelFsize,
  isInvestor,
  leftText = false,
}) => {
  const [checked, setChecked] = useState(defaultValue);

  const onChangeStatus = () => {
    setChecked(!checked);
    onChange(!checked);
  };

  return (
    <ContainerFlex w={width}>
      <DocInputLabel isInvestor={isInvestor} labelFsize={labelFsize}>
        {label}
      </DocInputLabel>
      <FooterBlock height={height} active={checked}>
        <CheckBox leftText={leftText} onClick={onChangeStatus} active={checked}>
          <Circle active={checked} />
        </CheckBox>
        <FooterBolockTxt fSize={fSize} margin={textMargin}>
          {name}
        </FooterBolockTxt>
      </FooterBlock>
    </ContainerFlex>
  );
};

export const VariablesComponent = ({ width = "100%", label, variables }) => {
  const renderVariables = () => {
    return Object.keys(variables).map(
      (e) =>
        e !== "messages" && (
          <Row key={e}>
            <VarButton>{e}</VarButton>{" "}
            <VarText>
              <span>-</span> {variables[e]}
            </VarText>
          </Row>
        )
    );
  };
  return (
    <ContainerFlex w={width}>
      <DocInputLabel>{label}</DocInputLabel>
      <ContainerFlex column>{renderVariables()}</ContainerFlex>
    </ContainerFlex>
  );
};

export const TextEditorComponent = ({
  label,
  onChange,
  defaultValue = "",
  heightToContent,
  customOptions = null,
  scrollToTop = false,
}) => {
  const ref = useRef(null);
  const refId = useRef(Math.random().toString(36).replace("0.", "id-editor"));
  const refWrapper = useRef(null);
  const [content, setContent] = useState(defaultValue);

  useEffect(() => {
    let item;
    let timerId = setInterval(() => {
      item = document.querySelector(`#${refId.current} .se-dialog.sun-editor-common`);
      if (item) {
        document.querySelector("#sun-editor-portal").appendChild(item);
        clearInterval(timerId);
      }
    }, 100);

    return () => {
      item && item.remove();
      clearInterval(timerId);
    };
  }, []);

  return (
    <Fragment>
      <WrapperEditor
        id={refId.current}
        ref={refWrapper}
        className={heightToContent ? "height-to-content" : ""}
        onFocus={(e) => {}}
      >
        <Text>
          <DocInputLabel>{label}</DocInputLabel>
        </Text>
        <SunEditor
          autoFocus={false}
          onChange={onChange}
          key="isEditor"
          ref={ref}
          placeholder={label}
          setContents={content}
          setOptions={options}
        />
      </WrapperEditor>
      <NoneInput
        autoFocus={scrollToTop}
        onFocus={() => {
          if (scrollToTop) {
            const needFocus = document.querySelector("[data-focus]");
            if (needFocus) needFocus.focus();
          }
        }}
      />
    </Fragment>
  );
};

export const LocationSearchInputComponent = ({
  onChange,
  width = "100%",
  label,
  placeholder = "",
  defaultValue = "",
  type = "text",
  countries = false,
  types = ["locality", "country"],
  disabled = false,
  maxHeight = "",
  componentHeight = "",
  padding = "",
  fontSize = "",
  top = 38,
  labelFsize,
  height,
  isInvestor,
  isAccountComponent = false,
}) => {
  const [show, setShow] = useState(false);
  const [address, setAddress] = useState(defaultValue);
  const handleChange = (address) => {
    setAddress(address);
    onChange && onChange(address);
  };

  const handleSelect = (address) => handleChange(address);
  const onFocus = () => setShow(true);
  const onBlur = () => setShow(false);

  return (
    <ContainerFlex w={width} overflow="visible" zIndex={1}>
      {componentHeight || isAccountComponent ? (
        label && !isAccountComponent ? (
          <DocInputLabel>{label}</DocInputLabel>
        ) : null
      ) : (
        <DocInputLabel>{label}</DocInputLabel>
      )}
      <PlacesAutocomplete
        value={address}
        onChange={handleChange}
        onSelect={handleSelect}
        // searchOptions={countries && { types }}
        searchOptions={countries && { types }}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <LocationWrapper>
            {isAccountComponent ? (
              <TextFieldComponent
                isInvestor={isInvestor}
                type={type}
                {...getInputProps({
                  placeholder,
                })}
                labelFontSize={labelFsize || 12}
                fontSize={fontSize || 16}
                height={height || 52}
                label={label}
                width="100%"
                disabled={disabled}
                onFocus={onFocus}
                onBlur={onBlur}
              />
            ) : (
              <DocInput
                type={type}
                {...getInputProps({
                  placeholder,
                })}
                width="100%"
                onFocus={onFocus}
                onBlur={onBlur}
                disabled={disabled}
                icon={maxHeight ? "/assets/chevron-down.svg" : ""}
                height={componentHeight}
                padding={padding}
                fontSize={fontSize}
              />
            )}

            <DocOptionContainer maxHeight={maxHeight} show={show && suggestions.length} top={top}>
              {suggestions.map((suggestion) => (
                <DocOptionsBlock {...getSuggestionItemProps(suggestion)}>{suggestion.description}</DocOptionsBlock>
              ))}
            </DocOptionContainer>
          </LocationWrapper>
        )}
      </PlacesAutocomplete>
    </ContainerFlex>
  );
};

export const PhoneComponent = ({
  width = "100%",
  defaultValue,
  fSize,
  placeholder = "Telephone Number",
  label = "Telephone Number",
  dataType = "default",
  onChange = console.log,
  disabled,
  isAdmin = false,
}) => {
  const handleChange = (phone, code) => {
    if (dataType === "default") {
      onChange(phone);
    }
  };
  return (
    <PhoneInputs isAdmin={isAdmin} height="63px" width={width} fSize={fSize}>
      <PhoneInput
        disabled={disabled}
        value={defaultValue}
        placeholder={placeholder}
        inputClass="phone-input"
        buttonClass="phone-flag"
        specialLabel={label}
        onChange={(phone, { dialCode }) => handleChange(phone, dialCode)}
      />
    </PhoneInputs>
  );
};
