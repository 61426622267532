import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ReactDom from "react-dom";
import { CircularProgress } from "@material-ui/core";

import { ReactComponent as CloseIcon } from "../../../../../../assets/x-circle.svg";
import { Btn } from "../../../../../../components/admin-layout/buttons";
import Api from "../../../../../helpers/api";
import { Document } from "../WorkLows/";
import { getTrackerHTML } from "../../../../../helpers/fileViewer";

const Background = styled.section`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: ${({ bg = "rgba(0, 0, 0, 0.15)" }) => bg};
  backdrop-filter: blur(5px);
  display: flex;
  z-index: 1000;
`;

const Wrapper = styled.div`
  width: 675px;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border: 1px solid #f9f9f9;
  box-sizing: border-box;
  box-shadow: 0px 10px 40px rgba(89, 120, 150, 0.06);
  border-radius: 4px;
  margin: auto;
`;

const Header = styled.div`
  height: 52px;
  width: 100%;
  padding: 0 25px;
  background: #ffffff;
  border: 1px solid #f9f9f9;
  box-sizing: border-box;
  box-shadow: 0px 10px 40px rgba(89, 120, 150, 0.08);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  > svg {
    cursor: pointer;
    width: 24px;
    height: 24px;
  }
`;

const Heading = styled.h5`
  font-family: Muli;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 135%;
  margin: 0;
  color: #181818;
`;

const Text = styled.p`
  font-weight: 600;
  font-size: 16px;
  line-height: 145%;
  color: #181818;
  margin: 0 0 20px;
`;

const Content = styled.div`
  width: 100%;
  padding: 20px 25px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const DocsCol = styled.div`
  box-sizing: border-box;
  width: 100%;
  overflow-y: scroll;
  height: 274px;
  padding: 20px 11px 20px 0;
  border-top: 1px solid #f6f7fc;
  border-bottom: 1px solid #f6f7fc;
  margin-bottom: 20px;
  &&::-webkit-scrollbar-track {
    background-color: ${({ theme: { primaryLight } }) => primaryLight};
    border-radius: 2px;
  }
  &&::-webkit-scrollbar {
    width: 8px;
    border-radius: 2px;
    background-color: ${({ theme: { primaryLight } }) => primaryLight};
  }
  &&::-webkit-scrollbar-thumb {
    background-color: ${({ theme: { primaryMiddle } }) => primaryMiddle};
    border-radius: 2px;
  }
`;

const SelectRow = styled.div`
  display: flex;
  margin: 0 auto 20px 0;
`;

const Wait = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  > div {
    margin: auto;
  }
`;

export const SetRules = ({ onClose, token, assetId, onError, doc: { name }, refreshDocs, history }) => {
  const [files, setFiles] = useState(null);
  const [wait, setWait] = useState(true);

  useEffect(() => {
    const getFiles = async () => {
      const _files = await Api.getDocumentRules(token, assetId, name).catch((e) => {
        onError(e);
        onClose();
        setWait(false);
      });

      if (_files) {
        setFiles(_files);
        setWait(false);
      }
    };

    getFiles();
  }, []);

  const onChangeDocStatus = (data) => {
    const { val, docIndex } = data;
    const _files = { ...files };
    _files.documents[docIndex].active = val;
    setFiles(_files);
  };

  const onSelectAll = (val = true) => () => {
    const _files = { ...files };
    _files.documents.forEach((d) => {
      d.active = val;
    });
    setFiles(_files);
  };

  const renderDocuments = () => {
    const { documents, lawName } = files;
    return documents.map((d, i) => (
      <Document
        callBack={onChangeDocStatus}
        doc={d}
        key={`${d.fileNameWithExtension}-${i}`}
        law={lawName}
        docIndex={i}
        lawIndex={0}
        onView={onOpenDocument(d.name, d.folderName)}
      />
    ));
  };

  const onOpenDocument = (fileName, folderName) => () => {
    getTrackerHTML(token, fileName, `${folderName}/${fileName}?assetId=${assetId}`, false, true, false, false);
  };

  const onSubmit = async () => {
    setWait(true);
    const res = await Api.setDocumentRules(token, assetId, name, files).catch((e) => {
      onError(e);
      onClose();
      setWait(false);
    });
    if (res) {
      res.firstTimePublished && history.push(`/admin/deals/create/confirmation/${assetId}`);
      setWait(false);
      refreshDocs();
      onClose();
    }
  };

  return ReactDom.createPortal(
    <Background onClick={onClose}>
      <Wrapper onClick={(e) => e.stopPropagation()} onDoubleClick={(e) => e.stopPropagation()}>
        <Header>
          <Heading>Set Rules for {files ? files.lawName : name}</Heading>
          <CloseIcon onClick={onClose} />
        </Header>
        <Content>
          <Text>
            Tick checkboxes for documents below which your investors can access after they sign your{" "}
            {files ? files.lawName : name}
          </Text>
          <SelectRow>
            <Btn transparent width={180} margin="0 9px 0 0" onClick={onSelectAll()}>
              SELECT ALL
            </Btn>
            <Btn hide width={180} onClick={onSelectAll(false)}>
              UNSELECT ALL
            </Btn>
          </SelectRow>
          <DocsCol>
            {files ? (
              renderDocuments()
            ) : (
              <Wait>
                <CircularProgress />
              </Wait>
            )}
          </DocsCol>
          <Row>
            <Btn hide onClick={onClose}>
              cancel
            </Btn>
            <Btn width={180} disabled={wait} onClick={onSubmit}>
              Save rules
            </Btn>
          </Row>
        </Content>
      </Wrapper>
    </Background>,
    document.getElementById("round-modal")
  );
};
