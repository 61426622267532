import React, { useEffect, useState, Fragment, useRef } from "react";
import { connect } from "react-redux";
import { CircularProgress } from "@material-ui/core";

import { Header, TextH3, BtnContainer, Separator } from "../Step/styled";
import { dealObjectFactory } from "../../../../../models/dealModel";
import { getAssets } from "modules/dataRedux/dataAction";

import { ContainerFlex } from "../../../containers/container";
import { Btn } from "../../../buttons";

import Api from "../../../../../helpers/api";
import KycApi from "../../KycFormsList/api";
import { LeftContainer, RightContainer } from "../helpers";

import { AdditionalInformation } from "../FullFormCards/AdditionalInformation";
import { MainInformation } from "../FullFormCards/MainInformation";
import { Summary } from "../FullFormCards/Summary";
import { Parameters } from "../FullFormCards/Parameters";
import { Images } from "../FullFormCards/Images";
import { Rules } from "../FullFormCards/Rules";
import { BankAccount } from "../FullFormCards/BankAccount";
import { CurrentRound } from "../FullFormCards/CurrentRound";
import { PreviousRound } from "../FullFormCards/PreviousRounds";
import { DinamicFinancial } from "../FullFormCards/DinamicFinancial";
import { icons } from "../../../../../models/icons";
import { ErrorMessagePortal } from "../../../errorMessage";
import { AssetPreview } from "../assetPreview";
import { DinamicCapital } from "../FullFormCards/DynamicCapital";
import { KycUserType } from "../Step/FinancialsAndRules/KycUserType";

const DeafFormContinue = (props) => {
  const {
    match: {
      params: { unfinishedId },
    },
  } = props;
  const [saveWait, setSaveWait] = useState(false);
  const [linearId, setLinear] = useState("");
  const [isReady, setReady] = useState(false);
  const [error, setError] = useState(null);
  const [values, _setValues] = useState(
    dealObjectFactory(
      props.acceptedCurrency[0] || "",
      props.sponsors[0] ? [props.sponsors[0]] : [],
      props.accesses[0] || "",
      props.dealTypes[0] || "",
      props.categories[0] || "",
      props.investorWorkflowTypes[0] || ""
    )
  );
  const [groups, setGroups] = useState([]);
  const [users, setUsers] = useState([]);
  const finishRef = useRef(null);
  const isMount = useRef(false);
  const valuesRef = useRef(null);
  const [preview, openPreview] = useState(false);
  const [kycForms, setKycForms] = useState([]);
  const [kycRoles, setKycRoles] = useState([]);

  const jsonValueRef = useRef(JSON.stringify(values));
  const setValues = (newValues) => {
    const _newValueJson = JSON.stringify(newValues);
    if (_newValueJson !== jsonValueRef.current) {
      _setValues(newValues);
    }
    jsonValueRef.current = _newValueJson;
  };

  const onChangePreview = () => {
    openPreview(!preview);
  };

  const dealType = values.assetProperties.dealsType;
  const isNewDeal = dealType === "NEW_DEAL";

  const onBackToAssents = () => {
    if (unfinishedId && props.history.length <= 2) {
      props.history.push(`/admin/deals/create`);
    } else {
      props.history.goBack();
    }
  };

  const changeValuesSimpleType = (...keys) => (e) => {
    const value = e && e.target ? e.target.value : e;
    const _values = { ...values };

    try {
      let link = _values;
      for (let i = 0; i < keys.length; i++) {
        if (i === keys.length - 1) {
          link[keys[i]] = value;
        } else {
          link = link[keys[i]];
        }
      }
    } catch (error) {
      // for lock up time only!!!
      _values.visibilityProperty.lockUpPeriod = {};
      let link = _values;
      for (let i = 0; i < keys.length; i++) {
        if (i === keys.length - 1) {
          link[keys[i]] = value;
        } else {
          link = link[keys[i]];
        }
      }
    }

    setValues(_values);
  };

  const onChangeRules = (data) => {
    const _values = { ...values };
    _values.visibilityProperty.activeRulesJson = JSON.stringify(data);
    Object.keys(data).forEach((field) => {
      if (!data[field]) {
        _values.visibilityProperty[field] = field === "countryRestriction" ? { listType: "ALL" } : null;
      }
    });
    setValues(_values);
  };

  const saveAs = (isActive) => async () => {
    setSaveWait(true);
    let res = null,
      val = {
        ...values,
        visibilityProperty: {
          ...values.visibilityProperty,
          isActive,
        },
      };

    //refactoring crash
    if (val.visibilityProperty.lockUpPeriod && !Object.keys(val.visibilityProperty.lockUpPeriod).length)
      val.visibilityProperty.lockUpPeriod = null;

    //format for existing deal
    if (!isNewDeal) {
      if (!val.baseCurrency) {
        const baseCurrency = val.assetFinancialProperty.baseCurrency;
        val.baseCurrency = baseCurrency;
      }
      val.assetFinancialProperty = null;
    }

    res = await Api.createAsset(props.token, val).catch((e) => {
      setSaveWait(false);
      setError(e);
    });

    if (res) {
      setLinear(res.assetId);
      finishRef.current = true;
    }

    setSaveWait(false);
  };

  useEffect(() => {
    if (finishRef.current && linearId) {
      props.history.push(`/admin/data-room/deal/${linearId}/file-manager`);
    }
  }, [finishRef, linearId]);

  useEffect(() => {
    if (isMount.current) setReady(true);
    valuesRef.current = values;

    console.clear();
    console.log(values);
  }, [values]);

  useEffect(() => {
    //save unfinished func
    const saveUnfinished = async () => {
      if (!finishRef.current) {
        for (
          let i = 0;
          valuesRef.current.assetProperties.images && i < valuesRef.current.assetProperties.images.length;
          i++
        ) {
          if (
            !valuesRef.current.assetProperties.images[i].file ||
            typeof valuesRef.current.assetProperties.images[i].file !== "object" ||
            typeof valuesRef.current.assetProperties.images[i].file.name !== "string"
          ) {
            continue;
          }

          const attachment = await Api.uploadAttachment(props.token, valuesRef.current.assetProperties.images[i].file);

          if (attachment) {
            valuesRef.current.assetProperties.images[i] = {
              ...attachment,
            };
          }
        }

        Api.updateUnfinished(props.token, valuesRef.current, unfinishedId);
      }
      getAssets(props.token);
    };
    Api.getUserGroups(props.token).then((gs) => setGroups(gs));
    Api.getUsers(props.token, "INVESTOR", 1, 1000, "").then((res) => setUsers(res.content));
    KycApi.getForms(props.token).then((forms) => setKycForms(forms));
    Api.getKycRoles(props.token).then((roles) => setKycRoles(roles));
    const getDeal = async () => {
      const res = await Api.getUnfinishedDeal(props.token, unfinishedId).catch((e) => setError(e));
      if (res) {
        _setValues({ ...JSON.parse(res.content), unfinishedId });
      }
    };
    //interval to unfinished
    const interval = setInterval(saveUnfinished, 600000);

    getDeal();
    isMount.current = true;

    return () => {
      saveUnfinished();
      clearInterval(interval);
    };
  }, []);

  const onChangeKyc = (data) => {
    const _values = { ...values };
    _values.assetKycTemplates = { ...data };
    setValues(_values);
  };

  const onDraft = () => props.history.push(`/admin/deals/unfinished_deal`);

  if (!isReady) return <CircularProgress style={{ margin: "auto" }} />;
  return (
    <Fragment>
      <Header>
        <Btn transparent onClick={onBackToAssents} width={150}>
          {icons.left} {unfinishedId ? "back to deals" : "back to option"}
        </Btn>
        <TextH3 marginSize="0 0 0 25px">Add New Asset</TextH3>{" "}
        <Btn width={180} margin="0 0 0 auto" onClick={onChangePreview}>
          {icons.eye} ASSET PREVIEW
        </Btn>
      </Header>
      <ContainerFlex h={preview ? "0px" : "auto"} row justify="space-between" wrap>
        <LeftContainer>
          {/* new markup */}
          <MainInformation
            token={props.token}
            onChange={changeValuesSimpleType}
            defaultValues={values}
            categories={props.categories}
            acceptedCurrency={props.acceptedCurrency}
            dealType={dealType}
            countriesOption={props.countriesOption}
            financialTypes={props.financialDealTypes}
            roleName={props.roleName}
            username={props.username}
            fullName={props.fullName}
          />
          <Separator />

          <DinamicFinancial
            _data={values.assetProperties.dynamicFinancialJson}
            onChange={(data) => {
              changeValuesSimpleType("assetProperties", "dynamicFinancialJson")(JSON.stringify(data));
            }}
          />
          <Separator />
          {isNewDeal ? (
            <Fragment>
              <CurrentRound
                currency={values.assetFinancialProperty.baseCurrency}
                defaultValues={values}
                onChange={changeValuesSimpleType}
              />
              <Separator />
            </Fragment>
          ) : null}

          <PreviousRound defaultValues={values} onChange={changeValuesSimpleType("roundsInfo")} />
          <Separator />
          <Summary
            token={props.token}
            onChange={changeValuesSimpleType}
            defaultValues={values}
            roleName={props.roleName}
          />
          <Separator />
          {isNewDeal ? (
            <Fragment>
              <Parameters
                token={props.token}
                onChange={changeValuesSimpleType}
                defaultValues={values}
                acceptedCurrency={props.acceptedCurrency}
              />
              <Separator />
            </Fragment>
          ) : null}
          <AdditionalInformation
            blocks={values.assetProperties.vocAssetDynamicProperties}
            onChange={changeValuesSimpleType}
            token={props.token}
          />
          <Separator />
          {/* todo view upload files files */}
          <Images token={props.token} onChange={changeValuesSimpleType} defaultValues={values} />
          <Separator />
          <Header between>
            <Btn width={94} white disabled={saveWait} onClick={onBackToAssents}>
              Cancel
            </Btn>
            <BtnContainer>
              <Btn width={180} onClick={onDraft} disabled={saveWait} white>
                save to draft
              </Btn>
              <Btn width={180} onClick={saveAs(true)} disabled={saveWait}>
                Save & Publish
              </Btn>
            </BtnContainer>
          </Header>
        </LeftContainer>

        <RightContainer>
          <Rules
            token={props.token}
            onChange={changeValuesSimpleType}
            defaultValues={values}
            ruleOptions={props.ruleOptions}
            countriesOption={props.countriesOption}
            accesses={props.accesses}
            dealTypes={props.dealTypes}
            categories={props.categories}
            investorWorkflowTypes={props.investorWorkflowTypes}
            lockUpTime={props.lockUpTime}
            groups={groups}
            onChangeGroups={changeValuesSimpleType("visibilityProperty", "groups")}
            onChangeUsers={changeValuesSimpleType("visibilityProperty", "users")}
            onChangeRules={onChangeRules}
            users={users}
          />
          <Separator />
          {isNewDeal ? (
            <Fragment>
              <BankAccount
                onChangeBank={changeValuesSimpleType("assetFinancialProperty", "account")}
                defaultValues={values}
              />
              <Separator />
              <DinamicCapital
                onChange={(e) =>
                  changeValuesSimpleType("assetProperties", "dynamicCapitalStructureJson")(JSON.stringify(e))
                }
                json={values.assetProperties.dynamicCapitalStructureJson}
                baseCurrency={values.assetFinancialProperty.baseCurrency}
              />{" "}
              <Separator />
              <KycUserType
                assetKycTemplates={
                  values.assetKycTemplates || {
                    institutionTemplateId: 0,
                    otherRolesTemplates: {},
                    professionalTemplateId: 0,
                  }
                }
                kycForms={kycForms}
                kycRoles={kycRoles}
                onChangeKyc={onChangeKyc}
              />
            </Fragment>
          ) : null}
        </RightContainer>
      </ContainerFlex>

      {error ? <ErrorMessagePortal er={error} onClose={() => setError(null)} /> : null}
      {preview ? (
        <AssetPreview
          onClose={onChangePreview}
          values={values}
          token={props.token}
          assetId=""
          _sponsors={props.sponsorsFull}
        />
      ) : null}
    </Fragment>
  );
};

export default connect(
  ({
    userReducer: {
      token,
      userInfo: { roleName, username, fullName },
    },
    dataReducer: { assets = [] },
    asyncDataReducer: {
      acceptedCurrency,
      sponsors,
      accesses,
      dealTypes,
      categories,
      investorWorkflowTypes,
      ruleOptions,
      countriesOption,
      lockUpTime,
      sponsorsFull,
      financialDealTypes,
    },
  }) => ({
    token,
    assets,
    roleName,
    username,
    // selects data
    acceptedCurrency,
    sponsors,
    accesses,
    dealTypes,
    categories,
    investorWorkflowTypes,
    ruleOptions,
    countriesOption,
    lockUpTime,
    sponsorsFull,
    financialDealTypes,
    fullName,
  }),
  { getAssets }
)(DeafFormContinue);
