import React from "react";

import { Card, Heading, Text, Quote } from "./styled";
import { Fragment } from "react";

export const Description = ({
  isTrade = false,
  info: { assetName, smallDescription, fullDescription },
  fullWidth = false,
}) => {
  return (
    <Card fullWidth={fullWidth} isOnSlider overflow="hidden">
      {!isTrade && (
        <Fragment>
          <Heading>{assetName}</Heading>
          <Quote isLastItem={!fullDescription} text={smallDescription} />
        </Fragment>
      )}
      <Text dangerouslySetInnerHTML={{ __html: fullDescription }} />
    </Card>
  );
};
