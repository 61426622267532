export default {
  pageName: "Terms & Conditions",
  preText:
    'These Terms of Use constitute a legal agreement exclusively between you, as the user of the Website, and Glafka Capital Limited. This Website is operated under license from VOC Corp Ltd (“VALK” or the “Technology Provider”). Throughout these Terms of Use we will refer to you as "you" or "user", and we will refer to Glafka Capital Limited as "we", "us" or " Glafka Capital ". ',
  // "The following <b>Terms of Service</b> (collectively with our Privacy Policy, the <b>“ToS”</b>) govern your use of https:// investure.co (the <b>“Site”</b>) and the products, features, contents, applications and services provided by Investure Global AB (together with the Site, the <b>“Platform”</b>). You should regularly review the ToS, as they are subject to change.",
  content: [
    {
      heading: `<span>1.</span> General Terms and Conditions.`,
      text: `All use of the Platform is subject to the ToS. By accessing and using the Platform, you acknowledge, accept, and agree to the ToS and all other terms, conditions, procedures and policies that may be published from time to time on the Site by us, each of which is incorporated by reference. The ToS represents a binding contract between you and Investure Global AB (Company number 559243-9102), its subsidiaries, affiliates, assignees and its and their officers, directors, employees and agents (<b>“Investure”</b>, <b>“we”</b>, <b>“us”</b> or <b>“our”</b>), and are in addition to any other agreements between you and Investure, including any other agreements that govern your use of products, features, contents, applications and services available on the Platform. If you are accessing the Platform on behalf of another entity or individual, you represent and warrant that you have the authority to agree to the ToS on such entity’s or individual’s behalf. If you do not agree with anything contained in the ToS, please do not submit information to, access information from, or otherwise utilize the Platform. Investure reserves the right to change the ToS at any time without notice to you.
        <br/>
        <br/>
            Access to the Website is permitted on a temporary basis, and we reserve the right to withdraw or amend the service we provide on this Website without notice. We will not be liable if for any reason our site is unavailable at any time or for any period.
        <br/>
        <br/>    
        Use of the Platform following the posting of any changes to the ToS shall be deemed to be acceptance thereof by you. In the ToS, we may refer to a customer/reader/entity as <b>“you”</b>. You agree to keep business information and trade secrets of Investure, including, but not limited to the terms and pricing set forth herein, and any user account information confidential until such information becomes known to the public generally without your fault and except to the extent that disclosure may be required by law, regulation or legal process`,
    },
    {
      heading: `<span>2.</span> Eligibility.`,
      text: `You represent and warrant that you are at least 18 years of age. If you are under age 18, you may not, under any circumstances or for any reason, use the Platform. We may, in our sole discretion, refuse to offer the Platform to any person or entity and change its eligibility criteria at any time. You are solely responsible for ensuring that the ToS are in compliance with all laws, rules and regulations applicable to you and the right to access the Platform is revoked where the ToS or use of the Platform is prohibited or to the extent offering, sale or provision of the Platform conflicts with any applicable law, rule or regulation. Further, the Platform is offered only for your use, and not for the use or benefit of any third party.`,
    },
    {
      heading: `<span>3.</span> Registration.`,
      text: `When signing up for the Platform and creating an account on the Platform (an <b>“Account”</b>), you must provide accurate and complete information and keep your Account information updated. You shall not: (a) select or use as a username a name of another person with the intent to impersonate that person; (b) use as a username a name subject to any rights of a person other than you without appropriate authorization; or (c) use, as a username, a name that is otherwise offensive, vulgar or obscene. You are solely responsible for the activity that occurs on your Account, and for keeping your Account password secure. You may never use another person’s user account or registration information for the Platform without permission. You must notify us immediately of any change in your eligibility to use the Platform (including any changes to or revocation of any licenses from state authorities), breach of security or unauthorized use of your Account. You should never publish, distribute or post login information for your Account. You shall have the ability to delete your Account, either directly or through a request made to one of our employees or affiliates.`,
    },
    {
      heading: `<span>4.</span> Privacy.`,
      text: `Your privacy is very important to us. Investure’s <a>Privacy Policy</a> explains how we treat your personal information and protect your privacy when you are using our Platform. By using our Platform, you agree that Investure may use your information as set forth in the Privacy Policy. We designed our Privacy Policy to make important disclosures about how you can use Investure to share with others and how we collect and can use your content and information. We encourage you to read the Privacy Policy, and to use it to help make informed decisions.`,
    },
    {
      heading: `<span>5.</span> Use of the Platform; Limitations; Acceptable Use.`,
      text: `
      <ul>
      <li>
        The Platform is designed for private market deal professionals to
        connect with each other for the purposes of general networking, business
        development, seeking and managing investment or acquisition
        opportunities, and identifying investors and providers of capital.
        Members of the Platform use a variety of tools to implement the
        aforementioned processes, including data room and document hosting
        tools, communication and messaging tools, and a variety of reports and
        analytics. Investure is not involved in the actual transaction between
        buyers and sellers, and does not render investment or legal advice in
        connection therewith. None of the information submitted on the Platform
        constitutes a solicitation, offer, opinion, or recommendation by
        Investure to buy or sell any shares or securities or other financial
        instruments or to provide legal, tax, accounting, or investment advice
        or services regarding the suitability or profitability of any security
        of any kind, investment or transaction. Investure has not made any
        recommendations regarding the merit of any company identified on the
        Platform, made any recommendation regarding the purchase or sale of any
        shares or security, or endorsed or sponsored any company identified on
        the Platform. For that reason all users of the Platform agree to be
        responsible for their own due diligence and the legal and regulatory
        compliance of any transaction they enter into, and Investure makes no
        representation or assurance about such compliance. As a user of the
        Platform, you are required to provide true and accurate information
        about their businesses, and to update and maintain such information, but
        Investure cannot and does not confirm the accuracy of information
        provided.
      </li>
      <li>
        If Investure permits you to input information into the Platform, you may
        not (and may not permit any third party to) input any information or
        content that:
        <ul>
          <li>you know is false, misleading, untruthful or inaccurate;</li>
          <li>
            is promotional in nature, including solicitations for funds or
            businesses, without the prior written authorization of Investure;
          </li>
          <li>
            constitutes junk mail, spam, chain letters, pyramid schemes or
            the like;
          </li>
          <li>
            is unlawful, harmful, threatening, abusive, harassing, tortious,
            defamatory, vulgar, obscene, libellous, deceptive, fraudulent,
            invasive of another’s privacy, offensive, profane, hateful or is
            racially, ethnically or otherwise objectionable as determined by us
            in our sole discretion, or otherwise violates the legal rights
            of others;
          </li>
          <li>
            you do not have the right to make available under any law or under
            contractual or fiduciary relationships (such as inside information,
            proprietary and confidential information learned or disclosed as
            part of employment relationships or under nondisclosure 
            agreements);
          </li>
          <li>
            infringes any patent, trademark, trade secret, copyright, right of
            publicity or other proprietary rights of any party or violates any
            law or contractual duty;
          </li>
          <li>
            impersonates any person or entity, including any of our employees or
            representatives; or
          </li>
          <li>
            contains software viruses or any other computer code, files, or
            programs designed to interrupt, destroy, gain access to or limit the
            functionality of any computer software or hardware, or
            telecommunications equipment.
          </li>
        </ul>
      </li>
      <li>
        You shall not: (i) take any action that imposes or may impose (as
        determined by us in our sole discretion) an unreasonable or
        disproportionately large load on our (or our third party providers’)
        infrastructure; (ii) interfere or attempt to interfere with the proper
        working of the Platform or any activities conducted on the Platform;
        (iii) bypass, circumvent or attempt to bypass or circumvent any measures
        we may use to prevent or restrict access to the Platform (or other
        accounts, computer systems or networks connected to the Services); (iv)
        use manual or automated software, devices, or other processes to “crawl”
        or “spider” any page of the website; (v) harvest or scrape any Content
        from the Platform; or (vi) otherwise take any action in violation of our
        guidelines and policies.
      </li>
      <li>
        You shall not (directly or indirectly): (i) decipher, decompile,
        disassemble, reverse engineer or otherwise attempt to derive any source
        code or underlying ideas or algorithms of any part of the Platform
        (including without limitation any application), except to the limited
        extent applicable laws specifically prohibit such restriction, (ii)
        modify, translate, or otherwise create derivative works of any part of
        the Platform, or (iii) copy, rent, lease, distribute, or otherwise
        transfer any of the rights that you receive hereunder. You shall abide
        by all applicable local, national and international laws and
        regulations.
      </li>
      <li>
        We also reserve the right to access, read, preserve, and disclose any
        information as we reasonably believe is necessary to (i) satisfy any
        applicable law, regulation, legal process or governmental request, (ii)
        enforce the ToS, including investigation of potential violations hereof,
        (iii) detect, prevent, or otherwise address fraud, security or technical
        issues, (iv) respond to user support requests, or (v) protect the
        rights, property or safety of us, our users and the public.
      </li>
      <li>
        Investure reserves the right to edit, restrict or remove any content you
        provide for any reason or no reason at any time. The information and
        materials made available through the Platform may contain typographical
        errors or inaccuracies. In addition, Investure does not control the
        information provided by other users that is made available through the
        Platform.
      </li>
      <li>
        Investure reserves the right to refuse service, terminate relationships,
        and/or cancel orders in its discretion.
      </li>
    </ul>
      `,
    },
    {
      heading: `<span>6.</span> Duty of Confidentiality.`,
      text: `
      You are permitted to access the information on the Platform solely for your personal use or in the course of your business for the purpose of assessing whether or not to participate in transactions, for conducting transaction and for administering transactions that you have participated in. The content of the Platform is not permitted to be used for any other purpose.
      <br/>
      <br/>
      Each user accepts that it owes a duty of confidentiality to Investure and its project partners with regard to:
      <ul>
      <li>
      Such information and documents may not be disclosed to any third party.
      </li>
      <li>
      the internal operating systems and procedures used by Investure;
      </li>
      <li>
      the contents of Investment Agreements and Documents executed; and
any information provided in relation to potential or actual fundraisers.
      </li>
    </ul>
    It is acknowledged by Investure that the duty of confidentiality owed by a user does not extend to information previously held by that user or is otherwise in the public domain.
      `,
    },
    {
      heading: `<span>7.</span> Non-Circumvention.`,
      text: `You agree not to pursue or engage in any transaction involving potential transactions on the platform in such a way that it damages the economic opportunity of Investure or its partners. Any transaction that has been identified or introduced to you on the platform shall be executed on the platform. 
      <br/><br/>
      By signing an NDA to access a data room on the platform you agree that you will be getting access to more detailed information about a transaction than is available in the public domain. You further agree not to undertake any transaction or series of transactions of any kind outside the platform with counter parties identified on the platform or collect fees from such parties without the express prior written consent of Investure, which will not be unreasonably withheld.
      `,
    },
    {
      heading: `<span>8.</span> Intellectual Property; Copyright.`,
      text: `
      <ul>
      <li>
        <b>Content.</b> The contents of the Platform and any products or
        services provided or sold by Investure, including the Platform’s look
        and feel, text, graphics, logos, button icons, images, audio and video
        clips (if any) and software, as well as the compilation of businesses,
        advisors, investors and lenders listed on the Platform
        (<b>“Content”</b>), are the property of Investure or its content
        suppliers, and are subject to the copyright or other intellectual
        property rights of Investure and to the terms of licenses held by
        Investure. Such intellectual property is protected by federal and state
        law.
      </li>
      <li>
        <b>Use License.</b> Subject to the ToS, we grant each user of the
        Platform a worldwide, non- exclusive, non-sublicensable and
        non-transferable license to use (i.e., to download and display locally)
        Content solely for purposes of accessing and using the Platform. Any
        unauthorized use, reproduction, modification, distribution,
        transmission, republication, display or performance of the Content and
        software on this Platform, or of any products or services sold by
        Investure, is strictly prohibited. You may copy information from the
        Platform only as strictly necessary for your own use of the Platform.
        Otherwise, no portion of the Platform may be reproduced, duplicated,
        copied, sold, resold, licensed, rented or otherwise exploited for any
        commercial purpose that is not expressly permitted by Investure. The
        commercial use or public dissemination of any information and data
        gathered from Investure is strictly prohibited, unless specifically
        authorized in writing. Any violation of the foregoing may subject you to
        compensatory and punitive damages, and shall specifically also entitle
        Investure to equitable relief, in addition to any other available
        remedies.
      </li>
      <li>
        <b>User Content.</b> All Content added, created, uploaded, submitted,
        distributed, or posted to the Platform by users (collectively,
        <b>“User Content”</b>), whether publicly posted or privately
        transmitted, is the sole responsibility of the person who originated
        such User Content. You represent and warrant that all User Content
        provided by you is accurate, complete, up-to- date, in compliance with
        all applicable laws, rules and regulations and you have all rights to
        provide the User Content and grant the licenses set forth in Section
        6(d), to us without infringement or violation of any third party rights,
        including without limitation, any privacy rights, publicity rights,
        copyrights, trademarks, contract rights, or any other intellectual
        property or proprietary rights.
      </li>
      <li>
        <b>License Grant.</b> By submitting any User Content to us, you grant
        Investure a perpetual, worldwide, royalty-free, irrevocable,
        non-exclusive, sub-licensable and transferable right to use, reproduce,
        modify, adapt, aggregate, publish, create derivative works of, display,
        perform, distribute and otherwise fully exploit such User Content, in
        any form or medium known or later developed (including, without
        limitation, third party websites and feeds), in furtherance of our (and
        our successors’ and assigns’) businesses, the terms of the ToS and the
        actions and transactions contemplated hereby, including after the
        termination of your Account or the Platform. You also hereby do and
        shall grant each user of the website and/ or the Platform a
        non-exclusive, perpetual license to access your User Content through the
        website and/or the Platform, and to use, edit, modify, reproduce,
        distribute, prepare derivative works of, display and perform such User
        Content, including after the termination of your Account or the
        Platform. For clarity, the foregoing license grants to us and our users
        does not affect your other ownership or license rights in your User
        Content, including the right to grant additional licenses to your User
        Content, unless otherwise agreed in writing. You agree that you will
        abide by and maintain all and will not and will not allow others to
        delete or alter, information, restrictions, author attributes or
        copyright notices contained in any Content accessed through the Platform
        . Nothing on the Platform or elsewhere should be construed to grant any
        license or right to use, implied or otherwise, any mark displayed on the
        Platform without the written permission of Investure or the third party
        owner of the mark.
      </li>
      <li>
        <b>Availability of Content.</b> We do not guarantee that any Content
        will be made available on the Site or through the Services. Investure
        reserves the right, in its sole discretion, to (i) remove, edit or
        modify any Content, at any time, without notice to you and for any
        reason (including, but not limited to, upon receipt of claims or
        allegations from third parties or authorities that such Content
        infringes or appears to infringe on intellectual property rights owned
        by others or if we are concerned that you may have violated the ToS),
        for no reason at all and (ii) to remove or block any Content from the
        Platform.
      </li>
      <li>
        <b>Copyright Infringement.</b> If you believe that any material
        contained in the Platform infringes your intellectual property, you
        should notify Investure of the alleged infringement in accordance with
        the following procedure: Send any notification of claimed copyright
        infringement to info@Investure.co. To be effective, the notification
        must be in writing and include the following information: physical or
        electronic signature of the owner or authorized agent of the owner of
        the allegedly infringed work; identification of the allegedly infringed
        work; identification of the material that is claimed to be infringing
        and reasonably sufficient information for Investure to locate the
        material; contact information of the notifying party, such as address,
        telephone number and email; a statement that the notifying party has a
        good faith belief that the use of the material in the manner complained
        of is not authorized by the owner of the allegedly infringed work, its
        agent or the law; and a statement, under penalty of perjury that the
        information in the notification is accurate and the notifying party is
        the owner or authorized agent of the allegedly infringed work.
      </li>
    </ul>
      `,
    },
    {
      heading: `<span>9.</span> Email Notification.`,
      text: `Investure uses email to communicate with users. You hereby authorize and agree that Investure may communicate with you via email and you consent to receiving and giving any notice required under the ToS or any other agreement with Investure via email. Investure will use reasonable efforts to honour any request you may have to opt out from receiving emails, but under no circumstance will Investure have any liability for sending any email to you or to any of its customers. Electronic notices should be sent to info@Investure.co.`,
    },
    {
      heading: `<span>10.</span> Hyperlink Policy.`,
      text: `The Platform may contain links to third party websites and other websites may contain links to the Platform. Any such link is provided only as a convenience. The inclusion of any link does not imply an affiliation, sponsorship, endorsement, approval, investigation, verification or monitoring by Investure of any information contained in any third-party website. In no event shall Investure be responsible or liable for the information contained on that third-party website, your use of or inability to use such website and any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such information, products or services available on or through any such website. You should also be aware that the terms and conditions of such website and the website’s privacy policy may be different from those applicable to your use of the Investure Platform.
        `,
    },
    {
      heading: `<span>11.</span> Third Party Content.`,
      text: `Certain portions of the Platform, including, without limitation, information on certain private companies, may contain unedited or third party content. All postings, messages, text, images, links to third-party websites or other materials published or otherwise made available through this section (the <b>“Third Party Content”</b>) are the sole responsibility of the person(s) who originated such Third Party Content and Investure may not monitor, does not control and does not investigate or validate such Third Party Content; provided that Investure reserves the right at all times (but will not have an obligation) to remove any Third Party Content. By using this Third Party Content, you agree to not rely on the Third Party Content and understand that you may be exposed to Third Party Content that is, without limitation, inaccurate, inappropriate, misleading, unlawful, offensive or otherwise objectionable, and that Investure makes no representations or warranties regarding the Third Party Content and is not responsible or liable in any manner for the Third Party Content or the conduct, whether online or offline, of any user. The Third Party Content does not constitute legal or financial advice and must not be used in the place of legal counsel or financial due diligence. You should independently evaluate and verify all Third Party Content.`,
    },
    {
      heading: `<span>12.</span> Payments and Billing.`,
      text: `
        <ul>
            <li>
                <b>Paid Services.</b> Certain services on the Platform may be subject to payments now or in the future (the <b>“Paid Services”</b>). Please note that any payment terms presented to you in the process of using or signing up for a Paid Service are deemed part of the ToS.
            </li>
            <li>
                <b>Billing.</b> We may use a third-party payment processor (the <b>“Payment Processor”</b>) to bill you through a payment account linked to your Account on the Platform (your <b>“Billing Account”</b>) for use of the Paid Services. The processing of payments will be subject to any terms, conditions and privacy policies of such a Payment Processor in addition to the ToS. We are not responsible for error by a Payment Processor. By choosing to use Paid Services, you agree to pay us, all charges at the prices then in effect for any use of such Paid Services in accordance with the applicable payment terms and you authorize us, to charge or have the Payment Processor charge, your chosen payment provider (your <b>“Payment Method”</b>). You agree to make payment using that selected Payment Method. We reserve the right to correct any errors or mistakes that it makes even if it has already requested or received payment.
            </li>
            <li>
                <b>Payment Method.</b> The terms of your payment will be based on your Payment Method and may be determined by agreements between you and the nancial institution, credit card issuer or other provider of your chosen Payment Method. If we, through a Payment Processor, do not receive payment from you, you agree to pay all amounts due on your Billing Account upon demand.
            </li>
        </ul>
      `,
    },
    {
      heading: `<span>13.</span> Termination.`,
      text: `From time to time we may update these Terms of Use to take account of changes in the law or for any other reason. If we update these Terms of Use, we will post a new version on the Website, and as soon as you use the Website after they are posted, you will be deemed to have agreed to the updated version, but you will still be bound by the terms of any previous versions to which you have agreed or been deemed to agree. If there is a conflict between two versions of the Terms of Use to which you have agreed or been deemed to agree, the more recent version shall take precedence unless it is expressly stated otherwise`,
    },
    {
      heading: `<span>14.</span> No Warranty; Limitation of Liability.`,
      text: `
        <ul>
            <li>
                Any material downloaded, accessed or otherwise obtained through the Platform is obtained at your own discretion and risk, and you will be solely responsible for any damage to your computer system or loss of data that results therefrom. EXCEPT AS EXPRESSLY SET FORTH HEREIN, INVESTURE AND ALL OF ITS THIRD-PARTY LICENSORS EXPRESSLY DISCLAIM ANY AND ALL WARRANTIES AND REPRESENTATIONS, EXPRESS OR IMPLIED, INCLUDING ANY (I) WARRANTIES OF TITLE, NON-INFRINGEMENT, MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE OR USE AS TO THE PLATFORM, INCLUDING THE CONTENT, INFORMATION, DATA, SOFTWARE, OR PRODUCTS CONTAINED THEREIN, OR THE RESULTS OBTAINED BY THEIR USE OR AS TO THE PERFORMANCE THEREOF, (II) WARRANTIES OR CONDITIONS ARISING THROUGH COURSE OF DEALING, COURSE OF PERFORMANCE OR USAGE OF TRADE, AND (III) WARRANTIES OR CONDITIONS OF UNINTERRUPTED OR ERROR-FREE ACCESS TO OR USE OF THE PLATFORM. INVESTURE PROVIDES THE PLATFORM AND ALL COMPONENTS THEREOF ARE PROVIDED ON AN “AS IS” BASIS AND YOUR USE OF THE PLATFORM IS AT YOUR OWN RISK. INVESTURE MAKES NO GUARANTEES OR WARRANTIES OF ANY KIND AS TO THE SECURITY, ADEQUACY, COMPLETENESS, SUFFICIENCY, TIMELINESS OR ACCURACY OF ANY CONTENT OR MATERIAL AVAILABLE IN OR THROUGH THE PLATFORM, THAT ANY DEFECTS OR ERRORS WILL BE CORRECTED, OR THAT ANY CONTENT OR SOFTWARE AVAILABLE AT OR THROUGH THE PLATFORM IS FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.
            </li>
            <li>
                YOU HEREBY AGREE THAT ANY MONETARY CLAIMS OF ANY KIND ASSESSED AGAINST INVESTURE OR ITS DIRECTORS, EMPLOYEES, AGENTS, PARTNERS, SUPPLIERS OR CONTENT PROVIDERS ARISING OUT OF OR RELATING TO THE USE OF THE PLATFORM SHALL NOT EXCEED (IN AGGREGATE) OF THE GREATER OF (I) THE TOTAL AMOUNT YOU (OR ANY OTHER USER) PAID TO INVESTURE FOR THE PLATFORM DURING THE PREVIOUS TWELVE (12) MONTHS OR (II) 5 000 SEK. IN NO EVENT SHALL INVESTURE OR ITS DIRECTORS, EMPLOYEES, AGENTS, PARTNERS, SUPPLIERS OR CONTENT PROVIDERS BE LIABLE TO YOU, OR TO ANY THIRD PARTY, FOR ANY LOST PROFITS, DATA LOSS, COST OF PROCUREMENT OF SUBSTITUTE GOODS OR SERVICES, OR INCIDENTAL, CONSEQUENTIAL, PUNITIVE, SPECIAL, COMPENSATORY OR INDIRECT DAMAGES OF ANY KIND WHATSOEVER (HOWEVER ARISING), EVEN IF ADVISED AS TO THE POSSIBILITY OF SUCH DAMAGES, REGARDLESS OF WHETHER THE CLAIM FOR SUCH DAMAGES IS BASED IN CONTRACT, STRICT LIABILITY, NEGLIGENCE OR OTHERWISE. THIS LIMITATION ON LIABILITY INCLUDES, BUT IS NOT LIMITED TO, THE TRANSMISSION OF ANY VIRUSES WHICH MAY INFECT YOUR EQUIPMENT, FAILURE OF MECHANICAL OR ELECTRONIC EQUIPMENT OR COMMUNICATION LINES, TELEPHONE OR OTHER INTERCONNECT PROBLEMS (E.G., YOU CANNOT ACCESS YOUR INTERNET PROVIDER), UNAUTHORIZED ACCESS, THEFT, OPERATOR ERRORS, STRIKES OR OTHER LABOR PROBLEMS OR ANY FORCE MAJEURE. INVESTURE CANNOT AND DOES NOT GUARANTEE CONTINUOUS, UNINTERRUPTED OR SECURE ACCESS TO THE PLATFORM.
            </li>
        </ul>
      `,
    },
    {
      heading: `<span>15.</span> Indemnity.`,
      text: `You agree to indemnify and hold harmless Investure, our affiliates and each of our and their respective officers, directors, agents, and employees, from and against any suit, action, claim, demand, penalty or loss, including reasonable attorneys’ fees, made by or resulting from any third party due to or arising out of your (or any third party using your Account or identity in the Platform) use or misuse of the Platform, breach of the ToS or the materials it incorporates by reference, or violation of any law, regulation, order or other legal mandate or the rights of a third party. We reserve the right to assume the exclusive defence and control of any matter otherwise subject to indemnification by you, in which event you will assist and cooperate with us in asserting any available defences.`,
    },
    {
      heading: `<span>16.</span> Choice of Law.`,
      text: `You will resolve any claim, cause of action or dispute (together a “claim”) you have with Investure arising out of or relating to the Platform or the ToS exclusively in Stockholm where you agree that the courts of Sweden shall have exclusive jurisdiction. The laws of Sweden will govern the ToS, as well as any claim that may arise between you and Investure, without regard to conflict of law principles. In any dispute arising under the ToS, the prevailing party will be entitled to reasonable attorneys’ fees and expenses.
      <br/>
      <br/>
      <ul>
        <li>
            You agree that regardless of any statute or law to the contrary, any claim or cause of action arising out of, related to or connected with the use of Platform or the ToS must be led within three (3) months after such claim of action arose or be forever banned.
        </li>
        <li>
            This section will survive the termination of your relationship with us.
        </li>
        </ul>
      `,
    },
    {
      heading: `<span>17.</span> Marketing of Names.`,
      text: `When you announce closed transactions via your website or press release or other broad disclosure methods, Investure may disseminate its own release to its constituents indicating the use of the Platform in association with said transaction. Investure may add your entity’s name to Investure’s Member roster, which is professionally presented from time to time on its website and in marketing materials.`,
    },
    {
      heading: `<span>18.</span> Miscellaneous.`,
      text: `The ToS sets forth the entire understanding and agreement between the parties with respect to the subject matter herein (excluding the terms and conditions of any client agreement you may have entered into separately with Investure). 
      <br/>
      <br/>
      We shall not be liable for any failure to perform our obligations hereunder where such failure results from any cause beyond our reasonable control, including, without limitation, mechanical, electronic or communications failure or degradation.
      <br/>
      <br/>
      You may not assign the ToS, or assign, transfer or sublicense your rights, if any, in the Platform. We may assign, transfer or delegate any of our rights and obligations hereunder without consent.
      <br/>
      <br/>
      A failure to act with respect to a breach by you or others does not waive Investure’s right to act with respect to subsequent or similar breaches.
      <br/>
      <br/>
      In the event that any provision or any portion of any provision of the ToS shall be held to be void or unenforceable, the remaining provisions of the ToS (and the balance of any provisions held void or unenforceable in part only) shall continue in full force and effect.
      <br/>
      <br/>
      If you do not agree with any part of these terms and conditions, or you have a dispute or claim against Investure or its supplier or affiliates with respect to the ToS you should immediately refrain from using the Platform.
      <br/>
      <br/>
      No agency, partnership, joint venture, or employment relationship is created as a result of the ToS and neither party has any authority of any kind to bind the other in any respect.
      `,
    },
    {
      heading: `<span>19.</span> International Use.`,
      text: `We make no warranties that materials on Investure are appropriate or available for use in locations outside Sweden and the European Economic Area. If it is illegal or prohibited in your country of origin to access or use Investure, then you should not do so. Those who choose to access Investure outside Sweden and the European Economic Area do so on their own initiative and are responsible for compliance with all local laws and regulations.`,
    },
    {
      heading: `<span>20.</span> No Investment Advice, Recommendations or Offer.`,
      text: `
      You acknowledge that the content of Investure is for general, informational purposes only and is not intended to constitute an offer to sell or buy any securities, promise to undertake or solicit business, or pursue any other particular course of action and may not be relied upon in connection with any offer or sale of securities. Any person seeking to rely upon the information contained on, or made available via, Investure, does so at their own risk. We are not utilizing Investure to provide investment or other advice, and nothing on Investure (including but not limited to the provision of third party documentation) is to be deemed a recommendation that you buy, sell or hold, or that you are eligible to buy, sell or hold any security or other investment or that you pursue any investment style or strategy. If you require investment, accounting, tax or legal advice, you should consult with your own advisors with respect to your individual circumstances and needs.
      <br/>
      <br/>
      We make no commitment and we disclaim any duty to update the information contained on or via, Investure or to require any third party to update such information.
      <br/>
      <br/>
      <b>Past Performance.</b> All performance data or comments expressed on, or made available via, Investure are an indication of past performance. Past performance is not indicative of future results; no representation is being made that any investment will or is likely to achieve pro ts or losses similar to those achieved in the past, or that signi cant losses will be avoided.
      <br/>
      <br/>
      <b>Securities Transactions.</b> Investure serves as an information medium and a private space to facilitate communications between members. Members are responsible for determining the qualifications of other members and for negotiating and effecting any resulting transactions. All interactions between members, including interactions regarding potential transactions and negotiations in connection therewith, take place between those members, and do not involve Investure. Investure does not act as the agent of any member, nor does it prepare or provide advice on the preparation of offering materials, assist in the negotiation of transactions, or effect any securities transactions. Investure does not make any recommendations regarding securities and does not hold any member funds or securities. Moreover, members should be aware that primary and secondary securities transactions are highly specialized and the merit of any particular securities transaction depends upon the specific facts and circumstances of that transaction. Investments in securities, including investments in private funds, are speculative and may involve the risk of substantial loss. Members are strongly urged to consult with their financial advisors before making an investment in securities.
      <br/>
      <br/>
      <b>MiFID Classification.</b> In accordance with the criteria established by the Markets in Financial Instruments Directive no. 2004/39/EC of April 21, 2004 (<b>“MiFID”</b>) and implementing measures as transposed into Swedish law for classification of clients, when the service is provided by Investure, you will be categorized by Investure as a <b>“Professional Investor”</b> as defined in MiFID, to the extent you are not already categorized as an “Eligible Counterparty,” for the purposes of its business relationship with Investure, and will therefore benefit from the regulatory protections afforded by applicable laws, rules and regulations to this category of client.
      <br/>
      <br/>
      To the extent that you intend to do so, you are eligible to invest in the investment opportunities that may be listed or otherwise become available through Investure. You acknowledge that an investment in any such investment opportunity should only be made by a Professional Investor pursuant to the laws and regulations applicable to it or the relevant investment opportunity. You further agree to submit any corresponding pre-qualification questionnaires reasonably requested by Investure throughout the term of the Agreement.
      <br/>
      <br/>
      Contact. You may contact us at the following address: Tomtebogatan 8, 11339 Stockholm, Sweden
      <br/>
      <br/>
      Effective Date of ToS: 1st of November, 2020
      `,
    },
  ],
};
