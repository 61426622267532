import { setCompanyInfo } from "./companyInfoActions";
import { handleActions } from "helpers/immer";

const initialState = () => ({
  companyInfo: null,
});

export const companyInfoReducer = handleActions(
  {
    [setCompanyInfo]: (draftState, { payload }) => {
      draftState.companyInfo = payload;
    },
  },
  initialState()
);
