import styled from "styled-components";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";

import { ReactComponent as More } from "../../../../../../assets/more-horizontal.svg";
import { ReactComponent as NoAvatar } from "../../../../../../assets/no-avatar.svg";

import DataRoomApi from "../../../../../helpers/api";
import theme from "models/themes";
import { DynamicBtn } from "components/admin-layout/pages/DealForm/styled";

export const FileWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  min-height: calc(100vh - 295px);
  ${({ isOpen }) => {
    if (isOpen) {
      return `
        & > * {
          width: 50%;
        }
      `;
    } else {
      return `
        & > * {
          width: 100%;
        }
      `;
    }
  }}
`;

export const Flex = styled.div`
  width: ${({ width = "100%" }) => width};
  height: ${({ height = "100%" }) => height};
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ mb = 25 }) => `${mb}px`};
  padding: ${({ p = 0 }) => p};
  box-sizing: border-box;
  align-items: center;
`;

export const Card = styled.div`
  width: ${({ width }) => width};
  height: 60px;
  background: #ffffff;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: ${({ p = 0 }) => p};
  box-sizing: border-box;
  overflow: hidden;
`;

export const RouteLink = styled(Link)`
  font-family: Muli;
  position: relative;
  white-space: nowrap;
  padding-bottom: 0;
  padding-top: 0;
  width: ${({ width, fullWidth, _width }) => (width ? `${width}px` : fullWidth ? "100%" : _width ? _width : "94px")};
  border: 1px solid
    ${({ black, hide, error, green, theme: { primary } }) =>
      black ? "#20263C" : hide ? "#CACACA" : error ? "#FF4343" : green ? "#55B17C" : primary};
  height: ${({ height }) => (height ? `${height}px` : "32px")};
  background: ${({ black, white, hide, transparent, error, green, theme: { primary } }) =>
    black ? "#20263C" : white || hide ? "#fff" : transparent || error || green ? "transparent" : primary};
  color: ${({ white, hide, transparent, error, green, theme: { primary, red } }) =>
    white || transparent ? primary : hide ? "#CACACA" : error ? red : green ? "#55B17C" : "#fff"};
  font-size: 10px;
  line-height: 135%;
  text-align: center;
  font-weight: bold;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  border-radius: 4px;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: ${({ margin }) => (margin ? margin : "0")};
  transition: all 0.3s linear;
  text-decoration: none;
  &:disabled {
    background: #e3e3e3 !important;
    color: white !important;
    border: none;
    > svg {
      path {
        stroke: #fff;
      }
    }
  }
  &:focus {
    background: ${({ black, white, hide, transparent, error, green, theme: { primary } }) =>
      black ? "#20263C" : white || hide ? "#fff" : transparent || error || green ? "transparent" : primary};
  }
  > svg {
    margin: ${({ rightIcon }) => (rightIcon ? "0 0 0 6px " : "0 6px 0 0")};
    transform: ${({ transform }) => (transform ? "rotate(180deg)" : "none")};
    transition: all 0.3s linear;

    path {
      stroke: ${({ transparent, error, green, theme: { primary, red } }) =>
        transparent ? primary : error ? red : green ? "#55B17C" : "#fff"};
    }
  }
`;

export const Heading = styled.h2`
  font-family: Muli;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 135%;
  color: #181818;
  margin: 0 0 0 25px;
`;

export const Button = styled(DynamicBtn)`
  width: ${({ width = 180 }) => `${width}px`};
  height: 32px;
  background: ${({ transparent, theme: { primary } }) => (transparent ? "#fff" : primary)};
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${({ theme: { primary } }) => primary};
  box-sizing: border-box;
  border-radius: 2px;
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 135%;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: ${({ transparent, theme: { primary } }) => (transparent ? primary : "#fff")};
  outline: none;
  cursor: pointer;
  position: relative;
  left: 0;
  top: 0;
  > svg {
    margin-right: 4px;
    path {
      stroke: ${({ transparent, theme: { primary } }) => (transparent ? primary : "#fff")};
    }
  }
  &:disabled {
    background: #e3e3e3 !important;
    color: white !important;
    border: none;
    > svg {
      path {
        stroke: #fff;
      }
    }
  }
`;

export const NavLink = styled(Link)`
  width: 118px;
  display: flex;
  height: 60px;
  align-items: center;
  justify-content: center;
  border-bottom: 2px solid ${({ active, theme: { primary } }) => (active ? primary : "transparent")};
  box-sizing: border-box;
  cursor: pointer;
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 135%;
  color: #181818;
  opacity: ${({ active }) => (active ? 1 : 0.5)};
  text-decoration: none;
  transition: all 0.1s linear;
  > svg {
    margin-right: 10px;
  }
`;

export const SerchBlock = styled.div`
  width: calc(100% - 410px);
  border-right: 1px solid #f1f3f8;
  padding: 0 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  position: relative;
  > svg {
    position: absolute;
    left: 36px;
    width: 12px;
    height: 12px;
    path {
      stroke: ${({ theme: { primary } }) => primary};
    }
  }
`;

export const SearchInput = styled.input`
  width: 100%;
  height: 42px;
  background: #ffffff;
  outline: none;
  border: 1px solid #efefef;
  box-sizing: border-box;
  border-radius: 4px;
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 135%;
  letter-spacing: -0.02em;
  color: #333333;
  padding: 0 20px 0 35px;
  box-sizing: border-box;
  &:placeholder {
    color: rgba(24, 24, 24, 0.4);
  }
`;

export const Table = styled.div`
  /* background: #ffffff; */
  border: 1px solid #efefef;
  box-sizing: border-box;
  border-radius: 4px;
  width: 100%;
  overflow: hidden;
`;

export const TRow = styled.div`
  border-bottom: 1px solid #efefef;
  box-sizing: border-box;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  cursor: ${({ noHover = false }) => (noHover ? "auto" : "pointer")};
  transition: all 0.1s linear;

  background: ${({ active, theme: { quoteBgInvestor } }) => (active ? quoteBgInvestor : "#fff")};
  &:hover,
  &:active {
    background: ${({ noHover = false, theme: { quoteBgInvestor } }) => (noHover ? "#fff" : quoteBgInvestor)};
  }
`;

export const TCeil = styled.div`
  height: 50px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  border-right: 1px solid #efefef;
  padding: 0 ${({ p = 20 }) => `${p}px`};
  width: ${({ big, middle, full, fixedWidth }) => {
    if (full) return "100%;";
    return big ? "calc(100% - 560px)" : middle ? "200px" : fixedWidth ? fixedWidth : "120px";
  }};

  font-family: Muli;
  font-style: normal;
  font-weight: ${({ lower = false }) => (lower ? "normal" : "bold")};
  font-size: ${({ lower = false }) => (lower ? "14px" : "10px")};
  line-height: 135%;
  letter-spacing: 0.05em;
  text-transform: ${({ lower = false }) => (lower ? "none" : "uppercase")};
  color: rgba(24, 24, 24, 0.4);
  &:last-child {
    border-right: none;
  }
`;

export const BlueText = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 135%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: ${({ theme: { primary } }) => primary};
`;

export const FolderName = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 135%;
  color: #181818;
  text-transform: none;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  > svg {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
  > svg:first-child {
    width: 12px;
    height: 12px;
    transform: ${({ open }) => (open ? "none" : "rotate(-90deg)")};
  }
  &:hover {
    > div {
      margin-left: 10px;
      display: flex;
    }
  }
`;

export const DocName = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 135%;
  color: #181818;
  text-transform: none;
  display: flex;
  align-items: center;
  width: calc(100% - 15px);
  height: 100%;
  > svg {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
  &:hover {
    > div {
      margin-left: 10px;
      display: flex;
    }
  }
`;

export const NewFolderBlock = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding-left: 22px;
  box-sizing: border-box;
  margin-left: 0 !important;
  > svg {
    width: 20px;
    height: 20px;
    margin-right: 20px;
  }
`;

export const Input = styled.input`
  width: 360px;
  height: 32px;
  padding: 0 10px;
  background: #ffffff;
  border: 1px solid #efefef;
  box-sizing: border-box;
  border-radius: 2px;
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 135%;
  color: rgb(24, 24, 24);
  margin-right: 5px;
  outline: none;
  &:placeholder {
    color: rgba(24, 24, 24, 0.5);
  }
`;

export const Submit = styled.button`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme: { primary } }) => primary};
  border: 1px solid #efefef;
  box-sizing: border-box;
  border-radius: 2px;
  cursor: pointer;
  outline: none;
  &:disabled {
    background: #e3e3e3 !important;
    color: white !important;
    border: none;
    > svg {
      path {
        stroke: #fff;
      }
    }
  }
`;

export const Close = styled.button`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  box-sizing: border-box;
  border-radius: 2px;
  cursor: pointer;
  margin-left: ${({ ml = "auto" }) => ml};
  outline: none;
  border: none;
`;

export const MoreBlock = styled.div`
  width: 18px;
  height: 18px;
  position: relative;
  display: none;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  cursor: pointer;
  &:hover {
    > div {
      display: block;
    }
  }
`;

export const Hidden = styled.div`
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 18px;
  height: 18px;
`;

export const Triangle = styled.div`
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 20px 12.5px 0 12.5px;
  border-color: #ffffff transparent transparent transparent;
  position: absolute;
  right: -3px;
  top: -11px;
  z-index: 101;
`;

export const DocTooltip = styled.div`
  position: absolute;
  padding: 16px;
  background: #ffffff;
  border: 1px solid #efefef;
  box-sizing: border-box;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  width: 195px;
  right: -50px;
  bottom: 28px;
  display: flex;
  flex-direction: column;
  z-index: 100;
  &::after {
    content: "";
    display: block;
    width: 195px;
    position: absolute;
    height: 20px;
    background: transparent;
    left: -15px;
    bottom: -35px;
  }
`;

export const Tooltip = ({ children }) => (
  <MoreBlock>
    <More />
    <Hidden>
      <Triangle />
      <DocTooltip>{children}</DocTooltip>
    </Hidden>
  </MoreBlock>
);

const ActionName = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 135%;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: #181818;
  display: flex;
  align-items: center;
  margin-bottom: 14px;
  > svg {
    margin-right: 5px;
  }
  &:last-child {
    margin-bottom: 0;
  }
`;

export const Action = ({ name, icon, onClick }) => (
  <ActionName onClick={onClick}>
    {icon}
    {name}
  </ActionName>
);

export const BG = styled.section`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  z-index: 1000;
  background: ${({ theme: { blurBg } }) => blurBg};
  backdrop-filter: blur(2px);
`;

export const FormBlockEmpty = styled.div`
  width: 439px;
  height: 180px;
  background: #ffffff;
  border-radius: 4px 0px 4px 4px;
  margin: auto;
  display: flex;
`;

export const FormEmpty = styled.form`
  width: 399px;
  height: 140px;
  background: ${({ theme: { quoteBgInvestor } }) => quoteBgInvestor};
  border-radius: 2px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  box-sizing: border-box;
  cursor: pointer;
  > svg {
    width: 52px;
    height: 52px;
  }
`;

export const FormText = styled.h5`
  font-family: Muli;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 135%;
  color: #181818;
  margin: 0;
`;

export const FilesModal = styled.div`
  width: 675px;
  height: ${({ height = "518px" }) => height};
  margin: auto;
  background: #ffffff;
  border-radius: 4px 0px 4px 4px;
  display: flex;
  flex-direction: column;
`;

export const MGeader = styled.div`
  width: 675px;
  height: 62px;
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid #f9f9f9;
  box-sizing: border-box;
  box-shadow: 0px 10px 40px rgba(89, 120, 150, 0.08);
  border-radius: 4px;
  padding: 0 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  > svg {
    cursor: pointer;
    width: 26px;
    height: 26px;
  }
`;

export const HeadingM = styled.h3`
  font-family: Muli;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 135%;
  color: #181818;
  margin: 0;
  width: calc(100% - 36px);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const MWrapper = styled.div`
  width: 100%;
  height: calc(100% - 62px);
  padding: 30px 25px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`;

export const Form = styled.form`
  width: 625px;
  height: 72px;
  background: ${({ theme: { quoteBgInvestor } }) => quoteBgInvestor};
  border-radius: 2px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const FormHeading = styled.h3`
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 135%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: rgba(24, 24, 24, 0.6);
  margin-right: 10px;
`;

export const FilesWrapper = styled.div`
  width: 100%;
  height: ${({ height = "251px" }) => height};
  border-bottom: 1px solid #f6f7fc;
  margin-bottom: 20px;
  overflow: scroll;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const File = styled.div`
  width: 625px;
  height: 50px;
  margin-bottom: 10px;
  border: 1px solid #efefef;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 0 25px;
`;

export const FileName_ = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 135%;
  color: #181818;
  margin: 0 10px;
  max-width: 410px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const Size = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 135%;
  color: rgba(24, 24, 24, 0.6);
`;

export const IconsBlock = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 50px;
  > svg {
    cursor: pointer;
  }
`;

export const StatisticsWrapper = styled.section`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const CardStatistic = styled.div`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  background: #ffffff;
  border-radius: 4px;
  padding: ${({ p = "20px 25px" }) => p};
  margin-bottom: 25px;
  box-sizing: border-box;
  overflow: hidden;
`;

export const CardHeading = styled.h6`
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 135%;
  color: #181818;
  margin: 0 0 18px;
`;

export const ChartRow = styled.div`
  display: flex;
  width: ${({ w }) => w};
`;

export const ChartCol = styled.div`
  display: flex;
  margin: ${({ m }) => m};
  flex-direction: column;
  width: ${({ w }) => w};
`;

const UsersColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
`;

const ChartRow_ = styled.div`
  display: flex;
  align-items: center;
  font-family: Muli;
  font-weight: 600;
  font-size: 10px;
  line-height: 135%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #181818;
  margin-bottom: 15px;
  &:last-child {
    margin-bottom: 0;
  }
`;

const Cirlcle = styled.div`
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: ${({ color }) => color};
  margin-right: ${({ mRight }) => (mRight ? `${mRight}px` : "0")};
`;

const ChartPercent = styled.span`
  color: #181818;
  margin-left: 3px;
`;

export const ChartData = ({ data }) => {
  const folderNames = Object.keys(data);
  return (
    <ChartRow w="100%">
      <UsersColumn>
        {data[folderNames[0]] || data[folderNames[0]] === 0 ? (
          <ChartRow_>
            <Cirlcle mRight={7} color="#55B17C" />
            1.1. {folderNames[0]}
            <ChartPercent>({data[folderNames[0]]}%)</ChartPercent>
          </ChartRow_>
        ) : null}
        {data[folderNames[1]] || data[folderNames[1]] === 0 ? (
          <ChartRow_>
            <Cirlcle mRight={7} color="#3F4B78" />
            1.2. {folderNames[1]}
            <ChartPercent>({data[folderNames[1]]}%)</ChartPercent>
          </ChartRow_>
        ) : null}
      </UsersColumn>
      <UsersColumn>
        {data[folderNames[2]] || data[folderNames[2]] === 0 ? (
          <ChartRow_>
            <Cirlcle mRight={7} color={theme.primaryLight} />
            1.3. {folderNames[2]}
            <ChartPercent>({data[folderNames[2]]}%)</ChartPercent>
          </ChartRow_>
        ) : null}
        {data[folderNames[3]] || data[folderNames[3]] === 0 ? (
          <ChartRow_>
            <Cirlcle mRight={7} color={theme.primaryMiddle} />
            1.4. {folderNames[3]}
            <ChartPercent>({data[folderNames[3]]}%)</ChartPercent>
          </ChartRow_>
        ) : null}
      </UsersColumn>
    </ChartRow>
  );
};

export const ViewRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const Label = styled.h6`
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 135%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: rgba(24, 24, 24, 0.6);
  margin: 0 0 8px;
`;

export const Bold = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: ${({ fW = "bold" }) => fW};
  font-size: 14px;
  line-height: 135%;
  letter-spacing: 0.05em;
  text-transform: none;
  color: #181818;
  margin: ${({ m = 0 }) => m};
`;

export const BtnsRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  z-index: 4;
  position: relative;
`;

export const Btn = styled.button`
  font-family: Muli;
  position: relative;
  white-space: nowrap;
  padding-bottom: 0;
  padding-top: 0;
  width: ${({ width, fullWidth, _width }) => (width ? `${width}px` : fullWidth ? "100%" : _width ? _width : "98px")};
  border: 1px solid
    ${({ black, hide, error, green, theme: { primary, red } }) =>
      black ? "#20263C" : hide ? "#CACACA" : error ? red : green ? "#55B17C" : primary};
  height: ${({ height }) => (height ? `${height}px` : "32px")};
  background: ${({ black, white, hide, transparent, error, green, theme: { primary } }) =>
    black ? "#20263C" : white || hide ? "#fff" : transparent || error || green ? "transparent" : primary};
  color: ${({ white, hide, transparent, error, green, theme: { primary, red } }) =>
    white || transparent ? primary : hide ? "#CACACA" : error ? red : green ? "#55B17C" : "#fff"};
  font-size: 10px;
  line-height: 135%;
  text-align: center;
  font-weight: bold;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  border-radius: 4px;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: ${({ margin }) => (margin ? margin : "0")};
  transition: all 0.3s linear;
  text-decoration: none;
  &:disabled {
    background: #e3e3e3 !important;
    color: white !important;
    border: none;
    > svg {
      path {
        stroke: #fff;
      }
    }
  }
  &:focus {
    background: ${({ black, white, hide, transparent, error, green, theme: { primary } }) =>
      black ? "#20263C" : white || hide ? "#fff" : transparent || error || green ? "transparent" : primary};
  }
  > svg {
    margin: ${({ rightIcon }) => (rightIcon ? "0 0 0 6px " : "0 6px 0 0")};
    transform: ${({ transform }) => (transform ? "rotate(180deg)" : "none")};
    transition: all 0.3s linear;

    path {
      stroke: ${({ transparent, error, green, theme: { primary, red } }) =>
        transparent ? primary : error ? red : green ? "#55B17C" : "#fff"};
    }
  }
`;

const SmallAvatar = styled.img`
  width: 18px;
  height: 18px;
  border-radius: 50%;
  margin-right: 10px;
  object-fit: cover;
`;

const UserName = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 135%;
  color: #181818;
  text-transform: none;
`;

const UserContainer = styled.div`
  display: flex;
  align-items: center;
  > svg {
    width: 18px;
    height: 18px;
    margin-right: 10px;
  }
  > svg {
    filter: grayscale(${({ theme: { greyFilter } }) => greyFilter});
  }
`;

export const User = ({ user: { logo, name }, token }) => {
  const [avatar, setAvatar] = useState(null);

  useEffect(() => {
    if (!avatar && logo) {
      const { fileNameWithExtension, secureHash } = logo;
      DataRoomApi.getAttachment(token, secureHash, fileNameWithExtension).then((blob) =>
        setAvatar(blob || DataRoomApi.defaultAvatar)
      );
    }
  });

  return (
    <UserContainer>
      {avatar ? <SmallAvatar src={avatar} alt="avatar" /> : !logo ? <NoAvatar /> : null}
      <UserName>{name}</UserName>
    </UserContainer>
  );
};

export const UserLink = styled(Link)`
  width: 25px;
  height: 25px;
  background: ${({ theme: { primary } }) => primary};
  border-radius: 2px;
  display: flex;
  margin: auto;
  > svg {
    margin: auto;
    width: 14px;
    height: 14px;
  }
`;

export const CenterContainer = styled.div`
  margin: auto;
`;

export const SearchInputUsers = styled.input`
  width: calc(100% - 652px);
  height: 60px;
  background: #ffffff;
  border-radius: 4px 0px 4px 4px;
  outline: none;
  border: none;
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 135%;
  letter-spacing: -0.02em;
  color: #333333;
  padding: 20px 20px 20px 56px;
  box-sizing: border-box;
  &:placeholder {
    color: rgba(24, 24, 24, 0.4);
  }
`;

export const HeaderRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
  position: relative;
  > svg {
    position: absolute;
    top: 22px;
    left: 27px;
    width: 16px;
    height: 16px;
  }
`;

export const HeatFilter = styled.div`
  width: 632px;
  height: 60px;
  display: flex;
  justify-content: space-between;
  background: #ffffff;
  border-radius: 4px 0px 4px 4px;
`;

export const BorderFlex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-right: 1px solid #f1f3f8;
  padding: 0 20px;
  &:last-child {
    border-right: none;
  }
  > button {
    margin-right: 6px;
    &:last-child {
      margin-right: 0;
    }
  }
`;

export const HeatTableWrapper = styled.section`
  min-width: 100%;
  overflow-x: scroll;
  display: flex;
  flex-direction: column;
  overflow: visible;
`;

export const HeatRow = styled.div`
  height: 50px;
  background: #ffffff;
  border-radius: 4px;
  min-width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const HeatCeil = styled.div`
  width: 160px;
  padding: 0 25px;
  height: 100%;
  display: flex;
  align-items: center;
`;

export const CeilHeadingBlue = styled.h6`
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 135%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: ${({ theme: { primary } }) => primary};
  margin: 0 8px 0 0;
`;

const ProgressBg = styled.div`
  width: 160px;
  height: 24px;
  background: #f4f4f4;
  border-radius: 2px;
  display: flex;
  cursor: pointer;
  position: relative;
`;

const Progress = styled.div`
  width: ${({ width }) => `${width}%`};
  height: 24px;
  background: ${({ width = 0 }) =>
    width < 26 ? "#AAE5FF" : width < 51 ? "#69EBAC" : width < 76 ? "#EB9869" : "#FF4343"};
  border-radius: 2px;
`;

export const HeatProgress = ({ progress, user: { avgPerView, folderName, fullName, totalTime, userName, views } }) => {
  const [open, setOpen] = useState(false);

  const onChangeOpen = () => {
    setOpen(!open);
  };
  return (
    <ProgressBg onMouseEnter={onChangeOpen} onMouseLeave={onChangeOpen}>
      <Progress width={progress} />
      {open ? (
        <HeatTooltip>
          <Bold m="0 0 15px">
            {userName} to {folderName}
          </Bold>
          <Flex mb={0}>
            <div>
              <Label>Total time</Label>
              <Bold>{totalTime} min</Bold>
            </div>
            <div>
              <Label>VIEWS</Label>
              <Bold>{views}</Bold>
            </div>
            <div>
              <Label>avg. per view</Label>
              <Bold>{avgPerView}</Bold>
            </div>
          </Flex>
          <HeatTriangle />
        </HeatTooltip>
      ) : null}
    </ProgressBg>
  );
};

const HeatTooltip = styled.div`
  background: #ffffff;
  border: 1px solid #efefef;
  box-sizing: border-box;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  width: 337px;
  position: absolute;
  z-index: 36;
  bottom: 36px;
  right: 25px;
  padding: 16px 25px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const HeatTriangle = styled.div`
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 20px 12.5px 0 12.5px;
  border-color: #ffffff transparent transparent transparent;
  position: absolute;
  right: 31px;
  bottom: -14px;
  z-index: 101;
`;

export const UploadButton = styled.button`
  width: 236px;
  height: 24px;
  border: 1px solid ${({ theme: { primary } }) => primary};
  box-sizing: border-box;
  border-radius: 2px;
  margin-left: auto;
  z-index: 100;
  position: relative;
  background: transparent;
  cursor: pointer;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 150%;
  text-transform: uppercase;
  color: ${({ theme: { primary } }) => primary};
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  > svg {
    margin-right: 6px;
    path {
      stroke: ${({ theme: { primary } }) => primary};
    }
  }
`;

export const RulesButton = styled.button`
  width: 113px;
  height: 24px;
  background: ${({ automated, theme: { primaryMiddle } }) => (automated ? primaryMiddle : "transparent")};
  border-radius: 2px;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 10px;
  line-height: 150%;
  text-transform: uppercase;
  outline: none;
  border: 1px solid ${({ automated, theme: { primaryMiddle } }) => (automated ? primaryMiddle : "#dfdfdf")};
  box-sizing: border-box;
  color: ${({ automated }) => (automated ? "#ffffff" : "#cacaca")};
  margin: 0 10px 0 auto;
  z-index: 100;
  position: relative;
  cursor: pointer;
  > svg {
    margin-right: 6px;
    path {
      stroke: ${({ automated }) => (automated ? "#ffffff" : "#cacaca")};
    }
  }
  &:disabled {
    background: #e3e3e3 !important;
    color: white !important;
    border: none;
    > svg {
      path {
        stroke: #fff;
      }
    }
  }
`;

export const ReplaceButton = styled.button`
  margin-left: ${({ ml = 0 }) => ml};
  width: 113px;
  height: 24px;
  border-radius: 2px;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 10px;
  line-height: 150%;
  text-transform: uppercase;
  outline: none;
  border: 1px solid #dfdfdf;
  color: #cacaca;
  z-index: 100;
  position: relative;
  cursor: pointer;
  background: transparent;
  > svg {
    margin-right: 6px;
  }
  &:disabled {
    background: #e3e3e3 !important;
    color: white !important;
    border: none;
    > svg {
      path {
        stroke: #fff;
      }
    }
  }
`;

export const StarBlock = styled.div`
  width: 20px;
  height: 20px;
  position: absolute;
  right: 10px;
  top: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  > div {
    display: none;
  }
  > svg {
    width: 20px;
    height: 20px;
    path {
      stroke: ${({ theme: { primary } }) => primary};
      fill: ${({ favorite, theme: { primary } }) => (favorite ? primary : "transparent")};
    }
  }
  &:hover {
    > div {
      display: flex;
    }
  }
`;

const BorderPaper = styled.div`
  background: #ffffff;
  border: 1px solid #efefef;
  box-sizing: border-box;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  position: absolute;
  top: -55px;
  padding: 14px;
  width: fit-content;
  font-size: 14px;
  line-height: 135%;
  color: #181818;
  text-transform: none;
  font-style: normal;
  font-weight: normal;
  white-space: nowrap;
  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 30px;
    height: 17px;
    background: #ffffff;
    z-index: 2;
    bottom: 0;
    left: calc(50% - 15px);
  }
`;

const TrianglePaper = styled.div`
  position: absolute;
  background: #ffffff;
  border: 1px solid #efefef;
  box-sizing: border-box;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  width: 20px;
  height: 20px;
  transform: rotate(45deg);
  left: calc(50% - 10px);
  bottom: -8px;
  z-index: 1;
`;

export const FavTooltip = ({ text }) => {
  return (
    <BorderPaper>
      <TrianglePaper /> {text}
    </BorderPaper>
  );
};

export const SeparateLine = styled.div`
  width: 100%;
  height: 1px;
  background: #ececec;
  margin: 5px 0;
`;
