import React, { useEffect, useState, Fragment } from "react";

import { Card, CardHead, Empty, Text, RightBlock, KeysWrapper, Key, Row, Remove, Expand } from "./styled";
import { Btn } from "components/admin-layout/buttons";
import { icons } from "models/icons";
import { ModalPortal } from "components/admin-layout/errorMessage";
import { TextFieldComponent } from "components/admin-layout/forms";
import { ManagerInfo } from "components/investor-layout/pages/Offer/styled";

export const KeyMetrics = ({ defaultValue = [], onChange, height, onExpand, close }) => {
  const [edit, setEdit] = useState(false);

  const onChangeEdit = () => setEdit(!edit);

  const renderKeys = () =>
    defaultValue.map(({ header, text }) => (
      <ManagerInfo total={defaultValue.length} key={header} heading={header} data={text} />
    ));

  return (
    <Card height={height} close={close}>
      <CardHead>Key Metrics (Optional)</CardHead>
      <RightBlock>
        <Btn width={150} margin="0 10px 0" transparent disabled={!defaultValue.length} onClick={onChangeEdit}>
          {icons.edit}EDIT KEY METRICS
        </Btn>
        <Expand close={close} onClick={onExpand}>
          {icons.arrow}
        </Expand>
      </RightBlock>
      {!defaultValue.length ? (
        <Empty>
          <div>
            <CardHead m="0 0 10px">There are no key metrics yet</CardHead>
            <Text>You can add key metrics with button «Add Key Metric»</Text>
          </div>
          <div>
            <Btn width={180} onClick={onChangeEdit}>
              {icons.plusCircle} ADD KEY METRIC
            </Btn>
          </div>
        </Empty>
      ) : (
        <Row>{renderKeys()}</Row>
      )}
      {edit ? <EditMetrics defaultValue={defaultValue} onClose={onChangeEdit} onSave={onChange} /> : null}
    </Card>
  );
};

const EditMetrics = ({ defaultValue, onClose, onSave }) => {
  const generateKey = () => `key-${Math.round(Math.random() * 10000)}`;
  const [keys, setKeys] = useState(defaultValue.map((key) => ({ ...key, id: generateKey() })));

  const onAddKey = () => {
    const _keys = [...keys];
    _keys.push({
      header: "",
      text: "",
      id: generateKey(),
    });
    setKeys(_keys);
  };

  const onRemoveKey = (index) => () => {
    const _keys = [...keys];
    _keys.splice(index, 1);
    setKeys(_keys);
  };

  const onChangeKey = (index, field) => (e) => {
    const _keys = [...keys];
    _keys[index][field] = e.target.value;
    setKeys(_keys);
  };

  const renderKeys = () =>
    keys.map(({ header, text, id }, i) => {
      const _id = id || generateKey();
      const currentNumber = i + 1;
      return (
        <Key key={_id}>
          <Row>
            <CardHead fWeight={600} m="0">
              Key Metric #{currentNumber}
            </CardHead>
            <Remove onClick={onRemoveKey(i)}>{icons.crossRed} REMOVE</Remove>
          </Row>
          <Row>
            <TextFieldComponent
              height={35}
              width="calc(100% - 200px)"
              label="Tittle"
              placeholder={`Tittle of key metric #${currentNumber}`}
              defaultValue={header}
              onChange={onChangeKey(i, "header")}
            />
            <TextFieldComponent
              height={35}
              width="190px"
              label="value"
              placeholder={`Value of key metric #${currentNumber}`}
              defaultValue={text}
              onChange={onChangeKey(i, "text")}
            />
          </Row>
        </Key>
      );
    });

  const maxLength = keys.length === 4;

  const onSubmit = () => {
    const _keys = [...keys];
    _keys.forEach((el) => {
      delete el.id;
    });
    onSave(_keys);
    onClose();
  };

  useEffect(() => console.log(keys), [keys]);

  return (
    <ModalPortal onClose={onClose} heading="Edit Key Metrics" submitText="ADD" width="565px" onSubmit={onSubmit}>
      <KeysWrapper>
        {renderKeys()}
        <Key>
          <Btn width={180} margin="auto" onClick={onAddKey} disabled={maxLength}>
            {icons.plusCircle} ADD KEY METRIC {maxLength ? "" : `#${keys.length + 1}`}
          </Btn>
        </Key>
      </KeysWrapper>
    </ModalPortal>
  );
};
