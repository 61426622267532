import styled from "styled-components";
import { Link as _Link } from "react-router-dom";
import { Btn } from "../../buttons";

export const Link = styled(_Link)`
  text-decoration: none;
`;

export const Main = styled.section`
  width: 100%;
  box-sizing: border-box;
  background: white;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 0;
`;

export const Header = styled.div`
  width: 100%;
  height: 60px;
  border-radius: 4px 0px 4px 4px;
  background: white;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0 25px;
  margin-bottom: 30px;
`;

export const CardContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 40px 0 47px 0;
`;

export const CardWrapper = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 260px;
  height: 360px;
  padding: 10px;
  margin: 0 15px;
  border: 1px solid ${({ pick = false, theme: { primary } }) => (pick ? primary : "#EFEFEF")};
  border-radius: 4px;
  cursor: pointer;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  transition-property: border-color;
  transition-duration: 0.2s;
`;

export const TextH3 = styled.h3`
  text-align: center;
  font-family: "Muli", sans-serif;
  font-size: ${({ size }) => (size ? `${size}px` : "20px")};
  font-weight: bold;
  ${({ marginSize = "" }) => (marginSize ? `margin: ${marginSize};` : "")}
`;

export const TextSub = styled.p`
  text-align: center;
  font-family: "Muli", sans-serif;
  font-size: 14px;
  ${({ marginSize = "" }) => (marginSize ? `margin: ${marginSize};` : "")}
`;

export const Dot = styled.div`
  position: absolute;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  border: 1px solid ${({ pick = false, theme: { primary } }) => (pick ? primary : "#EFEFEF")};
  background: white;
  bottom: -12px;

  display: flex;
  align-items: center;
  justify-content: center;

  transition-property: border-color;
  transition-duration: 0.2s;

  &::after {
    content: "";
    border-radius: 50%;
    height: 16px;
    width: 16px;
    background: ${({ pick = false, theme: { primary } }) => (pick ? primary : "white")};
    transition-property: background;
    transition-duration: 0.2s;
  }
`;

export const IconBg = styled.div`
  width: 240px;
  height: 210px;
  background: ${({ theme: { quoteBgInvestor } }) => quoteBgInvestor};
  border-radius: 4px;
  display: flex;
  > svg {
    margin: auto;
    path {
      stroke: ${({ theme: { primary } }) => primary};
      fill: ${({ theme: { primary } }) => primary};
    }
  }
`;
