import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import { CircularProgress } from "@material-ui/core";

import Api from "../../../../helpers/api";
import { AddUserModal } from "./AddUserModal";
import { icons } from "../../../../models/icons";
import {
  Header,
  TextH3,
  Wrapper,
  Column,
  Main,
  TextSub,
  InputRow,
  Head,
  UserCard,
  UserWrapper,
  IconsBlock,
  WaitingWrapper
} from "./styled";
import {
  TextFieldComponent,
  SelectComponent,
  PhoneComponent
} from "../../forms";
import { Btn } from "../../buttons";
import { ErrorMessage } from "../../errorMessage";
import { useDebounceState } from "helpers/customHook";

const CreateInstitution = ({
  token,
  history,
  countriesOption,
  match: {
    params: { companyName, country, firstName, lastName, email, phone }
  }
}) => {
  const [data, setData] = useDebounceState({
    name: companyName ? companyName : "",
    countryRegistration: country ? country : "",
    phone: phone ? phone : "",
    webSiteUrl: "",
    initialUsers:
      firstName && lastName && email
        ? [
            {
              firstName,
              lastName,
              email
            }
          ]
        : []
  });
  const [error, setError] = useState(null);
  const [wait, setWait] = useState(false);
  const [editUser, setEditUser] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  const onChangeModal = (user = null) => () => {
    setEditUser(user);
    setModalOpen(!modalOpen);
  };

  const onChangeSimple = (e, key) => {
    setData({ ...data, [key]: e.target ? e.target.value : e });
  };

  const onAddUser = user => {
    const _data = { ...data };
    _data.initialUsers.push(user);
    setData(_data);
  };

  const onEditUser = user => {
    const users = [...data.initialUsers];
    const index = users.findIndex(el => el.email === editUser.email);
    users.splice(index, 1, user);
    setData({ ...data, initialUsers: users });
  };

  const onRemoveUser = index => () => {
    const _data = { ...data };
    _data.initialUsers.splice(index, 1);
    setData(_data);
  };

  const onError = (e = null) => {
    if (wait) setWait(false);
    setError(e);
  };

  const onSubmit = async () => {
    setWait(true);
    let _data;
    if (window.location.hash.replace("#", "").length) {
      _data = {
        ...data,
        ...JSON.parse(decodeURI(window.location.hash).replace("#", ""))
      };
    } else {
      _data = { ...data };
    }
    _data.initialUsers.forEach(
      u => (u.countryOfResidence = _data.countryRegistration)
    );
    const res = await Api.registerInstitution(token, _data).catch(onError);
    if (res) {
      goBack();
    }
  };

  const fromApprove = companyName && country && firstName && lastName && email;

  const goBack = () => {
    if (fromApprove) {
      history.push("/admin/users/new-requests");
    } else history.push("/admin/users");
  };

  const isDisabled = () => {
    return Object.keys(data).some(
      e => (!data[e] || !data[e].length) && e !== "webSiteUrl" && e !== "phone"
    );
  };

  const renderUsers = () => {
    return data.initialUsers.map((user, i) => {
      return (
        <UserCard key={`unique-${user.email}-${i}`}>
          {icons.userSmall}
          <span>{user.firstName + " " + user.lastName}</span>
          <IconsBlock>
            <div onClick={onChangeModal(user)}>{icons.edit}</div>
            <div onClick={onRemoveUser(i)}>{icons.remove}</div>
          </IconsBlock>
        </UserCard>
      );
    });
  };

  return (
    <Fragment>
      {wait && (
        <WaitingWrapper>
          <CircularProgress />
        </WaitingWrapper>
      )}
      <Header>
        <Btn transparent onClick={goBack} width={150}>
          {icons.left}
          back to users
        </Btn>
        <TextH3 marginSize="0 0 0 25px">Add New Institution</TextH3>
      </Header>
      <Wrapper>
        <Column width="calc(72% - 30px)">
          <Main>
            <TextSub
              textAlign="left"
              fontWeight="bold"
              color="#181818"
              marginSize="0 0 25px 0"
            >
              Main Information
            </TextSub>
            <InputRow last>
              <TextFieldComponent
                label="Company name"
                placeholder="Company name"
                defaultValue={data.name}
                onChange={e => onChangeSimple(e, "name")}
              />
            </InputRow>
          </Main>
          <Main noHidden last padding="15px 25px 30px 25px">
            <Head>
              <TextSub
                textAlign="left"
                fontWeight="bold"
                color="#181818"
                marginSize="0 0 25px 0"
              >
                Representative(s)
              </TextSub>
              <Btn width={195} onClick={onChangeModal()}>
                {icons.plusCircle} add new
              </Btn>
            </Head>
            <UserWrapper>{renderUsers()}</UserWrapper>
          </Main>
        </Column>
        <Column width="28%">
          <Main noHidden>
            <TextSub
              textAlign="left"
              fontWeight="bold"
              color="#181818"
              marginSize="0 0 25px 0"
            >
              Contact Information
            </TextSub>
            <InputRow>
              <SelectComponent
                view="filter"
                values={countriesOption}
                value={
                  data.countryRegistration ? data.countryRegistration : null
                }
                onChange={e => onChangeSimple(e, "countryRegistration")}
                placeholder="Company location"
                label="Company location"
              />
            </InputRow>
            <InputRow>
              <PhoneComponent
                label="Phone"
                placeholder="Company phone"
                defaultValue={data.phone}
                onChange={num => onChangeSimple(num, "phone")}
              />
            </InputRow>
            <InputRow last>
              <TextFieldComponent
                label="Web-site"
                placeholder="Web-site"
                defaultValue={data.webSiteUrl}
                onChange={e => onChangeSimple(e, "webSiteUrl")}
              />
            </InputRow>
          </Main>
        </Column>
      </Wrapper>
      <Header isFooter between>
        <Btn transparent onClick={goBack} width={180}>
          cancel
        </Btn>
        <Btn width={180} disabled={isDisabled() || wait} onClick={onSubmit}>
          add institution
        </Btn>
      </Header>
      {modalOpen ? (
        <AddUserModal
          isEdit={editUser}
          _user={editUser}
          onEditUser={onEditUser}
          users={data.initialUsers}
          onAddUser={onAddUser}
          onBackdropClick={onChangeModal()}
        />
      ) : null}
      {error ? <ErrorMessage er={error} onClose={() => onError(null)} /> : null}
    </Fragment>
  );
};

export default connect(
  ({ userReducer: { token }, asyncDataReducer: { countriesOption } }) => ({
    token,
    countriesOption
  }),
  null
)(CreateInstitution);
