import React, { useState, useRef, useEffect, Fragment } from "react";

import { Separator, Main, TextSub, AnswerWrap, InfoCol } from "./styled";
import { Btn } from "../../buttons";
import { ReactComponent as Down } from "../../../../assets/chevron-down.svg";
import { FileHolder } from "../UserInfo/styled";

// export const DynamicSection = ({ section: { sectionName, fields } }) => {
//   const [openMore, setOpenMore] = useState(true);
//   const [height, setHeight] = useState(0);
//   const showRef = useRef(null);

//   const onShowMore = () => {
//     setOpenMore(!openMore);
//   };

//   useEffect(() => {
//     if (showRef && showRef.current) {
//       setHeight(showRef.current.offsetHeight);
//       setOpenMore(false);
//     }
//   }, []);

//   return (
//     <Fragment>
//       <Separator />
//       <Main key={`key-${sectionName}`}>
//         <TextSub
//           textAlign="left"
//           fontWeight="bold"
//           color="#181818"
//           marginSize="0 0 20px 0"
//         >
//           {sectionName}
//         </TextSub>
//         <AnswerWrap
//           ref={showRef}
//           height={
//             openMore && !height
//               ? "fit-content"
//               : openMore && height
//               ? `${height}px`
//               : "159px"
//           }
//         >
//           {fields.map(({ name, value }, i) => {
//             if (value) {
//               return (
//                 <InfoCol
//                   key={`key-${name}-${i}`}
//                   heading={name}
//                   info={Array.isArray(value) ? value.join(", ") : value}
//                 />
//               );
//             } else return null;
//           })}
//         </AnswerWrap>
//         <Btn
//           transparent
//           width={273}
//           onClick={onShowMore}
//           grey={openMore}
//           rightIcon
//           transform={openMore}
//         >
//           {openMore ? "hide" : "show more"}
//           <Down />
//         </Btn>
//       </Main>
//     </Fragment>
//   );
// };

export const DynamicSection = ({ token, section, SC, CC }) => {
  const [openMore, setOpenMore] = useState(true);
  const [height, setHeight] = useState(0);
  const showRef = useRef(null);

  const onShowMore = () => {
    setOpenMore(!openMore);
  };

  useEffect(() => {
    if (showRef && showRef.current) {
      setHeight(showRef.current.offsetHeight);
      setOpenMore(false);
    }
  }, []);

  const getTotalFiles = (keys, obj) => {
    let total = 0;
    keys.forEach(key => {
      if (obj[key]) {
        total = total + obj[key].length;
      }
    });
    return total;
  };

  if (SC && Object.keys(SC).length) {
    const keys = ["usBornProve"];
    return (
      <Fragment>
        <Separator />
        <Main key={`key-self-certification`}>
          <TextSub
            textAlign="left"
            fontWeight="bold"
            color="#181818"
            marginSize="0 0 20px 0"
          >
            Self Certification Attachments
          </TextSub>
          <AnswerWrap
            ref={showRef}
            height={
              getTotalFiles(keys, SC) > 3
                ? openMore && !height
                  ? "fit-content"
                  : openMore && height
                  ? `${height}px`
                  : "159px"
                : "fit-content"
            }
            mBottom={getTotalFiles(keys, SC) > 3 ? "" : "0px"}
            mb="18px"
          >
            {keys.map(key => {
              if (SC[key] && SC[key].length) {
                return SC[key].map((file, i) => {
                  return (
                    <FileHolder
                      key={`key-${file.fileName}-${i}`}
                      file={file}
                      token={token}
                    />
                  );
                });
              }
            })}
          </AnswerWrap>
          {getTotalFiles(keys, SC) > 3 ? (
            <Btn
              transparent
              width={273}
              onClick={onShowMore}
              grey={openMore}
              rightIcon
              transform={openMore}
            >
              {openMore ? "hide" : "show more"}
              <Down />
            </Btn>
          ) : null}
        </Main>
      </Fragment>
    );
  } else if (CC && Object.keys(CC).length) {
    const keys = [
      //professional
      "aGovernmentFiles",
      "anEntityFiles",
      "anotherInstitutionalInvestorFiles",
      "balanceOf20millionFiles",
      "netTurnoverOfEUR40MillionFiles",
      "ownFundsOfEUR2Million",
      //elective
      "relevantActivityStatementFiles",
      "financialStatementFiles",
      "cvFiles"
    ];
    return (
      <Fragment>
        <Separator />
        <Main key={`key-client-certification`}>
          <TextSub
            textAlign="left"
            fontWeight="bold"
            color="#181818"
            marginSize="0 0 20px 0"
          >
            Client Certification Attachments
          </TextSub>
          <AnswerWrap
            ref={showRef}
            height={
              getTotalFiles(keys, CC) > 3
                ? openMore && !height
                  ? "fit-content"
                  : openMore && height
                  ? `${height}px`
                  : "159px"
                : "fit-content"
            }
            mBottom={getTotalFiles(keys, CC) > 3 ? "" : "0px"}
            mb="18px"
          >
            {keys.map(key => {
              if (CC[key] && CC[key].length) {
                return CC[key].map((file, i) => {
                  return (
                    <FileHolder
                      key={`key-${file.fileName}-${i}`}
                      file={file}
                      token={token}
                    />
                  );
                });
              }
            })}
          </AnswerWrap>
          {getTotalFiles(keys, CC) > 3 ? (
            <Btn
              transparent
              width={273}
              onClick={onShowMore}
              grey={openMore}
              rightIcon
              transform={openMore}
            >
              {openMore ? "hide" : "show more"}
              <Down />
            </Btn>
          ) : null}
        </Main>
      </Fragment>
    );
  } else if (Object.keys(section).length) {
    const { sectionName, fields } = section;
    return (
      <Fragment>
        <Separator />
        <Main key={`key-${sectionName}`}>
          <TextSub
            textAlign="left"
            fontWeight="bold"
            color="#181818"
            marginSize="0 0 20px 0"
          >
            {sectionName}
          </TextSub>
          <AnswerWrap
            ref={showRef}
            height={
              openMore && !height
                ? "fit-content"
                : openMore && height
                ? `${height}px`
                : "159px"
            }
          >
            {fields.map(({ name, value }, i) => {
              if (value) {
                return (
                  <InfoCol
                    key={`key-${name}-${i}`}
                    heading={name}
                    info={Array.isArray(value) ? value.join(", ") : value}
                  />
                );
              } else return null;
            })}
          </AnswerWrap>
          <Btn
            transparent
            width={273}
            onClick={onShowMore}
            grey={openMore}
            rightIcon
            transform={openMore}
          >
            {openMore ? "hide" : "show more"}
            <Down />
          </Btn>
        </Main>
      </Fragment>
    );
  } else return null;
};
