import styled from "styled-components";
import { Link } from "react-router-dom";

export const HeaderRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
  position: relative;
  > svg {
    position: absolute;
    top: 22px;
    right: 400px;
    width: 16px;
    height: 16px;
    @media screen and (max-width: 1280px) {
      right: calc(50% - 53.5px);
    }
  }
`;

export const FilterBlock = styled.div`
  width: calc(100% - 465px);
  height: 60px;
  background: #ffffff;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 25px;
  box-sizing: border-box;
  @media screen and (max-width: 1280px) {
    width: calc(50% - 12.5px);
  }
`;

export const HeaderHeading = styled.h3`
  font-family: Muli;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 135%;
  margin: 0;
  color: #181818;
  > span {
    font-family: Muli;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 135%;
    color: rgba(24, 24, 24, 0.6);
    margin-left: 10px;
  }
`;

export const Flex = styled.div`
  width: ${({ width = "100%" }) => width};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const RouteLink = styled(Link)`
  box-sizing: border-box;
  position: relative;
  white-space: nowrap;
  padding-bottom: 0;
  padding-top: 0;
  width: ${({ width, fullWidth, _width }) =>
    width ? `${width}px` : fullWidth ? "100%" : _width ? _width : "94px"};
  border: 1px solid
    ${({ blue, grey, theme: { primary, existedLabel } }) =>
      blue ? primary : grey ? existedLabel : primary};
  height: ${({ height }) => (height ? `${height}px` : "32px")};
  background: transparent;
  color: ${({ blue, grey, theme: { primary, existedLabel } }) =>
    blue ? primary : grey ? existedLabel : primary};
  font-size: 10px;
  line-height: 135%;
  text-align: center;
  font-weight: bold;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  border-radius: 4px;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: ${({ margin }) => (margin ? margin : "0")};
  transition: all 0.3s linear;
  text-decoration: none;
  &::before {
    transition: all 0.3s linear;
    content: "";
    position: absolute;
    display: none;
    bottom: -1px;
    left: -1px;
    height: 3px;
    background: ${({ blue, grey, theme: { primary, existedLabel } }) =>
      blue ? primary : grey ? existedLabel : primary};
    width: calc(100% + 2px);
    filter: brightness(0.85);
    border-radius: 0 0 4px 4px;
  }
  &:hover {
    &::before {
      display: block;
    }
  }
`;

export const DealsWrapper = styled.section`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const SearchInput = styled.input`
  width: 440px;
  height: 60px;
  background: #ffffff;
  border-radius: 4px;
  outline: none;
  border: none;
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 135%;
  letter-spacing: -0.02em;
  color: #333333;
  padding: 20px 20px 20px 56px;
  box-sizing: border-box;
  &:placeholder {
    color: rgba(24, 24, 24, 0.4);
  }
  @media screen and (max-width: 1280px) {
    width: calc(50% - 12.5px);
  }
`;

export const Card = styled.div`
  width: calc(50% - 12.5px);
  height: 240px;
  background: #ffffff;
  border: 1px solid;
  border: 1px solid #f0f0f0;
  box-sizing: border-box;
  border-radius: 4px;
  margin-bottom: 25px;
  overflow: hidden;
  display: flex;
  ${({ column = false }) =>
    column ? "flex-direction:column; height: fit-content;" : ""};
`;

export const ImageBlock = styled.div`
  min-width: 225px;
  width: ${({ width, fullW }) =>
    width ? `${width}px` : fullW ? "100%" : "45%"};
  height: ${({ height }) => (height ? `${height}px` : "100%")};
  border-right: 1px solid #f0f0f0;
  filter: ${({ isDraft }) => (isDraft ? "grayscale(1)" : "none")};
  position: relative;
  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const Label = styled.div`
  position: absolute;
  width: 60px;
  height: 22px;
  right: 20px;
  top: 20px;
  background: #bdbdbd;
  border-radius: 4px;
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 135%;
  text-align: center;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
`;

export const InfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 25px;
  box-sizing: border-box;
  width: ${({ width = "55%" }) => width};
  height: 100%;
`;

export const DealName = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 135%;
  letter-spacing: -0.02em;
  color: #192036;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const CardHeader = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin: 0 0 16px;
`;

export const Count = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 22px;
  min-width: 22px;
  border-radius: 4px;
  background: ${({ count, theme: { primary } }) =>
    count ? primary : "#BDBDBD"};
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  text-align: center;
  color: #ffffff;
  padding: 0 8px;
  box-sizing: border-box;
`;

export const DataCol = styled.div`
  display: flex;
  flex-direction: column;
  ${({ vertical = false }) => (vertical ? "margin-bottom:16px;" : "")};
`;

export const DataRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 7px;
  &:last-child {
    margin-bottom: 0;
  }
  > svg {
    width: 9px;
    height: 9px;
    margin-right: 6px;
    path {
      stroke: ${({ theme: { primary } }) => primary};
    }
  }
`;

export const DataName = styled.p`
  margin: 0;
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  color: rgba(51, 51, 51, 0.8);
  > span {
    font-family: Muli;
    color: #192036;
    font-weight: bold;
  }
`;

export const ButtonsRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: auto;
`;

export const ActionButton = styled.button`
  width: calc(50% - 7px);
  border: 1px solid ${({ theme: { primary } }) => primary};
  box-sizing: border-box;
  border-radius: 4px;
  height: 32px;
  background: transparent;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: ${({ theme: { primary } }) => primary};
  font-size: 10px;
  line-height: 135%;
  text-align: center;
  font-weight: bold;
  letter-spacing: 0.06em;
  &:disabled {
    background: #e3e3e3 !important;
    color: white !important;
    border: none;
  }
`;

export const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(5px);
  z-index: 998;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MainPage = styled.section`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  padding: 0 30px;
  max-width: 1440px;
  box-sizing: border-box;
`;

export const StickName = styled.div`
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  padding: 0 8px;
  height: 24px;
  align-items: center;
  border-radius: 2px;
  margin-bottom: 10px;
  margin-right: auto;
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: #ffffff;
  background: ${({ active, theme: { primary, existedLabel } }) =>
    active ? primary : existedLabel};
  ${({ vertical = false }) =>
    vertical ? `position: absolute; top: 15px; left: 15px;` : ""}
`;
