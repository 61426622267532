import React, { useEffect, useState, Fragment } from "react";
import { connect } from "react-redux";

import { Wrapper, Col, Card, Separator, DocCard, SELECT_SIZE, EmptyCard } from "./styled";
import { SelectComponent } from "../../../admin-layout/forms";
import { LoaderWrapper } from "../../styled/Loader";
import { getDocuments } from "../../../../modules/dataRedux/dataAction";
import { ErrorMessage } from "../../../admin-layout/errorMessage";
import { isNoDocuments, filterAssets, isDealEmpty } from "./helpers";
import { EmptyCard as NoInfo } from "components/common/emptyInfo";

const Documents = ({ token, documents, getDocuments }) => {
  const [typeFilter, setType] = useState("All documents");
  const [asset, setAsset] = useState("All assets");
  const [error, setError] = useState(false);

  useEffect(() => {
    getDocuments(token, setError);
  }, []);

  const renderCards = () => {
    const arr = asset === "All assets" ? Object.keys(documents) : [asset];
    return arr.map((e) => {
      if (isDealEmpty(documents[e]) && asset !== "All assets") {
        return <EmptyCard small>No documents for this deal.</EmptyCard>;
      } else if (
        (isDealEmpty(documents[e]) && asset === "All assets") ||
        (asset === "All assets" && typeFilter !== "All documents" && !documents[e][typeFilter].length)
      ) {
        return null;
      } else {
        return (
          <DocCard
            onUpdate={() => getDocuments(token, setError)}
            heading={e}
            key={e}
            type={typeFilter}
            documents={documents[e]}
            token={token}
          />
        );
      }
    });
  };

  if (documents && !isNoDocuments(documents)) {
    const assets = filterAssets(documents);
    return (
      <Fragment>
        <Wrapper>
          <Col responsive={`@media (max-width: 1280px) {width: 280px;}`}>
            <Card>
              <SelectComponent
                {...SELECT_SIZE}
                label="Type of Document"
                values={["All documents", ...Object.keys(documents[assets[0]])]}
                value={typeFilter}
                onChange={(e) => setType(e.target.value)}
              />
              <Separator />
              <SelectComponent
                {...SELECT_SIZE}
                label="Asset"
                values={["All assets", ...assets]}
                value={asset}
                onChange={(e) => setAsset(e.target.value)}
              />
            </Card>
          </Col>
          <Col width="calc(100% - 354px)" responsive={`@media (max-width: 1280px) {width:calc(100% - 310px);}`}>
            {renderCards()}
          </Col>
        </Wrapper>
        {error && <ErrorMessage er={error} onClose={() => setError(null)} />}
      </Fragment>
    );
  } else if (documents && isNoDocuments(documents)) {
    return (
      <EmptyCard>
        <NoInfo heading="No Documents signed yet" />
      </EmptyCard>
    );
  } else {
    return <LoaderWrapper />;
  }
};

export default connect(
  ({ userReducer: { token }, dataReducer: { documents } }) => ({
    token,
    documents,
  }),
  { getDocuments }
)(Documents);
