import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { CircularProgress } from "@material-ui/core";

import {
  HeaderRow,
  SearchInput,
  FilterBlock,
  HeaderHeading,
  Flex,
  RouteLink,
  DealsWrapper,
  CenterContainer,
} from "./styled";
import { ReactComponent as SearchIcon } from "../../../../../assets/search-icon.svg";
import { DealCard } from "./deal";
import { ErrorMessage } from "../DealDocs/errorMessage";
import { getAdminFoldersView } from "../../../../../modules/dataRoomRedux/dataRoomActions";

const Main = ({
  token,
  match: {
    params: { type },
  },
  getAdminFoldersView,
  view,
}) => {
  const [search, setSearch] = useState("");
  const [errorObj, setError] = useState(null);

  useEffect(() => {
    getAdminFoldersView(token, setError);
  }, []);

  const onSearchInput = (e) => {
    const {
      target: { value },
    } = e;
    setSearch(value);
  };

  const updateDeals = () => {
    getAdminFoldersView(token, setError);
  };

  const renderDeals = () => {
    return view
      .filter((e) => {
        if (type === "all") {
          return true;
        }
        if (type === "active") {
          return e.active;
        }
        if (type === "draft") {
          return !e.active;
        }
      })
      .filter(({ assetName }) =>
        assetName.toLowerCase().includes(search.toLowerCase())
      )
      .sort((a, b) => (a.assetName > b.assetName ? 1 : -1))
      .map((e) => (
        <DealCard
          deal={e}
          token={token}
          key={e.assetId}
          onUpdateDealStatus={updateDeals}
        />
      ));
  };

  if (!view && !errorObj) {
    return (
      <CenterContainer>
        <CircularProgress />
      </CenterContainer>
    );
  } else if (view)
    return (
      <Fragment>
        <HeaderRow>
          <FilterBlock>
            <HeaderHeading>{`You have ${
              view.length ? view.length : "no"
            } assets now`}</HeaderHeading>
            <Flex width="324px">
              <RouteLink
                width={100}
                to="/admin/data-room/all"
                transparent={type !== "all"}
              >
                All
              </RouteLink>
              <RouteLink
                width={100}
                to="/admin/data-room/active"
                transparent={type !== "active"}
              >
                Active
              </RouteLink>
              <RouteLink
                width={100}
                to="/admin/data-room/draft"
                transparent={type !== "draft"}
              >
                Draft
              </RouteLink>
            </Flex>
          </FilterBlock>
          <SearchInput
            placeholder="Search by asset name"
            value={search}
            onChange={onSearchInput}
          />
          <SearchIcon />
        </HeaderRow>

        <DealsWrapper>{renderDeals()}</DealsWrapper>
      </Fragment>
    );
  else if (errorObj) {
    return <ErrorMessage er={errorObj} onClose={() => setError(null)} />;
  } else return null;
};

export default connect(
  ({
    userReducer: { token },
    dataRoomReducer: { foldersViewAdmin: view },
  }) => ({
    token,
    view,
  }),
  { getAdminFoldersView }
)(Main);
