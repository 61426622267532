import styled from "styled-components";

const SidebarMain = styled.div`
  background: white;
  height: 100vh;
  position: fixed;
  box-shadow: 0px 5px 7px 0px rgba(0, 0, 0, 0.02);
  transition: all 0.4s cubic-bezier(0.4, 0, 0.95, 0.4);
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  z-index: 2;
  box-sizing: border-box;
  /* *:hover {
    transform: translateX(0);
  } */
`;

export const SidebarFull = styled(SidebarMain)`
  width: 233px;
  opacity: 1;
  background: ${({ theme: { headerInvestor } }) => headerInvestor};
`;

export const SidebarLow = styled(SidebarMain)`
  width: 80px;
`;

export const Logo = styled.img`
  transition: opacity 0.4s cubic-bezier(0.4, 0, 0.95, 0.4);
  opacity: 1;
  width: ${({ w = "180px" }) => w};
  height: ${({ h = "30px" }) => h};
  margin: 15px 0;
  display: block;
  object-fit: contain;
  ${({ styles = "" }) => styles};
`;

export const LogoContainer = styled.div`
  width: 233px;
  height: 70px;
  display: flex;
  align-items: center;
  padding-left: 25px;
  box-sizing: border-box;
  background: ${({ theme: { headerAdmin } }) => headerAdmin};
  margin-bottom: 27px;
`;
