import React, { useEffect, Fragment, lazy } from 'react';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';

import { ReactComponent as ChatIcon } from '../assets/chat-icon.svg';

//new
import Offerings from 'components/investor-layout/pages/Offerings';
import Documents from 'components/investor-layout/pages/Documents';
import Offer from 'components/investor-layout/pages/Offer/index';
import Transactions from 'components/investor-layout/pages/Transactions';
import Account from 'components/investor-layout/pages/Account';
import Wallet from 'components/investor-layout/pages/Wallet';
import Chat from 'components/investor-layout/pages/Chat';
import NewInvestment from 'components/investor-layout/pages/Investment/';
import SignContract from 'components/investor-layout/pages/SignContract/';
import PaymentDetails from 'components/investor-layout/pages/PaymentDetails/';
import OrderDetails from 'components/investor-layout/pages/OrderDetails/';
import UnfinishedOrders from 'components/investor-layout/pages/UnfinishedOrders/';
import Trade from 'components/investor-layout/pages/Trade/';
import BuyShares from 'components/investor-layout/pages/BuyShares/';
import SignContractTrade from 'components/investor-layout/pages/SignContractTrade';
import PaymentDetailsTrade from 'components/investor-layout/pages/PaymentDetailsTrade';
import OrderDetailsTrade from 'components/investor-layout/pages/OrderDetailsTrade';
import DataRoomApp from 'data-room-panel/data-room.app';
import DigitalSignature from 'components/investor-layout/pages/DigitalSignature';
import InvestNew from 'components/investor-layout/pages/InvestNew';
import QAMain from 'components/investor-layout/pages/QAMain';
import QAAll from 'components/investor-layout/pages/QAAll';
import { InfoPage } from 'components/investor-layout/pages/InfoPage';
import Sponsor from 'components/investor-layout/pages/Sponsor';

import {
  Header,
  Wrapper,
  LogoContainer,
  LinkWrapper,
  NavLink,
  Notifications,
  UnfinishedOrders as Unfinished,
  IconsWrapper,
  ChatLink,
  FooterGlobal,
  HeaderText,
} from './styled';
import { Navigation, FacilitatorNav } from './investor.const';
import { AvatarMenu } from '../components/admin-layout/avatar';
import { clearStore } from '../helpers/localstore';
import { setUnreadMessages } from '../modules/chatRedux/chatAction';
import Api from '../helpers/api';
import { getOrderHistory, getWallet, getDocuments, getSettings } from '../modules/dataRedux/dataAction';
import { getInvFoldersView } from '../modules/dataRoomRedux/dataRoomActions';
import { setCountriesOption } from '../modules/asyncDataRedux/asyncDataAction';
import { NotFound } from '../pages/NotFound';

import ApiNotification from 'helpers/apiNotification';
import { requestToken } from 'modules/notification';
//info pages
import { terms } from 'models/terms';
import { privacy } from 'models/privacy';
//config
import config from 'config';
import { initConnect } from 'modules/socket/socket.io';
const current = process.env.REACT_APP_CURRENT;
//Specific pages
const Questionaries = lazy(() => import('components/investor-layout/pages/Questionnaires'));
const SelfCertification = lazy(() => import('components/investor-layout/pages/SelfCertification'));
const ClientCategorization = lazy(() => import('components/investor-layout/pages/ClientCategorization'));

const InvestorApp = (props) => {
  useEffect(() => {
    const { history, token, role } = props;
    if (
      (!token || (role && role !== 'INVESTOR' && role !== 'FACILITATOR')) &&
      history.location.pathname !== '/investor/singned-contract' &&
      history.location.pathname !== '/investor/singned-nda' &&
      !history.location.pathname.includes('/createpassword')
    ) {
      history.push('/');
    }
  });

  useEffect(() => {
    (async () => {
      const {
        getOrderHistory,
        getWallet,
        getInvFoldersView,
        getDocuments,
        setCountriesOption,
        token,
        history,
        questionnairePassed,
        getSettings,
      } = props;
      initConnect(props.token, props.username);
      if ((current === 'INVESTURE' || current === 'INVESTURE_PROD') && !questionnairePassed) {
        history.push('/investor/questionnaires');
      }
      await getSettings(token);
      const countriesOption = await Api.getCountriesOption(token);
      setCountriesOption(countriesOption);
      await getOrderHistory(token);
      await getDocuments(token);
      props.role !== 'FACILITATOR' && (await getWallet(token));
      await getInvFoldersView(token);

      try {
        const res = await Api.getUserData(token);
        console.log('res.deviceIds', res.deviceIds)
        if (res && res.deviceIds) {
          const deviceId = await requestToken();

          if (!res.deviceIds.includes(deviceId)) {
            ApiNotification.saveToken(token, deviceId);
          }
        }
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  useEffect(() => {
    if (props.socketConnect) {
      Api.updateUnreadMessages(props.token);
    }
  }, [props.socketConnect]);

  const nav = props.role === 'FACILITATOR' ? FacilitatorNav : Navigation;
  const fullHeader = window.location.pathname !== '/investor/questionnaires';

  return (
    <Fragment>
      <Header>
        <LogoContainer styles={config[current].logoStyles.investor}>
          <img src={config[current].logo} alt="logo" />
        </LogoContainer>
        {fullHeader ? (
          <Fragment>
            <LinkWrapper>
              {nav.map(({ text, link, re }) => {
                return <NavLink key={link} link={link} text={text} active={re.test(props.location.pathname)} />;
              })}
            </LinkWrapper>
            <IconsWrapper>
              {props.unFinishedCount ? (
                <Unfinished
                  onClick={() => props.history.push('/investor/unfinished-orders')}
                  unfinished={props.unFinishedCount}
                  path={props.history.location.pathname}
                />
              ) : null}
              <ChatLink
                to="/investor/chat"
                active={props.history.location.pathname.includes('/investor/chat') ? 1 : 0}
                newMess={props.unreadMessages.total}
              >
                <ChatIcon />
              </ChatLink>
              <Notifications unread={0} />
            </IconsWrapper>
          </Fragment>
        ) : !fullHeader && (current === 'INVESTURE' || current === 'INVESTURE_PROD') ? (
          <HeaderText>Complete questionnaire for Investure Investment Platform</HeaderText>
        ) : null}
        <AvatarMenu
          fullHeader={fullHeader}
          isInvestor
          userAvatar={props.logo}
          username={props.fullName}
          role={props.role}
          onLogout={async () => {
            try {
              requestToken().then((tokenNotification) => {
                if (tokenNotification) ApiNotification.removeToken(props.token, tokenNotification).catch(console.log);
              });
              clearStore();
              window.location.replace(`${window.location.origin}/`);
            } catch (error) {}
          }}
          onClick={fullHeader ? () => props.history.push('/investor/account/profile') : undefined}
        />
      </Header>

      <Wrapper>
        <Switch>
          {/* specific pages depending env */}
          {current === 'INVESTURE' || current === 'INVESTURE_PROD'
            ? [<Route key="Questionaries" exact path="/investor/questionnaires" component={Questionaries} />]
            : []}
          {current === 'GLAFKA_DEV' || current === 'GLAFKA_EUROPE' || current === 'GLAFKA_PROD'
            ? [
                <Route key="SelfCertification" exact path="/investor/offerings/:assetId/self-certification" component={SelfCertification} />,
                <Route
                  key="ClientCategorization"
                  exact
                  path="/investor/offerings/:assetId/:category/client-categorization"
                  component={ClientCategorization}
                />,
              ]
            : []}

          {/* general pages */}

          <Route exact path="/investor/offerings/companies/:id" component={Offer} />
          <Route exact path="/investor/offerings/users/:id/:type" component={Trade} />
          <Route exact path="/investor/offerings/:filter?" component={Offerings} />
          <Route exact path="/investor/account/:tab/:assetId?" component={Account} />
          <Route exact path="/investor/chat/:id?" component={Chat} />
          <Route path="/investor/data-room" component={DataRoomApp} />
          <Route exact path="/investor/offerings/question-and-answer/:assetId/:folderName?/:fileName?" component={QAMain} />
          <Route exact path="/investor/question-and-answer/main" component={QAAll} />
          <Route exact path="/investor/question-and-answer/:assetId/:folderName?/:fileName?" component={QAMain} />
          <Route exact path="/investor/offerings/sponsor/:userName/:assetId" component={Sponsor} />
          {/* investor */}
          {props.role !== 'FACILITATOR'
            ? [
                <Route key="NewInvestment" exact path="/investor/offerings/investment/:id" component={NewInvestment} />,
                <Route key="InvestNew" exact path="/investor/offerings/investment-new/:id" component={InvestNew} />,
                <Route key="BuyShares" exact path="/investor/offerings/buy-shares/:id/:txHash" component={BuyShares} />,
                <Route key="SignContract" exact path="/investor/offerings/sign-contract/:id" component={SignContract} />,
                <Route key="SignContractTrade" exact path="/investor/offerings/sign-contract-trade/:id/:txHash" component={SignContractTrade} />,
                <Route
                  key="PaymentDetailsTrade"
                  exact
                  path="/investor/offerings/payment-details-trade/:id/:txHash"
                  component={PaymentDetailsTrade}
                />,
                <Route key="PaymentDetails" exact path="/investor/offerings/payment-details/:id" component={PaymentDetails} />,
                <Route key="OrderDetails" exact path="/investor/offerings/order-details/:id" component={OrderDetails} />,
                <Route key="OrderDetailsTrade" exact path="/investor/offerings/order-details-trade/:id/:txHash" component={OrderDetailsTrade} />,
                <Route key="UnfinishedOrders" exact path="/investor/unfinished-orders" component={UnfinishedOrders} />,
                <Route key="Transactions" exact path="/investor/transactions/:status/:type" component={Transactions} />,
                <Route key="Documents" exact path="/investor/documents" component={Documents} />,
                <Route key="DigitalSignature" path="/investor/offerings/digital-signature/:assetId" component={DigitalSignature} />,
                <Route key="Wallet" exact path="/investor/wallet" component={Wallet} />,
                ,
              ]
            : []}

          {/* info pages  */}
          {privacy[current]
            ? [
                <Route
                  key="privacy"
                  path="/investor/info/privacy-policy"
                  component={() => <InfoPage data={privacy[current]} history={props.history} />}
                />,
              ]
            : []}
          {terms[current]
            ? [<Route key="terms" path="/investor/info/terms-of-use" component={() => <InfoPage data={terms[current]} history={props.history} />} />]
            : []}
          {/* empty */}
          <Route component={NotFound} />
        </Switch>
      </Wrapper>
      <FooterGlobal />
    </Fragment>
  );
};

export default connect(
  ({
    userReducer: {
      token,
      role,
      logo,
      username,
      fullName,
      userInfo: { questionnairePassed },
    },
    chatReducer: { unreadMessages, socketConnect },
    dataReducer: { orderHistory },
    asyncDataReducer: { countriesOption },
  }) => ({
    token,
    logo,
    role,
    fullName,
    username,
    unreadMessages,
    socketConnect,
    orderHistory,
    unFinishedCount: orderHistory ? orderHistory.Pending.filter((e) => e.movementType === 'PENDING' && !e.bankTrade).length : 0,
    questionnairePassed,
    countriesOption,
  }),
  {
    setUnreadMessages,
    getOrderHistory,
    getWallet,
    getInvFoldersView,
    getDocuments,
    setCountriesOption,
    getSettings,
  },
)(InvestorApp);
