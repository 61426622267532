import React, { useState, useEffect, Fragment } from "react";
import styled from "styled-components";

import { HeaderBlock } from "../components/admin-layout/header";
import { ReactComponent as BellIcon } from "../assets/bell-icon.svg";
import { Btn } from "components/admin-layout/buttons";
import { EmptyCard } from "components/common/emptyInfo";

import Api from "helpers/api";
import moment from "moment";

const Block = styled(HeaderBlock)`
  > svg {
    path {
      stroke: ${({ theme: { primary } }) => primary};
    }
  }
  position: relative;
  cursor: pointer;
  &:hover {
    > div {
      display: block;
    }
  }
`;

const Tooltip = styled.div`
  display: none;
  width: 323px;
  background: #ffffff;
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  position: absolute;
  z-index: 100;
  top: 60px;
  left: -120px;
  * {
    box-sizing: border-box;
  }
`;

const Header = styled.div`
  border-radius: 4px 4px 0 0;
  height: 50px;
  background: ${({ theme: { primary } }) => primary};
  display: flex;
  align-items: center;
  padding: 5px 20px;
  font-weight: bold;
  font-size: 14px;
  line-height: 135%;
  color: #fafafa;
`;

const Triangle = styled.div`
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 15px 20px 15px;
  border-color: transparent transparent ${({ theme: { primary } }) => primary} transparent;
  position: absolute;
  top: -13px;
  z-index: 101;
  left: 147px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  height: calc(100% - 50px);
`;

const Bottom = styled.div`
  height: 66px;
  display: flex;
  align-items: center;
  margin-top: auto;
`;

const Item = styled.div`
  height: 113px;
  border-bottom: 1px solid #eaeaea;
  width: ${({ mainPage = false }) => (mainPage ? "calc(100% - 300px)" : "100%")};
  display: flex;
  justify-content: space-between;
  padding: 18px 0;
  ${({ future }) =>
    future
      ? `filter: grayscale(1);
         opacity: 0.7;`
      : ""}
  ${({ mainPage = false }) => mainPage && `border: none; padding: 0; height: fit-content;`}
`;

const Col = styled.div`
  width: ${({ w = "52px" }) => w};
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const LogoBlock = styled.div`
  width: 52px;
  height: 52px;
  position: relative;
  background: ${({ bg }) => `url(${bg})`};
  ${({ mainPage = false }) =>
    mainPage &&
    ` width: 62px;
  height: 62px;`}
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 2px;
`;

const Dot = styled.div`
  position: absolute;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: ${({ bg }) => bg};
  right: -4px;
  top: 44px;
  ${({ mainPage = false }) =>
    mainPage &&
    `width: 14px;
  height: 14px; right: -6px;
  top: 51px;`}
`;

const EventDate = styled.div`
  font-weight: 600;
  font-size: 10px;
  line-height: 150%;
  text-transform: uppercase;
  color: rgba(24, 24, 24, 0.4);
  margin-bottom: 4px;
`;

const Name = styled.div`
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #181818;
  height: 50px;
  ${({ mainPage = false }) => mainPage && `height: 20px; margin-bottom: 9px;`}
`;

const Asset = styled.div`
  font-size: 12px;
  line-height: 150%;
  color: rgba(24, 24, 24, 0.7);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const EventItem = ({ mainPage, ev: { mainImage, color, dateTime, assetName, name }, token, future = false }) => {
  const [logo, setLogo] = useState(null);

  useEffect(() => {
    if (mainImage)
      Api.getAttachment(token, mainImage.secureHash, mainImage.fileNameWithExtension)
        .then((b) => setLogo(b))
        .catch(console.log);
  }, []);
  return (
    <Item future={future} mainPage={mainPage}>
      <Col>
        {logo ? (
          <LogoBlock mainPage={mainPage}>
            <Image src={logo} alt="logo" />
            <Dot mainPage={mainPage} bg={color} />
          </LogoBlock>
        ) : null}
      </Col>
      <Col w={`calc(100% - ${mainImage ? "86px" : "67px"})`}>
        <EventDate>{moment(dateTime).format("DD.MM.YYYY, hh:mm A")}</EventDate>
        <Name mainPage={mainPage}>{name}</Name>
        <Asset>{assetName}</Asset>
      </Col>
    </Item>
  );
};

export const Notifications = ({ token, history, events, socketCount, getEvents }) => {
  const [todayEvents, setTodayEvents] = useState([]);
  const [futureEvents, setFutureEvents] = useState([]);

  useEffect(() => {
    setTodayEvents(
      events.filter(
        (e) =>
          moment(e.dateTime).isBetween(
            moment().set({ hours: 0, minutes: 0 }),
            moment().set({ hours: 23, minutes: 59, milliseconds: 59 })
          ) && !e.read
      )
    );

    setFutureEvents(
      events.filter(
        (e) => +moment(e.dateTime).format("x") > +moment().set({ hours: 23, minutes: 59, milliseconds: 59 }).format("x")
      )
    );
  }, [events]);

  useEffect(() => {
    if (todayEvents.length !== socketCount) getEvents(token);
  }, [socketCount]);

  const renderEvents = () => {
    const _todayEvents = [...todayEvents];

    const arr = _todayEvents.map((e) => (
      <EventItem ev={e} token={token} key={`${e.assetId}-${e.dateTime}-${e.name}`} />
    ));
    futureEvents.forEach((e) => {
      if (arr.length < 3) {
        arr.push(<EventItem future ev={e} token={token} key={`${e.assetId}-${e.dateTime}-${e.name}`} />);
      }
    });
    arr.length = 3;
    if (arr.every((el) => !el))
      return (
        <Fragment>
          <Separator />
          <EmptyCard heading="No current or future events yet" text="You can create an event using the calendar" />
        </Fragment>
      );
    else return arr;
  };

  const count = events.length;
  return (
    <Block newMess={todayEvents.length}>
      <BellIcon />

      {/* tooltip block */}
      {count ? (
        <Tooltip>
          <Triangle />
          <Header>Notifications</Header>
          <Wrapper>
            {renderEvents()}
            <Bottom>
              <Btn fullWidth onClick={() => history.push("/admin/events-list")}>
                All notifications ({count})
              </Btn>
            </Bottom>
          </Wrapper>
        </Tooltip>
      ) : null}
    </Block>
  );
};

const Separator = styled.div`
  height: 15px;
`;
