import React, { Fragment, useEffect, useState } from "react";

import { CircularProgress } from "@material-ui/core";
import { TableHeader, HeaderTD } from "../../dealTypeCard";
import { ReactComponent as Arrow } from "../../../../assets/chevron-down.svg";
import { GroupRow } from "./GroupRow";
import { AssignUserToGroupModal } from "../UserList/AssignUserToGroupModal";
import { EmptyCard } from "./styled";
import { Link } from "react-router-dom";

export const Groups = ({ token, groups, callback, users, onRefresh }) => {
  const [load, setLoad] = useState(true);
  const [openAssignUserToGroup, setOpenAssignUserToGroup] = useState(false);
  const [groupId, setGroupId] = useState(null);

  const toggleAssignModal = (id) => {
    setOpenAssignUserToGroup(!openAssignUserToGroup);
    setGroupId(id);
  };

  useEffect(() => {
    if (groups) setLoad(false);
  }, [groups]);

  if (load) {
    return <CircularProgress style={{ margin: "auto" }} />;
  } else if (groups.length)
    return (
      <Fragment>
        <TableHeader justify="space-between">
          <HeaderTD
            boxSizing="border-box"
            padding="0 0 0 25px"
            blue
            width="calc(100% - 618px)"
          >
            Group <Arrow />
          </HeaderTD>
          <HeaderTD boxSizing="border-box" padding="0 25px" width="150px">
            Users
          </HeaderTD>
          <HeaderTD boxSizing="border-box" padding="0 25px" width="150px">
            Last edition
          </HeaderTD>
          <HeaderTD
            boxSizing="border-box"
            padding="0 25px"
            width="322px"
          ></HeaderTD>
        </TableHeader>
        {groups.map((group) => {
          return (
            <GroupRow
              key={`group-${group.id}`}
              token={token}
              {...group}
              callback={callback}
              toggleAssignModal={toggleAssignModal}
              onRefresh={onRefresh}
            />
          );
        })}

        <AssignUserToGroupModal
          onRefresh={onRefresh}
          token={token}
          isOpen={openAssignUserToGroup}
          onBackdropClick={toggleAssignModal}
          group={groups.find(({ id }) => id === groupId)}
          users={users}
        />
      </Fragment>
    );
  else {
    return (
      <EmptyCard>
        No groups created yet. You can{" "}
        <Link to="/admin/user/new-group">create group</Link>.
      </EmptyCard>
    );
  }
};
