import React from "react";
import styled from "styled-components";

import Api from "../../helpers/api";
import { ReactComponent as DisableDocIcon } from "../../assets/doc-icon.svg";
import { ReactComponent as EyeIcon } from "../../assets/eye.svg";
import { getTrackerHTML } from "../../models/timeTracker";
import { getFileExtention } from "../../helpers";

export const DocumentWrapper = styled.div`
  display: flex;
  min-height: 19px;
  height: 100%;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  cursor: ${({ disabled }) => (disabled ? "no-drop" : "pointer")};
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  text-overflow: ellipsis;

  img {
    width: 20px;
    height: 20px;
    object-fit: contain;
  }
  svg {
    width: 24px;
    height: 24px;
    path {
      stroke: #6454a3;
    }
  }
`;

export const DocumentText = styled.p`
  color: ${({ disabled }) => (disabled ? "#747474" : "#ae8a4d")};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 16px;
  margin: 0 0 0 10px;
`;

const Empty = styled.div`
  width: 24px;
  height: 24px;
`;

export const Document = ({ doc, token }) => {
  const { fileNameWithExtension, secureHash } = doc;

  const downloadFile = async () => {
    getTrackerHTML(token, fileNameWithExtension, secureHash, false);
  };

  const extention = getFileExtention(fileNameWithExtension);
  if (extention == "pdf") {
    return (
      <DocumentWrapper onClick={downloadFile}>
        <EyeIcon />
      </DocumentWrapper>
    );
  } else {
    return <Empty />;
  }
};

export const DisabledDocument = ({ doc }) => {
  if (doc) {
    const { fileNameWithExtension } = doc;
    return (
      <DocumentWrapper disabled>
        <DisableDocIcon />
        <DocumentText disabled>{fileNameWithExtension}</DocumentText>
      </DocumentWrapper>
    );
  } else {
    return null;
  }
};
