import React, { Fragment } from "react";

import { BaseLinks } from "./baseLinks";
import { InvestureLinks } from "./investureLinks";
import {
  Wrapper,
  Aside,
  LogoBlock,
  AsideText,
  BorderBlock,
  FlexRow,
  Step,
  FormBlock,
  Logo,
  PreviewLeft,
  Text,
  BottomBlock,
  Row,
} from "./styled";
import { Heading } from "components/auhtForms/styled";
import Background from "../assets/sing-bg.jpeg";
import BackgroundInv from "../assets/sign-in-investure.png";
import defaultLogo from "assets/asset-manager-logo.svg";
import inputs from "assets/inputs-preview.svg";
import DarkBg from "assets/sign-in-dark.png";

import config from "config";

import { isGeneral, isInvesture, isGlafka, isAlphier } from "helpers/envChecker";
const current = process.env.REACT_APP_CURRENT;

const AuthLayout = (props) => {
  if (isGeneral || isAlphier) return <AuthPreviev {...props} />;
  else
    return (
      <Wrapper>
        <Aside isInvesture={isInvesture} bg={isInvesture ? BackgroundInv : Background}>
          <LogoBlock styles={config[current].logoStyles.auth}>
            <img src={config[current].logo} alt="logo" />
          </LogoBlock>
          {isInvesture ? (
            <Fragment>
              <AsideText white mBottom={20} pRight={0}>
                The smartest way to raise funds or invest
                <br /> in Africa
              </AsideText>
              <AsideText mBottom={0} pRight={0} size={16} lineHeight="160%">
                Multilateral Private market platform for African debt and equity. Manage the entire investment process
                from end to end and trade on the secondary market.
              </AsideText>
            </Fragment>
          ) : isGlafka ? (
            <AsideText margin="-30px auto 0">We are committed to be the best</AsideText>
          ) : (
            <AsideText pRight={20}>Effortless management, investment and trading of unlisted assets</AsideText>
          )}

          <div style={{ marginTop: "auto" }}>
            {isInvesture ? null : (
              <BorderBlock>
                <AsideText white size={24} bold mBottom={20}>
                  All the investment opportunities in one platform
                </AsideText>
                {!isGlafka && (
                  <AsideText mBottom={35} pRight={50}>
                    Short explanation of this advantage in 1-2 sentences.
                  </AsideText>
                )}

                <FlexRow>
                  <Step active />
                  <Step />
                  <Step />
                </FlexRow>
              </BorderBlock>
            )}
            <AsideText mBottom={20} size={12}>
              SOCIAL NETWORKS
            </AsideText>
            {isInvesture ? <InvestureLinks /> : <BaseLinks />}
          </div>
        </Aside>
        <FormBlock infoPage={window.location.pathname.includes("info")}>{props.children}</FormBlock>
      </Wrapper>
    );
};

export const AuthPreviev = ({
  data: { platformLogo, slogan, description, footerText, companyName },
  preview = true,
  children = null,
  customYear = "",
}) => {
  return (
    <Wrapper preview={preview}>
      <PreviewLeft bg={DarkBg}>
        <Logo src={platformLogo || defaultLogo} />
        <Text fWeight={600}>{slogan}</Text>
        <Text o={0.7} lHeight="160" size={16}>
          {description}
        </Text>
        <BottomBlock>
          <Text size={16} fWeight="bold" lHeight={160} o={0.8} m="0 0 14px">
            {companyName} © {customYear || new Date().getFullYear()}. Powered by VALK
          </Text>
          <Text size={14} lHeight={160} o={0.6} m="0 0 44px">
            {footerText}
          </Text>
          <Row>
            <Text size={14} lHeight={160} m="0 24px 0 0">
              Privacy & Cookies
            </Text>
            <Text size={14} lHeight={160} m="0">
              Terms & Conditions
            </Text>
          </Row>
        </BottomBlock>
      </PreviewLeft>
      <FormBlock>
        {children || (
          <div style={{ margin: "auto" }}>
            <Heading>Sign In</Heading>
            <img src={inputs} alt="preview" />
          </div>
        )}
      </FormBlock>
    </Wrapper>
  );
};

export default AuthLayout;
