import React from "react";
import { InputRow } from "../Step/Information/styled";
import {
  TextFieldComponent,
  TextFieldComponentPlaceholder
} from "../../../forms";
import { CountingValues } from "../../DealForm/styled";
import { numberWithCommas } from "helpers";

import { ExpandedPanel } from "./ExpandedPanel";

export const CurrentRound = ({ onChange, defaultValues, currency }) => {
  return (
    <ExpandedPanel header="Current Round">
      <InputRow last>
        <TextFieldComponent
          label="Round Name"
          placeholder="Round Name"
          width="calc(50% - 15px)"
          defaultValue={defaultValues.assetFinancialProperty.roundName}
          onChange={onChange("assetFinancialProperty", "roundName")}
        />
        <TextFieldComponentPlaceholder
          pseudo
          pseudoText={currency}
          label="Company Valuation"
          type="number"
          placeholder="Company Valuation"
          width="calc(50% - 15px)"
          defaultValue={defaultValues.companyValuation}
          onChange={onChange("companyValuation")}
        />
      </InputRow>
      <InputRow last>
        <CountingValues w="calc(50% - 15px)"></CountingValues>
        <CountingValues w="calc(50% - 15px)">
          {defaultValues.companyValuation
            ? `~ ${numberWithCommas(
                (+defaultValues.companyValuation).toFixed(2)
              )} ${currency}`
            : null}
        </CountingValues>
      </InputRow>
    </ExpandedPanel>
  );
};
