import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import { ColWrapper, Col, Card, Quote } from "../Offer/styled";
import { Button } from "../Offerings/styled";
import { Separator, Row } from "./styled";

import { Navigation } from "./Navigation";
import { TokensOnSale } from "./TokensOnSale";

import MainWrapper from "./Wrapper";
import OfferSlider from "../Offer/Slider";
import { Description } from "../Offer/Description";
import { ShareInfo } from "../Offer/ShareInfo";
// import { Sponsor } from "../Offer/Sponsor";
import { DynamicBlock } from "../Offer/DynamicBlock";
import { DataRoom } from "../Offer/DataRoom";
import { DataRoomNew } from "../Offer/DataRoomNew";
import { DynamicFinancial } from "../Offer/DynamicFinancial";
import { DynamicCapital } from "../Offer/DynamicCapital";

const colWidth = 440;
const halfWidth = "calc(50% - 10px)";

const Trade = (props) => {
  const onRedirect = (link) => () => {
    props.history.push(link);
  };
  const [screenSize, setScreenSize] = useState(window.innerWidth);

  useEffect(() => {
    const onResize = () => {
      setScreenSize(window.innerWidth);
    };

    window.addEventListener("resize", onResize);

    return () => window.removeEventListener("resize", onResize);
  }, []);

  return (
    <MainWrapper {...props}>
      {(
        {
          assetId,
          assetProperties,
          assetProperties: {
            assetName,
            assetSymbol,
            images,
            documents,
            vocAssetDynamicProperties,
            smallDescription,
            dynamicCapitalStructureJson,
            fullDescription,
            openEndFund,
          },
          assetFinancialProperty,
          tokensOnSale,
          newWorkflow,
        },
        type
      ) => {
        if (type === "buy-shares") {
          return (
            <ColWrapper>
              <Col width={screenSize > 1280 ? `calc(100% - ${colWidth + 30}px)` : "100%"}>
                <Navigation assetName={assetName} path={type} id={assetId} />
                <TokensOnSale
                  assetId={assetId}
                  history={props.history}
                  token={props.token}
                  assetSymbol={assetSymbol}
                  shares={tokensOnSale}
                />
              </Col>
              {screenSize > 1280 ? (
                <Col width={`${colWidth}px`}>
                  <Card padding="25px">
                    <Button
                      size={16}
                      width={390}
                      height={42}
                      transparent
                      onClick={() => props.history.push(`/investor/offerings/users/${assetId}/info`)}
                    >
                      MORE INFORMATION
                    </Button>
                  </Card>
                  <Card padding="25px 25px 7px">
                    <Quote text={smallDescription} />
                  </Card>
                </Col>
              ) : null}
            </ColWrapper>
          );
        } else if (type === "info") {
          return (
            <ColWrapper>
              <Col width={screenSize > 1280 ? `calc(100% - ${colWidth + 30}px)` : "100%"}>
                <Navigation assetName={assetName} path={type} id={assetId} />
                <OfferSlider key={assetId || "null-of-asset"} images={images} token={props.token} />
                {fullDescription ? <Description isTrade={screenSize > 1280} info={assetProperties} /> : <Separator />}
                <ShareInfo
                  cutFlow={openEndFund}
                  info={assetProperties}
                  token={props.token}
                  assetId={assetId}
                  financialInfo={assetFinancialProperty}
                />
                {screenSize > 1280 ? null : (
                  <Row margin="0 0 20px" align="flex-start" justify="space-between">
                    <Col width={halfWidth}>
                      <DynamicFinancial info={assetProperties} />
                      {dynamicCapitalStructureJson ? (
                        <DynamicCapital
                          values={JSON.parse(dynamicCapitalStructureJson)}
                          baseCurrency={assetFinancialProperty.baseCurrency}
                        />
                      ) : null}
                    </Col>
                    <Col height="100%" width={halfWidth}>
                      {newWorkflow ? (
                        <DataRoomNew
                          stretchHeight
                          id={assetId}
                          token={props.token}
                          onRedirect={onRedirect}
                          isTradeView
                        />
                      ) : (
                        <DataRoom docs={documents} token={props.token} />
                      )}
                    </Col>
                  </Row>
                )}

                {/* <Sponsor token={props.token} manager={assetManagerDetails} history={props.history} /> */}
                {vocAssetDynamicProperties &&
                  vocAssetDynamicProperties
                    .filter((e) => e.header && e.text.replace(/<[^>]*>/g, ""))
                    .map((e) => <DynamicBlock block={e} key={e.header} />)}
              </Col>
              {screenSize > 1280 ? (
                <Col width={`${colWidth}px`}>
                  <Card padding="25px">
                    <Button
                      size={16}
                      width={390}
                      height={42}
                      onClick={onRedirect(`/investor/offerings/users/${assetId}/buy-shares`)}
                    >
                      BUY SHARES
                    </Button>
                  </Card>
                  <Card padding="25px 25px 7px">
                    <Quote text={smallDescription} />
                  </Card>
                  <DynamicFinancial info={assetProperties} />
                  {dynamicCapitalStructureJson ? (
                    <DynamicCapital
                      values={JSON.parse(dynamicCapitalStructureJson)}
                      baseCurrency={assetFinancialProperty.baseCurrency}
                    />
                  ) : null}
                  {newWorkflow ? (
                    <DataRoomNew id={assetId} token={props.token} onRedirect={onRedirect} isTradeView />
                  ) : (
                    <DataRoom docs={documents} token={props.token} />
                  )}
                </Col>
              ) : null}
            </ColWrapper>
          );
        } else {
          return <Redirect to="/offerings/all" />;
        }
      }}
    </MainWrapper>
  );
};

export default connect(({ userReducer: { token } }) => ({
  token,
}))(Trade);
