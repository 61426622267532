import React from "react";

import { TypeContainer, Circle, Type } from "./index";

export const TypeComponent = ({ value, label, onChange, active }) => {
  const onChoose = () => {
    onChange(value);
  };
  return (
    <TypeContainer active={active} onClick={onChoose}>
      <Circle active={active} />
      <Type active={active}>{label}</Type>
    </TypeContainer>
  );
};
