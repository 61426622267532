import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Redirect } from "react-router-dom";

import Api from "../../../../helpers/api";
import { Wrapper, Col, Card, Header, Row, Heading, Text, Line } from "../Investment/styled.js";
import { Label, InfoBlock, TextInfo, Link, Status, MoreInfo } from "../OrderDetails/styled";
import { Button } from "../../../investor-layout/pages/Offerings/styled";
import { Loader } from "../../styled/Loader";
import { TextFieldComponent } from "../../../admin-layout/forms";
import { Price } from "../Investment/Price";
import { numberWithCommas } from "helpers";

const OrderDetailsTrade = ({ token, id, history, paymentResponseDataTrade, soldTrade }) => {
  const [wait, setWait] = useState(false);
  const [smallScreen, setSmallScreen] = useState(window.innerWidth < 1280);

  useEffect(() => {
    const onResize = () => {
      setSmallScreen(window.innerWidth < 1280);
    };

    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  const PriceBlock = useCallback((price, assetSymbol) => <Price convert={price} assetSymbol={assetSymbol} />, []);

  const fullSize = "100%";
  const colWidth = 440;

  if (paymentResponseDataTrade && soldTrade) {
    const {
      TokenIdentifier,
      TokenQuantity,
      ["Amount to pay"]: amount,
      BankName,
      AmountForIssuerRequest,
      buyerAccountNumber,
      AccountHilderName,
      ["Company account number"]: companyAccountNumber,
      IBAN,
      ["Swift/BIC"]: bic,
      tx,
      txDate,
    } = paymentResponseDataTrade;
    const { assetName, price } = soldTrade;

    const makePayment = async () => {
      setWait(true);
      const res = await Api.makeTransaction(
        token,
        companyAccountNumber,
        buyerAccountNumber,
        tx,
        AmountForIssuerRequest
      ).catch(() => setWait(false));
      if (res) history.push("/investor/transactions/all/all");
    };

    const goToChats = () => {
      const link = document.createElement("a");
      link.href = `${window.location.origin}/investor/chat/`;
      link.target = "_blank";
      link.click();
    };

    return (
      <Wrapper>
        {wait && <Loader zIndex={100} />}
        <Header>
          <Heading margin="0 0 0 25px">Order #{tx}</Heading>
        </Header>

        {smallScreen ? (
          <Row margin="0 0 15px" width={fullSize} smallScreen={smallScreen} justify="space-between" align="start">
            {PriceBlock(price, TokenIdentifier)}
          </Row>
        ) : null}

        <Col width={smallScreen ? fullSize : `calc(${fullSize} - ${colWidth + 30}px)`}>
          <Card>
            <Row margin="0 0 14px 0">
              <Heading>Waiting for Payment Approval</Heading>
              <Text fSize={18} color="rgba(24, 24, 24, 0.4)" margin="0 0 0 auto">
                2 / 3
              </Text>
            </Row>
            <Row margin="0 0 25px 0">
              <Text lineH="160%" fSize={14} color="#7C7C7C">
                When the platform admin will approve your transaction your{" "}
                <span>
                  {numberWithCommas(TokenQuantity)} {TokenIdentifier}
                </span>{" "}
                shares will appear in your account.
              </Text>
            </Row>
            <Label>Your Transaction</Label>
            <InfoBlock>
              <Col width="230px">
                <TextInfo>
                  Shares Purchase for
                  <br />
                  <Link to={`/investor/offerings/companies/${id}`}>{assetName}</Link>
                </TextInfo>
              </Col>
              <Col width="fit-content">
                <TextInfo grey>{amount}</TextInfo>
                <TextInfo>
                  {numberWithCommas(TokenQuantity)} {TokenIdentifier}
                </TextInfo>
              </Col>
              <Status />
              <Col width="fit-content">
                <TextInfo>{moment(txDate).format("dddd, DD/MM/YYYY")}</TextInfo>
                <TextInfo grey>{moment(txDate).format("hh:mm:ss A")}</TextInfo>
              </Col>
              <MoreInfo />
            </InfoBlock>
          </Card>
          <Card>
            <Row margin="0 0 14px 0">
              <Heading>Bank Transfer to Invest</Heading>
            </Row>
            <Row margin="0 0 20px 0">
              <Text lineH="160%" fSize={14} color="#7C7C7C">
                Please use the below Bank information details to transfer the money and receive your shares.
              </Text>
            </Row>
            <Row margin="0 0 20px 0" justify="space-between" padding="0">
              <TextFieldComponent
                width="178px"
                label="Amount to tranfser"
                height={42}
                labelFontSize={10}
                fontSize={12}
                isInvestor
                disabled
                defaultValue={numberWithCommas(amount)}
              />
              <TextFieldComponent
                width="calc(100% - 202px)"
                label="Account Holder Name"
                height={42}
                labelFontSize={10}
                fontSize={12}
                isInvestor
                disabled
                defaultValue={AccountHilderName}
              />
            </Row>
            <Row margin=" 0" justify="space-between" padding="0">
              <TextFieldComponent
                width="178px"
                label="BIC (SWIFT) CODE"
                height={42}
                labelFontSize={10}
                fontSize={12}
                isInvestor
                disabled
                defaultValue={bic}
              />
              <TextFieldComponent
                width="255px"
                label="IBAN"
                height={42}
                labelFontSize={10}
                fontSize={12}
                isInvestor
                disabled
                defaultValue={IBAN}
              />
              <TextFieldComponent
                width="calc(100% - 482px)"
                label="Beneficiary’s bank"
                height={42}
                labelFontSize={10}
                fontSize={12}
                isInvestor
                disabled
                defaultValue={BankName}
              />
            </Row>
            <Line />
            <Row>
              <Text fSize={18}>
                Total shares: <span>{numberWithCommas(TokenQuantity) + " " + TokenIdentifier}</span>
              </Text>
            </Row>
          </Card>
          <Card noPadding height={62}>
            <Row justify="space-between">
              <Button transparent height={42} width={287} bold size={14} margin="10px 0 10px 30px" onClick={goToChats}>
                CHAT WITH ADMIN
              </Button>
              <Button height={42} width={287} bold size={14} margin="10px 30px 10px 0" onClick={makePayment}>
                PAY NOW
              </Button>
            </Row>
          </Card>
        </Col>

        {smallScreen ? null : <Col width={`${colWidth}px`}>{PriceBlock(price, TokenIdentifier)}</Col>}
      </Wrapper>
    );
  } else {
    return <Redirect to="/investor/offerings/users" />;
  }
};

export default connect(
  (
    { userReducer: { token, fullName, paymentResponseDataTrade }, dataReducer: { soldTrade } },
    {
      match: {
        params: { id, txHash },
      },
    }
  ) => {
    return {
      token,
      id,
      txHash,
      fullName,
      paymentResponseDataTrade,
      soldTrade,
    };
  },
  {}
)(OrderDetailsTrade);
