import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { Wrapper, EmptyCard } from "./styled";
import { Loading } from "../Account/styled";

import { PortfolioOverview } from "./PortfolioOverview";
import { SharesAccount } from "./SharesAccount";
import { CurrencyAccount } from "./CurrencyAccount";

import { TransactionMessage } from "../../ActionMessages";
import { getWallet } from "../../../../modules/dataRedux/dataAction";
import { EmptyCard as NoInfo } from "components/common/emptyInfo";

const Wallet = ({ token, history, balance, getWallet }) => {
  const [messageOpen, setMessageOpen] = useState(false);
  const [message, setMessage] = useState("0000");
  const [error, setError] = useState(false);
  const [smallScreen, setSmallScreen] = useState(window.innerWidth < 1150);

  useEffect(() => {
    const handleResize = () => {
      setSmallScreen(window.innerWidth < 1150);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    getWallet(token);
  }, []);

  const onSellShares = mess => {
    setMessage(mess);
    setMessageOpen(true);
  };

  const onFailureSell = e => {
    setMessage(e);
    setError(true);
    setMessageOpen(true);
  };

  const onCloseMessage = () => {
    setMessageOpen(false);
    if (error) setError(false);
    else getWallet(token);
  };

  const onChangeCurrency = currency => {
    getWallet(token, currency);
  };

  if (
    balance &&
    balance.InvestmentOverview &&
    balance.InvestmentOverview.investmentTotalOverview &&
    balance.InvestmentOverview.investmentTotalOverview.portfolio
  ) {
    return (
      <Wrapper>
        <PortfolioOverview
          token={token}
          data={balance.InvestmentOverview}
          sharesArr={balance.VocTokens}
          history={history}
          onExchange={onChangeCurrency}
        />
        <SharesAccount
          token={token}
          data={balance.VocTokens}
          history={history}
          onSuccess={mess => onSellShares(mess)}
          onFailure={e => onFailureSell(e)}
          smallScreen={smallScreen}
        />
        <CurrencyAccount token={token} data={balance.CashBalance} />

        <TransactionMessage
          isOpen={messageOpen}
          onClose={onCloseMessage}
          isError={error}
          message={message}
        />
      </Wrapper>
    );
  } else if (
    balance &&
    balance.InvestmentOverview &&
    balance.InvestmentOverview.investmentTotalOverview &&
    !balance.InvestmentOverview.investmentTotalOverview.portfolio
  ) {
    return (
      <EmptyCard>
        <NoInfo heading="No activity yet" />
      </EmptyCard>
    );
  } else {
    return <Loading />;
  }
};

export default connect(
  ({ userReducer: { token }, dataReducer: { balance } }) => ({
    token,
    balance
  }),
  { getWallet }
)(Wallet);
