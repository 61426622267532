import React from "react";
import { Main, TextSub } from "../styled";
import { InputRow } from "./styled";
import { TextAreaComponent, TextEditorComponent } from "../../../../forms";
import { options } from "./helpers";

export const Summary = ({ onChange, defaultValues, roleName }) => {
  return (
    <Main>
      <TextSub textAlign="left" fontWeight="bold" color="#181818" marginSize="0 0 25px 0">
        Summary
      </TextSub>
      <InputRow>
        <TextAreaComponent
          label={roleName === "SPONSOR" ? "Slogan" : "short information (up to 350 symbols)"}
          onChange={onChange("assetProperties", "smallDescription")}
          defaultValue={defaultValues.assetProperties.smallDescription}
        ></TextAreaComponent>
      </InputRow>
      <InputRow last>
        <TextEditorComponent
          scrollToTop
          label="Full information"
          onChange={onChange("assetProperties", "fullDescription")}
          defaultValue={defaultValues.assetProperties.fullDescription}
          customOptions={options}
        />
      </InputRow>
    </Main>
  );
};
