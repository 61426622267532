import styled from "styled-components";

export const HeatTableWrapper = styled.section`
  min-width: 100%;
  display: flex;
  flex-direction: column;
  width: fit-content;
`;

export const BorderFlex = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-right: 1px solid #f1f3f8;
  padding: 0 20px;
  &:last-child {
    border-right: none;
  }
  > button {
    margin-right: 6px;
    &:last-child {
      margin-right: 0;
    }
  }
`;

export const Btn = styled.button`
  font-family: Muli;
  position: relative;
  white-space: nowrap;
  padding-bottom: 0;
  padding-top: 0;
  width: ${({ width, fullWidth, _width }) => (width ? `${width}px` : fullWidth ? "100%" : _width ? _width : "98px")};
  border: 1px solid
    ${({ black, hide, error, green, theme: { primary, red } }) =>
      black ? "#20263C" : hide ? "#CACACA" : error ? red : green ? "#55B17C" : primary};
  height: ${({ height }) => (height ? `${height}px` : "32px")};
  background: ${({ black, white, hide, transparent, error, green, theme: { primary } }) =>
    black ? "#20263C" : white || hide ? "#fff" : transparent || error || green ? "transparent" : primary};
  color: ${({ white, hide, transparent, error, green, theme: { primary, red } }) =>
    white || transparent ? primary : hide ? "#CACACA" : error ? red : green ? "#55B17C" : "#fff"};
  font-size: 10px;
  line-height: 135%;
  text-align: center;
  font-weight: bold;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  border-radius: 4px;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: ${({ margin }) => (margin ? margin : "0")};
  transition: all 0.3s linear;
  text-decoration: none;
  &:disabled {
    background: #e3e3e3 !important;
    color: white !important;
    border: none;
    > svg {
      path {
        stroke: #fff;
      }
    }
  }
  &:focus {
    background: ${({ black, white, hide, transparent, error, green, theme: { primary } }) =>
      black ? "#20263C" : white || hide ? "#fff" : transparent || error || green ? "transparent" : primary};
  }
  > svg {
    margin: ${({ rightIcon }) => (rightIcon ? "0 0 0 6px " : "0 6px 0 0")};
    transform: ${({ transform }) => (transform ? "rotate(180deg)" : "none")};
    transition: all 0.3s linear;

    path {
      stroke: ${({ transparent, error, green, theme: { primary, red } }) =>
        transparent ? primary : error ? red : green ? "#55B17C" : "#fff"};
    }
  }
`;

export const CeilHeadingBlue = styled.h6`
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 135%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: ${({ theme: { primary } }) => primary};
  margin: 0 8px 0 0;
`;

export const CenterContainer = styled.div`
  margin: auto;
`;

export const FolderName = styled.div`
  font-weight: bold;
  font-size: 14px;
  line-height: 135%;
  color: #181818;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  text-align: center;
`;

export const HeaderRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
  position: relative;
  > svg {
    position: absolute;
    top: 22px;
    left: 27px;
    width: 16px;
    height: 16px;
  }
`;

export const HeatCeil = styled.div`
  width: 136px;
  padding: 0 5px 0 25px;
  height: 100%;
  display: flex;
  align-items: center;
`;

export const HeatFilter = styled.div`
  width: 632px;
  height: 60px;
  display: flex;
  justify-content: space-between;
  background: #ffffff;
  border-radius: 4px 0px 4px 4px;
`;

export const HeatRow = styled.div`
  width: fit-content;
  height: 50px;
  background: #ffffff;
  border-radius: 4px;
  min-width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const SearchInputUsers = styled.input`
  width: calc(100% - 652px);
  height: 60px;
  background: #ffffff;
  border-radius: 4px 0px 4px 4px;
  outline: none;
  border: none;
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 135%;
  letter-spacing: -0.02em;
  color: #333333;
  padding: 20px 20px 20px 56px;
  box-sizing: border-box;
  &:placeholder {
    color: rgba(24, 24, 24, 0.4);
  }
`;

export const ScrollYContainer = styled.section`
  width: 100%;
  overflow-x: scroll;
  padding-bottom: ${({ isScroll = false }) => (isScroll ? "10px" : 0)};
  &&::-webkit-scrollbar-track {
    background-color: ${({ theme: { primaryLight } }) => primaryLight};
    border-radius: 2px;
  }
  &&::-webkit-scrollbar {
    height: ${({ isScroll = false }) => (isScroll ? "8px" : 0)};
    border-radius: 2px;
    background-color: ${({ theme: { primaryLight } }) => primaryLight};
  }
  &&::-webkit-scrollbar-thumb {
    background-color: ${({ theme: { primaryMiddle } }) => primaryMiddle};
    border-radius: 2px;
  }
`;
