import React, { useState, Fragment, useEffect } from "react";

import Api from "../../../../helpers/api";
import { Folder } from "./folderComponent";
import {
  SelectComponent,
  TextAreaComponent,
  SwitcherComponent
} from "../../../admin-layout/forms/index";
import {
  ModalBackground,
  ModalWrapper,
  ModalHeader,
  ModalHeading
} from "../Offer/styled";
import { Button } from "../Offerings/styled";
import {
  BodyWrapperSelect,
  BodyWrapper,
  ModalFooter,
  FileSelector,
  Description,
  ScrollContainer,
  NameBlock,
  Controllers
} from "./styled";
import { ReactComponent as CloseIcon } from "../../../../assets/x-circle.svg";
import { ReactComponent as Rem } from "../../../../assets/x-close-red.svg";
import { File } from "components/common/icons-primary";
import { ReactComponent as Right } from "../../../../assets/chevron-right2.svg";
import { ReactComponent as Left } from "../../../../assets/chevron-left-grey.svg";
import { Wait } from "../../../admin-layout/pages/DashBoard/styled";
import {
  ErrorMessage,
  SuccessMessage
} from "../../../admin-layout/errorMessage";

export const AddModal = ({
  token,
  categories,
  onBackdropClick,
  assetName,
  assetId,
  onRefresh,
  data,
  _fileName,
  _folderName
}) => {
  const [question, setQuestion] = useState({
    category: categories[0],
    content: ""
  });
  const [isPrivate, setIsPrivate] = useState(true);
  const [wait, setWait] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState("");
  const [selectorOpen, setSelectorOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState({
    folderName: _folderName ? _folderName : "",
    fileName: _fileName ? _fileName : ""
  });

  const onClose = () => {
    onBackdropClick();
    setSelectedFile({ fileName: "", folderName: "" });
  };

  const onRemFile = e => {
    e.stopPropagation();
    setSelectedFile({ fileName: "", folderName: "" });
  };

  const renderFolders = () => {
    const random = Math.round(Math.random() * 1000) + "";
    return (
      <Fragment key={`folder-${random}`}>
        {data[assetId].folders.map((e, i) => (
          <Folder
            _file={selectedFile}
            folder={e}
            key={`${e.name}-${i}-${random}`}
            onBackdropClick={onAddFile}
            setSelectedFile={setSelectedFile}
          />
        ))}
      </Fragment>
    );
  };

  const onChangePrivate = () => {
    setIsPrivate(!isPrivate);
  };

  const onChangeSimple = (e, key) => {
    setQuestion({ ...question, [key]: e.target.value });
  };

  const onError = e => {
    setError(e);
    setWait(false);
  };

  const closeError = () => {
    setError(null);
    onClose();
  };

  const closeSuccess = () => {
    setSuccess("");
    onClose();
    onRefresh();
  };

  const onAddFile = () => {
    setSelectorOpen(!selectorOpen);
  };

  const onSendQuestion = async () => {
    setWait(true);
    const data = {
      ...question,
      isPrivate: !isPrivate,
      folderName: selectedFile.folderName ? selectedFile.folderName : null,
      fileName: selectedFile.fileName ? selectedFile.fileName : null
    };

    const res = await Api.askQuestion(token, assetId, data).catch(onError);

    if (res) {
      setWait(false);
      setSuccess(`Thank you! Your question have been sent!`);
    }
  };

  if (selectorOpen) {
    return (
      <ModalBackground onClick={onClose}>
        <ModalWrapper width={565} onClick={e => e.stopPropagation()}>
          <ModalHeader boxShadow="0px 10px 40px rgba(89, 120, 150, 0.08)">
            <ModalHeading textTransform="none">
              Link a File to Question
            </ModalHeading>
            <CloseIcon onClick={onClose} />
          </ModalHeader>
          <BodyWrapperSelect>
            <Button
              hide
              back
              height={32}
              width={180}
              size={10}
              onClick={onAddFile}
            >
              <Left />
              BACK TO FORM
            </Button>
            <Description>Description</Description>
            <ScrollContainer height={420}>{renderFolders()}</ScrollContainer>
          </BodyWrapperSelect>
        </ModalWrapper>
      </ModalBackground>
    );
  } else if (wait) {
    return (
      <ModalBackground onClick={onClose}>
        <Wait />
      </ModalBackground>
    );
  } else
    return (
      <ModalBackground onClick={onClose}>
        <ModalWrapper width={565} onClick={e => e.stopPropagation()}>
          <ModalHeader boxShadow="0px 10px 40px rgba(89, 120, 150, 0.08)">
            <ModalHeading textTransform="none">
              Post New Question for {assetName} Deal
            </ModalHeading>
            <CloseIcon onClick={onClose} />
          </ModalHeader>
          <BodyWrapper>
            <SelectComponent
              top={61}
              isInvestor
              values={categories}
              onChange={e => onChangeSimple(e, "category")}
              label="Choose category"
              value={question.category}
            />
            <TextAreaComponent
              isInvestor
              label="Your Question"
              areaHeight={182}
              placeholder="Enter your question..."
              onChange={e => onChangeSimple(e, "content")}
              defaultValue={question.content}
            />
            <h3>{selectedFile.fileName ? "LINKED FILE" : "LINK A FILE"}</h3>
            <FileSelector onClick={onAddFile} active={selectedFile.fileName}>
              {selectedFile.fileName ? (
                <NameBlock>
                  <File />
                  <span>{selectedFile.fileName}</span>
                </NameBlock>
              ) : (
                <h3>Choose a File</h3>
              )}
              <Controllers>
                {selectedFile.fileName ? <Rem onClick={onRemFile} /> : null}
                <Right style={{ marginLeft: "auto" }} />
              </Controllers>
            </FileSelector>
            <SwitcherComponent
              isInvestor
              label="Allow ADMIN TO SHARE YOUR QUESTION"
              name="Allow to Share"
              onChange={onChangePrivate}
              textMargin="0 auto 0 12px"
            />
            <ModalFooter>
              <Button
                transparent
                height={32}
                width={94}
                size={10}
                onClick={onClose}
              >
                CANCEL
              </Button>
              <Button
                disabled={wait || !question.content}
                height={32}
                width={180}
                size={10}
                onClick={onSendQuestion}
              >
                POST QUESTION
              </Button>
            </ModalFooter>
          </BodyWrapper>
        </ModalWrapper>
        {error && <ErrorMessage er={error} onClose={closeError} />}
        {success ? (
          <SuccessMessage message={success} onClose={closeSuccess} />
        ) : null}
      </ModalBackground>
    );
};
