import React, { useState, useEffect } from "react";

import { Main, TextSub, HeaderCircle } from "../../DealForm/Step/styled";
import { Filter, ActivityData } from "../styled";
import { FlexRow } from "../../../dashBoardCard";
import { ReactComponent as Maximize } from "../../../../../assets/maximize.svg";
import Api from "helpers/api";
import { CircularProgress } from "@material-ui/core";

const values = {
  all: 0,
  day: 1,
  week: 7,
  month: 30,
};

export const Activity = ({ id, history, token, w = "calc(33.33% - 20px)", h = "130px", existed = false }) => {
  const [period, setPeriod] = useState("all");
  const [data, setData] = useState(null);
  const [wait, setWait] = useState(true);

  const getActivity = async () => {
    const activity = await Api.getAssetActivity(token, id, values[period]).catch((_) => setWait(false));
    if (activity) {
      setData(activity);
      setWait(false);
    }
  };

  useEffect(() => {
    getActivity();
  }, []);

  useEffect(() => {
    if (data) {
      setWait(true);
      getActivity();
    }
  }, [period]);
  return (
    <Main currentHeight={h} width={w} padding="20px 25px 15px 25px">
      <FlexRow mBottom="0" align="flex-start">
        <TextSub textAlign="left" fontWeight="bold" color="#181818" marginSize="0 0 20px 0">
          Activity
        </TextSub>
        <Filter defaultValue={period} callback={setPeriod} />
        <HeaderCircle marginLeft="24px" onClick={() => history.push(`/admin/dashboard/statistic/${id}`)}>
          <Maximize />
        </HeaderCircle>
      </FlexRow>
      <FlexRow justify="flex-start">
        {wait ? (
          <CircularProgress size={24} style={{ margin: "auto" }} />
        ) : data ? (
          <ActivityData data={data} existed={existed} />
        ) : null}
      </FlexRow>
    </Main>
  );
};
