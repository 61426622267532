import React, { useState, Fragment } from "react";

import { ModalBackground, ModalWrapper, ModalHeader, ModalHeading } from "../Offer/styled";
import { Button } from "../Offerings/styled";
import { Row, Col, Loading } from "./styled";
import { TextFieldComponent } from "../../../admin-layout/forms";
import { ReactComponent as CloseIcon } from "../../../../assets/x-circle.svg";
import { InfoMessage } from "../../styled/infoMessages";
import { ReactComponent as EyeIcon } from "../../../../assets/eye.svg";

import Api from "../../../../helpers/api";

const iconStyle = {
  position: "absolute",
  top: 31,
  right: 20,
  zIndex: 2,
  width: 22,
  height: 22,
  cursor: "pointer",
};

export const ChangePassword = ({ isOpen, onBackDropClick, token, reLogin, username }) => {
  const [passData, setPassData] = useState({
    currentPassword: "",
    matchingPassword: "",
    password: "",
  });
  const [load, setLoad] = useState(false);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState(false);
  const [isRequest, setRequest] = useState(false);
  const [_token, set_token] = useState(token);
  const [passType, setPasstype] = useState("password");
  const [newPassType, setNewPasstype] = useState("password");
  const [confirmNewPassType, setConfirmNewPasstype] = useState("password");

  const isSomeEmpty = Object.values(passData).some((el) => el === "");

  const onChangePassType = (cb, field) => {
    if (field === "password") cb("text");
    else cb("password");
  };

  const onDataChange = (e, name) => {
    error && setError(false);
    const {
      target: { value },
    } = e;
    setPassData({
      ...passData,
      [name]: value,
    });
  };

  const onEndNotification = () => setMessage(false);

  const onSubmit = async () => {
    setRequest(true);
    setLoad(true);
    const success = await Api.changeUserPassword(_token, passData).catch(() => setLoad(false));
    if (success) {
      reLogin({ email: username, password: passData.password }, (t) => {
        if (t) {
          setMessage(true);
          set_token(t);
          setPassData({
            currentPassword: "",
            matchingPassword: "",
            password: "",
          });
          setPasstype("password");
          setNewPasstype("password");
          setConfirmNewPasstype("password");
        }
        onBackDropClick();
        setLoad(false);
      });
    } else {
      setError(true);
    }
  };
  console.log(message, isRequest);
  return (
    <Fragment>
      <InfoMessage
        onEnd={onEndNotification}
        showMessage={message && isRequest}
        heading="CHANGE PASSWORD AUTHENTICATION"
        text="Password change have been success"
      />
      {isOpen ? (
        <ModalBackground onClick={!load && onBackDropClick}>
          {load && <Loading />}
          <ModalWrapper width={440} onClick={(e) => e.stopPropagation()}>
            <ModalHeader boxShadow="0px 10px 40px rgba(89, 120, 150, 0.08)">
              <ModalHeading textTransform="none">Change Password</ModalHeading>
              <CloseIcon onClick={onBackDropClick} />
            </ModalHeader>
            <Col width="100%" padding="25px">
              <Row margin="0 0 15px" position="relative">
                <TextFieldComponent
                  error={error}
                  type="password"
                  labelFontSize={10}
                  fontSize={14}
                  height={42}
                  isInvestor
                  label="Current Password"
                  placeholder="Your current password"
                  defaultValue={passData.currentPassword}
                  onChange={(e) => onDataChange(e, "currentPassword")}
                  type={passType}
                  autoComplite="new-password"
                />
                <EyeIcon onClick={() => onChangePassType(setPasstype, passType)} style={iconStyle} />
              </Row>
              <Row margin="0 0 15px" position="relative">
                <TextFieldComponent
                  type="password"
                  labelFontSize={10}
                  fontSize={14}
                  height={42}
                  isInvestor
                  label="New password"
                  placeholder="New password here"
                  defaultValue={passData.password}
                  onChange={(e) => onDataChange(e, "password")}
                  type={newPassType}
                  autoComplite="new-password"
                />
                <EyeIcon onClick={() => onChangePassType(setNewPasstype, newPassType)} style={iconStyle} />
              </Row>
              <Row margin="0 0 25px" position="relative">
                <TextFieldComponent
                  type="password"
                  labelFontSize={10}
                  fontSize={14}
                  height={42}
                  isInvestor
                  label="Repeat New password"
                  placeholder="Repeat new password here"
                  defaultValue={passData.matchingPassword}
                  onChange={(e) => onDataChange(e, "matchingPassword")}
                  type={confirmNewPassType}
                  autoComplite="new-password"
                />
                <EyeIcon
                  onClick={() => onChangePassType(setConfirmNewPasstype, confirmNewPassType)}
                  style={iconStyle}
                />
              </Row>
              <Row>
                <Button tTransform="uppercase" size={12} width={94} height={36} hide onClick={onBackDropClick}>
                  Cancel
                </Button>
                <Button
                  tTransform="uppercase"
                  size={12}
                  width={180}
                  height={36}
                  onClick={onSubmit}
                  disabled={isSomeEmpty || passData.password !== passData.matchingPassword}
                >
                  Change Password
                </Button>
              </Row>
            </Col>
          </ModalWrapper>
        </ModalBackground>
      ) : null}
    </Fragment>
  );
};
