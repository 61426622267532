import React, { useState, useEffect } from "react";

import {
  ModalBackground,
  ModalWrapper,
  MHeading,
  MHeader,
  DealsWrapper,
  ListHeading,
  DealItem,
  WaitingWrapper,
} from "./styled";
import { ReactComponent as CloseIcon } from "../../../../assets/x-circle.svg";
import { Btn } from "../../buttons";
import { CircularProgress } from "@material-ui/core";

import Api from "../../../../helpers/api";

export const QuickViewList = ({ isOpen, onBackdropClick, deals, token, onReload }) => {
  const [waiting, setWaiting] = useState(false);

  const [shownDeals, setShownDeals] = useState([...deals.filter((e) => e.shown)]);

  const [hiddenDeals, setHideDeals] = useState([...deals.filter((e) => !e.shown)]);

  const [dealsToChange, setDealsToChange] = useState({});

  const changeDealStatus = ({ viewId, status }, fromShown) => {
    setDealsToChange({ ...dealsToChange, [viewId]: status });
    if (fromShown) {
      const assetIndex = shownDeals.findIndex((e) => e.viewId === viewId);
      setHideDeals([...hiddenDeals, shownDeals[assetIndex]]);
      setShownDeals(shownDeals.filter((_, i) => i !== assetIndex));
    } else {
      const assetIndex = hiddenDeals.findIndex((e) => e.viewId === viewId);
      setShownDeals([...shownDeals, hiddenDeals[assetIndex]]);
      setHideDeals(hiddenDeals.filter((_, i) => i !== assetIndex));
    }
  };

  const closeModal = () => {
    setShownDeals([...deals.filter((e) => e.shown)]);
    setHideDeals([...deals.filter((e) => !e.shown)]);
    onBackdropClick();
  };

  const renderDeals = (arr, active) => {
    if (arr) {
      return arr.map((e) => {
        const { assetName, viewId, mainImage } = e;
        return (
          <DealItem
            key={`${viewId}-${assetName}`}
            viewId={viewId}
            active={active}
            assetName={assetName}
            token={token}
            fileName={mainImage ? mainImage.fileNameWithExtension : ""}
            hash={mainImage ? mainImage.secureHash : ""}
            onChangeStatus={(data) => changeDealStatus(data, active)}
          />
        );
      });
    }
  };

  const onSubmit = () => {
    setWaiting(true);
    setTimeout(() => {
      Promise.all([
        Object.keys(dealsToChange).map((e) => Api.editDashboardQuickViewList(token, e, dealsToChange[e])),
      ]).then(() => {
        setWaiting(false);
        onBackdropClick();
        onReload();
      });
    }, 2000);
  };

  return (
    <ModalBackground isOpen={isOpen} onClick={closeModal}>
      <ModalWrapper onClick={(e) => e.stopPropagation()}>
        {waiting && (
          <WaitingWrapper>
            <CircularProgress />
          </WaitingWrapper>
        )}
        <MHeader>
          <MHeading>Quick View List</MHeading>
          <CloseIcon onClick={closeModal} />
        </MHeader>
        <DealsWrapper>
          {shownDeals.length ? <ListHeading>Deal to show on dashboard:</ListHeading> : null}
          {renderDeals(shownDeals, true)}
          {hiddenDeals.length ? <ListHeading last={shownDeals.length}>Hided deals:</ListHeading> : null}
          {renderDeals(hiddenDeals, false)}
        </DealsWrapper>
        <MHeader isFooter>
          <Btn onClick={closeModal} hide width={94}>
            Cancel
          </Btn>
          <Btn width={180} onClick={onSubmit}>
            save changes
          </Btn>
        </MHeader>
      </ModalWrapper>
    </ModalBackground>
  );
};
