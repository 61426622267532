import React from "react";

import { Card, BorderBlock, Heading, Row, NavLink } from "./styled";

export const Navigation = ({ assetName, path, id }) => {
  return (
    <Card>
      <BorderBlock>
        <Heading fSize={24}>{assetName}</Heading>
      </BorderBlock>
      <Row padding="0 25px" height="60px">
        <NavLink active={path === "buy-shares"} to={`/investor/offerings/users/${id}/buy-shares`}>
          Buy Shares
        </NavLink>
        <NavLink active={path === "info"} to={`/investor/offerings/users/${id}/info`}>
          Information
        </NavLink>
      </Row>
    </Card>
  );
};
