import React from "react";

//styled
import { Header, TextH3 } from "../../DealForm/Step/styled";
import { Btn } from "../../../buttons";
import { ManagerInfo } from "../common/managerAvatar";
import { BorderBlock } from "../styled";
//icons
import { ReactComponent as ArrowLeft } from "../../../../../assets/arrowleft.svg";
import { ReactComponent as EditIcon } from "../../../../../assets/edit-2.svg";
import { ReactComponent as MailIcon } from "../../../../../assets/mail-asset.svg";

export const PageHeader = ({
  token,
  history,
  deal: {
    assetProperties: { assetName },
    sponsors,
    linearId,
  },
  onOpenNetVal = console.log,
}) => {
  const onRedirect = (link) => () => {
    history.push(link);
  };
  return (
    <Header>
      <Btn transparent onClick={onRedirect("/admin/deals/new_deal")} width={150}>
        <ArrowLeft /> back to deals
      </Btn>
      <TextH3 marginSize="0 0 0 25px">
        {assetName} {sponsors.length ? "by " : null}
      </TextH3>
      {sponsors.length ? (
        <ManagerInfo token={token} avatar={sponsors[0].userLogo} managerName={sponsors[0].fullName} />
      ) : null}

      <Btn margin="0 25px 0 auto" transparent width={135} onClick={onRedirect(`/admin/send-emails/${linearId}`)}>
        <MailIcon style={{ width: 9, height: 9 }} /> TO SUBSCRIBERS
      </Btn>
      <BorderBlock>
        <Btn onClick={onOpenNetVal} width={125}>
          NET ASSET VALUE
        </Btn>
        <Btn margin="0 0 0 12px" transparent width={125} onClick={onRedirect(`/admin/deals/edit/${linearId}`)}>
          <EditIcon /> edit deal
        </Btn>
      </BorderBlock>
    </Header>
  );
};
