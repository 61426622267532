import React, { Fragment } from "react";
import { Separator } from "../styled";

import { MainInformation } from "./MainInformation";
import { Summary } from "./Summary";
import { AdditionalInformation } from "./AdditionalInformation";
import { Images } from "./Images";

export const Information = (props) => {
  return (
    <Fragment>
      <MainInformation {...props} />
      <Separator />
      <Summary {...props} />
      <Separator />
      <Images {...props} />
      <AdditionalInformation {...props} />
    </Fragment>
  );
};
