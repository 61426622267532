import moment from "moment";
import theme from "models/themes";
import { moutNumber } from "helpers";

export const optionCreator = (
  data,
  chartWidth = 1000,
  navigatorEnabled = true,
  selected,
  currency
) => {
  const buttons = [];

  let isZero = false;
  const max = {}
  try {
    const currentDate = (new Date()).getTime();

    const days = ({0: 6, 1: 7, 2: 31})[selected] || 6;
    const date31 = currentDate - days * 24 * 60 * 60 * 1000;

    isZero = data.filter(e => {
      const [x] = e;
      return x >= date31 && x <= currentDate;
    }).every(e => {
      const [,y] = e;
      return !y;
    });

    if(isZero) max.max = 100;
  } catch (e) {}

  buttons.push({
    type: "day",
    count: 1,
    text: "1d"
  });

  buttons.push({
    type: "week",
    count: 1,
    text: "1w"
  });

  buttons.push({
    type: "month",
    count: 1,
    text: "1m"
  });

  buttons.push({
    type: "month",
    count: 6,
    text: "6m"
  });

  buttons.push({
    type: "year",
    count: 1,
    text: "1y"
  });

  return {
    navigator: {
      enabled: navigatorEnabled
    },

    chart: {
      width: chartWidth,
      height: 255
    },

    rangeSelector: {
      selected,
      buttons,
      buttonTheme: {
        display: "none"
      }
    },
    xAxis: {
      type: "dateTime",
      title: {},
      labels: {
        formatter: function() {
          return moment(this.value).format("MMM DD");
        }
      },
      minTickInterval: 24 * 60 * 60 * 1000,
      lineColor: "#F6F8FB",
      gridLineWidth: 1
    },
    yAxis: {
      title: {
        // text: "GBP"
      },
      distance: 0,
      offset: 50,
      labels: {
        formatter: function() {
          if(isZero) return '';
          if (this.value > 0) {
            return `${moutNumber(this.value)} ${currency}`;
          }

          return "";
        }
      },
      ...max,
      min: -10,
      lineColor: theme.primaryMiddle,
      gridLineWidth: 1
    },
    tooltip: {
      split: true,
      valueSuffix: ` ${currency}`
    },
    series: [
      {
        name: "Transactions",
        type: "areaspline",
        data: data,
        threshold: null,
        tooltip: {
          valueDecimals: 2
        },
        marker: {
          enabled: true,
          radius: 4,
          fillColor: "#fff",
          lineColor: theme.primaryMiddle,
          lineWidth: 1,
          states: {
            hover: {
              fillColor: theme.primaryMiddle
            }
          }
        },
        color: theme.primaryMiddle,
        lineWidth: 1,
        fillColor: {
          linearGradient: {
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 1
          },
          stops: [
            [0, theme.chartBgFrom],
            [1, theme.chartBgTo]
          ]
        }
      }
    ]
  };
};
