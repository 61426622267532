import React, { useState, useEffect } from "react";
import moment from "moment";

import { Card, ImageBlock, InfoBlock, DealName, DataCol, DataRow, DataName, ButtonLink, StickName } from "./styled";
import Api from "../../../../helpers/investorApi";
import { ReactComponent as File } from "../../../../../assets/file-dr.svg";
import { ReactComponent as Folder } from "../../../../../assets/folder-dr.svg";
import { ReactComponent as Calendar } from "../../../../../assets/calendar-dr.svg";

export const DealCard = ({
  deal: { assetId, assetName, image, lastUpdate, totalFiles, totalFolders, dealsType },
  token,
}) => {
  const [logo, setLogo] = useState(null);
  const isNew = dealsType === "NEW_DEAL";
  useEffect(() => {
    if (image) {
      const { fileNameWithExtension, secureHash } = image;
      Api.getAttachment(token, secureHash, fileNameWithExtension).then((b) => setLogo(b));
    }
  }, []);

  return (
    <Card>
      <ImageBlock>{logo ? <img src={logo} alt={assetName} /> : null}</ImageBlock>
      <InfoBlock>
        <StickName active={isNew}>{isNew ? "NEW DEAL" : "EXISTED DEAL"}</StickName>
        <DealName>{assetName}</DealName>
        <DataCol>
          <DataRow>
            <File />
            <DataName>
              Total Files: <span>{totalFiles}</span>
            </DataName>
          </DataRow>
          <DataRow>
            <Folder />
            <DataName>
              Total Folders: <span>{totalFolders}</span>
            </DataName>
          </DataRow>
          <DataRow>
            <Calendar />
            <DataName>
              Last Update: <span>{lastUpdate ? moment(lastUpdate).format("DD.MM.YYYY, hh:mm A") : "no activity"}</span>
            </DataName>
          </DataRow>
        </DataCol>
        <ButtonLink blue={isNew} grey={!isNew} DR to={`/investor/data-room/deal/${assetId}`}>
          OPEN DATA ROOM
        </ButtonLink>
      </InfoBlock>
    </Card>
  );
};
