import React, { useState, useEffect, useRef } from "react";
import moment from "moment";

import Api from "../../../../helpers/api";
import {
  Question,
  BodyHeader,
  Privacy,
  TextMessage,
  SeparateLine,
  RowQuestion,
  UserInfo,
  UserAvatar,
  InfoBlock,
  FileBlock,
  FileName,
  FileControls,
} from "./styled";
import { Btn } from "../../../admin-layout/buttons";
import { File } from "components/common/icons-primary";
import { ReactComponent as Eye } from "../../../../assets/eye.svg";
import { ReactComponent as Download } from "../../../../assets/download.svg";
import { ErrorMessage } from "../../errorMessage";

export const QuestionCardWaiting = ({
  q: {
    assetId,
    category,
    textQuestion,
    userFullName,
    userLogo,
    dateOfQuestion,
    questionId,
    isPrivate,
    userInvestorType,
    userGender,
    size,
    filename,
    folderName,
  },
  token,
  history,
}) => {
  const [open, setOpen] = useState(true);
  const [height, setHeight] = useState(0);
  const [error, setError] = useState(null);

  const onReadMore = (_) => setOpen(!open);
  const textRef = useRef(null);

  const onError = (e) => {
    setError(e);
  };

  const onView = async () => {
    const fileUrl = await Api.viewQADocument(token, folderName, filename, assetId).catch(onError);

    if (fileUrl) {
      const link = document.createElement("a");
      link.target = "_blank";
      link.href = fileUrl;
      link.click();
    }
  };

  const downloadDocument = async () => {
    const fileUrl = await Api.downloadQADocument(token, folderName, filename, assetId).catch(onError);

    if (fileUrl) {
      const link = document.createElement("a");
      link.target = "_blank";
      link.href = fileUrl;
      link.click();
    }
  };

  useEffect(() => {
    if (textRef && textRef.current) {
      setHeight(textRef.current.offsetHeight);
      setOpen(false);
    }
  }, []);

  const gotoAnswer = () => {
    history.push(`/admin/question-and-answer/answer/${assetId}/${questionId}`);
  };
  return (
    <Question padding="18px 20px 22px 25px">
      <BodyHeader marginBottom="7px">
        <span>{category}</span>
        <Privacy isPrivate={isPrivate} />
      </BodyHeader>
      <TextMessage height={open && !height ? "fit-content" : open && height ? `${height}px` : "30px"} ref={textRef}>
        {textQuestion}
      </TextMessage>
      {filename && size ? (
        <FileBlock isAnswered>
          <FileName>
            <File />
            <h4>
              {filename}
              <span>{(size / 1024).toFixed(2)} mb</span>
            </h4>
          </FileName>
          <FileControls>
            <Eye onClick={onView} />
            <Download onClick={downloadDocument} />
          </FileControls>
        </FileBlock>
      ) : null}
      {height > 30 ? <span onClick={onReadMore}>Read More</span> : null}
      <SeparateLine />
      <RowQuestion>
        <UserInfo>
          <UserAvatar
            token={token}
            fileName={userLogo ? userLogo.fileNameWithExtension : null}
            hash={userLogo ? userLogo.secureHash : null}
            investorType={userInvestorType}
            gender={userGender}
          />
          <InfoBlock>
            <h3>{userFullName}</h3>
            <span>{moment(dateOfQuestion).format("DD.MM.YYYY, hh:mm A")}</span>
          </InfoBlock>
        </UserInfo>
        <Btn width={140} onClick={gotoAnswer}>
          ANSWER QUESTION
        </Btn>
      </RowQuestion>
      {error && <ErrorMessage er={error} onClose={() => setError(null)} />}
    </Question>
  );
};
