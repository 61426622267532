import React, { useState, useEffect } from "react";
import moment from "moment";

import { Card, Heading, Col, Row, Counter, Label } from "./styled";

function helpersFormatTime(ms) {
  const format = {
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  };

  const _hour = 60 * 60 * 1000;
  format.hours = Math.floor(ms / _hour);
  ms -= format.hours * _hour;

  const _minute = 60 * 1000;
  format.minutes = Math.floor(ms / _minute);
  ms -= format.minutes * _minute;

  format.seconds = Math.floor(ms / 1000);

  format.days = format.days < 10 ? `0${format.days}` : format.days;
  format.hours = format.hours < 10 ? `0${format.hours}` : format.hours;
  format.minutes = format.minutes < 10 ? `0${format.minutes}` : format.minutes;
  format.seconds = format.seconds < 10 ? `0${format.seconds}` : format.seconds;

  return format;
}

export const PaymentTime = ({ timeOfEnd, smallScreen = false }) => {
  const [endTime, setEndTime] = useState(+moment(timeOfEnd).format("x") - +moment().format("x"));

  const [fromEndTime, setFromEndTime] = useState(helpersFormatTime(endTime));

  useEffect(() => {
    const idInterval = setInterval(() => {
      const newEndTime = +moment(endTime).add(-1, "second").format("x");
      setEndTime(newEndTime);

      setFromEndTime(helpersFormatTime(newEndTime));
    }, 1000);
    return () => clearInterval(idInterval);
  });

  return (
    <Card fullHeight={smallScreen}>
      <Heading margin="0 0 20px 0">Payment Time</Heading>
      <Row justify="space-between">
        <Col width="118px" align="center">
          <Counter>{fromEndTime.hours}</Counter>
          <Label>hours</Label>
        </Col>
        <Col width="118px" align="center">
          <Counter>{fromEndTime.minutes}</Counter>
          <Label>min</Label>
        </Col>
        <Col width="118px" align="center">
          <Counter>{fromEndTime.seconds}</Counter>
          <Label>sec</Label>
        </Col>
      </Row>
    </Card>
  );
};
