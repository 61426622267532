import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { CircularProgress } from "@material-ui/core";

import Api from "helpers/api";

import { DynamicBlock } from "components/investor-layout/pages/Offer/DynamicBlock";
import { Background } from "components/investor-layout/pages/Sponsor/styled";
import { ManagerInfo } from "components/investor-layout/pages/Offer/styled";

import { Header, Main, TextSub, TextH3 } from "components/admin-layout/pages/DealForm/Step/styled";
import { ConfirmMessage } from "components/admin-layout/actionMessage";
import { Btn } from "components/admin-layout/buttons";

import { Wrapper, Column, Separator } from "components/admin-layout/pages/CreateUser/styled";
import { SendEmail } from "components/admin-layout/pages/UserList/SendEmail";

import { InfoCol, FlexRow, AvatarBlock } from "./styled";
import { ErrorMessagePortal } from "components/admin-layout/errorMessage";
import { icons } from "models/icons";

const headStyles = { textAlign: "left", fontWeight: "bold", color: "#181818", marginSize: "0 0 20px 0" };
const buttonStyles = { margin: "0 0 26px 0", fullWidth: true, transparent: true };
const colSize = 323;

const SponsorInfo = ({ history, token, match, role, username }) => {
  const [userInfo, setUserInfo] = useState(null);
  const [userShortInfo, setUserShortInfo] = useState(null);
  const [sendEmailOpen, setSendEmailOpen] = useState(false);
  const [load, setLoad] = useState(true);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmText, setConfirmText] = useState("");
  const [error, setError] = useState(null);
  const [wait, setWait] = useState(false);

  const isSponsor = role === "SPONSOR";

  const onChangeConfirm = (hasAssets = false) => () => {
    if (!confirmOpen && userInfo) {
      !hasAssets && setConfirmText(`Are you sure to delete <b>${userInfo.username}</b> ?`);
    } else {
      setConfirmText("");
    }
    setConfirmOpen(!confirmOpen);
  };

  const onGoBack = () => {
    try {
      history.goBack();
    } catch (_) {
      history.push(`/admin/users`);
    }
  };

  const onRemoveSponsor = async () => {
    setWait(true);
    const remove = await Api.removeSponsor(token, userInfo.username).catch((e) => {
      setWait(false);
      setError(e);
      onChangeConfirm()();
    });
    if (remove) {
      onGoBack();
    }
  };

  const onGetRemoveInfo = async () => {
    const hasAssets = await Api.getSponsorAssets(token, username).catch(setError);
    if (hasAssets === "true") {
      onChangeConfirm()(true);
      setConfirmText(`The user is the sponsor of the deals.<br/>Are you sure you want to permanently delete it?`);
    } else {
      onChangeConfirm()();
    }
  };

  const openEmailModal = () => {
    setSendEmailOpen(!sendEmailOpen);
  };

  useEffect(() => {
    const getData = async () => {
      const userName = match ? match.params.userName : username;
      const info = await Api.getAccountDetails(token, userName);
      const _info = await Api.getUserMoreInfo(token, userName);
      if (info && _info) {
        setUserInfo(info.userData);
        setUserShortInfo(_info);
        setLoad(false);
      }
    };

    getData();
  }, []);

  const renderDynamic = () => {
    const { additionalInformation } = userInfo;
    if (additionalInformation) {
      return additionalInformation
        .filter((e) => e.header && e.text.replace(/<[^>]*>/g, ""))
        .map((e) => <DynamicBlock block={e} key={e.header} />);
    }
  };

  const renderKeys = () =>
    userInfo.userDynamicProperties.map(({ header, text }) => (
      <ManagerInfo total={userInfo.userDynamicProperties.length} key={header} heading={header} data={text} />
    ));

  const formatDate = (time) => (time ? moment(time).format("DD MMM YYYY hh:mm a") : "No activity");

  const onGoToEdit = (name) => () => {
    history.push(`/admin/users/edit-sponsor/${name}`);
  };

  if (load) {
    return <CircularProgress style={{ margin: "auto" }} />;
  } else {
    const {
      userBackgroundLogo,
      userLogo,
      fullName,
      countryOfRegistration,
      userDescription,
      userDynamicProperties,
      additionalInformation,
      username,
      webSite,
    } = userInfo;
    const { creationDate, lastLoginDate } = userShortInfo;
    return (
      <Fragment>
        {/* header */}
        <Header>
          <Btn transparent onClick={onGoBack} width={150}>
            {icons.left}
            back {isSponsor ? "" : "to users"}
          </Btn>
          {isSponsor && <TextH3 marginSize="0 0 0 25px">My Profile</TextH3>}
        </Header>
        {/* columns */}
        <Wrapper>
          <Column width={`calc(100% - ${colSize + 30}px)`}>
            <Background token={token} logo={userBackgroundLogo} height={96} />
            <AvatarBlock
              token={token}
              file={userLogo ? userLogo.fileNameWithExtension : null}
              hash={userLogo ? userLogo.secureHash : null}
              absolute
            />
            <Main padding="48px 25px 25px">
              <TextSub {...headStyles} fSize={20}>
                {fullName} {isSponsor && <span>(you)</span>}
              </TextSub>
              <FlexRow noSpaceBetween>
                <InfoCol width={33} heading="Country of REGISTRATION" info={countryOfRegistration} />
                <InfoCol width={33} heading="type" info="SPONSOR" />
              </FlexRow>
            </Main>
            <Separator />
            <Main>
              <TextSub {...headStyles}>More Information</TextSub>
              <FlexRow>
                <InfoCol width={33} heading="Joining date" info={formatDate(creationDate)} />
                <InfoCol width={33} heading="Last login" info={formatDate(lastLoginDate)} />
                <InfoCol width={33} />
              </FlexRow>
            </Main>
            <Separator />
            {userDescription && userDynamicProperties ? (
              <Main>
                <TextSub {...headStyles}>Short Information & Numbers</TextSub>
                <TextSub textAlign="left" color="#333333" marginSize="0 0 24px 0">
                  {userDescription}
                </TextSub>
                <FlexRow>{renderKeys()}</FlexRow>
              </Main>
            ) : null}

            {/* Dynamic Blocks */}
            {additionalInformation && additionalInformation.length ? (
              <Fragment>
                <Separator />
                {renderDynamic()}
              </Fragment>
            ) : null}
          </Column>

          <Column width={`${colSize}px`}>
            <Main padding={`20px 25px ${isSponsor ? 0 : "25px"}`}>
              {!isSponsor ? (
                <Btn {...buttonStyles} transparent={false} onClick={openEmailModal}>
                  {icons.message} Send E-mail
                </Btn>
              ) : null}
              <Btn {...buttonStyles} onClick={onGoToEdit(username)}>
                {icons.edit}
                EDIT PROFILE
              </Btn>
              {!isSponsor ? (
                <Fragment>
                  <Btn {...buttonStyles}>{icons.login} Login data</Btn>
                  <Btn error fullWidth onClick={onGetRemoveInfo}>
                    {icons.suspend} Suspend
                  </Btn>
                </Fragment>
              ) : null}
            </Main>
            <Separator />
            <Main>
              <TextSub {...headStyles}>Contact Information</TextSub>
              <InfoCol heading="Web-site" info={webSite} link bold />
              <Separator height={20} />
              <InfoCol heading="E-mail" info={username} link bold />
            </Main>
          </Column>
        </Wrapper>
        {/* modals */}
        <SendEmail
          isOpen={sendEmailOpen}
          onBackdropClick={openEmailModal}
          token={token}
          singleUser={true}
          mailData={{
            userName: fullName,
            userEmail: username,
          }}
        />
        <ConfirmMessage
          heading="Delete sponsor"
          isOpen={confirmOpen}
          onBackDpopClick={onChangeConfirm()}
          message={confirmText}
          onAction={onRemoveSponsor}
          mHeight="fit-content"
          contentHeight="140px"
          wait={wait}
        />
        {error && <ErrorMessagePortal er={error} onClose={() => setError(null)} />}
      </Fragment>
    );
  }
};

export default connect(
  ({ userReducer: { token, role, username } }) => ({
    token,
    role,
    username,
  }),
  null
)(SponsorInfo);
