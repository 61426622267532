import React from "react";
import { Switch, Route } from "react-router-dom";
import { connect } from "react-redux";

import { Wrapper } from "./styled";
import { AdminView } from "./setup.app.admin_view";

import { model } from "./setup.app.flow_model";

import { PlatformSetup } from "components/setup-layout/pages/PlatformSetup";
import { SignContract } from "components/setup-layout/pages/SignContract";
import { AssetCreation } from "components/setup-layout/pages/AssetCreation";
import { Dashboard } from "components/setup-layout/pages/Dashboard";

const noSidebarList = ["platform", "sign-contract", "asset-creation"];

const SetupApp = (props) => {
  const {
    location: { pathname },
  } = props;

  if (noSidebarList.some((el) => pathname.includes(el))) {
    return (
      <Wrapper>
        <Switch>
          <Route path="/setup/platform/:username" component={PlatformSetup} />
          <Route path="/setup/sign-contract/:username" component={SignContract} />
          <Route path="/setup/asset-creation/:username" component={AssetCreation} />
        </Switch>
      </Wrapper>
    );
  } else {
    return (
      <AdminView {...props}>
        <Switch>
          <Route path="/setup/dashboard" component={Dashboard} />
          <Route component={() => <div>{pathname}</div>} />
        </Switch>
      </AdminView>
    );
  }
};

export default connect(
  ({ userReducer: { token, role, logo, fullName, username } }) => ({
    token,
    role,
    logo,
    fullName,
    username,
  }),
  null
)(SetupApp);
