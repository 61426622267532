export default class KycTableState {
  constructor(
    statuses = [],
    loading = true,
    loadFilter = false,
    filter = "ALL",
    list = [],
    page = 1,
    totalPages = 1,
    portion = 10
  ) {
    this.statuses = statuses;
    this.loading = loading;
    this.filter = filter;
    this.list = list;
    this.portion = portion;
    this.page = page;
    this.loadFilter = loadFilter;
    this.totalPages = totalPages;
  }
}

export function reducer(state, action) {
  if (action.type === "ON_LOAD") {
    return { ...state, loadFilter: true };
  }
  if (action.type === "OFF_LOAD") {
    return { ...state, loadFilter: false };
  }
  if (action.type === "SET_LIST") {
    return {
      ...state,
      list: action.list,
      totalPages: action.totalPages,
      loadFilter: false,
      page: action.page,
    };
  }
  if (action.type === "SET_FILTER") {
    return {
      ...state,
      totalPages: 1,
      page: 1,
      filter: action.filter,
      loadFilter: true,
    };
  }
  if (action.type === "CUSTOM_MERGE") {
    return { ...state, ...(action.payload || {}) };
  }
  return state;
}
