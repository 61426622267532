import styled from "styled-components";
import React, { useState, useEffect, Fragment } from "react";
import { Link } from "react-router-dom";

import Api from "../../../../helpers/api";
import { ReactComponent as NoAvatar } from "../../../../assets/no-avatar.svg";
import { ReactComponent as Institution } from "../../../../assets/institution.svg";
import { ReactComponent as NoAvatarFemale } from "../../../../assets/no-avatar-female.svg";

export const Header = styled.div`
  height: 60px;
  background: #ffffff;
  border-radius: 4px;
  margin-bottom: ${({ isFooter = false }) => (isFooter ? 0 : "25px")};
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 15px 20px;
  width: 100%;
  button {
    margin-left: auto;
  }
`;

export const BackLink = styled(Link)`
  margin-left: ${({ mLeft }) => (mLeft ? "auto" : "")};
  width: ${({ width = "150px" }) => width};
  height: 32px;
  border: 1px solid ${({ theme: { primary } }) => primary};
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 135%;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  text-decoration: none;
  color: ${({ transparent, theme: { primary } }) => (transparent ? "#fff" : primary)};
  background: ${({ transparent, theme: { primary } }) => (transparent ? primary : "#fff")};
  margin-right: 30px;
  :last-child {
    margin-right: 0px;
  }
  > svg {
    margin-right: 7px;
  }
`;

export const Heading = styled.h6`
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 135%;
  color: #181818;
  margin: 0;
`;

export const ColWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ right }) => (right ? "323px" : "calc(100% - 353px)")};
`;

export const InfoCard = styled.div`
  box-sizing: border-box;
  padding: 25px;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border-radius: 4px;
  > h3 {
    margin: 0 0 13px 0;
    font-family: Muli;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 135%;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: rgba(24, 24, 24, 0.4);
  }
`;

export const Question = styled.div`
  width: 100%;
  background: ${({ theme: { adminTableRowGrey } }) => adminTableRowGrey};
  > svg {
    path {
      stroke: ${({ theme: { primary } }) => primary};
    }
  }
  border-radius: 4px;
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  color: #333333;
  box-sizing: border-box;
  padding: 12px 20px 35px 20px;
  margin-bottom: 20px;
`;

export const Info = styled.div`
  background: ${({ from = false, theme: { adminTableRowGrey, documentBgInvestor } }) =>
    from ? documentBgInvestor : adminTableRowGrey};
  border-radius: 4px;
  width: 100%;
  display: flex;
  padding: ${({ from = false }) => (from ? "11px 20px" : "14px 20px")};
  box-sizing: border-box;
  margin-bottom: 20px;
  align-items: center;
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 135%;
  letter-spacing: -0.02em;
  color: #181818;
  :last-child {
    margin-bottom: 0px;
  }
`;

export const SubmBlock = styled.div`
  width: 100%;
  margin-top: 30px;
  background: #ffffff;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 15px 25px;
`;

export const UserAvatar = ({ fileName, hash, token, small = false, investorType, gender }) => {
  const [avatar, setAvatar] = useState(null);

  useEffect(() => {
    if (!avatar && fileName && hash) {
      Api.getAttachment(token, hash, fileName).then((blob) => setAvatar(blob || Api.defaultAvatar));
    }
  });
  return (
    <AvatarContainer small={small}>
      {avatar ? (
        <Avatar small={small} src={avatar} alt="avatar" />
      ) : !fileName && !hash ? (
        investorType && investorType === "INSTITUTION" ? (
          <Institution
            style={
              small
                ? {
                    width: 32,
                    height: 32,
                  }
                : {}
            }
          />
        ) : gender && gender === "FEMALE" ? (
          <NoAvatarFemale
            style={
              small
                ? {
                    width: 32,
                    height: 32,
                  }
                : {}
            }
          />
        ) : (
          <NoAvatar
            style={
              small
                ? {
                    width: 32,
                    height: 32,
                  }
                : {}
            }
          />
        )
      ) : null}
    </AvatarContainer>
  );
};

const Avatar = styled.img`
  ${({ small = false }) => {
    if (small) {
      return `
          width: 20px;
          height: 20px;
        `;
    }

    return `
        width: 32px;
        height: 32px;
      `;
  }}

  border-radius: 50%;
  object-fit: cover;
`;

const AvatarContainer = styled.div`
  ${({ small = false }) => {
    if (small) {
      return `
          width: 20px;
          height: 20px;
          margin-right:10px;
        `;
    }

    return `
        width: 42px;
        height: 42px;
        margin-right:14px;
      `;
  }}
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  > svg {
    filter: grayscale(${({ theme: { greyFilter } }) => greyFilter});
  }
`;

export const Sepatator = styled.div`
  height: 19px;
`;

export const EditSeparator = styled.div`
  margin-top: 10px;
  width: 100%;
  height: 1px;
  background: #f2f2f2;
  margin-bottom: 20px;
`;

export const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(5px);
  z-index: 998;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SelectFile = styled.div`
  width: 100%;
  height: 40px;
  background: #ffffff;
  border: 1px solid #efefef;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 23px;
  padding: 0 20px;
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 135%;
  letter-spacing: -0.02em;
  color: rgba(24, 24, 24, 0.6);
  cursor: pointer;
  > svg {
    transform: ${({ open }) => (open ? "rotate(270deg)" : "rotate(90deg)")};
    width: 16px;
    height: 16px;
    path {
      stroke: ${({ theme: { primary } }) => primary};
    }
  }
`;
