import React, { useState, useRef, useEffect, Fragment } from "react";
import moment from "moment";

import {
  Card,
  ChatHeader,
  Flex,
  ChatHeading,
  HeadingLink,
  SubmitBlock,
  MessageInput,
  ButtonsBlock,
  NoChatSelected,
  DealHeader,
  DealLogoCont,
  ColDeal,
  DealNameHeader,
  MessagesCont,
  Message,
  UserMessage,
  IconContainer,
} from "./styled";
import { Button } from "../../../investor-layout/pages/Offerings/styled";
import { ReactComponent as PaperClipIcon } from "../../../../assets/paperclip.svg";
import { ReactComponent as PaperClipIcon2 } from "../../../../assets/paperclip2.svg";
import { ReactComponent as SendIcon } from "../../../../assets/send.svg";
import { ReactComponent as RightIcon } from "../../../../assets/chevron-right2.svg";
import { AttachmentsUploader } from "./AttachmentsUploader";

import Api from "../../../../helpers/api";

export const ChatBar = ({ userName, assetId, messages, token, adminChatThemes, getNewMessage = console.log }) => {
  const chatContainer = useRef(null);
  const debounceRef = useRef(null);
  const lastPositionTop = useRef(null);
  const lastMessageId = useRef(null);

  const [avatar, setAvatar] = useState(null);
  const [userAvatar, setUserAvatar] = useState(null);
  const [newMessage, setNewMessage] = useState("");
  const sendButtonRef = useRef(null);
  const [uploaderOpen, setUploaderOpen] = useState(false);
  const [files, setFiles] = useState([]);
  const [wait, setWait] = useState(false);

  const onChangeUploaderStatus = () => {
    setUploaderOpen(!uploaderOpen);
  };

  const chat = adminChatThemes.find((e) => e.assetId === assetId && e.username === userName) || {};

  const moveToBottom = (top = 0) => {
    clearTimeout(debounceRef.current);
    debounceRef.current = setTimeout(() => {
      if (chatContainer.current) {
        chatContainer.current.scroll({
          left: 0,
          top,
        });
      }
    }, 500);
  };

  useEffect(() => {
    if (!messages[0]) moveToBottom();
    else if (lastMessageId.current !== messages[0].id) {
      moveToBottom();
      lastMessageId.current = messages[0].id;
    }
  }, [messages]);

  useEffect(() => {
    let localDebounce = null;
    function onTop(e) {
      if (
        e.target.scrollTop > lastPositionTop.current &&
        e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 100
      ) {
        clearTimeout(localDebounce);
        localDebounce = setTimeout(getNewMessage, 250);
      }
      lastPositionTop.current = e.target.scrollTop;
    }

    chatContainer.current && chatContainer.current.addEventListener("scroll", onTop);

    return () => {
      clearTimeout(localDebounce);
      chatContainer.current && chatContainer.current.removeEventListener("scroll", onTop);
    };
  }, [chatContainer.current]);

  useEffect(() => {
    if (chat.logo) {
      const { fileNameWithExtension, secureHash } = chat.logo;
      Api.getAttachment(token, secureHash, fileNameWithExtension).then((b) => setAvatar(b));
    } else {
      setAvatar(null);
    }
    if (chat.userLogo) {
      const { fileNameWithExtension, secureHash } = chat.userLogo;
      Api.getAttachment(token, secureHash, fileNameWithExtension).then((b) => setUserAvatar(b));
    } else {
      setUserAvatar(null);
    }
  }, [assetId, userName, chat]);

  const delegateOnEnter = () => {
    sendButtonRef.current && sendButtonRef.current.click();
  };

  const sendMessage = async () => {
    if (!newMessage && !files.length) return;
    setWait(true);
    let attachments = {};
    if (files.length) {
      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append(`files`, files[i], files[i].name);
      }

      const upload = await Api.uploadChatFiles(token, formData);
      if (upload) {
        attachments = { ...upload };
      }
    }

    Api.sendMessageToInvestor(token, userName, newMessage, assetId, attachments).catch(() => {
      setWait(false);
    });
    setNewMessage("");
    setFiles([]);
    setWait(false);
  };

  return (
    <Card width="calc(100% - 352px)">
      {userName && assetId ? (
        <Fragment>
          <ChatHeader>
            <Flex>
              <ChatHeading>chat with</ChatHeading> <HeadingLink>{userName}</HeadingLink>{" "}
              <ChatHeading mLeft>last online: {moment(chat.lastUpdateDate).format("DD.MM.YYYY hh:mm A")}</ChatHeading>{" "}
            </Flex>
            <Flex>
              <PaperClipIcon />
              <HeadingLink>shared media</HeadingLink>
            </Flex>
          </ChatHeader>
          <DealHeader>
            <DealLogoCont>{avatar && <img src={avatar} alt="deal-logo" />}</DealLogoCont>
            <ColDeal>
              <ChatHeading>From</ChatHeading>
              <DealNameHeader>{chat.name}</DealNameHeader>
            </ColDeal>
            <RightIcon />
          </DealHeader>

          <MessagesCont ref={chatContainer}>
            {messages.map(({ id, message, date, sender, read, attachments }, i) =>
              sender !== userName ? (
                <Message
                  offAnimation
                  key={`message-id-${id}`}
                  message={message}
                  date={date}
                  read={read}
                  token={token}
                  attachments={attachments}
                  token={token}
                  messageId={id}
                />
              ) : (
                <UserMessage
                  offAnimation
                  key={`message-id-${id}`}
                  logo={userAvatar}
                  message={message}
                  date={date}
                  attachments={attachments}
                  token={token}
                  messageId={id}
                />
              )
            )}
          </MessagesCont>

          <SubmitBlock>
            <MessageInput
              placeholder="Write your message here"
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              onKeyDown={(e) => e.keyCode === 13 && delegateOnEnter()}
            />
            <ButtonsBlock>
              <IconContainer onClick={onChangeUploaderStatus} count={files.length}>
                <PaperClipIcon2 />
              </IconContainer>
              <Button width={36} height={36} onClick={sendMessage} ref={sendButtonRef} disabled={wait}>
                <SendIcon style={{ margin: 0 }} />
              </Button>
            </ButtonsBlock>
            {uploaderOpen ? (
              <AttachmentsUploader
                onBackdropClick={onChangeUploaderStatus}
                onSetFilesToMessage={(f) => setFiles(f)}
                filesList={files}
              />
            ) : null}
          </SubmitBlock>
        </Fragment>
      ) : (
        <NoChatSelected>Please select a chat to start messaging</NoChatSelected>
      )}
    </Card>
  );
};
