import React, { useRef, useEffect, useState, Fragment } from "react";
import { ContainerFlex, DocSelect, DocOptionContainer, DocInput } from "../containers/container";
import { DocOptionsChips, DocChips, DocChipsWrapper, GroupName } from "./styled";
import SearchIcon from "../../../assets/search-gray.svg";

import theme from "models/themes";

export default ({
  onChange,
  width = "100%",
  label = "",
  placeholder,
  value,
  values = [],
  hiddenLabel,
  multiple = false,
  defaultValue,
  view = "default",
  disabled,
  labelFsize,
  fSize,
  selectHeight,
  isInvestor = false,
  padding,
  borderColor,
  token,
}) => {
  const wrapperRef = useRef(null);
  const [top, setTop] = useState(65);
  const [_values, _setValues] = useState([...values]);
  const [filterWord, setFilterWord] = useState("");

  const [selectedValues, setSelectedValues] = useState(Array.isArray(defaultValue) ? [...defaultValue] : []);

  useEffect(() => {
    if (wrapperRef.current) {
      const { height } = wrapperRef.current.getBoundingClientRect();
      setTop(height - 2);
    }
  }, [selectedValues]);

  useEffect(() => {
    _setValues(
      values.map((e) => {
        if (e.label.toLowerCase().includes(filterWord.toLowerCase())) {
          return e;
        } else {
          return null;
        }
      })
    );
  }, [values]);

  const onChangeFilter = (e) => setFilterWord(e.target.value || "");

  useEffect(() => {
    _setValues(
      values.map((e) => {
        if (e.label.toLowerCase().includes(filterWord.toLowerCase())) {
          return e;
        } else {
          return null;
        }
      })
    );
  }, [filterWord]);

  const uniqueId = useRef(Math.random().toString(36).replace("0.", ""));
  const currentSelect = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const onToggle = (e) => {
    e.stopPropagation();
    setOpen(!isOpen);
    const event = new CustomEvent("open_new", { uniqueId });
    window.dispatchEvent(event);
  };

  useEffect(() => {
    function detectNewOpen(e) {
      if (e.uniqueId !== uniqueId.current && isOpen) {
        setOpen(false);
      }
    }

    function closeAll() {
      setOpen(false);
    }

    function _onChange(...e) {
      onChange && onChange(...e);
    }

    currentSelect.current && currentSelect.current.addEventListener("change", _onChange);

    window.addEventListener("open_new", detectNewOpen);
    window.addEventListener("click", closeAll);
    return () => {
      currentSelect.current && currentSelect.current.removeEventListener("change", _onChange);
      window.removeEventListener("open_new", detectNewOpen);
      window.removeEventListener("click", closeAll);
    };
  });

  const changeEmitMultiple = (val, selected) => (e) => {
    e.stopPropagation();
    if (selected) {
      setSelectedValues(selectedValues.filter((e) => e !== val));
    } else {
      setSelectedValues([...selectedValues, val]);
    }
  };

  useEffect(() => {
    multiple && onChange([...selectedValues]);
  }, [selectedValues]);

  const renderOptions = () => {
    return _values.map((value, i) => {
      if (value) {
        const { label, iconHash, iconName } = value;
        return (
          <DocOptionsChips
            key={`${label}-${i}`}
            selected={selectedValues.includes(i)}
            onClick={changeEmitMultiple(i, selectedValues.includes(i))}
            token={token}
            iconHash={iconHash}
            iconName={iconName}
          >
            <GroupName>{label}</GroupName>
          </DocOptionsChips>
        );
      }
      return <Fragment key={`empty-${i}`} />;
    });
  };

  return (
    <ContainerFlex w={width} overflow="visible" zIndex={isOpen ? 1001 : 0}>
      <DocChipsWrapper
        padding={"0px"}
        ref={wrapperRef}
        isInvestor={isInvestor}
        labelFsize={labelFsize}
        asSelect={multiple}
        arrow={`/assets/chevron-down${theme.greyFilter ? "-dark" : ""}.svg`}
        hidden={hiddenLabel}
        onClick={onToggle}
      >
        {selectedValues.length
          ? selectedValues.map((i) => {
              const selectedItem = values[i];
              return (
                <DocChips
                  key={`${label}-${i}`}
                  onClick={changeEmitMultiple(i, selectedValues.includes(i))}
                  token={token}
                  iconHash={selectedItem.iconHash}
                  iconName={selectedItem.iconName}
                >
                  {selectedItem.label}
                </DocChips>
              );
            })
          : label}
      </DocChipsWrapper>
      <DocSelect
        padding={padding}
        borderColor={borderColor}
        height={selectHeight}
        fSize={fSize}
        ref={currentSelect}
        multiple={multiple}
        hidden={multiple}
        arrow={`/assets/chevron-down${theme.greyFilter ? "-dark" : ""}.svg`}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        defaultValue={defaultValue}
        onClick={onToggle}
        disabled={disabled}
      />
      <DocOptionContainer
        borderColor={borderColor}
        show={isOpen}
        top={top}
        height={view === "filter" ? (_values.length ? "250px" : "fit-content") : ""}
        offScroll={view === "filter"}
      >
        {view === "filter" ? (
          <Fragment>
            <DocInput
              onClick={(e) => e.stopPropagation()}
              placeholder="Search by name"
              icon={SearchIcon}
              width="100%"
              bgPos="left"
              leftPos="15px"
              padding="0 0 0 45px"
              margin="0 0 10px 0"
              onInput={onChangeFilter}
              value={filterWord}
            />
          </Fragment>
        ) : null}
        {view === "filter" ? (
          <DocOptionContainer paddingRight="0" show={isOpen} maxHeight="180px" offBorder full={false}>
            {renderOptions()}
          </DocOptionContainer>
        ) : (
          renderOptions()
        )}
      </DocOptionContainer>
    </ContainerFlex>
  );
};
