import React, { useState } from "react";

import {
  SendEmailWrapper,
  ModalBackground,
  ModalHeader,
  InputsWrapper,
  Heading,
  Separator,
  WaitingWrapper
} from "./styled";
import { Btn } from "../../buttons";
import { ReactComponent as CloseIcon } from "../../../../assets/x-circle.svg";
import { TextFieldComponent, TextAreaComponent } from "../../forms";
import { SendEmailMessage } from "../../actionMessage";
import Api from "../../../../helpers/api";
import { CircularProgress } from "@material-ui/core";

export const SendEmail = ({
  token,
  isOpen,
  onBackdropClick,
  mailData: { userName, userEmail }
}) => {
  const [waitingResponse, setWaitingResponse] = useState(false);

  const [message, setMessage] = useState("");
  const [subject, setSubject] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [error, setError] = useState(false);

  const sendEmailToUser = () => {
    setWaitingResponse(true);
    Api.sendEmail(token, userEmail, subject, message)
      .then(text => console.log(text))
      .then(() => {
        error && setError(false);
        setWaitingResponse(false);
        setModalMessage("Your message has been successfully sent.");
        setModalOpen(true);
      })
      .catch(e => {
        console.log(e);
        setWaitingResponse(false);
        setError(true);
        setModalMessage("Please try again or refresh page");
        setModalOpen(true);
      });
  };

  const onMessageInput = e => setMessage(e.target.value);
  const onSubjectInput = e => setSubject(e.target.value);

  const clearDataOnClose = () => {
    setModalMessage("");
    setModalOpen(false);
    setMessage("");
    setSubject("");
    setError(false);
    onBackdropClick();
  };

  return isOpen ? (
    <ModalBackground onClick={clearDataOnClose}>
      {modalOpen ? (
        <SendEmailMessage
          isOpen={modalOpen}
          onBackDpopClick={clearDataOnClose}
          customMessage={modalMessage}
          isError={error}
        />
      ) : (
        <SendEmailWrapper onClick={e => e.stopPropagation()}>
          {waitingResponse && (
            <WaitingWrapper>
              <CircularProgress />
            </WaitingWrapper>
          )}
          <ModalHeader>
            <Heading>Send E-mail to {userName}</Heading>
            <CloseIcon onClick={onBackdropClick} />
          </ModalHeader>
          <InputsWrapper padding="15px 25px">
            <TextFieldComponent
              label="User"
              placeholder="Name"
              defaultValue={userName}
              disabled
            />
            <Separator height={25} />
            <TextFieldComponent
              label="Subject"
              placeholder="Subject of e-mail here"
              defaultValue={subject}
              onChange={onSubjectInput}
            />
            <Separator height={20} />
            <TextAreaComponent
              fSize={12}
              label="Message"
              placeholder="Write your message to user here"
              onChange={onMessageInput}
              defaultValue={message}
            />
          </InputsWrapper>
          <ModalHeader isFooter>
            <Btn onClick={onBackdropClick} hide width={94}>
              Cancel
            </Btn>
            <Btn width={180} onClick={sendEmailToUser} disabled={!message}>
              Send e-mail
            </Btn>
          </ModalHeader>
        </SendEmailWrapper>
      )}
    </ModalBackground>
  ) : null;
};
