import styled from "styled-components";
import React from "react";
import { CircularProgress } from "@material-ui/core";

const Bg = styled.section`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.15);
  z-index: ${({ zIndex }) => zIndex};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Loader = ({ zIndex }) => (
  <Bg zIndex={zIndex}>
    <CircularProgress />
  </Bg>
);

const WaitContainer = styled.div`
  height: calc(100vh - 225px);
  display: flex;
  > div {
    margin: auto;
  }
`;

export const LoaderWrapper = () => (
  <WaitContainer>
    <CircularProgress />
  </WaitContainer>
);
