import styled from "styled-components";
import React from "react";

import { ReactComponent as Clock } from "../../../../assets/clock-i.svg";
import { ReactComponent as InfoIcon } from "../../../../assets/info.svg";

export const Label = styled.span`
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 135%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: rgba(24, 24, 24, 0.6);
  margin-bottom: 8px;
`;

export const InfoBlock = styled.div`
  height: 70px;
  width: 100%;
  background: ${({ theme: { disabledField } }) => disabledField};
  border: 1px solid #efefef;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 15px 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const TextInfo = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  color: ${({ grey }) => (grey ? "#6A6A6A" : "#333333")};
`;

export const Link = styled.span`
  cursor: pointer;
  font-family: Muli;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: ${({ theme: { primary } }) => primary};
`;

const Btn = styled.div`
  width: 125px;
  height: 32px;
  background: #c6c6c6;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 150%;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  > svg {
    margin-right: 8px;
  }
`;

export const Status = () => (
  <Btn>
    <Clock /> Pending
  </Btn>
);

const InfoBtn = styled.div`
  cursor: pointer;
  width: 32px;
  height: 32px;
  background: ${({ theme: { infoButtonBg } }) => infoButtonBg};
  > svg {
    path {
      stroke: ${({ theme: { primary } }) => primary};
    }
  }
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MoreInfo = ({ onClick }) => (
  <InfoBtn onClick={onClick}>
    <InfoIcon />
  </InfoBtn>
);
