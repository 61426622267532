import React, { useState, useEffect, Fragment } from "react";
import moment from "moment";
import { CircularProgress } from "@material-ui/core";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import { connect } from "react-redux";
import { set2Fa } from "../../../../modules/userRedux/userAction";

import {
  InfoCard,
  Col,
  InputsRow,
  Loading,
  BtnRow,
  BorderBlock,
  Param,
  Avatar,
  AddressLabel
} from "./styled";
import {
  TextFieldComponent,
  SwitcherComponent,
  InfoBlockComponent,
  SelectComponent
} from "../../../admin-layout/forms";
import { Button } from "../Offerings/styled";
import { Date } from "../Transactions/styled";
import { ChangePassword } from "./ChangePassword";
import { InfoMessage } from "../../styled/infoMessages";

import Api from "../../../../helpers/api";
import QuestionnaryApi from "../Questionnaires/api";
import { useDebounceState } from "helpers/customHook";
import { PhoneInputs } from "components/auhtForms/questionaries/styled";

export const _Profile = ({
  token,
  updateUserName,
  reLogin,
  username,
  countriesOption,
  investorType,
  userId
}) => {
  const [edit, setEdit] = useState(false);
  const [editAddress, setEditAddress] = useState(false);
  const [instEdit, setInstEdit] = useState(false);
  const [load, setLoad] = useState(false);
  const [userInfo, setInfo] = useState(null);
  const [changePassOpen, setChangePassOpen] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [local_2fa, setLocal_2fa] = useState(null);
  const [additionalInfo, setAdditionalInfo] = useDebounceState(null);
  const [instInfo, setInstInfo] = useDebounceState(
    investorType === "INSTITUTION" ? null : []
  );
  const [otherMail, setOtherMail] = useState(false);
  const [instBlob, setInstBlob] = useState(null);
  const [smallScreen, setSmallScreen] = useState(window.innerWidth < 1149);

  useEffect(() => {
    const handleResize = () => {
      setSmallScreen(window.innerWidth < 1149);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const changeModalStatus = () => setChangePassOpen(!changePassOpen);

  const change2FaStatus = async () => {
    setLoad(true);
    const result = await Api.update2FA(token, !local_2fa);
    if (result) {
      setShowMessage(true);
      setLocal_2fa(!local_2fa);
      set2Fa(!local_2fa);
    }
    setLoad(false);
  };

  const onEndNotification = () => setShowMessage(false);

  useEffect(() => {
    if (!userInfo) {
      Api.getUserData(token).then(info => {
        setLocal_2fa(info.use2fa);
        setInfo(info);

        set2Fa(info.use2fa);
      });
      QuestionnaryApi.getAnswers(token).then(form => {
        try {
          const info = JSON.parse(form.answerJson);
          setAdditionalInfo(info);
        } catch (_) {
          setAdditionalInfo([]);
        }
      });
      if (investorType === "INSTITUTION") {
        Api.getInstitutionById(token, userId, true)
          .then(info => {
            try {
              setInstInfo(info);
              if (info.mailingAddress) setOtherMail(true);
              Api.getAttachment(
                token,
                info.logoHash,
                info.logoFileName,
                true
              ).then(b => setInstBlob(b));
            } catch (_) {
              setInstInfo([]);
            }
          })
          .catch(_ => setInstInfo([]));
      }
    }
  }, []);

  const editUserData = () => {
    setEdit(!edit);
    if (edit) {
      setLoad(true);
      Api.changeUserData(token, userInfo)
        .then(_ => {
          updateUserName(userInfo.fullName);
          setLoad(false);
        })
        .catch(() => setLoad(false));
    }
  };

  const editUserAddress = () => {
    setEditAddress(!editAddress);
    if (editAddress) {
      setLoad(true);

      QuestionnaryApi.changeUserAnswer(token, additionalInfo)
        .then(_ => {
          setLoad(false);
        })
        .catch(() => setLoad(false));
    }
  };

  const editInstData = () => {
    setInstEdit(!instEdit);
    if (instEdit) {
      setLoad(true);
      const _data = {
        ...instInfo,
        countryRegistration: instInfo.countryOfRegistration
      };
      delete _data["countryOfRegistration"];
      if (!otherMail) _data.mailingAddress = null;

      Api.updateInstitution(token, instInfo.id, _data, true)
        .then(_ => {
          setLoad(false);
        })
        .catch(() => setLoad(false));
    }
  };

  const onDataChange = (e, name) => {
    setInfo({
      ...userInfo,
      [name]: e.target ? e.target.value : e
    });
  };

  const onAdditionDataChange = (e, index) => {
    const _info = [...additionalInfo];
    _info[0].fields[index].value = e.target.value;
    setAdditionalInfo([..._info]);
  };

  const onInstDataChange = (e, name) => {
    setInstInfo({
      ...instInfo,
      [name]: e.target ? e.target.value : e
    });
  };

  const onInstPhoneChange = (phone, code) => {
    setInstInfo({
      ...instInfo,
      phone: "+" + code + phone.replace(code, "")
    });
  };

  const onChangeField = (e, key1, key2) => {
    const _form = { ...instInfo };
    const val = e.target ? e.target.value : e;
    _form[key1] = { ..._form[key1], [key2]: val };
    setInstInfo(_form);
  };

  const onChangeMailing = val => () => setOtherMail(val);

  useEffect(() => {
    console.log(instInfo);
  }, [instInfo]);

  if (userInfo && additionalInfo && instInfo && local_2fa !== null) {
    const additionalArr = additionalInfo.length ? additionalInfo[0].fields : {};
    return (
      <Col width="100%">
        {load && <Loading />}
        <InfoCard heading="Profile Details" margin="0 0 20px 0">
          <InputsRow>
            <TextFieldComponent
              labelFontSize={10}
              fontSize={14}
              height={42}
              isInvestor
              label="Full name"
              width="calc(33% - 17px)"
              defaultValue={userInfo.fullName}
              onChange={e => onDataChange(e, "fullName")}
              disabled={!edit}
              edit={edit}
            />
            <TextFieldComponent
              labelFontSize={10}
              fontSize={14}
              height={42}
              isInvestor
              label="Login"
              defaultValue={userInfo.username}
              width="calc(33% - 17px)"
              disabled
            />
            <TextFieldComponent
              labelFontSize={10}
              fontSize={14}
              height={42}
              isInvestor
              label="Role"
              defaultValue={userInfo.roleName}
              width="calc(33% - 17px)"
              disabled
            />
          </InputsRow>
          <InputsRow mb="20px">
            <TextFieldComponent
              labelFontSize={10}
              fontSize={14}
              height={42}
              isInvestor
              defaultValue={userInfo.username}
              onChange={e => onDataChange(e, "username")}
              label="e-mail (User name)"
              width="calc(33% - 17px)"
              disabled
            />
            <PhoneInputs width="calc(33% - 17px)" fSize={14}>
              <PhoneInput
                disabled
                value={userInfo.telephoneNumber}
                placeholder="Telephone Number"
                inputClass="phone-input"
                buttonClass="phone-flag"
                specialLabel="Telephone Number"
              />
            </PhoneInputs>
            <SelectComponent
              labelFsize={10}
              fSize={14}
              height={42}
              isInvestor
              label="Country of residence"
              disabled={!edit}
              edit={edit}
              width="calc(33% - 17px)"
              value={userInfo.countryOfResidence}
              onChange={e => onDataChange(e, "countryOfResidence")}
              top={60}
              values={countriesOption}
            />
          </InputsRow>
          <Button
            onClick={editUserData}
            size={14}
            width={302}
            height={42}
            transparent={!edit}
            responsive="calc(33% - 17px)"
          >
            {edit ? "Save Changes" : "Edit Profile"}
          </Button>
        </InfoCard>
        {/* TODO: onChange logic here */}
        {investorType === "INSTITUTION" && Object.keys(instInfo).length ? (
          <InfoCard heading="Institution Details" margin="0 0 20px 0">
            <InputsRow>
              <Avatar
                updateAvatar={Api.changeLogoInstitution}
                instId={instInfo.id}
                token={token}
                blob={instBlob}
                noAbsolute
                isInstitution
                disabled={!instEdit}
              />
            </InputsRow>
            <InputsRow mb={instBlob ? "20px" : ""}>
              <TextFieldComponent
                labelFontSize={10}
                fontSize={14}
                height={42}
                isInvestor
                label="Institution name"
                width="calc(33% - 17px)"
                defaultValue={instInfo.name}
                onChange={e => onInstDataChange(e, "name")}
                disabled={!instEdit}
                edit={instEdit}
              />
              <PhoneInputs width="calc(33% - 17px)" fSize={14}>
                <PhoneInput
                  disabled={!instEdit}
                  value={instInfo.phone}
                  placeholder="Phone"
                  inputClass="phone-input"
                  buttonClass="phone-flag"
                  specialLabel="Phone"
                  onChange={(phone, { dialCode }) =>
                    onInstPhoneChange(phone, dialCode)
                  }
                />
              </PhoneInputs>
              <SelectComponent
                labelFsize={10}
                fSize={14}
                height={42}
                isInvestor
                label="Country of registration"
                disabled={!instEdit}
                edit={instEdit}
                width="calc(33% - 17px)"
                value={instInfo.countryOfRegistration}
                onChange={e => onInstDataChange(e, "countryOfRegistration")}
                top={60}
                values={countriesOption}
              />
            </InputsRow>
            <InputsRow mb="20px">
              <TextFieldComponent
                labelFontSize={10}
                fontSize={14}
                height={42}
                isInvestor
                label="Website"
                width="calc(50% - 12.5px)"
                defaultValue={instInfo.webSiteUrl}
                onChange={e => onInstDataChange(e, "webSiteUrl")}
                disabled={!instEdit}
                edit={instEdit}
              />
            </InputsRow>
            <AddressLabel>Registered Address</AddressLabel>

            <InputsRow mb="20px">
              <TextFieldComponent
                labelFontSize={10}
                fontSize={14}
                height={42}
                isInvestor
                label="Address"
                placeholder="Registered address"
                width="calc(25% - 18.75px)"
                defaultValue={
                  instInfo.address ? instInfo.address.permanentAddress : ""
                }
                onChange={e => onChangeField(e, "address", "permanentAddress")}
                disabled={!instEdit}
                edit={instEdit}
              />
              <TextFieldComponent
                labelFontSize={10}
                fontSize={14}
                height={42}
                isInvestor
                label="Zip code"
                placeholder="Zip code"
                width="calc(25% - 18.75px)"
                defaultValue={instInfo.address ? instInfo.address.zipCode : ""}
                onChange={e => onChangeField(e, "address", "zipCode")}
                disabled={!instEdit}
                edit={instEdit}
              />
              <TextFieldComponent
                labelFontSize={10}
                fontSize={14}
                height={42}
                isInvestor
                label="City or town, state or province"
                placeholder="City/town or state/province"
                width="calc(25% - 18.75px)"
                defaultValue={instInfo.address ? instInfo.address.city : ""}
                onChange={e => onChangeField(e, "address", "city")}
                disabled={!instEdit}
                edit={instEdit}
              />
              <SelectComponent
                labelFsize={10}
                fSize={14}
                height={42}
                isInvestor
                label="Country"
                placeholder="Country"
                disabled={!instEdit}
                edit={instEdit}
                width="calc(25% - 18.75px)"
                defaultValue={
                  instInfo.address ? instInfo.address.country : null
                }
                onChange={e => onChangeField(e, "address", "country")}
                top={60}
                values={countriesOption}
              />
            </InputsRow>
            <AddressLabel>Mailing Address</AddressLabel>
            <InputsRow mb="20px">
              <BtnRow width="calc(50% - 12.5px)">
                <BorderBlock
                  disabled={!instEdit}
                  active={!otherMail}
                  width="calc(50% - 12.5px)"
                  onClick={onChangeMailing(false)}
                >
                  <Param active={!otherMail} disabled={!instEdit}>
                    Same as Registered
                  </Param>
                </BorderBlock>
                <BorderBlock
                  disabled={!instEdit}
                  active={otherMail}
                  width="calc(50% - 12.5px)"
                  onClick={onChangeMailing(true)}
                >
                  <Param active={otherMail} disabled={!instEdit}>
                    Other {smallScreen ? "" : "Mailing"} Address
                  </Param>
                </BorderBlock>
              </BtnRow>
            </InputsRow>
            {otherMail ? (
              <Fragment>
                <InputsRow mb="20px">
                  <TextFieldComponent
                    labelFontSize={10}
                    fontSize={14}
                    height={42}
                    isInvestor
                    label="Address"
                    placeholder="Registered address"
                    width="calc(25% - 18.75px)"
                    defaultValue={
                      instInfo.mailingAddress
                        ? instInfo.mailingAddress.permanentAddress
                        : ""
                    }
                    onChange={e =>
                      onChangeField(e, "mailingAddress", "permanentAddress")
                    }
                    disabled={!instEdit}
                    edit={instEdit}
                  />
                  <TextFieldComponent
                    labelFontSize={10}
                    fontSize={14}
                    height={42}
                    isInvestor
                    label="Zip code"
                    placeholder="Zip code"
                    width="calc(25% - 18.75px)"
                    defaultValue={
                      instInfo.mailingAddress
                        ? instInfo.mailingAddress.zipCode
                        : ""
                    }
                    onChange={e =>
                      onChangeField(e, "mailingAddress", "zipCode")
                    }
                    disabled={!instEdit}
                    edit={instEdit}
                  />
                  <TextFieldComponent
                    labelFontSize={10}
                    fontSize={14}
                    height={42}
                    isInvestor
                    label="City or town, state or province"
                    placeholder="City/town or state/province"
                    width="calc(25% - 18.75px)"
                    defaultValue={
                      instInfo.mailingAddress
                        ? instInfo.mailingAddress.city
                        : ""
                    }
                    onChange={e => onChangeField(e, "mailingAddress", "city")}
                    disabled={!instEdit}
                    edit={instEdit}
                  />
                  <SelectComponent
                    labelFsize={10}
                    fSize={14}
                    height={42}
                    isInvestor
                    label="Country"
                    placeholder="Country"
                    disabled={!instEdit}
                    edit={instEdit}
                    width="calc(25% - 18.75px)"
                    defaultValue={
                      instInfo.mailingAddress
                        ? instInfo.mailingAddress.country
                        : null
                    }
                    onChange={e =>
                      onChangeField(e, "mailingAddress", "country")
                    }
                    top={60}
                    values={countriesOption}
                  />
                </InputsRow>
              </Fragment>
            ) : null}
            <Button
              onClick={editInstData}
              size={14}
              width={302}
              height={42}
              transparent={!instEdit}
              responsive="calc(33% - 17px)"
            >
              {instEdit ? "Save Changes" : "Edit Institution"}
            </Button>
          </InfoCard>
        ) : null}

        {additionalInfo.length && Object.keys(additionalInfo[0]).length ? (
          <InfoCard heading="Address" margin="0 0 20px 0">
            <InputsRow>
              <TextFieldComponent
                labelFontSize={10}
                fontSize={14}
                height={42}
                isInvestor
                label="Address"
                defaultValue={additionalArr[0].value}
                onChange={e => onAdditionDataChange(e, 0)}
                disabled={!editAddress}
                edit={editAddress}
              />
            </InputsRow>
            <InputsRow mb="20px">
              <TextFieldComponent
                width={"calc(50% - 15px)"}
                labelFontSize={10}
                fontSize={14}
                height={42}
                isInvestor
                label="CITY"
                defaultValue={additionalArr[2].value}
                onChange={e => onAdditionDataChange(e, 2)}
                disabled={!editAddress}
                edit={editAddress}
              />
              <TextFieldComponent
                width={"calc(50% - 15px)"}
                labelFontSize={10}
                fontSize={14}
                height={42}
                isInvestor
                label="POSTCODE"
                defaultValue={additionalArr[1].value}
                onChange={e => onAdditionDataChange(e, 1)}
                disabled={!editAddress}
                edit={editAddress}
              />
            </InputsRow>
            <Button
              onClick={editUserAddress}
              size={14}
              width={302}
              height={42}
              transparent={!editAddress}
              responsive="calc(33% - 17px)"
            >
              {editAddress ? "Save Changes" : "Edit Address"}
            </Button>
          </InfoCard>
        ) : null}
        <InfoCard heading="Security">
          <InputsRow>
            <InfoBlockComponent
              isInvestor
              labelFontSize={10}
              label="Last login information"
              width="calc(100% - 332px)"
              children={
                <Fragment>
                  <Date margin="0 5px 0 0">
                    {moment(userInfo.lastLoginDate).format("dddd, DD/MM/YYYY")}
                  </Date>
                  <Date light>
                    {moment(userInfo.lastLoginDate).format("hh:mm:ss A")}
                  </Date>{" "}
                </Fragment>
              }
            />
            <Button
              size={14}
              width={302}
              height={42}
              transparent
              margin="auto 0 0"
              onClick={changeModalStatus}
            >
              Change My Password
            </Button>
          </InputsRow>
          <InputsRow>
            <SwitcherComponent
              isInvestor
              labelFsize={10}
              fSize={14}
              height={42}
              textMargin="0 auto 0 16px"
              label="2-step authentication"
              width="calc(100% - 332px)"
              name="Switch On 2FA"
              defaultValue={local_2fa}
              onChange={change2FaStatus}
            />
          </InputsRow>
        </InfoCard>

        <ChangePassword
          isOpen={changePassOpen}
          onBackDropClick={changeModalStatus}
          token={token}
          reLogin={reLogin}
          username={username}
        />
        <InfoMessage
          onEnd={onEndNotification}
          showMessage={showMessage}
          heading="2-STEP AUTHENTICATION"
          text={
            local_2fa ? "QR-code for 2fa send to e-mail." : "2fa turned off"
          }
        />
      </Col>
    );
  } else {
    return <CircularProgress style={{ margin: "auto" }} />;
  }
};

export const Profile = connect(null, { set2Fa })(_Profile);
