import React, { Fragment } from "react";

import {
  ConfBackground,
  ModalWrap,
  ConfHeader,
  HeadingModal,
  SelectorBody,
  ScrollContainer,
  Description
} from "../QAAsset/styled";
import { ReactComponent as CloseIcon } from "../../../../assets/x-circle.svg";
import { Folder } from "./folderComponent";

export const FileSelector = ({
  onBackdropClick,
  file,
  data,
  assetId,
  setSelectedFile
}) => {
  const renderFolders = () => {
    const random = Math.round(Math.random() * 1000) + "";
    return (
      <Fragment key={`folder-${random}`}>
        {data[assetId].folders.map((e, i) => (
          <Folder
            _file={file}
            folder={e}
            key={`${e.name}-${i}-${random}`}
            onBackdropClick={onBackdropClick}
            setSelectedFile={setSelectedFile}
          />
        ))}
      </Fragment>
    );
  };

  return (
    <ConfBackground onClick={onBackdropClick}>
      <ModalWrap
        onClick={e => {
          e.stopPropagation();
        }}
      >
        <ConfHeader isInvestor>
          <HeadingModal small>Link a File to Question</HeadingModal>
          <CloseIcon onClick={onBackdropClick} />
        </ConfHeader>
        <SelectorBody>
          <Description>Description</Description>
          <ScrollContainer height={420}>{renderFolders()}</ScrollContainer>
        </SelectorBody>
      </ModalWrap>
    </ConfBackground>
  );
};
