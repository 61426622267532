import React, { useState, useEffect } from "react";

import { ReactComponent as CloseIcon } from "../../../../assets/x-circle.svg";
import { ModalBackground, ModalWrapper, ModalHeader, ModalHeading, Col, Row } from "../Investment/styled";
import { Button } from "../Offerings/styled";
import { WaitingModal } from "./styled";
import { CircularProgress } from "@material-ui/core";

import Api from "../../../../helpers/api";

export const SignatureType = ({
  isOpen,
  onBackDropClick,
  onSuccessResponse,
  token,
  assetId,
  currency,
  amount,
  type,
  activeAccount,
  signToolOptions = [],
}) => {
  useEffect(() => {
    if (isOpen && signToolOptions.length === 1) {
      onSubmit();
    }
  });

  const [load, setLoad] = useState(false);

  const onSubmit = () => {
    setLoad(true);
    Api.buyTokenAgreement(
      null,
      token,
      assetId,
      currency,
      amount,
      null,
      type === "cash" ? "online" : type,
      activeAccount
    )

      .then((json) => {
        setLoad(false);
        onSuccessResponse(json.redirectUrl);
        onBackDropClick();
      })
      .catch(() => {
        setLoad(false);
        onBackDropClick();
      });
  };

  return (
    isOpen && (
      <ModalBackground onClick={!load && onBackDropClick}>
        <ModalWrapper onClick={(e) => e.stopPropagation()}>
          {load && (
            <WaitingModal>
              <CircularProgress />
            </WaitingModal>
          )}
          <ModalHeader boxShadow="0px 10px 40px rgba(89, 120, 150, 0.08)">
            <ModalHeading>Choose signature type</ModalHeading>
            <CloseIcon onClick={onBackDropClick} />
          </ModalHeader>
          <Col padding="25px 30px" align="center">
            <Row width="100%" justify="space-between">
              <Button width={176} height={36} size={12} disabled={!signToolOptions.includes("INTERNAL_SIGN")}>
                Digital signature
              </Button>
              <Button
                width={176}
                height={36}
                size={12}
                onClick={onSubmit}
                disabled={!signToolOptions.includes("DOCUSIGN")}
              >
                DocuSign
              </Button>
            </Row>
          </Col>
        </ModalWrapper>
      </ModalBackground>
    )
  );
};
