import styled from "styled-components";

export const Header = styled.div`
  width: 100%;
  height: ${({ height = 60 }) => height}px;
  border-radius: 4px 0px 4px 4px;
  background: white;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: ${({ padding }) => (padding ? padding : "0 25px")};

  ${({ isFooter = false }) => {
    if (isFooter) return `margin-top: 30px;`;
    else return `margin-bottom: 30px;`;
  }}
  ${({ between = false }) => (between ? "justify-content: space-between;" : "")}
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const InputRow = styled.div`
  margin-bottom: ${({ last }) => (last ? "0" : "20px")};
  display: flex;
  align-items: center;
  width: ${({ width }) => (width ? width : "100%")};
  justify-content: space-between;
  box-sizing: border-box;
  ${({ wrap = false }) => (wrap ? "flex-wrap: wrap;" : "")}
`;

export const TextH3 = styled.h3`
  text-align: ${({ textAlign = "center" }) => textAlign};
  font-family: "Muli", sans-serif;
  font-size: ${({ size }) => (size ? `${size}px` : "14px")};
  font-weight: bold;
  ${({ marginSize = "" }) => (marginSize ? `margin: ${marginSize};` : "")}
`;
