import React, { useState, useEffect } from "react";

import { Card, Heading, BorderCont, Row, Text, Method, CurrencyBlock, TEXT_FIELD_SIZE } from "./styled.js";
import { TextFieldComponentPlaceholder } from "../../../admin-layout/forms";
import { ReactComponent as InfoIcon } from "../../../../assets/alert-circle.svg";
import { ReactComponent as RepeatIcon } from "../../../../assets/repeat.svg";
import { numberWithCommas } from "helpers/index.js";

export const Amount = ({
  type,
  onChangeType,
  cashBalance,
  asset: {
    intentionToBuy,
    assetProperties: { assetSymbol },
    assetFinancialProperty: { minInvestment, valuation, baseCurrency },
  },
}) => {
  const _onChangeType = (type) => () => {
    onChangeType(type);
  };

  return (
    <Card noPadding>
      <BorderCont>
        <Heading margin="0 0 20px 0">Amount of Shares</Heading>
        <Row padding="0" margin="0 0 14px 0">
          <TextFieldComponentPlaceholder
            {...TEXT_FIELD_SIZE}
            label="Shares to Buy"
            width="250px"
            pseudo
            pseudoText={assetSymbol}
            pseudoSize={12}
            isInvestor
            padding="0 70px 0 20px"
            defaultValue={numberWithCommas(intentionToBuy)}
            disabled
            after
            afterText="AS IN LOI"
          />
          <RepeatIcon style={{ margin: "25px 35px 0" }} />
          <TextFieldComponentPlaceholder
            {...TEXT_FIELD_SIZE}
            label="AMOUNT to pay"
            width="135px"
            defaultValue={numberWithCommas((intentionToBuy * valuation).toFixed(2))}
            disabled
            isInvestor
            margin="0 10px 0 0"
          />
          <CurrencyBlock>{baseCurrency}</CurrencyBlock>
        </Row>
        <Row padding="0">
          <InfoIcon />
          <Text margin="0 0 0 13px" fSize={12} color="#7C7C7C">
            The minimum amount of shares is {numberWithCommas(minInvestment)}
          </Text>
        </Row>
      </BorderCont>
      <BorderCont>
        <Heading margin="0 0 18px 0">Payment Method</Heading>
        <Row>
          <Method type="Bank Transfer" active={type === "bank"} onChangeType={_onChangeType("bank")} />
          {/* {cashBalance[baseCurrency] ? (
            <Method
              type="Currency Account"
              active={type === "cash"}
              onChangeType={_onChangeType("cash")}
              cashType
              cashBalance={cashBalance[baseCurrency]}
              currency={baseCurrency}
            />
          ) : null} */}
        </Row>
      </BorderCont>
      <Row padding="20px 30px">
        <Text fSize={18}>
          Total shares:{" "}
          <span>
            {numberWithCommas(intentionToBuy)} {assetSymbol}
          </span>
        </Text>
      </Row>
    </Card>
  );
};
