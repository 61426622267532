import React from "react";
import styled, { css } from "styled-components";
import { scroll } from "components/common/mixins";

import logo from "assets/logos/VALK.png";

const Flex = styled.div`
  display: flex;
  box-sizing: border-box;
`;

export const Col = styled(Flex)`
  width: ${({ w = "auto" }) => w};
  flex-direction: column;
  > .side-buttons {
    display: flex;
  }
  @media (max-width: 1280px) {
    > .side-buttons {
      display: none;
    }
  }
`;

export const Row = styled(Flex)`
  width: ${({ w = "100%" }) => w};
  margin: ${({ m = 0 }) => m};
  justify-content: ${({ j = "space-between" }) => j};
  align-items: ${({ a = "center" }) => a};
  position: relative;
  > .side-buttons {
    display: none;
  }
  @media (max-width: 1280px) {
    > .side-buttons {
      display: flex;
    }
  }
`;

const card = css`
  background: #ffffff;
  border-radius: 4px;
`;

const dots = css`
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const center = css`
  align-items: center;
`;

const iconButton = css`
  font-size: 12px;
  line-height: 135%;
  letter-spacing: -0.02em;
  color: #333333;
  padding: 0 15px;
  > svg {
    margin-right: 10px;
  }
`;

export const MainCol = styled(Col)`
  ${center};
  width: 676px;
`;

export const SideCol = styled(Col)`
  width: calc(50% - 338px);
  ${({ left }) => (left ? `padding-right: 30px;align-items: flex-end;` : `padding-left: 30px;`)};
`;

export const UserName = styled(Flex)`
  ${card};
  ${center};
  ${iconButton};
  width: 205px;
  height: 36px;
  > span {
    width: 160px;
    ${dots};
  }
`;

const LogoBlock = styled(Col)`
  width: 170px;
  margin: auto;
  > img {
    height: 40px;
    margin-bottom: 24px;
  }
`;

const PageDesc = styled.p`
  margin: 0;
  font-size: 14px;
  line-height: 145%;
  text-align: center;
  letter-spacing: -0.02em;
  color: rgba(51, 51, 51, 0.9);
`;

export const Card = styled(Col)`
  ${card};
  width: 100%;
  padding: 25px 30px 30px;
  margin-bottom: 25px;
`;

export const CardName = styled.h3`
  font-size: 14px;
  line-height: 135%;
  color: #181818;
  margin: ${({ m = "0 0 25px" }) => m};
`;

export const PageLogo = ({ description }) => (
  <LogoBlock>
    <img alt="logo" src={logo} />
    <PageDesc>{description}</PageDesc>
  </LogoBlock>
);

export const DomainPreview = styled(Flex)`
  ${card};
  ${center};
  ${iconButton};
  height: 42px;
  background: ${({ theme: { emptyCardBg } }) => emptyCardBg};
  width: 100%;
  > span {
    width: calc(100% - 56px);
    ${dots};
    .valk-domain {
      opacity: 0.5;
    }
  }
`;

export const BlueBg = styled(Flex)`
  ${card};
  background: ${({ theme: { quoteBgInvestor } }) => quoteBgInvestor};
  width: 100%;
  height: 82px;
  margin-bottom: 10px;
`;

export const Text = styled.p`
  margin: ${({ m = 0 }) => m};
  font-size: ${({ small }) => (small ? "12px" : "14px")};
  line-height: 135%;
  letter-spacing: -0.02em;
  color: ${({ small }) => (small ? "rgba(24, 24, 24, 0.4)" : "#333333")}; ;
`;

export const TypeContainer = styled.div`
  border: 1px solid ${({ theme: { primary } }) => primary};
  box-sizing: border-box;
  border-radius: 4px;
  transition: 0.15s all linear;
  width: 100%;
  height: 42px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  background: ${({ active, theme: { primary } }) => (active ? primary : "#fff")};
  cursor: pointer;
`;

export const Circle = styled.div`
  width: 18px;
  height: 18px;
  border: 1px solid ${({ active, theme: { primary } }) => (!active ? primary : "#fff")};
  border-radius: 50%;
  position: relative;
  margin-right: 15px;
  &::after {
    content: "";
    top: 3px;
    left: 3px;
    position: absolute;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: #ffffff;
  }
`;

export const Type = styled.div`
  font-weight: bold;
  font-size: 12px;
  line-height: 135%;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: ${({ active, theme: { primary } }) => (!active ? primary : "#fff")};
`;

export const Contract = styled.div`
  height: 400px;
  background: #fbfbfd;
  border-radius: 4px;
  padding: 25px 30px 25px 35px;
  box-sizing: border-box;
  position: relative;
  &::after {
    content: "";
    position: absolute;
    width: calc(100% - 80px);
    height: 100px;
    bottom: 25px;
    left: 30px;
    background: linear-gradient(to top, #fbfbfd, rgba(255, 255, 255, 0));
  }
`;

export const Scroll = styled.div`
  ${scroll};
`;
