import styled from "styled-components";
import { Link } from "react-router-dom";

export const StatisticsWrapper = styled.section`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const Card = styled.div`
  width: ${({ width }) => width};
  height: 60px;
  background: #ffffff;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: ${({ p = 0 }) => p};
  box-sizing: border-box;
  margin-bottom: 25px;
`;

export const RouteLink = styled(Link)`
  font-family: Muli;
  position: relative;
  white-space: nowrap;
  padding-bottom: 0;
  padding-top: 0;
  width: ${({ width, fullWidth, _width }) => (width ? `${width}px` : fullWidth ? "100%" : _width ? _width : "94px")};
  border: 1px solid
    ${({ black, hide, error, green, theme: { primary, red } }) =>
      black ? "#20263C" : hide ? "#CACACA" : error ? red : green ? "#55B17C" : primary};
  height: ${({ height }) => (height ? `${height}px` : "32px")};
  background: ${({ black, white, hide, transparent, error, green, theme: { primary } }) =>
    black ? "#20263C" : white || hide ? "#fff" : transparent || error || green ? "transparent" : primary};
  color: ${({ white, hide, transparent, error, green, theme: { primary, red } }) =>
    white || transparent ? primary : hide ? "#CACACA" : error ? red : green ? "#55B17C" : "#fff"};
  font-size: 10px;
  line-height: 135%;
  text-align: center;
  font-weight: bold;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  border-radius: 4px;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: ${({ margin }) => (margin ? margin : "0")};
  transition: all 0.3s linear;
  text-decoration: none;
  &:disabled {
    background: #e3e3e3 !important;
    color: white !important;
    border: none;
    > svg {
      path {
        stroke: #fff;
      }
    }
  }
  &:focus {
    background: ${({ black, white, hide, transparent, error, green, theme: { primary } }) =>
      black ? "#20263C" : white || hide ? "#fff" : transparent || error || green ? "transparent" : primary};
  }
  > svg {
    margin: ${({ rightIcon }) => (rightIcon ? "0 0 0 6px " : "0 6px 0 0")};
    transform: ${({ transform }) => (transform ? "rotate(180deg)" : "none")};
    transition: all 0.3s linear;

    path {
      stroke: ${({ transparent, error, green, theme: { primary, red } }) =>
        transparent ? primary : error ? red : green ? "#55B17C" : "#fff"};
    }
  }
`;

export const Heading = styled.h2`
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 135%;
  color: #181818;
  margin: 0 0 0 25px;
`;

export const CardStatistic = styled.div`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  background: #ffffff;
  border-radius: 4px;
  padding: ${({ p = "20px 25px" }) => p};
  margin-bottom: 25px;
  box-sizing: border-box;
  position: relative;
`;

export const ChartRow = styled.div`
  display: flex;
  width: ${({ w }) => w};
`;

export const ChartCol = styled.div`
  display: flex;
  margin: ${({ m }) => m};
  flex-direction: column;
  width: ${({ w }) => w};
`;

export const CardHeading = styled.h6`
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 135%;
  color: #181818;
  margin: 0 0 18px;
  margin-left: ${({ ml = 0 }) => ml};
`;

export const ViewRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const Label = styled.h6`
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 135%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: rgba(24, 24, 24, 0.6);
  margin: 0 0 8px;
  white-space: nowrap;
`;

export const Bold = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: ${({ fW = "bold" }) => fW};
  font-size: 14px;
  line-height: 135%;
  letter-spacing: 0.05em;
  text-transform: ${({ transform = "uppercase" }) => transform};
  color: #181818;
`;

export const AvatarWrapper = styled.div`
  width: 86px;
  height: 86px;
  position: relative;
  left: 0;
  top: -36px;
  > svg {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    filter: grayscale(${({ theme: { greyFilter } }) => greyFilter});
  }
`;

export const Avatar = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
`;

export const Table = styled.section`
  background: #ffffff;
  border: 1px solid #efefef;
  box-sizing: border-box;
  border-radius: 4px;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Ceil = styled.div`
  height: 100%;
  width: ${({ big }) => (big ? "calc(100% - 306px)" : "102px")};
  border-right: 1px solid #efefef;
  padding: ${({ big, isFile }) => (isFile ? "0 78px" : big ? "5px 25px" : "5px 20px")};
  display: flex;
  align-items: center;
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 135%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: rgba(24, 24, 24, 0.4);
  box-sizing: border-box;
  &:last-child {
    border-right: none;
  }
`;

export const TRow = styled.div`
  width: 100%;
  border-bottom: 1px solid #efefef;
  height: 50px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  &:last-child {
    border-bottom: none;
  }
`;

export const CenterContainer = styled.div`
  margin: auto;
`;

export const ColSmall = styled.div`
  width: 86px;
`;
