import styled from "styled-components";
import React, { useState, Fragment, useEffect } from "react";
import HighchartsReact from "highcharts-react-official";
import { CircularProgress } from "@material-ui/core";
import moment from "moment";
import { Link } from "react-router-dom";

import { ReactComponent as InfoIcon } from "../../../../assets/info.svg";
import { ReactComponent as CloseIcon } from "../../../../assets/x-circle.svg";
import { ReactComponent as CheckIcon } from "../../../../assets/check.svg";
import { ReactComponent as RejectIcon } from "../../../../assets/slash.svg";
import { ReactComponent as LinkIcon } from "../../../../assets/link.svg";

import { TextFieldComponent, StatusComponent, StatusDinamicComponent } from "../../forms";
import { DataColumn } from "../AssetDetails/styled";

import { Btn } from "../../buttons";

import Api from "../../../../helpers/api";

export const Header = styled.div`
  width: 100%;
  height: ${({ height = 60 }) => height}px;
  border-radius: 4px 0px 4px 4px;
  background: white;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: ${({ padding }) => (padding ? padding : "0 25px")};

  ${({ isFooter = false }) => {
    if (isFooter) return `margin-top: 30px;`;
    else return `margin-bottom: 30px;`;
  }}
  ${({ between = false }) => (between ? "justify-content: space-between;" : "")}
`;

export const Heading = styled.h6`
  font-family: Muli;
  font-weight: ${({ bold }) => (bold ? "bold" : "normal")};
  font-size: ${({ fSize }) => (fSize ? `${fSize}px` : "14px")};
  line-height: 135%;
  color: #181818;
  margin: 0 5px 0 0;
  text-transform: none;
`;

export const Loading = styled.div`
  width: 100%;
  height: calc(100vh - 160px);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DealCard = styled.div`
  width: 100%;
  height: 200px;
  background: #ffffff;
  border-radius: 4px;
  display: flex;
  overflow: hidden;
`;

export const Info = styled.div`
  width: 50%;
  border-right: 1px solid #efefef;
  padding: 20px 25px;
  box-sizing: border-box;
`;

export const ChartWrapper = styled.div`
  width: 50%;
  padding: 0 20px 0 15px;
  box-sizing: border-box;
  transform: translateY(-15px);
`;

export const HR = styled(HighchartsReact)`
  background: red;
  & .highcharts-range-selector-group {
    display: none !important;
  }
`;

export const AssetName = styled.h3`
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 135%;
  color: #181818;
  margin: 0;
`;

const StaisticText = styled.span`
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 135%;
  color: ${({ red, green }) => (red ? "#d96666" : green ? "#019D52" : "#181818")};
  margin-right: ${({ mR }) => (mR ? "5px" : "0")};
`;

export const Statistic = ({ data, period }) => {
  const { dynamic, positive } = data;
  return (
    <div>
      {dynamic ? (
        <Fragment>
          <StaisticText mR red={!positive} green={positive}>
            {!isNaN(Number.parseInt(dynamic))
              ? `${parseFloat(dynamic).toFixed(2)}  %  ${positive ? "more" : "less"}`
              : dynamic}
          </StaisticText>
          {dynamic && <StaisticText>than last {period}</StaisticText>}
        </Fragment>
      ) : (
        <StaisticText>No activity</StaisticText>
      )}
    </div>
  );
};

export const Separator = styled.div`
  height: ${({ height }) => (height ? `${height}px` : "30px")};
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: ${({ justify = "space-between" }) => justify};
  align-items: flex-start;
  margin: ${({ margin }) => margin};
`;

export const FilterCont = styled.div`
  width: 140px;
  height: 32px;
  border: 1px solid #efefef;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(24, 24, 24, 0.6);
`;

const Period = styled.span`
  margin: 0 3px;
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 135%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  cursor: pointer;
  color: ${({ active }) => (active ? "#181818" : "rgba(24, 24, 24, 0.6)")};
  :first-child {
    margin-left: 0px;
  }
  :last-child {
    margin-right: 0px;
  }
`;

export const Filter = ({ periods, filter }) => {
  const [active, setActive] = useState(periods[0]);

  const setPeriod = (period) => {
    setActive(period);
    filter(period);
  };

  const renderPeriods = () => {
    return periods.map((e, i) => {
      return (
        <Fragment key={e}>
          <Period onClick={() => setPeriod(e)} active={active === e} key={e}>
            {e}
          </Period>{" "}
          {i !== periods.length - 1 && `/`}
        </Fragment>
      );
    });
  };
  return <FilterCont>{renderPeriods()}</FilterCont>;
};

export const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: ${({ isOpen }) => (isOpen ? "0" : "2250px")};
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(5px);
  z-index: 999;
`;

export const ModalWrapper = styled.div`
  position: relative;
  width: ${({ width }) => (width ? `${width}px` : "565px")};
  background: #ffffff;
  border: 1px solid #f9f9f9;
  box-sizing: border-box;
  box-shadow: 0px 10px 40px rgba(89, 120, 150, 0.06);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`;

export const MHeading = styled.h3`
  font-family: Muli;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 135%;
  margin: 0;
  color: #181818;
`;

export const MHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: ${({ isFooter = false }) => (isFooter ? "auto" : "0")};
  height: ${({ isFooter = false }) => (isFooter ? "" : "52px")};

  padding: ${({ isFooter = false }) => (isFooter ? "0 25px 20px" : "0 25px")};
  background: #ffffff;
  border: ${({ isFooter = false }) => (isFooter ? "" : "1px solid #f9f9f9")};
  box-sizing: border-box;
  box-shadow: ${({ isFooter = false }) => (isFooter ? "" : "0px 10px 40px rgba(89, 120, 150, 0.08)")};
  border-radius: ${({ isFooter = false }) => (isFooter ? "" : "4px")};
  > svg {
    cursor: pointer;
    width: 24px;
    height: 24px;
  }
`;

export const DealsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 0 20px 0;
  border-top: 1px solid #f6f7fc;
  border-bottom: 1px solid #f6f7fc;
  z-index: 1000;
  max-height: 400px;
  overflow-x: scroll;
  padding: 25px;
  box-sizing: border-box;
`;

export const ListHeading = styled.h6`
  margin: ${({ last }) => (last ? "20px 0 15px 0" : "0 0 15px 0")};
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 135%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: rgba(24, 24, 24, 0.6);
`;

const DealContainer = styled.div`
  width: 100%;
  height: 42px;
  background: ${({ hide, theme: { adminTableRowGrey } }) => (hide ? "transparent" : adminTableRowGrey)};
  border: 1px solid #efefef;
  box-sizing: border-box;
  border-radius: 2px;
  margin-bottom: 5px;
  padding: 5px 15px 5px 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: background linear 0.1s;
`;

const DealImg = styled.img`
  width: 60px;
  height: 30px;
  border-radius: 2px;
  margin-right: 15px;
  filter: ${({ hide }) => (hide ? "grayscale(1)" : "none")};
`;

const Name = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 150%;
  color: #333333;
`;

const CheckBox = styled.div`
  width: 32px;
  height: 16px;
  position: relative;
  background: ${({ active, theme: { primary } }) => (active ? primary : "#EFF0F3")};
  transition: background linear 0.1s;
  border-radius: 8px;
  cursor: pointer;
`;

const Circle = styled.div`
  width: 14px;
  height: 14px;
  background: ${({ active }) => (active ? "#fff" : "#616161")};
  border-radius: 8px;
  position: absolute;
  right: ${({ active }) => (active ? "1px" : "17px")};
  transition: right linear 0.1s;
  top: 1px;
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const DealItem = ({ active, token, fileName, hash, assetName, onChangeStatus, viewId }) => {
  const [image, setImage] = useState(null);

  useEffect(() => {
    if (!image && fileName && hash) {
      Api.getAttachment(token, hash, fileName).then((blob) => setImage(blob));
    }
  });

  const [show, setShow] = useState(active);

  const changeStatus = async () => {
    const _show = show;
    setShow(!_show);
    await new Promise((res) => setTimeout(res, 500));
    onChangeStatus({
      viewId: viewId,
      status: !_show,
    });
  };

  return (
    <DealContainer hide={!show}>
      <FlexContainer>
        {image && <DealImg hide={!show} src={image} alt="img" />}
        <Name>{assetName}</Name>
      </FlexContainer>
      <CheckBox active={show} onClick={changeStatus}>
        <Circle active={show} />
      </CheckBox>
    </DealContainer>
  );
};

export const WaitingWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.1);
  z-index: 1001;
`;

const StatusType = styled.div`
  height: 24px;
  padding: 0 10px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  background: ${({ type }) => type};
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 150%;
  text-transform: uppercase;
  color: #ffffff;
  width: fit-content;
`;

const types = {
  INCOME: "#019D52",
  OUTCOME: "#D96666",
  PERSONAL: "#BFDCFC",
  PENDING: "#C6C6C6",
  CANCELED: "#D96666",
  OTHER: "#cecece",
  REJECTED: "#FF4343",
};

export const Status = ({ status }) => {
  return <StatusType type={types[status]}>{status}</StatusType>;
};

const ApproveButton = styled.button`
  position: relative;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  cursor: pointer;
  background: ${({ disabled, transparent, theme: { primary, infoButtonBg } }) =>
    disabled ? "#F0F3FD" : transparent ? infoButtonBg : primary};
  border-radius: 2px;
  border: 1px solid ${({ transparent, theme: { primary, infoButtonBg } }) => (transparent ? infoButtonBg : primary)};
  padding: 0;
  > svg {
    path {
      stroke: ${({ disabled, transparent, theme: { primary } }) => (disabled || transparent ? primary : "#fff")};
    }
  }
`;

const BG = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(5px);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModalHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 25px;
  margin-top: ${({ isFooter = false }) => (isFooter ? "auto" : "0")};
  > svg {
    cursor: pointer;
  }
`;

export const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ padding }) => (padding ? padding : "25px 0")};
  margin: 0 0 20px 0;
  border-top: 1px solid #f6f7fc;
  border-bottom: 1px solid #f6f7fc;
  z-index: 1000;
`;

const Line = styled.div`
  width: 100%;
  height: 0px;
  margin: 25px 0;
  border: 1px solid #f6f7fc;
`;

const InfoHeading = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 135%;
  margin-bottom: 20px;
  color: #181818;
  text-transform: none;
  text-align: left;
`;

const FixedBlock = styled.div`
  width: ${({ width }) => (width ? width : "25%")};
  overflow: hidden;
  box-sizing: border-box;
`;

const AssetBlock = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px 0 0;
  height: 42px;
  background: ${({ theme: { quoteBgInvestor } }) => quoteBgInvestor};
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 9px;
  svg {
    path {
      stroke: ${({ theme: { primary } }) => primary};
    }
  }
`;

const EmptyImage = styled.div`
  width: 62px;
  height: 42px;
  border-radius: 2px 0px 0px 2px;
  background: transparent;
  margin-right: 18px;
`;

const DealLogo = styled.img`
  width: 62px;
  height: 42px;
  border-radius: 2px 0px 0px 2px;
  object-fit: cover;
  margin-right: 18px;
`;

const DealName = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 135%;
  letter-spacing: -0.02em;
  color: #181818;
  text-transform: none;
  margin-right: auto;
`;

export const ManualApprove = ({ time, tx, token, refresh, disabled, image, dealName, shareName }) => {
  const [open, setOpen] = useState(false);
  const [txInfo, setInfo] = useState(null);
  const [wait, setWait] = useState(false);
  const [logo, setLogo] = useState(null);

  useEffect(() => {
    if (!logo && image && image.secureHash && image.fileNameWithExtension) {
      Api.getAttachment(token, image.secureHash, image.fileNameWithExtension).then((blob) => setLogo(blob));
    }
  }, []);

  const openModal = () => setOpen(!open);

  useEffect(() => {
    if (open && !txInfo) {
      Api.getPendingTxInfo(token, tx).then((res) => setInfo(res));
    }
  });

  const approveTx = async (isReject = false) => {
    setWait(true);
    const res = await Api.approveTransaction(token, txInfo.paymentId, isReject);

    if (res) {
      setWait(false);
      setOpen(false);
      setTimeout(() => {
        refresh();
      }, 3000);
    }

    setWait(false);
  };
  return (
    <ApproveButton onClick={() => setOpen(!open)} disabled={disabled}>
      <InfoIcon />
      {open && txInfo && (
        <BG onClick={openModal}>
          <ModalWrapper width={735} onClick={(e) => e.stopPropagation()}>
            {wait && (
              <WaitingWrapper>
                <CircularProgress />
              </WaitingWrapper>
            )}
            <MHeader>
              <Heading bold>{time} Adding Funds Transaction</Heading>
              <CloseIcon onClick={openModal} />
            </MHeader>
            <InputsWrapper padding="25px">
              <Row>
                <TextFieldComponent disabled defaultValue={txInfo.user} width="265px" label="User" />
                <TextFieldComponent disabled defaultValue={time} width="110px" label="Time" />
                <StatusComponent width="240px" label="Status" />
              </Row>
              <Row margin="20px 0 0 0">
                <DataColumn
                  childrenWidth="100%"
                  width="100%"
                  mRight="0"
                  heading="Asset"
                  data={
                    <AssetBlock>
                      {logo ? <DealLogo src={logo} alt="logo" /> : <EmptyImage />}
                      <DealName>{dealName}</DealName>
                      <LinkIcon />
                    </AssetBlock>
                  }
                />
              </Row>
              <Line />
              <InfoHeading>Transaction Information</InfoHeading>
              <Row>
                <FixedBlock>
                  <DataColumn mRight={30} heading="Transaction Type" data={txInfo.txType} />
                </FixedBlock>
                <FixedBlock>
                  <DataColumn mRight={30} heading="Payment method" data={txInfo.paymentMethod} />
                </FixedBlock>
                <FixedBlock>
                  <DataColumn mRight={30} heading="Payment Id" data={txInfo.paymentId} />
                </FixedBlock>
                <FixedBlock>
                  <DataColumn mRight={30} heading="Deposit from" data={txInfo.depositFrom} />
                </FixedBlock>
              </Row>
              <Separator height={25} />
              <InfoHeading>Share Details</InfoHeading>
              <Row>
                <FixedBlock>
                  <DataColumn mRight={30} heading="Paid Amount" data={txInfo.paidAmount} />
                </FixedBlock>
                <FixedBlock>
                  <DataColumn mRight={30} heading="shares" data={`${txInfo.share} ${txInfo.currency}`} />
                </FixedBlock>
                <FixedBlock>
                  <DataColumn
                    mRight={30}
                    heading="Share price"
                    data={`1 ${shareName ? shareName : "Share"} = ${txInfo.sharePrice + " " + txInfo.currency}`}
                  />
                </FixedBlock>
                <FixedBlock>
                  <DataColumn />
                </FixedBlock>
              </Row>
            </InputsWrapper>
            <MHeader isFooter>
              <Btn onClick={openModal} hide width={94}>
                Cancel
              </Btn>
              <Btn width={144} red margin="0 16px 0 auto" onClick={() => approveTx(true)}>
                <RejectIcon />
                Reject
              </Btn>
              <Btn fullGreen width={184} onClick={() => approveTx(false)}>
                <CheckIcon /> Yes, approve
              </Btn>
            </MHeader>
          </ModalWrapper>
        </BG>
      )}
    </ApproveButton>
  );
};

export const TxInfo = ({
  tx: {
    action,
    time,
    counterparty,
    movementType,
    cashTx,
    paymentMethod,
    paymentId,
    fullname,
    quantity,
    tokenIdentifier,
    vocTokenQuantity,
    sharePrice,
    iban,
    assetNameMovementFor,
  },
  shareName,
  image,
  token,
}) => {
  const [open, setOpen] = useState(false);
  const [logo, setLogo] = useState(null);

  useEffect(() => {
    if (!logo && image && image.secureHash && image.fileNameWithExtension) {
      Api.getAttachment(token, image.secureHash, image.fileNameWithExtension).then((blob) => setLogo(blob));
    }
  }, []);

  const openModal = () => setOpen(!open);

  return (
    <ApproveButton transparent onClick={openModal}>
      <InfoIcon />
      {open && (
        <BG onClick={openModal}>
          <ModalWrapper width={735} onClick={(e) => e.stopPropagation()}>
            <MHeader>
              <Heading fSize={16}>
                {moment(time).format("DD/MM/YYYY")} {action}
              </Heading>
              <CloseIcon onClick={openModal} />
            </MHeader>
            <InputsWrapper padding="25px">
              <Row>
                <TextFieldComponent disabled defaultValue={fullname || counterparty} width="265px" label="User" />
                <TextFieldComponent
                  disabled
                  defaultValue={moment(time).format("DD/MM/YYYY")}
                  width="110px"
                  label="Time"
                />
                <StatusDinamicComponent movementType={movementType} width="240px" label="Status" />
              </Row>
              <Row margin="20px 0 0 0">
                <DataColumn
                  childrenWidth="100%"
                  width="100%"
                  mRight="0"
                  heading="Asset"
                  data={
                    <AssetBlock>
                      {logo ? <DealLogo src={logo} alt="logo" /> : <EmptyImage />}
                      <DealName>{assetNameMovementFor}</DealName>
                      <LinkIcon />
                    </AssetBlock>
                  }
                />
              </Row>
              <Line />
              <InfoHeading>Transaction Information</InfoHeading>
              <Row justify="flex-start">
                <FixedBlock width="22%">
                  <DataColumn mRight={30} heading="Transaction Type" data={cashTx ? "Cash" : "Shares"} />
                </FixedBlock>
                {paymentMethod ? (
                  <FixedBlock width="22%">
                    <DataColumn mRight={30} heading="Payment method" data={paymentMethod} />
                  </FixedBlock>
                ) : null}
                {paymentId ? (
                  <FixedBlock>
                    <DataColumn mRight={30} heading="Payment Id" data={paymentId} />
                  </FixedBlock>
                ) : null}
                {iban ? (
                  <FixedBlock width="31%">
                    <DataColumn mRight={30} heading="iban" data={iban} />
                  </FixedBlock>
                ) : null}
              </Row>
              <Separator height={25} />
              <InfoHeading>Share Details</InfoHeading>
              <Row justify="flex-start">
                {quantity && cashTx ? (
                  <FixedBlock width="22%">
                    <DataColumn mRight={30} heading="Paid Amount" data={quantity.toFixed(2) + " " + tokenIdentifier} />
                  </FixedBlock>
                ) : null}
                {(cashTx && vocTokenQuantity) || (!cashTx && quantity) ? (
                  <FixedBlock width="22%">
                    <DataColumn mRight={30} heading="shares" data={cashTx ? vocTokenQuantity : quantity} />
                  </FixedBlock>
                ) : null}

                {cashTx && sharePrice ? (
                  <FixedBlock>
                    <DataColumn
                      mRight={30}
                      heading="Share price"
                      data={`1 ${shareName ? shareName : "Share"} = ${sharePrice}`}
                    />
                  </FixedBlock>
                ) : null}

                <FixedBlock width="31%">
                  <DataColumn />
                </FixedBlock>
              </Row>
            </InputsWrapper>
            <MHeader isFooter>
              <Btn onClick={openModal} hide width={245} margin="0 0 0 auto">
                Cancel
              </Btn>
            </MHeader>
          </ModalWrapper>
        </BG>
      )}
    </ApproveButton>
  );
};

const WaitingLabel = styled.div`
  min-width: 14px;
  height: 14px;
  left: 647px;
  top: 649px;
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 9px;
  line-height: 135%;
  text-align: center;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: #ffffff;
  background: #ff4343;
  border-radius: 2px;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Label = ({ count }) => <WaitingLabel>{count}</WaitingLabel>;

const CenterContainer = styled.div`
  margin: auto;
`;

const CenterRealtive = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Wait = () => (
  <CenterContainer>
    <CircularProgress />
  </CenterContainer>
);

export const WaitBlock = () => (
  <CenterRealtive>
    <CircularProgress />
  </CenterRealtive>
);

const WaitContainer = styled.div`
  height: calc(100vh - 225px);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Loader = () => (
  <WaitContainer>
    <CircularProgress />
  </WaitContainer>
);

export const ChatWrapper = styled(Link)`
  text-decoration: none;
  display: flex;
  align-items: center;
  height: 86px;
  width: 100%;
  background: ${({ theme: { adminTableRowGrey } }) => adminTableRowGrey};
  > svg {
    path {
      stroke: ${({ theme: { primary } }) => primary};
    }
  }
  border: 1px solid transparent;
  box-sizing: border-box;
  border-radius: 2px;
  padding: 18px;
  cursor: pointer;
  transition: all 0.3s linear;
  margin-bottom: 10px;
  &:hover {
    border: 1px solid ${({ theme: { primary } }) => primary};
  }
`;

export const LeftBlock = styled.div`
  height: 100%;
  border-right: ${({ fullWidth = false }) => (fullWidth ? "none" : "1px solid #e7e7e7")};
  width: ${({ fullWidth = false }) => (fullWidth ? "100%" : "322px")};
  display: flex;
  align-items: center;
  > svg {
    width: 52px;
    height: 52px;
    margin-right: 15px;
  }
`;

export const FullWidthBlock = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  &::after {
    content: "${({ unread }) => unread}";
    display: ${({ unread }) => (unread ? "flex" : "none")};
    background: ${({ theme: { primary } }) => primary};
    width: 15px;
    height: 15px;
    border-radius: 50%;
    position: absolute;
    top: 0;
    right: 0;
    font-family: Muli;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    color: #fff;
    align-items: center;
    justify-content: center;
  }
  > svg {
    filter: grayscale(${({ theme: { greyFilter } }) => greyFilter});
  }
`;

export const Avatar = styled.img`
  width: 52px;
  height: 52px;
  border-radius: 50%;
  margin-right: 15px;
  object-fit: cover;
`;

export const NoAvatar = styled.div`
  width: 52px;
  height: 52px;
  background: transparent;
  margin-right: 15px;
`;

export const UserName = styled.h6`
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: ${({ active = false, theme: { primary } }) => (active ? primary : "#181818")};
  margin: 0 0 3px 0;
  transition: color 0.2s linear;
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 67px);
  overflow: hidden;
`;

export const DealNameSmall = styled.p`
  margin: 0;
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  color: rgba(24, 24, 24, 0.7);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const DealFlex = styled.div`
  display: flex;
  align-items: center;
`;

export const DealLogoSmall = styled.img`
  width: 11px;
  height: 11px;
  object-fit: cover;
  margin-right: 6px;
`;

export const NoDealLogoSmall = styled.div`
  width: 11px;
  height: 11px;
  margin-right: 6px;
`;

export const RightBlock = styled.div`
  height: 100%;
  width: calc(100% - 322px);
  box-sizing: border-box;
  position: relative;
  padding-right: 60px;
  padding-left: 16px;
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 160%;
  color: #333333;
  overflow: hidden;
  text-overflow: ellipsis;
  &::after {
    content: "${({ unread }) => unread}";
    display: ${({ unread }) => (unread ? "flex" : "none")};
    background: ${({ theme: { primary } }) => primary};
    width: 15px;
    height: 15px;
    border-radius: 50%;
    position: absolute;
    top: 0;
    right: 0;
    font-family: Muli;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    color: #fff;
    align-items: center;
    justify-content: center;
  }
`;
