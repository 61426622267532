import React from "react";

import { SharesCurrencyCat } from "./SharesCurrencyCat";
import { DinamicCapital } from "../../FullFormCards/DynamicCapital";
import { StartEndDates } from "./StartEndDates";
import { Row, Col, Separator } from "./styled";

export const Parameters = (props) => {
  return (
    <Row marginTop="0" alignItems="flex-start">
      <Col>
        <SharesCurrencyCat {...props} />
      </Col>
      <Col w="31%">
        <StartEndDates {...props} />
        <Separator />
        <DinamicCapital
          json={props.json}
          onChange={props.onChangeDynamic}
          baseCurrency={props.baseCurrency}
        />
      </Col>
    </Row>
  );
};
