import React, { Component, Fragment } from "react";

import { connect } from "react-redux";
import { Switch, Route } from "react-router-dom";
import { ThemeProvider } from "styled-components";

import "./App.scss";
import { getUserInfo } from "./modules/userRedux/userAction";
import history from "./modules/history";
import { PortalModal, PortalLoader } from "./components/investor-layout/ActionMessages";
// apps
import AdminApp from "admin-panel/admin.app";
import InvestorApp from "investor-panel/investor.app";
import { AuthApp } from "auth-panel/auth.app";
import SetupApp from "setup-panel/setup.app";
import { factorySingleTon } from "./helpers/interceptor";

import { NotFound } from "./pages/NotFound";

import config from "config";
import theme from "models/themes";
const current = process.env.REACT_APP_CURRENT;

class App extends Component {
  interceptor = null;
  state = { messageError: "" };

  shouldComponentUpdate(nextProps) {
    if (
      nextProps.token &&
      (nextProps.role === "AM" || nextProps.role === "ADMIN") &&
      this.props.role === "" &&
      window &&
      window.location &&
      !window.location.href.includes("admin") &&
      !window.location.href.includes("createpassword")
    ) {
      history.push("/admin/full-dashboard");
    } else if (
      nextProps.token &&
      (nextProps.role === "INVESTOR" || nextProps.role === "FACILITATOR") &&
      this.props.role === "" &&
      window &&
      window.location &&
      !window.location.href.includes("investor") &&
      !window.location.href.includes("createpassword")
    ) {
      history.push("/investor/offerings/all");
    }
    return true;
  }

  componentDidMount() {
    const link = document.getElementById("favicon");
    link.setAttribute("href", config[current].favicon || "/favicon.png");
    document.title = config[current].title || "Asset Manager";

    if (this.props.token) this.props.getUserInfo(this.props.token);

    this.interceptor = factorySingleTon(
      ["auth", "login", "is2faEnabled", "createpassword"], // игнорировать путь если в нем есть одно из слов (403)
      [".png", ".jpg"], // игнорировать путь если в нем есть одно из слов (500)
      ["createpassword"], // игнорировать все запросы с любым статусом на 403
      // функция вернет текст ответа если сможет  this.setState({ messageError })
      (messageError) => console.log(messageError)
    );
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <Fragment>
          {this.props.isAppNotReady ? <PortalLoader /> : null}
          {this.state.messageError ? (
            <PortalModal message={this.state.messageError} onClose={() => this.setState({ messageError: "" })} />
          ) : null}
          <Switch>
            <Route path="/admin" component={AdminApp} />
            <Route path="/investor" component={InvestorApp} />
            {/* <Route path="/setup" component={SetupApp} /> */}
            <Route path="/" component={AuthApp} />
            <Route component={NotFound} />
          </Switch>
        </Fragment>
      </ThemeProvider>
    );
  }
}

function mapStateToProps({ userReducer: { token, role, isAppNotReady } }) {
  return {
    token,
    role,
    isAppNotReady,
  };
}

export default connect(mapStateToProps, { getUserInfo })(App);
