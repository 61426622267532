import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
  bottom: ${({ bottom = 0 }) => -bottom}px;
  direction: ltr;
  transform: rotate(180deg);
  transform-origin: center;
  position: absolute;
  width: calc(100% + 20px);
  height: 100%;
  z-index: 100;
  overflow-y: scroll;
  opacity: 0;

  &::after {
    content: "";
    display: block;
    height: ${({ height = 100000 }) => height}px;
    background: linear-gradient(red, blue);
  }
`;

export const CustomScrollEvent = ({ onScroll, maxHeight }) => {
  const container = useRef(null);
  const height = 50000;

  const [bottom, setBottom] = useState(0);

  useEffect(() => {
    if (container.current) {
      container.current.scroll({ left: 0, top: 50000 });
    }
  }, []);

  return (
    <Wrapper
      ref={container}
      bottom={bottom}
      onScroll={(e) => {
        e.preventDefault();
        e.stopPropagation();

        const { scrollTop } = e.target;
        // console.log(height, clientHeight, scrollTop, maxHeight);
        const d = height - scrollTop;

        if (d > 0 && d < maxHeight) {
          onScroll(d);
          setBottom(d);
        } else if (d < 0) {
          container.current.scroll({ left: 0, top: 50000 });
          onScroll(0);
          setBottom(0);
        } else if (maxHeight > 0 && d > maxHeight) {
          container.current.scroll({ left: 0, top: 50000 - maxHeight });
          onScroll(maxHeight);
          setBottom(maxHeight);
        }
      }}
    />
  );
};
