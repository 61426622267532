import React, { useState, useEffect, Fragment } from "react";

import {
  SendEmailWrapper,
  ModalBackground,
  ModalHeader,
  InputsWrapper,
  Heading,
  Separator,
  WaitingWrapper,
  Row,
  CurrentUserRow,
  UserAvatar,
  AssignModalLabel
} from "./styled";
import { Btn } from "../../buttons";
import { ReactComponent as CloseIcon } from "../../../../assets/x-circle.svg";
import { ChipsSelectComponent } from "../../forms";
import Api from "../../../../helpers/api";
import { CircularProgress } from "@material-ui/core";

import { ErrorMessage, SuccessMessage } from "../../errorMessage";

export const AssignToGroupModal = ({
  token,
  isOpen,
  onBackdropClick,
  user,
  groups = [],
  onRefresh
}) => {
  const [wait, setWait] = useState(false);
  const [readyToAssignGroups, setReadyToAssignGroups] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [disable, setDisable] = useState(true);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState("");

  const assignUserToGroup = async () => {
    setWait(true);

    const data = selectedGroups.map(gr => gr.id);

    const res = await Api.addUserToGroups(token, data, user.id).catch(e => {
      setWait(false);
      setError(e);
    });
    if (res) {
      setWait(false);
      setSuccess(
        `${data.length > 1 ? "Users" : "User"} successfully assigned to group.`
      );
    }
  };

  const onCloseSuccessMessage = () => {
    setReadyToAssignGroups([]);
    setSelectedGroups([]);
    setSuccess("");
    onRefresh();
    onBackdropClick();
  };

  const onChangeSelect = val => {
    let buf;
    if (val) {
      buf = val.map(el => readyToAssignGroups[el]);
      buf.length ? setDisable(false) : setDisable(true);
      setSelectedGroups(buf);
    }
  };

  useEffect(() => {
    if (user) {
      const groupsId = groups
        .map(gr => {
          const isUserInGroup = gr.users.filter(u => u.id === user.id);
          if (isUserInGroup.length) {
            return gr.id;
          }
        })
        .filter(id => id);

      const availableGroups = groups
        .map(group => {
          if (!groupsId.find(gr => gr === group.id)) {
            return group;
          }
        })
        .filter(group => group);

      setReadyToAssignGroups(availableGroups);
    }
  }, [user]);

  return isOpen ? (
    <Fragment>
      <ModalBackground onClick={onBackdropClick}>
        <SendEmailWrapper onClick={e => e.stopPropagation()}>
          {wait && (
            <WaitingWrapper>
              <CircularProgress />
            </WaitingWrapper>
          )}

          <ModalHeader>
            <Heading>
              Assign {(user && user.fullName) || "none"} to Groups
            </Heading>
            <CloseIcon onClick={onBackdropClick} />
          </ModalHeader>
          <InputsWrapper padding="15px 25px">
            <AssignModalLabel>User</AssignModalLabel>
            <CurrentUserRow>
              <UserAvatar
                small
                token={token}
                fileName={(user && user.userLogoFileName) || ""}
                hash={(user && user.userLogoHash) || ""}
              />
              <span>{(user && user.fullName) || "none"}</span>
            </CurrentUserRow>
            <Separator height={25} />
            <AssignModalLabel>
              Choose One or several groups to assign
            </AssignModalLabel>
            <Row>
              <ChipsSelectComponent
                view="filter"
                label=""
                width="100%"
                multiple
                token={token}
                values={readyToAssignGroups.map(group => {
                  return {
                    label: group.name,
                    iconHash: group.logoHash,
                    iconName: group.logoFileName
                  };
                })}
                onChange={onChangeSelect}
              />
            </Row>
          </InputsWrapper>
          <ModalHeader isFooter>
            <Btn
              onClick={() => {
                setReadyToAssignGroups([]);
                setSelectedGroups([]);
                onBackdropClick();
              }}
              hide
              width={94}
            >
              Cancel
            </Btn>
            <Btn width={180} onClick={assignUserToGroup} disabled={disable}>
              ASSIGN user TO GROUPS
            </Btn>
          </ModalHeader>
        </SendEmailWrapper>
      </ModalBackground>
      {error ? (
        <ErrorMessage er={error} onClose={() => setError(null)} />
      ) : null}
      {success ? (
        <SuccessMessage message={success} onClose={onCloseSuccessMessage} />
      ) : null}
    </Fragment>
  ) : (
    <div></div>
  );
};
