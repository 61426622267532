import React, { useState } from "react";
import styled from "styled-components";

import { ReactComponent as Check } from "../../../assets/check.svg";

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const InputsRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  flex-direction: ${({ column }) => (column ? "column" : "")};
  > span {
    margin-top: 10px;
    font-family: Muli;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 135%;
    letter-spacing: -0.02em;
    color: #989898;
  }
`;
export const PhoneInputs = styled.div`
  width: ${({ width = "100%" }) => width};
  height: ${({ height }) => height || "auto"};
  display: flex;
  justify-content: space-between;
  position: relative;
  .country-list {
    margin-top: 1px !important;
  }
  .react-tel-input {
    display: flex;
    flex-direction: column;
  }
  .react-tel-input .form-control {
    margin-top: auto !important;
  }
  .phone-input {
    width: 100% !important;
    height: 42px !important;
    font-family: Muli !important;
    font-weight: normal !important;
    font-size: ${({ fSize = 12 }) => `${fSize}px !important`};
    line-height: 135% !important;
    border: 1px solid ${({ theme: { red }, error = false }) => (error ? red : "#efefef")} !important;
    box-sizing: border-box !important;
    border-radius: 4px !important;
    outline: none !important;
    padding-left: 65px !important;
    &::placeholder {
      color: rgba(24, 24, 24, 0.3) !important;
    }
    &:disabled {
      color: #181818;
      background: ${({ errorWithBG = false, theme: { disabledField } }) =>
        errorWithBG ? "rgba(255, 67, 67, 0.05)" : disabledField} !important;
    }
  }
  .phone-flag {
    height: 42px;
    width: 45px;
    top: 21px !important;
    border: 1px solid ${({ theme: { red }, error = false }) => (error ? red : "#efefef")} !important;
  }
  .arrow {
    left: 23px !important;
  }
  .selected-flag {
    width: 100% !important;
  }
  .special-label {
    display: block !important;
    left: 0 !important;
    top: 0 !important;
    font-weight: bold !important;
    font-size: 10px !important;
    line-height: 135% !important;
    text-transform: uppercase !important;
    color: rgba(24, 24, 24, 0.6) !important;
    ${({ isAdmin }) => isAdmin && `letter-spacing: 0.05em !important;color: rgba(24,24,24,0.4) !important;`}
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const CheckBox = styled.div`
  overflow: hidden;
  width: 20px;
  height: 20px;
  margin-right: 12px;
  background: transparent;
  border: 1px solid
    ${({ checked, borderColor = "#EFEFEF", red = false, theme: { primary } }) =>
      red ? "#FF4343" : checked ? primary : borderColor};
  box-sizing: border-box;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  > svg {
    width: 14px;
    height: 14px;
    transform: ${({ checked }) => (checked ? "rotateY(0)" : "rotateY(90deg)")};
    path {
      stroke: ${({ theme: { primary } }) => primary};
    }
    transition: all linear 0.1s;
  }
`;

const CheckBoxRow = styled.div`
  width: 100%;
  display: flex;
`;

const CheckBoxText = styled.p`
  margin: 0;
  width: calc(100% - 32px);
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: ${({ fSize }) => (fSize ? `${fSize}px` : "12px")};
  line-height: 135%;
  letter-spacing: -0.02em;
  color: #181818;
  > a {
    text-decoration: underline;
    color: ${({ theme: { primary } }) => primary};
    font-family: Muli;
    cursor: pointer;
  }
  > b {
    font-family: Muli;
  }
`;

export const CheckboxComponent = ({ question, onChange }) => {
  const { label, value, check } = question || {};
  const [val, setVal] = useState(value);

  const onChoose = () => {
    setVal(!val);
    onChange(!val);
  };

  return (
    <Container>
      <CheckBoxRow>
        <CheckBox checked={val} onClick={onChoose} red={!val && check}>
          <Check />
        </CheckBox>
        <CheckBoxText width="calc(100% - 36px)" fSize={16} dangerouslySetInnerHTML={{ __html: label }} />
      </CheckBoxRow>
    </Container>
  );
};

export const Extra = styled.div`
  position: absolute;
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 135%;
  letter-spacing: -0.02em;
  color: #181818;
  top: 36px;
  left: 12px;
  height: fit-content;
`;
