import React, { useState, useEffect, Fragment } from "react";
import { CircularProgress } from "@material-ui/core";

import { ErrorMessagePortal, ModalPortal } from "components/admin-layout/errorMessage";
import {
  ModalText,
  InputCol,
  Row,
  CurrencyCol,
  PlaceHoldersCol,
  PleceholderItem,
  ConvertBlock,
  LoaderBlock,
} from "./styled";
import { TextFieldComponent, SelectComponent } from "components/admin-layout/forms";
import { ReactComponent as InfoIcon } from "assets/info.svg";
import Api from "helpers/api";
import { numberWithCommas } from "helpers";
import { GetIntent } from "./SignLoi";

export const SubscriptionPlaceholders = ({
  onCancel,
  assetId,
  baseCurrency,
  token,
  history,
  onSetSignData,
  minInvestment = 1,
  maxInvestment,
  noIntent = false,
  actionType,
  normalIntent = false,
  shareName,
}) => {
  const [error, setError] = useState(null);
  const [data, setData] = useState({
    actionType,
    intentionToBuy: "",
    additionalPlaceholders: null,
  });
  const [countOpen, setCountOpen] = useState(!noIntent);
  const [placeholdersOpen, setPlaceholdersOpen] = useState(noIntent);
  const [countError, setCountError] = useState(false);
  const [wait, setWait] = useState(false);
  const [originalsPlaceholders, setOriginal] = useState(null);

  useEffect(() => {
    if (noIntent) getPlaceholders();
  }, []);

  const { intentionToBuy, additionalPlaceholders } = data;

  const onChangeIntention = (e) => {
    countError && setCountError(false);
    setData({ ...data, intentionToBuy: e.target.value });
  };

  const getPlaceholders = async (intentionToBuy = 0) => {
    const _placeholders = await Api.getAdditionalPlaceholders(token, assetId).catch((e) => {
      setCountOpen(false);
      setError(e);
      setWait(false);
    });
    if (_placeholders) {
      const _additional = {};
      Object.keys(_placeholders).forEach((type) => {
        _additional[type] = {};
        _placeholders[type].forEach(({ placeholder }) => {
          _additional[type][placeholder] = "";
        });
      });
      const _data = { ...data, additionalPlaceholders: _additional };
      if (intentionToBuy) _data.intentionToBuy = intentionToBuy;
      setData(_data);
      setOriginal(_placeholders);
      setWait(false);
      setCountOpen(false);
      setPlaceholdersOpen(true);
    }
  };

  const onSetCount = async () => {
    if (!intentionToBuy || intentionToBuy < minInvestment) {
      setCountError(true);
      return;
    }
    setWait(true);
    getPlaceholders();
  };

  const onGetIntentionAndNext = (count) => {
    setWait(true);
    getPlaceholders(count);
  };

  const onChangeAdditional = (e, type, key) => {
    const _obj = { ...data.additionalPlaceholders[type] };
    _obj[key] = e.target.value;
    setData({ ...data, additionalPlaceholders: { ...data.additionalPlaceholders, [type]: _obj } });
  };

  const renderPlaceHolders = () => {
    return Object.keys(originalsPlaceholders).map((type) =>
      originalsPlaceholders[type].map(({ question, placeholder }) => (
        <PleceholderItem key={placeholder}>
          <div className="text">{question}</div>
          <TextFieldComponent
            key={placeholder}
            height={35}
            fontSize={12}
            value={additionalPlaceholders[type][placeholder]}
            onChange={(e) => onChangeAdditional(e, type, placeholder)}
          />
        </PleceholderItem>
      ))
    );
  };

  const countPlaceholders = () => {
    let count = 0;
    Object.keys(originalsPlaceholders).forEach((type) => {
      originalsPlaceholders[type].forEach((_) => {
        count++;
      });
    });
    return count;
  };

  const onGoToSign = () => {
    const _data = { ...data };
    Object.keys(_data.additionalPlaceholders).forEach((type) => {
      for (let key in _data.additionalPlaceholders[type]) {
        if (!_data.additionalPlaceholders[type][key]) {
          _data.additionalPlaceholders[type][key] = " ";
        }
      }
    });
    onSetSignData(_data, () => history.push(`/investor/offerings/digital-signature/${assetId}`));
  };

  useEffect(() => console.log(data), [data]);

  console.log("render");
  return (
    <Fragment>
      {countOpen ? (
        normalIntent ? (
          <GetIntent
            key="count-modal-simple"
            token={token}
            assetId={assetId}
            minInvestment={minInvestment}
            maxInvestment={maxInvestment}
            baseCurrency={baseCurrency}
            onBackDropClick={wait ? undefined : onCancel}
            onSubmit={onGetIntentionAndNext}
            shareName={shareName}
            load={wait}
          />
        ) : (
          <ModalPortal
            disableBackdrop
            key="count-modal"
            heading="Investment Amount"
            onClose={wait ? undefined : onCancel}
            submitText="Confirm"
            onSubmit={onSetCount}
            load={wait}
          >
            <ModalText size={14}>Please provide the amount you intend to invest</ModalText>
            <Row margin="0 0 15px">
              <InputCol w="calc(100% - 130px)">
                <TextFieldComponent
                  type="number"
                  height={42}
                  fontSize={14}
                  value={intentionToBuy}
                  onChange={onChangeIntention}
                  placeholder="Please enter value"
                  error={countError}
                />
              </InputCol>
              <CurrencyCol>
                <SelectComponent
                  hiddenLabel
                  value={baseCurrency}
                  values={[baseCurrency]}
                  isInvestor
                  selectHeight={42}
                  fSize={14}
                  onChange={console.log}
                  top={47}
                />
              </CurrencyCol>
            </Row>
            <ConvertBlock error={countError}>
              <InfoIcon style={{ marginRight: "8px" }} />
              <p>
                Minimum Investment is{" "}
                <b>
                  {numberWithCommas(minInvestment)} {baseCurrency}
                </b>
              </p>
            </ConvertBlock>
          </ModalPortal>
        )
      ) : null}
      {placeholdersOpen ? (
        <ModalPortal
          disableBackdrop
          width="680px"
          heading="Subscription Form Information"
          onClose={onCancel}
          onSubmit={onGoToSign}
          load={!additionalPlaceholders || !originalsPlaceholders}
        >
          {additionalPlaceholders && originalsPlaceholders ? (
            <PlaceHoldersCol noScroll={countPlaceholders() < 5}>{renderPlaceHolders()}</PlaceHoldersCol>
          ) : (
            <LoaderBlock>
              <CircularProgress size={24} color="inherit" />
            </LoaderBlock>
          )}
        </ModalPortal>
      ) : null}
      {error && <ErrorMessagePortal er={error} onClose={() => setError(null)} />}
    </Fragment>
  );
};
