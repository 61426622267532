import styled from "styled-components";

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 30px;
  box-sizing: border-box;
  height: ${({ isAuth }) => (isAuth ? "calc(100vh - 60px)" : "calc(100vh - 95px)")};
  overflow-y: scroll;
`;

export const ColWrapper = styled.div`
  display: flex;
  max-width: 1380px;
  justify-content: space-between;
  width: 100%;
`;
export const Col = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => width};
  box-sizing: border-box;
  padding: ${({ padding }) => padding};
  align-items: ${({ align }) => align};
  height: ${({ height }) => height};
`;

export const Header = styled.div`
  height: 60px;
  width: 100%;
  max-width: 1380px;
  background: #ffffff;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 0 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
`;

export const BorderBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding-right: 30px;
  box-sizing: border-box;
  height: 100%;
  border-right: 1px solid #f2f2f2;
`;

export const Paper = styled.div`
  width: 100%;
  background: #ffffff;
  border-radius: 4px;
  padding: 20px 30px;
  box-sizing: border-box;
`;

export const Navigation = styled.div`
  width: 100%;
  background: #e8e9ea;
  border: 1px solid #f0f0f0;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 20px 15px 20px 25px;
  position: sticky;
  top: 0;
  max-height: calc(100vh - 325px);
`;

export const MainHeading = styled.h1`
  margin: 0 auto 0 25px;
  font-family: Muli;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 135%;
  color: #181818;
`;

export const TextHeading = styled.h3`
  font-family: Muli;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 135%;
  color: #181818;
  margin: 0 0 16px;
`;

export const Text = styled.p`
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 160%;
  color: #333333;
  margin: 0 0 25px;
  &:last-child {
    margin: 0;
  }
  li {
    font-family: Muli;
    margin-bottom: 12px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  a {
    color: ${({ theme: { primary } }) => primary};
    text-decoration: underline;
    font-family: Muli;
  }
  > .contact-form {
    width: 100%;
    border-collapse: collapse;
    th,
    td {
      width: 50%;
      box-sizing: border-box;
      padding: 10px 20px;
      vertical-align: top;
      h6 {
        margin: 0 0 10px;
        font-size: 14px;
      }
    }
  }
`;

export const Nav = styled.div`
  cursor: pointer;
  font-family: Muli;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 135%;
  color: ${({ active = 0, theme: { primaryMiddle } }) => (active ? "rgba(24, 24, 24, 0.4)" : primaryMiddle)};
  margin-bottom: 12px;
  text-decoration: ${({ active = 0 }) => (active ? "none" : "underline")};
  transition: all 0.3s linear;
  &:last-child {
    margin-bottom: 0;
  }
  &:hover {
    color: rgba(24, 24, 24, 0.4);
    text-decoration: none;
    > span {
      color: rgba(24, 24, 24, 0.4);
    }
  }
  > span {
    font-weight: 400;
    color: #181818;
  }
`;

export const Anchor = styled.div`
  position: relative;
  top: -20px;
`;

export const ScrollContainer = styled.div`
  height: 100%;
  padding-right: 15px;
  box-sizing: border-box;
  margin-left: -15px;
  width: calc(100% + 15px);
  overflow-y: scroll;
  &&::-webkit-scrollbar-track {
    background-color: ${({ theme: { primaryLight } }) => primaryLight};
    border-radius: 2px;
  }
  &&::-webkit-scrollbar {
    width: 8px;
    border-radius: 2px;
    background-color: ${({ theme: { primaryLight } }) => primaryLight};
  }
  &&::-webkit-scrollbar-thumb {
    background-color: ${({ theme: { primaryMiddle } }) => primaryMiddle};
    border-radius: 2px;
  }
`;
