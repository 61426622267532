import React, { useState, useRef, useEffect } from "react";
import { Main, CloseIconBlock, TextSub } from "../Step/styled";
import { ReactComponent as ExpandIcon } from "../../../../../assets/expand-card-icon.svg";

import { RightBlock, Expand } from "components/admin-layout/pages/CreateUser/styled";
import { icons } from "models/icons";

export const ExpandedPanel = ({
  children,
  header,
  lock = false,
  padding,
  extraHeader = false,
  buttonExpand = false,
}) => {
  const [cardClose, setCardClose] = useState(false);
  const [noHidden, setNoHidden] = useState(false);
  const [height, setHeight] = useState(null);
  const ref = useRef(null);

  useEffect(() => {
    setHeight(ref.current.clientHeight + 50);
  }, []);

  //test
  useEffect(() => {
    setHeight(ref.current.clientHeight + 50);
  }, [children]);

  useEffect(() => {
    setTimeout(() => {
      setNoHidden(!cardClose);
    }, 200);
  }, [cardClose]);

  const expandCard = () => setCardClose(!cardClose);

  return (
    <Main
      isClosed={cardClose}
      currentHeight={cardClose ? "85px" : lock ? "fit-content" : `${height}px`}
      noHidden={noHidden}
      onKeyUp={() => {
        setHeight(ref.current.clientHeight + 50);
      }}
      padding={padding}
    >
      <div ref={ref}>
        <TextSub
          onClick={expandCard}
          fullWidth
          textAlign="left"
          fontWeight="bold"
          color="#181818"
          marginSize="0 0 25px 0"
        >
          {header}
        </TextSub>
        {extraHeader && !cardClose ? extraHeader : null}
        {buttonExpand ? (
          <RightBlock>
            <Expand onClick={expandCard} close={cardClose}>
              {icons.arrow}
            </Expand>
          </RightBlock>
        ) : (
          <CloseIconBlock isClosed={cardClose}>
            <ExpandIcon onClick={expandCard} />
          </CloseIconBlock>
        )}

        {children}
      </div>
    </Main>
  );
};

export const SimpleCard = ({ children, header }) => {
  return (
    <Main>
      <TextSub fullWidth textAlign="left" fontWeight="bold" color="#181818" marginSize="0 0 25px 0">
        {header}
      </TextSub>
      {children}
    </Main>
  );
};
