import moment from "moment";

import theme from "models/themes";

export const optionCreator = (data, chartWidth = 1000, navigatorEnabled = true, selected, units) => {
  const buttons = [];

  buttons.push({
    type: "month",
    count: 1,
    text: "1m",
  });

  buttons.push({
    type: "month",
    count: 6,
    text: "6m",
  });

  buttons.push({
    type: "year",
    count: 1,
    text: "1y",
  });

  return {
    navigator: {
      enabled: navigatorEnabled,
    },

    chart: {
      width: chartWidth,
      height: 300,
    },

    rangeSelector: {
      selected,
      buttons,
      buttonTheme: {
        display: "none",
      },
    },
    xAxis: {
      type: "dateTime",
      title: {},
      labels: {
        formatter: function () {
          return moment(this.value).format("MMM DD, YYYY");
        },
      },
      minTickInterval: 24 * 60 * 60 * 1000,
      lineColor: "#F6F8FB",
      gridLineWidth: 1,
    },
    yAxis: {
      title: {},
      distance: 0,
      offset: 50,
      labels: {
        formatter: function () {
          if (this.value > 0) {
            return `${this.value} ${units}`;
          } else {
            return "";
          }
        },
      },
      min: -10,
      lineColor: theme.primaryMiddle,
      gridLineWidth: 1,
    },
    tooltip: {
      split: true,
      valueSuffix: ` ${units}`,
    },
    series: [
      {
        name: "Value",
        type: "areaspline",
        data: data,
        threshold: null,
        tooltip: {
          valueDecimals: units === "minutes" ? 1 : 0,
        },
        marker: {
          enabled: true,
          radius: selected === 30 ? 4 : 1,
          fillColor: theme.primary,
          lineColor: theme.primaryMiddle,
          lineWidth: 1,
          states: {
            hover: {
              fillColor: theme.primaryMiddle,
            },
          },
        },
        color: theme.primaryMiddle,
        lineWidth: 1,
        fillColor: {
          linearGradient: {
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 1,
          },
          stops: [
            [0, theme.chartBgTo],
            [1, theme.chartBgFrom],
          ],
        },
      },
    ],
  };
};
