import styled from "styled-components";
import React, { useEffect, useRef, useState } from "react";

import { ReactComponent as Arrow } from "../../../../assets/chevron-down.svg";
import { ReactComponent as Plus } from "../../../../assets/plus-circle.svg";
import { ReactComponent as Check } from "../../../../assets/check.svg";
import { Button } from "../Offerings/styled";
import { privacy } from "models/privacy";

export const Header = styled.div`
  height: 60px;
  width: 100%;
  background: #ffffff;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 0 30px 0 0;
  display: flex;
  align-items: center;
  margin-bottom: 25px;
`;

export const BorderBlock = styled.div`
  padding: 14px 25px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #f2f2f2;
  margin-right: 24px;
`;

export const Wrapper = styled.section`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 auto;
  width: 100%;
  padding: 0 30px;
  max-width: 1440px;
  box-sizing: border-box;
`;

export const Col = styled.div`
  width: ${({ width }) => (width ? width : "440px")};
  display: flex;
  flex-direction: column;
  align-items: ${({ align }) => align};
  padding: ${({ padding }) => padding};
  box-sizing: border-box;
  margin: ${({ margin = 0 }) => margin};
  height: ${({ height }) => (height ? `${height}px` : "auto")};
`;

export const Card = styled.div`
  width: 100%;
  padding: ${({ noPadding, padding }) =>
    noPadding ? 0 : padding ? padding : "25px 30px"};
  height: ${({ height, fullHeight }) =>
    fullHeight ? "100%" : height ? `${height}px` : "auto"};
  box-sizing: border-box;
  background: #ffffff;
  border: 1px solid #f0f0f0;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const Heading = styled.h3`
  font-family: Muli;
  font-style: normal;
  font-weight: ${({ fWeight }) => (fWeight ? fWeight : 600)};
  font-size: 18px;
  line-height: 135%;
  color: #181818;
  margin: ${({ margin }) => (margin ? margin : 0)};
`;

export const BorderCont = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 30px;
  border-bottom: 1px solid #f2f2f2;
`;

export const Row = styled.div`
  display: flex;
  padding: ${({ padding }) => padding};
  margin: ${({ margin }) => (margin ? margin : 0)};
  box-sizing: border-box;
  align-items: ${({ align = "center" }) => align};
  justify-content: ${({ justify }) => justify};
  width: ${({ width }) => width};
  svg {
    path {
      stroke: ${({ theme: { primary } }) => primary};
    }
  }
`;

export const Text = styled.p`
  font-family: Muli;
  margin: ${({ margin }) => (margin ? margin : 0)};
  color: ${({ color }) => (color ? color : "rgba(24, 24, 24, 0.6)")};
  font-size: ${({ fSize }) => (fSize ? `${fSize}px` : "20px")};
  text-transform: ${({ upper }) => (upper ? "uppercase" : "none")};
  line-height: ${({ lineH }) => lineH};
  text-align: ${({ align }) => align};
  > span {
    font-family: Muli;
    color: #181818;
    font-weight: 600;
  }
`;

const Border = styled.div`
  width: 287px;
  height: 42px;
  padding: 13px 15px;
  display: flex;
  align-items: center;
  border: 1px solid ${({ theme: { primary } }) => primary};
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid
    ${({ active, theme: { primary } }) => (active ? primary : "#EFEFEF")};
  background: ${({ active, theme: { disabledField } }) =>
    active ? "transparent" : disabledField};
  margin-right: 10px;
  transition: all 0.3s linear;
  &:last-child {
    margin-right: 0;
  }
`;

const Circle = styled.div`
  position: relative;
  width: 20px;
  height: 20px;
  border: 1px solid
    ${({ active, theme: { primary } }) => (active ? primary : "#EFEFEF")};
  box-sizing: border-box;
  border-radius: 50%;
  display: flex;
  margin-right: 12px;
  cursor: pointer;
  &::before {
    content: "";
    display: block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin: auto;
    background: ${({ active, theme: { primary } }) =>
      active ? primary : "#EFEFEF"};
  }
`;

const TypeText = styled.span`
  font-family: Muli;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 135%;
  letter-spacing: -0.02em;
  color: ${({ active, theme: { primary } }) =>
    active ? primary : "rgba(24, 24, 24, 0.4)"};
  margin-left: ${({ mLeft }) => mLeft};
`;

export const Method = ({
  active,
  type,
  onChangeType,
  cashType = false,
  cashBalance,
  baseCurrency
}) => {
  return (
    <Border active={active} onClick={onChangeType}>
      <Circle active={active} />
      <TypeText active={active}>{type}</TypeText>
      {cashType && (
        <TypeText active={active} mLeft="auto">
          {cashBalance.toFixed(2)} {baseCurrency}
        </TypeText>
      )}
    </Border>
  );
};

export const Counter = styled.div`
  display: flex;
  width: 100%;
  height: 56px;
  align-items: center;
  justify-content: center;
  margin-bottom: 6px;
  background: ${({ theme: { quoteBgInvestor } }) => quoteBgInvestor};
  border-radius: 4px;
  font-family: Muli;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 135%;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.05em;
  color: #181818;
`;

export const Label = styled.div`
  margin: ${({ margin }) => (margin ? margin : "0 auto")};
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 135%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: rgba(24, 24, 24, 0.6);
`;

const ProgressContainer = styled.div`
  width: 100%;
  height: 8px;
  background: ${({
    isTrade,
    isExisted,
    theme: { primaryLight, secondaryLight, tertiary }
  }) => (isTrade ? secondaryLight : isExisted ? tertiary : primaryLight)};
  display: flex;
`;

export const Progress = styled.div`
  height: 8px;
  background: ${({
    isTrade,
    isExisted,
    theme: { primary, secondary, tertiary }
  }) => (isTrade ? secondary : isExisted ? tertiary : primary)};
  width: ${({ width }) => `${width}%`};
  transition: ${({ noTransition }) =>
    noTransition ? "none" : "width 1.5s cubic-bezier(0.22, 0.61, 0.36, 1)"};
`;

export const ProgressBar = ({
  sold,
  unsold,
  transition = 1000,
  noTransition,
  isTrade = false,
  isExisted
}) => {
  const [progressWidth, setProgressWidth] = useState(0);

  setTimeout(() => {
    setProgressWidth((sold / (sold + unsold)) * 100);
  }, transition);
  return (
    <ProgressContainer isTrade={isTrade} isExisted={isExisted}>
      <Progress
        isExisted={isExisted}
        isTrade={isTrade}
        noTransition={noTransition}
        width={isExisted ? 100 : progressWidth}
      />
    </ProgressContainer>
  );
};

const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: ${({ mRight }) => (mRight ? `${mRight}px` : "60px")};
  width: ${({ width }) => width};
  align-items: flex-start;
`;

const ColHeading = styled.h6`
  font-family: Muli;
  font-weight: bold;
  font-size: 10px;
  line-height: 135%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  margin: 0 0 3px 0;
  color: rgba(24, 24, 24, 0.6);
`;

const Data = styled.div`
  font-family: Muli;
  font-weight: bold;
  font-size: 14px;
  line-height: 135%;
  letter-spacing: 0.05em;
  color: #181818;
  display: flex;
  align-items: center;
  width: ${({ width }) => width};
`;

const Cirlcle = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: ${({ color }) => color};
  margin-left: 5px;
  margin-right: ${({ mRight }) => (mRight ? `${mRight}px` : "0")};
`;

export const DataColumn = ({
  mRight,
  heading,
  data,
  circleColor = false,
  width,
  childrenWidth
}) => {
  return (
    <Column mRight={mRight} width={width}>
      <ColHeading>{heading}</ColHeading>
      <Data width={childrenWidth}>
        {data}
        {circleColor && <Cirlcle color={circleColor} />}
      </Data>
    </Column>
  );
};

export const CurrencyBlock = styled.div`
  width: 105px;
  height: 42px;
  border: 1px solid #efefef;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 15px 20px;
  display: flex;
  align-items: center;
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 135%;
  letter-spacing: -0.02em;
  color: #181818;
  margin-top: auto;
`;

export const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(5px);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ModalWrapper = styled.div`
  position: relative;
  width: ${({ width }) => (width ? `${width}px` : "440px")};
  background: #ffffff;
  border: 1px solid #f9f9f9;
  box-sizing: border-box;
  box-shadow: 0px 10px 40px rgba(89, 120, 150, 0.06);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`;

export const ModalHeader = styled.div`
  width: 100%;
  display: flex;
  height: 52px;
  padding: 0 25px;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  margin-top: ${({ isFooter = false }) => (isFooter ? "auto" : "0")};
  box-shadow: ${({ boxShadow }) => boxShadow};
  z-index: 100;
  > svg {
    cursor: pointer;
    width: 24px;
    height: 24px;
  }
`;

export const ModalHeading = styled.h3`
  font-family: Muli;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 135%;
  margin: 0;
  color: #181818;
  text-transform: ${({ textTransform }) => textTransform};
`;

const SelectBox = styled.div`
  width: 100%;
  height: 42px;
  background: #ffffff;
  border: 1px solid #efefef;
  box-sizing: border-box;
  border-radius: ${({ open }) => (open ? "4px 4px 0 0" : "4px")};
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  position: relative;
  > span {
    font-family: Muli;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 135%;
    letter-spacing: -0.02em;
    color: #181818;
  }
  > svg {
    width: 18px;
    height: 18px;
  }
`;

const ListWrapper = styled.div`
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 40px;
  left: 0;
  border: 1px solid #efefef;
  border-radius: 0 0 4px 4px;
  z-index: 1002;
  background: #ffffff;
  max-height: 260px;
`;

const IbanItem = styled.div`
  width: 100%;
  height: 62px;
  background: ${({ active, theme: { quoteBgInvestor } }) =>
    active ? "#FFFFFF" : quoteBgInvestor};
  border: 1px solid
    ${({ active, theme: { primary, quoteBgInvestor } }) =>
      active ? primary : quoteBgInvestor};
  border-radius: 4px;
  margin-bottom: 10px;
  padding: 12px 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  cursor: pointer;
`;

const BankName = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 135%;
  letter-spacing: -0.02em;
  color: #181818;
`;

const CheckCircle = styled.div`
  position: absolute;
  top: 8px;
  right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: ${({ theme: { primary } }) => primary};
`;

export const IbanSelect = ({ ibans, callBack, onAddNewAccount }) => {
  const [listOpen, openList] = useState(false);
  const [activeAccount, setActiveAccount] = useState(
    ibans && ibans.length ? ibans[0].iban : ""
  );
  const scrollRef = useRef(null);
  const originalList = useRef(null);

  useEffect(() => {
    originalList.current = [...ibans];
  }, []);

  const changeOpenStatus = () => openList(!listOpen);

  const onChooseIban = (iban, accountNumber) => {
    setActiveAccount(iban);
    changeOpenStatus();
    callBack(accountNumber);
  };

  useEffect(() => {
    if (
      scrollRef &&
      scrollRef.current &&
      originalList.current.length !== ibans.length
    ) {
      scrollRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [ibans]);

  const renderIbans = () => {
    if (ibans) {
      return ibans.map(({ bankName, iban, accountNumber }) => {
        const active = iban === activeAccount;

        return (
          <IbanItem
            key={iban}
            active={active}
            onClick={() => onChooseIban(iban, accountNumber)}
          >
            <BankName>{bankName}</BankName>
            <Text fSize={12} color="rgba(24, 24, 24, 0.8)">
              {iban}
            </Text>
            {active && (
              <CheckCircle>
                <Check />
              </CheckCircle>
            )}
          </IbanItem>
        );
      });
    }
  };

  return (
    <div style={{ position: "relative", width: "100%" }}>
      <SelectBox open={listOpen} onClick={changeOpenStatus}>
        <span>{activeAccount}</span>
        <Arrow />
      </SelectBox>
      {listOpen && (
        <ListWrapper>
          <Scroll>
            {renderIbans()}
            <div ref={scrollRef} />
          </Scroll>
          <Button width={340} height={36} onClick={onAddNewAccount}>
            <Plus style={{ marginRight: 10 }} /> Add New Account
          </Button>
        </ListWrapper>
      )}
    </div>
  );
};

export const Line = styled.div`
  width: calc(100% + 60px);
  transform: translateX(-30px);
  height: 1px;
  background: #f2f2f2;
  margin: 20px 0;
`;

const Scroll = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: 165px;
  margin-bottom: 10px;
  padding-right: 8px;
  width: 340px;
  box-sizing: border-box;
  &&::-webkit-scrollbar-track {
    background-color: ${({ theme: { primaryLight } }) => primaryLight};
    border-radius: 2px;
  }
  &&::-webkit-scrollbar {
    width: 8px;
    border-radius: 2px;
    background-color: ${({ theme: { primaryLight } }) => primaryLight};
  }
  &&::-webkit-scrollbar-thumb {
    background-color: ${({ theme: { primaryMiddle } }) => primaryMiddle};
    border-radius: 2px;
  }
`;
