import DEV from "./dev";
import INVESTURE from "./investure";
import ALPHIER from "./alphier";

const INVESTURE_PROD = { ...INVESTURE };
const ALPHIER_PROD = { ...ALPHIER };

const current = process.env.REACT_APP_CURRENT;

const themes = { DEV, INVESTURE, INVESTURE_PROD, ALPHIER, ALPHIER_PROD };

const palete = themes[current] || themes.DEV;

export default palete;
