import React, { useState } from "react";
import moment from "moment";
import { Tooltip } from "@material-ui/core";

import {
  ModalBG,
  ModalWrapper,
  ModalHeader,
  ModalHeading,
  TableWrapper,
  ScrollContainerRegister
} from "../styled";
import {
  TransactionsHeader,
  HeaderTD,
  TransactionsTD
} from "../../../dealTypeCard";
import { Wait } from "../../DashBoard/styled";
import { ReactComponent as CloseIcon } from "../../../../../assets/x-circle.svg";
import { Btn } from "../../../buttons";
import Api from "../../../../../helpers/api";
import { moutNumber } from "helpers";

export const RegisterModal = ({
  isOpen,
  onBackDropClick,
  token,
  register,
  assetId
}) => {
  const [wait, setWait] = useState(false);

  const downloadXlsx = async () => {
    setWait(true);
    const res = await Api.getXlsxUsersRegister(token, assetId).catch(() =>
      setWait(false)
    );
    if (res) {
      const { file, fileName } = res;
      const fileURL = URL.createObjectURL(file);
      const link = document.createElement("a");
      link.href = fileURL;
      link.download = fileName;
      link.click();
      setWait(false);
    }
  };

  const renderRows = () => {
    return register.map((el, i) => {
      const {
        username,
        fullName,
        shares,
        soldShares,
        residence,
        currency,
        lastTxDate,
        investedAmount,
        assetSymbol
      } = el;
      return (
        <TransactionsHeader grey={i % 2 === 0} key={`${username}-${i}`}>
          <TransactionsTD
            width="230px"
            translate={15}
            padding="0 0 0 15px"
            boxSizing="border-box"
            lower
            fWeight="bold"
          >
            {fullName}
          </TransactionsTD>
          <TransactionsTD width="230px" boxSizing="border-box" blue lower>
            {username}
          </TransactionsTD>
          <Tooltip
            title={`${investedAmount.toFixed(2)} ${currency}`}
            placement="top"
          >
            <TransactionsTD width="120px" boxSizing="border-box" fWeight={600}>
              {moutNumber(investedAmount.toFixed())} {currency}
            </TransactionsTD>
          </Tooltip>
          <Tooltip
            title={`${shares.toFixed(2)} ${assetSymbol}`}
            placement="top"
          >
            <TransactionsTD width="120px" boxSizing="border-box">
              {moutNumber(shares.toFixed())} {assetSymbol}
            </TransactionsTD>
          </Tooltip>
          <TransactionsTD width="105px" boxSizing="border-box">
            {soldShares.toFixed(3)} %
          </TransactionsTD>
          <TransactionsTD
            width="135px"
            boxSizing="border-box"
            fWeight={600}
            lower
          >
            {residence}
          </TransactionsTD>
          <TransactionsTD width="135px" boxSizing="border-box">
            {moment(lastTxDate).format("DD/MM/YYYY")}
          </TransactionsTD>
        </TransactionsHeader>
      );
    });
  };

  return (
    isOpen && (
      <ModalBG onClick={onBackDropClick}>
        {wait && <Wait />}
        <ModalWrapper padding="10px 25px" onClick={e => e.stopPropagation()}>
          <ModalHeader>
            <ModalHeading>Register</ModalHeading>
            <CloseIcon onClick={onBackDropClick} />
          </ModalHeader>
          <TableWrapper>
            <TransactionsHeader>
              <HeaderTD
                width="230px"
                translate={15}
                padding="0 25px 0 0"
                boxSizing="border-box"
              >
                legal name
              </HeaderTD>
              <HeaderTD
                width="230px"
                padding="0 5px "
                boxSizing="border-box"
                translate={register.length > 6 ? 0 : 2}
              >
                e-mail
              </HeaderTD>
              <HeaderTD
                padding="0 5px"
                width="120px"
                boxSizing="border-box"
                translate={register.length > 6 ? 0 : 2}
              >
                Invested Amount
              </HeaderTD>
              <HeaderTD
                padding="0 5px"
                width="120px"
                boxSizing="border-box"
                translate={register.length > 6 ? 0 : 5}
              >
                shares
              </HeaderTD>
              <HeaderTD
                padding="0 5px"
                width="105px"
                boxSizing="border-box"
                translate={register.length > 6 ? 0 : 4}
              >
                global shares
              </HeaderTD>
              <HeaderTD
                padding="0 5px"
                width="135px"
                boxSizing="border-box"
                translate={register.length > 6 ? 0 : 6}
              >
                residence
              </HeaderTD>
              <HeaderTD
                width="135px"
                padding="0 25px 0 0 "
                boxSizing="border-box"
                translate={register.length > 6 ? 0 : 13}
              >
                last transaction
              </HeaderTD>
            </TransactionsHeader>
            <ScrollContainerRegister isScroll={register.length > 6}>
              {renderRows()}
            </ScrollContainerRegister>
          </TableWrapper>
          <ModalHeader isFooter>
            <Btn width={180} hide onClick={downloadXlsx}>
              download xlsx
            </Btn>
            <Btn width={180} hide onClick={onBackDropClick}>
              Close
            </Btn>
          </ModalHeader>
        </ModalWrapper>
      </ModalBG>
    )
  );
};
