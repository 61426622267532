import React from "react";

import { ReactComponent as SearchIcon } from "../../../../assets/search-icon.svg";
import { Btn } from "../../buttons";
import { Row, Card, SearchInput } from "../UserList/styled";

export const Filter = ({ types, getFiltered, searchUsers, type }) => {
  const onChangeFilter = (filter) => () => {
    getFiltered(filter);
  };

  return (
    <Row>
      <Card width="calc(100% - 716px)" padding="0 27px">
        <SearchIcon />
        <SearchInput
          placeholder="Search by username or e-mail"
          onChange={searchUsers}
        />
      </Card>
      <Card padding="0 14px" width="701px">
        {types.map((e) => (
          <Btn
            transparent={e !== type}
            key={e}
            onClick={onChangeFilter(e)}
            width={125}
          >
            {e}
          </Btn>
        ))}
      </Card>
    </Row>
  );
};
