import styled from "styled-components";

import * as MainStyle from "../styled";
import { flexSize, scroll } from "components/common/mixins";

export const Title = styled(MainStyle.Title)`
  margin: 0;
`;

export const TitleField = styled(MainStyle.Title)`
  margin: ${({ margin = "0 0 12px 0" }) => margin};
  color: rgba(24, 24, 24, 0.6);
  text-transform: uppercase;
`;

export const FieldWrapper = styled.div`
  ${flexSize};
  margin: ${({ margin = 0 }) => margin};
`;

export const Events = styled(MainStyle.Events)`
  max-height: 360px;
  padding-right: 24px;
  & > *.title:not(:first-child) {
    margin-top: 18px;
  }
  ${scroll};
  padding: 0px 15px 0px 0px;
`;

export const EventRow = styled(MainStyle.EventRow)`
  border: 1px solid #efefef;
  border-radius: 2px;
  margin-bottom: 6px;
  padding: 15px;
`;

export const EventDate = styled(MainStyle.EventDate)`
  color: #333;
  font-weight: 400;
  flex: 3;
  text-align: center;
`;

export const IconWrapper = styled(MainStyle.ArrowWrapper)`
  background: transparent;
`;
