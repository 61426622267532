import styled from "styled-components";

export const Row = styled.div`
  margin-top: ${({ marginTop = "20px" }) => marginTop};
  display: flex;
  justify-content: space-between;
  align-items: ${({ alignItems = "center" }) => alignItems};
  width: ${({ width = "100%" }) => width};
  ${({ wrap = false }) => (wrap ? "flex-wrap: wrap;" : "")}
`;

export const Col = styled.div`
  width: ${({ w = "calc(69% - 30px)" }) => w};
  display: flex;
  flex-direction: column;
`;

export const Separator = styled.div`
  height: 30px;
`;
