import React, { useState, useEffect, Fragment, useRef } from "react";
import { CircularProgress } from "@material-ui/core";

import { Main, TextSub } from "../../DealForm/Step/styled";
import { FlexRow, SepareteLine } from "../../../dashBoardCard";
import { Wait } from "../../DashBoard/styled";
import {
  TransactionsHeader,
  ScrollContainer,
  HeaderTD,
  TransactionsTD,
  EmailTD,
  Stage,
  SignedDocuments,
  User,
} from "../../../dealTypeCard";
import { Btn } from "components/admin-layout/buttons";
import { ReactComponent as ExpandIcon } from "../../../../../assets/chevron-down.svg";
import { ReactComponent as InfoIcon } from "../../../../../assets/info.wallet.svg";
import { Note, SwitcherDra, Label, Text } from "../styled";
import { PortalTooltipWrapperDRA } from "../../../portalTooltipWrapper";
import { EmptyInfo } from "../common/emptyInfo";

import Api from "helpers/api";
import { icons } from "models/icons";

export const Users = ({ token, assetId, history, role }) => {
  const [usersProgress, setUsersProgress] = useState(null);
  const [tableFullHeight, setTableFullHeight] = useState(false);
  const [wait, setWait] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const ceilRef = useRef(null);

  useEffect(() => {
    if (!usersProgress) {
      Api.getUsersProgress(token, assetId).then((json) => setUsersProgress(json));
    }
  }, []);

  const downloadXlsx = async () => {
    setWait(true);
    const res = await Api.getXlsxUSers(token, assetId).catch(() => setWait(false));
    if (res) {
      const { file, fileName } = res;
      const fileURL = URL.createObjectURL(file);
      const link = document.createElement("a");
      link.href = fileURL;
      link.download = fileName;
      link.click();
      setWait(false);
    }
  };

  const onRefreshNotes = () => {
    Api.getUsersProgress(token, assetId).then((json) => setUsersProgress(json));
  };

  const renderTable = (table) => {
    if (table.length) {
      return (
        <Fragment>
          <TransactionsHeader width="calc(100% - 18px)" translate={-15}>
            <HeaderTD translate={15} boxSizing="border-box" padding="0 25px 0 0" width="23%" blue>
              User
              <ExpandIcon />
            </HeaderTD>
            <HeaderTD boxSizing="border-box" width="23%" translate={table.length > 5 ? 4 : 15}>
              Email
            </HeaderTD>
            <HeaderTD boxSizing="border-box" width="85px" translate={table.length > 5 ? 3 : 24}>
              Stage
            </HeaderTD>
            <HeaderTD boxSizing="border-box" width="105px" translate={table.length > 5 ? 3 : 28}>
              Invested
            </HeaderTD>
            <HeaderTD boxSizing="border-box" width="75px" translate={table.length > 5 ? 2 : 30}>
              Notes
            </HeaderTD>
            <HeaderTD boxSizing="border-box" width="175px" translate={table.length > 5 ? 2 : 31}>
              Signed documents
            </HeaderTD>
            <HeaderTD boxSizing="border-box" width="45px" ref={ceilRef} translate={table.length > 5 ? 0 : 34}>
              <Fragment>
                DrA <InfoIcon onMouseEnter={() => setTooltipOpen(true)} style={{ cursor: "pointer", marginLeft: 3 }} />
                {tooltipOpen ? (
                  <PortalTooltipWrapperDRA anchorRef={ceilRef} onLeave={() => setTooltipOpen(false)}>
                    <Label>Data Room ACCESS</Label>
                    <Text>User with active Data Room Access will have access for all documents for this asset</Text>
                  </PortalTooltipWrapperDRA>
                ) : null}
              </Fragment>
            </HeaderTD>
          </TransactionsHeader>

          <ScrollContainer fullHeight={tableFullHeight} minHeight={250} isScroll={table.length > 5}>
            {table.map((e, i) => {
              const { email, signedDocuments, invested, stage, fullName, logo, userId, notes, dra } = e;
              return (
                <TransactionsHeader grey={i % 2 === 0} key={email}>
                  <TransactionsTD boxSizing="border-box" width="23%" padding="0 0 0 15px">
                    <User
                      token={token}
                      userName={fullName}
                      logo={logo}
                      onClick={() => history.push(`/admin/user/${email}/${userId}`)}
                    />
                  </TransactionsTD>
                  <EmailTD boxSizing="border-box" width="23%" padding="0 5px">
                    {email}
                  </EmailTD>
                  <TransactionsTD boxSizing="border-box" width="85px">
                    <Stage type={stage}>{stage}</Stage>
                  </TransactionsTD>
                  <TransactionsTD boxSizing="border-box" fWeight="bold" width="105px">
                    {invested}
                  </TransactionsTD>
                  <TransactionsTD boxSizing="border-box" fWeight="bold" width="75px" overflow="visible">
                    <Note
                      role={role}
                      token={token}
                      notes={notes}
                      userName={email}
                      assetId={assetId}
                      fullName={fullName}
                      onRefresh={onRefreshNotes}
                    />
                  </TransactionsTD>
                  <TransactionsTD boxSizing="border-box" width="175px">
                    <SignedDocuments docs={signedDocuments} />
                  </TransactionsTD>
                  <TransactionsTD boxSizing="border-box" width="50px">
                    <SwitcherDra value={dra} userName={email} token={token} assetId={assetId} />
                  </TransactionsTD>
                </TransactionsHeader>
              );
            })}
          </ScrollContainer>
          <SepareteLine />
          <FlexRow mBottom="0" align="flex-start">
            <Btn hide width={180} onClick={downloadXlsx}>
              download xlsx
            </Btn>
            <Btn width={180} rightIcon onClick={() => setTableFullHeight(!tableFullHeight)} transform={tableFullHeight}>
              {tableFullHeight ? "minimize" : "expand"}
              <ExpandIcon />
            </Btn>
          </FlexRow>
        </Fragment>
      );
    } else
      return (
        <EmptyInfo
          icon={icons.users}
          heading="There are no users yet"
          text="When we’ll have user to show for you we’ll show them here"
        />
      );
  };

  return (
    <Main currentHeight={tableFullHeight ? "fit-content" : "465px"} minHeight={465} padding="25px 25px 30px 25px">
      {wait && <Wait />}
      <FlexRow mBottom="5px" align="flex-start">
        <TextSub textAlign="left" fontWeight="bold" color="#181818" marginSize="0 0 14px 0">
          Users
        </TextSub>
      </FlexRow>
      {!usersProgress ? (
        <CircularProgress size={24} style={{ margin: "auto" }} />
      ) : (
        <Fragment>{renderTable(usersProgress)}</Fragment>
      )}
    </Main>
  );
};
