import React, { useState } from "react";
import styled from "styled-components";

const Border = styled.div`
  width: 217px;
  height: 32px;
  background: #ffffff;
  border: 1px solid ${({ active, theme: { primary } }) => (active ? primary : "#EFEFEF")};
  box-sizing: border-box;
  border-radius: 4px;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({ active, theme: { primary } }) => (active ? primary : "#EFEFEF")};
  font-size: 12px;
  line-height: 135%;
  letter-spacing: -0.02em;
  cursor: pointer;
`;

const Radio = styled.div`
  width: 28px;
  height: 14px;
  background: ${({ active, theme: { quoteBgInvestor } }) => (active ? quoteBgInvestor : "#EEEEEE")};
  border-radius: 12px;
  transition: all 0.1s linear;
  position: relative;
`;

const Circle = styled.div`
  position: absolute;
  width: 14px;
  height: 14px;
  left: ${({ active }) => (active ? "14px" : 0)};
  top: 0;
  transition: all 0.1s linear;
  background: ${({ active, theme: { primary } }) => (active ? primary : "#BCBCBC")};
  border-radius: 12px;
`;

export const RequireRadio = ({ val, onChange }) => {
  const [value, setValue] = useState(val);

  const onChangeVal = () => {
    onChange(!value);
    setValue(!value);
  };
  return (
    <Border active={value} onClick={onChangeVal}>
      Required Field{" "}
      <Radio active={value}>
        <Circle active={value} />
      </Radio>
    </Border>
  );
};
