import React from "react";
import { CircularProgress } from "@material-ui/core";
import styled from "styled-components";

import { Main, TextSub } from "../../DealForm/Step/styled";
import { FlexRow } from "../../../dashBoardCard";
import { LeftBlock } from "../styled";
import { Btn } from "../../../buttons";
import { Folder } from "components/common/icons-primary";

const Row = styled.div`
  width: 100%;
  display: flex;
`;

const Col = styled.div`
  max-width: 30%;
  margin-right: 20px;
  display: flex;
  flex-direction: column;
  &:last-child {
    margin-right: 0;
  }
`;

const Label = styled.h6`
  font-size: 10px;
  line-height: 135%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: rgba(24, 24, 24, 0.6);
  margin: 0 0 4px;
`;

const Value = styled.p`
  margin: 0;
  font-weight: bold;
  font-size: 14px;
  line-height: 135%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #181818;
`;

export const VievsStatistic = ({ info, newDeal, history, assetId }) => {
  const onGoToDR = () => history.push(`/admin/data-room/deal/${assetId}/file-manager`);

  const renderStatistic = () => {
    const { averagePerUser, totalUsers, totalViews } = info.viewStatistic;
    return (
      <Row>
        <Col>
          <Label>total users</Label>
          <Value>{totalUsers || "-"}</Value>
        </Col>
        <Col>
          <Label>total views</Label>
          <Value>{totalViews || "-"}</Value>
        </Col>
        <Col>
          <Label>average per user</Label>
          <Value>{averagePerUser ? averagePerUser.toFixed(3) : "-"}</Value>
        </Col>
      </Row>
    );
  };
  return (
    <Main currentHeight="126px" width={newDeal ? "100%" : "calc(44.8% - 30px)"} padding="20px 25px 25px 25px">
      <FlexRow mBottom="0" align="flex-start">
        <TextSub textAlign="left" fontWeight="bold" color="#181818" marginSize="0 0 18px 0">
          Data Room View Statistics
        </TextSub>
      </FlexRow>
      <LeftBlock>
        <Btn width={180} onClick={onGoToDR}>
          <Folder /> OPEN DATA ROOM
        </Btn>
      </LeftBlock>
      {!info ? <CircularProgress size={24} style={{ margin: "auto" }} /> : renderStatistic()}
    </Main>
  );
};
