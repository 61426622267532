import logo from "assets/logos/u-start.png";

export default {
  origin: "VoC5",
  url: "https://valueonchain.cf",
  logo: logo,
  logoStyles: {
    auth: `width: 310px;height: 85px;margin-bottom: 30px;> img {width: 75%;}`,
    admin: `position: relative;left: -10px;`,
    investor: `> img {width: 140px;}`,
  },
};
