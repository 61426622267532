import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import { CircularProgress } from "@material-ui/core";

import { Wrapper } from "../../main";
import { Header, TextH3 } from "../DealForm/Step/styled";
import { ReactComponent as ArrowLeft } from "../../../../assets/arrowleft.svg";
import { Btn } from "../../buttons";
import {
  Row,
  Main,
  TextSub,
  InputRow,
  Col,
  Label,
  Statistic,
  SubmitBlock,
  UploadersCol,
  EmptyBlock,
  AvatarsWrapper
} from "./styled";
import {
  TextFieldComponent,
  TextEditorComponent,
  FooterComponent,
  VariablesComponent,
  TextAreaComponent
} from "../../forms";
import { FileUploader } from "./fileUploader";
import Api from "../../../../helpers/api";
import MailApi from "./api";
import { ErrorMessagePortal, SuccessMessage } from "../../errorMessage";
import { UserItem } from "components/admin-layout/forms/styled";

const GroupEmails = ({ token, linearId, extra, history, asset }) => {
  const [_asset, setAsset] = useState(asset);
  const [templateData, setTemplateData] = useState(null);
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [uploadersCount, setUploadersCount] = useState(1);
  const [files, setFiles] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [error, setError] = useState(null);
  const [wait, setWait] = useState(false);
  //for extra
  const [groupsList, setGroups] = useState([]);
  const [usersList, setUsers] = useState([]);

  const renderUploaders = () => {
    let uploaders = [];
    for (let i = 0; i < uploadersCount; i++) {
      const random = Math.round(Math.random() * 1000);
      uploaders.push(
        <FileUploader
          key={`uploader-${random}`}
          order={i + 1}
          onAddFile={f => onAddFile(f)}
          onDelete={f => onRemoveFile(f)}
          _file={files[i] ? files[i] : null}
        />
      );
    }
    return uploaders;
  };

  const onAddFile = file => {
    const _files = [...files];
    _files.push(file);
    setFiles(_files);
  };

  const onRemoveFile = name => {
    const _files = [...files];
    const index = _files.findIndex(e => e.name === name);
    _files.splice(index, 1);
    setFiles(_files);
  };

  useEffect(() => {
    setUploadersCount(files.length + 1);
  }, [files]);

  useEffect(() => {
    const getInitialData = async () => {
      if (!_asset) {
        const asset = await Api.getAsset(token, linearId);
        if (asset) setAsset(asset);
      }
      if (extra) {
        const groups = await Api.getUserGroups(token);
        const users = await Api.getUsers(token, "INVESTOR", 1, 1000, "");
        setGroups(groups);
        setUsers(users.content);
      }
      const template = await Api.getTemplateSettings(
        token,
        "SEND_EMAIL_TO_USER"
      );
      if (template) {
        delete template.variables.variables.messages;
        setTemplateData(template);
      }
    };

    getInitialData();
  }, []);

  const onBackToDeal = () => history.push(`/admin/dashboard/${linearId}`);

  const onError = e => {
    setError(e);
    setWait(false);
  };

  const onChangeFooterText = e => {
    const data = { ...templateData };
    data.customFooterText = e.target.value;
    setTemplateData(data);
  };

  const onSubmit = async () => {
    setWait(true);
    const formData = new FormData();
    if (files.length) {
      for (let i = 0; i < files.length; i++) {
        formData.append(`attachments`, files[i], files[i].name);
      }
    }
    formData.append("linearId", linearId);
    formData.append("message", message);
    formData.append("subject", subject);
    let res;
    if (!extra) {
      res = await Api.sendMailsToInvestors(token, formData).catch(onError);
    } else {
      await Api.updateTemplateSettings(token, templateData);
      res =
        extra === "change-rules-mailing"
          ? await MailApi.sendNewInvestorsMails(token, formData).catch(onError)
          : await MailApi.sendIntialMails(token, formData).catch(onError);
    }

    if (res) {
      setModalOpen(true);
    }
    setWait(false);
  };

  const onCloseModal = () => {
    setModalOpen(false);
    error && setError(false);
    onBackToDeal();
  };

  const onChangeCustomFooter = async () => {
    const newData = {
      ...templateData,
      customFooter: !templateData.customFooter
    };
    setTemplateData(newData);
    await Api.updateTemplateSettings(token, newData);
  };

  const renderAvatars = (idsArr, generalArr, type) => {
    if (idsArr) {
      let data;
      if (type === "users") {
        data = idsArr.map(id => {
          const user = { ...generalArr.find(u => u.id === id) };
          return {
            label: user.fullName,
            iconHash: user.userLogoHash,
            iconName: user.userLogoFileName
          };
        });
      } else {
        data = idsArr.map(id => {
          const group = { ...generalArr.find(g => g.id === +id) };
          return {
            label: group.name,
            iconHash: group.logoHash,
            iconName: group.logoFileName
          };
        });
      }

      return data.map((el, i) => (
        <UserItem token={token} el={el} key={el.label + i} />
      ));
    }
  };

  if (_asset && templateData) {
    const {
      variables: { variables },
      customFooter,
      customFooterText
    } = templateData;
    const {
      subscribers,
      assetProperties: { assetName },
      visibilityProperty: { groups, users }
    } = _asset;
    const usersExist = users && users.length;
    const groupsExist = groups && groups.length;
    return (
      <Wrapper bg="transparent">
        <Header>
          <Btn transparent onClick={onBackToDeal} width={150}>
            <ArrowLeft /> back to deal
          </Btn>
          <TextH3 marginSize="0 0 0 25px">Send e-mail to {assetName}</TextH3>
        </Header>

        <Row>
          <Col width="calc(100% - 383px)">
            <Main width="100%">
              <TextSub
                textAlign="left"
                fontWeight="bold"
                color="#181818"
                marginSize="0 0 25px 0"
              >
                Message to Users
              </TextSub>
              <InputRow>
                <TextFieldComponent
                  label="Subject"
                  placeholder="E-mail subject"
                  defaultValue={subject}
                  onChange={event => setSubject(event.target.value)}
                />
              </InputRow>
              <InputRow>
                <TextEditorComponent
                  label="Template content"
                  defaultValue={message}
                  onChange={event => setMessage(event)}
                />
              </InputRow>
              <InputRow last={!customFooter}>
                <FooterComponent
                  label="e-mail footer"
                  name="Global E-mail footer"
                  defaultValue={!customFooter}
                  onChange={onChangeCustomFooter}
                />
              </InputRow>{" "}
              {customFooter && (
                <InputRow last>
                  <TextAreaComponent
                    label="custom signature for e-mail"
                    placeholder="Write here custom signature or left this fields if you don’t want to use the signature"
                    defaultValue={customFooterText}
                    onChange={onChangeFooterText}
                  />
                </InputRow>
              )}
              <UploadersCol>
                {renderUploaders()}
                <EmptyBlock />
                <EmptyBlock />
              </UploadersCol>
              <SubmitBlock>
                <Btn width={200} height={42} onClick={onSubmit} disabled={wait}>
                  SEND e-mails {!extra ? `(${subscribers})` : null}
                </Btn>
              </SubmitBlock>
            </Main>
          </Col>

          <Col width="353px">
            <Main
              // currentHeight="fit-content"
              margin="0 0 30px"
              key={`variables-${variables.length}`}
            >
              <TextSub
                textAlign="left"
                fontWeight="bold"
                color="#181818"
                marginSize="0 0 25px 0"
              >
                Help
              </TextSub>
              <VariablesComponent label="Shortcuts" variables={variables} />
            </Main>
            {extra && (usersExist || groupsExist) ? (
              <Main currentHeight="fit-content" width="353px">
                <TextSub
                  textAlign="left"
                  fontWeight="bold"
                  color="#181818"
                  marginSize="0 0 25px 0"
                >
                  Users
                </TextSub>
                {usersExist ? (
                  <Fragment>
                    <Label>Deal Users</Label>
                    <AvatarsWrapper>
                      {renderAvatars(users, usersList, "users")}
                    </AvatarsWrapper>
                  </Fragment>
                ) : null}
                {groupsExist ? (
                  <Fragment>
                    <Label>Deal Groups</Label>
                    <AvatarsWrapper>
                      {renderAvatars(groups, groupsList, "groups")}
                    </AvatarsWrapper>
                  </Fragment>
                ) : null}
              </Main>
            ) : (
              <Main
                // currentHeight="fit-content"
                width="353px"
              >
                <TextSub
                  textAlign="left"
                  fontWeight="bold"
                  color="#181818"
                  marginSize="0 0 25px 0"
                >
                  Statistics
                </TextSub>
                <Label>Total subscribers</Label>
                <Statistic>{subscribers} subscribers</Statistic>
              </Main>
            )}
          </Col>
        </Row>

        {modalOpen ? (
          <SuccessMessage
            onClose={onCloseModal}
            message="Your email has been sent successfully to all the subscribers"
          />
        ) : null}
        {error && (
          <ErrorMessagePortal onClose={() => setError(null)} er={error} />
        )}
      </Wrapper>
    );
  } else {
    return (
      <Wrapper
        width="100%"
        justifyContent="center"
        alignItems="center"
        bg="transparent"
        height="calc(100vh - 130px)"
      >
        <CircularProgress />
      </Wrapper>
    );
  }
};

export default connect(
  (
    { userReducer: { token }, dataReducer: { assetInfo, assets } },
    {
      match: {
        params: { id, extra }
      }
    }
  ) => ({
    token,
    assetInfo,
    asset: assets.find(e => e.linearId === id),
    linearId: id,
    extra
  }),
  null
)(GroupEmails);
