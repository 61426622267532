import React, { useState, useEffect, Fragment } from "react";

import {
  SendEmailWrapper,
  ModalBackground,
  ModalHeader,
  InputsWrapper,
  Heading,
  Separator,
  WaitingWrapper
} from "./styled";
import { Btn } from "../../buttons";
import { ReactComponent as CloseIcon } from "../../../../assets/x-circle.svg";
import { TextFieldComponent } from "../../forms";
import Api from "../../../../helpers/api";
import { CircularProgress } from "@material-ui/core";

import { ErrorMessage, SuccessMessage } from "../../errorMessage";

export const DuplicateGroupModal = ({
  token,
  onBackdropClick,
  isOpen,
  groupId,
  onRefresh
}) => {
  const [name, setName] = useState("");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState("");
  const [wait, setWait] = useState(false);

  const onNameInput = e => setName(e.target.value);

  const onCloseSuccessMessage = () => {
    setSuccess("");
    setName("");
    onBackdropClick();
    onRefresh();
  };

  const duplicateGroup = async () => {
    setWait(true);
    const res = await Api.duplicateGroup(token, name, groupId).catch(e => {
      setWait(false);
      setError(e);
    });
    if (res) {
      setWait(false);
      setSuccess("Group successfully duplicated.");
    }
  };

  return isOpen ? (
    <Fragment>
      <ModalBackground onClick={onBackdropClick}>
        <SendEmailWrapper onClick={e => e.stopPropagation()}>
          {wait && (
            <WaitingWrapper>
              <CircularProgress />
            </WaitingWrapper>
          )}

          <ModalHeader>
            <Heading>Duplicate group</Heading>
            <CloseIcon onClick={onBackdropClick} />
          </ModalHeader>
          <InputsWrapper padding="15px 25px">
            <TextFieldComponent
              label="New name"
              placeholder="Rename duplicate group here"
              defaultValue={name}
              onChange={onNameInput}
            />
            <Separator height={20} />
          </InputsWrapper>
          <ModalHeader isFooter>
            <Btn onClick={onBackdropClick} hide width={94}>
              Cancel
            </Btn>
            <Btn width={180} disabled={!name} onClick={duplicateGroup}>
              Duplicate Group
            </Btn>
          </ModalHeader>
          {error ? (
            <ErrorMessage er={error} onClose={() => setError(null)} />
          ) : null}
          {success ? (
            <SuccessMessage message={success} onClose={onCloseSuccessMessage} />
          ) : null}
        </SendEmailWrapper>
      </ModalBackground>
    </Fragment>
  ) : (
    <div></div>
  );
};
