import React, { useState, Fragment } from "react";
import styled, { css } from "styled-components";
import { CircularProgress } from "@material-ui/core";

import { ModalPortal } from "components/admin-layout/errorMessage";
import { EmptyCard } from "components/common/emptyInfo.js";
import { icons } from "models/icons";
import { TextFieldComponent, SelectComponent, PhoneComponent } from "components/admin-layout/forms";
import { Info as InfoIcon } from "components/common/icons-primary";
import Api from "helpers/api";

const base = css`
  width: 100%;
  box-sizing: border-box;
`;

const Wrapper = styled.div`
  ${base}
  padding: 0 0 30px;
  display: flex;
  flex-direction: column;
  border-top: 1px solid #f6f7fc;
  border-bottom: 1px solid #f6f7fc;
  margin-bottom: 25px;
  position: relative;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ mb = "24px" }) => mb};
  ${base};
`;

const Info = styled(Row)`
  padding: 15px 20px;
  height: 60px;
  background: ${({ theme: { emptyCardBg } }) => emptyCardBg};
  border-radius: 2px;
  margin: 0;
  > svg {
    width: 24px;
    height: 24px;
    margin-right: 16px;
  }
  > div {
    width: calc(100% - 40px);
    font-size: 12px;
    line-height: 135%;
    color: #181818;
  }
`;

const Waiting = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  color: ${({ theme: { primary } }) => primary};
  > * {
    margin: auto;
  }
`;

export const CreateSponsor = ({ token, onClose, countries = [], onUpdate, onError = console.log }) => {
  const [sponsor, setSponsor] = useState({
    email: "",
    fullName: "",
    description: "",
    slogan: "",
    countryOfResidence: null,
    logo: null,
    backgroundLogo: null,
    registrationMethod: "EMAIL",
    phoneNumber: "",
    webSite: "",
    userDynamicProperties: [],
    additionalInformation: [],
  });
  const [success, setSuccess] = useState(false);
  const [wait, setWait] = useState(false);

  const emailRegExp = /\S+@\S+\.\S+/;
  const { email, fullName, countryOfResidence, webSite, phoneNumber } = sponsor;
  const disabled = () => !fullName || !countryOfResidence || !email || !email.match(emailRegExp);
  const createProps = (value, onChange, width = "calc(50% - 12.5px)") => ({ isInvestor: true, width, value, onChange });

  const onChange = (e, key) => setSponsor({ ...sponsor, [key]: e.target ? e.target.value : e });

  const onCreate = async () => {
    setWait(true);
    const res = await Api.registerSponsor(token, sponsor).catch((e) => {
      onError(e);
      onClose();
    });
    if (res) {
      setSuccess(res);
      setWait(false);
      onUpdate(fullName);
    }
  };

  return (
    <ModalPortal
      width="565px"
      heading="Add Sponsor"
      submitText={!success ? "CREATE SPONSOR" : "OK, close"}
      onClose={onClose}
      noCancel={success}
      onSubmit={success ? onClose : onCreate}
      load={disabled() || wait}
    >
      <Wrapper>
        {success ? (
          <EmptyCard
            icon={icons.checkCircle}
            heading="An invitation has been sent to Company Name"
            text="You'll be informed once their subscription is done"
          />
        ) : (
          <Fragment>
            <Row>
              <Info>
                <InfoIcon />
                <div>
                  You can fill additional information about the Sponsor later on in the "Users" tab. Please add the
                  basic information for now.
                </div>
              </Info>
            </Row>
            <Row>
              <TextFieldComponent
                {...createProps(fullName, (e) => onChange(e, "fullName"), "100%")}
                label="company name"
                placeholder="Company name"
              />
            </Row>
            <Row>
              <TextFieldComponent
                {...createProps(email, (e) => onChange(e, "email"))}
                label="e-mail"
                placeholder="Company e-mail"
                error={email && !email.match(emailRegExp)}
              />
              <SelectComponent
                view="filter"
                top={62}
                {...createProps(countryOfResidence, (e) => onChange(e, "countryOfResidence"))}
                values={countries}
                label="Country of registration (optional)"
              />
            </Row>
            <Row mb={0}>
              <TextFieldComponent
                {...createProps(webSite, (e) => onChange(e, "webSite"))}
                label="web-site (optional)"
                placeholder="Company web-site"
              />
              <PhoneComponent
                {...createProps(phoneNumber, (e) => onChange(e, "phoneNumber"))}
                label="Phone Number (optional)"
                placeholder="Phone number"
              />
            </Row>
            {wait ? (
              <Waiting>
                <CircularProgress size={24} color="inherit" />
              </Waiting>
            ) : null}
          </Fragment>
        )}
      </Wrapper>
    </ModalPortal>
  );
};
