import React, { useState, useEffect, Fragment } from "react";

import NestedSelect from "components/common/NestedSelect";
import { InfoCard, Col, InputsRow, Loading, SelectorWrapper } from "./styled";
import { TextFieldComponent, SelectComponent } from "../../../admin-layout/forms";
import { Button } from "../Offerings/styled";

import Api from "../Questionnaires/api";

const eligible = ["Yes", "No"];

const sectors = [
  "Agriculture & Agro-industries",
  "Education",
  "Energy & Power",
  "Financial Services",
  "Health",
  "Hospitality & Tourism",
  "ICT & Telecom",
  "Industrial & Trade",
  "Infrastructure & Transport",
  "Construction & Real Estate",
  "Water & Sanitation",
  "Utilities",
  "Other",
];

const ticketSizes = [
  "Below USD 1m",
  "USD 1m - 10m",
  "USD 10m - 50m",
  "USD 50m - 100m",
  "USD 100m - 500m",
  "Above USD 500m",
];

const invInstruments = ["Equity", "Debt", "Quasi-Equity", "Convertibles", "Guarantees", "Grants", "Blended"];

export const ProfileAdditional = ({ token }) => {
  const [edit, setEdit] = useState(false);
  const [load, setLoad] = useState(false);
  const [userInfo, setInfo] = useState(null);

  const editUserData = () => {
    setEdit(!edit);
    if (edit) {
      setLoad(true);
      Api.changeUserAnswer(token, userInfo)
        .then((_) => {
          setLoad(false);
        })
        .catch(() => setLoad(false));
    }
  };

  const disableSubmit = () => {
    return userInfo[1].fields.some((f) => {
      if (
        f.name === "Sector" ||
        f.name === "Ticket size" ||
        f.name === "Investment Instrument" ||
        f.name === "Geography (can be choosen multiple)"
      ) {
        if (!f.value.length) {
          return true;
        } else return false;
      } else return false;
    });
  };

  const onChangeDynamicType = (e, index) => {
    const _info = [...userInfo];
    _info[1].fields[index].value = Array.isArray(e) ? e : e.target.value;
    setInfo([..._info]);
  };

  useEffect(() => {
    (async () => {
      const res = await Api.getAnswers(token);
      if (res) {
        const form = JSON.parse(res.answerJson);
        setInfo(form);
      }
    })();
  }, []);

  const convertToString = (el) => {
    if (Array.isArray(el)) {
      return el.join(", ");
    } else return el;
  };

  const convertToNasted = (arr = []) => {
    const res = {};
    arr.forEach((key) => (res[key] = true));
    return res;
  };

  if (userInfo && userInfo.length) {
    const dataArr = userInfo[1].fields;
    return (
      <Col width="100%">
        {load && <Loading />}
        <InfoCard heading="Additional Information">
          {!edit ? (
            <Fragment>
              <InputsRow>
                <TextFieldComponent
                  labelFontSize={10}
                  fontSize={14}
                  height={42}
                  isInvestor
                  label="Eligible investor"
                  width="calc(50% - 15px)"
                  defaultValue={convertToString(dataArr[0].value)}
                  disabled
                />
                <TextFieldComponent
                  labelFontSize={10}
                  fontSize={14}
                  height={42}
                  isInvestor
                  label="Geography"
                  width="calc(50% - 15px)"
                  defaultValue={convertToString(dataArr[2].value)}
                  disabled
                />
              </InputsRow>
              <InputsRow>
                <TextFieldComponent
                  labelFontSize={10}
                  fontSize={14}
                  height={42}
                  isInvestor
                  label="Sector"
                  width="calc(50% - 15px)"
                  defaultValue={convertToString(dataArr[1].value)}
                  disabled
                />
                <TextFieldComponent
                  labelFontSize={10}
                  fontSize={14}
                  height={42}
                  isInvestor
                  label="Ticket size"
                  width="calc(50% - 15px)"
                  defaultValue={convertToString(dataArr[3].value)}
                  disabled
                />
              </InputsRow>
              <InputsRow mb="20px">
                <TextFieldComponent
                  labelFontSize={10}
                  fontSize={14}
                  height={42}
                  isInvestor
                  label="Investment Instrument"
                  defaultValue={convertToString(dataArr[5].value)}
                  disabled
                  width="calc(50% - 15px)"
                />
              </InputsRow>
              <Button onClick={editUserData} size={14} width={302} height={42} transparent>
                {"Edit Investment Mandate"}
              </Button>
            </Fragment>
          ) : (
            <Fragment>
              <InputsRow mb="24px">
                <SelectComponent
                  edit={edit}
                  values={eligible}
                  width="calc(50% - 15px)"
                  value={dataArr[0].value}
                  onChange={(e) => onChangeDynamicType(e, 0)}
                  top={62}
                  label="Eligible investor"
                  isInvestor
                  fSize={14}
                />
                <SelectorWrapper width="calc(50% - 15px)">
                  <span>Geography</span>
                  <NestedSelect
                    edit={edit}
                    _selected={convertToNasted(dataArr[2].value)}
                    onChange={(e) => onChangeDynamicType(e, 2)}
                    mBottom="0px"
                    inProfile
                  />
                </SelectorWrapper>
              </InputsRow>
              <InputsRow mb="12px">
                <SelectorWrapper width="calc(50% - 15px)">
                  <span>Sector</span>
                  <SelectComponent
                    edit={edit}
                    multiple
                    values={sectors}
                    defaultValue={dataArr[1].value}
                    onChange={(e) => onChangeDynamicType(e, 1)}
                    top={66}
                    label="Choose sectors from list"
                    markAll
                  />
                </SelectorWrapper>
                <SelectorWrapper width="calc(50% - 15px)">
                  <span>TICKET SIZE</span>
                  <SelectComponent
                    edit={edit}
                    multiple
                    values={ticketSizes}
                    defaultValue={dataArr[3].value}
                    onChange={(e) => onChangeDynamicType(e, 3)}
                    top={66}
                    label="Choose ticket sizes from list"
                    markAll
                  />
                </SelectorWrapper>
              </InputsRow>
              <InputsRow mb="8px">
                <SelectorWrapper width="calc(50% - 15px)">
                  <span>Investment Instrument</span>
                  <SelectComponent
                    edit={edit}
                    multiple
                    values={invInstruments}
                    defaultValue={dataArr[5].value}
                    onChange={(e) => onChangeDynamicType(e, 5)}
                    top={66}
                    label="Choose Investment Instruments from list"
                    markAll
                  />
                </SelectorWrapper>
              </InputsRow>
              <Button onClick={editUserData} size={14} width={302} height={42} disabled={disableSubmit()}>
                {"Save Changes"}
              </Button>
            </Fragment>
          )}
        </InfoCard>
      </Col>
    );
  } else return null;
};
