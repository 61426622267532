import React, { useState, Fragment, useEffect } from "react";
import moment from "moment";

import { ExpandCard } from "../Documents/styled";
import {
  ContentWrapper,
  InfoCard,
  InfoCardHeader,
  InfoHeading,
  Row,
  InfoItem,
  InfoTxt,
  AssetName,
  ReportBtn,
  CurrenciesBlock
} from "./styled";
import { Button } from "../Offerings/styled";
import { getTrackerHTML } from "../../../../data-room-panel/helpers/fileViewer";
import { icons } from "../../../../models/icons";
import Api from "helpers/api";
import theme from "models/themes";

const Deal = ({
  token,
  e: {
    assetId,
    assetName,
    invested,
    currencyCode,
    performanceAmount,
    performancePercent,
    shares,
    sharePrice,
    tokenIdentifier
  },
  i,
  dealsCount,
  isPositive
}) => {
  const [report, setReport] = useState(null);

  useEffect(() => {
    const getReport = async () => {
      console.log(token, assetId);
      const rep = await Api.getAssetLastReport(token, assetId);
      if (rep) setReport(rep);
    };
    getReport();
  }, []);

  return (
    <InfoCard
      key={assetId}
      width="calc(50% - 10px)"
      bg={theme.mainBgAdmin}
      margin={i !== dealsCount - 1 && i !== dealsCount - 2 && "0 0 30px 0"}
    >
      <InfoCardHeader>
        <InfoHeading>{assetName}</InfoHeading>
        <InfoTxt
          bold
          color={
            isPositive === 1
              ? "#019D52"
              : isPositive === -1
              ? "#FF4343"
              : "#333333"
          }
          fSize={14}
          margin="0 0 0 12px"
          isNegative={isPositive === -1}
          none={isPositive === 0}
        >
          {performancePercent.toFixed(2)}% {icons.arrowUp}
        </InfoTxt>
        {report ? (
          <ReportBtn
            onClick={() =>
              getTrackerHTML(
                token,
                report.name,
                `Reports/${report.name}?assetId=${assetId}`,
                false,
                true,
                true,
                false
              )
            }
          >
            {icons.paper} REPORT {moment(report.dateAdded).format("DD/MM")}
          </ReportBtn>
        ) : null}
      </InfoCardHeader>
      <Row height={75} padding="18px 24px">
        <InfoItem
          heading="Invested"
          data={
            <InfoTxt bold color="#181818" fSize={14}>
              <span>{invested.toFixed(2) + " " + currencyCode}</span>
            </InfoTxt>
          }
        />
        <InfoItem
          heading="Performance"
          data={
            <InfoTxt
              bold
              color={
                isPositive === 1
                  ? "#019D52"
                  : isPositive === -1
                  ? "#FF4343"
                  : "#333333"
              }
              fSize={14}
              isNegative={isPositive === -1}
              none={isPositive === 0}
            >
              <span>{performanceAmount.toFixed(2) + " " + currencyCode} </span>
              {icons.arrowUp}
            </InfoTxt>
          }
        />
        <InfoItem
          width="20%"
          heading="Shares"
          data={
            <InfoTxt fWeight={600} color="#181818" fSize={14}>
              <span>{shares + " " + tokenIdentifier}</span>
            </InfoTxt>
          }
        />
        <InfoItem
          heading="Share price"
          data={
            <InfoTxt fWeight={600} color="#181818" fSize={14}>
              <span>{sharePrice.toFixed(2) + " " + currencyCode}</span>
            </InfoTxt>
          }
        />
      </Row>
    </InfoCard>
  );
};

export const PortfolioOverview = ({
  history,
  data: {
    investmentTotalOverview: {
      portfolio,
      invested,
      currencyCode,
      performanceAmount,
      performancePercent,
      investedIn,
      mostGainingAssetName,
      mostGainingAssetId
    },
    investmentAssetOverviews
  },
  onExchange,
  token
}) => {
  const [filter, setFilter] = useState("WEEK");
  const [currency, setCurrency] = useState(currencyCode);
  const isTotalPositive = Math.sign(performancePercent);

  const onChangeCurrency = cur => () => {
    setCurrency(cur);
    onExchange(cur);
  };

  const renderDeals = () => {
    return investmentAssetOverviews.map((e, i) => {
      const dealsCount = investmentAssetOverviews.length;
      const isPositive = Math.sign(performancePercent);
      return (
        <Deal
          key={e.assetId}
          e={e}
          i={i}
          isPositive={isPositive}
          dealsCount={dealsCount}
          token={token}
        />
      );
    });
  };

  return (
    <ExpandCard
      stopPropagation
      heading="Portfolio Overview"
      extraChildren={
        <Fragment>
          <Button
            transparent={filter !== "WEEK"}
            onClick={() => setFilter("WEEK")}
            width={100}
            height={32}
            size={12}
            margin="0 12px 0 40px"
          >
            WEEK
          </Button>
          <Button
            transparent={filter !== "MONTH"}
            onClick={() => setFilter("MONTH")}
            width={100}
            height={32}
            size={12}
            margin="0 12px 0 0"
          >
            MONTH
          </Button>
          <Button
            transparent={filter !== "6 MONTH"}
            onClick={() => setFilter("6 MONTH")}
            width={100}
            height={32}
            size={12}
            margin="0 auto 0 0"
          >
            6 MONTH
          </Button>
        </Fragment>
      }
    >
      <ContentWrapper>
        <InfoCard
          width="100%"
          bg={theme.documentBgInvestor}
          margin="0 0 20px 0"
        >
          <InfoCardHeader>
            <InfoHeading upper>
              Total OVERVIEW{" "}
              <InfoTxt
                bold
                color={
                  performancePercent > 0
                    ? "#019D52"
                    : performancePercent < 0
                    ? "#FF4343"
                    : "#333333"
                }
                fSize={14}
                margin="0 0 0 12px"
                isNegative={performancePercent < 0}
                none={performancePercent === 0}
              >
                {performancePercent.toFixed(2)}% {icons.arrowUp}
              </InfoTxt>
            </InfoHeading>
            <CurrenciesBlock>
              <Button
                transparent={currency !== "CHF"}
                onClick={onChangeCurrency("CHF")}
              >
                CHF
              </Button>
              <Button
                transparent={currency !== "GBP"}
                onClick={onChangeCurrency("GBP")}
              >
                GBP
              </Button>
              <Button
                transparent={currency !== "USD"}
                onClick={onChangeCurrency("USD")}
              >
                USD
              </Button>
              <Button
                transparent={currency !== "EUR"}
                onClick={onChangeCurrency("EUR")}
              >
                EUR
              </Button>
            </CurrenciesBlock>
          </InfoCardHeader>
          <Row height={75}>
            <Row padding="16px 24px" height={75} width="50%" border>
              <InfoItem
                heading="portfolio"
                data={
                  <InfoTxt bold color="#181818" fSize={14}>
                    <span>{portfolio.toFixed(2) + " " + currencyCode}</span>
                  </InfoTxt>
                }
              />
              <InfoItem
                heading="Invested"
                data={
                  <InfoTxt fWeight={600} color="#181818" fSize={14}>
                    <span>{invested.toFixed(2) + " " + currencyCode}</span>
                  </InfoTxt>
                }
              />
              <InfoItem
                width="22%"
                heading="Invested in"
                data={
                  <InfoTxt fWeight={600} color="#181818" fSize={14}>
                    <span>{investedIn + " assets"}</span>
                  </InfoTxt>
                }
              />
              <InfoItem
                heading="Performance"
                data={
                  <InfoTxt
                    bold
                    color={
                      isTotalPositive === 1
                        ? "#019D52"
                        : isTotalPositive === -1
                        ? "#FF4343"
                        : "#333333"
                    }
                    fSize={14}
                    isNegative={isTotalPositive === -1}
                    none={isTotalPositive === 0}
                  >
                    <span>
                      {performanceAmount.toFixed(2) + " " + currencyCode}
                      {/* {icons.arrowUp} */}
                    </span>
                    {icons.arrowUp}
                  </InfoTxt>
                }
              />
            </Row>
            <Row padding="16px 24px" height={75} width="50%">
              <InfoItem
                width="80%"
                heading="Most gaining asset"
                data={
                  mostGainingAssetName ? (
                    <AssetName
                      noMargin
                      onClick={() =>
                        history.push(
                          `/investor/offerings/companies/${mostGainingAssetId}`
                        )
                      }
                    >
                      {mostGainingAssetName}
                    </AssetName>
                  ) : (
                    "-"
                  )
                }
              />
            </Row>
          </Row>
        </InfoCard>
        {renderDeals()}
      </ContentWrapper>
    </ExpandCard>
  );
};
