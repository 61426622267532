import React, { useState, useRef, useEffect } from "react";

import { countAllCategoryQuestions, countAllRemoveCategoryQuestions } from "./helper";
import {
  ModalBackground,
  ModalWrap,
  ModalBody,
  HeadingModal,
  ModalHeader,
  ModalCategory,
  ScrollContainer,
  SeparModal,
  HeadContainer,
} from "./styled";
import { TextFieldComponent } from "../../forms";
import { Btn } from "../../../admin-layout/buttons";
import { ReactComponent as CloseIcon } from "../../../../assets/x-circle.svg";
import { ReactComponent as DelIcon } from "../../../../assets/x.svg";
import { ReactComponent as PlusCircle } from "../../../../assets/plus-circle.svg";
import { RemoveConfirmationModal } from "./RemoveConfirmModal";
import { CircularProgress } from "@material-ui/core";
import { ErrorMessage } from "../../errorMessage";
import Api from "../../../../helpers/api";

export const EditCategories = ({
  onBackdropClick,
  _categories,
  assetName,
  allQuestions,
  token,
  assetId,
  onRefresh,
}) => {
  const [name, setName] = useState("");
  const [categories, setCategories] = useState([..._categories]);
  const [added, setAdded] = useState([]);
  const [removed, setRemoved] = useState([]);
  const [conflict, setConflict] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [wait, setWait] = useState(false);
  const [error, setError] = useState(null);

  const onClose = () => {
    setConfirmOpen(!confirmOpen);
  };

  const onError = (e) => {
    setError(e);
    setWait(false);
    setConfirmOpen(false);
  };

  const scrollRef = useRef(null);

  const original = [..._categories];

  const onAdd = () => {
    setCategories([...categories, name]);
    if (!original.includes(name)) {
      setAdded([...added, name]);
    }
    if (removed.includes(name)) {
      const _removed = [...removed];
      _removed.splice(_removed.indexOf(name), 1);
      setRemoved(_removed);
    }
    setName("");

    setTimeout(() => {
      if (scrollRef && scrollRef.current) {
        scrollRef.current.scroll({
          left: 0,
          top: 1000000000,
          behavior: "smooth",
        });
      }
    }, 300);
  };

  const onRemove = (name) => () => {
    const _categories = [...categories];
    _categories.splice(_categories.indexOf(name), 1);

    setCategories([..._categories]);

    if (original.includes(name)) {
      setRemoved([...removed, name]);
    }

    if (added.includes(name) && !original.includes(name)) {
      const _added = [...added];
      _added.splice(_added.indexOf(name), 1);
      setAdded(_added);
    }
  };

  const onCategoryInput = (e) => {
    if (conflict) {
      setConflict(false);
    }
    setName(e.target.value);
  };

  const disabled = !name || categories.includes(name);

  const onConflict = () => {
    setConflict(true);
  };

  const isArrEqual = () =>
    original.length === categories.length && original.map((c) => categories.includes(c)).every((e) => e);

  const onSubmit = async () => {
    if (removed.length) {
      setConfirmOpen(true);
    } else onSendRequest();
  };

  const onSendRequest = async () => {
    if (!isArrEqual()) {
      setWait(true);
      const promises = [];
      for (let i = 0; i < added.length; i++) {
        promises.push(await Api.createQuestionCategory(token, assetId, added[i]).catch(onError));
      }
      for (let i = 0; i < removed.length; i++) {
        promises.push(await Api.delQuestionCategory(token, assetId, removed[i]).catch(onError));
      }
      Promise.all(promises)
        .then((res) => {
          if (res.every((r) => typeof r === "string")) {
            setWait(false);
            onBackdropClick();
            onRefresh();
          }
        })
        .catch(onError);
    }
  };

  const renderCategories = () => {
    return categories.map((category) => {
      return (
        <ModalCategory key={category}>
          {category}
          <span>{countAllCategoryQuestions(allQuestions, category)}</span>
          {category !== "Other" ? <DelIcon onClick={onRemove(category)} /> : null}
        </ModalCategory>
      );
    });
  };

  if (wait) {
    return (
      <ModalBackground>
        <CircularProgress style={{ margin: "auto" }} />
      </ModalBackground>
    );
  } else
    return (
      <ModalBackground onClick={onBackdropClick}>
        <ModalWrap onClick={(e) => e.stopPropagation()}>
          <ModalHeader>
            <HeadingModal small>Q&A Categories ({assetName})</HeadingModal>
            <CloseIcon onClick={onBackdropClick} />
          </ModalHeader>
          <ModalBody>
            <HeadContainer>
              <TextFieldComponent
                label="Create New category"
                placeholder="Category name"
                value={name}
                onChange={(e) => onCategoryInput(e)}
                error={conflict}
              />
              <PlusCircle onClick={disabled ? onConflict : onAdd} />
            </HeadContainer>
            <span>YOU HAVE {categories.length} CATEGORIES</span>
            <ScrollContainer height={234} ref={scrollRef}>
              {renderCategories()}
            </ScrollContainer>
            <SeparModal />
          </ModalBody>
          <ModalHeader isFooter>
            <Btn width={94} transparent onClick={onBackdropClick}>
              cancel
            </Btn>
            <Btn width={180} onClick={onSubmit}>
              SAVE CHANGES
            </Btn>
          </ModalHeader>
        </ModalWrap>
        {confirmOpen ? (
          <RemoveConfirmationModal
            onAgree={onSendRequest}
            removed={removed}
            onBackdropClick={onClose}
            count={countAllRemoveCategoryQuestions(allQuestions, removed)}
          />
        ) : null}
        {error ? (
          <ErrorMessage
            er={error}
            onClose={() => {
              onError(null);
              onBackdropClick();
            }}
          />
        ) : null}
      </ModalBackground>
    );
};
