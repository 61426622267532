import React, { useEffect, useState, Fragment } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import { getAllDeals } from "../../../../modules/dataRedux/dataAction";
import { setDefaultDealsView } from "../../../../modules/userRedux/userAction";
import {
  FilterCont,
  Wrapper,
  OffersCounter,
  ViewWrapper,
  BtnsWrapper,
  Button,
  DealsWrapper,
  EmptyCard,
} from "./styled";
import { Loader } from "../../styled/Loader";
import { Btn } from "../../../admin-layout/buttons";
import { ReactComponent as Row } from "../../../../assets/row.svg";
import { ReactComponent as Col } from "../../../../assets/col.svg";

import { AssetRow } from "./AssetRow";
import { AssetCard } from "./AssetCard";
import { TradeRow } from "./TradeRow";
import { TradeCard } from "./TradeCard";

const Offerings = ({
  history,
  match: {
    params: { filter },
  },
  assets,
  trades,
  getAllDeals,
  token,
  setDefaultDealsView,
  defaultView,
  firstDeal,
}) => {
  const [smallScreen, setSmallScreen] = useState(window.innerWidth < 1024);

  const [mount, setMount] = useState(false);
  let defaultDeals = [];

  if (!mount) {
    if (filter === "all") {
      defaultDeals = [...assets, ...trades];
    } else if (filter === "companies") {
      defaultDeals = assets;
    } else if (filter === "users") {
      defaultDeals = trades;
    }
  }

  const [offerings, setOfferings] = useState(defaultDeals);
  const [wait, setWait] = useState(false);
  const setView = (name) => {
    if (smallScreen) name = "row";
    localStorage.setItem("defaultView", name);
    setDefaultDealsView(name);
  };

  useEffect(() => {
    const handleResize = () => {
      setSmallScreen(window.innerWidth < 1200);
      if (window.innerWidth < 1200) {
        setView("row");
      }
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    let onLoader = !assets.length && !trades.length;
    if (!assets.length || !trades.length) {
      setWait(onLoader);
      getAllDeals(token, () => {
        setWait(false);
        setMount(true);
      });
    } else {
      getAllDeals(token, console.log);
    }
  }, []);

  useEffect(() => {
    if (filter === "all") {
      const deals = [...assets, ...trades];
      setOfferings(deals);
    } else if (filter === "companies") {
      setOfferings(assets);
    } else if (filter === "users") {
      setOfferings(trades);
    }
  }, [assets, trades, filter]);

  const filterOffers = (name) => history.push(`/investor/offerings/${name}`);

  const renderDeals = () => {
    return offerings.map((e, i) => {
      if (defaultView === "col") {
        if (!e.isTrade) {
          return <AssetRow key={`${e.linearId}-${i}`} item={e} token={token} history={history} />;
        } else {
          let totalShares = 0;
          let _subscribers = 0;
          try {
            const {
              raisedVsToBeRaised: { raised, toBeRaised },
              subscribers,
            } = assets.find((_e) => {
              return _e.assetProperties.assetName === e.assetProperties.assetName;
            });
            _subscribers = subscribers || 0;
            totalShares = raised + toBeRaised;
          } catch (_) {
            console.log(_);
          }

          return (
            <TradeRow
              key={`${e.linearId}-${i}`}
              item={e}
              token={token}
              history={history}
              totalShares={totalShares}
              subscribers={_subscribers}
            />
          );
        }
      } else if (defaultView === "row") {
        if (!e.isTrade) {
          return <AssetCard key={`${e.linearId}-${i}`} item={e} token={token} history={history} />;
        } else {
          let totalShares = 0;
          let _subscribers = 0;
          try {
            const {
              raisedVsToBeRaised: { raised, toBeRaised },
              subscribers,
            } = assets.find((_e) => {
              return _e.assetProperties.assetName === e.assetProperties.assetName;
            });
            _subscribers = subscribers || 0;
            totalShares = raised + toBeRaised;
          } catch (_) {
            console.log(_);
          }
          return (
            <TradeCard
              key={`${e.linearId}-${i}`}
              item={e}
              token={token}
              history={history}
              totalShares={totalShares}
              subscribers={_subscribers}
            />
          );
        }
      }
    });
  };

  if (wait) {
    return <Loader />;
  } else if (assets.length === 1 && !trades.length) {
    return <Redirect to={`/investor/offerings/companies/${firstDeal}`} />;
  } else {
    return (
      <Wrapper>
        <FilterCont>
          <OffersCounter count={offerings.length} />
          <BtnsWrapper>
            <Button size={12} width={160} height={36} hide={filter !== "all"} onClick={() => filterOffers("all")}>
              All Offerings ({assets.length + trades.length})
            </Button>
            <Button
              size={12}
              width={160}
              height={36}
              hide={filter !== "companies"}
              onClick={() => filterOffers("companies")}
            >
              From Companies ({assets.length})
            </Button>
            <Button size={12} width={160} height={36} hide={filter !== "users"} onClick={() => filterOffers("users")}>
              From Users ({trades.length})
            </Button>
          </BtnsWrapper>
          <ViewWrapper>
            <Btn width={36} height={36} transparent={defaultView !== "row"} onClick={() => setView("row")}>
              <Row style={{ marginRight: 0, width: 16, height: 16 }} />
            </Btn>
            <Btn
              width={36}
              height={36}
              transparent={defaultView !== "col"}
              onClick={() => setView("col")}
              disabled={smallScreen}
            >
              <Col style={{ marginRight: 0, width: 16, height: 16 }} />
            </Btn>
          </ViewWrapper>
        </FilterCont>
        <DealsWrapper>
          {mount && !offerings.length ? "Deals not found" : renderDeals()}
          {defaultView === "row" && (
            <Fragment>
              <EmptyCard />
              <EmptyCard />
            </Fragment>
          )}
        </DealsWrapper>
      </Wrapper>
    );
  }
};

export default connect(
  ({ userReducer: { token, defaultView }, dataReducer: { assets = [], trades = [] } }) => ({
    token,
    assets,
    trades,
    defaultView,
    firstDeal: assets.length && assets[0].linearId,
  }),
  { getAllDeals, setDefaultDealsView }
)(Offerings);
