import styled from "styled-components";
import _Select from "./select";
import { Input as _Input } from "./input";

export const Select = _Select;
export const Input = _Input;

export const Button = styled.button`
  outline: none;
  width: 180px;
  height: 32px;
  background: ${({ theme: { primary } }) => primary};
  border-radius: 4px;
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 135%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  padding: 0;
  color: #ffffff;
  border: none;
  cursor: pointer;

  & > svg {
    margin-right: 10px;
  }
`;

export const GrayButton = styled(Button)`
  background: transparent;
  color: #dfdfdf;
  border: 1px solid #dfdfdf;
  width: fit-content;
  padding: 0 30px;
`;
