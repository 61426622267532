import React, { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";

import {
  Wrapper,
  Header,
  BorderBlock,
  ColWrapper,
  Col,
  Card,
  CardHead,
  Row,
  ManagerInfo,
  Quote,
  Text,
} from "components/investor-layout/pages/Offer/styled";
import { Button } from "components/investor-layout/pages/Offerings/styled";
import { ReactComponent as BackIcon } from "assets/arrowleft.svg";
import { ReactComponent as MessageIcon } from "assets/message-circle.svg";
import { DynamicBlock } from "components/investor-layout/pages/Offer/DynamicBlock";
import { Background, Avatar, Label, Link, Info } from "./styled";
import { getSponsorDetails } from "modules/dataRedux/dataAction";
import { Loader } from "components/investor-layout/styled/Loader";

const Sponsor = ({ token, history, userName, assetId, sponsor, getSponsorDetails }) => {
  const [screenSize, setScreenSize] = useState(window.innerWidth);

  useEffect(() => {
    if (!sponsor) getSponsorDetails(token, userName);
    const onResize = () => {
      setScreenSize(window.innerWidth);
    };
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  const onRedirect = (link) => () => {
    history.push(link);
  };

  const renderDynamic = () => {
    const { additionalInformation } = sponsor;
    if (additionalInformation) {
      return additionalInformation
        .filter((e) => e.header && e.text.replace(/<[^>]*>/g, ""))
        .map((e) => <DynamicBlock block={e} key={e.header} />);
    }
  };

  const renderMetrics = () => {
    const { userDynamicProperties } = sponsor;
    if (userDynamicProperties && userDynamicProperties.length) {
      return (
        <Card padding="25px">
          <Row>
            {userDynamicProperties.map(({ header, text }) => (
              <ManagerInfo key={header} total={userDynamicProperties.length} heading={header} data={text} />
            ))}
          </Row>
        </Card>
      );
    }
  };

  const MainInfo = ({ sponsor: { countryOfRegistration } }) => {
    return (
      <Fragment key="main-info">
        <Card padding="25px">
          <Button fullWidth size={16} height={42} onClick={onRedirect(`/investor/chat/${assetId}`)}>
            <MessageIcon style={{ marginRight: 10, width: 16, height: 16 }} />
            SEND MESSAGE
          </Button>
        </Card>
        <Card padding="20px 25px 29px">
          <CardHead fWeight={600} margin="0 0 20px">
            Main Information
          </CardHead>
          <Col>
            <Label>Country of registration</Label>
            <Info>{countryOfRegistration || "-"}</Info>
          </Col>
        </Card>
      </Fragment>
    );
  };

  const AdditionalCard = ({ sponsor: { webSite, phoneNumber, username } }) => {
    return (
      <Card padding="20px 25px 30px">
        <CardHead fWeight={600} margin="0 0 20px">
          Contact Information
        </CardHead>
        <Col margin="0 0 18px">
          <Label>Phone number</Label>
          <Info>{phoneNumber ? `+${phoneNumber}` : "-"}</Info>
        </Col>
        <Col margin="0 0 18px">
          <Label>Web-site</Label>
          {webSite ? (
            <Link target="_blank" href={webSite}>
              {webSite}
            </Link>
          ) : (
            <Info>-</Info>
          )}
        </Col>
        <Col>
          <Label>E-mail</Label>
          <Link>{username || "-"}</Link>
        </Col>
      </Card>
    );
  };

  if (sponsor) {
    const { fullName, userBackgroundLogo, userLogo, slogan, userDescription } = sponsor;
    return (
      <Wrapper>
        <Header>
          <BorderBlock>
            <Button
              transparent
              height={36}
              width={157}
              size={12}
              onClick={onRedirect(`/investor/offerings/companies/${assetId}`)}
            >
              <BackIcon style={{ marginRight: 8 }} />
              Back to Deal
            </Button>
          </BorderBlock>
          <CardHead fWeight={600} size={16} margin="0 auto 0 30px">
            Originator «{fullName}»
          </CardHead>
        </Header>
        <ColWrapper>
          <Col width={screenSize > 1280 ? "calc(100% - 470px)" : "100%"}>
            <Background token={token} logo={userBackgroundLogo} />
            <Card padding="25px">
              <Row margin="0 0 24px" align="flex-start">
                <Col width="92px">
                  <Avatar token={token} logo={userLogo} />
                </Col>
                <Col width="calc(100% - 117px)">
                  <CardHead size={24} margin="0 0 14px">
                    {fullName}
                  </CardHead>
                  {slogan ? <Quote isLastItem text={slogan} /> : null}
                </Col>
              </Row>
              <Text dangerouslySetInnerHTML={{ __html: userDescription }} />
            </Card>
            {screenSize > 1280 ? null : (
              <Row margin="0 0 20px">
                <Col width="calc(50% - 15px)">
                  <AdditionalCard sponsor={sponsor} />
                </Col>
                <Col width="calc(50% - 15px)">
                  <MainInfo sponsor={sponsor} />
                </Col>
              </Row>
            )}
            {renderMetrics()}
            {renderDynamic()}
          </Col>
          {screenSize > 1280 ? (
            <Col width="440px">
              <MainInfo sponsor={sponsor} />
              <AdditionalCard sponsor={sponsor} />
            </Col>
          ) : null}
        </ColWrapper>
      </Wrapper>
    );
  } else return <Loader />;
};

export default connect(
  (
    { dataReducer: { sponsorsDetails }, userReducer: { token } },
    {
      match: {
        params: { userName, assetId },
      },
    }
  ) => ({ userName, token, assetId, sponsor: sponsorsDetails[userName] }),
  { getSponsorDetails }
)(Sponsor);
