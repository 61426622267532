import React, { Fragment, useState, useEffect } from "react";
import moment from "moment";

import {
  ModalBackground,
  SendEmailWrapper,
  ModalHeader,
  Heading,
  InputsWrapper,
  InputsRow,
  SeparatorLine,
  BtnBlock
} from "./styled";
import { Btn } from "../../buttons";
import { ReactComponent as CloseIcon } from "../../../../assets/x-circle.svg";
import { ReactComponent as CheckIcon } from "../../../../assets/check.svg";
import { ReactComponent as SuspendIcon } from "../../../../assets/slash.svg";
import { ReactComponent as Arrow } from "../../../../assets/Arrow-right 2.svg";
import { TextFieldComponent } from "components/admin-layout/forms";
import Api from "helpers/api";
import { WaitBlock } from "../DashBoard/styled";
import {
  ErrorMessage,
  ApproveRequestPortal,
  RejectRequestPortal
} from "components/admin-layout/errorMessage";
import { SelectInstitution } from "./SelectInstitution";

import { icons } from "../../../../models/icons";

export const RequestInfo = ({
  user,
  token,
  onBackdropClick,
  history,
  onRefresh
}) => {
  const [step, setStep] = useState(1);
  const [load, setLoad] = useState(true);
  const [institutions, setInstitutions] = useState([]);
  const [error, setError] = useState(null);
  const [waitResponse, setWaitResponse] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [confirmRej, setConfirmRej] = useState(false);

  const [selectedInst, setSelectedInst] = useState(null);
  const [alreadyInst, setAlreadyInst] = useState(null);

  const {
    firstName,
    lastName,
    loginDate,
    email,
    countryOfResidence,
    telephoneNumber,
    institutionName,
    role,
    sector,
    serviceType,
    investorTypeText,
    address,
    mailingAddress
  } = user;

  const onChangeConfirm = () => {
    setConfirm(!confirm);
  };

  const onChangeConfirmRej = () => {
    setConfirmRej(!confirmRej);
  };

  const nextStep = () => {
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
    setSelectedInst(null);
  };

  const approveUser = async () => {
    setWaitResponse(true);
    const res = await Api.approveUserNew(token, email, selectedInst.id).catch(
      onError
    );
    if (res) {
      onCloseSuccesfull();
      setWaitResponse(false);
    }
  };

  const rejectUser = async () => {
    setWaitResponse(true);
    const res = await Api.regectUser(token, email).catch(onError);
    if (res) {
      onCloseSuccesfull();
      setWaitResponse(false);
    }
  };

  const onCloseSuccesfull = () => {
    onRefresh();
    if (confirm) onChangeConfirm();
    if (confirmRej) onChangeConfirmRej();
  };

  const onError = e => {
    setError(e);
    setLoad(false);
    setWaitResponse(false);
  };

  const onAddInstitution = () => {
    history.push(
      `/admin/user/institution/create/${lastName}/${firstName}/${email}/${countryOfResidence}/${institutionName}/${telephoneNumber}#${JSON.stringify(
        { address, mailingAddress }
      )}`
    );
  };

  useEffect(() => {
    const getInit = async () => {
      const res = await Api.getInstitutionAll(token).catch(onError);
      if (res) {
        setInstitutions(res);
        setLoad(false);
        const myInst = res.find(inst => inst.name === institutionName);
        if (myInst) {
          setAlreadyInst(myInst);
        }
      }
    };
    getInit();
  }, []);

  const renderInfo = () => {
    return (
      <Fragment>
        <ModalHeader>
          <Heading>
            {firstName} {lastName}
          </Heading>
          <CloseIcon onClick={onBackdropClick} />
        </ModalHeader>
        <span style={{ padding: "0 25px" }}>
          {moment(loginDate).format("DD MMM YYYY, hh:mm A")}
        </span>
        <InputsWrapper padding="15px 25px">
          <InputsRow>
            <TextFieldComponent
              label="Type"
              width="calc(50% - 17.5px)"
              value={role}
            />
            <TextFieldComponent
              label="Residence"
              width="calc(50% - 17.5px)"
              value={countryOfResidence}
            />
          </InputsRow>
          <InputsRow>
            <TextFieldComponent
              label="E-mail"
              width="calc(50% - 17.5px)"
              defaultValue={email}
              disabled
            />
            <TextFieldComponent
              label="Telephone number"
              width="calc(50% - 17.5px)"
              value={telephoneNumber}
            />
          </InputsRow>
          <SeparatorLine margin="5px 0 25px 0" />
          {investorTypeText || sector || serviceType ? (
            <InputsRow>
              <TextFieldComponent
                label="Type of investor"
                label={
                  investorTypeText
                    ? "Type of investor"
                    : sector
                    ? "Sector"
                    : serviceType
                    ? "Type of service"
                    : null
                }
                value={
                  investorTypeText
                    ? investorTypeText
                    : sector
                    ? sector
                    : serviceType
                    ? serviceType
                    : null
                }
              />
            </InputsRow>
          ) : null}
          {institutionName ? (
            <InputsRow>
              <TextFieldComponent label="Company" value={institutionName} />
            </InputsRow>
          ) : null}
        </InputsWrapper>
        <ModalHeader isFooter>
          <BtnBlock>
            <Btn onClick={onBackdropClick} hide width={94} margin="0 10px 0 0">
              cancel
            </Btn>
            <Btn onClick={onChangeConfirmRej} error width={94}>
              <SuspendIcon />
              reject
            </Btn>
          </BtnBlock>
          {institutionName ? (
            <Btn onClick={nextStep} width={200} rightIcon>
              CONFIRM & NEXT STEP
              <Arrow />
            </Btn>
          ) : (
            <Btn onClick={onChangeConfirm} fullGreen width={200}>
              <CheckIcon />
              approve
            </Btn>
          )}
        </ModalHeader>
      </Fragment>
    );
  };

  const chooseInst = () => {
    return (
      <Fragment>
        <ModalHeader>
          <Heading>Choose an Institution</Heading>
          <CloseIcon onClick={onBackdropClick} />
        </ModalHeader>
        <span style={{ padding: "0 25px" }}>
          for {firstName} {lastName}
        </span>
        <InputsWrapper padding="15px 25px">
          <InputsRow>
            <TextFieldComponent
              label="Company name (submitted by the user)"
              value={institutionName}
              disabled
            />
          </InputsRow>
          <SeparatorLine margin="0 0 25px 0" />
          <SelectInstitution
            institutions={institutions}
            userInstitution={institutionName}
            onSetInstitution={setSelectedInst}
            onAddInstitution={onAddInstitution}
            role={role}
          />
        </InputsWrapper>
        <ModalHeader isFooter>
          <Btn onClick={prevStep} hide width={94}>
            go back
          </Btn>
          <BtnBlock>
            {role !== "FACILITATOR" ? (
              <Btn
                transparent
                width={160}
                margin="0 10px 0 0"
                onClick={onAddInstitution}
              >
                {icons.plusCircle}
                NEW INSTITUTION
              </Btn>
            ) : null}
            <Btn
              fullGreen
              width={160}
              disabled={!selectedInst}
              onClick={onChangeConfirm}
            >
              CHOOSE & APPROVE
            </Btn>
          </BtnBlock>
        </ModalHeader>
      </Fragment>
    );
  };

  if (load) {
    return <WaitBlock />;
  } else
    return (
      <ModalBackground onClick={onBackdropClick}>
        <SendEmailWrapper onClick={e => e.stopPropagation()}>
          {step === 1 ? renderInfo() : chooseInst()}
        </SendEmailWrapper>
        {error && <ErrorMessage er={error} onClose={() => setError(null)} />}
        {confirm ? (
          <ApproveRequestPortal
            heading="Approve Request"
            onClose={onChangeConfirm}
            onSubmit={approveUser}
            message={`Are you sure you want <br/>to approve ${firstName} ${lastName}?`}
            load={waitResponse}
          />
        ) : null}
        {confirmRej ? (
          <RejectRequestPortal
            heading="Reject Request"
            onClose={onChangeConfirmRej}
            onSubmit={rejectUser}
            message={`Are you sure you want <br/>to reject ${firstName} ${lastName}?`}
            load={waitResponse}
          />
        ) : null}
      </ModalBackground>
    );
};
