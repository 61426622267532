import React, { useState, useEffect } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import moment from "moment";
import "react-circular-progressbar/dist/styles.css";

import {
  AssetRowCard,
  ImgCont,
  InfoBlock,
  ChartBlock,
  Labels,
  BorderBlock,
  LinkBlock,
  SponsorBlock,
  AssetName,
  SmallDesc,
  Row,
  Col,
  InfoRow,
  Button,
  Sponsor,
  __ChartBg,
  ReminingChart,
  ChartData,
  ExistedChart,
  ExistedLabel,
  ExistedVal,
  NoInfo,
  FundRaisingChart,
  ChatrLayout,
} from "./styled";
import Api from "../../../../helpers/api";
import { currenciesModel } from "../../../../models/currenciesModel";
import { nFormatter } from "../../../../helpers/";
import theme from "models/themes";
import { Fragment } from "react";

export const AssetRow = ({
  history,
  item: {
    linearId,
    assetProperties: {
      images,
      category,
      assetLocation,
      assetName,
      smallDescription,
      dynamicFinancialJson,
      dealsType,
      openEndFund,
      currentlyFundraising,
    },
    raisedVsToBeRaised: { raised, toBeRaised },
    sponsors,
    subscribers,
    assetFinancialProperty: { timeOfEnd, baseCurrency },
    roundsInfo,
  },
  token,
}) => {
  const [image, setImage] = useState(null);
  const data = JSON.parse(dynamicFinancialJson || "[]");

  const isExisted = dealsType === "EXISTED_DEAL";

  useEffect(() => {
    if (!image && images && images[0]) {
      const { secureHash, fileNameWithExtension } = images[0];
      Api.getAttachment(token, secureHash, fileNameWithExtension).then((blob) => setImage(blob));
    }
  });

  const progress = (parseFloat(raised) / (parseFloat(toBeRaised) + parseFloat(raised))).toFixed(3) * 100;

  const isDealExpired = (moment(timeOfEnd).isBefore(Date.now()) || raised === toBeRaised + raised) && !isExisted;

  return (
    <AssetRowCard expired={isDealExpired}>
      <ImgCont>
        {image && <img src={image} alt="asset" />}
        <Labels blue={isExisted && !currentlyFundraising} category={category} location={assetLocation} />
      </ImgCont>
      <InfoBlock>
        <BorderBlock height={114} padding="15px 25px">
          <AssetName>{assetName}</AssetName>
          <Row height="44px">
            <Col>
              {data[0] ? <InfoRow label={data[0].name} val={data[0].value} /> : null}
              {data[1] ? <InfoRow label={data[1].name} val={data[1].value} /> : null}
            </Col>
            <Col>
              {data[2] ? <InfoRow label={data[2].name} val={data[2].value} /> : null}
              {data[3] ? <InfoRow label={data[3].name} val={data[3].value} /> : null}
            </Col>
          </Row>
        </BorderBlock>
        <BorderBlock height={81} padding="12px 25px">
          <SmallDesc dangerouslySetInnerHTML={{ __html: smallDescription }} />
        </BorderBlock>
        <div style={{ display: "flex", height: 65 }}>
          <SponsorBlock>{sponsors.length ? <Sponsor token={token} sponsor={sponsors[0]} /> : null}</SponsorBlock>
          <LinkBlock>
            <Button
              width={190}
              height={32}
              size={12}
              tTransform="uppercase"
              fWeight="bold"
              blue={isExisted && !currentlyFundraising}
              onClick={() => history.push(`/investor/offerings/companies/${linearId}`)}
            >
              More
            </Button>
          </LinkBlock>
        </div>
      </InfoBlock>
      <ChartBlock>
        {openEndFund ? (
          <NoInfo width="calc(100% - 32px)" text="The raised amount will be disclosed once the funding has ended" />
        ) : (
          <Fragment>
            {!isExisted ? (
              <__ChartBg>
                <CircularProgressbar
                  value={progress}
                  strokeWidth={8}
                  styles={buildStyles({
                    strokeLinecap: "butt",
                    pathTransitionDuration: 1,
                    // Colors
                    pathColor: theme.primary,
                    trailColor: theme.primaryLight,
                    backgroundColor: theme.primary,
                  })}
                />
                <ReminingChart
                  roundsInfo={roundsInfo}
                  isExisted={isExisted}
                  percent={progress.toFixed(progress === 100 ? 0 : 1)}
                />
              </__ChartBg>
            ) : currentlyFundraising ? (
              <FundRaisingChart>
                <ExistedLabel>DEAL SIZE</ExistedLabel>
                <ExistedVal
                  currentlyFundraising={currentlyFundraising}
                  dangerouslySetInnerHTML={{
                    __html: `${
                      currenciesModel[baseCurrency] ? currenciesModel[baseCurrency].symbol : baseCurrency
                    }${nFormatter(roundsInfo[0].companyValuation, 1)}`,
                  }}
                />
              </FundRaisingChart>
            ) : (
              <ExistedChart>
                <ExistedLabel>Valuation</ExistedLabel>
                <ExistedVal
                  currentlyFundraising={currentlyFundraising}
                  dangerouslySetInnerHTML={{
                    __html: `${
                      currenciesModel[baseCurrency] ? currenciesModel[baseCurrency].symbol : baseCurrency
                    }${nFormatter(roundsInfo[0].companyValuation, 1)}`,
                  }}
                />
              </ExistedChart>
            )}

            {currentlyFundraising ? null : (
              <ChartData
                baseCurrency={baseCurrency}
                subscribers={subscribers || 0}
                raised={raised}
                toBeRaised={toBeRaised}
                isExisted={isExisted}
                roundsInfo={roundsInfo}
              />
            )}
          </Fragment>
        )}
      </ChartBlock>
    </AssetRowCard>
  );
};
