import React, { Fragment, useState, useEffect } from "react";

import { TRow, TCeil, FolderName } from "./styled";

import { FileComponent } from "./fileComponent";
import { FolderOpen, FolderClose, Eye, Arrow } from "components/common/icons-primary";

export const Folder = ({ folder: { name, files, section }, onBackdropClick, setSelectedFile, _file }) => {
  const [open, setOpen] = useState(false);

  useEffect(() => () => console.log("UNMOUNT"), []);

  useEffect(() => {
    if (_file.folderName && _file.folderName === name) {
      setOpen(!open);
    }
  }, []);

  const renderDocs = () => {
    return files.map((e, i) => (
      <FileComponent
        _file={_file}
        key={`${e.name}-${i}-${name}`}
        file={e}
        i={i}
        section={section}
        folderName={name}
        onBackdropClick={onBackdropClick}
        setSelectedFile={setSelectedFile}
      />
    ));
  };

  return (
    <Fragment>
      <TRow onClick={() => setOpen(!open)}>
        <TCeil full>
          <FolderName open={open}>
            <Arrow />
            {!open ? name !== "Unsorted Files" ? <FolderClose /> : <Eye /> : <FolderOpen />}
            <span>
              {section != 0 ? `${section}. ` : null}
              {name}
            </span>
          </FolderName>
        </TCeil>
      </TRow>

      {open ? renderDocs() : null}
    </Fragment>
  );
};
