import React, { useEffect, useState } from "react";
import { CircularProgress } from "@material-ui/core";
import moment from "moment";

import Api from "../../../../helpers/api";
import { HeaderTD } from "../../dealTypeCard";
import { Btn } from "../../buttons";
import { TableRow, Email, TableCol, Text, VeriFyStatus, UserAvatar, DotsButton } from "./styled";
import { SendEmailMessage } from "../../actionMessage";
import { numberWithCommas } from "../../../../helpers";
import { SuccessMessage } from "../../errorMessage";
import theme from "models/themes";

import { useOnlineStates } from "modules/socket/socket.io";

export const UserRow = ({
  id,
  userLogoHash,
  userLogoFileName,
  roleName,
  fullName,
  username = "",
  lastLoginDate,
  enabled,
  kyced,
  token,
  goToDetails,
  goToDetailsSponsor,
  toggleModalSendEmail,
  toggleAssignModal,
  groupInfo,
  userId,
  groupId,
  onRefresh,
  investorType,
  gender,
  onSuspendUser,
}) => {
  const [shares, setShares] = useState(null);

  const [messageOpen, setMessageOpen] = useState(false);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");
  const [succesUpdate, setSuccessUpdate] = useState("");
  const handleAssignModal = () => toggleAssignModal(id);

  const clearMessegeData = () => {
    error && setError(false);
    setMessageOpen(false);
    setMessage("");
  };

  const onSuccessDelete = () => {
    setMessage(`User successfully deleted from group.`);
    setMessageOpen(true);
  };

  const onSuccessResetPassword = () => {
    setMessage(`Email with password recovery link sent to <b>${fullName}</b>.`);
    setMessageOpen(true);
  };

  const onFailureResetPassword = () => {
    setMessageOpen(true);
    setError(true);
    setMessage("Operation went wrong, try to repeat or refresh your page");
  };

  useEffect(() => {
    Api.getUserFinancialInfo(token, username)
      .then((data) => setShares(data.shares))
      .catch(() => setShares(0));
  }, []);

  return (
    <TableRow key={`user-id-${id}`} justify="space-between">
      <HeaderTD width="220px" boxSizing="border-box" padding="0 0 0 15px">
        <UserAvatar
          token={token}
          fileName={userLogoFileName}
          hash={userLogoHash}
          investorType={investorType}
          gender={gender}
          connectStatus={useOnlineStates(token, username)}
        />
        <TableCol heading={fullName} text={investorType ? investorType : roleName} />
      </HeaderTD>
      <HeaderTD boxSizing="border-box" padding="0 5px" width="245px">
        <Email>{username}</Email>
      </HeaderTD>
      <HeaderTD boxSizing="border-box" width="160px" padding="0 5px">
        <VeriFyStatus enabled={enabled} kyced={kyced} />
      </HeaderTD>
      <HeaderTD boxSizing="border-box" padding="0 5px" width="150px">
        {typeof shares == "number" ? (
          <Text>{shares !== 0 && `${numberWithCommas(shares.toFixed(2))} GBP`} </Text>
        ) : (
          <div style={{ color: theme.primary, margin: "auto" }}>
            <CircularProgress color="inherit" size={24} />
          </div>
        )}
      </HeaderTD>
      <HeaderTD boxSizing="border-box" padding="0 5px" width="122px">
        {lastLoginDate ? (
          <TableCol
            heading={moment(new Date(lastLoginDate)).format("DD MMM YYYY")}
            text={moment(new Date(lastLoginDate)).format("hh:mm a")}
          />
        ) : (
          ""
        )}
      </HeaderTD>
      <HeaderTD boxSizing="border-box" width="295px" padding="0 5px">
        <Btn
          onClick={() => toggleModalSendEmail(fullName, username)}
          transparent
          width={94}
          margin="0 12px 0 0"
          disabled={!enabled}
          hide={!enabled}
        >
          send e-mail
        </Btn>
        <Btn
          width={94}
          margin="0 12px 0 0"
          onClick={roleName && roleName === "SPONSOR" ? goToDetailsSponsor : goToDetails}
        >
          more
        </Btn>
        <DotsButton
          role={roleName}
          onSuspendUser={onSuspendUser}
          investorType={investorType}
          _userId={id}
          userId={userId}
          groupId={groupId}
          groupInfo={groupInfo}
          onError={onFailureResetPassword}
          onSuccess={onSuccessResetPassword}
          onSuccessDel={onSuccessDelete}
          link={investorType === "INSTITUTION" ? `/admin/users/institution/${id}` : `/admin/user/${username}/${id}`}
          email={username}
          toggleAssignModal={handleAssignModal}
          token={token}
          enabled={enabled}
        />
      </HeaderTD>

      <SendEmailMessage
        isOpen={messageOpen}
        isError={error}
        customMessage={message}
        onBackDpopClick={() => {
          clearMessegeData();
          onRefresh();
        }}
      />

      {succesUpdate ? <SuccessMessage message={succesUpdate} onClose={() => setSuccessUpdate("")} /> : null}
    </TableRow>
  );
};
