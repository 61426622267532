import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";

import {
  DealBody,
  DealTD,
  ButtonsContainer,
  DealStatus,
} from "../../dealTypeCard";
import { AssetLogo } from "../../avatar";
import { Btn } from "../../buttons";
import { ConfirmMessagePortal, ErrorMessagePortal } from "../../errorMessage";
import Api from "../../../../helpers/api";

export const UnfinishedDeals = ({ rows, token, callBack, unFinalized }) => {
  const data = rows
    .sort((a, b) =>
      moment(a.lastUpdateDate).format("x") <
      moment(b.lastUpdateDate).format("x")
        ? 1
        : -1
    )
    .map(({ id, content, lastUpdateDate }) => ({
      id,
      content: JSON.parse(content),
      lastUpdateDate,
    }));

  const [modalOpen, setModalOpen] = useState(false);
  const [dealInfo, setDealInfo] = useState(null);
  const [error, setError] = useState(null);
  const [wait, setWait] = useState(false);

  const onOpenDelete = (assetName, lastUpdateDate, id) => () => {
    setModalOpen(true);
    setDealInfo({ assetName, lastUpdateDate, id });
  };

  const onAbort = () => {
    setModalOpen(false);
    setDealInfo(null);
  };

  const onDelete = (id) => async () => {
    setWait(true);
    const res = await Api.deleteUnfinishedDeal(token, id).catch((e) => {
      setModalOpen(false);
      setDealInfo(null);
      setError(e);
      setWait(false);
    });
    if (res) {
      setWait(false);
      setModalOpen(false);
      setDealInfo(null);
      callBack();
    }
  };

  const renderUnfinalized = () => {
    return unFinalized.map(
      ({
        linearId,
        assetProperties: { assetName, images },
        visibilityProperty,
      }) => (
        <DealBody key={`${linearId}`}>
          <DealTD center>
            <AssetLogo isUnfinished images={images} token={token} />
          </DealTD>
          <DealTD block width="20%" bold lower>
            {assetName}
            <DealStatus draft={!visibilityProperty.isActive}>
              unfinalized
            </DealStatus>
          </DealTD>
          <DealTD medium></DealTD>
          <DealTD></DealTD>
          <DealTD></DealTD>
          <DealTD width="calc(7% + 100px)"></DealTD>
          <ButtonsContainer isUnfinished>
            <Link
              style={{ marginLeft: 0 }}
              to={`/admin/deals/edit/${linearId}`}
            >
              <Btn margin="0 15px 0 0" white>
                Edit
              </Btn>
            </Link>
            <Link
              style={{ marginLeft: 0 }}
              to={`/admin/data-room/deal/${linearId}/file-manager`}
            >
              <Btn>data room</Btn>
            </Link>
          </ButtonsContainer>
        </DealBody>
      )
    );
  };

  const renderUnfinished = () => {
    return data.map(
      ({
        content: {
          assetProperties: { assetName, images },
          visibilityProperty,
        },
        lastUpdateDate,
        id,
      }) => (
        <DealBody publish={false} key={`${assetName}-${lastUpdateDate}-${id}`}>
          <DealTD center>
            <AssetLogo isUnfinished images={images} token={token} />
          </DealTD>
          <DealTD block width="20%" bold lower>
            {assetName}
            <DealStatus draft={!visibilityProperty.isActive}>
              unfinished
            </DealStatus>
          </DealTD>
          <DealTD medium></DealTD>
          <DealTD></DealTD>
          <DealTD></DealTD>
          <DealTD width="calc(7% + 100px)">
            {lastUpdateDate
              ? moment(lastUpdateDate).format("DD MMM YYYY, hh:mm")
              : null}
          </DealTD>
          <ButtonsContainer isUnfinished>
            <Link style={{ marginLeft: 0 }} to={`/admin/deals/continue/${id}`}>
              <Btn margin="0 15px 0 0" white>
                Edit
              </Btn>
            </Link>
            <Btn onClick={onOpenDelete(assetName, lastUpdateDate, id)}>
              Delete
            </Btn>
          </ButtonsContainer>
        </DealBody>
      )
    );
  };

  return (
    <Fragment>
      {renderUnfinished()}
      {renderUnfinalized()}
      {modalOpen && dealInfo ? (
        <ConfirmMessagePortal
          heading="Delete unfinished deal"
          message={`Are you sure to delete <b>${
            dealInfo.assetName ? dealInfo.assetName : "deal"
          }</b>  from  ${moment(dealInfo.lastUpdateDate).format(
            "DD MMM YYYY, hh:mm"
          )}?`}
          onClose={onAbort}
          onSubmit={onDelete(dealInfo.id)}
          load={wait}
        />
      ) : null}
      {error && (
        <ErrorMessagePortal er={error} onClose={() => setError(null)} />
      )}
    </Fragment>
  );
};
