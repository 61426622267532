import React from "react";

import { Card, SponsorBlock, Row, Line, Text, ManagerInfo } from "./styled";
import { Button } from "../Offerings/styled";

export const Sponsor = ({ token, manager, assetId, history, isPreview = false }) => {
  const renderBlocks = () => {
    if (manager.userDynamicProperties) {
      return manager.userDynamicProperties.map(({ header, text }) => (
        <ManagerInfo key={header} total={manager.userDynamicProperties.length} heading={header} data={text} />
      ));
    }
  };

  return (
    <Card padding="20px 25px 30px">
      <Row>
        <SponsorBlock token={token} manager={manager} />
        <Button
          size={12}
          width={223}
          height={36}
          transparent
          onClick={
            isPreview ? undefined : () => history.push(`/investor/offerings/sponsor/${manager.username}/${assetId}`)
          }
        >
          MORE ABOUT ORIGINATOR
        </Button>
      </Row>
      <Line />
      <Text dangerouslySetInnerHTML={{ __html: manager.userDescription }} />
      <Row margin="20px 0 0">{renderBlocks()}</Row>
    </Card>
  );
};
