import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import { setPaymentResponseData } from "../../../../modules/userRedux/userAction";

import Api from "../../../../helpers/api";

import {
  Wrapper,
  Col,
  Card,
  Header,
  BorderBlock,
  Row,
  Heading,
  Text,
  Line
} from "../Investment/styled.js";
import { Stepper } from "../SignContract/styled";
import { Button } from "../../../investor-layout/pages/Offerings/styled";
import { ReactComponent as BackIcon } from "../../../../assets/arrowleft.svg";
import { Loader } from "../../styled/Loader";
import { TextFieldComponent } from "../../../admin-layout/forms";

import { CountDown } from "../Investment/CountDown";
import { Raised } from "../Investment/Raised";
import { Price } from "../Investment/Price";
import { PaymentMessage, PayOnlineModal } from "../../ActionMessages";
import { numberWithCommas } from "helpers";

export const PaymentDetails = ({
  token,
  fullName,
  history,
  paymentData,
  setPaymentResponseData,
  match: {
    params: { id }
  },
  paymentDetails
}) => {
  const [responseData, setResponseData] = useState(null);
  const [bankDetails, setBankDetails] = useState(null);
  const [wait, setWait] = useState(false);
  const [error, setError] = useState(false);
  const [modalOpen, showModal] = useState(false);
  const [payOnlineModalOpen, setPayOlineModalOpen] = useState(false);

  const getInfo = async () => {
    await Api.getDocuSignInfo(
      token,
      paymentDetails.envelopeId,
      paymentDetails.token
    ).then(json => setResponseData(json));
    await Api.getBankDetails(token).then(json =>
      setBankDetails(
        json.find(json => json.accountNumber === paymentData.activeAccount)
      )
    );
  };

  const changeOnlineModalStatus = () =>
    setPayOlineModalOpen(!payOnlineModalOpen);

  useEffect(() => {
    if ((!responseData || !bankDetails) && paymentDetails) {
      getInfo();
    }
  }, []);

  const sendRequest = async () => {
    const date = Date.now();
    setWait(true);
    const {
      docType,
      assetId,
      spaProperties: { currency, quantity }
    } = responseData;
    if (docType === "INVEST_BANK") {
      await Api.buyViaBank(
        token,
        assetId,
        currency,
        quantity,
        bankDetails.accountNumber
      )
        .then(res => {
          const data = { ...res };
          data.txDate = date;
          data.accountNumberUser = bankDetails.accountNumber;
          setPaymentResponseData(data);
          showModal(true);
        })
        .catch(() => {
          setError(true);
          setWait(false);
        });
    } else if (docType === "INVEST_ONLINE") {
      await Api.buyOnline(token, assetId, currency, quantity)
        .then(res => {
          history.push("/investor/transactions/all/all");
          console.log(res);
        })
        .catch(() => {
          setError(true);
          setWait(false);
          setPayOlineModalOpen(false);
        });
    }
    setWait(false);
  };

  if ((!responseData || !bankDetails) && paymentData && paymentDetails) {
    return <Loader />;
  } else if (paymentData && paymentDetails && bankDetails) {
    const {
      price,
      type,
      assetSymbol,
      assetName,
      amount,
      valuation,
      timeOfEnd,
      raised,
      toBeRaised,
      baseCurrency
    } = paymentData;
    const { bankName, bic, iban } = bankDetails;
    return (
      <Wrapper>
        {wait && <Loader zIndex={100} />}
        <Header>
          <BorderBlock>
            <Button
              transparent
              height={36}
              width={176}
              size={12}
              onClick={() =>
                history.push(`/investor/offerings/sign-contract/${id}`)
              }
            >
              <BackIcon style={{ marginRight: 8 }} />
              Back to sign
            </Button>
          </BorderBlock>
          <Heading>Invest in {assetName}</Heading>
        </Header>
        <Col width="calc(100% - 470px)">
          <Card>
            <Row margin="0 0 14px 0">
              <Heading>Bank Transfer to Invest</Heading>
              <Text
                fSize={18}
                color="rgba(24, 24, 24, 0.4)"
                margin="0 0 0 auto"
              >
                2 / 3
              </Text>
            </Row>
            <Row margin="0 0 15px 0">
              <Text lineH="160%" fSize={14} color="#7C7C7C">
                Please transfer the amount of money to purchase the amount of
                shares using the below bank account details you have added to
                your account. The platform admin will recognise your payment by
                its IBAN
              </Text>
            </Row>
            <Row margin="0 0 14px 0" justify="space-between" padding="0">
              <TextFieldComponent
                width="178px"
                label="Amount to tranfser"
                height={42}
                labelFontSize={10}
                fontSize={12}
                isInvestor
                disabled
                defaultValue={`${numberWithCommas(
                  (amount * valuation).toFixed(2)
                )} ${baseCurrency}`}
              />
              <TextFieldComponent
                width="calc(100% - 202px)"
                label="Account Holder Name"
                height={42}
                labelFontSize={10}
                fontSize={12}
                isInvestor
                disabled
                defaultValue={fullName}
              />
            </Row>
            <Row margin=" 0" justify="space-between" padding="0">
              <TextFieldComponent
                width="178px"
                label="BIC (SWIFT) CODE"
                height={42}
                labelFontSize={10}
                fontSize={12}
                isInvestor
                disabled
                defaultValue={bic}
              />
              <TextFieldComponent
                width="255px"
                label="IBAN"
                height={42}
                labelFontSize={10}
                fontSize={12}
                isInvestor
                disabled
                defaultValue={iban}
              />
              <TextFieldComponent
                width="367px"
                label="Beneficiary’s bank"
                height={42}
                labelFontSize={10}
                fontSize={12}
                isInvestor
                disabled
                defaultValue={bankName}
              />
            </Row>
            <Line />
            <Row>
              <Text fSize={18}>
                Total shares:{" "}
                <span>
                  {numberWithCommas(amount)} {assetSymbol}
                </span>
              </Text>
            </Row>
          </Card>
          <Card noPadding height={62}>
            <Button
              height={42}
              width={287}
              bold
              size={14}
              margin="10px 0 10px 30px"
              onClick={type === "cash" ? changeOnlineModalStatus : sendRequest}
            >
              CONFIRM & SEND REQUEST
            </Button>
          </Card>
        </Col>
        <Col>
          <Stepper step={1} />
          <CountDown timeOfEnd={timeOfEnd} />
          <Raised
            transition={0}
            noTransition
            raised={raised}
            toBeRaised={toBeRaised}
            baseCurrency={baseCurrency}
          />
          <Price convert={price} assetSymbol={assetSymbol} />
        </Col>

        {payOnlineModalOpen ? (
          <PayOnlineModal
            paymentData={paymentData}
            onCancel={changeOnlineModalStatus}
            onSubmit={sendRequest}
          />
        ) : null}

        <PaymentMessage
          isOpen={modalOpen}
          isError={error}
          onBackDpopClick={() => showModal(!modalOpen)}
          onClose={() =>
            history.push(`/investor/offerings/order-details/${id}`)
          }
        />
      </Wrapper>
    );
  } else {
    return <Redirect to="/investor/offerings/all" />;
  }
};

export default connect(
  (
    { userReducer: { token, paymentInfo, paymentDetails, fullName } },
    {
      match: {
        params: { id }
      }
    }
  ) => {
    return {
      token,
      id,
      paymentData: paymentInfo.find(e => e.assetId === id),
      paymentDetails: paymentDetails[0],
      fullName
    };
  },
  { setPaymentResponseData }
)(PaymentDetails);
