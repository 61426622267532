import styled from "styled-components";

export const Row = styled.div`
  margin-top: 20px;
  margin-bottom: ${({ mBottom }) => (mBottom ? "25px" : "0")};
  border-bottom: ${({ mBottom }) => (mBottom ? "1px solid #EFEFEF" : "none")};
  padding-bottom: ${({ mBottom }) => (mBottom ? "25px" : "0")};
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: ${({ width = "100%" }) => width};
  ${({ wrap = false }) => (wrap ? "flex-wrap: wrap;" : "")}
`;

export const InputRow = styled.div`
  margin-bottom: ${({ last }) => (last ? "0" : "20px")};
  display: flex;
  align-items: center;
  width: ${({ width }) => (width ? width : "100%")};
  justify-content: space-between;
  box-sizing: border-box;
  ${({ wrap = false }) => (wrap ? "flex-wrap: wrap;" : "")}
  ${({ otherStyles = "" }) => otherStyles}
`;

export const HtmlContainer = styled.div`
  width: 100%;
  height: ${({ full = false }) => (full ? "auto" : "55px")};
  overflow: hidden;
  position: relative;

  &::after {
    content: "";
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    height: ${({ full = false }) => (full ? 0 : "55px")};
    display: block;
    background: linear-gradient(to top, white, rgba(255, 255, 255, 0));
  }
  img {
    width: 100%;
  }
  figure {
    height: auto !important;
    padding-bottom: 0 !important;
    iframe {
      height: 480px !important;
    }
  }
  p {
    font-size: 14px;
    color: #181818;
  }
`;

export const BtnWrapper = styled.div`
  display: flex;
  & > :first-child {
    margin-right: 10px;
  }
`;
