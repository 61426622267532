import React, { useEffect, useState } from "react";
import { CircularProgress } from "@material-ui/core";

import { Card, CardHead, Load, LetfBlock } from "./styled";
import Api from "helpers/api";
import { CardAnswered } from "components/investor-layout/pages/QAMain/CardAnswered";
import { Button } from "../Offerings/styled";

export const Questions = ({ token, assetId, history, username }) => {
  const [data, setData] = useState(null);
  const [loadError, setLoadError] = useState(false);

  useEffect(() => {
    const getQuestions = async () => {
      const _data = await Api.getQuestionAssetAllInv(token, assetId).catch(setLoadError);
      if (_data) {
        setData(_data.filter((q) => !q.isWaiting));
      }
    };

    getQuestions();
  }, []);

  const renderQuestions = () => {
    const _data = [...data];
    _data.length = 3;
    return _data.map((q) => <CardAnswered key={q.questionId} q={q} token={token} currentUser={username} />);
  };

  const onGoToQA = () => {
    history.push(`/investor/question-and-answer/${assetId}`);
  };

  if (!loadError) {
    return !data ? (
      <Card padding="25px">
        <Load>
          <CircularProgress color="inherit" size={20} />
        </Load>
      </Card>
    ) : data.length ? (
      <Card padding="25px 25px 5px">
        <CardHead margin="0 auto 17px 0">Popular Questions from Q&A</CardHead>
        {renderQuestions()}
        <LetfBlock>
          <Button width={223} size={12} transparent onClick={onGoToQA}>
            ALL QUESTIONS ({data.length})
          </Button>
        </LetfBlock>
      </Card>
    ) : null;
  } else {
    return null;
  }
};
