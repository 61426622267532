import React, { useState, useEffect, Fragment, useRef } from "react";

import {
  Card,
  CardHead,
  CardLoad,
  ChatWrapper,
  InputBlock,
  ChatInput,
  MessagesCont,
  Message,
  ManagerMessage,
  AM,
  Layer,
  Row,
  Col,
  Text,
  OpenChat,
  AMContainer
} from "./styled";
import { ButtonsBlock, IconContainer } from "../Chat/styled";
import { ReactComponent as PaperClipIcon2 } from "assets/paperclip2.svg";
import { Button } from "../Offerings/styled";
import { ReactComponent as SendIcon } from "../../../../assets/send.svg";
import { TextAreaComponent } from "../../../admin-layout/forms";
import Api from "../../../../helpers/api";
import { useOnlineStates } from "modules/socket/socket.io";
import { statuses } from "components/common/onlineStatuses";
import { AttachmentsUploader } from "../../../admin-layout/pages/Chat/AttachmentsUploader";

export const RequestToManager = ({
  token,
  logo,
  reduxMessages,
  username,
  unreadMessages,
  assetId
}) => {
  const [wait, setWait] = useState(false);
  const [message, setMessage] = useState("");
  const [chatStarted, startChat] = useState(false);
  const [newMessage, setNewMessage] = useState("");
  const [uploaderOpen, setUploaderOpen] = useState(false);
  const [files, setFiles] = useState([]);

  const chatBottom = useRef(null);
  const sendButtonRef = useRef(null);

  const pageRef = useRef(1);
  const deounceRef = useRef(false);
  const ferstScroolRef = useRef(true);
  const lastScroollRef = useRef(0);

  const onChangeUploaderStatus = () => {
    setUploaderOpen(!uploaderOpen);
  };

  useEffect(() => {
    (async function() {
      await Api.updateChatMessages(token, 1, 10, assetId);
      await new Promise(res => setTimeout(res, 5000));
      deounceRef.current = true;
    })();
  }, []);

  const sendRequest = async () => {
    setWait(true);
    const response = await Api.sendMessageToAM(
      token,
      message,
      assetId
    ).catch(() => setWait(false));
    if (response === 200) {
      setWait(false);
      startChat(true);
    }
  };

  const sendMessage = async () => {
    // if (!newMessage) return;
    if (!newMessage && !files.length) return;

    setWait(true);
    let attachments = {};

    if (files.length) {
      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append(`files`, files[i], files[i].name);
      }

      const upload = await Api.uploadChatFiles(token, formData);
      if (upload) {
        attachments = { ...upload };
      }
    }

    // Api.sendMessageToAM(token, newMessage, assetId);
    // setNewMessage("");

    await Api.sendMessageToAM(token, newMessage, assetId, attachments).catch(
      () => {
        setWait(false);
      }
    );
    setNewMessage("");
    setFiles([]);
    setWait(false);
  };

  useEffect(() => {
    Api.updateChatMessages(token, 1, 10, assetId);
  }, [unreadMessages.total, assetId]);

  const delegateOnEnter = () => {
    sendButtonRef.current && sendButtonRef.current.click();
  };

  const renderChat = () => {
    return reduxMessages
      .slice(0)
      .reverse()
      .map(e => {
        const { id, sender, message, date, attachments } = e;
        return sender === username ? (
          <Message
            key={id}
            message={message}
            date={date}
            attachments={attachments}
            token={token}
            messageId={id}
            key={`message-id-${id}`}
          />
        ) : (
          <ManagerMessage
            logo={logo}
            message={message}
            token={token}
            key={id}
            date={date}
            attachments={attachments}
            messageId={id}
            key={`message-id-${id}`}
          />
        );
      });
  };

  const scrollToBottom = () => {
    setTimeout(() => {
      if (
        chatBottom.current &&
        (ferstScroolRef.current || deounceRef.current)
      ) {
        ferstScroolRef.current = false;
        chatBottom.current.scroll({
          left: 0,
          top: 10000000000000,
          behavior: "smooth"
        });
      }
    }, 500);
  };

  useEffect(scrollToBottom);

  const _onScroll = async e => {
    // todo reget init

    if (
      deounceRef.current &&
      e.target.scrollTop < lastScroollRef.current &&
      e.target.scrollTop < 100
    ) {
      pageRef.current++;

      deounceRef.current = false;
      await Api.updateChatMessages(token, pageRef.current, 10, assetId);
      await new Promise(res => setTimeout(res, 3000));
      deounceRef.current = true;
    }
    if (e.target) lastScroollRef.current = e.target.scrollTop;
    //
  };

  return (
    <Card
      padding={
        chatStarted || reduxMessages.length ? "25px 30px 0" : "25px 30px 30px"
      }
      height={chatStarted ? "547px" : "auto"}
    >
      {wait && <CardLoad />}
      {chatStarted || reduxMessages.length ? (
        <Fragment>
          <CardHead margin="0 auto 25px 0">Chat with Manager</CardHead>
          <ChatWrapper>
            <MessagesCont ref={chatBottom} onScroll={_onScroll}>
              {renderChat()}
            </MessagesCont>
            <InputBlock>
              <ChatInput
                placeholder="Your message"
                value={newMessage}
                onChange={e => setNewMessage(e.target.value)}
                onKeyDown={e => e.keyCode === 13 && delegateOnEnter()}
                width={290}
              />

              <ButtonsBlock>
                <IconContainer
                  onClick={onChangeUploaderStatus}
                  count={files.length}
                >
                  <PaperClipIcon2 />
                </IconContainer>
                <Button
                  ref={sendButtonRef}
                  width={42}
                  height={42}
                  onClick={sendMessage}
                >
                  <SendIcon style={{ width: 16, height: 16, margin: 0 }} />
                </Button>
              </ButtonsBlock>

              {/* <Button ref={sendButtonRef} width={42} height={42} onClick={sendMessage}>
                <SendIcon style={{ width: 16, height: 16, margin: 0 }} />
              </Button> */}
            </InputBlock>
          </ChatWrapper>
          {uploaderOpen ? (
            <AttachmentsUploader
              onBackdropClick={onChangeUploaderStatus}
              onSetFilesToMessage={f => setFiles(f)}
              filesList={files}
            />
          ) : null}
        </Fragment>
      ) : (
        <Fragment>
          <CardHead margin="0 auto 24px 0">Request to Manager</CardHead>
          <TextAreaComponent
            isInvestor
            areaHeight={102}
            labelFsize={12}
            fSize={14}
            label="Message"
            placeholder="Ask your question here"
            defaultValue={message}
            onChange={e => setMessage(e.target.value)}
          />
          <Button
            width={380}
            height={36}
            margin="18px 0 0"
            size={12}
            onClick={sendRequest}
            disabled={!message}
          >
            SEND MESSAGE
          </Button>
        </Fragment>
      )}
    </Card>
  );
};

export const RequestPreview = () => {
  return (
    <Card padding="25px 30px 30px">
      <Layer /> <CardHead margin="0 auto 24px 0">Request to Manager</CardHead>
      <TextAreaComponent
        isInvestor
        areaHeight={102}
        labelFsize={12}
        fSize={14}
        label="Message"
        placeholder="Ask your question here"
        defaultValue={""}
        onChange={() => console.log()}
      />
      <Button width={380} height={36} margin="18px 0 0" size={12}>
        SEND MESSAGE
      </Button>
    </Card>
  );
};

const AmAvatar = ({ token, logo }) => {
  const [img, setImg] = useState(null);

  useEffect(() => {
    if (logo) {
      const { fileNameWithExtension, secureHash } = logo;
      Api.getAttachment(token, secureHash, fileNameWithExtension).then(blob =>
        setImg(blob)
      );
    }
  }, []);

  return <AM src={img} />;
};

export const Preview = ({ token, logo, amName, onOpen }) => {
  const status = useOnlineStates(token, amName);

  return (
    <Card padding="30px" height="160px">
      <Row>
        <AMContainer>
          <AmAvatar token={token} logo={logo} />
          {status && statuses[status] ? statuses[status]() : null}
        </AMContainer>

        <Col width="calc(100% - 130px)">
          <CardHead margin="0 0 12px">Chat with the Admin</CardHead>
          <Text>{amName}</Text>
          <OpenChat onClick={onOpen}>OPEN CHAT</OpenChat>
        </Col>
      </Row>
    </Card>
  );
};
