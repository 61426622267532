import React from "react";
import styled from "styled-components";

import { icons } from "models/icons";
import { Notes } from "../../../../admin-layout/pages/UserInfo/styled";
import theme from "models/themes";

const types = {
  PROCESSING: {
    heading: "KYC Review in progress",
    text: "Please, wait patiently while Administrator checking your KYC",
    icon: icons.refresh,
    bg: "#D3D3D3",
  },
  MISSING: {
    heading: "Your KYC have missing information",
    text: "Please, check carefully form below and send it again with new information",
    icon: icons.info,
    bg: theme.primary,
  },
  APPROVED: {
    heading: "Your KYC is approved by Admin",
    text: "Please, check the note from admin",
    icon: icons.check,
    bg: theme.green,
  },
  REJECTED: {
    heading: "Your KYC is rejected by Admin",
    text: "Please, check carefully form below and send it again with new information",
    icon: icons.close,
    bg: theme.red,
  },
};

const Block = styled.div`
  min-height: 80px;
  width: 100%;
  background: #ffffff;
  border-radius: 4px;
  margin-bottom: 30px;
  box-sizing: border-box;
  overflow: hidden;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  padding: ${({ p = 0 }) => p};
`;

const Square = styled.div`
  width: 80px;
  height: 80px;
  background: ${({ bg }) => bg};
  margin-right: 25px;
  display: flex;
  box-sizing: border-box;
  > svg {
    width: 32px;
    height: 32px;
    margin: auto;
    path {
      stroke: #fff;
    }
  }
`;

const Heading = styled.div`
  font-weight: 600;
  font-size: 18px;
  line-height: 135%;
  color: #333333;
  margin-bottom: 4px;
`;

const Text = styled.div`
  font-weight: 300;
  font-size: 14px;
  line-height: 150%;
  color: #333333;
`;

const NotesBlock = styled.div`
  width: 100%;
  border-top: 1px solid #f0f0f0;
  padding: 16px 20px;
  box-sizing: border-box;
  height: 100px;
  background: #ffffff;
`;

export const Status = ({ status, note }) => {
  if (status) {
    const { heading, text, icon, bg } = types[status];
    return (
      <Block>
        <Row>
          <Square bg={bg}>{icon}</Square>
          <div>
            <Heading>{heading}</Heading>
            <Text>{text}</Text>
          </div>
        </Row>
        {note ? (
          <NotesBlock>
            <Notes disabled heading="Note from ADMIN" defaultValue={note} />
          </NotesBlock>
        ) : null}
      </Block>
    );
  } else return null;
};
