import styled from "styled-components";
import React from "react";
import { Btn } from "../../buttons";
import { CircularProgress } from "@material-ui/core";

export const SetupWrapper = styled.section`
  display: flex;
  flex-direction: ${({ mailing = false }) => (mailing ? "row" : "column")};
  width: 100%;
  min-height: calc(100vh - 214px);
  justify-content: space-between;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ fullWidth }) => (fullWidth ? "100%" : "calc(50% - 17.5px)")};
`;

export const MailColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ small = false }) => (small ? "348px" : "calc(100% - 378px)")};
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const Separator = styled.div`
  height: ${({ height }) => (height ? `${height}px` : "35px")};
`;

const Item = styled.div`
  width: 100%;
  height: 62px;
  background: #ffffff;
  padding: 0 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0;
  }
`;

const Name = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: #181818;
`;

export const EditItem = ({ name, onClick }) => {
  return (
    <Item>
      <Name>{name}</Name>
      <Btn onClick={onClick} transparent>
        Edit
      </Btn>
    </Item>
  );
};

export const Heading = styled.h1`
  font-family: Muli;
  margin: 0 0 20px 0;
  height: 27px;
  font-weight: bold;
  font-size: 20px;
  line-height: 135%;
  letter-spacing: -0.02em;
  color: #192036;
  margin-top: ${({ mTop = false }) => (mTop ? mTop : "")};
`;

const Loading = styled.div`
  width: 100%;
  height: calc(100vh - 160px);
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Waiting = () => {
  return (
    <Loading>
      <CircularProgress />
    </Loading>
  );
};

const WaitingWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.1);
  z-index: 1001;
`;

export const WaitingFull = () => (
  <WaitingWrapper>
    <CircularProgress />
  </WaitingWrapper>
);

export const Card = styled.div`
  width: 100%;
  padding: 20px 25px 30px;
  background: #ffffff;
  border-radius: 4px;
  margin-bottom: 30px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`;

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
  h1 {
    font-family: Muli;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 135%;
    color: #181818;
    margin: 0;
  }
  span {
    font-family: Muli;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 135%;
    color: ${({ theme: { primary } }) => primary};
    display: flex;
    align-items: center;
    cursor: pointer;
    svg {
      width: 13px;
      height: 13px;
      margin-right: 7px;
      path {
        stroke: ${({ theme: { primary } }) => primary};
      }
    }
  }
`;

export const CenterContainer = styled.div`
  margin: auto;
`;

export const Uploader = styled.div`
  width: 100%;
  display: flex;
  padding: ${({ padding = "" }) => padding};
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  background: ${({ transparent, theme: { quoteBgInvestor } }) =>
    transparent ? "transparent" : quoteBgInvestor};
  border-radius: 4px;
  min-height: 190px;
  color: ${({ theme: { primary } }) => primary};

  h1 {
    margin: 0 0 12px 0;
    font-family: Muli;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 135%;
    color: #181818;
    text-align: center;
  }
  h2 {
    width: 100%;
    font-family: Muli;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 135%;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: rgba(24, 24, 24, 0.6);
    margin: 0 0 12px 0;
  }
  p {
    font-family: Muli;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 135%;
    color: #181818;
    width: 185px;
    text-align: center;
    margin-bottom: 18px;
    span {
      cursor: pointer;
      color: ${({ theme: { primary } }) => primary};
      text-decoration: underline;
    }
  }
`;

export const FileCard = styled.div`
  background: ${({ theme: { quoteBgInvestor } }) => quoteBgInvestor};
  border-radius: 4px;
  width: 100%;
  margin-bottom: 21px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 12px 14px;
  svg {
    width: 18px;
    height: 18px;
    :first-child {
      margin-right: 15px;
      path {
        stroke: ${({ theme: { primary } }) => primary};
      }
    }
    :last-child {
      margin-left: auto;
      cursor: pointer;
      path {
        stroke: #ff4343;
      }
    }
  }
  span {
    font-family: Muli;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 135%;
    letter-spacing: -0.02em;
    color: #181818;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: calc(100% - 70px);
  }
`;

export const FileInfo = styled.div`
  width: 100%;
  margin-bottom: 26px;
  display: flex;
  div {
    width: 50%;
    display: flex;
    flex-direction: column;
    h3 {
      margin: 0;
      font-family: Muli;
      font-style: normal;
      font-weight: bold;
      font-size: 10px;
      line-height: 135%;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      color: rgba(24, 24, 24, 0.6);
    }
    span {
      margin-top: 8px;
      font-family: Muli;
      font-style: normal;
      font-weight: bold;
      font-size: 12px;
      line-height: 135%;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      color: #181818;
    }
  }
`;

export const SubmitBlock = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
`;

export const BtnBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ width = "270px" }) => width};
  height: 42px;
  background: ${({ white, theme: { quoteBgInvestor } }) =>
    white ? "#fff" : quoteBgInvestor};
  border-radius: ${({ white }) => (white ? "4px" : "2px")};
  margin: ${({ margin }) => (margin ? margin : "0")};
  border: ${({ white }) => (white ? "1px solid #EFEFEF" : "")};
  box-sizing: border-box;
`;

export const ContainerFlex = styled.div`
  position: relative;
  display: flex;
  flex-direction: ${({ row = false }) => (row ? "row" : "column")};
  justify-content: ${({ justify = "flex-start" }) => justify};
  align-items: ${({ align = "flex-start" }) => align};
  ${({ wrap = false }) => (wrap ? "flex-wrap: wrap;" : "")}

  width: ${({ w = "100%" }) => w};
  ${({ h = null }) => (h ? `height: ${h};` : "")}

  padding: ${({ paddingSize = 0 }) => paddingSize};
  margin: ${({ marginSize = 0 }) => marginSize};
  background: ${({ bg = "transparent" }) => bg};
  border-radius: ${({ borderRadius = 0 }) => borderRadius};

  ${({ border = null }) => (border ? `border: ${border};` : "")}
  overflow: ${({ overflow = "hidden" }) => overflow};

  z-index: ${({ zIndex = 0 }) => zIndex};
  transition: height 0.3s linear;
  box-sizing: border-box;
`;

export const UploaderLabel = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 135%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  margin-bottom: 12px;
  color: rgba(24, 24, 24, 0.4);
`;

export const EmptyBlock = styled.div`
  width: 270px;
`;

export const UploadersCol = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  width: 100%;
`;

export const ColumnContainer = styled.div`
  display: flex;
  width: calc(50% - 17.5px);
`;

export const CardWrap = styled.div`
  display: flex;
  flex-direction: column;
`;
