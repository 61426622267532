import React from "react";

import { EmptyCard, Card } from "components/common/emptyInfo.js";
import { ClockProgress } from "components/common/icons-primary";

export const Connect = () => (
  <Card>
    <EmptyCard heading="In progress" text="This page is under construction" icon={<ClockProgress />} />
  </Card>
);
