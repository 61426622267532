import React from "react";

import { Card, Stepper } from "./styled";
import { Button } from "../Offerings/styled";
import { ReactComponent as FileIcon } from "../../../../assets/file-text.svg";

export const SubmitBlock = ({ stepName, onSubmit, dealsType, newWorkflow, step }) => {
  return (
    <Card padding="25px" key={`submit-button-${stepName}`}>
      <Button
        fullWidth
        size={16}
        height={42}
        onClick={onSubmit}
        disabled={dealsType === "EXISTED_DEAL" || stepName === "UPLOAD LOI"}
        margin={newWorkflow ? 0 : "0 auto 24px"}
      >
        <FileIcon style={{ marginRight: 10, width: 16, height: 16 }} />
        {stepName.toUpperCase()}
      </Button>
      {!newWorkflow && <Stepper step={step} />}
    </Card>
  );
};
