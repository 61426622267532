import React, { useState } from "react";
import moment from "moment";

import {
  Question,
  Qheader,
  Qbody,
  BodyQA,
  BodyHeader,
  TextMessage,
  Privacy,
  UserInfo,
  UserAvatar,
  InfoBlock,
  ButtonsBlock,
  AnswerInfo,
  ModalBackground,
  FileBlock,
  FileName,
  FileControls,
} from "./styled";
import { Btn } from "../../../admin-layout/buttons";
import { ReactComponent as Close } from "../../../../assets/close.svg";
import { File } from "components/common/icons-primary";
import { ReactComponent as Eye } from "../../../../assets/eye.svg";
import { ReactComponent as Download } from "../../../../assets/download.svg";
import { RemoveQuestionModal } from "./RemoveQuestionModal";
import { CircularProgress } from "@material-ui/core";
import { ErrorMessage } from "../../errorMessage";
import Api from "../../../../helpers/api";

export const QuestionCardAnswered = ({
  q: {
    assetId,
    category,
    textQuestion,
    userFullName,
    userLogo,
    amlogo,
    questionId,
    isPrivate,
    amfullName,
    userInvestorType,
    userGender,
    amgender,
    aminvestorType,
    dateOfQuestion,
    textAnswer,
    dateOfAnswer,
    size,
    filename,
    folderName,
    amsize,
    amfilename,
    amfolderName,
  },
  token,
  history,
  onRefresh,
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [wait, setWait] = useState(false);
  const [error, setError] = useState(null);

  const onError = (e) => {
    setError(e);
    setWait(false);
  };

  const onView = (folderName, filename) => async () => {
    const fileUrl = await Api.viewQADocument(token, folderName, filename, assetId).catch(onError);

    if (fileUrl) {
      const link = document.createElement("a");
      link.target = "_blank";
      link.href = fileUrl;
      link.click();
    }
  };

  const downloadDocument = (folderName, filename) => async () => {
    const fileUrl = await Api.downloadQADocument(token, folderName, filename, assetId).catch(onError);

    if (fileUrl) {
      const link = document.createElement("a");
      link.target = "_blank";
      link.href = fileUrl;
      link.click();
    }
  };

  const gotoEdit = () => {
    history.push(`/admin/question-and-answer/edit-answer/${assetId}/${questionId}/edit`);
  };

  const onClose = () => {
    setModalOpen(!modalOpen);
  };

  const onRemove = () => {
    setModalOpen(true);
  };

  const onSendRequest = async () => {
    setWait(true);
    const res = await Api.deleteQuestion(token, questionId).catch(onError);

    if (res) onRefresh();
    setWait(false);
  };

  return (
    <Question>
      <Qheader>
        <UserInfo>
          <UserAvatar
            token={token}
            fileName={userLogo ? userLogo.fileNameWithExtension : null}
            hash={userLogo ? userLogo.secureHash : null}
            investorType={userInvestorType}
            gender={userGender}
          />
          <InfoBlock>
            <h3>{userFullName}</h3>
            <span>{moment(dateOfQuestion).format("DD.MM.YYYY, hh:mm A")}</span>
          </InfoBlock>
        </UserInfo>
        <ButtonsBlock>
          <Btn width={140} transparent onClick={gotoEdit}>
            EDIT ANSWER
          </Btn>
          <Btn error width={32} onClick={onRemove}>
            <Close />
          </Btn>
        </ButtonsBlock>
      </Qheader>
      <Qbody>
        <BodyQA>
          <BodyHeader>
            <span>{category}</span>
            <Privacy isPrivate={isPrivate} />
          </BodyHeader>
          <TextMessage>{textQuestion}</TextMessage>
          {filename && size ? (
            <FileBlock isAnswered>
              <FileName>
                <File />
                <h4>
                  {filename}
                  <span>{(size / 1024).toFixed(2)} mb</span>
                </h4>
              </FileName>
              <FileControls>
                <Eye onClick={onView(folderName, filename)} />
                <Download onClick={downloadDocument(folderName, filename)} />
              </FileControls>
            </FileBlock>
          ) : null}
        </BodyQA>
        <BodyQA isAnswer>
          <BodyHeader>
            <span>Answer from {amfullName}</span>
            <AnswerInfo>
              <span>{moment(dateOfAnswer).format("DD.MM.YYYY, hh:mm A")}</span>
              <UserAvatar
                small
                token={token}
                fileName={amlogo ? amlogo.fileNameWithExtension : null}
                hash={amlogo ? amlogo.secureHash : null}
                investorType={aminvestorType}
                gender={amgender}
              />
            </AnswerInfo>
          </BodyHeader>
          <TextMessage isAnswer>{textAnswer}</TextMessage>
          {amfilename && amsize ? (
            <FileBlock isAnswered grey>
              <FileName>
                <File />
                <h4>
                  {amfilename}
                  <span>{(amsize / 1024).toFixed(2)} mb</span>
                </h4>
              </FileName>
              <FileControls>
                <Eye onClick={onView(amfolderName, amfilename)} />
                <Download onClick={downloadDocument(amfolderName, amfilename)} />
              </FileControls>
            </FileBlock>
          ) : null}
        </BodyQA>
      </Qbody>
      {modalOpen ? (
        <RemoveQuestionModal
          onAgree={onSendRequest}
          onBackdropClick={onClose}
          userFullName={userFullName}
          dateOfQuestion={dateOfQuestion}
        />
      ) : null}
      {wait ? (
        <ModalBackground>
          <CircularProgress style={{ margin: "auto" }} />
        </ModalBackground>
      ) : null}
      {error ? <ErrorMessage er={error} onClose={() => setError(null)} /> : null}
    </Question>
  );
};
