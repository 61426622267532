import React, { Fragment, useState, useEffect } from "react";

import { Card, Row, Heading, Text, Line, TEXT_FIELD_SIZE } from "./styled.js";
import { TextFieldComponent } from "../../../admin-layout/forms";
import { Button } from "../../../investor-layout/pages/Offerings/styled";
import Api from "../../../../helpers/api";
import { PaymentMessage, PayOnlineModal } from "../../ActionMessages";
import { numberWithCommas } from "helpers/index.js";

export const PaymentDetails = ({
  asset: {
    assetProperties: { assetSymbol },
    assetFinancialProperty: { valuation, baseCurrency },
    intentionToBuy
  },
  account: { bic, iban, bankName, accountNumber },
  fullName,
  assetId,
  token,
  onPay,
  onError,
  type,
  cashBalance,
  history
}) => {
  const [wait, setWait] = useState(false);
  const [modalOnlineOPen, setModalOnline] = useState(false);
  const [smallScreen, setSmallScreen] = useState(window.innerWidth < 1279);

  useEffect(() => {
    const handleResize = () => {
      setSmallScreen(window.innerWidth < 1279);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const openOnline = () => setModalOnline(!modalOnlineOPen);

  const onSubmit = async () => {
    setWait(true);
    const res = await Api.buyViaBank(
      token,
      assetId,
      baseCurrency,
      intentionToBuy,
      accountNumber
    ).catch(e => {
      setWait(false);
      onError(e);
    });
    if (res) {
      setWait(false);
      onPay(res);
    }
  };

  const payOnline = async () => {
    setWait(true);
    await Api.buyOnline(token, assetId, baseCurrency, intentionToBuy)
      .then(res => {
        history.push("/investor/transactions/all/all");
        console.log(res);
      })
      .catch(e => onError(e));
    setWait(false);
  };

  return (
    <Fragment>
      <Card>
        <Row margin="0 0 14px 0">
          <Heading>
            {type === "bank" ? "Bank Transfer to Invest" : "Online payment"}
          </Heading>
          <Text fSize={18} color="rgba(24, 24, 24, 0.4)" margin="0 0 0 auto">
            2 / 3
          </Text>
        </Row>
        <Row margin="0 0 15px 0">
          <Text lineH="160%" fSize={14} color="#7C7C7C">
            Please transfer the amount of money to purchase the amount of shares
            using the below bank account details you have added to your account.
            The platform admin will recognise your payment by its IBAN
          </Text>
        </Row>
        <Row margin="0 0 14px 0" justify="space-between" padding="0">
          <TextFieldComponent
            width="178px"
            label="Amount to tranfser"
            {...TEXT_FIELD_SIZE}
            isInvestor
            disabled
            defaultValue={`${numberWithCommas(
              (intentionToBuy * valuation).toFixed(2)
            )} ${baseCurrency}`}
          />
          <TextFieldComponent
            width="calc(100% - 202px)"
            label="Account Holder Name"
            {...TEXT_FIELD_SIZE}
            isInvestor
            disabled
            defaultValue={fullName}
          />
        </Row>
        {!smallScreen ? (
          <Row margin="0" justify="space-between" padding="0">
            <TextFieldComponent
              width="178px"
              label="BIC (SWIFT) CODE"
              {...TEXT_FIELD_SIZE}
              isInvestor
              disabled
              defaultValue={bic}
            />
            <TextFieldComponent
              width="255px"
              label="IBAN"
              {...TEXT_FIELD_SIZE}
              isInvestor
              disabled
              defaultValue={iban}
            />
            <TextFieldComponent
              width="calc(100% - 482px)"
              label="Beneficiary’s bank"
              {...TEXT_FIELD_SIZE}
              isInvestor
              disabled
              defaultValue={bankName}
            />
          </Row>
        ) : (
          <Fragment>
            <Row margin="0 0 14px 0" justify="space-between" padding="0">
              <TextFieldComponent
                width="178px"
                label="BIC (SWIFT) CODE"
                {...TEXT_FIELD_SIZE}
                isInvestor
                disabled
                defaultValue={bic}
              />
              <TextFieldComponent
                width="calc(100% - 202px)"
                label="IBAN"
                {...TEXT_FIELD_SIZE}
                isInvestor
                disabled
                defaultValue={iban}
              />
            </Row>
            <Row>
              <TextFieldComponent
                width="100%"
                label="Beneficiary’s bank"
                {...TEXT_FIELD_SIZE}
                isInvestor
                disabled
                defaultValue={bankName}
              />
            </Row>
          </Fragment>
        )}

        <Line />
        <Row>
          <Text fSize={18}>
            Total shares:{" "}
            <span>
              {numberWithCommas(intentionToBuy)} {assetSymbol}
            </span>
          </Text>
        </Row>
      </Card>
      <Card noPadding height={62}>
        <Button
          height={42}
          width={287}
          bold
          size={14}
          margin="10px 0 10px 30px"
          onClick={type === "bank" ? onSubmit : openOnline}
          disabled={wait}
        >
          {type === "bank" ? "CONFIRM & SEND REQUEST" : "Make payment"}
        </Button>
      </Card>

      {/* Online modal */}
      {modalOnlineOPen ? (
        <PayOnlineModal
          baseCurrency={baseCurrency}
          onCancel={openOnline}
          paymentData={{
            cashBalance,
            amount: intentionToBuy,
            assetSymbol,
            valuation
          }}
          onSubmit={payOnline}
          wait={wait}
        />
      ) : null}
    </Fragment>
  );
};
