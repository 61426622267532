import Api from '../../helpers/api';
import { setOrderHistory, getAllDeals, updateAssetButton, updateDealRaised, setEventsCount, setQACount } from '../dataRedux/dataAction';
import { setChatMessages, setUnreadMessages, setChatThemes, setSocketConnectionStatus, setAdminChatThemes } from '../chatRedux/chatAction';

export const SockLink = {
  stompClient: null,
  checkerId: [],
  fallbackData: null,
};

export const _SockLink = {
  stompClient: null,
  checkerId: [],
  fallbackData: null,
};

function checkerTimer() {
  SockLink.checkerId.forEach((id) => clearTimeout(id));

  SockLink.checkerId = [
    setTimeout(() => {
      if (SockLink.fallbackData && SockLink.fallbackData.length && (!SockLink.stompClient || !SockLink.stompClient.connected)) {
        try {
          const {
            userReducer: { token },
          } = SockLink.fallbackData[SockLink.fallbackData.length - 1]();
          if (token) {
            socketConnect(...SockLink.fallbackData);
          }
        } catch (error) {}
      }
      checkerTimer();
    }, 60 * 1000),
  ];
}

function _checkerTimer() {
  _SockLink.checkerId.forEach((id) => clearTimeout(id));

  _SockLink.checkerId = [
    setTimeout(() => {
      if (_SockLink.fallbackData && _SockLink.fallbackData.length && (!_SockLink.stompClient || !_SockLink.stompClient.connected)) {
        _socketConnect(..._SockLink.fallbackData);
      }
      _checkerTimer();
    }, 60 * 1000),
  ];
}

export function socketConnect(token, username, role, dispatch, getState) {
  const { SockJS, Stomp } = window;
  const socket = new SockJS(`${Api.url}/chat?token=${decodeURI(token)}&OriginVoC=${Api.origin}`);
  SockLink.fallbackData = [token, username, role, dispatch, getState];
  SockLink.stompClient = Stomp.over(socket);
  SockLink.stompClient.connect(
    {},
    function (frame) {
      dispatch(setSocketConnectionStatus(true));
      SockLink.stompClient.subscribe(`/notify/txHistory-${username}`, (messageOutput) => {
        try {
          dispatch(setOrderHistory(JSON.parse(messageOutput.body)));
          dispatch(getAllDeals(token));
        } catch (e) {
          console.log(e);
        }
      });

      SockLink.stompClient.subscribe(`/notify/messages-${username}`, (messageOutput) => {
        try {
          console.log(JSON.parse(messageOutput.body).content);
          dispatch(setChatMessages(JSON.parse(messageOutput.body).content));
        } catch (e) {
          console.log(e);
        }
      });

      SockLink.stompClient.subscribe(`/notify/unreadMessages-${username}`, async (messageOutput) => {
        if (window.location.pathname.includes('/chat/') && (role === 'ADMIN' || role === 'AM')) {
          await Api.getInvestorsMessages(token);
        } else if (role === 'INVESTOR' || role === 'FACILITATOR') {
          await Api.requestAssetMessage(token);
        }
        await new Promise((res) => setTimeout(res, 1000));
        try {
          dispatch(setUnreadMessages(JSON.parse(messageOutput.body)));
        } catch (e) {
          console.log(e);
        }
      });

      SockLink.stompClient.subscribe(`/notify/messagesOverview-${username}`, (messageOutput) => {
        dispatch(setChatThemes(JSON.parse(messageOutput.body)));
      });

      SockLink.stompClient.subscribe(`/notify/admin/messagesOverview-${username}`, (messageOutput) => {
        dispatch(setAdminChatThemes(JSON.parse(messageOutput.body)));
      });

      SockLink.stompClient.subscribe(`/notify/updateDeals-${username}`, (messageOutput) => {
        try {
          const data = JSON.parse(messageOutput.body);
          console.log('update raised', data);
          dispatch(updateDealRaised(data));
        } catch (e) {
          console.log(e);
        }
      });

      SockLink.stompClient.subscribe(`/notify/dealActionButton-${username}`, (messageOutput) => {
        try {
          const data = JSON.parse(messageOutput.body);
          console.log('update action button', data);
          dispatch(updateAssetButton(data));
        } catch (e) {
          console.log(e);
        }
      });

      SockLink.stompClient.subscribe(`/notify/calendarOfEvents/count-${username}`, (messageOutput) => {
        try {
          console.log(messageOutput.body);
          dispatch(setEventsCount(+messageOutput.body));
        } catch (e) {
          console.log(e);
        }
      });
    },
    function () {
      SockLink.fallbackData = null;
    },
  );
}

export function _socketConnect(token, username, role, dispatch, getState) {
  const { SockJS, Stomp } = window;
  const socket = new SockJS(`${Api.url}/api/qanda/chat?token=${decodeURI(token)}&OriginVoC=${Api.origin}`);
  _SockLink.fallbackData = [token, username, role, dispatch, getState];
  _SockLink.stompClient = Stomp.over(socket);
  _SockLink.stompClient.connect(
    {},
    function (frame) {
      // dispatch(setSocketConnectionStatus(true));

      _SockLink.stompClient.subscribe(`/api/qanda/qanda/count-${username}`, (messageOutput) => {
        try {
          console.log(messageOutput.body);
          dispatch(setQACount(+messageOutput.body));
        } catch (e) {
          console.log(e);
        }
      });
    },
    function () {
      SockLink.fallbackData = null;
    },
  );
}

checkerTimer();
_checkerTimer();
