import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import { Wrapper } from "../../main";
import Api from "../../../../helpers/api";
import { TableHeader, HeaderTD, AssetsTabs, AssetTypeTab } from "../../dealTypeCard";
import { Btn } from "../../buttons";
import { getAssets } from "../../../../modules/dataRedux/dataAction";
import { ReactComponent as Arrow } from "../../../../assets/chevron-down.svg";
import { ReactComponent as Plus } from "../../../../assets/plus-circle.svg";
import { ReactComponent as NewAssetIcon } from "../../../../assets/na.svg";
import { ReactComponent as ExistendAssetIcon } from "../../../../assets/ea.svg";
import { NoDeals } from "./noDeals";

import DealsBody from "./dealsBody";
import { UnfinishedDeals } from "./unfinishedDeals";

const Deals = (props) => {
  const {
    match: {
      params: { type },
    },
  } = props;
  const [assets, setAssets] = useState([]);
  const [unfinished, setUnfinished] = useState([]);
  const [editStatus, setEditStatus] = useState(null);
  const [load, setLoad] = useState(true);
  if (assets.length !== props.assets.length) {
    setAssets(props.assets);
  }

  const getUnfinished = async () => {
    const unFinished = await Api.getUnfinishedDeals(props.token);
    if (unFinished) setUnfinished(unFinished);
  };

  useEffect(() => {
    (async () => {
      getUnfinished();
      await props.getAssets(props.token);
      setLoad(false);
    })();
  }, []);

  const changeStatus = (id, status) => async () => {
    setEditStatus(id);
    const res = await Api.toggleAssetStatus(props.token, id, status);
    if (res) {
      setAssets(
        assets.map((e) => {
          if (e.linearId === id) {
            return {
              ...e,
              visibilityProperty: {
                ...e.visibilityProperty,
                active: e.linearId === id ? !status : e.active,
              },
            };
          }
          return e;
        })
      );
    }
    setEditStatus(null);
  };

  const newAssets = assets.filter((e) => e.type === "NEW_DEAL" && e.finalized);
  const existedAssets = assets.filter((e) => e.type === "EXISTED_DEAL");
  const unfinilizedAssets = assets.filter((e) => e.type === "NEW_DEAL" && !e.finalized);

  return (
    <Wrapper alignItems="center" height="100%" bg="transparent">
      <AssetsTabs>
        <AssetTypeTab to={"/admin/deals/new_deal"} active={type === "new_deal"}>
          <NewAssetIcon />
          New Assets ({newAssets.length})
        </AssetTypeTab>
        <AssetTypeTab to={"/admin/deals/existed_deal"} active={type === "existed_deal"}>
          <ExistendAssetIcon />
          Existing Assets ({existedAssets.length})
        </AssetTypeTab>
        <AssetTypeTab to={"/admin/deals/unfinished_deal"} active={type === "unfinished_deal"}>
          <ExistendAssetIcon />
          Unfinished Assets{" "}
          {unfinished.length || unfinilizedAssets.length ? `(${unfinished.length + unfinilizedAssets.length})` : null}
        </AssetTypeTab>
        <Link to={"/admin/deals/create"}>
          <Btn width={215}>
            <Plus style={{ marginRight: 6 }} />
            add new asset
          </Btn>
        </Link>
      </AssetsTabs>
      <TableHeader justify="space-between">
        <HeaderTD boxSizing="border-box" />
        <HeaderTD boxSizing="border-box" width="20%" blue>
          Asset Name <Arrow />
        </HeaderTD>
        <HeaderTD boxSizing="border-box">
          {type === "unfinished_deal" ? "" : type === "existed_deal" ? "Sponsor" : "Raised Amount"}
        </HeaderTD>
        <HeaderTD boxSizing="border-box">
          {type === "unfinished_deal" ? "" : type === "existed_deal" ? "deal size" : "Total amount"}
        </HeaderTD>
        <HeaderTD boxSizing="border-box">{type === "unfinished_deal" ? "" : "Sold Shares"}</HeaderTD>
        <HeaderTD boxSizing="border-box" width={type === "unfinished_deal" ? "calc(7% + 100px)" : "7%"}>
          {type === "unfinished_deal" ? "Last update" : "Subscribers"}
        </HeaderTD>
        <HeaderTD boxSizing="border-box" width={type === "unfinished_deal" ? "243px" : "343px"} />
      </TableHeader>
      {type === "new_deal" &&
        (newAssets.length ? (
          <DealsBody
            key="new-deals"
            rows={newAssets}
            token={props.token}
            onChangeStatus={changeStatus}
            editStatus={editStatus}
          />
        ) : (
          <NoDeals heading="New assets" />
        ))}
      {type === "existed_deal" &&
        (existedAssets.length ? (
          <DealsBody
            key="existed_deal"
            rows={existedAssets}
            token={props.token}
            onChangeStatus={changeStatus}
            editStatus={editStatus}
          />
        ) : (
          <NoDeals heading="Existing assets" />
        ))}
      {type === "unfinished_deal" &&
        (unfinilizedAssets.length || unfinished.length ? (
          <UnfinishedDeals
            unFinalized={unfinilizedAssets}
            key="unfinished_deal"
            rows={unfinished}
            token={props.token}
            callBack={getUnfinished}
          />
        ) : (
          <NoDeals heading="Unfinished assets" />
        ))}

      {load && <CircularProgress />}
    </Wrapper>
  );
};

export default connect(
  ({ userReducer: { token }, dataReducer: { assets = [] } }) => ({
    token,
    assets,
  }),
  { getAssets }
)(Deals);
