import React from "react";

import { Wrapper as FullWrapper, MainWrapper } from "components/admin-layout/main";
import { SidebarFull, Logo, LogoContainer } from "components/admin-layout/sidebar";
import { AdminHeader, HeaderBlock, SearchBar } from "components/admin-layout/header";
import { ReactComponent as SearchIcon } from "assets/search-icon.svg";
import { AvatarMenu } from "components/admin-layout/avatar";
import { TextLink, BottomLinksBlock, LinksBlockHeading, BottomLink } from "components/admin-layout/navigation";
import logo from "assets/logos/asset-manager-logo.svg";

import { Navigation, SetupNavigation } from "./setup.app.constants";

import theme from "models/themes";

export const AdminView = ({ location: { pathname }, children }) => {
  const renderNav = () => {
    return Navigation.map(({ text, link, re, available }) => (
      <TextLink
        available={available}
        count={0}
        key={`text-${link}`}
        link={link}
        active={re.test(pathname)}
        text={text}
      />
    ));
  };

  const renderManagement = () => {
    return SetupNavigation.map(({ text, link, re }) => (
      <BottomLink key={link} text={text} link={link} active={re.test(pathname)} />
    ));
  };
  return (
    <FullWrapper row>
      <SidebarFull>
        <LogoContainer>
          <Logo src={logo} />
        </LogoContainer>
        {renderNav()}
        <BottomLinksBlock>
          <LinksBlockHeading>Management</LinksBlockHeading>
          {renderManagement()}
        </BottomLinksBlock>
      </SidebarFull>
      <AdminHeader>
        <HeaderBlock noBorder leftAlign padding="0 15px 0 30px">
          <SearchIcon />
        </HeaderBlock>
        <SearchBar placeholder="Search among connect" />
        <HeaderBlock>
          <AvatarMenu
            userAvatar={undefined}
            fullName={"Setup test"}
            username={"Setup test"}
            role={"Administator"}
            onLogout={console.log}
            onClick={console.log}
          />
        </HeaderBlock>
      </AdminHeader>
      <MainWrapper bg={theme.mainBgAdmin}>{children}</MainWrapper>
    </FullWrapper>
  );
};
