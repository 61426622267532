import React, { useState } from "react";
import { CircularProgress } from "@material-ui/core";

import {
  FlexRow,
  FormCol,
  Heading,
  InputCont,
  Input,
  Submit,
  FormBottom,
  LegalInfo,
  FooterLinks,
  SignUpTxt,
  HalfCont,
  WaitingWrapper,
  CancelButton,
} from "./styled";

import { ReactComponent as PassIcon } from "../../assets/pass.svg";

export const _2faForm = ({ login, authData, set2Fa, callBack }) => {
  const [wait, setWait] = useState(false);
  const [error, setError] = useState(false);
  const [code, setCode] = useState("");

  const onPasswordChange = (e) => {
    error && setError(false);
    setCode(e.target.value);
  };

  const submit = async () => {
    setWait(true);
    await login(authData, code).catch(() => {
      setWait(false);
      setError(true);
    });
  };

  return (
    <FormCol>
      <div style={{ margin: "auto 0" }}>
        <Heading>2-Step verification</Heading>
        <SignUpTxt left color="#767676" margin="0 0 25px 0">
          Please enter your verification code below.
        </SignUpTxt>
        <InputCont>
          <Input
            type="password"
            placeholder="*** *** *** ***"
            value={code}
            error={error}
            onChange={(e) => onPasswordChange(e)}
          />
          <PassIcon />
        </InputCont>
        <FlexRow justify="space-between" margin="40px 0 0">
          <HalfCont>
            <CancelButton
              onClick={() => {
                callBack();
                set2Fa(false);
              }}
            >
              Cancel & Back
            </CancelButton>
          </HalfCont>
          <HalfCont>
            <Submit noMargin onClick={submit} disabled={!code}>
              Verify
            </Submit>
          </HalfCont>
        </FlexRow>
      </div>
      <FormBottom>
        <LegalInfo>LEGAL INFORMATION</LegalInfo>
        <FlexRow>
          <FooterLinks to="/info/privacy-policy">Privacy Policy</FooterLinks>
          <FooterLinks to="/info/terms-of-use">Terms and Conditions</FooterLinks>
        </FlexRow>
      </FormBottom>
      {wait && (
        <WaitingWrapper>
          <CircularProgress />
        </WaitingWrapper>
      )}
    </FormCol>
  );
};
