import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";

import Api from "../../../../helpers/api";
import { Header, Row, InputRow, TextH3 } from "./styled";
import { Main, TextSub } from "../DealForm/Step/styled";
import { ReactComponent as ArrowLeft } from "../../../../assets/arrowleft.svg";
import { Btn } from "../../buttons";
import {
  TextFieldComponent,
  TextEditorComponent,
  FooterComponent,
  TextAreaComponent,
  VariablesComponent
} from "../../forms";

const TemplateSetup = ({
  history,
  token,
  match: {
    params: { template }
  }
}) => {
  const [templateData, setTemplateData] = useState(null);
  const [submitDisable, setSubmitDisable] = useState(false);

  const updateTemplate = async () => {
    setSubmitDisable(true);
    await Api.updateTemplateSettings(token, templateData)
      .then(() => redirectToTemplatesList())
      .catch(() => setSubmitDisable(false));
  };

  const redirectToTemplatesList = () =>
    history.push("/admin/comunication-setup/email-templates");

  useEffect(() => {
    Api.getTemplateSettings(token, template).then(json =>
      setTemplateData(json)
    );
  }, []);

  const onChangeData = (e, key) => {
    if (
      key === "customFooter" ||
      key === "sendToInvestor" ||
      key === "sendToAm"
    ) {
      setTemplateData({
        ...templateData,
        [key]: !templateData[key]
      });
    } else {
      setTemplateData({
        ...templateData,
        [key]: e.target ? e.target.value : e
      });
    }
  };

  if (!templateData) {
    return null;
  } else {
    const {
      name,
      nameVisualization,
      subject,
      greeting,
      content,
      variables: { variables },
      customFooter,
      customFooterText,
      sendToAm,
      sendToInvestor
    } = templateData;
    return (
      <Fragment>
        <Header>
          <Btn transparent onClick={redirectToTemplatesList} width={150}>
            <ArrowLeft /> back to templates
          </Btn>
          <TextH3 marginSize="0 0 0 25px">{nameVisualization}</TextH3>
        </Header>
        <Row>
          <Main width="calc(72% - 30px)">
            <TextSub
              textAlign="left"
              fontWeight="bold"
              color="#181818"
              marginSize="0 0 25px 0"
            >
              Edit Template
            </TextSub>
            <InputRow>
              <TextFieldComponent
                label="Name of template"
                placeholder="Name of template"
                defaultValue={nameVisualization}
                onChange={event => onChangeData(event, "nameVisualization")}
              />
            </InputRow>
            <InputRow>
              <TextFieldComponent
                label="Template subject"
                placeholder="Template subject"
                defaultValue={subject}
                onChange={event => onChangeData(event, "subject")}
              />
            </InputRow>
            <InputRow>
              <TextFieldComponent
                label="Template greeting"
                placeholder="Template greeting"
                defaultValue={greeting}
                onChange={event => onChangeData(event, "greeting")}
              />
            </InputRow>
            <InputRow>
              <TextEditorComponent
                label="Template content"
                defaultValue={content}
                onChange={event => onChangeData(event, "content")}
              />
            </InputRow>
            <InputRow>
              <FooterComponent
                label="Send to AM"
                name="Send to AM"
                onChange={event => onChangeData(event, "sendToAm")}
                defaultValue={sendToAm}
              />
            </InputRow>
            <InputRow>
              <FooterComponent
                label="Send to investor"
                name="Send to investor"
                onChange={event => onChangeData(event, "sendToInvestor")}
                defaultValue={sendToInvestor}
              />
            </InputRow>
            <InputRow last={!customFooter}>
              <FooterComponent
                label="e-mail footer"
                name="Global E-mail footer"
                onChange={event => onChangeData(event, "customFooter")}
                defaultValue={!customFooter}
              />
            </InputRow>
            {customFooter && (
              <InputRow last>
                <TextAreaComponent
                  label="custom signature for e-mail"
                  placeholder="Write here custom signature or left this fields if you don’t want to use the signature"
                  defaultValue={customFooterText}
                  onChange={event => onChangeData(event, "customFooterText")}
                />
              </InputRow>
            )}
          </Main>
          <Main currentHeight="fit-content" width="28%">
            <TextSub
              textAlign="left"
              fontWeight="bold"
              color="#181818"
              marginSize="0 0 25px 0"
            >
              Help
            </TextSub>
            <VariablesComponent label="Shortcuts" variables={variables} />
          </Main>
        </Row>
        <Header isFooter>
          <Btn width={180} hide>
            Set default
          </Btn>
          <Btn
            width={180}
            margin="0 0 0 auto"
            disabled={submitDisable}
            onClick={updateTemplate}
          >
            Save
          </Btn>
        </Header>
      </Fragment>
    );
  }
};

export default connect(
  ({ userReducer: { token } }) => ({ token }),
  null
)(TemplateSetup);
