import React, { Fragment, useState, useEffect } from "react";
import moment from "moment";

import { FlexRow, SepareteLine } from "../../dashBoardCard";
import { TransactionsHeader, ScrollContainer, HeaderTD, TransactionsTD, User } from "../../dealTypeCard";
import { ManualApprove, TxInfo, Wait } from "./styled";
import { Amount } from "../UserInfo/styled";
import { ReactComponent as ExpandIcon } from "../../../../assets/chevron-down.svg";
import { Btn } from "../../buttons";

import { Main } from "../DealForm/Step/styled";
import { Status, Label } from "./styled";
import Api from "../../../../helpers/api";

export const Transactions = ({ transactions, token, history, refresh, assets, dealsImages }) => {
  const [tableFullHeight, setTableFullHeight] = useState(false);
  const [renderTransactions, setRender] = useState(transactions);
  const waitingTransactions = transactions.filter((e) => e.movementType === "PENDING");

  const [filter, setFilter] = useState("all");
  const [wait, setWait] = useState(false);

  const getAssetExtraInfo = (name) => {
    const asset = assets.find((e) => e.assetProperties.assetName === name);
    if (asset)
      return {
        assetSymbol: asset.assetProperties.assetSymbol,
        logo: dealsImages[name],
      };
    else return {};
  };

  const filterTransactions = (type) => {
    setFilter(type);
    if (type === "all") {
      setRender(transactions);
    } else {
      setRender(waitingTransactions);
    }
  };

  useEffect(() => {
    setFilter("all");
    setRender(transactions);
  }, [transactions]);

  const downloadXlsx = async () => {
    setWait(true);
    const res = await Api.getXlsxTransactions(token).catch(() => setWait(false));
    if (res) {
      const { file, fileName } = res;
      const fileURL = URL.createObjectURL(file);
      const link = document.createElement("a");
      link.href = fileURL;
      link.download = fileName;
      link.click();
      setWait(false);
    }
  };

  const renderTable = () => {
    return (
      <Fragment>
        <TransactionsHeader translate={-10}>
          <HeaderTD width="21%" translate={25} boxSizing="border-box">
            User
          </HeaderTD>
          <HeaderTD width="23%" translate={renderTransactions.length > 5 ? 9 : 14} boxSizing="border-box">
            Share name
          </HeaderTD>
          <HeaderTD translate={renderTransactions.length > 5 ? 11 : 16} width="150px" boxSizing="border-box">
            Amount
          </HeaderTD>
          <HeaderTD translate={renderTransactions.length > 5 ? -9 : -3} width="95px" boxSizing="border-box">
            Sold Shares
          </HeaderTD>
          <HeaderTD translate={renderTransactions.length > 5 ? -7 : 0} width="184px" boxSizing="border-box">
            Time
          </HeaderTD>
          <HeaderTD translate={renderTransactions.length > 5 ? -5 : 2} width="110px" boxSizing="border-box">
            Status
          </HeaderTD>
          <HeaderTD width="55px" boxSizing="border-box"></HeaderTD>
        </TransactionsHeader>
        <ScrollContainer noMargin fullHeight={tableFullHeight} minHeight={250} isScroll={renderTransactions.length > 5}>
          {renderTransactions.map((e, i) => {
            const {
              tokenIdentifier,
              time,
              quantity,
              txHash,
              soldShares,
              counterparty,
              logo,
              movementType,
              fullname,
              assetNameMovementFor,
              userId,
            } = e;
            return (
              <TransactionsHeader grey={i % 2 === 0} key={`${txHash}-${i}`}>
                <TransactionsTD lower width="21%" boxSizing="border-box" padding="0 0 0 15px">
                  <User
                    onClick={() => history.push(`/admin/user/${counterparty}/${userId}`)}
                    token={token}
                    userName={fullname}
                    logo={logo}
                  />
                </TransactionsTD>
                <TransactionsTD lower width="23%" boxSizing="border-box">
                  {assetNameMovementFor}
                </TransactionsTD>
                <TransactionsTD width="130px" fWeight="bold" boxSizing="border-box">
                  <Amount movementType={movementType} quantity={quantity} tokenIdentifier={tokenIdentifier} />
                </TransactionsTD>
                <TransactionsTD width="95px" boxSizing="border-box">
                  {soldShares && `${soldShares.toFixed(2)} %`}
                </TransactionsTD>
                <TransactionsTD width="184px" boxSizing="border-box">
                  {moment(time).format("DD/MM/YYYY,hh:mm:ss a")}
                </TransactionsTD>
                <TransactionsTD width="110px" boxSizing="border-box">
                  <Status status={movementType} />
                </TransactionsTD>
                <TransactionsTD width="55px" boxSizing="border-box">
                  {movementType === "PENDING" ? (
                    <ManualApprove
                      refresh={refresh}
                      token={token}
                      tx={txHash}
                      time={moment(time).format("DD/MM/YYYY")}
                      shareName={getAssetExtraInfo(assetNameMovementFor).assetSymbol}
                      image={getAssetExtraInfo(assetNameMovementFor).logo}
                      dealName={e.assetNameMovementFor}
                    />
                  ) : (
                    <TxInfo
                      tx={e}
                      token={token}
                      shareName={getAssetExtraInfo(assetNameMovementFor).assetSymbol}
                      image={getAssetExtraInfo(assetNameMovementFor).logo}
                    />
                  )}
                </TransactionsTD>
              </TransactionsHeader>
            );
          })}
        </ScrollContainer>
      </Fragment>
    );
  };

  return (
    <Main currentHeight={tableFullHeight ? "fit-content" : "445px"} minHeight={445} padding="20px 25px 25px 25px">
      {wait && <Wait />}
      <FlexRow>
        <Btn width={190} margin="0 7px 0 0" transparent={filter !== "all"} onClick={() => filterTransactions("all")}>
          all transactions
        </Btn>
        {waitingTransactions.length ? (
          <Btn
            width={190}
            transparent
            margin="0 auto 0 0"
            transparent={filter !== "pending"}
            onClick={() => filterTransactions("pending")}
          >
            waiting for approval <Label count={waitingTransactions.length} />
          </Btn>
        ) : null}
      </FlexRow>
      {transactions.length ? renderTable() : null}
      <SepareteLine />
      <FlexRow mBottom="0" align="flex-start">
        <Btn hide width={180} onClick={downloadXlsx}>
          download xlsx
        </Btn>
        <Btn
          width={180}
          rightIcon
          onClick={() => setTableFullHeight(!tableFullHeight)}
          transform={Number(tableFullHeight)}
        >
          {tableFullHeight ? "minimize" : "expand"}
          <ExpandIcon />
        </Btn>
      </FlexRow>
    </Main>
  );
};
