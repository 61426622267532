import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import { CircularProgress } from "@material-ui/core";

import { FileManager } from "../../../../data-room-panel/data-room-layout/admin/pages/DealDocs/FileManager";
import DataRoomApi from "../../../../data-room-panel/helpers/api";
import { ErrorMessage } from "../../errorMessage";
import { Stepper } from "../DealForm/Step/Stepper";
import { Header, TextSub, BtnContainer } from "../DealForm/Step/styled";
import { Btn } from "../../buttons";
import { HeaderMain, DealName } from "./styled";
import { icons } from "../../../../models/icons";

import { setDocInfo } from "../../../../modules/dataRoomRedux/dataRoomActions";

let cleanSessionStore = {};

export const DataRoomDeal = ({
  token,
  match: {
    params: { id, dealType },
  },
  history,
  setDocInfo,
}) => {
  const [files, setFiles] = useState(null);
  const [initialLoad, setInitialLoad] = useState(true);
  const [errorObj, setError] = useState(null);

  useEffect(() => {
    getInitialData();
    sessionStorage.setItem("Legal Documents", 1);
    return () => {
      Object.keys(cleanSessionStore).forEach((name) => sessionStorage.removeItem(name));
    };
  }, []);

  const getInitialData = async () => {
    const _files = await DataRoomApi.getDealsFiles(token, id).catch((e) => {
      setError(e);
    });
    if (_files) {
      setFiles(_files);
      _files.folders.map(({ name }) => {
        cleanSessionStore[name] = 1;
      });
      initialLoad && setInitialLoad(false);
    }
  };

  const random = Math.round(Math.round() * 1000);

  if (files) {
    return (
      <Fragment key={`file-manager-deal-data-room-${random}`}>
        <HeaderMain>
          <Btn width={150} transparent onClick={() => history.push(`/admin/deals/edit/${id}`)}>
            {icons.left}
            back to options
          </Btn>
          <DealName>Add New Asset ({files.dealInfo.assetName}) </DealName>
        </HeaderMain>
        <Header height={100} padding="20px 25px">
          <Stepper dealType={dealType} step={dealType === "EXISTED_DEAL" ? 2 : 3} />
        </Header>
        <FileManager
          key={`file-manager-deal-data-room-${random}`}
          token={token}
          assetId={id}
          data={files}
          refreshDocs={getInitialData}
          initialLoad={initialLoad}
          history={history}
          setDocInfo={setDocInfo}
        >
          <Header isFooter between>
            <Btn width={180} white onClick={() => history.push(`/admin/deals/edit/${id}`)}>
              previous step
            </Btn>
            <div>
              <TextSub>4 / 4</TextSub>
            </div>
            <BtnContainer onClick={() => history.push(`/admin/deals/${dealType.toLowerCase()}`)}>
              <Btn width={180}>Save</Btn>
            </BtnContainer>
          </Header>
        </FileManager>
      </Fragment>
    );
  } else if (errorObj) {
    return <ErrorMessage er={errorObj} onClose={() => setError(null)} />;
  } else {
    return <CircularProgress style={{ margin: "auto" }} />;
  }
};

export default connect(
  ({ userReducer: { token } }) => ({
    token,
  }),
  { setDocInfo }
)(DataRoomDeal);
