import styled from "styled-components";
import React from "react";
import { CircularProgress } from "@material-ui/core";

import { ReactComponent as CloseIcon } from "../../../assets/x-circle.svg";

const WaitContainer = styled.div`
  height: calc(100vh - 225px);
  display: flex;
  > div {
    margin: auto;
  }
`;

export const Loader = () => (
  <WaitContainer>
    <CircularProgress />
  </WaitContainer>
);

const Background = styled.section`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: ${({ bg = "rgba(0, 0, 0, 0.15)" }) => bg};
  backdrop-filter: blur(5px);
  display: flex;
  z-index: ${({ zIndex }) => (zIndex ? zIndex : 1000)};
`;

const Wrapper = styled.div`
  width: fit-content;
  min-width: 400px;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  background: ${({ bg = "#ffffff" }) => bg};
  border: 1px solid ${({ borderColor = "#f9f9f9" }) => borderColor};
  box-sizing: border-box;
  box-shadow: 0px 10px 40px rgba(89, 120, 150, 0.06);
  border-radius: 4px;
  margin: auto;
`;

const Header = styled.div`
  height: 52px;
  width: 100%;
  padding: 0 25px;
  background: #ffffff;
  border: 1px solid #f9f9f9;
  box-sizing: border-box;
  box-shadow: 0px 10px 40px rgba(89, 120, 150, 0.08);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  > svg {
    cursor: pointer;
    width: 24px;
    height: 24px;
  }
`;

const Content = styled.div`
  width: 100%;
  padding: 25px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Heading = styled.h5`
  font-family: Muli;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 135%;
  margin: 0;
  color: #181818;
`;

const Text = styled.p`
  margin: ${({ noMargin, margin }) => (noMargin ? 0 : margin ? margin : "15px 0 30px")};
  font-family: Muli;
  font-style: normal;
  font-weight: ${({ bold }) => (bold ? 700 : "normal")};
  font-size: ${({ fSize }) => (fSize ? `${fSize}px` : "18px")};
  line-height: 150%;
  text-align: ${(textAlign = "center") => textAlign};
  color: #333333;
`;

const Button = styled.button`
  outline: none;
  width: 170px;
  height: 32px;
  background: ${({ grey, theme: { primary } }) => (grey ? "transparent" : primary)};
  border-radius: 4px;
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 135%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  padding: 0;
  color: ${({ grey }) => (grey ? "#CACACA" : "#ffffff")};
  box-sizing: border-box;
  border: 1px solid ${({ grey, theme: { primary } }) => (grey ? "#CACACA" : primary)};
  cursor: pointer;
`;

export const ErrorMessage = ({ onClose, er: { message } }) => {
  return (
    <Background onClick={onClose}>
      <Wrapper onClick={(e) => e.stopPropagation()}>
        <Header>
          <Heading>Error</Heading>
          <CloseIcon onClick={onClose} />
        </Header>
        <Content>
          <Text fSize={12}>{message.replace("java.lang.IllegalArgumentException: ", "")}</Text>
          <Button width={200} height={36} onClick={onClose}>
            CLOSE
          </Button>
        </Content>
      </Wrapper>
    </Background>
  );
};

export const SuccessMessage = ({ onClose, message }) => (
  <Background onClick={onClose}>
    <Wrapper onClick={(e) => e.stopPropagation()}>
      <Header>
        <Heading>Success</Heading>
        <CloseIcon onClick={onClose} />
      </Header>
      <Content>
        <Text fSize={12}>{message}</Text>
        <Button width={200} height={36} onClick={onClose}>
          Ok
        </Button>
      </Content>
    </Wrapper>
  </Background>
);
