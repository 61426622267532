import styled from "styled-components";
import { Link } from "react-router-dom";
import React from "react";

export const Header = styled.div`
  height: 60px;
  background: #ffffff;
  border-radius: 4px;
  width: 100%;
  margin-bottom: 30px;
  box-sizing: border-box;
  padding: 14px 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const RouteLink = styled(Link)`
  font-family: Muli;
  position: relative;
  white-space: nowrap;
  padding-bottom: 0;
  padding-top: 0;
  width: ${({ width, fullWidth, _width }) => (width ? `${width}px` : fullWidth ? "100%" : _width ? _width : "94px")};
  border: 1px solid
    ${({ black, hide, error, green, theme: { primary, red } }) =>
      black ? "#20263C" : hide ? "#CACACA" : error ? red : green ? "#55B17C" : primary};
  height: ${({ height }) => (height ? `${height}px` : "32px")};
  background: ${({ black, white, hide, transparent, error, green, theme: { primary } }) =>
    black ? "#20263C" : white || hide ? "#fff" : transparent || error || green ? "transparent" : primary};
  color: ${({ white, hide, transparent, error, green, theme: { primary } }) =>
    white || transparent ? primary : hide ? "#CACACA" : error ? "#FF4343" : green ? "#55B17C" : "#fff"};
  font-size: 10px;
  line-height: 135%;
  text-align: center;
  font-weight: bold;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  border-radius: 4px;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: ${({ margin }) => (margin ? margin : "0")};
  transition: all 0.3s linear;
  text-decoration: none;
  &:disabled {
    background: #e3e3e3 !important;
    color: white !important;
    border: none;
    > svg {
      path {
        stroke: #fff;
      }
    }
  }
  &:focus {
    background: ${({ black, white, hide, transparent, error, green, theme: { primary } }) =>
      black ? "#20263C" : white || hide ? "#fff" : transparent || error || green ? "transparent" : primary};
  }
  > svg {
    margin: ${({ rightIcon }) => (rightIcon ? "0 0 0 6px " : "0 6px 0 0")};
    transform: ${({ transform }) => (transform ? "rotate(180deg)" : "none")};
    transition: all 0.3s linear;

    path {
      stroke: ${({ transparent, error, green, theme: { primary, red } }) =>
        transparent ? primary : error ? red : green ? "#55B17C" : "#fff"};
    }
  }
`;

export const Heading = styled.h3`
  font-weight: bold;
  font-size: 14px;
  line-height: 135%;
  color: #181818;
  margin: ${({ m = "0 0 20px" }) => m};
`;

export const Type = styled.span`
  font-size: 14px;
  line-height: 135%;
  color: rgba(24, 24, 24, 0.6);
  margin-left: auto;
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const Card = styled.div`
  background: #ffffff;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  padding: 20px 25px;
  width: ${({ width = "400px" }) => width};
  box-sizing: border-box;
  height: fit-content;
  margin: ${(m = false) => (m ? "0 0 20px" : 0)};
`;

export const Text = styled.p`
  font-size: 14px;
  line-height: 175%;
  color: #181818;
  margin: 0 0 25px;
`;

export const Image = styled.img`
  width: 100%;
  margin-bottom: 25px;
  border: 1px solid #efefef;
  border-radius: 4px;
`;

export const ActionButton = styled.button`
  width: 240px;
  height: 32px;
  background: ${({ theme: { primary } }) => primary};
  border-radius: 4px;
  border: 1px solid ${({ theme: { primary } }) => primary};
  font-weight: bold;
  font-size: 10px;
  line-height: 135%;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: #ffffff;
  cursor: pointer;
  outline: none;
  &:disabled {
    background: #e3e3e3 !important;
    color: white !important;
    border: none;
    > svg {
      path {
        stroke: #fff;
      }
    }
  }
`;

export const LoadWrapper = styled.section`
  height: calc(100vh - 70px);
  width: 100%;
  display: flex;
  > * {
    margin: auto;
  }
`;

export const Label = styled.h6`
  font-weight: bold;
  font-size: 10px;
  line-height: 135%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: rgba(24, 24, 24, 0.6);
  margin: 0 0 12px;
`;

const Block = styled.div`
  height: 32px;
  background: ${({ theme: { disabledField } }) => disabledField};
  border: 1px solid #efefef;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 135%;
  text-align: center;
  letter-spacing: -0.02em;
  color: #181818;
  justify-content: center;
  padding: 0 15px;
  width: 184px;
  position: relative;
  margin-right: 42px;
  &::after {
    content: "";
    display: block;
    width: 20px;
    height: 1px;
    background: #181818;
    position: absolute;
    top: 14px;
    right: -32px;
  }
`;

const Flex = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0;
  }
`;

const Description = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  line-height: 135%;
  letter-spacing: -0.02em;
  color: #181818;
`;

export const Var = ({ name, description }) => (
  <Flex>
    <Block>{name}</Block>
    <Description>{description}</Description>
  </Flex>
);

export const DropZone = styled.form`
  height: 205px;
  width: 100%;
  background: ${({ theme: { quoteBgInvestor } }) => quoteBgInvestor};
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 25px;
`;

export const DropLabel = styled.div`
  font-weight: bold;
  font-size: 12px;
  line-height: 135%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: rgba(24, 24, 24, 0.6);
  margin-right: 16px;
`;

export const UploadBtn = styled.button`
  border: none;
  outline: none;
  width: 165px;
  height: 32px;
  background: ${({ theme: { primary } }) => primary};
  border-radius: 2px;
  font-weight: bold;
  font-size: 10px;
  line-height: 135%;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  > svg {
    margin-right: 7px;
  }
`;

export const FileBlock = styled.div`
  height: 50px;
  border: 1px solid #efefef;
  box-sizing: border-box;
  border-radius: 4px;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  padding: 0 25px;
`;

export const FileName_ = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 135%;
  color: #181818;
  margin: 0 10px;
  max-width: 410px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  max-width: calc(100% - 300px);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const Size = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 135%;
  color: rgba(24, 24, 24, 0.6);
`;

export const ButtonsBlock = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 196px;
  > button {
    cursor: pointer;
    outline: none;
  }
`;

export const FileButton = styled.button`
  width: 93px;
  height: 24px;
  border: 1px solid ${({ red = false, theme: { primary, red: error } }) => (red ? error : primary)};
  box-sizing: border-box;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ red = false, theme: { primary, red: error } }) => (red ? error : primary)};
  font-weight: bold;
  font-size: 10px;
  line-height: 150%;
  text-transform: uppercase;
  background: transparent;
  > svg {
    margin-right: 7px;
    width: 13px;
    height: 13px;
    path {
      stroke: ${({ red = false, theme: { primary, red: error } }) => (red ? error : primary)};
    }
  }
`;

export const ReaderWrapper = styled.div`
  width: calc(100% - 383px);
  display: flex;
  flex-direction: column;
`;

export const Col = styled.div`
  width: 353px;
`;

export const ResultAction = styled.button`
  width: 100%;
  height: 32px;
  background: ${({ isReject = false }) => (isReject ? "transparent" : "#019d52")};
  border-radius: 2px;
  outline: none;
  margin-bottom: ${({ isReject = false }) => (isReject ? 0 : "12px")};
  box-sizing: border-box;
  border: 1px solid ${({ isReject = false }) => (isReject ? "#FF4343" : "#019d52")};
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({ isReject = false }) => (isReject ? "#FF4343" : "#FFF")};
  font-weight: bold;
  font-size: 10px;
  line-height: 135%;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  padding: 0 16px;
  cursor: pointer;
  > svg {
    width: 16px;
    height: 16px;
  }
  &:disabled {
    background: #e3e3e3 !important;
    color: white !important;
    border: none;
    > svg {
      path {
        stroke: #fff;
      }
    }
  }
`;

export const AskBtn = styled.button`
  height: 32px;
  border: 1px solid ${({ theme: { primary } }) => primary};
  box-sizing: border-box;
  border-radius: 2px;
  width: 100%;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
  font-size: 10px;
  line-height: 135%;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  padding: 0 16px;
  cursor: pointer;
  color: ${({ theme: { primary } }) => primary};
  background: transparent;
  > svg {
    width: 16px;
    height: 16px;
  }
  &:disabled {
    background: #e3e3e3 !important;
    color: white !important;
    border: none;
    > svg {
      path {
        stroke: #fff;
      }
    }
  }
`;

export const ViewerHeader = styled.div`
  height: 50px;
  background: ${({ theme: { headerInvestor } }) => headerInvestor};
  border-radius: ${({ isExpand = false }) => (isExpand ? 0 : "4px 4px 0 0")};
  width: 100%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 0 20px;
`;

export const ViewerDocName = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 135%;
  color: #ffffff;
  margin-right: 10px;
`;

export const SizeDate = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 135%;
  color: rgba(255, 255, 255, 0.5);
`;

export const ExpandBtn = styled.button`
  width: 32px;
  height: 32px;
  background: #192036;
  border-radius: 2px;
  outline: none;
  border: none;
  display: flex;
  margin-left: auto;
  cursor: pointer;
  > svg {
    margin: auto;
  }
`;

export const View = styled.embed`
  width: 100%;
  min-height: calc(100vh - 265px);
  height: ${({ isExpand = false }) => (isExpand ? "calc(100vh - 50px)" : "auto")};
`;

export const ExpandWrapper = styled.section`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 1000;
`;
