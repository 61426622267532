import React from "react";

import { Card, CardHead, Row, Text, InfoColVal as Val } from "./styled";

export const DynamicFinancial = ({ info: { dynamicFinancialJson } }) => {
  const data = JSON.parse(dynamicFinancialJson || "[]");

  const renderFields = () =>
    data.map(({ name, value }, i) => (
      <Row align="flex-start" margin={i === data.length - 1 ? 0 : "0 0 15px 0"} key={`${name}-${value}-${i}`}>
        <Text size={16}>{name}</Text>
        <Val size={18} noTransform styleAdd="text-align: right;max-width:70%;">
          {value}
        </Val>
      </Row>
    ));

  if (Object.keys(data).length) {
    return (
      <Card padding="25px">
        <CardHead margin="0 auto 24px 0">Financial Information</CardHead>
        {renderFields()}
      </Card>
    );
  } else {
    return null;
  }
};
