export default {
  pageName: "Privacy Policy",
  content: [
    {
      heading: `<span>1.</span> INTRODUCTION`,
      text: `
        1.1. This privacy policy (“Privacy Policy”) relates to users (“Users” or “you”) of this website (“Website”), which is operated under
         license from VOC Corp Ltd (“Licensor” or the “Technology Provider”) by: <br/><br/>
  
        Glafka Capital Limited <br/>
        18th Floor 40 Bank Street <br/>
        London <br/>
        E14 5NR <br/>
        United Kingdom <br/>
        Referred to as the “Glafka Capital” or “we” or “us” or “our” <br/><br/>
        
        1.2. This Privacy Policy describes how Glafka Capital collects and uses the information you provide on the Website.
         It also describes the choices available to you regarding our use of your information and how you can access and update this information. <br/>
        1.3. Users within the United Kingdom: The Privacy Policy adheres to guidance provided under the General Data Protection Regulation (“GDPR”)
         and the Data Protection Act 2018 (DPA 2018) <br/>
        1.4. Users outside the United Kingdom: The Privacy Policy provides protection for users on the same basis as
         per guidance provided under the General Data Protection Regulation (“GDPR”).  <br/>
        `,
    },
    {
      heading: `<span>2.</span> GENERAL DATA PROTECTION REGULATION (“GDPR”) IN THE UNITED KINGDOM`,
      text: `
        2.1. In complying with GDPR, we will ensure you have the following rights protected: <br/>
        2.1.1. The right to be informed. <br/>
        2.1.2. The right of access. <br/>
        2.1.3. The right to rectification. <br/>
        2.1.4. The right to erasure. <br/>
        2.1.5. The right to restrict processing. <br/>
        2.1.6. The right to data portability. <br/>
        2.1.7. The right to object. <br/>
        2.1.8. Rights in relation to automated decision making and profiling.
        `,
    },
    {
      heading: `<span>3.</span> INFORMATION WE MAY COLLECT FROM YOU`,
      text: `
        3.1. We may collect and process the following data about you, provided at the time of requesting services or information from us: <br/>
        3.1.1. Information that you provide to us when you write to us (including by email). <br/>
        3.1.2. Information that you provide to us when we speak to you by telephone. We may make and keep a record of the information you share with us. <br/>
        3.1.3. Information that you provide to us by completing any surveys. <br/>
        3.1.4. Information that you provide to us by filling in forms on the Website. This includes Contact Information such as name, email address, mailing address,
         phone number, country of residence, Financial Information such as bank or brokerage account numbers, Unique Identifiers such as username, account number, password
          and Preferences Information such as favourite lists, transaction history, marketing preferences. <br/>
        3.1.5. Details of transactions you carry out through the Website and of the fulfilment of your orders. <br/>
        3.1.6. Data and time of every sign in on the Website. <br/>
        3.1.7. Time spent on each offering. <br/>
        3.1.8. Time spent on the data room documents. <br/>
        3.2. As is true of most websites, we gather certain information automatically and store it in log files. This information includes internet protocol (IP)
         addresses, browser type, internet service provider (ISP), referring/exit pages, operating system, date/time stamp, and clickstream data. We use this information,
          which does not identify individual users, to analyse trends, to administer the site, to track users’ movements around the site and to gather demographic information
           about our user base as a whole. We do not link this automatically collected data to personally identifiable information.
        
        `,
    },
    {
      heading: `<span>4.</span> HOW DO WE COLLECT YOUR DATE AND USES MADE OF THE INFORMATION`,
      text: `
        4.1. You directly provide us with most of the data we collect. We collect data and process data when you:<br/>
        4.1.1. Register online or place an order for our services.<br/>
        4.1.2. Voluntarily complete a customer survey or provide feedback on any of our message boards or via email.<br/>
        4.1.3. View the Website via your browser’s cookies.<br/>
        4.1.4. Read documents uploaded on the Website.<br/>
        4.1.5. Complete an investment offering.<br/>
        4.2. We use information held about you in the following ways:<br/>
        4.3. To ensure that content from the Website is presented in the most effective manner for you and for your computer.<br/>
        4.4. To provide you with information or services that you request from us, where you have consented to be contacted for such purposes.<br/>
        4.5. To carry out our obligations arising from any contracts entered into between you and us.<br/>
        4.6. To notify you about changes to our services.<br/>
        4.7. We may also use your data, and/or permit selected third parties to use your data, to provide you with information about services
         which may be of interest to you and we and/or they may contact you about these. If you are an existing customer, we will only contact you 
         by electronic means (email or SMS) with information about services similar to those which were the subject of a previous sale to you. <br/>
        4.8. We and/or any third party that we permit to use your data will not otherwise contact you by electronic means to provide you with 
        information about services which may be of interest to you, unless you have consented to this. <br/>
        4.9. If you are a new customer, we and/or any third party that we permit to use your data will only contact you by electronic means to
         provide you with information about services which may be of interest to you if you have consented to this. If you do not want us to use
          your data in one or more of the ways mentioned above, or to pass your details on to third parties for marketing purposes, please let us know by contacting us
        `,
    },
    {
      heading: `<span>5.</span> DISCLOSURE OF YOUR INFORMATION`,
      text: `
        5.1. We will share your personal information with third parties only in the ways that are described in this Privacy Policy.  <br/>
        We do not sell your personal information to third parties.
        5.2. We may disclose your personal information to any member of our group, which means our subsidiaries, our ultimate holding
         company and its subsidiaries if connected to the services referred in the Website. <br/>
        5.3. In addition to the above, we may disclose your personal information to third parties: <br/>
        5.3.1. In the event that we sell or buy any business or assets, in which case we may disclose your personal data to the prospective seller 
        or buyer of such business or assets. <br/>
        5.3.2. If all of our assets are acquired by a third party, in which case personal data held by it about its customers will be one of the
         transferred assets. <br/>
        5.3.3. If we are under a duty to disclose or share your personal data in order to comply with any legal obligation, or in order to enforce 
        or apply the Website Terms of Use and other agreements; or to protect the rights, property, or safety of us, our customers, or others. This 
        includes exchanging information with other companies and organizations for the purposes of fraud protection and credit risk reduction. <br/>
        5.4. We do not disclose information about identifiable individuals to our advertisers, but we may provide them with aggregate information 
        about our users on a no-names basis. We may make use of the personal data we have collected from you to enable us to comply with our advertisers'
         wishes by displaying their advertisements to that target audience.
        `,
    },
    {
      heading: `<span>6.</span> ACCESS TO INFORMATION`,
      text: `
        6.1. If your personal information changes, or if you no longer desire our service, you may correct, update, amend, delete/remove or request it be
         deleted by making the changes in your customer account settings page or by contacting us by email or postal mail at the contact information listed below.
          We will respond to your request to access within 30 days. Any access request may be subject to a fee of £50 to meet our costs in providing you with details
           of the information we hold about you.
           <br/>
        6.2. We will retain your information for as long as your account is active or as needed to provide you services. We will retain and use your information
         as necessary to comply with our legal obligations, resolve disputes, and enforce our agreements, which in some cases involving the collection and processing
          of financial data may require a retention period of 7 years.
          <br/>
        6.3. If you wish to subscribe to our newsletter(s), we will use your name and email address to send the newsletter to you. Out of respect for your privacy,
         you may choose to stop receiving our newsletter or marketing emails by following the unsubscribe instructions included in these emails, accessing the email
          preferences in your account settings page.
          <br/>
        6.4. Similarly, you have the right to ask us not to process your personal data for marketing purposes. We will usually inform you (before collecting your data)
         if we intend to use your data for such purposes, or if we intend to disclose your information to any third party for such purposes. You can exercise your right
          to prevent such processing by contacting us at any time.
        `,
    },
    {
      heading: `<span>7.</span> COOKIES AND TRACKING TECHNOLOGIES`,
      text: `
        7.1. We may collect information about your computer, including where available your IP address, operating system and browser type, for system administration.
         This is statistical data about our user's browsing actions and patterns and does not identify any individual.<br/>
        7.2. For the same reason, we may obtain information about your general internet usage by using a cookie file which is stored on the hard drive of your computer.
         Cookies contain information that is transferred to your computer's hard drive. They help us to improve the Website and to deliver a better and more personalized
          service. They enable us:<br/>
        7.2.1. To estimate our audience size and usage pattern.<br/>
        7.2.2. To store information about your preferences, and so allow us to customize the Website according to your individual interests.<br/>
        7.2.3. To speed up your searches.<br/>
        7.2.4. To recognize you when you return to the Website.<br/>
        7.3. You may refuse to accept cookies by activating the setting on your browser which allows you to refuse the setting of cookies. However, if you select
         this setting you may be unable to access certain parts of the Website. Unless you have adjusted your browser setting so that it will refuse cookies, our 
         system may issue cookies when you log on to the Website. <br/>
        7.4. The use of cookies by our partners, affiliates, advertisers or service providers is not covered by our Privacy Policy. We do not have access or control
         over these cookies. <br/>
        7.5. We may partner with third party ad networks to either display advertising on the Website or to manage our advertising on other sites. Our eligible ad network
         partner may use cookies and Web beacons to collect non-personally identifiable information about your activities on this and other websites to provide you targeted
          advertising based upon your interests. 
        `,
    },
    {
      heading: `<span>8.</span> HOW WE STORE, PROCESS AND SECURE YOUR PERSONAL DATA`,
      text: `
        8.1. The data that we collect from you may be transferred to, and stored at, a destination inside or outside the United Kingdom.
         It may also be processed by staffs operating inside or outside the UK who work for us or for one of our suppliers. Such staff may
          be engaged in, among other things, the fulfilment of your orders, the processing of your payment details and the provision of support services. 
          By submitting your personal data, you agree to this transfer, storing and/or processing. We will take such steps as we consider reasonably
           necessary to ensure that your data is treated securely and in accordance with this Privacy Policy. <br/>
        8.2. All information you provide to us via email is stored on our secure servers. <br/>
        8.3. All information you provide to us via the Website is stored on our Licensor’s servers. <br/>
        8.4. Where we have given you (or where you have chosen) a password which enables you to access certain parts of the Website, you are responsible
         for keeping this password confidential. We ask you not to share a password with anyone. <br/>
        8.5. The security of your personal information is important to us. When you enter sensitive information (such as bank account information) on our
         order forms, we encrypt the transmission of that information using secure socket layer technology (SSL). <br/>
        8.6. We follow generally accepted standards to protect the personal information submitted to us, both during transmission and once we receive it. 
        No method of transmission over the Internet, or method of electronic storage, is 100% secure, however. Therefore, we cannot guarantee its absolute security. 
        If you have any questions about security on the Website, you can contact us. <br/>
        `,
    },
    {
      heading: `<span>9.</span> LINKS TO THIRD PARTY SITES`,
      text: `
        9.1. The Website may, from time to time, contain links to and from the website of our suppliers, partner networks, advertisers, affiliates and other third parties. <br/>
        9.2. If you follow a link to any of these websites, please note that these websites should have their own privacy policies and we do not accept any responsibility
         or liability for these policies or the content or operation of these websites. Please check these policies and the terms of the website before you submit any personal
          data to these websites.
        `,
    },
    {
      heading: `<span>10.</span> SOCIAL MEDIA WIDGETS`,
      text: `
        10.1. The Website may include Social Media Features. These Features may collect your IP address, which page you are visiting on the Website,
         and may set a cookie to enable the Feature to function properly. <br/>
        10.2. Social Media Features and Widgets are either hosted by a third party or hosted directly on the Website. Your interactions with these 
        Features are governed by the Privacy Policy of the company providing it.`,
    },
    {
      heading: `<span>11.</span> CHANGES TO OUR PRIVACY POLICY`,
      text: `
        We may update this Privacy Policy to reflect changes to our information practices. If we make any material changes,
         we will notify you by email (sent to the e-mail address specified in your account) or by means of a notice on this
          Website prior to the change becoming effective. We encourage you to periodically review this page for the latest 
          information on our privacy practices.`,
    },
    {
      heading: `<span>12.</span> WEBSITE PROVIDER`,
      text: `
        This Website is provided under license to Glafka Capital Limited by VOC Corp Ltd.
         The management of data on this Website is the exclusive responsibility of Glafka Capital Limited.
        `,
    },
    {
      heading: `<span>13.</span> CONTACT`,
      text: `
        13.1. Questions, comments and requests regarding this Privacy Policy are welcomed and should be addressed to us at: <br/><br/>
  
          Glafka Capital Limited <br/>
          18th Floor 40 Bank Street <br/>
          London <br/>
          E14 5NR <br/>
          United Kingdom <br/>
          info@glafkacapital.com <br/><br/>
          
          13.2. Should you wish to report a complaint or if you feel that we have not addressed your concern in a satisfactory manner, you may contact the Information Commissioner’s Office at:<br/><br/>
          
          Information Commissioner’s Officer <br/>
          T: 0303 123 1113 <br/>
          W: <a href="https://ico.org.uk/make-a-complaint/"  target="_blank"></a>
            `,
    },
  ],
};
