import styled from "styled-components";
import React from "react";
import { CircularProgress } from "@material-ui/core";

const Center = styled.div`
  color: ${({ theme: { primary } }) => primary};
  margin: auto;
`;

export const Loader = () => (
  <Center>
    <CircularProgress color="inherit" />
  </Center>
);
