import React from "react";

import { Card, ProgressBar, DataColumn, Row } from "./styled.js";
import { numberWithCommas } from "../../../../helpers/index.js";
import theme from "models/themes";

export const Raised = ({ baseCurrency, raisedVsToBeRaised: { raised, toBeRaised } }) => {
  return (
    <Card padding="15px 30px 20px">
      <Row margin="0 0 15px 0" justify="space-between">
        <DataColumn
          mRight={30}
          heading="raised amount"
          data={`${numberWithCommas(raised.toFixed())} ${baseCurrency}`}
          circleColor={theme.primaryMiddle}
        />
        <DataColumn
          mRight={40}
          heading="total"
          data={`${numberWithCommas((raised + toBeRaised).toFixed())}  ${baseCurrency}`}
          circleColor={theme.primaryLight}
        />
      </Row>

      <ProgressBar sold={raised} unsold={toBeRaised} />
    </Card>
  );
};
