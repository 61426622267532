import React, { useState, useEffect } from "react";

import { AvatarText, ManagerContainer, Avatar, NoAvatar } from "../../../dashBoardCard";
import Api from "../../../../../helpers/api";

export const ManagerInfo = ({ token, avatar, managerName, small }) => {
  if (!avatar) avatar = {};
  const { fileNameWithExtension, secureHash } = avatar;
  const [_avatar, setAvatar] = useState(null);

  useEffect(() => {
    if (avatar) {
      Api.getAttachment(token, secureHash, fileNameWithExtension).then((blob) => {
        setAvatar(blob || Api.defaultAvatar);
      });
    }
  });
  return (
    <ManagerContainer>
      <Avatar small={small}>{avatar ? <img src={_avatar} alt="avatar" /> : <NoAvatar small={small} />}</Avatar>
      <AvatarText small={small}>{managerName}</AvatarText>
    </ManagerContainer>
  );
};
