import React, { Fragment, useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Switch, Route } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";

import DataRoomApi from "../../../../helpers/api";
import { Flex, Card, RouteLink, Heading, NavLink, CenterContainer } from "./styled";
import { ReactComponent as ArrowLeft } from "../../../../../assets/arrowleft.svg";
import { ReactComponent as File } from "../../../../../assets/file-drr.svg";
import { ReactComponent as Pie } from "../../../../../assets/pie-chart-dr.svg";
import { ReactComponent as Bar } from "../../../../../assets/bar-chart-dr.svg";
import { ReactComponent as Link } from "../../../../../assets/link-dr.svg";
import { ErrorMessage } from "./errorMessage";

import { FileManager } from "./FileManager/";
import { Statistic } from "./Statistics/";
import { WorkLows } from "./WorkLows";
import { HeatMap } from "./HeatMap/";

import { setDocInfo } from "../../../../../modules/dataRoomRedux/dataRoomActions";

let cleanSessionStore = {};

const DealDocs = ({
  token,
  match: {
    params: { id, type },
  },
  setDocInfo,
  history,
}) => {
  const [files, setFiles] = useState(null);
  const [errorObj, setError] = useState(null);
  const [load, setLoad] = useState(true);
  const [initialLoad, setInitialLoad] = useState(true);

  useEffect(() => {
    getInitialData();
    sessionStorage.setItem("Legal Documents", 1);
    return async () => {
      Object.keys(cleanSessionStore).forEach((name) => sessionStorage.removeItem(name));
    };
  }, []);

  const getInitialData = async () => {
    const _files = await DataRoomApi.getDealsFiles(token, id).catch((e) => {
      setLoad(false);
      setError(e);
    });
    if (_files) {
      setFiles(_files);
      _files.folders.map(({ name }) => {
        cleanSessionStore[name] = 1;
      });
      setLoad(false);
      initialLoad && setInitialLoad(false);
    }
  };

  const fromAsset = window.location.hash === "#asset";

  const HeatMapCallBack = useCallback(() => <HeatMap key={`heat-map-${id}`} token={token} assetId={id} />, [id]);
  const StatisticsCallBack = useCallback(() => <Statistic key={`statistic-${id}`} token={token} assetId={id} />, [id]);
  const WorkLowsCallBack = useCallback(() => <WorkLows key={`worklows-${id}`} token={token} assetId={id} />, [id]);

  if (load) {
    return (
      <CenterContainer>
        <CircularProgress />
      </CenterContainer>
    );
  } else if (files && !initialLoad) {
    return (
      <Fragment>
        <Flex>
          <Card width="calc(100% - 590px)" p="0 20px">
            <RouteLink width={150} to={fromAsset ? `/admin/dashboard/${id}` : "/admin/data-room/all"} transparent="1">
              <ArrowLeft />
              back to asset{fromAsset ? "" : "s"}
            </RouteLink>
            <Heading>
              {files.dealInfo.assetName}
              {files.dealInfo.active ? "" : " (Draft)"}
            </Heading>
          </Card>
          <Card width="570px" p="0 30px">
            <Flex mb={0}>
              <NavLink active={type === "file-manager" ? 1 : 0} to={`/admin/data-room/deal/${id}/file-manager`}>
                <File />
                File Manager
              </NavLink>
              <NavLink active={type === "statistics" ? 1 : 0} to={`/admin/data-room/deal/${id}/statistics`}>
                <Pie />
                Statistics
              </NavLink>
              <NavLink active={type === "heat-map" ? 1 : 0} to={`/admin/data-room/deal/${id}/heat-map`}>
                <Bar />
                Heat Map
              </NavLink>
              <NavLink active={type === "workflows" ? 1 : 0} to={`/admin/data-room/deal/${id}/workflows`}>
                <Link />
                Workflows
              </NavLink>
            </Flex>
          </Card>
        </Flex>

        <Switch key="file-system">
          <Route
            path={`/admin/data-room/deal/:id/file-manager`}
            component={() => {
              return (
                <FileManager
                  key="file-system"
                  token={token}
                  data={files}
                  assetId={id}
                  refreshDocs={getInitialData}
                  initialLoad={initialLoad}
                  setDocInfo={setDocInfo}
                  history={history}
                />
              );
            }}
          />
          <Route path={`/admin/data-room/deal/:id/statistics`} component={StatisticsCallBack} />
          <Route path={`/admin/data-room/deal/:id/heat-map`} component={HeatMapCallBack} />
          <Route path={`/admin/data-room/deal/:id/workflows`} component={WorkLowsCallBack} />
        </Switch>
      </Fragment>
    );
  } else if (errorObj) {
    return <ErrorMessage er={errorObj} onClose={() => setError(null)} />;
  } else {
    return null;
  }
};

export default connect(
  ({ userReducer: { token } }) => ({
    token,
  }),
  { setDocInfo }
)(DealDocs);
