import React, { Fragment, useState } from "react";

import Flags from "country-flag-icons/react/3x2";
import { hasFlag } from "country-flag-icons";

import Api from "../../../../helpers/api";
import {
  TextSub,
  FlexRow,
  SelectFilter,
  SepareteLine,
  ScrollContainer,
  Stage,
  DotsButton,
  ButtonsBlock,
  EmailTD,
  FlagWrapper
} from "./styled";
import { Btn } from "../../buttons";
import {
  TransactionsHeader,
  HeaderTD,
  TransactionsTD,
  User
} from "../../dealTypeCard";
import { CircularProgress } from "@material-ui/core";
import { ModalBackground } from "../UserList/styled";
import { ErrorMessage } from "../../errorMessage";

export const StatisticByUser = ({
  statisticByUsers,
  token,
  history,
  toggleModalSendEmail,
  toggleModalUserInfo,
  days,
  id
}) => {
  const [error, setError] = useState(null);
  const [wait, setWait] = useState(false);

  const downloadXlsx = async (id, days) => {
    setWait(true);
    const res = await Api.getXlsxUsersStatisticsAll(token, id, days).catch(
      e => {
        setWait(false);
        setError(e);
      }
    );
    if (res) {
      const { file, fileName } = res;
      const fileURL = URL.createObjectURL(file);
      const link = document.createElement("a");
      link.href = fileURL;
      link.download = fileName;
      link.click();
      setWait(false);
    }
  };

  const headerStyles = (translate, width, padding, justify) => {
    return {
      boxSizing: "border-box",
      translate: translate,
      width: width,
      padding: padding,
      justify: justify
    };
  };

  const renderHeader = table => {
    const headerNames = [
      { User: [15, "230px"] },
      { Email: [table.length > 10 ? 17 : 19, "250px", "0"] },
      { Country: [table.length > 10 ? 12 : 15, "60px", "0 0 0 5px"] },
      { Stage: [table.length > 10 ? 14 : 20, "90px", "0 0 0 5px"] },
      { Visits: [table.length > 10 ? 16 : 22, "45px", "0 0 0 5px"] },
      { "Total Time": [table.length > 10 ? 18 : 26, "80px", "0 0 0 5px"] },
      { "Avg Per Visit": [table.length > 10 ? 18 : 30, "80px", "0 0 0 5px"] },
      { "More Info": [table.length > 10 ? -29 : -15, "156px", "0", "center"] }
    ];
    return headerNames.map(obj => {
      let name = Object.keys(obj);
      name = name[0];
      const arrStyles = obj[name];
      return (
        <HeaderTD key={name} {...headerStyles(...arrStyles)}>
          {name}
        </HeaderTD>
      );
    });
  };

  const renderTable = table => {
    if (table.length)
      return (
        <Fragment>
          <TransactionsHeader width="calc(100% - 18px)" translate={-15}>
            {renderHeader(table)}
          </TransactionsHeader>
          <ScrollContainer isScroll={table.length > 10}>
            {table.map((e, i) => {
              const {
                email,
                fullName,
                logo,
                userId,
                countryCode = "",
                stage,
                visits,
                totalTime,
                avgTimeVisit
              } = e;
              const v = countryCode;
              const Flag = Flags[v];
              return (
                <TransactionsHeader grey={i % 2 === 0} key={email}>
                  <TransactionsTD
                    boxSizing="border-box"
                    width="240px"
                    padding="0 0 0 15px"
                  >
                    <User
                      token={token}
                      userName={fullName}
                      logo={logo}
                      onClick={() =>
                        history.push(`/admin/user/${email}/${userId}`)
                      }
                    />
                  </TransactionsTD>
                  <EmailTD boxSizing="border-box" width="250px" padding="0 5px">
                    {email}
                  </EmailTD>
                  <TransactionsTD
                    boxSizing="border-box"
                    width="60px"
                    fWeight="600"
                  >
                    <FlagWrapper>
                      {hasFlag(countryCode) ? <Flag className="flag" /> : null}
                      {countryCode !== "Unknown" ? countryCode : "-"}
                    </FlagWrapper>
                  </TransactionsTD>
                  <TransactionsTD boxSizing="border-box" width="90px">
                    <Stage type={stage}>{stage}</Stage>
                  </TransactionsTD>
                  <TransactionsTD
                    boxSizing="border-box"
                    width="45px"
                    fWeight="600"
                  >
                    {visits}
                  </TransactionsTD>
                  <TransactionsTD
                    boxSizing="border-box"
                    width="80px"
                    lower
                    fWeight="bold"
                  >
                    {totalTime}
                  </TransactionsTD>
                  <TransactionsTD boxSizing="border-box" width="80px" lower>
                    {avgTimeVisit}
                  </TransactionsTD>
                  <ButtonsBlock>
                    <Btn
                      transparent
                      width={100}
                      height={26}
                      onClick={() => {
                        toggleModalUserInfo(email);
                      }}
                    >
                      more
                    </Btn>
                    <DotsButton
                      email={email}
                      row={i}
                      fullName={fullName}
                      toggleModalSendEmail={toggleModalSendEmail}
                    />
                  </ButtonsBlock>
                </TransactionsHeader>
              );
            })}
          </ScrollContainer>
        </Fragment>
      );
  };

  return (
    <Fragment>
      <FlexRow mBottom="5px" align="flex-start">
        <TextSub textAlign="left" fontWeight="bold" color="#181818">
          Statistic by Users
        </TextSub>
        <SelectFilter
          defaultValue="All stages users"
          values={["All stages users"]}
          margin="0 20px 0 auto"
          width="180px"
        />
        <SelectFilter
          values={["All countries"]}
          defaultValue="All countries"
          width="180px"
        />
      </FlexRow>
      {statisticByUsers && renderTable(statisticByUsers)}
      <SepareteLine />
      <FlexRow mBottom="0" align="flex-start">
        <Btn
          disabled={wait}
          transparent
          width={180}
          onClick={() => {
            downloadXlsx(id, days);
          }}
        >
          download xlsx
        </Btn>
      </FlexRow>
      {wait ? (
        <ModalBackground>
          <CircularProgress />
        </ModalBackground>
      ) : null}

      {error ? (
        <ErrorMessage er={error} onClose={() => setError(null)} />
      ) : null}
    </Fragment>
  );
};
