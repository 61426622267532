import React, { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

import { MainCol, SideCol, UserName, PageLogo, Card, Row } from "components/setup-layout/common";
import { Btn } from "components/admin-layout/buttons";
import { UserFill } from "components/common/icons-primary";

import { AccessInfo } from "./AccessInfo";
import { MainInfo } from "./MainInfo";
import { PlatformDesign } from "./PlatformDesign";
import { Privacy } from "./Privacy";

import { Preview } from "./Preview";

export const PlatformSetup = ({
  match: {
    params: { username },
  },
}) => {
  const [data, setData] = useState({
    email: "",
    companyName: "",
    domain: "",
    slogan: "",
    description: "",
    footerText: "",
    platformLogo: "",
    private: false,
  });

  const [preview, setPreview] = useState(false);
  const linkRef = useRef(null);

  const Help = useCallback(
    () => (
      <Btn key="help" className="side-buttons" transparent width={205}>
        I NEED HELP
      </Btn>
    ),
    []
  );

  const User = useCallback(
    () => (
      <UserName key="user" className="side-buttons">
        <UserFill />
        <span>{username}</span>
      </UserName>
    ),
    []
  );

  const onRedirect = () => {
    if (linkRef && linkRef.current) linkRef.current.click();
  };

  const onChangePreview = () => setPreview(!preview);

  const onDataChange = (key, val) => () => setData({ ...data, [key]: val });

  useEffect(() => console.log(data), [data]);

  return (
    <Fragment>
      <SideCol left>{Help()}</SideCol>
      <MainCol>
        <Row m="0 0 52px" a="space-between">
          {Help()}
          <PageLogo description="Please set up your platform" /> {User()}
        </Row>
        <AccessInfo />
        <MainInfo />
        <PlatformDesign onChange={onDataChange} onPreview={onChangePreview} data={data} />
        <Privacy />
        <Card>
          <Row m="-5px 0 -10px">
            <Btn width={171} hide>
              Cancel
            </Btn>
            <Btn onClick={onRedirect} width={291}>
              FINISH & GO TO CONTRACT
            </Btn>
          </Row>
        </Card>
      </MainCol>
      <SideCol>{User()}</SideCol>
      <Link ref={linkRef} to={`/setup/sign-contract/${username}`} />
      {preview ? <Preview data={data} onClose={onChangePreview} /> : null}
    </Fragment>
  );
};
