import styled from "styled-components";

export const Wrapper = styled.section`
  display: flex;
  box-sizing: border-box;
  justify-content: ${({ justifyContent = "flex-start" }) => justifyContent};
  align-items: ${({ alignItems = "flex-start" }) => alignItems};
  flex-direction: ${({ row = false }) => (row ? "row" : "column")};
  background: ${({ bg = "white" }) => bg};
  width: ${({ width = "100%" }) => width};
  ${({ height }) => (height ? `height: ${height};` : "")};
  ${({ minHeight = "" }) => (minHeight ? `min-height: ${minHeight};` : "")}
  ${({ maxHeight = "" }) => (maxHeight ? `max-height: ${maxHeight};` : "")}
  ${({ padding }) => (padding ? `padding: ${padding};` : "")}
  ${({ margin }) => (margin ? `margin: ${margin};` : "")}
  ${({ wrapBlock }) => (wrapBlock ? "flex-wrap: wrap;" : "")}
  ${({ border = "" }) => (border ? `border: ${border};` : "")}
`;

export const MainWrapper = styled(Wrapper)`
  margin: 70px 0 0 233px;
  min-height: calc(100vh - 70px);
  width: calc(100% - 233px);
  padding: 30px;
  .MuiCircularProgress-colorPrimary {
    color: ${({ theme: { primary } }) => primary};
  }
`;

export const WrapperScrollX = styled(Wrapper)`
  overflow-x: auto;
  min-height: ${({ mHeight = "0" }) => mHeight};
`;

export const WrapperScrollY = styled(Wrapper)`
  overflow-y: auto;
`;
