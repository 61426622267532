import Api from "helpers/api";

export default class ApiKyc {
  static url = Api.url;

  static origin = Api.origin;

  static getInvestorAnswersByID(token, userId) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/kyc/getUserAnswerByUserId/${userId}`, {
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });

      if (result.status === 200) res(await result.json());
      else {
        rej(await result.json());
      }
    });
  }

  static setUserFormStatus(token, action, note, userId) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/kyc/${action}/${userId}`, {
        method: "post",
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ note }),
      });

      if (result.status === 200) res(await result.text());
      else {
        rej(await result.json());
      }
    });
  }

  static downloadFile(token, userId, uuid, fileNameWithExtension, assetId) {
    return new Promise(async (res, rej) => {
      const result = await fetch(
        `${Api.url}/api/dataroom/fileLinkForDownload/Attachments~${userId}/${uuid}_${fileNameWithExtension}?assetId=${assetId}`,
        {
          headers: {
            OriginVoC: Api.origin,
            Authorization: token,
          },
        }
      );

      if (result.status === 200) res(await result.text());
      else {
        rej(await result.json());
      }
    });
  }
}
