import React, { useState, useEffect } from "react";
import {
  DocBlock,
  InputRow,
  BtnBlock,
  DocInput,
  ContainerFlex,
  FileBlock,
} from "../../../containers/container";
import { DocType, InputLabel as DocInputLabel } from "../../../text";
import { Btn } from "../../../buttons";
import { ReactComponent as UploadIcon } from "../../../../../assets/upload.svg";

import {
  createEmptyDoc,
  getFileExtention,
  getFileName,
} from "../../../../../helpers";
import { DOC_TYPES } from "../Step/Information/helpers";
import { ExpandedPanel } from "./ExpandedPanel";
import { Required } from "../styled";
import { Document } from "../../../../investor-layout/Document";

export const DataRoom = ({ token, onChange, defaultValues }) => {
  const [documents, setDocuments] = useState(
    defaultValues.assetProperties.documents || {}
  );
  const [otherDocs, setOtherDocs] = useState(
    defaultValues.assetProperties.documents.otherDocs || []
  );

  useEffect(() => {
    onChange(
      "assetProperties",
      "documents"
    )({
      ...documents,
      otherDocs,
    });
  }, [documents, otherDocs]);

  const onUploadFile = (type) => (e) => {
    const [file] = e.target.files;
    if (type === "otherDocs") {
      setOtherDocs([
        ...otherDocs,
        {
          ...createEmptyDoc(file.name),
          file,
        },
      ]);
    } else {
      setDocuments({
        ...documents,
        [type]: {
          ...documents[type],
          ...createEmptyDoc(file.name),
          file,
        },
      });
    }
  };

  const onChangeRequire = (key, type, orderNumber) => (val) => {
    if (type !== "otherDocs") {
      setDocuments({
        ...documents,
        [type]: {
          ...documents[type],
          [key]: val,
        },
      });
    } else {
      const _otherDocs = [...otherDocs];
      _otherDocs[orderNumber][key] = val;
      setOtherDocs(_otherDocs);
    }
  };

  const onChangeDocumentNameByIndex = (index, extention) => (e) => {
    const { value } = e.target;
    const _otherDocs = [...otherDocs];
    _otherDocs[index].attachmentFile.fileNameWithExtension =
      value + "." + extention;
    setOtherDocs(_otherDocs);
  };

  const onRemoveByIndex = (index) => (e) => {
    setOtherDocs(otherDocs.filter((_, i) => i !== index));
  };

  const onRemoveByType = (type) => (e) => {
    const newDocuments = { ...documents };
    delete newDocuments[type];
    setDocuments(newDocuments);
  };

  const onDelegateToInput = (e) => {
    if (e.target.nodeName === "BUTTON") e.target.querySelector("input").click();
  };

  const onChangeDocumentNameByType = (type, extention) => (e) => {
    const { value } = e.target;
    setDocuments({
      ...documents,
      [type]: {
        ...documents[type],
        attachmentFile: {
          ...documents[type].attachmentFile,
          fileNameWithExtension: value + "." + extention,
        },
      },
    });
  };

  return (
    <ExpandedPanel header="Data room">
      {DOC_TYPES.map(({ type, nameDoc = "" }, i) => {
        const {
          attachmentFile: { fileNameWithExtension = "" },
          ndaRequired = null,
          loiRequired = null,
        } = documents[type] || {
          attachmentFile: {},
        };
        const extention = getFileExtention(fileNameWithExtension);
        const fileName = getFileName(fileNameWithExtension);
        return (
          <DocBlock key={`${type}-${nameDoc}`}>
            <DocType>{nameDoc}</DocType>
            <InputRow noMargin>
              <ContainerFlex w="calc(100% - 480px)">
                <DocInputLabel>Name</DocInputLabel>
                <DocInput
                  width="100%"
                  placeholder="Document name"
                  defaultValue={fileName}
                  onChange={onChangeDocumentNameByType(type, extention)}
                />
              </ContainerFlex>
              {documents &&
              documents[type] &&
              documents[type].attachmentFile &&
              documents[type].attachmentFile.secureHash ? (
                <Document doc={documents[type].attachmentFile} token={token} />
              ) : null}

              <ContainerFlex w="150px" marginSize="auto 0 1px">
                <Required
                  defaultValue={ndaRequired}
                  label="NDA required"
                  onChange={onChangeRequire("ndaRequired", type)}
                />
                <Required
                  defaultValue={loiRequired}
                  label="LOI required"
                  onChange={onChangeRequire("loiRequired", type)}
                />
              </ContainerFlex>

              <ContainerFlex w="270px">
                <DocInputLabel>File</DocInputLabel>
                <BtnBlock white={fileNameWithExtension}>
                  {!fileNameWithExtension ? (
                    <Btn width={260} onClick={onDelegateToInput}>
                      <UploadIcon />
                      <label htmlFor={`custom-id-${type}`}></label>
                      upload file
                      <input
                        id={`custom-id-${type}`}
                        type="file"
                        accept="application/pdf"
                        style={{ display: "none" }}
                        onChange={onUploadFile(type)}
                      />
                    </Btn>
                  ) : (
                    <FileBlock
                      name={fileNameWithExtension}
                      onDelete={onRemoveByType(type)}
                    />
                  )}
                </BtnBlock>
              </ContainerFlex>
            </InputRow>
          </DocBlock>
        );
      })}

      {otherDocs.map(
        (
          {
            attachmentFile,
            attachmentFile: { fileNameWithExtension = "" },
            loiRequired,
            ndaRequired,
          },
          i
        ) => {
          const type = "otherDocs";
          const extention = getFileExtention(fileNameWithExtension);
          const fileName = getFileName(fileNameWithExtension);
          return (
            <DocBlock key={`${type}-${i}`}>
              <InputRow noMargin>
                <ContainerFlex w="calc(100% - 480px)">
                  <DocInputLabel>Name</DocInputLabel>
                  <DocInput
                    width="100%"
                    placeholder="Document name"
                    defaultValue={fileName}
                    onChange={onChangeDocumentNameByIndex(i, extention)}
                  />
                </ContainerFlex>

                <Document doc={attachmentFile} token={token} />

                <ContainerFlex w="150px" marginSize="auto 0 1px">
                  <Required
                    defaultValue={ndaRequired}
                    label="NDA required"
                    onChange={onChangeRequire("ndaRequired", type, i)}
                  />
                  <Required
                    defaultValue={loiRequired}
                    label="LOI required"
                    onChange={onChangeRequire("loiRequired", type, i)}
                  />
                </ContainerFlex>

                <ContainerFlex w="270px">
                  <DocInputLabel>File</DocInputLabel>
                  <BtnBlock white>
                    <FileBlock
                      name={fileNameWithExtension}
                      onDelete={onRemoveByIndex(i)}
                    />
                  </BtnBlock>
                </ContainerFlex>
              </InputRow>
            </DocBlock>
          );
        }
      )}

      <Btn width={215} margin="0 auto 0" onClick={onDelegateToInput}>
        add new document
        <input
          type="file"
          accept="application/pdf"
          style={{ display: "none" }}
          onChange={onUploadFile("otherDocs")}
        />
      </Btn>
    </ExpandedPanel>
  );
};
