import React, { Fragment, useEffect } from "react";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import { icons } from "models/icons";
import { updateAdminNotifications } from "modules/dataRedux/dataAction";
import Api from "helpers/api";

const base = css`
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Header = styled.div`
  ${base}
  justify-content: flex-start;
  height: 60px;
  background: #ffffff;
  width: 100%;
  padding: 5px 25px;
  margin-bottom: 15px;
  > h3 {
    margin: 0;
    font-size: 14px;
    line-height: 135%;
    color: #181818;
  }
`;

const Info = styled.div`
  ${base}
  flex-direction:column;
  height: 460px;
  background: ${({ theme: { emptyCardBg } }) => emptyCardBg};

  width: 100%;
  > svg {
    width: 82px;
    height: 82px;
    margin-bottom: 40px;
    path {
      stroke: ${({ theme: { primary } }) => primary};
    }
  }
  > h2 {
    font-size: 20px;
    line-height: 135%;
    text-align: center;
    letter-spacing: -0.02em;
    color: #192036;
    margin: 0 0 30px;
  }
  > p {
    margin: 0 0 40px;
    width: 500px;
    font-size: 16px;
    line-height: 145%;
    text-align: center;
    color: #181818;
  }
`;

const Button = styled(Link)`
  ${base}
  height: 32px;
  border: 1px solid ${({ theme: { primary } }) => primary};
  font-weight: bold;
  font-size: 10px;
  line-height: 135%;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: ${({ dark, theme: { primary } }) => (dark ? "#fff" : primary)};
  background: ${({ dark, theme: { primary } }) => (dark ? primary : "transparent")};
  width: ${({ small }) => (small ? "150px" : "215px")};
  margin-right: ${({ mr = 25 }) => `${mr}px`};
  text-decoration: none;
  > svg {
    width: 9px;
    height: 9px;
    ${({ dark }) => (dark ? "margin-left: 7px" : "margin-right: 7px")};
    path {
      stroke: ${({ theme: { primary } }) => primary};
    }
  }
`;

const Flex = styled.div`
  ${base}
`;

const Confirmation = ({
  match: {
    params: { assetId },
  },
  updateAdminNotifications,
  token,
}) => {
  useEffect(() => {
    return async () => {
      const assets = await Api.getAssets(token);
      if (assets) {
        updateAdminNotifications(assets.NEW_DEAL.filter((e) => !e.finalized).length, "Assets");
      }
    };
  }, []);

  return (
    <Fragment>
      <Header>
        <Button to="/admin/deals/new_deal" small>
          {icons.left}ASSET LIST
        </Button>
        <h3>Successfull</h3>
      </Header>
      <Info>
        {icons.checkCircle}
        <h2>
          Asset was Successfully <br />
          Published
        </h2>
        <p>
          Your asset is now online and this asset which you give access in rules will be available for them. You can
          send the e-mail to users with template on next page.
        </p>
        <Flex>
          <Button to="/admin/deals/new_deal" mr={12}>
            CONTINUE WITHOUT E-MAIL
          </Button>
          <Button mr={0} dark to={`/admin/send-emails/${assetId}/initial`}>
            SEND E-MAIL TO USERS {icons.right}
          </Button>
        </Flex>
      </Info>
    </Fragment>
  );
};

export default connect(
  ({ userReducer: { token } }) => ({
    token,
  }),
  { updateAdminNotifications }
)(Confirmation);
