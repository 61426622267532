import React, { useState, useEffect } from "react";

import { Main, TextSub } from "../../DealForm/Step/styled";
import { FlexRow } from "../../../dashBoardCard";
import { ScrollContainer } from "../styled";
import {
  ChatWrapper,
  Avatar,
  NoAvatar,
  Col,
  UserName,
  DealFlex,
  DealNameSmall,
  FullWidthBlock,
} from "../../DashBoard/styled";
import { EmptyInfo } from "./emptyInfo";

import Api from "helpers/api";
import { ReactComponent as MessageIcon } from "assets/message-square-thin.svg";

export const ChatCard = ({ token, adminChatThemes, id, newDeal = false }) => {
  const filtered = adminChatThemes.filter((e) => e.assetId === id);

  const renderChats = () => {
    return filtered.map((e) => {
      return <Chat token={token} chat={e} key={`${e.assetId}-${e.username}-${e.unread}`} />;
    });
  };

  return (
    <Main
      currentHeight="415px"
      width={`calc(${newDeal ? "50% - 185px" : "40.2% - 20px"})`}
      padding="20px 25px 25px 25px"
    >
      <FlexRow mBottom="0" align="flex-start">
        <TextSub textAlign="left" fontWeight="bold" color="#181818" marginSize="0 0 25px 0">
          Chat with Investors
        </TextSub>
      </FlexRow>
      {filtered.length ? (
        <ScrollContainer height={325} pLeft={15}>
          {renderChats()}
        </ScrollContainer>
      ) : (
        <EmptyInfo
          heading="There are no chats started yet"
          text="When we’ll have messages to show for you we’ll show them here"
          icon={<MessageIcon />}
        />
      )}
    </Main>
  );
};

const Chat = ({ token, chat: { logo, assetId, unread, userLogo, userFullName, username, lastMessage } }) => {
  const [img, setImg] = useState(null);
  const [dealLogo, setDealLogo] = useState(null);

  useEffect(() => {
    if (!img && userLogo) {
      const { fileNameWithExtension, secureHash } = userLogo;
      Api.getAttachment(token, secureHash, fileNameWithExtension).then((b) => setImg(b));
    }
    if (!dealLogo && logo) {
      const { fileNameWithExtension, secureHash } = logo;
      Api.getAttachment(token, secureHash, fileNameWithExtension).then((b) => setDealLogo(b));
    }
  }, []);
  return (
    <ChatWrapper to={`/admin/chat/${assetId}/${username}`}>
      <FullWidthBlock unread={unread}>
        {img ? <Avatar src={img} alt="logo" /> : <NoAvatar />}

        <Col>
          <UserName>{userFullName}</UserName>
          <DealFlex>
            <DealNameSmall>{lastMessage}</DealNameSmall>
          </DealFlex>
        </Col>
      </FullWidthBlock>
    </ChatWrapper>
  );
};
