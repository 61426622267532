import logo from "assets/logos/asset-manager-logo.svg";

export default {
  origin: "https://valk-demo.cf",
  url: "https://valueonchain.cf",
  logo: logo,
  logoStyles: {
    auth: `width: 310px;height: 42px;margin-bottom: 30px;> img {width: 100%;}`,
    admin: ``,
    investor: ``,
  },
};
