import styled from "styled-components";
import { Link } from "react-router-dom";

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  padding: 0 30px;
  max-width: 1440px;
  box-sizing: border-box;
`;

export const Header = styled.div`
  height: 60px;
  background: #ffffff;
  border-radius: 4px;
  width: 100%;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 20px 30px;
  margin-bottom: 25px;
`;

export const Text = styled.p`
  font-family: Muli;
  margin: ${({ margin }) => (margin ? margin : 0)};
  color: ${({ color }) => (color ? color : "#6A6A6A")};
  font-size: ${({ fSize }) => (fSize ? `${fSize}px` : "16px")};
  text-transform: ${({ upper }) => (upper ? "uppercase" : "none")};
  text-align: ${({ align }) => align};
  line-height: 160%;
  > span {
    font-family: Muli;
    color: #181818;
    font-weight: 600;
  }
`;

export const Heading = styled.h3`
  font-family: Muli;
  font-style: normal;
  font-weight: ${({ fWeight }) => (fWeight ? fWeight : 600)};
  font-size: ${({ fSize }) => (fSize ? `${fSize}px` : "18px")};
  line-height: 135%;
  color: #181818;
  margin: ${({ margin }) => (margin ? margin : 0)};
`;

export const Card = styled.div`
  width: 100%;
  background: #ffffff;
  border-radius: 4px;
  box-sizing: border-box;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  min-height: 20px;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const CardHeader = styled.div`
  width: 100%;
  height: 76px;
  padding: 5px 30px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #f2f2f2;
`;

export const ContentBlock = styled.div`
  width: 100%;
  padding: ${({ padding }) => (padding ? padding : "20px 30px 30px")};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #f2f2f2;
`;

export const SubmitBlock = styled.div`
  height: 82px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
`;

export const Status = styled.div`
  height: ${({ height }) => (height ? `${height}px` : "32px")};
  background: #c6c6c6;
  border-radius: 2px;
  width: ${({ width }) => (width ? `${width}px` : "190px")};
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  text-transform: uppercase;
  color: #ffffff;
  margin-left: 18px;
  > svg {
    margin-right: 9px;
  }
`;

export const Expand = styled.button`
  width: 32px;
  height: 32px;
  border: 1px solid ${({ theme: { primary } }) => primary};
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: ${({ noMargin }) => (noMargin ? 0 : "auto")};
  outline: none;
  cursor: pointer;
  > svg {
    path {
      stroke: ${({ theme: { primary } }) => primary};
    }
  }
`;

export const InfoBlock = styled.div`
  height: 52px;
  width: 100%;
  background: ${({ theme: { disabledField } }) => disabledField};
  border: 1px solid #efefef;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 0 30px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const AssetLink = styled(Link)`
  font-family: Muli;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  color: ${({ theme: { primary } }) => primary};
  text-decoration: none;
  border-bottom: 1px solid rgba(98, 128, 218, 0.2);
`;

export const LinkText = styled.span`
  font-family: Muli;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  color: #333333;
`;

export const Info = styled.button`
  cursor: pointer;
  outline: none;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ theme: { infoButtonBg } }) => infoButtonBg};
  > svg {
    path {
      stroke: ${({ theme: { primary } }) => primary};
    }
  }
  border-radius: 2px;
  border: none;
`;

export const TimerWrapper = styled.div`
  width: 228px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 30px 0 auto;
  align-self: flex-end;
  > span {
    font-family: Muli;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 135%;

    display: flex;
    align-items: center;
    text-align: center;
    letter-spacing: 0.05em;

    color: rgba(24, 24, 24, 0.6);
  }
`;

export const TimerCol = styled.div`
  width: 62px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ mBot }) => mBot};
`;

export const DigitBlock = styled.div`
  width: 30px;
  height: 32px;
  background: ${({ theme: { quoteBgInvestor } }) => quoteBgInvestor};
  border-radius: 4px;
  justify-content: center;
  font-family: Muli;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 135%;
  display: flex;
  align-items: center;
  letter-spacing: 0.05em;
  color: #181818;
`;

export const Label = styled.span`
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 135%;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: rgba(24, 24, 24, 0.6);
`;

export const Background = styled.section`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(5px);
  display: flex;
  z-index: ${({ zIndex }) => (zIndex ? zIndex : 1000)};
`;

export const WrapperModal = styled.div`
  width: 600px;
  min-height: 270px;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border: 1px solid #f9f9f9;
  box-sizing: border-box;
  box-shadow: 0px 10px 40px rgba(89, 120, 150, 0.06);
  border-radius: 4px;
  margin: auto;
`;

export const HeaderModal = styled.div`
  height: 52px;
  width: 100%;
  padding: 0 25px;
  background: #ffffff;
  border: 1px solid #f9f9f9;
  box-sizing: border-box;
  box-shadow: 0px 10px 40px rgba(89, 120, 150, 0.08);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  > svg {
    cursor: pointer;
    width: 24px;
    height: 24px;
  }
`;

export const ContentModal = styled.div`
  width: 100%;
  padding: 25px 30px 30px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const HeadingModal = styled.h5`
  font-family: Muli;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 135%;
  margin: 0;
  color: #181818;
`;
