import styled from "styled-components";
import React from "react";
import { CircularProgress } from "@material-ui/core";

export const Wrapper = styled.section`
  display: flex;
  justify-content: space-between;
  width: 100vw;
  min-height: ${({ preview = false }) => (preview ? "calc(100vh - 80px)" : "100vh")};
  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px ${({ theme: { inputsAutoFillBg } }) => inputsAutoFillBg} inset;
  }
`;

export const Aside = styled.div`
  width: 470px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 50px 50px 60px;
  background: url(${({ bg }) => bg}) ${({ isInvesture }) => !isInvesture && ", #121625"};
  background-blend-mode: soft-light, normal;
  box-sizing: border-box;
  background-position: center;
  background-size: cover;
  filter: grayscale(${({ theme: { greyFilter } }) => greyFilter});
`;

export const FormBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  padding: 30px 50px;
  width: calc(100% - 470px);
  background-color: ${({ infoPage }) => (infoPage ? "#f4f5f6" : "#fff")};
`;

export const LogoBlock = styled.div`
  ${({ styles }) =>
    styles
      ? styles
      : `width: 310px;
         height: 42px;
         margin-bottom: 30px;
         > img {
           width: 100%;
         }`}
`;

export const AsideText = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: ${({ bold }) => (bold ? "bold" : "normal")};
  font-size: ${({ size }) => (size ? `${size}px` : "18px")};
  line-height: ${({ lineHeight = "145%" }) => lineHeight};
  color: ${({ white }) => (white ? "#fff" : "rgba(255, 255, 255, 0.7)")};
  margin: ${({ margin = 0 }) => margin};
  margin-bottom: ${({ mBottom }) => `${mBottom}px`};
  padding-right: ${({ pRight }) => `${pRight}px`};
`;

export const BorderBlock = styled.div`
  width: 100%;
  padding: ${({ p = "60px 0" }) => p};
  box-sizing: border-box;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  margin-bottom: 50px;
`;

export const FlexRow = styled.div`
  display: flex;
`;

export const Step = styled.div`
  width: 40px;
  height: 5px;
  background: #ffffff;
  margin-right: 10px;
  opacity: ${({ active }) => (active ? 1 : 0.2)};
`;

export const SocialLink = styled.a`
  margin-right: 20px;
  transition: opacity 0.3s linear;
  svg {
    width: 32px;
    height: 32px;
  }
  &:last-child {
    margin-right: 0;
  }
  &:hover {
    opacity: 0.7;
  }
`;

export const SmallScreenBg = styled.section`
  width: 100vw;
  height: 100vh;
  background-color: #e5e5e5;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 25px;
  position: fixed;
  top: 0;
  left: 0;
  box-sizing: border-box;
`;

export const ScreenMess = styled.div`
  width: 100%;
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 160%;
  color: #333333;
  text-align: center;
`;

export const GreyBg = styled.div`
  background-color: #f4f5f6;
  min-height: 100vh;
  box-sizing: border-box;
  padding-top: 30px;
`;

//auth preview

const Column = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
`;

export const PreviewLeft = styled(Column)`
  width: 470px;
  padding: 30px 50px 60px;
  background: url(${({ bg }) => bg}), #1f263ec7;
  background-blend-mode: soft-light, normal;
  background-position: center;
  background-size: cover;
  filter: grayscale(${({ theme: { greyFilter } }) => greyFilter});
`;

export const Logo = styled.img`
  height: 75px;
  margin-bottom: 21px;
`;

export const Text = styled.p`
  font-weight: ${({ fWeight = "normal" }) => fWeight};
  font-size: ${({ size = "18" }) => `${size}px`};
  opacity: ${({ o = 1 }) => o};
  margin: ${({ m = "0 0 20px" }) => m};
  line-height: ${({ lHeight = 145 }) => `${lHeight}%`};
  color: #ffffff;
`;

export const BottomBlock = styled(Column)`
  margin-top: auto;
`;

export const Row = styled.div`
  display: flex;
`;

const Layout = styled.section`
  left: 0px;
  top: 0px;
  position: fixed;
  background: rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(5px);
  width: 100vw;
  height: 100vh;
  display: flex;
  > * {
    margin: auto;
  }
`;

export const Loader = () => (
  <Layout>
    <CircularProgress />
  </Layout>
);
