import React, { Fragment } from "react";

import { PieChartWrapper, CapStructData } from "./styledDynamic";
import { Card, CardHead, Col, ValuesFlex } from "./styled";
import { moutNumber } from "helpers";
import ToolTip from "./TootipChart";

export const DynamicCapital = ({ values: { cardName, fields = [] }, baseCurrency }) => {
  const getChartValues = () => {
    let total = 0;
    fields.forEach(({ value }) => {
      total += +value;
    });

    return fields.map(({ name, value, color }) => ({
      // title: `${name} (${value + " " + baseCurrency})`,
      title: `${name} ${((value / total) * 100).toFixed(0)}% (${moutNumber(value) + " " + baseCurrency})`,
      value: +value,
      color,
    }));
  };

  const renderData = () => {
    let total = 0;
    fields.forEach(({ value }) => {
      total += +value;
    });

    return (
      <Fragment>
        {fields.map(({ name, value, color }) => (
          <CapStructData
            half
            key={name}
            heading={name}
            val={value}
            total={total}
            color={color}
            currency={baseCurrency}
          />
        ))}
        <CapStructData half heading="Total" val={total} total={total} currency={baseCurrency} />
      </Fragment>
    );
  };

  const isValuesExist = () => fields.every(({ value }) => value);

  if (fields.length && isValuesExist()) {
    return (
      <Card padding="0">
        <Col height="100%" padding="25px" width="100%" align="center">
          <CardHead margin="0 auto 23px">{cardName}</CardHead>
          <PieChartWrapper>
            <ToolTip data={getChartValues()} />
          </PieChartWrapper>
          <ValuesFlex>{renderData()}</ValuesFlex>
        </Col>
      </Card>
    );
  } else return null;
};
