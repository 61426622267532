import React, { Fragment, useState, useEffect, lazy } from "react";
import { Droppable } from "react-beautiful-dnd";

import { TRow, TCeil, FolderName, Tooltip, Action } from "../styled";
import { SeparateLine } from "./styled";
import { ReactComponent as Edit } from "../../../../../../assets/edit-small.svg";
import { ReactComponent as Delete } from "../../../../../../assets/del-small.svg";

import { Eye, FolderClose, FolderOpen, Arrow, Watermark } from "components/common/icons-primary";

import { AddFolder } from "./addNewFolder";
import { FileComponent } from "./fileComponent";
import DataRoomApi from "../../../../../helpers/api";
import { ErrorMessage } from "../errorMessage";

export const Folder = ({
  folder: { name, files, section },
  onChange,
  token,
  assetId,
  onOpenFile,
  isOpenViewer = false,
  setDocInfo,
  history = { history },
}) => {
  const [open, setOpen] = useState(!!parseInt(sessionStorage.getItem(name)));
  const [renameActive, setRenameActive] = useState(false);
  const [stamp, setStamp] = useState(files.every((file) => file.watermarked === true));
  const [errorObj, setError] = useState(null);

  const waterMarkVisible = () => files.length && files.every((f) => f.type && f.type.toLocaleLowerCase() === "pdf");

  useEffect(() => {
    sessionStorage.setItem(name, open ? 1 : 0);
  }, [open]);

  const onChangeWatermark = async (e) => {
    e.stopPropagation();
    setStamp(!stamp);

    const res = !stamp
      ? await DataRoomApi.watermarkingFolder(token, assetId, name).catch((e) => {
          setStamp(false);
          setError(e);
        })
      : await DataRoomApi.unWatermarkingFolder(token, assetId, name).catch((e) => {
          setStamp(true);
          setError(e);
        });

    if (res) {
      onChange();
    }
  };

  const onChangeRenameStatus = (e) => {
    e.stopPropagation();
    setRenameActive(!renameActive);
  };

  const onDeleteFolder = async (e) => {
    e.stopPropagation();
    const del = await DataRoomApi.deleteFolder(token, name, assetId);

    if (del) onChange();
  };

  const onDelteFile = async (e, token, folderName, fileName, assetId) => {
    e.stopPropagation();
    const del = await DataRoomApi.deleteFile(token, folderName, assetId, fileName);

    if (del) onChange();
  };

  const renderDocs = () => {
    return files.map((e, i) => (
      <FileComponent
        key={`${e.name}-${i}-${name}`}
        file={e}
        i={i}
        section={section}
        token={token}
        assetId={assetId}
        onDelteFile={onDelteFile}
        onRename={onChange}
        onReplace={onChange}
        folderName={name}
        onDoubleClick={() => onOpenFile({ ...e, folderName: name })}
        isOpenViewer={isOpenViewer}
        setDocInfo={setDocInfo}
        history={history}
      />
    ));
  };

  useEffect(() => () => console.log("UNMOUNT"), []);

  return (
    <Fragment>
      {/* need to refactoring droppableId */}
      <Droppable droppableId={`${name}/.,!!,..!!!~//-${section}`} key={`${name}-${section}`}>
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {!renameActive ? (
              <TRow onClick={() => setOpen(!open)}>
                <TCeil big full={isOpenViewer}>
                  <FolderName open={open}>
                    <Arrow />
                    {!open ? name !== "Unsorted Files" ? <FolderClose /> : <Eye /> : <FolderOpen />}
                    <span>
                      {section != 0 ? `${section}.` : null} {name}
                    </span>
                    {name !== "Unsorted Files" && name !== "Legal Documents" ? (
                      <Tooltip>
                        {waterMarkVisible() ? (
                          <Fragment>
                            <Action
                              radio
                              val={stamp}
                              name={stamp ? "watermark" : "set watermark"}
                              icon={<Watermark />}
                              onClick={onChangeWatermark}
                            />
                            <SeparateLine />
                          </Fragment>
                        ) : null}

                        <Action name="rename folder" icon={<Edit />} onClick={onChangeRenameStatus} />
                        <Action name="Delete folder" icon={<Delete />} onClick={onDeleteFolder} />
                      </Tooltip>
                    ) : null}
                  </FolderName>
                </TCeil>
                <TCeil lower>{files.length}</TCeil>
                {isOpenViewer ? null : (
                  <Fragment>
                    <TCeil></TCeil>
                    <TCeil></TCeil>
                    <TCeil middle></TCeil>
                  </Fragment>
                )}
              </TRow>
            ) : (
              <AddFolder
                folderName={name}
                onClose={onChangeRenameStatus}
                onAbort={() => setRenameActive(false)}
                callBack={() => {
                  setRenameActive(false);
                  onChange();
                }}
                isRename
                assetId={assetId}
                filesCount={files.length}
                token={token}
              />
            )}
            {open ? renderDocs() : null}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
      {errorObj ? <ErrorMessage er={errorObj} onClose={() => setError(null)} /> : null}
    </Fragment>
  );
};
