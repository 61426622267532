import React, { useEffect, useState, Fragment } from "react";
import { connect } from "react-redux";
import moment from "moment";

import { getQuestionAsset, getAssets } from "../../../../modules/dataRedux/dataAction";
import { countAllQuestions, countByCategory, isHowPage } from "./helper";
import {
  Header,
  BackLink,
  Heading,
  Col,
  ColWrapper,
  TypeFilter,
  FilterHeading,
  TypeBtn,
  CatFilter,
  CategoryBtn,
  Row,
  SearchInput,
  SortFilter,
  Qestions,
  NoData,
} from "./styled";
import { Btn } from "../../../admin-layout/buttons";
import { ReactComponent as ArrowLeft } from "../../../../assets/arrowleft.svg";
import { ReactComponent as Edit } from "../../../../assets/edit-2.svg";
import { ReactComponent as SearchIcon } from "../../../../assets/search-icon.svg";
import { SelectComponent } from "../../forms";
import { QuestionCardAnswered } from "./QuestionCardAnswered";
import { QuestionCardWaiting } from "./QuestionCardWaiting";
import { EditCategories } from "./EditModal";
import { ErrorMessage } from "../../errorMessage";
import { Wait } from "../DashBoard/styled";
import { EmptyCard as NoInfo } from "components/common/emptyInfo";

const QAAsset = ({
  token,
  match: {
    params: { assetId },
  },
  history,
  getQuestionAsset,
  dataQA,
  getAssets,
  assets,
}) => {
  const [search, setSearch] = useState("");
  const [type, setType] = useState("waiting for answer");
  const [category, setCategory] = useState("All Categories");
  const [filter, setFilter] = useState("Sort by date");
  const [modalOpen, setModalOpen] = useState(false);
  const [error, setError] = useState(null);
  const [ready, setReady] = useState(false);

  const onError = (e) => {
    if (!ready) setReady(true);
    setError(e);
  };

  const onSearchInput = (e) => {
    const {
      target: { value },
    } = e;
    setSearch(value);
  };

  const onChangeType = (type) => {
    setType(type);
  };

  const onChangeCategory = (category) => {
    setCategory(category);
  };

  const onEditCategories = (_) => {
    setModalOpen(!modalOpen);
  };

  const onSelectFilter = (e) => setFilter(e.target.value);

  const renderCategories = () => {
    return ["All Categories", ...dataQA[assetId].categories].map((cat) => {
      return (
        <CategoryBtn
          active={category === cat}
          name={cat}
          count={countByCategory(dataQA[assetId].data, cat, type === "waiting for answer")}
          onClick={onChangeCategory}
        />
      );
    });
  };

  const renderQuestions = () => {
    return dataQA[assetId].data
      .filter((q) => {
        return (
          (type === "waiting for answer" ? q.isWaiting : !q.isWaiting) &&
          (category === "All Categories" || q.category === category) &&
          q.textQuestion.toLowerCase().includes(search.toLowerCase())
        );
      })
      .sort((q1, q2) => {
        switch (filter) {
          case "Sort by date":
            return +moment(q1.data).format("x") - +moment(q2.data).format("x") ? 1 : -1;
          case "Sort by category":
            return q1.category.toLowerCase() > q2.category.toLowerCase() ? 1 : -1;
          case "Sort by user":
            return q1.userFullName.toLowerCase() > q2.userFullName.toLowerCase() ? 1 : -1;
          default:
            return 0;
        }
      })
      .map((q) =>
        type === "waiting for answer" ? (
          <QuestionCardWaiting key={q.questionId} q={q} history={history} token={token} />
        ) : (
          <QuestionCardAnswered key={q.questionId} q={q} history={history} token={token} onRefresh={onRefresh} />
        )
      );
  };

  const onRefresh = () => {
    getQuestionAsset(token, assetId, onError);
  };

  useEffect(() => {
    const initLoad = async () => {
      if (!assets.length) {
        await getAssets(token);
      }
      await getQuestionAsset(token, assetId, onError, () => setReady(true));
    };
    initLoad();
  }, []);

  if (!ready) {
    return <Wait />;
  } else if (!isHowPage(dataQA[assetId]) && error) {
    return (
      <Fragment>
        <Header>
          <BackLink to="/admin/question-and-answer/all">
            <ArrowLeft /> back to assets
          </BackLink>
        </Header>
        <ErrorMessage er={error} onClose={() => setError(null)} />
      </Fragment>
    );
  } else if (isHowPage(dataQA[assetId]) && assets.length) {
    const {
      assetProperties: { assetName },
    } = assets.find((a) => a.linearId === assetId);

    return (
      <Fragment>
        <Header>
          <BackLink to="/admin/question-and-answer/all">
            <ArrowLeft /> back to assets
          </BackLink>
          <Heading>{assetName} Q&A</Heading>
          <Btn width={180} onClick={onEditCategories}>
            <Edit /> EDIT Q&A CATEGORIES
          </Btn>
        </Header>
        <ColWrapper>
          <Col left>
            <TypeFilter>
              <FilterHeading>Type of Questions</FilterHeading>
              <TypeBtn
                name="waiting for answer"
                onClick={onChangeType}
                active={type === "waiting for answer"}
                count={countAllQuestions(dataQA[assetId].data, true)}
              />
              <TypeBtn
                name="answered questions"
                onClick={onChangeType}
                active={type === "answered questions"}
                count={countAllQuestions(dataQA[assetId].data, false)}
              />
            </TypeFilter>

            <CatFilter>
              <FilterHeading>Categories</FilterHeading>
              {renderCategories()}
            </CatFilter>
          </Col>

          <Col>
            <Row>
              <SearchInput placeholder="Search question" value={search} onChange={(e) => onSearchInput(e)} />
              <SearchIcon />
              <SortFilter>
                <SelectComponent
                  top={39}
                  value={filter}
                  values={["Sort by date", "Sort by category", "Sort by user"]}
                  onChange={(e) => onSelectFilter(e)}
                />
              </SortFilter>
            </Row>
            <Qestions>{renderQuestions()}</Qestions>
          </Col>
        </ColWrapper>
        {modalOpen ? (
          <EditCategories
            onRefresh={onRefresh}
            assetName={assetName}
            _categories={dataQA[assetId].categories}
            onBackdropClick={onEditCategories}
            allQuestions={dataQA[assetId].data}
            token={token}
            assetId={assetId}
          />
        ) : null}
      </Fragment>
    );
  } else if (!isHowPage(dataQA[assetId]) && assets.length) {
    const {
      assetProperties: { assetName },
    } = assets.find((a) => a.linearId === assetId);

    return (
      <Fragment>
        <Header>
          <BackLink to="/admin/question-and-answer/all">
            <ArrowLeft /> back to assets
          </BackLink>
          <Heading>{assetName} Q&A</Heading>
          <Btn width={180} onClick={onEditCategories}>
            <Edit /> EDIT Q&A CATEGORIES
          </Btn>
        </Header>
        <NoData>
          <NoInfo heading="No Questions yet" />
        </NoData>
        {modalOpen ? (
          <EditCategories
            onRefresh={onRefresh}
            assetName={assetName}
            _categories={dataQA[assetId].categories}
            onBackdropClick={onEditCategories}
            allQuestions={dataQA[assetId].data}
            token={token}
            assetId={assetId}
          />
        ) : null}
      </Fragment>
    );
  }
};

export default connect(
  ({ userReducer: { token }, dataReducer: { dataQA, assets } }) => ({
    token,
    dataQA,
    assets,
  }),
  { getQuestionAsset, getAssets }
)(QAAsset);
