import React from "react";

import { Card, ProgressBar, DataColumn, Row } from "./styled.js";
import theme from "models/themes";

export const Raised = ({ raised, toBeRaised, transition, noTransition, baseCurrency }) => {
  const total = raised + toBeRaised;
  return (
    <Card padding="15px 30px 20px">
      <Row margin="0 0 15px 0">
        <DataColumn
          mRight={68}
          heading="raised amount"
          data={`${raised.toFixed()} ${baseCurrency}`}
          circleColor={theme.primaryMiddle}
        />
        <DataColumn
          mRight={40}
          heading="total"
          data={`${total.toFixed()}  ${baseCurrency}`}
          circleColor={theme.primaryLight}
        />
      </Row>

      <ProgressBar transition={transition} noTransition={noTransition} sold={raised} unsold={toBeRaised} />
    </Card>
  );
};
