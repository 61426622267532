import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { CircularProgress } from "@material-ui/core";

import Api from "../../../../helpers/api";
import { fileEncodeToBase64 } from "../../../../helpers";
import { Upload } from "components/common/icons-primary";

export const Wrapper = styled.section`
  display: flex;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
`;

export const Col = styled.div`
  box-sizing: border-box;
  width: ${({ width }) => (width ? width : "324px")};
  padding: ${({ padding }) => padding};
  display: flex;
  flex-direction: column;
`;

export const Card = styled.div`
  background: #ffffff;
  border: 1px solid #f0f0f0;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  align-items: ${({ align }) => align};
  justify-content: ${({ justify }) => justify};
  padding: ${({ padding }) => padding};
  margin: ${({ margin }) => margin};
  height: ${({ height }) => height};
  min-height: ${({ minHeight }) => minHeight};
`;

export const UserName = styled.h1`
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 135%;
  text-align: center;
  letter-spacing: -0.02em;
  color: #181818;
  height: 24px;
  margin: 0 0 6px;
`;

export const Role = styled.h6`
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 135%;
  text-align: center;
  letter-spacing: -0.02em;
  color: #8a8a8a;
  margin: 0 0 34px;
`;

const AvatarBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: #e5e5e5;
  border-radius: 50%;
  width: 112px;
  height: 112px;
  margin: 0 auto 19px;
  position: relative;
  &:hover {
    > div.layout-bg {
      display: flex;
    }
  }
`;

const AvatarBg = styled.div`
  position: absolute;
  border-radius: 50%;
  width: 112px;
  height: 112px;
  display: none;
  background: ${({ theme: { primary } }) => primary};
  background-blend-mode: normal, normal;
  opacity: 0.7;
  > svg {
    width: 32px;
    height: 32px;
    margin: auto;
    path {
      stroke: #fff;
    }
  }
`;

const User = styled.img`
  width: 112px;
  height: 112px;
  border-radius: 50%;
  object-fit: cover;
`;

export const Avatar = ({ token, blob, updateAvatar, onRefMount }) => {
  const [load, setLoad] = useState(false);
  const [avatar, setAvatar] = useState(blob);
  const inputRef = useRef(null);

  useEffect(() => setAvatar(blob), [blob]);

  useEffect(() => {
    if (inputRef) {
      onRefMount(inputRef.current);
    }
  }, [inputRef]);

  const onFileUpload = async (e) => {
    setLoad(true);
    const file = e.target.files[0];
    await Api.uploadAttachment(token, file)
      .then(async ({ fileNameWithExtension, secureHash }) => {
        Api.changeAvatar(token, fileNameWithExtension, secureHash).then((json) => {
          console.log(json);
          setAvatar(fileEncodeToBase64(file));
        });
        await Api.getAttachment(token, secureHash, fileNameWithExtension, true).then((blob) => {
          setAvatar(blob);
          updateAvatar(blob);
          setLoad(false);
        });
      })
      .catch((e) => {
        console.log(e);
        setLoad(false);
      });
    setLoad(false);
  };

  return (
    <AvatarBlock onClick={() => inputRef.current.click()}>
      {load ? <CircularProgress /> : avatar && <User src={avatar} alt="user" />}
      <input onChange={onFileUpload} type="file" ref={inputRef} style={{ display: "none" }} />
      <AvatarBg className="layout-bg">
        <Upload />
      </AvatarBg>
    </AvatarBlock>
  );
};

const WaitingWrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  background: #00000045;
`;

export const Loading = () => (
  <WaitingWrapper>
    <CircularProgress />
  </WaitingWrapper>
);

export const CardHeading = styled.h6`
  font-family: Muli;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 135%;
  margin: ${({ noMargin, margin }) => (noMargin ? "0" : margin ? margin : "0 0 20px 0")};
  color: #181818;
`;

export const InfoCard = ({ heading, margin, children }) => {
  return (
    <Card padding="20px 30px 30px" margin={margin}>
      <CardHeading>{heading}</CardHeading>
      {children}
    </Card>
  );
};

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: ${({ margin }) => margin};
`;

export const InputsRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ mb }) => (mb ? mb : "24px")};
  &:last-child {
    margin-bottom: 0;
  }
`;

export const Date = styled.div`
  white-space: nowrap;
  font-family: Muli;
  font-style: normal;
  font-weight: ${({ light }) => (light ? 300 : 600)};
  font-size: ${({ fSize }) => (fSize ? `${fSize}px` : "14px")};
  line-height: 150%;
  color: #333333;
  margin: ${({ margin }) => margin};
`;
