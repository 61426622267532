import React from "react";
import styled from "styled-components";

import { AuthPreviev } from "auth-panel/auth.layout";

const Wrapper = styled.section`
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
`;

const Header = styled.header`
  background: #181e33;
  height: 80px;
  width: 100vw;
  z-index: 10;
`;

export const Preview = ({ data, onClose }) => {
  return (
    <Wrapper>
      <Header onClick={onClose}></Header>
      <AuthPreviev data={data} />
    </Wrapper>
  );
};
