import React, { Fragment, useCallback } from "react";
import { Switch, Route } from "react-router-dom";
import { connect } from "react-redux";
import { updateAvatar, updateUserName, reLogin } from "../../../../modules/userRedux/userAction";

import { Wrapper, Col, Card, MenuBg, Avatar, UserName, MenuLink } from "./styled";
import { Navigation, FacilitatorNav } from "./account.const";

import { Profile } from "./Profile";
import { ProfileAdditional } from "./ProfileAdditional";
import { BankAccount } from "./BankAccount";
import { KYC } from "./KYC";

const current = process.env.REACT_APP_CURRENT;

const Account = ({
  token,
  logo,
  _2fa,
  fullName,
  updateAvatar,
  updateUserName,
  reLogin,
  location: { pathname = "" },
  username,
  countriesOption,
  history,
  role,
  match,
  questionnairePassed,
  investorType,
  id,
}) => {
  const RenderProfile = useCallback(
    () => (
      <Profile
        _2fa={_2fa}
        token={token}
        updateUserName={updateUserName}
        reLogin={reLogin}
        username={username}
        countriesOption={countriesOption}
        investorType={investorType}
        userId={id}
      />
    ),
    [_2fa]
  );

  const RenderBankAccounts = useCallback(() => <BankAccount token={token} />, [_2fa]);

  const RenderKYC = useCallback(() => <KYC token={token} match={match} history={history} />, [_2fa]);

  const RenderProfileAdditional = useCallback(() => <ProfileAdditional token={token} />, [_2fa]);

  const isInvestor = role === "INVESTOR";
  const nav = isInvestor ? Navigation : FacilitatorNav;

  return (
    <Wrapper>
      <Col responsive>
        <Card>
          <MenuBg>
            <Avatar updateAvatar={updateAvatar} token={token} blob={logo} />
          </MenuBg>
          <UserName>{fullName}</UserName>
          {nav
            .filter((el) => (questionnairePassed ? el : el.name !== "Additional Information"))
            .map(({ name, link, icon, re }) => (
              <MenuLink to={link} key={link} active={re.test(pathname) ? 1 : 0}>
                {icon}
                {name}
              </MenuLink>
            ))}
        </Card>
      </Col>
      <Col width="calc(100% - 354px)" responsive>
        <Switch>
          <Route path="/investor/account/profile" component={RenderProfile} />
          {role === "INVESTOR" ? (
            <Fragment>
              <Route path="/investor/account/bank-account" component={RenderBankAccounts} />
              <Route path="/investor/account/verify/:assetId?" component={RenderKYC} />
              {(current === "INVESTURE" || current === "INVESTURE_PROD") && (
                <Route path="/investor/account/additional" component={RenderProfileAdditional} />
              )}
            </Fragment>
          ) : null}
        </Switch>
      </Col>
    </Wrapper>
  );
};

export default connect(
  ({
    userReducer: {
      token,
      fullName,
      logo,
      _2fa,
      username,
      role,
      userInfo: { questionnairePassed, investorType, id },
    },
    asyncDataReducer: { countriesOption },
  }) => ({
    token,
    fullName,
    logo,
    _2fa,
    username,
    countriesOption,
    role,
    questionnairePassed,
    investorType,
    id,
  }),
  { updateAvatar, updateUserName, reLogin }
)(Account);
