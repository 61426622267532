import styled, { css } from "styled-components";

const img = css`
  width: 220px;
  height: 50px;
  border-radius: 2px;
  overflow: hidden;
`;

const font = css`
  font-size: 12px;
  line-height: 135%;
  letter-spacing: -0.02em;
  white-space: nowrap;
`;

export const InputHidder = styled.input.attrs(() => ({
  type: "file",
}))``;

export const FormZone = styled.form``;

export const Img = styled.img`
  width: 220px;
  height: 50px;
`;

export const CropContainer = styled.div`
  ${img};
`;

export const Row = styled.div`
  width: 100%;
  height: ${({ h = "100%" }) => h};
  justify-content: space-between;
  display: flex;
`;

export const Card = styled.div`
  box-sizing: border-box;
  padding: 15px 20px;
  border-radius: 4px;
  background: ${({ theme: { uploaderBg } }) => uploaderBg};
  width: 100%;
  height: 82px;
  margin-bottom: 10px;
`;

export const DefaultImage = styled.img`
  ${img};
`;

export const Col = styled.div`
  width: calc(100% - 240px);
  display: flex;
  flex-direction: column;
`;

export const FileName = styled.div`
  ${font};
  color: ${({ name }) => (name ? "#181818" : "#8A8A8A")};
  margin-bottom: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  height: 16px;
`;

export const ActionButton = styled.button`
  ${font};
  border: none;
  background: transparent;
  color: ${({ theme: { primary, red }, remove }) => (remove ? red : primary)};
  height: fit-content;
  padding: 0;
  cursor: pointer;
  &:first-child {
    margin-right: 12px;
  }
`;
