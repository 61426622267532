import React, { useState, useEffect } from "react";

import Api from "../../../../../helpers/api";
//styled
import { Wrapper } from "../../../main";
import { FlexRow } from "../../../dashBoardCard";
import { Col, Separator } from "../styled";
//components
import { PageHeader } from "./Header";
import { Financial } from "./Financial";
import { Revenue } from "./Revenue";
import { UserStages } from "./UserStages";
import { Users } from "./Users";
import { SignedDocuments } from "./signedDocuments";
// common
import { Activity } from "../common/activity";
import { ChatCard } from "../common/Chat";
import { Register } from "../common/Register";
import { Calendar } from "components/common/calendar";
import { Questions } from "../common/Questions";
import { VievsFolders } from "../common/ViewsFolders";
import { VievsStatistic } from "../common/ViewsStatistic";
import { FoldersTable } from "../common/FoldersTable";
import { StatisticTable } from "../common/StatisticTable";
import { PastTransactions } from "../common/PastTransactions";

export const NewDeal = ({
  token,
  deal,
  deal: {
    newWorkflow,
    linearId,
    assetProperties: { assetName, images, assetSymbol },
  },
  history,
  adminChatThemes,
  role,
  getEvents,
  changeValModalStatus,
}) => {
  const [soldUnsold, setSoldUnsold] = useState(null);
  const [views, setViews] = useState(null);

  useEffect(() => {
    Api.getSoldAndUnsold(token, linearId).then((sold) => setSoldUnsold(sold));
    Api.getFilesViewStatistic(token, linearId).then((v) => setViews(v));
  }, []);

  return (
    <Wrapper bg="transparent">
      <PageHeader deal={deal} token={token} history={history} onOpenNetVal={changeValModalStatus} />
      <FlexRow align="flex-start">
        <Financial deal={deal} soldAndUnsold={soldUnsold} />
      </FlexRow>
      <FlexRow align="flex-start">
        <Revenue deal={deal} soldAndUnsold={soldUnsold} />
        <UserStages token={token} assetId={linearId} />
        <Activity id={linearId} history={history} token={token} />
      </FlexRow>
      <FlexRow align="flex-start">
        <Users token={token} assetId={linearId} history={history} assetName={assetName} role={role} />
      </FlexRow>
      <FlexRow align="flex-start">
        <ChatCard newDeal token={token} id={linearId} adminChatThemes={adminChatThemes} />
        <Col w="330px">
          <Calendar token={token} assetName={assetName} assetNames={[assetName]} inAsset updateAction={getEvents} />
        </Col>
        <Register newDeal history={history} id={linearId} token={token} />
      </FlexRow>
      <FlexRow align="flex-start">
        <Questions token={token} assetId={linearId} history={history} />
      </FlexRow>
      <FlexRow align="flex-start">
        <Col w="calc(41% - 15px)">
          <VievsStatistic assetId={linearId} history={history} info={views} newDeal />
          <Separator />
          <VievsFolders info={views} newDeal />
        </Col>
        <Col w="calc(59% - 15px)">
          <SignedDocuments newWorkflow={newWorkflow} token={token} id={linearId} />
        </Col>
      </FlexRow>
      <FlexRow height={!views && 325}>
        <FoldersTable newDeal info={views} />
        <StatisticTable history={history} assetId={linearId} newDeal info={views} token={token} />
      </FlexRow>
      <PastTransactions
        assetImage={images && images.length && images[0]}
        assetName={assetName}
        newDeal
        token={token}
        assetId={linearId}
        assetSymbol={assetSymbol}
      />
    </Wrapper>
  );
};
