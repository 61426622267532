import React, { useState, useEffect, Fragment, useRef } from "react";
import styled from "styled-components";

import { Btn } from "components/admin-layout/buttons";
import { icons } from "models/icons";
import { getRandomString } from "./helpers.js";
import { Field } from "./field";
import BaseField from "./fieldModel";
import { ConfirmMessagePortal } from "components/admin-layout/errorMessage.js";

const Card = styled.div`
  width: 100%;
  background: #ffffff;
  border: 1px solid #efefef;
  box-sizing: border-box;
  border-radius: 4px;
  padding: ${({ p = "20px 25px" }) => p};
  margin-bottom: ${({ mb = "30px" }) => mb};
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  align-items: center;
`;

const Heading = styled.h3`
  font-size: 14px;
  line-height: 135%;
  color: #181818;
  margin: ${({ m = 0 }) => m};
`;

const Buttons = styled.div`
  display: flex;
  margin-left: auto;
`;

const Button = styled(Btn)`
  > svg {
    width: 16px;
    height: 16px;
    margin: auto;
  }
`;

const Line = styled.div`
  width: calc(100% + 50px);
  margin: 25px 0 20px;
  border-top: 1px solid #efefef;
  transform: translateX(-25px);
`;

const Block = styled.div`
  height: 60px;
  background: ${({ theme: { emptyCardBg } }) => emptyCardBg};

  border-radius: 2px;
  width: 100%;
  display: flex;
  margin-bottom: ${({ mb = 0 }) => mb};
`;

const Input = styled.input`
  width: 471px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #efefef;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 0 20px;
  margin-right: 12px;
  outline: none;
`;

const More = styled.div`
  height: 32px;
  width: 32px;
  position: relative;
  background: #eeeeee;
  border-radius: 4px;
  display: flex;
  cursor: pointer;
  > svg {
    width: 16px;
    height: 16px;
    margin: auto;
    path {
      stroke: #333333;
    }
  }
  &:hover {
    > div {
      display: flex;
    }
  }
`;

const Paper = styled.div`
  position: absolute;
  width: 200px;
  bottom: 31px;
  left: -87px;
  padding: 15px;
  background: #ffffff;
  border: 1px solid #edebeb;
  box-sizing: border-box;
  box-shadow: 0px 10px 40px rgba(89, 120, 150, 0.06);
  border-radius: 4px;
  display: none;
  flex-direction: column;
  &:after {
    content: "";
    display: block;
    width: 20px;
    height: 20px;
    border: 1px solid #edebeb;
    box-sizing: border-box;
    border-radius: 4px;
    position: absolute;
    background: #fff;
    bottom: -8px;
    left: 92px;
    transform: rotate(45deg);
    z-index: 2;
  }
  &:before {
    position: absolute;
    content: "";
    display: block;
    width: 30px;
    height: 15px;
    background: #fff;
    z-index: 3;
    bottom: 0;
    left: 87px;
  }
`;

const Action = styled.h6`
  font-size: 10px;
  line-height: 135%;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  margin: 0 0 10px;
  color: #181818;
  display: flex;
  align-items: center;
  &:last-child {
    margin: 0;
    svg {
      path {
        stroke: #ff4343;
      }
    }
  }
  > svg {
    width: 9px;
    height: 9px;
    margin-right: 7px;
    path {
      stroke: ${({ theme: { primary } }) => primary};
    }
  }
`;

export const Section = ({ defaultValue, onRemove, onChange, onCopy }) => {
  const [value, setValue] = useState({ ...defaultValue });
  const { sectionName, fields, id } = value;
  const [edit, setEdit] = useState(false);
  const [newName, setNewName] = useState(sectionName);
  const [confirm, setConfirm] = useState(false);
  const bottomRef = useRef(null);

  const onScroll = () => {
    if (bottomRef && bottomRef.current) {
      setTimeout(() => {
        bottomRef.current.scrollIntoView({ behavior: "smooth" });
      }, 100);
    }
  };

  const onChangeConirm = () => {
    setConfirm(!confirm);
  };

  const onChangeName = (e) => {
    setNewName(e.target.value);
  };

  const onSaveName = () => {
    setValue({ ...value, sectionName: newName });
    onChangeEdit();
  };

  const onAddField = () => {
    const _value = { ...value };
    _value.fields.push(new BaseField());
    setValue(_value);
  };

  useEffect(() => {
    onChange(value);
  }, [value]);

  const _onRemove = () => {
    onRemove(id);
  };

  const onChangeEdit = () => {
    setEdit(!edit);
  };

  const onChangeField = (f) => {
    const _fields = [...fields];
    const index = _fields.findIndex((el) => el.id === f.id);
    _fields[index] = f;
    setValue({ ...value, fields: _fields });
  };

  const renderFields = (fields) => {
    return fields.map((f, i) => {
      return (
        <Fragment key={`key-${f.id}-${i}`}>
          {i === fields.length - 1 ? <div ref={bottomRef} /> : null}
          <Field
            f={f}
            key={`key-${f.id}`}
            onChange={onChangeField}
            onRemove={onRemoveField}
            onCopy={onCopyField}
            error={f.error}
            valuesError={f.valuesError}
          />
        </Fragment>
      );
    });
  };

  const _onCopy = () => {
    //create section copy object
    const copy = JSON.parse(JSON.stringify(value));
    //set new section  id
    copy.id = getRandomString(16);
    copy.sectionName += "(copy)";
    //iterate all fields to set new field id
    copy.fields.forEach((f) => {
      f.id = getRandomString(16);
      //clear all current answers
      f.values = [];
      //set new options id
      f.options.forEach((o) => {
        o.id = getRandomString(16);
      });
    });
    onCopy(copy);
  };

  const onRemoveField = (id) => {
    const _fields = [...fields];
    const index = _fields.findIndex((el) => el.id === id);
    _fields.splice(index, 1);
    setValue({ ...value, fields: _fields });
  };

  const onCopyField = (f) => {
    const _fields = [...fields];
    const copy = JSON.parse(JSON.stringify(f));

    copy.id = getRandomString(16);
    copy.values = [];
    copy.options.forEach((o) => {
      o.id = getRandomString(16);
    });
    _fields.push(copy);
    setValue({ ...value, fields: _fields });
    onScroll();
  };

  if (!fields.length) {
    return (
      <Card>
        <Row>
          {edit ? (
            <Fragment>
              <Input placeholder="Section name" value={newName} onChange={onChangeName} />
              <Button width={32} onClick={onSaveName}>
                {icons.check}
              </Button>
            </Fragment>
          ) : (
            <Heading>{sectionName}</Heading>
          )}

          <Buttons>
            <Button width={32} margin="0 10px 0 0" onClick={onChangeEdit}>
              {icons.edit}
            </Button>
            <Button width={32} error onClick={_onRemove}>
              {icons.crossRed}
            </Button>
          </Buttons>
        </Row>
        <Line />
        <Block>
          <Btn width={200} margin="auto" onClick={onAddField}>
            {icons.plusCircle}
            ADD NEW question
          </Btn>
        </Block>
      </Card>
    );
  } else {
    return (
      <Fragment>
        <Card mb="10px">
          <Row>
            {edit ? (
              <Fragment>
                <Input placeholder="Section name" value={newName} onChange={onChangeName} />
                <Button width={32} onClick={onSaveName} margin="0 auto 0 0">
                  {icons.check}
                </Button>
              </Fragment>
            ) : (
              <Fragment>
                <Heading>{sectionName}</Heading>
                <More>
                  {icons.more}
                  <Paper>
                    <Action onClick={_onCopy}>{icons.copy} COPY SECTION</Action>
                    <Action onClick={onChangeEdit}>{icons.edit} RENAME SECTION</Action>
                    <Action onClick={onChangeConirm}>{icons.cross} Remove Section</Action>
                  </Paper>
                </More>
              </Fragment>
            )}
          </Row>
        </Card>
        {renderFields(fields)}
        <Block mb="30px">
          <Btn width={200} margin="auto" onClick={onAddField}>
            {icons.plusCircle}
            ADD NEW question
          </Btn>
        </Block>
        {confirm ? (
          <ConfirmMessagePortal
            heading="Remove section"
            message={`Are you sure to remove <b>${sectionName}</b> with <b>${fields.length} fields</b>?`}
            onClose={onChangeConirm}
            onSubmit={_onRemove}
          />
        ) : null}
      </Fragment>
    );
  }
};
