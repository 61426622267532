export const DOC_TYPES = [
  { type: "teaser", nameDoc: "Teaser" },
  { type: "nda", nameDoc: "NDA" },
  { type: "infoMemo", nameDoc: "Info Memo" },
  { type: "loi", nameDoc: "LOI" },
  { type: "dealInfo", nameDoc: "Deal Info" },
  { type: "spa", nameDoc: "SPA" },
];

export function preventDefaultCustom(e) {
  e.preventDefault();
  e.stopPropagation();
}

export const options = {
  fontSize: [8, 10, 14, 18, 24, 36],
  formats: ["p", "blockquote", "h1", "h2", "h3"],
  buttonList: [["formatBlock", "bold", "underline", "italic", "align", "list"]],
};
