export const countAllQuestions = (arr, forWaiting) => {
  let count = 0;
  forWaiting
    ? arr.forEach(el => (el.isWaiting ? count++ : null))
    : arr.forEach(el => (el.isWaiting ? null : count++));

  return count;
};

export const countByCategory = (arr, category, forWaiting) => {
  let count = 0;
  if (category === "All Categories") {
    count = countAllQuestions(arr, forWaiting);
  } else {
    forWaiting
      ? arr.forEach(el => {
          if (el.category === category && el.isWaiting === forWaiting) {
            count++;
          }
        })
      : arr.forEach(el => {
          if (el.category === category && el.isWaiting === forWaiting) {
            count++;
          }
        });
  }
  return count;
};

export const countAllCategoryQuestions = (arr, category) => {
  return arr.reduce((count, el) => {
    return count + el.category === category ? 0 : 1;
  }, 0);
};

export const countAllRemoveCategoryQuestions = (arr, categories = []) => {
  return arr.reduce((count, el) => {
    return count + (categories.includes(el.category) ? 1 : 0);
  }, 0);
};

export const isHowPage = obj => {
  if (!obj) return false;

  if (!Object.keys(obj).length) {
    return false;
  } else {
    return obj.data.length;
  }
};
