import React from "react";

import { ReactComponent as CloseIcon } from "../../../../assets/x-circle.svg";
import { ReactComponent as Plus } from "../../../../assets/plus-circle.svg";
import {
  ModalBackground,
  ModalWrapper,
  ModalHeader,
  ModalHeading,
  Col,
  Text,
  IbanSelect,
} from "./styled";
import { Button } from "../Offerings/styled";

export const BankDetails = ({
  isOpen,
  onBackDropClick,
  bankDetails,
  history,
  onSubmit,
  callBack,
  onAddNewAccount,
  defaultValue,
}) => {
  return isOpen ? (
    <ModalBackground onClick={onBackDropClick}>
      <ModalWrapper onClick={(e) => e.stopPropagation()}>
        <ModalHeader boxShadow="0px 10px 40px rgba(89, 120, 150, 0.08)">
          <ModalHeading>
            {bankDetails ? "Confirm Bank Details" : "Add Your Bank Details"}
          </ModalHeading>
          <CloseIcon onClick={onBackDropClick} />
        </ModalHeader>
        {bankDetails ? (
          <Col padding="20px 30px" align="center">
            <Text fSize={14} color="#192036" margin="0 0 18px 0">
              Choose your correct IBAN
            </Text>
            <IbanSelect
              defaultValue={defaultValue}
              ibans={bankDetails}
              history={history}
              callBack={(accountNumber) => callBack(accountNumber)}
              onAddNewAccount={onAddNewAccount}
            />
            <Button
              width={180}
              height={36}
              size={12}
              tTransform="uppercase"
              margin="24px 0 0 0"
              onClick={onSubmit}
            >
              Confirm
            </Button>
          </Col>
        ) : (
          <Col padding="20px 30px" align="center">
            <Text fSize={14} color="#192036" margin="0 0 24px 0" align="center">
              We need your bank details for next step of investment proccess
            </Text>
            <Button width={320} height={36} onClick={onAddNewAccount}>
              <Plus style={{ marginRight: 10 }} /> Add Account
            </Button>
          </Col>
        )}
      </ModalWrapper>
    </ModalBackground>
  ) : null;
};
