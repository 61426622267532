import React, { Fragment, useEffect, useReducer } from "react";

import { Wrapper, Column, InputRow, Footer, Card, CardHead, RightBlock, Expand } from "./styled";
import {
  TextFieldComponent,
  SelectComponent,
  ImageUploaderComponent,
  TextAreaComponent,
  PhoneComponent,
} from "components/admin-layout/forms";
import { Btn } from "components/admin-layout/buttons";
import sponsorState, { reducer } from "./sponsorReducer";
import { AdditionalInformation } from "components/admin-layout/pages/DealForm/FullFormCards/AdditionalInformation";
import { KeyMetrics } from "./keyMetrics";
import { ErrorMessagePortal } from "components/admin-layout/errorMessage";
import Api from "helpers/api";
import { icons } from "models/icons";

export const CreateSponsor = ({ token, onChangeRole, roles, countries, history, sponsorData = false, role }) => {
  const [state, dispatch] = useReducer(
    reducer,
    sponsorData ? { ...sponsorState, sponsor: { ...sponsorData } } : sponsorState
  );
  const {
    wait,
    error,
    sponsor,
    sponsor: {
      fullName,
      countryOfResidence,
      slogan,
      description,
      email,
      phoneNumber,
      webSite,
      userDynamicProperties,
      additionalInformation,
      backgroundLogo,
      logo,
    },
    cardsClose: { about, metrics, additional },
  } = state;

  const isUpdate = Boolean(sponsorData);

  const onChangeDynamic = (_) => (e) => {
    const blocks = [...e];
    blocks.forEach((el) => delete el.newBlock);
    dispatch({
      type: "ON_CHANGE",
      payload: { key: "additionalInformation", data: blocks },
    });
  };

  const onChangeSimple = (key, value) => {
    dispatch({ type: "ON_CHANGE", payload: { key: key, data: value } });
  };

  const onError = (error) => {
    dispatch({ type: "ON_ERROR", payload: { error } });
  };

  const onExpand = (card) => () => {
    dispatch({ type: "ON_EXPAND", payload: { key: card } });
  };

  const onSubmit = async () => {
    dispatch({ type: "ON_WAIT" });
    const _sponsor = { ...sponsor };
    if (_sponsor.logo && !_sponsor.logo.secureHash) {
      const file = await Api.uploadAttachment(token, _sponsor.logo).catch(onError);
      if (file) _sponsor.logo = file;
    }
    if (_sponsor.backgroundLogo && !_sponsor.backgroundLogo.secureHash) {
      const file = await Api.uploadAttachment(token, _sponsor.backgroundLogo).catch(onError);
      if (file) _sponsor.backgroundLogo = file;
    }
    const res = isUpdate
      ? await Api.updateSponsor(token, _sponsor)
      : await Api.registerSponsor(token, _sponsor).catch(onError);
    if (res) {
      try {
        history.goBack();
      } catch (_) {
        history.push("/admin/users");
      }
    }
  };

  const disabled = () => !fullName || !countryOfResidence || !email;

  return (
    <Fragment>
      <Wrapper>
        <Column width="calc(100% - 353px)">
          <Card>
            <CardHead>Main Information</CardHead>
            <InputRow>
              <TextFieldComponent
                width="calc(50% - 15px)"
                label="company name"
                placeholder="Company name"
                defaultValue={fullName}
                onChange={(e) => onChangeSimple("fullName", e.target.value)}
              />
              <TextFieldComponent
                width="calc(50% - 15px)"
                label="e-mail"
                placeholder="Company e-mail"
                defaultValue={email}
                onChange={(e) => onChangeSimple("email", e.target.value)}
                disabled={sponsorData}
              />
            </InputRow>
            <InputRow last>
              <SelectComponent
                view="filter"
                defaultValue={countryOfResidence}
                onChange={(e) => onChangeSimple("countryOfResidence", e.target.value)}
                values={countries}
                label="Country of registration (optional)"
                placeholder="Choose from list"
              />
            </InputRow>
          </Card>
          <Card height={438} close={about}>
            <CardHead>About Sponsor (optional)</CardHead>
            <RightBlock>
              <Expand close={about} onClick={onExpand("about")}>
                {icons.arrow}
              </Expand>
            </RightBlock>
            <InputRow>
              <TextFieldComponent
                label="Slogan"
                placeholder="Company slogan goes here"
                defaultValue={slogan}
                onChange={(e) => onChangeSimple("slogan", e.target.value)}
              />
            </InputRow>
            <InputRow>
              <ImageUploaderComponent
                token={token}
                sponsorBg
                width="calc(100% - 303px)"
                label="Background image"
                onChange={(file) => onChangeSimple("backgroundLogo", file)}
                file={backgroundLogo}
              />
              <ImageUploaderComponent
                token={token}
                width="273px"
                label="Avatar image"
                onChange={(file) => onChangeSimple("logo", file)}
                file={logo}
              />
            </InputRow>
            <InputRow last>
              <TextAreaComponent
                label="short information (up to 350 symbols)"
                placeholder="Sponsors short information"
                defaultValue={description}
                onChange={(e) => onChangeSimple("description", e.target.value)}
              />
            </InputRow>
          </Card>
          <KeyMetrics
            height={189}
            close={metrics}
            onExpand={onExpand("metrics")}
            defaultValue={userDynamicProperties}
            onChange={(arr) => onChangeSimple("userDynamicProperties", arr)}
          />
          <AdditionalInformation blocks={additionalInformation} buttonExpand onChange={onChangeDynamic} />
        </Column>
        <Column width="323px">
          <Card>
            <CardHead>Options</CardHead>
            <InputRow last>
              <SelectComponent
                label="User type"
                values={roles}
                defaultValue="SPONSOR"
                onChange={(e) => {
                  onChangeRole(e.target.value);
                }}
                disabled={sponsorData}
              />
            </InputRow>
          </Card>
          <Card height={250} close={additional}>
            <CardHead>Additional Contact Information</CardHead>
            <RightBlock>
              <Expand close={additional} onClick={onExpand("additional")}>
                {icons.arrow}
              </Expand>
            </RightBlock>
            <InputRow>
              <TextFieldComponent
                label="web-site"
                placeholder="Company web-site"
                defaultValue={webSite}
                onChange={(e) => onChangeSimple("webSite", e.target.value)}
              />
            </InputRow>
            <InputRow last>
              <PhoneComponent
                isAdmin
                label="Phone Number"
                placeholder="Phone number"
                defaultValue={phoneNumber}
                onChange={(e) => onChangeSimple("phoneNumber", e)}
              />
            </InputRow>
          </Card>
        </Column>
      </Wrapper>
      <Footer>
        <Btn hide width={180}>
          Cancel
        </Btn>
        <Btn width={180} disabled={wait || disabled()} onClick={onSubmit}>
          {isUpdate ? "Update" : "Add"} {role === "SPONSOR" ? "account" : "user"}
        </Btn>
      </Footer>
      {error && <ErrorMessagePortal er={error} onClose={() => onError(null)} />}
    </Fragment>
  );
};
