import React, { useState, useEffect } from "react";
import { CircularProgress } from "@material-ui/core";

import Api from "../../../../helpers/api";
import { Col, Card, CardHeading, Account, InfoText } from "./styled";
import { Button } from "../Offerings/styled";
import { ReactComponent as PlusIcon } from "../../../../assets/plus-circle.svg";
import { AddBankAccount } from "./AddBankAccount";

export const BankAccount = ({ token }) => {
  const [bankAccounts, setBankAccounts] = useState(null);
  const [load, setLoad] = useState(true);
  const [newAccountModalOpen, setNewAccountModalOpen] = useState(false);

  const openNewAccModal = () => {
    setNewAccountModalOpen(!newAccountModalOpen);
  };

  const loadData = async () => {
    const res = await Api.getBankDetails(token);
    if (res) setBankAccounts(res);
    console.log(res);
    setLoad(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  const renderAccounts = () => {
    return bankAccounts.map((e, i) => {
      return <Account key={e.iban} defaultAcc={i == -0} account={e} />;
    });
  };

  if (load) {
    return <CircularProgress style={{ margin: "auto" }} />;
  } else if (bankAccounts) {
    return (
      <Col width="100%">
        <Card margin="0 0 12px 0" height="60px" padding="18px 30px">
          <CardHeading noMargin>Bank Accounts</CardHeading>
        </Card>
        {renderAccounts()}

        <Button width={240} height={46} size={14} margin="25px 0 0" onClick={openNewAccModal}>
          <PlusIcon style={{ marginRight: 8 }} /> Add New Account
        </Button>

        <AddBankAccount
          token={token}
          isOpen={newAccountModalOpen}
          action="Add"
          onBackDropClick={openNewAccModal}
          callBack={(data) => {
            setTimeout(() => {
              console.log(data);
              loadData();
            }, 1000);
          }}
        />
      </Col>
    );
  } else {
    return (
      <Card align="center" justify="center" height="237px" padding="20px 30px">
        <CardHeading margin="0 auto 24px">You have not connected bank account yet</CardHeading>
        <InfoText>
          In order to transfer funds to and from your wallet, you’ll need to provide an external bank account
        </InfoText>
        <Button width={240} height={42} size={14} margin="42px 0 0" onClick={openNewAccModal}>
          <PlusIcon style={{ marginRight: 8 }} /> Add New Account
        </Button>

        <AddBankAccount
          token={token}
          isOpen={newAccountModalOpen}
          action="Add"
          onBackDropClick={openNewAccModal}
          callBack={(data) => {
            setTimeout(() => {
              console.log(data);
              loadData();
            }, 1000);
          }}
        />
      </Card>
    );
  }
};
