import React from "react";

import { ReactComponent as PlusCircle } from "../assets/plus-circle.svg";
import { ReactComponent as Remove } from "../assets/delete-file.svg";
import { ReactComponent as Close } from "../assets/x-circle.svg";
import { ReactComponent as Edit } from "../assets/edit-2.svg";
import { ReactComponent as Check } from "../assets/check.svg";
import { ReactComponent as Users } from "../assets/users.svg";
import { ReactComponent as User } from "../assets/user-1.svg";
import { ReactComponent as NewReqests } from "../assets/user-check.svg";
import { ReactComponent as Shares } from "../assets/briefcase.svg";
import { ReactComponent as CreditCard } from "../assets/credit-card.svg";
import { ReactComponent as Plus } from "../assets/plus-circle.svg";
import { ReactComponent as Search } from "../assets/search-icon.svg";
import { ReactComponent as Arrow } from "../assets/chevron-down.svg";
import { ReactComponent as CloseSmall } from "../assets/close.svg";
import { ReactComponent as ArrowLeft } from "../assets/arrowleft.svg";
import { ReactComponent as Paper } from "../assets/file-text-1.svg";
import { ReactComponent as ArrowUp } from "../assets/arrow-up-right.svg";
import { ReactComponent as Menu } from "../assets/menu.svg";
import { ReactComponent as Eye } from "../assets/eye.svg";
import { UserSmall } from "components/common/icons-primary";
import { ReactComponent as SearchBig } from "../assets/search-big.svg";
import { ReactComponent as More } from "../assets/more-vertical.svg";
import { ReactComponent as Copy } from "../assets/copy.svg";
import { ReactComponent as CrossRed } from "../assets/x-red-small.svg";
import { ReactComponent as CopyBlack } from "../assets/copy-black.svg";
import { ReactComponent as CrossGrey } from "../assets/x-grey.svg";
import { ReactComponent as CrossRemove } from "../assets/cross-red.svg";
import { ReactComponent as ArrowRight } from "../assets/Arrow-right 2.svg";
import { ReactComponent as Upload } from "../assets/upload.svg";
import { ReactComponent as File } from "../assets/kyc-icon.svg";
import { ReactComponent as Info } from "../assets/info.svg";
import { ReactComponent as Clock } from "../assets/clock-2.svg";
import { ReactComponent as Refresh } from "../assets/refresh-cw.svg";
import { ReactComponent as CheckCircle } from "../assets/check-circle.svg";
import { ReactComponent as PlusCircleSlim } from "../assets/plus-circle-slim.svg";
import { ReactComponent as UserCheck } from "../assets/user-check-m.svg";
import { ReactComponent as UserPlus } from "../assets/user-plus-m.svg";
import { ReactComponent as MessageThin } from "../assets/message-square-thin.svg";
import { ReactComponent as MessageIcon } from "assets/message-square.svg";
import { ReactComponent as LoginIcon } from "assets/log-in.svg";
import { ReactComponent as ResetIcon } from "assets/lock.svg";
import { ReactComponent as SuspendIcon } from "assets/slash.svg";

export const icons = {
  add: <PlusCircle />,
  remove: <Remove />,
  close: <Close />,
  edit: <Edit />,
  check: <Check />,
  users: <Users />,
  user: <User />,
  newRequests: <NewReqests />,
  shares: <Shares />,
  creditCard: <CreditCard />,
  plusCircle: <Plus />,
  search: <Search />,
  arrow: <Arrow />,
  closeSmall: <CloseSmall />,
  left: <ArrowLeft />,
  paper: <Paper />,
  arrowUp: <ArrowUp />,
  menu: <Menu />,
  eye: <Eye />,
  userSmall: <UserSmall />,
  searchBig: <SearchBig />,
  more: <More />,
  copy: <Copy />,
  cross: <CrossRed />,
  copyBlack: <CopyBlack />,
  crossGrey: <CrossGrey />,
  crossRed: <CrossRemove />,
  right: <ArrowRight />,
  upload: <Upload />,
  file: <File />,
  info: <Info />,
  clock: <Clock />,
  refresh: <Refresh />,
  checkCircle: <CheckCircle />,
  plusCircleSlim: <PlusCircleSlim />,
  userCheck: <UserCheck />,
  userPlus: <UserPlus />,
  messageThin: <MessageThin />,
  message: <MessageIcon />,
  login: <LoginIcon />,
  reset: <ResetIcon />,
  suspend: <SuspendIcon />,
};
