import React from "react";

import { Card, DataColumn, Row } from "./styled.js";

export const Price = ({ convert, assetSymbol }) => {
  return (
    <Card padding="20px 30px">
      <Row>
        <DataColumn
          mRight={40}
          heading="share price"
          data={`1 ${assetSymbol} = ${convert}`}
        />
      </Row>
    </Card>
  );
};
