import React, { useState, useEffect, Fragment } from "react";
import moment from "moment";

import {
  Card,
  ImageBlock,
  InfoBlock,
  DealName,
  DataCol,
  DataRow,
  DataName,
  ButtonsRow,
  RouteLink,
  ActionButton,
  Label,
} from "./styled";
import DataRoomApi from "../../../../helpers/api";
import { ReactComponent as File } from "../../../../../assets/file-dr.svg";
import { ReactComponent as Folder } from "../../../../../assets/folder-dr.svg";
import { ReactComponent as Calendar } from "../../../../../assets/calendar-dr.svg";

export const DealCard = ({
  deal: { active, assetId, assetName, image, lastUpdate, totalFiles, totalFolders },
  token,
  onUpdateDealStatus,
}) => {
  const [logo, setLogo] = useState(null);
  const [wait, setWait] = useState(false);

  useEffect(() => {
    if (image) {
      const { fileNameWithExtension, secureHash } = image;
      DataRoomApi.getAttachment(token, secureHash, fileNameWithExtension).then((b) => setLogo(b));
    }
  }, []);

  const onPublishDeal = async () => {
    setWait(true);
    const res = await DataRoomApi.toggleAssetStatus(token, assetId, false).catch(() => setWait(false));

    if (res) {
      setWait(false);
      onUpdateDealStatus();
    }
  };
  return (
    <Card>
      <ImageBlock isDraft={!active}>
        {logo ? <img src={logo} alt={assetName} /> : null}
        {!active && <Label>DRAFT</Label>}
      </ImageBlock>
      <InfoBlock>
        <DealName>{assetName}</DealName>
        <DataCol>
          <DataRow>
            <File />
            <DataName>
              Total Files: <span>{totalFiles}</span>
            </DataName>
          </DataRow>
          <DataRow>
            <Folder />
            <DataName>
              Total Folders: <span>{totalFolders}</span>
            </DataName>
          </DataRow>
          <DataRow>
            <Calendar />
            <DataName>
              Last Update: <span>{lastUpdate ? moment(lastUpdate).format("DD.MM.YYYY, hh:mm A") : "no activity"}</span>
            </DataName>
          </DataRow>
        </DataCol>
        <ButtonsRow>
          {active ? (
            <RouteLink to={`/admin/data-room/deal/${assetId}/file-manager`} fullWidth>
              OPEN DATA ROOM
            </RouteLink>
          ) : (
            <Fragment>
              <RouteLink _width="calc(50% - 7px)" to={`/admin/data-room/deal/${assetId}/file-manager`}>
                OPEN DATA ROOM
              </RouteLink>
              <ActionButton disabled={wait} onClick={onPublishDeal}>
                PUBLISH
              </ActionButton>
            </Fragment>
          )}
        </ButtonsRow>
      </InfoBlock>
    </Card>
  );
};
