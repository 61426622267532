import React, { useState, Fragment } from "react";
import { CircularProgress } from "@material-ui/core";

import Api from "../../helpers/api";

import {
  FlexRow,
  FormCol,
  Heading,
  InputCont,
  Input,
  InfoBlock,
  Submit,
  FormBottom,
  LegalInfo,
  FooterLinks,
  SignUpTxt,
  SignUpLink,
  WaitingWrapper,
} from "./styled";

import { ReactComponent as InfoIcon } from "../../assets/info.svg";
import { ReactComponent as MailIcon } from "../../assets/mail.svg";
import { isGeneral, isAlphier } from "helpers/envChecker";

export const PasswordRecoveryForm = () => {
  const [email, setEmail] = useState("");
  const [wait, setWait] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const onSubmit = async () => {
    setWait(true);

    const res = await Api.resetPassword(email).catch((e) => {
      setError(true);
      setErrorMessage(e.message);
      setWait(false);
    });

    if (res) {
      setWait(false);
      setSuccess(true);
    }
  };

  const onEmailChange = (e) => {
    if (error) {
      setErrorMessage("");
      setError(false);
    }
    setEmail(e.target.value);
  };

  return (
    <FormCol>
      <div style={{ margin: "auto 0" }}>
        <Heading>Reset Password</Heading>
        <InputCont>
          <Input error={error} placeholder="Your e-mail" value={email} onChange={(e) => onEmailChange(e)} />
          <MailIcon />
        </InputCont>
        <InfoBlock>
          You will get an E-mail with password reset link to your registration E-mail.
          <InfoIcon />
        </InfoBlock>
        <InfoBlock hide={!success}>
          {success && (
            <Fragment>
              Your request successfuly sent
              <InfoIcon />
            </Fragment>
          )}
        </InfoBlock>
        <InfoBlock hide={!error}>
          {error && (
            <Fragment>
              {errorMessage}
              <InfoIcon />
            </Fragment>
          )}
        </InfoBlock>
        <Submit onClick={onSubmit} disabled={!email.match(/\S+@\S+\.\S+/) || success}>
          Reset Password
        </Submit>
        <SignUpTxt>
          Remember your Password? <SignUpLink to="/">Sign In here</SignUpLink>{" "}
        </SignUpTxt>
      </div>
      {isGeneral || isAlphier ? null : (
        <FormBottom>
          <LegalInfo>LEGAL INFORMATION</LegalInfo>
          <FlexRow>
            <FooterLinks to="/info/privacy-policy">Privacy Policy</FooterLinks>
            <FooterLinks to="/info/terms-of-use">Terms and Conditions</FooterLinks>
          </FlexRow>
        </FormBottom>
      )}
      {wait && (
        <WaitingWrapper>
          <CircularProgress />
        </WaitingWrapper>
      )}
    </FormCol>
  );
};
