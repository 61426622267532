import React, { useState, useEffect } from "react";

import { Card, SearchBlock, Search, ThemesCol, ThemeItem, Logo, NoLogo, Col, DealName, LastMessage } from "./styled";
import { ReactComponent as SearchIcon } from "../../../../assets/search-icon.svg";
import Api from "../../../../helpers/api";

export const ThemesBar = ({ chatThemes, token, history }) => {
  const renderThemes = () => {
    return chatThemes.map((e) => <Chat history={history} el={e} key={e.name} token={token} />);
  };
  return (
    <Card>
      <SearchBlock>
        <Search placeholder="Search asset" />
        <SearchIcon />
      </SearchBlock>
      <ThemesCol>{renderThemes()}</ThemesCol>
    </Card>
  );
};

const Chat = ({ el: { name, logo, lastMessage, unread, assetId }, token, history }) => {
  const [img, setImg] = useState(null);

  useEffect(() => {
    if (!img && logo) {
      const { fileNameWithExtension, secureHash } = logo;
      Api.getAttachment(token, secureHash, fileNameWithExtension).then((b) => setImg(b));
    }
  }, []);

  const active = history.location.pathname === `/investor/chat/${assetId}`;
  return (
    <ThemeItem active={active} to={`/investor/chat/${assetId}`} unread={active ? 0 : unread}>
      {img ? <Logo src={img} alt="logo" /> : <NoLogo />}
      <Col>
        <DealName active={history.location.pathname === `/investor/chat/${assetId}`}>{name}</DealName>
        <LastMessage>{lastMessage}</LastMessage>
      </Col>
    </ThemeItem>
  );
};
