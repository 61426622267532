import styled from "styled-components";
import React, { useEffect, useState } from "react";

import NoBg from "assets/no-sponsor-bg.png";
import Api from "helpers/api";
import { UserIcon } from "components/common/icons-primary";

const Block = styled.div`
  width: 100%;
  height: ${({ heigth = 200 }) => `${heigth}px`};
  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const AvatarSponsor = styled.img`
  object-fit: cover;
  width: 92px;
  height: 92px;
  border-radius: 50%;
`;

export const Label = styled.h6`
  font-size: 10px;
  line-height: 135%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: rgba(24, 24, 24, 0.6);
  margin: 0 0 4px;
`;

export const Info = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 135%;
  color: #181818;
`;

export const Link = styled.a`
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: -0.02em;
  color: ${({ theme: { primary } }) => primary};
  text-decoration: underline;
`;

export const Avatar = ({ token, logo }) => {
  const [image, setImage] = useState(null);

  useEffect(() => {
    if (logo) {
      const { fileNameWithExtension, secureHash } = logo;
      Api.getAttachment(token, secureHash, fileNameWithExtension).then((b) => setImage(b));
    }
  }, []);

  if (!logo) {
    return <UserIcon style={{ width: 92, height: 92 }} />;
  } else if (logo && image) {
    return <AvatarSponsor src={image} alt="bg" />;
  } else return null;
};

export const Background = ({ token, logo, height }) => {
  const [image, setImage] = useState(null);

  useEffect(() => {
    if (logo) {
      const { fileNameWithExtension, secureHash } = logo;
      Api.getAttachment(token, secureHash, fileNameWithExtension).then((b) => setImage(b));
    }
  }, []);
  return (
    <Block heigth={height}>
      {!logo ? <img src={NoBg} alt="bg" /> : logo && image ? <img src={image} alt="bg" /> : null}
    </Block>
  );
};
