import styled from "styled-components";
import React from "react";
import { Link } from "react-router-dom";

import { ReactComponent as SearchIcon } from "../../../../../assets/search-icon.svg";
import { More } from "components/common/icons-primary";

import theme from "models/themes";

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  padding: 0 30px;
  max-width: 1440px;
  box-sizing: border-box;
`;

export const Header = styled.div`
  width: 100%;
  height: 60px;
  box-sizing: border-box;
  margin-bottom: 25px;
  background: #ffffff;
  border-radius: 4px;
  display: flex;
  align-items: center;
`;

export const Back = styled(Link)`
  width: 176px;
  height: 36px;
  border: 1px solid ${({ theme: { primary } }) => primary};
  box-sizing: border-box;
  border-radius: 4px;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  > svg {
    margin-right: 7px;
    path {
      stroke: ${({ theme: { primary } }) => primary};
    }
  }
  > span {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 135%;
    color: ${({ theme: { primary } }) => primary};
  }
  &:hover {
    &::before {
      display: block;
    }
  }
  &::before {
    transition: all 0.3s linear;
    content: "";
    position: absolute;
    display: none;
    bottom: -1px;
    left: -1px;
    height: 3px;
    background: ${({ theme: { primary } }) => primary};
    width: calc(100% + 2px);
    filter: brightness(0.85);
    border-radius: 0 0 4px 4px;
  }
`;

export const BorderBlock = styled.div`
  border-right: 1px solid #f2f2f2;
  height: 100%;
  display: flex;
  align-items: center;
  margin-right: 30px;
  padding: 0 30px;
`;

export const DealName = styled.h3`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 135%;
  margin: 0;
  color: #181818;
`;

const SearchInput = styled.input`
  width: 100%;
  height: 100%;
  background: #ffffff;
  border-radius: 4px 0px 4px 4px;
  outline: none;
  border: none;
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 135%;
  letter-spacing: -0.02em;
  color: #333333;
  padding: 20px 20px 20px 45px;
  box-sizing: border-box;
  border: 1px solid #efefef;
  box-sizing: border-box;
  border-radius: 4px;
  &:placeholder {
    color: rgba(24, 24, 24, 0.4);
  }
`;

const SearchBlock = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 25px;
  padding: 9px 20px;
  box-sizing: border-box;
  height: 60px;
  background: #ffffff;
  border-radius: 4px 0px 4px 4px;
  > svg {
    position: absolute;
    top: 22px;
    left: 35px;
    width: 18px;
    height: 18px;
    path {
      stroke: ${({ theme: { primary } }) => primary};
    }
  }
`;

export const FileWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  min-height: calc(100vh - 295px);
  ${({ isOpen }) => {
    if (isOpen) {
      return `
        & > div:first-child {
          width: 440px;
        }
        & > div:last-child {
          width: calc(100% - 70px);
        }
      `;
    } else {
      return `
        & > * {
          width: 100%;
        }
      `;
    }
  }}
`;

export const Table = styled.div`
  background: #ffffff;
  border: 1px solid #efefef;
  box-sizing: border-box;
  border-radius: 4px;
  width: 100%;
  overflow: hidden;
`;

export const TRow = styled.div`
  border-bottom: 1px solid #efefef;
  box-sizing: border-box;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  cursor: ${({ noHover = false, hidden }) => (noHover ? "auto" : hidden ? "not-allowed" : "pointer")};
  transition: all 0.1s linear;
  opacity: ${({ hidden }) => (hidden ? 0.5 : 1)};
  background: ${({ active, hidden, theme: { quoteBgInvestor } }) =>
    active ? quoteBgInvestor : hidden ? "#8080805c" : "#fff"};
  &:hover,
  &:active {
    background: ${({ noHover = false, hidden, theme: { quoteBgInvestor } }) =>
      noHover ? "#fff" : hidden ? "#8080805c" : quoteBgInvestor};
  }
`;

export const TCeil = styled.div`
  height: 50px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  border-right: 1px solid #efefef;
  padding: 0 ${({ padding, p = 20 }) => (padding ? padding : `${p}px`)};
  width: ${({ big, full, fixedWidth }) => {
    if (full) return "100%;";
    return big ? "calc(100% - 662px)" : fixedWidth ? fixedWidth : "120px";
  }};

  font-family: Muli;
  font-style: normal;
  font-weight: ${({ lower = false }) => (lower ? "normal" : "bold")};
  font-size: ${({ lower = false }) => (lower ? "14px" : "10px")};
  line-height: 135%;
  letter-spacing: 0.05em;
  text-transform: ${({ lower = false }) => (lower ? "none" : "uppercase")};
  color: rgba(24, 24, 24, 0.4);
  &:last-child {
    border-right: none;
  }
  ${({ responsive = "" }) => responsive};
`;

const ActionName = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 135%;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: #181818;
  display: flex;
  align-items: center;
  height: 25px;
  > svg {
    margin-right: 5px;
    path {
      stroke: ${({ theme: { primary } }) => primary};
    }
  }
`;

export const FolderName = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 135%;
  color: #181818;
  text-transform: none;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  > svg {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
  > svg:first-child {
    width: 12px;
    height: 12px;
    transform: ${({ open }) => (open ? "none" : "rotate(-90deg)")};
  }
  &:hover {
    > div {
      margin-left: 10px;
      display: flex;
    }
  }
  > span {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100% - 80px);
  }
`;

export const MoreBlock = styled.div`
  width: 18px;
  height: 18px;
  position: relative;
  display: none;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  cursor: pointer;
  &:hover {
    > div {
      display: block;
    }
  }
`;

export const Hidden = styled.div`
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 18px;
  height: 18px;
`;

export const Triangle = styled.div`
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 20px 12.5px 0 12.5px;
  border-color: #ffffff transparent transparent transparent;
  position: absolute;
  right: -3px;
  top: -11px;
  z-index: 101;
`;

export const DocTooltip = styled.div`
  position: absolute;
  padding: 8px 16px;
  background: #ffffff;
  border: 1px solid #efefef;
  box-sizing: border-box;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  width: 195px;
  right: -50px;
  bottom: 28px;
  display: flex;
  flex-direction: column;
  z-index: 100;
  &::after {
    content: "";
    display: block;
    width: 195px;
    position: absolute;
    height: 20px;
    background: transparent;
    left: -15px;
    bottom: -35px;
  }
`;

export const DocName = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 135%;
  color: #181818;
  text-transform: none;
  display: flex;
  align-items: center;
  width: calc(100% - 15px);
  height: 100%;
  > svg {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
  &:hover {
    > div {
      margin-left: 10px;
      display: flex;
    }
  }
  > span {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100% - 60px);
  }
`;

const StatusBtn = styled.div`
  width: 105px;
  height: 24px;
  background: ${({ bg }) => bg};
  border-radius: 2px;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 150%;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  display: flex;
  white-space: nowrap;
  > span {
    margin: auto;
  }
`;

const statuses = {
  SIGNED: "#33A02C",
  NOT_SIGNED: "#62A7DA",
  OPENED: theme.primary,
  NOT_OPENED: "#ABB0BF",
};

const WrapperRelative = styled.div`
  position: relative;
`;

const WrapperSticky = styled.div`
  position: sticky;
  top: ${({ top = 30 }) => top}px;
`;

export const RevievedBtn = styled.button`
  border: none;
  width: 80px;
  height: 24px;
  background: ${({ status, left = false }) =>
    status ? "#019D52" : status === false ? "#858EA2" : left ? "rgba(1, 157, 82, 0.2)" : "rgba(89, 120, 150, 0.1)"};
  border-radius: 2px;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 150%;
  text-align: center;
  text-transform: uppercase;
  color: ${({ status, left = false }) => (status ? "#fff" : status === false ? "#fff" : left ? "#96CEB3" : "#858EA1")};
  display: flex;
  white-space: nowrap;
  cursor: pointer;
  > span {
    margin: auto;
  }
  &:disabled {
    opacity: 0.5;
  }
`;

export const BtnRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const FileViewerStickyWrapper = (props) => (
  <WrapperRelative>
    <WrapperSticky {...props}>{props.children}</WrapperSticky>
  </WrapperRelative>
);

export const Status = ({ status }) => (
  <StatusBtn bg={statuses[status]}>
    <span>{status.replace("_", " ")}</span>
  </StatusBtn>
);

export const Tooltip = ({ children }) => (
  <MoreBlock>
    <More />
    <Hidden>
      <Triangle />
      <DocTooltip>{children}</DocTooltip>
    </Hidden>
  </MoreBlock>
);

export const Action = ({ name, icon, onClick }) => (
  <ActionName onClick={onClick}>
    {icon}
    {name}
  </ActionName>
);

export const Search = ({ value, onChange }) => (
  <SearchBlock>
    <SearchInput placeholder="Search files or folders" value={value} onChange={(e) => onChange(e.target.value)} />
    <SearchIcon />
  </SearchBlock>
);

export const EmptyCard = styled.div`
  background: #ffffff;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 25px;
  width: 100%;
  margin: 0 auto;
  font-weight: 600;
  font-size: 16px;
  line-height: 160%;
  color: #333333;
  text-align: center;
`;
