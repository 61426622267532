import styled from "styled-components";
import { Link } from "react-router-dom";

export const Card = styled.div`
  width: 100%;
  background: #ffffff;
  border-radius: 4px;
  padding: ${({ padding }) => padding};
  margin-bottom: 20px;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const BorderBlock = styled.div`
  border-bottom: 1px solid #f0f0f0;
  box-sizing: border-box;
  width: 100%;
  padding: 15px 25px;
  display: flex;
  align-items: center;
`;

export const Heading = styled.h5`
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: ${({ fSize }) => `${fSize}px`};
  line-height: 135%;
  letter-spacing: -0.02em;
  color: #192036;
  margin: 0;
`;

export const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: ${({ align = "center" }) => align};
  justify-content: ${({ justify }) => justify};
  padding: ${({ padding }) => padding};
  margin: ${({ margin }) => margin};
  height: ${({ height }) => height};
  box-sizing: border-box;
`;

export const NavLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  box-sizing: border-box;
  font-family: Muli;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 135%;
  color: ${({ active, theme: { primary } }) => (active ? primary : "rgba(24, 24, 24, 0.6)")};
  height: 100%;
  margin-right: 30px;
  border-bottom: 2px solid ${({ active, theme: { primary } }) => (active ? primary : "transparent")};
  &:last-child {
    margin-right: 0;
  }
`;

export const ShareRow = styled.div`
  height: 62px;
  box-sizing: border-box;
  padding: 5px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  background: ${({ own }) => (own ? "#e4e4e4" : "#fff")};
  border-radius: 4px;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const TH = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 135%;
  height: 100%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  box-sizing: border-box;
  color: rgba(24, 24, 24, 0.6);
  width: ${({ width }) => width};
  display: flex;
  align-items: center;
`;

export const TD = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: ${({ normal }) => (normal ? "normal" : 600)};
  font-size: 14px;
  line-height: 150%;
  height: 100%;
  box-sizing: border-box;
  color: ${({ owner }) => (owner ? "rgba(51, 51, 51, 0.7)" : "#333333")};
  width: ${({ width }) => width};
  display: flex;
  align-items: center;
  padding-right: 20px;
  > span {
    font-family: Muli;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    max-width: calc(100% - 40px);
  }
  &:last-child {
    padding-right: 0;
  }
  > svg {
    filter: grayscale(${({ theme: { greyFilter } }) => greyFilter});
    width: 24px;
    height: 24px;
    margin-right: 16px;
  }
`;

export const NoAvatar = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 16px;
  background: transparent;
`;

export const Avatar = styled.img`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  margin-right: 16px;
  object-fit: cover;
`;

export const RemoveBtn = styled.button`
  width: 130px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #efefef;
  box-sizing: border-box;
  border-radius: 4px;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Muli;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 135%;
  display: flex;
  align-items: center;
  text-align: center;
  color: rgba(24, 24, 24, 0.4);
  cursor: pointer;
`;

export const Separator = styled.div`
  height: 20px;
`;
