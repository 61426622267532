export function getUniqName(arr, newItemName) {
  // items should be an objects with field 'name'

  if (arr.find((e) => e.name == newItemName)) {
    let j = 0;

    arr.forEach((e) => {
      if (e.name.includes(newItemName)) {
        const curentIndex = e.name.match(/\d+/);
        if (curentIndex && curentIndex[0] && parseInt(curentIndex[0]) > j) {
          j = parseInt(curentIndex[0]);
        }
      }
    });
    j++;
    return `${newItemName} (${j})`;
  } else {
    return newItemName;
  }
}
