import React from "react";

import {
  ConfBackground,
  ModalWrap,
  ConfHeader,
  HeadingModal,
  ConfBody,
  ConfFooter
} from "./styled";
import { Btn } from "../../../admin-layout/buttons";
import { ReactComponent as CloseIcon } from "../../../../assets/x-circle.svg";

export const RemoveConfirmationModal = ({
  onBackdropClick,
  removed,
  count,
  onAgree
}) => {
  return (
    <ConfBackground
      onClick={e => {
        e.stopPropagation();
        onBackdropClick();
      }}
    >
      <ModalWrap
        width="440px"
        onClick={e => {
          e.stopPropagation();
        }}
      >
        <ConfHeader>
          <HeadingModal small>Remove Category</HeadingModal>
          <CloseIcon onClick={onBackdropClick} />
        </ConfHeader>
        <ConfBody>
          <h3>{`Are you sure to remove ${
            removed.length > 1 ? "categories" : "category"
          } «${removed.join("», «")}»?`}</h3>
          <span>
            You have{" "}
            <b>{count > 1 ? `${count} question` : `${count} questions`}</b> in
            this {removed.length > 1 ? "categories" : "category"}.
            {count ? (
              <b> All questions will be movet to the category «Other».</b>
            ) : null}
          </span>
        </ConfBody>
        <ConfFooter>
          <Btn width={94} transparent onClick={onBackdropClick}>
            cancel
          </Btn>
          <Btn
            width={180}
            red
            onClick={() => {
              onAgree();
              onBackdropClick();
            }}
          >
            REMOVE CATEGORY
          </Btn>
        </ConfFooter>
      </ModalWrap>
    </ConfBackground>
  );
};
