import React, { Fragment, useState, useEffect, useRef } from "react";
import { connect } from "react-redux";

import { Btn } from "../../../buttons";

import { Header, TextH3, TextSub, BtnContainer } from "./styled";
import { Stepper, Slots } from "./Stepper";

import { Information } from "./Information";
import { Parameters } from "./Parameters";
import { FinancialsAndRules } from "./FinancialsAndRules";

import { dealObjectFactory } from "../../../../../models/dealModel";
import Api from "../../../../../helpers/api";
import KycApi from "../../KycFormsList/api";
import { ErrorMessagePortal as ErrorMessage } from "../../../errorMessage";
import { AssetPreview } from "../assetPreview";
import { icons } from "../../../../../models/icons";
import { EmptyNewDeal, EmptyExistedDeal } from "../helpers";

export const DealForm = (props) => {
  const [step, setStep] = useState(0);
  const [saveWait, setSaveWait] = useState(false);
  const [linearId, setLinear] = useState("");
  const [error, setError] = useState(null);
  const [groups, setGroups] = useState([]);
  const [users, setUsers] = useState([]);
  const idRef = useRef(null);
  const finishRef = useRef(null);
  const valuesRef = useRef(null);
  const [preview, openPreview] = useState(false);

  const onChangePreview = () => {
    openPreview(!preview);
  };

  const onBackToAssents = () => {
    props.history.goBack();
  };

  const onChangeStep = (direction) => () => {
    window.scrollTo(0, 0);
    setStep(step + direction);
  };

  const [values, _setValues] = React.useState(
    dealObjectFactory(
      props.acceptedCurrency[0] || "",
      [],
      props.accesses[0] || "",
      props.match.params.dealType,
      props.categories[0] || "",
      props.investorWorkflowTypes[0] || "",
      props.financialDealTypes
    )
  );
  const [kycForms, setKycForms] = useState([]);
  const [kycRoles, setKycRoles] = useState([]);

  const jsonValueRef = useRef(JSON.stringify(values));
  const setValues = (newValues) => {
    const _newValueJson = JSON.stringify(newValues);
    if (_newValueJson !== jsonValueRef.current) {
      _setValues(newValues);
    }
    jsonValueRef.current = _newValueJson;
  };

  useEffect(() => {
    valuesRef.current = values;
    console.log(values);
  }, [values]);

  const changeValuesSimpleType = (...keys) => (e) => {
    const value = e && e.target ? e.target.value : e;
    const _values = { ...values };

    try {
      let link = _values;
      for (let i = 0; i < keys.length; i++) {
        if (i === keys.length - 1) {
          link[keys[i]] = value;
        } else {
          link = link[keys[i]];
        }
      }
    } catch (error) {
      //for lock up time only!!!
      _values.visibilityProperty.lockUpPeriod = {};
      let link = _values;
      for (let i = 0; i < keys.length; i++) {
        if (i === keys.length - 1) {
          link[keys[i]] = value;
        } else {
          link = link[keys[i]];
        }
      }
    }

    setValues(_values);
  };

  const saveAs = (isActive) => async () => {
    setSaveWait(true);
    let res = null,
      val = {
        ...values,
        visibilityProperty: {
          ...values.visibilityProperty,
          isActive,
        },
      };

    //refactoring crash
    if (val.visibilityProperty.lockUpPeriod && !Object.keys(val.visibilityProperty.lockUpPeriod).length)
      val.visibilityProperty.lockUpPeriod = null;

    //format for existing deal
    if (props.match.params.dealType === "EXISTED_DEAL") {
      if (!val.baseCurrency) {
        const baseCurrency = val.assetFinancialProperty.baseCurrency;
        val.baseCurrency = baseCurrency;
      }
      val.assetFinancialProperty = null;
    }

    if (val.linearId)
      res = await Api.updateAsset(props.token, val).catch((e) => {
        setSaveWait(false);
        setError(e);
      });
    else
      res = await Api.createAsset(props.token, val).catch((e) => {
        setSaveWait(false);
        setError(e);
      });

    if (res) {
      setLinear(res.assetId);
      finishRef.current = true;
    }

    setSaveWait(false);
  };

  const onChangeRules = (data) => {
    const _values = { ...values };
    _values.visibilityProperty.activeRulesJson = JSON.stringify(data);
    Object.keys(data).forEach((field) => {
      if (!data[field]) {
        _values.visibilityProperty[field] = field === "countryRestriction" ? { listType: "ALL" } : null;
      }
    });
    setValues(_values);
  };

  const onChangeKyc = (data) => {
    const _values = { ...values };
    _values.assetKycTemplates = { ...data };
    setValues(_values);
  };

  //redirect after submit
  useEffect(() => {
    if (finishRef.current && linearId) {
      props.history.push(`/admin/deals/create/data-room/${linearId}/${props.match.params.dealType}`);
    }
  }, [finishRef, linearId]);

  useEffect(() => {
    const saveUnfinished = async () => {
      if (idRef.current) {
        const _values = { ...valuesRef.current };
        delete _values.unfinishedId;
        delete _values.assetFinancialProperty.timeOfStart;
        delete _values.assetFinancialProperty.timeOfEnd;
        const isChanged =
          JSON.stringify(_values) !==
          JSON.stringify(_values.assetProperties.dealsType === "EXISTED_DEAL" ? EmptyExistedDeal : EmptyNewDeal);
        if (!finishRef.current && isChanged) {
          for (
            let i = 0;
            valuesRef.current.assetProperties.images && i < valuesRef.current.assetProperties.images.length;
            i++
          ) {
            if (
              !valuesRef.current.assetProperties.images[i].file ||
              typeof valuesRef.current.assetProperties.images[i].file !== "object" ||
              typeof valuesRef.current.assetProperties.images[i].file.name !== "string"
            ) {
              continue;
            }

            const attachment = await Api.uploadAttachment(
              props.token,
              valuesRef.current.assetProperties.images[i].file
            );

            if (attachment) {
              valuesRef.current.assetProperties.images[i] = {
                ...attachment,
              };
            }
          }
          Api.updateUnfinished(props.token, valuesRef.current, idRef.current);
        }
      }
    };
    Api.getUserGroups(props.token).then((gs) => setGroups(gs));
    Api.getUsers(props.token, "INVESTOR", 1, 1000, "").then((res) => setUsers(res.content));
    KycApi.getForms(props.token).then((forms) => setKycForms(forms));
    Api.getKycRoles(props.token).then((roles) => setKycRoles(roles));

    Api.saveDealAsUnfinished(props.token, values).then((res) => {
      idRef.current = res.id;
      const _values = { ...values };
      _values.unfinishedId = res.id;
      _setValues(_values);
    });

    const interval = setInterval(saveUnfinished, 600000);

    return () => {
      saveUnfinished();
      clearInterval(interval);
    };
  }, []);

  const dealType = props.match.params.dealType;

  const onDraft = () => props.history.push(`/admin/deals/unfinished_deal`);

  return (
    <Fragment>
      <Header>
        <Btn transparent onClick={onBackToAssents} width={150}>
          {icons.left} back to option
        </Btn>
        <TextH3 marginSize="0 0 0 25px">Add New Asset</TextH3>
        {(dealType === "NEW_DEAL" && step === 2) || (dealType === "EXISTED_DEAL" && step === 1) ? (
          <Btn width={180} margin="0 0 0 auto" onClick={onChangePreview}>
            {icons.eye} ASSET PREVIEW
          </Btn>
        ) : null}
      </Header>
      <Header height={100} padding="20px 25px">
        <Stepper dealType={dealType} step={step} />
      </Header>
      <Slots
        h={preview ? "0px" : "auto"}
        dealType={dealType}
        step={step}
        components={
          dealType === "NEW_DEAL"
            ? [
                <Information
                  onError={setError}
                  token={props.token}
                  onChange={changeValuesSimpleType}
                  defaultValues={values}
                  categories={props.categories}
                  sponsors={props.sponsors}
                  acceptedCurrency={props.acceptedCurrency}
                  dealType={dealType}
                  countriesOption={props.countriesOption}
                  financialTypes={props.financialDealTypes}
                  roleName={props.roleName}
                  username={props.username}
                  fullName={props.fullName}
                />,
                <Parameters
                  token={props.token}
                  onChange={changeValuesSimpleType}
                  defaultValues={values}
                  acceptedCurrency={props.acceptedCurrency}
                  onChangeDynamic={(e) =>
                    changeValuesSimpleType("assetProperties", "dynamicCapitalStructureJson")(JSON.stringify(e))
                  }
                  json={values.assetProperties.dynamicCapitalStructureJson}
                  baseCurrency={values.assetFinancialProperty.baseCurrency}
                />,
                <FinancialsAndRules
                  token={props.token}
                  onChange={changeValuesSimpleType}
                  onChangeRound={changeValuesSimpleType("roundsInfo")}
                  defaultValues={values}
                  acceptedCurrency={props.acceptedCurrency}
                  ruleOptions={props.ruleOptions}
                  countriesOption={props.countriesOption}
                  accesses={props.accesses}
                  dealTypes={props.dealTypes}
                  investorWorkflowTypes={props.investorWorkflowTypes}
                  categories={props.categories}
                  lockUpTime={props.lockUpTime}
                  onChangeDynamicJson={(data) => {
                    changeValuesSimpleType("assetProperties", "dynamicFinancialJson")(JSON.stringify(data));
                  }}
                  onChangeGroups={changeValuesSimpleType("visibilityProperty", "groups")}
                  onChangeUsers={changeValuesSimpleType("visibilityProperty", "users")}
                  groups={groups}
                  onChangeRules={onChangeRules}
                  onChangeBank={changeValuesSimpleType("assetFinancialProperty", "account")}
                  users={users}
                  dealType={dealType}
                  kycForms={kycForms}
                  kycRoles={kycRoles}
                  onChangeKyc={onChangeKyc}
                />,
              ]
            : [
                <Information
                  onError={setError}
                  token={props.token}
                  onChange={changeValuesSimpleType}
                  defaultValues={values}
                  categories={props.categories}
                  sponsors={props.sponsors}
                  acceptedCurrency={props.acceptedCurrency}
                  dealType={dealType}
                  countriesOption={props.countriesOption}
                  financialTypes={props.financialDealTypes}
                  roleName={props.roleName}
                  username={props.username}
                  fullName={props.fullName}
                />,
                <FinancialsAndRules
                  token={props.token}
                  onChange={changeValuesSimpleType}
                  onChangeRound={changeValuesSimpleType("roundsInfo")}
                  defaultValues={values}
                  acceptedCurrency={props.acceptedCurrency}
                  ruleOptions={props.ruleOptions}
                  countriesOption={props.countriesOption}
                  accesses={props.accesses}
                  dealTypes={props.dealTypes}
                  investorWorkflowTypes={props.investorWorkflowTypes}
                  categories={props.categories}
                  lockUpTime={props.lockUpTime}
                  onChangeDynamicJson={(data) => {
                    changeValuesSimpleType("assetProperties", "dynamicFinancialJson")(JSON.stringify(data));
                  }}
                  onChangeGroups={changeValuesSimpleType("visibilityProperty", "groups")}
                  onChangeUsers={changeValuesSimpleType("visibilityProperty", "users")}
                  groups={groups}
                  onChangeRules={onChangeRules}
                  users={users}
                  dealType={dealType}
                  kycForms={kycForms}
                  kycRoles={kycRoles}
                  onChangeKyc={onChangeKyc}
                />,
              ]
        }
      />
      <Header isFooter between>
        <Btn width={180} white onClick={onChangeStep(-1)} disabled={step === 0}>
          previous step
        </Btn>
        <div>
          <TextSub fontWeight="bold" color="#181818">
            {step + 1}
          </TextSub>{" "}
          <TextSub>/ {dealType === "NEW_DEAL" ? 4 : 3}</TextSub>
        </div>
        {step !== (dealType === "NEW_DEAL" ? 2 : 1) ? (
          <Btn width={180} onClick={onChangeStep(+1)}>
            next step
          </Btn>
        ) : (
          <BtnContainer>
            <Btn width={180} onClick={onDraft} disabled={saveWait} white>
              save to draft
            </Btn>
            <Btn width={180} onClick={saveAs(true)} disabled={saveWait}>
              Save & Publish
            </Btn>
          </BtnContainer>
        )}
      </Header>

      {error ? <ErrorMessage er={error} onClose={() => setError(null)} /> : null}
      {preview ? (
        <AssetPreview
          onClose={onChangePreview}
          values={values}
          token={props.token}
          assetId={""}
          _sponsors={props.sponsorsFull}
        />
      ) : null}
    </Fragment>
  );
};

export default connect(
  ({
    userReducer: {
      token,
      userInfo: { roleName, username, fullName },
    },
    dataReducer: { assets = [] },
    asyncDataReducer: {
      acceptedCurrency,
      sponsors,
      accesses,
      dealTypes,
      categories,
      investorWorkflowTypes,
      ruleOptions,
      countriesOption,
      lockUpTime,
      sponsorsFull,
      financialDealTypes,
    },
  }) => ({
    token,
    assets,
    roleName,
    username,
    acceptedCurrency,
    sponsors,
    accesses,
    dealTypes,
    categories,
    investorWorkflowTypes,
    ruleOptions,
    countriesOption,
    lockUpTime,
    sponsorsFull,
    financialDealTypes,
    fullName,
  }),
  {}
)(DealForm);
