import styled from "styled-components";

export const Wrapper = styled.section`
  display: flex;
  box-sizing: border-box;
  padding: 65px 0;
  width: 100vw;
  min-height: 100vh;
  background: ${({ theme: { mainBgAdmin } }) => mainBgAdmin};
`;
