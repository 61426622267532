import React, { useState } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Redirect } from "react-router-dom";

import Api from "../../../../helpers/api";

import { Wrapper, Col, Card, Header, Row, Heading, Text, Line } from "../Investment/styled.js";
import { Label, InfoBlock, TextInfo, Link, Status, MoreInfo } from "./styled";
import { Button } from "../../../investor-layout/pages/Offerings/styled";
import { Loader } from "../../styled/Loader";
import { TextFieldComponent } from "../../../admin-layout/forms";

import { Raised } from "../Investment/Raised";
import { Price } from "../Investment/Price";
import { CountDown } from "./CountDown";
import { numberWithCommas } from "helpers";

export const OrderDetails = ({ token, history, paymentData, paymentResponseData }) => {
  const [wait, setWait] = useState(false);

  if (paymentResponseData && paymentData) {
    const {
      txDate,
      tx,
      ["Swift/BIC"]: bic,
      IBAN,
      ["Company account number"]: accountNumber,
      AccountHilderName,
      BankName,
      ["Amount to pay"]: amount,
      TokenQuantity,
      TokenIdentifier,
      buyerAccountNumber,
      AmountForIssuerRequest,
    } = paymentResponseData;
    const { price, assetName, assetId, raised, toBeRaised, baseCurrency } = paymentData;

    const makePayment = async () => {
      setWait(true);
      const res = await Api.makeTransaction(
        token,
        accountNumber,
        buyerAccountNumber,
        tx,
        AmountForIssuerRequest
      ).catch(() => setWait(false));
      if (res) history.push("/investor/transactions/all/all");
    };

    const goToChat = (id) => () => {
      const link = document.createElement("a");
      link.href = `${window.location.origin}/investor/chat/${id}`;
      link.target = "_blank";
      link.click();
    };

    return (
      <Wrapper>
        {wait && <Loader zIndex={100} />}
        <Header>
          <Heading margin="0 0 0 25px">Order #{tx}</Heading>
        </Header>
        <Col width="calc(100% - 470px)">
          <Card>
            <Row margin="0 0 14px 0">
              <Heading>Waiting for Payment Approval</Heading>
              <Text fSize={18} color="rgba(24, 24, 24, 0.4)" margin="0 0 0 auto">
                2 / 3
              </Text>
            </Row>
            <Row margin="0 0 25px 0">
              <Text lineH="160%" fSize={14} color="#7C7C7C">
                When the platform admin will approve your transaction your{" "}
                <span>
                  {numberWithCommas(TokenQuantity)} {TokenIdentifier}
                </span>{" "}
                shares will appear in your account.
              </Text>
            </Row>
            <Label>Your Transaction</Label>
            <InfoBlock>
              <Col width="230px">
                <TextInfo>
                  Shares Purchase for
                  <br />
                  <Link to={`/investor/offerings/companies/${assetId}`}>{assetName}</Link>
                </TextInfo>
              </Col>
              <Col width="fit-content">
                <TextInfo grey>{amount}</TextInfo>
                <TextInfo>
                  {numberWithCommas(TokenQuantity)} {TokenIdentifier}
                </TextInfo>
              </Col>
              <Status />
              <Col width="fit-content">
                <TextInfo>{moment(txDate).format("dddd, DD/MM/YYYY")}</TextInfo>
                <TextInfo grey>{moment(txDate).format("hh:mm:ss A")}</TextInfo>
              </Col>
              <MoreInfo />
            </InfoBlock>
          </Card>
          <Card>
            <Row margin="0 0 14px 0">
              <Heading>Bank Transfer to Invest</Heading>
            </Row>
            <Row margin="0 0 20px 0">
              <Text lineH="160%" fSize={14} color="#7C7C7C">
                Please use the below Bank information details to transfer the money and receive your shares.
              </Text>
            </Row>
            <Row margin="0 0 20px 0" justify="space-between" padding="0">
              <TextFieldComponent
                width="178px"
                label="Amount to tranfser"
                height={42}
                labelFontSize={10}
                fontSize={12}
                isInvestor
                disabled
                defaultValue={numberWithCommas(amount)}
              />
              <TextFieldComponent
                width="calc(100% - 202px)"
                label="Account Holder Name"
                height={42}
                labelFontSize={10}
                fontSize={12}
                isInvestor
                disabled
                defaultValue={AccountHilderName}
              />
            </Row>
            <Row margin=" 0" justify="space-between" padding="0">
              <TextFieldComponent
                width="178px"
                label="BIC (SWIFT) CODE"
                height={42}
                labelFontSize={10}
                fontSize={12}
                isInvestor
                disabled
                defaultValue={bic}
              />
              <TextFieldComponent
                width="255px"
                label="IBAN"
                height={42}
                labelFontSize={10}
                fontSize={12}
                isInvestor
                disabled
                defaultValue={IBAN}
              />
              <TextFieldComponent
                width="367px"
                label="Beneficiary’s bank"
                height={42}
                labelFontSize={10}
                fontSize={12}
                isInvestor
                disabled
                defaultValue={BankName}
              />
            </Row>
            <Line />
            <Row>
              <Text fSize={18}>
                Total shares: <span>{numberWithCommas(TokenQuantity) + " " + TokenIdentifier}</span>
              </Text>
            </Row>
          </Card>
          <Card noPadding height={62}>
            <Row justify="space-between">
              <Button
                transparent
                height={42}
                width={287}
                bold
                size={14}
                margin="10px 0 10px 30px"
                onClick={goToChat(assetId)}
              >
                CHAT WITH ADMIN
              </Button>
              {/* <Button
                height={42}
                width={287}
                bold
                size={14}
                margin="10px 30px 10px 0"
                onClick={makePayment}
              >
                PAY NOW
              </Button> */}
            </Row>
          </Card>
        </Col>
        <Col>
          <CountDown timeOfEnd={moment(txDate).add(3, "day")} />
          <Raised transition={0} noTransition raised={raised} toBeRaised={toBeRaised} baseCurrency={baseCurrency} />
          <Price convert={price} assetSymbol={TokenIdentifier} />
        </Col>
      </Wrapper>
    );
  } else {
    return <Redirect to="/investor/offerings/all" />;
  }
};

export default connect(
  (
    { userReducer: { token, paymentInfo, paymentResponseData, fullName } },
    {
      match: {
        params: { id },
      },
    }
  ) => {
    return {
      token,
      id,
      paymentData: paymentInfo.find((e) => e.assetId === id),
      paymentResponseData: paymentResponseData[0],
      fullName,
    };
  },
  {}
)(OrderDetails);
