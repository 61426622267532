import MainApi from "helpers/api";

export default class Api {
  static url = MainApi.url;

  static origin = MainApi.origin;

  static getForm(token) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/questionnaire/form`, {
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json"
        }
      });
      if (result.status === 200) {
        try {
          res(await result.json());
        } catch (error) {
          res(null);
        }
      } else rej(await result.json());
    });
  }

  static postAnswer(token, answers) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/questionnaire/answer`, {
        method: "post",
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ answerJson: JSON.stringify(answers) })
      });
      if (result.status === 200) res(await result.json());
      else rej(await result.json());
    });
  }

  static getAnswers(token) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/questionnaire/answer`, {
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json"
        }
      });
      if (result.status === 200) {
        try {
          res(await result.json());
        } catch (error) {
          res(null);
        }
      } else rej(await result.json());
    });
  }

  static changeUserAnswer(token, answers) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/questionnaire/answer`, {
        method: "put",
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ answerJson: JSON.stringify(answers) })
      });
      if (result.status === 200) res(await result.json());
      else rej(await result.json());
    });
  }

  static uploadAttachment = MainApi.uploadAttachment;
}
