import React, { useState, useEffect, Fragment } from "react";
import moment from "moment";

import Api from "../../../../helpers/api";
import {
  Card,
  ImageBlock,
  Label,
  InfoBlock,
  DealName,
  Count,
  CardHeader,
  DataCol,
  DataName,
  DataRow,
  ButtonsRow,
  RouteLink,
  ActionButton,
} from "./styled";
import { ReactComponent as Help } from "../../../../assets/help-circle.svg";
import { ReactComponent as Folder } from "../../../../assets/folder-dr.svg";
import { ReactComponent as Calendar } from "../../../../assets/calendar-dr.svg";

export const DealCard = ({
  deal: { active, assetId, assetName, image, lastUpdate, totalQuestions, waitingQuestions },
  token,
  onUpdateDealStatus,
}) => {
  const [logo, setLogo] = useState(null);
  const [wait, setWait] = useState(false);

  const onPublishDeal = async () => {
    setWait(true);

    const res = await Api.toggleAssetStatus(token, assetId, false);
    if (res) onUpdateDealStatus(token);

    setWait(false);
  };

  useEffect(() => {
    if (image) {
      const { fileNameWithExtension, secureHash } = image;
      Api.getAttachment(token, secureHash, fileNameWithExtension).then((b) => setLogo(b));
    }
  }, []);

  return (
    <Card>
      <ImageBlock isDraft={!active}>
        {/* null можно, bool нет */}
        {logo && <img src={logo} alt={assetName} />}
        {!active ? <Label>DRAFT</Label> : null}
      </ImageBlock>
      <InfoBlock>
        <CardHeader>
          <DealName>{assetName}</DealName>
          <Count count={waitingQuestions}>{waitingQuestions}</Count>
        </CardHeader>
        <DataCol>
          <DataRow>
            <Help />
            <DataName>
              Questions: <span>{totalQuestions}</span>
            </DataName>
          </DataRow>
          <DataRow>
            <Folder />
            <DataName>
              Waiting for Answers: <span>{waitingQuestions}</span>
            </DataName>
          </DataRow>
          <DataRow>
            <Calendar />
            <DataName>
              Last Update: <span>{lastUpdate ? moment(lastUpdate).format("DD.MM.YYYY, hh:mm A") : "no activity"}</span>
            </DataName>
          </DataRow>
        </DataCol>
        <ButtonsRow>
          {active ? (
            <RouteLink to={`/admin/question-and-answer/asset/${assetId}`} fullWidth>
              OPEN ASSET Q&A
            </RouteLink>
          ) : (
            <Fragment>
              <RouteLink _width="calc(50% - 7px)" to={`/admin/question-and-answer/asset/${assetId}`}>
                OPEN ASSET Q&A
              </RouteLink>
              <ActionButton disabled={wait} onClick={onPublishDeal}>
                PUBLISH
              </ActionButton>
            </Fragment>
          )}
        </ButtonsRow>
      </InfoBlock>
    </Card>
  );
};
