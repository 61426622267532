import React, { useState, useEffect, Fragment } from "react";

import { Card, Row, Heading, Text, Line } from "./styled.js";
import { Button } from "../../../investor-layout/pages/Offerings/styled";
import { ReactComponent as InfoIcon } from "../../../../assets/alert-circle.svg";

import { DigitalSignature } from "./DigitalSignature";
import { ErrorMessage } from "../../../admin-layout/errorMessage";
import { Loader } from "../../styled/Loader";
import { ChooseSignType } from "../Offer/ChooseSignType";
import { DocuSignFrame } from "./DocuSignFrame";

import Api from "../../../../helpers/api";
import { queryToObject } from "../../../../helpers/";

export const SignContract = ({
  asset: {
    assetProperties: { assetSymbol },
    intentionToBuy,
  },
  fullName,
  token,
  assetId,
  onNextStep,
  signToolOptions,
}) => {
  const [signTypeOpen, setSignTypeOpen] = useState(false);
  //Data for children component (for fix markup)
  const [preSignDocument, setPreSignDocument] = useState(null);
  const [useSignatureImage, setUseSignatureImage] = useState(false);
  const [digitalSignature, setDigitalSignature] = useState(null);

  const [error, setError] = useState(null);
  const [load, setLoad] = useState(false);

  const [ready, setReady] = useState(false);
  const [frameUrl, setFrameUrl] = useState("");
  const [frameOpen, setFrameOpen] = useState(false);

  const onError = (e) => {
    load && setLoad(false);
    setError(e);
  };

  const onChangeSign = () => {
    getInitial();
  };

  const onChangeSignType = () => {
    setSignTypeOpen(!signTypeOpen);
  };

  const onDigitalSign = () => {
    onChangeSignType();
    onChangeSign();
  };

  const onDocuSign = async () => {
    setLoad(true);
    onChangeSignType();
    const link = await Api.getDocusignLink(token, assetId, intentionToBuy).catch(onError);
    if (link) {
      setFrameUrl(link);
      setFrameOpen(true);
    }
    setLoad(false);
  };

  const getInitial = async () => {
    setLoad(true);
    const file = await Api.getPreSignDocument(token, assetId).catch(onError);

    const signature = await Api.getDigitalSignature(token).catch(onError);

    if (signature) {
      setUseSignatureImage(true);
      setDigitalSignature(signature);
    }

    if (file) {
      setPreSignDocument(file);
      setLoad(false);
      setReady(true);
    }
  };

  useEffect(() => {
    const reciveMessage = async (e) => {
      if (e.data.href) {
        setFrameOpen(false);
        setFrameUrl("");
        const { envelopeId, token: tokenToBuy, event } = queryToObject(e.data.href);
        if (event === "signing_complete") {
          if (envelopeId && token) {
            setLoad(true);
            const res = await Api.approveDocuSign(token, envelopeId, tokenToBuy).catch(onError);
            if (res) {
              setLoad(false);
              onNextStep();
            }
          }
        }
      }
    };
    window.addEventListener("message", reciveMessage, false);
    return () => {
      window.removeEventListener("message", reciveMessage);
    };
  }, []);

  return (
    <Fragment>
      {load ? <Loader zIndex={2} /> : null}
      {ready ? (
        <DigitalSignature
          ready={ready}
          token={token}
          assetId={assetId}
          onNextStep={onNextStep}
          _useSignatureImage={useSignatureImage}
          _digitalSignature={digitalSignature}
          _preSignDocument={preSignDocument}
        />
      ) : (
        <Fragment>
          <Card>
            <Row margin="0 0 14px 0">
              <Heading>Sign Contract</Heading>
              <Text fSize={18} color="rgba(24, 24, 24, 0.4)" margin="0 0 0 auto">
                1 / 3
              </Text>
            </Row>
            <Row margin="0 0 15px 0">
              <Text lineH="160%" fSize={14} color="#7C7C7C">
                <span>{fullName}, your contract is ready!</span> You are eligible to participate in this offering,
                please access and sign your contract and go to next step to reserve{" "}
                <span>
                  {intentionToBuy} {assetSymbol}
                </span>{" "}
                shares.
              </Text>
            </Row>
            <Row padding="0">
              <InfoIcon />
              <Text margin="0 0 0 13px" fSize={12} color="#7C7C7C">
                Please sign the contract and make the payment within next 72 hours
              </Text>
            </Row>
          </Card>
          <Card noPadding height={62}>
            <Button height={42} width={287} bold size={14} margin="10px 0 10px 30px" onClick={onChangeSignType}>
              READ AND SIGN CONTRACT
            </Button>
          </Card>
        </Fragment>
      )}
      <ChooseSignType
        isOpen={signTypeOpen}
        onBackDropClick={onChangeSignType}
        onDigitalSign={onDigitalSign}
        onDocuSign={onDocuSign}
        signToolOptions={signToolOptions}
      />
      {error && <ErrorMessage er={error} onClose={() => setError(null)} />}
      {frameOpen ? (
        <DocuSignFrame
          url={frameUrl}
          onBackDropClick={() => {
            setFrameOpen(false);
            setFrameUrl("");
          }}
        />
      ) : null}
    </Fragment>
  );
};
