import React, { useState, useEffect } from "react";
import "react-circular-progressbar/dist/styles.css";
import { PieChart } from "react-minimal-pie-chart";

import {
  AssetRowCard,
  ImgCont,
  InfoBlock,
  ChartBlock,
  Labels,
  BorderBlock,
  LinkBlock,
  SponsorBlock,
  AssetName,
  SmallDesc,
  Row,
  Col,
  InfoRow,
  Button,
  Sponsor,
  PieChartCircle,
  ChartBg,
  TradeChartData,
} from "./styled";
import Api from "../../../../helpers/api";
import theme from "models/themes";

export const TradeRow = ({
  history,
  item: {
    assetId,
    assetProperties: { images, category, assetLocation, assetName, smallDescription, dynamicFinancialJson },
    assetManagerDetails,
    total,
  },
  totalShares,
  subscribers,
  token,
}) => {
  const [image, setImage] = useState(null);

  const data = JSON.parse(dynamicFinancialJson || "[]");

  useEffect(() => {
    if (!image && images && images[0]) {
      const { secureHash, fileNameWithExtension } = images[0];
      Api.getAttachment(token, secureHash, fileNameWithExtension).then((blob) => setImage(blob));
    }
  });

  const segments =
    (360 * total) / totalShares >= 5
      ? [
          { value: totalShares, color: "#949DB9" },
          { value: total, color: "#6876A0" },
        ]
      : [];

  return (
    <AssetRowCard>
      <ImgCont>
        {image && <img src={image} alt="asset" />}
        <Labels category={category} location={assetLocation} violet />
      </ImgCont>
      <InfoBlock>
        <BorderBlock height={114} padding="15px 25px">
          <AssetName>{assetName}</AssetName>
          <Row>
            <Col>
              {data[0] ? <InfoRow label={data[0].name} val={data[0].value} /> : null}
              {data[1] ? <InfoRow label={data[1].name} val={data[1].value} /> : null}
            </Col>
            <Col>
              {data[2] ? <InfoRow label={data[2].name} val={data[2].value} /> : null}
              {data[3] ? <InfoRow label={data[3].name} val={data[3].value} /> : null}
            </Col>
          </Row>
        </BorderBlock>
        <BorderBlock height={81} padding="12px 25px">
          <SmallDesc dangerouslySetInnerHTML={{ __html: smallDescription }} />
        </BorderBlock>
        <div style={{ display: "flex", height: 65 }}>
          <SponsorBlock>
            {assetManagerDetails ? <Sponsor sponsor={assetManagerDetails} token={token} /> : null}
          </SponsorBlock>
          <LinkBlock>
            <Button
              violet
              width={190}
              height={32}
              size={12}
              tTransform="uppercase"
              fWeight="bold"
              onClick={() => history.push(`/investor/offerings/users/${assetId}/buy-shares`)}
            >
              More
            </Button>
          </LinkBlock>
        </div>
      </InfoBlock>
      <ChartBlock>
        <ChartBg segments={segments}>
          <PieChartCircle />
          <PieChart
            viewBoxSize={[100, 100]}
            paddingAngle={!segments.length ? 2 : 0}
            radius={50}
            animate={true}
            data={[
              {
                title: "Total shares",
                value: totalShares,
                color: theme.secondaryLight,
              },
              {
                title: "Available shares",
                value: total,
                color: theme.secondary,
              },
            ]}
          />
        </ChartBg>
        <TradeChartData subscribers={subscribers} total={totalShares} available={total} />
      </ChartBlock>
    </AssetRowCard>
  );
};
