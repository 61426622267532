import React, { Fragment, useState, useEffect } from "react";

import {
  ModalBackground,
  ModalWrapper,
  ModalHeader,
  Heading,
  InputsWrapper,
  InputRow,
} from "./styled";
import { Btn } from "../../buttons";

import { TextFieldComponent } from "../../forms";
import { ReactComponent as CloseIcon } from "../../../../assets/x-circle.svg";

export const AddUserModal = ({
  onBackdropClick,
  onAddUser,
  onEditUser,
  users,
  _user,
  isEdit = false,
}) => {
  const [user, setUser] = useState(
    _user ? { ..._user } : { firstName: "", lastName: "", email: "" }
  );
  const originUser = _user ? { ..._user } : null;

  const onChange = (e) => {
    const { value, name } = e.target;
    setUser({ ...user, [name]: value });
  };

  const onSubmit = () => {
    isEdit ? onEditUser(user) : onAddUser(user);
    onBackdropClick();
  };

  const { firstName, lastName, email } = user;

  const isDisabled = () => {
    if (isEdit) {
      return (
        !firstName ||
        !lastName ||
        !email.match(/\S+@\S+\.\S+/) ||
        JSON.stringify(originUser) === JSON.stringify(user) ||
        (users.find((el) => el.email === email) && email !== originUser.email)
      );
    } else {
      return (
        !firstName ||
        !lastName ||
        !email.match(/\S+@\S+\.\S+/) ||
        users.findIndex((el) => el.email === email) !== -1
      );
    }
  };

  return (
    <ModalBackground onClick={onBackdropClick}>
      <ModalWrapper onClick={(e) => e.stopPropagation()}>
        <ModalHeader>
          <Heading>{isEdit ? "Edit" : "Add"} User</Heading>
          <CloseIcon onClick={onBackdropClick} />
        </ModalHeader>
        <InputsWrapper>
          <InputRow>
            <TextFieldComponent
              label="First Name"
              placeholder="User’s first name"
              name="firstName"
              defaultValue={firstName}
              onChange={onChange}
            />
          </InputRow>
          <InputRow>
            <TextFieldComponent
              label="Last Name"
              placeholder="User’s last name"
              defaultValue={lastName}
              name="lastName"
              onChange={onChange}
            />
          </InputRow>
          <InputRow last>
            <TextFieldComponent
              label="Email"
              placeholder="User’s email"
              name="email"
              defaultValue={email}
              onChange={onChange}
            />
          </InputRow>
        </InputsWrapper>
        <ModalHeader isFooter>
          <Btn hide width={94} onClick={onBackdropClick}>
            Cancel
          </Btn>
          <Btn width={180} disabled={isDisabled()} onClick={onSubmit}>
            {isEdit ? "Edit" : "Add"} User
          </Btn>
        </ModalHeader>
      </ModalWrapper>
    </ModalBackground>
  );
};
