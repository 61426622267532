import Socket from "socket.io-client";
import React, { useEffect, useState } from "react";
import Api from "helpers/api";

let io = null;

export const OnlineStatus = (props) => {
  const status = useOnlineStates(props.token, props.email);
  return <h1>{status}</h1>;
};

export const useOnlineStates = (token, email) => {
  const [status, setStatus] = useState("connected");

  useEffect(() => {
    let unmount = false;
    const handlerData = (data) => {
      if (!unmount) setStatus(data);
    };

    let id = setTimeout(() => {
      if (!unmount) setStatus("offline");
    }, 1000);

    initConnect(token, email, "check");
    io.on(email, handlerData);

    return () => {
      clearTimeout(id);
      unmount = true;
      io.off(email);
    };
  }, []);

  return status;
};

export const initConnect = (token, email, type = "status") => {
  if (io) return io;
  io = new Socket(Api.url, {
    query: { type, token, email },
  });

  let timerId;
  let currentStatus;
  const onWait = () => {
    clearTimeout(timerId);
    if (!currentStatus || currentStatus === "wait" || currentStatus === "siteOut") {
      io.emit("onOnline");
      currentStatus = "online";
    }

    timerId = setTimeout(() => {
      io.emit("onWait");
      currentStatus = "wait";
    }, 10000 || 5 * 60 * 1000);
  };

  const _onMove = window.onmousemove;
  window.onmousemove = (...args) => {
    _onMove && _onMove(...args);
    onWait();
  };

  const _onKeydown = window.onkeydown;
  window.onkeydown = (...args) => {
    _onKeydown && _onKeydown(...args);
    onWait();
  };

  window.onfocus = () => onWait();
  window.onblur = () => {
    currentStatus = "siteOut";
    io.emit("onSiteOut");
  };

  onWait();
};
