import styled from "styled-components";

import { DynamicBtn } from "../../styled";

export const Row = styled.div`
  margin-top: ${({ marginTop = "20px" }) => marginTop};
  display: flex;
  justify-content: space-between;
  align-items: ${({ alignItems = "center" }) => alignItems};
  width: ${({ width = "100%" }) => width};
  ${({ wrap = false }) => (wrap ? "flex-wrap: wrap;" : "")}
`;

export const Col = styled.div`
  margin-top: ${({ marginTop = "20px" }) => marginTop};
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: ${({ alignItems = "center" }) => alignItems};
  width: ${({ width = "100%" }) => width};
  ${({ wrap = false }) => (wrap ? "flex-wrap: wrap;" : "")};
`;

export const Empty = styled.div`
  width: ${({ width = "calc(33% - 15px)" }) => width};
`;

export const Separator = styled.div`
  height: 25px;
`;

export const Button = styled(DynamicBtn)`
  position: ${({ p = "static" }) => p};
  width: 100%;
`;

export const Remove = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 10px;
  line-height: 135%;
  text-align: right;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #ff4343;
  svg {
    margin-right: 6px;
    width: 9px;
    height: 9px;
  }
`;
