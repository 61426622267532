import styled from "styled-components";

export const Flex = styled.div`
  display: flex;
`;

export const Column = styled(Flex)`
  flex-direction: column;
`;

export const FlexCenterAuto = styled(Flex)`
  margin: 0 auto;
`;

export const ColumCenterAuto = styled(Column)`
  margin: 0 auto;
`;
