import React, { Fragment, useState, useEffect } from "react";

import { Card, BorderBlock, Heading, Row, ShareRow, TH, TD, Avatar, RemoveBtn } from "./styled";
import { UserIcon as NoAvatar } from "components/common/icons-primary";
import { Button } from "../Offerings/styled";
import Api from "../../../../helpers/api";
import UserIcon from "../../../../assets/no-avatar.svg";

const Item = ({
  token,
  e: { txHash, price, currency, tokenIdentifier, tokenId, quantity, owner, buyable, ownerLogo },
  onRemoveShare,
  history,
  assetId,
}) => {
  const [avatar, setAvatar] = useState(null);

  useEffect(() => {
    if (!avatar && ownerLogo) {
      Api.getAttachment(token, ownerLogo.secureHash, ownerLogo.fileNameWithExtension).then((blob) => setAvatar(blob));
    }
  }, []);
  return (
    <ShareRow own={!buyable}>
      <TD owner={!buyable} width="290px">
        {avatar ? <Avatar src={avatar} alt="avatar" /> : <NoAvatar />}
        <span>
          {owner} {!buyable && "(your order)"}
        </span>
      </TD>
      <TD owner={!buyable} width="95px" normal>
        {currency}
      </TD>
      <TD owner={!buyable} width="95px">
        {quantity + " " + tokenIdentifier}
      </TD>
      <TD owner={!buyable} width="95px">
        {price + " " + currency}
      </TD>
      <TD width="130px">
        {buyable ? (
          <Button
            width={130}
            height={32}
            size={12}
            onClick={() => history.push(`/investor/offerings/buy-shares/${assetId}/${txHash}`)}
          >
            BUY
          </Button>
        ) : (
          <RemoveBtn onClick={() => onRemoveShare(txHash)}>REMOVE</RemoveBtn>
        )}
      </TD>
    </ShareRow>
  );
};

export const TokensOnSale = ({ assetSymbol, shares, token, history, assetId }) => {
  const [_shares, setShares] = useState(shares);

  useEffect(() => {
    setShares(shares);
  }, [shares]);

  const removeShare = (txHash) => {
    const originalShares = [..._shares];
    const index = originalShares.findIndex((e) => e.txHash === txHash);
    originalShares.splice(index, 1);
    setShares(originalShares);
  };

  const renderShares = () => {
    return _shares.map((e) => {
      return (
        <Item
          assetId={assetId}
          history={history}
          token={token}
          e={e}
          key={`${e.txHash}-${e.tokenIdentifier}-${e.quantity}`}
          onRemoveShare={(txHash) => removeShare(txHash)}
        />
      );
    });
  };
  return (
    <Fragment>
      <Card>
        <BorderBlock>
          <Heading fSize={18}>Buy {assetSymbol} Shares from users</Heading>
        </BorderBlock>
        <Row justify="space-between" padding="0 30px" height="58px">
          <TH width="290px">Advertiser</TH>
          <TH width="95px">Accepted cur.</TH>
          <TH width="95px">Available</TH>
          <TH width="95px">Price</TH>
          <TH width="130px" />
        </Row>
      </Card>
      {renderShares()}
    </Fragment>
  );
};
