import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";

import Api from "../../../../helpers/api";
import { AddUserModal } from "./AddUserModal";
import { icons } from "../../../../models/icons";
import {
  Header,
  TextH3,
  Wrapper,
  Column,
  Main,
  TextSub,
  InputRow,
  Head,
  UserCard,
  UserWrapper,
  IconsBlock,
  WaitingWrapper,
} from "./styled";
import { TextFieldComponent, SelectComponent, PhoneComponent } from "../../forms";
import { Btn } from "../../buttons";
import { CircularProgress } from "@material-ui/core";
import { ErrorMessage } from "../../errorMessage";

import { uidGenerator } from "helpers";

const EditInstitution = ({
  token,
  history,
  countriesOption,
  match: {
    params: { investorId, instId },
  },
}) => {
  const [institution, setInstitution] = useState(null);
  const [companyData, setCompanyData] = useState({
    name: "",
    phone: "",
    webSiteUrl: "",
    countryOfRegistration: "",
  });
  const [invId, setInvId] = useState(investorId);

  const [representatives, setRepresentatives] = useState([]);
  const [error, setError] = useState(null);
  const [wait, setWait] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);

  const [editUser, setEditUser] = useState(null);
  const [load, setLoad] = useState(true);

  const [deletedUsersId, setDeletedUsersId] = useState([]);
  const [editedUsersSimpleIdAndData, setEditedUsersSimpleIdAndData] = useState([]);
  const [editedUsersIdAndData, setEditedUsersIdAndData] = useState([]);

  const onCompanyData = (key) => (e) => setCompanyData({ ...companyData, [key]: e.target.value });

  const onPhoneChange = (num) => setCompanyData({ ...companyData, phone: num });

  const toggleModal = (user) => {
    setEditUser(user);
    setModalOpen(!modalOpen);
  };

  const isDisabled = () => {
    const keys = ["name", "phone", "webSiteUrl", "countryOfRegistration"];
    const _toStr = (e) => JSON.stringify(e);
    const { users } = institution;
    return (
      (keys.every((key) => companyData[key] === institution[key]) &&
        representatives.every((e, i) => _toStr(e) === _toStr(users[i]))) ||
      keys.some((key) => !companyData[key])
    );
  };

  const onAddUser = (user) => {
    setRepresentatives([...representatives, { ...user, localId: uidGenerator() }]);
  };

  const onEditUser = (user) => {
    setRepresentatives(
      representatives.map((e) => {
        return (e.id && e.id === user.id) || (e.localId && e.localId === user.localId) ? { ...e, ...user } : e;
      })
    );

    if (editUser.enabled) {
      setEditedUsersSimpleIdAndData([
        ...editedUsersSimpleIdAndData.filter((e) => e.id !== editUser.id),
        {
          firstName: user.firstName,
          lastName: user.lastName,
          id: editUser.id,
        },
      ]);
    } else if (user.id) {
      setEditedUsersIdAndData([
        ...editedUsersIdAndData.filter((e) => e.id !== editUser.id),
        {
          firstName: user.firstName,
          lastName: user.lastName,
          username: user.username,
          email: user.username,
          id: user.id,
        },
      ]);
    }
  };

  const onRemUser = (user) => () => {
    if (user.id === invId) {
      const { id } = representatives.find((el) => el.id !== user.id);
      setInvId(id);
    }

    setRepresentatives(
      representatives.filter((e) => {
        return (user.id && user.id !== e.id) || (user.localId && user.localId !== e.localId);
      })
    );

    setEditedUsersIdAndData(editedUsersIdAndData.filter((e) => e.id !== user.id));

    setEditedUsersSimpleIdAndData(editedUsersSimpleIdAndData.filter((e) => e.id !== user.id));

    if (user.id) setDeletedUsersId([...deletedUsersId, user.id]);
  };

  const onChangeInstitution = async () => {
    setWait(true);
    const { countryOfRegistration, webSiteUrl, name, phone } = companyData;
    const request = {
      countryRegistration: countryOfRegistration,
      webSiteUrl,
      name,
      phone,
    };

    request.newUsers = representatives
      .filter((e) => e.localId)
      .map((user) => ({
        ...user,
        email: user.username,
        countryOfResidence: companyData.countryOfRegistration,
      }));
    if (!request.newUsers.length) delete request.newUsers;

    if (deletedUsersId.length) request.deletedUsersId = [...deletedUsersId];

    const arrayToMapById = (result, item) => ({
      ...result,
      [item.id]: {
        ...item,
      },
    });
    if (editedUsersSimpleIdAndData.length) {
      request.editedUsersSimpleIdAndData = editedUsersSimpleIdAndData.reduce(arrayToMapById, {});
    }

    if (editedUsersIdAndData.length) {
      request.editedUsersIdAndData = editedUsersIdAndData.reduce(arrayToMapById, {});
    }

    const res = await Api.updateInstitution(token, instId, request).catch(setError);
    if (res) history.push(`/admin/users/institution/${invId}`);
    setWait(false);
  };

  useEffect(() => {
    const getInitial = async () => {
      const inst = await Api.getInstitutionById(token, investorId).catch(setError);

      if (inst) {
        setInstitution(inst);
        setCompanyData({ ...inst });
        setRepresentatives(inst.users);
      }

      setLoad(false);
    };
    getInitial();
  }, []);

  if (load) {
    return <CircularProgress style={{ margin: "auto" }} />;
  } else {
    return (
      <Fragment>
        {wait ? (
          <WaitingWrapper>
            <CircularProgress />
          </WaitingWrapper>
        ) : null}
        <Header>
          <Btn transparent onClick={() => history.push(`/admin/users/institution/${invId}`)} width={150}>
            {icons.left}
            <span>back</span>
          </Btn>
          <TextH3 marginSize="0 0 0 25px">Edit {institution.name}</TextH3>
        </Header>
        <Wrapper>
          <Column width="calc(72% - 30px)">
            <Main>
              <TextSub textAlign="left" fontWeight="bold" color="#181818" marginSize="0 0 25px 0">
                Main Information
              </TextSub>
              <InputRow last>
                <TextFieldComponent
                  label="Company name"
                  placeholder="Company name"
                  defaultValue={companyData.name}
                  onChange={onCompanyData("name")}
                />
              </InputRow>
            </Main>
            <Main noHidden last padding="15px 25px 30px 25px">
              <Head>
                <TextSub textAlign="left" fontWeight="bold" color="#181818" marginSize="0 0 25px 0">
                  Representative(s)
                </TextSub>
                <Btn width={195} onClick={() => toggleModal()}>
                  {icons.plusCircle} add new
                </Btn>
              </Head>
              <UserWrapper>
                {representatives.map((user) => (
                  <UserCard key={`unique-${user.id || user.localId}`}>
                    {icons.userSmall}
                    <span>{user.firstName + " " + user.lastName}</span>
                    <IconsBlock>
                      <div onClick={() => toggleModal(user)}>{icons.edit}</div>
                      {user.enabled ? null : <div onClick={onRemUser(user)}>{icons.remove}</div>}
                    </IconsBlock>
                  </UserCard>
                ))}
              </UserWrapper>
            </Main>
          </Column>
          <Column width="28%">
            <Main noHidden>
              <TextSub textAlign="left" fontWeight="bold" color="#181818" marginSize="0 0 25px 0">
                Contact Information
              </TextSub>
              <InputRow>
                <SelectComponent
                  view="filter"
                  values={countriesOption}
                  defaultValue={companyData.countryOfRegistration}
                  onChange={onCompanyData("countryOfRegistration")}
                  placeholder="Company location"
                  label="Company location"
                />
              </InputRow>
              <InputRow>
                <PhoneComponent
                  label="Phone"
                  placeholder="Company phone"
                  defaultValue={companyData.phone}
                  onChange={onPhoneChange}
                />
              </InputRow>
              <InputRow last>
                <TextFieldComponent
                  label="Web-site"
                  placeholder="Web-site"
                  defaultValue={companyData.webSiteUrl}
                  onChange={onCompanyData("webSiteUrl")}
                />
              </InputRow>
            </Main>
          </Column>
        </Wrapper>
        <Header isFooter between>
          <Btn transparent onClick={() => history.push(`/admin/users/institution/${invId}`)} width={180}>
            cancel
          </Btn>
          <Btn width={180} disabled={isDisabled() || wait} onClick={onChangeInstitution}>
            save changes
          </Btn>
        </Header>
        {modalOpen ? (
          <AddUserModal
            user={editUser}
            representatives={representatives}
            onEditUser={onEditUser}
            onAddUser={onAddUser}
            onBackdropClick={() => toggleModal()}
          />
        ) : null}
        {error && <ErrorMessage er={error} onClose={() => setError(null)} />}
      </Fragment>
    );
  }
};

export default connect(
  ({ userReducer: { token }, asyncDataReducer: { countriesOption } }) => ({
    token,
    countriesOption,
  }),
  null
)(EditInstitution);
