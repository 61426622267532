import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { shadeColor } from "helpers/increseBrightnes";

export const Button = styled.button`
  line-height: normal;
  background: ${({ bg = "#2b56f5" }) => bg};
  ${({ fullWidth }) => (fullWidth ? "width: 100%;" : "")}
  &:focus {
    background: ${({ bg = "#2b56f5" }) => bg};
  }
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 2px solid ${({ bg = "#2b56f5", borderColor = "" }) => borderColor || bg};
  color: ${({ color = "#fff" }) => color};
  border-radius: 4px;
  line-height: 18px;
  font-size: ${({ fontSize = "14px" }) => fontSize};
  ${({ noShadow = false }) => {
    return noShadow ? "" : "box-shadow: 0px 2px 18px 2px rgba(43, 86, 245, 0.25);";
  }}
  min-width: 115px;
  height: 30px;
  ${({ margin = "" }) => (margin ? `margin: ${margin};` : "")}
`;

export const ButtonImg = styled.img`
  width: ${({ width = "100%" }) => width};
  ${({ height }) => (height ? `height: ${height};` : "")};
  display: block;
  object-fit: cover;
`;

export const UploadImg = ({ load, ...props }) => {
  const [base64, setBase64] = useState("");
  useEffect(() => {
    (async () => {
      if (load) setBase64(await load());
    })();
  }, []);

  return <ButtonImg src={base64} {...props} />;
};

export const ButtonFitWrapper = styled.div`
  display: inline-block;
  cursor: pointer;
  user-select: none;
`;

export const Btn = styled.button`
  position: relative;
  white-space: nowrap;
  padding-bottom: 0;
  padding-top: 0;
  width: ${({ width, fullWidth }) => (width ? `${width}px` : fullWidth ? "100%" : "94px")};
  border: 1px solid
    ${({ black, hide, error, green, red, fullGreen, grey, theme: { primary } }) =>
      black
        ? "#20263C"
        : grey
        ? "#8A8A8A"
        : fullGreen
        ? "#019D52"
        : hide
        ? "#CACACA"
        : red
        ? "#FF4343"
        : error
        ? "#FF4343"
        : green
        ? "#55B17C"
        : primary};
  height: ${({ height }) => (height ? `${height}px` : "32px")};
  background: ${({ black, white, hide, transparent, error, green, red, fullGreen, grey, theme: { primary } }) =>
    black
      ? "#20263C"
      : grey
      ? "#F9F9F9"
      : red
      ? "#FF4343"
      : white || hide
      ? "#fff"
      : transparent || error || green
      ? "transparent"
      : fullGreen
      ? "#019D52"
      : primary};
  color: ${({ white, hide, transparent, error, green, red, grey, theme: { primary } }) =>
    grey
      ? "#8A8A8A"
      : white || transparent
      ? primary
      : hide
      ? "#CACACA"
      : red
      ? "#FFFFFF"
      : error
      ? "#FF4343"
      : green
      ? "#55B17C"
      : "#fff"};
  font-size: 10px;
  /* line-height: 135%; */
  text-align: center;
  font-weight: bold;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  border-radius: 4px;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: ${({ margin }) => (margin ? margin : "0")};
  transition: all 0.1s linear;
  &:disabled {
    background: #e3e3e3 !important;
    border: 1px solid #e3e3e3;
    color: white !important;
    &::before {
      background: #e3e3e3 !important;
    }
    > svg {
      path {
        stroke: #fff;
      }
    }
  }
  > svg {
    margin: ${({ rightIcon }) => (rightIcon ? "0 0 0 6px " : "0 6px 0 0")};
    transform: ${({ transform }) => (transform ? "rotate(180deg)" : "none")};
    transition: all 0.3s linear;
    width: 9px;
    height: 9px;
    path {
      stroke: ${({ transparent, error, green, grey, theme: { primary } }) =>
        grey ? "#8A8A8A" : transparent ? primary : error ? "#FF4343" : green ? "#55B17C" : "#fff"};
    }
  }
  ${({ extraInCard = false, theme: { primary, navLinkActive } }) => {
    if (extraInCard) {
      return `
      background: transparent;
      color:${primary};
      :hover{
        background:${primary};
        color:${navLinkActive};
      }
      `;
    } else return "";
  }}
  //states
  &:hover {
    &::before {
      display: ${({ hideBorder = false }) => (hideBorder ? "none" : "block")};
    }
  }
  &:active {
    ${({ black, hide, error, green, red, fullGreen, grey, theme: { primary } }) => {
      const activeColor = black
        ? shadeColor("#20263C", -15)
        : grey
        ? shadeColor("#8A8A8A", -15)
        : fullGreen
        ? shadeColor("#019D52", -15)
        : hide
        ? shadeColor("#CACACA", -15)
        : red
        ? shadeColor("#FF4343", -15)
        : error
        ? shadeColor("#FF4343", -15)
        : green
        ? shadeColor("#55B17C", -15)
        : shadeColor(primary, -15);
      return `background: ${activeColor};border: 1px solid ${activeColor};color:#fff;`;
    }};
    &::before {
      display: none;
    }
    > svg {
      path {
        stroke: #fff;
      }
    }
  }
  &::before {
    transition: all 0.3s linear;
    content: "";
    position: absolute;
    display: none;
    bottom: -1px;
    left: -1px;
    height: 3px;
    background: ${({ black, hide, error, green, red, fullGreen, grey, theme: { primary } }) =>
      black
        ? "#20263C"
        : grey
        ? "#8A8A8A"
        : fullGreen
        ? "#019D52"
        : hide
        ? "#CACACA"
        : red
        ? "#FF4343"
        : error
        ? "#FF4343"
        : green
        ? "#55B17C"
        : primary};
    width: calc(100% + 2px);
    filter: brightness(0.85);
    border-radius: 0 0 4px 4px;
  }
`;

export const VarButton = styled.div`
  width: 120px;
  height: 42px;
  background: ${({ theme: { disabledField } }) => disabledField};
  border: 1px solid #efefef;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 135%;
  letter-spacing: -0.02em;
  color: #181818;
  box-sizing: border-box;
  margin-right: 12px;
`;
