import GLAFKA_DEV from "./termsGlafka";
import INVESTURE from "./termsInvesture";

const GLAFKA_EUROPE = GLAFKA_DEV;
const GLAFKA_PROD = GLAFKA_DEV;
const INVESTURE_PROD = INVESTURE;

export const terms = {
  GLAFKA_DEV,
  GLAFKA_EUROPE,
  GLAFKA_PROD,
  INVESTURE,
  INVESTURE_PROD,
};
