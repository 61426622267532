import React from "react";
import { FlexRow } from "components/admin-layout/dashBoardCard";
import { EmptyInfo } from "components/admin-layout/pages/AssetDetails/common/emptyInfo";
import { Main, TextSub } from "components/admin-layout/pages/DealForm/Step/styled";

export const NoDeals = ({ heading }) => (
  <Main currentHeight="415px" padding="20px 25px 25px">
    <FlexRow mBottom="0" align="flex-start">
      <TextSub textAlign="left" fontWeight="bold" color="#181818" marginSize="0 0 25px 0">
        {heading}
      </TextSub>
    </FlexRow>
    <EmptyInfo
      heading="There are no assets yet"
      text="When we’ll have assets to show for you we’ll show them here"
    ></EmptyInfo>
  </Main>
);
