import React from "react";

import { Card, Row } from "./styled";
import { Button } from "../Offerings/styled";
import { ReactComponent as FileIcon } from "../../../../assets/file-text.svg";
import { ReactComponent as ShareIcon } from "../../../../assets/share.svg";

export const BottomSubmitBlock = ({ stepName, onSubmit, dealsType, toBeRaised }) => {
  return (
    <Card padding="14px 25px">
      <Row>
        <Button height={42} transparent width={200} size={14}>
          <ShareIcon style={{ marginRight: 8 }} />
          Share Deal
        </Button>
        {dealsType !== "EXISTED_DEAL" && toBeRaised ? (
          <Button size={14} width={280} height={42} margin="0" onClick={onSubmit} disabled={stepName === "UPLOAD LOI"}>
            <FileIcon style={{ marginRight: 10, width: 16, height: 16 }} /> {stepName.toUpperCase()}
          </Button>
        ) : null}
      </Row>
    </Card>
  );
};
