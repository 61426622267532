import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import { getAssets, getOrderHistory } from "../../../../modules/dataRedux/dataAction";
import { SelectComponent } from "../../../admin-layout/forms";
import { Wrapper, Block, SelectBlock, TableHead, Transaction, EmptyCard } from "./styled";
import { Button } from "../Offerings/styled";
import { Paginator } from "../../../admin-layout/pages/UserList/styled";
import { LoaderWrapper } from "../../styled/Loader";
import { EmptyCard as NoInfo } from "components/common/emptyInfo";

import theme from "models/themes";

const hashMapStatus = {
  all: "All",
  completed: "Completed",
  pending: "Pending",
};

const types = ["Cash & Shares", "Only Cash", "Only Shares"];
const hashMapType = {
  all: "Cash & Shares",
  cash: "Only Cash",
  share: "Only Shares",
  "Cash & Shares": "all",
  "Only Cash": "cash",
  "Only Shares": "share",
};

const Transactions = ({
  history,
  token,
  assets,
  getAssets,
  getOrderHistory,
  orderHistory,
  match: {
    params: { status, type },
  },
}) => {
  const [currentTransaction, setCurrentTransaction] = useState([]);
  const [pagination, setPaginationPage] = useState(1);
  const [typeTransaction, setTypeTransaction] = useState(type);

  useEffect(() => {
    history.push(`/investor/transactions/${status}/${typeTransaction}`);
  }, [typeTransaction]);

  useEffect(() => {
    if (!orderHistory && token) {
      getOrderHistory(token);
    }
    if (!assets.length) getAssets(token);
  }, []);

  useEffect(() => {
    setCurrentTransaction(
      ((orderHistory && orderHistory[hashMapStatus[status]]) || []).filter((e) => {
        if (type === "all") return true;
        if (type === "cash") return e.cashTx;
        else return !e.cashTx;
      })
    );
  }, [orderHistory, status, type]);

  useEffect(() => {
    setPaginationPage(1);
  }, [currentTransaction]);

  const onChangeType = (e) => {
    setTypeTransaction(hashMapType[e.target.value] || "all");
  };

  const renderTransactions = () => {
    const _transactions = currentTransaction.slice((pagination - 1) * 10, (pagination - 1) * 10 + 10);
    if (_transactions.length) {
      return _transactions.map((e) => {
        const random = Math.round(Math.random() * 10000);
        return <Transaction assets={assets} key={`${random}-${e.txHash}`} tx={e} history={history} token={token} />;
      });
    } else
      return (
        <EmptyCard>
          <NoInfo heading="No Transaction recorded yet" />
        </EmptyCard>
      );
  };

  if (orderHistory && assets.length && orderHistory.All.length) {
    return (
      <Wrapper>
        <Block height={60}>
          <Button
            width={200}
            height={36}
            size={12}
            hide={status !== "all"}
            onClick={() => history.push(`/investor/transactions/all/${type}`)}
          >
            All Transactions
          </Button>
          <Button
            width={200}
            height={36}
            size={12}
            margin="0 12px"
            hide={status !== "completed"}
            onClick={() => history.push(`/investor/transactions/completed/${type}`)}
          >
            Completed Transactions
          </Button>
          <Button
            width={200}
            height={36}
            size={12}
            hide={status !== "pending"}
            onClick={() => history.push(`/investor/transactions/pending/${type}`)}
          >
            Pending Transactions
          </Button>
          <SelectBlock>
            <SelectComponent
              fSize={12}
              selectHeight={36}
              top={45}
              values={types}
              borderColor={theme.primary}
              defaultValue={hashMapType[typeTransaction]}
              onChange={onChangeType}
            />
          </SelectBlock>
        </Block>

        <TableHead />

        {renderTransactions()}

        {currentTransaction.length > 10 ? (
          <Paginator
            key={`key-page-${pagination}-${currentTransaction.length}`}
            isInvestor
            array={currentTransaction}
            itemsPerPage={10}
            startPage={pagination}
            onChangePage={(i) => setPaginationPage(i)}
          />
        ) : null}
      </Wrapper>
    );
  } else if (orderHistory && !orderHistory.All.length) {
    return (
      <EmptyCard>
        <NoInfo heading="No Transaction recorded yet" />
      </EmptyCard>
    );
  } else {
    return <LoaderWrapper />;
  }
};

export default connect(
  ({ userReducer: { token }, dataReducer: { orderHistory, assets = [] } }) => ({
    token,
    assets,
    orderHistory,
  }),
  { getAssets, getOrderHistory }
)(Transactions);
