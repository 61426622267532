import React, { useState } from "react";

import { Card, CardName, Row, Col, DomainPreview } from "components/setup-layout/common";
import { TextFieldComponent } from "components/admin-layout/forms";
import { Label } from "components/auhtForms/styled";
import { Globe } from "components/common/icons-primary";

const marginSize = "0 0 24px";
const halfSize = "calc(50% - 15px)";

export const MainInfo = () => {
  const [domain, setDomain] = useState("");
  const [name, setName] = useState("");
  return (
    <Card>
      <CardName>Main Information</CardName>
      <Row m={marginSize}>
        <TextFieldComponent isInvestor placeholder="Company Name" label="Company Name" />
      </Row>
      <Row>
        <Col w={halfSize}>
          <TextFieldComponent
            isInvestor
            placeholder="yourplatform"
            label="PLATFORM DOMAIN"
            value={domain}
            onChange={(e) => setDomain(e.target.value)}
          />
        </Col>
        <Col w={halfSize}>
          <Label>LINK PREVIEW</Label>
          <DomainPreview>
            <Globe />
            <span>
              {domain || "yourplatform"}.<span className="valk-domain">valkplatform.com</span>
            </span>
          </DomainPreview>
        </Col>
      </Row>
    </Card>
  );
};
