import React, { useState, useEffect, Fragment, useCallback } from "react";
import { connect } from "react-redux";

import { getTrades } from "../../../../modules/dataRedux/dataAction";
import { setSoldTrade } from "../../../../modules/dataRedux/dataAction";

import Api from "../../../../helpers/api";

import { Wrapper, Col, Card, Header, BorderBlock, Heading, Row } from "../Investment/styled.js";
import { Button } from "../../../investor-layout/pages/Offerings/styled";
import { ReactComponent as BackIcon } from "../../../../assets/arrowleft.svg";
import { Loader } from "../../styled/Loader";

import { Amount } from "./Amount";
import { Price } from "../Investment/Price";
import { BankDetails } from "../Investment/BankDetailsModal";
import { AddBankAccount } from "../Investment/AddBankAccount";

const fullSize = "100%";
const colWidth = 440;

export const BuyShares = ({
  token,
  history,
  getTrades,
  trade,
  match: {
    params: { id, txHash },
  },
  setSoldTrade,
}) => {
  const [bankDetails, setBankDetails] = useState(null);
  const [cashBalance, setCashBalance] = useState(null);
  const [type, setType] = useState("bank");
  const [amount, setAmount] = useState("");
  const [bankDetailsOpen, openBankDetails] = useState(false);
  const [activeAccount, setActiveAccount] = useState(null);
  const [inputError, setInputError] = useState(false);
  const [addBankOpen, setAddBankOpen] = useState(false);
  const [smallScreen, setSmallScreen] = useState(window.innerWidth < 1280);

  useEffect(() => {
    const onResize = () => {
      setSmallScreen(window.innerWidth < 1280);
    };

    window.addEventListener("resize", onResize);

    if (!trade) getTrades(token);

    Promise.all([Api.getCashBalance(token), Api.getBankDetails(token)]).then((data) => {
      setCashBalance(data[0]);
      setBankDetails(data[1]);
    });

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  const openAddAccount = () => {
    setAddBankOpen(!addBankOpen);
  };

  const onAddNewAccount = async (account) => {
    const accounts = await Api.getBankDetails(token);
    if (accounts) {
      setBankDetails(accounts);
      openAddAccount();
    }
  };

  useEffect(() => {
    if (bankDetails) {
      setActiveAccount(bankDetails[0].accountNumber);
    }
  }, [bankDetails]);

  const changeModalStatus = () => openBankDetails(!bankDetailsOpen);

  const PriceBlock = useCallback(
    (price, currency, assetSymbol) => <Price convert={price + " " + currency} assetSymbol={assetSymbol} />,
    []
  );

  if (trade && cashBalance) {
    const {
      assetProperties: { assetName, assetSymbol },
      assetFinancialProperty: { baseCurrency },
      tokensOnSale,
    } = trade;
    const tokenToSell = tokensOnSale.find((e) => e.txHash === txHash);

    const { owner, price, currency } = tokenToSell;

    const onSubmit = () => {
      setSoldTrade({
        ...tokenToSell,
        amount,
        activeAccount,
        type,
        cashBalance,
        baseCurrency,
      });
      history.push(`/investor/offerings/sign-contract-trade/${id}/${txHash}`);
    };

    return (
      <Wrapper>
        <Header>
          <BorderBlock>
            <Button
              transparent
              height={36}
              size={12}
              width={176}
              onClick={() => history.push(`/investor/offerings/users/${id}/buy-shares`)}
            >
              <BackIcon style={{ marginRight: 8 }} />
              Back to Deal
            </Button>
          </BorderBlock>
          <Heading>
            Buy shares of {assetName} from {owner}
          </Heading>
        </Header>
        {smallScreen ? (
          <Col margin="0 0 15px" width={fullSize}>
            {PriceBlock(price, currency, assetSymbol)}
          </Col>
        ) : null}
        <Col width={smallScreen ? fullSize : `calc(${fullSize} - ${colWidth + 30}px)`}>
          <Amount
            onAmountChange={setAmount}
            tokenToSell={tokenToSell}
            type={type}
            onChangeType={setType}
            cashBalance={cashBalance}
            onError={setInputError}
            baseCurrency={baseCurrency}
          />
          <Card noPadding height={62}>
            <Button
              disabled={!amount || inputError}
              height={42}
              width={287}
              bold
              size={14}
              margin="10px 0 10px 30px"
              onClick={type === "bank" ? changeModalStatus : onSubmit}
            >
              NEXT STEP
            </Button>
          </Card>
        </Col>
        {smallScreen ? null : <Col width={`${colWidth}px`}>{PriceBlock(price, currency, assetSymbol)}</Col>}
        <BankDetails
          bankDetails={bankDetails}
          isOpen={bankDetailsOpen}
          history={history}
          onSubmit={onSubmit}
          onBackDropClick={changeModalStatus}
          callBack={setActiveAccount}
          onAddNewAccount={openAddAccount}
        />
        {/* Add new account */}
        <AddBankAccount
          isOpen={addBankOpen}
          onBackDropClick={openAddAccount}
          token={token}
          callBack={onAddNewAccount}
        />
      </Wrapper>
    );
  } else {
    return <Loader />;
  }
};

export default connect(
  (
    { userReducer: { token }, dataReducer: { trades = [] } },
    {
      match: {
        params: { id, txHash },
      },
    }
  ) => {
    const trade = trades.find((e) => e.assetId === id);

    return { token, trade, id, txHash };
  },
  { getTrades, setSoldTrade }
)(BuyShares);
