import React, { Fragment, useState, useRef, useEffect } from "react";
import Highcharts from "highcharts/highstock";

import { optionCreator } from "./helpers";
import { DealCard, Separator, Info, Row, AssetName, Filter, Statistic, HR, ChartWrapper } from "./styled";
import { ProgressBar, DataColumn } from "../AssetDetails/styled";
import { Btn } from "../../buttons";
import { moutNumber } from "../../../../helpers";
import theme from "models/themes";

export const Deal = ({
  deal: {
    assetName,
    assetId,
    raisedAmountDay,
    raisedAmountWeek,
    raisedAmountMonth,
    raisedAmountTotal,
    compareDayPercent,
    compareWeekPercent,
    compareMonthPercent,
    total,
    soldSharesPercent,
    subscribers,
    currency,
  },
  chartData,
  history,
}) => {
  const [dealData, setDealData] = useState({
    raised: raisedAmountDay,
    comparePercent: compareDayPercent,
  });

  const chartRef = useRef(null);

  const [width, setWidth] = useState(0);

  useEffect(() => {
    setWidth(chartRef.current.offsetWidth);
  }, []);

  const [period, setPeriod] = useState("day");

  const [chartSelected, setChartSelected] = useState(0);

  const filterData = (period) => {
    setDealData({
      raised:
        period === "day"
          ? raisedAmountDay
          : period === "week"
          ? raisedAmountWeek
          : period === "month"
          ? raisedAmountMonth
          : 0,
      comparePercent:
        period === "day"
          ? compareDayPercent
          : period === "week"
          ? compareWeekPercent
          : period === "month"
          ? compareMonthPercent
          : 0,
    });
    setPeriod(period);
    setChartSelected(period === "week" ? 1 : period === "month" ? 2 : period === "day" ? 0 : 0);
  };

  return (
    <Fragment>
      <DealCard>
        <Info>
          <Row>
            <AssetName>{assetName}</AssetName>
            <Statistic data={dealData.comparePercent} period={period} />
          </Row>
          <Separator height={20} />
          <Row>
            <DataColumn
              width="150px"
              mRight={2}
              heading="raised amount"
              data={`${moutNumber(raisedAmountTotal.toFixed())} ${currency}`}
              circleColor={theme.primaryMiddle}
            />
            <DataColumn
              width="150px"
              mRight={2}
              heading="total"
              data={`${moutNumber(total.toFixed())} ${currency}`}
              circleColor={theme.primaryLight}
            />
            <DataColumn
              width="80px"
              mRight={20}
              heading="sold shares"
              data={`${parseFloat(soldSharesPercent).toFixed(2)} %`}
            />
            <DataColumn width="76px" mRight={10} heading="subscribers" data={subscribers} />
          </Row>
          <Separator height={21} />
          <ProgressBar soldSharesPercent={soldSharesPercent} isDashBoard={true} />
          <Separator height={20} />
          <Row>
            <Filter periods={["day", "week", "month"]} filter={(data) => filterData(data)} />
            <Btn onClick={() => history.push(`/admin/dashboard/${assetId}`)}>More info</Btn>
          </Row>
        </Info>
        <ChartWrapper ref={chartRef}>
          <HR
            key={`chart-${chartSelected}`}
            highcharts={Highcharts}
            options={optionCreator(chartData, width - 30, false, chartSelected, currency)}
            constructorType={"stockChart"}
          />
        </ChartWrapper>
      </DealCard>
      <Separator />
    </Fragment>
  );
};
