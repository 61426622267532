import styled from "styled-components";
import React, { useState, useEffect } from "react";
import moment from "moment";

import Api from "../../../../helpers/api";
import { ReactComponent as UserIcon } from "../../../../assets/institution-icon.svg";
import { ReactComponent as RigthIcon } from "../../../../assets/chevron-right.svg";
import { ReactComponent as IncomeIcon } from "../../../../assets/arrow-down-right.svg";
import { ReactComponent as OutIcon } from "../../../../assets/arrow-up-right.svg";
import { numberWithCommas } from "../../../../helpers";
import { Link } from "react-router-dom";

export const WaitingWrapper = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  background: #00000045;
`;

export const Header = styled.div`
  width: 100%;
  height: ${({ height = 60 }) => height}px;
  border-radius: 4px;
  background: white;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: ${({ padding }) => (padding ? padding : "0 25px")};

  ${({ isFooter = false }) => {
    if (isFooter) return `margin-top: 30px;`;
    else return `margin-bottom: 30px;`;
  }}
  ${({ between = false }) => (between ? "justify-content: space-between;" : "")}
  > a {
    text-decoration: none;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => width};
`;

export const AvatarBlock = ({ token, file, hash, onClick }) => {
  const [avatar, setAvatar] = useState(null);
  const [initialHash, setInitialHash] = useState(null);

  useEffect(() => {
    if (!avatar && file && hash) {
      Api.getAttachment(token, hash, file).then((blob) => setAvatar(blob || Api.defaultAvatar));
      setInitialHash(hash);
    }
  }, []);

  useEffect(() => {
    if (initialHash && initialHash !== hash && file && hash) {
      Api.getAttachment(token, hash, file).then((blob) => setAvatar(blob || Api.defaultAvatar));
    }
  }, [file, hash]);
  return (
    <GreyBlock>
      {!avatar && file && hash ? null : avatar ? (
        <Avatar src={avatar} alt="avatar" onClick={onClick} />
      ) : (
        <NoAvatar onClick={onClick}>
          <UserIcon />
        </NoAvatar>
      )}
    </GreyBlock>
  );
};

const GreyBlock = styled.div`
  height: 96px;
  background: #e2e3e8;
  border-radius: 4px;
  position: relative;
  > svg {
    width: 102px;
    height: 102px;
    position: absolute;
    z-index: 20;
    top: 30px;
    left: 25px;
  }
  > svg {
    filter: grayscale(${({ theme: { greyFilter } }) => greyFilter});
  }
`;

const Avatar = styled.img`
  width: 102px;
  height: 102px;
  border-radius: 50%;
  position: absolute;
  z-index: 20;
  top: 30px;
  left: 25px;
  object-fit: cover;
  cursor: pointer;
`;

const NoAvatar = styled.div`
  cursor: pointer;
  width: 102px;
  height: 102px;
  border-radius: 50%;
  position: relative;
  z-index: 20;
  top: 30px;
  left: 25px;
  object-fit: cover;
  background: ${({ theme: { quoteBgInvestor } }) => quoteBgInvestor};
  svg {
    width: 52px;
    height: 52px;
    position: absolute;
    top: 25px;
    left: 25px;
    path {
      stroke: ${({ theme: { primary } }) => primary};
    }
  }
`;

export const Main = styled.section`
  position: relative;
  height: ${({ isClosed, currentHeight }) => (isClosed ? "57px" : currentHeight)};
  min-height: ${({ minHeight }) => (minHeight ? `${minHeight}px` : 0)};
  width: ${({ width }) => (width ? width : "100%")};
  overflow: ${({ noHidden }) => (noHidden ? "visible" : "hidden")};
  box-sizing: border-box;
  background: white;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  padding: ${({ padding }) => (padding ? padding : "20px 25px 25px 25px")};
  transition: 0.2s height linear;
`;

export const TextSub = styled.p`
  width: ${({ fullWidth }) => (fullWidth ? "100%" : "auto")};
  cursor: ${({ fullWidth }) => (fullWidth ? "pointer" : "auto")};
  text-align: ${({ textAlign = "center" }) => textAlign};
  font-family: "Muli", sans-serif;
  font-size: ${({ fSize }) => (fSize ? `${fSize}px` : "14px")};
  display: inline-block;
  font-weight: ${({ fontWeight = "normal" }) => fontWeight};
  color: ${({ color = "#CACACA" }) => color};
  ${({ marginSize = "" }) => (marginSize ? `margin: ${marginSize};` : "")}
`;

export const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: ${({ width }) => (width ? `${width}%` : "100%")};
`;

export const InfoCol = ({ heading, info, email = false, bold = false, width, link }) => {
  return (
    <ColumnInfoCol width={width}>
      <Heading>{heading}</Heading>
      <Info email={email} bold={bold}>
        {link ? (
          <a target="_blank" href={info}>
            {info}
          </a>
        ) : (
          info
        )}
      </Info>
    </ColumnInfoCol>
  );
};

const ColumnInfoCol = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => (width ? `${width}%` : "auto")};
`;

const Heading = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 135%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  margin-bottom: 5px;
  color: rgba(24, 24, 24, 0.6);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const Info = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: ${({ bold }) => (bold ? "bold" : "normal")};
  font-size: 14px;
  line-height: 150%;
  color: ${({ bold, email, theme: { primary } }) => (bold ? "#181818" : email ? primary : "#333333")};
  a {
    cursor: pointer;
    /* pointer-events: none; */
    text-decoration: none;
    color: ${({ theme: { primary } }) => primary};
    border-bottom: 0.5px solid rgba(98, 128, 218, 0.2);
  }
`;

export const Separator = styled.div`
  height: ${({ height }) => (height ? `${height}px` : "30px")};
`;

export const Total = styled.div`
  position: absolute;
  top: 20px;
  right: 25px;
  font-weight: 600;
  font-size: 14px;
  line-height: 135%;
  color: rgba(24, 24, 24, 0.6);
`;

export const TransactionsHeader = styled.div`
  height: ${({ height }) => (height ? `${height}px` : "50px")};
  background: ${({ grey, theme: { adminTableRowGrey } }) => (grey ? adminTableRowGrey : "#ffffff")};
  justify-content: space-between;
  display: flex;
  align-items: center;
  transform: ${({ translate }) => (translate ? `translateX(${translate}px)` : "none")};
  padding-right: ${({ paddingR }) => paddingR};
  width: ${({ width }) => width};
`;

export const HeaderTD = styled.div`
  transform: ${({ translate }) => (translate ? `translateX(${translate}px)` : "none")};
  display: flex;
  align-items: center;
  justify-content: ${({ justify = "flex-start" }) => justify};
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 135%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  box-sizing: ${({ boxSizing }) => boxSizing};
  padding: ${({ padding }) => (padding ? padding : "0")};
  color: ${({ blue, theme: { primary } }) => (blue ? primary : "rgba(24, 24, 24, 0.4)")};

  cursor: ${({ blue }) => (blue ? "pointer" : "")};
  width: ${({ width }) => (width ? width : "10%")};
  white-space: nowrap;
`;

export const UserCard = styled.div`
  width: 100%;
  height: 40px;
  background: ${({ theme: { quoteBgInvestor } }) => quoteBgInvestor};
  border-radius: 4px;
  box-sizing: border-box;
  padding: 10px 15px;
  margin-bottom: 8px;
  font-family: Muli;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 135%;
  letter-spacing: -0.02em;
  color: #181818;
  display: flex;
  align-items: center;
  > span {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: calc(100% - 40px);
  }
  > svg {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
  &:last-child {
    margin-bottom: 0;
  }
`;

export const InvestedItem = ({
  asset: { assetLogo, assetName, currency, date, invested, assetId },
  token,
  history,
}) => {
  const [logo, setLogo] = useState(null);

  useEffect(() => {
    if (!logo && assetLogo) {
      const { fileNameWithExtension, secureHash } = assetLogo;
      Api.getAttachment(token, secureHash, fileNameWithExtension).then((b) => setLogo(b));
    }
  }, []);
  return (
    <AssetBlock>
      <LogoBlock>
        {logo ? <AssetLogo src={logo} alt={assetName} /> : <NoLogo />}
        <AssetName
          onClick={() => {
            history.push(`/admin/dashboard/${assetId}`);
          }}
        >
          {assetName}
        </AssetName>
      </LogoBlock>
      <InvestedCount>
        {numberWithCommas(invested.toFixed(2))} {currency}
      </InvestedCount>
      <InvestedDate>{moment(date).format("DD/MM/YYYY, hh:mm a")}</InvestedDate>
      <LinkBlock>
        <AssetLink to={`/admin/dashboard/${assetId}`}>
          <RigthIcon />
        </AssetLink>
      </LinkBlock>
    </AssetBlock>
  );
};

const AssetBlock = styled.div`
  width: 100%;
  height: 50px;
  background: #ffffff;
  border: 1px solid #f0f0f0;
  box-sizing: border-box;
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.02);
  border-radius: 4px;
  margin-bottom: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  &:last-child {
    margin-bottom: 0;
  }
`;

const LogoBlock = styled.div`
  display: flex;
  align-items: center;
  width: calc(100% - 365px);
  height: 100%;
`;

const NoLogo = styled.div`
  width: 80px;
  height: 100%;
  margin-right: 18px;
`;

const AssetLogo = styled.img`
  width: 80px;
  height: 100%;
  margin-right: 18px;
  object-fit: cover;
`;

const AssetName = styled.h6`
  font-size: 12px;
  line-height: 15px;
  letter-spacing: -0.02em;
  border-bottom: 0.5px solid rgba(98, 128, 218, 0.2);
  color: ${({ theme: { primary } }) => primary};
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: calc(100% - 120px);
  padding-bottom: 4px;
  cursor: pointer;
`;

const InvestedCount = styled.div`
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: -0.02em;
  color: #181818;
  width: 150px;
  box-sizing: border-box;
  padding: 0 15px 0 10px;
`;

const InvestedDate = styled.div`
  width: 150px;
  box-sizing: border-box;
  padding: 0 15px 0 10px;
  font-weight: 600;
  font-size: 12px;
  line-height: 150%;
  color: #333333;
`;

const LinkBlock = styled.div`
  width: 55px;
  height: 100%;
  display: flex;
  > a {
    margin: auto;
  }
`;

const AssetLink = styled(Link)`
  width: 24px;
  height: 24px;
  background: ${({ theme: { infoButtonBg } }) => infoButtonBg};
  > svg {
    path {
      stroke: ${({ theme: { primary } }) => primary};
    }
  }
  border-radius: 2px;
  display: flex;
  > svg {
    margin: auto;
    width: 8px;
    height: 8px;
    transform: rotate(180deg);
    path {
      stroke: ${({ theme: { primary } }) => primary};
    }
  }
`;

export const ScrollContainer = styled.div`
  height: ${({ fullHeight }) => (fullHeight ? "fit-content" : "250px")};
  min-height: ${({ minHeight }) => (minHeight ? `${minHeight}px` : 0)};
  padding-right: 25px;
  box-sizing: border-box;
  /* transform: translateX(-15px); */
  margin-left: ${({ noTranslate }) => (noTranslate ? 0 : "-15px")};
  width: calc(100% + 15px);
  overflow-y: scroll;
  &&::-webkit-scrollbar-track {
    background-color: ${({ theme: { primaryLight } }) => primaryLight};
    border-radius: 2px;
  }
  &&::-webkit-scrollbar {
    width: 8px;
    border-radius: 2px;
    background-color: ${({ theme: { primaryLight } }) => primaryLight};
  }
  &&::-webkit-scrollbar-thumb {
    background-color: ${({ theme: { primaryMiddle } }) => primaryMiddle};
    border-radius: 2px;
  }
`;

export const TransactionsTD = styled.div`
  text-overflow: ellipsis;
  box-sizing: ${({ boxSizing }) => boxSizing};
  font-family: Muli;
  font-style: normal;
  font-weight: ${({ fWeight }) => (fWeight ? fWeight : "normal")};
  font-size: 12px;
  line-height: 150%;
  letter-spacing: ${({ blue }) => (blue ? "-0.02" : "0.05em")};
  white-space: nowrap;
  overflow: hidden;
  cursor: ${({ cursor }) => cursor};
  text-transform: ${({ lower }) => (lower ? "none" : "uppercase")};
  padding: ${({ padding }) => (padding ? padding : "0 10px 0 15px")};
  /* padding: 0 10px 0 15px; */
  color: ${({ blue, theme: { primary } }) => (blue ? primary : "#333333")};
  width: ${({ width }) => (width ? width : "11%")};
  border-bottom: ${({ borderBottom }) => (borderBottom ? "0.5px solid rgba(98, 128, 218, 0.2); " : "none")};
`;

export const TransactionLink = styled.span`
  cursor: pointer;
  color: ${({ theme: { primary } }) => primary};
  border-bottom: 0.5px solid rgba(98, 128, 218, 0.2);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Amount = ({ movementType, quantity, tokenIdentifier }) => {
  return (
    <Count movementType={movementType}>
      {movementType === "OUTCOME" ? "-" : null} {tokenIdentifier} {numberWithCommas(quantity)}{" "}
      {movementType === "INCOME" ? <OutIcon /> : movementType === "OUTCOME" ? <IncomeIcon /> : null}
    </Count>
  );
};

const Count = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 150%;
  display: flex;
  align-items: center;
  color: ${({ movementType }) =>
    movementType === "INCOME"
      ? "#019D52"
      : movementType === "OUTCOME" || movementType === "REJECTED"
      ? "#FF4343"
      : "#333333"};
`;

export const AnswerWrap = styled.div`
  height: ${({ height }) => height};
  transition: height 0.1s linear;
  overflow: ${({ height }) => (height && height !== "fit-content" ? "hidden" : "visible")};
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: ${({ marginBottom }) => (marginBottom ? marginBottom : "20px")};
  > div {
    margin-bottom: 20px;
    :last-child {
      margin-bottom: 0;
    }
  }
`;
