import React from "react";
import styled from "styled-components";

import { ClockBig } from "./icons-primary";

export const statuses = {
  wait: (props) => <Wait {...props} />,
  online: (props) => <Online {...props} />,
  siteOut: (props) => <SiteOut {...props} />,
};

const Circle = styled.div`
  width: ${({ size = 20 }) => `${size}px`};
  height: ${({ size = 20 }) => `${size}px`};
  display: flex;
  border-radius: 50%;
  background: ${({ bg = "#019D52" }) => bg};
  position: absolute;
  right: ${({ right = 5 }) => `${right}px`};
  bottom: ${({ bottom = 5 }) => `${bottom}px`};
  > svg {
    width: calc(100% - 8px);
    margin: auto;
    height: calc(100% - 8px);
  }
`;

const CircleSmall = styled.div`
  width: calc(100% - 8px);
  height: calc(100% - 8px);
  background: #fff;
  border-radius: 50%;
  margin: auto;
`;

const Online = (props) => <Circle {...props} />;

const SiteOut = (props) => (
  <Circle {...props}>
    <CircleSmall />
  </Circle>
);

const Wait = (props) => (
  <Circle bg="#ffcd0b" {...props}>
    <ClockBig color="#fff" />
  </Circle>
);
