import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Router } from "react-router-dom";
import { Provider } from "react-redux";

import store, { persistor } from "./modules/store";
import history from "./modules/history";

import { PersistGate } from "redux-persist/integration/react";
import { app } from 'modules/notification';

history.listen((...args) => {
  window.scrollTo(0, 0);
  try {
    const { userReducer : { username } } = store.getState();
    app.analytics().logEvent('CHANGE_ROUTE', {
      pathname: args[0].pathname,
      username: username || 'none'
    });
  } catch(error) {
    console.error(error)
  }
});

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <Suspense fallback={<div></div>}>
        <Router history={history}>
          <App />
        </Router>
      </Suspense>
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();
export default store;
