import styled from "styled-components";
import { Link } from "react-router-dom";

export const Bold = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: ${({ fW = "bold" }) => fW};
  font-size: 14px;
  line-height: 135%;
  letter-spacing: 0.05em;
  text-transform: none;
  color: #181818;
  margin: ${({ m = 0 }) => m};
`;

export const BtnsRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  z-index: 4;
  position: relative;
`;

export const CardHeading = styled.h6`
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 135%;
  color: #181818;
  margin: 0 0 18px;
`;

export const CardStatistic = styled.div`
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  background: #ffffff;
  border-radius: 4px;
  padding: ${({ p = "20px 25px" }) => p};
  margin-bottom: 25px;
  box-sizing: border-box;
  overflow: hidden;
`;

export const CenterContainer = styled.div`
  margin: auto;
`;

export const ChartCol = styled.div`
  display: flex;
  margin: ${({ m }) => m};
  flex-direction: column;
  width: ${({ w }) => w};
`;

export const ChartRow = styled.div`
  display: flex;
  width: ${({ w }) => w};
`;

export const DocName = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 135%;
  color: #181818;
  text-transform: none;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  > svg {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
  &:hover {
    > div {
      margin-left: 10px;
      display: flex;
    }
  }
  > span {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100% - 33px);
  }
`;

export const Flex = styled.div`
  width: ${({ width = "100%" }) => width};
  height: ${({ height = "100%" }) => height};
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ mb = 25 }) => `${mb}px`};
  padding: ${({ p = 0 }) => p};
  box-sizing: border-box;
  align-items: center;
`;

export const FolderName = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 135%;
  color: #181818;
  text-transform: none;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  > svg {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
  > svg:first-child {
    width: 12px;
    height: 12px;
    transform: ${({ open }) => (open ? "none" : "rotate(-90deg)")};
  }
  &:hover {
    > div {
      margin-left: 10px;
      display: flex;
    }
  }
  > span {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100% - 55px);
  }
`;

export const Label = styled.h6`
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 135%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: rgba(24, 24, 24, 0.6);
  margin: 0 0 8px;
`;

export const StatisticsWrapper = styled.section`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const TCeil = styled.div`
  height: 50px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  border-right: 1px solid #efefef;
  padding: 0 ${({ p = 20 }) => ` 20px 0  ${p}px`};
  width: ${({ big, middle, full, fixedWidth }) => {
    if (full) return "100%;";
    return big ? "calc(100% - 560px)" : middle ? "200px" : fixedWidth ? fixedWidth : "120px";
  }};

  font-family: Muli;
  font-style: normal;
  font-weight: ${({ lower = false }) => (lower ? "normal" : "bold")};
  font-size: ${({ lower = false }) => (lower ? "14px" : "10px")};
  line-height: 135%;
  letter-spacing: 0.05em;
  text-transform: ${({ lower = false }) => (lower ? "none" : "uppercase")};
  color: rgba(24, 24, 24, 0.4);
  &:last-child {
    border-right: none;
  }
`;

export const TRow = styled.div`
  border-bottom: 1px solid #efefef;
  box-sizing: border-box;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  cursor: ${({ noHover = false }) => (noHover ? "auto" : "pointer")};
  transition: all 0.1s linear;

  background: ${({ active, theme: { quoteBgInvestor } }) => (active ? quoteBgInvestor : "#fff")};
  &:hover,
  &:active {
    background: ${({ noHover = false, theme: { quoteBgInvestor } }) => (noHover ? "#fff" : quoteBgInvestor)};
  }
`;

export const Table = styled.div`
  background: #ffffff;
  border: 1px solid #efefef;
  box-sizing: border-box;
  border-radius: 4px;
  width: 100%;
  overflow: hidden;
`;

export const UserLink = styled(Link)`
  width: 25px;
  height: 25px;
  background: ${({ theme: { primary } }) => primary};
  border-radius: 2px;
  display: flex;
  margin: auto;
  > svg {
    margin: auto;
    width: 14px;
    height: 14px;
  }
`;

export const ViewRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
