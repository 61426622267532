import { createAction } from "redux-act";

import ApiInv from "../../data-room-panel/helpers/investorApi";
import ApiAdmin from "../../data-room-panel/helpers/api";
import { isStateUpdate, isDeepStateUpdate } from "../../helpers/isStateUpdate";

export const setLegalDocomentStatus = createAction("SET LEGAL DOCUMENT STATUS");
export const setInvestorView = createAction("SET INVESTOR MAIN FOLDERS VIEW");
export const setAdminView = createAction("SET ADMIN MAIN FOLDERS VIEW");
export const setInvDealFiles = createAction("SET INVESTOR DEAL FILES");
export const setAdmDealFiles = createAction("SET ADMIN DEAL FILES");

export const setDocInfo = (info, cb) => async dispatch => {
  await dispatch(setLegalDocomentStatus(info));

  cb();
};

export const getInvFoldersView = (
  token,
  errorCb = console.log
) => async dispatch => {
  const view = await ApiInv.getDealsInfo(token).catch(errorCb);
  if (view) {
    if (isStateUpdate("dataRoomReducer", "foldersViewInvestor", view)) {
      dispatch(setInvestorView(view));
    }
  }
};

export const getAdminFoldersView = (
  token,
  errorCb = console.log
) => async dispatch => {
  const view = await ApiAdmin.getAllFiles(token).catch(errorCb);
  if (view) {
    if (isStateUpdate("dataRoomReducer", "foldersViewAdmin", view)) {
      dispatch(setAdminView(view));
    }
  }
};

export const getInvDealFiles = (
  token,
  assetId,
  errorCb = console.log
) => async dispatch => {
  const files = await ApiInv.getDealDocuments(token, assetId).catch(errorCb);
  if (files) {
    const data = { assetId, files };
    if (isDeepStateUpdate("dataRoomReducer", "investorDeals", assetId, files)) {
      dispatch(setInvDealFiles(data));
    }
  }
};

export const getAdmDealFiles = (
  token,
  assetId,
  errorCb = console.log
) => async dispatch => {
  const files = await ApiAdmin.getDealsFiles(token, assetId).catch(errorCb);
  if (files) {
    const data = { assetId, files };
    if (isDeepStateUpdate("dataRoomReducer", "adminDeals", assetId, files)) {
      dispatch(setAdmDealFiles(data));
    }
  }
};
