import MainApi from "helpers/api";

export default class Api {
  static url = MainApi.url;

  static origin = MainApi.origin;

  static createForm(token, data) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/kyc/form`, {
        method: "post",
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (result.status === 200) res(await result.json());
      else {
        rej(await result.json());
      }
    });
  }

  static getForm(token, id) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/kyc/form/${id}`, {
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });

      if (result.status === 200) res(await result.json());
      else {
        rej(await result.json());
      }
    });
  }

  static updateForm(token, id, data) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/kyc/form/${id}`, {
        method: "put",
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (result.status === 200) res(await result.json());
      else {
        rej(await result.json());
      }
    });
  }

  static removeForm(token, id) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/admin/kyc/form/${id}`, {
        method: "delete",
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });

      if (result.status === 200) res(true);
      else {
        rej(await result.json());
      }
    });
  }
}
