import React, { Fragment, useState, useEffect } from "react";

import {
  ModalBackground,
  ModalWrapper,
  ModalHeader,
  Heading,
  InputsWrapper,
  InputRow,
} from "./styled";
import { Btn } from "../../buttons";

import { TextFieldComponent } from "../../forms";
import { ReactComponent as CloseIcon } from "../../../../assets/x-circle.svg";

export const AddUserModal = ({
  onBackdropClick,
  onAddUser,
  representatives,
  user,
  onEditUser,
}) => {
  const [firstName, setFirstName] = useState(user ? user.firstName : "");
  const [lastName, setLastName] = useState(user ? user.lastName : "");
  const [username, setUsername] = useState(user ? user.username : "");
  const [disableEdit, setDisableEdit] = useState(true);

  const onFirstNameInput = (e) => setFirstName(e.target.value);
  const onLastNameInput = (e) => setLastName(e.target.value);
  const onEmailInput = (e) => setUsername(e.target.value);

  const isDisabled = () => {
    return !firstName || !lastName || !username.match(/\S+@\S+\.\S+/);
  };

  const isDisabledOnEdit = () => {
    return (
      !username.match(/\S+@\S+\.\S+/) ||
      !firstName ||
      !lastName ||
      (firstName === (user && user.firstName) &&
        lastName === (user && user.lastName) &&
        username === (user && user.username))
    );
  };

  useEffect(() => {
    if (user) {
      setDisableEdit(
        !!representatives.filter(
          (e) => e.username !== user.username && e.username === username
        ).length
      );
    }
  }, [username]);

  const emailExist = () =>
    !!representatives.find((el) => el.username === username);

  return (
    <ModalBackground onClick={onBackdropClick}>
      <ModalWrapper width="365px" onClick={(e) => e.stopPropagation()}>
        <ModalHeader>
          <Heading>{user ? "Edit User" : "New User"}</Heading>
          <CloseIcon onClick={onBackdropClick} />
        </ModalHeader>
        <InputsWrapper padding="20px 0">
          <InputRow>
            <TextFieldComponent
              label="First Name"
              placeholder="User’s first name"
              defaultValue={firstName}
              onChange={(e) => onFirstNameInput(e)}
            />
          </InputRow>
          <InputRow>
            <TextFieldComponent
              label="Last Name"
              placeholder="User’s last name"
              defaultValue={lastName}
              onChange={(e) => onLastNameInput(e)}
            />
          </InputRow>
          <InputRow>
            <TextFieldComponent
              disabled={user ? user.enabled : false}
              label="Email"
              placeholder="User’s email"
              defaultValue={username}
              onChange={(e) => onEmailInput(e)}
            />
          </InputRow>
        </InputsWrapper>
        <ModalHeader isFooter>
          <Btn hide width={94} onClick={onBackdropClick}>
            Cancel
          </Btn>
          {user ? (
            <Btn
              width={180}
              disabled={isDisabledOnEdit() || disableEdit}
              onClick={() => {
                const selectId = {};
                if (user && user.id) selectId.id = user.id;
                else if (user && user.localId) selectId.localId = user.localId;

                onEditUser({
                  firstName,
                  lastName,
                  username,
                  ...selectId,
                });
                onBackdropClick();
              }}
            >
              Edit User
            </Btn>
          ) : (
            <Btn
              width={180}
              disabled={isDisabled() || emailExist()}
              onClick={() => {
                onAddUser({ firstName, lastName, username });
                onBackdropClick();
              }}
            >
              Add User
            </Btn>
          )}
        </ModalHeader>
      </ModalWrapper>
    </ModalBackground>
  );
};
