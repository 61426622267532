import React, { useState, useEffect } from "react";

import {
  ModalBackground,
  ModalWrapper,
  ModalHeader,
  ModalHeading,
} from "../Offer/styled";
import { Button } from "../Offerings/styled";
import { Row, Col, Loading, BankError } from "../Account/styled";
import { TextFieldComponent } from "../../../admin-layout/forms";
import { ReactComponent as CloseIcon } from "../../../../assets/x-circle.svg";

import Api from "../../../../helpers/api";

export const AddBankAccount = ({
  isOpen,
  onBackDropClick,
  token,
  callBack,
}) => {
  const [account, setAccount] = useState({
    accountNumber: "",
    bankName: "",
    bic: "",
    iban: "",
    accountName: "",
  });

  useEffect(() => {
    const { iban, accountNumber } = account;
    if (iban.length >= 16) {
      setAccount({
        ...account,
        accountNumber: iban.substr(iban.length - 14),
      });
    } else if (iban.length < 16 && accountNumber) {
      setAccount({
        ...account,
        accountNumber: "",
      });
    }

    if (iban && !iban.match(ibanFormat)) {
      setIbanError(true);
    } else if ((ibanError && iban.match(ibanFormat)) || (ibanError && !iban)) {
      setIbanError(false);
    }
  }, [account.iban]);

  const [load, setLoad] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [ibanError, setIbanError] = useState(false);

  const ibanFormat = /[a-zA-Z]{2,2}[0-9a-zA-Z]{0,}[0-9]{14,14}/;

  const onDataChange = (e, name) => {
    const {
      target: { value },
    } = e;
    setAccount({
      ...account,
      [name]: value,
    });
  };

  const onSubmit = async () => {
    if (!account.iban.match(ibanFormat)) {
      setErrorMessage(
        "Incorrect IBAN format. IBAN should contain minimum 16 characters and last 14 should be numbers."
      );
      setError(true);
      return;
    }
    setLoad(true);
    const success = await Api.addBankAccount(token, account).catch((e) => {
      const { message } = e;
      setLoad(false);
      setErrorMessage(
        message.replace("java.lang.IllegalArgumentException: ", "")
      );
      setError(true);
      return;
    });
    if (success) {
      callBack(account);
      setLoad(false);
      onBackDropClick();
    }
  };

  const clearError = () => {
    setError(false);
    setErrorMessage("");
  };

  const { iban, accountName, bic, bankName } = account;
  return (
    isOpen && (
      <ModalBackground onClick={!load && onBackDropClick}>
        {load && <Loading fullHeight />}
        <ModalWrapper width={440} onClick={(e) => e.stopPropagation()}>
          <ModalHeader boxShadow="0px 10px 40px rgba(89, 120, 150, 0.08)">
            <ModalHeading textTransform="none">Add Bank Account</ModalHeading>
            <CloseIcon onClick={onBackDropClick} />
          </ModalHeader>
          <Col width="100%" padding="30px 30px 20px">
            <Row margin="0 0 18px ">
              <TextFieldComponent
                isInvestor
                labelFontSize={10}
                fontSize={14}
                height={42}
                label="Account Name"
                placeholder="Account Name"
                defaultValue={account.accountName}
                onChange={(e) => onDataChange(e, "accountName")}
              />
            </Row>
            <Row margin="0 0 18px ">
              <TextFieldComponent
                isInvestor
                labelFontSize={10}
                fontSize={14}
                height={42}
                label="Bank name"
                placeholder="Bank name"
                defaultValue={account.bankName}
                onChange={(e) => onDataChange(e, "bankName")}
              />
            </Row>
            <Row margin="0 0 18px ">
              <TextFieldComponent
                isInvestor
                labelFontSize={10}
                fontSize={14}
                height={42}
                label="IBAN"
                placeholder="IBAN"
                error={ibanError}
                defaultValue={account.iban}
                onChange={(e) => onDataChange(e, "iban")}
              />
            </Row>
            <Row margin="0 0 24px ">
              <TextFieldComponent
                isInvestor
                labelFontSize={10}
                fontSize={14}
                height={42}
                label="BIC"
                placeholder="BIC"
                defaultValue={account.bic}
                onChange={(e) => onDataChange(e, "bic")}
              />
            </Row>
            <Row>
              <Button
                tTransform="uppercase"
                size={12}
                width={94}
                height={36}
                hide
                onClick={onBackDropClick}
              >
                Cancel
              </Button>
              <Button
                tTransform="uppercase"
                size={12}
                width={180}
                height={36}
                onClick={onSubmit}
                disabled={!iban || !accountName || !bankName || !bic}
              >
                Add
              </Button>
            </Row>
          </Col>
          <BankError
            isOpen={error}
            message={errorMessage}
            onBackdropClick={clearError}
          />
        </ModalWrapper>
      </ModalBackground>
    )
  );
};
