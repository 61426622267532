import React from "react";
import { PieChart } from "react-minimal-pie-chart";
import { CircularProgress } from "@material-ui/core";

import { FlexRow, Column } from "../../../dashBoardCard";
import { Main, TextSub } from "../../DealForm/Step/styled";
import { ChartData, Empty, EmptyCircle } from "../styled";

import theme from "models/themes";

export const Revenue = ({ soldAndUnsold }) => {
  return (
    <Main
      currentHeight="130px"
      width="calc(33.33% - 20px)"
      padding="20px 25px 15px 25px"
    >
      <TextSub
        textAlign="left"
        fontWeight="bold"
        color="#181818"
        marginSize="0 0 14px 0"
      >
        Revenue Breakdown
      </TextSub>
      {!soldAndUnsold ? (
        <CircularProgress size={24} style={{ margin: "auto" }} />
      ) : (
        <FlexRow mBottom="0" justify="flex-start">
          <Column>
            {soldAndUnsold.sold ? (
              <PieChart
                data={[
                  {
                    title: "Sold",
                    value: soldAndUnsold.sold,
                    color: theme.primaryMiddle
                  },
                  {
                    title: "Unsold",
                    value: soldAndUnsold.unsold,
                    color: theme.primaryLight
                  }
                ]}
                style={{
                  width: 62,
                  height: 62
                }}
              />
            ) : (
              <EmptyCircle />
            )}
          </Column>
          <Column width="100%" mLeft={24} justify="center">
            {soldAndUnsold.sold ? (
              <ChartData
                sold={soldAndUnsold.sold}
                unsold={soldAndUnsold.unsold}
              />
            ) : (
              <Empty h="62px">No Data Yet</Empty>
            )}
          </Column>
        </FlexRow>
      )}
    </Main>
  );
};
