import React, { useEffect, useState, Fragment, useCallback } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import { Wrapper, Header, BorderBlock, Heading, Col, Card, Row } from "./styled";
import { Button } from "../../../investor-layout/pages/Offerings/styled";
import { icons } from "../../../../models/icons";
import { Loader } from "../../styled/Loader";

//Components
import { CountDown } from "../Investment/CountDown";
import { Raised } from "./Raised";
import { Amount } from "./Amount";
import { BankDetails } from "../Investment/BankDetailsModal";
import { AddBankAccount } from "../Investment/AddBankAccount";
import { PaymentDetails } from "./PaymentDetails";
import { OrderDetails } from "./OrderDetails";
import { Price } from "./Price";
import { PaymentTime } from "./PaymentTime";
import { SignContract } from "./SignContract";

//Error global
import { ErrorMessage } from "../../../admin-layout/errorMessage";
//Api
import Api from "../../../../helpers/api";
//Actions
import { onSetInvestData } from "../../../../modules/dataRedux/dataAction";

const colWidth = 440;
const fullSize = "100%";

const InvestNew = ({
  onSetInvestData,
  token,
  investData,
  id,
  asset,
  fullName,
  asset: {
    assetProperties: { assetName, assetSymbol },
    assetFinancialProperty: { timeOfEnd, baseCurrency, valuation },
    raisedVsToBeRaised,
    intentionToBuy,
  },
  signToolOptions,
  history,
}) => {
  const [step, setStep] = useState(0);
  const [balance, setBalance] = useState(null);
  const [account, setAccount] = useState(null);
  const [convert, setConvert] = useState("");
  const [ready, setReady] = useState(false);
  const [error, setError] = useState(null);
  const [type, setType] = useState("bank");
  const [bankOpen, setBankOpen] = useState(false);
  const [addBankOpen, setAddBankOpen] = useState(false);
  const [accountToPay, setAccountToPay] = useState(null);
  const [paymentResponse, setPaymentResponse] = useState(null);
  const [smallScreen, setSmallScreen] = useState(window.innerWidth < 1280);

  useEffect(() => {
    const onResize = () => {
      setSmallScreen(window.innerWidth < 1280);
    };

    window.addEventListener("resize", onResize);

    const getData = async () => {
      const balance = await Api.getCashBalance(token).catch(onError);
      if (balance) setBalance(balance);

      const account = await Api.getBankDetails(token).catch(onError);
      if (account) {
        setAccount(account);
        setAccountToPay(account[0].accountNumber);
      }

      const convert = await Api.convertTokens(token, id, 1, baseCurrency).catch(onError);
      if (convert) setConvert(convert);

      setReady(true);
    };

    getData();

    return () => {
      onSetInvestData(null);
      window.removeEventListener("resize", onResize);
    };
  }, []);

  const onError = (e) => setError(e);

  const onChangeStep = (step) => () => {
    bankOpen && openBank();
    setStep(step);
  };

  const openBank = () => {
    setBankOpen(!bankOpen);
  };

  const openAddAccount = () => {
    setAddBankOpen(!addBankOpen);
  };

  const onAddNewAccount = (number) => {
    getAccounts().then(() => {
      setAccountToPay(number.accountNumber);
      openAddAccount();
      onChangeStep(investData === "INVEST" ? 2 : 1)();
    });
  };

  const getAccounts = async () => {
    const newList = await Api.getBankDetails(token);
    if (newList) setAccount(newList);
  };

  const onPay = (res) => {
    setPaymentResponse(res);
    onChangeStep(3)();
  };

  const RaisedCB = useCallback(
    () => <Raised raisedVsToBeRaised={raisedVsToBeRaised} baseCurrency={baseCurrency} />,
    []
  );

  const PriceCB = useCallback(() => <Price assetSymbol={assetSymbol} convert={convert} />, [convert]);

  const CountDownCB = useCallback(
    () =>
      step == 3 ? (
        <PaymentTime key="payment-time" smallScreen={smallScreen} timeOfEnd={paymentResponse.txEndDate} />
      ) : (
        <CountDown key="countdown" smallScreen={smallScreen} timeOfEnd={timeOfEnd} />
      ),
    [step]
  );

  if (ready) {
    const steps = [
      <Fragment key="step-0">
        <Amount type={type} onChangeType={setType} cashBalance={balance} asset={asset} />
        <Card noPadding height={62}>
          <Button
            height={42}
            width={287}
            bold
            size={14}
            margin="10px 0 10px 30px"
            onClick={type === "bank" ? openBank : onChangeStep(investData === "INVEST" ? 2 : 1)}
            disabled={type === "cash" && balance[baseCurrency] < intentionToBuy * valuation}
          >
            NEXT STEP
          </Button>
        </Card>
      </Fragment>,
      <SignContract
        key="step-1"
        asset={asset}
        fullName={fullName}
        token={token}
        assetId={id}
        onNextStep={onChangeStep(2)}
        signToolOptions={signToolOptions}
      />,
      <PaymentDetails
        key="step-2"
        asset={asset}
        account={account ? account.find((a) => a.accountNumber === accountToPay) : ""}
        fullName={fullName}
        token={token}
        assetId={id}
        onPay={onPay}
        onError={setError}
        type={type}
        cashBalance={balance}
        history={history}
      />,
      <OrderDetails
        key="step-3"
        asset={asset}
        res={paymentResponse}
        token={token}
        assetId={id}
        history={history}
        onError={setError}
      />,
    ];

    return (
      <Fragment>
        <Wrapper>
          <Header>
            <BorderBlock>
              <Button
                transparent
                height={36}
                size={12}
                width={176}
                onClick={step ? onChangeStep(0) : () => history.push(`/investor/offerings/companies/${id}`)}
              >
                {icons.left}
                Back {!step && "to Deal"}
              </Button>
            </BorderBlock>
            <Heading>Invest in {assetName}</Heading>
          </Header>
          {smallScreen ? (
            <Row width={fullSize} margin="0 0 30px" justify="space-between">
              <Col width={`calc(${fullSize} - ${colWidth + 30}px)`}>
                {RaisedCB()}
                {PriceCB()}
              </Col>
              <Col height={fullSize} width={`${colWidth}px`}>
                {CountDownCB()}
              </Col>
            </Row>
          ) : null}
          <Col width={smallScreen ? fullSize : `calc(${fullSize} - ${colWidth + 30}px)`}>{steps[step]}</Col>
          {smallScreen ? null : (
            <Col width={`${colWidth}px`}>
              {CountDownCB()}
              {RaisedCB()}
              {PriceCB()}
            </Col>
          )}
        </Wrapper>

        {/* Bank choose */}
        <BankDetails
          bankDetails={account}
          isOpen={bankOpen}
          history={history}
          onSubmit={onChangeStep(investData === "INVEST" ? 2 : 1)}
          onBackDropClick={openBank}
          callBack={setAccountToPay}
          onAddNewAccount={openAddAccount}
        />
        {/* Add new account */}
        <AddBankAccount
          isOpen={addBankOpen}
          onBackDropClick={openAddAccount}
          token={token}
          callBack={onAddNewAccount}
        />
        {error && <ErrorMessage er={error} onClose={() => setError(null)} />}
      </Fragment>
    );
  } else {
    return <Loader />;
  }
};

export default connect(
  (
    { userReducer: { token, fullName }, dataReducer: { assets, investData, AMSettings } },
    {
      match: {
        params: { id },
      },
    }
  ) => ({
    token,
    investData,
    id,
    fullName,
    asset: assets.find((a) => a.linearId === id),
    signToolOptions: AMSettings.signToolOptions,
  }),
  { onSetInvestData }
)((props) =>
  props.investData && props.asset ? (
    <InvestNew {...props} />
  ) : (
    <Redirect to={`/investor/offerings/companies/${props.id}`} />
  )
);
