import styled from "styled-components";
import React from "react";

const Card = styled.div`
  width: 100%;
  background: #ffffff;
  border: 1px solid #f0f0f0;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 25px 0;
  margin-bottom: 15px;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: ${({ width }) => (width ? width : "100%")};
  align-items: center;
  margin: ${({ margin }) => margin};
`;

const StepCol = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Circle = styled.div`
  margin-bottom: 10px;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  border: 1px solid ${({ active, theme: { primary } }) => (active ? primary : "#dfdfdf")};
  box-sizing: border-box;
  border-radius: 4px;
  border-radius: 50%;
  position: relative;
  &:before {
    content: "";
    display: ${({ active }) => (active ? "block" : "none")};
    background: ${({ theme: { primary } }) => primary};
    width: 14px;
    height: 14px;
    border-radius: 50%;
  }
  &:after {
    content: "";
    display: ${({ last }) => (last ? "none" : "block")};
    width: 123px;
    height: 1px;
    background: #dfdfdf;
    position: absolute;
    right: -124px;
  }
`;

const StepTxt = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 135%;
  text-align: center;
  color: ${({ active }) => (active ? "#181818" : "rgba(24, 24, 24, 0.6)")};
  width: 82px;
`;

export const Stepper = ({ step }) => {
  return (
    <Card>
      <Row>
        <StepCol>
          <Circle active={step >= 0}></Circle>
          <StepTxt active={step >= 0}>1. Contract Signature</StepTxt>
        </StepCol>
        <StepCol>
          <Circle active={step >= 1}></Circle>
          <StepTxt active={step >= 1}>2. Payment Details</StepTxt>
        </StepCol>
        <StepCol>
          <Circle last active={step >= 2}></Circle>
          <StepTxt active={step >= 2}>3. Payment Received</StepTxt>
        </StepCol>
      </Row>
    </Card>
  );
};

export const WaitingModal = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1003;
  background: rgba(0, 0, 0, 0.15);
`;

export const DocuSign = styled.iframe`
  min-height: 100%;
  width: 100vw;
  position: absolute;
  top: 0;
  border: none;
  margin: 0 auto;
`;

export const FrameBg = styled.section`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  z-index: 101;
`;
