export const Navigation = [
  {
    text: "Dashboard",
    link: "/admin/full-dashboard",
    re: /\/admin\/full-dashboard$/,
  },
  {
    text: "Assets",
    link: "/admin/deals/new_deal",
    re: /(\/admin\/deals\/(existed_deal|new_deal|unfinished_deal|continue|create(\/|)(step|full|))|\/admin\/(dashboard|deals\/edit|send-emails)\/[0-9a-z]+)/,
  },
  {
    text: "Users",
    link: "/admin/users",
    re: /\/admin\/user(s|)(\/.{0,}|)$/,
  },
  {
    text: "KYC",
    link: "/admin/kyc/list",
    re: /\/admin\/(kyc\/(list|form-settings\/.+)|((kyc-user|kyc-form|kyc-form-view)\/.+))$/,
  },
  {
    text: "Connect",
    link: "/admin/connect",
    re: /\/admin\/connect$/,
  },
  {
    text: "Data Room",
    link: "/admin/data-room/all",
    re: /\/admin\/data-room.+$/,
  },
  {
    text: "Q&A",
    link: "/admin/question-and-answer/all",
    re: /\/admin\/question-and-answer.+$/,
  },
];

export const SponsorNavigation = [
  {
    text: "Dashboard",
    link: "/admin/full-dashboard",
    re: /\/admin\/full-dashboard$/,
  },
  {
    text: "Assets",
    link: "/admin/deals/new_deal",
    re: /(\/admin\/deals\/(existed_deal|new_deal|create(\/|)(step|full|))|\/admin\/(dashboard|deals\/edit)\/[0-9a-z]+)/,
  },
  {
    text: "Data Room",
    link: "/admin/data-room/all",
    re: /\/admin\/data-room.+$/,
  },
  {
    text: "Q&A",
    link: "/admin/question-and-answer/all",
    re: /\/admin\/question-and-answer.+$/,
  },
];

export const SetupNavigation = [
  {
    text: "Payment Setup",
    link: "/admin/payment-setup",
    re: /\/admin\/payment-setup$/g,
  },
  {
    text: "Communication Setup",
    link: "/admin/comunication-setup/email-templates",
    re: /\/admin\/comunication-setup\/email-(templates(|\/.+)|settings|mailing)$/,
  },
];
