import React, { useState, useEffect, Fragment, useRef } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { CircularProgress } from "@material-ui/core";

import Api from "../../../../helpers/api";
import { formatCount } from "../../../../helpers";
import { ErrorMessage } from "../../errorMessage";
import {
  Header,
  Wrapper,
  Column,
  AvatarBlock,
  Main,
  TextSub,
  FlexRow,
  InfoCol,
  Separator,
  Total,
  TransactionsHeader,
  HeaderTD,
  InvestedItem,
  ScrollContainer,
  TransactionsTD,
  Amount,
  TransactionLink,
  WaitingWrapper,
} from "./styled";
import { ConfirmMessage } from "../../actionMessage";
import { TxInfo } from "../DashBoard/styled";
import { Btn } from "../../buttons";
import { ReactComponent as ArrowLeft } from "../../../../assets/arrowleft.svg";
import { ReactComponent as SharesIcon } from "../../../../assets/briefcase.svg";
import { ReactComponent as SuspendIcon } from "../../../../assets/slash.svg";
import { ReactComponent as EditIcon } from "../../../../assets/edit-small-2.svg";
import { ReactComponent as MessageIcon } from "../../../../assets/message-square.svg";
import { DynamicRepresentatives } from "./DynamicRepresentatives";
import { DynamicSection } from "./DynamicSection";
import { Documents } from "components/admin-layout/pages/UserInfo/documents";
import { UploadedDocuments } from "components/admin-layout/pages/UserInfo/uploadedDocuments";

const InstitutionInfo = ({
  token,
  history,
  assets,
  match: {
    params: { id },
  },
}) => {
  const [transactions, setTransactions] = useState(null);
  const [investedIn, setInvestedIn] = useState(null);
  const [institution, setInstitution] = useState(null);
  const [load, setLoad] = useState(true);
  const [upload, setUpload] = useState(false);
  const [error, setError] = useState(null);
  const inputRef = useRef(null);
  const [totalInvested, setTotalInvested] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [quesForm, setQuesForm] = useState(null);
  const [scAnswer, setScAnswer] = useState(null);
  const [ccAnswer, setCcAnswer] = useState(null);
  const [uploadedDocs, setUploadedDocs] = useState(null);

  const onChangeConfirm = () => {
    setConfirmOpen(!confirmOpen);
  };

  const getTotalInvested = async (arr) => {
    if (arr.length) {
      let total = 0;
      const baseCurrency = arr[0].currency;

      for (const { invested, currency } of arr) {
        if (currency === baseCurrency) {
          total += invested;
        } else {
          const res = await Api.exchangeCurrency(token, baseCurrency, [
            {
              quantity: invested,
              currency,
            },
          ]).catch((e) => onError(e));
          if (res) {
            total += res;
          }
        }
      }
      setTotalInvested(total);
    }
  };

  const onRemoveInstitution = async () => {
    setUpload(true);

    const del = await Api.deleteInstitution(token, institution.id).catch((e) => onError(e));

    if (del) {
      setUpload(false);
      onChangeConfirm();
      history.push("/admin/users");
    }
  };

  const onError = (e) => {
    setError(e);
    setLoad(false);
    setUpload(false);
    if (confirmOpen) {
      setConfirmOpen(false);
    }
  };

  const renderAssets = (arr) => {
    return arr.map((asset) => (
      <InvestedItem history={history} key={`${asset.assetName}-${asset.date}`} asset={asset} token={token} />
    ));
  };

  const getAssetExtraInfo = (name) => {
    const asset = assets.find((e) => e.assetProperties.assetName === name);
    if (asset)
      return {
        assetSymbol: asset.assetProperties.assetSymbol,
        logo: asset.assetProperties.images.length ? asset.assetProperties.images[0] : null,
      };
    else return {};
  };

  const goToDeal = (_assetName) => {
    assets.forEach((asset) => {
      const { linearId } = asset;
      const { assetName } = asset.assetProperties;
      if (assetName && _assetName) {
        if (assetName === _assetName) {
          history.push(`/admin/dashboard/${linearId}`);
        }
      }
    });
  };

  const renderTransactions = (arr) => {
    if (arr && arr.length) {
      return (
        <Fragment>
          <TransactionsHeader translate={-15}>
            <HeaderTD boxSizing="border-box" width="25%" translate={15} padding="0 25px 0 0">
              action
            </HeaderTD>
            <HeaderTD boxSizing="border-box" width="25%" padding="0 15px 0 10px">
              Deal
            </HeaderTD>
            <HeaderTD boxSizing="border-box" padding="0 15px 0 10px" width="16.6%">
              Amount
            </HeaderTD>
            <HeaderTD boxSizing="border-box" padding="0 15px 0 10px" width="16.6%">
              Shares
            </HeaderTD>
            <HeaderTD boxSizing="border-box" width="16.6%" padding="0 25px 0 0">
              Date
            </HeaderTD>
            <HeaderTD width="55px" boxSizing="border-box"></HeaderTD>
          </TransactionsHeader>
          <ScrollContainer>
            {arr.map((e, i) => {
              const { action, time, quantity, movementType, assetNameMovementFor, soldShares, tokenIdentifier } = e;
              return (
                <TransactionsHeader grey={i % 2 === 0} key={`${action}-${i}`}>
                  <TransactionsTD boxSizing="border-box" width="25%" lower fWeight="bold" translate={15}>
                    {action}
                  </TransactionsTD>
                  <TransactionsTD boxSizing="border-box" width="25%" lower>
                    <TransactionLink
                      onClick={() => {
                        goToDeal(assetNameMovementFor);
                      }}
                    >
                      {assetNameMovementFor}
                    </TransactionLink>
                  </TransactionsTD>
                  <TransactionsTD boxSizing="border-box" width="16.6%">
                    <Amount movementType={movementType} quantity={quantity} tokenIdentifier={tokenIdentifier} />
                  </TransactionsTD>
                  <TransactionsTD boxSizing="border-box" padding="0 15px 0 22px" width="16.6%">
                    {soldShares && `${soldShares.toFixed(2)} %`}
                  </TransactionsTD>
                  <TransactionsTD boxSizing="border-box" width="16.6%" fWeight="bold">
                    {moment(time).format("DD/MM/YYYY")}
                  </TransactionsTD>
                  <TransactionsTD width="55px" boxSizing="border-box">
                    <TxInfo
                      tx={e}
                      shareName={getAssetExtraInfo(assetNameMovementFor).assetSymbol}
                      image={getAssetExtraInfo(assetNameMovementFor).logo}
                      token={token}
                    />
                  </TransactionsTD>
                </TransactionsHeader>
              );
            })}
          </ScrollContainer>
        </Fragment>
      );
    }
  };

  useEffect(() => {
    const getInitial = async () => {
      const inst = await Api.getInstitutionById(token, id).catch(onError);

      if (inst) {
        setInstitution(inst);

        const answerInfo = await Api.getQuestionnaireAnswers(token, id).catch((e) => setError(e));
        if (answerInfo) {
          const form = JSON.parse(answerInfo.answerJson);
          setQuesForm(form);
        }

        const scInfo = await Api.getSCAnswers(token, id).catch((e) => setError(e));
        if (scInfo) {
          const scForm = JSON.parse(scInfo.answerJson);
          setScAnswer(scForm);
          console.log("SC", scForm);
        }

        const ccInfo = await Api.getCCAnswers(token, id).catch((e) => setError(e));
        if (ccInfo) {
          let ccForm;
          if (ccInfo.formType === "PROFESSIONAL_CLIENT_CC_FORM") {
            ccForm = JSON.parse(ccInfo.answerPFJson);
          } else ccForm = JSON.parse(ccInfo.answerEPFJson);
          setCcAnswer(ccForm);
          console.log("CC", ccForm);
        }

        const invested = await Api.getInstitutionInvested(token, inst.id).catch(onError);

        const transactions = await Api.getInstitutionTransaction(token, inst.id).catch(onError);

        if (invested && transactions) {
          setTransactions(transactions);
          setInvestedIn(invested);
          getTotalInvested(invested);
          setLoad(false);
        }
      }
    };
    getInitial();
  }, []);

  const isSuspend = () => {
    if (institution) {
      const { users } = institution;
      return users.some((user) => user.enabled);
    } else {
      return false;
    }
  };

  const onOpenFileDialog = (e) => {
    e.stopPropagation();
    if (inputRef && inputRef.current) {
      inputRef.current.click();
    }
  };

  const onAddLogo = async (e) => {
    e.stopPropagation();
    const file = e.target.files[0];
    if (file) {
      setUpload(true);
      const upload = await Api.uploadAttachment(token, file).catch(onError);
      if (upload) {
        const res = await Api.changeLogoInstitution(token, institution.id, upload).catch(onError);
        if (res) {
          setUpload(false);
          setInstitution(res);
        }
      }
    }
  };

  const renderUsers = () => {
    return <DynamicRepresentatives users={institution.users} key={"section-representatives"} />;
  };

  const renderAnswers = () => {
    return quesForm.reverse().map((section) => {
      return <DynamicSection section={section} key={`section-${section.sectionName}`} />;
    });
  };

  if (load || !institution) {
    return <CircularProgress style={{ margin: "auto" }} />;
  } else {
    return (
      <Fragment>
        <Header>
          <Btn transparent onClick={() => history.push("/admin/users")} width={150}>
            <ArrowLeft /> back to users
          </Btn>
          <Btn
            margin="0 12px 0 auto"
            width={140}
            transparent
            disabled
            // onClick={openSharesModal}
          >
            <SharesIcon /> Add shares
          </Btn>
          {/* <Btn
            margin="0 12px 0 0"
            width={140}
            transparent
            disabled
            //   onClick={openMoneyModal}
          >
            <MoneyIcon /> Add Money
          </Btn> */}
          <Btn
            margin="0"
            width={195}
            onClick={() => {
              history.push(`/admin/users/edit-institution/${institution.id}/${id}`);
            }}
          >
            <EditIcon />
            Edit Institution
          </Btn>
        </Header>
        {/* columns */}

        <Wrapper>
          <Column width="calc(100% - 353px)">
            <AvatarBlock
              token={token}
              file={institution.logoFileName}
              hash={institution.logoHash}
              onClick={onOpenFileDialog}
            />
            <input
              onChange={onAddLogo}
              ref={inputRef}
              type="file"
              accept="image/jpeg,image/png"
              style={{ display: "none" }}
            />
            <Main padding="56px 25px 25px">
              <TextSub textAlign="left" fontWeight="bold" color="#181818" fSize={20} marginSize="0 0 20px 0">
                {institution.name}
              </TextSub>
              <FlexRow>
                <InfoCol width={33} heading="Company location" info={institution.countryOfRegistration} />
                <InfoCol width={33} heading="phone" info={institution.phone} />
                <InfoCol width={33} email={true} heading="web-site" info={institution.webSiteUrl} link />
              </FlexRow>
            </Main>
            <Separator />
            <Main>
              <TextSub textAlign="left" fontWeight="bold" color="#181818" marginSize="0 0 20px 0">
                More Information
              </TextSub>
              <FlexRow>
                <InfoCol
                  width={33}
                  heading="Creating date"
                  info={
                    institution.creationDate
                      ? moment(new Date(institution.creationDate)).format("DD MMM, YYYY hh:mm a")
                      : ""
                  }
                />
                <InfoCol
                  width={33}
                  heading="Last action"
                  info={
                    institution.lastActionDate
                      ? moment(new Date(institution.lastActionDate)).format("DD MMM, YYYY hh:mm a")
                      : ""
                  }
                />
                <InfoCol width={33} />
              </FlexRow>
            </Main>
            <Separator />

            {investedIn.length ? (
              <Fragment>
                <Main>
                  <TextSub textAlign="left" fontWeight="bold" color="#181818" marginSize="0 0 25px 0">
                    Invested in
                  </TextSub>
                  <Total>
                    Total Invested: {totalInvested ? formatCount(totalInvested) : null} {investedIn[0].currency}
                  </Total>
                  <TransactionsHeader translate={0}>
                    <HeaderTD boxSizing="border-box" width="calc(100% - 365px)" translate={0} padding="0 25px 0 0">
                      Asset
                    </HeaderTD>
                    <HeaderTD boxSizing="border-box" width="150px" padding="0 15px 0 10px">
                      Invested
                    </HeaderTD>
                    <HeaderTD boxSizing="border-box" padding="0 15px 0 10px" width="150px">
                      Date & Time
                    </HeaderTD>
                    <HeaderTD width="55px" boxSizing="border-box"></HeaderTD>
                  </TransactionsHeader>
                  {renderAssets(investedIn)}
                </Main>
                <Separator />
              </Fragment>
            ) : null}

            <Documents token={token} userId={id} onGetUploaded={setUploadedDocs} />

            {transactions.length ? (
              <Main>
                <TextSub textAlign="left" fontWeight="bold" color="#181818" marginSize="0 0 25px 0">
                  Transactions
                </TextSub>
                {renderTransactions(transactions)}
              </Main>
            ) : null}
          </Column>

          <Column width="323px">
            {investedIn.length ? (
              <Fragment>
                <Main>
                  <TextSub textAlign="left" fontWeight="bold" color="#181818" marginSize="0 0 25px 0">
                    Status
                  </TextSub>
                  <InfoCol
                    heading="portfolio"
                    info={
                      investedIn.length
                        ? `${totalInvested ? formatCount(totalInvested) : null} ${investedIn[0].currency}`
                        : ""
                    }
                    bold
                  />
                </Main>
                <Separator />
              </Fragment>
            ) : null}
            <Main>
              <Btn
                margin={isSuspend() ? "0" : "0 0 12px 0"}
                fullWidth
                onClick={() => {
                  history.push(`/admin/users/institution/send-emails/${id}`);
                }}
              >
                <MessageIcon /> Send E-mail to all users
              </Btn>
              {!isSuspend() ? (
                <Btn error fullWidth onClick={onChangeConfirm}>
                  <SuspendIcon /> Suspend
                </Btn>
              ) : null}
            </Main>
            {institution.users.length ? renderUsers() : null}
            {quesForm ? renderAnswers() : null}
            {/* extra for SC */}
            {scAnswer && scAnswer.usBornProve ? <DynamicSection SC={scAnswer} token={token} /> : null}
            {/* extra for CC */}
            {ccAnswer ? <DynamicSection CC={ccAnswer} token={token} /> : null}
            {uploadedDocs && <UploadedDocuments data={uploadedDocs} token={token} userId={id} />}
          </Column>
        </Wrapper>
        {upload ? (
          <WaitingWrapper>
            <CircularProgress style={{ margin: "auto" }} />
          </WaitingWrapper>
        ) : null}
        {/* modals */}
        {error ? <ErrorMessage er={error} onClose={() => setError(null)} /> : null}
        <ConfirmMessage
          heading="Delete institution"
          isOpen={confirmOpen}
          onBackDpopClick={onChangeConfirm}
          message={`Are you sure to delete <b>${institution.name}</b> ?`}
          onAction={onRemoveInstitution}
        />
      </Fragment>
    );
  }
};

export default connect(
  ({ userReducer: { token }, dataReducer: { assets } }) => ({
    token,
    assets,
  }),
  null
)(InstitutionInfo);
