import MainApi from "helpers/api";

export default class Api {
  static url = MainApi.url;

  static origin = MainApi.origin;

  static getForm(token, assetId = "") {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/kyc/answer${assetId && `?assetId=${assetId}`}`, {
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });

      if (result.status === 200) res(await result.json());
      else {
        rej(await result.json());
      }
    });
  }

  static uploadAttachment(token, file) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/kyc/attachment`, {
        method: "post",
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
        },
        body: file,
      });

      if (result.status === 200) res(await result.json());
      else {
        rej(await result.json());
      }
    });
  }

  static submitForm(token, jsonForm, idForm) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/kyc/answer`, {
        method: "post",
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          answerJson: jsonForm,
          idForm: idForm,
        }),
      });

      if (result.status === 200) res(await result.text());
      else {
        rej(await result.json());
      }
    });
  }

  static saveToDraft(token, jsonForm, idForm) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${Api.url}/api/kyc/answerDraft`, {
        method: "post",
        headers: {
          OriginVoC: Api.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          answerJson: JSON.stringify(jsonForm),
          idForm: idForm,
        }),
      });

      if (result.status === 200) res(await result.text());
      else {
        rej(await result.json());
      }
    });
  }
}
