import React, { useState, useEffect, useRef, Fragment } from "react";
import styled from "styled-components";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";

import Api from "../../../../helpers/api";
import { optionCreator } from "./helpers/optionsCreator";
import { TextSub, FlexRow, ButtonsRow } from "./styled";
import { Btn } from "../../buttons";
import { ErrorMessage } from "../../errorMessage";

const HR = styled(HighchartsReact)`
  background: red;
  & .highcharts-range-selector-group {
    display: none !important;
  }
`;

export const DynamicLineCard = ({ token, id, period, currency }) => {
  const [width, setWidth] = useState(0);
  const [type, setType] = useState("views");
  const [dynamicChart, setDynamicChart] = useState([]);
  const [chartKey, setChartKey] = useState("minutes-30");
  const [error, setError] = useState(null);

  const ref = useRef(null);

  const onChangeType = (type) => () => {
    setType(type);
  };

  useEffect(() => {
    const getInitial = async () => {
      const _dynamicChart = await Api.getDynamicChart(
        token,
        id,
        period,
        type
      ).catch((e) => {
        setError(e);
      });
      if (_dynamicChart) {
        setDynamicChart(_dynamicChart);
      }
    };
    getInitial();
  }, []);

  useEffect(() => {
    if (dynamicChart) {
      Api.getDynamicChart(token, id, period, type)
        .then((c) => {
          setDynamicChart(c);
          setChartKey(`${type}-${period}`);
        })
        .catch((e) => {
          setError(e);
        });
    }
  }, [type, period]);

  useEffect(() => {
    if (!width && ref && ref.current) {
      setWidth(ref.current.offsetWidth + 10);
    }
  }, []);

  return (
    <Fragment>
      <FlexRow mBottom="0" zIndex="1" align="flex-start">
        <TextSub
          fSize="14"
          textAlign="left"
          fontWeight="bold"
          color="#181818"
          marginSize="0"
        >
          Dynamic Chart
        </TextSub>
        <ButtonsRow>
          <Btn
            width={80}
            transparent={type !== "views"}
            onClick={onChangeType("views")}
          >
            Views
          </Btn>
          <Btn
            width={80}
            transparent={type !== "minutes"}
            onClick={onChangeType("minutes")}
          >
            Minutes
          </Btn>
        </ButtonsRow>
      </FlexRow>

      {/* This is graph */}
      <FlexRow justify="center" ref={ref} relative top="-35">
        <HR
          key={chartKey}
          highcharts={Highcharts}
          options={optionCreator(
            dynamicChart,
            width,
            false,
            period,
            type === "minutes" ? "minutes" : "views"
          )}
          constructorType="stockChart"
        />
      </FlexRow>
      {error ? (
        <ErrorMessage er={error} onClose={() => setError(null)} />
      ) : null}
    </Fragment>
  );
};
