import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import moment from "moment";

import { UserIcon } from "components/common/icons-primary";
import { ReactComponent as DeliveredIcon } from "../../../../assets/delivered.svg";
import { ReactComponent as ReadIcon } from "../../../../assets/Read.svg";
import { ReactComponent as FileIcon } from "../../../../assets/file-11.svg";
import { ReactComponent as Upload } from "../../../../assets/upload-big.svg";

import Api from "../../../../helpers/api";

export const ChatWrapper = styled.section`
  display: flex;
  max-width: 1380px;
  margin: 0 auto;
  justify-content: space-between;
  height: calc(100vh - 225px);
  @media screen and (max-width: 1439px) {
    justify-content: space-around;
  }
`;

export const ChatColumn = styled.div`
  width: ${({ width }) => width};
  height: ${({ height = "auto" }) => height};
  @media screen and (max-width: 1439px) {
    width: ${({ responsive, responsiveBig }) =>
      responsive ? "32%" : responsiveBig ? "calc(67% - 40px)" : ""};
  }
`;

export const Card = styled.div`
  width: 100%;
  height: ${({ height = "auto" }) => height};
  display: flex;
  flex-direction: column;
  background: #ffffff;
  border: 1px solid #f0f0f0;
  box-sizing: border-box;
  border-radius: 4px;
`;

export const SearchBlock = styled.div`
  padding: 20px 25px;
  box-sizing: border-box;
  border-bottom: 1px solid #f6f7fc;
  position: relative;
  > svg {
    position: absolute;
    left: 43px;
    top: 29px;
    path {
      stroke: #8a8a8a;
    }
  }
`;

export const Search = styled.input`
  width: 100%;
  height: 42px;
  background: #ffffff;
  border: 1px solid #efefef;
  box-sizing: border-box;
  border-radius: 4px;
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 135%;
  letter-spacing: -0.02em;
  padding: 0 50px;
  color: #181818;
  outline: none;
  &:placeholder {
    color: #8a8a8a;
  }
`;

export const ThemesCol = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 308px);
  overflow: scroll;
`;

export const ThemeItem = styled(Link)`
  width: 100%;
  height: 82px;
  border-bottom: 1px solid #f6f7fc;
  box-sizing: border-box;
  padding: 10px 25px;
  display: flex;
  align-items: center;
  text-decoration: none;
  position: relative;
  background: ${({ theme: { activeChatBG }, active }) =>
    active ? activeChatBG : "transparent"};
  &:last-child {
    border-bottom: none;
  }
  &::after {
    content: "${({ unread }) => unread}";
    display: ${({ unread }) => (unread ? "flex" : "none")};
    background: ${({ theme: { primary } }) => primary};
    width: 15px;
    height: 15px;
    border-radius: 50%;
    position: absolute;
    top: 20px;
    right: 25px;
    font-family: Muli;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    color: #fff;
    align-items: center;
    justify-content: center;
  }
`;

export const Logo = styled.img`
  width: 52px;
  height: 52px;
  border-radius: 50%;
  margin-right: 15px;
  object-fit: cover;
`;

export const NoLogo = styled.div`
  width: 52px;
  height: 52px;
  background: transparent;
  margin-right: 15px;
`;

export const DealName = styled.h6`
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;
  color: ${({ active = false, theme: { primary } }) =>
    active ? primary : "#181818"};
  margin: 0 0 3px 0;
  transition: color 0.2s linear;
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 67px);
  overflow: hidden;
`;

export const LastMessage = styled.p`
  margin: 0;
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  color: rgba(24, 24, 24, 0.5);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const ChatHeading = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 135%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: rgba(24, 24, 24, 0.6);
`;

export const HeadingLink = styled(Link)`
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 135%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: ${({ theme: { primary } }) => primary};
  margin-left: 5px;
`;

export const Flex = styled.div`
  display: flex;
  svg {
    path {
      stroke: ${({ theme: { primary } }) => primary};
    }
  }
`;

export const ChatHeader = styled.div`
  padding: 20px 25px;
  box-sizing: border-box;
  border-bottom: 1px solid #f6f7fc;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const SubmitBlock = styled.div`
  margin-top: auto;
  padding: 20px 25px;
  box-sizing: border-box;
  border-top: 1px solid #f6f7fc;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const MessageInput = styled.input`
  width: calc(100% - 200px);
  height: 42px;
  border: none;
  color: #181818;
  outline: none;
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 135%;
  letter-spacing: -0.02em;
  &:placeholder {
    color: #8a8a8a;
  }
`;

export const ButtonsBlock = styled.div`
  display: flex;
  align-items: center;
  > svg {
    cursor: pointer;
    width: 18px;
    height: 18px;
    margin-right: 15px;
  }
`;

export const NoChatSelected = styled.div`
  margin: auto;
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 135%;
  color: #181818;
`;

export const MessagesCont = styled.div`
  direction: rtl;
  transform-origin: center;
  transform: rotate(180deg);

  width: calc(100% - 50px);
  margin: 0 auto;
  left: 0;

  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 15px 0 5px;
  &&::-webkit-scrollbar-track {
    background-color: ${({ theme: { primaryLight } }) => primaryLight};
    border-radius: 2px;
  }
  &&::-webkit-scrollbar {
    width: 8px;
    border-radius: 2px;
    background-color: ${({ theme: { primaryLight } }) => primaryLight};
  }
  &&::-webkit-scrollbar-thumb {
    background-color: ${({ theme: { primaryMiddle } }) => primaryMiddle};
    border-radius: 2px;
  }
`;

const ManagerLogo = styled.img`
  width: 42px;
  height: 42px;
  border-radius: 50%;
  margin-right: 14px;
  position: absolute;
  object-fit: cover;
  left: -50px;
`;

const MessageWrapper = styled.div`
  transform-origin: center;
  direction: ltr;
  align-self: ${({ isManager }) => (isManager ? "flex-start" : "flex-end")};
  margin-right: 10px;
  position: relative;
  z-index: 101;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  transform: ${({ translate }) => `translateX(${translate}px) rotate(180deg)`};
  /* transition: all 0.2s linear; */
  &:last-of-type {
    margin-bottom: 0;
  }
`;

const MessageBg = styled.div`
  padding: 15px 20px;
  box-sizing: border-box;
  max-width: ${({ isManager }) => (isManager ? "338px" : "380px")};
  background: ${({ isManager, theme: { quoteBgInvestor, primary } }) =>
    isManager ? quoteBgInvestor : primary};
  border-radius: 4px;
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  color: ${({ isManager }) => (isManager ? "#181818" : "#fff")};
  margin-bottom: 6px;
  span {
    font-family: Muli;
  }
`;

const MessageTriangle = styled.div`
  position: absolute;
  bottom: 21px;
  right: -10px;
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 0 0 15px;
  border-color: transparent transparent transparent
    ${({ theme: { primary } }) => primary};
`;

const MessDate = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 150%;
  text-align: right;
  color: #181818;
  opacity: 0.6;
  margin-right: auto;
  display: flex;
  align-items: center;
  > svg {
    margin-right: 4px;
    path {
      stroke: ${({ theme: { primary } }) => primary};
    }
  }
`;

export const Attachment = styled.div`
  width: 100%;
  min-width: ${({ noMinWidth = false }) => (noMinWidth ? "" : "312px")};
  height: 46px;
  background: ${({ theme: { documentBgInvestor } }) => documentBgInvestor};
  border: 1px solid ${({ theme: { primary } }) => primary};
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 12px;
  box-sizing: border-box;
  padding: 5px 17px;
  display: flex;
  align-items: center;
  filter: ${({ wait }) => (wait ? "grayscale(1)" : "none")};
  > svg {
    path {
      stroke: ${({ theme: { primary } }) => primary};
    }
    &:first-child {
      path {
        &:first-child {
          stroke: transparent;
          fill: ${({ theme: { primary } }) => primary};
        }
        fill: transparent;
        stroke: ${({ theme: { primary } }) => primary};
      }
    }
    &:last-child {
      margin-left: auto;
      width: 16px;
      height: 16px;
      cursor: pointer;
      path {
        stroke-width: 4px;
      }
    }
  }
  &:first-child {
    margin-top: 0;
  }
`;

export const FileName_ = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 135%;
  color: #181818;
  margin: 0 10px;
  max-width: calc(100% - 55px);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const AttachmentComponent = ({ token, messageId, hash, fileName }) => {
  const [waitDownload, setWait] = useState(false);

  const downLoadFile = async (hash, fileName) => {
    setWait(true);
    const blob = await Api.getChatFile(token, messageId, hash).catch(() => {
      setWait(false);
    });
    const link = document.createElement("a");
    link.href = blob;
    link.download = fileName;
    link.click();
    setWait(false);
  };
  return (
    <Attachment wait={waitDownload}>
      <FileIcon />
      <FileName_>{fileName}</FileName_>
      <Upload
        onClick={
          !waitDownload
            ? () => downLoadFile(hash, fileName)
            : () => {
                alert();
              }
        }
      />
    </Attachment>
  );
};

export const Message = ({
  message,
  date,
  read,
  offAnimation,
  token,
  messageId,
  attachments
}) => {
  const [translate, setTranslate] = useState(offAnimation ? 30 : -500);

  const files = Object.keys(attachments);

  return (
    <MessageWrapper translate={translate}>
      <MessageBg>
        {message ? <span>{message} </span> : null}
        {files.length
          ? files.map(f => (
              <AttachmentComponent
                key={f}
                token={token}
                hash={f}
                fileName={attachments[f]}
                messageId={messageId}
              />
            ))
          : null}
      </MessageBg>
      <MessageTriangle />
      <MessDate>
        {read ? <ReadIcon /> : <DeliveredIcon />}{" "}
        {moment(date).format("DD.MM.YYYY, hh:mm a")}
      </MessDate>
    </MessageWrapper>
  );
};

export const ManagerMessage = ({
  logo,
  message,
  date,
  offAnimation,
  attachments,
  token,
  messageId
}) => {
  const [translate, setTranslate] = useState(offAnimation ? -65 : 500);

  // useEffect(() => {
  //   setTimeout(() => {
  //     setTranslate(-65);
  //   }, 0);
  // }, []);

  const files = Object.keys(attachments);

  return (
    <MessageWrapper translate={translate} isManager={true}>
      {logo ? (
        <ManagerLogo src={logo} />
      ) : (
        <UserIcon
          style={{ width: 36, height: 36, position: "absolute", left: -50 }}
        />
      )}
      <MessageBg isManager>
        {message ? <span>{message} </span> : null}
        {files.length
          ? files.map(f => (
              <AttachmentComponent
                key={f}
                token={token}
                hash={f}
                fileName={attachments[f]}
                messageId={messageId}
              />
            ))
          : null}
      </MessageBg>
      <MessDate>{moment(date).format("DD.MM.YYYY, hh:mm a")}</MessDate>
    </MessageWrapper>
  );
};

export const IconContainer = styled.div`
  position: relative;
  width: 18px;
  height: 18px;
  margin-right: 15px;
  cursor: pointer;
  > svg {
    width: 100%;
    height: 100%;
  }
  &::after {
    content: "${({ count }) => count}";
    display: ${({ count }) => (count ? "flex" : "none")};
    height: 16px;
    width: 16px;
    font-size: 10px;
    background: ${({ theme: { primary } }) => primary};
    position: absolute;
    border-radius: 50%;
    color: #fff;
    align-items: center;
    justify-content: center;
    font-family: Muli;
    font-style: normal;
    right: -2px;
    top: -8px;
  }
`;
