import React, { Fragment, useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { connect } from "react-redux";

//components
import { HeaderRow, Footer, Statistic, General, Empty, AddSection } from "./components";
import { Section } from "./section.js";
import { countFields } from "./helpers.js";
import BaseSection from "./sectionModel";
import { ConfirmMessagePortal, ErrorMessagePortal } from "components/admin-layout/errorMessage.js";
import Api from "./api";
import { CircularProgress } from "@material-ui/core";

const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ w = "calc(100% - 353px)" }) => w};
  height: fit-content;
  box-sizing: border-box;
`;

const CreateKycForm = ({
  token,
  history,
  match: {
    params: { id },
  },
}) => {
  const emptyForm = { templateName: "", sections: [] };
  const [load, setLoad] = useState(Boolean(id));
  const [form, setForm] = useState({ ...emptyForm });
  const [confirm, setConfirm] = useState(false);
  const [nameError, setNameError] = useState(false);
  const bottomRef = useRef(null);
  const [wait, setWait] = useState(false);
  const [error, setError] = useState(null);
  const [noTemplate, setNoTemplate] = useState(null);
  const { templateName, sections } = form;

  useEffect(() => {
    if (id) {
      Api.getForm(token, id)
        .then((res) => {
          const { rightAnswers, jsonKYCForm } = res;
          const form = JSON.parse(jsonKYCForm);

          //set right values back
          form.sections.forEach((s) => {
            s.fields.forEach((field) => {
              if (Object.keys(rightAnswers).includes(field.id)) {
                field.values = [...rightAnswers[field.id]];
              } else {
                field.values = [];
              }
            });
          });
          setForm(form);
          setLoad(false);
        })
        .catch((e) => {
          setNoTemplate(e);
          setLoad(false);
        });
    }
  }, []);

  const onError = (e = null) => () => setError(e);

  const onChangeConfirm = () => {
    setConfirm(!confirm);
  };

  const onClearForm = () => {
    setForm({ ...emptyForm });
    onChangeConfirm();
  };

  const renderSections = () => {
    return sections.map((s, i) => (
      <Fragment key={s.id}>
        {i === sections.length - 1 ? <div ref={bottomRef} /> : null}
        <Section
          key={s.id}
          defaultValue={s}
          onRemove={onRemoveSection}
          onChange={onChangeSection}
          onCopy={onCopySection}
        />
      </Fragment>
    ));
  };

  const onAddSection = () => {
    const _sections = [...form.sections, new BaseSection(`Section ${form.sections.length + 1}`)];
    setForm({ ...form, sections: _sections });
    onScrollToBottom();
  };

  const onCopySection = (copy) => {
    const _sections = [...form.sections, copy];
    setForm({ ...form, sections: _sections });
    onScrollToBottom();
  };

  const onRemoveSection = (id) => {
    const _sections = [...form.sections];
    const index = _sections.findIndex((s) => s.id === id);
    _sections.splice(index, 1);
    setForm({ ...form, sections: _sections });
  };

  const onChangeSection = (section) => {
    const _sections = [...form.sections];
    const index = _sections.findIndex((s) => s.id === section.id);
    _sections[index] = section;
    setForm({ ...form, sections: _sections });
  };

  const onChangeName = (val) => {
    if (nameError) setNameError(false);
    const _form = { ...form };
    _form.templateName = val;
    setForm(_form);
  };

  const onScrollToBottom = () => {
    if (bottomRef && bottomRef.current) {
      setTimeout(() => {
        bottomRef.current.scrollIntoView({ behavior: "smooth" });
      }, 100);
    }
  };

  const onSubmit = async () => {
    if (!validate()) {
      return;
    } else {
      setWait(true);
      const rightAnswers = {};
      const template = JSON.parse(JSON.stringify(form));
      template.sections.forEach((section, i) => {
        section.fields.forEach((field, j) => {
          const { id, values } = field;
          if (values.length) {
            rightAnswers[id] = [...values];
          }
          template.sections[i].fields[j].values = [];
        });
      });
      const data = { rightAnswers, template: JSON.stringify(template) };

      let res;

      if (id)
        res = await Api.updateForm(token, id, data).catch((e) => {
          setWait(false);
          onError(e)();
        });
      else
        res = await Api.createForm(token, data).catch((e) => {
          setWait(false);
          onError(e)();
        });
      if (res) history.push("/admin/kyc/form-settings/forms");
    }
  };

  const validate = () => {
    let result = true;
    const _form = { ...form };
    //template name validate
    if (!_form.templateName) {
      setNameError(true);
      window.scrollTo({ left: 0, top: 0, behavior: "smooth" });
      result = false;
    }
    //set error for empty fields or unchoosen options
    _form.sections.forEach((section) => {
      section.fields.forEach((field) => {
        if (!field.name) {
          result = false;
          field.error = true;
        } else if (
          (field.type === "Multiple Answers" && !field.values.length) ||
          !field.options.every((o) => o.value)
        ) {
          result = false;
          field.valuesError = true;
        }
      });
    });
    setForm(_form);
    //scroll to highest error input
    if (_form.templateName) {
      setTimeout(() => {
        const inputs = document.getElementsByClassName("field-name-error");
        const options = document.getElementsByClassName("options-error");
        if (inputs.length) {
          window.scrollTo({
            left: 0,
            top: inputs[0].offsetTop - 100,
            behavior: "smooth",
          });
        } else if (options.length) {
          window.scrollTo({
            left: 0,
            top: options[0].offsetTop - 100,
            behavior: "smooth",
          });
        }
      }, 100);
    }
    return result;
  };

  const disabled = () => {
    return !sections.length || (sections.length && sections.some((s) => !s.fields.length));
  };

  if (load) {
    return <CircularProgress style={{ margin: "auto" }} />;
  } else if (noTemplate) {
    return <ErrorMessagePortal er={noTemplate} onClose={() => history.push("/admin/kyc/form-settings/forms")} />;
  } else
    return (
      <Fragment>
        <HeaderRow history={history} />
        <Row>
          <Column>
            {sections.length ? (
              <Fragment>
                {renderSections()}
                <AddSection onAdd={onAddSection} />
              </Fragment>
            ) : (
              <Empty onAdd={onAddSection} />
            )}
            <Footer onSubmit={onSubmit} onCancel={onChangeConfirm} disabled={disabled() || wait} />
          </Column>
          <Column w="323px">
            <General onChange={onChangeName} name={templateName} error={nameError} />
            <Statistic sections={sections.length} fields={countFields(sections)} />
          </Column>
        </Row>

        {confirm ? (
          <ConfirmMessagePortal
            heading="Discard changes"
            message="Are you sure to clear the form?"
            onClose={onChangeConfirm}
            onSubmit={onClearForm}
          />
        ) : null}
        {error && <ErrorMessagePortal er={error} onClose={onError(null)} />}
      </Fragment>
    );
};

export default connect(({ userReducer: { token } }) => ({ token }))(CreateKycForm);
