import React from "react";

import { ReactComponent as CloseIcon } from "../../../../assets/x-circle.svg";
import {
  Background,
  WrapperModal,
  HeaderModal,
  ContentModal,
  HeadingModal,
  Row,
} from "./styled";
import { TextFieldComponent } from "../../../admin-layout/forms";

export const TxInfo = ({
  name,
  onClose,
  amountToTransfer,
  accountName,
  bic,
  bankName,
  iban,
}) => {
  return (
    <Background onClick={onClose}>
      <WrapperModal width="fit-content" onClick={(e) => e.stopPropagation()}>
        <HeaderModal>
          <HeadingModal>{name} Details</HeadingModal>
          <CloseIcon onClick={onClose} />
        </HeaderModal>
        <ContentModal>
          <Row mBot="20px">
            <TextFieldComponent
              width="140px"
              label="Amount to tranfser"
              height={42}
              labelFontSize={10}
              fontSize={12}
              isInvestor
              disabled
              defaultValue={amountToTransfer}
            />
            <TextFieldComponent
              width="calc(100% - 160px)"
              label="Account Holder Name"
              height={42}
              labelFontSize={10}
              fontSize={12}
              isInvestor
              disabled
              defaultValue={accountName}
            />
          </Row>
          <Row mBot="20px">
            <TextFieldComponent
              width="140px"
              label="BIC (SWIFT) CODE"
              height={42}
              labelFontSize={10}
              fontSize={12}
              isInvestor
              disabled
              defaultValue={bic}
            />
            <TextFieldComponent
              width="calc(100% - 160px)"
              label="Beneficiary’s bank"
              height={42}
              labelFontSize={10}
              fontSize={12}
              isInvestor
              disabled
              defaultValue={bankName}
            />
          </Row>
          <Row>
            <TextFieldComponent
              width="100%"
              label="IBAN"
              height={42}
              labelFontSize={10}
              fontSize={12}
              isInvestor
              disabled
              defaultValue={iban}
            />
          </Row>
        </ContentModal>
      </WrapperModal>
    </Background>
  );
};
