import React, { Fragment, useEffect, useMemo, useState } from "react";
import moment from "moment";

import { TableHeader, HeaderTD } from "../../dealTypeCard";
import { ReactComponent as Arrow } from "../../../../assets/chevron-down.svg";
import { TableRow, TableCol } from "../UserList/styled";
import { Status, Notes, Dots, Paper, ExtraLink } from "./styled";
import { ReactComponent as MoreIcon } from "../../../../assets/more-vertical.svg";
import { ReactComponent as SuspendIcon } from "../../../../assets/slash.svg";
import { ReactComponent as EmailAllUsers } from "../../../../assets/email-all-users.svg";
import { Btn } from "../../buttons";
import { ConfirmMessagePortal, ErrorMessagePortal, SuccessMessage } from "components/admin-layout/errorMessage";
import { SendEmail } from "../UserList/SendEmail";
import Api from "../UserKyc/api";

export const Table = ({ history, list = [], token, onUpdate }) => {
  const [confirm, setConfirm] = useState(false);
  const [emailOpen, setEmailOPen] = useState(false);
  const [user, setUser] = useState({});
  const [wait, setWait] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState("");

  const onChangeConfirm = (userName, id) => () => {
    if (userName && id) setUser({ userName, id });
    else setUser({});
    setConfirm(!confirm);
  };

  const onOpenEmail = (userName, email) => () => {
    if (userName && email) setUser({ userName, email });
    else setUser({});
    setEmailOPen(!emailOpen);
  };

  const onReject = async () => {
    setWait(true);
    const rej = await Api.setUserFormStatus(token, "rejectUserKYC", "", user.id).catch((e) => onError(e)());
    if (rej) {
      setUser({});
      setConfirm(false);
      setWait(false);
      onSuccess(rej)();
    }
  };

  const onError = (e = null) => () => {
    setWait(false);
    setError(e);
  };

  const onSuccess = (mess = "") => () => {
    setSuccess(mess);
    if (!mess) onUpdate();
  };

  const renderRows = (arr) => {
    return arr.map(({ submitted, status, note, user: { fullName, id, roleName, username } }) => {
      const rejEnabled = status === "PROCESSING" || status === "MISSING";
      return (
        <TableRow key={id} justify="space-between">
          <HeaderTD boxSizing="border-box" padding="0 0 0 25px" width="210px">
            <TableCol heading={fullName} text={roleName} />
          </HeaderTD>
          <HeaderTD boxSizing="border-box" padding="0 25px" width="130px">
            <TableCol
              heading={moment(new Date(submitted)).format("DD MMM YYYY")}
              text={moment(new Date(submitted)).format("hh:mm a")}
            />
          </HeaderTD>
          <HeaderTD boxSizing="border-box" padding="0 25px" width="160px">
            {status && <Status status={status} />}
          </HeaderTD>
          <HeaderTD boxSizing="border-box" padding="0 25px" width="430px">
            <Notes>{note}</Notes>
          </HeaderTD>
          <HeaderTD boxSizing="border-box" padding="0 25px" width="190px">
            <Btn width={94} margin="0 12px 0 0" onClick={() => history.push(`/admin/kyc-user/${id}`)}>
              more
            </Btn>
            <Dots>
              <MoreIcon />
              <Paper big={rejEnabled}>
                <ExtraLink onClick={onOpenEmail(fullName, username)}>
                  <EmailAllUsers /> send e-mail
                </ExtraLink>
                {rejEnabled ? (
                  <ExtraLink red onClick={onChangeConfirm(fullName, id)}>
                    <SuspendIcon /> reject
                  </ExtraLink>
                ) : null}
              </Paper>
            </Dots>
          </HeaderTD>
        </TableRow>
      );
    });
  };

  const render = useMemo(() => renderRows(list), [list]);

  return (
    <Fragment>
      <TableHeader justify="space-between">
        <HeaderTD boxSizing="border-box" padding="0 0 0 25px" blue width="210px">
          User <Arrow />
        </HeaderTD>
        <HeaderTD boxSizing="border-box" padding="0 25px" width="130px">
          Submitted
        </HeaderTD>
        <HeaderTD boxSizing="border-box" padding="0 25px" width="160px">
          status
        </HeaderTD>
        <HeaderTD boxSizing="border-box" padding="0 25px" width="430px">
          notes
        </HeaderTD>
        <HeaderTD boxSizing="border-box" padding="0 25px" width="190px"></HeaderTD>
      </TableHeader>
      {render}

      {confirm ? (
        <ConfirmMessagePortal
          message={`Are you sure to reject <b>${user.userName}</b> KYC form ?`}
          heading="Reject"
          onClose={onChangeConfirm()}
          onSubmit={onReject}
          load={wait}
        />
      ) : null}
      <SendEmail
        isOpen={emailOpen}
        token={token}
        onBackdropClick={onOpenEmail()}
        mailData={{ userName: user.userName, userEmail: user.email }}
      />
      {error && <ErrorMessagePortal er={error} onClose={onError()} />}
      {success ? <SuccessMessage message={success} onClose={onSuccess("")} /> : null}
    </Fragment>
  );
};
