import React, { useEffect, useState } from "react";
import { CircularProgress } from "@material-ui/core";
import Api from "helpers/api";
import { icons } from "models/icons";
import moment from "moment";

import { Card, Load, StepRow, Circle, StepTxt, DateText } from "./styled";
import { createModel } from "./stepperPreview";

export const Stepper = ({ token, assetId, stepName, isPreview = false }) => {
  const [steps, setSteps] = useState(isPreview ? createModel() : null);

  const getSteps = async () => {
    const res = await Api.getWorkflowSteps(token, assetId);
    if (res) setSteps(res.steps);
  };

  useEffect(() => {
    !isPreview && getSteps();
  }, []);

  useEffect(() => {
    if (steps && stepName) {
      const current = steps.find((s) => s.current);
      current.name !== stepName && getSteps();
    }
  }, [stepName]);

  const renderSteps = () => {
    return steps.map(({ name, current, date, passed }, i) => (
      <StepRow key={`${name}-${i}`}>
        <Circle done={passed} active={current} last={i === steps.length - 1}>
          {passed ? icons.check : null}
        </Circle>
        <StepTxt done={passed} active={current}>
          {i + 1}. {name.toUpperCase()}
        </StepTxt>
        {date && <DateText>{moment(date).format("DD.MM.YYYY,hh:mm A")}</DateText>}
      </StepRow>
    ));
  };

  return (
    <Card padding="25px" key={stepName}>
      {steps ? (
        <div>{renderSteps()}</div>
      ) : (
        <Load>
          <CircularProgress color="inherit" size={20} />
        </Load>
      )}
    </Card>
  );
};
