import styled from "styled-components";

export const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const Main = styled.section`
  position: relative;
  height: ${({ isClosed, currentHeight }) => (isClosed ? "57px" : currentHeight)};
  min-height: ${({ minHeight }) => (minHeight ? `${minHeight}px` : 0)};
  width: ${({ width }) => (width ? width : "100%")};
  overflow: ${({ noHidden }) => (noHidden ? "visible" : "hidden")};
  box-sizing: border-box;
  background: white;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  padding: ${({ padding }) => (padding ? padding : "20px 25px 25px 25px")};
  transition: 0.2s height linear;
  margin: ${({ margin }) => margin};
`;

export const TextSub = styled.p`
  width: ${({ fullWidth }) => (fullWidth ? "100%" : "auto")};
  cursor: ${({ fullWidth }) => (fullWidth ? "pointer" : "auto")};
  text-align: ${({ textAlign = "center" }) => textAlign};
  font-family: "Muli", sans-serif;
  font-size: ${({ fSize }) => (fSize ? `${fSize}px` : "14px")};
  display: inline-block;
  font-weight: ${({ fontWeight = "normal" }) => fontWeight};
  color: ${({ color = "#CACACA" }) => color};
  ${({ marginSize = "" }) => (marginSize ? `margin: ${marginSize};` : "")}
`;

export const InputRow = styled.div`
  margin-bottom: ${({ last }) => (last ? "0" : "20px")};
  display: flex;
  align-items: center;
  width: ${({ width }) => (width ? width : "100%")};
  justify-content: space-between;
  box-sizing: border-box;
  ${({ wrap = false }) => (wrap ? "flex-wrap: wrap;" : "")}
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => width};
  box-sizing: border-box;
  padding: ${({ padding }) => padding};
  align-items: ${({ align }) => align};
  height: ${({ height }) => height};
`;

export const Label = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 135%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: rgba(24, 24, 24, 0.6);
  margin: 0 0 4px;
`;

export const Statistic = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 135%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #181818;
`;

export const SubmitBlock = styled.div`
  padding: 20px 0 0;
  border-top: 1px solid #efefef;
  display: flex;
  margin-top: 20px;
`;

export const ContainerFlex = styled.div`
  position: relative;
  display: flex;
  flex-direction: ${({ row = false }) => (row ? "row" : "column")};
  justify-content: ${({ justify = "flex-start" }) => justify};
  align-items: ${({ align = "flex-start" }) => align};
  ${({ wrap = false }) => (wrap ? "flex-wrap: wrap;" : "")}

  width: ${({ w = "100%" }) => w};
  ${({ h = null }) => (h ? `height: ${h};` : "")}

  padding: ${({ paddingSize = 0 }) => paddingSize};
  margin: ${({ marginSize = 0 }) => marginSize};
  background: ${({ bg = "transparent" }) => bg};
  border-radius: ${({ borderRadius = 0 }) => borderRadius};

  ${({ border = null }) => (border ? `border: ${border};` : "")}
  overflow: ${({ overflow = "hidden" }) => overflow};

  z-index: ${({ zIndex = 0 }) => zIndex};
  transition: height 0.3s linear;
  box-sizing: border-box;
`;

export const BtnBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ width = "270px" }) => width};
  height: 42px;
  background: ${({ white, theme: { quoteBgInvestor } }) => (white ? "#fff" : quoteBgInvestor)};
  border-radius: ${({ white }) => (white ? "4px" : "2px")};
  margin: ${({ margin }) => (margin ? margin : "0")};
  border: ${({ white }) => (white ? "1px solid #EFEFEF" : "")};
  box-sizing: border-box;
`;

export const UploadersCol = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  width: 100%;
`;

export const UploaderLabel = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 135%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  margin-bottom: 12px;
  color: rgba(24, 24, 24, 0.4);
`;

export const EmptyBlock = styled.div`
  width: 270px;
`;
