export const isNoDocuments = (docs) => {
  if (docs) {
    const assetNames = Object.keys(docs);
    return assetNames.every((asset) => {
      console.log(asset);
      return Object.keys(docs[asset]).every(
        (docType) => !docs[asset][docType].length
      );
    });
  }
};

export const filterAssets = (documents) => {
  return Object.keys(documents).filter((asset) => {
    if (!isDealEmpty(documents[asset])) return asset;
  });
};

export const isDealEmpty = (deal) => {
  const types = Object.keys(deal);
  return types.every((type) => !deal[type].length);
};
