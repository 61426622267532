import React, { useState, useEffect, Fragment } from "react";

import { Card, CardHead, Col, Layer, BlockDoc, InfoBox, WrapperTips, TipsText, Triang, FileNameDR } from "./styled";
import { ReactComponent as FolderIcon } from "../../../../assets/folder.svg";
import { ReactComponent as FileIcon } from "../../../../assets/file-text.svg";
import { ReactComponent as DownloadIcon } from "../../../../assets/download.svg";
import { ReactComponent as InfoIcon } from "../../../../assets/info.svg";
import { Button } from "../Offerings/styled";

import Api from "../../../../data-room-panel/helpers/investorApi";

export const DataRoomNew = ({
  token,
  isPreview = false,
  id,
  onRedirect,
  isTradeView = false,
  stepName,
  stretchHeight = false,
}) => {
  const [docs, setDocs] = useState([]);

  useEffect(() => {
    const getDocs = async () => {
      const res = await Api.getStarredFiles(token, id);
      if (res) setDocs(res);
    };

    getDocs();
  }, [stepName]);

  const renderDocs = () => {
    return docs
      .filter((e) => (isTradeView ? e.available : e))
      .map((e) => <File key={`${e.fileName}=${e.folderName}`} file={e} token={token} />);
  };

  if (isPreview || !docs.length) {
    return null;
  } else {
    return (
      <Card height={stretchHeight ? "100%" : "auto"} padding="25px">
        {isPreview ? <Layer /> : null}
        <CardHead margin="0 auto 24px 0">Data Room</CardHead>
        <Col>{renderDocs()}</Col>
        <Button
          height={36}
          fullWidth
          size={12}
          onClick={onRedirect(`/investor/data-room/deal/${id}#${isTradeView ? "trade" : "deal"}`)}
        >
          <FolderIcon style={{ marginRight: 8 }} />
          OPEN DATA ROOM
        </Button>
      </Card>
    );
  }
};

const File = ({ file: { assetId, available, fileName, folderName }, token }) => {
  const onDownLoad = async () => {
    const s3Link = await Api.getS3Link(token, folderName, fileName, assetId);
    const link = document.createElement("a");
    link.download = fileName;
    link.href = s3Link;
    link.click();
  };

  return (
    <BlockDoc active={available}>
      <FileIcon />
      <FileNameDR active={available}>{fileName}</FileNameDR>
      {available ? (
        <DownloadIcon onClick={onDownLoad} />
      ) : (
        <InfoBox>
          <InfoIcon />
          {/* tooltip block */}

          <WrapperTips>
            <TipsText>You will be able to view & download this file after you pass to the next steps.</TipsText>
          </WrapperTips>
          <Triang />
        </InfoBox>
        // <InfoIcon />
      )}
    </BlockDoc>
  );
};
