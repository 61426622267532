import { compose, createStore, combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
// import app from "./app/app.reducer";

// with immer
import { userReducer } from "./userRedux/userReducer";
import { dataReducer } from "./dataRedux/dataReducer";
import { cashReducer } from "./cashRedux/cashReducer";
import { asyncDataReducer } from "./asyncDataRedux/asyncDataReducer";
import { chatReducer } from "./chatRedux/chatReduser";
import { dataRoomReducer } from "./dataRoomRedux/dataRoomReducer";
import { companyInfoReducer } from "./companyInfoRedux/companyInfoReducer";

import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const rootReducer = combineReducers({
  userReducer,
  dataReducer,
  cashReducer,
  asyncDataReducer,
  chatReducer,
  dataRoomReducer,
  companyInfoReducer,
});

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["cashReducer", "companyInfoReducer"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(
  persistedReducer,
  undefined,
  compose(applyMiddleware(thunk.withExtraArgument()), window.devToolsExtension ? window.devToolsExtension() : (f) => f)
);

export let persistor = persistStore(store, {});

window.addEventListener("keydown", (e) => {
  if (e.keyCode === 32) console.log(store.getState());
});
export default store;
