import React, { useState, useEffect, Fragment } from "react";
import { InputRow } from "../Step/Information/styled";
import { TextFieldComponent, SelectComponent, SwitcherComponent } from "../../../forms";
import { Btn } from "../../../buttons";
import { BtnBlock } from "../../../containers/container";
import { ReactComponent as PlusIcon } from "../../../../../assets/plus-circle.svg";
import { ExpandedPanel } from "./ExpandedPanel";

import Api from "../../../../../helpers/api";
import { CreateSponsor } from "../createSponsor";

export const MainInformation = ({
  token,
  onChange,
  defaultValues,
  categories,
  acceptedCurrency,
  dealType,
  countriesOption,
  financialTypes,
  roleName,
  username,
  onError,
  fullName,
}) => {
  const [sponsors, setSponsors] = useState([]);
  const [extendedInfo, setExtendedInfo] = useState([]);
  const [modal, setModal] = useState(false);
  const toggleModal = (status) => () => setModal(status);
  const isEditPage = window.location.pathname.includes("/admin/deals/edit/");

  useEffect(() => {
    (async function () {
      const sponsors = await Api.getSponsors(token);
      setExtendedInfo(sponsors);
      setSponsors(["none", ...sponsors.map(({ fullName }) => fullName)]);
    })();
  }, []);

  const onAddNewSponsor = async (sponsor) => {
    const _sponsors = await Api.getSponsors(token);
    setExtendedInfo(_sponsors);
    setSponsors([...sponsors, sponsor]);
    onChange("managerSponsors")([_sponsors.find((sp) => sp.fullName === sponsor).username]);
  };

  const getDefaultSponsor = () => {
    const _sponsor =
      defaultValues.managerSponsors && defaultValues.managerSponsors[0]
        ? extendedInfo.find((sp) => sp.username === defaultValues.managerSponsors[0])
        : null;
    if (_sponsor) return _sponsor.fullName;
  };

  const getSponsorMail = (fullName) => {
    return extendedInfo.find((sp) => sp.fullName === fullName).username;
  };

  return (
    <Fragment>
      {modal ? (
        <CreateSponsor
          onUpdate={onAddNewSponsor}
          countries={countriesOption}
          token={token}
          onClose={toggleModal(!modal)}
          onError={onError}
        />
      ) : null}
      <ExpandedPanel header="Main Information">
        <InputRow>
          <TextFieldComponent
            autoFocus={!isEditPage}
            width="calc(100% - 400px)"
            label="Asset name"
            placeholder="Write here name of your asset"
            onChange={onChange("assetProperties", "assetName")}
            defaultValue={defaultValues.assetProperties.assetName}
            disabled={isEditPage}
          />
          <TextFieldComponent
            width="100px"
            label="Asset symbol"
            placeholder="XXXX"
            onChange={onChange("assetProperties", "assetSymbol")}
            defaultValue={defaultValues.assetProperties.assetSymbol}
            disabled={isEditPage}
          />
          <SelectComponent
            width="240px"
            label="Category"
            placeholder="Category"
            values={categories || []}
            defaultValue={defaultValues.assetProperties.category}
            onChange={onChange("assetProperties", "category")}
          />
        </InputRow>
        <InputRow
        // last={dealType !== "EXISTED_DEAL"}
        >
          <SelectComponent
            view="filter"
            width="calc(50% - 15px)"
            label="Asset location"
            placeholder="Choose location from list"
            onChange={onChange("assetProperties", "assetLocation")}
            defaultValue={defaultValues.assetProperties.assetLocation}
            values={countriesOption}
          />
          <SelectComponent
            width="calc(50% - 15px)"
            label="Financial deal type"
            placeholder="Financial deal type"
            values={financialTypes}
            onChange={onChange("assetProperties", "dealTypeFinancial")}
            defaultValue={defaultValues.assetProperties.dealTypeFinancial}
          />
        </InputRow>
        <InputRow last={dealType !== "EXISTED_DEAL"}>
          {dealType === "EXISTED_DEAL" ? (
            <SelectComponent
              width="120px"
              label="Base currency"
              placeholder="Base currency"
              values={acceptedCurrency}
              onChange={onChange("assetFinancialProperty", "baseCurrency")}
              defaultValue={defaultValues.assetFinancialProperty.baseCurrency}
            />
          ) : null}
          {roleName !== "SPONSOR" ? (
            <Fragment>
              <SelectComponent
                key={`count-sponsors-${(defaultValues.managerSponsors && defaultValues.managerSponsors.length) || -1}`}
                width={`calc(100% - ${dealType === "EXISTED_DEAL" ? 450 : 300}px)`}
                label="Sponsor"
                placeholder="Choose sponsor from the list"
                values={sponsors}
                onChange={(e) =>
                  onChange("managerSponsors")(e.target.value !== "none" ? [getSponsorMail(e.target.value)] : [])
                }
                defaultValue={getDefaultSponsor()}
              />
              <BtnBlock margin="auto 0  0">
                <Btn width={255} onClick={toggleModal(!modal)} transparent>
                  <PlusIcon />
                  Add new sponsor
                </Btn>
              </BtnBlock>
            </Fragment>
          ) : (
            <Fragment>
              <SelectComponent
                disabled
                key={`count-sponsors-${(defaultValues.managerSponsors && defaultValues.managerSponsors.length) || -1}`}
                width={`calc(100% - ${dealType === "EXISTED_DEAL" ? 450 : 300}px)`}
                label="Sponsor"
                placeholder="Choose sponsor from the list"
                values={[fullName]}
                defaultValue={fullName}
              />
              <BtnBlock margin="auto 0  0">
                <Btn width={255} disabled transparent>
                  <PlusIcon />
                  Add new sponsor
                </Btn>
              </BtnBlock>
            </Fragment>
          )}
        </InputRow>
        {dealType === "EXISTED_DEAL" ? (
          <InputRow last>
            <SwitcherComponent
              defaultValue={defaultValues.assetProperties.currentlyFundraising}
              onChange={onChange("assetProperties", "currentlyFundraising")}
              leftText
              name="Currently Fundraising"
              width="calc(50% - 15px)"
              label="Deal status"
            />
          </InputRow>
        ) : null}
      </ExpandedPanel>
    </Fragment>
  );
};
