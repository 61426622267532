import React, { useState, useEffect, Fragment } from "react";
import moment from "moment";
import Flags from "country-flag-icons/react/3x2";
import { hasFlag } from "country-flag-icons";

import { ModalBackground, Heading } from "../UserList/styled";
import { TransactionsHeader, HeaderTD, TransactionsTD } from "../../dealTypeCard";
import {
  UserStatisticWraper,
  UserStatisticModalHeader,
  SeparatorLine,
  UserRowInStat,
  AvatarCol,
  InfoCol,
  AvatarBlock,
  InfoRow,
  InfoBlock,
  InfoHeader,
  Stage,
  ScrollContainer,
  StatWrapper,
  FlagHolder,
} from "./styled";
import { Btn } from "../../buttons";
import { ReactComponent as CloseIcon } from "../../../../assets/x-circle.svg";
import { ReactComponent as EmailAllUsers } from "../../../../assets/email-all-users.svg";
import Api from "../../../../helpers/api";
import { Countries } from "../../../../models/currenciesModel";
import { CircularProgress } from "@material-ui/core";
import { ErrorMessage } from "../../errorMessage";

export const UserStatistic = ({ token, onBackdropClick, assetId, days, username, toggleModalSendEmail, history }) => {
  const [wait, setWait] = useState(true);
  const [waitDownload, setWaitDownload] = useState(false);
  const [userData, setUserData] = useState(null);
  const [error, setError] = useState(null);

  const downloadXlsx = async (id, days, username) => {
    setWaitDownload(true);
    const res = await Api.getXlsxUsersStatisticsByUser(token, id, days, username).catch((e) => {
      setError(e);
      setWaitDownload(false);
    });
    if (res) {
      const { file, fileName } = res;
      const fileURL = URL.createObjectURL(file);
      const link = document.createElement("a");
      link.href = fileURL;
      link.download = fileName;
      link.click();
      setWaitDownload(false);
    }
  };

  useEffect(() => {
    const getInitial = async () => {
      const data = await Api.getUserStatisticInfo(token, assetId, days, username).catch((e) => {
        setWait(false);
        setError(e);
      });

      if (data) {
        setUserData(data);
        setWait(false);
      }
    };
    getInitial();
  }, []);

  const renderTable = () => {
    const visits = [...userData.visits];
    const formated = visits.sort((a, b) =>
      +moment(a.visitTime).format("x") < +moment(b.visitTime).format("x") ? 1 : -1
    );
    return (
      <Fragment>
        <TransactionsHeader width="calc(100% - 18px)" translate={-15}>
          <HeaderTD translate={15} boxSizing="border-box" width="70%">
            visit time
          </HeaderTD>
          <HeaderTD boxSizing="border-box" width={formated.length > 4 ? "43%" : "39%"}>
            spent time
          </HeaderTD>
        </TransactionsHeader>
        <ScrollContainer maxHeight={200} isScroll={formated.length > 4}>
          {formated.map((e, i) => {
            const { visitTime, spentTime } = e;
            return (
              <TransactionsHeader grey={i % 2 === 0} key={visitTime}>
                <TransactionsTD boxSizing="border-box" width="65%" fWeight="600" padding="0 0 0 15px">
                  {moment(visitTime).format("DD.MM.YYYY, hh:mm A")}
                </TransactionsTD>

                <TransactionsTD boxSizing="border-box" width="50%" padding="0 15px 0 10px" fWeight="600">
                  {spentTime}
                </TransactionsTD>
              </TransactionsHeader>
            );
          })}
        </ScrollContainer>
      </Fragment>
    );
  };

  if (wait) {
    return (
      <ModalBackground>
        <CircularProgress />
      </ModalBackground>
    );
  } else if (userData) {
    const { avgTimeVisit, totalTime, visitsCounter, stage, countryCode, logo, userId } = userData;
    const v = countryCode;
    const Flag = Flags[v];
    return (
      <Fragment>
        <ModalBackground onClick={onBackdropClick}>
          <UserStatisticWraper onClick={(e) => e.stopPropagation()}>
            <UserStatisticModalHeader>
              <Heading>{`${userData.fullName}’s Visits`}</Heading>
              <CloseIcon onClick={onBackdropClick} />
            </UserStatisticModalHeader>
            <SeparatorLine mBottom="20px" />
            <UserRowInStat>
              <AvatarCol>
                <AvatarBlock
                  token={token}
                  file={logo ? logo.fileNameWithExtension : null}
                  hash={logo ? logo.secureHash : null}
                />
              </AvatarCol>
              <InfoCol>
                <InfoRow content="flex-start">
                  <InfoBlock>
                    <InfoHeader>country</InfoHeader>
                    <h3>
                      <FlagHolder>{hasFlag(countryCode) ? <Flag className="flag" /> : null}</FlagHolder>
                      {countryCode}
                    </h3>
                  </InfoBlock>
                  <InfoBlock>
                    <InfoHeader>stage</InfoHeader>
                    <Stage height={20} type={stage}>
                      {stage}
                    </Stage>
                  </InfoBlock>
                  <InfoBlock></InfoBlock>
                </InfoRow>
                <InfoRow content="flex-start">
                  <InfoBlock>
                    <InfoHeader>visits</InfoHeader>
                    <h3>{visitsCounter}</h3>
                  </InfoBlock>
                  <InfoBlock>
                    <InfoHeader>total</InfoHeader>
                    <h3>{totalTime}</h3>
                  </InfoBlock>
                  <InfoBlock>
                    <InfoHeader>avg. per visit</InfoHeader>
                    <h3>{avgTimeVisit}</h3>
                  </InfoBlock>
                </InfoRow>
                <InfoRow>
                  <Btn
                    onClick={() => {
                      onBackdropClick();
                      toggleModalSendEmail({ userEmail: username, userName: userData.fullName });
                    }}
                    width={130}
                  >
                    <EmailAllUsers /> send e-mail
                  </Btn>
                  <Btn transparent width={130} onClick={() => history.push(`/admin/user/${username}/${userId}`)}>
                    Go to profile
                  </Btn>
                </InfoRow>
              </InfoCol>
            </UserRowInStat>
            <SeparatorLine />
            <StatWrapper>{userData && userData.visits.length && renderTable()}</StatWrapper>
            <SeparatorLine />
            <UserStatisticModalHeader isFooter>
              <Btn
                disabled={waitDownload}
                onClick={(e) => {
                  e.stopPropagation();
                  downloadXlsx(assetId, days, username);
                }}
                transparent
                width={180}
              >
                Download XLSX
              </Btn>
              <Btn onClick={onBackdropClick} hide width={94}>
                Cancel
              </Btn>
            </UserStatisticModalHeader>
          </UserStatisticWraper>
        </ModalBackground>
        {waitDownload ? (
          <ModalBackground>
            <CircularProgress />
          </ModalBackground>
        ) : null}
        {error ? <ErrorMessage er={error} onClose={() => setError(null)} /> : null}
      </Fragment>
    );
  } else return null;
};
