import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import { setPaymentDetails } from "../../../../modules/userRedux/userAction";

import { SignatureType } from "./SignTypeModal";

import { Wrapper, Col, Card, Header, BorderBlock, Row, Heading, Text } from "../Investment/styled.js";
import { Stepper } from "./styled";
import { Button } from "../../../investor-layout/pages/Offerings/styled";
import { ReactComponent as BackIcon } from "../../../../assets/arrowleft.svg";
import { ReactComponent as InfoIcon } from "../../../../assets/alert-circle.svg";

import { queryToObject } from "../../../../helpers/";
import { CountDown } from "../Investment/CountDown";
import { Raised } from "../Investment/Raised";
import { Price } from "../Investment/Price";
import { DocuSignFrame } from "./DocuSignFrame";

export const SignContract = ({
  token,
  fullName,
  history,
  paymentData,
  setPaymentDetails,
  match: {
    params: { id },
  },
  signToolOptions,
}) => {
  const [signTypeModalOpen, setSignTypeModalOpen] = useState(false);
  const [frameOpen, setFrameOpen] = useState(false);
  const [frameUrl, setFrameUrl] = useState("");

  useEffect(() => {
    const reciveMessage = (e) => {
      setFrameOpen(false);
      setFrameUrl("");

      if (e.data && e.data.href) {
        const data = queryToObject(e.data.href);
        if (data.event === "signing_complete") {
          setPaymentDetails(data);
          history.push(`/investor/offerings/payment-details/${id}`);
        }
      }
    };
    window.addEventListener("message", reciveMessage, false);
    return () => {
      window.removeEventListener("message", reciveMessage);
    };
  }, []);

  const changeModalStatus = () => setSignTypeModalOpen(!signTypeModalOpen);

  if (paymentData) {
    const {
      price,
      type,
      assetSymbol,
      assetName,
      amount,
      assetId,
      timeOfEnd,
      raised,
      toBeRaised,
      activeAccount,
      baseCurrency,
    } = paymentData;
    return (
      <Wrapper>
        <Header>
          <BorderBlock>
            <Button
              transparent
              height={36}
              width={176}
              size={12}
              onClick={() => history.push(`/investor/offerings/investment/${id}`)}
            >
              <BackIcon style={{ marginRight: 8 }} />
              Back to details
            </Button>
          </BorderBlock>
          <Heading>Invest in {assetName}</Heading>
        </Header>
        <Col width="calc(100% - 470px)">
          <Card>
            <Row margin="0 0 14px 0">
              <Heading>Sign Contract</Heading>
              <Text fSize={18} color="rgba(24, 24, 24, 0.4)" margin="0 0 0 auto">
                1 / 3
              </Text>
            </Row>
            <Row margin="0 0 15px 0">
              <Text lineH="160%" fSize={14} color="#7C7C7C">
                <span>{fullName}, your contract is ready!</span> You are eligible to participate in this offering,
                please access and sign your contract and go to next step to reserve{" "}
                <span>
                  {amount} {assetSymbol}
                </span>{" "}
                shares.
              </Text>
            </Row>
            <Row padding="0">
              <InfoIcon />
              <Text margin="0 0 0 13px" fSize={12} color="#7C7C7C">
                Please sign the contract and make the payment within next 72 hours
              </Text>
            </Row>
          </Card>
          <Card noPadding height={62}>
            <Button height={42} width={287} bold size={14} margin="10px 0 10px 30px" onClick={changeModalStatus}>
              READ AND SIGN CONTRACT
            </Button>
          </Card>
        </Col>
        <Col>
          <Stepper step={0} />
          <CountDown timeOfEnd={timeOfEnd} />
          <Raised transition={0} noTransition raised={raised} toBeRaised={toBeRaised} baseCurrency={baseCurrency} />
          <Price convert={price} assetSymbol={assetSymbol} />
        </Col>

        <SignatureType
          isOpen={signTypeModalOpen}
          onBackDropClick={changeModalStatus}
          onSuccessResponse={(url) => {
            setFrameOpen(true);
            setFrameUrl(url);
          }}
          token={token}
          assetId={assetId}
          currency={baseCurrency}
          amount={amount}
          type={type}
          activeAccount={activeAccount}
          signToolOptions={signToolOptions}
        />

        {frameOpen ? (
          <DocuSignFrame
            url={frameUrl}
            onBackDropClick={() => {
              setFrameOpen(false);
              setFrameUrl("");
            }}
          />
        ) : null}
      </Wrapper>
    );
  } else {
    return <Redirect to="/investor/offerings/all" />;
  }
};

export default connect(
  (
    { userReducer: { token, paymentInfo, fullName }, dataReducer: { AMSettings } },

    {
      match: {
        params: { id },
      },
    }
  ) => {
    return {
      token,
      id,
      paymentData: paymentInfo.find((e) => e.assetId === id),
      fullName,
      signToolOptions: AMSettings.signToolOptions,
    };
  },
  { setPaymentDetails }
)(SignContract);
