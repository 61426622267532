import React, { Fragment, useCallback, useState } from "react";

import {
  MainCol,
  SideCol,
  UserName,
  PageLogo,
  Card,
  Row,
  CardName,
  Text,
  Contract,
  Scroll,
} from "components/setup-layout/common";
import { Btn } from "components/admin-layout/buttons";
import { UserFill } from "components/common/icons-primary";
import { CheckboxComponent } from "components/admin-layout/pages/ViewKycForm/components";

export const SignContract = ({
  match: {
    params: { username },
  },
}) => {
  const [agree, setAgree] = useState(false);

  const onAgree = () => setAgree(!agree);

  const Help = useCallback(
    () => (
      <Btn key="help" className="side-buttons" transparent width={205}>
        I NEED HELP
      </Btn>
    ),
    []
  );

  const User = useCallback(
    () => (
      <UserName key="user" className="side-buttons">
        <UserFill />
        <span>{username}</span>
      </UserName>
    ),
    []
  );
  return (
    <Fragment>
      <SideCol left>{Help()}</SideCol>
      <MainCol>
        <Row m="0 0 52px" a="space-between">
          {Help()}
          <PageLogo description="Please set up your platform" /> {User()}
        </Row>
        <Card>
          <CardName>Sign the Contract</CardName>
          <Text m="0 0 24px">
            Please, sign the contract to use VALK Freemium Platform using checkbox and Button «SIGN» in the end of the
            page
          </Text>
          <Contract>
            <Scroll>
              <CardName m="0 0 12px">VALK Contract</CardName>
              <Text m="0 24px 0 0">
                Folly words widow one downs few age every seven. If miss part by fact he park just shew. Discovered had
                get considered projection who favourable. Necessary up knowledge it tolerably. Unwilling departure
                education is be dashwoods or an. Use off agreeable law unwilling sir deficient curiosity instantly. Easy
                mind life fact with see has bore ten.
                <br />
                <br /> Parish any chatty can elinor direct for former. Up as meant widow equal an share least. Another
                journey chamber way yet females man. Way extensive and dejection get delivered deficient sincerity
                gentleman age. Too end instrument possession contrasted motionless. Calling offence six joy feeling.
                Coming merits and was talent enough far. Sir joy northward sportsmen education. Discovery incommode
                earnestly no he commanded if. Put still any about manor heard. Village did removed enjoyed explain nor
                ham saw calling talking.
                <br />
                <br /> Securing as informed declared or margaret. Joy horrible moreover man feelings own shy. Request
                norland neither mistake for yet. Between the for morning assured country believe. On even feet time have
                an no at. Folly words widow one downs few age every seven. If miss part by fact he park just shew.
                Discovered had get considered projection who favourable. Necessary up knowledge it tolerably. Unwilling
                departure education is be dashwoods or an. Use off agreeable law unwilling sir deficient curiosity
                instantly. Easy mind life fact with see has bore ten.
                <br />
                <br /> Parish any chatty can elinor direct for former. Up as meant widow equal an share least. Another
                journey chamber way yet females man. Way extensive and dejection get delivered deficient sincerity
                gentleman age. Too end instrument possession contrasted motionless. Calling offence six joy feeling.
                Coming merits and was talent enough far. Sir joy northward sportsmen education. Discovery incommode
                earnestly no he commanded if. Put still any about manor heard. Village did removed enjoyed explain nor
                ham saw calling talking.
                <br />
                <br /> Securing as informed declared or margaret. Joy horrible moreover man feelings own shy. Request
                norland neither mistake for yet. Between the for morning assured country believe. On even feet time have
                an no at.{" "}
              </Text>
            </Scroll>
          </Contract>
          <Row j="flex-start" m="30px 0 0">
            <CheckboxComponent
              style={{ marginRight: 12 }}
              value={agree}
              onChange={onAgree}
              label="I have read and understood contract"
            />
            <Text size={16}>I have read and understood contract</Text>
          </Row>
        </Card>
        <Card>
          <Row m="-5px 0 -10px">
            <Btn width={171} hide>
              Cancel
            </Btn>
            <Btn disabled={!agree} width={291}>
              SIGN & PUBLISH PLATFORM
            </Btn>
          </Row>
        </Card>
      </MainCol>
      <SideCol>{User()}</SideCol>
    </Fragment>
  );
};
