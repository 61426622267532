import * as cash from "./cashAction";
import { handleActions } from "../../helpers/immer";

function initState() {
  return {};
}

export const cashReducer = handleActions(
  {
    [cash.setCash]: (draftState, { payload }) => {
      const { secureHash, blob } = payload;
      draftState[secureHash] = blob;
    }
  },
  initState()
);
