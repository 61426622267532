import React, { Fragment, useState, useEffect } from "react";
import { Main, TextSub } from "../styled";
import { InputRow } from "./styled";
import { TextFieldComponent, SelectComponent, SwitcherComponent } from "../../../../forms";
import { Btn } from "../../../../buttons";
import { BtnBlock } from "../../../../containers/container";
import { ReactComponent as PlusIcon } from "assets/plus-circle.svg";

import Api from "helpers/api";
import { CreateSponsor } from "../../createSponsor";

export const MainInformation = ({
  token = "",
  onChange,
  defaultValues,
  categories,
  dealType,
  acceptedCurrency,
  countriesOption,
  financialTypes,
  roleName,
  username,
  onError,
  fullName,
}) => {
  const [sponsors, setSponsors] = useState([]);
  const [extendedInfo, setExtendedInfo] = useState([]);
  const [modal, setModal] = useState(false);
  const toggleModal = (status) => () => setModal(status);

  useEffect(() => {
    (async function () {
      const sponsors = await Api.getSponsors(token);
      setExtendedInfo(sponsors);
      setSponsors([...sponsors.map(({ fullName }) => fullName)]);
    })();
  }, []);

  const onAddNewSponsor = async (sponsor) => {
    const _sponsors = await Api.getSponsors(token);
    setExtendedInfo(_sponsors);
    setSponsors([...sponsors, sponsor]);
    onChange("managerSponsors")([_sponsors.find((sp) => sp.fullName === sponsor).username]);
  };

  const getDefaultSponsor = () => {
    const _sponsor =
      defaultValues.managerSponsors && defaultValues.managerSponsors[0]
        ? extendedInfo.find((sp) => sp.username === defaultValues.managerSponsors[0])
        : null;
    if (_sponsor) return _sponsor.fullName;
  };

  const getSponsorMail = (fullName) => {
    return extendedInfo.find((sp) => sp.fullName === fullName).username;
  };

  console.log(username, roleName);

  return (
    <Fragment>
      {modal ? (
        <CreateSponsor
          onUpdate={onAddNewSponsor}
          countries={countriesOption}
          token={token}
          onClose={toggleModal(!modal)}
          onError={onError}
        />
      ) : null}
      <Main noHidden>
        <TextSub textAlign="left" fontWeight="bold" color="#181818" marginSize="0 0 25px 0">
          Main Information
        </TextSub>
        <InputRow>
          <TextFieldComponent
            width="32.5%"
            label="Asset name"
            placeholder="Write here name of your asset"
            onChange={onChange("assetProperties", "assetName")}
            defaultValue={defaultValues.assetProperties.assetName}
          />

          <TextFieldComponent
            width="11%"
            label="Asset symbol"
            placeholder="XXXX"
            onChange={onChange("assetProperties", "assetSymbol")}
            defaultValue={defaultValues.assetProperties.assetSymbol}
          />
          <SelectComponent
            width="24.7%"
            label="Category"
            values={categories}
            onChange={onChange("assetProperties", "category")}
            defaultValue={defaultValues.assetProperties.category}
          />
          <SelectComponent
            view="filter"
            width="24.7%"
            label="Asset location"
            placeholder="Choose location from list"
            onChange={onChange("assetProperties", "assetLocation")}
            defaultValue={defaultValues.assetProperties.assetLocation}
            values={countriesOption}
          />
        </InputRow>
        {dealType === "EXISTED_DEAL" ? (
          <Fragment>
            <InputRow>
              {roleName === "SPONSOR" ? (
                <Fragment>
                  <SelectComponent
                    disabled
                    width="46%"
                    label="Sponsor"
                    placeholder="Choose sponsor from the list"
                    values={[fullName]}
                    defaultValue={fullName}
                  />
                  <BtnBlock mTop="auto" width="24.7%">
                    <Btn width={255} disabled>
                      <PlusIcon />
                      Add new sponsor
                    </Btn>
                  </BtnBlock>
                </Fragment>
              ) : (
                <Fragment>
                  <SelectComponent
                    width="46%"
                    label="Sponsor"
                    placeholder="Choose sponsor from the list"
                    values={["none", ...sponsors]}
                    onChange={(e) =>
                      onChange("managerSponsors")(e.target.value !== "none" ? [getSponsorMail(e.target.value)] : [])
                    }
                    defaultValue={getDefaultSponsor()}
                  />
                  <BtnBlock mTop="auto" width="24.7%">
                    <Btn width={255} onClick={toggleModal(!modal)} transparent>
                      <PlusIcon />
                      Add new sponsor
                    </Btn>
                  </BtnBlock>
                </Fragment>
              )}
              <SelectComponent
                width="calc(12.5% - 15px)"
                label="Base currency"
                placeholder="Base currency"
                values={acceptedCurrency}
                onChange={onChange("baseCurrency")}
                defaultValue={defaultValues.assetFinancialProperty.baseCurrency}
              />
              <SelectComponent
                width="calc(12.5% - 15px)"
                label="Financial deal type"
                placeholder="Financial deal type"
                values={financialTypes}
                onChange={onChange("assetProperties", "dealTypeFinancial")}
                defaultValue={defaultValues.assetProperties.dealTypeFinancial}
              />
            </InputRow>
            <InputRow last>
              <SwitcherComponent
                defaultValue={defaultValues.assetProperties.currentlyFundraising}
                onChange={onChange("assetProperties", "currentlyFundraising")}
                leftText
                name="Currently Fundraising"
                width="46%"
                label="Deal status"
              />
            </InputRow>
          </Fragment>
        ) : (
          <Fragment>
            <InputRow last>
              {roleName === "SPONSOR" ? (
                <Fragment>
                  <SelectComponent
                    disabled
                    width="46%"
                    label="Sponsor"
                    placeholder="Choose sponsor from the list"
                    values={[fullName]}
                    defaultValue={fullName}
                  />
                  <BtnBlock mTop="auto" width="24.7%">
                    <Btn width={255} disabled>
                      <PlusIcon />
                      Add new sponsor
                    </Btn>
                  </BtnBlock>
                </Fragment>
              ) : (
                <Fragment>
                  <SelectComponent
                    width="46%"
                    label="Sponsor"
                    placeholder="Choose sponsor from the list"
                    values={["none", ...sponsors]}
                    onChange={(e) =>
                      onChange("managerSponsors")(e.target.value !== "none" ? [getSponsorMail(e.target.value)] : [])
                    }
                    defaultValue={getDefaultSponsor()}
                  />
                  <BtnBlock mTop="auto" width="24.7%">
                    <Btn width={255} onClick={toggleModal(!modal)} transparent>
                      <PlusIcon />
                      Add new sponsor
                    </Btn>
                  </BtnBlock>
                </Fragment>
              )}
              <SelectComponent
                width="24.7%"
                label="Financial deal type"
                placeholder="Financial deal type"
                values={financialTypes}
                onChange={onChange("assetProperties", "dealTypeFinancial")}
                defaultValue={defaultValues.assetProperties.dealTypeFinancial}
              />
            </InputRow>
          </Fragment>
        )}
      </Main>
    </Fragment>
  );
};
