import React, { Fragment, useState, useRef, useEffect } from "react";
import {
  CardNew,
  DropZone,
  ImagesContainer,
  SingleImgItem,
} from "../../../../containers/container";
import { CardTitle, GreyText } from "../../../../text";
import { ButtonImg, UploadImg, Btn } from "../../../../buttons";
import { ReactComponent as UploadIcon } from "../../../../../../assets/upload.svg";
import { ReactComponent as DeleteIcon } from "../../../../../../assets/delete-image-icon.svg";
import { ReactComponent as CheckIcon } from "../../../../../../assets/check-image-icon.svg";
import { ReactComponent as MainIcon } from "../../../../../../assets/main-icon.svg";

import { fileEncodeToBase64 } from "../../../../../../helpers";
import { preventDefaultCustom } from "./helpers";

export const Images = ({ onChange }) => {
  const [images, setImages] = useState([]);
  const inputFile = useRef(null);

  useEffect(() => {
    onChange("assetProperties", "images")(images);
  }, [images]);

  const onUpload = () => {
    inputFile && inputFile.current && inputFile.current.click();
  };

  const onAddImage = async (file) => {
    const base64 = await fileEncodeToBase64(file);
    setImages([
      ...images,
      {
        base64,
        file,
        id: `k${Math.random().toString(36).replace("0.", "")}`,
      },
    ]);
  };

  const onSetAsMain = (index) => () => {
    const [main] = images.splice(index, 1);
    setImages([main, ...images]);
  };

  const onRemove = (index) => () => {
    setImages(images.filter((_, i) => i !== index));
  };

  return (
    <Fragment>
      <CardNew
        onDragEnter={preventDefaultCustom}
        onDragOver={preventDefaultCustom}
        onDragLeave={preventDefaultCustom}
        onDrop={preventDefaultCustom}
      >
        <CardTitle>Images</CardTitle>
        <ImagesContainer>
          {images.map((e, i) => (
            <SingleImgItem key={e.id}>
              <DeleteIcon onClick={onRemove(i)} style={{ right: 8 }} />
              {i === 0 ? (
                <MainIcon style={{ right: 34 }} />
              ) : (
                <CheckIcon style={{ right: 34 }} onClick={onSetAsMain(i)} />
              )}
              {e.base64 ? (
                <ButtonImg height="100%" src={e.base64} />
              ) : (
                <UploadImg height="100px" width="calc(50vw / 4 - 20px)" />
              )}
            </SingleImgItem>
          ))}
        </ImagesContainer>
        <DropZone
          onSubmit={(e) => e.preventDefault()}
          enctype="multipart/form-data"
          action=""
          method="post"
          onDragEnter={preventDefaultCustom}
          onDragOver={preventDefaultCustom}
          onDragLeave={preventDefaultCustom}
          onDrop={(e) => {
            preventDefaultCustom(e);
            onAddImage(e.dataTransfer.files[0]);
          }}
        >
          <GreyText>Drop images here or</GreyText>
          <input
            ref={inputFile}
            type="file"
            accept="image/"
            style={{ display: "none" }}
            id="file-dataAndRoom"
            onChange={(e) => {
              if (e.target.files[0]) onAddImage(e.target.files[0]);
            }}
          />
          <Btn margin="0 0 0 10px" width={125} onClick={onUpload}>
            <UploadIcon />
            upload image
          </Btn>
        </DropZone>
      </CardNew>
    </Fragment>
  );
};
