import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";

import Api from "../../../../helpers/api";
import { fileEncodeToBase64 } from "../../../../helpers";
import { Button } from "../Offerings/styled";
import { ReactComponent as EditIcon } from "../../../../assets/edit.svg";
import { ReactComponent as CheckIcon } from "../../../../assets/check-circle.svg";
import { AddBankAccount } from "./AddBankAccount";
import { Upload } from "components/common/icons-primary";

export const Wrapper = styled.section`
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  width: 100%;
  padding: 0 30px;
  max-width: 1440px;
  box-sizing: border-box;
`;

export const Col = styled.div`
  box-sizing: border-box;
  width: ${({ width }) => (width ? width : "324px")};
  padding: ${({ padding }) => padding};
  display: flex;
  flex-direction: column;
  @media (max-width: 1439px) {
    width: ${({ responsive, width }) => (width && responsive ? "calc(77% - 30px)" : responsive ? "23%" : "")};
  }
  @media (max-width: 1280px) {
    width: ${({ responsive, width }) => (width && responsive ? "calc(100% - 220px)" : responsive ? "200px" : "")};
  }
`;

export const Card = styled.div`
  background: #ffffff;
  border: 1px solid #f0f0f0;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  align-items: ${({ align }) => align};
  justify-content: ${({ justify }) => justify};
  padding: ${({ padding }) => padding};
  margin: ${({ margin }) => margin};
  height: ${({ height }) => height};
`;

export const MenuBg = styled.div`
  width: 100%;
  height: 110px;
  background: ${({ theme: { headerInvestor } }) => headerInvestor};
  border: 1px solid #f0f0f0;
  box-sizing: border-box;
  border-radius: 4px;
  position: relative;
`;

const AvatarBlock = styled.div`
  width: 112px;
  height: 112px;
  position: ${({ noAbsolute }) => (noAbsolute ? "static" : "absolute")};
  top: 34px;
  left: 102px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: ${({ disabled }) => (disabled ? "" : "pointer")};
  background: #e5e5e5;
  border-radius: 50%;
  filter: ${({ disabled }) => (disabled ? "grayscale(50%)" : "")};
  @media (max-width: 1439px) {
    left: 32%;
  }
  @media (max-width: 1300px) {
    left: 30%;
  }
  @media (max-width: 1280px) {
    left: 44px;
  }
  &:hover {
    > div.layout-bg {
      display: ${({ disabled }) => (disabled ? "" : "flex")};
    }
  }
`;

const AvatarBg = styled.div`
  position: absolute;
  border-radius: 50%;
  width: 112px;
  height: 112px;
  display: none;
  background: ${({ theme: { primary } }) => primary};
  background-blend-mode: normal, normal;
  opacity: 0.7;
  > svg {
    width: 32px;
    height: 32px;
    margin: auto;
    path {
      stroke: #fff;
    }
  }
`;

const User = styled.img`
  width: 112px;
  height: 112px;
  border-radius: 50%;
  object-fit: cover;
`;

export const UserName = styled.h1`
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 135%;
  text-align: center;
  letter-spacing: -0.02em;
  margin: 56px auto 20px;
  color: #181818;
  height: 24px;
`;

export const StepInfoTxt = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: ${({ big }) => (big ? "600" : "300")};
  font-size: ${({ big }) => (big ? "18px" : "14px")};
  line-height: 150%;
  color: #ffffff;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const Avatar = ({
  token,
  blob,
  updateAvatar = console.log,
  noAbsolute = false,
  isInstitution = false,
  instId,
  disabled = false,
}) => {
  const [load, setLoad] = useState(false);
  const [avatar, setAvatar] = useState(blob);
  const inputRef = useRef(null);

  useEffect(() => setAvatar(blob), [blob]);

  const onFileUpload = async (e) => {
    setLoad(true);
    const file = e.target.files[0];
    if (isInstitution) {
      await Api.uploadAttachment(token, file).then(async (res) => {
        updateAvatar(token, instId, res, true);
        await Api.getAttachment(token, res.secureHash, res.fileNameWithExtension, true).then((blob) => {
          setAvatar(blob);
          setLoad(false);
        });
      });
    } else {
      await Api.uploadAttachment(token, file)
        .then(async ({ fileNameWithExtension, secureHash }) => {
          Api.changeAvatar(token, fileNameWithExtension, secureHash).then((json) => {
            console.log(json);
            setAvatar(fileEncodeToBase64(file));
          });
          await Api.getAttachment(token, secureHash, fileNameWithExtension, true).then((blob) => {
            setAvatar(blob);
            updateAvatar(blob);
            setLoad(false);
          });
        })
        .catch((e) => {
          console.log(e);
          setLoad(false);
        });
    }

    setLoad(false);
  };

  return (
    <AvatarBlock
      onClick={disabled ? undefined : () => inputRef.current.click()}
      noAbsolute={noAbsolute}
      disabled={disabled}
    >
      {load ? <CircularProgress /> : avatar && <User src={avatar} alt="user" />}
      <input onChange={onFileUpload} type="file" ref={inputRef} style={{ display: "none" }} />
      <AvatarBg className="layout-bg">
        <Upload />
      </AvatarBg>
    </AvatarBlock>
  );
};

export const MenuLink = styled(Link)`
  height: 64px;
  width: 100%;
  background: #ffffff;
  border-top: 1px solid #f0f0f0;
  box-sizing: border-box;
  box-shadow: ${({ active }) => (active ? "0px 8px 30px rgba(0, 0, 0, 0.03)" : "none")};
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 4px solid ${({ active, theme: { primary } }) => (active ? primary : "transparent")};
  font-family: Muli;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 135%;
  text-decoration: none;
  color: ${({ active, theme: { primary } }) => (active ? primary : "rgba(24, 24, 24, 0.3)")};
  transition: all 0.2s linear;
  > svg {
    width: 22px;
    height: 22px;
    margin-right: 10px;
    transition: all 0.2s linear;
    path {
      stroke: ${({ active, theme: { primary } }) => (active ? primary : "rgba(24, 24, 24, 0.3)")};
    }
  }
  @media (max-width: 1280px) {
    justify-content: flex-start;
    padding-left: 30px;
  }
`;

export const HeaderBlock = styled.div`
  height: 78px;
  width: 100%;
  background: ${({ theme: { headerInvestor } }) => headerInvestor};
  border: 1px solid #f0f0f0;
  box-sizing: border-box;
  border-radius: 4px;
  margin-bottom: 25px;
  display: flex;
  padding-right: 30px;
  align-items: center;
`;

export const Info = styled.div`
  width: 78px;
  height: 76px;
  display: flex;
  background: #171d32;
  border-radius: 4px;
  margin-right: 24px;
  box-sizing: border-box;
  > svg {
    margin: auto;
    width: 32px;
    height: 32px;
  }
`;

export const CardHeading = styled.h6`
  font-family: Muli;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 135%;
  margin: ${({ noMargin, margin }) => (noMargin ? "0" : margin ? margin : "0 0 20px 0")};
  color: #181818;
`;

export const InputsRow = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ mb }) => (mb ? mb : "24px")};
  &:last-child {
    margin-bottom: 0;
  }
`;

const WaitingWrapper = styled.div`
  position: fixed;
  left: 0;
  top: ${({ full }) => (full ? 0 : "70px")};
  width: 100vw;
  height: ${({ full }) => (full ? "100vh" : "calc(100vh - 70px)")};
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  background: #00000045;
`;

export const Loading = ({ fullHeight = false }) => (
  <WaitingWrapper full={fullHeight}>
    <CircularProgress />
  </WaitingWrapper>
);

export const InfoCard = ({ heading, margin, children }) => {
  return (
    <Card padding="20px 30px 25px" margin={margin}>
      <CardHeading>{heading}</CardHeading>
      {children}
    </Card>
  );
};

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: ${({ margin }) => margin};
  position: ${({ position = "static" }) => position};
  svg {
    path {
      stroke: ${({ theme: { primary } }) => primary};
    }
  }
`;

const BankAccountBlock = styled.div`
  height: 82px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #fff;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  padding: 5px 30px;
  transition: all 0.1s linear;
  &:last-child {
    margin-bottom: 0;
  }
  &:hover {
    border: 1px solid ${({ theme: { primary } }) => primary};
  }
`;

const SmallBtn = styled.button`
  outline: none;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  margin: ${({ margin }) => margin};
  background: ${({ theme: { infoButtonBg } }) => infoButtonBg};
  > svg {
    path {
      stroke: ${({ theme: { primary } }) => primary};
    }
  }
  border-radius: 2px;
  cursor: pointer;
  border: none;
  transition: all 0.2s linear;
  &:hover {
    background: ${({ theme: { primary } }) => primary};
    > svg {
      path {
        stroke: #fff;
      }
    }
  }
`;

const BankName = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 150%;
  color: #333333;
  margin-bottom: 6px;
`;

const BankData = styled.span`
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  color: ${({ black }) => (black ? "#333333" : "rgba(51, 51, 51, 0.4)")};
  margin-right: ${({ black }) => (black ? "0" : "5px")};
`;

export const Account = ({ defaultAcc = false, account }) => {
  const { bankName, bic, iban } = account;
  const [modalOpen, openModal] = useState(false);

  const changeModalStaus = () => {
    openModal(!modalOpen);
  };

  return (
    <BankAccountBlock>
      <Col width="auto">
        <BankName>{bankName}</BankName>
        <Row width="fit-content">
          <div style={{ marginRight: 20 }}>
            <BankData>IBAN: </BankData>
            <BankData black>{iban}</BankData>
          </div>
          <div>
            <BankData>BIC: </BankData>
            <BankData black>{bic}</BankData>
          </div>
        </Row>
      </Col>
      <Col width="175px">
        <Row>
          {defaultAcc ? (
            <Button bold width={125} height={32}>
              DEFAULT
            </Button>
          ) : (
            <SmallBtn margin="0 18px 0 auto">
              <CheckIcon />
            </SmallBtn>
          )}
          <SmallBtn onClick={changeModalStaus}>
            <EditIcon />
          </SmallBtn>
        </Row>
      </Col>
      <AddBankAccount isOpen={modalOpen} action="Edit" onBackDropClick={changeModalStaus} defaultValues={account} />
    </BankAccountBlock>
  );
};

export const InfoText = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 160%;
  width: 390px;
  text-align: center;
  color: #333333;
`;

const Error = styled.div`
  width: 380px;
  background: #ffffff;
  border: 1px solid #ff0000;
  box-sizing: border-box;
  box-shadow: 0px 10px 40px rgba(89, 120, 150, 0.06);
  border-radius: 4px;
  margin: auto;
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 160%;
  color: #333333;
  max-height: 80%;
  padding: 20px;
`;

const ErrorWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
`;

export const BankError = ({ isOpen, onBackdropClick, message }) =>
  isOpen ? (
    <ErrorWrapper onClick={onBackdropClick}>
      <Error>{message}</Error>
    </ErrorWrapper>
  ) : null;

export const SelectorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => width};
  > span {
    font-family: Muli;
    font-weight: bold;
    font-size: 10px;
    line-height: 135%;
    -webkit-letter-spacing: 0.05em;
    -moz-letter-spacing: 0.05em;
    -ms-letter-spacing: 0.05em;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: rgba(24, 24, 24, 0.6);
    margin-bottom: 8px;
  }
`;

export const BtnRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: ${({ width = "" }) => width};
`;

export const BorderBlock = styled.div`
  width: ${({ width }) => width};
  height: 42px;
  margin-top: auto;
  justify-content: center;
  padding-left: 35px;
  background: #ffffff;
  border: 1px solid ${({ active, disabled, theme: { primary } }) => (active && !disabled ? primary : "#dadada")};
  box-sizing: border-box;
  border-radius: 4px;
  position: relative;
  display: flex;
  flex-direction: column;
  margin: ${({ margin }) => margin};
  transition: all 0.3s linear;
  cursor: pointer;
  @media screen and (max-width: 1099px) {
    padding-left: 30px;
  }
  &::before {
    content: "";
    position: absolute;
    top: 11px;
    left: 11px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 1px solid ${({ active, disabled, theme: { primary } }) => (active && !disabled ? primary : "#dadada")};
    @media screen and (max-width: 1099px) {
      left: 8px;
    }
  }
  &::after {
    content: "";
    position: absolute;
    display: ${({ active }) => (active ? "block" : "none")};
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: ${({ disabled, theme: { primary } }) => (!disabled ? primary : "#dadada")};
    top: 16px;
    left: 16px;
    @media screen and (max-width: 1099px) {
      left: 13px;
    }
  }
`;

export const Param = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 135%;
  letter-spacing: -0.02em;
  color: ${({ active, disabled, theme: { textPrimary, textSecondary } }) =>
    active && !disabled ? textPrimary : textSecondary};
  @media screen and (max-width: 1099px) {
    font-size: 13px;
  }
`;

export const AddressLabel = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 135%;
  letter-spacing: -0.02em;
  margin-bottom: 15px;
`;
