import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import { setTradePaymentDetails } from "../../../../modules/userRedux/userAction";

import { SignatureType } from "./SignTypeModal";

import {
  Wrapper,
  Col,
  Card,
  Header,
  BorderBlock,
  Row,
  Heading,
  Text
} from "../Investment/styled.js";
import { Stepper } from "../SignContract/styled";
import { Button } from "../../../investor-layout/pages/Offerings/styled";
import { ReactComponent as BackIcon } from "../../../../assets/arrowleft.svg";
import { ReactComponent as InfoIcon } from "../../../../assets/alert-circle.svg";

import { queryToObject } from "../../../../helpers/";
import { Price } from "../Investment/Price";
import { DocuSignFrame } from "../SignContract/DocuSignFrame";

export const SignContractTrade = ({
  token,
  history,
  soldTrade,
  fullName,
  id,
  txHash,
  setTradePaymentDetails,
  signToolOptions
}) => {
  const [signTypeModalOpen, setSignTypeModalOpen] = useState(false);
  const [frameOpen, setFrameOpen] = useState(false);
  const [frameUrl, setFrameUrl] = useState("");
  const [smallScreen, setSmallScreen] = useState(window.innerWidth < 1280);

  useEffect(() => {
    const onResize = () => {
      setSmallScreen(window.innerWidth < 1280);
    };

    window.addEventListener("resize", onResize);

    const reciveMessage = e => {
      const data = queryToObject(e.data.href);
      if (data && data.event === "signing_complete") {
        setTradePaymentDetails(data);
        history.push(
          `/investor/offerings/payment-details-trade/${id}/${txHash}`
        );
      }
    };
    window.addEventListener("message", reciveMessage, false);
    return () => {
      window.removeEventListener("message", reciveMessage);
      window.removeEventListener("resize", onResize);
    };
  }, []);

  const changeModalStatus = () => setSignTypeModalOpen(!signTypeModalOpen);

  const fullSize = "100%";
  const colWidth = 440;

  const PriceBlock = useCallback(
    (price, currency, assetSymbol, smallScreen) => (
      <Price
        fullHeight={smallScreen}
        convert={price + " " + currency}
        assetSymbol={assetSymbol}
      />
    ),
    [smallScreen]
  );

  const StepperBlock = useCallback(() => <Stepper step={0} />, []);

  if (soldTrade) {
    const {
      assetName,
      price,
      currency,
      tokenIdentifier,
      tokenId,
      owner,
      amount,
      activeAccount,
      type,
      baseCurrency
    } = soldTrade;

    return (
      <Wrapper>
        <Header>
          <BorderBlock>
            <Button
              transparent
              height={36}
              size={12}
              width={176}
              onClick={() =>
                history.push(`/investor/offerings/buy-shares/${id}/${txHash}`)
              }
            >
              <BackIcon style={{ marginRight: 8 }} />
              Back to Details
            </Button>
          </BorderBlock>
          <Heading>
            Buy shares of {assetName} from {owner}
          </Heading>
        </Header>
        {smallScreen ? (
          <Row
            width={fullSize}
            smallScreen={smallScreen}
            justify="space-between"
            align="start"
          >
            <Col width={`calc(${fullSize} - ${colWidth + 30}px)`} height={130}>
              {PriceBlock(price, currency, tokenIdentifier, smallScreen)}
            </Col>
            <Col width={`${colWidth}px`}>{StepperBlock()}</Col>
          </Row>
        ) : null}
        <Col
          width={
            smallScreen ? fullSize : `calc(${fullSize} - ${colWidth + 30}px)`
          }
        >
          <Card>
            <Row margin="0 0 14px 0">
              <Heading>Sign Contract</Heading>
              <Text
                fSize={18}
                color="rgba(24, 24, 24, 0.4)"
                margin="0 0 0 auto"
              >
                1 / 3
              </Text>
            </Row>
            <Row margin="0 0 15px 0">
              <Text lineH="160%" fSize={14} color="#7C7C7C">
                <span>{fullName}, your contract is ready!</span> You are
                eligible to participate in this offering, please access and sign
                your contract and go to next step to reserve{" "}
                <span>
                  {amount} {tokenIdentifier}
                </span>{" "}
                shares.
              </Text>
            </Row>
            <Row padding="0">
              <InfoIcon />
              <Text margin="0 0 0 13px" fSize={12} color="#7C7C7C">
                Please sign the contract and make the payment within next 72
                hours
              </Text>
            </Row>
          </Card>
          <Card noPadding height={62}>
            <Button
              height={42}
              width={287}
              bold
              size={14}
              margin="10px 0 10px 30px"
              onClick={changeModalStatus}
            >
              READ AND SIGN CONTRACT
            </Button>
          </Card>
        </Col>
        {smallScreen ? null : (
          <Col width={`${colWidth}px`}>
            {StepperBlock()}
            {PriceBlock(price, currency, tokenIdentifier, smallScreen)}
          </Col>
        )}

        <SignatureType
          isOpen={signTypeModalOpen}
          onBackDropClick={changeModalStatus}
          onSuccessResponse={url => {
            setFrameOpen(true);
            setFrameUrl(url);
          }}
          token={token}
          tokenId={tokenId}
          currency={baseCurrency}
          amount={amount}
          type={type}
          txHash={txHash}
          seller={owner}
          activeAccount={activeAccount}
          signToolOptions={signToolOptions}
        />
        {frameOpen ? (
          <DocuSignFrame
            key={`iframe-${frameUrl}`}
            url={frameUrl}
            onBackDropClick={() => {
              setFrameOpen(false);
              setFrameUrl("");
            }}
          />
        ) : null}
      </Wrapper>
    );
  } else {
    return <Redirect to="/investor/offerings/users" />;
  }
};

export default connect(
  (
    {
      userReducer: { token, fullName },
      dataReducer: { soldTrade, AMSettings }
    },

    {
      match: {
        params: { id, txHash }
      }
    }
  ) => {
    return {
      token,
      id,
      txHash,
      soldTrade,
      fullName,
      signToolOptions: AMSettings.signToolOptions
    };
  },
  { setTradePaymentDetails }
)(SignContractTrade);
