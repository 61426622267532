import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";

import { AssetsTabs, AssetTypeTab } from "../../dealTypeCard";
import { Wrapper } from "../../main";
import { ReactComponent as TemplateIcon } from "../../../../assets/clipboard.svg";
import { ReactComponent as SettingsIcon } from "../../../../assets/setings.svg";
import { ReactComponent as MailIcon } from "../../../../assets/mail.svg";
import {
  Column,
  Row,
  Separator,
  EditItem,
  Heading,
  Waiting,
  SetupWrapper,
  WaitingFull,
  ColumnContainer,
  CardWrap,
} from "./styled";
import { ExpandedPanel } from "../DealForm/FullFormCards/ExpandedPanel";
import Api from "../../../../helpers/api";
import { TextFieldComponent, SelectComponent, TextAreaComponent } from "../../forms";
import { Btn } from "../../buttons";
import { Header } from "../TemplateSetup/styled";
import { Mailing } from "./Mailing";
import { UpdateEmailSettingsPortal } from "components/admin-layout/errorMessage";

const ComunicationSetup = ({
  history,
  token,
  match: {
    params: { type },
  },
}) => {
  const [templates, setTemplates] = useState(null);
  const [personalSettings, setPersonalSettings] = useState(null);
  const [mailConnection, setMailConnection] = useState(null);
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [updateError, setError] = useState(false);

  useEffect(() => {
    Promise.all([Api.getMailingTemplates(token), Api.getPersonalSettings(token), Api.getMailConnection(token)]).then(
      (data) => {
        setTemplates(data[0]);
        setPersonalSettings(data[1]);
        setMailConnection(data[2]);
      }
    );
  }, []);

  const onChangeData = (e, key, state) => {
    state === "personal"
      ? setPersonalSettings({ ...personalSettings, [key]: e.target.value })
      : setMailConnection({ ...mailConnection, [key]: e.target.value });
  };

  const renderItems = (arr) => {
    return arr.map((e) => {
      const { name, nameVisualization } = e;
      return (
        <EditItem
          key={nameVisualization}
          name={nameVisualization}
          onClick={() => history.push(`/admin/comunication-setup/email-templates/${name}`)}
        />
      );
    });
  };

  const onCloseModal = () => {
    setModalOpen(false);
    setError(false);
  };

  const upDateSettings = async () => {
    setLoading(true);
    await Promise.all([
      Api.getMailConnection(token, true, mailConnection),
      Api.getPersonalSettings(token, true, personalSettings),
    ])
      .then(() => {
        setLoading(false);
        setModalOpen(true);
      })
      .catch(() => {
        setLoading(false);
        setError(true);
        setModalOpen(true);
      });
  };

  return (
    <Wrapper alignItems="center" minHeight="calc(100vh - 130px)" bg="transparent">
      {loading && <WaitingFull />}
      <AssetsTabs>
        <AssetTypeTab to={"/admin/comunication-setup/email-mailing"} active={type === "email-mailing"}>
          <MailIcon />
          Mailing
        </AssetTypeTab>
        <AssetTypeTab to={"/admin/comunication-setup/email-templates"} active={type === "email-templates"}>
          <TemplateIcon />
          E-mail Templates
        </AssetTypeTab>
        <AssetTypeTab to={"/admin/comunication-setup/email-settings"} active={type === "email-settings"}>
          <SettingsIcon />
          E-mail Settings
        </AssetTypeTab>
        <span></span>
      </AssetsTabs>
      {templates && mailConnection && personalSettings ? (
        <Fragment>
          {type === "email-templates" ? (
            <Fragment>
              <Row>
                <ColumnContainer>
                  <Column fullWidth>
                    <CardWrap>
                      <Heading>User creation</Heading>
                      {renderItems(templates.USER_CREATION)}
                    </CardWrap>
                    <CardWrap>
                      <Heading mTop="20px">Account access</Heading>
                      {renderItems(templates.ACCOUNT_ACCESS)}
                    </CardWrap>
                  </Column>
                </ColumnContainer>

                <ColumnContainer>
                  <Column fullWidth>
                    <CardWrap>
                      <Heading>Deal workflow</Heading>
                      {renderItems(templates.DEAL_WORKFLOW)}
                    </CardWrap>
                    <CardWrap>
                      <Heading mTop="20px">KYC</Heading>
                      {renderItems(templates.KYC)}
                    </CardWrap>
                  </Column>
                </ColumnContainer>
              </Row>
            </Fragment>
          ) : type === "email-mailing" ? (
            <Mailing token={token} history={history} />
          ) : (
            <SetupWrapper>
              {modalOpen && <UpdateEmailSettingsPortal error={updateError} onClose={onCloseModal} />}
              <Row>
                <Column>
                  <ExpandedPanel header="General Settings">
                    <Row>
                      <TextFieldComponent
                        label="SMTP Host"
                        width="calc(50% - 15px)"
                        defaultValue={mailConnection.host}
                        onChange={(e) => onChangeData(e, "host", "connection")}
                      />
                      <TextFieldComponent
                        label="SMTP PORT"
                        width="calc(50% - 15px)"
                        defaultValue={mailConnection.port}
                        onChange={(e) => onChangeData(e, "port", "connection")}
                      />
                    </Row>
                    <Separator height={20} />
                    <Row>
                      <TextFieldComponent
                        label="SMTP USERNAME"
                        width="calc(50% - 15px)"
                        defaultValue={mailConnection.mailingUsername}
                        onChange={(e) => onChangeData(e, "mailingUsername", "connection")}
                      />
                      <TextFieldComponent
                        label="SMTP password"
                        width="calc(50% - 15px)"
                        defaultValue={mailConnection.password}
                        type="password"
                        onChange={(e) => onChangeData(e, "password", "connection")}
                      />
                    </Row>
                    <Separator height={20} />
                    <Row>
                      <SelectComponent
                        label="e-mail send to admin"
                        value="All Admins"
                        values={["All Admins"]}
                        onChange={(e) => console.log(e)}
                      />
                    </Row>
                    <Separator height={20} />
                    <Row>
                      <TextFieldComponent
                        label="ENTER EXTERNAL EMAILS"
                        placeholder="External E-mail Here, separated by comma"
                        disabled
                      />
                    </Row>
                  </ExpandedPanel>
                </Column>
                <Column>
                  <ExpandedPanel header="Personal Settings">
                    <Row>
                      <TextFieldComponent
                        label="E-mail from address"
                        width="calc(50% - 15px)"
                        defaultValue={personalSettings.amName}
                        onChange={(e) => onChangeData(e, "amName", "personal")}
                      />
                      <TextFieldComponent
                        label="e-mail from name"
                        width="calc(50% - 15px)"
                        defaultValue={personalSettings.emailFromName}
                        onChange={(e) => onChangeData(e, "emailFromName", "personal")}
                      />
                    </Row>
                    <Separator height={20} />
                    <Row>
                      <TextAreaComponent
                        label="E-mail global footer"
                        defaultValue={personalSettings.emailFooter}
                        onChange={(e) => onChangeData(e, "emailFooter", "personal")}
                      />
                    </Row>
                  </ExpandedPanel>
                </Column>
              </Row>

              <Header isFooter>
                <Btn width={180} hide>
                  Set default
                </Btn>
                <Btn width={180} margin="0 0 0 auto" onClick={upDateSettings}>
                  Save
                </Btn>
              </Header>
            </SetupWrapper>
          )}
        </Fragment>
      ) : (
        <Waiting />
      )}
    </Wrapper>
  );
};
export default connect(({ userReducer: { token } }) => ({ token }), null)(ComunicationSetup);
