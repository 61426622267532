import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import Api from "../../../../helpers/api";
import { Loading } from "../Account/styled";
import { Wrapper, Header, Heading } from "./styled";

import { PayedOrder } from "./PayedOrder";
import { UnpayedOrder } from "./UnpayedOrder";

const UnfinishedOrders = ({ token, history, unFinishedCount }) => {
  const [data, setData] = useState(null);
  const [load, setLoad] = useState(true);

  useEffect(() => {
    (async () => {
      if (await getOrders()) {
        setLoad(false);
      }
    })();
  }, []);

  useEffect(() => {
    if (!unFinishedCount) {
      history.push("/investor/transactions/all/all");
    }
  }, [unFinishedCount]);

  const getOrders = async () => {
    const res = await Api.getUnfinishedOrders(token).catch(() => null);
    if (res && res.length) {
      setData(res);
      return true;
    } else {
      history.push("/investor/transactions/all/all");
      return false;
    }
  };

  const renderOrders = () => {
    return data.map((e) => {
      return e.paymentIsDone ? (
        <PayedOrder order={e} key={e.expiredIn} history={history} />
      ) : (
        <UnpayedOrder token={token} order={e} key={e.expiredIn} callBack={getOrders} />
      );
    });
  };

  if (load) return <Loading />;
  else {
    return (
      <Wrapper>
        <Header>
          <Heading>You have {data.length} unfinished order</Heading>
        </Header>
        {renderOrders()}
      </Wrapper>
    );
  }
};

export default connect(({ userReducer: { token }, dataReducer: { orderHistory } }) => ({
  token,
  unFinishedCount: orderHistory
    ? orderHistory.Pending.filter((e) => e.movementType === "PENDING" && !e.bankTrade).length
    : 0,
}))(UnfinishedOrders);
