import React from "react";

import { Main, TextSub } from "../styled";
import { TextFieldComponent, SelectComponent } from "../../../../forms";
import { CountingValues } from "../../styled";
import { numberWithCommas } from "helpers";

import { InputRow } from "../Information/styled";

export const SharesCurrencyCat = ({
  onChange,
  defaultValues,
  acceptedCurrency,
}) => {
  const {
    valuation,
    baseCurrency,
    tokenQuantity,
    minInvestment,
    maxInvestment,
  } = defaultValues.assetFinancialProperty;
  return (
    <Main>
      <TextSub
        marginSize="0 0 25px 0"
        textAlign="left"
        fontWeight="bold"
        color="#181818"
      >
        Shares, Currency & Cap
      </TextSub>
      <InputRow>
        <TextFieldComponent
          width="calc(25% - 30px)"
          label="SHARE PRICE"
          placeholder="Share price"
          type="number"
          onChange={onChange("assetFinancialProperty", "valuation")}
          defaultValue={defaultValues.assetFinancialProperty.valuation}
        />
        <SelectComponent
          width="calc(25% - 15px)"
          label="Base currency"
          placeholder="Base currency"
          values={acceptedCurrency}
          onChange={onChange("assetFinancialProperty", "baseCurrency")}
          defaultValue={defaultValues.assetFinancialProperty.baseCurrency}
        />
        <SelectComponent
          width="calc(50% - 15px)"
          label="Accepted currency (can be more then one)"
          placeholder="accepted currency (can be more then one)"
          values={acceptedCurrency}
          onChange={(e) => {
            onChange(
              "assetFinancialProperty",
              "acceptedCurrency"
            )([e.target.value]);
          }}
          defaultValue={defaultValues.assetFinancialProperty.acceptedCurrency}
        />
      </InputRow>
      <InputRow>
        <TextFieldComponent
          width="calc(50% - 15px)"
          label="Soft cap (optional)"
          placeholder="Soft cap (optional)"
          onChange={onChange("assetProperties", "softCap")}
          defaultValue={defaultValues.assetProperties.softCap}
        />
        <TextFieldComponent
          width="calc(50% - 15px)"
          label="Hard cap (optional)"
          placeholder="Hard cap (optional)"
          onChange={onChange("assetProperties", "hardCap")}
          defaultValue={defaultValues.assetProperties.hardCap}
        />
      </InputRow>
      <InputRow last>
        <TextFieldComponent
          width="calc(50% - 15px)"
          label="Share Quantity (MAXIMUM OF SHARES)"
          placeholder="Share Quantity (maximum of shares)"
          onChange={onChange("assetFinancialProperty", "tokenQuantity")}
          defaultValue={defaultValues.assetFinancialProperty.tokenQuantity}
        />

        <TextFieldComponent
          width="calc(25% - 30px)"
          label="Min investment (shares)"
          placeholder="Min investment (shares)"
          onChange={onChange("assetFinancialProperty", "minInvestment")}
          defaultValue={defaultValues.assetFinancialProperty.minInvestment}
        />
        <TextFieldComponent
          width="calc(25% - 15px)"
          label="Max investment (shares)"
          placeholder="Max investment (shares)"
          onChange={onChange("assetFinancialProperty", "maxInvestment")}
          defaultValue={defaultValues.assetFinancialProperty.maxInvestment}
        />
      </InputRow>
      <InputRow last>
        <CountingValues w="calc(50% - 15px)">
          {valuation && baseCurrency && tokenQuantity
            ? `~ ${numberWithCommas(
                (valuation * tokenQuantity).toFixed(2)
              )} ${baseCurrency}`
            : null}
        </CountingValues>
        <CountingValues w="calc(25% - 30px)">
          {valuation && baseCurrency && minInvestment
            ? `~ ${numberWithCommas(
                (valuation * minInvestment).toFixed(2)
              )} ${baseCurrency}`
            : null}
        </CountingValues>
        <CountingValues w="calc(25% - 15px)">
          {valuation && baseCurrency && maxInvestment
            ? `~ ${numberWithCommas(
                (valuation * maxInvestment).toFixed(2)
              )} ${baseCurrency}`
            : null}
        </CountingValues>
      </InputRow>
    </Main>
  );
};
