import React from "react";
import { Dot, ContainerStep, TextSub } from "./styled";

import theme from "models/themes";

const getColor = (stepNumber, step) => {
  if (stepNumber < step) return theme.primary;
  else if (stepNumber === step) return "#181818";
  else if (stepNumber > step) return "rgba(24, 24, 24, 0.3)";
};

export const Step = ({ stepNumber, step, text = "" }) => {
  return (
    <ContainerStep onLeft={stepNumber <= step} onRight={stepNumber < step}>
      <Dot active={stepNumber <= step} end={stepNumber < step} />
      <TextSub color={getColor(stepNumber, step)} fontWeight="bold" marginSize="10px 0 0">
        {`${stepNumber + 1}. ${text}`}
      </TextSub>
    </ContainerStep>
  );
};
