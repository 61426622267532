import React, { useEffect, useRef, useState, Fragment, useMemo } from "react";
import { connect } from "react-redux";

import { setCurrentChatId } from "../../../../modules/chatRedux/chatAction";
import {
  getAssets,
  getSoldAsset,
  getAssetActionButton,
  onSetSignData,
  onSetInvestData,
} from "../../../../modules/dataRedux/dataAction";
import { extract } from "../../../../helpers";
import { Wrapper, Header, ColWrapper, Col, BorderBlock, Row } from "./styled";
import { Button, NavBlock } from "../Offerings/styled";
import { Loader } from "../../styled/Loader";

import { ReactComponent as ShareIcon } from "../../../../assets/share.svg";
import { ReactComponent as FolderIcon } from "../../../../assets/folder.svg";
import { ReactComponent as BackIcon } from "../../../../assets/arrowleft.svg";
import { ReactComponent as HelpIcon } from "../../../../assets/help-circle-small.svg";
//components
import OfferSlider from "./Slider";
import { Description } from "./Description";
import { ShareInfo } from "./ShareInfo";
import { Sponsor } from "./Sponsor";
import { SubmitBlock } from "./SubmitBlock";
import { RaisedChart } from "./RaisedChart";
import { DataRoomNew } from "./DataRoomNew";
import { DynamicFinancial } from "./DynamicFinancial";
import { DataRoom } from "./DataRoom";
import { RequestToManager, Preview } from "./RequestToManager";
import { DynamicBlock } from "./DynamicBlock";
import { SignLoi } from "./SignLoi";
import { BottomSubmitBlock } from "./BottomSubmit";
import { ChooseSignType } from "./ChooseSignType";
import { CategorySelectorPortal, ErrorMessage, ErrorMessagePortal } from "../../../admin-layout/errorMessage";
import { DynamicCapital } from "./DynamicCapital";
import { Stepper } from "./Stepper";
import { Questions } from "./Questions";
import { DocumentsUploader } from "./DocumentsUploader";
import { SubscriptionPlaceholders } from "./SubscriptionPlaceholders";

//Frame
import { DocuSignFrame } from "../SignContract/DocuSignFrame";
import { queryToObject } from "../../../../helpers/";

import Api from "../../../../helpers/api";
import { visitAsset } from "../../../../models/timeTracker";

const Offer = (props) => {
  const { asset } = props;
  const slides = extract(asset, "assetProperties", "images") || [];
  const info = extract(asset, "assetProperties");
  const financialInfo = extract(asset, "assetFinancialProperty");
  const manager = extract(asset, "managerHolder") || {};
  const sponsors = extract(asset, "sponsors") || [];
  const stepName = extract(asset, "dealWorkflowActionButtonText");
  const raisedVsToBeRaised = extract(asset, "raisedVsToBeRaised");
  const newWorkflow = extract(asset, "newWorkflow");
  const actionType = extract(asset, "actionType");
  const capitalStructure = extract(asset, "assetProperties", "dynamicCapitalStructureJson");
  const cutFlow = info.openEndFund;

  const [_, updateState] = useState();
  const [signLoiOpen, setSignLoiOpen] = useState(false);
  const [frameUrl, setFrameUrl] = useState("");
  const [frameOpen, setFrameOpen] = useState(false);
  const [wait, setWait] = useState(false);
  const [signTypeOpen, setSignTypeOpen] = useState(false);
  const [intention, setIntention] = useState(0);
  const [error, setError] = useState(null);
  const [category, setCategory] = useState(false);
  const [preview, setPreview] = useState(true);
  const [isSubscription, setSubscription] = useState(false);
  const [screenSize, setScreenSize] = useState(window.innerWidth);
  const [flowError, setFlowError] = useState(null);
  const [placeholdersModal, setPlaceHoldersModal] = useState(false);

  const chatRef = useRef(null);
  const stepNames = ["SIGN NDA", "UPLOAD LOI", "LOI SUBMITTED", "LOI APPROVED", "INVEST NOW"];

  //to get value for did mount
  const workflowRef = useRef(null);
  useEffect(() => {
    workflowRef.current = newWorkflow;
  }, [newWorkflow]);

  const onChangeSignType = () => {
    setSignTypeOpen(!signTypeOpen);
  };

  const onNextStep = (int) => {
    setIntention(int);
    onChangeSignType();
    openLoi();
  };

  const onOpenPlaceholders = () => {
    setPlaceHoldersModal(!placeholdersModal);
  };

  const setPaymentInfo = () => {
    const paymentInfo = {
      assetSymbol: info.assetSymbol,
      assetId: props.id,
      timeOfEnd: financialInfo.timeOfEnd,
      raisedVsToBeRaised: { ...raisedVsToBeRaised },
      intentionToBuy: asset.intentionToBuy,
    };
    props.getSoldAsset(paymentInfo);
  };

  useEffect(() => {
    let unsubscribe = {};

    const close = () => {
      if (typeof unsubscribe.ok === "function") unsubscribe.ok();
    };

    const start = () => {
      if (props.id && props.username && props.fullName) {
        unsubscribe.ok = visitAsset(props.id, {
          email: props.username,
          name: props.fullName,
        });
      }
    };

    const onResize = () => {
      setScreenSize(window.innerWidth);
    };

    start();

    window.addEventListener("blur", close);
    window.addEventListener("focus", start);
    window.addEventListener("resize", onResize);

    return () => {
      if (typeof unsubscribe.ok === "function") unsubscribe.ok();
      window.removeEventListener("blur", close);
      window.removeEventListener("focus", start);
      window.removeEventListener("resize", onResize);
    };
  }, []);

  const signNDA = () => {
    setWait(true);

    Api.signNDA(props.token, props.id)
      .then((json) => {
        setWait(false);
        setFrameUrl(json.redirectUrl);
        setFrameOpen(true);
      })
      .catch((e) => {
        console.log(e);
        setWait(false);
      });
  };

  const openLoi = () => {
    setSignLoiOpen(!signLoiOpen);
    if (signLoiOpen) setSubscription(false);
  };

  const onDigitalSign = () => {
    props.onSetSignData({ actionType, intentionToBuy: intention }, () =>
      props.history.push(`/investor/offerings/digital-signature/${props.id}`)
    );
  };

  const onDocuSign = async () => {
    const { token, id } = props;
    setWait(true);
    onChangeSignType();
    const link = await Api.getDocusignLink(token, id, intention).catch(onError);
    if (link) {
      setFrameUrl(link);
      setFrameOpen(true);
    }
    setWait(false);
  };

  const onError = (e) => {
    setWait(false);
    setError(e);
  };

  const onGoToInvest = () => {
    props.onSetInvestData(actionType, () => props.history.push(`/investor/offerings/investment-new/${props.id}`));
  };

  const sendDocusignRequest = async (envelopeId, token) => {
    setWait(true);
    const res = await Api.getDocuSignInfo(props.token, envelopeId, token).catch(onError);
    if (res) {
      props.getAssetActionButton(props.id, props.token);
      setWait(false);
    }
  };

  useEffect(() => {
    props.setCurrentChatId(props.id);
    // if (!props.asset)
    props.getAssets(props.token);

    const reciveMessage = async (e) => {
      if (e.data.href) {
        setFrameOpen(false);
        setFrameUrl("");
        const { envelopeId, token, event } = queryToObject(e.data.href);
        //new workflow
        if (workflowRef.current) {
          if (event === "signing_complete") {
            if (envelopeId && token) {
              setWait(true);
              const res = await Api.approveDocuSign(props.token, envelopeId, token).catch(onError);
              if (res) {
                setIntention(0);
                setWait(false);
              }
            }
          }
        } else {
          //old workflow

          if (event === "signing_complete") {
            if (envelopeId && token) {
              sendDocusignRequest(envelopeId, token);
            }
          }
        }
      }
    };
    window.addEventListener("message", reciveMessage, false);
    return () => {
      window.removeEventListener("message", reciveMessage);
    };
  }, []);

  //update after redirect
  useEffect(() => {
    updateState({});
  }, [props.id]);

  //hot fix
  useEffect(() => {
    props.getAssets(props.token);
  }, [stepName, actionType]);

  useEffect(() => {
    const { unreadMessages } = props;
    if (unreadMessages && unreadMessages.assets && unreadMessages.assets.find((e) => e.assetId === props.id)) {
      window.scroll({
        left: 0,
        top: document.body.scrollHeight,
        behavior: "smooth",
      });
    }
  });

  const onRedirect = (link) => () => {
    props.history.push(link);
  };

  const onChangeCategory = () => setCategory(!category);

  const actionTypes = {
    SIGN_DOCUMENT_SUBSCRIPTION: openLoi,
    SIGN_DOCUMENT: onChangeSignType,
    INVEST: onGoToInvest,
    SIGN_DOCUMENT_INVEST: onGoToInvest,
    KYC_INTERNAL: onRedirect(`/investor/account/verify/${props.id}`),
    SELF_CERTIFICATION: onRedirect(`/investor/offerings/${props.id}/self-certification`),
    CLIENT_CATEGORIZATION: onChangeCategory,
    SUBSCRIPTION: () => {
      setSubscription(true);
      openLoi();
    },
    INFO: () => {
      setFlowError({ error: "Information", message: asset.message || "Step is not available now." });
    },
    SIGN_DOCUMENT_SUBSCRIPTION_PLACEHOLDERS: onOpenPlaceholders,
    SIGN_DOCUMENT_PLACEHOLDERS: onOpenPlaceholders,
    SIGN_DOCUMENT_SUBSCRIPTION_PLACEHOLDERS_TOKEN: onOpenPlaceholders,
  };

  const submitTypes = {
    "SIGN LETTER OF INTENT": openLoi,
    "SIGN NDA": signNDA,
    "INVEST NOW": () => {
      setPaymentInfo();
      props.history.push(`/investor/offerings/investment/${props.id}`);
    },
    "PASS KYC": onRedirect(`/investor/account/verify`),
    "UPLOAD LOI": undefined,
  };

  const isInvestor = props.role !== "FACILITATOR";

  //callback blocks
  const RightColBlockOne = () => (
    <Fragment key={`right-block-one-${stepName}-${actionType}`}>
      {info.dealsType !== "EXISTED_DEAL" && raisedVsToBeRaised.toBeRaised ? (
        <Fragment>
          {isInvestor ? (
            <Fragment>
              <SubmitBlock
                stepName={stepName}
                onSubmit={newWorkflow ? actionTypes[actionType] : submitTypes[stepName]}
                dealsType={info.dealsType}
                newWorkflow={newWorkflow}
                step={stepNames.indexOf(stepName)}
              />
              {newWorkflow && <Stepper token={props.token} assetId={props.id} stepName={stepName} />}
            </Fragment>
          ) : null}
          {!cutFlow ? (
            <RaisedChart
              baseCurrency={financialInfo.baseCurrency}
              raisedVsToBeRaised={raisedVsToBeRaised}
              assetSymbol={info.assetSymbol}
              subscribers={asset.subscribers || 0}
              minInvestment={financialInfo.minInvestment}
              maxInvestment={financialInfo.maxInvestment}
              valuation={financialInfo.valuation}
            />
          ) : null}
        </Fragment>
      ) : null}
      <DynamicFinancial info={info} />
    </Fragment>
  );

  const RightColBlockTwo = () => (
    <Fragment key={`right-block-two-${stepName}-${actionType}`}>
      {capitalStructure ? (
        <DynamicCapital values={JSON.parse(capitalStructure)} baseCurrency={financialInfo.baseCurrency} />
      ) : null}
      {!asset.newWorkflow ? (
        <DataRoom docs={info.documents} token={props.token} stepName={stepName} makeSign={submitTypes[stepName]} />
      ) : (
        <DataRoomNew
          stretchHeight={screenSize < 1280}
          stepName={stepName}
          id={props.id}
          token={props.token}
          onRedirect={onRedirect}
        />
      )}
    </Fragment>
  );

  const ChatBlock = () => (
    <Fragment key={`chat-block-one-${stepName}-${actionType}`}>
      <div ref={chatRef} />
      {preview ? (
        <Preview
          token={props.token}
          logo={manager.userLogo}
          amName={manager.fullName || manager.username}
          onOpen={() => setPreview(false)}
        />
      ) : (
        <RequestToManager
          reduxMessages={props.messages}
          token={props.token}
          logo={manager.userLogo}
          username={props.username}
          unreadMessages={props.unreadMessages}
          assetId={props.id}
        />
      )}
    </Fragment>
  );

  const MainInfo = () => (
    <Fragment key="MainInfo">
      {slides.length ? <OfferSlider key={props.id || "null-of-asset"} images={slides} token={props.token} /> : null}
      <Description info={info} />
      <ShareInfo cutFlow={cutFlow} info={info} token={props.token} assetId={props.id} financialInfo={financialInfo} />
    </Fragment>
  );

  const AdditionalInfo = () => (
    <Fragment key="AdditionalInfo">
      {sponsors.length ? (
        <Sponsor token={props.token} manager={sponsors[0]} history={props.history} assetId={props.id} />
      ) : null}
      {info.vocAssetDynamicProperties &&
        info.vocAssetDynamicProperties
          .filter((e) => e.header && e.text.replace(/<[^>]*>/g, ""))
          .map((e) => <DynamicBlock block={e} key={e.header} />)}

      <Questions token={props.token} history={props.history} assetId={props.id} username={props.username} />
      {isInvestor ? (
        <BottomSubmitBlock
          stepName={stepName}
          onSubmit={newWorkflow ? actionTypes[actionType] : submitTypes[stepName]}
          dealsType={info.dealsType}
          toBeRaised={raisedVsToBeRaised.toBeRaised}
        />
      ) : null}
    </Fragment>
  );

  const UploadDocuments = () => <DocumentsUploader token={props.token} assetId={props.id} />;

  return props.waitData || !asset ? (
    <Loader />
  ) : (
    <Wrapper frameOpen={frameOpen}>
      {wait && <Loader zIndex={100} />}
      <Header>
        <BorderBlock>
          <Button transparent height={36} width={157} size={12} onClick={onRedirect("/investor/offerings/all")}>
            <BackIcon style={{ marginRight: 8 }} />
            Back to Deals
          </Button>
        </BorderBlock>
        {newWorkflow ? (
          <NavBlock>
            <Button
              height={36}
              transparent
              width={185}
              size={12}
              onClick={onRedirect(`/investor/offerings/question-and-answer/${props.id}`)}
            >
              <HelpIcon style={{ marginRight: 8 }} />
              OPEN Q&A
            </Button>
            <Button
              height={36}
              transparent
              width={185}
              size={12}
              onClick={onRedirect(`/investor/data-room/deal/${props.id}#deal`)}
            >
              <FolderIcon style={{ marginRight: 8 }} />
              GO TO DATA ROOM
            </Button>
          </NavBlock>
        ) : (
          <NavBlock>
            <Button
              height={36}
              transparent
              width={185}
              size={12}
              onClick={onRedirect(`/investor/offerings/question-and-answer/${props.id}`)}
            >
              <HelpIcon style={{ marginRight: 8 }} />
              OPEN Q&A
            </Button>
            <Button height={36} transparent width={185} size={12}>
              <ShareIcon style={{ marginRight: 8 }} />
              Share Deal
            </Button>
          </NavBlock>
        )}
      </Header>
      <ColWrapper>
        {/* big screen  */}
        {screenSize > 1280 ? (
          <Fragment>
            <Col width="calc(100% - 470px)">
              {MainInfo()}
              {AdditionalInfo()}
            </Col>
            <Col width="440px">
              <RightColBlockOne key={`right-block-one-${stepName}`} />
              <RightColBlockTwo key={`right-block-two-${stepName}`} />
              <ChatBlock key={`chat-block-one-${stepName}`} />
              <UploadDocuments />
            </Col>
          </Fragment>
        ) : (
          <Col width="100%">
            {MainInfo()}
            <Row align="flex-start" margin="0 0 20px">
              <Col width="calc(50% - 15px)">
                <RightColBlockOne key={`right-block-one-${stepName}`} />
                <ChatBlock key={`chat-block-one-${stepName}`} />
                <UploadDocuments />
              </Col>
              <Col height="100%" width="calc(50% - 15px)">
                <RightColBlockTwo key={`right-block-two-${stepName}`} />
              </Col>
            </Row>
            {AdditionalInfo()}
          </Col>
        )}
      </ColWrapper>

      <SignLoi
        isOpen={signLoiOpen}
        onBackDropClick={openLoi}
        token={props.token}
        assetId={props.id}
        minInvestment={financialInfo.minInvestment}
        maxInvestment={financialInfo.maxInvestment}
        baseCurrency={financialInfo.baseCurrency}
        callBack={(url) => {
          setSignLoiOpen(false);
          setFrameUrl(url);
          setFrameOpen(true);
        }}
        newWorkflow={newWorkflow}
        getIntention={onNextStep}
        shareName={info.assetSymbol}
        isSubscription={isSubscription}
      />

      {frameOpen ? (
        <DocuSignFrame
          fullHeight
          url={frameUrl}
          onBackDropClick={() => {
            setFrameOpen(false);
            setFrameUrl("");
          }}
        />
      ) : null}
      <ChooseSignType
        isOpen={signTypeOpen}
        onBackDropClick={onChangeSignType}
        onDigitalSign={onDigitalSign}
        onDocuSign={onDocuSign}
        signToolOptions={props.signToolOptions}
      />
      {error && <ErrorMessage er={error} onClose={() => setError(null)} />}
      {category && <CategorySelectorPortal onClose={onChangeCategory} history={props.history} assetId={props.id} />}
      {flowError && <ErrorMessagePortal width="500px" er={flowError} onClose={() => setFlowError(null)} />}
      {placeholdersModal ? (
        <SubscriptionPlaceholders
          onCancel={onOpenPlaceholders}
          token={props.token}
          assetId={props.id}
          history={props.history}
          onSetSignData={props.onSetSignData}
          baseCurrency={financialInfo.baseCurrency}
          minInvestment={financialInfo.minInvestment}
          maxInvestment={financialInfo.maxInvestment}
          shareName={info.assetSymbol}
          normalIntent={actionType === "SIGN_DOCUMENT_SUBSCRIPTION_PLACEHOLDERS_TOKEN"}
          noIntent={actionType === "SIGN_DOCUMENT_PLACEHOLDERS"}
          actionType={actionType}
        />
      ) : null}
    </Wrapper>
  );
};

export default connect(
  (
    {
      userReducer: { token, username, role, fullName },
      dataReducer: { assets = [], waitData, AMSettings },
      chatReducer: { messages, unreadMessages },
    },

    {
      match: {
        params: { id },
      },
    }
  ) => {
    return {
      token,
      asset: assets.find((e) => e.linearId === id),
      waitData,
      id,
      messages,
      username,
      role,
      fullName,
      unreadMessages,
      signToolOptions: AMSettings.signToolOptions,
    };
  },
  {
    getAssets,
    getSoldAsset,
    setCurrentChatId,
    getAssetActionButton,
    onSetSignData,
    onSetInvestData,
  }
)(Offer);
