import React, { useState, useEffect, Fragment } from "react";
import { CircularProgress } from "@material-ui/core";

import { Main, TextSub, HeaderCircle } from "../../DealForm/Step/styled";
import { FlexRow } from "../../../dashBoardCard";
import { ReactComponent as Maximize } from "../../../../../assets/maximize.svg";
import { ScrollContainer } from "../styled";
import { TransactionsHeader, HeaderTD, TransactionsTD, User } from "../../../dealTypeCard";
import { RegisterModal } from "./RegisterModal.js";

import Api from "../../../../../helpers/api";
import { EmptyInfo } from "./emptyInfo";
import { ReactComponent as Icon } from "assets/info.svg";

export const Register = ({ token, id, history, newDeal = false }) => {
  const [register, setRegister] = useState(null);
  const [wait, setWait] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);

  const changeModalStatus = () => setModalOpen(!modalOpen);

  const getRegister = async () => {
    const reg = await Api.getRegister(token, id).catch((_) => setWait(false));
    if (reg) {
      setWait(false);
      setRegister(reg);
    }
  };

  useEffect(() => {
    getRegister();
  }, []);

  const registerExist = register && register.length;

  const renderTable = () => {
    return (
      <Fragment>
        <TransactionsHeader translate={-15} height={38}>
          <HeaderTD width="calc(100% - 120px)" translate={15} padding="0 25px 0 0">
            Investor
          </HeaderTD>
          <HeaderTD width="75px" padding="0 18px 0 0">
            sold shares
          </HeaderTD>
        </TransactionsHeader>

        <ScrollContainer isScroll={register.length > 6}>
          {register.map((el, i) => {
            const { soldShares, fullName, logo, username, userId } = el;
            return (
              <TransactionsHeader grey={i % 2 === 0} key={`${el}-${i}`}>
                <TransactionsTD lower width="calc(100% - 75px)" padding="0 0 0 15px">
                  <User
                    token={token}
                    userName={fullName}
                    logo={logo}
                    onClick={() => history.push(`/admin/user/${username}/${userId}`)}
                  />
                </TransactionsTD>
                <TransactionsTD fWeight="bold" lower width="75px">
                  {soldShares.toFixed(2)} %
                </TransactionsTD>
              </TransactionsHeader>
            );
          })}
        </ScrollContainer>
      </Fragment>
    );
  };
  return (
    <Main
      currentHeight="415px"
      width={`calc(${newDeal ? "50% - 185px" : "29.9% - 20px"})`}
      padding="20px 25px 25px 25px"
    >
      <FlexRow mBottom="0" align="flex-start">
        <TextSub textAlign="left" fontWeight="bold" color="#181818" marginSize="0 0 25px 0">
          Register
        </TextSub>
        {registerExist ? (
          <HeaderCircle onClick={changeModalStatus}>
            <Maximize />
          </HeaderCircle>
        ) : null}
      </FlexRow>
      {wait ? (
        <CircularProgress style={{ margin: "auto" }} size={24} />
      ) : registerExist ? (
        renderTable()
      ) : (
        <EmptyInfo icon={<Icon />} />
      )}
      {modalOpen && (
        <RegisterModal register={register} isOpen={modalOpen} onBackDropClick={changeModalStatus} token={token} />
      )}
    </Main>
  );
};
