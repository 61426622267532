import React, { useState, useEffect } from "react";

import { Main, TextSub } from "../DealForm/Step/styled";
import { DealBlock, FlexRow, Ceil, AssetName, SignedDocument, TH } from "./styled";
import { EmptyInfo } from "components/admin-layout/pages/AssetDetails/common/emptyInfo";
import Api from "data-room-panel/helpers/api";
import { icons } from "models/icons";

export const Documents = ({ token, userId, onGetUploaded }) => {
  const [data, setData] = useState({});
  const [load, setLoad] = useState(true);

  useEffect(() => {
    Api.getInvestorDocuments(token, userId).then((docs) => {
      setData(docs);
      onGetUploaded(docs);
      setLoad(false);
    });
  }, []);

  const renderDocs = () => {
    const assets = Object.keys(data).filter((name) => {
      if (data[name]["Signed documents"] && data[name]["Signed documents"].length) return name;
    });

    if (assets.length) {
      return assets.map((asset) => {
        const docs = data[asset]["Signed documents"];
        return (
          <DealBlock key={`${asset}-${docs.length}`}>
            <FlexRow m="0 0 10px">
              <Ceil w="calc(100% - 270px)">
                <AssetName>{asset}</AssetName>
              </Ceil>
              <Ceil w="150px">
                <TH>Date</TH>
              </Ceil>
              <Ceil w="120px">
                <TH>{docs.length} Signed Documents</TH>
              </Ceil>
            </FlexRow>
            {docs.map((d) => (
              <SignedDocument token={token} key={`${asset}-${d.documentName}-${d.date}`} file={d} userId={userId} />
            ))}
          </DealBlock>
        );
      });
    } else
      return (
        <EmptyInfo
          icon={icons.file}
          heading="There are no signed documents yet"
          text="When we’ll have documents to show for you we’ll show them here"
        />
      );
  };

  if (load) return null;
  else
    return (
      <Main margin="0 0 30px">
        <TextSub textAlign="left" fontWeight="bold" color="#181818" marginSize="0 0 25px 0">
          Signed Documents
        </TextSub>
        {renderDocs()}
      </Main>
    );
};
