import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";

import { ReactComponent as Check } from "../../../assets/success.svg";
import { ReactComponent as Error } from "../../../assets/err.svg";
import { ReactComponent as Close } from "../../../assets/x .svg";

const Container = styled.div`
  position: fixed;
  top: 30px;
  right: 0;
  width: 320px;
  background: #ffffff;
  border: 1px solid ${({ error = false }) => (error ? "#FF4343" : "#019D52")};
  box-sizing: border-box;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.03);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  transform: translateX(${({ translateX = "100%" }) => translateX});
  transition: transform 0.4s linear;

  box-sizing: border-box;
  padding: 15px 20px;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:last-child {
    cursor: pointer;
  }
`;

const Heading = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: -0.02em;
  color: #181818;
  margin: 0 auto 0 6px;
`;

const Text = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 135%;
  letter-spacing: 0.05em;
  color: rgba(24, 24, 24, 0.4);
  margin-top: 7px;
`;

export const InfoMessage = ({
  showMessage,
  heading,
  text,
  err = false,
  onEnd
}) => {
  const [translateX, setTranslateX] = useState("100%");
  const refContainer = useRef(null);

  useEffect(() => {
    if (refContainer.current) {
      (async function() {
        if (showMessage) {
          setTranslateX("-30px");
          await new Promise(r => setTimeout(r, 3000));
          setTranslateX("100%");
          onEnd && onEnd();
        }
      })();
    }
  }, [showMessage]);

  return (
    <Container
      key={"message-box-notification"}
      ref={refContainer}
      translateX={translateX}
    >
      <Row>
        {!err ? <Error /> : <Check />}
        <Heading>{heading}</Heading>
        <Close onClick={onEnd} />
      </Row>
      <Text>{text}</Text>
    </Container>
  );
};
