import React, { useEffect, useState, Fragment } from "react";
import { connect } from "react-redux";

import { getQuestionAssetInv, getAssets } from "../../../../modules/dataRedux/dataAction";
import { getInvDealFiles } from "../../../../modules/dataRoomRedux/dataRoomActions";
import { countByCategory, sortQuestions, isHowPage } from "./helper";
import {
  Header,
  BackButtonContainer,
  TextHeaderContainer,
  HeaderContainer,
  ColWrapper,
  Col,
  Search,
  CatFilter,
  FilterHeading,
  CategoryBtn,
  WrapContainer,
  Questions,
  SeparateLine,
  NoData,
  LoaderWrapper,
  Wrapper,
} from "./styled";
import { Button } from "../Offerings/styled";
import { ReactComponent as BackIcon } from "../../../../assets/arrowleft.svg";
import { ReactComponent as Plus } from "../../../../assets/plus-circle.svg";
import { CardWaiting } from "./CardWaiting";
import { CardAnswered } from "./CardAnswered";
import { AddModal } from "./AddModal";
import { ErrorMessage } from "../../../admin-layout/errorMessage";
import { Wait } from "../../../admin-layout/pages/DashBoard/styled";
import { EmptyCard as NoInfo } from "components/common/emptyInfo";

const QAMain = ({
  username,
  token,
  match: {
    params: { assetId, folderName, fileName },
  },
  history,
  dataQAInv,
  getQuestionAssetInv,
  getAssets,
  assets,
  trades,
  getInvDealFiles,
  investorDeals,
}) => {
  const [search, setSearch] = useState("");
  const [category, setCategory] = useState("All Categories");
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(null);
  const [ready, setReady] = useState(false);

  const onError = (e) => {
    if (!ready) setReady(true);
    setError(e);
  };

  const onOpenModal = () => {
    setOpen(!open);
  };

  const goBack = () => {
    folderName && fileName
      ? history.push(`/investor/data-room/deal/${assetId}${window.location.hash || ""}`)
      : history.location.pathname.match(`^\/investor\/question-and-answer\/`)
      ? history.push(`/investor/question-and-answer/main`)
      : history.push(`/investor/offerings/companies/${assetId}`);
  };

  const onSearch = (s) => {
    setSearch(s);
  };

  const onChangeCategory = (category) => {
    setCategory(category);
  };

  const renderCategories = () => {
    const categories = ["All Categories", ...dataQAInv[assetId].categories];

    return categories.map((cat) => {
      return (
        <CategoryBtn
          active={category === cat}
          name={cat}
          count={countByCategory(dataQAInv[assetId].data, cat, username)}
          onClick={onChangeCategory}
        />
      );
    });
  };

  const renderQuestions = () => {
    const { own, another } = sortQuestions(dataQAInv[assetId].data, username);

    return (
      <Fragment>
        {own
          .filter((q) => (category === "All Categories" ? true : q.category === category))
          .filter((q) => q.textQuestion.toLowerCase().includes(search.toLowerCase()))
          .map((q) => (
            <CardWaiting key={q.questionId} q={q} token={token} ownQuestion onRefresh={onRefresh} />
          ))}
        {own.length && own.some((q) => q.category === category) ? <SeparateLine /> : null}
        {another
          .filter((q) => (category === "All Categories" ? true : q.category === category))
          .filter((q) => q.textQuestion.toLowerCase().includes(search.toLowerCase()))
          .map((q) =>
            q.isWaiting ? (
              <CardWaiting key={q.questionId} q={q} token={token} onRefresh={onRefresh} />
            ) : (
              <CardAnswered key={q.questionId} q={q} token={token} currentUser={username} />
            )
          )}
      </Fragment>
    );
  };

  const onRefresh = () => {
    getQuestionAssetInv(token, assetId, onError);
  };

  useEffect(() => {
    const initLoad = async () => {
      if (!assets.length) {
        await getAssets(token);
      }
      await getQuestionAssetInv(token, assetId, onError, () => setReady(true));
      await getInvDealFiles(token, assetId, onError);
      if (folderName && fileName) setOpen(true);
    };
    initLoad();
  }, []);

  if (!ready) {
    return (
      <LoaderWrapper>
        <Wait />
      </LoaderWrapper>
    );
  } else if (!isHowPage(dataQAInv[assetId]) && error) {
    return <ErrorMessage er={error} onClose={() => setError(null)} />;
  } else if (isHowPage(dataQAInv[assetId]) && assets.length) {
    const {
      assetProperties: { assetName },
    } = assets.find((a) => a.linearId === assetId) || trades.find((t) => t.assetId === assetId);
    return (
      <Wrapper>
        <Header>
          <BackButtonContainer>
            <Button transparent height={36} width={176} size={12} onClick={goBack}>
              <BackIcon style={{ marginRight: 8 }} />
              Back{" "}
              {fileName && folderName
                ? "to Data Room"
                : history.location.pathname.match(`^\/investor\/question-and-answer\/`)
                ? "to Q&A"
                : "to Deal"}
            </Button>
          </BackButtonContainer>
          <HeaderContainer>
            <TextHeaderContainer>{assetName} Deal Q&A</TextHeaderContainer>
            <Button height={36} width={240} size={12} onClick={onOpenModal}>
              <Plus style={{ marginRight: 8 }} />
              Post New Question
            </Button>
          </HeaderContainer>
        </Header>
        <ColWrapper>
          <Col left>
            <Search value={search} onChange={onSearch} />
            <CatFilter>
              <FilterHeading>Categories</FilterHeading>
              <WrapContainer>{renderCategories()}</WrapContainer>
            </CatFilter>
          </Col>
          <Col>
            <Questions>{renderQuestions()}</Questions>
          </Col>
        </ColWrapper>
        {open ? (
          <AddModal
            _fileName={fileName}
            _folderName={folderName}
            token={token}
            data={investorDeals}
            onBackdropClick={onOpenModal}
            assetName={assetName}
            categories={dataQAInv[assetId].categories}
            assetId={assetId}
            onRefresh={onRefresh}
          />
        ) : null}
      </Wrapper>
    );
  } else if (!isHowPage(dataQAInv[assetId]) && assets.length) {
    const {
      assetProperties: { assetName },
    } = assets.find((a) => a.linearId === assetId);
    return (
      <Wrapper>
        <Header>
          <BackButtonContainer>
            <Button transparent height={36} width={176} size={12} onClick={goBack}>
              <BackIcon style={{ marginRight: 8 }} />
              Back{" "}
              {fileName && folderName
                ? "to Data Room"
                : history.location.pathname.match(`^\/investor\/question-and-answer\/`)
                ? "to Q&A"
                : "to Deal"}
            </Button>
          </BackButtonContainer>
          <HeaderContainer>
            <TextHeaderContainer>
              {assetName}
              Deal Q&A
            </TextHeaderContainer>
            <Button height={36} width={240} size={12} onClick={onOpenModal}>
              <Plus style={{ marginRight: 8 }} />
              Post New Question
            </Button>
          </HeaderContainer>
        </Header>
        <NoData>
          <NoInfo heading="No Questions yet" />
        </NoData>
        {open ? (
          <AddModal
            _fileName={fileName}
            _folderName={folderName}
            token={token}
            data={investorDeals}
            token={token}
            onBackdropClick={onOpenModal}
            assetName={assetName}
            categories={dataQAInv[assetId].categories}
            assetId={assetId}
            onRefresh={onRefresh}
          />
        ) : null}
      </Wrapper>
    );
  }
};

export default connect(
  ({
    userReducer: { token, username },
    dataReducer: { dataQAInv, assets, trades },
    dataRoomReducer: { investorDeals },
  }) => ({
    username,
    token,
    dataQAInv,
    assets,
    trades,
    investorDeals,
  }),
  { getQuestionAssetInv, getInvDealFiles, getAssets }
)(QAMain);
