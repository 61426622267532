import React, { useState } from "react";

import { Card, CardHeader, ContentBlock, SubmitBlock, Heading, Status, Expand, Text, Row } from "./styled";
import { Button } from "../../../investor-layout/pages/Offerings/styled";
import { ReactComponent as Clock } from "../../../../assets/clock-i.svg";
import { ReactComponent as Arrow } from "../../../../assets/chevron-up.svg";

import { CountDown } from "./CountDown";
import Api from "../../../../helpers/api";
import { TextFieldComponent } from "../../../admin-layout/forms";
import { Loading } from "../Account/styled";
import { TransactionMessage, ValidateOrderByUser } from "../../ActionMessages";

export const UnpayedOrder = ({
  order: {
    assetName,
    amountToTransfer,
    amountForIssuerRequest,
    code,
    expiredIn,
    destAccountNumber,
    recipient: { accountNumber, bankName, bic, iban, accountName },
    sender: { bankName: senderBankName, iban: senderIban },
  },
  token,
  callBack,
}) => {
  const [wait, setWait] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [paymentError, setPaymentError] = useState(false);
  const [validateModalOpen, setValidateModalOpen] = useState(false);

  const makePayment = async () => {
    setWait(true);

    const res = await Api.makeTransaction(
      token,
      accountNumber,
      destAccountNumber,
      code,
      amountForIssuerRequest
    ).catch(() => setWait(false));

    setWait(false);
    if (res) {
      setModalOpen(true);
    } else {
      setPaymentError(true);
      setModalOpen(true);
    }
  };

  const sayPaymentDone = async () => {
    setWait(true);

    const res = await Api.sayPaymentIsDone(token, code).catch(() => {
      setPaymentError(true);
      setModalOpen(true);
    });

    if (res) {
      setWait(false);
      setValidateModalOpen(true);
    } else {
      setPaymentError(true);
      setModalOpen(true);
    }
  };

  const goToChats = () => {
    const link = document.createElement("a");
    link.href = `${window.location.origin}/investor/chat/`;
    link.target = "_blank";
    link.click();
  };

  return (
    <Card>
      {wait && <Loading />}
      <CardHeader>
        <Heading>{assetName + ", " + amountToTransfer}</Heading>
        <Status>
          <Clock /> Waiting for payment
        </Status>
        <CountDown expiredIn={expiredIn} />
        <Expand noMargin>
          <Arrow />
        </Expand>
      </CardHeader>
      <ContentBlock>
        <Text margin="0 0 30px 0">
          Please transfer amount of money to purchase amount of shares to this bank account.
        </Text>
        <Heading margin="0 0 15px">Recipient</Heading>
        <Row mBot="20px">
          <TextFieldComponent
            width="248px"
            label="Amount to tranfser"
            height={42}
            labelFontSize={10}
            fontSize={12}
            isInvestor
            disabled
            defaultValue={amountToTransfer}
          />
          <TextFieldComponent
            width="calc(100% - 273px)"
            label="Account Holder Name"
            height={42}
            labelFontSize={10}
            fontSize={12}
            isInvestor
            disabled
            defaultValue={accountName}
          />
        </Row>
        <Row mBot="30px">
          <TextFieldComponent
            width="248px"
            label="BIC (SWIFT) CODE"
            height={42}
            labelFontSize={10}
            fontSize={12}
            isInvestor
            disabled
            defaultValue={bic}
          />
          <TextFieldComponent
            width="calc(50% - 151px)"
            label="Beneficiary’s bank"
            height={42}
            labelFontSize={10}
            fontSize={12}
            isInvestor
            disabled
            defaultValue={bankName}
          />
          <TextFieldComponent
            width="calc(50% - 151px)"
            label="IBAN"
            height={42}
            labelFontSize={10}
            fontSize={12}
            isInvestor
            disabled
            defaultValue={iban}
          />
        </Row>
        <Heading margin="0 0 15px">Your Bank Account Details</Heading>
        <Row>
          <TextFieldComponent
            label="THE BANK ACCOUNT YOU CHOSE TO SEND THE TRANSFER FROM"
            height={42}
            labelFontSize={10}
            fontSize={12}
            isInvestor
            disabled
            defaultValue={`${senderBankName} (${senderIban})`}
          />
        </Row>
      </ContentBlock>
      <SubmitBlock>
        <Button transparent width={248} height={42} onClick={goToChats}>
          CHAT WITH ADMIN
        </Button>
        {/* <Button
          onClick={makePayment}
          transparent
          width={248}
          height={42}
          size={14}
          margin="0 16px 0 auto"
        >
          PAY NOW
        </Button> */}
        <Button width={248} height={42} size={14} onClick={sayPaymentDone}>
          I MADE THE TRANSFER
        </Button>
      </SubmitBlock>

      <TransactionMessage
        isOpen={modalOpen}
        customHeading={paymentError ? "" : "Success"}
        message={
          paymentError
            ? "Please try again for refresh your page"
            : "Operation went good, close this window and continue your work"
        }
        isError={paymentError}
        onClose={() => {
          callBack();
          setModalOpen(false);
        }}
      />

      <ValidateOrderByUser isOpen={validateModalOpen} onClose={callBack} />
    </Card>
  );
};
