import React, { useState } from "react";

import { ReactComponent as UploadIcon } from "../../../../assets/upload.svg";
import { FileBlock } from "../../containers/container";
import { ContainerFlex, UploaderLabel, BtnBlock } from "./styled";
import { Btn } from "../../buttons";

export const FileUploader = ({ order, _file, onAddFile, onDelete }) => {
  const [file, setFile] = useState(_file || null);

  const onDelegateToInput = (e) => {
    if (e.target.nodeName === "BUTTON") e.target.querySelector("input").click();
  };

  const onUploadFile = (e) => {
    const [file] = e.target.files;
    setFile(file);
    onAddFile(file);
  };

  const onDeleteFile = () => {
    onDelete(file.name);
    setFile(null);
  };

  return (
    <ContainerFlex w="270px" marginSize="0 10px 20px">
      <UploaderLabel>File #{order}</UploaderLabel>
      <BtnBlock white={file}>
        {!file ? (
          <Btn transparent width={260} onClick={onDelegateToInput}>
            <UploadIcon />
            <label htmlFor={`custom-id-${order}`}> upload file</label>

            <input
              id={`custom-id-${order}`}
              type="file"
              style={{ display: "none" }}
              onChange={onUploadFile}
            />
          </Btn>
        ) : (
          <FileBlock name={file.name} onDelete={onDeleteFile} />
        )}
      </BtnBlock>
    </ContainerFlex>
  );
};
