import React, { useState, useEffect, Fragment } from "react";
import styled from "styled-components";
import { CircularProgress, Button } from "@material-ui/core";

import { ContainerFlex } from "./containers/container";
import Api from "../../helpers/api";
import { LogoContainer, LogoImage } from "./dealTypeCard";
import { ReactComponent as MenuIcon } from "../../assets/hidden-menu-icon.svg";
import { ReactComponent as UserIcon } from "../../assets/no-avatar.svg";
import { ReactComponent as OutIcon } from "../../assets/log-out.svg";

export const Img = styled.img`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  object-fit: cover;
`;

const WrapperAvatar = styled(ContainerFlex)`
  position: relative;
  width: fit-content;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  overflow: visible;
  ${({ cutHeader }) => (cutHeader ? `margin-left: auto;` : ``)};
`;

const NameContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 15px;
  /* min-width: 270px; */
`;

const Name = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 135%;
  letter-spacing: -0.02em;
  color: ${({ isInvestor }) => (isInvestor ? "#fff" : "#192036")};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 150px;
`;

const Role = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 135%;
  letter-spacing: -0.02em;
  color: ${({ isInvestor }) => (isInvestor ? "rgba(255, 255, 255, 0.6)" : "#d5d7dd")};
`;

const MenuContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 15px;
  cursor: pointer;
  position: relative;
`;

const Tooltip = styled.div`
  position: absolute;
  z-index: 1000;
  width: 200px;
  height: 50px;
  background: #ffffff;
  border: 1px solid #edebeb;
  box-sizing: border-box;
  box-shadow: 0px 10px 40px rgba(89, 120, 150, 0.06);
  border-radius: 4px;
  bottom: -67px;
  right: -8px;
  display: flex;
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 135%;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: #181818;
  align-items: center;
  padding: 20px;
  svg {
    margin-right: 10px;
  }
  &:before {
    content: "";
    display: block;
    position: absolute;
    height: 3px;
    width: 36px;
    background-color: #ffffff;
    right: 1px;
    top: -1px;
    border-radius: 2px 2px 0 0;
  }
`;

const Square = styled.div`
  width: 30px;
  height: 30px;
  background: #ffffff;
  border: 1px solid #edebeb;
  box-sizing: border-box;
  box-shadow: 0px 10px 40px rgba(89, 120, 150, 0.06);
  border-radius: 4px;
  position: absolute;
  transform: rotate(45deg);
  bottom: -35px;
  z-index: 999;
`;

const HiddenLayout = styled.section`
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  z-index: 998;
  background-color: transparent;
`;

export const AvatarMenu = ({ userAvatar, onLogout, username, role, isInvestor, onClick, fullHeader }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [open, setOpen] = useState(false);

  const onChangeOpen = () => {
    setOpen(!open);
  };

  return (
    <WrapperAvatar cutHeader={!fullHeader}>
      {userAvatar ? (
        <Img src={userAvatar} onClick={onClick} />
      ) : (
        <UserIcon style={{ width: 36, height: 36 }} onClick={onClick} />
      )}
      <NameContainer>
        <Name isInvestor={isInvestor}>{username}</Name>
        <Role isInvestor={isInvestor}>{role}</Role>
      </NameContainer>
      <MenuContainer aria-controls="simple-menu" onClick={onChangeOpen}>
        <MenuIcon />
        {open ? (
          <Fragment>
            <HiddenLayout onClick={onChangeOpen} />
            <Tooltip onClick={onLogout}>
              <OutIcon />
              Logout
            </Tooltip>
            <Square />
          </Fragment>
        ) : null}
      </MenuContainer>
    </WrapperAvatar>
  );
};

export const AssetLogo = ({ images = [], token, isUnfinished }) => {
  const [logo, setLogo] = useState(null);

  useEffect(() => {
    (async function () {
      if (isUnfinished && images[0] && images[0].base64) {
        setLogo(images[0].base64);
      } else if (!logo && images[0]) {
        const { fileNameWithExtension, secureHash } = images[0];
        setLogo((await Api.getAttachment(token, secureHash, fileNameWithExtension)) || Api.defaultDeal);
      } else {
        setLogo(Api.defaultDeal);
      }
    })();
  }, []);
  return (
    <LogoContainer>
      {logo ? <LogoImage src={logo} alt="logo" /> : <CircularProgress size={20} color="inherit" />}
    </LogoContainer>
  );
};

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const UploadImg = (props) => {
  return (
    <ContainerFlex
      justify="center"
      align="center"
      h="calc(100% - 10px)"
      w="calc(100% - 10px)"
      borderRadius="4px"
      border="1px solid #3B49E7"
    >
      {props.url ? (
        <Image src={props.url} />
      ) : (
        <Button variant="contained" component="label">
          Add picture
          <input type="file" style={{ display: "none" }} onChange={props.uploadFile} />
        </Button>
      )}
    </ContainerFlex>
  );
};
