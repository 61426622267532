import React, { useState, useEffect, Fragment } from "react";
import { CircularProgress } from "@material-ui/core";
import { connect } from "react-redux";

import {
  FlexRow,
  FormCol,
  Heading,
  InputCont,
  InfoBlock,
  Submit,
  FormBottom,
  LegalInfo,
  FooterLinks,
  WaitingWrapper,
  SignUpLink,
  SignUpTxt,
  InputsCont,
  Label,
  _Input,
  LabelsLine,
  PassInfo,
  PassStreightLine,
  Progress,
  Step,
  Welcome,
} from "./styled";

import { ReactComponent as InfoIcon } from "../../assets/info.svg";
import { ReactComponent as PassIcon } from "../../assets/pass.svg";
import { ReactComponent as EyeIcon } from "../../assets/eye.svg";
import { ReactComponent as XIcon } from "../../assets/x-icon.svg";
import { ReactComponent as CheckIcon } from "../../assets/check-icon.svg";
import { ErrorMessage } from "../investor-layout/ActionMessages";
import { isGeneral, isAlphier } from "helpers/envChecker";

import Api from "../../helpers/api";
import { login } from "modules/userRedux/userAction";

const iconStyle = {
  position: "absolute",
  top: 21,
  left: "90%",
  zIndex: 2,
  width: 22,
  height: 22,
  cursor: "pointer",
};

const CreatePasswordForm = ({
  match: {
    params: { token },
  },
  login,
}) => {
  const [response, setResponce] = useState(null);
  const [error, setError] = useState("");
  const [password, setPassword] = useState("");
  const [matchingPassword, setMatchingPassword] = useState("");
  const [waiting, setWaiting] = useState(false);
  const [success, setSuccess] = useState(false);
  const [passType, setPasstype] = useState("password");
  const [confirmPassType, setConfirmPasstype] = useState("password");
  const [fieldError, setFieldError] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  const errorsArr = ["qwerty", "123", "abc", "Passw0rd", "ABC", "Password123"];

  const passError =
    !/(?=.*[A-Z])/.test(password) ||
    password.length < 8 ||
    password.length > 30 ||
    !/(?=.*[^0-9A-Za-z])/.test(password) ||
    (password && errorsArr.map((str) => password.includes(str)).some((e) => e)) ||
    !/(?=.*[0-9])/.test(password);

  const disabled = waiting || !password || !matchingPassword || passError || password !== matchingPassword;

  useEffect(() => {
    Api.getCreatePasswordToken(token)
      .then((json) => setResponce(json))
      .catch(({ message }) => setError(message));
  }, []);

  const onChangePassType = (cb, field) => {
    if (field === "password") cb("text");
    else cb("password");
  };

  const onInputChange = (e, cb) => {
    fieldError && onChangeError();
    cb(e.target.value);
  };
  const onChangeError = () => setFieldError(!fieldError);
  const onChangeModal = () => setModalOpen(!modalOpen);

  const createPassword = () => {
    const formData = new FormData();
    formData.append("token", response.token);
    formData.append("matchingPassword", matchingPassword);
    formData.append("password", password);
    setWaiting(true);
    Api.createPassword(formData)
      .then((text) => {
        setWaiting(false);
        setSuccess(true);
        setTimeout(async () => {
          const res = await login({ email: text, password: password }, "", true);
          if (res) window.location.href = "/";
        }, 1000);
      })
      .catch((e) => {
        onChangeModal();
        let message;
        try {
          message = e.join(" ");
        } catch (error) {
          message = e.message;
        }
        setModalMessage(message);
        onChangeError();
        setWaiting(false);
      });
  };

  if (!response && !error) {
    return (
      <WaitingWrapper>
        <CircularProgress />
      </WaitingWrapper>
    );
  } else if (error) {
    return (
      <FormCol>
        <div style={{ margin: "auto 0" }}>
          <Heading error>Error</Heading>
          <InfoBlock>
            {error}
            <InfoIcon />
          </InfoBlock>
          <SignUpTxt>
            Plese send request again <SignUpLink to="/password-recovery">Forgot password here</SignUpLink>{" "}
          </SignUpTxt>
          {isGeneral || isAlphier ? null : (
            <FormBottom>
              <LegalInfo>LEGAL INFORMATION</LegalInfo>
              <FlexRow>
                <FooterLinks>Privacy And Policy</FooterLinks>
                <FooterLinks>Terms and Conditions</FooterLinks>
              </FlexRow>
            </FormBottom>
          )}
        </div>
      </FormCol>
    );
  } else {
    return (
      <FormCol>
        <div style={{ margin: "auto 0" }}>
          <Welcome>
            <h3>Hello, {response.fullName}!</h3>
            <p>{response.email}</p>
          </Welcome>
          <Heading>Please create your password</Heading>
          <InputsCont>
            <Label>Password</Label>
            <InputCont width="100%" error={password && passError} done={password && !passError}>
              <_Input
                autoComplete="new-password"
                isPass={passType === "password" && password}
                empty={!password}
                done={password && !passError}
                type={passType}
                pRight={75}
                placeholder="Set your password"
                error={password && passError}
                value={password}
                onChange={(e) => onInputChange(e, setPassword)}
              />
              <PassIcon />
              {password && passError ? (
                <XIcon
                  style={{
                    ...iconStyle,
                    left: "84%",
                    width: 16,
                    height: 16,
                    top: 24,
                  }}
                  onClick={() => setPassword("")}
                />
              ) : password && !passError ? (
                <CheckIcon
                  style={{
                    ...iconStyle,
                    left: "84%",
                    width: 16,
                    height: 16,
                    top: 24,
                  }}
                />
              ) : null}

              <EyeIcon onClick={() => onChangePassType(setPasstype, passType)} style={iconStyle} />
            </InputCont>
            <LabelsLine>
              <Label>Confirm password</Label>
              {password && matchingPassword && password !== matchingPassword ? (
                <Label error>The password doesn’t match</Label>
              ) : null}
            </LabelsLine>
            <InputCont
              width="100%"
              error={password && matchingPassword && (password !== matchingPassword || (matchingPassword && passError))}
              done={password && matchingPassword && password === matchingPassword && !passError}
            >
              <_Input
                autoComplete="new-password"
                isPass={confirmPassType === "password" && matchingPassword}
                empty={!matchingPassword}
                type={confirmPassType}
                pRight={75}
                placeholder="Confirm password"
                error={
                  password && matchingPassword && (password !== matchingPassword || (matchingPassword && passError))
                }
                done={password && matchingPassword && password === matchingPassword && !passError}
                value={matchingPassword}
                onChange={(e) => onInputChange(e, setMatchingPassword)}
              />
              <PassIcon />
              {password && matchingPassword && (password !== matchingPassword || (matchingPassword && passError)) ? (
                <XIcon
                  style={{
                    ...iconStyle,
                    left: "84%",
                    width: 16,
                    height: 16,
                    top: 24,
                  }}
                  onClick={() => setMatchingPassword("")}
                />
              ) : password && matchingPassword && password === matchingPassword ? (
                <CheckIcon
                  style={{
                    ...iconStyle,
                    left: "84%",
                    width: 16,
                    height: 16,
                    top: 24,
                  }}
                />
              ) : null}
              <EyeIcon onClick={() => onChangePassType(setConfirmPasstype, confirmPassType)} style={iconStyle} />
            </InputCont>
          </InputsCont>

          <PassInfo>
            <PassStreightLine>
              <Label error={password && passError}>Password Strength</Label>
              <Progress password={password} arr={errorsArr} />
            </PassStreightLine>

            <Step
              key="symbol"
              value={password}
              regExp={/(?=.*[A-Z])/g}
              description="Password must contain at least 1 uppercase character"
            />
            <Step
              key="character"
              value={password}
              regExp={/(?=.*[^0-9A-Za-z])/g}
              description="Password must contain at least 1 special character (!,%,?, etc)"
            />
            <Step
              key="combination"
              value={password}
              isList
              arr={errorsArr}
              regExp={/(?=.*[^qwerty])(?=.*[^123])(?=.*[^abcd])/g}
              description="Password must be without often-used combinations"
            />
            <Step
              key="number"
              value={password}
              regExp={/(?=.*[0-9])/g}
              description="Password must contain at least 1 number (0-9)"
            />
          </PassInfo>

          <InfoBlock hide={!success}>
            {success && (
              <Fragment>
                Password successfuly created
                <InfoIcon />
              </Fragment>
            )}
          </InfoBlock>
          {!success ? (
            <Submit onClick={createPassword} disabled={disabled}>
              Confirm
            </Submit>
          ) : null}
        </div>
        {modalOpen ? (
          <ErrorMessage
            textAlign="left"
            maxWidth={500}
            heading="Error"
            margin="0 0 25px"
            message={modalMessage}
            onClose={onChangeModal}
          />
        ) : null}
        {waiting && (
          <WaitingWrapper>
            <CircularProgress color="inherit" />
          </WaitingWrapper>
        )}
      </FormCol>
    );
  }
};

export default connect(null, { login })(CreatePasswordForm);
