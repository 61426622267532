import React, { useState, useEffect, Fragment, useRef } from "react";
import { connect } from "react-redux";

import {
  setCurrentChatId,
  getArchiveMessage,
} from "../../../../modules/chatRedux/chatAction";
import { TextH3 } from "../DealForm/Step/styled";
import { Header, ColWrapper } from "./styled";
import { Btn } from "../../buttons";
import { Loader } from "../../../investor-layout/styled/Loader";
import { ReactComponent as ArrowLeft } from "../../../../assets/arrowleft.svg";

import { SideBar } from "./SideBar";
import { ChatBar } from "./ChatBar";

import Api from "../../../../helpers/api";

const Chat = ({
  token,
  setCurrentChatId,
  getArchiveMessage,
  socketConnect,
  adminChatThemes,
  messages,
  unreadMessages,
  history,
  match: {
    params: { assetId, userName },
  },
}) => {
  const getNewMessage = () => getArchiveMessage(token, assetId, userName);
  const [messagesByUser, setMessages] = useState([]);
  const [wait, setWait] = useState(true);
  const debounceRef = useRef(null);
  const once = useRef(true);
  //sidebar
  useEffect(() => {
    if (socketConnect || (socketConnect && unreadMessages)) {
      if(once.current) {
        once.current = false;
        Api.getInvestorsMessages(token);
        if (wait) {
          setTimeout(() => {
            setWait(false);
          }, 1000);
        }
      }
    }
  }, [socketConnect, unreadMessages]);

  //on change chat
  useEffect(() => {
    if (userName && assetId && socketConnect) {
      setCurrentChatId(assetId);
      getArchiveMessage(token, assetId, userName);
    }
  }, [assetId, userName, socketConnect]);

  // update sidebar and unread
  useEffect(() => {
    if (unreadMessages.total !== 0) {
      const unreadCurrentChat = adminChatThemes.find(
        (e) => e.assetId === assetId && e.username === userName && e.unread
      );
      // Api.getInvestorsMessages(token);
      if (unreadCurrentChat) {
        clearTimeout(debounceRef.current);
        debounceRef.current = setTimeout(() => {
          Api.updateChatMessages(token, 1, 10, assetId, userName);
        }, 1000);
      }
    }
  }, [unreadMessages]);

  //filter by user
  useEffect(() => {
    const filtered = messages.filter(
      (e) => e.sender === userName || e.receiver === userName
    );
    setMessages(filtered);
  }, [messages, userName]);

  if (Object.keys(adminChatThemes).length || !wait) {
    return (
      <Fragment>
        <Header>
          <Btn
            transparent
            width={287}
            onClick={() => history.push("/admin/full-dashboard")}
          >
            <ArrowLeft />
            back to dashboard
          </Btn>
          <TextH3 marginSize="0 0 0 25px">Global chat with Investors</TextH3>
        </Header>

        <ColWrapper>
          <SideBar adminChatThemes={adminChatThemes} token={token} />
          <ChatBar
            getNewMessage={getNewMessage}
            token={token}
            messages={messagesByUser}
            userName={userName}
            assetId={assetId}
            adminChatThemes={adminChatThemes}
          />
        </ColWrapper>
      </Fragment>
    );
  } else {
    return <Loader />;
  }
};

export default connect(
  ({
    userReducer: { token },
    chatReducer: { socketConnect, messages, adminChatThemes, unreadMessages },
  }) => ({
    token,
    socketConnect,
    messages,
    adminChatThemes,
    unreadMessages,
  }),
  { setCurrentChatId, getArchiveMessage }
)(Chat);
