import React, { useState, useEffect } from "react";

import { ModalBackground, ModalWrapper, ModalHeader, ModalHeading } from "../Offer/styled";
import { Button } from "../Offerings/styled";
import { FlexRow, Col, CurrencyName, InfoBlock, BorderBlock, MaxAmount } from "./styled";
import { TextFieldComponent, TextFieldComponentPlaceholder } from "../../../admin-layout/forms";
import { Loader } from "../../styled/Loader";
import { ReactComponent as CloseIcon } from "../../../../assets/x-circle.svg";

import Api from "../../../../helpers/api";

export const SellShares = ({
  token,
  isOpen,
  info: { assetName, tokenIdentifier, linearId, currency, quantityCold },
  onBackDropClick,
  onSuccess,
  onFailure,
}) => {
  const [amount, setAmout] = useState("");
  const [price, setPrice] = useState("");
  const [wait, setWait] = useState(false);

  const onAmountInput = (e) => {
    const {
      target: { value },
    } = e;
    const integer = /(^.{0,0}$)|(^[1-9]{1,1}[0-9]{0,}$)/;
    if (!value.match(integer) || value > quantityCold) return;

    setAmout(value);
  };

  const onPriceChange = (e) => {
    const {
      target: { value },
    } = e;
    const double = /^\d{0,9}(\.\d{0,2}){0,1}$/;
    if (value < 0 || !value.match(double)) return;
    setPrice(value);
  };

  useEffect(() => {
    if (!isOpen) {
      setAmout("");
      setPrice("");
    }
  });

  const sellShares = async () => {
    setWait(true);

    const res = await Api.sellShares(token, linearId, price, currency, amount).catch((e) => {
      setWait(false);
      onFailure(e.message);
    });

    if (res) {
      onSuccess(res);
      setWait(false);
      onBackDropClick();
    }
  };

  return isOpen ? (
    <ModalBackground onClick={onBackDropClick}>
      {wait && <Loader zIndex={101} />}
      <ModalWrapper width={440} onClick={(e) => e.stopPropagation()}>
        <ModalHeader boxShadow="0px 10px 40px rgba(89, 120, 150, 0.08)">
          <ModalHeading textTransform="none">Sell {tokenIdentifier} Shares</ModalHeading>
          <CloseIcon onClick={onBackDropClick} />
        </ModalHeader>
        <Col width="100%" padding="20px 30px">
          <FlexRow margin="0 0 18px 0">
            <InfoBlock label="asset" data={assetName} />
          </FlexRow>
          <FlexRow margin="0 0 18px 0">
            <TextFieldComponentPlaceholder
              type="number"
              isInvestor
              labelFontSize={10}
              fontSize={14}
              height={42}
              label="QUANTITY"
              placeholder="Quantity"
              width="260px"
              value={amount}
              onChange={(e) => onAmountInput(e)}
            />
            <MaxAmount active={quantityCold === amount} onClick={() => setAmout(quantityCold)}>
              MAX AMOUNT
            </MaxAmount>
          </FlexRow>
          <FlexRow margin="0 0 18px 0">
            <TextFieldComponent
              isInvestor
              type="number"
              labelFontSize={10}
              fontSize={14}
              height={42}
              label="Share Price"
              placeholder="Share Price"
              width="240px"
              defaultValue={price}
              onChange={(e) => onPriceChange(e)}
            />
            <CurrencyName>{currency}</CurrencyName>
          </FlexRow>
          <BorderBlock>
            <FlexRow>
              <InfoBlock label="AMOUNT" grey={1} data={`${(amount * price).toFixed(2)} ${currency}`} />
            </FlexRow>
          </BorderBlock>
          <FlexRow margin="20px 0 0 0">
            <Button tTransform="uppercase" size={10} width={94} height={36} hide onClick={onBackDropClick}>
              Cancel
            </Button>
            <Button
              disabled={!amount || amount === "0" || !price}
              tTransform="uppercase"
              size={10}
              width={180}
              height={36}
              onClick={sellShares}
            >
              Sell
            </Button>
          </FlexRow>
        </Col>
      </ModalWrapper>
    </ModalBackground>
  ) : null;
};
