import React, { useState, useEffect } from "react";

import Api from "helpers/api";
//styled
import { Wrapper } from "../../../main";
import { FlexRow } from "../../../dashBoardCard";

//components
import { PageHeader } from "./Header";
import { Financial } from "./Financial";
import { ReportsActions } from "./ReportsActions";
import { Reports } from "./Reports";
import { UserStatistic } from "./UsersStatistic";
//common
import { PastTransactions } from "../common/PastTransactions";
import { Register } from "../common/Register";
import { Questions } from "../common/Questions";
import { VievsFolders } from "../common/ViewsFolders";
import { VievsStatistic } from "../common/ViewsStatistic";
import { FoldersTable } from "../common/FoldersTable";
import { StatisticTable } from "../common/StatisticTable";
import { ChatCard } from "../common/Chat";
import { Activity } from "../common/activity";

export const ExisedDeal = ({ token, deal, deal: { linearId }, history, adminChatThemes }) => {
  //states

  const [lastReport, setReport] = useState(null);
  const [reports, setReports] = useState(null);
  const [views, setViews] = useState(null);

  //methods

  const getReports = async () => {
    const report = await Api.getAssetLastReport(token, linearId);
    setReport(report || {});

    const reports = await Api.getAssetReports(token, linearId);
    setReports(reports);
  };

  const getStatistic = async () => {
    const views = await Api.getFilesViewStatistic(token, linearId);
    setViews(views);
  };

  //did mount

  useEffect(() => {
    getReports();
    getStatistic();
  }, []);

  //render

  return (
    <Wrapper bg="transparent">
      <PageHeader deal={deal} token={token} history={history} />
      <FlexRow align="flex-start">
        <Financial deal={deal} />
        <Activity token={token} id={linearId} history={history} w="400px" h="120px" existed />
      </FlexRow>
      <ReportsActions
        token={token}
        id={linearId}
        history={history}
        lastReport={lastReport}
        onUpdateReports={getReports}
      />
      <FlexRow align="flex-start">
        <ChatCard token={token} id={linearId} adminChatThemes={adminChatThemes} />
        <Reports reports={reports} assetId={linearId} />
        <Register history={history} id={linearId} token={token} />
      </FlexRow>
      <FlexRow align="flex-start">
        <Questions token={token} assetId={linearId} history={history} />
      </FlexRow>
      <FlexRow align="flex-start">
        <VievsFolders info={views} />
        <VievsStatistic assetId={linearId} history={history} info={views} />
      </FlexRow>
      <FlexRow height={!views && 126}>
        <FoldersTable info={views} />
        <StatisticTable history={history} assetId={linearId} info={views} token={token} />
      </FlexRow>
      <FlexRow>
        <UserStatistic token={token} assetId={linearId} history={history} />
      </FlexRow>
      <PastTransactions token={token} assetId={linearId} />
    </Wrapper>
  );
};
