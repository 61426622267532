import styled from "styled-components";
import { Link } from "react-router-dom";

export const Header = styled.div`
  height: 60px;
  background: #ffffff;
  border-radius: 4px 0px 4px 4px;
  margin-bottom: ${({ isFooter = false }) => (isFooter ? 0 : "30px")};
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 5px 25px;
  width: 100%;
`;

export const BackLink = styled(Link)`
  width: 150px;
  height: 32px;
  border: 1px solid ${({ theme: { primary } }) => primary};
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 135%;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  text-decoration: none;
  color: ${({ theme: { primary } }) => primary};
  margin-right: 25px;
  > svg {
    margin-right: 7px;
  }
`;

export const Heading = styled.h6`
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 135%;
  color: #181818;
  margin: 0;
`;

export const Flex = styled.div`
  width: 100%;
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
`;

export const Card = styled.div`
  width: ${({ big = false }) => (big ? "calc(100% - 353px)" : "323px")};
  min-height: 290px;
  box-sizing: border-box;
  background: #ffffff;
  border-radius: 4px;
  padding: 20px 25px 30px;
  display: flex;
  flex-direction: column;
  > h6 {
    margin-bottom: 25px;
  }
`;

export const Separator = styled.div`
  height: ${({ height = 20 }) => `${height}px`};
`;

export const GreyBlock = styled.div`
  height: 108px;
  background: ${({ theme: { mainBgAdmin } }) => mainBgAdmin};
  border-radius: 4px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  > div {
    font-family: Muli;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 135%;
    letter-spacing: -0.02em;
    color: rgba(24, 24, 24, 0.5);
  }
  > svg {
    margin-right: 12px;
  }
`;

export const UsersBlock = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const UserBlock = styled.div`
  width: ${({ width }) => (width ? width : "calc(50% - 10px)")};
  margin-bottom: 8px;
  height: 42px;
  background: ${({ theme: { quoteBgInvestor } }) => quoteBgInvestor};
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 5px 16px;
  box-sizing: border-box;
  > svg {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
  > svg {
    filter: grayscale(${({ theme: { greyFilter } }) => greyFilter});
  }
  > span {
    font-family: Muli;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 135%;
    letter-spacing: -0.02em;
    color: #181818;
  }
  > svg {
    &:last-child {
      margin-left: auto;
      cursor: pointer;
    }
  }
`;

export const SmallLogo = styled.img`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 10px;
  object-fit: cover;
`;

export const EmptyLogo = styled.div`
  width: 20px;
  height: 20px;
  margin-right: 10px;
  background: transparent;
`;
