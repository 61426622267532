import React, { Fragment, useState, useEffect } from "react";

import { TRow, TCeil, FolderName } from "./styled";

import { FolderClose, FolderOpen, Arrow } from "components/common/icons-primary";
import { File } from "./File";

export const Folder = ({
  folder: { name, files, section },
  token,
  assetId,
  onOpenFile,
  isOpenViewer = false,
  updateFiles,
  history,
}) => {
  const [open, setOpen] = useState(!!parseInt(sessionStorage.getItem(name)));
  useEffect(() => {
    sessionStorage.setItem(name, open ? 1 : 0);
  }, [open]);

  const renderDocs = () => {
    return files.map((e, i) => (
      <File
        history={history}
        key={`${e.name}-${i}-${name}`}
        file={e}
        i={i}
        section={section}
        token={token}
        assetId={assetId}
        folderName={name}
        onDoubleClick={() => onOpenFile({ ...e, folderName: name })}
        isOpenViewer={isOpenViewer}
        updateFiles={updateFiles}
      />
    ));
  };

  return (
    <Fragment>
      <TRow onClick={() => setOpen(!open)}>
        <TCeil big full={isOpenViewer}>
          <FolderName open={open}>
            <Arrow />
            {!open ? <FolderClose /> : <FolderOpen />}
            <span>
              {section != 0 ? `${section}.` : null} {name}
            </span>
          </FolderName>
        </TCeil>
        {isOpenViewer ? null : (
          <Fragment>
            <TCeil fixedWidth="147px"></TCeil>
            <TCeil fixedWidth="107px"></TCeil>
            <TCeil fixedWidth="204px"></TCeil>
            <TCeil fixedWidth="210px"></TCeil>
          </Fragment>
        )}
      </TRow>
      {open ? renderDocs() : null}
    </Fragment>
  );
};
