import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import moment from "moment";

import {
  Wrapper,
  Col,
  Card,
  UserName,
  Role,
  Avatar,
  Loading,
  InfoCard,
  InputsRow,
  Date
} from "./styled";
import { Btn } from "../../buttons";
import {
  updateAvatar,
  updateUserName,
  reLogin
} from "../../../../modules/userRedux/userAction";
import { TextFieldComponent, InfoBlockComponent } from "../../forms";
import { ChangePassword } from "../../../investor-layout/pages/Account/ChangePassword";
import SponsorInfo from "../SponsorInfo";
import Api from "../../../../helpers/api";

let _inputRef = null;

const Account = ({
  token,
  fullName,
  role,
  logo,
  updateAvatar,
  username,
  updateUserName,
  reLogin,
  history
}) => {
  const [edit, setEdit] = useState(false);
  const [load, setLoad] = useState(false);
  const [userInfo, setInfo] = useState(null);
  const [changePassOpen, setChangePassOpen] = useState(false);

  useEffect(() => {
    if (role !== "SPONSOR") {
      Api.getUserData(token).then(info => {
        setInfo(info);
      });
    }
  }, []);

  const onDataChange = (e, name) => {
    setInfo({
      ...userInfo,
      [name]: e.target ? e.target.value : e
    });
  };

  const changeModalStatus = () => setChangePassOpen(!changePassOpen);

  const editUserData = () => {
    setEdit(!edit);
    if (edit) {
      setLoad(true);
      Api.changeUserData(token, userInfo)
        .then(_ => {
          updateUserName(userInfo.fullName);
          setLoad(false);
        })
        .catch(() => setLoad(false));
    }
  };

  if (userInfo) {
    return (
      <Wrapper>
        <Col>
          <Card align="center" padding="30px">
            <Avatar
              updateAvatar={updateAvatar}
              token={token}
              blob={logo}
              onRefMount={ref => (_inputRef = ref)}
            />
            <UserName>{fullName}</UserName>
            <Role>{role}</Role>
            <Btn
              transparent
              width={200}
              height={42}
              onClick={() => _inputRef.click()}
            >
              Change IMAGE
            </Btn>
          </Card>
        </Col>
        <Col width="calc(100% - 354px)">
          {load && <Loading />}

          <InfoCard heading="Profile Details" margin="0 0 30px 0">
            <InputsRow>
              <TextFieldComponent
                edit={edit}
                label="Full name"
                width="calc(50% - 15px)"
                defaultValue={fullName}
                onChange={e => onDataChange(e, "fullName")}
                disabled={!edit}
              />
              <TextFieldComponent
                label="Role"
                defaultValue={role}
                width="calc(50% - 15px)"
                disabled
              />
            </InputsRow>
            <InputsRow>
              <TextFieldComponent
                label="e-mail (User name)"
                defaultValue={username}
                width="100%"
                disabled
              />
            </InputsRow>
            <Btn
              onClick={editUserData}
              width={200}
              height={42}
              transparent={!edit}
            >
              {edit ? "Save Changes" : "Edit Profile"}
            </Btn>
          </InfoCard>

          <InfoCard heading="Security">
            <InputsRow>
              <InfoBlockComponent
                label="Last login information"
                width="calc(100% - 230px)"
                children={
                  <Fragment>
                    <Date margin="0 5px 0 0">
                      {moment(userInfo.lastLoginDate).format(
                        "dddd, DD/MM/YYYY"
                      )}
                    </Date>
                    <Date light>
                      {moment(userInfo.lastLoginDate).format("hh:mm:ss A")}
                    </Date>{" "}
                  </Fragment>
                }
              />
              <Btn
                width={200}
                height={42}
                transparent
                margin="auto 0 0"
                onClick={changeModalStatus}
              >
                Change My Password
              </Btn>
            </InputsRow>
          </InfoCard>

          <ChangePassword
            isOpen={changePassOpen}
            onBackDropClick={changeModalStatus}
            token={token}
            reLogin={reLogin}
            username={username}
          />
        </Col>
      </Wrapper>
    );
  } else {
    return <SponsorInfo history={history} />;
  }
};

export default connect(
  ({ userReducer: { token, fullName, logo, _2fa, username, role } }) => ({
    token,
    fullName,
    logo,
    _2fa,
    username,
    role,
    logo
  }),
  { updateAvatar, updateUserName, reLogin }
)(Account);
