import React, { useState, Fragment, useEffect } from "react";
import styled from "styled-components";

import { Btn } from "components/admin-layout/buttons";
import { icons } from "models/icons";
import Api from "./api";
import { ConfirmMessagePortal } from "components/admin-layout/errorMessage";
import moment from "moment";

const Row = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 80px;
  background: #ffffff;
  border-radius: 4px;
  margin-bottom: 10px;
  padding: 5px 30px 5px 25px;
  &:last-child {
    margin-bottom: 0;
  }
`;

const TD = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: ${({ w }) => w};
`;

const Text = styled.div`
  font-size: 12px;
  line-height: 15px;
  letter-spacing: -0.02em;
  color: #494d5b;
`;

const Name = styled.div`
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: -0.02em;
  color: #181818;
`;

export const Form = ({
  form: { id, jsonKYCForm, lastUpdate },
  token,
  history,
  updateCb = console.log,
}) => {
  const { templateName, sections } = JSON.parse(jsonKYCForm);
  const [confirm, setConfirm] = useState(false);

  const fieldsCount = () => {
    let count = 0;
    sections.forEach((s) => {
      count += s.fields.length;
    });
    return count;
  };

  const onChangeConfirm = () => {
    setConfirm(!confirm);
  };

  const onRemove = async () => {
    const remove = await Api.removeForm(token, id);
    if (remove) {
      onChangeConfirm();
      updateCb();
    }
  };

  const onRedirect = (path) => () => history.push(path);

  return (
    <Fragment>
      <Row>
        <TD w="calc(100% - 777px)">
          <Name>{templateName}</Name>
        </TD>
        <TD w="95px">
          <Text>{sections.length}</Text>
        </TD>
        <TD w="95px">
          <Text>{fieldsCount()}</Text>
        </TD>
        <TD w="120px">
          <Text>{moment(lastUpdate).format("DD.MM.YYYY, hh:mm A")}</Text>
        </TD>
        <TD w="354px">
          <Btn width={120} onClick={onRedirect(`/admin/kyc-form-view/${id}`)}>
            {icons.eye} view
          </Btn>
          <Btn
            width={120}
            transparent
            onClick={onRedirect(`/admin/kyc-form/${id}`)}
          >
            {icons.edit} edit
          </Btn>
          <Btn onClick={onChangeConfirm} error>
            {icons.remove} remove
          </Btn>
        </TD>
      </Row>
      {confirm ? (
        <ConfirmMessagePortal
          heading="Remove form"
          onClose={onChangeConfirm}
          message={`Are you sure to remove <b>${templateName}</b> form?`}
          onSubmit={onRemove}
        />
      ) : null}
    </Fragment>
  );
};
