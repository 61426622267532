import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";

import Api from "../../../../helpers/api";
import { Header, TextH3, Separator, Row, Card, InfoBlock, ColInCard } from "./styled";
import { Btn } from "../../buttons";
import { ReactComponent as ArrowLeft } from "../../../../assets/arrowleft.svg";
import { ManagerInfo } from "../AssetDetails/common/managerAvatar";
import { Wrapper } from "../../main";
import { DynamicLineCard } from "./dynamicLineCard";
import { ViewsByCountries } from "./viewsByCountries";
import { StatisticByUser } from "./statisticByUsers";
import { SendEmail } from "./SendEmail";
import { UserStatistic } from "./UserStatistic";
import { ErrorMessage } from "../../errorMessage";

const DealsStatistic = ({
  token,
  match: {
    params: { id },
  },
  history,
}) => {
  const [assetData, setAssetData] = useState(null);
  const [mainStatistic, setMainStatistic] = useState(null);
  const [viewsByCountries, setViewsByCountries] = useState([]);
  const [statisticByUsers, setStatisticByUsers] = useState([]);
  const [openSendEmail, setOpenSendEmail] = useState(false);
  const [openUserInfo, setOpenUserInfo] = useState(false);
  const [mailData, setMailData] = useState({});
  const [modalUserInfoName, setModalUserInfoName] = useState(null);
  const [period, setPeriod] = useState(30);
  const [error, setError] = useState(null);
  const [wait, setWait] = useState(true);

  const onBackToDeal = () => history.push(`/admin/dashboard/${id}`);

  const toggleModalSendEmail = (data) => {
    setMailData(data);
    setOpenSendEmail(!openSendEmail);
  };

  const toggleModalUserInfo = (email) => {
    setModalUserInfoName(email);
    setOpenUserInfo(!openUserInfo);
  };

  const onChangePeriod = (period) => () => {
    setPeriod(period);
  };

  useEffect(() => {
    const getInitial = async () => {
      const stat = await Api.getVisitorStatistic(token, id, period).catch((e) => {
        setWait(false);
        setError(e);
      });
      if (stat) {
        setStatisticByUsers(stat.statisticsByUser);
        setViewsByCountries(stat.visitByCountries);
        setMainStatistic(stat.summary);
        setWait(false);
      }
    };
    getInitial();
  }, []);

  useEffect(() => {
    if (period) {
      Api.getVisitorStatistic(token, id, period)
        .then((c) => {
          setStatisticByUsers(c.statisticsByUser);
          setViewsByCountries(c.visitByCountries);
          setMainStatistic(c.summary);
        })
        .catch((e) => {
          setError(e);
        });
    }
  }, [period]);

  //get asset info
  useEffect(() => {
    (async function () {
      Promise.all([Api.getAsset(token, id)]).then((data) =>
        setAssetData({
          asset: data[0],
        })
      );
    })();
  }, []);

  if (!assetData) {
    return (
      <Wrapper width="100%" justifyContent="center" alignItems="center" bg="transparent">
        <CircularProgress />
      </Wrapper>
    );
  } else {
    const { asset } = assetData;

    if (wait) {
      return (
        <Wrapper width="100%" justifyContent="center" alignItems="center" bg="transparent">
          <CircularProgress />
        </Wrapper>
      );
    } else
      return (
        <Fragment>
          <Header>
            <Btn transparent onClick={onBackToDeal} width={150}>
              <ArrowLeft /> back to deal
            </Btn>
            <TextH3 marginSize="0 0 0 25px">
              {"Visitor Statistics of " + asset.assetProperties.assetName + " Deal"}{" "}
              {asset.sponsors.length ? "by " : null}
            </TextH3>
            {asset.sponsors.length ? (
              <ManagerInfo token={token} avatar={asset.sponsors[0].userLogo} managerName={asset.sponsors[0].fullName} />
            ) : null}
          </Header>
          <Separator />
          <Row>
            <Card width="calc(100% - 494px)" alignItems="center" flexDirection="row" justify="flex-start">
              <InfoBlock>
                <span>views</span>
                <h3>{mainStatistic.views}</h3>
              </InfoBlock>
              <InfoBlock>
                <span>users</span>
                <h3>{mainStatistic.users}</h3>
              </InfoBlock>
              <InfoBlock>
                <span>Avg Time per user</span>
                <h3 transform="uppercase">{mainStatistic.avgTimeUser}</h3>
              </InfoBlock>
              <InfoBlock>
                <span>Avg Time per visit</span>
                <h3 transform="uppercase">{mainStatistic.avgTimeVisit}</h3>
              </InfoBlock>
            </Card>
            <Card width="474px" alignItems="center" flexDirection="row">
              <Btn width={80} transparent={period !== 0} onClick={onChangePeriod(0)}>
                total
              </Btn>
              <Btn width={80} transparent={period !== 7} onClick={onChangePeriod(7)}>
                week
              </Btn>
              <Btn width={80} transparent={period !== 30} onClick={onChangePeriod(30)}>
                month
              </Btn>
              <Btn width={80} transparent={period !== 180} onClick={onChangePeriod(180)}>
                6 month
              </Btn>
              <Btn width={80} transparent={period !== 365} onClick={onChangePeriod(365)}>
                year
              </Btn>
            </Card>
          </Row>
          <Separator />
          <Row>
            <Card width="calc(100% - 292px)" height="285px" paddingBottom="0">
              <ColInCard chart>
                <DynamicLineCard id={id} token={token} period={period} currency={"Views"} />
              </ColInCard>
            </Card>
            <Card width="272px" height="285px">
              <ColInCard>
                <ViewsByCountries viewsByCountries={viewsByCountries} />
              </ColInCard>
            </Card>
          </Row>
          <Separator />
          <Card height="fit-content">
            <ColInCard>
              <StatisticByUser
                id={id}
                days={period}
                statisticByUsers={statisticByUsers}
                token={token}
                history={history}
                toggleModalSendEmail={toggleModalSendEmail}
                toggleModalUserInfo={toggleModalUserInfo}
              />
            </ColInCard>
          </Card>
          <SendEmail
            token={token}
            mailData={mailData}
            onBackdropClick={() => toggleModalSendEmail({})}
            isOpen={openSendEmail}
          ></SendEmail>
          {openUserInfo ? (
            <UserStatistic
              history={history}
              token={token}
              assetId={id}
              days={period}
              username={modalUserInfoName}
              onBackdropClick={toggleModalUserInfo}
              toggleModalSendEmail={toggleModalSendEmail}
            />
          ) : null}

          {error ? <ErrorMessage er={error} onClose={() => setError(null)} /> : null}
        </Fragment>
      );
  }
};

export default connect(
  ({ userReducer: { token }, dataReducer: { assetInfo, assets } }) => ({
    token,
    assetInfo,
    assets,
  }),
  null
)(DealsStatistic);
