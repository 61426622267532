import React, { useState, useEffect } from "react";

import { Card, Heading, BorderCont, Row, Text, Method, CurrencyBlock } from "../Investment/styled.js";
import { TextFieldComponentPlaceholder } from "../../../admin-layout/forms";
import { ReactComponent as InfoIcon } from "../../../../assets/alert-circle.svg";
import { ReactComponent as RepeatIcon } from "../../../../assets/repeat.svg";
import { numberWithCommas } from "helpers/index.js";

export const Amount = ({
  type,
  onChangeType,
  cashBalance,
  onAmountChange,
  tokenToSell: { price, tokenIdentifier, quantity },
  onError,
  baseCurrency,
}) => {
  const [amount, setAmount] = useState("");
  const [inputError, setInputError] = useState(false);

  useEffect(() => {
    onAmountChange(amount);
    setAmount(amount);
  }, []);

  const onCountChange = (e) => {
    if (inputError) {
      onError(false);
      setInputError(false);
    }
    const integerNumber = /((^[1-9]{1,1})[0-9]{0,}$)|(^.{0,0}$)/;

    const {
      target: { value },
    } = e;
    if (
      !value.match(integerNumber) ||
      value > quantity ||
      (Object.keys(cashBalance).length && value * price > cashBalance.GBP)
    ) {
      onError(true);
      setInputError(true);
    }
    setAmount(value);
    onAmountChange(value);
  };

  return (
    <Card noPadding>
      <BorderCont>
        <Heading margin="0 0 20px 0">Amount of Shares</Heading>
        <Row padding="0" margin="0 0 14px 0">
          <TextFieldComponentPlaceholder
            height={42}
            label="Shares to Buy"
            width="250px"
            labelFontSize={10}
            pseudo
            pseudoText={tokenIdentifier}
            fontSize={12}
            pseudoSize={12}
            error={inputError}
            isInvestor
            padding="0 70px 0 20px"
            onChange={(e) => onCountChange(e)}
            defaultValue={amount}
            type="number"
          />
          <RepeatIcon style={{ margin: "25px 35px 0" }} />
          <TextFieldComponentPlaceholder
            height={42}
            labelFontSize={10}
            label="AMOUNT to pay"
            width="135px"
            fontSize={12}
            defaultValue={numberWithCommas((amount * price).toFixed(2))}
            disabled
            isInvestor
            margin="0 10px 0 0"
          />
          <CurrencyBlock>{baseCurrency}</CurrencyBlock>
        </Row>
        <Row padding="0">
          <InfoIcon />
          <Text margin="0 0 0 13px" fSize={12} color="#7C7C7C">
            The maximum amount of shares is {numberWithCommas(quantity)}
          </Text>
        </Row>
      </BorderCont>
      <BorderCont>
        <Heading margin="0 0 18px 0">Payment Method</Heading>
        <Row>
          <Method type="Bank Transfer" active={type === "bank"} onChangeType={() => onChangeType("bank")} />
          {Object.keys(cashBalance).length && cashBalance[baseCurrency] ? (
            <Method
              type="Currency Account"
              active={type === "cash"}
              onChangeType={() => onChangeType("cash")}
              cashType
              cashBalance={cashBalance[baseCurrency]}
              baseCurrency={baseCurrency}
            />
          ) : null}
        </Row>
      </BorderCont>
      <Row padding="20px 30px">
        <Text fSize={18}>
          Total shares:{" "}
          <span>
            {numberWithCommas(amount ? amount : 0)} {tokenIdentifier}
          </span>
        </Text>
      </Row>
    </Card>
  );
};
