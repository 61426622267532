import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import { PieChart } from "react-minimal-pie-chart";
import { CircularProgress } from "@material-ui/core";

import {
  StatisticsWrapper,
  Card,
  RouteLink,
  Heading,
  CardStatistic,
  ChartRow,
  ChartCol,
  CardHeading,
  ViewRow,
  Label,
  Bold,
  AvatarWrapper,
  Avatar,
  Table,
  TRow,
  Ceil,
  CenterContainer,
  ColSmall
} from "./styled";
import { ReactComponent as ArrowLeft } from "../../../../../assets/arrowleft.svg";
import { ReactComponent as NoAvatar } from "../../../../../assets/no-avatar.svg";
import { ReactComponent as ArrowFolder } from "../../../../../assets/chevron-down.svg";
import { ReactComponent as FolderClose } from "../../../../../assets/folder-close.svg";
import { ReactComponent as FolderOpen } from "../../../../../assets/folder-open.svg";
import { ReactComponent as File } from "../../../../../assets/file-11.svg";
import { ChartData, DocName, FolderName } from "../DealDocs/styled";
import DataRoomApi from "../../../../helpers/api";
import { ErrorMessage } from "../DealDocs/errorMessage";
import theme from "models/themes";

const chartColors = [
  theme.green,
  "#3F4B78",
  theme.primaryLight,
  theme.primaryMiddle
];

const UserStatisctic = ({
  match: {
    params: { assetId, userName }
  },
  token
}) => {
  const [avatar, setAvatar] = useState(null);
  const [data, setData] = useState(null);
  const [errorObj, setError] = useState(null);
  const [load, setLoad] = useState(true);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const res = await DataRoomApi.getUserViewStatistic(
      token,
      assetId,
      userName
    ).catch(e => {
      setLoad(false);
      setError(e);
    });

    if (res) {
      setLoad(false);
      setData(res);
      if (res.userViewStatistic.logo) {
        const {
          secureHash,
          fileNameWithExtension
        } = res.userViewStatistic.logo;
        const blob = await DataRoomApi.getAttachment(
          token,
          secureHash,
          fileNameWithExtension
        );

        setAvatar(blob);
      }
    }
  };

  const renderViewsByFolders = arr => {
    const sorted = arr.sort((a, b) => {
      if (a.percentage > b.percentage) return a;
    });

    let formatedData = {};

    sorted
      .filter(e => e)
      .forEach(({ folderName, percentage }) => {
        formatedData = {
          ...formatedData,
          ...{ [folderName]: percentage.toFixed(2) }
        };
      });
    sorted.length = 4;
    const pieData = sorted
      .filter(e => e)
      .map(({ folderName, percentage }, i) => ({
        title: folderName,
        value: percentage,
        color: chartColors[i]
      }));

    return (
      <CardStatistic width="calc(100% - 513px)" height="126px">
        <ChartRow>
          <ChartCol m="0 25px 0 0">
            <PieChart
              data={
                pieData.some(e => e.value)
                  ? pieData
                  : [{ title: "No views", value: 100, color: "#b6b6b699" }]
              }
              style={{
                width: 86,
                height: 86
              }}
            />
          </ChartCol>
          <ChartCol w="calc(100% - 106px)">
            <CardHeading>Views by Folders</CardHeading>
            <ChartData data={formatedData} />
          </ChartCol>
        </ChartRow>
      </CardStatistic>
    );
  };

  const renderFolders = arr =>
    arr
      .filter(f => {
        if (f.name !== "Unsorted Files") {
          return f;
        }
      })
      .map(f => <Folder key={f.name} folder={f} />);

  if (data) {
    const {
      filesViewStatistics: {
        folders,
        dealInfo: { assetName }
      },
      userViewStatistic: { fullName, logo, totalViews, spentTime, avgPerView },
      viewsByFolders
    } = data;
    return (
      <StatisticsWrapper>
        {/* header */}
        <Card width="100%" p="0 20px">
          <RouteLink
            width={150}
            to={`/admin/data-room/deal/${assetId}/statistics`}
            transparent="1"
          >
            <ArrowLeft />
            back to statistic
          </RouteLink>
          <Heading>
            {fullName}’s {assetName} statistics
          </Heading>
        </Card>

        {/* cards */}
        <CardStatistic width="493px" height="126px">
          <CardHeading ml="121px">{fullName}</CardHeading>
          <ViewRow>
            <AvatarWrapper>
              {logo && !avatar ? null : avatar ? (
                <Avatar src={avatar} alt={userName} />
              ) : (
                <NoAvatar />
              )}
            </AvatarWrapper>
            <ColSmall>
              <Label>spent time</Label>
              <Bold>{spentTime}</Bold>
            </ColSmall>
            <ColSmall>
              <Label>total views</Label>
              <Bold>{totalViews}</Bold>
            </ColSmall>
            <ColSmall>
              <Label>avg. per view</Label>
              <Bold>{avgPerView}</Bold>
            </ColSmall>
          </ViewRow>
        </CardStatistic>

        {renderViewsByFolders(viewsByFolders)}

        {/* table */}
        <Table>
          <TRow>
            <Ceil big>Description</Ceil>
            <Ceil>spent time</Ceil>
            <Ceil>total views</Ceil>
            <Ceil>AVG. per view</Ceil>
          </TRow>
          {renderFolders(folders)}
        </Table>
      </StatisticsWrapper>
    );
  } else if (errorObj) {
    return <ErrorMessage er={errorObj} onClose={() => setError(null)} />;
  } else if (load) {
    return (
      <CenterContainer>
        <CircularProgress />
      </CenterContainer>
    );
  } else {
    return (
      <StatisticsWrapper>
        <Card width="100%" p="0 20px">
          <RouteLink
            width={150}
            to={`/admin/data-room/deal/${assetId}/statistics`}
            transparent="1"
          >
            <ArrowLeft />
            back to statistic
          </RouteLink>
          <Heading>{userName}’s statistics</Heading>
        </Card>
      </StatisticsWrapper>
    );
  }
};

export const Folder = ({
  folder: { averagePerView, spentTime, files, name, section, totalViews }
}) => {
  const [open, setOpen] = useState(false);

  const renderDocs = section => {
    return files.map(
      ({ averagePerView, spentTime, name, subsection, totalViews }) => (
        <TRow key={`${name}=${subsection}`}>
          <Ceil big isFile>
            <DocName>
              <File />
              {section ? `${section}.${subsection}.` : null} {name}
            </DocName>
          </Ceil>
          <Ceil>
            <Bold transform="lowercase" fW="normal">
              {spentTime === "0 min" ? "-" : spentTime}
            </Bold>
          </Ceil>
          <Ceil>
            <Bold transform="lowercase" fW="normal">
              {totalViews || "-"}
            </Bold>
          </Ceil>
          <Ceil>
            <Bold transform="lowercase" fW="normal">
              {averagePerView ? `${averagePerView.toFixed()}min` : "-"}
            </Bold>
          </Ceil>
        </TRow>
      )
    );
  };

  return (
    <Fragment>
      <TRow onClick={() => setOpen(!open)}>
        <Ceil big>
          <FolderName open={open}>
            <ArrowFolder />
            {!open ? <FolderClose /> : <FolderOpen />}
            {section ? `${section}.` : null} {name}
          </FolderName>
        </Ceil>
        <Ceil>
          <Bold transform="lowercase">
            {spentTime === "0 min" ? "-" : spentTime}
          </Bold>
        </Ceil>
        <Ceil>
          <Bold transform="lowercase">{totalViews || "-"}</Bold>
        </Ceil>
        <Ceil>
          <Bold transform="lowercase">
            {averagePerView ? `${averagePerView.toFixed()}min` : "-"}
          </Bold>
        </Ceil>
      </TRow>
      {open ? renderDocs(section) : null}
    </Fragment>
  );
};

export default connect(
  ({ userReducer: { token } }) => ({
    token
  }),
  null
)(UserStatisctic);
