import Api from "./api";

export const getTrackerHTML_URL = (
  token,
  fileNameWithExtension,
  hash,
  docusign = false,
  isDataRoom = false,
  isInvestorViewer = false
) => {
  try {
    const { username, fullName, role } = JSON.parse(
      localStorage.getItem("global_user_flag")
    );
    return `${window.location.origin}/file-viewer.html#${JSON.stringify({
      username,
      fullName,
      role,
      fileNameWithExtension,
      hash,
      docusign,
      domain: Api.url,
      fileServerDomain: Api.url,
      originVoc: Api.origin,
      isDataRoom,
      origin: window.location.origin,
      isInvestorViewer,
    })}`;
  } catch (e) {
    console.log(e);
  }
};
export const getTrackerHTML = (
  token,
  fileNameWithExtension,
  hash,
  docusign = false,
  isDataRoom = false,
  isInvestorViewer = false,
  isReportS3 = false
) => {
  try {
    const { username, fullName, role } = JSON.parse(
      localStorage.getItem("global_user_flag")
    );
    window.open(
      `${window.location.origin}/file-viewer.html#${JSON.stringify({
        username,
        fullName,
        role,
        fileNameWithExtension,
        hash,
        docusign,
        domain: Api.url,
        fileServerDomain: Api.url,
        originVoc: Api.origin,
        isDataRoom,
        isReportS3,
        origin: window.location.origin,
        isInvestorViewer,
      })}`,
      "_blank"
    );
  } catch (e) {
    console.log(e);
  }
};
