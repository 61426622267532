import React, { Fragment, useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { CircularProgress } from "@material-ui/core";

import { Header, Heading, Loading, Row, Separator } from "./styled";
import { Btn } from "../../buttons";
import { ReactComponent as SettingsIcon } from "../../../../assets/settings.svg";
import { ReactComponent as ExpandIcon } from "../../../../assets/chevron-up.svg";

import Api from "../../../../helpers/api";
import { getAssets, getEvents, updateAdminNotifications } from "../../../../modules/dataRedux/dataAction";

import { Deal } from "./DealCard";
import { Transactions } from "./Transactions.js";
// import { CalendarBlock } from "./calendar";
import { Requests } from "./requests";
import { QuickViewList } from "./QuickViewList.js";

import { Calendar } from "components/common/calendar";

const DashBoard = ({
  token,
  history,
  getAssets,
  assets,
  adminChatThemes,
  unreadMessages,
  socketConnect,
  getEvents,
  updateAdminNotifications,
}) => {
  const [hideQuickView, setHideQuickView] = useState(false);
  const [loading, setLoading] = useState(false);
  const [viewList, setViewList] = useState(null);
  const [viewListSettings, setViewListSettings] = useState(null);
  const [transactions, setTransactions] = useState(null);
  const [filterOpen, setFilterOpen] = useState(false);
  const once = useRef(true);

  const loadData = async () => {
    if (!assets.length) await getAssets(token);
    setLoading(true);
    await Promise.all([
      Api.getDashboardQuickViewList(token),
      Api.getDashboardQuickViewListSettings(token),
      Api.getDashboardTransactions(token),
    ]).then((results) => {
      setViewList(results[0]);
      setViewListSettings(results[1]);
      setTransactions(results[2]);
    });
    setLoading(false);
  };

  useEffect(() => {
    if (socketConnect || (socketConnect && unreadMessages)) {
      if(once.current) {
        once.current = false;
        Api.getInvestorsMessages(token);
      }
    }
  }, [socketConnect, unreadMessages]);

  const refreshTransactions = () => {
    Api.getDashboardTransactions(token).then((res) => {
      setTransactions(res);
      const pending = res.filter((t) => t.movementType === "PENDING").length;
      updateAdminNotifications(pending, "Dashboard");
    });
  };

  useEffect(() => {
    loadData();
  }, []);

  const onChangeQuickView = () => {
    setHideQuickView(!hideQuickView);
  };

  const openFilterModal = () => setFilterOpen(!filterOpen);

  const renderDeals = () => {
    return viewList.map((e) => {
      return <Deal history={history} chartData={e.chart} deal={e} key={e.assetName} />;
    });
  };

  const getDealsImages = () => {
    if (viewListSettings) {
      const images = {};
      viewListSettings.forEach(({ assetName, mainImage }) => {
        images[assetName] = mainImage;
      });

      return images;
    } else return {};
  };

  return (
    <Fragment>
      <Header>
        {!hideQuickView && (
          <Fragment>
            <Heading>Now showing quick view of </Heading>
            <Heading bold>{viewList ? `${viewList.length} deals` : null}</Heading>
          </Fragment>
        )}

        <Btn transparent width={180} margin="0 12px 0 auto" onClick={openFilterModal}>
          <SettingsIcon />
          Edit quick view list
        </Btn>
        <Btn
          rightIcon
          transparent={!hideQuickView}
          transform={Number(hideQuickView)}
          width={180}
          onClick={onChangeQuickView}
        >
          {hideQuickView ? "show" : "hide"} deals quick view <ExpandIcon />
        </Btn>
      </Header>
      {loading || !viewList || !transactions || !QuickViewList ? (
        <Loading>
          <CircularProgress />
        </Loading>
      ) : (
        <Fragment>
          {!hideQuickView && renderDeals()}
          {transactions.length ? (
            <Transactions
              history={history}
              transactions={transactions}
              token={token}
              refresh={refreshTransactions}
              assets={assets}
              dealsImages={getDealsImages()}
            />
          ) : null}
          <Separator />
          <Row>
            <Requests token={token} adminChatThemes={adminChatThemes} />
            <div style={{ width: "calc(310px - 15px)" }}>
              <Calendar
                token={token}
                assetName={viewList && viewList[0] && viewList[0].assetName}
                assetNames={viewList && viewList.map((e) => e.assetName)}
                updateAction={getEvents}
              />
            </div>
          </Row>

          {/* modal */}

          <QuickViewList
            token={token}
            isOpen={filterOpen}
            deals={viewListSettings}
            onBackdropClick={openFilterModal}
            onReload={loadData}
          />
        </Fragment>
      )}
    </Fragment>
  );
};

export default connect(
  ({
    userReducer: { token },
    dataReducer: { assets = [] },
    chatReducer: { socketConnect, adminChatThemes, unreadMessages },
  }) => ({
    token,
    assets,
    adminChatThemes,
    unreadMessages,
    socketConnect,
  }),
  { getAssets, getEvents, updateAdminNotifications }
)(DashBoard);
