import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import { CircularProgress } from "@material-ui/core";

import {
  Header,
  BackLink,
  Heading,
  Flex,
  Card,
  Separator,
  GreyBlock,
  UserBlock,
  UsersBlock,
  SmallLogo,
  EmptyLogo,
} from "./styled";
import { Btn } from "../../buttons";
import { ReactComponent as ArrowLeft } from "../../../../assets/arrowleft.svg";
import { ReactComponent as UserIcon } from "../../../../assets/user-plus.svg";
import { ReactComponent as NoAvatar } from "../../../../assets/no-avatar.svg";
import { ReactComponent as Close } from "../../../../assets/x-close-red.svg";
import {
  TextFieldComponent,
  SelectComponent,
  GroupsLogoUploader,
} from "../../forms";
import Api from "../../../../helpers/api";
import { ErrorMessage, SuccessMessage } from "../../errorMessage";

const NewGroup = ({
  token,
  history,
  match: {
    params: { id },
  },
}) => {
  const [name, setName] = useState("");
  const [usersList, setUsersList] = useState([]);
  const [allUsers, setAllUsers] = useState(null);
  const [logo, setLogo] = useState(null);
  const [load, setLoad] = useState(true);
  const [wait, setWait] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState("");

  useEffect(() => {
    // Раз она оноразовая то можно создать ее прям сдесь
    const getInitial = async () => {
      const res = await Api.getUsersList(token);
      if (res) {
        setAllUsers(res.content);
        setLoad(false);
      }
    };
    getInitial();
  }, []);

  // Переделал селек на ids
  const onAddInvestors = (val = []) => setUsersList(val);

  useEffect(() => {
    console.log(usersList);
  }, [usersList]);

  const renderUsers = () => {
    return usersList.reduce((users, _id) => {
      const user = allUsers.find((el) => el.id === _id);
      return [
        ...users,
        <User
          data={{ ...user }}
          key={`${user.username}-${user.fullName}`}
          token={token}
          onRemove={(id) => onRemoveInvestor(id)}
        />,
      ];
    }, []);
  };

  const onRemoveInvestor = (id) => {
    setUsersList(usersList.filter((_id) => _id !== id));
  };

  const onSubmit = async () => {
    setWait(true);
    let upload;
    if (logo) {
      const _upload = await Api.uploadAttachment(token, logo).catch((e) => {
        setWait(false);
        setError(e);
      });
      upload = _upload;
    }

    const data = {
      name,
      logoHash: upload ? upload.secureHash : "",
      logoFileName: upload ? upload.fileNameWithExtension : "",
      users: usersList,
    };

    const res = await Api.createGroup(token, data).catch((e) => {
      setWait(false);
      setError(e);
    });

    if (res) {
      setWait(false);
      setSuccess("Group successfully created.");
    }
  };

  if (load) {
    return <CircularProgress style={{ margin: "auto" }} />;
  } else {
    return (
      <Fragment>
        <Header>
          <BackLink to="/admin/users/groups">
            <ArrowLeft /> back to Groups
          </BackLink>
          <Heading>Add New Group</Heading>
        </Header>
        <Flex>
          <Card>
            <Heading>Main Information</Heading>
            <TextFieldComponent
              label="Group Name"
              placeholder="Group Name"
              defaultValue={name}
              onChange={(e) => setName(e.target.value)}
            />
            <Separator />
            <GroupsLogoUploader
              label="Group Image"
              onChange={(file) => setLogo(file)}
            />
          </Card>
          <Card big>
            <Heading>
              Users {usersList.length ? `(${usersList.length})` : null}
            </Heading>
            <SelectComponent
              multiple
              // Массив значений которыми будет оперировать компонент
              values={allUsers.map((e) => e.id)}
              // Массив надписей которые будут отображены, должен совпадать по длинне с values
              labels={allUsers.map((e) => e.fullName)}
              // будет изменять внутренний стейт на заднный каждый раз когда они не равны
              controlledValue={usersList}
              onChange={(val) => onAddInvestors(val)}
              label="assign users to group"
            />
            <Separator />
            {usersList.length ? (
              <UsersBlock>{renderUsers()}</UsersBlock>
            ) : (
              <GreyBlock>
                <UserIcon />
                <div>Assign users to this group using the field above</div>
              </GreyBlock>
            )}
          </Card>
        </Flex>
        <Header isFooter>
          <Btn width={180} hide>
            Cancel
          </Btn>
          <Btn
            margin="0 0 0 auto"
            width={180}
            onClick={onSubmit}
            disabled={wait || !name}
          >
            Create group
          </Btn>
        </Header>

        {error ? (
          <ErrorMessage er={error} onClose={() => setError(null)} />
        ) : null}
        {success ? (
          <SuccessMessage
            message={success}
            onClose={() => history.push("/admin/users/groups")}
          />
        ) : null}
      </Fragment>
    );
  }
};

export default connect(
  ({ userReducer: { token } }) => ({ token }),
  null
)(NewGroup);

const User = ({
  token,
  data: { userLogoFileName, userLogoHash, fullName, username, id },
  onRemove,
}) => {
  const [avatar, setAvatar] = useState(null);

  useEffect(() => {
    if (!avatar) {
      Api.getAttachment(token, userLogoHash, userLogoFileName).then((b) =>
        setAvatar(b)
      );
    }
  });

  return (
    <UserBlock>
      {userLogoFileName && userLogoHash && !avatar ? (
        <EmptyLogo />
      ) : !userLogoHash || !userLogoFileName ? (
        <NoAvatar />
      ) : (
        <SmallLogo src={avatar} />
      )}
      <span>{fullName}</span>
      <Close onClick={() => onRemove(id)} />
    </UserBlock>
  );
};
