import React from "react";
import styled from "styled-components";
import { ShadowCard, ContainerFlex } from "../../containers/container";

export const Card = (props) => (
  <ShadowCard
    paddingSize="20px 10px 10px 10px"
    marginSize="10px 0"
    justify="space-between"
    overflow="visible"
    wrap
    row
    {...props}
  >
    {props.children}
  </ShadowCard>
);

export const LeftContainer = styled(ContainerFlex)`
  width: calc(69% - 30px);
`;

export const RightContainer = styled(ContainerFlex)`
  width: calc(31%);
  z-index: -1;
  position: relative;
  overflow: visible;
`;

export const EmptyNewDeal = {
  newWorkflow: true,
  roundsInfo: [],
  assetProperties: {
    assetName: "",
    assetSymbol: "",
    category: "real estate",
    dealsType: "NEW_DEAL",
    investorWorkflowType: "",
    assetLocation: "",
    images: [],
    vocAssetDynamicProperties: [],
    descriptionHeader: "",
    smallDescription: "",
    fullDescription: "",
    targetInvestorIRR: "",
    targetEquityMultiple: "",
    targetAverageCashYield: "",
    targetedInvestmentPeriod: "",
    acquisitionDate: "",
    preferredReturnAccrualDate: "",
    acquisitionCost: "",
    targetedProjectLevelIRR: "",
    distributionCommencement: "",
    distributionPeriod: "",
    targetPortfolioCloseDate: "",
    softCap: "",
    hardCap: "",
    period: "Year",
    currency: "GBP",
    documents: {
      teaser: {
        attachmentFile: { fileNameWithExtension: "", secureHash: "" },
        loiRequired: null,
        ndaRequired: null,
        description: "",
        visibility: true,
      },
      nda: {
        attachmentFile: { fileNameWithExtension: "", secureHash: "" },
        loiRequired: null,
        ndaRequired: null,
        description: "",
        visibility: true,
      },
      infoMemo: {
        attachmentFile: { fileNameWithExtension: "", secureHash: "" },
        loiRequired: null,
        ndaRequired: null,
        description: "",
        visibility: true,
      },
      loi: {
        attachmentFile: { fileNameWithExtension: "", secureHash: "" },
        loiRequired: null,
        ndaRequired: null,
        description: "",
        visibility: true,
      },
      dealInfo: {
        attachmentFile: { fileNameWithExtension: "", secureHash: "" },
        loiRequired: null,
        ndaRequired: null,
        description: "",
        visibility: true,
      },
      spa: {
        attachmentFile: { fileNameWithExtension: "", secureHash: "" },
        loiRequired: null,
        ndaRequired: null,
        description: "",
        visibility: true,
      },
      otherDocs: [],
    },
    dynamicCapitalStructureName: null,
    dynamicCapitalStructureJson: '{"cardName":"Capital Structure","fields":[]}',
    dealTypeFinancial: "DEBT",
    dynamicFinancialJson:
      '[{"name":"Target Investor IRR (%)","value":""},{"name":"Target Equity Multiple (x)","value":""},{"name":"Target Average Cash Yield (%)","value":""}]',
  },
  sharingProperty: { company: "VoC", sharedCompanies: ["Voc"] },
  visibilityProperty: {
    access: "LOG_IN",
    isActive: true,
    countryRestriction: { listType: "ALL" },
    lockUpPeriod: {},
    groups: null,
    users: null,
    activeRulesJson: '{"countryRestriction":false,"lockUpPeriod":false,"groups":false,"users":false}',
  },
  assetFinancialProperty: {
    baseCurrency: "GBP",
    acceptedCurrency: ["GBP"],
    tokenQuantity: "",
    minInvestment: "",
    maxInvestment: "",
    sponsorCoInvestment: -1,
    investorEquity: -1,
    seniorDebt: -1,
    account: { accountName: "", accountNumber: "", bankName: "", bic: "", iban: "" },
  },
  managerSponsors: [],
  assetKycTemplates: { institutionTemplateId: 0, otherRolesTemplates: {}, professionalTemplateId: 0 },
};

export const EmptyExistedDeal = {
  newWorkflow: true,
  roundsInfo: [],
  assetProperties: {
    assetName: "",
    assetSymbol: "",
    category: "real estate",
    dealsType: "EXISTED_DEAL",
    investorWorkflowType: "",
    assetLocation: "",
    images: [],
    vocAssetDynamicProperties: [],
    descriptionHeader: "",
    smallDescription: "",
    fullDescription: "",
    targetInvestorIRR: "",
    targetEquityMultiple: "",
    targetAverageCashYield: "",
    targetedInvestmentPeriod: "",
    acquisitionDate: "",
    preferredReturnAccrualDate: "",
    acquisitionCost: "",
    targetedProjectLevelIRR: "",
    distributionCommencement: "",
    distributionPeriod: "",
    targetPortfolioCloseDate: "",
    softCap: "",
    hardCap: "",
    period: "Year",
    currency: "GBP",
    documents: {
      teaser: {
        attachmentFile: { fileNameWithExtension: "", secureHash: "" },
        loiRequired: null,
        ndaRequired: null,
        description: "",
        visibility: true,
      },
      nda: {
        attachmentFile: { fileNameWithExtension: "", secureHash: "" },
        loiRequired: null,
        ndaRequired: null,
        description: "",
        visibility: true,
      },
      infoMemo: {
        attachmentFile: { fileNameWithExtension: "", secureHash: "" },
        loiRequired: null,
        ndaRequired: null,
        description: "",
        visibility: true,
      },
      loi: {
        attachmentFile: { fileNameWithExtension: "", secureHash: "" },
        loiRequired: null,
        ndaRequired: null,
        description: "",
        visibility: true,
      },
      dealInfo: {
        attachmentFile: { fileNameWithExtension: "", secureHash: "" },
        loiRequired: null,
        ndaRequired: null,
        description: "",
        visibility: true,
      },
      spa: {
        attachmentFile: { fileNameWithExtension: "", secureHash: "" },
        loiRequired: null,
        ndaRequired: null,
        description: "",
        visibility: true,
      },
      otherDocs: [],
    },
    dynamicCapitalStructureName: null,
    dynamicCapitalStructureJson: null,
    dealTypeFinancial: "DEBT",
    dynamicFinancialJson:
      '[{"name":"Target Investor IRR (%)","value":""},{"name":"Target Equity Multiple (x)","value":""},{"name":"Target Average Cash Yield (%)","value":""}]',
  },
  sharingProperty: { company: "VoC", sharedCompanies: ["Voc"] },
  visibilityProperty: {
    access: "LOG_IN",
    isActive: true,
    countryRestriction: { listType: "ALL" },
    lockUpPeriod: {},
    groups: null,
    users: null,
    activeRulesJson: '{"countryRestriction":false,"groups":false,"users":false}',
  },
  assetFinancialProperty: {
    baseCurrency: "GBP",
    acceptedCurrency: ["GBP"],
    tokenQuantity: "",
    minInvestment: "",
    maxInvestment: "",
    sponsorCoInvestment: -1,
    investorEquity: -1,
    seniorDebt: -1,
    account: { accountName: "", accountNumber: "", bankName: "", bic: "", iban: "" },
  },
  managerSponsors: [],
  assetKycTemplates: null,
};
