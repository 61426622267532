import React, { Fragment } from "react";
import styled from "styled-components";
import { CircularProgress } from "@material-ui/core";

import { Folder } from "../../../../../data-room-panel/data-room-layout/admin/pages/DealDocs/Statistics";
import { Row, TH, CeilHead } from "./StatisticTable";

const Card = styled.div`
  width: 55.2%;
  background: #ffffff;
  border-radius: 4px;
  display: flex;
  align-self: stretch;
  flex-direction: column;
  border: 1px solid #efefef;
`;

export const FoldersTable = ({ info }) => {
  const renderFolders = () => {
    const folders = info.filesViewStatistics.folders;
    return folders.map((f) => <Folder isDeal key={f.name} folder={f} />);
  };

  return (
    <Card>
      {!info ? (
        <CircularProgress size={24} style={{ margin: "auto" }} />
      ) : (
        <Fragment>
          <Row>
            <TH long>
              <CeilHead>Description</CeilHead>
            </TH>
            <TH>
              <CeilHead>% of all time</CeilHead>
            </TH>
            <TH>
              <CeilHead>Average time</CeilHead>
            </TH>
            <TH style={{ borderRight: "none" }}>
              <CeilHead>total views</CeilHead>
            </TH>
          </Row>
          {renderFolders()}
        </Fragment>
      )}
    </Card>
  );
};
