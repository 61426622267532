import React, { Fragment, useState, useRef } from "react";
import moment from "moment";
import { Draggable } from "react-beautiful-dnd";

import { TRow, TCeil, DocName, Tooltip, Action, NewFolderBlock, Input, Submit, Close } from "../styled";
import { File } from "components/common/icons-primary";
import { ReactComponent as Edit } from "../../../../../../assets/edit-small.svg";
import { ReactComponent as Replace } from "../../../../../../assets/repeat-small.svg";
import { ReactComponent as Delete } from "../../../../../../assets/del-small.svg";
import { ReactComponent as Watermark } from "../../../../../../assets/watermark.svg";
import { ReactComponent as Check } from "../../../../../../assets/check.svg";
import { ReactComponent as CloseIcon } from "../../../../../../assets/x.svg";
import { ReactComponent as Upload } from "../../../../../../assets/upload.svg";
import { ReactComponent as RulesIcon } from "../../../../../asset/sliders.svg";
import { ReactComponent as Repeat } from "../../../../../asset/repeat-grey.svg";
import { ReactComponent as Star } from "../../../../../asset/star.svg";
import { ReactComponent as StarBlue } from "../../../../../asset/star-blue.svg";

import { UploadButton, ReplaceButton, RulesButton, StarBlock, FavTooltip, SeparateLine } from "./styled";
import DataRoomApi from "../../../../../helpers/api";
import { ErrorMessage } from "../errorMessage";
import { ReplaceFile } from "./replaceFile";
import { SetRules } from "./setRules";
import { FileMessagePortal } from "components/admin-layout/fileMessage";

export const FileComponent = ({
  file: {
    type,
    name: fileName,
    size,
    lastUpdate,
    subsection,
    uploaded,
    afterSubscription,
    automated,
    checked,
    starred,
    watermarked,
    documentType,
  },
  file,
  i,
  section,
  token,
  assetId,
  onDelteFile,
  onRename,
  onReplace,
  folderName,
  onDoubleClick,
  isOpenViewer,
  setDocInfo,
  history,
}) => {
  const [renameActive, setRenameActive] = useState(false);
  const [wait, setWait] = useState(false);
  const [newName, setNewName] = useState(fileName.replace(/\.[^/.]+$/, ""));
  const [errorObj, setError] = useState(null);
  const [replaceActive, setReplaceActive] = useState(false);
  const [rulesOpen, setRulesOpen] = useState(false);
  const [favorite, setFavorite] = useState(starred);
  const [stamp, setStamp] = useState(watermarked);
  const [messageOpen, setMessageOpen] = useState(false);
  const inputRef = useRef(null);

  const {
    location: { pathname },
  } = window;
  const from = pathname.includes("NEW_DEAL") ? "NEW_DEAL" : pathname.includes("EXISTED_DEAL") ? "EXISTED_DEAL" : "";

  const onChangeFavorite = async () => {
    setWait(true);
    const res = await DataRoomApi.staringDoc(token, assetId, fileName, folderName).catch((e) => {
      setWait(false);
      setError(e);
    });
    if (res) {
      setFavorite(!favorite);
      onRename();
    }
  };

  const onChangeWatermark = async () => {
    setWait(true);
    setStamp(!stamp);
    const res = await DataRoomApi.watermarkingDoc(token, assetId, fileName, folderName).catch((e) => {
      setWait(false);
      setStamp(!stamp);
      setError(e);
    });
    if (res) {
      onRename();
    }
  };

  const onChangeRulesModal = () => {
    setRulesOpen(!rulesOpen);
  };

  const onChangeRenameStatus = () => {
    setRenameActive(!renameActive);
  };

  const onChangeReplaceStatus = () => {
    setReplaceActive(!replaceActive);
  };

  const onSubmit = async () => {
    const newFileName = newName + `.${type}`;
    if (fileName === newFileName) {
      onChangeRenameStatus();
      return;
    }
    setWait(true);
    const res = await DataRoomApi.renameFile(token, fileName, newFileName, assetId, folderName).catch((e) => {
      setWait(false);
      setError(e);
    });
    if (res) {
      setWait(false);
      onRename();
    }
  };

  const onGoToLegalDocPage = () => {
    setDocInfo(
      {
        assetId,
        fileName,
        data: { ...file },
      },
      () =>
        history.push(
          `/admin/data-room/deal/legal-document/${assetId}/${fileName}/${afterSubscription}${from ? `#${from}` : ""}`
        )
    );
  };

  const onReplaceUploadedLegal = async (e) => {
    setWait(true);
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append(`file`, file, file.name);

    const replace = await DataRoomApi.replaceLegalDocument(token, assetId, formData, fileName, afterSubscription).catch(
      (e) => {
        setError(e);
        setWait(false);
      }
    );

    if (replace) {
      setWait(false);
      await onReplace();
      onGoToLegalDocPage();
    }
  };

  const onOpenFileDialog = () => {
    if (inputRef && inputRef.current) {
      inputRef.current.click();
    }
  };

  const onOpenMessage = () => {
    setMessageOpen(!messageOpen);
  };

  const downloadFile = async () => {
    setWait(true);
    const fileBlob = await DataRoomApi.downloadFileFromModal(token, folderName, fileName, assetId).catch((e) => {
      onOpenMessage();
      setError(e);
      setWait(false);
    });

    if (fileBlob) {
      setWait(false);
      onOpenMessage();
      const reader = new FileReader();
      reader.readAsDataURL(fileBlob);
      reader.onload = () => {
        const link = document.createElement("a");
        link.href = reader.result;
        link.download = file.name;
        link.click();
      };
    }
  };

  return (
    <Draggable
      key={`${fileName}-${folderName}-${section}-${subsection}`}
      draggableId={`${fileName}-${folderName}-${section}-${subsection}`}
      index={i}
    >
      {(provided) => (
        <TRow
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          key={`${fileName}-${size}-${lastUpdate}`}
          onDoubleClick={
            folderName === "Legal Documents" && !uploaded
              ? undefined
              : type === "xlsx" || type === "xlx"
              ? onOpenMessage
              : onDoubleClick
          }
        >
          <TCeil pos="relative" big p={78} full={isOpenViewer}>
            <DocName>
              <File />
              {renameActive ? (
                <NewFolderBlock>
                  <Input placeholder="New File Name" value={newName} onChange={(e) => setNewName(e.target.value)} />
                  <Submit onClick={onSubmit} disabled={wait}>
                    <Check />
                  </Submit>
                  <Close ml="5px" onClick={onChangeRenameStatus}>
                    <CloseIcon />
                  </Close>
                </NewFolderBlock>
              ) : (
                <Fragment>
                  <span>
                    {section !== 0 ? `${section}.${subsection}.` : null} {fileName}
                  </span>
                  {folderName !== "Legal Documents" ? (
                    <Tooltip>
                      {type === "pdf" ? (
                        <Fragment>
                          <Action
                            radio
                            val={stamp}
                            name={stamp ? "watermark" : "set watermark"}
                            icon={<Watermark />}
                            onClick={wait ? undefined : onChangeWatermark}
                          />
                          <SeparateLine />
                        </Fragment>
                      ) : null}
                      <Action name="change name" icon={<Edit />} onClick={onChangeRenameStatus} />
                      <Action name="Replace file" icon={<Replace />} onClick={onChangeReplaceStatus} />
                      <Action
                        name="Remove file"
                        icon={<Delete />}
                        onClick={(e) => onDelteFile(e, token, folderName, fileName, assetId)}
                      />
                    </Tooltip>
                  ) : (
                    <Fragment>
                      {!uploaded ? (
                        <UploadButton onClick={onGoToLegalDocPage}>
                          <Upload /> Upload & SET AUTOMATION
                        </UploadButton>
                      ) : uploaded && !checked ? (
                        <UploadButton onClick={onGoToLegalDocPage}>
                          <Check /> check & set automation
                        </UploadButton>
                      ) : (uploaded && checked && !automated) || automated ? (
                        <Fragment>
                          {!documentType ? (
                            <RulesButton automated={automated} onClick={onChangeRulesModal} disabled={wait}>
                              <RulesIcon />
                              SET RULES
                            </RulesButton>
                          ) : null}
                          <ReplaceButton ml={documentType ? "auto" : 0} disabled={wait} onClick={onOpenFileDialog}>
                            <Repeat /> Replace
                          </ReplaceButton>
                        </Fragment>
                      ) : null}
                    </Fragment>
                  )}
                </Fragment>
              )}
            </DocName>
            {folderName !== "Legal Documents" && folderName !== "Unsorted Files" ? (
              <StarBlock favorite={favorite} onClick={onChangeFavorite}>
                {favorite ? <StarBlue /> : <Star />}
                <FavTooltip
                  text={
                    favorite
                      ? "This document is showing on asset page. Unmark to hide."
                      : "Mark as favourite to show this document on asset page"
                  }
                />
              </StarBlock>
            ) : null}
          </TCeil>

          {isOpenViewer ? null : (
            <Fragment>
              <TCeil></TCeil>
              <TCeil lower>{type}</TCeil>
              <TCeil lower>{(size / 1048576).toFixed(2)} mb</TCeil>
              <TCeil lower middle>
                {lastUpdate ? moment(lastUpdate).format("DD.MM.YYYY, hh:mm A") : null}
              </TCeil>
            </Fragment>
          )}
          {errorObj ? <ErrorMessage er={errorObj} onClose={() => setError(null)} /> : null}
          {replaceActive ? (
            <ReplaceFile
              onBackdropClick={onChangeReplaceStatus}
              originalFile={fileName}
              folderName={folderName}
              token={token}
              assetId={assetId}
              refreshDocs={onReplace}
            />
          ) : null}
          {rulesOpen ? (
            <SetRules
              history={history}
              refreshDocs={onReplace}
              onClose={onChangeRulesModal}
              doc={file}
              token={token}
              assetId={assetId}
              onError={setError}
            />
          ) : null}
          {messageOpen ? (
            <FileMessagePortal
              onClose={onOpenMessage}
              title="Message"
              message={`Sorry, the browser does not support this file type. 
              <br/>However, you can download it.`}
              onDownload={downloadFile}
              disable={wait}
            />
          ) : null}
          <input
            type="file"
            accept=".docx"
            onChange={onReplaceUploadedLegal}
            ref={inputRef}
            style={{ display: "none" }}
          />
        </TRow>
      )}
    </Draggable>
  );
};
