import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { ReactComponent as Refresh } from "../../../../assets/refresh-cw.svg";
import { ReactComponent as Check } from "../../../../assets/check.svg";
import { ReactComponent as X } from "../../../../assets/rej.svg";
import { ReactComponent as Search } from "../../../../assets/search-icon.svg";

import theme from "models/themes";

export const WaitingWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.1);
  z-index: 1001;
`;

const StatusType = styled.div`
  height: 24px;
  padding: 0 10px;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  background: ${({ type }) => type};
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 150%;
  text-transform: uppercase;
  color: #ffffff;
  width: fit-content;
  > svg {
    margin-right: 6px;
    width: 9px;
    height: 9px;
    path {
      stroke: #ffffff;
    }
  }
`;

const types = {
  PROCESSING: {
    color: theme.primary,
    icon: <Refresh />,
  },
  MISSING: {
    color: "#CACACA",
    icon: <Search />,
  },
  APPROVED: {
    color: "#55B17C",
    icon: <Check />,
  },
  REJECTED: {
    color: "#FF4343",
    icon: <X />,
  },
  DRAFT: {
    color: "#98a4c7",
    icon: <Refresh />,
  },
  ALL: {
    color: "#98a4c7",
    icon: <Refresh />,
  },
};

export const Status = ({ status }) => {
  return (
    <StatusType type={types[status].color}>
      {types[status].icon}
      {status}
    </StatusType>
  );
};

export const Notes = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  text-overflow: ellipsis;
  letter-spacing: -0.02em;
  overflow: hidden;
  color: #484848;
  text-transform: none;
  height: 36px;
  white-space: pre-wrap;
`;

const FormFilterContainer = styled.div`
  height: 80px;
  width: 100%;
  background: #e4e4e4;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 15px;
  box-sizing: border-box;
  margin-bottom: 30px;
  position: relative;
`;

const FormButton = styled(Link)`
  width: 157px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ active }) => (active ? "#1F263E" : "transparent")};
  border-radius: 4px;
  font-family: Muli;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 135%;
  color: ${({ active }) => (active ? "#FFF" : "#1F263E;")};
  opacity: ${({ active }) => (active ? 1 : 0.6)};
  margin-right: 5px;
  text-decoration: none;
`;

export const FormFilter = ({ pathname }) => {
  return (
    <FormFilterContainer>
      <FormButton
        active={pathname === "/admin/kyc/form-settings/institution"}
        to="/admin/kyc/form-settings/institution"
      >
        Institution Users
      </FormButton>
      <FormButton
        active={pathname === "/admin/kyc/form-settings/professional"}
        to="/admin/kyc/form-settings/professional"
      >
        Professional Users
      </FormButton>
    </FormFilterContainer>
  );
};

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  border-top: 1px solid #efefef;
  flex-direction: ${({ column = false }) => (column ? "column" : "row")};
  justify-content: space-between;
  overflow: hidden;
  &:after {
    content: "";
    position: absolute;
    height: ${({ column = false }) => (column ? 0 : "calc(100% - 115px)")};
    width: 1px;
    left: 50%;
    top: 85px;
    background: #efefef;
  }
`;

const ParamRow = styled.div`
  width: ${({ fullWidth }) => (fullWidth ? "100%" : "calc(50% - 20px)")};
  height: 58px;
  border-bottom: ${({ noBorder = false }) => (noBorder ? "none" : "1px solid #efefef")};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ParamName = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 135%;
  letter-spacing: -0.02em;
  color: ${({ show }) => (show ? "#181818" : "rgba(24, 24, 24, 0.7)")};
  transition: color 0.1s linear;
  width: calc(100% - 175px);
  padding-right: 15px;
`;

const ShowButton = styled.div`
  width: 32px;
  height: 16px;
  position: relative;
  background: ${({ active, theme: { primary } }) => (active ? primary : "#EFF0F3")};
  transition: background linear 0.1s;
  border-radius: 8px;
  cursor: pointer;
  margin-right: 8px;
`;

const Circle = styled.div`
  width: 14px;
  height: 14px;
  background: ${({ active }) => (active ? "#fff" : "#616161")};
  border-radius: 8px;
  position: absolute;
  right: ${({ active }) => (active ? "1px" : "17px")};
  transition: right linear 0.1s;
  top: 1px;
`;

const ShowText = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 135%;
  letter-spacing: -0.02em;
  color: ${({ active, theme: { primary } }) => (active ? primary : "rgba(24, 24, 24, 0.7)")};
`;

const CheckBoxCont = styled.div`
  display: flex;
  align-items: center;
  margin-right: 14px;
  width: 80px;
  &:last-child {
    margin-right: 0;
  }
`;

const CheckBoxesCont = styled.div`
  display: flex;
  width: 165px;
  align-items: center;
  justify-content: space-between;
`;

const RequiredCheckBox = styled.div`
  overflow: hidden;
  width: 18px;
  height: 18px;
  margin-right: 14px;
  background: ${({ checked }) => (checked ? "transparent" : "#f3f3f3")};
  border: 1px solid ${({ checked, theme: { primary } }) => (checked ? primary : "#EFEFEF")};
  box-sizing: border-box;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  > svg {
    width: 14px;
    height: 14px;
    /* transform: ${({ checked }) => (checked ? "translateY(0)" : "translateY(-15px)")}; */
    transform: ${({ checked }) => (checked ? "rotateY(0)" : "rotateY(90deg)")};
    path {
      stroke: ${({ theme: { primary } }) => primary};
    }
    transition: all linear 0.1s;
  }
`;

export const Parameter = ({ label, show, required, onChange }) => {
  const [showParam, setShow] = useState(show);
  const [requiredParam, setRequiredParam] = useState(required);

  const onChangeRequired = () => {
    setRequiredParam(!requiredParam);
    onChange({
      show: showParam,
      required: !requiredParam,
    });
  };

  const onChangeStatus = () => {
    setShow(!showParam);
    onChange({
      show: !showParam,
      required: requiredParam,
    });
  };

  return (
    <ParamRow>
      <ParamName show={showParam}>{label}</ParamName>
      <CheckBoxesCont>
        <CheckBoxCont>
          <ShowButton active={showParam} onClick={onChangeStatus}>
            <Circle active={showParam} />
          </ShowButton>
          <ShowText active={showParam}>{showParam ? "Show" : "Hide"}</ShowText>
        </CheckBoxCont>
        <CheckBoxCont>
          <RequiredCheckBox checked={requiredParam} onClick={onChangeRequired}>
            <Check />
          </RequiredCheckBox>
          <ShowText active={requiredParam}>Required</ShowText>
        </CheckBoxCont>
      </CheckBoxesCont>
    </ParamRow>
  );
};

export const GeneralParam = ({ label, onChange, noBorder }) => {
  const [active, setActive] = useState(true);

  const onChangeStatus = () => {
    setActive(!active);
    onChange(!active);
  };

  return (
    <ParamRow fullWidth noBorder={noBorder}>
      <ParamName show={active}>{label}</ParamName>
      <CheckBoxCont>
        <ShowButton active={active} onClick={onChangeStatus}>
          <Circle active={active} />
        </ShowButton>
        <ShowText active={active}>{active ? "Show" : "Hide"}</ShowText>
      </CheckBoxCont>
    </ParamRow>
  );
};

export const Dots = styled.div`
  width: 32px;
  height: 32px;
  border: 1px solid ${({ theme: { primary } }) => primary};
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  transition: all 0.3s linear;
  cursor: pointer;
  position: relative;
  > svg {
    transition: all 0.3s linear;
    margin: auto;
    path {
      stroke: ${({ theme: { primary } }) => primary};
    }
  }
  &:hover {
    background-color: ${({ theme: { primary } }) => primary};
    > div {
      display: flex;
    }
    > svg {
      path {
        stroke: #fff;
      }
    }
  }
`;

export const Paper = styled.div`
  width: 200px;
  background: #ffffff;
  border: 1px solid #edebeb;
  box-sizing: border-box;
  box-shadow: 0px 10px 40px rgba(89, 120, 150, 0.06);
  border-radius: 4px;
  padding: 15px 20px;
  display: none;
  flex-direction: column;
  justify-content: space-between;
  height: ${({ big = false }) => (big ? "70px" : "44px")};
  position: absolute;
  bottom: 27px;
  right: -30px;
  z-index: 1;
  &:before {
    content: "";
    display: block;
    z-index: 2;
    width: 20px;
    height: 20px;
    border: 1px solid #edebeb;
    border-radius: 4px;
    position: absolute;
    transform: rotate(45deg);
    right: 33px;
    bottom: -9px;
    background: #fff;
  }
  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 30px;
    height: 16px;
    background: white;
    z-index: 3;
    right: 29px;
    bottom: 0;
  }
`;

export const ExtraLink = styled.div`
  font-weight: bold;
  font-size: 10px;
  line-height: 135%;
  display: flex;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  align-items: center;
  color: ${({ red }) => (red ? "rgb(255, 67, 67)" : "#181818")};
  text-decoration: none;
  z-index: 2;
  > svg {
    margin-right: 7px;
    width: 9px;
    height: 9px;
    path {
      stroke: ${({ red, theme: { primary } }) => (red ? "rgb(255,67,67)" : primary)};
    }
  }
`;
