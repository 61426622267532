import React, { useEffect, useState, Fragment } from "react";
import ReactDom from "react-dom";
import { connect } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import { Redirect } from "react-router-dom";

import Api from "../../../../helpers/api";
import { SignSettings } from "./SignSettings";
import {
  Header,
  BackButtonContainer,
  TextHeaderContainer,
  Row,
  View,
  ReaderWrapper,
  ViewerHeader,
  ViewerDocName,
  SizeDate,
  ExpandBtn,
  ExpandWrapper,
  Col,
  Card,
  CardHeader,
  ModalBg,
  Center,
} from "./styled";
import { Button } from "../Offerings/styled";
import { ReactComponent as BackIcon } from "../../../../assets/arrowleft.svg";
import { ReactComponent as Expand } from "../../../../assets/expand.svg";
import { ErrorMessage } from "../../../admin-layout/errorMessage";
import { onSetSignData } from "../../../../modules/dataRedux/dataAction";
import { formatUppercase } from "../../../../helpers";

const Preparing = ({ lastStep = false, signing = false }) => {
  return (
    <ModalBg>
      <Center>
        <CircularProgress />
        {!lastStep ? <div>Your document is being {signing ? "signed" : "generated"}</div> : null}
      </Center>
    </ModalBg>
  );
};

const DigitalSignature = ({
  token,
  match: {
    params: { assetId },
  },
  signData,
  onSetSignData,
  history,
  assets,
  redirectLink = `/investor/offerings/companies/${assetId}`,
}) => {
  const {
    dealWorkflowActionButtonText,
    assetProperties: { assetName },
  } = assets.find((e) => e.linearId === assetId);

  const [expand, setExpand] = useState(false);
  const [openSignSettings, setOpenSignSettings] = useState(false);
  const [preSignDocument, setPreSignDocument] = useState(null);
  const [signDocument, setSignDocument] = useState(null);
  const [useSignatureImage, setUseSignatureImage] = useState(false);
  const [digitalSignature, setDigitalSignature] = useState(null);
  const [error, setError] = useState(null);
  const [load, setLoad] = useState(true);
  const [wait, setWait] = useState(false);
  const [approveStep, setApproveStep] = useState(false);
  const [signStep, setSignStep] = useState(false);

  const onChangeExpand = () => setExpand(!expand);

  const onError = (e) => {
    wait && setWait(false);
    load && setLoad(false);
    approveStep && setApproveStep(false);
    signStep && setSignStep(false);

    setError(e);
  };

  const toggleModalSignSettings = () => setOpenSignSettings(!openSignSettings);

  const modifySign = async (isModify) => {
    setUseSignatureImage(true);
    const signature = await Api.getDigitalSignature(token).catch(onError);

    if (isModify && signature) setDigitalSignature(signature);
    else if (!signature) setDigitalSignature(null);
  };

  const onSign = async () => {
    setSignStep(true);
    setWait(true);
    const file = await Api.getSignedDocument(token, assetId, useSignatureImage, signData.additionalPlaceholders).catch(
      onError
    );

    if (file) {
      setSignDocument(file);
      setPreSignDocument(null);
    }
    setWait(false);
  };

  const onFinish = (print = false) => async () => {
    setApproveStep(true);
    setWait(true);

    const res = await Api.approveSignedDocument(token, assetId).catch(onError);
    if (res) {
      setWait(false);

      if (print) window.open(signDocument).print();
      history.push(redirectLink);
    }
  };

  useEffect(() => {
    const getInitial = async () => {
      const file = await Api.getPreSignDocument(
        token,
        assetId,
        signData.intentionToBuy,
        signData.additionalPlaceholders
      ).catch(onError);

      const signature = await Api.getDigitalSignature(token).catch(onError);

      if (signature) {
        setUseSignatureImage(true);
        setDigitalSignature(signature);
      }

      if (file) {
        setPreSignDocument(file);
        setLoad(false);
      }
    };
    getInitial();

    return () => onSetSignData(null);
  }, []);

  if (load) {
    return <Preparing />;
  } else
    return (
      <Fragment>
        <Header fontSize={18}>
          <BackButtonContainer>
            <Button transparent height={36} width={157} size={12} onClick={() => history.push(redirectLink)}>
              <BackIcon style={{ marginRight: 8 }} />
              Back to Deal
            </Button>
          </BackButtonContainer>
          <TextHeaderContainer>
            {formatUppercase(dealWorkflowActionButtonText)} for {assetName}
          </TextHeaderContainer>
        </Header>
        <Row>
          <Col left>
            {expand ? <ExpandedReader onChange={onChangeExpand} fileURL={preSignDocument || signDocument} /> : null}
            <ReaderWrapper>
              <ViewerHeader>
                <ViewerDocName></ViewerDocName>
                <SizeDate />
                <ExpandBtn onClick={onChangeExpand}>
                  <Expand />
                </ExpandBtn>
              </ViewerHeader>
              <View src={preSignDocument || signDocument} key={`key-${preSignDocument || signDocument}`} />
            </ReaderWrapper>
          </Col>
          <Col>
            <Card>
              <CardHeader>Sign Document</CardHeader>
              <Button disabled={wait} size={12} fullWidth height={36} onClick={preSignDocument ? onSign : onFinish()}>
                {preSignDocument ? "Sign" : "Finish"}
              </Button>
              {preSignDocument ? null : (
                <Button
                  disabled={wait}
                  margin="10px 0 0 0"
                  size={12}
                  fullWidth
                  height={36}
                  transparent
                  onClick={onFinish(true)}
                >
                  Print & Finish
                </Button>
              )}
            </Card>
            {preSignDocument && (
              <Card>
                <CardHeader>Sign Settings</CardHeader>
                <Button disabled={wait} size={12} fullWidth height={36} transparent onClick={toggleModalSignSettings}>
                  Change Sign Image
                </Button>
              </Card>
            )}
          </Col>
        </Row>

        {openSignSettings ? (
          <SignSettings
            isSignatureNone={!useSignatureImage}
            token={token}
            modifySign={modifySign}
            digitalSignature={digitalSignature}
            onBackdropClick={toggleModalSignSettings}
          />
        ) : null}

        {error && <ErrorMessage er={error} onClose={() => setError(null)} />}
        {wait ? <Preparing lastStep={approveStep} signing={signStep} /> : null}
      </Fragment>
    );
};

const ExpandedReader = ({ fileURL, onChange }) =>
  ReactDom.createPortal(
    <ExpandWrapper>
      <ViewerHeader isExpand>
        <ViewerDocName />
        <SizeDate />
        <ExpandBtn onClick={onChange}>
          <Expand />
        </ExpandBtn>
      </ViewerHeader>
      <View isExpand src={fileURL} />
    </ExpandWrapper>,
    document.getElementById("round-modal")
  );

export default connect(
  ({ userReducer: { token }, dataReducer: { signData, assets } }) => ({
    token,
    signData,
    assets,
  }),
  { onSetSignData }
)((props) => (props.signData ? <DigitalSignature {...props} /> : <Redirect to="/investor/offerings/all" />));
