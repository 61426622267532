export const newDealArr = [
  { text: "Information", key: "one" },
  { text: "Parameters", key: "two" },
  { text: "Financials & Rules", key: "three" },
  { text: "Data Room", key: "four" },
];

export const existDealArr = [
  { text: "Information", key: "one" },
  { text: "Financials", key: "two" },
  { text: "Data Room", key: "three" },
];
