import React, { useState, useEffect, Fragment } from "react";
import moment from "moment";

import {
  Asset,
  ImgCont,
  Labels,
  LinkBlock,
  SponsorBlock,
  AssetName,
  Row,
  InfoRow,
  Button,
  Sponsor,
  __ChartBg,
  TooltipCont,
  RaisedCardBlock,
  InfoCardBlock,
  DataCol,
  ChartTxt,
  Val,
  WrapperTips,
  TipsText,
  Triang,
  NoInfo,
} from "./styled";
import { ProgressBar } from "../Investment/styled";
import { ReactComponent as InfoIcon } from "../../../../assets/info.svg";
import Api from "../../../../helpers/api";
import { numberWithCommas } from "../../../../helpers/index";
import { currenciesModel } from "../../../../models/currenciesModel";

export const AssetCard = ({
  history,
  item: {
    linearId,
    assetProperties: {
      images,
      category,
      assetLocation,
      assetName,
      smallDescription,
      dynamicFinancialJson,
      dealsType,
      openEndFund,
      currentlyFundraising,
    },
    raisedVsToBeRaised: { raised, toBeRaised },
    sponsors,
    subscribers,
    assetFinancialProperty: { timeOfEnd, baseCurrency },
    roundsInfo,
  },
  token,
}) => {
  const [image, setImage] = useState(null);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const isExisted = dealsType === "EXISTED_DEAL";
  const lastRound = roundsInfo ? roundsInfo[0] : {};

  const data = JSON.parse(dynamicFinancialJson || "[]");
  data.length = 3;

  useEffect(() => {
    if (!image && images && images[0]) {
      const { secureHash, fileNameWithExtension } = images[0];
      Api.getAttachment(token, secureHash, fileNameWithExtension).then((blob) => setImage(blob));
    }
  });

  const isDealExpired = (moment(timeOfEnd).isBefore(Date.now()) || raised === toBeRaised + raised) && !isExisted;

  return (
    <Asset expired={isDealExpired}>
      <ImgCont fullW height={230}>
        {image && <img src={image} alt="asset" />}
        <Labels category={category} location={assetLocation} blue={isExisted && !currentlyFundraising} />
      </ImgCont>
      <InfoCardBlock>
        <Row noHidden>
          <AssetName>{assetName}</AssetName>
          <TooltipCont>
            <InfoIcon />
            <WrapperTips>
              <h1>{assetName}</h1>
              <TipsText>{smallDescription}</TipsText>
            </WrapperTips>
            <Triang />
          </TooltipCont>
        </Row>
        {data.map(({ name, value }, i) => (
          <InfoRow key={name} margin={i !== 2 ? "0 0 8px 0" : 0} label={name} val={value} />
        ))}
      </InfoCardBlock>
      <RaisedCardBlock>
        {openEndFund ? (
          <NoInfo text="The raised amount will be disclosed <br/> once the funding has ended" />
        ) : (
          <Fragment>
            <Row margin="0 0 8px 0" justify="space-between">
              <DataCol align="flex-start" width={31}>
                <ChartTxt tAlign="left" size={10}>
                  raised amount
                </ChartTxt>
                <Val
                  dangerouslySetInnerHTML={{
                    __html: isExisted
                      ? numberWithCommas(lastRound.roundSize) +
                        (currenciesModel[lastRound.currency]
                          ? currenciesModel[lastRound.currency].symbol
                          : lastRound.currency)
                      : (currenciesModel[baseCurrency] ? currenciesModel[baseCurrency].symbol : baseCurrency) +
                        numberWithCommas(raised.toFixed()),
                  }}
                  fSize={14}
                  fWeight="bold"
                />
              </DataCol>
              <DataCol align="flex-start" width={31}>
                <ChartTxt tAlign="left" size={10}>
                  {isExisted ? "LATEST ROUND" : "Total amount"}
                </ChartTxt>
                <Val
                  fSize={14}
                  fWeight="bold"
                  dangerouslySetInnerHTML={{
                    __html: isExisted
                      ? lastRound.fundingName
                      : (currenciesModel[baseCurrency] ? currenciesModel[baseCurrency].symbol : baseCurrency) +
                        numberWithCommas((raised + toBeRaised).toFixed()),
                  }}
                />
              </DataCol>
              <DataCol align="flex-start" width={23}>
                <ChartTxt tAlign="left" size={10}>
                  {isExisted ? "DATE" : "subscribers"}
                </ChartTxt>
                <Val fSize={14} fWeight="bold">
                  {isExisted ? moment(lastRound.roundTo).format("MM/YYYY") : subscribers || 0}
                </Val>
              </DataCol>
            </Row>
            <ProgressBar sold={raised} unsold={toBeRaised} isExisted={isExisted && !currentlyFundraising} />
          </Fragment>
        )}
      </RaisedCardBlock>
      <div style={{ display: "flex", height: 65, marginTop: "auto" }}>
        <SponsorBlock width="calc(100% - 173px)">
          {sponsors.length ? <Sponsor token={token} sponsor={sponsors[0]} /> : null}
        </SponsorBlock>
        <LinkBlock>
          <Button
            blue={isExisted && !currentlyFundraising}
            width={123}
            height={32}
            size={12}
            tTransform="uppercase"
            fWeight="bold"
            onClick={() => history.push(`/investor/offerings/companies/${linearId}`)}
          >
            More
          </Button>
        </LinkBlock>
      </div>
    </Asset>
  );
};
