import React, { useRef, useState, Fragment } from "react";
import { CircularProgress } from "@material-ui/core";

import { Card } from "./styled";
import { Button } from "../Offerings/styled";
import { ReactComponent as FileIcon } from "assets/upload.svg";

import Api from "data-room-panel/helpers/api";
import { ErrorMessagePortal, SuccessMessage } from "components/admin-layout/errorMessage";

export const DocumentsUploader = ({ assetId, token }) => {
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState("");
  const [wait, setWait] = useState(false);

  const inputRef = useRef(null);

  const onOpenFileDialog = () => inputRef && inputRef.current && inputRef.current.click();

  const onUploadFiles = async (e) => {
    if (e.target && e.target.files && e.target.files.length) {
      setWait(true);
      const files = e.target.files;
      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append(`files`, files[i], files[i].name);
      }
      const res = await Api.uploadUserDocument(token, assetId, formData).catch((e) => {
        setWait(false);
        setError(e);
      });

      if (res) {
        setWait(false);
        setSuccess("Uploaded successfully.");
      }
    }
  };

  return (
    <Card padding="25px">
      <Button transparent={wait} fullWidth size={16} height={42} onClick={wait ? undefined : onOpenFileDialog}>
        {wait ? (
          <CircularProgress size={20} />
        ) : (
          <Fragment>
            <FileIcon style={{ marginRight: 10, width: 16, height: 16 }} />
            UPLOAD DOCUMENTS
          </Fragment>
        )}
      </Button>
      <input
        type="file"
        accept="image/emf,image/wmf,image/pict,image/jpeg,image/png,image/dib,image/gif,image/tiff,image/eps,image/bmp,image/jpg,image/wpg,application/pdf"
        multiple
        style={{ display: "none" }}
        ref={inputRef}
        onChange={onUploadFiles}
      />
      {success ? <SuccessMessage message={success} onClose={() => setSuccess("")} /> : null}
      {error && <ErrorMessagePortal er={error} onClose={() => setError(null)} />}
    </Card>
  );
};
