import React, { Fragment, useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { Button } from "components/investor-layout/pages/Offerings/styled";
import { icons } from "models/icons";
import theme from "models/themes";

const flex = `width: 100%;
              display: flex;
              box-sizing: border-box;`;

const Col = styled.div`
  ${flex}
  flex-direction: column;
`;

const Row = styled.div`
  ${flex}
  align-items: center;
  justify-content: ${({ justify = "space-between" }) => justify};
  margin: ${({ m = 0 }) => m};
  flex-wrap: ${({ wrap = "nowrap" }) => wrap};
  flex-direction: ${({ dir = "row" }) => dir};
  width: 100%;
`;

const SectionName = styled.h3`
  margin: 0;
  font-weight: 600;
  font-size: 16px;
  line-height: 135%;
  color: #181818;
`;

const Counting = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 135%;
  text-align: right;
  color: rgba(24, 24, 24, 0.4);
`;

const FieldBlock = styled.div`
  ${flex};
  position: relative;
  flex-direction: column;
  padding-top: 18px;
  margin-bottom: 18px;
  width: ${({ half = false }) => (half ? "calc(50% - 10px)" : "100%")};
  &:before {
    content: "";
    display: block;
    position: absolute;
    left: -30px;
    top: 0;
    border-top: 1px solid #efefef;
    width: calc(${({ longBorder }) => (longBorder ? "100% + 60px" : "200% + 90px")});
  }
  &:last-child {
    margin-bottom: 0;
  }
`;

const FieldName = styled.h6`
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 135%;
  color: #181818;
  margin: 0 0 12px;
`;

const Input = styled.input`
  height: 52px;
  background: #ffffff;
  border: 1px solid #efefef;
  box-sizing: border-box;
  border-radius: 4px;
  width: 100%;
  padding: 0 20px;
  font-size: 14px;
  line-height: 135%;
  letter-spacing: -0.02em;
  color: #181818;
  outline: none;
  &:placeholder {
    color: rgba(24, 24, 24, 0.4);
  }
  &[type="date"]::-webkit-inner-spin-button,
  &[type="date"]::-webkit-calendar-picker-indicator,
  &[type="date"]::-webkit-clear-button {
    opacity: 0;
    cursor: pointer;
    -webkit-appearance: none;
  }
  &[type="date"]:before {
    color: transparent !important;
  }
  &[type="time"]:placeholder {
    color: transparent;
  }
  background-repeat: no-repeat;
  background-position-x: calc(100% - 20px);
  background-position-y: 17px;
  background-image: ${({ icon }) => `url(${icon})`};
`;

const Form = styled.form`
  width: 100%;
  height: 92px;
  background: ${({ theme: { quoteBgInvestor } }) => quoteBgInvestor};
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: ${({ files }) => (files ? "10px" : 0)};
`;

const Label = styled.div`
  font-weight: bold;
  font-size: 12px;
  line-height: 135%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: rgba(24, 24, 24, 0.6);
  margin-right: 14px;
`;

export const File = styled.div`
  height: 42px;
  box-sizing: border-box;
  background: ${({ theme: { quoteBgInvestor } }) => quoteBgInvestor};
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 3px 17px;
  margin-top: 10px;
  width: calc(50% - 10px);
  border: 1px solid ${({ theme: { primary } }) => primary};
  svg {
    width: 13px;
    height: 13px;
  }
  > svg {
    path {
      stroke: ${({ theme: { primary } }) => primary};
    }
  }
  span {
    cursor: pointer;
  }
`;

export const FileName = styled.h6`
  font-size: 12px;
  line-height: 135%;
  letter-spacing: -0.02em;
  color: #181818;
  margin: 0 auto 0 8px;
  max-width: calc(100% - 50px);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const FilesRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  &:last-of-type {
    margin-bottom: 0;
  }
`;

export const BorderBlock = styled.div`
  width: ${({ width }) => width};
  padding: 10px 40px;
  background: #ffffff;
  border: 1px solid ${({ active, theme: { primary } }) => (active ? primary : "#dadada")};
  box-sizing: border-box;
  border-radius: 4px;
  position: relative;
  display: flex;
  flex-direction: column;
  margin: ${({ margin }) => margin};
  transition: all 0.3s linear;
  cursor: pointer;
  &:last-child {
    margin: 0;
  }
  &::before {
    content: "";
    position: absolute;
    top: 11px;
    left: 11px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 1px solid ${({ active, theme: { primary } }) => (active ? primary : "#dadada")};
  }
  &::after {
    content: "";
    position: absolute;
    display: ${({ active }) => (active ? "block" : "none")};
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: ${({ theme: { primary } }) => primary};
    top: 16px;
    left: 16px;
  }
`;

export const Param = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 135%;
  letter-spacing: -0.02em;
  color: ${({ active }) => (active ? "#181818" : "rgba(24, 24, 24, 0.6)")};
`;

const Text = styled.p`
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: ${({ fSize = 12 }) => `${fSize}px`};
  line-height: 135%;
  letter-spacing: -0.02em;
  color: #181818;
  text-align: ${({ center }) => (center ? "center" : "left")};
  margin: ${({ margin = 0 }) => margin};
  width: ${({ width }) => width};
`;

const Description = styled.div`
  width: 100%;
  background: ${({ theme: { emptyCardBg } }) => emptyCardBg};
  border-radius: 4px;
  position: relative;
  box-sizing: border-box;
  padding: 15px 25px 15px 50px;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: -0.02em;
  color: #56585f;
  margin-top: 20px;
  > svg {
    position: absolute;
    width: 18px;
    height: 18px;
    left: 20px;
    top: 18px;
    path {
      stroke: ${({ theme: { primary } }) => primary};
    }
  }
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

const RadiobuttonComponent = ({
  options,
  values = [],
  onChange,
  disabled = false,
  multiple = false,
  id: fieldId,
  type,
}) => {
  const [answers, setAnswers] = useState(values);

  const onChoose = (id) => () => {
    const _answers = [...answers];
    if (multiple) {
      const index = _answers.findIndex((a) => a === id);
      if (index !== -1) _answers.splice(index, 1);
      else _answers.push(id);
    } else {
      _answers[0] = id;
    }
    setAnswers(_answers);
    onChange({ id: fieldId, type, val: _answers });
  };

  const renderButtons = () => {
    return options.map(({ id, value }) => (
      <BorderBlock
        width="fit-content"
        margin="0 0 10px"
        key={id}
        active={answers.includes(id)}
        onClick={disabled ? undefined : onChoose(id)}
      >
        <Param>{value}</Param>
      </BorderBlock>
    ));
  };
  return (
    <Container>
      <Text margin="0 0 14px">Please choose one {multiple ? " or several options" : " option"}</Text>
      <Col>{renderButtons()}</Col>
    </Container>
  );
};

const Uploader = ({ attachments, id, type, onChange }) => {
  const [files, setFiles] = useState(attachments);

  const inputRef = useRef(null);

  const onUpload = (e) => {
    e.stopPropagation();
    inputRef && inputRef.current && inputRef.current.click();
  };

  const prevDefault = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const onDropFiles = (e) => {
    prevDefault(e);
    setFiles([...files, ...e.dataTransfer.files]);
  };

  const onAddFiles = (e) => {
    setFiles([...files, ...e.target.files]);
  };

  const onRemoveFile = (f) => () => {
    const _files = [...files];

    //check if files
    const index = _files.findIndex((file) =>
      "type" in f ? file.name === f.name && file.lastModified === f.lastModified : file.uuid === f.uuid
    );
    _files.splice(index, 1);
    setFiles(_files);
  };

  useEffect(() => {
    onChange({ id, type, val: files });
  }, [files]);

  const renderFiles = () => {
    return files.map((f) => {
      const random = Math.round(Math.random() * 1000);
      return (
        <File key={`${f.name || f.fileName}-${f.size || f.uuid}-${random}`}>
          {icons.file}
          <FileName>{f.name || f.fileName}</FileName>
          <span onClick={onRemoveFile(f)}>{icons.crossRed}</span>
        </File>
      );
    });
  };

  return (
    <Fragment>
      <Form
        enctype="multipart/form-data"
        onClick={onUpload}
        onSubmit={prevDefault}
        onDragEnter={prevDefault}
        onDragOver={prevDefault}
        onDragLeave={prevDefault}
        onDrop={onDropFiles}
        files={files.length}
      >
        <Label>Drop files here or</Label>
        <Button size={12} width={165} onClick={onUpload}>
          {icons.upload}UPLOAD FILES
        </Button>
        <input onChange={onAddFiles} multiple type="file" style={{ display: "none" }} ref={inputRef} />
      </Form>
      <FilesRow>{renderFiles()}</FilesRow>
    </Fragment>
  );
};

export const Section = ({ s: { sectionName, fields }, steps, step, onChangeSimple, onChangeSpecific }) => {
  const _onChangeSimple = (e, id) => {
    onChangeSimple({ id, value: e.target.value });
  };

  const _onChangeSpecific = (data) => {
    onChangeSpecific(data);
  };

  const halfTypes = ["Text", "Date", "Single Answer", "Multiple Answers"];

  const renderFields = () => {
    return fields.map(
      ({ name, id, type, visibility, required, value, values, options, attachments, description }, i) => {
        const nextField = fields[i + 1];
        const half = halfTypes.includes(type) && name.length < 45;
        const longBorder = half && nextField && nextField.visibility && halfTypes.includes(nextField.type);
        if (visibility)
          return (
            <FieldBlock key={id} half={half} longBorder={longBorder}>
              <FieldName>
                {name}
                {required ? <sum> *</sum> : null}
              </FieldName>
              {type === "Text" ? (
                <Input key={id} value={value} placeholder={name} onChange={(e) => _onChangeSimple(e, id)} />
              ) : type === "Date" ? (
                <Input
                  key={id}
                  value={value}
                  placeholder={name}
                  type="date"
                  icon={`/assets/calendar${theme.greyFilter ? "-dark" : ""}.svg`}
                  onChange={(e) => _onChangeSimple(e, id)}
                />
              ) : type === "Upload File" ? (
                <Uploader attachments={attachments} id={id} type={type} onChange={_onChangeSpecific} />
              ) : type === "Single Answer" || type === "Multiple Answers" ? (
                <RadiobuttonComponent
                  values={values}
                  options={options}
                  multiple={type === "Multiple Answers"}
                  onChange={_onChangeSpecific}
                  id={id}
                  type={type}
                />
              ) : null}
              {description ? (
                <Description>
                  {icons.info} {description}
                </Description>
              ) : null}
            </FieldBlock>
          );
      }
    );
  };
  return (
    <Col>
      <Row m="0 0 18px">
        <SectionName>{sectionName}</SectionName>
        <Counting>
          {step + 1} / {steps}
        </Counting>
      </Row>
      <Wrapper>{renderFields()}</Wrapper>
    </Col>
  );
};
