import React, { useState, useEffect, useRef } from "react";

import {
  Wrapper,
  Card,
  Footer,
  Heading,
  Flex,
  DocsCol,
  DocBlock,
  IconBlock,
  DocName,
  CheckBox,
  NameContainer,
  Line,
  EmptyCard,
  WrapperForViewer,
} from "./styled";
import { CenterContainer } from "../styled";
import { Btn } from "components/admin-layout/buttons";
import { ReactComponent as EyeIcon } from "../../../../../asset/eye.svg";
import { ReactComponent as FileIcon } from "../../../../../asset/paper.svg";
import { CircularProgress } from "@material-ui/core";
import DataRoomApi from "../../../../../helpers/api";
import { ErrorMessage, SuccessMessage } from "../errorMessage";

import { FileViewer } from "../FileViewer";

export const WorkLows = ({ token, assetId }) => {
  const [viewDoc, setViewDoc] = useState(null);
  const [data, setData] = useState(null);
  const [wait, setWait] = useState(false);
  const [initialValues, setInitialValues] = useState(null);
  const [errorObj, setError] = useState(null);
  const [success, setSuccess] = useState("");

  const onChangeViewFile = (doc) => setViewDoc(doc);

  const viewRef = useRef(null);
  useEffect(() => {
    if (viewDoc && viewRef.current) {
      window.scrollTo(0, viewRef.current.getBoundingClientRect().top - 80);
    }
  }, [viewDoc]);

  const getData = async () => {
    const _data = await DataRoomApi.getRulesList(token, assetId);

    if (_data) {
      setData(_data);
      setInitialValues(JSON.stringify(_data));
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const onChangeAll = (info) => {
    const { val, lawIndex } = info;
    const _data = [...data];
    _data[lawIndex].documents.forEach((d) => {
      d.active = val;
    });
    setData(_data);
  };

  const onChangeDocument = (info) => {
    const { val, lawIndex, docIndex } = info;
    const _data = [...data];
    _data[lawIndex].documents[docIndex].active = val;
    setData(_data);
  };

  const onSubmit = async () => {
    setWait(true);

    const res = await DataRoomApi.setRulesList(token, assetId, data).catch((e) => {
      setError(e);
      setWait(false);
    });

    if (res) {
      setWait(false);
      setSuccess(res);
    }
  };

  const onCancelChanges = () => {
    setData(JSON.parse(initialValues));
  };

  const renderLaws = () =>
    data.map((l, i) => (
      <Low
        law={l}
        key={`${l.lawName}-${i}`}
        callBack={(info) => onChangeDocument(info)}
        onChangeAllCB={(info) => onChangeAll(info)}
        lawIndex={i}
        onView={onChangeViewFile}
      />
    ));

  if (data) {
    return (
      <Wrapper>
        {renderLaws()}
        <EmptyCard />
        <WrapperForViewer hidden={!viewDoc} ref={viewRef}>
          {viewDoc ? (
            <FileViewer
              viewerMargin={0}
              viewerWidth="100%"
              key={JSON.stringify(viewDoc)}
              token={token}
              assetId={assetId}
              file={viewDoc}
              onClose={() => onChangeViewFile(null)}
            />
          ) : null}
        </WrapperForViewer>
        <Footer>
          <Btn width={180} hide onClick={onCancelChanges}>
            CANCEL CHANGES
          </Btn>
          <Btn width={180} onClick={onSubmit} disabled={wait}>
            SAVE CHANGES
          </Btn>
        </Footer>
        {errorObj ? <ErrorMessage er={errorObj} onClose={() => setError(null)} /> : null}

        {success ? <SuccessMessage message={success} onClose={() => setSuccess("")} /> : null}
      </Wrapper>
    );
  } else {
    return (
      <CenterContainer>
        <CircularProgress />
      </CenterContainer>
    );
  }
};

const Low = ({ law: { documents, lawName }, callBack, lawIndex, onChangeAllCB, onView }) => {
  const onChange = (data) => {
    callBack(data);
  };

  const renderDocuments = () =>
    documents.map((d, i) => (
      <Document
        callBack={(data) => onChange(data)}
        doc={d}
        key={`${d.fileNameWithExtension}-${i}`}
        law={lawName}
        docIndex={i}
        lawIndex={lawIndex}
        onView={onView}
      />
    ));

  const onChangeAll = (val, lawIndex) => () => {
    onChangeAllCB({ val, lawIndex });
  };

  return (
    <Card>
      <Heading>{lawName} rules</Heading>
      <Flex>
        <Btn transparent onClick={onChangeAll(true, lawIndex)}>
          SELECT ALL
        </Btn>
        <Btn hide onClick={onChangeAll(false, lawIndex)}>
          UNSELECT ALL
        </Btn>
      </Flex>
      <Line />
      <DocsCol>{renderDocuments()}</DocsCol>
    </Card>
  );
};

export const Document = ({ doc: { name, active, ...otherParams }, lawIndex, docIndex, callBack, onView }) => {
  const onChange = (val) => {
    callBack({ val, lawIndex, docIndex });
  };
  return (
    <DocBlock active={active}>
      <IconBlock active={active}>
        <CheckBox cheked={active} callBack={(val) => onChange(val)} />
      </IconBlock>
      <DocName active={active}>
        <FileIcon /> <NameContainer>{name}</NameContainer>
      </DocName>
      <IconBlock active={active}>
        <EyeIcon onClick={() => onView({ name, active, ...otherParams })} />
      </IconBlock>
    </DocBlock>
  );
};
