import React, { useEffect, useState, Fragment, useRef } from "react";
import { CircularProgress } from "@material-ui/core";
import { connect } from "react-redux";
import moment from "moment";

import { AssignToGroupModal } from "./AssignToGroupModal";
// import { AddMoney } from "./AddMoney";
import { SendEmail } from "./SendEmail";
import { AddShares } from "./AddShares";
import { UserRow } from "./UserRow";
import { ErrorMessage, ConfirmMessagePortal } from "../../errorMessage";
import { Groups } from "./Groups";
import { ChooseUserType } from "./ChooseUserType";
import { RequestInfo } from "./RequestInfo";

import {
  TableHeader,
  HeaderTD,
  AssetsTabs,
  AssetTypeTab
} from "../../dealTypeCard";
import { Btn } from "../../buttons";
import {
  Separator,
  Row,
  Card,
  SearchInput,
  TableRow,
  Email,
  Paginator,
  NewReqItem,
  ApproveRegect
} from "./styled";
import { Wait } from "../DashBoard/styled";
import { Wrapper } from "../../main";

import { icons } from "../../../../models/icons";
import Api from "../../../../helpers/api";
import { updateAdminNotifications } from "modules/dataRedux/dataAction";

export const UserList = props => {
  const {
    match: {
      params: { type }
    },
    updateAdminNotifications
  } = props;

  const [total, setTotal] = useState(0);
  const [wait, setWait] = useState(false);

  const debounceTime = useRef(null);
  const [keyword, setKeyword] = useState("");
  const [searchReq, setSearchReq] = useState("");

  const onChangeKeyWork = e => {
    const { value } = e.target;
    clearTimeout(debounceTime.current);
    debounceTime.current = setTimeout(() => {
      setKeyword(value);
    }, 500);
  };

  const onChangeSearchReq = e => {
    const { value } = e.target;
    clearTimeout(debounceTime.current);
    debounceTime.current = setTimeout(() => {
      setSearchReq(value);
    }, 500);
  };

  const [users, setUsers] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [newRequests, setNewRequests] = useState([]);
  const [currencies, setCurrencies] = useState([]);
  const [assetsList, setAssetsList] = useState([]);
  const [groups, setGroups] = useState(null);
  const [error, setError] = useState(null);
  const [typeModalOpen, setTypeModalOpen] = useState(false);

  const [openMore, setOpenMore] = useState(false);
  // const [openAddMoney, setOpenAddMoney] = useState(false);
  const [openSendEmail, setOpenSendEmail] = useState(false);
  const [openAddShares, setOpenAddShares] = useState(false);
  const [openAssignToGroup, setOpenAssignToGroup] = useState(false);
  const [load, setLoad] = useState(false);
  const [mailData, setMailData] = useState({});

  const [filter, setFilter] = useState("INVESTOR");
  const [typeReq, setTypeReq] = useState("ALL");

  const [currentPage, setCurrentPage] = useState(1);

  const [userId, setUserId] = useState(null);

  const toggleAssignModal = id => {
    setOpenAssignToGroup(!openAssignToGroup);
    setUserId(id);
  };

  const onOpenMore = email => () => {
    setOpenMore(!openMore);
    if (email) {
      setUserId(email);
    }
  };

  const toggleTypeModal = _ => setTypeModalOpen(!typeModalOpen);

  const loadUsers = async (role = "ALL") => {
    setLoad(true);

    const { content, totalPages } = await Api.getUsers(
      props.token,
      role,
      currentPage,
      10, // size
      keyword
    ).catch(e => setError(e));
    setTotal(totalPages * 10);
    const hashMap = [];
    const logoPromises = [];
    for (let user of content) {
      user.activityStatus = user.activityStatus === "ACTIVE";
      user.sharesDetailed = JSON.parse(user.sharesDetailed);

      hashMap.push(user.id);
      logoPromises.push(
        Api.getAttachment(props.token, user.userLogoHash, user.userLogoFileName)
      );
    }

    const usersList = await Api.getUsersList(props.token);

    setAllUsers(usersList.content);
    setUsers(content);
    setLoad(false);
  };

  const onSuspendUser = () => {
    loadUsers(filter);
  };

  const onRefresh = () => {
    onUpdateGroups();
  };

  useEffect(() => {
    setUsers([]);
    loadUsers(filter);
  }, [currentPage, keyword]);

  useEffect(() => {
    if (currentPage === 1) {
      setUsers([]);
      loadUsers(filter);
    } else {
      setCurrentPage(1);
    }
  }, [filter]);

  useEffect(() => {
    loadUsers(filter);
    (async () => {
      const currencies = await Api.getPossibleCurrencies(props.token).catch(
        e => {
          setError(e);
        }
      );
      setCurrencies(currencies);

      const assets = await Api.getAssetsList(props.token).catch(e =>
        setError(e)
      );
      setAssetsList(assets);

      const newReq = await Api.getNewRequests(props.token).catch(e => {
        setError(e);
      });
      setNewRequests(newReq);

      const groups = await Api.getUserGroups(props.token).catch(e => {
        setError(e);
      });
      setGroups(groups);
    })();
  }, []);

  const onUpdateGroups = async () => {
    const groups = await Api.getUserGroups(props.token).catch(e => {
      setError(e);
    });
    setGroups(groups);
  };

  const onUpdateNewReq = async () => {
    setOpenMore(false);
    const newReq = await Api.getNewRequests(props.token).catch(e => {
      setError(e);
    });
    if (newReq) {
      setNewRequests(newReq);
      updateAdminNotifications(newReq.length, "Users");
    }
  };

  useEffect(() => {
    if (type) {
      (async () => {
        const newReq = await Api.getNewRequests(props.token);
        setNewRequests(newReq);
      })();
    }
  }, [type]);

  // const toggleModalOpenAddMoney = () => setOpenAddMoney(!openAddMoney);
  const toggleModalSendEmail = (name, email) => {
    setMailData({
      userName: name,
      userEmail: email
    });
    setOpenSendEmail(!openSendEmail);
  };
  const toggleModalOpenAddShares = () => setOpenAddShares(!openAddShares);

  const onChangeFilter = name => {
    setFilter(name);
  };

  const onChangeTypeFilter = name => {
    setTypeReq(name);
  };

  const downloadXlsx = async () => {
    const res = await Api.getXlsxUsersList(props.token).catch(e => {
      setError(e);
    });
    if (res) {
      const { file, fileName } = res;
      const fileURL = URL.createObjectURL(file);
      const link = document.createElement("a");
      link.href = fileURL;
      link.download = fileName;
      link.click();
    }
  };

  const renderNewRequests = () => {
    return newRequests
      .filter(req => {
        if (typeReq === "ALL") {
          return true;
        }
        if (typeReq === "SPONSOR" || typeReq === "FACILITATOR") {
          return req.role === typeReq;
        }
        if (typeReq === "PROFESSIONAL" || typeReq === "INSTITUTION") {
          return req.investorType === typeReq;
        }
      })
      .filter(
        req =>
          req.email.toLowerCase().includes(searchReq.toLowerCase()) ||
          req.firstName.toLowerCase().includes(searchReq.toLowerCase()) ||
          req.lastName.toLowerCase().includes(searchReq.toLowerCase())
      )
      .map(user => {
        const {
          email,
          firstName,
          lastName,
          countryOfResidence,
          loginDate,
          investorType,
          role
        } = user;
        return (
          <TableRow key={email} justify="space-between">
            <NewReqItem
              boxSizing="border-box"
              padding="0 0 0 15px"
              width="180px"
              bold
            >
              <span>
                {firstName} {lastName}
              </span>
            </NewReqItem>
            <NewReqItem
              boxSizing="border-box"
              padding="0 0 0 5px"
              width="120px"
            >
              {role === "INVESTOR" ? investorType : role}
            </NewReqItem>
            <HeaderTD boxSizing="border-box" padding="0 5px" width="240px">
              <Email>{email}</Email>
            </HeaderTD>
            <NewReqItem boxSizing="border-box" padding="0 5px" width="120px">
              {countryOfResidence}
            </NewReqItem>
            <NewReqItem boxSizing="border-box" padding="0 5px" width="160px">
              {moment(loginDate).format("DD MMM YYYY, hh:mm A")}
            </NewReqItem>
            <HeaderTD boxSizing="border-box" padding="0 5px" width="215px">
              {!investorType || investorType === "PROFESSIONAL" ? (
                <ApproveRegect
                  token={props.token}
                  email={email}
                  onRefresh={onUpdateNewReq}
                  _user={{ lastName, firstName }}
                />
              ) : (
                <ApproveRegect
                  newFlow
                  token={props.token}
                  email={email}
                  openModal={onOpenMore}
                  onRefresh={onUpdateNewReq}
                  _user={{ lastName, firstName }}
                />
              )}
            </HeaderTD>
          </TableRow>
        );
      });
  };

  return (
    <Wrapper
      alignItems="center"
      minHeight="calc(100vh - 130px)"
      bg="transparent"
    >
      {wait && <Wait />}
      <AssetsTabs>
        <AssetTypeTab to={"/admin/users"} active={!type}>
          {icons.user}
          Users
        </AssetTypeTab>
        <AssetTypeTab
          to={"/admin/users/new-requests"}
          active={type === "new-requests"}
        >
          {icons.newRequests}
          New Requests {newRequests.length ? `(${newRequests.length})` : null}
        </AssetTypeTab>
        <AssetTypeTab to={"/admin/users/groups"} active={type === "groups"}>
          {icons.users}
          Groups
        </AssetTypeTab>
        {type === "groups" ? (
          <Fragment key="groups">
            <Btn
              margin="0 0 0 auto"
              width={215}
              onClick={() => props.history.push("/admin/user/new-group")}
            >
              {icons.plusCircle} add new group
            </Btn>
          </Fragment>
        ) : (
          <Fragment key="users">
            <Btn margin="0 12px 0 auto" width={140} hide onClick={downloadXlsx}>
              download xlsx
            </Btn>
            <Btn
              margin="0 12px 0 0"
              width={140}
              transparent
              onClick={toggleModalOpenAddShares}
            >
              {icons.shares} Add shares
            </Btn>
            {/* <Btn
              margin="0 12px 0 0"
              width={140}
              transparent
              onClick={toggleModalOpenAddMoney}
              // disabled={filter !== "INVESTOR"}
              disabled
            >
              {icons.creditCard} Add Money
            </Btn> */}
            <Btn width={215} onClick={toggleTypeModal}>
              {icons.plusCircle} add new user
            </Btn>
          </Fragment>
        )}
      </AssetsTabs>
      <Row>
        {type === "new-requests" ? (
          <Fragment>
            <Card padding="0 14px" width="675px">
              <Btn
                width={120}
                onClick={() => onChangeTypeFilter("PROFESSIONAL")}
                transparent={typeReq !== "PROFESSIONAL"}
              >
                Individual
              </Btn>
              <Btn
                width={120}
                onClick={() => onChangeTypeFilter("INSTITUTION")}
                transparent={typeReq !== "INSTITUTION"}
              >
                INSTITUTION
              </Btn>
              <Btn
                width={120}
                onClick={() => onChangeTypeFilter("FACILITATOR")}
                transparent={typeReq !== "FACILITATOR"}
              >
                FACILITATOR
              </Btn>
              <Btn
                width={120}
                onClick={() => onChangeTypeFilter("SPONSOR")}
                transparent={typeReq !== "SPONSOR"}
              >
                SPONSOR
              </Btn>
              <Btn
                width={120}
                onClick={() => onChangeTypeFilter("ALL")}
                transparent={typeReq !== "ALL"}
              >
                ALL
              </Btn>
            </Card>
            <Card width={"calc(100% - 705px)"} padding="0 27px">
              {icons.search}
              <SearchInput
                key={`${filter}-${currentPage}-byNewRequests`}
                placeholder="Search by username or e-mail"
                onInput={onChangeSearchReq}
              />
            </Card>
          </Fragment>
        ) : (
          <Fragment>
            <Card
              width={!type ? "calc(100% - 570px)" : "100%"}
              padding="0 27px"
            >
              {icons.search}
              <SearchInput
                key={`${filter}-${currentPage}`}
                placeholder="Search by username or e-mail"
                onInput={onChangeKeyWork}
              />
            </Card>
            {!type && (
              <Card padding="0 14px" width="540px">
                <Btn
                  width={120}
                  onClick={() => onChangeFilter("INVESTOR")}
                  transparent={filter !== "INVESTOR"}
                >
                  Regular
                </Btn>
                <Btn
                  width={120}
                  onClick={() => onChangeFilter("SPONSOR")}
                  transparent={filter !== "SPONSOR"}
                >
                  Sponsor
                </Btn>
                <Btn
                  width={120}
                  onClick={() => onChangeFilter("ADMIN")}
                  transparent={filter !== "ADMIN"}
                >
                  Admin
                </Btn>
                <Btn
                  width={120}
                  onClick={() => onChangeFilter("FACILITATOR")}
                  transparent={filter !== "FACILITATOR"}
                >
                  FACILITATOR
                </Btn>
              </Card>
            )}
          </Fragment>
        )}
      </Row>
      <Separator />
      {users.length && !load && !type ? (
        <Fragment>
          <TableHeader justify="space-between">
            <HeaderTD
              boxSizing="border-box"
              padding="0 0 0 15px"
              blue
              width="220px"
            >
              User {icons.arrow}
            </HeaderTD>
            <HeaderTD boxSizing="border-box" padding="0 5px" width="245px">
              E-mail
            </HeaderTD>
            <HeaderTD boxSizing="border-box" padding="0 5px" width="160px">
              verified status
            </HeaderTD>
            <HeaderTD boxSizing="border-box" padding="0 5px" width="150px">
              portfolio
            </HeaderTD>
            <HeaderTD boxSizing="border-box" padding="0 5px" width="122px">
              Last login
            </HeaderTD>
            <HeaderTD
              boxSizing="border-box"
              padding="0 5px"
              width="295px"
            ></HeaderTD>
          </TableHeader>
          {users.map(user => {
            return (
              <UserRow
                key={`user-${user.id}`}
                token={props.token}
                {...user}
                goToDetails={() => {
                  user.investorType === "INSTITUTION"
                    ? props.history.push(`/admin/users/institution/${user.id}`)
                    : props.history.push(
                        `/admin/user/${user.username}/${user.id}`
                      );
                }}
                goToDetailsSponsor={() =>
                  props.history.push(`/admin/users/sponsor/${user.username}`)
                }
                toggleModalSendEmail={toggleModalSendEmail}
                toggleAssignModal={toggleAssignModal}
                onSuspendUser={onSuspendUser}
              />
            );
          })}
          {total > 1 ? (
            <Fragment>
              <Separator height={15} />
              <Paginator
                totalPage={total}
                onChangePage={data => setCurrentPage(data)}
                itemsPerPage={10}
                startPage={currentPage}
              />
            </Fragment>
          ) : null}
        </Fragment>
      ) : type === "new-requests" && newRequests.length ? (
        <Fragment>
          <TableHeader justify="space-between">
            <HeaderTD
              boxSizing="border-box"
              padding="0 0 0 15px"
              blue
              width="180px"
            >
              full name {icons.arrow}
            </HeaderTD>
            <HeaderTD boxSizing="border-box" padding="0 5px" width="120px">
              Type
            </HeaderTD>
            <HeaderTD boxSizing="border-box" padding="0 5px" width="240px">
              E-mail
            </HeaderTD>
            <HeaderTD boxSizing="border-box" padding="0 5px" width="120px">
              residence
            </HeaderTD>
            <HeaderTD boxSizing="border-box" padding="0 5px" width="160px">
              register time
            </HeaderTD>
            <HeaderTD
              boxSizing="border-box"
              padding="0 5px"
              width="215px"
            ></HeaderTD>
          </TableHeader>
          {renderNewRequests()}
        </Fragment>
      ) : type === "groups" && !load ? (
        <Fragment>
          <Groups
            onRefresh={onRefresh}
            load={load}
            token={props.token}
            groups={groups}
            users={allUsers}
            callback={onUpdateGroups}
          />
        </Fragment>
      ) : load ? (
        <CircularProgress style={{ margin: "auto" }} />
      ) : null}

      {error ? (
        <ErrorMessage er={error} onClose={() => setError(null)} />
      ) : null}

      <AssignToGroupModal
        onRefresh={onRefresh}
        token={props.token}
        isOpen={openAssignToGroup}
        onBackdropClick={toggleAssignModal}
        user={users.find(({ id }) => id === userId)}
        groups={groups}
      />

      {openMore ? (
        <RequestInfo
          history={props.history}
          token={props.token}
          user={newRequests.find(({ email }) => email === userId)}
          onBackdropClick={onOpenMore()}
          onRefresh={onUpdateNewReq}
        />
      ) : null}
      {/* <AddMoney
        token={props.token}
        isOpen={openAddMoney}
        onBackdropClick={toggleModalOpenAddMoney}
        users={allUsers}
        currencies={currencies}
        reloadUsers={() => loadUsers(filter)}
      /> */}
      <AddShares
        isOpen={openAddShares}
        token={props.token}
        onBackdropClick={toggleModalOpenAddShares}
        users={allUsers}
        assets={assetsList}
        reloadUsers={() => loadUsers(filter)}
      />
      <SendEmail
        token={props.token}
        mailData={mailData}
        onBackdropClick={toggleModalSendEmail}
        isOpen={openSendEmail}
      ></SendEmail>
      {typeModalOpen ? (
        <ChooseUserType
          onBackdropClick={toggleTypeModal}
          history={props.history}
        />
      ) : null}
    </Wrapper>
  );
};

export default connect(({ userReducer: { token } }) => ({ token }), {
  updateAdminNotifications
})(UserList);
