import React, { useState, useEffect, Suspense } from "react";
import { Switch, Route } from "react-router-dom";
import { connect } from "react-redux";
import { useLocation } from "helpers/customHook";
import { SmallScreenBg, ScreenMess } from "./styled";

import SignInForm from "../components/auhtForms/signInForm";
import CreatePasswordForm from "../components/auhtForms/createPasswordForm";
import { PasswordRecoveryForm } from "../components/auhtForms/passwordRecoveryForm";
import { InfoPage } from "../components/investor-layout/pages/InfoPage";

import LayoutMain from "./auth.layout";
import LayoutEmpty from "./auth-empty.layout";
//info pages
import { terms } from "models/terms";
import { privacy } from "models/privacy";
import { alphierCompany } from "models/alphierCompanyInfo";

import { isGlafka, isInvesture, customSignUp, isAlphier } from "helpers/envChecker";

//config
const current = process.env.REACT_APP_CURRENT;

const SignUpForm = React.lazy(() => {
  if (isInvesture) {
    return import("components/auhtForms/newSignUpForm");
  } else if (isGlafka) {
    return import("components/auhtForms/glafkaSignUp");
  }
  return import("components/auhtForms/signUpForm");
});

export const AuthApp = connect(({ userReducer: { token, role }, companyInfoReducer: { companyInfo } }) => ({
  token,
  role,
  companyInfo,
}))((props) => {
  const [smallScreen, setSmallScreen] = useState(window.innerWidth < 1200);
  const { pathname = "" } = useLocation();

  useEffect(() => {
    const handleResize = () => {
      setSmallScreen(window.innerWidth < 1024);
    };

    const { history, token, role } = props;

    if (token && role && !history.location.pathname.includes("/createpassword")) {
      if (role === "INVESTOR" || role === "FACILITATOR") {
        history.push("/investor/offerings/all");
      }
      if (role === "AM" || (role === "ADMIN" && role === "SPONSOR")) {
        history.push("/admin/full-dashboard");
      }
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (smallScreen) {
    return (
      <SmallScreenBg>
        <ScreenMess>
          Our platform supports large screen resolutions. Please visit our website using a laptop or PC.
        </ScreenMess>
      </SmallScreenBg>
    );
  } else {
    const Layout =
      (customSignUp && pathname.includes("signup")) || pathname.includes("info") ? LayoutEmpty : LayoutMain;

    return (
      <Layout
        customYear={isAlphier ? "2019" : ""}
        data={isAlphier ? alphierCompany : props.companyInfo}
        preview={false}
      >
        <Switch>
          <Route exact path="/" component={SignInForm} />
          <Route
            path="/signup"
            component={(props) => {
              return (
                <Suspense fallback={<div>Loading...</div>}>
                  <SignUpForm {...props} />
                </Suspense>
              );
            }}
          />
          <Route path="/createpassword/:token" component={CreatePasswordForm} />
          <Route path="/password-recovery" component={PasswordRecoveryForm} />
          {privacy[current]
            ? [
                <Route
                  key="privacy"
                  path="/info/privacy-policy"
                  component={() => <InfoPage data={privacy[current]} isAuth history={props.history} />}
                />,
              ]
            : []}
          {terms[current]
            ? [
                <Route
                  key="terms"
                  path="/info/terms-of-use"
                  component={() => <InfoPage data={terms[current]} isAuth history={props.history} />}
                />,
              ]
            : []}
        </Switch>
      </Layout>
    );
  }
});
