import React, { Fragment, useRef, createRef } from "react";

import { Button } from "../Offerings/styled";
import {
  Wrapper,
  Header,
  ColWrapper,
  Col,
  BorderBlock,
  Paper,
  Navigation,
  MainHeading,
  TextHeading,
  Text,
  Nav,
  Anchor,
  ScrollContainer,
} from "./styled";
import { ReactComponent as BackIcon } from "../../../../assets/arrowleft.svg";

export const InfoPage = ({ history, data, isAuth = false }) => {
  const { pageName, content } = data;
  const elementsRef = useRef(content.map(() => createRef()));

  const scrollToRef = (ref) => ref.current.scrollIntoView({ behavior: "smooth", block: "start" });

  const renderContent = () => {
    return content.map(({ heading, text }, i) => {
      return (
        <Fragment key={heading}>
          <Anchor ref={elementsRef.current[i]} />
          <TextHeading dangerouslySetInnerHTML={{ __html: heading }} />
          <Text
            dangerouslySetInnerHTML={{
              __html: text.replace(
                "<a>Privacy Policy</a>",
                `<a target="_blank" href="${
                  window.location.pathname.includes("investor")
                    ? "/investor/info/privacy-policy"
                    : "/info/privacy-policy"
                }">Privacy Policy</a>`
              ),
            }}
          />
        </Fragment>
      );
    });
  };

  const renderNav = () => {
    return content.map(({ heading }, i) => (
      <Nav
        onClick={() => scrollToRef(elementsRef.current[i])}
        key={heading}
        dangerouslySetInnerHTML={{ __html: heading }}
      />
    ));
  };

  return (
    <Wrapper isAuth={isAuth}>
      <Header>
        <BorderBlock>
          <Button
            transparent
            height={36}
            width={157}
            size={12}
            onClick={() => {
              if (history.length > 1) {
                history.goBack();
              } else {
                history.push(isAuth ? "" : "/investor/offerings/all");
              }
            }}
          >
            <BackIcon style={{ marginRight: 8 }} />
            Back to Main
          </Button>
        </BorderBlock>
        <MainHeading>{pageName}</MainHeading>
      </Header>

      <ColWrapper>
        <Col width="calc(100% - 470px)">
          <Paper>{renderContent()}</Paper>
        </Col>
        <Col width="440px">
          <Navigation>
            <ScrollContainer>{renderNav()}</ScrollContainer>
          </Navigation>
        </Col>
      </ColWrapper>
    </Wrapper>
  );
};
