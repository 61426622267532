import React, { Fragment, useState, useEffect } from "react";
import moment from "moment";

import { TRow, TCeil, DocName, Tooltip, Action, Status, RevievedBtn, BtnRow } from "./styled";
import { ReactComponent as DownLoad } from "../../../../asset/download.svg";
import { ReactComponent as Eye } from "../../../../asset/eye-small.svg";
import { ReactComponent as Help } from "../../../../../assets/help-circle.svg";

import { File as FileIcon } from "components/common/icons-primary";

import Api from "../../../../helpers/investorApi";
import { ErrorMessage } from "../../styled";
import { getTrackerHTML } from "../../../../helpers/fileViewer";
import { FileMessagePortal } from "../../../../../components/admin-layout/fileMessage";

export const File = ({
  file: { type, name: fileName, size, subsection, status, added, available, reviewed },
  section,
  onDoubleClick,
  isOpenViewer,
  token,
  assetId,
  folderName,
  updateFiles,
  history,
}) => {
  const [_reviewed, setReviewed] = useState(reviewed);
  const [wait, setWait] = useState(false);
  const [errorObj, setError] = useState(null);
  const [messageOpen, setMessageOpen] = useState(false);
  const [screenSize, setScreenSize] = useState(window.innerWidth);

  const onChangeReviewStatus = (status = false) => async () => {
    setWait(true);

    const res = await Api.setReviewStatus(token, folderName, fileName, assetId, status).catch((e) => {
      setWait(false);
      setError(e);
    });

    if (res) {
      setWait(false);
      setReviewed(status);
      updateFiles();
    }
  };

  const onDownLoad = async () => {
    setWait(true);
    const s3Link = await Api.getS3Link(token, folderName, fileName, assetId).catch((e) => {
      setWait(false);
      setMessageOpen(false);
      console.log(e);
    });
    const link = document.createElement("a");
    link.download = fileName;
    link.href = s3Link;
    link.click();
    setMessageOpen(false);
    setWait(false);
  };

  const openFileInNewTab = () => {
    getTrackerHTML(token, fileName, `${folderName}/${fileName}?assetId=${assetId}`, false, true, false, false);
  };

  const onOpenMessage = () => {
    setMessageOpen(!messageOpen);
  };

  const onAskQuestion = () => {
    history.push(
      `/investor/offerings/question-and-answer/${assetId}/${folderName}/${fileName}${window.location.hash || ""}`
    );
  };

  useEffect(() => {
    const onResize = () => {
      setScreenSize(window.innerWidth);
    };

    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  const smallScreen = screenSize < 1280;

  return (
    <Fragment>
      <TRow
        key={`${fileName}-${size}-${added}`}
        onDoubleClick={
          available ? (type === "xlsx" || type === "xlx" ? onOpenMessage : onDoubleClick) : () => console.log()
        }
        hidden={!available}
      >
        <TCeil big p={78} full={isOpenViewer} responsive={`@media(max-width:1280px){padding-right:0;}`}>
          <DocName>
            <FileIcon />
            <Fragment>
              <span>
                {section != "0" ? `${section}.${subsection}.` : null} {fileName}
              </span>
              {available ? (
                <Tooltip>
                  <Action name="download file" icon={<DownLoad />} onClick={onDownLoad} />
                  <Action name="open in new tab" icon={<Eye />} onClick={openFileInNewTab} />
                  <Action name="ask a question" icon={<Help />} onClick={onAskQuestion} />
                </Tooltip>
              ) : null}
            </Fragment>
          </DocName>
        </TCeil>

        {isOpenViewer ? null : (
          <Fragment>
            <TCeil fixedWidth="147px">{available ? <Status status={status} /> : null}</TCeil>
            <TCeil lower fixedWidth="107px">
              {type}
            </TCeil>
            <TCeil lower fixedWidth="204px">
              {moment(added).format("DD.MM.YYYY, hh:mm A")}
            </TCeil>
            <TCeil lower fixedWidth="210px">
              {available && status !== "NOT_OPENED" ? (
                <BtnRow>
                  <RevievedBtn
                    onClick={onChangeReviewStatus()}
                    status={_reviewed === false ? false : null}
                    disabled={wait}
                    left
                  >
                    <span>In review</span>
                  </RevievedBtn>
                  <RevievedBtn onClick={onChangeReviewStatus(true)} status={_reviewed || null} disabled={wait}>
                    <span>Reviewed</span>
                  </RevievedBtn>
                </BtnRow>
              ) : null}
            </TCeil>
          </Fragment>
        )}
      </TRow>

      {messageOpen ? (
        <FileMessagePortal
          onClose={onOpenMessage}
          title="Message"
          message={`Sorry, the browser does not support this file type. 
              <br/>However, you can download it.`}
          onDownload={onDownLoad}
          disable={wait}
        />
      ) : null}
      {errorObj ? <ErrorMessage er={errorObj} onClose={() => setError(null)} /> : null}
    </Fragment>
  );
};
