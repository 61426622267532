import React from "react";
import ReactDOM from "react-dom";
import {
  Background,
  Wrapper,
  HeaderModal,
  Heading,
  Content,
  FoooterModal,
  RowInputs,
} from "../styled";
import { Btn } from "../../../buttons";
import { ReactComponent as CloseIcon } from "../../../../../assets/x-circle.svg";
import {
  TextFieldComponent,
  TextFieldComponentPlaceholder,
  DateComponent,
  SelectComponent,
} from "../../../forms";
import { useState } from "react";

export const AddRound = ({
  onBackdropClick,
  curency = "GBP",
  callBack,
  isEdit = false,
  defaultRound = {
    fundingName: "",
    sharePrice: "",
    roundSize: "",
    newSharesQuantity: "",
    companyValuation: "",
    currency: curency,
    roundFrom: "",
    roundTo: "",
  },
}) => {
  const [round, setRound] = useState({ ...defaultRound });

  const onChange = (e, key) => {
    const value = e && e.target ? e.target.value : e;
    setRound({ ...round, [key]: value });
  };

  return ReactDOM.createPortal(
    <Background onClick={onBackdropClick}>
      <Wrapper onClick={(e) => e.stopPropagation()}>
        <HeaderModal>
          <Heading>{isEdit ? "Edit" : "Add"} Round</Heading>
          <CloseIcon onClick={onBackdropClick} />
        </HeaderModal>
        <Content p="25px">
          <RowInputs>
            <TextFieldComponent
              width="calc(50% - 12px)"
              label="Round Name"
              placeholder="Round Name"
              onChange={(e) => onChange(e, "fundingName")}
              defaultValue={round.fundingName}
            />
            <DateComponent
              width="calc(50% - 12px)"
              label="Round Date"
              placeholder="Round Date"
              onChange={(e) => onChange(e, "roundTo")}
              defaultValue={round.roundTo}
            />
          </RowInputs>
          <RowInputs>
            <TextFieldComponent
              width="calc(50% - 12px)"
              label="ROUND SIZE"
              placeholder="Round size"
              type="number"
              onChange={(e) => onChange(e, "roundSize")}
              defaultValue={round.roundSize}
            />
            <SelectComponent
              width="calc(50% - 12px)"
              label="currency"
              placeholder="currency"
              values={[round.currency]}
              defaultValue={round.currency}
              onChange={(e) => onChange(e, "currency")}
            />
          </RowInputs>
          <RowInputs>
            <TextFieldComponentPlaceholder
              pseudo
              pseudoText={curency}
              width="calc(50% - 12px)"
              label="share price (AT THE TIME OF FUNDING)"
              placeholder="Share price"
              type="number"
              onChange={(e) => onChange(e, "sharePrice")}
              defaultValue={round.sharePrice}
            />
            <TextFieldComponent
              width="calc(50% - 12px)"
              label="NEW CREATED SHARES"
              placeholder="New created shares"
              type="number"
              onChange={(e) => onChange(e, "newSharesQuantity")}
              defaultValue={round.newSharesQuantity}
              disabled={isEdit && round.roundId}
            />
          </RowInputs>
          <RowInputs>
            <TextFieldComponentPlaceholder
              pseudo
              pseudoText={curency}
              label="VALUATION OF THE COMPANY (AT THE TIME OF FUNDING)"
              placeholder="Valuation of the company"
              onChange={(e) => onChange(e, "companyValuation")}
              defaultValue={round.companyValuation}
            />
          </RowInputs>
        </Content>
        <FoooterModal>
          <Btn hide onClick={onBackdropClick}>
            cancel
          </Btn>
          <Btn
            width={180}
            onClick={() => {
              callBack(round);
              onBackdropClick();
            }}
            disabled={
              !round.fundingName ||
              !round.newSharesQuantity ||
              !round.sharePrice ||
              !round.companyValuation
            }
          >
            {isEdit ? "EDIT" : "ADD"} ROUND
          </Btn>
        </FoooterModal>
      </Wrapper>
    </Background>,
    document.getElementById("round-modal")
  );
};
