import { handleActions } from "../../helpers/immer";
import * as asyncData from "./asyncDataAction";

function initState() {
  return {
    acceptedCurrency: [],
    sponsors: [],
    accesses: [],
    dealTypes: [],
    categories: [],
    investorWorkflowTypes: [],
    ruleOptions: [],
    countriesOption: [],
    lockUpTime: [],
    sponsorsFull: [],
    financialDealTypes: [],
  };
}

export const asyncDataReducer = handleActions(
  {
    [asyncData.setAcceptedCurrency]: (draftState, { payload }) => {
      draftState.acceptedCurrency = payload;
    },
    [asyncData.setSponsors]: (draftState, { payload }) => {
      draftState.sponsors = payload;
    },
    [asyncData.setAccesses]: (draftState, { payload }) => {
      draftState.accesses = payload;
    },
    [asyncData.setDealTypes]: (draftState, { payload }) => {
      draftState.dealTypes = payload;
    },
    [asyncData.setCategories]: (draftState, { payload }) => {
      draftState.categories = payload;
    },
    [asyncData.setInvestorWorkflowTypes]: (draftState, { payload }) => {
      draftState.investorWorkflowTypes = payload;
    },
    [asyncData.setRuleOptions]: (draftState, { payload }) => {
      draftState.ruleOptions = payload;
    },
    [asyncData.setCountriesOption]: (draftState, { payload }) => {
      draftState.countriesOption = payload;
    },
    [asyncData.setLockUpTimes]: (draftState, { payload }) => {
      draftState.lockUpTime = payload;
    },
    [asyncData.setSponsorsFull]: (draftState, { payload }) => {
      draftState.sponsorsFull = payload;
    },
    [asyncData.setFinancialTypes]: (draftState, { payload }) => {
      draftState.financialDealTypes = payload;
    },
  },
  initState()
);
