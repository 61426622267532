import React, { useState, Fragment, useEffect } from "react";
import styled from "styled-components";
import { CircularProgress } from "@material-ui/core";

import Api from "./api";
import { Form } from "./form";
import { EmptyInfo } from "../AssetDetails/common/emptyInfo";
import { icons } from "models/icons";

const Column = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 212px);
`;

const Row = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  background: #ffffff;
  border-radius: 4px;
  margin-bottom: 15px;
  padding: 5px 30px 5px 25px;
`;

const center = { margin: "auto" };

const TH = styled.div`
  font-weight: bold;
  font-size: 10px;
  line-height: 135%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: ${({ blue = false, theme: { primary } }) => (blue ? primary : "rgba(24, 24, 24, 0.6)")};
  width: ${({ w }) => w};
`;

const Card = styled.div`
  width: 100%;
  background: #fff;
  padding: 25px;
  box-sizing: border-box;
`;

export const FormsList = ({ token, history }) => {
  const [forms, setForms] = useState(null);

  const getForms = async () => {
    const res = await Api.getForms(token);
    if (res) setForms(res);
  };

  useEffect(() => {
    getForms();
  }, []);

  const renderForms = () => {
    return forms.map((f) => <Form key={f.id} form={f} token={token} history={history} updateCb={getForms} />);
  };

  return (
    <Column>
      {!forms ? (
        <CircularProgress style={center} />
      ) : forms.length ? (
        <Fragment>
          <Row>
            <TH w="calc(100% - 777px)" blue>
              TEMPLATE NAME
            </TH>
            <TH w="95px">SECTIONS</TH>
            <TH w="95px">FIELDS</TH>
            <TH w="120px">LAST UPDATE</TH>
            <TH w="354px"></TH>
          </Row>
          {renderForms()}
        </Fragment>
      ) : (
        <Card>
          <EmptyInfo
            icon={icons.paper}
            heading="There are no forms created yet"
            text='Please click "ADD NEW TEMPLATE" to create first form.'
          />
        </Card>
      )}
    </Column>
  );
};
