import React, { useState, useRef, useEffect } from "react";

import {
  BG,
  FormBlockEmpty,
  FormEmpty,
  FormText,
  FilesModal,
  MGeader,
  HeadingM,
  MWrapper,
  Form,
  FormHeading,
  Button,
  FilesWrapper,
  Row,
  File,
  FileName_,
  Size,
  IconsBlock,
  Separator,
} from "../styled";
import { ReactComponent as Upload } from "../../../../../../assets/upload-big.svg";
import { ReactComponent as CloseIcon } from "../../../../../../assets/x-circle.svg";
import { ReactComponent as UploadIcon } from "../../../../../../assets/upload.svg";
import { File as FileIcon } from "components/common/icons-primary";
import { Repeat as Replace } from "components/common/icons-primary";
import { ReactComponent as Delete } from "../../../../../../assets/delete-file.svg";
import DataRoomApi from "../../../../../helpers/api";
import { ErrorMessage } from "../errorMessage";

import { SelectComponent } from "../../../../../../components/admin-layout/forms";

export const AddFile = ({ onBackdropClick, token, assetId, refreshDocs, folders }) => {
  const [files, setFiles] = useState([]);
  const [replaced, setIsReplaced] = useState(null);
  const [wait, setWait] = useState(false);
  const [targetFolder, setTargetFolder] = useState("Unsorted Files");
  const inputFile = useRef(null);
  const [errorObj, setError] = useState(null);

  const preventDefaultCustom = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const onAddFiles = async (_files) => {
    const arrFiles = Array.from(_files);
    if (
      arrFiles.some(
        ({ type }) =>
          type !== "application/pdf" &&
          type !== "image/png" &&
          type !== "image/jpeg" &&
          type !== "image/jpg" &&
          type !== "application/vnd.ms-excel" &&
          type !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      )
    ) {
      return;
    } else {
      if (typeof replaced === "number") {
        new Promise((res) => {
          const list = [...files];
          list.splice(replaced, 1, _files[0]);
          setFiles(list);

          res(setIsReplaced(null));
        });
      } else {
        setFiles([...files, ..._files]);
      }
    }
  };

  const onUpload = (e) => {
    e.stopPropagation();
    inputFile && inputFile.current && inputFile.current.click();
  };

  const onDelteFile = (num) => {
    const list = [...files];
    list.splice(num, 1);
    setFiles(list);
  };

  const onReplaceFile = (e, num) => {
    new Promise((res) => {
      setIsReplaced(num);
      res(onUpload(e));
    });
  };

  const renderFiles = () =>
    files.map(({ name, lastModified, size }, i) => (
      <File key={`${name}-${lastModified}-${i}`}>
        <FileIcon />
        <FileName_>{name}</FileName_>
        <Size>{(size / 1048576).toFixed(2)} mb</Size>
        <IconsBlock>
          <Replace onClick={(e) => onReplaceFile(e, i)} />
          <Delete onClick={() => onDelteFile(i)} />
        </IconsBlock>
      </File>
    ));

  const onSubmit = async () => {
    setWait(true);
    const formData = new FormData();

    for (let i = 0; i < files.length; i++) {
      formData.append(`files`, files[i], files[i].name);
    }

    let res;

    if (targetFolder === "Unsorted Files") {
      res = await DataRoomApi.uploadFiles(token, formData, assetId).catch((e) => {
        setWait(false);
        setError(e);
      });
    } else {
      res = await DataRoomApi.uploadFilesToFolder(token, formData, assetId, targetFolder).catch((e) => {
        setWait(false);
        setError(e);
      });
    }

    if (res) {
      setWait(false);
      refreshDocs();
      onBackdropClick();
    }
  };

  if (errorObj) {
    return <ErrorMessage er={errorObj} onClose={() => setError(null)} />;
  } else {
    return (
      <BG
        onClick={onBackdropClick}
        onDragEnter={preventDefaultCustom}
        onDragOver={preventDefaultCustom}
        onDragLeave={preventDefaultCustom}
        onDrop={preventDefaultCustom}
      >
        {files.length ? (
          <FilesModal
            onDragEnter={preventDefaultCustom}
            onDragOver={preventDefaultCustom}
            onDragLeave={preventDefaultCustom}
            onDrop={preventDefaultCustom}
            onClick={(e) => e.stopPropagation()}
          >
            <MGeader>
              <HeadingM>Upload New Files</HeadingM>
              <CloseIcon onClick={onBackdropClick} />
            </MGeader>
            <MWrapper>
              <Form
                onSubmit={(e) => e.preventDefault()}
                enctype="multipart/form-data"
                action=""
                method="post"
                onDragEnter={preventDefaultCustom}
                onDragOver={preventDefaultCustom}
                onDragLeave={preventDefaultCustom}
                onDrop={(e) => {
                  preventDefaultCustom(e);
                  onAddFiles(e.dataTransfer.files);
                }}
                onClick={(e) => onUpload(e)}
              >
                <FormHeading>Drop files here or</FormHeading>
                <Button width={165}>
                  <UploadIcon /> upload FILES
                </Button>
                <input
                  ref={inputFile}
                  multiple={!(typeof replaced === "number")}
                  type="file"
                  style={{ display: "none" }}
                  id="file-dataAndRoom"
                  accept=".pdf,image/jpeg,image/png,image/jpg,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  onChange={(e) => {
                    if (e.target.files.length) {
                      onAddFiles(e.target.files);
                    }
                  }}
                />
              </Form>
              <SelectComponent
                padding="0 60px 0 55px"
                label="Add files to folder"
                placeholder="Add files to folder"
                values={folders.filter((f) => f !== "Legal Documents")}
                defaultValue={targetFolder}
                selectHeight={50}
                top={74}
                fSize={14}
                onChange={(e) => setTargetFolder(e.target.value)}
                view="filter"
              />
              <Separator />
              <FilesWrapper>{renderFiles()}</FilesWrapper>
              <Row>
                <Button transparent width={94}>
                  cancel
                </Button>
                <Button width={180} onClick={onSubmit} disabled={wait}>
                  UPLOAD {files.length} FILES
                </Button>
              </Row>
            </MWrapper>
          </FilesModal>
        ) : (
          <FormBlockEmpty
            onDragEnter={preventDefaultCustom}
            onDragOver={preventDefaultCustom}
            onDragLeave={preventDefaultCustom}
            onDrop={preventDefaultCustom}
          >
            <FormEmpty
              onSubmit={(e) => e.preventDefault()}
              enctype="multipart/form-data"
              action=""
              method="post"
              onDragEnter={preventDefaultCustom}
              onDragOver={preventDefaultCustom}
              onDragLeave={preventDefaultCustom}
              onDrop={(e) => {
                preventDefaultCustom(e);
                onAddFiles(e.dataTransfer.files);
              }}
              onClick={(e) => onUpload(e)}
            >
              <Upload />
              <FormText>Drop your files here to upload</FormText>
              <input
                ref={inputFile}
                multiple
                type="file"
                accept=".pdf,image/jpeg,image/png,image/jpg,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                style={{ display: "none" }}
                id="file-dataAndRoom"
                onChange={(e) => {
                  if (e.target.files.length) onAddFiles(e.target.files);
                }}
              />
            </FormEmpty>
          </FormBlockEmpty>
        )}
      </BG>
    );
  }
};
