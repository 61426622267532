import React from "react";
import styled from "styled-components";

import { ReactComponent as CloseIcon } from "../../assets/x-circle.svg";

const Background = styled.section`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(5px);
  display: flex;
  z-index: 1000;
`;

const Wrapper = styled.div`
  width: 440px;
  display: flex;
  min-height: ${({ mHeight = false }) => (mHeight ? mHeight : "270px")};
  flex-direction: column;
  background: #ffffff;
  border: 1px solid #f9f9f9;
  box-sizing: border-box;
  box-shadow: 0px 10px 40px rgba(89, 120, 150, 0.06);
  border-radius: 4px;
  margin: auto;
`;

const Header = styled.div`
  height: 52px;
  width: 100%;
  padding: 0 25px;
  background: #ffffff;
  border: 1px solid #f9f9f9;
  box-sizing: border-box;
  box-shadow: 0px 10px 40px rgba(89, 120, 150, 0.08);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  > svg {
    cursor: pointer;
    width: 24px;
    height: 24px;
  }
`;

const Content = styled.div`
  width: 100%;
  min-height: ${({ contentHeight = false }) => (contentHeight ? contentHeight : "198px")};
  padding: ${({ padding }) => (padding ? padding : "30px 100px 40px")};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

const Heading = styled.h5`
  font-family: Muli;
  font-style: normal !important;
  font-weight: 600;
  font-size: 16px;
  line-height: 135%;
  margin: 0;
  color: #181818;
  text-transform: none;
`;

const MessageHeading = styled.h6`
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 135%;
  text-align: center;
  letter-spacing: -0.02em;
  margin: 0;
  color: ${({ error = false }) => (error ? "#FF4343" : "#019D52")};
`;

const Text = styled.p`
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  text-align: center;
  margin: 0;
  color: #333333;
  text-transform: none;
  > b {
    font-family: Muli;
  }
`;

const Button = styled.button`
  outline: none;
  width: 180px;
  height: 32px;
  background: ${({ theme: { primary } }) => primary};
  border-radius: 4px;
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 135%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  padding: 0;
  color: #ffffff;
  border: none;
  cursor: pointer;
`;

const MessagesContent = {
  success: {
    heading: "Successfull",
    messageHeading: "All right!",
    message: "Operation went good, close this window and continue your work",
  },
  failure: {
    heading: "Error!",
    messageHeading: "Something Went Wrong",
    message: "Operation went wrong, try to repeat or refresh your page",
  },
};

const Cancel = styled.button`
  outline: none;
  width: 90px;
  height: 32px;
  background: ${({ theme: { primary } }) => primary};
  border-radius: 4px;
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 135%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  padding: 0;
  border: 1px solid #efefef;
  background: transparent;
  color: #efefef;
  cursor: pointer;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const ActionMessage = ({ isError = false, isOpen, onBackDpopClick, customMessage = false }) => {
  const type = isError ? "failure" : "success";

  const { heading, messageHeading, message } = MessagesContent[type];

  return (
    isOpen && (
      <Background onClick={onBackDpopClick}>
        <Wrapper onClick={(e) => e.stopPropagation()}>
          <Header>
            <Heading>{heading}</Heading>
            <CloseIcon onClick={onBackDpopClick} />
          </Header>
          <Content>
            <MessageHeading error={isError}>{messageHeading}</MessageHeading>
            <Text>{isError && customMessage ? customMessage : message}</Text>
            <Button onClick={onBackDpopClick}>CLOSE</Button>
          </Content>
        </Wrapper>
      </Background>
    )
  );
};

export const SendEmailMessage = ({ isError = false, isOpen, onBackDpopClick, customMessage = false }) => {
  const type = isError ? "failure" : "success";

  const { heading, messageHeading, message } = MessagesContent[type];

  return (
    isOpen && (
      <Background onClick={onBackDpopClick}>
        <Wrapper onClick={(e) => e.stopPropagation()}>
          <Header>
            <Heading>{heading}</Heading>
            <CloseIcon onClick={onBackDpopClick} />
          </Header>
          <Content padding="30px">
            <MessageHeading error={isError}>{messageHeading}</MessageHeading>
            <Text
              dangerouslySetInnerHTML={{
                __html: customMessage ? customMessage : message,
              }}
            />
            <Button onClick={onBackDpopClick}>CLOSE</Button>
          </Content>
        </Wrapper>
      </Background>
    )
  );
};

export const ConfirmMessage = ({
  heading,
  isOpen,
  onBackDpopClick,
  message,
  onAction,
  actionName = "Delete",
  mHeight,
  contentHeight,
  wait = false,
}) => {
  return isOpen ? (
    <Background onClick={onBackDpopClick}>
      <Wrapper onClick={(e) => e.stopPropagation()} mHeight={mHeight}>
        <Header>
          <Heading>{heading}</Heading>
          <CloseIcon onClick={onBackDpopClick} />
        </Header>
        <Content padding="30px" contentHeight={contentHeight}>
          <Text
            dangerouslySetInnerHTML={{
              __html: message,
            }}
          />
          <Row>
            <Cancel onClick={onBackDpopClick}>CLOSE</Cancel>
            <Button disabled={wait} onClick={onAction}>
              {actionName}
            </Button>
          </Row>
        </Content>
      </Wrapper>
    </Background>
  ) : null;
};
