import moment from 'moment';

export function getDateArea(date = moment()) {
  const area = [];

  try {
    date = date.startOf('month');
    let weekDay = date.day();
    if (weekDay <= 0) date = date.add(-1, 'month');

    while (weekDay > 0) {
      date = date.add(-1, 'day');
      area.unshift(date.toISOString());
      weekDay--;
    }

    date = date.add(1, 'month').startOf('month');
    let month = date.format('M');
    while (month == date.format('M')) {
      area.push(date.toISOString());
      date = date.add(1, 'day');
    }

    date = date.add(-1, 'month').endOf('month');
    weekDay = date.day();
    while (weekDay < 6) {
      date = date.add(1, 'day');
      area.push(date.toISOString());
      weekDay++;
    }
  } catch (_) {}
  return area;
}
