import React, { Fragment, useState } from "react";
import { connect } from "react-redux";

import { Btn } from "../../buttons";

import { CardExisted, CardNew } from "./card";
import { Link, Main, Header, CardContainer, TextH3 } from "./styled";
import { ReactComponent as ArrowLeft } from "../../../../assets/arrowleft.svg";
import { ReactComponent as Plus } from "../../../../assets/plus-circle.svg";

const ChooseAssetType = (props) => {
  const [type, setType] = useState("NEW_DEAL");
  const onChangeType = (newType) => setType(newType);
  const onBackToAssents = () => props.history.push(`/admin/deals/new_deal`);

  return (
    <Fragment>
      <Header>
        <Btn onClick={onBackToAssents} width={150} transparent>
          <ArrowLeft /> back to Assets
        </Btn>
        <TextH3 size={14} marginSize="0 0 0 25px">
          Add New Asset
        </TextH3>
      </Header>
      <Main>
        <TextH3 marginSize="0">What Type of Asset you Want to Add?</TextH3>
        <CardContainer>
          <CardNew type={type} onChangeType={onChangeType} />
          <CardExisted type={type} onChangeType={onChangeType} />
        </CardContainer>
        <Link to={`/admin/deals/create/step/${type}`}>
          <Btn width={215}>
            <Plus /> add new asset
          </Btn>
        </Link>
      </Main>
    </Fragment>
  );
};

export default connect()(ChooseAssetType);
