import React from "react";

import { FlexRow, SocialLink } from "./styled";
import { ReactComponent as LinkedIn } from "../assets/linked.svg";
import { ReactComponent as FB } from "../assets/fb.svg";
import { ReactComponent as Instagram } from "../assets/Instagram.svg";
import { ReactComponent as Medium } from "../assets/Medium.svg";
import { ReactComponent as Twitter } from "../assets/Twitter.svg";

export const InvestureLinks = () => {
  return (
    <FlexRow>
      <SocialLink
        href="https://www.linkedin.com/company/investure-co"
        target="_blank"
      >
        <LinkedIn />
      </SocialLink>
      <SocialLink
        href="https://www.facebook.com/WeAreInvesture"
        target="_blank"
      >
        <FB />
      </SocialLink>
      <SocialLink href="https://medium.com/@investure" target="_blank">
        <Medium />
      </SocialLink>
      <SocialLink
        href="https://www.instagram.com/investure_markets/"
        target="_blank"
      >
        <Instagram />
      </SocialLink>
      <SocialLink href="https://twitter.com/WeAreInvesture" target="_blank">
        <Twitter />
      </SocialLink>
    </FlexRow>
  );
};
