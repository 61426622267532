import { createEmptyDoc } from "../helpers";

export const dealObjectFactory = (
  acceptedCurrency = "GBP",
  managerSponsors = [],
  access = "",
  dealsType = "",
  category = "",
  investorWorkflowType,
  financialDealTypes = []
) => {
  return {
    newWorkflow: true,
    roundsInfo: [],
    assetProperties: {
      assetName: "", //
      assetSymbol: "", //
      category,
      dealsType, //
      investorWorkflowType: investorWorkflowType, //
      assetLocation: null, //
      images: [], //
      vocAssetDynamicProperties: [], //
      descriptionHeader: "", //
      smallDescription: "", //
      fullDescription: "", //
      targetInvestorIRR: "", //
      targetEquityMultiple: "", //
      targetAverageCashYield: "", //
      targetedInvestmentPeriod: "", //
      acquisitionDate: "", //
      preferredReturnAccrualDate: "", //
      acquisitionCost: "", //
      targetedProjectLevelIRR: "", //
      distributionCommencement: "", //
      distributionPeriod: "", //
      targetPortfolioCloseDate: "", //
      softCap: "", //
      hardCap: "", //
      // new fields
      period: "Year",
      currency: acceptedCurrency,

      documents: {
        teaser: createEmptyDoc(),
        nda: createEmptyDoc(),
        infoMemo: createEmptyDoc(),
        loi: createEmptyDoc(),
        dealInfo: createEmptyDoc(),
        spa: createEmptyDoc(),
        otherDocs: [],
      },
      dynamicCapitalStructureName: null,
      dynamicCapitalStructureJson: null,
      dealTypeFinancial: financialDealTypes[0] ? financialDealTypes[0] : null,
      currentlyFundraising: null,
    },
    sharingProperty: {
      company: "VoC",
      sharedCompanies: ["Voc"],
    },
    visibilityProperty: {
      access: "LOG_IN", //
      // active: true,
      isActive: true,
      countryRestriction: { listType: "ALL" },
      lockUpPeriod: {},
      groups: null,
      users: null,
      activeRulesJson:
        dealsType === "NEW_DEAL"
          ? '{"countryRestriction":false,"lockUpPeriod":false,"groups":false,"users":false}'
          : '{"countryRestriction":false,"groups":false,"users":false}',
    },
    assetFinancialProperty: {
      baseCurrency: "GBP", //
      acceptedCurrency: [acceptedCurrency], //
      tokenQuantity: "", //
      minInvestment: "", //
      maxInvestment: "", //
      timeOfStart: new Date().toISOString(), //
      timeOfEnd: new Date().toISOString(), //
      sponsorCoInvestment: -1, //
      investorEquity: -1, //
      seniorDebt: -1, //

      account: {
        accountName: "",
        accountNumber: "", //
        bankName: "", //
        bic: "", //
        iban: "", //
      },
    },
    managerSponsors,
    assetKycTemplates:
      dealsType === "NEW_DEAL"
        ? {
            institutionTemplateId: 0,
            otherRolesTemplates: {},
            professionalTemplateId: 0,
          }
        : null,
  };
};

export const activeRulesJson = {
  countryRestriction: "Country Restriction",
  lockUpPeriod: "Lock Up Period",
  groups: "Groups",
  users: "Users",
};
