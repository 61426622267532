import React from "react";

import { ReactComponent as UserIcon } from "../../../../assets/user1.svg";
import { ReactComponent as BankIcon } from "../../../../assets/briefcase1.svg";
import { ReactComponent as KYCIcon } from "../../../../assets/kyc-icon.svg";

export const Navigation = [
  {
    name: "My Profile",
    link: "/investor/account/profile",
    icon: <UserIcon />,
    re: /\/investor\/account\/profile$/
  },
  {
    name: "Bank Account",
    link: "/investor/account/bank-account",
    icon: <BankIcon />,
    re: /\/investor\/account\/bank-account$/
  },
  {
    name: "KYC",
    link: "/investor/account/verify/",
    icon: <KYCIcon />,
    re: /\/investor\/account\/verify.+$/
  },
  {
    name: "Additional Information",
    link: "/investor/account/additional",
    icon: <KYCIcon />,
    re: /\/investor\/account\/additional$/
  }
];

export const FacilitatorNav = [
  {
    name: "My Profile",
    link: "/investor/account/profile",
    icon: <UserIcon />,
    re: /\/investor\/account\/profile$/
  },
  {
    name: "Additional Information",
    link: "/investor/account/additional",
    icon: <KYCIcon />,
    re: /\/investor\/account\/additional$/
  }
];
