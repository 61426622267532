import React, { useState, useEffect, Fragment } from "react";
import styled from "styled-components";

import { icons } from "models/icons";
import { SelectType } from "./typeSelect";
import { ReactComponent as Text } from "../../../../assets/typeSelect/text.svg";
import { ReactComponent as Single } from "../../../../assets/typeSelect/single.svg";
import { ReactComponent as Multiple } from "../../../../assets/typeSelect/multiple.svg";
import { ReactComponent as DateIcon } from "../../../../assets/typeSelect/date.svg";
import { ReactComponent as File } from "../../../../assets/typeSelect/file.svg";
import { RequireRadio } from "./requredRadio";
import { getRandomString } from "./helpers";

const Card = styled.div`
  width: 100%;
  background: #ffffff;
  border: 1px solid #efefef;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 20px 25px;
  margin-bottom: 10px;
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  align-items: center;
  margin: ${({ m = 0 }) => m};
`;

const Line = styled.div`
  width: calc(100% + 50px);
  margin: 20px 0 15px;
  border-top: 1px solid #efefef;
  transform: translateX(-25px);
`;

const Input = styled.input`
  width: calc(100% - 273px);
  height: 42px;
  background: #ffffff;
  border: 1px solid ${({ error }) => (error ? "#FF4343" : "#efefef")};
  box-sizing: border-box;
  border-radius: 4px;
  padding: 0 20px;
  margin-right: 12px;
  outline: none;
`;

const TextArea = styled.textarea`
  width: 100%;
  height: 82px;
  background: #ffffff;
  border: 1px solid #efefef;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 12px 20px;
  outline: none;
  margin-top: 12px;
  resize: none;
`;

const Copy = styled.div`
  width: 125px;
  height: 32px;
  background: ${({ remove }) => (remove ? "transparent" : "#eeeeee")};
  border-radius: 4px;
  cursor: pointer;
  box-sizing: border-box;
  padding: 0 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  line-height: 135%;
  letter-spacing: -0.02em;
  color: ${({ remove }) => (remove ? "rgba(51, 51, 51, 0.6)" : "#181818")};
  border: 1px solid #eeeeee;
  margin: ${({ m = 0 }) => m};
  > svg {
    width: 16px;
    height: 16px;
  }
`;

const Column = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 20px 0 0;
  display: flex;
  flex-direction: column;
`;

const AddAnswer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 10px;
  line-height: 135%;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: ${({ theme: { primary } }) => primary};
  position: relative;
  padding-left: 20px;
  box-sizing: border-box;
  svg {
    width: 9px;
    height: 9px;
    margin-right: 7px;
    path {
      stroke: ${({ theme: { primary } }) => primary};
    }
  }

  &:before {
    content: "";
    display: block;
    width: 10px;
    border-top: 1px solid #efefef;
    position: absolute;
    left: 0;
  }
  &:after {
    content: "";
    display: block;
    position: absolute;
    width: calc(100% - 170px);
    left: 170px;
    border-top: 1px solid #efefef;
  }
`;

export const selectValues = [
  { name: "Text", icon: <Text /> },
  {
    name: "Single Answer",
    icon: <Single />,
  },
  {
    name: "Multiple Answers",
    icon: <Multiple />,
  },
  { name: "Date", icon: <DateIcon /> },
  {
    name: "Upload File",
    icon: <File />,
  },
];

const Current = styled.div`
  width: 205px;
  height: 42px;
  background: #ffffff;
  border: 1px solid ${({ active, theme: { primary } }) => (active ? primary : "#EFEFEF")};
  box-sizing: border-box;
  border-radius: 4px;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({ active }) => (active ? "#333333" : "rgba(51, 51, 51, 0.6)")};
  font-size: 12px;
  line-height: 135%;
  letter-spacing: -0.02em;
  cursor: pointer;
  margin-right: auto;
  transition: all 0.1s linear;
`;

const RemoveOption = styled.div`
  width: 42px;
  height: 42px;
  background: #f4f4f4;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  cursor: pointer;
  svg {
    margin: auto;
    width: 14px;
    height: 14px;
  }
`;

const CheckBox = styled.div`
  width: 14px;
  height: 14px;
  border: 1px solid ${({ active, theme: { primary } }) => (active ? primary : "#EFEFEF")};
  background: ${({ active }) => (active ? "transparent" : "#EEEEEE")};
  border-radius: 2px;
  box-sizing: border-box;
  display: flex;
  transition: all 0.1s linear;
  svg {
    width: 8px;
    height: 8px;
    margin: auto;
    transform: ${({ active }) => (active ? "none" : "scale(0)")};
    transition: all 0.1s linear;
    path {
      stroke: ${({ theme: { primary } }) => primary};
    }
  }
`;

const ErrorBlock = styled.div`
  color: red;
  padding-bottom: 20px;
  font-size: 14px;
`;

const Answer = ({ onSetRight, onChange, _options, values, error }) => {
  const [options, setOptions] = useState([..._options]);

  const onAddOption = () => {
    const opt = [...options];
    opt.push({
      id: getRandomString(16),
      value: "",
    });
    setOptions(opt);
  };

  const onChangeOption = (e, i) => {
    const opt = [...options];
    opt[i].value = e.target.value;
    setOptions(opt);
  };

  const onRemoveOption = (id) => () => {
    const opt = [...options];
    const index = opt.findIndex((o) => o.id === id);
    opt.splice(index, 1);
    setOptions(opt);
  };

  const renderOptions = () => {
    return options.map((o, i) => {
      const rightOption = values.includes(o.id);
      return (
        <Row key={o.id} m={`0 0 ${i === options.length - 1 ? "20px" : "10px"}`}>
          <Input
            placeholder="Answer Without Headline"
            value={options[i].value}
            onChange={(e) => onChangeOption(e, i)}
          />
          <Current active={rightOption} onClick={onChooseRight(o.id)}>
            Flag as correct
            <CheckBox active={rightOption}>{icons.check}</CheckBox>
          </Current>
          <RemoveOption onClick={onRemoveOption(o.id)}>{icons.crossGrey}</RemoveOption>
        </Row>
      );
    });
  };

  const onChooseRight = (val) => () => {
    onSetRight(val);
  };

  useEffect(() => {
    onChange(options);
  }, [options]);

  return (
    <Column className={error ? "options-error" : ""}>
      {error ? (
        <ErrorBlock>
          Single answer or multiple answers sould contain current options. Please
          {options.length ? "" : " add and then "} set them.
        </ErrorBlock>
      ) : null}
      {renderOptions()}
      <AddAnswer onClick={onAddOption}>{icons.plusCircle} ADD ANSWER OPTION</AddAnswer>
    </Column>
  );
};

export const Field = ({ f, onChange, onRemove, onCopy, error = false, valuesError = false }) => {
  const [field, setField] = useState({ ...f });
  const { id, name, required, type, options, values, description } = field;

  const isAnswer = type === "Single Answer" || type === "Multiple Answers";

  const onChangeType = (type) => {
    const _field = { ...field, type, values: [], options: [], attachments: [] };
    delete _field.error;
    delete _field.valuesError;

    setField(_field);
  };

  const onChangeName = (e) => {
    const _field = { ...field, name: e.target.value };
    if (_field.error) delete _field.error;
    setField(_field);
  };

  const onDescript = (e) => {
    const _field = { ...field, description: e.target.value };
    setField(_field);
  };

  const onChangeRequired = (val) => {
    const _field = { ...field, required: val };
    setField(_field);
  };

  const _onRemove = () => {
    onRemove(id);
  };

  const _onCopy = () => {
    onCopy(field);
  };

  const onChangeAnswers = (opt) => {
    const _field = { ...field, options: opt };
    _field.values.forEach((id, i, arr) => {
      if (!opt.find((o) => o.id === id)) {
        arr.splice(i, 1);
      }
    });
    if (valuesError) delete _field.valuesError;
    setField(_field);
  };

  const onSetCurrent = (id) => {
    if (type === "Single Answer") {
      const _field = { ...field };
      if (_field.values.includes(id)) {
        _field.values.length = 0;
      } else {
        _field.values.splice(0, 1, id);
      }
      if (valuesError) delete _field.valuesError;
      setField(_field);
    }
    // Miltiple
    else {
      const _field = { ...field };
      const index = _field.values.indexOf(id);
      if (index === -1) {
        _field.values.push(id);
      } else {
        _field.values.splice(index, 1);
      }
      if (valuesError) delete _field.valuesError;
      setField(_field);
    }
  };

  useEffect(() => {
    onChange(field);
  }, [field]);

  return (
    <Card key={id}>
      <Row>
        <Input
          className={error ? "field-name-error" : ""}
          key={`${id}-${type}`}
          placeholder="Field name"
          value={name}
          onChange={onChangeName}
          error={error}
        />
        <SelectType
          w="260px"
          onChange={onChangeType}
          values={selectValues}
          defaultValue={selectValues.findIndex((el) => el.name === field.type)}
        />
      </Row>
      {type === "Upload File" ? <TextArea placeholder="Description" value={description} onChange={onDescript} /> : null}
      {isAnswer ? (
        <Answer
          multiple={type === "Multiple Answers"}
          key={options.join("-")}
          _options={options}
          values={values}
          onChange={onChangeAnswers}
          onSetRight={onSetCurrent}
          error={valuesError}
        />
      ) : null}
      <Line />
      <Row>
        <RequireRadio val={required} onChange={onChangeRequired} />
        <Copy m="0 9px 0 auto" onClick={_onCopy}>
          Copy {icons.copyBlack}
        </Copy>
        <Copy remove onClick={_onRemove}>
          Remove {icons.crossGrey}
        </Copy>
      </Row>
    </Card>
  );
};
