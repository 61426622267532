import React, { useState } from "react";
import styled from "styled-components";

import { ReactComponent as Arrow } from "../../../../assets/chevron-down.svg";

const Block = styled.div`
  width: ${({ w = "100%" }) => w};
  margin: ${({ m = 0 }) => m};
  position: relative;
`;

const Select = styled.div`
  width: 100%;
  height: 42px;
  background: #ffffff;
  border: 1px solid #efefef;
  box-sizing: border-box;
  border-radius: ${({ open }) => (open ? "4px 4px 0 0" : "4px")};
  transition: all 0.1s linear;
  display: flex;
  align-items: center;
  padding: 0 20px;
  justify-content: space-between;
  cursor: pointer;
  > svg {
    width: 16px;
    height: 16px;
    transform: ${({ open }) => (open ? "rotate(180deg)" : "none")};
    transition: all 0.1s linear;
    path {
      stroke: ${({ theme: { primary } }) => primary};
    }
  }
`;

const Options = styled.div`
  width: 100%;
  height: ${({ h = 200 }) => `${h}px`};
  background: #ffffff;
  border: ${({ open }) => (open ? "1px solid #efefef" : "none")};
  box-sizing: border-box;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.05);
  border-radius: 0 0 4px 4px;
  position: absolute;
  overflow: hidden;
  transition: all 0.1s linear;
  z-index: 1000;
  top: 41px;
  box-sizing: border-box;
  padding: ${({ open }) => (open ? "20px" : "0 20px")};
  display: flex;
  flex-direction: column;
`;

const Layout = styled.section`
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 999;
  top: 0;
  left: 0;
`;

const Option = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  font-size: 12px;
  line-height: 135%;
  letter-spacing: -0.02em;
  color: #181818;
  opacity: ${({ active }) => (active ? 0.5 : 1)};
  transition: all 0.1s linear;
  cursor: pointer;
  &:hover {
    opacity: 0.5;
  }
  &:last-of-type {
    margin-bottom: 0;
  }
  > svg {
    width: 16px;
    height: 16px;
    margin-right: 9px;
    path {
      stroke: #181818;
    }
  }
`;

export const SelectType = ({ w, m, onChange, defaultValue, values }) => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(defaultValue || 0);

  const onChangeOpen = () => {
    setOpen(!open);
  };

  const onChoose = (index) => () => {
    setValue(index);
    onChange(values[index].name);
    onChangeOpen();
  };

  const renderOptions = () => {
    return values.map(({ name, icon }, i) => (
      <Option key={`${name}-${i}`} onClick={onChoose(i)} active={value === i}>
        {icon}
        {name}
      </Option>
    ));
  };

  return (
    <Block w={w} m={m}>
      <Select open={open} onClick={onChangeOpen}>
        <Option>
          {values[value].icon}
          {values[value].name}
        </Option>
        <Arrow />
      </Select>
      <Options open={open} h={open ? 200 : 0}>
        {renderOptions()}
      </Options>
      {open ? <Layout onClick={onChangeOpen} /> : null}
    </Block>
  );
};
