import React, { useState, useEffect } from "react";

import { Main, TextSub } from "../DealForm/Step/styled";
import { FlexRow } from "../../dashBoardCard";
import { ScrollContainer } from "../AssetDetails/styled";
import {
  ChatWrapper,
  LeftBlock,
  Avatar,
  Col,
  UserName,
  DealFlex,
  DealLogoSmall,
  NoDealLogoSmall,
  DealNameSmall,
  RightBlock,
} from "./styled";

import Api from "../../../../helpers/api";
import { UserIcon } from "components/common/icons-primary";
import { EmptyInfo } from "components/admin-layout/pages/AssetDetails/common/emptyInfo";
import { icons } from "models/icons";

export const Requests = ({ token, adminChatThemes = [] }) => {
  const renderChats = () => {
    if (adminChatThemes.length) {
      return adminChatThemes.map((e) => {
        return <Chat token={token} chat={e} key={`${e.assetId}-${e.username}-${e.unread}`} />;
      });
    } else
      return (
        <EmptyInfo
          icon={icons.messageThin}
          heading="There are no chats yet"
          text="When we’ll have chats to show for you we’ll show them here"
        />
      );
  };
  return (
    <Main currentHeight="415px" width="calc(100% - 325px)" padding="20px 25px 15px 25px">
      <FlexRow mBottom="0" align="flex-start">
        <TextSub textAlign="left" fontWeight="bold" color="#181818" marginSize="0 0 25px 0">
          Global chat from Investors
        </TextSub>
      </FlexRow>
      <ScrollContainer height={325} pLeft={15} isScroll={adminChatThemes && adminChatThemes.length > 3}>
        {renderChats()}
      </ScrollContainer>
    </Main>
  );
};

const Chat = ({
  token,
  chat: { logo, name, assetId, unread, userLogo, userFullName, username, lastUpdateDate, lastMessage },
}) => {
  const [img, setImg] = useState(null);
  const [dealLogo, setDealLogo] = useState(null);

  useEffect(() => {
    if (!img && userLogo) {
      const { fileNameWithExtension, secureHash } = userLogo;
      Api.getAttachment(token, secureHash, fileNameWithExtension).then((b) => setImg(b));
    }
    if (!dealLogo && logo) {
      const { fileNameWithExtension, secureHash } = logo;
      Api.getAttachment(token, secureHash, fileNameWithExtension).then((b) => setDealLogo(b));
    }
  }, []);
  return (
    <ChatWrapper to={`/admin/chat/${assetId}/${username}`}>
      <LeftBlock>
        {img ? <Avatar src={img} alt="logo" /> : <UserIcon />}

        <Col>
          <UserName>{userFullName}</UserName>
          <DealFlex>
            {dealLogo ? <DealLogoSmall src={dealLogo} alt="logo" /> : <NoDealLogoSmall />}
            <DealNameSmall>{name}</DealNameSmall>
          </DealFlex>
        </Col>
      </LeftBlock>
      <RightBlock unread={unread}>{lastMessage}</RightBlock>
    </ChatWrapper>
  );
};
