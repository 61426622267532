import { getRandomString } from "./helpers.js";

export default class BaseField {
  constructor(
    name = "",
    type = "Text",
    values = [],
    id = getRandomString(16),
    options = [],
    required = false,
    visibility = true,
    value = "",
    attachments = [],
    description = ""
  ) {
    this.name = name;
    this.type = type;
    this.values = values;
    this.id = id;
    this.options = options;
    this.required = required;
    this.visibility = visibility;
    this.value = value;
    this.attachments = attachments;
    this.description = description;
  }
}
