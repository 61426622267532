import React from "react";
import styled from "styled-components";

import { currenciesModel } from "../../../../models/currenciesModel";
import { moutNumber } from "helpers";

export const PieChartWrapper = styled.div`
  width: 200px;
  height: 200px;
  margin: 0 auto 20px;
`;

const InfoClol = styled.div`
  width: ${({ half = false }) => (half ? "calc(50% - 10px)" : "auto")};
  display: flex;
  flex-direction: column;
  align-items: ${({ align }) => (align ? align : "center")};
  margin-bottom: 24px;
  &:last-child {
    margin-bottom: 0;
  }
`;

const InfoColHeading = styled.h6`
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 135%;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  color: rgba(24, 24, 24, 0.6);
  margin: 0 0 4px 0;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: ${({ width }) => (width ? width : "100%")};
  align-items: center;
  margin: ${({ margin }) => margin};
`;

const InfoColVal = styled.p`
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: ${({ size }) => (size ? `${size}px` : "16px")};
  line-height: 135%;
  letter-spacing: 0.05em;
  text-transform: ${({ noTransform }) => (noTransform ? "none" : "uppercase")};
  margin: 0;
  color: #181818;
`;

const Percent = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 135%;
  margin: ${({ noMargin }) => (noMargin ? 0 : "0 6px 0 10px")};
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: rgba(24, 24, 24, 0.4);
`;

const StructureCircle = styled.div`
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: ${({ bg }) => bg};
  margin-left: auto;
`;

export const CapStructData = ({
  heading,
  val,
  color = false,
  rate = 1,
  total,
  half,
  currency
}) => {
  return (
    <InfoClol half={half} align="flex-start">
      <InfoColHeading>{heading}</InfoColHeading>
      <Row width="157px">
        <InfoColVal
          dangerouslySetInnerHTML={{
            __html:
              (currenciesModel[currency]
                ? currenciesModel[currency].symbol
                : currency) +
              // ((val * rate) / 1000000).toFixed() +
              // "M"
              moutNumber(val * rate)
          }}
        />
        <Percent>{((val / total) * 100).toFixed(0)}%</Percent>
        {color && <StructureCircle bg={color} />}
      </Row>
    </InfoClol>
  );
};
