import React, { useEffect, Fragment, useReducer } from "react";
import { connect } from "react-redux";
import { Switch, Route } from "react-router-dom";

import { Wrapper } from "../../main";
import { Tabs } from "./Tabs";
import { Filter } from "./Filter";
import { Table } from "./Table";
import { FormsList } from "../KycFormsList";

import { Paginator, WaitingWrapper, Separator } from "../UserList/styled";

import Api from "../../../../helpers/api";
import { CircularProgress } from "@material-ui/core";
import KycTableState, { reducer } from "./reducer";
import { EmptyInfo } from "../AssetDetails/common/emptyInfo";

const KYC = ({
  history,
  token,
  match: {
    params: { type },
  },
}) => {
  const [state, dispatch] = useReducer(reducer, new KycTableState());
  const { list, loadFilter, filter, statuses, loading, page, portion, totalPages } = state;

  const getInfo = async () => {
    const statuses = await Api.getKycStatuses(token);
    const answers = await Api.getInvestorAnswers(token, page, portion, filter);

    if (answers && statuses)
      dispatch({
        type: "CUSTOM_MERGE",
        payload: {
          statuses,
          list: answers.content,
          totalPages: answers.totalPages,
          loading: false,
        },
      });
  };

  useEffect(() => {
    getInfo();
  }, []);

  const onChangeFilter = async (filter) => {
    dispatch({ type: "SET_FILTER", filter });
    onChangePage(1, filter);
  };

  const onChangePage = async (page, _filter = filter) => {
    const answers = await Api.getInvestorAnswers(token, page, portion, _filter).catch(() =>
      dispatch({ type: "OFF_LOAD" })
    );

    if (answers)
      dispatch({
        type: "SET_LIST",
        list: answers.content,
        totalPages: answers.totalPages,
        page,
      });
  };

  if (loading) {
    return <CircularProgress style={{ margin: "auto" }} />;
  } else {
    return (
      <Wrapper alignItems="center" minHeight="calc(100vh - 130px)" bg="transparent">
        {loadFilter && (
          <WaitingWrapper>
            <CircularProgress />
          </WaitingWrapper>
        )}
        <Tabs type={type} history={history} />

        <Switch>
          <Route
            path="/admin/kyc/list"
            component={() => (
              <Fragment key={`kyc-filter-${type}`}>
                <Filter types={statuses} getFiltered={onChangeFilter} searchUsers={console.log} type={filter} />
                <Separator />
                {list.length ? (
                  <Fragment>
                    <Table list={list} history={history} token={token} onUpdate={getInfo} />
                    {totalPages > 1 ? (
                      <Paginator
                        totalPage={totalPages * portion}
                        array={list}
                        onChangePage={onChangePage}
                        itemsPerPage={portion}
                        startPage={page}
                        disabledInit
                      />
                    ) : null}
                  </Fragment>
                ) : (
                  <EmptyInfo />
                )}
              </Fragment>
            )}
          />
          <Route
            path="/admin/kyc/form-settings/forms"
            component={() => <FormsList token={token} history={history} />}
          />
        </Switch>
      </Wrapper>
    );
  }
};

export default connect(({ userReducer: { token } }) => ({ token }), null)(KYC);
