import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { CircularProgress } from "@material-ui/core";

import { Wrapper } from "../../main";
import { Header, TextH3 } from "../DealForm/Step/styled";
import { ReactComponent as ArrowLeft } from "../../../../assets/arrowleft.svg";
import { Btn } from "../../buttons";
import {
  Row,
  Main,
  TextSub,
  InputRow,
  Col,
  SubmitBlock,
  UploadersCol,
  EmptyBlock,
} from "./styled";
import {
  TextFieldComponent,
  TextEditorComponent,
  FooterComponent,
} from "../../forms";
import { SendEmailMessage } from "../../actionMessage";
import { FileUploader } from "./fileUploader";
import Api from "../../../../helpers/api";
import { SuccessMessage } from "../../errorMessage";

const OneGroupEmail = ({
  token,
  history,
  match: {
    params: { id, fromGroup },
  },
}) => {
  const [templateData, setTemplateData] = useState(null);
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [uploadersCount, setUploadersCount] = useState(1);
  const [files, setFiles] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [error, setError] = useState(false);
  const [wait, setWait] = useState(false);
  const [group, setGroup] = useState([]);

  const renderUploaders = () => {
    let uploaders = [];
    for (let i = 0; i < uploadersCount; i++) {
      const random = Math.round(Math.random() * 1000);
      uploaders.push(
        <FileUploader
          key={`uploader-${random}`}
          order={i + 1}
          onAddFile={(f) => onAddFile(f)}
          onDelete={(f) => onRemoveFile(f)}
          _file={files[i] ? files[i] : null}
        />
      );
    }
    return uploaders;
  };

  const onAddFile = (file) => {
    const _files = [...files];
    _files.push(file);
    setFiles(_files);
  };

  const onRemoveFile = (name) => {
    const _files = [...files];
    const index = _files.findIndex((e) => e.name === name);
    _files.splice(index, 1);
    setFiles(_files);
  };

  useEffect(() => {
    setUploadersCount(files.length + 1);
  }, [files]);

  const getInitialData = async () => {
    const group = await Api.getGroupById(token, id);
    if (group) setGroup(group);

    const template = await Api.getTemplateSettings(token, "SEND_EMAIL_TO_USER");
    if (template) setTemplateData(template);
  };

  useEffect(() => {
    getInitialData();
  }, []);

  const onBackToDeal = () => {
    fromGroup
      ? history.push(`/admin/users/group/${id}`)
      : history.push("/admin/users/groups");
  };

  const onSubmit = async () => {
    setWait(true);
    const formData = new FormData();
    formData.append("linearId", "");
    formData.append("message", message);
    formData.append("subject", subject);

    if (files.length) {
      for (let i = 0; i < files.length; i++) {
        formData.append(`attachments`, files[i], files[i].name);
      }
    }

    const res = await Api.sendMailsToGroup(token, formData, id).catch(() => {
      setError(true);
      setModalOpen(true);
      setWait(false);
    });

    if (res) {
      setModalOpen(true);
      setWait(false);
    }
  };

  const onCloseModal = () => {
    setModalOpen(false);
    error && setError(false);
  };

  const onChangeCustomFooter = async () => {
    setTemplateData({
      ...templateData,
      customFooter: !templateData.customFooter,
    });
    await Api.updateTemplateSettings(token, templateData);
  };

  if (templateData) {
    const {
      variables: { variables },
    } = templateData;
    return (
      <Wrapper bg="transparent">
        <Header>
          <Btn transparent onClick={onBackToDeal} width={150}>
            <ArrowLeft /> {fromGroup ? "back to group" : "back to groups"}
          </Btn>
          <TextH3 marginSize="0 0 0 25px">
            Send e-mail to all users in {group.name}
          </TextH3>
        </Header>

        <Row>
          <Col width="100%">
            <Main width="100%">
              <TextSub
                textAlign="left"
                fontWeight="bold"
                color="#181818"
                marginSize="0 0 25px 0"
              >
                Message to Users
              </TextSub>
              <InputRow>
                <TextFieldComponent
                  label="Subject"
                  placeholder="E-mail subject"
                  defaultValue={subject}
                  onChange={(event) => setSubject(event.target.value)}
                />
              </InputRow>
              <InputRow>
                <TextEditorComponent
                  label="Template content"
                  defaultValue={message}
                  onChange={(event) => setMessage(event)}
                />
              </InputRow>
              <InputRow last>
                <FooterComponent
                  label="e-mail footer"
                  name="Global E-mail footer"
                  defaultValue={true}
                  onChange={onChangeCustomFooter}
                />
              </InputRow>
              <UploadersCol>
                {renderUploaders()}
                <EmptyBlock />
                <EmptyBlock />
              </UploadersCol>

              <SubmitBlock>
                <Btn width={200} height={42} onClick={onSubmit} disabled={wait}>
                  SEND e-mails ({group.users.length})
                </Btn>
              </SubmitBlock>
            </Main>
          </Col>
        </Row>
        {modalOpen ? (
          <SuccessMessage
            onClose={onCloseModal}
            message="Your email has been sent successfully to all the users"
          />
        ) : null}
        <SendEmailMessage
          isError={error}
          onBackDpopClick={onCloseModal}
          isOpen={modalOpen && error}
        />
      </Wrapper>
    );
  } else {
    return (
      <Wrapper
        width="100%"
        justifyContent="center"
        alignItems="center"
        bg="transparent"
        height="calc(100vh - 130px)"
      >
        <CircularProgress />
      </Wrapper>
    );
  }
};

export default connect(
  (
    { userReducer: { token } },
    {
      match: {
        params: { id },
      },
    }
  ) => ({
    token,
    linearId: id,
  }),
  null
)(OneGroupEmail);
