import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import { connect } from "react-redux";
import { DealBody, DealTD, ButtonsContainer, DealStatus } from "../../dealTypeCard";
import { AssetLogo } from "../../avatar";
import { Btn } from "../../buttons";
import { getAssetInfo } from "../../../../modules/dataRedux/dataAction";
import { formatNumbers, numberWithCommas } from "../../../../helpers/";
import { ManagerInfo } from "../AssetDetails/common/managerAvatar";

const DealsBody = ({ rows, editStatus, token, onChangeStatus, getAssetInfo }) => {
  const makeAssetInfo = (id) => () => getAssetInfo(id);

  return (
    <Fragment>
      {rows.map(
        ({
          linearId,
          assetProperties: { assetName, images },
          raisedVsToBeRaised: { raised, toBeRaised },
          visibilityProperty,
          subscribers,
          assetFinancialProperty: { baseCurrency },
          type,
          roundsInfo,
          sponsors,
        }) => (
          <DealBody publish={visibilityProperty.active} key={`${linearId}-${type}`}>
            <DealTD center>
              <AssetLogo images={images} token={token} />
            </DealTD>
            <DealTD block width="20%" bold lower>
              {assetName}
              <DealStatus draft={!visibilityProperty.active}>
                {visibilityProperty.active ? "published" : "draft"}
              </DealStatus>
            </DealTD>
            <DealTD medium>
              {type === "NEW_DEAL" ? (
                `${numberWithCommas(raised.toFixed())} ${baseCurrency}`
              ) : sponsors[0] ? (
                <ManagerInfo small token={token} avatar={sponsors[0].userLogo} managerName={sponsors[0].fullName} />
              ) : (
                "-"
              )}
            </DealTD>
            <DealTD>
              {type === "NEW_DEAL"
                ? `${numberWithCommas((raised + toBeRaised).toFixed())} ${baseCurrency}`
                : roundsInfo[0]
                ? `${numberWithCommas(roundsInfo[0].companyValuation)} ${baseCurrency}`
                : "-"}
            </DealTD>
            <DealTD>
              {type === "NEW_DEAL"
                ? `${toBeRaised ? ((raised / (raised + toBeRaised)) * 100).toFixed(2) : "0.00"} %`
                : "-"}
            </DealTD>
            <DealTD width="7%">{subscribers ? formatNumbers(subscribers) : "-"}</DealTD>
            <ButtonsContainer>
              {linearId === editStatus ? (
                <CircularProgress style={{ marginRight: 32 }} size={29} />
              ) : (
                <Btn
                  hide
                  black={visibilityProperty.active}
                  onClick={onChangeStatus(linearId, visibilityProperty ? visibilityProperty.active : false)}
                  disabled={!!editStatus}
                >
                  {visibilityProperty && visibilityProperty.active ? "Publish" : "Hide"}
                </Btn>
              )}
              <Link to={`/admin/deals/edit/${linearId}`}>
                <Btn white>EDIT</Btn>
              </Link>
              <Link to={`/admin/dashboard/${linearId}`} onClick={makeAssetInfo(linearId)}>
                <Btn>DETAILS</Btn>
              </Link>
            </ButtonsContainer>
          </DealBody>
        )
      )}
    </Fragment>
  );
};

export default connect(
  ({ userReducer: { token }, dataReducer: { assets = [] } }) => ({
    token,
    assets,
  }),
  { getAssetInfo }
)(DealsBody);
