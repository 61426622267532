import styled from "styled-components";
import { Link } from "react-router-dom";
import React, { useState, useEffect, Fragment } from "react";

import Api from "../../../../helpers/api";
import { ReactComponent as Check } from "../../../../assets/check.svg";
import { ReactComponent as Lock } from "../../../../assets/lock-grey.svg";
import { ReactComponent as Eye } from "../../../../assets/eye.svg";
import { ReactComponent as NoAvatar } from "../../../../assets/no-avatar.svg";
import { ReactComponent as Institution } from "../../../../assets/institution.svg";
import { ReactComponent as NoAvatarFemale } from "../../../../assets/no-avatar-female.svg";

export const Header = styled.div`
  height: 60px;
  background: #ffffff;
  border-radius: 4px;
  margin-bottom: ${({ isFooter = false }) => (isFooter ? 0 : "25px")};
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 15px 20px;
  width: 100%;
  button {
    margin-left: auto;
  }
`;

export const BackLink = styled(Link)`
  margin-left: ${({ mLeft }) => (mLeft ? "auto" : "")};
  width: ${({ width = "150px" }) => width};
  height: 32px;
  border: 1px solid ${({ theme: { primary } }) => primary};
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 135%;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  text-decoration: none;
  color: ${({ transparent, theme: { primary } }) => (transparent ? "#fff" : primary)};
  background: ${({ transparent, theme: { primary } }) => (transparent ? primary : "#fff")};
  margin-right: 25px;
  position: relative;
  :last-child {
    margin-right: 0px;
  }
  > svg {
    margin-right: 7px;
  }
  &:hover {
    &::before {
      display: block;
    }
  }
  &::before {
    transition: all 0.3s linear;
    content: "";
    position: absolute;
    display: none;
    bottom: -1px;
    left: -1px;
    height: 3px;
    background: ${({ theme: { primary } }) => primary};
    width: calc(100% + 2px);
    filter: brightness(0.85);
    border-radius: 0 0 4px 4px;
  }
`;

export const Heading = styled.h6`
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 135%;
  color: #181818;
  margin: 0;
`;

export const ColWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ left }) => (left ? "323px" : "calc(100% - 353px)")};
`;

export const TypeFilter = styled.div`
  width: 100%;
  display: flex;
  background: #ffffff;
  border-radius: 4px;
  flex-direction: column;
  padding: 20px 25px 25px;
  box-sizing: border-box;
  margin-bottom: 10px;
`;

export const CatFilter = styled.div`
  width: 100%;
  display: flex;
  background: #ffffff;
  border-radius: 4px;
  flex-direction: column;
  padding: 20px 25px 30px 25px;
  box-sizing: border-box;
`;

export const FilterHeading = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 135%;
  color: #181818;
  margin-bottom: 20px;
`;

const Type = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 32px;
  background: ${({ transparent, theme: { primary } }) => (transparent ? "#fff" : primary)};
  color: ${({ transparent }) => (transparent ? "#8A8A8A" : "#fff")};
  border: ${({ transparent, theme: { primary } }) => (transparent ? "1px solid #EFEFEF" : `1px solid ${primary}`)};
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 135%;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  padding: 8px 12px;
  margin-bottom: 10px;
  :last-child {
    margin-bottom: 0px;
  }
`;

const CircleBorder = styled.div`
  width: 16px;
  height: 16px;
  border: 1px solid ${({ active }) => (active ? "#fff" : "#EFEFEF")};
  display: flex;
  border-radius: 50%;
  box-sizing: border-box;
  margin-right: 10px;
`;

const Circle = styled.div`
  width: 8px;
  height: 8px;
  background: ${({ active }) => (active ? "#fff" : "transparent")};
  margin: auto;
  border-radius: 50%;
  box-sizing: border-box;
`;

const Count = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ active }) => (active ? "#FF4343" : "#F3F3F3")};
  margin-left: auto;
  height: 16px;
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 9px;
  line-height: 135%;
  letter-spacing: 0.06em;
  color: ${({ active }) => (active ? "#FFFFFF" : "#8A8A8A")};
  min-width: 16px;
  padding: 2px;
  box-sizing: border-box;
`;

export const TypeBtn = ({ name, onClick, count, active }) => {
  const onChange = () => {
    onClick(name);
  };
  return (
    <Type onClick={onChange} transparent={!active}>
      <CircleBorder active={active}>
        <Circle active={active} />
      </CircleBorder>
      {name}
      <Count active={active}>{count}</Count>
    </Type>
  );
};

const Category = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  letter-spacing: -0.02em;
  color: ${({ active }) => (active ? "#181818" : "#8A8A8A")};
  cursor: pointer;
  margin-bottom: 16px;
  :last-child {
    margin-bottom: 0;
  }
`;

const CatCount = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  letter-spacing: -0.02em;
  color: ${({ active }) => (active ? "#181818" : "rgba(24, 24, 24, 0.4)")};
  margin-left: 8px;
`;

const CheckBox = styled.div`
  width: 22px;
  height: 22px;
  border: 1px solid ${({ active, theme: { primary } }) => (active ? primary : "#EFEFEF")};
  box-sizing: border-box;
  border-radius: 1px;
  margin-right: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  > svg {
    width: 14px;
    height: 14px;
    transform: ${({ active }) => (active ? "rotateY(0)" : "rotateY(90deg)")};
    path {
      stroke: ${({ theme: { primary } }) => primary};
    }
    transition: all linear 0.1s;
  }
`;

export const CategoryBtn = ({ name, onClick, count, active }) => {
  const onChange = () => {
    onClick(name);
  };
  return (
    <Category onClick={onChange} active={active}>
      <CheckBox active={active}>
        <Check />
      </CheckBox>
      {name}
      <CatCount active={active}>{count}</CatCount>
    </Category>
  );
};

export const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-bottom: 25px;
  > svg {
    position: absolute;
    top: 23px;
    left: 24px;
    width: 16px;
    height: 16px;
  }
`;

export const SearchInput = styled.input`
  width: calc(100% - 352px);
  height: 60px;
  background: #ffffff;
  border-radius: 4px;
  outline: none;
  border: none;
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 135%;
  letter-spacing: -0.02em;
  color: #333333;
  padding: 20px 20px 20px 56px;
  box-sizing: border-box;
  &:placeholder {
    color: rgba(24, 24, 24, 0.4);
  }
`;

export const SortFilter = styled.div`
  width: 322px;
  background: #ffffff;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
  > div > div {
    margin-bottom: 0;
  }
`;

export const Qestions = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Question = styled.div`
  width: 100%;
  margin-bottom: 10px;
  background: #ffffff;
  border: 1px solid #f2f2f2;
  box-sizing: border-box;
  border-radius: 2px;
  background: #ffffff;
  padding: ${({ padding }) => (padding ? padding : "0")};
  > span {
    cursor: pointer;
    font-family: Muli;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 135%;
    letter-spacing: 0.06em;
    text-transform: uppercase;
    color: ${({ theme: { primary } }) => primary};
    margin-top: 10px;
    display: block;
  }
  :last-child {
    margin-bottom: 0px;
  }
`;

export const Qheader = styled.div`
  width: 100%;
  border-bottom: 1px solid #ececec;
  box-sizing: border-box;
  padding: 22px 20px 20px 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const ButtonsBlock = styled.div`
  width: 182px;
  display: flex;
  justify-content: space-between;
  svg {
    margin: 0;
  }
`;

export const Qbody = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: flex;
`;

export const BodyQA = styled.div`
  width: 50%;
  padding: 20px 20px 22px 25px;
  background: ${({ isAnswer, theme: { adminTableRowGrey } }) => (isAnswer ? adminTableRowGrey : "transparent")};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`;

export const BodyHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${({ marginBottom = "10px" }) => marginBottom};
  > span {
    font-family: Muli;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    color: #181818;
  }
`;

export const TextMessage = styled.div`
  overflow: hidden;
  height: ${({ height }) => height};
  transition: height 0.3s linear;
  font-family: Muli;
  font-style: ${({ isAnswer }) => (isAnswer ? "italic" : "normal")};
  font-weight: normal;
  font-size: 12px;
  color: #333333;
  margin-bottom: ${({ marginBottom = "0px" }) => marginBottom};
`;

export const SeparateLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: #ececec;
  margin-bottom: 19px;
  margin-top: 20px;
`;

const PrivacyBlock = styled.div`
  display: flex;
  align-items: center;
  color: ${({ privat, theme: { primary } }) => (privat ? "#8A8A8A" : primary)};
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  > svg {
    margin-right: 7px;
    path {
      stroke: ${({ theme: { primary } }) => primary};
    }
  }
`;

export const Privacy = ({ isPrivate }) => {
  return (
    <PrivacyBlock privat={isPrivate}>
      {isPrivate ? (
        <Fragment>
          <Lock />
          PRIVATE
        </Fragment>
      ) : (
        <Fragment>
          <Eye />
          ALL USERS
        </Fragment>
      )}
    </PrivacyBlock>
  );
};

export const RowQuestion = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const UserInfo = styled.div`
  display: flex;
  align-items: center;
`;

export const InfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  > h3 {
    font-family: Muli;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    color: #333333;
    margin-top: 0px;
    margin-bottom: 5px;
  }
  > span {
    font-family: Muli;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    color: rgba(24, 24, 24, 0.5);
  }
`;

export const UserAvatar = ({ fileName, hash, token, small = false, investorType, gender }) => {
  const [avatar, setAvatar] = useState(null);

  useEffect(() => {
    if (!avatar && fileName && hash) {
      Api.getAttachment(token, hash, fileName).then((blob) => setAvatar(blob || Api.defaultAvatar));
    }
  });
  return (
    <AvatarContainer small={small}>
      {avatar ? (
        <Avatar small={small} src={avatar} alt="avatar" />
      ) : !fileName && !hash ? (
        investorType && investorType === "INSTITUTION" ? (
          <Institution
            style={
              small
                ? {
                    width: 32,
                    height: 32,
                  }
                : {}
            }
          />
        ) : gender && gender === "FEMALE" ? (
          <NoAvatarFemale
            style={
              small
                ? {
                    width: 32,
                    height: 32,
                  }
                : {}
            }
          />
        ) : (
          <NoAvatar
            style={
              small
                ? {
                    width: 32,
                    height: 32,
                  }
                : {}
            }
          />
        )
      ) : null}
    </AvatarContainer>
  );
};

const Avatar = styled.img`
  ${({ small = false }) => {
    if (small) {
      return `
        width: 16px;
        height: 16px;
      `;
    }

    return `
      width: 32px;
      height: 32px;
    `;
  }}

  border-radius: 50%;
  object-fit: cover;
`;

const AvatarContainer = styled.div`
  ${({ small = false }) => {
    if (small) {
      return `
        width: 16px;
        height: 16px;
        margin-right:0;
      `;
    }

    return `
      width: 42px;
      height: 42px;
      margin-right:14px;
    `;
  }}
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  > svg {
    filter: grayscale(${({ theme: { greyFilter } }) => greyFilter});
  }
`;

export const AnswerInfo = styled.div`
  display: flex;
  align-items: center;
  > span {
    font-family: Muli;
    font-style: normal;
    font-weight: normal;
    font-size: 10px;
    color: rgba(24, 24, 24, 0.5);
    margin-right: 5px;
  }
`;

export const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(5px);
  z-index: 998;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ConfBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(5px);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ConfHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  height: 52px;
  box-shadow: 0px 10px 40px rgba(89, 120, 150, 0.08);
  padding: 0 30px;
  > svg {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
`;

export const ConfFooter = styled.div`
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0 77px;
  margin-bottom: 31px;
`;

export const ModalHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: ${({ isFooter = false }) => (isFooter ? "auto" : "0")};
  margin-bottom: ${({ isFooter = false }) => (isFooter ? "24px" : "0")};
  box-shadow: ${({ isFooter = false }) => (isFooter ? "none" : "0px 10px 40px rgba(89, 120, 150, 0.08)")};
  padding: ${({ isFooter = false }) => (isFooter ? "0 30px" : "15px 30px")};
  box-sizing: border-box;
  > svg {
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
`;

export const HeadContainer = styled.div`
  width: 100%;
  position: relative;
  > svg {
    cursor: pointer;
    position: absolute;
    bottom: 12px;
    right: 21px;
    width: 16px;
    height: 16px;
    path {
      stroke: #8a8a8a;
    }
  }
`;

export const HeadingModal = styled.h3`
  text-transform: none;
  font-family: Muli;
  font-style: normal;
  font-weight: 600;
  font-size: ${({ small = false }) => (small ? "16px" : "18px")};
  line-height: 135%;
  margin: 0;
  color: #181818;
`;

export const ModalWrap = styled.div`
  position: relative;
  width: ${({ width }) => (width ? width : "565px")};
  background: #ffffff;
  border: 1px solid #f9f9f9;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`;

export const ModalBody = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 20px 30px 24px 30px;
  box-sizing: border-box;
  > span {
    display: block;
    font-family: Muli;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 135%;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    margin-top: 20px;
    margin-bottom: 13px;
    color: rgba(24, 24, 24, 0.4);
  }
`;

export const ConfBody = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  > h3 {
    margin: 0 20px;
    font-family: Muli;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 135%;
    text-align: center;
    letter-spacing: -0.02em;
    color: #192036;
  }
  > span {
    margin: 12px 60px 24px 60px;
    font-family: Muli;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 150%;
    text-align: center;
    color: #333333;
  }
`;

export const ModalCategory = styled.div`
  display: flex;
  align-items: center;
  width: 475px;
  background: ${({ theme: { quoteBgInvestor } }) => quoteBgInvestor};
  border-radius: 4px;
  margin-bottom: 6px;
  font-family: Muli;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 135%;
  letter-spacing: -0.02em;
  color: #181818;
  box-sizing: border-box;
  padding: 12px 24px 14px 20px;
  > svg {
    cursor: pointer;
    margin-left: auto;
    width: 16px;
    height: 16px;
    path {
      stroke: #ff4343;
    }
  }

  > span {
    font-family: Muli;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 135%;
    letter-spacing: -0.02em;
    color: rgba(24, 24, 24, 0.6);
    margin-left: 8px;
  }
  :last-child {
    margin-bottom: 0;
  }
`;

export const ScrollContainer = styled.div`
  min-height: 58px;
  max-height: 420px;
  padding-left: ${({ pLeft }) => (pLeft ? `${pLeft}px` : "0")};
  box-sizing: border-box;
  margin-bottom: 24px;
  overflow-y: scroll;
  &&::-webkit-scrollbar-track {
    background-color: ${({ theme: { primaryLight } }) => primaryLight};
    border-radius: 2px;
  }
  &&::-webkit-scrollbar {
    width: 6px;
    border-radius: 2px;
    background-color: ${({ theme: { primaryLight } }) => primaryLight};
  }
  &&::-webkit-scrollbar-thumb {
    background-color: ${({ theme: { primaryMiddle } }) => primaryMiddle};
    border-radius: 2px;
  }
`;

export const SeparModal = styled.div`
  width: 100%;
  height: 1px;
  background: #f6f7fc;
`;

export const NoData = styled.div`
  box-sizing: border-box;
  width: 100%;
  font-weight: 600;
  font-size: 16px;
  line-height: 160%;
  color: rgb(51, 51, 51);
  text-align: center;
  background: rgb(255, 255, 255);
  border-radius: 4px;
  padding: 25px;
  margin: 0px auto;
`;

export const FileBlock = styled.div`
  width: 100%;
  height: 40px;
  margin-top: ${({ isAnswered, edit }) => (isAnswered && !edit ? "10px" : "0")};
  margin-bottom: ${({ edit }) => (edit ? "23px" : "0")};
  background: ${({ grey, lightgrey, theme: { adminTableRowGrey } }) =>
    grey ? "rgba(98, 128, 218, 0.05)" : lightgrey ? adminTableRowGrey : "transparent"};
  border: 1px solid #efefef;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 13px 19px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  svg {
    path {
      stroke: ${({ theme: { primary } }) => primary};
    }
  }
`;

export const FileName = styled.div`
  display: flex;
  align-items: center;
  width: calc(100% - 50px);
  > h4 {
    width: calc(100% - 40px);
    margin: 0;
    font-family: Muli;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 135%;
    color: #181818;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    > span {
      font-family: Muli;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 135%;
      color: rgba(24, 24, 24, 0.6);
      margin-left: 6px;
    }
  }

  > svg {
    width: 16px;
    height: 16px;
    margin-right: 14px;
    path {
      &:first-child {
        stroke: transparent;
        fill: ${({ theme: { primary } }) => primary};
      }
      fill: transparent;
      stroke: ${({ theme: { primary } }) => primary};
    }
  }
`;

export const FileControls = styled.div`
  display: flex;
  align-items: center;
  svg {
    cursor: pointer;
    transform: ${({ open, rotate }) => (!rotate ? "0deg" : open ? "rotate(270deg)" : "rotate(90deg)")};
    width: 16px;
    height: 16px;
    margin-right: 17px;
    :last-child {
      margin-right: 0;
    }
  }
`;

export const SelectorBody = styled.div`
  box-sizing: border-box;
  width: 100%;
  padding: 30px;
  display: flex;
  flex-direction: column;
`;

export const Description = styled.div`
  width: 100%;
  height: 57px;
  background: #ffffff;
  border: 1px solid #efefef;
  box-sizing: border-box;
  border-radius: 2px 2px 2px 0px;
  padding-left: 25px;
  display: flex;
  align-items: center;
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 135%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: rgba(24, 24, 24, 0.4);
`;

export const TRow = styled.div`
  border: 1px solid #efefef;
  border-top: none;
  box-sizing: border-box;
  width: 100%;
  height: 57px;
  display: flex;
  justify-content: space-between;
  cursor: ${({ noHover = false }) => (noHover ? "auto" : "pointer")};
  transition: all 0.1s linear;
  background: ${({ active, theme: { quoteBgInvestor } }) => (active ? quoteBgInvestor : "#fff")};
  &:hover,
  &:active {
    background: ${({ noHover = false, theme: { quoteBgInvestor } }) => (noHover ? "#fff" : quoteBgInvestor)};
  }
  :last-child {
    border-radius: 0 0 2px 2px;
  }
`;

export const TCeil = styled.div`
  background: ${({ active, theme: { primaryLight } }) => (active ? primaryLight : "transparent")};
  position: ${({ pos = "static" }) => pos};
  height: 57px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  border-right: 1px solid #efefef;
  padding: 0 ${({ p = 20 }) => ` 20px 0  ${p}px`};
  width: ${({ big, middle, full, fixedWidth }) => {
    if (full) return "100%;";
    return big ? "calc(100% - 560px)" : middle ? "200px" : fixedWidth ? fixedWidth : "120px";
  }};
  font-family: Muli;
  font-style: normal;
  font-weight: ${({ lower = false }) => (lower ? "normal" : "bold")};
  font-size: ${({ lower = false }) => (lower ? "14px" : "10px")};
  line-height: 135%;
  letter-spacing: 0.05em;
  text-transform: ${({ lower = false }) => (lower ? "none" : "uppercase")};
  color: rgba(24, 24, 24, 0.4);
  &:last-child {
    border-right: none;
  }
`;

export const FolderName = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 135%;
  color: #181818;
  text-transform: none;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  > svg {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
  > svg:first-child {
    width: 12px;
    height: 12px;
    transform: ${({ open }) => (open ? "none" : "rotate(-90deg)")};
  }
  &:hover {
    > div {
      margin-left: 10px;
      display: flex;
    }
  }
  > span {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100% - 80px);
  }
`;

export const DocName = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 135%;
  color: #181818;
  text-transform: none;
  display: flex;
  align-items: center;
  width: calc(100% - 15px);
  height: 100%;
  > svg {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
  &:hover {
    > div {
      margin-left: 10px;
      display: flex;
    }
  }
  > span {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100% - 60px);
  }
`;
