import styled from "styled-components";

import { Column, Flex } from "components/common/containers";
import { border4, flexCenter, muliFont, scroll, borderBox } from "components/common/mixins";

export const CalendarContainer = styled(Column)`
  min-height: 415px;
  min-width: calc(28% - 15px);

  max-height: 415px;
  /* max-width: 315px; */
  padding: 20px;
  background: white;
  ${border4};
  ${borderBox};

  user-select: none;
  & * {
    user-select: none;
  }
`;

export const Row = styled(Flex)`
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${({ mBottom }) => (mBottom ? `${mBottom}px` : "0")};
  height: ${({ height = false }) => (height ? height : "auto")};
`;

export const Title = styled.h4`
  ${muliFont};
  font-weight: 700;
  margin: 0;
`;

export const TitlePopup = styled(Title)`
  margin: 0;
  font-weight: 600;
  font-size: 12px;
  text-align: left;
  margin-bottom: 4px;
`;

export const TextPopup = styled(TitlePopup)`
  font-size: 10px;
  font-weight: 400;
  color: rgba(24, 24, 24, 0.7);
`;

export const EventDate = styled(Title)`
  margin: 0;
  font-size: ${({ fontSize = 10 }) => fontSize}px;
  flex: 1;
`;

export const EventText = styled(EventDate)`
  font-weight: 400;
  flex: 4;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-right: 10px;
  white-space: nowrap;
`;

export const EventRow = styled(Flex)`
  align-items: center;
  margin-bottom: 15px;
  :last-child {
    margin-bottom: 0;
  }
`;

export const WeekDay = styled(Title)`
  cursor: pointer;
  color: rgba(24, 24, 24, 0.6);
  font-size: 10px;
  width: 14.2%;
  text-align: center;
`;

export const MonthDay = styled(WeekDay)`
  font-weight: 600;
  /* color: #181818; */
  color: ${({ isOffset = false }) => (isOffset ? "#18181894" : "#181818")};
  padding-top: 10%;
  margin: 0;
  position: relative;
  /* opacity: ${({ isOffset = false }) => (isOffset ? 0.6 : 1)}; */
  &::before {
    content: "${({ content = "1" }) => content}";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 20px;
    height: 20px;
    display: flex;

    ${({ isEvent }) => {
      if (isEvent) {
        return `
          background: ${({ theme: { adminTableRowGrey } }) => adminTableRowGrey};
  > svg {
    path {
      stroke: ${({ theme: { primary } }) => primary};
    }
  }
        `;
      }
      return "";
    }}

    ${flexCenter};
  }
  &.active,
  &:hover {
    ::before {
      border-radius: 50%;
      background: ${({ isOffset = false, theme: { primary } }) => (isOffset ? `${primary + 94}` : primary)};
      color: white;
    }
  }

  &.active:hover {
    [data-popup] {
      display: block;
      color: #181818;
    }
  }
`;

export const EventPoint = styled.div`
  display: block;
  ${({ isSmall = false }) => {
    if (isSmall) {
      return `
        position: absolute;
        width: 6px;
        height: 6px;
      `;
    }
    return `
      width: 12px;
      height: 12px;
      margin-right: 10px;
    `;
  }}
  border-radius: 50%;
  top: calc(50% + 4px);
  left: calc(50% + 5px);

  background: ${({ isOffset = false, eventColor = "transparent" }) => {
    if (isOffset) {
      return `${eventColor + 94}` || "transparent";
    } else return eventColor || "transparent";
  }};
`;

export const DateArea = styled(Flex)`
  flex-wrap: wrap;
`;

export const ArrowWrapper = styled(Flex)`
  cursor: pointer;
  width: 24px;
  height: 24px;
  background: ${({ theme: { adminTableRowGrey } }) => adminTableRowGrey};
  > svg {
    path {
      stroke: ${({ theme: { primary } }) => primary};
    }
  }
  border-radius: 50%;
  ${flexCenter};

  &:first-child {
    margin-right: 10px;
  }
`;

export const SettingsWrapper = styled(ArrowWrapper)`
  background: ${({ theme: { adminTableRowGrey } }) => adminTableRowGrey};
`;

export const Separator = styled.div`
  width: 100%;
  height: 1px;
  background: #efefef;
  margin: 14px 0;
`;

export const Events = styled.div`
  flex: 1;
  ${({ isScroll = false }) => (isScroll ? scroll : "")};
  padding: 0;
  max-height: 75px;
`;

export const Popup = styled.div.attrs(() => ({
  "data-popup": true,
}))`
  display: none;
  padding: 10px;
  background: white;
  ${border4};
  ${borderBox};

  border: 1px solid #efefef;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.05);

  z-index: 1;

  width: 200px;
  min-height: 25px;
  position: absolute;
  right: 0;
  bottom: 0;

  transform: translate(25px, -35px);

  &::before {
    content: "";
    width: 0;
    height: 0;
    display: block;
    position: absolute;
    background: white;
    border: 10px solid white;
    right: 35px;
    bottom: -10px;
    transform: rotate(45deg);
  }
`;

export const Close = styled(Flex)`
  cursor: pointer;
  height: 32px;
  width: 32px;
  ${flexCenter};
`;
