import React, { useState, Fragment, useRef } from "react";
import moment from "moment";
import CircularProgress from "@material-ui/core/CircularProgress";

import { Header, TextH3 } from "../../DealForm/Step/styled";
import { ReactComponent as MailIcon } from "../../../../../assets/mail.svg";
import { ReactComponent as UploadIcon } from "../../../../../assets/upload.svg";
import { Btn } from "../../../buttons";
import { BorderBlock, FullBg } from "../styled";
import { icons } from "../../../../../models/icons";
import { getTrackerHTML } from "../../../../../data-room-panel/helpers/fileViewer";
import Api from "../../../../../helpers/api";

//modals
import { ErrorMessage, SuccessMessage } from "../../../errorMessage";

export const ReportsActions = ({
  token,
  history,
  id,
  onUpdateReports,
  lastReport,
}) => {
  const [wait, setWait] = useState(false);
  const [errorUpload, setErrorUpload] = useState(null);
  const [success, setSuccess] = useState("");
  const inputRef = useRef(null);

  const onOpenFileDialog = () => {
    if (inputRef && inputRef.current) {
      inputRef.current.click();
    }
  };

  const onUploadReport = async (file) => {
    setWait(true);

    const res = await Api.uploadReport(token, file, id).catch((e) => {
      setErrorUpload(e);
      setWait(false);
    });

    if (res) {
      setWait(false);
      setSuccess(`File has been successfully uploaded.`);
      onUpdateReports();
    }
  };

  const onOpenReport = (report) => () => {
    getTrackerHTML(
      token,
      report.name,
      `Reports/${report.name}?assetId=${id}`,
      false,
      true,
      false,
      false
    );
  };

  const reportExist = lastReport && Object.keys(lastReport).length;

  return (
    <Fragment>
      <Header>
        {lastReport ? (
          <Fragment>
            <TextH3 marginSize="0 18px 0 0">Asset reports</TextH3>
            {reportExist ? (
              <Fragment>
                <span>
                  Last Update:{" "}
                  {moment(lastReport.dateAdded).format("DD/MM/YYYY hh:mm A")}
                </span>
                <Btn
                  width={100}
                  margin="0 15px 0 auto"
                  onClick={onOpenReport(lastReport)}
                >
                  {icons.eye} open
                </Btn>
              </Fragment>
            ) : null}
            <Btn
              transparent
              width={125}
              onClick={onOpenFileDialog}
              margin={`0 25px 0 ${reportExist ? 0 : "auto"}`}
            >
              <UploadIcon style={{ width: 9, height: 9 }} />
              Upload Report
            </Btn>
            <BorderBlock>
              <Btn
                disabled={!reportExist || (lastReport && lastReport.sent)}
                width={262}
                onClick={() => history.push(`/admin/send-report/${id}`)}
              >
                <MailIcon style={{ width: 9, height: 9 }} />
                SEND REPORT TO USERS
              </Btn>
            </BorderBlock>
          </Fragment>
        ) : (
          <CircularProgress size={24} style={{ margin: "auto" }} />
        )}
      </Header>

      {/* input */}
      <input
        ref={inputRef}
        style={{ display: "none" }}
        onChange={(e) => onUploadReport(e.target.files[0])}
        type="file"
      />

      {errorUpload ? (
        <ErrorMessage er={errorUpload} onClose={() => setErrorUpload(null)} />
      ) : null}

      {success ? (
        <SuccessMessage message={success} onClose={() => setSuccess("")} />
      ) : null}

      {wait ? (
        <FullBg>
          <CircularProgress />
        </FullBg>
      ) : null}
    </Fragment>
  );
};
