import React, { useState, useEffect, Fragment } from "react";

import {
  Asset,
  ImgCont,
  Labels,
  LinkBlock,
  SponsorBlock,
  AssetName,
  Row,
  InfoRow,
  Button,
  Sponsor,
  __ChartBg,
  TooltipCont,
  RaisedCardBlock,
  InfoCardBlock,
  DataCol,
  ChartTxt,
  Val,
  CircleData,
  WrapperTips,
  TipsText,
  Triang,
} from "./styled";
import { ProgressBar } from "../Investment/styled";
import { ReactComponent as InfoIcon } from "../../../../assets/info.svg";
import Api from "../../../../helpers/api";
import { numberWithCommas } from "../../../../helpers/index";

import theme from "models/themes";

export const TradeCard = ({
  history,
  item: {
    assetId,
    assetProperties: { images, category, assetLocation, assetName, smallDescription, dynamicFinancialJson },
    assetManagerDetails,
    total,
  },
  totalShares,
  subscribers,
  token,
}) => {
  const [image, setImage] = useState(null);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const data = JSON.parse(dynamicFinancialJson || "[]");
  data.length = 3;

  useEffect(() => {
    if (!image && images && images[0]) {
      const { secureHash, fileNameWithExtension } = images[0];
      Api.getAttachment(token, secureHash, fileNameWithExtension).then((blob) => setImage(blob));
    }
  });
  return (
    <Asset>
      <ImgCont fullW height={230}>
        {image && <img src={image} alt="asset" />}
        <Labels category={category} location={assetLocation} violet />
      </ImgCont>
      <InfoCardBlock>
        <Row noHidden>
          <AssetName>{assetName}</AssetName>
          <TooltipCont>
            <InfoIcon />
            <WrapperTips>
              <h1>{assetName}</h1>
              <TipsText>{smallDescription}</TipsText>
            </WrapperTips>
            <Triang />
          </TooltipCont>
        </Row>
        {data.map(({ name, value }, i) => (
          <InfoRow key={name} margin={i !== 2 ? "0 0 8px 0" : 0} label={name} val={value} />
        ))}
      </InfoCardBlock>
      <RaisedCardBlock>
        <Row margin="0 0 8px 0">
          <DataCol align="flex-start" width={34}>
            <ChartTxt tAlign="left" size={10}>
              Available shares
            </ChartTxt>
            <Val fSize={14} fWeight="bold">
              {numberWithCommas(totalShares.toFixed())} <CircleData color={theme.secondary} />
            </Val>
          </DataCol>
          <DataCol align="flex-start" width={28}>
            <ChartTxt tAlign="left" size={10}>
              Total Shares
            </ChartTxt>
            <Val fSize={14} fWeight="bold">
              {numberWithCommas(total.toFixed())} <CircleData color={theme.secondaryLight} />
            </Val>
          </DataCol>
          <DataCol align="flex-start" width={31}>
            <ChartTxt tAlign="left" size={10}>
              subscribers
            </ChartTxt>
            <Val fSize={14} fWeight="bold">
              {subscribers || 0}
            </Val>
          </DataCol>
        </Row>
        <ProgressBar isTrade sold={total} unsold={totalShares - total} />
      </RaisedCardBlock>
      <div style={{ display: "flex", height: 65, marginTop: "auto" }}>
        <SponsorBlock width="calc(100% - 173px)">
          {assetManagerDetails ? <Sponsor token={token} sponsor={assetManagerDetails} /> : null}
        </SponsorBlock>
        <LinkBlock>
          <Button
            violet
            width={123}
            height={32}
            size={12}
            tTransform="uppercase"
            fWeight="bold"
            onClick={() => history.push(`/investor/offerings/users/${assetId}/buy-shares`)}
          >
            More
          </Button>
        </LinkBlock>
      </div>
    </Asset>
  );
};
