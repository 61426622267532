import React, { useState } from "react";

import {
  SendEmailWrapper,
  ModalBackground,
  ModalHeader,
  InputsWrapper,
  Heading,
  Separator,
  WaitingWrapper,
} from "../UserList/styled";
import { Btn } from "../../buttons";
import { ReactComponent as CloseIcon } from "../../../../assets/x-circle.svg";
import { TextFieldComponent, TextAreaComponent } from "../../forms";
import Api from "../../../../helpers/api";
import { CircularProgress } from "@material-ui/core";
import { ErrorMessagePortal, SuccessMessage } from "components/admin-layout/errorMessage";

export const SendEmail = ({ token, isOpen, onBackdropClick, mailData: { userName, userEmail } }) => {
  const [waitingResponse, setWaitingResponse] = useState(false);

  const [message, setMessage] = useState("");
  const [subject, setSubject] = useState("");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState("");

  const sendEmailToUser = () => {
    setWaitingResponse(true);
    Api.sendEmail(token, userEmail, subject, message)
      .then((_) => {
        error && setError(false);
        setWaitingResponse(false);
        setSuccess("Your message has been successfully sent.");
      })
      .catch((e) => {
        console.log(e);
        setWaitingResponse(false);
        setError(e);
      });
  };

  const onMessageInput = (e) => setMessage(e.target.value);
  const onSubjectInput = (e) => setSubject(e.target.value);

  const clearDataOnClose = () => {
    setSuccess("");
    setMessage("");
    setSubject("");
    onBackdropClick();
  };

  return isOpen ? (
    <ModalBackground onClick={onBackdropClick}>
      <SendEmailWrapper onClick={(e) => e.stopPropagation()}>
        {waitingResponse && (
          <WaitingWrapper>
            <CircularProgress />
          </WaitingWrapper>
        )}
        <ModalHeader>
          <Heading>Send E-mail to {userName}</Heading>
          <CloseIcon onClick={onBackdropClick} />
        </ModalHeader>
        <InputsWrapper padding="20px 25px">
          <TextFieldComponent label="User" placeholder="Name" defaultValue={userName} disabled />
          <Separator height={25} />
          <TextFieldComponent
            label="Subject"
            placeholder="Subject of e-mail here"
            defaultValue={subject}
            onChange={onSubjectInput}
          />
          <Separator height={20} />
          <TextAreaComponent
            fSize={12}
            label="Message"
            placeholder="Write your message to user here"
            onChange={onMessageInput}
            defaultValue={message}
          />
        </InputsWrapper>
        <ModalHeader isFooter>
          <Btn onClick={onBackdropClick} hide width={94}>
            Cancel
          </Btn>
          <Btn width={180} onClick={sendEmailToUser} disabled={!message}>
            Send e-mail
          </Btn>
        </ModalHeader>
      </SendEmailWrapper>
      {error && <ErrorMessagePortal er={error} onClose={() => setError(null)} />}
      {success ? <SuccessMessage message={success} onClose={clearDataOnClose} /> : null}
    </ModalBackground>
  ) : null;
};
