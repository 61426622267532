import React from "react";

import { CardWrapper, TextH3, TextSub, Dot, IconBg } from "./styled";
import { ReactComponent as ExistIcon } from "../../../../assets/exist-asset.svg";
import { ReactComponent as NewIcon } from "../../../../assets/new-asset.svg";

export const Card = ({ children, pick, onClick }) => {
  return (
    <CardWrapper pick={pick} onClick={onClick}>
      {children}
    </CardWrapper>
  );
};

export const CardNew = ({ type, onChangeType }) => {
  const _type = "NEW_DEAL";

  return (
    <Card pick={type === _type} onClick={() => onChangeType(_type)}>
      <IconBg>
        <NewIcon />
      </IconBg>
      <TextH3 marginSize="25px 0 15px 0">New Asset</TextH3>
      <TextSub marginSize="0">
        Create a New Asset you would
        <br />
        like to fundraise for
      </TextSub>
      <Dot pick={type === _type} />
    </Card>
  );
};

export const CardExisted = ({ type, onChangeType }) => {
  const _type = "EXISTED_DEAL";

  return (
    <Card pick={type === _type} onClick={() => onChangeType(_type)}>
      <IconBg>
        <ExistIcon />
      </IconBg>
      <TextH3 marginSize="25px 0 15px 0">Existing Asset</TextH3>
      <TextSub marginSize="0">
        Add an Existing Asset
        <br />
        with past transactions and rounds
      </TextSub>
      <Dot pick={type === _type} />
    </Card>
  );
};
