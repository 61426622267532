import styled from "styled-components";
import React, { useState, useEffect, Fragment, useRef } from "react";
import { CircularProgress } from "@material-ui/core";
import { Link } from "react-router-dom";

import { ReactComponent as UploadIcon } from "../../../../assets/upload.svg";
import { ReactComponent as RefreshIcon } from "../../../../assets/refresh-cw.svg";
import { ReactComponent as CheckIcon } from "../../../../assets/check.svg";
import { ReactComponent as Arrow } from "../../../../assets/chevron-down.svg";
import { ReactComponent as NoAvatar } from "../../../../assets/no-avatar.svg";
import { ReactComponent as Institution } from "../../../../assets/institution.svg";
import { ReactComponent as NoAvatarFemale } from "../../../../assets/no-avatar-female.svg";
import { ReactComponent as SuspendIcon } from "../../../../assets/slash.svg";
import { ReactComponent as MoreIcon } from "../../../../assets/more-vertical.svg";
import { ReactComponent as Data } from "../../../../assets/database.svg";
import { ReactComponent as LoginIcon } from "../../../../assets/log-in.svg";
import { ReactComponent as ResetIcon } from "../../../../assets/lock.svg";
import { ReactComponent as UsersMiniIcon } from "../../../../assets/users-mini.svg";
import { ReactComponent as DuplicateIcon } from "../../../../assets/duplicate.svg";
import { ReactComponent as EmailAllUsers } from "../../../../assets/email-all-users.svg";
import { ReactComponent as Close } from "../../../../assets/x-close-red.svg";
import { ReactComponent as InstitutionIcon } from "../../../../assets/institution-icon.svg";
import { ReactComponent as UserIcon } from "../../../../assets/user-icon.svg";
import { ReactComponent as GroupIcon } from "../../../../assets/users-grey.svg";
import {
  ErrorMessage,
  SuccessMessage,
  ConfirmMessagePortal,
  RejectRequestPortal,
  ApproveRequestPortal,
} from "../../errorMessage";
import { DuplicateGroupModal } from "../UserList/DuplicateGroupModal";
import { statuses } from "components/common/onlineStatuses";

import { Btn } from "../../buttons";

import Api from "../../../../helpers/api";
import { ConfirmMessage } from "../../actionMessage";

export const Separator = styled.div`
  height: ${({ height }) => (height ? `${height}px` : "30px")};
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const Card = styled.div`
  background: #ffffff;
  border-radius: 4px 0px 4px 4px;
  width: ${({ width }) => (width ? width : "auto")};
  display: flex;
  align-items: center;
  justify-content: ${({ justify }) => (justify ? justify : "space-between")};
  height: ${({ height }) => (height ? `${height}px` : "60px")};
  padding: ${({ padding }) => (padding ? padding : "0")};
  box-sizing: border-box;
  > svg {
    width: 16px;
    height: 16px;
    margin-right: 15px;
  }
`;

export const SearchInput = styled.input`
  width: 100%;
  height: 80%;
  font-family: Muli;
  font-weight: normal;
  font-size: 12px;
  line-height: 135%;
  letter-spacing: -0.02em;
  color: #181818;
  border: none;
  outline: none;
  &:focus::placeholder {
    color: transparent;
  }
`;

export const TableRow = styled.div`
  height: 70px;
  width: 100%;
  background: #ffffff;
  border-radius: 4px;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  justify-content: ${({ justify }) => (justify ? justify : "flex-start")};
`;

export const Email = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  color: ${({ theme: { primary } }) => primary};
  text-transform: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const Column = styled.div`
  overflow: hidden;
`;

export const ColHeading = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: -0.02em;
  margin-bottom: ${({ marginBottom }) => marginBottom};
  color: #181818;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-transform: none;
  margin-bottom: 7px;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const Text = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: -0.02em;
  color: #181818;
`;

const ColText = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 135%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: rgba(24, 24, 24, 0.6);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const TableCol = ({ heading, text, marginBottom }) => {
  return (
    <Column>
      <ColHeading marginBottom={marginBottom}>{heading}</ColHeading>
      {text ? <ColText>{text}</ColText> : null}
    </Column>
  );
};

const Button = styled.div`
  font-family: Muli;
  font-weight: bold;
  font-size: 10px;
  text-transform: uppercase;
  line-height: 135%;
  height: 24px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  color: ${({ active }) => (active ? "#ffffff" : "#CACACA")};
  background: ${({ active }) => (active ? "#55B17C" : "transparent")};
  border: 1px solid ${({ active }) => (active ? "transparent" : "#CACACA")};
  border-radius: 2px;
  white-space: nowrap;
  > svg {
    margin-right: 7px;
  }
`;

const StatusRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${({ left }) => (left ? "flex-start" : "center")};
  margin-top: ${({ left }) => (left ? "3px" : "0")};
`;

export const VeriFyStatus = ({ enabled, kyced, left = false }) => {
  return (
    <StatusRow left={left}>
      <Button active={enabled}>{enabled ? <CheckIcon /> : <RefreshIcon />} E-mail</Button>
      <Button active={kyced}>{kyced ? <CheckIcon /> : <RefreshIcon />}KYC </Button>
    </StatusRow>
  );
};

const Wrapper = styled.div`
  height: ${({ isInvestor }) => (isInvestor ? "68px" : "60px")};
  width: 100%;
  background: #ffffff;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${({ isInvestor }) => (isInvestor ? "0 25px" : "0 30px")};
  box-sizing: border-box;
  margin-top: auto;
`;

const DataInfo = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: ${({ isInvestor }) => (isInvestor ? "12px" : "10px")};
  line-height: 135%;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: rgba(24, 24, 24, 0.3);
`;

const PaginateBtn = styled.button`
  box-sizing: border-box;
  width: ${({ isInvestor }) => (isInvestor ? "36px" : "32px")};
  height: ${({ isInvestor }) => (isInvestor ? "36px" : "32px")};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${({ active, theme: { primary } }) => (active ? primary : "#EFEFEF")};
  background: ${({ active, theme: { primary } }) => (active ? primary : "transparent")};
  color: ${({ active, theme: { primary } }) => (active ? "#ffffff" : primary)};
  margin: ${({ margin }) => margin};
  outline: none;
  cursor: pointer;
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 135%;
  font-size: ${({ isInvestor }) => (isInvestor ? "12px" : "10px")};
  transition: all 0.1s linear;
  > svg {
    transform: ${({ forward }) => (forward ? "rotate(-90deg)" : "rotate(90deg)")};
    width: ${({ isInvestor }) => (isInvestor ? "14px" : "auto")};
    height: ${({ isInvestor }) => (isInvestor ? "14px" : "auto")};
    path {
      stroke: ${({ active }) => (active ? "#ffffff" : "#DFDFDF")};
    }
  }
`;

const BtnsBlock = styled.div`
  display: flex;
  align-items: center;
`;

export const Paginator = ({
  array,
  totalPage,
  onChangePage,
  itemsPerPage,
  isInvestor = false,
  startPage = 1,
  disabledInit = false,
}) => {
  const total = typeof totalPage === "number" ? totalPage : array.length;
  const pageCount = Math.ceil(total / itemsPerPage);
  const [currentPage, setCurrentPage] = useState(startPage);
  const from = currentPage * itemsPerPage - (itemsPerPage - 1);
  const to = currentPage * itemsPerPage > total ? total : currentPage * itemsPerPage;

  const isMountRef = useRef(false);

  const nextPage = (dir) => {
    setCurrentPage(currentPage + dir);
  };

  useEffect(() => {
    if (disabledInit) {
      if (isMountRef.current) onChangePage && onChangePage(currentPage);
      else isMountRef.current = true;
    } else {
      window.scrollTo(0, 0);
      onChangePage && onChangePage(currentPage);
    }
  }, [currentPage]);

  const renderPages = () => {
    const res = [];

    // one
    res.push(
      <PaginateBtn
        key={Math.random().toString(36).replace("0.", "key-")}
        isInvestor={isInvestor}
        onClick={() => setCurrentPage(1)}
        active={currentPage === 1}
        margin="6px"
      >
        {1}
      </PaginateBtn>
    );

    if (currentPage - 2 > 2)
      res.push(
        <PaginateBtn
          key={Math.random().toString(36).replace("0.", "key-")}
          margin={`0 6px 0 0`}
          isInvestor={isInvestor}
        >
          ...
        </PaginateBtn>
      );

    for (
      let i = currentPage - 3 > 1 ? currentPage - 3 : 1;
      i < (currentPage + 2 < pageCount - 2 ? currentPage + 2 : pageCount - 1);
      i++
    ) {
      const page = i + 1;
      res.push(
        <PaginateBtn
          key={Math.random().toString(36).replace("0.", "key-")}
          isInvestor={isInvestor}
          onClick={() => setCurrentPage(page)}
          active={currentPage === page}
          margin={`0 6px 0 0`}
        >
          {page}
        </PaginateBtn>
      );
    }

    if (currentPage + 3 < pageCount)
      res.push(
        <PaginateBtn
          key={Math.random().toString(36).replace("0.", "key-")}
          margin={`0 6px 0 0`}
          isInvestor={isInvestor}
        >
          ...
        </PaginateBtn>
      );

    // last
    if (pageCount > 1) {
      res.push(
        <PaginateBtn
          key={Math.random().toString(36).replace("0.", "key-")}
          isInvestor={isInvestor}
          onClick={() => setCurrentPage(pageCount)}
          active={currentPage === pageCount}
          margin="0"
        >
          {pageCount}
        </PaginateBtn>
      );
    }

    return res;
  };

  return total ? (
    <Wrapper isInvestor={isInvestor}>
      <BtnsBlock>
        <PaginateBtn
          isInvestor={isInvestor}
          onClick={() => nextPage(-1)}
          active={currentPage !== 1}
          disabled={currentPage === 1}
          margin="0 20px 0 0"
        >
          <Arrow />
        </PaginateBtn>
        {renderPages()}
        <PaginateBtn
          isInvestor={isInvestor}
          onClick={() => nextPage(+1)}
          forward
          active={currentPage !== pageCount}
          disabled={currentPage === pageCount}
          margin="0 0 0 20px"
        >
          <Arrow />
        </PaginateBtn>
      </BtnsBlock>
      <DataInfo isInvestor={isInvestor}>
        {from} - {to} of {total}
      </DataInfo>
    </Wrapper>
  ) : null;
};

export const SendEmailWrapper = styled.div`
  position: relative;
  width: ${({ width }) => (width ? width : "565px")};
  background: #ffffff;
  border: 1px solid #f9f9f9;
  box-sizing: border-box;
  box-shadow: 0px 10px 40px rgba(89, 120, 150, 0.06);
  border-radius: 4px;
  /* padding: 25px 30px; */
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  > span {
    font-family: Muli;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 135%;
    letter-spacing: -0.02em;
    color: rgba(51, 51, 51, 0.6);
    margin: 10px 0 5px 0;
    text-transform: none;
  }
`;

export const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(5px);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ModalHeader = styled.div`
  /* width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between; */
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: ${({ isFooter = false }) => (isFooter ? "auto" : "0")};
  height: ${({ isFooter = false }) => (isFooter ? "" : "52px")};

  padding: ${({ isFooter = false }) => (isFooter ? "0 25px 20px" : "0 25px")};
  background: #ffffff;
  border: ${({ isFooter = false }) => (isFooter ? "" : "1px solid #f9f9f9")};
  box-sizing: border-box;
  box-shadow: ${({ isFooter = false }) => (isFooter ? "" : "0px 10px 40px rgba(89, 120, 150, 0.08)")};
  border-radius: ${({ isFooter = false }) => (isFooter ? "" : "4px")};
  display: flex;
  align-items: center;
  justify-content: space-between;
  > svg {
    cursor: pointer;
    width: 24px;
    height: 24px;
  }
`;

export const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ padding = "25px 0" }) => padding};
  margin: 0 0 20px 0;
  border-top: 1px solid #f6f7fc;
  border-bottom: 1px solid #f6f7fc;
  z-index: 1000;
`;

export const Heading = styled.h3`
  text-transform: none;
  font-family: Muli;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 135%;
  margin: 0;
  color: #181818;
`;

export const WaitingWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.1);
  z-index: 1001;
`;

const Avatar = styled.img`
  ${({ small = false }) => {
    if (small) {
      return `
        width: 16px;
        height: 16px;
      `;
    }

    return `
      width: 42px;
      height: 42px;
    `;
  }}

  border-radius: 50%;
  object-fit: cover;
`;

const AvatarContainer = styled.div`
  position: relative;
  ${({ small = false }) => {
    if (small) {
      return `
        width: 16px;
        height: 16px;
      `;
    }

    return `
      width: 42px;
      height: 42px;
    `;
  }}
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 14px;
  > svg {
    filter: grayscale(${({ theme: { greyFilter } }) => greyFilter});
  }
`;

export const UserAvatar = ({
  fileName,
  hash,
  token,
  small = false,
  investorType,
  gender,
  isGroup = false,
  connectStatus,
}) => {
  const [avatar, setAvatar] = useState(null);

  useEffect(() => {
    if (!avatar && fileName && hash) {
      Api.getAttachment(token, hash, fileName).then((blob) => setAvatar(blob || Api.defaultAvatar));
    }
  });
  return (
    <AvatarContainer small={small}>
      {avatar ? (
        <Avatar small={small} src={avatar} alt="avatar" />
      ) : !fileName && !hash ? (
        investorType && investorType === "INSTITUTION" ? (
          <Institution
            style={
              small
                ? {
                    width: 16,
                    height: 16,
                  }
                : {}
            }
          />
        ) : gender && gender === "FEMALE" ? (
          <NoAvatarFemale
            style={
              small
                ? {
                    width: 16,
                    height: 16,
                  }
                : {}
            }
          />
        ) : isGroup ? (
          <GroupIcon />
        ) : (
          <NoAvatar
            style={
              small
                ? {
                    width: 16,
                    height: 16,
                  }
                : {}
            }
          />
        )
      ) : null}
      {connectStatus && statuses[connectStatus] ? statuses[connectStatus]({ size: 16, right: 0, bottom: 0 }) : null}
    </AvatarContainer>
  );
};

export const NewReqItem = styled.div`
  transform: ${({ translate }) => (translate ? `translateX(${translate}px)` : "none")};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: Muli;
  font-style: normal;
  font-weight: ${({ bold }) => (bold ? "bold" : "normal")};
  font-size: 12px;
  line-height: 135%;
  letter-spacing: 0.05em;
  box-sizing: ${({ boxSizing }) => boxSizing};
  padding: ${({ padding }) => (padding ? padding : "0")};
  color: ${({ bold }) => (bold ? "#181818" : "#494D5B")};
  width: ${({ width }) => (width ? width : "11%")};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  > span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

const Regected = styled.div`
  cursor: pointer;
  width: 94px;
  height: 32px;
  border: 1px solid ${({ rejected }) => (rejected ? "transparent" : "#ff4343")};
  background: ${({ rejected }) => (rejected ? "#ff4343" : "transparent")};
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  font-family: Muli;
  font-weight: bold;
  font-size: 10px;
  line-height: 135%;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: ${({ rejected }) => (rejected ? "#fff" : "#ff4343")};
  visibility: ${({ approved }) => (approved ? "hidden" : "visible")};
  > svg {
    margin-right: 5px;
    path {
      stroke: ${({ rejected }) => (rejected ? "#fff" : "#ff4343")};
    }
  }
`;

const Approve = styled.div`
  cursor: pointer;
  width: 94px;
  height: 32px;
  border: 1px solid ${({ approved }) => (approved ? "transparent" : "#55b17c")};
  background: ${({ approved }) => (approved ? "#55b17c" : "transparent")};
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  font-family: Muli;
  font-weight: bold;
  font-size: 10px;
  line-height: 135%;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  margin-right: 12px;
  visibility: ${({ rejected }) => (rejected ? "hidden" : "visible")};
  color: ${({ approved }) => (approved ? "#fff" : "#55b17c")};
  > svg {
    margin-right: 5px;
    path {
      stroke: ${({ approved }) => (approved ? "#fff" : "#55b17c")};
    }
  }
`;

export const ApproveRegect = ({ token, email, newFlow = false, openModal, onRefresh, _user }) => {
  const [approved, setApproved] = useState(false);
  const [rejected, setRejected] = useState(false);
  const [waitResponse, setWaitResponse] = useState(false);
  const [waitReject, setWaitReject] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [confirmRej, setConfirmRej] = useState(false);
  const debounceRef = useRef(null);

  const approveUser = () => {
    if (confirm) setConfirm(false);
    setWaitResponse(true);
    Api.approveUser(token, email)
      .then((text) => {
        console.log(text);
        setApproved(true);
        setWaitResponse(false);
        clearTimeout(debounceRef.current);
        debounceRef.current = setTimeout(() => {
          onRefresh();
        }, 450);
      })
      .catch(() => setWaitResponse(false));
  };

  const regectUser = () => {
    if (confirmRej) setConfirmRej(false);
    setWaitReject(true);

    Api.regectUser(token, email)
      .then((text) => {
        console.log(text);
        setRejected(true);
        setWaitReject(false);
        clearTimeout(debounceRef.current);
        debounceRef.current = setTimeout(() => {
          onRefresh();
        }, 450);
      })
      .catch(() => setWaitReject(false));
  };

  const onChangeConfirm = () => {
    setConfirm(!confirm);
  };

  const onChangeConfirmRej = () => {
    setConfirmRej(!confirmRej);
  };

  return (
    <Fragment>
      <Approve rejected={rejected} approved={approved} onClick={newFlow ? openModal(email) : onChangeConfirm}>
        {waitResponse ? (
          <CircularProgress size={12} />
        ) : (
          <Fragment>
            {" "}
            <CheckIcon /> Approve
          </Fragment>
        )}
      </Approve>
      <Regected approved={approved} rejected={rejected} onClick={onChangeConfirmRej}>
        {waitReject ? (
          <CircularProgress size={12} />
        ) : (
          <Fragment>
            <SuspendIcon />
            reject
          </Fragment>
        )}
      </Regected>
      {confirm ? (
        <ApproveRequestPortal
          heading="Approve Request"
          onClose={onChangeConfirm}
          onSubmit={!approved && approveUser}
          message={`Are you sure you want <br/>to approve ${_user.firstName} ${_user.lastName}?`}
        />
      ) : null}
      {confirmRej ? (
        <RejectRequestPortal
          heading="Reject Request"
          onClose={onChangeConfirmRej}
          onSubmit={!rejected ? regectUser : undefined}
          message={`Are you sure you want <br/>to reject ${_user.firstName} ${_user.lastName}?`}
        />
      ) : null}
    </Fragment>
  );
};

const ExtraInfo = styled.div`
  z-index: 3;
  position: absolute;
  height: ${({ height = 135 }) => `${height}px`};
  width: 200px;
  /* height: 135px; */
  left: -143px;
  top: ${({ top = -128 }) => `${top}px`};
  /* top: -128px; */
  background: #ffffff;
  border: 1px solid #edebeb;
  box-sizing: border-box;
  box-shadow: 0px 10px 40px rgba(89, 120, 150, 0.06);
  border-radius: 4px;
  padding: 12px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
`;

export const AssignModalLabel = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 135%;
  padding-bottom: 12px;
  color: rgba(24, 24, 24, 0.6);
  /* identical to box height, or 14px */

  letter-spacing: 0.05em;
  text-transform: uppercase;
`;

export const CurrentUserRow = styled.div`
  background: ${({ theme: { quoteBgInvestor } }) => quoteBgInvestor};
  border-radius: 4px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 42px;

  padding: 0 20px;

  font-family: Muli;
  font-size: 12px;
  line-height: 135%;
  letter-spacing: -0.02em;

  color: #181818;
`;

const Triangle = styled.div`
  position: absolute;
  top: -19px;
  width: 32px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #edebeb;
  box-sizing: border-box;
  box-shadow: 0px 10px 40px rgba(89, 120, 150, 0.06);
  border-radius: 4px;
  transform: rotate(45deg);
`;

const WhiteBlock = styled.div`
  bottom: 24px;
  width: 43px;
  height: 31px;
  background: #fff;
  position: absolute;
`;

export const ExtraLink = styled(Link)`
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 135%;
  display: flex;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  align-items: center;
  color: ${({ red }) => (red ? "rgb(255, 67, 67)" : "#181818")};
  text-decoration: none;
  z-index: 2;
  > svg {
    margin-right: 7px;
    width: 9px;
    height: 9px;
    path {
      stroke: ${({ red, theme: { primary } }) => (red ? "rgb(255,67,67)" : primary)};
    }
  }
`;

export const GroupLink = styled(Link)`
  text-decoration: none;
  margin: 0 12px 0 0;
`;

export const DotsButton = ({
  link,
  email,
  onSuccess,
  onError,
  toggleAssignModal,
  token,
  onSuccessDel,
  groupInfo,
  userId,
  groupId,
  investorType,
  _userId,
  onSuspendUser,
  enabled,
  role,
}) => {
  const [active, setActive] = useState(false);
  const [wait, setWait] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);

  const onChangeConfirm = () => {
    setConfirmOpen(!confirmOpen);
  };

  const onRemoveInvestor = async () => {
    setWait(true);

    const del = await Api.removeInvestor(token, _userId).catch((e) => {
      onError(e.message);
      setWait(false);
    });
    if (del) {
      setWait(false);
      onChangeConfirm();
      onSuspendUser();
    }
  };

  const deleteUserFromGroup = () => {
    setWait(true);

    const res = Api.deleteUserFromGroup(token, [userId], groupId).catch((e) => {
      onError(e.message);
      setWait(false);
    });

    if (res) {
      setWait(false);
      onSuccessDel(true);
    }
  };

  const resetPassword = async () => {
    setWait(true);

    const res = await Api.resetPassword(email).catch((e) => {
      onError(e.message);
      setWait(false);
    });

    if (res) {
      setWait(false);
      onSuccess(true);
    }
  };

  const isInstitution = investorType === "INSTITUTION";

  return (
    <Fragment>
      {wait && (
        <WaitingWrapper>
          <CircularProgress />
        </WaitingWrapper>
      )}
      <Btn transparent={!active} width={32} onClick={() => setActive(!active)}>
        <MoreIcon style={{ marginRight: 0 }} />
        {active && (
          <Fragment>
            <ExtraInfo
              height={isInstitution ? 85 : 135}
              top={isInstitution ? -77 : -128}
              onClick={(e) => e.stopPropagation()}
            >
              <ExtraLink to={link}>
                <Data /> transactions
              </ExtraLink>
              <ExtraLink>
                <LoginIcon /> Login Data
              </ExtraLink>
              <ExtraLink onClick={resetPassword}>
                <ResetIcon /> Reset Password
              </ExtraLink>
              {isInstitution ? null : (
                <Fragment>
                  <ExtraLink onClick={toggleAssignModal}>
                    <UsersMiniIcon /> Assign to a group
                  </ExtraLink>
                  {enabled ? (
                    <ExtraLink to={`/admin/send-report-user/${email}`}>
                      <UploadIcon /> Send Report Update
                    </ExtraLink>
                  ) : null}
                </Fragment>
              )}
              {groupInfo ? (
                <ExtraLink red onClick={deleteUserFromGroup}>
                  <Close /> delete from group
                </ExtraLink>
              ) : null}
              {!enabled && role !== "SPONSOR" ? (
                <ExtraLink red onClick={onChangeConfirm}>
                  <SuspendIcon /> Suspend
                </ExtraLink>
              ) : null}
            </ExtraInfo>
            <Triangle />
            <WhiteBlock />
          </Fragment>
        )}
      </Btn>
      {confirmOpen ? (
        <ConfirmMessagePortal
          heading="Delete investor"
          onClose={onChangeConfirm}
          message={`Are you sure to delete <b>${email}</b> ?`}
          onSubmit={onRemoveInvestor}
        />
      ) : null}
    </Fragment>
  );
};

export const DotsButtonGroup = ({ token, id, callback, onRefresh }) => {
  const [active, setActive] = useState(false);
  const [wait, setWait] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState("");
  const [openDuplicateModal, setOpenDuplicateModal] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);

  const onChangeConfirm = () => {
    setConfirmOpen(!confirmOpen);
  };

  const toggleDuplicateModal = () => {
    setOpenDuplicateModal(!openDuplicateModal);
  };

  const deleteGroup = async () => {
    setWait(true);
    const res = await Api.deleteGroupById(token, id).catch((e) => {
      setWait(false);
      setError(e);
    });
    if (res) {
      setWait(false);
      onChangeConfirm();
      setSuccess("Group successfully deleted.");
    }
  };

  const onDeleteGroup = () => {
    setSuccess("");
    callback();
  };

  return (
    <Fragment>
      {wait && (
        <WaitingWrapper>
          <CircularProgress />
        </WaitingWrapper>
      )}
      <Btn transparent={!active} width={32} onClick={() => setActive(!active)}>
        <MoreIcon style={{ marginRight: 0 }} />
        {active && (
          <Fragment>
            <ExtraInfo height={85} top={-77} onClick={(e) => e.stopPropagation()}>
              <ExtraLink to={`/admin/users/group/send-emails/${id}`}>
                <EmailAllUsers /> Email to all users
              </ExtraLink>
              <ExtraLink onClick={toggleDuplicateModal}>
                <DuplicateIcon /> Duplicate
              </ExtraLink>
              <ExtraLink red onClick={onChangeConfirm}>
                <SuspendIcon /> Delete
              </ExtraLink>
            </ExtraInfo>
            <Triangle />
            <WhiteBlock />
          </Fragment>
        )}
      </Btn>

      <DuplicateGroupModal
        token={token}
        isOpen={openDuplicateModal}
        onBackdropClick={toggleDuplicateModal}
        groupId={id}
        onRefresh={onRefresh}
      />

      {error ? <ErrorMessage er={error} onClose={() => setError(null)} /> : null}
      {success ? <SuccessMessage message={success} onClose={onDeleteGroup} /> : null}
      {confirmOpen ? (
        <ConfirmMessagePortal
          heading="Confirm"
          onClose={onChangeConfirm}
          onSubmit={deleteGroup}
          message={`Are you sure you want to delete this Group?`}
        />
      ) : null}
    </Fragment>
  );
};

export const Line = styled.div`
  width: 100%;
  border-top: 1px solid #f6f7fc;
  margin: 25px 0;
`;

export const CardContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ padding = "25px" }) => padding};
`;

const CardWrapper = styled.div`
  position: relative;
  box-sizing: border-box;
  width: 293px;
  height: 340px;
  padding: 15px;
  border: 1px solid ${({ pick = false, theme: { primary } }) => (pick ? primary : "#EFEFEF")};
  border-radius: 4px;
  cursor: pointer;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  transition-property: border-color;
  transition-duration: 0.2s;
  :hover {
    border-color: ${({ theme: { primary } }) => primary};
  }
`;

const CardM = ({ children }) => {
  return <CardWrapper>{children}</CardWrapper>;
};

export const CardUser = ({ history }) => {
  return (
    <CardM>
      <IconBg>
        <UserIcon />
      </IconBg>
      <TextH3 marginSize="23px 0 12px 0">User</TextH3>
      <TextSub marginSize="0 0 20px 0">Create a new investor, sponsor or another admin</TextSub>
      <Btn extraInCard width={178} onClick={() => history.push("/admin/user/create")}>
        ADD NEW USER
      </Btn>
    </CardM>
  );
};

export const CardInstitution = ({ history }) => {
  return (
    <CardM>
      <IconBg>
        <InstitutionIcon />
      </IconBg>
      <TextH3 marginSize="23px 0 12px 0">Institution</TextH3>
      <TextSub marginSize="0 0 20px 0">
        Create new Institution for Investors
        <br />
        with users
      </TextSub>
      <Btn extraInCard width={178} onClick={() => history.push("/admin/user/institution/create")}>
        ADD NEW INSTITUTION
      </Btn>
    </CardM>
  );
};

const IconBg = styled.div`
  width: 263px;
  height: 145px;
  background: ${({ theme: { quoteBgInvestor } }) => quoteBgInvestor};
  border-radius: 4px;
  display: flex;
  > svg {
    margin: auto;
    path {
      stroke: ${({ theme: { primary } }) => primary};
    }
  }
`;

const TextH3 = styled.h3`
  text-align: center;
  font-family: "Muli", sans-serif;
  font-size: ${({ size }) => (size ? `${size}px` : "16px")};
  font-weight: bold;
  ${({ marginSize = "" }) => (marginSize ? `margin: ${marginSize};` : "")}
`;

const TextSub = styled.p`
  text-align: center;
  font-family: "Muli", sans-serif;
  font-size: 14px;
  ${({ marginSize = "" }) => (marginSize ? `margin: ${marginSize};` : "")}
`;

export const EmptyCard = styled.div`
  background: #ffffff;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 25px;
  width: 100%;
  margin: 0 auto;
  font-weight: 600;
  font-size: 16px;
  line-height: 160%;
  color: #333333;
  text-align: center;
  > a {
    color: ${({ theme: { primary } }) => primary};
  }
`;

export const InputsRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  :last-child {
    margin-bottom: 0;
  }
`;

export const SeparatorLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: #f6f7fc;
  margin: ${({ margin = false }) => (margin ? margin : "25px 0")};
`;

export const BtnBlock = styled.div`
  display: flex;
`;

export const SearchWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const NoCompanies = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${({ theme: { emptyCardBg } }) => emptyCardBg};

  border: 1px solid #efefef;
  box-sizing: border-box;
  border-radius: 4px;
  height: 180px;
  margin-top: 15px;
  padding: 27px 0;
  > h3 {
    font-family: Muli;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 135%;
    text-align: center;
    letter-spacing: -0.02em;
    color: #333333;
    margin: 0;
  }
  > p {
    font-family: Muli;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 135%;
    text-align: center;
    letter-spacing: -0.02em;
    color: #333333;
    margin: 12px 0 22px 0;
  }
`;

export const SelectorWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 252px;
`;
export const SearchBlock = styled.div`
  width: 100%;
  height: 42px;
  border: 1px solid #efefef;
  box-sizing: border-box;
  border-radius: 4px;
  position: relative;
  > svg {
    position: absolute;
    left: 17px;
    top: 12px;
    width: 16px;
    height: 16px;
    path {
      stroke: #181818;
    }
  }
`;

export const SearchInputSel = styled.input`
  width: 100%;
  height: 100%;
  padding-left: 43px;
  box-sizing: border-box;
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 135%;
  letter-spacing: -0.02em;
  color: #333333;
  border: none;
  outline: none;
  &:focus::placeholder {
    color: transparent;
  }
`;

export const InstCard = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  align-items: center;
  background: ${({ theme: { quoteBgInvestor } }) => quoteBgInvestor};
  border-radius: 4px;
  width: 100%;
  height: 42px;
  padding-left: 16px;
  margin-bottom: 8px;
  border: 1px solid ${({ active, theme: { primary, quoteBgInvestor } }) => (active ? primary : quoteBgInvestor)};
  box-sizing: border-box;
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 135%;
  letter-spacing: -0.02em;
  color: #181818;
  :last-child {
    margin-bottom: 3px;
  }
  > svg {
    margin-right: 17px;
    width: 16px;
    height: 16px;
    path {
      stroke: ${({ theme: { primary } }) => primary};
    }
  }
`;

export const ScrollContainer = styled.div`
  min-height: 195px;
  max-height: 195px;
  padding-left: ${({ pLeft }) => (pLeft ? `${pLeft}px` : "0")};
  padding-right: ${({ pRight }) => (pRight ? `${pRight}px` : "0")};
  box-sizing: border-box;
  margin-top: 15px;
  overflow-y: scroll;
  &&::-webkit-scrollbar-track {
    background-color: ${({ theme: { primaryLight } }) => primaryLight};
    border-radius: 2px;
  }
  &&::-webkit-scrollbar {
    width: 6px;
    border-radius: 2px;
    background-color: ${({ theme: { primaryLight } }) => primaryLight};
  }
  &&::-webkit-scrollbar-thumb {
    background-color: ${({ theme: { primaryMiddle } }) => primaryMiddle};
    border-radius: 2px;
  }
`;
