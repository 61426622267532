import styled from "styled-components";
import React from "react";

import { ReactComponent as FileIcon } from "assets/file-text.svg";
import { ReactComponent as DelIcon } from "assets/cross-del.svg";
import { ReactComponent as Check } from "assets/check_box.svg";

import theme from "models/themes";

export const ContainerFlex = styled.div`
  position: relative;
  display: flex;
  flex-direction: ${({ row = false }) => (row ? "row" : "column")};
  justify-content: ${({ justify = "flex-start" }) => justify};
  align-items: ${({ align = "flex-start" }) => align};
  ${({ wrap = false }) => (wrap ? "flex-wrap: wrap;" : "")}

  width: ${({ w = "100%" }) => w};
  ${({ h = null }) => (h ? `height: ${h};` : "")}

  padding: ${({ paddingSize = 0 }) => paddingSize};
  margin: ${({ marginSize = 0 }) => marginSize};
  background: ${({ bg = "transparent" }) => bg};
  border-radius: ${({ borderRadius = 0 }) => borderRadius};

  ${({ border = null }) => (border ? `border: ${border};` : "")}
  overflow: ${({ overflow = "hidden" }) => overflow};

  z-index: ${({ zIndex = 0 }) => zIndex};
  transition: height 0.3s linear;
  box-sizing: border-box;
`;

export const FileNameBlock = styled.div`
  width: 224px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const DocInput = styled.input`
  background: ${({ errorWithBG }) => (errorWithBG ? "rgba(255, 67, 67, 0.05)" : "transparent")};
  height: ${({ height }) => (height ? `${height}px` : "42px")};
  width: ${({ width }) => (width ? width : "calc(100% - 300px)")};
  border: 1px solid ${({ error, errorWithBG }) => (error || errorWithBG ? "#FF4343" : "#efefef")};
  box-sizing: border-box;
  border-radius: 4px;
  outline: none;
  padding: ${({ padding }) => padding || "0 20px"};
  margin: ${({ margin = 0 }) => margin};
  font-family: Muli;
  font-weight: normal;
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : "12px")};
  line-height: 135%;
  letter-spacing: -0.02em;
  color: #181818;

  &[type="date"]::-webkit-inner-spin-button,
  &[type="date"]::-webkit-calendar-picker-indicator,
  &[type="date"]::-webkit-clear-button,
  &[type="time"]::-webkit-clear-button {
    opacity: 0;
    cursor: pointer;
    -webkit-appearance: none !important;
  }
  &[type="time"]::-webkit-inner-spin-button,
  &[type="time"]::-webkit-calendar-picker-indicator {
    opacity: 0;
    cursor: pointer;
    -webkit-appearance: none;
  }
  &[type="number"]::-webkit-outer-spin-button,
  &[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
  }
  background-image: ${({ icon }) => `url(${icon})`};
  background-position: ${({ bgPos = "right" }) => bgPos};
  background-position-x: ${({ arrowPosition, leftPos }) => {
    if (leftPos) return leftPos;
    return arrowPosition ? `calc(100% - ${arrowPosition}px)` : "calc(100% - 24px)";
  }};

  background-size: 16px;
  background-repeat: no-repeat;
  &::placeholder {
    color: rgba(24, 24, 24, 0.3);
  }
  &:disabled {
    background: ${({ errorWithBG = false, theme: { disabledField } }) =>
      errorWithBG ? "rgba(255, 67, 67, 0.05)" : disabledField};
  }
`;

export const DocSelect = styled.select`
  ${({ hidden }) => {
    if (hidden) return "display: none!important;";
    return "display: block!important;";
  }}
  position: relative;
  height: ${({ height }) => (height ? `${height}px` : "42px")};
  width: ${({ width }) => (width ? width : "100%")};
  border: 1px solid ${({ borderColor = "#efefef" }) => borderColor};
  box-sizing: border-box;
  appearance: none;
  border-radius: 4px;
  outline: none;
  padding: ${({ padding }) => (padding ? padding : "0 20px")};
  font-family: Muli;
  font-weight: normal;
  font-size: ${({ fSize }) => (fSize ? `${fSize}px` : "12px")};
  line-height: 135%;
  letter-spacing: -0.02em;
  color: #181818;
  background: #fff;
  background-image: ${({ arrow }) => `url(${arrow})`};
  background-position: right;
  background-position-x: ${({ arrowPosition }) =>
    arrowPosition ? `calc(100% - ${arrowPosition}px)` : "calc(100% - 24px)"};
  background-size: 16px;
  background-repeat: no-repeat;
  &::placeholder {
    color: rgba(24, 24, 24, 0.3);
  }
`;

export const DocOptionContainer = styled.div`
  position: relative;
  display: block;

  left: ${({ left = 0 }) => left}px;
  width: ${({ width }) => width}px;
  overflow-y: ${({ offScroll }) => (offScroll ? "hidden" : "auto")};

  background: #ffffff;
  box-sizing: border-box;

  ${({ offBorder, borderColor = "#efefef", padding = "12px 20px", paddingRight = "20px" }) => {
    if (offBorder) {
      return `
      padding-right: ${paddingRight};
      padding-bottom: 12px;`;
    } else {
      return `
      border: 1px solid ${borderColor};
      box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.05);
      padding: ${padding};
      `;
    }
  }}

  border-radius: 0 0 4px 4px;
  top: ${({ top = 0 }) => top}px;
  max-height: ${({ maxHeight = "260px" }) => maxHeight};

  ${({ height = "" }) => {
    if (height) return `height: ${height};`;
    else return "";
  }}

  &&::-webkit-scrollbar-track {
    background-color: ${({ theme: { primaryLight } }) => primaryLight};
    border-radius: 2px;
  }
  &&::-webkit-scrollbar {
    width: 8px;
    border-radius: 2px;
    background-color: ${({ theme: { primaryLight } }) => primaryLight};
  }
  &&::-webkit-scrollbar-thumb {
    background-color: ${({ theme: { primaryMiddle } }) => primaryMiddle};
    border-radius: 2px;
  }
`;

export const DocOptionsBlock = styled.div`
  font-family: Muli;
  font-size: 12px;
  color: #181818;
  cursor: pointer;
  text-transform: none;
  padding: ${({ padding = "0px" }) => padding};
  background: ${({ bg = "transparent" }) => bg};
  border-radius: ${({ radius = 0 }) => radius};
  display: flex;

  opacity: ${({ selected }) => (selected ? 0.4 : 1)};
  pointer-events: ${({ selected }) => (selected ? "none" : "auto")};
  position: relative;
  user-select: none;
  &:after {
    display: ${({ pseudo = "" }) => (pseudo ? "block" : "none")};
    content: " (${({ pseudoContent }) => pseudoContent})";
    margin-left: 5px;
    color: #55b17c;
  }
  &:not(:last-child) {
    margin-bottom: 10px;
  }

  ${({ disabled }) => {
    if (disabled) {
      return `
        color: rgba(24, 24, 24, 0.3);
        pointer-events: none;
      `;
    }
    return "";
  }}

  &:hover {
    ${({ selected = false }) => {
      if (selected) return "color: #181818;";
      else return `color: ${theme.primary};`;
    }}
  }
`;

const CheckboxCustom = styled.div`
  width: 16px;
  height: 16px;
  border: 1px solid ${({ selected, theme: { primary } }) => (selected ? primary : "#EFEFEF")};
  > svg {
    path {
      stroke: ${({ theme: { primary } }) => primary};
    }
  }
  box-sizing: border-box;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
`;

export const DocOptionsCheckbox = ({ children, onClick, selected }) => {
  return (
    <DocOptionsBlock onClick={onClick}>
      <CheckboxCustom selected={selected}>{selected ? <Check /> : null}</CheckboxCustom>
      {children}
    </DocOptionsBlock>
  );
};

export const DocOptions = styled.option`
  font-family: Muli;
  font-size: 12px;
  line-height: 135%;
  letter-spacing: -0.02em;
  color: #181818;
  ${({ hidden }) => (hidden ? "display: none;" : "")}
`;

export const DateWrapper = styled.div`
  width: ${({ width }) => width};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const ImageWrapper = styled.div`
  height: 82px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  border: 1px solid #efefef;
  box-sizing: border-box;
  border-radius: 4px;
  width: 100%;
`;

const NameCont = styled.div`
  display: flex;
  align-items: center;
  > svg {
    margin-right: 6px;
  }
`;

const Name = styled.div`
  font-family: Muli;
  font-size: 12px;
  line-height: 135%;
  letter-spacing: -0.02em;
  color: #181818;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  max-width: 185px;
`;

export const StatusBlock = styled.div`
  box-sizing: border-box;
  width: 240px;
  height: 42px;
  background: ${({ bg }) => (bg ? bg : "#019d52")};
  border-radius: 2px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 135%;
  letter-spacing: -0.02em;
  color: #ffffff;
  text-transform: none;
`;

export const FileBlock = ({ name, onDelete }) => {
  return (
    <FileNameBlock>
      <NameCont>
        <FileIcon />
        <Name> {name}</Name>
      </NameCont>
      <DelIcon style={{ cursor: "pointer" }} onClick={onDelete} />
    </FileNameBlock>
  );
};
