import styled from "styled-components";

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => width};
`;

export const InputRow = styled.div`
  margin-bottom: ${({ last }) => (last ? "0" : "20px")};
  display: flex;
  align-items: center;
  width: ${({ width }) => (width ? width : "100%")};
  justify-content: space-between;
  box-sizing: border-box;
  ${({ wrap = false }) => (wrap ? "flex-wrap: wrap;" : "")}
`;

export const InfoBlock = styled.div`
  margin-top: 25px;
  box-sizing: border-box;
  width: ${({ width }) => width};
  height: 42px;
  background: ${({ theme: { quoteBgInvestor } }) => quoteBgInvestor};
  border-radius: 2px;
  display: flex;
  align-items: center;
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 135%;
  letter-spacing: -0.02em;
  color: #181818;
  padding: 0 17px;
  > svg {
    margin-right: 9px;
    path {
      stroke: ${({ theme: { primary } }) => primary};
    }
  }
`;

export const Footer = styled.div`
  width: 100%;
  background: #ffffff;
  border-radius: 4px 0px 4px 4px;
  height: 60px;
  margin-top: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 25px;
  box-sizing: border-box;
`;

export const WaitingWrapper = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.3);
  z-index: 1001;
`;

export const Separator = styled.div`
  height: ${({ height }) => (height ? `${height}px` : "30px")};
`;

export const UsersBlock = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const GroupsBlock = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const SpanGroup = styled.span`
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: calc(100% - 30px);
`;

export const InputRowGroups = styled.div`
  /* margin-bottom: ${({ last }) => (last ? "0" : "20px")}; */
  flex-direction: column;
  display: flex;
  align-items: center;
  width: ${({ width }) => (width ? width : "100%")};
  justify-content: space-between;
  box-sizing: border-box;
  ${({ wrap = false }) => (wrap ? "flex-wrap: wrap;" : "")}
`;
//new
const col = ` display: flex;
flex-direction: column;
box-sizing: border-box;
width: 100%;`;

export const Card = styled.div`
  position: relative;
  background: #ffffff;
  border-radius: 4px;
  padding: 20px 25px 30px;
  margin-bottom: 20px;
  min-height: 62px;
  height: ${({ close, height }) =>
    close ? "62px" : height ? `${height}px` : "fit-content"};
  overflow: ${({ close }) => (close ? "hidden" : "visible")};
  transition: all 0.1s linear;
  ${col}
  &:last-child {
    margin-bottom: 0;
  }
`;

export const CardHead = styled.h3`
  color: #181818;
  margin: ${({ m = "0 0 25px" }) => m};
  font-weight: ${({ fWeight = "bold" }) => fWeight};
  font-size: 14px;
  line-height: 135%;
`;

export const RightBlock = styled.div`
  position: absolute;
  top: 15px;
  right: 25px;
  display: flex;
`;

export const Empty = styled.div`
  background: ${({ theme: { emptyCardBg } }) => emptyCardBg};
  width: 100%;
  height: 97px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 20px 20px 30px;
  box-sizing: border-box;
`;

export const Text = styled.p`
  font-size: 12px;
  line-height: 135%;
  color: #181818;
  margin: ${({ m = 0 }) => m};
`;

export const KeysWrapper = styled.div`
  ${col}
  padding-bottom:20px;
`;

export const Key = styled.div`
  ${col};
  padding: 15px 0;
  border-bottom: 1px solid #f6f7fc;
  &:first-child {
    padding-top: 0;
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const Remove = styled.button`
  border: none;
  outline: none;
  width: 190px;
  height: 32px;
  background: ${({ theme: { emptyCardBg } }) => emptyCardBg};
  color: ${({ theme: { red } }) => red};
  font-size: 10px;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  cursor: pointer;
  border-radius: 4px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Expand = styled.button`
  width: 32px;
  height: 32px;
  background: ${({ theme: { primary }, close }) =>
    close ? primary : "#F0F0F0"};
  border-radius: 4px;
  cursor: pointer;
  border: none;
  display: flex;
  transition: all 0.1s linear;
  outline: none;
  > svg {
    width: 16px;
    height: 16px;
    margin: auto;
    transition: all 0.1s linear;
    transform: ${({ close }) => (close ? "rotate(180deg)" : "rotate(0deg)")};
    path {
      stroke: ${({ close }) => (close ? "#fff" : "#333333")};
    }
  }
`;
