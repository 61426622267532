import React, { Component } from "react";
import Slider from "react-slick";
import styled from "styled-components";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { CircularProgress } from "@material-ui/core";
import Api from "../../../../helpers/api";

const settings = {
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
};

export const NoSliderContainer = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SliderWrapper = styled.div`
  height: 300px;
  width: 100%;
  max-width: 100%;
  overflow-y: hidden;

  & .slick-arrow.slick-prev {
    width: 42px;
    height: 42px;
    left: 30px;
    top: 150px;
    background: #ffffff;
    border-radius: 50%;
    z-index: 1;
    &:hover {
      background: ${({ theme: { quoteBgInvestor } }) => quoteBgInvestor};
    }
    &::before {
      content: url("/assets/chevron-right.svg");
      display: block;
      margin-bottom: 6px;
    }
  }
  & .slick-arrow.slick-next {
    width: 42px;
    height: 42px;
    right: 30px;
    top: 150px;
    background: #ffffff;
    border-radius: 50%;
    z-index: 1;
    &:hover {
      background: ${({ theme: { quoteBgInvestor } }) => quoteBgInvestor};
    }
    &::before {
      content: url("/assets/chevron-right.svg");
      display: block;
      margin-top: 6px;
      transform: rotate(180deg);
    }
  }
`;

export const CustomSlider = styled.div`
  height: 360px;
  width: 100%;
`;

export const ImgSlide = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 4px 4px 0px 0px;
  border: 1px solid #f0f0f0;
`;

class OfferSlider extends Component {
  state = {
    imgs: [],
  };

  addToState = (base64) => {
    return new Promise((res) => {
      this.setState((state, props) => {
        return {
          imgs: [...state.imgs, base64],
        };
      }, res);
    });
  };

  componentWillMount() {
    this.props.images.map(async ({ secureHash, fileNameWithExtension, base64 }) => {
      if (secureHash && fileNameWithExtension) {
        const _base64 = await Api.getAttachment(this.props.token, secureHash, fileNameWithExtension);
        await this.addToState(_base64);
      } else if (base64) {
        this.addToState(base64);
      }
    });
  }

  renderSlides = () => {
    return this.state.imgs.map((e) => {
      return (
        <CustomSlider key={e}>
          <ImgSlide src={e} alt="image" />
        </CustomSlider>
      );
    });
  };

  render() {
    if (this.state.imgs.length) {
      return (
        <SliderWrapper>
          <Slider {...settings}>{this.renderSlides()}</Slider>
        </SliderWrapper>
      );
    } else {
      return (
        <NoSliderContainer>
          <CircularProgress />
        </NoSliderContainer>
      );
    }
  }
}

export default OfferSlider;
