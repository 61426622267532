import styled from "styled-components";
import { ReactComponent as CheckIcon } from "../../../../../asset/check.svg";
import React, { useState, useEffect } from "react";

export const Wrapper = styled.section`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  min-height: 100%;
`;

export const Card = styled.div`
  width: calc(33.33% - 20px);
  height: 333px;
  margin-bottom: 25px;
  background: #ffffff;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 25px;
  display: flex;
  flex-direction: column;
`;

export const EmptyCard = styled.div`
  width: calc(33.33% - 20px);
`;

export const Footer = styled.div`
  height: 60px;
  width: 100%;
  border-radius: 4px 0px 4px 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 14px 25px;
  box-sizing: border-box;
  margin-top: auto;
  background: #ffffff;
  margin-top: auto;
`;

export const Heading = styled.h4`
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 135%;
  color: #181818;
  margin: 0 0 16px;
`;

export const Flex = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  > button {
    width: calc(50% - 6px);
  }
`;

export const DocsCol = styled.div`
  box-sizing: border-box;
  width: 100%;
  overflow-y: scroll;
  height: 201px;
  padding-right: 11px;
  &&::-webkit-scrollbar-track {
    background-color: ${({ theme: { primaryLight } }) => primaryLight};
    border-radius: 2px;
  }
  &&::-webkit-scrollbar {
    width: 8px;
    border-radius: 2px;
    background-color: ${({ theme: { primaryLight } }) => primaryLight};
  }
  &&::-webkit-scrollbar-thumb {
    background-color: ${({ theme: { primaryMiddle } }) => primaryMiddle};
    border-radius: 2px;
  }
`;

export const Line = styled.div`
  width: 100%;
  height: 0;
  border-top: 1px solid #f0f0f0;
  margin: 16px 0;
`;

export const DocBlock = styled.div`
  height: 40px;
  background: #ffffff;
  border: 1px solid ${({ active, theme: { primary } }) => (active ? primary : "#EFEFEF")};
  box-sizing: border-box;
  border-radius: 4px;
  width: 100%;
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  transition: all 0.1s linear;
  &:last-child {
    margin-bottom: 0;
  }
  svg {
    path {
      stroke: ${({ theme: { primary } }) => primary};
    }
  }
`;

export const IconBlock = styled.div`
  width: 40px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  transition: all 0.1s linear;
  &:first-child {
    border-right: 1px solid ${({ active, theme: { primary } }) => (active ? primary : "#efefef")};
  }
  &:last-child {
    border-left: 1px solid ${({ active, theme: { primary } }) => (active ? primary : "#efefef")};
  }
  > svg {
    cursor: pointer;
  }
`;

export const DocName = styled.div`
  height: 100%;
  box-sizing: border-box;
  width: calc(100% - 80px);
  display: flex;
  align-items: center;
  padding: 0 15px;

  > svg {
    margin-right: 13px;

    path {
      transition: all 0.1s linear;
      fill: ${({ active, theme: { primary } }) => (active ? primary : "#efefef")};
      stroke: transparent;
    }
  }
`;

export const NameContainer = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: calc(100% - 30px);
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 135%;
  color: #181818;
`;

const Border = styled.div`
  width: 16px;
  height: 16px;
  background: #ffffff;
  border: 1px solid ${({ cheked, theme: { primary } }) => (cheked ? primary : "#EFEFEF")};
  box-sizing: border-box;
  border-radius: 2px;
  cursor: pointer;
  display: flex;
  transition: all 0.1s linear;
  > svg {
    margin: auto;
    transform: ${({ cheked }) => (cheked ? "scale(1)" : "scale(0)")};
    transition: all 0.1s linear;
  }
`;

export const CheckBox = ({ cheked, callBack }) => {
  const [value, setValue] = useState(cheked);

  useEffect(() => {
    setValue(cheked);
  }, [cheked]);

  const onChange = (e) => {
    e.stopPropagation();
    callBack(!value);
    setValue(!value);
  };
  return (
    <Border cheked={value} onClick={onChange}>
      <CheckIcon />
    </Border>
  );
};

export const WrapperForViewer = styled.section`
  width: 100%;
  height: calc(100vh - 100px);
  min-height: 500px;
  padding-bottom: 20px;
  display: ${({ hidden = false }) => (hidden ? "none" : "block")};
  overflow: hidden;
`;
