import React from "react";
import styled from "styled-components";

//styled
import { Main, TextSub } from "../../DealForm/Step/styled";
import { ProgressBar, Row, DataColumn, Filter } from "../styled";
import { numberWithCommas } from "helpers";

export const Financial = ({
  deal: {
    subscribers,
    roundsInfo: [last],
  },
}) => {
  const { companyValuation, currency, sharePrice } = last;
  return (
    <Card>
      <TextSub textAlign="left" fontWeight="bold" color="#181818" marginSize="0 0 20px 0">
        Financial
      </TextSub>
      <Row>
        <DataColumn heading="ASSET VALUATION" data={`${numberWithCommas(companyValuation)} ${currency}`} />
        <DataColumn heading="Share Price" data={`${numberWithCommas(sharePrice)} ${currency}`} />
        <DataColumn heading="subscribers" data={subscribers || "-"} />
      </Row>
    </Card>
  );
};

const Card = styled.div`
  height: 120px;
  background: #ffffff;
  border-radius: 4px;
  padding: 20px 25px;
  box-sizing: border-box;
  width: calc(100% - 430px);
`;
