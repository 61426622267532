export const objectKeysToArray = (object, array = []) => {
    if (typeof object === "object" && !Array.isArray(object)) {
      const keys = Object.keys(object);
      keys.forEach((e) => {
        objectKeysToArray(object[e], array);
        array.push(e);
      });
    } else if (Array.isArray(object)) {
      object.forEach((e) => array.push(e));
    } else if (typeof object === "string") {
      array.push(object);
    }
  
    return array;
  };