import React, { useState } from "react";
import ReactDom from "react-dom";
import moment from "moment";

import {
  Row,
  Card,
  ReaderWrapper,
  Heading,
  Text,
  ResultAction,
  AskBtn,
  Col,
  ViewerHeader,
  ViewerDocName,
  SizeDate,
  ExpandBtn,
  View,
  ExpandWrapper,
} from "./styled";

import { ReactComponent as Check } from "../../../../../assets/check-slim.svg";
import { ReactComponent as Delete } from "../../../../../assets/delete-file.svg";
import { ReactComponent as Chat } from "../../../../../assets/message-circle.svg";
import { ReactComponent as Expand } from "../../../../../assets/expand.svg";

export const Approve = ({
  wait,
  blob,
  docName,
  size,
  lastUpdate,
  onRejectDocument,
  onApproveDocument,
}) => {
  const fileURL = URL.createObjectURL(blob);
  const [expand, setExpand] = useState(false);

  const onChangeExpand = () => {
    setExpand(!expand);
  };
  return (
    <Row>
      {expand ? (
        <ExpandedReader
          docName={docName}
          lastUpdate={lastUpdate}
          size={size}
          onChange={onChangeExpand}
          fileURL={fileURL}
        />
      ) : null}
      <ReaderWrapper>
        <ViewerHeader>
          <ViewerDocName>{docName}</ViewerDocName>
          <SizeDate>
            {(size / 1048576).toFixed(2)} mb /
            {moment(lastUpdate).format("DD.MM.YYYY, hh:mm A")}
          </SizeDate>
          <ExpandBtn onClick={onChangeExpand}>
            <Expand />
          </ExpandBtn>
        </ViewerHeader>
        <View src={fileURL} />
      </ReaderWrapper>
      <Col>
        <Card m width="100%">
          <Heading>Check the Result</Heading>
          <Text>
            Please <b>check this file</b>. Our system added information to your
            document. If all fields are correct press{" "}
            <b>«Document is Correct»</b>, if not «Document is Incorrect», we’ll
            repeat these steps to check what’s wrong.
          </Text>
          <ResultAction disabled={wait} onClick={onApproveDocument}>
            Document is correct <Check />
          </ResultAction>
          <ResultAction disabled={wait} isReject onClick={onRejectDocument}>
            Document is incorrect <Delete />
          </ResultAction>
        </Card>
        <Card width="100%">
          <Heading>Ask the Support</Heading>
          <Text>
            Having a problem with setting automation? We’re here to help!
          </Text>
          <AskBtn disabled={wait}>
            Contact support <Chat />
          </AskBtn>
        </Card>
      </Col>
    </Row>
  );
};

const ExpandedReader = ({ docName, lastUpdate, fileURL, size, onChange }) =>
  ReactDom.createPortal(
    <ExpandWrapper>
      <ViewerHeader isExpand>
        <ViewerDocName>{docName}</ViewerDocName>
        <SizeDate>
          {(size / 1048576).toFixed(2)} mb /
          {moment(lastUpdate).format("DD.MM.YYYY, hh:mm A")}
        </SizeDate>
        <ExpandBtn onClick={onChange}>
          <Expand />
        </ExpandBtn>
      </ViewerHeader>
      <View isExpand src={fileURL} />
    </ExpandWrapper>,
    document.getElementById("round-modal")
  );
