import React, { Fragment, useState } from "react";
import ReactDOM from "react-dom";

import { InputRow } from "../Step/Information/styled";
import { SelectComponent, TextFieldComponent, ChipsSelectComponent } from "../../../forms";
import { icons } from "../../../../../models/icons";
import {
  Label,
  Card,
  DynamicBtn,
  RowCard,
  HeadingCard,
  Separator,
  Wrapper,
  HeaderModal,
  Heading,
  PaddingBox,
  ShowButton,
  Circle,
  FoooterModal,
  Background,
  ModalText,
  RuleBlock,
  RulesColumn,
  SettingsBg,
  AddRound,
  SettingsHeading,
  SettingsText,
} from "../styled";
import { Btn } from "../../../buttons";
import { activeRulesJson } from "../../../../../models/dealModel";
import { ReactComponent as Plus } from "../../../../../assets/plus-circle.svg";
import { ReactComponent as Icon } from "../../../../../assets/sliders.svg";

export const Rules = ({
  onChange,
  defaultValues,
  users,
  ruleOptions,
  countriesOption,
  lockUpTime,
  groups,
  token,
  onChangeGroups,
  onChangeRules,
  onChangeUsers,
}) => {
  const [modalOpen, setModalOpen] = useState(false);

  const onChangeModal = () => {
    setModalOpen(!modalOpen);
  };

  const _users = users.map((u) => {
    return {
      label: u.fullName,
      iconHash: u.userLogoHash,
      iconName: u.userLogoFileName,
    };
  });

  const _groups = groups.map((group) => {
    return {
      label: group.name,
      iconHash: group.logoHash,
      iconName: group.logoFileName,
    };
  });

  const selectedGroups = () => {
    if (defaultValues.visibilityProperty.groups) {
      const indexes = [];

      defaultValues.visibilityProperty.groups.forEach((g) => {
        const index = groups.findIndex((_g) => _g.id === +g);
        if (index !== -1) indexes.push(index);
      });
      return indexes;
    } else {
      return [];
    }
  };

  const selectedUsers = () => {
    if (defaultValues.visibilityProperty.users) {
      const indexes = [];

      defaultValues.visibilityProperty.users.forEach((id) => {
        const index = users.findIndex((_u) => _u.id === id);
        if (index !== -1) indexes.push(index);
      });
      return indexes;
    } else {
      return [];
    }
  };

  const onSelectGroup = (arr) => {
    const values = [];
    arr.forEach((i) => {
      values.push(String(groups[i].id));
    });
    onChangeGroups(values.length ? values : null);
  };

  const onSelectUser = (arr) => {
    const values = [];
    arr.forEach((i) => {
      values.push(String(users[i].id));
    });
    onChangeUsers(values.length ? values : null);
  };

  const rulesVisibility = JSON.parse(
    defaultValues.visibilityProperty.activeRulesJson ||
      '{"countryRestriction":false,"lockUpPeriod":false,"groups":false,"users":false}'
  );

  const someRules = Object.keys(rulesVisibility).some((rule) => rulesVisibility[rule]);

  return (
    <Fragment>
      <Card pb="10px">
        <RowCard>
          <HeadingCard>Rules</HeadingCard>
          {someRules ? (
            <DynamicBtn rightPosition="25px" small onClick={onChangeModal}>
              {icons.edit}
              <span>Edit</span>
            </DynamicBtn>
          ) : null}
        </RowCard>
        <Separator />
        {someRules ? (
          <Fragment>
            {rulesVisibility.groups ? (
              <Fragment>
                <Label>Share with Groups</Label>
                <InputRow style={{ marginBottom: 10 }}>
                  {_groups.length ? (
                    <ChipsSelectComponent
                      view="filter"
                      multiple
                      token={token}
                      values={_groups}
                      defaultValue={selectedGroups()}
                      onChange={onSelectGroup}
                    />
                  ) : null}
                </InputRow>
              </Fragment>
            ) : null}

            {rulesVisibility.users ? (
              <Fragment>
                <Label>Share with Users</Label>
                <InputRow style={{ marginBottom: 10 }}>
                  {users.length ? (
                    <ChipsSelectComponent
                      view="filter"
                      multiple
                      token={token}
                      values={_users}
                      defaultValue={selectedUsers()}
                      onChange={onSelectUser}
                    />
                  ) : null}
                </InputRow>
              </Fragment>
            ) : null}

            {rulesVisibility.countryRestriction ? (
              <Fragment>
                <InputRow>
                  <SelectComponent
                    label="Country"
                    placeholder="Country"
                    values={ruleOptions}
                    defaultValue={defaultValues.visibilityProperty.countryRestriction.listType}
                    onChange={onChange("visibilityProperty", "countryRestriction", "listType")}
                  />
                </InputRow>
                {defaultValues.visibilityProperty.countryRestriction.listType != "ALL" ? (
                  <InputRow>
                    <SelectComponent
                      label="Country List"
                      placeholder="Country List"
                      values={countriesOption}
                      multiple
                      defaultValue={defaultValues.visibilityProperty.countryRestriction.countries || []}
                      onChange={onChange("visibilityProperty", "countryRestriction", "countries")}
                    />
                  </InputRow>
                ) : null}
              </Fragment>
            ) : null}
            {rulesVisibility.lockUpPeriod ? (
              <InputRow>
                <TextFieldComponent
                  width="calc(50% - 6.5px)"
                  type="number"
                  label="lock Up Period"
                  placeholder="Lock up period"
                  defaultValue={
                    defaultValues.visibilityProperty.lockUpPeriod &&
                    defaultValues.visibilityProperty.lockUpPeriod.amount
                      ? defaultValues.visibilityProperty.lockUpPeriod.amount
                      : ""
                  }
                  onChange={onChange("visibilityProperty", "lockUpPeriod", "amount")}
                />
                <SelectComponent
                  width="calc(50% - 6.5px)"
                  disabled={
                    defaultValues.visibilityProperty.lockUpPeriod &&
                    !defaultValues.visibilityProperty.lockUpPeriod.amount
                  }
                  hiddenLabel
                  label="Unit"
                  placeholder="Unit"
                  values={lockUpTime}
                  defaultValue={
                    defaultValues.visibilityProperty.lockUpPeriod &&
                    defaultValues.visibilityProperty.lockUpPeriod.unit &&
                    defaultValues.visibilityProperty.lockUpPeriod.unit
                  }
                  onChange={onChange("visibilityProperty", "lockUpPeriod", "unit")}
                />
              </InputRow>
            ) : null}
          </Fragment>
        ) : (
          <SettingsBg m="0 0 20px">
            <Icon />
            <SettingsHeading>There are no added rules yet</SettingsHeading>
            <SettingsText>You can add rules for this asset by using the «Add Rules» button</SettingsText>
            <AddRound position="static" onClick={onChangeModal}>
              <Plus />
              <span>ADD RULES</span>
            </AddRound>
          </SettingsBg>
        )}
      </Card>
      {modalOpen ? (
        <EditFields
          onBackdropClick={onChangeModal}
          originalData={rulesVisibility}
          onSubmit={(data) => onChangeRules(data)}
        />
      ) : null}
    </Fragment>
  );
};

const EditFields = ({ onBackdropClick, originalData, onSubmit }) => {
  const [editedData, setEditedData] = useState({ ...originalData });

  const onChangeRule = (data) => {
    const { modelName, value } = data;
    const _data = { ...editedData };
    _data[modelName] = value;
    setEditedData(_data);
  };

  const onSave = () => {
    onSubmit(editedData);
    onBackdropClick();
  };

  const renderRules = () => {
    return Object.keys(originalData).map((r) => {
      return <Rule key={r} modelName={r} name={activeRulesJson[r]} active={originalData[r]} callBack={onChangeRule} />;
    });
  };

  return ReactDOM.createPortal(
    <Background onClick={onBackdropClick}>
      <Wrapper width={675} onClick={(e) => e.stopPropagation()}>
        <HeaderModal>
          <Heading>Rules Fields</Heading>
          <span onClick={onBackdropClick}>{icons.close}</span>
        </HeaderModal>
        <ModalText>Turn on switchers of fields you need to use as Rules</ModalText>
        <PaddingBox>
          <RulesColumn>{renderRules()}</RulesColumn>
        </PaddingBox>
        <FoooterModal>
          <Btn hide onClick={onBackdropClick}>
            cancel
          </Btn>
          <Btn width={180} onClick={onSave}>
            Save
          </Btn>
        </FoooterModal>
      </Wrapper>
    </Background>,
    document.getElementById("round-modal")
  );
};

const Rule = ({ name, modelName, active, callBack }) => {
  const [show, setShow] = useState(active);

  const onChange = () => {
    setShow(!show);
    callBack({ modelName, value: !show });
  };
  return (
    <RuleBlock active={show}>
      {icons.menu} {name}{" "}
      <ShowButton active={show} onClick={onChange}>
        <Circle active={show} />
      </ShowButton>
    </RuleBlock>
  );
};
