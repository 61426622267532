import React from "react";
import { DocuSign, FrameBg } from "./styled";

export const DocuSignFrame = ({ url }) => {
  return (
    <FrameBg>
      <DocuSign
        id="docusign-frame"
        src={url}
        onClick={(e) => e.stopPropagation()}
      />
    </FrameBg>
  );
};
