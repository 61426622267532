import styled from "styled-components";

import { CardBase } from "components/common/mixins";
import { Btn } from "components/admin-layout/buttons";

export const Card = styled.div`
  ${CardBase}
  ${({ header = false }) => header && `padding: 0 25px;`}
  align-items: center;
  &:last-child {
    margin-bottom: 0;
  }
  > button {
    width: 140px;
  }
`;

export const Heading = styled.h3`
  font-weight: bold;
  font-size: ${({ size = 14 }) => `${size}px`};
  line-height: 135%;
  color: #181818;
`;

export const Button = Btn;
