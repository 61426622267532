import React, { useState, useEffect } from "react";
import { Main, TextSub } from "../styled";
import { TextFieldComponent } from "../../../../forms";

import { InputRow } from "../Information/styled";
import { IbanError } from "../../styled";

export const BankAccount = ({ onChangeBank, defaultValues }) => {
  const [account, setAccount] = useState({
    ...defaultValues.assetFinancialProperty.account,
  });
  const [error, setError] = useState(false);
  const { accountName, bankName, bic, iban, accountNumber } = account;

  const ibanFormat = /[a-zA-Z]{2,2}[0-9a-zA-Z]{0,}[0-9]{14,14}/;

  const onChangeValues = (e) => {
    const { name, value } = e.target;
    setAccount({ ...account, [name]: value });
  };

  useEffect(() => {
    onChangeBank(account);
  }, [account]);

  useEffect(() => {
    if (iban.length >= 16) {
      setAccount({
        ...account,
        accountNumber: iban.substr(iban.length - 14),
      });
    } else if (iban.length < 16 && accountNumber) {
      setAccount({
        ...account,
        accountNumber: "",
      });
    }

    if (iban && !iban.match(ibanFormat)) {
      setError(true);
    } else if ((error && iban.match(ibanFormat)) || (error && !iban)) {
      setError(false);
    }
  }, [iban]);
  return (
    <Main>
      <TextSub textAlign="left" fontWeight="bold" color="#181818" marginSize="0 0 25px 0">
        Bank Account
      </TextSub>
      <InputRow>
        <TextFieldComponent
          width="calc(100% - 257px)"
          label="Account name"
          placeholder="Account name"
          defaultValue={accountName}
          onChange={onChangeValues}
          name="accountName"
        />
        <TextFieldComponent
          width="227px"
          label="IBan"
          placeholder="XXXX XXXX XXXX XXXX"
          defaultValue={iban}
          name="iban"
          onChange={onChangeValues}
          error={error}
        />
      </InputRow>
      <InputRow last>
        <TextFieldComponent
          width="calc(100% - 257px)"
          placeholder="Short explanation of deal or slogan, up to 150 symbols"
          label="Bank name"
          defaultValue={bankName}
          onChange={onChangeValues}
          name="bankName"
        />
        <TextFieldComponent
          width="227px"
          label="BIC"
          placeholder="XXXX XXXX"
          defaultValue={bic}
          onChange={onChangeValues}
          name="bic"
        />
      </InputRow>
      {error ? (
        <IbanError center>
          Incorrect IBAN format. IBAN should contain minimum 16 characters and last 14 should be numbers.
        </IbanError>
      ) : null}
    </Main>
  );
};
