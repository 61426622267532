import styled from "styled-components";

export const StepsWrapper = styled.section`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const ContainerStep = styled.section`
  position: relative;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  &::after {
    content: "";
    position: absolute;
    left: calc(50% + 16px);
    top: 16px;
    width: ${({ onRight = false }) => (onRight ? "calc(100% - 32px)" : 0)};
    height: 1px;
    background: ${({ theme: { primary } }) => primary};
    transition: width 0.3s linear;
  }
  &::before {
    content: "";
    position: absolute;
    left: calc(50% + 16px);
    top: 16px;
    width: calc(100% - 32px);
    height: 1px;
    background: #efefef;
  }
  &:last-child {
    &::before {
      width: 0;
    }
  }
`;

export const Dot = styled.div`
  position: relative;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 1px solid ${({ active = false, theme: { primary } }) => (active ? primary : "#efefef")};
  padding: ${({ end = false }) => (end ? 0 : "5px")};
  box-sizing: border-box;
  transition: all 0.3s linear;

  &::after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: ${({ active = false, theme: { primary } }) => (active ? primary : "white")};
  }
`;

export const TextSub = styled.p`
  text-align: center;
  font-family: "Muli", sans-serif;
  font-size: 14px;
  display: inline-block;
  font-weight: ${({ fontWeight = "normal" }) => fontWeight};
  color: ${({ color = "#CACACA" }) => color};
  ${({ marginSize = "" }) => (marginSize ? `margin: ${marginSize};` : "")}
`;

export const SlotsWrapper = styled.section`
  width: 100%;
  min-height: 100%;
  overflow-x: hidden;
  height: ${({ h }) => h};
`;

export const Slider = styled.div`
  transform: translateX(-${({ position = 0 }) => position}px);
  transition: transform 0.3s linear;
  width: ${({ width = 0 }) => width}px;
  min-height: 100%;
  display: flex;
`;

export const Slot = styled.div`
  width: ${({ width }) => width}px;
  height: ${({ active = false }) => (active ? "fit-content" : 0)};
  overflow: ${({ active = false }) => (active ? "visible" : "hidden")};
  min-height: 100%;
`;
