import React, { useState, useEffect, Fragment } from "react";
import styled from "styled-components";
import moment from "moment";
import { CircularProgress, Tooltip } from "@material-ui/core";

import { Main, TextSub } from "../../DealForm/Step/styled";
import { FlexRow } from "../../../dashBoardCard";
import { Logo } from "./StatisticTable";

import { EmptyInfo } from "./emptyInfo";
import Api from "helpers/api";
import { ReactComponent as ExpandIcon } from "../../../../../assets/chevron-down.svg";
import { Btn as Button } from "../../../buttons";
import { ErrorMessagePortal } from "components/admin-layout/errorMessage";
import { moutNumber } from "helpers";
import {
  ManualApprove,
  TxInfo,
  Label as WaitingCount
} from "../../DashBoard/styled";

const Row = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  background: ${({ grey, theme: { adminTableRowGrey } }) =>
    grey ? adminTableRowGrey : "#fff"};
  border-radius: 2px;
  padding-left: 15px;
  ${({ pr }) =>
    pr
      ? `padding-right: 33px;
         padding-left: 0;`
      : ``};
  box-sizing: border-box;
`;

export const ScrollContainer = styled.div`
  height: ${({ open }) => (open ? "fit-content" : "245px")};
  min-height: 245px;
  padding-right: ${({ noMargin, isScroll }) =>
    noMargin ? "10px" : isScroll ? "25px" : ""};
  box-sizing: border-box;
  margin: 0 0 20px -15px;
  width: calc(100% + 15px);
  overflow-y: ${({ isScroll = true }) => (isScroll ? "scroll" : "hidden")};

  &&::-webkit-scrollbar-track {
    background-color: ${({ theme: { primaryLight } }) => primaryLight};
    border-radius: 2px;
  }
  &&::-webkit-scrollbar {
    width: 8px;
    border-radius: 2px;
    background-color: ${({ theme: { primaryLight } }) => primaryLight};
  }
  &&::-webkit-scrollbar-thumb {
    background-color: ${({ theme: { primaryMiddle } }) => primaryMiddle};
    border-radius: 2px;
  }
`;

const Label = styled.h6`
  transform: ${({ translate = 0 }) => `translateX(${translate}px)`};
  margin: 0;
  font-size: 10px;
  line-height: 135%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: ${({ blue, theme: { primary } }) =>
    blue ? primary : "rgba(24, 24, 24, 0.6)"};
  width: ${({ w }) => w};
  box-sizing: border-box;
`;

const Ceil = styled.div`
  width: ${({ w }) => w};
  box-sizing: border-box;
  display: flex;
  align-items: center;
  > svg {
    width: 16px;
    height: 16px;
    margin-right: 6px;
  }
`;

const Value = styled.div`
  font-size: 12px;
  line-height: 135%;
  color: #181818;
  font-weight: ${({ weight = 600 }) => weight};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Mail = styled.div`
  font-size: 12px;
  line-height: 150%;
  color: ${({ theme: { primary } }) => primary};
  text-decoration: underline;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
`;

const Btn = styled.button`
  width: 24px;
  height: 24px;
  background: ${({ theme: { infoButtonBg } }) => infoButtonBg};
  > svg {
    path {
      stroke: ${({ theme: { primary } }) => primary};
    }
  }
  border-radius: 2px;
  cursor: pointer;
  border: none;
  outline: none;
  padding: 0;
  display: flex;
  > svg {
    margin: auto;
  }
`;

const Buttons = styled.div`
  padding: 20px 0 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  position: relative;
  &::before {
    content: "";
    display: block;
    width: calc(100% + 50px);
    border-top: 1px solid #f6f7fc;
    position: absolute;
    left: -25px;
    top: 0;
  }
`;

const HeaderButtons = styled.div`
  position: absolute;
  top: 25px;
  right: 25px;
  display: flex;
  > button {
    &:first-child {
      margin-right: 12px;
    }
  }
`;

export const PastTransactions = ({
  token,
  assetId,
  newDeal,
  assetName,
  assetImage,
  assetSymbol
}) => {
  const [primary, setPrimary] = useState(null);
  const [secondary, setSecondary] = useState(null);
  const [wait, setWait] = useState(false);
  const [error, setError] = useState(null);
  const [open, setOpen] = useState(false);
  const [current, setCurrent] = useState("primary");

  const onChangeCurrent = type => () => {
    setOpen(false);
    setCurrent(type);
  };

  const getPrimary = async () => {
    const primary = await Api.getTransaction(token, "primary", assetId);
    if (primary) setPrimary(primary);
  };

  useEffect(() => {
    const getData = async () => {
      const primary = await Api.getTransaction(token, "primary", assetId);
      if (primary) setPrimary(primary);

      const secondary = await Api.getTransaction(token, "secondary", assetId);
      if (secondary) setSecondary(secondary);
    };

    getData();
  }, []);

  const downloadXlsx = async () => {
    setWait(true);
    const res = await Api.getXlsxAssetTransactions(
      token,
      assetId,
      current === "primary" ? "exportPrimary" : "exportSecondary"
    ).catch(() => setWait(false));
    if (res) {
      const { file, fileName } = res;
      const fileURL = URL.createObjectURL(file);
      const link = document.createElement("a");
      link.href = fileURL;
      link.download = fileName;
      link.click();
      setWait(false);
    }
  };

  const onError = (err = null) => () => setError(err);

  const renderTransactions = () => {
    const arr = current === "primary" ? primary : secondary;

    const renderPrimary = () => {
      return arr.map((e, i) => {
        const {
          action,
          counterparty,
          logo,
          quantity,
          time,
          soldShares,
          txHash,
          movementType
        } = e;
        return (
          <Row key={`${txHash}-${action}-primary`} grey={i % 2 ? 0 : 1}>
            <Tooltip title={action} placement="top">
              <Ceil w="280px">
                <Value weight={400}>{action}</Value>
              </Ceil>
            </Tooltip>
            <Ceil w="280px">
              {counterparty ? (
                <Fragment>
                  <Logo token={token} logo={logo} />
                  <Mail>{counterparty}</Mail>
                </Fragment>
              ) : null}
            </Ceil>

            <Tooltip title={quantity.toFixed(2)} placement="top">
              <Ceil w="55px">
                <Value weight="bold"> {moutNumber(quantity.toFixed())}</Value>
              </Ceil>
            </Tooltip>
            <Ceil w="80px">
              <Value weight={400}>
                {soldShares && `${soldShares.toFixed(2)}  %`}
              </Value>
            </Ceil>
            <Ceil w="145px">
              <Value> {moment(time).format("DD/MM/YYYY,hh:mm:ss A")}</Value>
            </Ceil>
            <Ceil w="40px">
              {movementType === "PENDING" && newDeal ? (
                <ManualApprove
                  dealName={assetName}
                  image={assetImage}
                  refresh={getPrimary}
                  token={token}
                  tx={txHash}
                  time={moment(time).format("DD/MM/YYYY")}
                />
              ) : (
                <TxInfo
                  tx={e}
                  token={token}
                  shareName={assetSymbol}
                  image={assetImage}
                />
              )}
            </Ceil>
          </Row>
        );
      });
    };

    const renderSecondary = () => {
      return arr.map(({ action, from, to, quantity, time, txHash }, i) => {
        return (
          <Row key={`${txHash}-${action}-secondary`} grey={i % 2 ? 0 : 1}>
            <Ceil w="6%">
              <Value>{action}</Value>
            </Ceil>
            <Ceil w="250px">
              <Mail>{from}</Mail>
            </Ceil>
            <Ceil w="250px">
              <Mail>{to}</Mail>
            </Ceil>
            <Tooltip title={quantity.toFixed(2)} placement="top">
              <Ceil w="110px">
                <Value weight="bold">{moutNumber(quantity.toFixed())}</Value>
              </Ceil>
            </Tooltip>
            <Tooltip title={txHash} placement="top">
              <Ceil w="110px">
                <Value>{txHash}</Value>
              </Ceil>
            </Tooltip>
            <Ceil w="155px">
              <Value> {moment(time).format("DD/MM/YYYY,hh:mm:ss A")}</Value>
            </Ceil>
          </Row>
        );
      });
    };

    if (arr.length) {
      return (
        <Fragment>
          {current === "primary" ? (
            <Row pr>
              <Label w="280px" blue>
                action
              </Label>
              <Label w="280px" translate={arr.length > 5 ? 2 : 9}>
                User
              </Label>
              <Label w="55px" translate={arr.length > 5 ? 0 : 14}>
                Amount
              </Label>
              <Label w="80px" translate={arr.length > 5 ? 0 : 19}>
                Sold shares
              </Label>
              <Label w="145px" translate={arr.length > 5 ? 0 : 27}>
                Time
              </Label>
              <Label w="40px"></Label>
            </Row>
          ) : (
            <Row pr>
              <Label w="6%" blue>
                action
              </Label>
              <Label w="250px" translate={arr.length > 5 ? 0 : 9}>
                From
              </Label>
              <Label w="250px" translate={arr.length > 5 ? 0 : 14}>
                To
              </Label>
              <Label w="110px" translate={arr.length > 5 ? 0 : 20}>
                Amount
              </Label>
              <Label w="110px" translate={arr.length > 5 ? 0 : 27}>
                TX HASH
              </Label>
              <Label w="155px" translate={arr.length > 5 ? 0 : 34}>
                Time
              </Label>
            </Row>
          )}

          <ScrollContainer open={open} isScroll={arr.length > 5}>
            {current === "primary" ? renderPrimary() : renderSecondary()}
          </ScrollContainer>
          <Buttons>
            <Button
              transparent
              width={180}
              onClick={downloadXlsx}
              disabled={wait}
            >
              download xlsx
            </Button>
            <Button width={180} onClick={onChange} transform={open} rightIcon>
              {open ? "minimize" : "expand"}
              <ExpandIcon />
            </Button>
          </Buttons>
        </Fragment>
      );
    } else {
      return (
        <EmptyInfo
          key={`empty-${current}`}
          heading={`There are no ${current} transactions yet`}
          text="When we’ll have transactions to show for you we’ll show them here"
        />
      );
    }
  };

  const onChange = () => {
    setOpen(!open);
  };

  const waiting = primary
    ? primary.filter(t => t.movementType === "PENDING").length
    : 0;

  return (
    <Main
      minHeight={465}
      currentHeight={open ? "fit-content" : "465px"}
      width="100%"
      padding="20px 25px 25px 25px"
    >
      {primary && secondary && (
        <HeaderButtons>
          <Button
            width={180}
            transparent={current !== "primary"}
            onClick={onChangeCurrent("primary")}
          >
            Primary {waiting ? <WaitingCount count={waiting} /> : null}
          </Button>
          <Button
            width={180}
            transparent={current !== "secondary"}
            onClick={onChangeCurrent("secondary")}
          >
            Secondary
          </Button>
        </HeaderButtons>
      )}

      <FlexRow mBottom="0" align="flex-start">
        <TextSub
          textAlign="left"
          fontWeight="bold"
          color="#181818"
          marginSize="0 0 35px 0"
        >
          {!newDeal ? "Past " : ""}Transactions
        </TextSub>
      </FlexRow>
      {!primary || !secondary ? (
        <CircularProgress size={24} style={{ margin: "auto" }} />
      ) : (
        renderTransactions()
      )}

      {error && <ErrorMessagePortal er={error} onClose={onError(null)} />}
    </Main>
  );
};
