import React, { Fragment, useState, useEffect, useRef } from "react";
import { DragDropContext } from "react-beautiful-dnd";

import {
  Flex,
  Card,
  Button,
  SerchBlock,
  SearchInput,
  Table,
  TRow,
  TCeil,
  BlueText,
  FileWrapper,
} from "./styled";
import { ReactComponent as Plus } from "../../../../../../assets/plus-circle.svg";
import { ReactComponent as SearchIcon } from "../../../../../../assets/search-icon.svg";

import { AddFolder } from "./addNewFolder";
import { AddFile } from "./addNewFile";
import { Folder } from "./folderComponent";
import { ErrorMessage } from "../errorMessage";
import DataRoomApi from "../../../../../helpers/api";
import { getFolderName } from "../helpers";

import { FileViewer, FileViewerStickyWrapper } from "../FileViewer/";

export const FileManager = ({
  token,
  data,
  assetId,
  refreshDocs,
  initialLoad,
  children,
  setDocInfo,
  history,
}) => {
  const [isOpenViewer, toggleOpenViewer] = useState(false);
  const [docData, setDocData] = useState(null);
  const [wait, setWait] = useState(false);
  const [search, setSearch] = useState("");
  const [dealDocs, setDealDocs] = useState({ ...data });
  const [addFolder, setFolder] = useState(false);
  const [addFile, setFile] = useState(false);
  const [errorObj, setError] = useState(null);

  //fix no render
  useEffect(() => {
    setDealDocs({ ...data });
  }, [data]);

  const timerDebounceRef = useRef(null);

  const onChangeAddFolder = () => {
    setFolder(!addFolder);
  };

  const onAddNewFolder = async () => {
    setWait(true);
    const folderName = getFolderName(dealDocs.folders, "New folder");
    const res = await DataRoomApi.createNewFolder(
      token,
      folderName,
      assetId
    ).catch((e) => {
      setWait(false);
      setError(e);
    });

    if (res) {
      setWait(false);
      refreshDocs();
      setTimeout(() => {
        window.scrollTo(0, document.body.scrollHeight);
      }, 1000);
    }
  };

  const onChangeAddFile = () => {
    setFile(!addFile);
  };

  const onCreateNewFolder = () => {
    onChangeAddFolder();
    refreshDocs();
  };

  useEffect(() => {
    toggleOpenViewer(!!docData);
  }, [docData]);
  const onToggleOpenViewer = (data) => {
    setDocData(data);
  };

  const {
    dealInfo: { active },
    folders,
  } = dealDocs;

  const onSearchInput = (e) => {
    const {
      target: { value },
    } = e;
    setSearch(value);
  };

  useEffect(() => {
    if (!initialLoad) {
      clearTimeout(timerDebounceRef.current);
      timerDebounceRef.current = setTimeout(async () => {
        let searchResult = null;
        if (search) {
          searchResult = await DataRoomApi.searchFileOrFolder(
            token,
            assetId,
            search
          );
        } else {
          searchResult = await DataRoomApi.getDealsFiles(token, assetId);
        }
        if (searchResult) setDealDocs(searchResult);
      }, 500);
    }
  }, [search]);

  const renderFolders = () => {
    const random = Math.round(Math.random() * 1000) + "";
    return (
      <Fragment key={`folder-${random}`}>
        {folders.map((e, i) => (
          <Folder
            history={history}
            folder={e}
            key={`${e.name}-${i}-${random}`}
            token={token}
            assetId={assetId}
            onChange={refreshDocs}
            onOpenFile={(...args) =>
              console.log(...args) || onToggleOpenViewer(...args)
            }
            isOpenViewer={isOpenViewer}
            setDocInfo={setDocInfo}
          />
        ))}
      </Fragment>
    );
  };

  const onDragEnd = (dealDocs) => async (result) => {
    const {
      source: { droppableId: from, index },
      destination: { droppableId: to, index: subsection },
    } = result;

    // need to refactoring

    const _from = from.match(/^[^\/]+/g)[0];
    const _to = to.match(/^[^\/]+/g)[0];

    // need to refactoring

    if (_from === "Legal Documents" || _to === "Legal Documents") return;

    const _dealDocs = { ...dealDocs };
    const prevDealDocs = JSON.stringify(dealDocs);

    const docIndex = _dealDocs.folders.findIndex((e) => e.name === _from);
    const toIndex = _dealDocs.folders.findIndex((e) => e.name === _to);

    const doc = { ..._dealDocs.folders[docIndex].files[index] };

    _dealDocs.folders[docIndex].files.splice(index, 1);
    _dealDocs.folders[toIndex].files.splice(subsection, 0, doc);
    setDealDocs(_dealDocs);

    const res = await DataRoomApi.moveFile(
      token,
      doc.name,
      _from,
      _to,
      subsection,
      assetId
    ).catch((e) => {
      setDealDocs(JSON.parse(prevDealDocs));
      setTimeout(() => {
        setError(e);
      }, 500);
    });

    if (res) {
      refreshDocs();
    }
  };

  return (
    <Fragment key="wrapper-files">
      <Flex mb={20}>
        <Card width={active ? "100%" : "calc(100% - 240px)"}>
          <SerchBlock>
            <SearchIcon />
            <SearchInput
              placeholder="Search files or folders"
              value={search}
              onChange={(e) => onSearchInput(e)}
            />
          </SerchBlock>
          <Flex width="412px" p="0 20px" mb="0" p="0 20px">
            <Button transparent="1" onClick={onAddNewFolder} disabled={wait}>
              <Plus />
              NEW FOLDER
            </Button>
            <Button onClick={onChangeAddFile}>
              <Plus />
              NEW FILES
            </Button>
          </Flex>
        </Card>
        {!active ? (
          <Card width="220px" p="0 20px">
            <Button>Publish asset</Button>
          </Card>
        ) : null}
      </Flex>

      <FileWrapper isOpen={isOpenViewer}>
        <div>
          <Table>
            <TRow noHover>
              <TCeil big>
                <BlueText>Description</BlueText>
              </TCeil>
              <TCeil>Files</TCeil>
              {isOpenViewer ? null : (
                <Fragment>
                  <TCeil>Type</TCeil>
                  <TCeil>Size</TCeil>
                  <TCeil middle>Last update</TCeil>
                </Fragment>
              )}
            </TRow>
            <DragDropContext onDragEnd={onDragEnd(dealDocs)}>
              {renderFolders()}
            </DragDropContext>

            {children}
            {addFolder ? (
              <AddFolder
                callBack={onCreateNewFolder}
                assetId={assetId}
                token={token}
                onClose={onChangeAddFolder}
              />
            ) : null}
            {addFile ? (
              <AddFile
                onBackdropClick={onChangeAddFile}
                assetId={assetId}
                token={token}
                refreshDocs={refreshDocs}
                folders={dealDocs.folders.map((f) => f.name)}
              />
            ) : null}
            {errorObj ? (
              <ErrorMessage er={errorObj} onClose={() => setError(null)} />
            ) : null}
          </Table>
        </div>
        {isOpenViewer && docData ? (
          <FileViewerStickyWrapper>
            <FileViewer
              token={token}
              assetId={assetId}
              file={docData}
              onClose={() => setDocData(null)}
            />
          </FileViewerStickyWrapper>
        ) : null}
      </FileWrapper>
    </Fragment>
  );
};
