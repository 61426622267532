import React, { useState, Fragment, useEffect } from "react";
import ReactDOM from "react-dom";

import styled from "styled-components";

import { ReactComponent as CloseIcon } from "../../assets/x-circle.svg";
import { ReactComponent as SuspendIcon } from "../../assets/slash.svg";
import { ReactComponent as CheckIcon } from "../../assets/check.svg";
import { icons } from "models/icons";

const Background = styled.section`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: ${({ bg = "rgba(0, 0, 0, 0.15)" }) => bg};
  backdrop-filter: blur(5px);
  display: flex;
  z-index: ${({ zIndex }) => (zIndex ? zIndex : 1000)};
`;

const Wrapper = styled.div`
  width: ${({ width = false }) => (width ? width : "fit-content")};
  min-width: 400px;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  background: ${({ bg = "#ffffff" }) => bg};
  border: 1px solid ${({ borderColor = "#f9f9f9" }) => borderColor};
  box-sizing: border-box;
  box-shadow: 0px 10px 40px rgba(89, 120, 150, 0.06);
  border-radius: 4px;
  margin: auto;
`;

const Header = styled.div`
  height: 52px;
  width: 100%;
  padding: 0 25px;
  background: #ffffff;
  border: 1px solid #f9f9f9;
  box-sizing: border-box;
  box-shadow: 0px 10px 40px rgba(89, 120, 150, 0.08);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  > svg {
    cursor: pointer;
    width: 24px;
    height: 24px;
  }
`;

const Content = styled.div`
  width: 100%;
  padding: 25px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: ${({ align = "center" }) => align};
`;

const Heading = styled.h5`
  font-family: Muli;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 135%;
  margin: 0;
  color: #181818;
  text-transform: none;
`;

const Text = styled.p`
  text-transform: none;
  margin: ${({ noMargin, margin }) => (noMargin ? 0 : margin ? margin : "15px 0 30px")};
  font-family: Muli;
  font-style: normal;
  color: #333333;
  font-weight: ${({ bold }) => (bold ? 600 : "normal")};
  font-size: ${({ fSize }) => (fSize ? `${fSize}px` : "18px")};
  line-height: 150%;
  text-align: ${({ textAlign = "center" }) => textAlign};
`;

const TextApprove = styled.p`
  font-weight: bold;
  font-size: 16px;
  line-height: 135%;
  text-align: center;
  letter-spacing: -0.02em;
  color: #192036;
  text-transform: none;
  margin: ${({ noMargin, margin }) => (noMargin ? 0 : margin ? margin : "15px 0 30px")};
  font-family: Muli;
  font-style: normal;
  font-weight: ${({ bold }) => (bold ? 700 : "normal")};
  font-size: ${({ fSize }) => (fSize ? `${fSize}px` : "18px")};
  text-align: ${(textAlign = "center") => textAlign};
`;

const Button = styled.button`
  margin: ${({ margin = false }) => (margin ? margin : "")};
  outline: none;
  width: ${({ width = 170 }) => `${width}px`};
  height: 32px;
  background: ${({ grey, green, red, theme: { primary } }) =>
    grey ? "transparent" : green ? "#019D52" : red ? "#ff4343" : primary};
  border-radius: 4px;
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: ${({ noLineHeight = false }) => (noLineHeight ? "135%" : "")};
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  padding: 0;
  color: ${({ grey }) => (grey ? "#CACACA" : "#ffffff")};
  box-sizing: border-box;
  border: 1px solid
    ${({ grey, green, red, theme: { primary } }) => (grey ? "#CACACA" : green ? "#019D52" : red ? "#ff4343" : primary)};
  cursor: pointer;
  > svg {
    ${({ red, green }) => {
      if (red || green) {
        return `margin-right:7px;
                path{
                  stroke: #fff;
                }`;
      } else return "";
    }}
  }
  &:disabled {
    background: #e3e3e3 !important;
    color: white !important;
    border: none;
    > svg {
      path {
        stroke: #fff;
      }
    }
  }
`;

const Flex = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: ${({ jc = false }) => (jc ? jc : "space-between")};
`;

const BtnBlock = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ImgContainer = styled.div`
  svg {
    width: 488px;
    height: 180px;
  }
`;

const IconCont = styled.div`
  margin-left: 7px;
  transform: rotate(180deg);
  display: flex;
  svg {
    margin: auto;
    path {
      stroke: #fff;
    }
  }
`;

const CardContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
`;

const Card = styled.div`
  width: calc(50% - 10px);
  height: 245px;
  border: 1px solid ${({ active, theme: { primary } }) => (active ? primary : "#EFEFEF")};
  box-sizing: border-box;
  border-radius: 4px;
  cursor: pointer;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 0.1s linear;
  position: relative;
  &::before {
    transition: all 0.1s linear;
    content: "";
    width: 20px;
    height: 20px;
    background: #ffffff;
    border: 1px solid ${({ active, theme: { primary } }) => (active ? primary : "#EFEFEF")};
    position: absolute;
    bottom: -10px;
    box-sizing: border-box;
    border-radius: 50%;
  }
  &::after {
    content: "";
    transition: all 0.1s linear;
    position: absolute;
    bottom: -6px;
    width: 12px;
    height: 12px;
    background: ${({ active, theme: { primary } }) => (active ? primary : "#fff")};
    /* border: 1px solid ${({ theme: { primary } }) => primary}; */
    box-sizing: border-box;
    border-radius: 50%;
  }
`;

const IconBg = styled.div`
  width: 100%;
  height: 160px;
  background: ${({ theme: { quoteBgInvestor } }) => quoteBgInvestor};
  border-radius: 4px;
  display: flex;
  margin-bottom: 14px;
  > svg {
    margin: auto;
    width: 62px;
    height: 62px;
    transition: all 0.1s linear;
    path {
      stroke: ${({ active, theme: { primary } }) => (active ? primary : "#A2B1DE")};
    }
  }
`;

export const ErrorMessage = ({ onClose, er: { message } }) => {
  return ReactDOM.createPortal(
    <Background onClick={onClose}>
      <Wrapper onClick={(e) => e.stopPropagation()}>
        <Header>
          <Heading>Error</Heading>
          <CloseIcon onClick={onClose} />
        </Header>
        <Content>
          <Text fSize={12}>{message.replace("java.lang.IllegalArgumentException: ", "")}</Text>
          <Button width={200} height={36} onClick={onClose}>
            CLOSE
          </Button>
        </Content>
      </Wrapper>
    </Background>,
    document.getElementById("globalModal500Error")
  );
};

export const ErrorMessagePortal = ({ onClose, er: { error, message }, width }) => {
  return ReactDOM.createPortal(
    <Background onClick={onClose}>
      <Wrapper width={width} onClick={(e) => e.stopPropagation()}>
        <Header>
          <Heading>{error}</Heading>
          <CloseIcon onClick={onClose} />
        </Header>
        <Content>
          <Text
            fSize={12}
            dangerouslySetInnerHTML={{ __html: message.replace("java.lang.IllegalArgumentException: ", "") }}
          />
          <Button width={200} height={36} onClick={onClose}>
            CLOSE
          </Button>
        </Content>
      </Wrapper>
    </Background>,
    document.getElementById("globalModal500Error")
  );
};

export const SuccessMessage = ({ onClose, message }) => (
  <Background onClick={onClose}>
    <Wrapper onClick={(e) => e.stopPropagation()}>
      <Header>
        <Heading>Success</Heading>
        <CloseIcon onClick={onClose} />
      </Header>
      <Content>
        <Text fSize={12}>{message}</Text>
        <Button width={200} height={36} onClick={onClose}>
          Ok
        </Button>
      </Content>
    </Wrapper>
  </Background>
);

export const ConfirmMessagePortal = ({
  onClose,
  onSubmit,
  message,
  heading,
  load = false,
  confirmText = "Confirm",
  red = false,
}) => {
  return ReactDOM.createPortal(
    <Background onClick={onClose}>
      <Wrapper onClick={(e) => e.stopPropagation()}>
        <Header>
          <Heading>{heading}</Heading>
          <CloseIcon onClick={onClose} />
        </Header>
        <Content>
          <Text fSize={12} dangerouslySetInnerHTML={{ __html: message }} />
          <Flex>
            <Button width={100} height={36} onClick={onClose} grey>
              CLOSE
            </Button>
            <Button height={36} onClick={onSubmit} disabled={load} red={red}>
              {confirmText}
            </Button>
          </Flex>
        </Content>
      </Wrapper>
    </Background>,
    document.getElementById("globalModal500Error")
  );
};

export const ApproveRequestPortal = ({ onClose, onSubmit, message, heading, load = false }) => {
  return ReactDOM.createPortal(
    <Background onClick={onClose}>
      <Wrapper onClick={(e) => e.stopPropagation()}>
        <Header>
          <Heading>{heading}</Heading>
          <CloseIcon onClick={onClose} />
        </Header>
        <Content>
          <TextApprove dangerouslySetInnerHTML={{ __html: message }} noMargin />
          <Text
            fSize={12}
            dangerouslySetInnerHTML={{
              __html: `After the validation an email to set-up the <br/>password will be sent`,
            }}
          />
          <BtnBlock>
            <Button noLineHeight width={94} height={32} onClick={onClose} grey margin="0 12px 0 0">
              cancel
            </Button>
            <Button noLineHeight width={180} height={32} onClick={onSubmit} disabled={load} green>
              <CheckIcon />
              Yes, approve
            </Button>
          </BtnBlock>
        </Content>
      </Wrapper>
    </Background>,
    document.getElementById("globalModal500Error")
  );
};

export const RejectRequestPortal = ({ onClose, onSubmit, message, heading, load = false }) => {
  return ReactDOM.createPortal(
    <Background onClick={onClose}>
      <Wrapper onClick={(e) => e.stopPropagation()}>
        <Header>
          <Heading>{heading}</Heading>
          <CloseIcon onClick={onClose} />
        </Header>
        <Content>
          <TextApprove dangerouslySetInnerHTML={{ __html: message }} />
          <BtnBlock>
            <Button noLineHeight width={94} height={32} onClick={onClose} grey margin="0 12px 0 0">
              cancel
            </Button>
            <Button noLineHeight width={180} height={32} onClick={onSubmit} disabled={load} red>
              <SuspendIcon />
              Yes, reject
            </Button>
          </BtnBlock>
        </Content>
      </Wrapper>
    </Background>,
    document.getElementById("globalModal500Error")
  );
};

export const GuidePortal = ({ onClose, name, header, message, img }) => {
  return ReactDOM.createPortal(
    <Background onClick={onClose}>
      <Wrapper onClick={(e) => e.stopPropagation()} width="540px">
        <Header>
          <Heading>{name}</Heading>
          <CloseIcon onClick={onClose} />
        </Header>
        <Content align="start">
          <Text margin="5px 0 15px 0" bold fSize={16}>
            {header}
          </Text>
          <ImgContainer>{img}</ImgContainer>
        </Content>
      </Wrapper>
    </Background>,
    document.getElementById("globalModal500Error")
  );
};

export const CategorySelectorPortal = ({ onClose, history, assetId }) => {
  const [type, setType] = useState("elective");

  const onChangeType = (type) => () => setType(type);

  const onSubmit = () => history.push(`/investor/offerings/${assetId}/${type}/client-categorization`);

  return ReactDOM.createPortal(
    <Background onClick={onClose}>
      <Wrapper onClick={(e) => e.stopPropagation()} width="440px">
        <Header>
          <Heading>Client Categorization</Heading>
          <CloseIcon onClick={onClose} />
        </Header>
        <Content>
          <Text fSize={16} bold margin="0 0 24px">
            Please choose what client are you
          </Text>
          <CardContainer>
            <Card active={type === "elective"} onClick={onChangeType("elective")}>
              <IconBg active={type === "elective"}>{icons.userCheck}</IconBg>
              <Text fSize={14} bold margin="0">
                Elective Professional Client
              </Text>
            </Card>
            <Card active={type === "professional"} onClick={onChangeType("professional")}>
              <IconBg active={type === "professional"}>{icons.userPlus}</IconBg>{" "}
              <Text fSize={14} bold margin="0">
                Professional <br /> Client
              </Text>
            </Card>
          </CardContainer>
          <Flex>
            <Button width={94} height={36} onClick={onClose} grey>
              CLOSE
            </Button>
            <Button height={36} width={266} onClick={onSubmit}>
              Confirm & Next <IconCont>{icons.left}</IconCont>
            </Button>
          </Flex>
        </Content>
      </Wrapper>
    </Background>,
    document.getElementById("globalModal500Error")
  );
};

export const UpdateEmailSettingsPortal = ({ onClose, error }) => {
  return ReactDOM.createPortal(
    <Background onClick={onClose}>
      <Wrapper onClick={(e) => e.stopPropagation()}>
        <Header>
          <Heading>{error ? "Unsuccess" : "Success"}</Heading>
          <CloseIcon onClick={onClose} />
        </Header>
        <Content>
          {error ? (
            <Fragment>
              <Text
                noMargin
                fSize={14}
                dangerouslySetInnerHTML={{
                  __html: `The email integration has been unsuccessful.`,
                }}
              />
              <Text
                fSize={12}
                dangerouslySetInnerHTML={{
                  __html: `Please check that your credentials are right or with your
                          <br/> 
                          email provider if you are allowed to perform such an action.`,
                }}
              />
            </Fragment>
          ) : (
            <Fragment>
              <Text
                noMargin
                fSize={14}
                dangerouslySetInnerHTML={{
                  __html: `A test email has just been sent to your account.<br/>
                   Please open the email to make sure 
                    the integration <br/> has been successful.`,
                }}
              />
              <Text
                fSize={12}
                dangerouslySetInnerHTML={{
                  __html: `In case you didn’t receive this email, please check 
                  <br/> with you email provider or contact 
                  <br/> us at <u>contact@valktech.io</u>`,
                }}
              />
            </Fragment>
          )}
          <Flex jc="center">
            <Button width={100} height={36} onClick={onClose} green={!error} red={error}>
              CLOSE
            </Button>
          </Flex>
        </Content>
      </Wrapper>
    </Background>,
    document.getElementById("globalModal500Error")
  );
};

export const ModalPortal = ({
  onClose,
  onSubmit,
  heading,
  load = false,
  submitText = "Ok",
  red = false,
  children,
  width,
  noCancel = false,
  disableBackdrop = false,
}) => {
  useEffect(() => {
    document.body.style.overflowY = "hidden";

    return () => (document.body.style.overflowY = "auto");
  }, []);
  return ReactDOM.createPortal(
    <Background onClick={disableBackdrop ? undefined : onClose}>
      <Wrapper width={width} onClick={(e) => e.stopPropagation()}>
        <Header>
          <Heading>{heading}</Heading>
          <CloseIcon onClick={onClose} />
        </Header>
        <Content>
          {children}
          <Flex>
            <Button
              style={{ visibility: noCancel ? "hidden" : "visible" }}
              width={100}
              height={36}
              onClick={onClose}
              grey
            >
              CLOSE
            </Button>
            <Button height={36} onClick={onSubmit} disabled={load} red={red}>
              {submitText}
            </Button>
          </Flex>
        </Content>
      </Wrapper>
    </Background>,
    document.getElementById("globalModal500Error")
  );
};
