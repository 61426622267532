import React, { Fragment, useState, useEffect, useRef } from "react";
import { CircularProgress } from "@material-ui/core";

import {
  HeatTableWrapper,
  SearchInputUsers,
  HeaderRow,
  HeatFilter,
  BorderFlex,
  HeatRow,
  HeatCeil,
  CeilHeadingBlue,
  FolderName,
  CenterContainer,
  ScrollYContainer,
} from "./styled";
import { Btn } from "components/admin-layout/buttons";
import { HeatProgress, User } from "../styled";
import { ReactComponent as SearchIcon } from "../../../../../../assets/search-icon.svg";
import { ReactComponent as ArrowIcon } from "../../../../../../assets/chevron-down-b.svg";
import DataRoomApi from "../../../../../helpers/api";
import { ErrorMessage } from "../errorMessage";

export const HeatMap = ({ token, assetId }) => {
  const [data, setData] = useState(null);
  const [period, setPeriod] = useState(30);
  const [type, setType] = useState("users");
  const [search, setSearch] = useState("");
  const [errorObj, setError] = useState(null);
  const timerDebounceRef = useRef(null);
  const tableRef = useRef(null);
  const scrollContainerRef = useRef(null);
  const [tableWidth, setTableWidth] = useState(0);
  const [initialLoad, setInitialLoad] = useState(true);

  const getData = async () => {
    const _data = await DataRoomApi.getHeatMap(token, assetId, period, type).catch((e) => setError(e));

    if (_data) setData(_data);
  };

  useEffect(() => {
    getData();
    setInitialLoad(false);
  }, []);

  useEffect(() => {
    if (tableRef && tableRef.current && !tableWidth) {
      setTableWidth(tableRef.current.offsetWidth);
      console.log(tableRef.current.offsetWidth);
    }
  });

  useEffect(() => {
    if (search) setSearch("");
    if (data && !initialLoad) getData();
  }, [period, type]);

  const onChangePeriod = (per) => () => {
    setPeriod(per);
  };

  const onChangeType = (type) => () => {
    setType(type);
  };

  const getMaxTime = () => {
    let max = 0;

    data.forEach((f) => {
      if (f.users.length) {
        f.users.forEach((u) => {
          if (u.totalTime > max) {
            max = u.totalTime;
          }
        });
      }
    });

    return max;
  };

  const onSearch = async (e) => {
    const {
      target: { value },
    } = e;
    setSearch(value);
  };

  useEffect(() => {
    if (!initialLoad) {
      clearTimeout(timerDebounceRef.current);
      timerDebounceRef.current = setTimeout(async () => {
        let searchResult = null;
        if (search) {
          searchResult = await DataRoomApi.searchHeatMap(token, assetId, period, type, search).catch((e) =>
            setError(e)
          );
        } else {
          searchResult = await DataRoomApi.getHeatMap(token, assetId, period, type).catch((e) => setError(e));
        }
        if (searchResult) setData(searchResult);
      }, 500);
    }
  }, [search]);

  const renderRow = () => {
    const percent = getMaxTime() / 100;
    const random = Math.round(Math.random() * 1000);
    const _users = data.length ? data[0].users : [];
    return _users.map(({ fullName, logo, userName }, i) => (
      <HeatRow key={`user-${fullName}-${i}-${random}`}>
        <HeatCeil key={`${userName}-${fullName}`}>
          <User token={token} user={{ logo, name: fullName }} />
        </HeatCeil>
        {data.map(({ folderName, users }, i) => {
          const _user = users.find((el) => el.userName === userName);
          if (_user) {
            return (
              <HeatCeil key={`${folderName}-${userName}-${i}`}>
                <HeatProgress containerRef={scrollContainerRef} user={_user} progress={_user.totalTime / percent} />
              </HeatCeil>
            );
          } else return null;
        })}
      </HeatRow>
    ));
  };

  const renderTableHead = () => {
    return (
      <HeatRow>
        <HeatCeil>
          <CeilHeadingBlue>
            User <ArrowIcon />
          </CeilHeadingBlue>
        </HeatCeil>
        {data.map(({ folderName }, i) => (
          <HeatCeil>
            <FolderName key={`${folderName}=${i}`}>{folderName}</FolderName>
          </HeatCeil>
        ))}
      </HeatRow>
    );
  };

  if (data) {
    return (
      <Fragment>
        <HeaderRow>
          <SearchInputUsers placeholder="Search by folder" value={search} onChange={onSearch} />
          <SearchIcon />
          <HeatFilter>
            <BorderFlex>
              <Btn transparent={period !== 30} onClick={onChangePeriod(30)}>
                Month
              </Btn>
              <Btn transparent={period !== 183} onClick={onChangePeriod(183)}>
                6 Month
              </Btn>
              <Btn transparent={period !== 365} onClick={onChangePeriod(365)}>
                Year
              </Btn>
            </BorderFlex>
            <BorderFlex>
              <Btn width={120} transparent={type !== "users"} onClick={onChangeType("users")}>
                Users
              </Btn>
              <Btn width={120} transparent={type !== "groups"} onClick={onChangeType("groups")}>
                Groups
              </Btn>
            </BorderFlex>
          </HeatFilter>
        </HeaderRow>

        <ScrollYContainer
          key={`scrollconatainer-${tableWidth}`}
          isScroll={tableWidth > window.innerWidth - 293}
          ref={scrollContainerRef}
        >
          <HeatTableWrapper ref={tableRef}>
            {renderTableHead()}
            {renderRow()}
          </HeatTableWrapper>
        </ScrollYContainer>
        {errorObj ? <ErrorMessage er={errorObj} onClose={() => setError(null)} /> : null}
      </Fragment>
    );
  } else {
    return (
      <CenterContainer>
        <CircularProgress />
      </CenterContainer>
    );
  }
};
