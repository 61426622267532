import React, { Fragment, useState, useCallback, useEffect, useLayoutEffect, useRef } from "react";
import { TextH3 } from "../Step/styled";
import { Row, HtmlContainer, BtnWrapper, InputRow } from "../Step/Information/styled";
import { TextFieldComponent, TextEditorComponent } from "../../../forms";
import { Btn } from "../../../buttons";
import { ReactComponent as PlusIcon } from "../../../../../assets/plus-circle.svg";
import { ExpandedPanel } from "./ExpandedPanel";

function createHTML(__html) {
  return { __html };
}

export const AdditionalInformation = (props) => {
  const [blocks, setBlocks] = useState(props.blocks || []);
  const [draftInfo, setChangeDraft] = useState(null);
  const draftRef = useRef({ header: "", text: "" });
  const [editIndex, setEditIndex] = useState(null);
  const [fullIndex, setFullIndex] = useState(null);

  useEffect(() => {
    props.onChange && props.onChange("assetProperties", "vocAssetDynamicProperties")(blocks);
  }, [blocks]);

  const addNewBlock = () => {
    let _blocks = [...blocks];
    if (draftInfo) {
      _blocks = _blocks.map((_, i) => {
        if (i === editIndex) return { ...draftInfo, newBlock: false };
        else return _;
      });
    }
    setBlocks([..._blocks, { header: "", text: "", newBlock: true }]);
    draftRef.current = { header: "", text: "", newBlock: true };
    setChangeDraft({ header: "", text: "", newBlock: true });
    setEditIndex(_blocks.length);
  };

  const onChangeDraftHeader = (e) => {
    draftRef.current = {
      ...draftRef.current,
      header: e.target ? e.target.value : e,
    };
    setChangeDraft({ ...draftRef.current });
  };

  const onChangeDraftText = (e) => {
    draftRef.current = {
      ...draftRef.current,
      text: e.target ? e.target.value : e,
    };
    setChangeDraft({
      ...draftRef.current,
    });
  };

  const onSave = () => {
    setBlocks(
      blocks.map((_, i) => {
        if (i === editIndex) return { ...draftInfo, newBlock: false };
        else return _;
      })
    );
    setChangeDraft(null);
    draftRef.current = null;
    setEditIndex(null);
  };

  const onCancel = () => {
    if (blocks[editIndex].newBlock) {
      setBlocks(blocks.filter((_, i) => i !== editIndex));
    }
    setChangeDraft(null);
    draftRef.current = null;
    setEditIndex(null);
  };

  const onFull = (index) => () => {
    if (index === fullIndex) setFullIndex(null);
    else setFullIndex(index);
  };

  const onDelete = (index) => () => {
    if (draftInfo) {
      let newEditIndex = index <= editIndex ? editIndex - 1 : editIndex;
      setEditIndex(newEditIndex);
    }
    setBlocks(blocks.filter((_, i) => index !== i));
  };

  const onEdit = (index) => () => {
    let _blocks = [...blocks];
    if (draftInfo) {
      _blocks = _blocks.map((_, i) => {
        if (i === editIndex) return { ...draftInfo, newBlock: false };
        else return _;
      });

      setBlocks(_blocks);
    }

    draftRef.current = { ..._blocks.find((_, i) => i === index) };
    setChangeDraft({ ...draftRef.current });
    setEditIndex(index);
  };

  return (
    <ExpandedPanel
      buttonExpand={props.buttonExpand}
      header="Additional Information"
      key={`block-counter-key-${blocks.length}-${editIndex}-${fullIndex}`}
    >
      {blocks.map(({ header, text, newBlock = false }, i) => {
        if (i === editIndex && draftInfo) {
          const { text, header } = draftRef.current;
          return (
            <Fragment key={`key-editable-${i}`}>
              <InputRow>
                <TextFieldComponent
                  label={`Block#${i + 1} header`}
                  placeholder="Write your block header here"
                  defaultValue={header}
                  onChange={onChangeDraftHeader}
                />
              </InputRow>
              <InputRow>
                <TextEditorComponent
                  label={`Block#${i + 1} content`}
                  placeholder="Content of block goes here"
                  defaultValue={text}
                  onChange={onChangeDraftText}
                />
              </InputRow>
              <Row mBottom={i !== blocks.length - 1}>
                <Btn white onClick={onCancel}>
                  cancel
                </Btn>
                {newBlock ? (
                  <Btn
                    onClick={onSave}
                    disabled={
                      !((text.replace(/<[^>]*>/g, "") || text.includes("<img") || text.includes("<figure")) && header)
                    }
                  >
                    save & add
                  </Btn>
                ) : (
                  <Btn
                    onClick={onSave}
                    disabled={
                      !((text.replace(/<[^>]*>/g, "") || text.includes("<img") || text.includes("<figure")) && header)
                    }
                  >
                    save
                  </Btn>
                )}
              </Row>
            </Fragment>
          );
        } else {
          return (
            <Fragment key={`key-${header.replace(/\s+/g, "-")}-${i}`}>
              <TextH3 size={16} textAlign="left" marginSize="0 0 16px 0">
                {header}
              </TextH3>
              <HtmlContainer dangerouslySetInnerHTML={createHTML(text)} full={i === fullIndex} />
              <Row mBottom={i !== blocks.length - 1}>
                <Btn white onClick={onFull(i)}>
                  full
                </Btn>
                <BtnWrapper>
                  <Btn onClick={onDelete(i)} white>
                    Delete
                  </Btn>
                  <Btn onClick={onEdit(i)}>edit</Btn>
                </BtnWrapper>
              </Row>
            </Fragment>
          );
        }
      })}
      <Row>
        <span />
        <Btn width={215} onClick={addNewBlock} disabled={draftInfo && !(draftInfo.text && draftInfo.header)}>
          <PlusIcon /> add new block
        </Btn>
        <span />
      </Row>
    </ExpandedPanel>
  );
};
