import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";
import moment from "moment";

import { Header, Main, TextSub } from "../DealForm/Step/styled";
import { Btn } from "../../buttons";
import { ReactComponent as ArrowLeft } from "../../../../assets/arrowleft.svg";
import { ReactComponent as Check } from "../../../../assets/check.svg";
import { UserName, Status, Field, LoadWrapper, AmName } from "./styled";

import { ReactComponent as MessageIcon } from "../../../../assets/message-square.svg";
import { ReactComponent as SuspendIcon } from "../../../../assets/slash.svg";
import { Wrapper, Column, Separator } from "../CreateUser/styled";
import { InfoCol, FlexRow, Notes } from "../UserInfo/styled";
import { SendEmail } from "../UserList/SendEmail";

import Api from "./api";
import ApiForm from "../CreateKycForm/api";
import MainApi from "helpers/api";
import { ErrorMessagePortal, SuccessMessage } from "components/admin-layout/errorMessage";
import { CircularProgress } from "@material-ui/core";
import { updateAdminNotifications } from "modules/dataRedux/dataAction";

export const UserKYC = ({
  history,
  token,
  match: {
    params: { id },
  },
  updateAdminNotifications,
}) => {
  const [user, setUser] = useState(null);
  const [form, setForm] = useState(null);
  const [load, setLoad] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState("");
  const [answers, setAnswers] = useState({});
  const [am, setAm] = useState("");

  const changeModalMode = () => setModalOpen(!modalOpen);

  const onNotesChange = (e) => {
    const val = e.target.value;
    setForm({ ...form, note: val });
  };

  const getInfo = async () => {
    const info = await Api.getInvestorAnswersByID(token, id).catch(onError);

    if (info) {
      const answers = await ApiForm.getForm(token, info.idForm).catch(onError);
      if (answers) {
        setForm({
          answers: JSON.parse(info.answerJson),
          status: info.status,
          submitted: info.submitted,
          note: info.note,
        });
        setUser(info.user);
        setAnswers(answers.rightAnswers);
        setAm(info.amname);
        setLoad(false);
      }
    }
  };

  useEffect(() => {
    getInfo();

    return async () => {
      const processingKyc = await MainApi.getInvestorAnswers(token, 1, 1000, "PROCESSING");
      if (processingKyc) {
        updateAdminNotifications(processingKyc.content.length, "KYC");
      }
    };
  }, []);

  const onSetStatus = (action) => async () => {
    setLoad(true);

    const res = await Api.setUserFormStatus(token, action, form.note || "", id).catch(onError);

    if (res) {
      setSuccess(res);
      setLoad(false);
    }
  };

  const renderSections = (arr, odd = true) => {
    const _arr = arr.filter((_, i) => (odd ? i % 2 === 1 : !(i % 2)));
    const renderFields = (fields) =>
      fields.map((f, i) => <Field token={token} userId={user.id} rightAnswers={answers} key={f.id} i={i} field={f} />);
    return _arr.map(({ sectionName, fields }, i) => (
      <Main key={sectionName} margin={`0 0 ${i !== arr.length - 1 ? "20px" : 0}`}>
        <TextSub textAlign="left" fontWeight="bold" color="#181818" marginSize="0 0 20px 0">
          {sectionName}
        </TextSub>
        {renderFields(fields)}
      </Main>
    ));
  };

  const onError = (e) => {
    setLoad(false);
    setError(e);
  };

  const onSuccess = async () => {
    setLoad(true);
    setSuccess("");
    await getInfo();
  };

  if (user && form) {
    const { fullName, username } = user;
    const { submitted, status, answers, note, checked } = form;
    return (
      <Fragment>
        {load && (
          <LoadWrapper>
            <CircularProgress style={{ margin: "auto" }} />
          </LoadWrapper>
        )}
        <SendEmail
          isOpen={modalOpen}
          token={token}
          onBackdropClick={changeModalMode}
          mailData={{ userName: fullName, userEmail: username }}
        />
        <Header>
          <Btn transparent onClick={() => history.push("/admin/kyc/list")} width={150}>
            <ArrowLeft /> back to users
          </Btn>
          <UserName>{fullName}</UserName>
        </Header>
        <Wrapper>
          <Column width="calc(100% - 355px)">
            <Main>
              <TextSub textAlign="left" fontWeight="bold" color="#181818" marginSize="0 0 20px 0">
                Status
              </TextSub>
              <FlexRow>
                <InfoCol width={20} heading="Status" info={<Status status={status} />} />
                <InfoCol width={33} heading="Submitted" info={moment(submitted).format("DD MMM YYYY hh:mm a")} />
                <InfoCol
                  width={46}
                  heading="Checked"
                  info={
                    <Fragment>
                      {moment(checked).format("DD MMM YYYY hh:mm a")} by <AmName>{am}</AmName>
                    </Fragment>
                  }
                />
              </FlexRow>
              <Separator height={20} />
              <Notes heading="Note" defaultValue={note} onChange={onNotesChange} />
            </Main>
            <Separator height={20} />
            <FlexRow>
              <Column width="calc(50% - 13px)">{renderSections(answers.sections, false)}</Column>
              <Column width="calc(50% - 13px)">{renderSections(answers.sections, true)}</Column>
            </FlexRow>
          </Column>

          <Column width="325px">
            <Main padding="25px">
              {status === "MISSING" || status === "PROCESSING" ? (
                <Fragment>
                  <Btn fullGreen fullWidth margin="0 0 12px" onClick={onSetStatus("approveUserKYC")}>
                    <Check /> approve
                  </Btn>
                  <Btn error fullWidth margin="0 0 12px" onClick={onSetStatus("rejectUserKYC")}>
                    <SuspendIcon /> reject
                  </Btn>
                  <Btn transparent fullWidth margin="0 0 12px" onClick={onSetStatus("missingKYC")}>
                    missing
                  </Btn>
                </Fragment>
              ) : null}

              <Btn fullWidth onClick={changeModalMode}>
                <MessageIcon /> Send E-mail
              </Btn>
            </Main>
          </Column>
        </Wrapper>

        {error && <ErrorMessagePortal er={error} onClose={() => onError(null)} />}
        {success ? <SuccessMessage message={success} onClose={onSuccess} /> : null}
      </Fragment>
    );
  } else {
    return <CircularProgress style={{ margin: "auto" }} />;
  }
};

export default connect(({ userReducer: { token } }) => ({ token }), { updateAdminNotifications })(UserKYC);
