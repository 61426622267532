export default {
  pageName: "Investure Privacy Notice",
  content: [
    {
      heading: `<span>1.</span> GENERAL INFORMATION`,
      text: `
      This document describes how Investure Global AB, company registration number 5592439102 having its registered address at Tomtebogatan 8,
       113 39 Stockholm, (<b>“Investure” “we”</b> or <b>“us”</b>) collects, uses and processes personal data (<b>“Privacy Policy”</b>).
        This Privacy Notice is applicable 
       when you visit our website (the<b> “Website”</b>), create an account, use our platform (the <b>“Platform”</b>) or otherwise use our services
        (we will collectively refer to the Website, the Platform and the other services as the <b>“Services”</b>). <br/><br/>
        Our Services are offered to companies looking to make investments, to companies offering investment opportunities and to companies
         that facilitate the investment process. All personal data on the Platform and otherwise processed in connection with our Services
          are processed by us as controllers, meaning that we determine the purposes and means for the use of the personal data. <br/><br/>
          All definitions in this Privacy Notice shall be interpreted in accordance with applicable data protection laws which refers
           to the General Data Protection Regulation (Regulation no. 2016/679) and the Directive on Privacy and Electronic Communications
            (Directive 2002/58/EC), as well as the national implementations and related national legislation.
          `,
    },
    {
      heading: `<span>2.</span> DATA PROCESSED`,
      text: `
      We collect personal data either directly from you, as provided by you to us, or we may have received the personal data about you
       from a colleague or team member of yours.<br/><br/>
          <b>2.1. Website contact form</b><br/><br/>
          When you provide us with your contact details so that we can contact you, or an administrator adds you as a team member or 
          representative of a company in the Platform, we will process the following personal data:
        <ul>
          <li>your full name;</li>
          <li>the name of the company you work for or represent, and the sector you operate in;</li>
          <li>your country of residence and your gender;</li>
          <li>your phone number; and</li>
          <li>your email address.</li>
        </ul>

          We need the information above in order to communicate with you. Since you (or a colleague of yours) provide(s) us with this information on our Website, 
          we believe that you are ok with us reaching out to you. <br/><br/>

          <table border="1" class="contact-form">
      <tr>
        <th>PURPOSE</th>
        <th>LEGAL GROUND</th>
      </tr>
      <tr>
        <td>
          To send you alerts or messages by email or otherwise, including to provide you with marketing of our and our
          related parties’ products and services. To inform you about updates of our services, their terms of use, and
          if necessary, changes in how we process personal data.
        </td>
        <td>
          Our legitimate interest to make contact with new persons, to manage our existing relations, and to reach out
          with news and updates regarding our offerings.
        </td>
      </tr>
      <tr>
        <td>
          To enforce our Terms of Conditions, to protect our rights, property and safety and also the rights, property
          and safety of third parties if necessary.
        </td>
        <td>
          Our legitimate interest to safeguard data within our services, to protect our business and employees, and if
          necessary, to help third parties to do so.
        </td>
      </tr>
      <tr>
        <td>To fulfil requirements by law, if necessary.</td>
        <td>
          Our legal obligations, pursuant to e.g. the Bookkeeping Act (1999:1078), the Procedural Code (1942:749), the
          GDPR, and other rules regarding companies and transactions, as applicable.
        </td>
      </tr>
    </table>
    <br/>    
    <b>2.2. Platform data</b><br/><br/>
    The personal data set out above (in 2.1), and what is described below, will also be used when we operate the Platform and provide our Services. 
    <br/><br/>
    <table border="1" class="contact-form">
      <tr>
        <th>PURPOSE AND PERSONAL DATA USED</th>
        <th>LEGAL GROUND</th>
      </tr>
      <tr>
        <td>
          <h6>To provide the services</h6>
          When you use the Platform, we will log your activities on the Platform in order to provide our Services. This
          means that we will store important event data (such as you having accessed certain information about an
          investment opportunity, signed a non-disclosure agreement, or similar) and maintain records of your history of
          activity on the Platform, including communications with other users. Please note than an asset owner can also
          see investors’ activities regarding their asset.
        </td>
        <td>
          <h6>Legitimate interest</h6>
          We will process this personal data based on our legitimate interest to provide our Services. As our Services
          are mainly about making connections between investors and investees, the processing of personal data to make
          such connections is necessary, and we believe our interest to do so balances with the interests and rights and
          freedoms of those persons the personal data pertains to.
        </td>
      </tr>
      <tr>
        <td>
          <h6>To maintain the security</h6>
          In order to verify login details and to maintain a secure connection with you, and to ensure the security and
          technical function of the Platform, we will also process technical data such as technical data, which may
          include the URL you are accessing the Website from, your IP address, unique device ID, network and computer
          performance, browser type, language and identifying information and operating system.
        </td>
        <td>
          <h6>Legitimate interest</h6>
          We will process this personal data based on our legitimate interest to protect ourselves and our users from
          fraud or other types of cyber-attacks.
        </td>
      </tr>
      <tr>
        <td>
          <h6>To prevent fraudulent behaviour</h6>
          We will keep audit trails on the actions of users on the platform which can be reviewed if suspicious
          behaviour is identified.
        </td>
        <td>
          <h6>Legitimate interest</h6>
          We will process this personal data based on our legitimate interest to provide Services which are safe and
          compliant with applicable laws.
        </td>
      </tr>
      <tr>
        <td>
          <h6>To improve our services</h6>
          to improve and develop our business and possible new services and products and to analyse your use of the
          Website;
        </td>
        <td>
          <h6>Legitimate interest</h6>
          Our legitimate interests to improve our business, our Website, and our Services.
        </td>
      </tr>
    </table>
    <br />
    <b>2.3. Know-your-customer (“KYC”) for private persons </b><br/><br/>
    When we are required to undertake a KYC-check for private persons, we will send out a questionnaire to the person in question and thereafter process the personal data contained in the answers to that questionnaire.
     This processing is based on our legal obligation to comply with anti-money laundering legislation.<br/><br/> 
     <b>2.4. Facilitating deals with electronic signature solutions </b><br/><br/>
     When deals are concluded through our Services, we offer electronic signature solutions to make it easier for everyone. We will then process the signatories’ names, roles and contact details, together with any personal data contained in the actual agreements being signed. This processing is based on our legitimate interest to provide the services to our customers when
      they initiate the contract signing process. <br/><br/>
      <b>2.5. Cookies </b><br/><br/>
      We collect information by using technology such as cookies, pixels and tags (on your browser or device). <br/><br/>
      Insofar as the information collected from the cookies constitute personal data, we process the personal data based on
       your consent, as provided by you when accepting the cookie(s). Please note that without such consent, the Website
        and Services may not function as intended or include all features.<br/><br/>
        Our purposes for using data from cookies is to:
        <ul>
      <li>
        collect information about how visitors use our Website and to learn which pages on the Website are the most
        popular. This data is used to optimise our Website and make easier to navigate;
      </li>
      <li>
        enable our Website to remember choices visitors make while browsing, such as their geographical location where
        they are based in order to offer localised experiences (where possible), and to remember featured projects we
        may have already shown the visitor in order to avoid repetition; and to
      </li>
      <li>
        show visitors and logged in users the right information and to personalise their experience when using the
        Services.
      </li>
    </ul>
          `,
    },
    {
      heading: `<span>3.</span> DISCLOSURE OF PERSONAL DATA`,
      text: `
      <b>3.1. Information exchange on the platform and to relevant authorities</b><br/><br/>
      As our Services are about connecting investors and investees, information such as your contact details, focus areas and
       investment interests will be shared with other users of our Services. We will furthermore disclose personal data about 
       you to authorities as necessary in accordance with applicable laws. 
      These categories of recipients may be based outside of the EU/EEA – for more information on this, please see below. <br/><br/>
      <b>3.2. Our use of service providers</b><br/><br/>
      To provide our Services, we use service providers who process personal data on our behalf and in accordance with our instructions.
       These service providers include suppliers of hosting services, electronic signing solutions, and blockchain technology. Where the
        service providers will process personal data on our behalf as processors, they are bound by contractual obligations to only process
         the personal data in accordance with our instructions. If any of the service providers would process personal data as controllers,
          we would let you know here. These service providers may be based outside of the EU/EEA – for more information on this, please see below.<br/><br/>
          <b>3.3. Recipients outside of the EU and the EEA</b><br/><br/>
    Some of the abovementioned recipients are located outside the EU/EEA in countries which does not ensure a level of protection 
    for personal data that is equivalent to that ensured in the EU/EEA, and we are therefore applying safeguards for the data transfers
     as required by applicable law (such safeguards being comprised of the standard contractual clauses issued by the European Commission). 
     You can acquire further information regarding the transfers and standard contractual clauses in use by contacting us at <a>privacy@investure.co.</a><br/><br/>
     <b>3.4. Other websites and services</b><br/><br/>
     When visiting our Website or using our Services you may be directed to other websites or services where the personal
      data collected is not in our control. The privacy notice of the other website or service will govern the personal data obtained from you on
       that website or service.
      `,
    },
    {
      heading: `<span>4.</span> RESPONDING TO LEGAL REQUESTS AND PREVENTING HARM`,
      text: `We may access, preserve and share your personal data in response to a legal request (like a search warrant, court order or a subpoena
         or the like), or when necessary to detect, prevent and address fraud and other illegal activity, to protect ourselves, you and other users,
          including as part of investigations. `,
    },
    {
      heading: `<span>5.</span> RETENTION `,
      text: `We store personal data regarding users of the Services for the duration of their accounts being active.
       If an account is inactive for two (2) years, we will send an email to verify if the account is still in use. 
       If we get no answer to this email, we will delete the account in question<br/><br/>
       When we are asked to delete an account, we will retain the personal data in question for up to ninety (90) days in
        case there has been a misunderstanding or the user changes his or her mind.<br/><br/>
        We will store personal data about a transaction having been made until both parties request that we delete such personal data. <br/><br/>
        Personal data from cookies are not stored for longer than thirteen (13) months. <br/><br/>
        Personal data required for complying with applicable laws will be stored for the duration stipulated by or required to comply with those laws.<br/><br/>
        Personal data that is necessary for us to establish, exercise and/or defend legal claims may be stored for up to ten (10) years, and if necessary
         the duration of any such process where the personal data is required.
       `,
    },
    {
      heading: `<span>6.</span> CHILDREN`,
      text: `The Services are not directed to persons under the age of eighteen (18). If you are a parent or guardian of a person under
       the age of eighteen (18) and you become aware of that the child has provided personal data to us without your consent, 
       please contact privacy@investure.co to exercise your access, rectification, erasure, limiting of processing and objection rights.`,
    },
    {
      heading: `<span>7.</span> YOUR RIGHTS`,
      text: `You have the right to object to our processing of personal data based on our legitimate interests, and you have an absolute right
       to object to the processing of your personal data for direct marketing.<br/><br/>
       You have the right to request access and further information concerning the processing of your personal data,
        or request that we correct, rectify, complete, erase or restrict the processing of your personal data. You have the right to obtain
         a copy of the personal data that we process relating to you free of charge once (1) every calendar year. For any additional copies
          requested by you, we may charge a reasonable fee based on administrative costs.<br/><br/>
        If the processing is based on the legal grounds consent or fulfilment of contract you have the right to data portability.
         Data portability means that you can receive the personal data that you have provided to us, in a structured, commonly used
          and machine-readable format, and have the right to transfer such data to another data controllers. 
          `,
    },
    {
      heading: `<span>8.</span> CONTACT INFORMATION`,
      text: `To exercise your rights, or if you have any questions regarding our processing of your personal data, please contact us using
       the details set out in the beginning of this Privacy Notice, or send an email to the following address: privacy@investure.co. In your
        letter/email please state your full name, telephone number and account reference at Investure. Note that you should sign the request
         to receive information of the processing of your personal data yourself, and that we may take additional steps to verify your identity.<br/><br/>
         If you have any complaints regarding our processing of your personal data, you may file a complaint to the competent data 
         protection authority. You can find out more about the local data protection authorities under the following link
          <a target="_blank" href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm">http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm.</a> `,
    },
    {
      heading: `<span>9.</span> CHANGE OF CONTROL`,
      text: `If there is a change of control in our business, we may transfer your information to the new owners for them to continue
       to provide the Services. The new owners shall continue to comply with the commitments we have made in this Privacy Notice.`,
    },
    {
      heading: `<span>10.</span> NOTICE OF CHANGES TO THE PRIVACY NOTICE`,
      text: `If we make changes to this Privacy Notice, we will make a notification regarding this on the Website,
       when the changes are of minor nature, and if the change is more substantial, we will notify you by email. 
       If your consent is required due to the changes, we will provide you additional prominent notice as appropriate under
        the circumstances and, ask for your consent in accordance with applicable law.<br/><br/>
        Date of this Privacy Notice: 26 November 2020`,
    },
  ],
};
