import { createAction } from "redux-act";
import Api from "../../helpers/api";
import history from "../history";

import { socketConnect } from "../socket";
import { _socketConnect } from "../socket";

export const appReadyToWork = createAction("SET FLAG FOR SIGNALING ON READY APP FOR WORK");
export const loginSuccess = createAction("AUTH_USER_SUCCESS");
export const saveUserInfo = createAction("SAVE_USER_INFO");
export const logOut = createAction("USER_LOGOUT");
export const rememberUserToggle = createAction("REMEMBER_USER_TOGGLE");
export const set2Fa = createAction("SET 2 FA");
export const updateAvatar = createAction("UPDATE USER AVATAR");
export const updateUserName = createAction("UPDATE USER NAME");
export const setPaymentInfo = createAction("SET PAYMENT INFO");
export const setPaymentDetails = createAction("SET PAYMENT DETAILS");
export const setTradePaymentDetails = createAction("SET TRADE PAYMENT DETAILS");
export const setPaymentResponseData = createAction("SET PAYMENT RESPONSE DATA");
export const setPaymentResponseDataTrade = createAction("SET PAYMENT RESPONSE DATA TRADE");
export const setDefaultDealsView = createAction("SET DEFAULT DEALS VIEW");

export const is2FaEnabled = (authData, cb) => async (dispatch) => {
  const _2fa = await Api.is2faRequired(authData);
  if (_2fa["2faEnabled"]) {
    dispatch(set2Fa(_2fa["2faEnabled"]));
  } else if (cb) {
    cb();
  }
};

export const login = (authData, code = "", checkLogin = false) => async (dispatch) => {
  const { token, user } = await Api.login(authData, code);
  if (token) {
    dispatch(loginSuccess(token));
    dispatch(getUserInfo(token, true));

    if (user && user.userData) {
      fetch(`${Api.url}/api/userviewstatistics/count`, {
        headers: {
          "Content-type": "application/json",
          Authorization: token,
          OriginVoC: Api.origin,
        },
        method: "post",
        body: JSON.stringify({
          email: user.userData.username,
          name: user.userData.fullName,
          role: user.role,
          origin: Api.origin,
        }),
      }).catch(console.log);
    }

    if (checkLogin) return true;
  }
};

export const reLogin = (authData, cb) => async (dispatch) => {
  const { token } = await Api.login(authData);

  if (token) dispatch(loginSuccess(token));
  if (cb) cb(token);
};

export const getUserInfo = (token, moveTo = false) => async (dispatch, getState) => {
  try {
    const user = await Api.getUserInfo(token);
    const userInfo = await Api.getUserData(token);
    if (user && userInfo) {
      const {
        userData: { userLogo },
        username,
        role,
        am,
      } = user;
      const { gender } = userInfo;
      const { fileNameWithExtension, secureHash } = userLogo || {};

      const logo =
        fileNameWithExtension && secureHash
          ? await Api.getAttachment(token, secureHash, fileNameWithExtension)
          : gender === "FEMALE"
          ? Api.defaultFemaleAvatar
          : Api.defaultAvatar;
      dispatch(saveUserInfo({ ...user, logo, userInfo }));
      socketConnect(token, role === "ADMIN" ? am : username, role, dispatch, getState);
      // _socketConnect(token, role === "ADMIN" ? am : username, role, dispatch);
      if (moveTo && (user.role === "AM" || user.role === "ADMIN" || user.role === "SPONSOR")) {
        history.push("/admin/full-dashboard");
      }
    }
  } catch (error) {
    console.log(error);
  } finally {
    setTimeout(() => {
      dispatch(appReadyToWork());
    }, 1000);
  }
};

export const paymentReLogin = (token) => (dispatch) => {
  dispatch(loginSuccess(token));
  dispatch(getUserInfo(token, true));
};
