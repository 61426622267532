import React, { useState, useEffect, Fragment } from "react";

import {
  SendEmailWrapper,
  ModalBackground,
  ModalHeader,
  InputsWrapper,
  Heading,
  Separator,
  WaitingWrapper,
  Row,
  CurrentUserRow,
  UserAvatar,
  AssignModalLabel
} from "./styled";
import { Btn } from "../../buttons";
import { ReactComponent as CloseIcon } from "../../../../assets/x-circle.svg";
import { ChipsSelectComponent } from "../../forms";
import Api from "../../../../helpers/api";
import { CircularProgress } from "@material-ui/core";

import { ErrorMessage, SuccessMessage } from "../../errorMessage";

export const AssignUserToGroupModal = ({
  token,
  isOpen,
  onBackdropClick,
  group,
  users = [],
  onRefresh
}) => {
  const [wait, setWait] = useState(false);
  const [readyToAssignUsers, setReadyToAssignUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [disable, setDisable] = useState(true);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState("");

  const assignUserToGroup = async () => {
    // console.log(selectedUsers);
    setWait(true);

    const data = selectedUsers.map(user => user.id);

    const res = await Api.addUsersToGroup(token, data, group.id).catch(e => {
      setWait(false);
      setError(e);
    });
    if (res) {
      setWait(false);
      setSuccess(
        `${data.length > 1 ? "Users" : "User"} successfully assigned to group.`
      );
    }
  };

  const onCloseSuccessMessage = () => {
    setReadyToAssignUsers([]);
    setSelectedUsers([]);
    setSuccess("");
    onRefresh();
    onBackdropClick();
  };

  const onChangeSelect = val => {
    let buf;
    if (val) {
      buf = val.map(el => readyToAssignUsers[el]);
      buf.length ? setDisable(false) : setDisable(true);
      setSelectedUsers(buf);
    }
  };

  useEffect(() => {
    if (group) {
      const usersID = users
        .map(user => {
          if (group.users.find(userInGroup => userInGroup.id === user.id)) {
            return user.id;
          }
        })
        .filter(id => id);

      const availableUsers = users
        .map(user => {
          if (!usersID.find(us => us === user.id)) {
            return user;
          }
        })
        .filter(user => user);

      setReadyToAssignUsers(availableUsers);
    }
  }, [group]);

  return isOpen ? (
    <Fragment>
      <ModalBackground onClick={onBackdropClick}>
        <SendEmailWrapper onClick={e => e.stopPropagation()}>
          {wait && (
            <WaitingWrapper>
              <CircularProgress />
            </WaitingWrapper>
          )}
          <ModalHeader>
            <Heading>Assign Users to Group</Heading>
            <CloseIcon onClick={onBackdropClick} />
          </ModalHeader>
          <InputsWrapper padding="15px 25px">
            <AssignModalLabel>Group</AssignModalLabel>
            <CurrentUserRow>
              <UserAvatar
                small
                token={token}
                fileName={(group && group.logoFileName) || ""}
                hash={(group && group.logoHash) || ""}
              />
              <span>{(group && group.name) || "none"}</span>
            </CurrentUserRow>
            <Separator height={25} />
            <AssignModalLabel>
              Choose One or several users to assign
            </AssignModalLabel>
            <Row>
              <ChipsSelectComponent
                view="filter"
                label=""
                width="100%"
                multiple
                token={token}
                values={readyToAssignUsers.map(user => {
                  return {
                    label: user.fullName,
                    iconHash: user.userLogoHash,
                    iconName: user.userLogoFileName
                  };
                })}
                onChange={onChangeSelect}
              />
            </Row>
          </InputsWrapper>
          <ModalHeader isFooter>
            <Btn
              onClick={() => {
                setReadyToAssignUsers([]);
                setSelectedUsers([]);
                onBackdropClick();
              }}
              hide
              width={94}
            >
              Cancel
            </Btn>
            <Btn width={180} onClick={assignUserToGroup} disabled={disable}>
              ASSIGN users TO GROUP
            </Btn>
          </ModalHeader>
        </SendEmailWrapper>
      </ModalBackground>
      {error ? (
        <ErrorMessage er={error} onClose={() => setError(null)} />
      ) : null}
      {success ? (
        <SuccessMessage message={success} onClose={onCloseSuccessMessage} />
      ) : null}
    </Fragment>
  ) : (
    <div></div>
  );
};
