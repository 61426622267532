import React, { useState, useRef, useEffect } from "react";
import moment from "moment";
import { CircularProgress } from "@material-ui/core";
import ReactDOM from "react-dom";

import Api from "../../../../../helpers/api";
import ApiInvestor from "../../../../../helpers/investorApi";
import { getTrackerHTML, getTrackerHTML_URL } from "../../../../../helpers/fileViewer";

import {
  Wrapper,
  DocHeader,
  CloseButton,
  FullButton,
  DotButton,
  Name,
  Info,
  Tooltip,
  Action,
  FileViewFrame,
  LoaderWrapper,
  FileViewerStickyWrapper as _FileViewerStickyWrapper,
} from "./styled";
import { Button } from "../../../../../../components/investor-layout/pages/Offerings/styled";
import { ReactComponent as HelpIcon } from "../../../../../../assets/help-circle.svg";

import expand from "./assets/fullSize.png";
import { ReactComponent as Collapse } from "./assets/collapse.svg";

export const FileViewerStickyWrapper = _FileViewerStickyWrapper;

export const FileViewer = ({
  file,
  onClose,
  token,
  assetId,
  viewerWidth,
  viewerMargin,
  history,
  isInvestorViewer = false,
  callBack = () => console.log(),
  smallScreen = false,
}) => {
  const downloadRef = useRef(null);
  const headerRef = useRef(null);

  const [fileLoadError, setFileLoadError] = useState(false);
  const [fileLoad, setFileLoad] = useState(true);
  const [headerWidth, setHeaderWidth] = useState(0);
  const [isTooltip, setIsTooltip] = useState(false);
  const [isFull, setIsFull] = useState(smallScreen);
  const [fileUrl, setFileUrl] = useState(
    getTrackerHTML_URL(
      token,
      file.name,
      `${file.folderName}/${file.name}?assetId=${assetId}`,
      false,
      true,
      isInvestorViewer
    )
  );

  const [reviewed, setReviewed] = useState(file.reviewed);

  const toggleFull = (isF) => () => setIsFull(isF);
  const toggleTooltip = (isT) => () => setIsTooltip(isT);

  useEffect(() => {
    setFileLoad(true);
    setFileUrl(
      getTrackerHTML_URL(
        token,
        file.name,
        `${file.folderName}/${file.name}?assetId=${assetId}`,
        false,
        true,
        isInvestorViewer
      )
    );
  }, [file]);

  useEffect(() => {
    if (headerRef && headerRef.current && !headerWidth) {
      setHeaderWidth(headerRef.current.clientWidth - 40);
      console.log((headerRef.current.clientWidth - 40) * 1.42);
    }
  }, []);

  const openInNewTabAndClose = async () => {
    setFileLoadError(false);
    getTrackerHTML(
      token,
      file.name,
      `${file.folderName}/${file.name}?assetId=${assetId}`,
      false,
      true,
      isInvestorViewer,
      false
    );
  };

  const downloadFile = async () => {
    let fileBlob;
    fileBlob = isInvestorViewer
      ? await ApiInvestor.downloadFile(token, file.folderName, file.name, assetId)
      : await Api.downloadFile(token, file.folderName, file.name, assetId);

    const reader = new FileReader();
    reader.readAsDataURL(fileBlob);
    reader.onload = () => {
      downloadRef.current.href = reader.result;
      downloadRef.current.click();
    };
  };

  const onDownLoad = async () => {
    const s3Link = await ApiInvestor.getS3Link(token, file.folderName, file.name, assetId).catch((e) => {
      console.log(e);
    });
    const link = document.createElement("a");
    link.download = file.name;
    link.href = s3Link;
    link.click();
  };

  const onSetReviewStatus = () => {
    callBack({
      fileName: file.name,
      folderName: file.folderName,
      assetId,
      reviewed,
    });
    setIsTooltip(false);
    setReviewed(!reviewed);
  };

  const onAskQuestion = ({ name, folderName }) => () => {
    history.push(
      `/investor/offerings/question-and-answer/${assetId}/${folderName}/${name}${window.location.hash || ""}`
    );
  };

  const content = (
    <Wrapper
      maxHeight={isFull ? "none" : `${headerWidth * 1.42}px`}
      isFull={isFull}
      width={viewerWidth}
      ml={viewerMargin}
    >
      <DocHeader ref={headerRef}>
        <div>
          <Name>{file.name.replace(`.${file.type}`, "")}</Name>
          <Info>
            {(file.size / 1048576).toFixed(2)} mb / UPD: {moment(file.data).format("DD.MM.YYYY, hh:mm A")}
          </Info>
        </div>
        <div>
          {isInvestorViewer ? (
            <Button height={32} width={135} size={10} margin="0 10px 0 0" onClick={onAskQuestion(file)}>
              <HelpIcon style={{ marginRight: 10 }} />
              ASK A QUESTION
            </Button>
          ) : null}
          <DotButton onClick={toggleTooltip(!isTooltip)}>
            {isTooltip ? (
              <Tooltip isInvestor={isInvestorViewer}>
                <Action img={require("./assets/download.png")} onClick={isInvestorViewer ? onDownLoad : downloadFile}>
                  download file
                </Action>
                <Action img={require("./assets/eye.png")} onClick={openInNewTabAndClose}>
                  open in new tab
                </Action>
                {isInvestorViewer ? (
                  <Action img={require("./assets/alert-octagon.svg")} onClick={onSetReviewStatus}>
                    Set status as {reviewed ? '"in review"' : '"reviewed"'}
                  </Action>
                ) : null}
              </Tooltip>
            ) : null}
          </DotButton>
          <FullButton onClick={toggleFull(!isFull)}>
            {isFull ? <Collapse /> : <img src={expand} alt="icon" />}
          </FullButton>
          <CloseButton onClick={onClose} />
        </div>
      </DocHeader>
      <a download={file.name} ref={downloadRef}></a>
      {fileLoadError ? <h1>File not found</h1> : null}
      {fileLoad ? (
        <LoaderWrapper>
          <CircularProgress />
        </LoaderWrapper>
      ) : null}
      <FileViewFrame
        src={fileUrl}
        key={fileUrl}
        onLoad={() => setTimeout(() => setFileLoad(false), 1000)}
        onError={() => setFileLoadError(true)}
      />
    </Wrapper>
  );

  if (isFull) {
    return ReactDOM.createPortal(content, document.getElementById("round-modal"));
  }

  return content;
};
