export const Navigation = [
  {
    text: "Dashboard",
    link: "/setup/dashboard",
    re: /\/setup\/dashboard$/,
    available: true,
  },
  {
    text: "Assets",
    link: "/setup/deals",
    re: /\/setup\/deals$/,
    available: true,
  },
  {
    text: "Users",
    link: "/setup/users",
    re: /\/setup\/users$/,
    available: true,
  },
  {
    text: "Data Room",
    link: "/setup/data-room/all",
    re: /\/setup\/data-room.+$/,
    available: true,
  },
  {
    text: "Q&A",
    link: "/setup/question-and-answer/",
    re: /\/setup\/question-and-answer.+$/,
    available: true,
  },

  {
    text: "KYC",
    link: "/setup/kyc",
    re: /\/setup\/kyc$/,
    available: false,
  },
  {
    text: "Connect",
    link: "/setup/connect",
    re: /\/setup\/connect$/,
    available: false,
  },
];

export const SetupNavigation = [
  {
    text: "Payment Setup",
    link: "/setup/payment-setup",
    re: /\/setup\/payment-setup$/g,
  },
  {
    text: "Communication Setup",
    link: "/setup/comunication-setup/email-templates",
    re: /\/setup\/comunication-setup\/email-(templates(|\/.+)|settings|mailing)$/,
  },
];
