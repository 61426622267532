import styled from "styled-components";

export const Header = styled.div`
  width: 100%;
  height: ${({ height = 60 }) => height}px;
  border-radius: 4px;
  background: white;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: ${({ padding }) => (padding ? padding : "0 25px")};

  ${({ isFooter = false }) => {
    if (isFooter) return `margin-top: 30px;`;
    else return `margin-bottom: 30px;`;
  }}
  ${({ between = false }) => (between ? "justify-content: space-between;" : "")}
  > a {
    text-decoration: none;
  }
  span {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;

export const TextH3 = styled.h3`
  text-align: ${({ textAlign = "center" }) => textAlign};
  font-family: "Muli", sans-serif;
  font-size: ${({ size }) => (size ? `${size}px` : "14px")};
  font-weight: bold;
  ${({ marginSize = "" }) => (marginSize ? `margin: ${marginSize};` : "")}
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => width};
`;

export const Main = styled.section`
  margin-top: ${({ last }) => (last ? "10px" : "0")};
  position: relative;
  height: ${({ isClosed, currentHeight }) => (isClosed ? "57px" : currentHeight)};
  min-height: ${({ minHeight }) => (minHeight ? `${minHeight}px` : 0)};
  width: ${({ width }) => (width ? width : "100%")};
  overflow: ${({ noHidden }) => (noHidden ? "visible" : "hidden")};
  box-sizing: border-box;
  background: white;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  padding: ${({ padding }) => (padding ? padding : "20px 25px 25px 25px")};
  transition: 0.2s height linear;
`;

export const TextSub = styled.p`
  width: ${({ fullWidth }) => (fullWidth ? "100%" : "auto")};
  cursor: ${({ fullWidth }) => (fullWidth ? "pointer" : "auto")};
  text-align: ${({ textAlign = "center" }) => textAlign};
  font-family: "Muli", sans-serif;
  font-size: ${({ fSize }) => (fSize ? `${fSize}px` : "14px")};
  display: inline-block;
  font-weight: ${({ fontWeight = "normal" }) => fontWeight};
  color: ${({ color = "#CACACA" }) => color};
  ${({ marginSize = "" }) => (marginSize ? `margin: ${marginSize};` : "")}
`;

export const InputRow = styled.div`
  margin-bottom: ${({ last }) => (last ? "0" : "20px")};
  display: flex;
  align-items: center;
  width: ${({ width }) => (width ? width : "100%")};
  justify-content: space-between;
  box-sizing: border-box;
  ${({ wrap = false }) => (wrap ? "flex-wrap: wrap;" : "")}
`;

export const Head = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(5px);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ModalWrapper = styled.div`
  position: relative;
  width: ${({ width }) => (width ? width : "565px")};
  background: #ffffff;
  border: 1px solid #f9f9f9;
  box-sizing: border-box;
  box-shadow: 0px 10px 40px rgba(89, 120, 150, 0.06);
  border-radius: 4px;
  padding: 25px 30px 30px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`;

export const ModalHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: ${({ isFooter = false }) => (isFooter ? "auto" : "0")};
  > svg {
    cursor: pointer;
  }
`;

export const Heading = styled.h3`
  text-transform: none;
  font-family: Muli;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 135%;
  margin: 0;
  color: #181818;
`;

export const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ padding = "25px 0" }) => padding};
  padding-bottom: 5px;
  margin: 20px 0;
  border-top: 1px solid #f6f7fc;
  border-bottom: 1px solid #f6f7fc;
  z-index: 1000;
`;

export const UserCard = styled.div`
  width: calc(50% - 10px);
  height: 42px;
  background: ${({ theme: { quoteBgInvestor } }) => quoteBgInvestor};
  border-radius: 4px;
  box-sizing: border-box;
  padding: 10px 20px;
  margin: 4px 0;
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 135%;
  letter-spacing: -0.02em;
  color: #181818;
  border: 1px solid transparent;
  display: flex;
  align-items: center;
  > span {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: calc(100% - 146px);
    margin-right: 56px;
  }
  &:hover {
    border: 1px solid ${({ theme: { primary } }) => primary};
  }
  > svg {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
`;

export const UserWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const IconsBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 56px;
  > div {
    cursor: pointer;
    height: 16px;
    :first-child {
      path {
        stroke: ${({ theme: { primary } }) => primary};
      }
    }
  }
  svg {
    width: 16px;
    height: 16px;
  }
`;

export const WaitingWrapper = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.3);
  z-index: 1001;
`;
