import styled from "styled-components";

export const HeaderMain = styled.div`
  height: 60px;
  background: #ffffff;
  border-radius: 4px 0px 4px 4px;
  width: 100%;
  box-sizing: border-box;
  padding: 14px 25px;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
`;

export const DealName = styled.h3`
  line-height: 135%;
  color: #181818;
  font-size: 14px;
  margin: 0 0 0 25px;
`;
