import styled from "styled-components";
import React from "react";
import { Link } from "react-router-dom";

import { ReactComponent as SearchIcon } from "../../../../../assets/search-icon.svg";
import { shadeColor } from "helpers/increseBrightnes";

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  padding: 0 30px;
  max-width: 1440px;
  box-sizing: border-box;
`;

const SearchInput = styled.input`
  width: 440px;
  height: 60px;
  background: #ffffff;
  border-radius: 4px 0px 4px 4px;
  outline: none;
  border: none;
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 135%;
  letter-spacing: -0.02em;
  color: #333333;
  padding: 20px 20px 20px 56px;
  box-sizing: border-box;
  &:placeholder {
    color: rgba(24, 24, 24, 0.4);
  }
  @media screen and (max-width: 1280px) {
    width: calc(50% - 12.5px);
  }
`;

const SearchBlock = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  position: relative;
  margin-bottom: 25px;
  > svg {
    position: absolute;
    top: 22px;
    right: 400px;
    width: 16px;
    height: 16px;
    @media screen and (max-width: 1280px) {
      right: calc(50% - 53.5px);
    }
  }
`;

export const DealsWrapper = styled.section`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const Card = styled.div`
  width: calc(50% - 12.5px);
  height: 240px;
  background: #ffffff;
  border: 1px solid #f0f0f0;
  box-sizing: border-box;
  border-radius: 4px;
  margin-bottom: 25px;
  overflow: hidden;
  display: flex;
  ${({ column = false }) =>
    column ? "flex-direction:column; height: fit-content;" : ""};
`;

export const ImageBlock = styled.div`
  min-width: 225px;
  width: ${({ width, fullW }) =>
    width ? `${width}px` : fullW ? "100%" : "45%"};
  height: ${({ height }) => (height ? `${height}px` : "100%")};
  border-right: 1px solid #f0f0f0;
  position: relative;
  > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const InfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 25px;
  box-sizing: border-box;
  width: ${({ width = "55%" }) => width};
  height: 100%;
`;

export const StickName = styled.div`
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  padding: 0 8px;
  height: 24px;
  align-items: center;
  border-radius: 2px;
  margin-bottom: 10px;
  margin-right: auto;
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: #ffffff;
  background: ${({ active, theme: { primary, existedLabel } }) =>
    active ? primary : existedLabel};
  ${({ vertical = false }) =>
    vertical ? `position: absolute; top: 15px; left: 15px;` : ""}
`;

export const DealName = styled.h3`
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 135%;
  letter-spacing: -0.02em;
  margin: 0 0 16px;
  color: #192036;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const DataCol = styled.div`
  display: flex;
  flex-direction: column;
  ${({ vertical = false }) => (vertical ? "margin-bottom:16px;" : "")};
`;

export const DataRow = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 7px;
  &:last-child {
    margin-bottom: 0;
  }
  > svg {
    width: 9px;
    height: 9px;
    margin-right: 6px;
    path {
      stroke: ${({ theme: { primary } }) => primary};
    }
  }
`;

export const DataName = styled.p`
  margin: 0;
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  color: rgba(51, 51, 51, 0.8);
  > span {
    font-family: Muli;
    color: #192036;
    font-weight: bold;
  }
`;

export const ButtonLink = styled(Link)`
  height: 32px;
  /* left: 545px; */
  background: ${({ DR = false, theme: { primary } }) =>
    DR ? "transparent" : primary};
  border-radius: 4px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 135%;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  box-sizing: border-box;
  color: ${({ blue, grey, theme: { primary, existedLabel } }) =>
    blue ? primary : grey ? existedLabel : "#ffffff"};
  border: 1px solid
    ${({ blue, grey, theme: { primary, existedLabel } }) =>
      blue ? primary : grey ? existedLabel : primary};
  text-decoration: none;
  margin-top: auto;
  position: relative;
  &:hover {
    &::before {
      display: block;
    }
  }
  &::before {
    transition: all 0.3s linear;
    content: "";
    position: absolute;
    display: none;
    bottom: -1px;
    left: -1px;
    height: 3px;
    background: ${({ blue, grey, theme: { primary, existedLabel } }) =>
      blue ? primary : grey ? existedLabel : primary};
    width: calc(100% + 2px);
    filter: brightness(0.85);
    border-radius: 0 0 4px 4px;
  }
`;

const Header = styled.div`
  width: calc(100% - 465px);
  height: 60px;
  background: #ffffff;
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 14px 25px;
  box-sizing: border-box;
  font-family: Muli;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 135%;
  color: #181818;
  @media screen and (max-width: 1280px) {
    width: calc(50% - 12.5px);
  }
`;

export const Search = ({ value, onChange, text }) => (
  <SearchBlock>
    <Header>{text}</Header>
    <SearchInput
      placeholder="Search by asset name"
      value={value}
      onChange={e => onChange(e.target.value)}
    />
    <SearchIcon />
  </SearchBlock>
);
