import styled from "styled-components";
import React, { useState, useEffect } from "react";

import Api from "../../../../helpers/api";
import { ReactComponent as SearchIcon } from "../../../../assets/search-icon.svg";
import { ReactComponent as Check } from "../../../../assets/check.svg";
import { ReactComponent as NoAvatar } from "../../../../assets/no-avatar.svg";
import { ReactComponent as Institution } from "../../../../assets/institution.svg";
import { ReactComponent as NoAvatarFemale } from "../../../../assets/no-avatar-female.svg";

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  padding: 0 30px;
  max-width: 1440px;
  box-sizing: border-box;
`;

export const Header = styled.div`
  display: flex;
  margin: 0 auto;
  width: 100%;
  max-width: 1380px;
  height: 60px;
  background: #ffffff;
  border-radius: 4px;
  margin-bottom: 25px;
  font-family: Muli;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 135%;
  color: #181818;
`;

export const BackButtonContainer = styled.div`
  display: flex;
  width: 236px;
  height: 100%;
  color: ${({ theme: { primary } }) => primary};
  justify-content: center;
  align-items: center;
  border-right: 1px solid #f2f2f2;
  box-sizing: border-box;
`;

export const TextHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  padding-left: 24px;
  box-sizing: border-box;
`;

export const HeaderContainer = styled.div`
  width: calc(100% - 237px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 0 25px;
`;

export const ColWrapper = styled.div`
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1380px;
`;

export const Col = styled.div`
  width: ${({ left }) => (left ? "440px" : "calc(100% - 470px)")};
  @media screen and (max-width: 1280px) {
    width: ${({ left }) => (left ? "240px" : "calc(100% - 270px)")};
  }
`;

export const Search = ({ value, onChange }) => (
  <SearchBlock>
    <SearchInput
      placeholder="Search question"
      value={value}
      onChange={e => onChange(e.target.value)}
    />
    <SearchIcon />
  </SearchBlock>
);

const SearchInput = styled.input`
  width: 100%;
  height: 60px;
  background: #ffffff;
  border-radius: 4px;
  outline: none;
  border: none;
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 135%;
  letter-spacing: -0.02em;
  color: #333333;
  padding: 20px 20px 20px 56px;
  box-sizing: border-box;
  &:placeholder {
    color: rgba(24, 24, 24, 0.4);
  }
`;

const SearchBlock = styled.div`
  width: 100%;
  position: relative;
  margin-bottom: 25px;
  > svg {
    position: absolute;
    top: 22px;
    left: 22px;
    width: 16px;
    height: 16px;
  }
`;

export const CatFilter = styled.div`
  width: 100%;
  display: flex;
  background: #ffffff;
  border-radius: 4px;
  flex-direction: column;
  padding: 20px 25px 30px 25px;
  box-sizing: border-box;
`;

export const FilterHeading = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 135%;
  color: #181818;
  margin-bottom: 24px;
`;

export const WrapContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Category = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  letter-spacing: -0.02em;
  color: ${({ active }) => (active ? "#181818" : "#8A8A8A")};
  cursor: pointer;
  margin-bottom: 21px;
  :last-child {
    margin-bottom: 0;
  }
`;

const CatCount = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  letter-spacing: -0.02em;
  color: ${({ active }) => (active ? "#181818" : "rgba(24, 24, 24, 0.4)")};
  margin-left: 6px;
`;

const CheckBox = styled.div`
  width: 22px;
  height: 22px;
  border: 1px solid
    ${({ active, theme: { primary } }) => (active ? primary : "#EFEFEF")};
  box-sizing: border-box;
  border-radius: 1px;
  margin-right: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  > svg {
    width: 14px;
    height: 14px;
    transform: ${({ active }) => (active ? "rotateY(0)" : "rotateY(90deg)")};
    path {
      stroke: ${({ theme: { primary } }) => primary};
    }
    transition: all linear 0.1s;
  }
`;

export const CategoryBtn = ({ name, onClick, count, active }) => {
  const onChange = () => {
    onClick(name);
  };
  return (
    <Category onClick={onChange} active={active}>
      <CheckBox active={active}>
        <Check />
      </CheckBox>
      {name}
      <CatCount active={active}>{count}</CatCount>
    </Category>
  );
};

export const Questions = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const Question = styled.div`
  width: 100%;
  margin-bottom: 20px;
  border: 1px solid #f2f2f2;
  box-sizing: border-box;
  border-radius: 2px;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  :last-child {
    margin-bottom: 0px;
  }
`;

export const TopWrapper = styled.div`
  padding: 20px 25px 16px 25px;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  border-bottom: 1px solid #f2f2f2;
`;

export const TextWrapper = styled.div`
  display: flex;
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  color: #333333;
`;

export const QHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  > h4 {
    margin: 0 0 0 12px;
    font-family: Muli;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 135%;
    color: rgba(24, 24, 24, 0.4);
  }
  > h3 {
    margin: 0;
    font-family: Muli;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 135%;
    color: #181818;
  }
  > span {
    margin: 0 0 0 auto;
    font-family: Muli;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    color: #8a8a8a;
  }
`;

export const BottomWrapper = styled.div`
  width: 100%;
  height: ${({ answer = false }) => (answer ? "fit-content" : "64px")};
  background: ${({ theme: { adminTableRowGrey } }) => adminTableRowGrey};
  > svg {
    path {
      stroke: ${({ theme: { primary } }) => primary};
    }
  }
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 16px 25px;
  > h3 {
    margin: 0;
    font-family: Muli;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 135%;
    letter-spacing: 0.06em;
    text-transform: uppercase;
    color: #767676;
  }
`;

export const TextBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 21px;
  width: calc(100% - 53px);
  > span {
    display: flex;
    align-items: center;
    font-family: Muli;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 135%;
    letter-spacing: 0.06em;
    text-transform: uppercase;
    color: ${({ theme: { primary } }) => primary};
    > svg {
      margin-left: 8px;
    }
  }
`;

export const TextMessage = styled.div`
  overflow: hidden;
  height: ${({ height, open }) =>
    height && open ? `${height}px` : !height && open ? "fit-content" : 0};
  transition: height 0.3s linear;
  font-family: Muli;
  margin-bottom: ${({ marginBottom = "0px" }) => marginBottom};
  font-style: italic;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  color: #181818;
`;

export const ClockBlock = styled.div`
  background: #767676;
  border-radius: 24px;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 31px;
`;

export const RemButton = styled.div`
  margin-left: auto;
  display: flex;
  justify-content: center;
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 135%;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: #ff4343;
  cursor: pointer;
  > svg {
    margin-right: 14px;
  }
`;

export const UserAvatar = ({
  fileName,
  hash,
  token,
  small = false,
  investorType,
  gender
}) => {
  const [avatar, setAvatar] = useState(null);

  useEffect(() => {
    if (!avatar && fileName && hash) {
      Api.getAttachment(token, hash, fileName).then(blob =>
        setAvatar(blob || Api.defaultAvatar)
      );
    }
  });
  return (
    <AvatarContainer small={small}>
      {avatar ? (
        <Avatar small={small} src={avatar} alt="avatar" />
      ) : !fileName && !hash ? (
        investorType && investorType === "INSTITUTION" ? (
          <Institution
            style={
              small
                ? {
                    width: 32,
                    height: 32
                  }
                : {}
            }
          />
        ) : gender && gender === "FEMALE" ? (
          <NoAvatarFemale
            style={
              small
                ? {
                    width: 32,
                    height: 32
                  }
                : {}
            }
          />
        ) : (
          <NoAvatar
            style={
              small
                ? {
                    width: 32,
                    height: 32
                  }
                : {}
            }
          />
        )
      ) : null}
    </AvatarContainer>
  );
};

const AvatarContainer = styled.div`
  ${({ small = false }) => {
    if (small) {
      return `
        width: 32px;
        height: 32px;
        margin-right:0;
      `;
    }

    return `
      width: 42px;
      height: 42px;
      margin-right:14px;
    `;
  }}
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  > svg {
    filter: grayscale(${({ theme: { greyFilter } }) => greyFilter});
  }
`;

const Avatar = styled.img`
  ${({ small = false }) => {
    if (small) {
      return `
        width: 32px;
        height: 32px;
      `;
    }

    return `
      width: 42px;
      height: 42px;
    `;
  }}

  border-radius: 50%;
  object-fit: cover;
`;

export const AnswerWrapper = styled.div`
  background: ${({ theme: { adminTableRowGrey } }) => adminTableRowGrey};
  > svg {
    path {
      stroke: ${({ theme: { primary } }) => primary};
    }
  }
  box-sizing: border-box;
  border-radius: 2px;
  padding: 23px 25px 16px 78px;
  position: relative;
  min-height: 64px;
`;

export const AvatarHolder = styled.div`
  position: absolute;
  top: 16px;
  left: 25px;
`;

export const AnswerText = styled.div`
  width: 100%;
  overflow: hidden;
  height: ${({ height, open }) =>
    height && open ? `${height}px` : !height && open ? "fit-content" : 0};
  transition: height 0.15s linear;
  font-family: Muli;
  margin-bottom: ${({ marginBottom = "0px" }) => marginBottom};
  font-style: italic;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  color: #181818;
  margin-bottom: ${({ open }) => (open ? "13px" : "0px")};
`;

export const More = styled.div`
  width: fit-content;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 135%;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: ${({ theme: { primary } }) => primary};
  > svg {
    margin-left: 9px;
    transform: ${({ open }) => (open ? "rotate(180deg)" : "rotate(0deg)")};
    transition: transform 0.15s linear;
    path {
      stroke: ${({ theme: { primary } }) => primary};
    }
  }
`;

export const SeparateLine = styled.div`
  height: 1px;
  background: #f2f2f2;
  width: 100%;
  margin: 4px 0 24px 0;
`;

export const BodyWrapper = styled.div`
  width: 100%;
  padding: 20px 30px 30px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  > h3 {
    margin: 0 0 13px 0;
    font-family: Muli;
    font-style: normal;
    font-weight: bold;
    font-size: 10px;
    line-height: 135%;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: rgba(24, 24, 24, 0.6);
  }
  > div {
    margin-bottom: 20px;
    :last-child {
      margin-bottom: 0;
    }
  }
`;

export const BodyWrapperSelect = styled.div`
  width: 100%;
  padding: 20px 30px 30px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`;

export const FileSelector = styled.div`
  width: 100%;
  height: 42px;
  background: #ffffff;
  border: 1px solid
    ${({ active, theme: { primary } }) => (active ? primary : "#EFEFEF")};
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  box-sizing: border-box;
  cursor: pointer;
  > h3 {
    margin: 0;
    height: 16px;
    font-family: Muli;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 135%;
    letter-spacing: -0.02em;
    color: rgba(24, 24, 24, 0.6);
  }
`;

export const NameBlock = styled.div`
  display: flex;
  align-items: center;
  > span {
    margin: 0 0 0 14px;
    font-family: Muli;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 135%;
    letter-spacing: -0.02em;
    color: #181818;
  }
`;

export const ModalFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
`;

export const NoData = styled.div`
  box-sizing: border-box;
  max-width: 1380px;
  width: 100%;
  font-weight: 600;
  font-size: 16px;
  line-height: 160%;
  color: rgb(51, 51, 51);
  text-align: center;
  background: rgb(255, 255, 255);
  border-radius: 4px;
  padding: 25px;
  margin: 0px auto;
`;

export const FileBlock = styled.div`
  width: 100%;
  height: 50px;
  margin-top: ${({ isAnswered, edit }) => (isAnswered && !edit ? "10px" : "0")};
  margin-bottom: ${({ edit }) => (edit ? "23px" : "0")};
  background: ${({ grey, lightgrey, theme: { adminTableRowGrey } }) =>
    grey
      ? "rgba(98, 128, 218, 0.05)"
      : lightgrey
      ? adminTableRowGrey
      : "transparent"};
  border: 1px solid #efefef;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 13px 19px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const FileName = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 135%;
  color: #181818;
  display: flex;
  align-items: center;
  width: calc(100% - 80px);
  > div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100% - 95px);
  }
  > span {
    font-family: Muli;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 135%;
    color: rgba(24, 24, 24, 0.6);
    margin-left: 6px;
  }
  > svg {
    width: 20px;
    height: 20px;
    margin-right: 14px;
    path {
      fill: transparent;
      stroke: ${({ theme: { primary } }) => primary};
      &:first-child {
        stroke: transparent;
        fill: ${({ theme: { primary } }) => primary};
      }
    }
  }
`;

export const FileControls = styled.div`
  display: flex;
  align-items: center;
  svg {
    cursor: pointer;
    width: 18px;
    height: 18px;
    margin-right: 20px;
    :last-child {
      margin-right: 0;
    }
    path {
      stroke: ${({ theme: { primary } }) => primary};
    }
  }
`;

export const Description = styled.div`
  margin-top: 28px;
  width: 100%;
  height: 57px;
  background: #ffffff;
  border: 1px solid #efefef;
  box-sizing: border-box;
  border-radius: 2px 2px 2px 0;
  padding-left: 25px;
  display: flex;
  align-items: center;
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 135%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: rgba(24, 24, 24, 0.4);
`;

export const ScrollContainer = styled.div`
  min-height: 58px;
  max-height: 420px;
  padding-left: ${({ pLeft }) => (pLeft ? `${pLeft}px` : "0")};
  box-sizing: border-box;
  margin-bottom: 24px;
  overflow-y: scroll;
  &&::-webkit-scrollbar-track {
    background-color: ${({ theme: { primaryLight } }) => primaryLight};
    border-radius: 2px;
  }
  &&::-webkit-scrollbar {
    width: 6px;
    border-radius: 2px;
    background-color: ${({ theme: { primaryLight } }) => primaryLight};
  }
  &&::-webkit-scrollbar-thumb {
    background-color: ${({ theme: { primaryMiddle } }) => primaryMiddle};
    border-radius: 2px;
  }
`;

export const TRow = styled.div`
  border: 1px solid #efefef;
  border-top: none;
  box-sizing: border-box;
  width: 100%;
  height: 57px;
  display: flex;
  justify-content: space-between;
  cursor: ${({ noHover = false }) => (noHover ? "auto" : "pointer")};
  transition: all 0.1s linear;

  background: ${({ active, theme: { quoteBgInvestor } }) =>
    active ? quoteBgInvestor : "#fff"};
  &:hover,
  &:active {
    background: ${({ noHover = false, theme: { quoteBgInvestor } }) =>
      noHover ? "#fff" : quoteBgInvestor};
  }
  :last-child {
    border-radius: 0 0 2px 2px;
  }
`;

export const TCeil = styled.div`
  background: ${({ active, theme: { primaryLight } }) =>
    active ? primaryLight : "transparent"};
  position: ${({ pos = "static" }) => pos};
  height: 57px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  border-right: 1px solid #efefef;
  padding: 0 ${({ p = 20 }) => ` 20px 0  ${p}px`};
  width: ${({ big, middle, full, fixedWidth }) => {
    if (full) return "100%;";
    return big
      ? "calc(100% - 560px)"
      : middle
      ? "200px"
      : fixedWidth
      ? fixedWidth
      : "120px";
  }};
  font-family: Muli;
  font-style: normal;
  font-weight: ${({ lower = false }) => (lower ? "normal" : "bold")};
  font-size: ${({ lower = false }) => (lower ? "14px" : "10px")};
  line-height: 135%;
  letter-spacing: 0.05em;
  text-transform: ${({ lower = false }) => (lower ? "none" : "uppercase")};
  color: rgba(24, 24, 24, 0.4);
  &:last-child {
    border-right: none;
  }
`;

export const FolderName = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 135%;
  color: #181818;
  text-transform: none;
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
  > svg {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
  > svg:first-child {
    width: 12px;
    height: 12px;
    transform: ${({ open }) => (open ? "none" : "rotate(-90deg)")};
  }
  &:hover {
    > div {
      margin-left: 10px;
      display: flex;
    }
  }
  > span {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100% - 80px);
  }
`;

export const DocName = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 135%;
  color: #181818;
  text-transform: none;
  display: flex;
  align-items: center;
  width: calc(100% - 15px);
  height: 100%;
  > svg {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
  &:hover {
    > div {
      margin-left: 10px;
      display: flex;
    }
  }
  > span {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100% - 60px);
  }
`;

export const Controllers = styled.div`
  width: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  > svg {
    path {
      stroke: ${({ theme: { primary } }) => primary};
    }
  }
`;

export const LoaderWrapper = styled.div`
  width: 100%;
  height: calc(100vh - 230px);
  display: flex;
`;
