import React, { Fragment, useState, useEffect } from "react";
import styled from "styled-components";
import { CircularProgress } from "@material-ui/core";
import Api from "helpers/api";
import { Link } from "react-router-dom";

import { ReactComponent as Right } from "../../../../../assets/chevron-right.svg";
import { EmptyInfo } from "./emptyInfo";
import { UserStatistic } from "../../DealsStatistic/UserStatistic";
import { SendEmail } from "../../DealsStatistic/SendEmail";

const Card = styled.div`
  width: calc(44.8% - 30px);
  background: #ffffff;
  border-radius: 4px;
  display: flex;
  align-self: stretch;
  border: 1px solid #efefef;
  overflow: hidden;
  flex-direction: column;
`;

export const Row = styled.div`
  height: 50px;
  background: #ffffff;
  border-bottom: 1px solid #efefef;
  box-sizing: border-box;
  width: 100%;
  display: flex;
`;

const Block = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
`;

export const TH = styled.div`
  width: ${({ small, main, long }) =>
    small ? "49px" : long ? "calc(100% - 246px)" : main ? "calc(100% - 213px)" : "82px"};
  height: 100%;
  display: flex;
  align-items: center;
  padding: ${({ small, main }) => (small ? 0 : main ? "0 25px" : "0 15px")};
  border-right: ${({ small }) => (small ? "none" : "1px solid #efefef")};
  box-sizing: border-box;
  > svg {
    filter: grayscale(${({ theme: { greyFilter } }) => greyFilter});
  }
`;

export const CeilHead = styled.h6`
  font-size: 10px;
  line-height: 135%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  margin: 0;
  color: rgba(24, 24, 24, 0.4);
`;

const Value = styled.div`
  font-size: 14px;
  line-height: 135%;
  color: #181818;
  font-weight: ${({ bold }) => (bold ? 600 : 400)};
`;

const Avatar = styled.img`
  width: 18px;
  height: 18px;
  margin-right: 7px;
  border-radius: 50%;
  object-fit: cover;
`;

const NoAvatar = styled.div`
  width: 18px;
  height: 18px;
  margin-right: 7px;
`;

const UserLink = styled.div`
  width: 25px;
  height: 25px;
  background: ${({ theme: { primary } }) => primary};
  border-radius: 2px;
  display: flex;
  margin: auto;
  cursor: pointer;
  > svg {
    transform: rotate(180deg);
    margin: auto;
    width: 10px;
    height: 10px;
    path {
      stroke: #fff;
    }
  }
`;

export const StatisticTable = ({ info, token, assetId, history }) => {
  const [email, setEmail] = useState(null);
  const [mailData, setMailData] = useState(null);

  const onSetMailData = (data) => {
    setMailData(data);
  };

  const onOpenStatistic = (email) => () => {
    setEmail(email);
  };

  useEffect(() => console.log(mailData), [mailData]);

  const renderUsers = () => {
    const data = info.userViewStatistics;
    if (data.length) {
      return data.map(({ fullName, logo, spentTime, totalViews, useName }) => (
        <Row key={useName}>
          <TH main>
            <Logo token={token} logo={logo} />
            <Value bold>{fullName}</Value>
          </TH>
          <TH>
            <Value>{spentTime}</Value>
          </TH>
          <TH>
            <Value>{totalViews}</Value>
          </TH>
          <TH small>
            <UserLink onClick={onOpenStatistic(useName)}>
              <Right />
            </UserLink>
          </TH>
        </Row>
      ));
    } else
      return (
        <Block>
          <EmptyInfo
            heading="There are no users in statistics yet"
            text="When we’ll have statistics to show for you we’ll show them here"
          />
        </Block>
      );
  };
  return (
    <Card>
      {!info ? (
        <CircularProgress size={24} style={{ margin: "auto" }} />
      ) : (
        <Fragment>
          <Row>
            <TH main>
              <CeilHead>User</CeilHead>
            </TH>
            <TH>
              <CeilHead>Spent time</CeilHead>
            </TH>
            <TH>
              <CeilHead>Total views</CeilHead>
            </TH>
            <TH small></TH>
          </Row>
          {renderUsers()}
          {email ? (
            <UserStatistic
              history={history}
              token={token}
              assetId={assetId}
              days={30}
              username={email}
              onBackdropClick={onOpenStatistic(null)}
              toggleModalSendEmail={onSetMailData}
            />
          ) : null}
          <SendEmail
            token={token}
            mailData={mailData || {}}
            onBackdropClick={() => onSetMailData(null)}
            isOpen={mailData}
          />
        </Fragment>
      )}
    </Card>
  );
};

export const Logo = ({ logo, token }) => {
  const [avatar, setAvatar] = useState(null);

  const getAvatar = async () => {
    if (logo) {
      const { fileNameWithExtension, secureHash } = logo;
      const _avatar = await Api.getAttachment(token, secureHash, fileNameWithExtension);
      setAvatar(_avatar);
    } else {
      setAvatar(Api.defaultAvatar);
    }
  };

  useEffect(() => {
    getAvatar();
  }, []);

  if (avatar) return <Avatar src={avatar} alt="logo" />;
  else return <NoAvatar />;
};

// getAssetActivity
