import React, { useState, useRef, useEffect } from "react";

import {
  BG,
  FormBlockEmpty,
  FormEmpty,
  FormText,
  FilesModal,
  MGeader,
  HeadingM,
  MWrapper,
  Form,
  FormHeading,
  Button,
  FilesWrapper,
  Row,
  File,
  FileName_,
  Size,
  IconsBlock,
} from "./styled";
import { ReactComponent as Upload } from "../../../../assets/upload-big.svg";
import { ReactComponent as CloseIcon } from "../../../../assets/x-circle.svg";
import { ReactComponent as UploadIcon } from "../../../../assets/upload.svg";
import { ReactComponent as FileIcon } from "../../../../assets/file-11.svg";
import { ReactComponent as Replace } from "../../../../assets/repeat-file.svg";
import { ReactComponent as Delete } from "../../../../assets/delete-file.svg";

export const AttachmentsUploader = ({ onBackdropClick, filesList = [], onSetFilesToMessage }) => {
  const [files, setFiles] = useState(filesList);
  const [replaced, setIsReplaced] = useState(null);
  const inputFile = useRef(null);

  const preventDefaultCustom = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const _onBackdropClick = () => {
    if (!files.length) {
      onSetFilesToMessage([]);
    }
    onBackdropClick();
  };

  const onAddFiles = async (_files) => {
    if (typeof replaced === "number") {
      new Promise((res) => {
        const list = [...files];
        list.splice(replaced, 1, _files[0]);
        setFiles(list);

        res(setIsReplaced(null));
      });
    } else {
      setFiles([...files, ..._files]);
    }
  };

  const onUpload = (e) => {
    e.stopPropagation();
    inputFile && inputFile.current && inputFile.current.click();
  };

  const onDelteFile = (num) => {
    const list = [...files];
    list.splice(num, 1);
    setFiles(list);
  };

  const onReplaceFile = (e, num) => {
    new Promise((res) => {
      setIsReplaced(num);
      res(onUpload(e));
    });
  };

  const renderFiles = () =>
    files.map(({ name, lastModified, size }, i) => (
      <File key={`${name}-${lastModified}-${i}`}>
        <FileIcon />
        <FileName_>{name}</FileName_>
        <Size>{(size / 1048576).toFixed(2)} mb</Size>
        <IconsBlock>
          <Replace onClick={(e) => onReplaceFile(e, i)} />
          <Delete onClick={() => onDelteFile(i)} />
        </IconsBlock>
      </File>
    ));

  const onSubmit = async () => {
    onSetFilesToMessage(files);
    _onBackdropClick();
  };

  return (
    <BG
      onClick={_onBackdropClick}
      onDragEnter={preventDefaultCustom}
      onDragOver={preventDefaultCustom}
      onDragLeave={preventDefaultCustom}
    >
      {files.length ? (
        <FilesModal
          onDragEnter={preventDefaultCustom}
          onDragOver={preventDefaultCustom}
          onDragLeave={preventDefaultCustom}
          onDrop={preventDefaultCustom}
          onClick={(e) => e.stopPropagation()}
        >
          <MGeader>
            <HeadingM>Attach Files to Chat</HeadingM>
            <CloseIcon onClick={_onBackdropClick} />
          </MGeader>
          <MWrapper>
            <Form
              onSubmit={(e) => e.preventDefault()}
              enctype="multipart/form-data"
              action=""
              method="post"
              onDragEnter={preventDefaultCustom}
              onDragOver={preventDefaultCustom}
              onDragLeave={preventDefaultCustom}
              onDrop={(e) => {
                preventDefaultCustom(e);
                onAddFiles(e.dataTransfer.files);
              }}
              onClick={(e) => onUpload(e)}
            >
              <FormHeading>Drop files here or</FormHeading>
              <Button width={165}>
                <UploadIcon /> upload FILES
              </Button>
              <input
                ref={inputFile}
                multiple={!(typeof replaced === "number")}
                type="file"
                style={{ display: "none" }}
                id="file-dataAndRoom"
                onChange={(e) => {
                  if (e.target.files.length) {
                    onAddFiles(e.target.files);
                  }
                }}
              />
            </Form>
            <FilesWrapper>{renderFiles()}</FilesWrapper>
            <Row>
              <Button transparent width={94}>
                cancel
              </Button>
              <Button width={180} onClick={onSubmit}>
                Attach {files.length} FILES
              </Button>
            </Row>
          </MWrapper>
        </FilesModal>
      ) : (
        <FormBlockEmpty
          onDragEnter={preventDefaultCustom}
          onDragOver={preventDefaultCustom}
          onDragLeave={preventDefaultCustom}
          onDrop={preventDefaultCustom}
        >
          <FormEmpty
            onSubmit={(e) => e.preventDefault()}
            enctype="multipart/form-data"
            action=""
            method="post"
            onDragEnter={preventDefaultCustom}
            onDragOver={preventDefaultCustom}
            onDragLeave={preventDefaultCustom}
            onDrop={(e) => {
              preventDefaultCustom(e);
              onAddFiles(e.dataTransfer.files);
            }}
            onClick={(e) => onUpload(e)}
          >
            <Upload />
            <FormText>Drop your files here to upload</FormText>
            <input
              ref={inputFile}
              multiple
              type="file"
              style={{ display: "none" }}
              id="file-dataAndRoom"
              onChange={(e) => {
                if (e.target.files.length) onAddFiles(e.target.files);
              }}
            />
          </FormEmpty>
        </FormBlockEmpty>
      )}
    </BG>
  );
};
