import React from "react";
import moment from "moment";

import {
  ConfBackground,
  ModalWrap,
  ConfHeader,
  HeadingModal,
  ConfBody,
  ConfFooter,
} from "./styled";
import { Btn } from "../../../admin-layout/buttons";
import { ReactComponent as CloseIcon } from "../../../../assets/x-circle.svg";

export const RemoveQuestionModal = ({
  onBackdropClick,
  userFullName,
  dateOfQuestion,
  onAgree,
}) => {
  return (
    <ConfBackground onClick={onBackdropClick}>
      <ModalWrap
        width="440px"
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <ConfHeader isInvestor>
          <HeadingModal small>Remove Question</HeadingModal>
          <CloseIcon onClick={onBackdropClick} />
        </ConfHeader>
        <ConfBody>
          <h3>Are you sure to remove question?</h3>
          <span>
            The question from {userFullName} from{" "}
            {moment(dateOfQuestion).format("DD.MM.YYYY")} will be removed.
          </span>
        </ConfBody>
        <ConfFooter>
          <Btn width={94} transparent onClick={onBackdropClick}>
            cancel
          </Btn>
          <Btn
            width={180}
            red
            onClick={() => {
              onAgree();
              onBackdropClick();
            }}
          >
            REMOVE question
          </Btn>
        </ConfFooter>
      </ModalWrap>
    </ConfBackground>
  );
};
