import React, { useState, useEffect } from "react";

import {
  Card,
  SearchBlock,
  Search,
  ChatHeading,
  SearchLine,
  ThemesWrapper,
  ThemeWrapper,
  Avatar,
  NoAvatar,
  Col,
  UserName,
  DealNameSmall,
  NoDealLogoSmall,
  DealLogoSmall,
  DealFlex,
} from "./styled";
import { ReactComponent as SearchIcon } from "../../../../assets/search-icon.svg";
import { UserIcon } from "components/common/icons-primary";

import { ReactComponent as EditIcon } from "../../../../assets/edit-3.svg";
import { Button } from "../../../investor-layout/pages/Offerings/styled";

import Api from "../../../../helpers/api";

export const SideBar = ({ adminChatThemes, token }) => {
  const renderThemes = () => {
    return adminChatThemes.map((e) => <Theme token={token} key={`${e.assetId}-${e.username}-${e.unread}`} theme={e} />);
  };
  return (
    <Card width="322px">
      <SearchBlock>
        <ChatHeading>chat mini</ChatHeading>
        <SearchLine mTop="25px">
          <Search placeholder="Search user or deal" />
          <SearchIcon />
          <Button width={42} height={42} transparent>
            <EditIcon style={{ margin: 0 }} />
          </Button>
        </SearchLine>
      </SearchBlock>

      <ThemesWrapper>{renderThemes()}</ThemesWrapper>
    </Card>
  );
};

const Theme = ({ token, theme: { logo, name, assetId, unread, userLogo, userFullName, username, lastUpdateDate } }) => {
  const [img, setImg] = useState(null);
  const [dealLogo, setDealLogo] = useState(null);

  useEffect(() => {
    if (!img && userLogo) {
      const { fileNameWithExtension, secureHash } = userLogo;
      Api.getAttachment(token, secureHash, fileNameWithExtension).then((b) => setImg(b));
    } else {
      setImg(null);
    }
    if (!dealLogo && logo) {
      const { fileNameWithExtension, secureHash } = logo;
      Api.getAttachment(token, secureHash, fileNameWithExtension).then((b) => setDealLogo(b));
    } else {
      setDealLogo(null);
    }
  }, []);
  const active = window.location.pathname === `/admin/chat/${assetId}/${username}`;
  return (
    <ThemeWrapper to={`/admin/chat/${assetId}/${username}`} active={active} unread={active ? 0 : unread}>
      {img ? (
        <Avatar src={img} alt="logo" />
      ) : (
        <NoAvatar>
          <UserIcon />
        </NoAvatar>
      )}

      <Col>
        <UserName>{userFullName}</UserName>
        <DealFlex>
          {dealLogo ? <DealLogoSmall src={dealLogo} alt="logo" /> : <NoDealLogoSmall />}
          <DealNameSmall>{name}</DealNameSmall>
        </DealFlex>
      </Col>
    </ThemeWrapper>
  );
};
