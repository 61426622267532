import React, { useState, useEffect, Fragment } from "react";
import styled from "styled-components";
import moment from "moment";
import { CircularProgress } from "@material-ui/core";

import Api from "../../../../helpers/api";
import * as Doc from "../../Document";
import { ReactComponent as QuoteIcon } from "../../../../assets/quote.svg";
import { ReactComponent as FolderIcon } from "../../../../assets/folder-plus.svg";
import { UserIcon } from "components/common/icons-primary";
import { ReactComponent as FileIcon } from "../../../../assets/file-text.svg";
import { ReactComponent as DownloadIcon } from "../../../../assets/download.svg";
import { ReactComponent as InfoIcon } from "../../../../assets/info.svg";
import { ReactComponent as Upload } from "../../../../assets/upload-big.svg";
import { currenciesModel } from "../../../../models/currenciesModel";
import { Attachment, FileName_ } from "../Chat/styled";
import { ReactComponent as FileIconMessage } from "../../../../assets/file-11.svg";

import { borderBox, scroll } from "components/common/mixins";

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 30px;
  box-sizing: border-box;
  max-height: ${({ frameOpen }) => (frameOpen ? "calc(100vh - 230px)" : "auto")};
  overflow-y: ${({ frameOpen }) => (frameOpen ? "hidden" : "visible")};
`;

export const Header = styled.div`
  height: 60px;
  width: 100%;
  max-width: 1380px;
  background: #ffffff;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 0 25px 0 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
`;

export const ColWrapper = styled.div`
  display: flex;
  max-width: 1380px;
  justify-content: space-between;
  width: 100%;
`;
export const Col = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => width};
  box-sizing: border-box;
  padding: ${({ padding }) => padding};
  align-items: ${({ align }) => align};
  height: ${({ height }) => height};
  margin: ${({ margin = 0 }) => margin};
`;

export const Card = styled.div`
  height: ${({ height }) => height};
  position: relative;
  background: #ffffff;
  border-radius: ${({ isOnSlider = false }) => (isOnSlider ? "0 0 4px 4px" : "4px")};
  box-sizing: border-box;
  padding: ${({ padding }) => (padding ? padding : "20px 25px")};
  margin-bottom: 20px;
  overflow: ${({ overflow = "visible" }) => overflow};
  &:last-child {
    margin-bottom: 0;
  }
  margin-top: ${({ fullWidth }) => (fullWidth ? "20px" : 0)};
  min-height: fit-content;
`;

export const Heading = styled.h1`
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 135%;
  letter-spacing: -0.02em;
  color: #192036;
  margin: 0 0 14px 0;
`;

export const Text = styled.p`
  margin: 0;
  font-family: Muli !important;
  font-style: normal;
  font-weight: normal;
  font-size: ${({ size }) => (size ? `${size}px` : "14px")};
  line-height: 160%;
  color: #333333;
  > p,
  strong,
  span {
    margin: 0;
    font-family: Muli !important;
  }
  > h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: Muli !important;
  }
  figure {
    padding-bottom: 0 !important;
    margin-bottom: 30px;
    height: auto !important;
    &:last-child {
      margin-bottom: 0;
    }
    > iframe {
      height: 480px !important;
    }
    img {
      width: 100%;
    }
  }
`;

const SmallDescBlock = styled.div`
  box-sizing: border-box;
  background: ${({ theme: { quoteBgInvestor } }) => quoteBgInvestor};
  border-radius: 4px;
  width: 100%;
  padding: 16px 18px 16px 47px;
  margin-bottom: ${({ isLastItem = false }) => (isLastItem ? 0 : "18px")};
  position: relative;
  overflow: hidden;
  > svg {
    width: 14px;
    height: 16px;
    position: absolute;
    top: 16px;
    left: 18px;
    path {
      stroke: ${({ theme: { primary } }) => primary};
      fill: ${({ theme: { primary } }) => primary};
    }
  }
`;

export const Row = styled.div`
  display: flex;
  justify-content: ${({ justify = "space-between" }) => justify};
  width: ${({ width }) => (width ? width : "100%")};
  align-items: ${({ align = "center" }) => align};
  margin: ${({ margin }) => margin};
  > svg {
    path {
      stroke: ${({ error = false, theme: { primary, red } }) => (error ? red : primary)};
    }
  }
`;

const InfoCol = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(25% - 25px);
`;

const InfoHeading = styled.h6`
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 135%;
  margin: 0 0 4px 0;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  color: rgba(24, 24, 24, 0.6);
`;

const ShareData = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 135%;
  letter-spacing: 0.05em;
  text-transform: ${({ lower }) => (lower ? "none" : "uppercase")};
  color: #181818;
  white-space: nowrap;
  height: 21px;
`;

const Eq = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: 600;
  font-size: 16px !important;
  line-height: 135%;
  color: #31adbf;
  text-transform: none;
  margin: 0 auto 0 9px;
  cursor: pointer;
`;

export const Quote = ({ text, isLastItem }) => {
  return (
    <SmallDescBlock isLastItem={isLastItem}>
      <QuoteIcon />
      <Text dangerouslySetInnerHTML={{ __html: text }} />
    </SmallDescBlock>
  );
};

export const ShareProperty = ({ heading, data, lower }) => {
  return (
    <InfoCol>
      <InfoHeading>{heading}</InfoHeading>
      <ShareData lower={lower}>{data}</ShareData>
    </InfoCol>
  );
};

export const Equtity = () => {
  return (
    <Row>
      <FolderIcon />
      <Eq>Equity</Eq>
    </Row>
  );
};

export const BorderBlock = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding-right: 30px;
  box-sizing: border-box;
  height: 100%;
  border-right: 1px solid #f2f2f2;
`;

const Avatar = styled.img`
  object-fit: cover;
  width: 62px;
  height: 62px;
  border-radius: 50%;
  margin-right: 22px;
`;

const SponsorName = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 150%;
  color: #181818;
`;

const SponsorDesc = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 135%;
  letter-spacing: -0.02em;
  color: #8a8a8a;
`;

const IconBlock = styled.div`
  width: 62px;
  height: 62px;
  margin-right: 22px;
  > svg {
    width: 100%;
    height: 100%;
  }
`;

export const SponsorBlock = ({
  manager: { username, userLogo, fullName, userLogoHash, userLogoFileName, slogan, countryOfRegistration },
  token,
}) => {
  const [logo, setLogo] = useState(null);

  useEffect(() => {
    if (!logo && userLogo) {
      const { fileNameWithExtension, secureHash } = userLogo;
      Api.getAttachment(token, secureHash, fileNameWithExtension).then((blob) => setLogo(blob));
    } else if (userLogoHash && userLogoFileName) {
      Api.getAttachment(token, userLogoHash, userLogoFileName).then((blob) => setLogo(blob));
    }
  });
  return (
    <Row justify="flex-start" width="calc(100% - 253px)">
      {logo ? (
        <Avatar src={logo} />
      ) : (
        <IconBlock>
          <UserIcon />
        </IconBlock>
      )}
      <Col>
        <SponsorName>{fullName}</SponsorName>
        <SponsorDesc>{slogan || countryOfRegistration || username}</SponsorDesc>
      </Col>
    </Row>
  );
};

export const Line = styled.div`
  width: calc(100% + 50px);
  margin: 20px 0;
  height: 1px;
  background: #f0f0f0;
  transform: translateX(-25px);
`;

const Block = styled.div`
  width: ${({ total }) => `calc(${100 / total}% - ${((total - 1) * 25) / total}px)`};
  height: 96px;
  background: #ffffff;
  border: 1px solid #f0f0f0;
  box-sizing: border-box;
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.02);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 12px;
`;

const BlockHeading = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 135%;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #181818;
  margin-bottom: 6px;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const BlockData = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 135%;
  text-align: center;
  color: rgba(24, 24, 24, 0.6);
`;

export const ManagerInfo = ({ heading, data, total }) => {
  return (
    <Block total={total}>
      <BlockHeading>{heading}</BlockHeading>
      <BlockData>{data}</BlockData>
    </Block>
  );
};

export const Circle = styled.div`
  margin-right: 10px;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ done, theme: { primary } }) => (done ? primary : " #fff")};
  border: 1px solid ${({ active, done, theme: { primary } }) => (active || done ? primary : "#dfdfdf")};
  box-sizing: border-box;
  border-radius: 4px;
  border-radius: 50%;
  position: relative;
  transition: all 0.3s linear;
  > svg {
    width: 16px;
    height: 16px;
  }
  &:before {
    content: "";
    background: ${({ theme: { primary } }) => primary};
    width: ${({ active, done }) => (active && !done ? "14px" : 0)};
    height: ${({ active, done }) => (active && !done ? "14px" : 0)};
    border-radius: 50%;
    transition: all 0.3s linear;
    display: block;
  }
  &:after {
    content: "";
    display: ${({ last }) => (last ? "none" : "block")};
    width: 1px;
    height: 24px;
    background: ${({ done, theme: { primary } }) => (done ? primary : "#dfdfdf")};
    position: absolute;
    right: 15px;
    bottom: -25px;
    transition: all 0.3s linear;
  }
`;

export const StepTxt = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 135%;
  color: ${({ active, done, theme: { primary } }) => (active ? primary : done ? "#181818" : "rgba(24, 24, 24, 0.6)")};
  width: 222px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: ${({ active }) => (active ? "underline" : "none")};
  transition: all linear 0.3s;
`;

export const DateText = styled.div`
  font-size: 12px;
  line-height: 135%;
  text-align: right;
  color: #b9b9b9;
  width: 125px;
  overflow: hidden;
  margin-left: auto;
`;

export const ChartBg = styled.div`
  width: 140px;
  height: 140px;
  border-radius: 50%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 9px;
`;

const WhiteBg = styled.div`
  width: 140px;
  height: 140px;
  border-radius: 50%;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  top: 4px;
`;

const ChartTxt = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: ${({ size }) => (size ? `${size}px` : "12px")};
  line-height: 135%;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: rgba(24, 24, 24, 0.6);
  width: ${({ width }) => width};
`;

const Percents = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 135%;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: ${({ trade, theme: { primaryMiddle } }) => (trade ? "#8862DA" : primaryMiddle)};
  margin-bottom: 19px;
`;

export const ChartStatistic = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 135%;
  text-align: center;
  letter-spacing: 0.05em;
  margin: ${({ margin }) => margin};
  color: ${({ bold }) => (bold ? "#181818" : "rgba(24, 24, 24, 0.4)")};
`;

const InfoClol = styled.div`
  /* width: calc(50% - 10px); */
  display: flex;
  flex-direction: column;
  align-items: ${({ align }) => (align ? align : "center")};
  margin-bottom: 24px;
  &:last-child {
    margin-bottom: 0;
  }
`;

const InfoColHeading = styled.h6`
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 135%;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  color: rgba(24, 24, 24, 0.6);
  margin: 0 0 4px 0;
`;

export const InfoColVal = styled.p`
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: ${({ size }) => (size ? `${size}px` : "16px")};
  line-height: 135%;
  letter-spacing: 0.05em;
  text-transform: ${({ noTransform }) => (noTransform ? "none" : "uppercase")};
  margin: 0;
  color: #181818;
  ${({ styleAdd = "" }) => styleAdd};
`;

export const ChartData = ({ heading, val, minInv = false, baseCurrency }) => {
  return (
    <InfoClol align="flex-start">
      <InfoColHeading>{heading}</InfoColHeading>
      <InfoColVal
        dangerouslySetInnerHTML={{
          __html: minInv
            ? (currenciesModel[baseCurrency] ? currenciesModel[baseCurrency].symbol : baseCurrency) + val
            : val,
        }}
      />
    </InfoClol>
  );
};

export const ReminingChart = ({ percent }) => {
  return (
    <WhiteBg>
      <ChartTxt>Raised</ChartTxt>
      <Percents>{!isNaN(percent) ? percent : 0}%</Percents>
    </WhiteBg>
  );
};

export const CardHead = styled.h5`
  font-family: Muli;
  font-style: normal;
  font-weight: ${({ fWeight = "bold" }) => fWeight};
  font-size: ${({ size = 18 }) => `${size}px`};
  line-height: 135%;
  width: fit-content;
  color: #181818;
  margin: ${({ margin }) => margin};
`;

export const PieChartWrapper = styled.div`
  width: 140px;
  height: 140px;
  margin: 0 auto 23px;
`;

export const Percent = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 135%;
  margin: ${({ noMargin }) => (noMargin ? 0 : "0 6px 0 10px")};
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: rgba(24, 24, 24, 0.4);
`;

const StructureCircle = styled.div`
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: ${({ bg }) => bg};
  margin-left: auto;
`;

export const CapStructData = ({ heading, val, color = false, rate = 1, total, baseCurrency }) => {
  return (
    <InfoClol align="flex-start">
      <InfoColHeading>{heading}</InfoColHeading>
      <Row width="126px">
        <InfoColVal>
          <span
            dangerouslySetInnerHTML={{
              __html: currenciesModel[baseCurrency] ? currenciesModel[baseCurrency].symbol : baseCurrency,
            }}
          />
          {((val * rate) / 1000000).toFixed(2)}M
        </InfoColVal>
        <Percent>{!isNaN(val / total) ? ((val / total) * 100).toFixed(0) : 0}%</Percent>
        {color && <StructureCircle bg={color} />}
      </Row>
    </InfoClol>
  );
};

const DocContainer = styled.div`
  box-sizing: border-box;
  position: relative;
  width: 100%;
  height: 50px;
  background: ${({ disabled, theme: { documentBgInvestor } }) => (disabled ? "#F5F5F5" : documentBgInvestor)};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
  padding: 0 18px;
  &:last-child {
    margin-bottom: 0;
  }
  svg {
    width: 16px;
    height: 20px;
    path {
      stroke: ${({ disabled, theme: { primaryDark } }) => (disabled ? "rgba(24, 24, 24, 0.4)" : primaryDark)};
    }
    &:last-child {
      cursor: pointer;
    }
  }
`;

const FileName = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 135%;
  margin: 0 auto 0 16px;
  letter-spacing: -0.02em;
  width: ${({ visible }) => (visible ? "calc(100% - 120px)" : "calc(100% - 90px)")};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: ${({ disabled }) => (disabled ? "rgba(24, 24, 24, 0.4)" : "#181818")};
`;

const DocTooltip = styled.div`
  position: absolute;
  padding: 16px;
  background: #ffffff;
  border: 1px solid #efefef;
  box-sizing: border-box;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  width: 220px;
  right: 0;
  bottom: 40px;
  display: flex;
  flex-direction: column;
  z-index: 100;
`;

const Triangle = styled.div`
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 20px 12.5px 0 12.5px;
  border-color: #ffffff transparent transparent transparent;
  position: absolute;
  right: 14px;
  top: 3px;
  z-index: 101;
`;

const TooltipTxt = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 145%;
  letter-spacing: -0.02em;
  margin-bottom: 12px;
  color: #181818;
`;

const StepBlock = styled.div`
  display: flex;
  align-items: center;
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 135%;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: ${({ theme: { primary } }) => primary};
  cursor: pointer;
  > svg {
    width: 12px;
    height: 12px;
    margin-right: 7px;
  }
`;

export const Document = ({ token, doc, stepName, makeSign }) => {
  const {
    visibility,
    ndaRequired,
    loiRequired,
    attachmentFile: { fileNameWithExtension, secureHash },
  } = doc;

  const downLoadFile = () => {
    Api.getAttachment(token, secureHash, fileNameWithExtension).then((blob) => {
      const link = document.createElement("a");
      link.href = blob;
      link.download = fileNameWithExtension;
      link.click();
    });
  };

  const [tooltipShown, setShow] = useState(false);

  return (
    <DocContainer disabled={!visibility}>
      <FileIcon />
      <FileName visible={visibility} disabled={!visibility}>
        {fileNameWithExtension}
      </FileName>
      {visibility && <Doc.Document doc={{ fileNameWithExtension, secureHash }} token={token} />}
      <div style={{ width: 20 }} />
      {visibility ? (
        <DownloadIcon onClick={downLoadFile} />
      ) : (
        <Fragment>
          <InfoIcon onClick={() => setShow(!tooltipShown)} />
          {tooltipShown && (
            <Fragment>
              <DocTooltip>
                <TooltipTxt>
                  You will be able to view & download this file after you sign the {ndaRequired && "NDA, "}{" "}
                  {loiRequired && "LOI"}
                </TooltipTxt>
                <StepBlock onClick={makeSign}>
                  <FileIcon />
                  {stepName === "SIGN LETTER OF INTENT" ? "SIGN LOI" : stepName}
                </StepBlock>
              </DocTooltip>
              <Triangle></Triangle>
            </Fragment>
          )}
        </Fragment>
      )}
    </DocContainer>
  );
};

const WaitWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

export const CardLoad = () => (
  <WaitWrapper>
    <CircularProgress />
  </WaitWrapper>
);

export const ChatWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 49px);
`;

export const InputBlock = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px 30px;
  border-top: 1px solid #f6f7fc;
  margin-top: auto;
  width: calc(100% + 60px);
  transform: translateX(-30px);
`;

export const ChatInput = styled.input`
  width: ${({ width = 310 }) => `${width}px !important`};
  height: 42px !important;
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 135%;
  letter-spacing: -0.02em;
  color: #181818;
  border: 1px solid #efefef !important;
  box-sizing: border-box;
  border-radius: 4px !important;
  margin: 0 !important;
  outline: none;
  padding: 0 20px;
  &::placeholder {
    color: rgba(24, 24, 24, 0.4);
  }
`;

export const MessagesCont = styled.div`
  width: 100%;
  height: 380px;
  overflow-y: scroll;
  overflow-x: hidden;
  box-sizing: border-box;
  margin-left: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  &&::-webkit-scrollbar-track {
    background-color: ${({ theme: { primaryLight } }) => primaryLight};
    border-radius: 2px;
  }
  &&::-webkit-scrollbar {
    width: 8px;
    border-radius: 2px;
    background-color: ${({ theme: { primaryLight } }) => primaryLight};
  }
  &&::-webkit-scrollbar-thumb {
    background-color: ${({ theme: { primaryMiddle } }) => primaryMiddle};
    border-radius: 2px;
  }
`;

const MessageBg = styled.div`
  padding: 15px 20px;
  box-sizing: border-box;
  width: ${({ isManager }) => (isManager ? "280px" : "311px")};
  background: ${({ isManager, theme: { primary, documentBgInvestor } }) => (isManager ? documentBgInvestor : primary)};
  border-radius: 4px;
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  color: ${({ isManager }) => (isManager ? "#181818" : "#fff")};
  margin-bottom: 6px;
`;

export const MessageWrapper = styled.div`
  float: ${({ isManager }) => (isManager ? "left" : "right")};
  margin-right: 10px;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  transform: ${({ translate }) => `translateX(${translate}px)`};
  transition: all 0.2s linear;
  &:last-of-type {
    margin-bottom: 0;
  }
`;

const MessageTriangle = styled.div`
  position: absolute;
  bottom: 21px;
  right: -10px;
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 0 0 15px;
  border-color: transparent transparent transparent ${({ theme: { primary } }) => primary};
`;

const MessDate = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: 300;
  font-size: 10px;
  line-height: 150%;
  text-align: right;
  color: #181818;
  opacity: 0.6;
  margin-right: auto;
`;

const AttachmentComponent = ({ token, messageId, hash, fileName }) => {
  const [waitDownload, setWait] = useState(false);

  const downLoadFile = async (hash, fileName) => {
    setWait(true);
    const blob = await Api.getChatFile(token, messageId, hash).catch(() => {
      setWait(false);
    });
    const link = document.createElement("a");
    link.href = blob;
    link.download = fileName;
    link.click();
    setWait(false);
  };
  return (
    <Attachment wait={waitDownload} noMinWidth>
      <FileIconMessage />
      <FileName_>{fileName}</FileName_>
      <Upload
        onClick={
          !waitDownload
            ? () => downLoadFile(hash, fileName)
            : () => {
                alert();
              }
        }
      />
    </Attachment>
  );
};

export const Message = ({ message, date, attachments, token, messageId }) => {
  const [translate, setTranslate] = useState(500);

  const files = Object.keys(attachments);

  useEffect(() => {
    setTimeout(() => {
      setTranslate(0);
    }, 300);
  }, []);

  return (
    <MessageWrapper translate={translate}>
      <MessageBg>
        {message}
        {files.length
          ? files.map((f) => (
              <AttachmentComponent key={f} token={token} hash={f} fileName={attachments[f]} messageId={messageId} />
            ))
          : null}
      </MessageBg>
      <MessageTriangle />
      <MessDate>{moment(date).format("DD.MM.YYYY, hh:mm a")}</MessDate>
    </MessageWrapper>
  );
};

const ManagerLogo = styled.img`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  margin-right: 14px;
  position: absolute;
  object-fit: cover;
  left: -50px;
`;

export const ManagerMessage = ({ logo, message, token, date, attachments, messageId }) => {
  const [translate, setTranslate] = useState(-500);
  const [avatar, setAvatar] = useState(null);

  const files = Object.keys(attachments);

  useEffect(() => {
    if (!avatar && logo) {
      const { fileNameWithExtension, secureHash } = logo;
      Api.getAttachment(token, secureHash, fileNameWithExtension).then((blob) => setAvatar(blob));
    }
  });

  useEffect(() => {
    setTimeout(() => {
      setTranslate(-32);
    }, 300);
  }, []);

  return (
    <MessageWrapper translate={translate}>
      {avatar ? (
        <ManagerLogo src={avatar} />
      ) : (
        <UserIcon style={{ width: 36, height: 36, position: "absolute", left: -50 }} />
      )}
      <MessageBg isManager>
        {message}
        {files.length
          ? files.map((f) => (
              <AttachmentComponent key={f} token={token} hash={f} fileName={attachments[f]} messageId={messageId} />
            ))
          : null}
      </MessageBg>
      <MessDate>{moment(date).format("DD.MM.YYYY, hh:mm a")}</MessDate>
    </MessageWrapper>
  );
};

export const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(5px);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ModalWrapper = styled.div`
  position: relative;
  width: ${({ width }) => (width ? `${width}px` : "440px")};
  background: #ffffff;
  border: 1px solid #f9f9f9;
  box-sizing: border-box;
  box-shadow: 0px 10px 40px rgba(89, 120, 150, 0.06);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`;

export const ModalHeader = styled.div`
  width: 100%;
  display: flex;
  height: 52px;
  padding: 0 25px;
  box-sizing: border-box;
  align-items: center;
  justify-content: space-between;
  margin-top: ${({ isFooter = false }) => (isFooter ? "auto" : "0")};
  box-shadow: ${({ boxShadow }) => boxShadow};
  z-index: 100;
  > svg {
    cursor: pointer;
    width: 24px;
    height: 24px;
  }
`;

export const ModalHeading = styled.h3`
  font-family: Muli;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 135%;
  margin: 0;
  color: #181818;
  text-transform: ${({ textTransform }) => textTransform};
`;

export const InputsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-items: center;
  padding: 25px 30px 30px;
  background: #fff;
`;

export const ModalText = styled.p`
  margin: 0 0 10px;
  font-family: Muli;
  font-style: normal;
  font-weight: ${({ weight = 600 }) => weight};
  font-size: ${({ size = 16 }) => `${size}px`};
  line-height: 135%;
  text-align: ${({ align = "center" }) => align};
  letter-spacing: -0.02em;
  color: #192036;
`;

export const InputCol = styled.div`
  width: ${({ w = "160px" }) => w};
`;

export const CurrencyCol = styled.div`
  width: 100px;
  margin-top: 4px;
`;

export const ConvertBlock = styled.div`
  height: 42px;
  width: 100%;
  border-radius: 4px;
  margin-bottom: 24px;
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 135%;
  display: flex;
  justify-content: center;
  text-align: center;
  letter-spacing: -0.02em;
  align-items: center;
  color: ${({ error = false, theme: { red } }) => (error ? red : "#7D818B")};
  > svg {
    path {
      stroke: ${({ error = false, theme: { primary, red } }) => (error ? red : primary)};
    }
  }
`;

export const WaitingModalWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: ${({ width }) => (width ? `${width}px` : "100%")};
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  background: #00000045;
`;

export const VerticalLine = styled.div`
  width: 1px;
  background: #f0f0f0;
  align-self: stretch;
`;

export const Layer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1000;
  background: transparent;
  width: 100%;
  height: 100%;
`;

export const ValuesFlex = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const BlockDoc = styled.div`
  width: 100%;
  height: 52px;
  background: ${({ active, theme: { documentBgInvestor } }) => (active ? documentBgInvestor : "#F5F5F5")};
  border-radius: 4px;
  margin-bottom: 12px;
  font-weight: bold;
  padding: 0 20px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  &:last-child {
    margin-bottom: 25px;
  }
  > svg {
    width: 24px;
    height: 24px;
    opacity: ${({ active }) => (active ? 1 : 0.4)};
    path {
      stroke: ${({ active, theme: { primaryDark } }) => (active ? primaryDark : "#181818")};
    }
    &:first-child {
      margin-right: 12px;
    }
    &:last-child {
      margin-left: auto;
      cursor: pointer;
    }
  }
`;

export const FileNameDR = styled.div`
  max-width: 280px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 16px;
  line-height: 135%;
  letter-spacing: -0.02em;
  color: #181818;
  opacity: ${({ active }) => (active ? 1 : 0.4)};
`;

export const InfoBox = styled.div`
  display: flex;
  margin-left: auto;
  justify-content: center;
  align-items: center;
  > svg {
    width: 24px;
    height: 24px;
    opacity: 0.4;
    margin-left: auto;
    path {
      stroke: #181818;
    }
  }

  position: relative;
  cursor: pointer;
  &:hover {
    > div {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    > svg {
      display: block;
    }
  }
`;

export const WrapperTips = styled.div`
  position: absolute;
  padding: 16px;
  background: #ffffff;
  border: 1px solid #efefef;
  box-sizing: border-box;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  width: 220px;
  right: -29px;
  bottom: 28px;
  display: none;
  flex-direction: column;
  z-index: 100;
`;

export const TipsText = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 145%;
  letter-spacing: -0.02em;
  color: #181818;
`;

export const Triang = styled.div`
  display: none;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 20px 12.5px 0 12.5px;
  border-color: #ffffff transparent transparent transparent;
  position: absolute;
  bottom: 10px;
  z-index: 101;
`;

export const Load = styled.div`
  display: flex;
  color: ${({ theme: { primary } }) => primary};
  > div {
    margin: auto;
  }
`;

export const StepRow = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 24px;
  &:last-child {
    margin-bottom: 0;
  }
`;

//old stepper
const StepCol = styled.div`
  width: 25%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CircleOld = styled.div`
  margin-bottom: 10px;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  border: 1px solid ${({ active, theme: { primary } }) => (active ? primary : "#dfdfdf")};
  box-sizing: border-box;
  border-radius: 4px;
  border-radius: 50%;
  position: relative;
  &:before {
    content: "";
    display: ${({ active }) => (active ? "block" : "none")};
    background: ${({ theme: { primary } }) => primary};
    width: 14px;
    height: 14px;
    border-radius: 50%;
  }
  &:after {
    content: "";
    display: ${({ last }) => (last ? "none" : "block")};
    width: 70px;
    height: 1px;
    background: #dfdfdf;
    position: absolute;
    right: -71px;
  }
`;

const StepTxtOld = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 135%;
  text-align: center;
  color: ${({ active }) => (active ? "#181818" : "rgba(24, 24, 24, 0.6)")};
  width: 82px;
`;

export const Stepper = ({ step }) => {
  return (
    <Row width="390px" margin="auto">
      <StepCol>
        <CircleOld active={step >= 0}></CircleOld>
        <StepTxtOld active={step >= 0}>1. Sign the NDA</StepTxtOld>
      </StepCol>
      <StepCol>
        <CircleOld active={step >= 1}></CircleOld>
        <StepTxtOld active={step >= 1}>2. Upload LOI</StepTxtOld>
      </StepCol>
      <StepCol>
        <CircleOld active={step >= 4}></CircleOld>
        <StepTxtOld active={step >= 4}>3. Sign the Contract</StepTxtOld>
      </StepCol>
      <StepCol>
        <CircleOld last active={step >= 5}></CircleOld>
        <StepTxtOld active={step >= 5}>4. Finalise the process</StepTxtOld>
      </StepCol>
    </Row>
  );
};

export const AM = styled.img`
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 50%;
`;

export const OpenChat = styled.button`
  font-weight: bold;
  font-size: 14px;
  color: ${({ theme: { primary } }) => primary};
  margin-top: 20px;
  cursor: pointer;
  width: fit-content;
  height: fit-content;
  outline: none;
  border: none;
  background: transparent;
  padding: 0;
`;

export const AMContainer = styled.div`
  position: relative;
`;

export const LetfBlock = styled.div`
  position: absolute;
  top: 20px;
  right: 25px;
`;

export const PlaceHoldersCol = styled.div`
  ${scroll};
  width: 100%;
  max-height: 400px;
  padding: 0 20px 0 20px;
  margin-bottom: 30px;
  overflow-y: ${({ noScroll = false }) => (noScroll ? "visible" : "scroll")};
`;

export const PleceholderItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  > div.text {
    font-size: 14px;
    line-height: 145%;
    letter-spacing: -0.02em;
    color: rgba(24, 24, 24, 0.8);
    font-weight: 500;
  }
  &:last-child {
    margin-bottom: 0;
  }
`;

export const LoaderBlock = styled.div`
  margin: auto;
  color: ${({ theme: { primary } }) => primary};
`;
