import React from "react";
import styled from "styled-components";

export const Wrapper = styled.section`
  position: relative;
  width: ${({ width = "calc(100% - 25px)" }) => width};
  height: ${({ height = "100%" }) => height};
  margin-left: ${({ ml = "25px" }) => ml};
  box-sizing: border-box;
  background: #f2f3f7;
  height: 100%;
  max-height: ${({ maxHeight }) => maxHeight};
  ${({ isFull }) => {
    if (isFull) {
      return `
        position: fixed;
        top: 0;
        left: 0;
        z-index: 101;
        width: 100vw;
        height: 100vh;
        padding: 5px;
        margin-left: 0px;
      `;
    } else {
      return `
        height: calc(100vh - 60px);
      `;
    }
  }};
`;

export const DocHeader = styled.div`
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  height: 60px;
  background: ${({ theme: { headerInvestor } }) => headerInvestor};
  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 0 20px;
  box-sizing: border-box;
  & > div {
    width: 75%;
  }

  & > div:last-child {
    display: flex;
    justify-content: flex-end;
    width: 50%;
  }
`;

const Button = styled.button`
  border-radius: 2px;
  border: none;
  width: 32px;
  height: 32px;

  &:focus {
    border: none;
    outline: none;
  }
  &::after {
    content: "";
    display: block;
    width: 18px;
    height: 18px;
    background-size: contain;
    background-position: center;
    margin: 0 auto;
  }
`;

export const CloseButton = styled(Button)`
  background: ${({ theme: { primary } }) => primary};
  &::after {
    background-image: url(${require("./assets/close.png")});
  }
`;

export const FullButton = styled(Button)`
  margin-right: 10px;
  background: ${({ theme: { primary } }) => primary};
  display: flex;
  padding: 0;
  > img {
    width: 18px;
    height: 18px;
    margin: auto;
  }
  > svg {
    margin: auto;
  }
  &::after {
    display: none;
    /* background-image: url(${require("./assets/fullSize.png")}); */
  }
`;

export const DotButton = styled(Button)`
  margin-right: 10px;
  background: ${({ theme: { primary } }) => primary};
  position: relative;
  &::after {
    background-image: url(${require("./assets/point.png")});
  }
`;

export const Name = styled.div`
  font-family: Muli;
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
  display: inline-block;
  margin-right: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 575px;
`;

export const Info = styled.div`
  font-family: Muli;
  font-weight: 600;
  font-size: 14px;
  line-height: 135%;
  color: #ffffff;
  color: rgba(255, 255, 255, 0.5);
  display: inline-block;
  width: 50%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const Tooltip = styled.div`
  position: absolute;
  width: ${({ isInvestor }) => (isInvestor ? "180px" : "130px")};
  padding: 16px;
  background: white;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  bottom: ${({ isInvestor }) => (isInvestor ? "-86px" : "-80px")};
  right: -14px;
  &::after {
    content: "";
    border: 10px solid transparent;
    border-bottom: 10px solid white;
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    right: 20px;
    top: 1px;
    transform: translateY(-100%);
  }
`;

export const Action = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  letter-spacing: 0.06em;
  text-transform: uppercase;

  color: #181818;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  user-select: none;
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  &::before {
    content: "";
    display: inline-block;
    width: 12px;
    height: 12px;
    background-size: contain;
    background-position: center;
    background-image: url(${({ img = "" }) => img});
    margin-right: 10px;
  }
`;

export const FileViewFrame = styled.iframe`
  width: 100%;
  height: calc(100% - 42px);

  border: none;
  box-sizing: border-box;
  padding: 20px;
  img {
    height: auto;
  }
`;

export const LoaderWrapper = styled.div`
  height: 200px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const WrapperRelative = styled.div`
  position: relative;
`;

const WrapperSticky = styled.div`
  position: sticky;
  top: ${({ top = 100 }) => top}px;
`;

export const FileViewerStickyWrapper = (props) => (
  <WrapperRelative>
    <WrapperSticky {...props}>{props.children}</WrapperSticky>
  </WrapperRelative>
);
