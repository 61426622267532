import React, { useState, useEffect } from "react";
import moment from "moment";

import { TimerWrapper, TimerCol, Row, DigitBlock, Label } from "./styled";
import { helpersFormatTime } from "../../../../helpers/";

export const CountDown = ({ expiredIn }) => {
  const [endTime, setEndTime] = useState(
    +expiredIn * 1000 - +moment().format("x")
  );

  const [fromEndTime, setFromEndTime] = useState(helpersFormatTime(endTime));

  useEffect(() => {
    const idInterval = setInterval(() => {
      const newEndTime = +moment(endTime).add(-1, "second").format("x");
      setEndTime(newEndTime);

      setFromEndTime(helpersFormatTime(newEndTime));
    }, 1000);
    return () => clearInterval(idInterval);
  });

  const formatCount = (time, num) => {
    const str = time.toString();
    const symbols = str.split("");

    return symbols[num];
  };

  console.log(fromEndTime);

  return (
    <TimerWrapper>
      <TimerCol>
        <Row mBot="4px">
          <DigitBlock>{formatCount(fromEndTime.hours, 0)}</DigitBlock>
          <DigitBlock>{formatCount(fromEndTime.hours, 1)}</DigitBlock>
        </Row>
        <Label>hrs</Label>
      </TimerCol>
      <span>:</span>
      <TimerCol>
        <Row mBot="4px">
          <DigitBlock>{formatCount(fromEndTime.minutes, 0)}</DigitBlock>
          <DigitBlock>{formatCount(fromEndTime.minutes, 1)}</DigitBlock>
        </Row>
        <Label>min</Label>
      </TimerCol>
      <span>:</span>
      <TimerCol>
        <Row mBot="4px">
          <DigitBlock>{formatCount(fromEndTime.seconds, 0)}</DigitBlock>
          <DigitBlock>{formatCount(fromEndTime.seconds, 1)}</DigitBlock>
        </Row>
        <Label>Sec</Label>
      </TimerCol>
    </TimerWrapper>
  );
};
