import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import moment from "moment";

import Api from "../../../../helpers/api";
import { AssignToGroupModal } from "../UserList/AssignToGroupModal";
import { SendEmail } from "../UserList/SendEmail";
import { DuplicateGroupModal } from "../UserList/DuplicateGroupModal";
import { AssignUserToGroupModal } from "../UserList/AssignUserToGroupModal";
import {
  Header,
  BackLink,
  AddUserButton,
  EditGroupButton,
  Flex,
  Card,
  TableHeader,
  HeaderTD,
  AvatarBlock,
  Btn,
  GroupName,
  Title,
  LastEdition,
  UsersInGroup,
  Column,
} from "./styled";

import { ReactComponent as ArrowLeft } from "../../../../assets/arrowleft.svg";
import { ReactComponent as Arrow } from "../../../../assets/chevron-down.svg";
import { ReactComponent as SuspendIcon } from "../../../../assets/slash.svg";
import { ReactComponent as DuplicateIcon } from "../../../../assets/duplicate.svg";
import { ReactComponent as EmailAllUsers } from "../../../../assets/email-all-users.svg";

import { UserRow } from "../UserList/UserRow";
import {
  ErrorMessage,
  SuccessMessage,
  ConfirmMessagePortal,
} from "../../errorMessage";

const GroupInfo = ({
  history,
  token,
  match: {
    params: { id },
  },
}) => {
  const [group, setGroup] = useState([]);
  const [groups, setGroups] = useState([]);
  const [load, setLoad] = useState(true);
  const [users, setUsers] = useState([]);
  const [filter, setFilter] = useState("INVESTOR");
  const [currentPage, setCurrentPage] = useState(1);
  const [keyword, setKeyword] = useState("");
  const [total, setTotal] = useState(0);

  const [mailData, setMailData] = useState({});
  const [openSendEmail, setOpenSendEmail] = useState(false);
  const [userId, setUserId] = useState(null);

  const [error, setError] = useState(null);
  const [success, setSuccess] = useState("");
  const [wait, setWait] = useState(false);
  const [openAssignToGroup, setOpenAssignToGroup] = useState(false);
  const [openDuplicateModal, setOpenDuplicateModal] = useState(false);
  const [openAssignUserToGroup, setOpenAssignUserToGroup] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);

  const toggleModalSendEmail = (name, email) => {
    setMailData({
      userName: name,
      userEmail: email,
    });
    setOpenSendEmail(!openSendEmail);
  };

  const loadUsers = async (role = "ALL") => {
    const { content, totalPages } = await Api.getUsers(
      token,
      role,
      currentPage,
      1000, // size
      keyword
    ).catch((e) => setError(e));
    setTotal(totalPages * 10);
    const hashMap = [];
    const logoPromises = [];
    for (let user of content) {
      user.activityStatus = user.activityStatus === "ACTIVE";
      user.sharesDetailed = JSON.parse(user.sharesDetailed);

      hashMap.push(user.id);
      logoPromises.push(
        Api.getAttachment(token, user.userLogoHash, user.userLogoFileName)
      );
    }

    setUsers(content);
  };

  const toggleDuplicateModal = () => {
    setOpenDuplicateModal(!openDuplicateModal);
  };

  const toggleAssignModal = (id) => {
    setOpenAssignToGroup(!openAssignToGroup);
    setUserId(id);
  };

  const toggleAssignModalUserToGroup = () => {
    setOpenAssignUserToGroup(!openAssignUserToGroup);
  };

  const deleteGroup = async () => {
    onChangeConfirm();
    setWait(true);
    const res = await Api.deleteGroupById(token, id).catch((e) => {
      setWait(false);
      setError(e);
    });
    if (res) {
      setWait(false);
      setSuccess("Group successfuly deleted.");
    }
  };

  const onRefreshAfterDeleteUser = async () => {
    setLoad(true);
    const group = await Api.getGroupById(token, id).catch((e) => {
      setError(e);
    });
    if (group) {
      setGroup(group);
      setLoad(false);
    }
  };

  const onRefresh = async () => {
    setLoad(true);
    const groups = await Api.getUserGroups(token).catch((e) => {
      setError(e);
    });
    if (groups) {
      setGroups(groups);
      setLoad(false);
    }
  };

  useEffect(() => {
    setUsers([]);
    loadUsers(filter);
  }, [currentPage, keyword]);

  useEffect(() => {
    if (currentPage === 1) {
      setUsers([]);
      loadUsers(filter);
    } else {
      setCurrentPage(1);
    }
  }, [filter]);

  useEffect(() => {
    loadUsers(filter);
    const getInitial = async () => {
      const groups = await Api.getUserGroups(token).catch((e) => {
        setError(e);
      });
      const group = await Api.getGroupById(token, id).catch((e) => {
        setError(e);
      });

      if (group && groups) {
        setGroup(group);
        setGroups(groups);
        setLoad(false);
      }
    };
    getInitial();
  }, []);

  const onChangeConfirm = () => {
    setConfirmOpen(!confirmOpen);
  };

  if (load) {
    return <CircularProgress style={{ margin: "auto" }} />;
  } else {
    return (
      <Fragment>
        <Header>
          <BackLink to="/admin/users/groups">
            <ArrowLeft /> back to Groups
          </BackLink>
          <AddUserButton onClick={toggleAssignModalUserToGroup}>
            add new user
          </AddUserButton>
          <EditGroupButton to={`../edit-group/${id}`}>
            edit group
          </EditGroupButton>
        </Header>
        <Flex>
          <Card big>
            <AvatarBlock
              token={token}
              file={group.logoFileName}
              hash={group.logoHash}
            />
            <section>
              <GroupName>{group.name}</GroupName>
              <div>
                <Column>
                  <Title>Users in group</Title>
                  <UsersInGroup>
                    {group.users.length > 1
                      ? `${group.users.length} users`
                      : `${group.users.length} user`}
                  </UsersInGroup>
                </Column>
                <Column>
                  <Title>Last edition</Title>
                  <LastEdition>
                    {group.lastEdition
                      ? moment(new Date(group.lastEdition)).format(
                          "DD MMM YYYY, hh:mm a"
                        )
                      : "Not modified"}
                  </LastEdition>
                </Column>
              </div>
            </section>
          </Card>
          <Card>
            <Btn
              margin="0 0 12px 0"
              fullWidth
              onClick={() => {
                history.push(`/admin/users/group/send-emails/${id}/fromGroup`);
              }}
              disabled={group.users.length > 0 ? false : true}
            >
              <EmailAllUsers /> send e-mail to all users
            </Btn>
            <Btn
              margin="0 0 12px 0"
              fullWidth
              transparent
              onClick={toggleDuplicateModal}
            >
              <DuplicateIcon /> duplicate
            </Btn>
            <Btn error fullWidth onClick={onChangeConfirm} disabled={wait}>
              <SuspendIcon /> delete
            </Btn>
          </Card>
        </Flex>

        {group.users.length ? (
          <Fragment>
            <TableHeader justify="space-between">
              <HeaderTD
                boxSizing="border-box"
                padding="0 0 0 25px"
                blue
                width="210px"
              >
                User <Arrow />
              </HeaderTD>
              <HeaderTD boxSizing="border-box" padding="0 25px" width="230px">
                E-mail
              </HeaderTD>
              <HeaderTD boxSizing="border-box" padding="0 25px" width="160px">
                verified status
              </HeaderTD>
              <HeaderTD boxSizing="border-box" padding="0 25px" width="120px">
                portfolio
              </HeaderTD>
              <HeaderTD boxSizing="border-box" padding="0 25px" width="120px">
                Last login
              </HeaderTD>
              <HeaderTD
                boxSizing="border-box"
                padding="0 25px"
                width="295px"
              ></HeaderTD>
            </TableHeader>
            {group.users.map((user) => {
              return (
                <UserRow
                  key={`user-${user.id}`}
                  token={token}
                  {...user}
                  goToDetails={() => {
                    history.push(
                      `/admin/user/${user.username}/${user.id}/${id}`
                    );
                  }}
                  toggleModalSendEmail={toggleModalSendEmail}
                  toggleAssignModal={toggleAssignModal}
                  groupInfo={true}
                  userId={user.id}
                  groupId={id}
                  onRefresh={onRefreshAfterDeleteUser}
                />
              );
            })}
          </Fragment>
        ) : null}

        <DuplicateGroupModal
          token={token}
          isOpen={openDuplicateModal}
          onBackdropClick={toggleDuplicateModal}
          groupId={group.id}
        />

        <AssignUserToGroupModal
          onRefresh={() => {
            onRefresh();
            onRefreshAfterDeleteUser();
          }}
          token={token}
          isOpen={openAssignUserToGroup}
          onBackdropClick={toggleAssignModalUserToGroup}
          group={group}
          users={users}
        />

        <AssignToGroupModal
          onRefresh={onRefresh}
          token={token}
          isOpen={openAssignToGroup}
          onBackdropClick={toggleAssignModal}
          user={group.users.find(({ id }) => id === userId)}
          groups={groups}
        />
        <SendEmail
          token={token}
          mailData={mailData}
          onBackdropClick={toggleModalSendEmail}
          isOpen={openSendEmail}
        ></SendEmail>
        {error ? (
          <ErrorMessage er={error} onClose={() => setError(null)} />
        ) : null}
        {success ? (
          <SuccessMessage
            message={success}
            onClose={() => history.push("/admin/users/groups")}
          />
        ) : null}
        {confirmOpen ? (
          <ConfirmMessagePortal
            heading="Confirm"
            onClose={onChangeConfirm}
            onSubmit={deleteGroup}
            message={`Are you sure you want to delete this Group?`}
          />
        ) : null}
      </Fragment>
    );
  }
};

export default connect(
  ({ userReducer: { token } }) => ({ token }),
  null
)(GroupInfo);
