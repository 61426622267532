import React, { useState, useEffect, Fragment, useRef } from "react";
import { CircularProgress } from "@material-ui/core";
import styled from "styled-components";

import { Button } from "components/investor-layout/pages/Offerings/styled";
import Api from "./api";
import { ReactComponent as Next } from "assets/arrow-left-slim.svg";
import { ReactComponent as Check } from "assets/check.svg";
import { Section } from "./components";
import { Status } from "./statuses";
import { ErrorMessagePortal, SuccessMessage } from "components/admin-layout/errorMessage";
import { CardHeading, InfoText, Card as BaseCard } from "../styled";

const Row = styled.div`
  height: 60px;
  background: #ffffff;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 0 30px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
  &:last-child {
    margin-bottom: 0;
    margin-top: 30px;
  }
`;

const Card = styled.div`
  width: 100%;
  padding: 30px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  background: #ffffff;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  &:before {
    content: "";
    display: block;
    position: absolute;
    height: 8px;
    width: 100%;
    left: 0;
    top: 0;
    background: #f0f0f0;
  }
  &:after {
    content: "";
    display: block;
    position: absolute;
    height: 8px;
    width: ${({ w }) => `${w}%`};
    left: 0;
    top: 0;
    background: ${({ theme: { primary } }) => primary};
    transition: width 0.3s linear;
  }
`;

export const KYC = ({
  token,
  match: {
    params: { assetId },
  },
  history,
}) => {
  const [form, setForm] = useState(null);
  const [step, setStep] = useState(0);
  const [wait, setWait] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState("");
  const [status, setStatus] = useState(null);
  const [note, setNote] = useState("");
  const [notAvailable, setNotAvailable] = useState(false);
  const formRef = useRef(null);
  const formIdRef = useRef(null);
  const submitedRef = useRef(null);
  const statusRef = useRef(null);

  const getForm = async () => {
    const res = await Api.getForm(token, assetId).catch(onError);
    if (res && !res.idForm) setNotAvailable(true);
    else if (res && res.answerJson && res.idForm) {
      setForm(JSON.parse(res.answerJson));
      setNote(res.note);
      setStatus(res.status);
      statusRef.current = res.status;
      formIdRef.current = res.idForm;
    }
  };

  useEffect(() => {
    getForm();

    return async () => {
      if (formRef.current && !submitedRef.current && (statusRef.current === "DRAFT" || !statusRef.current)) {
        const _form = await replaceFiles(formRef.current);
        await Api.saveToDraft(token, _form, formIdRef.current);
      }
    };
  }, []);

  const onChangeStep = (dir) => () => {
    window.scrollTo({ left: 0, top: 0, behavior: "smooth" });
    setStep(dir ? step + 1 : step - 1);
  };

  const onChangeSimple = ({ id, value }) => {
    const _form = { ...form };
    const fieldIndex = _form.sections[step].fields.findIndex((f) => f.id === id);
    _form.sections[step].fields[fieldIndex].value = value;
    setForm(_form);
  };

  const onChangeSpecific = ({ id, type, val }) => {
    const _form = { ...form };
    const fieldIndex = _form.sections[step].fields.findIndex((f) => f.id === id);

    if (type === "Multiple Answers" || type === "Single Answer") _form.sections[step].fields[fieldIndex].values = val;
    else if (type === "Upload File") _form.sections[step].fields[fieldIndex].attachments = val;
    setForm(_form);
  };

  useEffect(() => {
    formRef.current = form;
  }, [form]);

  const disabled = () => {
    let res;
    const fields = form.sections[step].fields;
    for (let i = 0; i < fields.length; i++) {
      if (
        !fields[i].value &&
        !fields[i].values.length &&
        !fields[i].attachments.length &&
        fields[i].required &&
        fields[i].visibility
      ) {
        console.log(fields[i]);
        res = true;
        break;
      } else res = false;
    }
    return res;
  };

  const onError = (e) => {
    setWait(false);
    setError(e);
  };

  const replaceFiles = async (form) => {
    const _form = { ...form };
    //sections cicle
    for (let i = 0; i < _form.sections.length; i++) {
      //section fields cicle
      for (let j = 0; j < _form.sections[i].fields.length; j++) {
        if (_form.sections[i].fields[j].type === "Upload File") {
          //file-type field cicle
          for (let k = 0; k < _form.sections[i].fields[j].attachments.length; k++) {
            if ("type" in _form.sections[i].fields[j].attachments[k]) {
              const formData = new FormData();
              formData.append(
                `file`,
                _form.sections[i].fields[j].attachments[k],
                _form.sections[i].fields[j].attachments[k].name
              );
              const file = await Api.uploadAttachment(token, formData).catch(onError);

              if (file) _form.sections[i].fields[j].attachments.splice(k, 1, file);
            }
          }
        }
      }
    }
    return _form;
  };

  const onSubmit = async () => {
    setWait(true);
    //replace files
    const _form = await replaceFiles(form);
    //submit
    const submit = await Api.submitForm(token, JSON.stringify(_form), formIdRef.current).catch(onError);
    if (submit) {
      setWait(false);
      setSuccess(submit);
      submitedRef.current = true;
    }
  };

  if (!form && !notAvailable && !error) return <CircularProgress style={{ margin: "auto" }} />;
  else if (notAvailable)
    return (
      <BaseCard align="center" justify="center" height="237px" padding="20px 30px">
        <CardHeading margin="0 auto 24px">KYC form is not available yet</CardHeading>
        <InfoText>The form will be available in the next steps of the investment workflow.</InfoText>
        <Button
          width={240}
          height={42}
          size={14}
          margin="42px 0 0"
          onClick={() => history.push("/investor/offerings/all")}
        >
          Go to offerings
        </Button>
      </BaseCard>
    );
  else if (form) {
    const sections = form.sections;
    const lastStep = step === sections.length - 1;
    return (
      <Fragment>
        {/* statuses card */}
        {status && status !== "DRAFT" ? <Status status={status} note={note} /> : null}
        {/* form */}
        {status !== "PROCESSING" && status !== "APPROVED" ? (
          <Fragment>
            <Card w={(100 / sections.length) * (step + 1)}>
              <Section
                key={`section-${sections[step].sectionName}`}
                s={sections[step]}
                step={step}
                steps={sections.length}
                onChangeSimple={onChangeSimple}
                onChangeSpecific={onChangeSpecific}
              />
            </Card>
            <Row>
              {step ? (
                <Button hide width={206} onClick={onChangeStep(0)}>
                  <Next style={{ marginLeft: 7, transform: "rotate(180deg)" }} /> Prev Step
                </Button>
              ) : (
                <div />
              )}
              <Button width={206} disabled={disabled() || wait} onClick={lastStep ? onSubmit : onChangeStep(1)}>
                {lastStep ? (
                  <Fragment>
                    <Check style={{ marginRight: 7 }} /> Send Application
                  </Fragment>
                ) : (
                  <Fragment>
                    Next Step <Next style={{ marginLeft: 7 }} />
                  </Fragment>
                )}
              </Button>
            </Row>
            {error && <ErrorMessagePortal er={error} onClose={() => onError(null)} />}
            {success && (
              <SuccessMessage
                message={success}
                onClose={() => {
                  setSuccess("");
                  getForm();
                }}
              />
            )}
          </Fragment>
        ) : null}
      </Fragment>
    );
  } else {
    return <ErrorMessagePortal er={error} onClose={() => onError(null)} />;
  }
};
