import React, { Fragment } from "react";
import { Col, Separator, Row } from "./styled";

import { Rules } from "../../FullFormCards/Rules";
import { BankAccount } from "./BankAccount";
import { KycUserType } from "./KycUserType";
import { PreviousRound } from "../../FullFormCards/PreviousRounds";
import { CurrentRound } from "../../FullFormCards/CurrentRound";
import { DinamicFinancial } from "../../FullFormCards/DinamicFinancial";

export const FinancialsAndRules = (props) => {
  const { defaultValues, onChangeDynamicJson, kycForms, kycRoles, onChangeKyc } = props;
  const newDeal = props.dealType === "NEW_DEAL";

  return (
    <Row alignItems="flex-start" marginTop="0">
      <Col alignItems="flex-start" width="calc(69% - 30px)" marginTop="0">
        <DinamicFinancial
          onChange={(data) => onChangeDynamicJson(data)}
          _data={defaultValues.assetProperties.dynamicFinancialJson}
        />
        <Separator />
        {newDeal ? (
          <Fragment>
            <CurrentRound
              currency={defaultValues.assetFinancialProperty.baseCurrency}
              defaultValues={defaultValues}
              onChange={props.onChange}
            />
            <Separator />
          </Fragment>
        ) : null}

        <PreviousRound defaultValues={defaultValues} onChange={props.onChangeRound} />
        {newDeal ? (
          <Fragment>
            <Separator />
            <BankAccount {...props} />
          </Fragment>
        ) : null}
      </Col>
      <Col alignItems="flex-start" width="31%" marginTop="0">
        {newDeal ? (
          <Fragment>
            <KycUserType
              assetKycTemplates={defaultValues.assetKycTemplates}
              kycForms={kycForms}
              kycRoles={kycRoles}
              onChangeKyc={onChangeKyc}
            />
            <Separator />
          </Fragment>
        ) : null}

        <Rules {...props} />
        <Separator />
      </Col>
    </Row>
  );
};
