import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import { PieChart } from "react-minimal-pie-chart";

import { Btn } from "../../../buttons";
import {
  EditBtn,
  ChartBlock,
  ChartRow,
  DataBlock,
  Parameter,
  ParamBlock,
  ColorPickerComponent,
  RemoveBlock,
} from "./styled";
import { ReactComponent as Plus } from "../../../../../assets/plus-circle.svg";
import { ReactComponent as Cross } from "../../../../../assets/cross-grey.svg";

import {
  Card,
  HeadingCard,
  RowCard,
  Background,
  Wrapper,
  HeaderModal,
  Heading,
  Content,
  ContentScroll,
  FoooterModal,
  PaddingBox,
  ModalText,
} from "../styled";
import { TextFieldComponent, TextFieldComponentPlaceholder } from "../../../forms";
import { icons } from "../../../../../models/icons";
import { getUniqName } from "../../../../../helpers/getUniqName";

const colors = ["#6781D4", "#3E43B4", "#6876A0", "#2F55CF", "#20263C", "#9CA2DB", "#BFDCFC", "#3E4BC1"];

export const DinamicCapital = ({ json, onChange, baseCurrency }) => {
  const [editOpen, setEditOpen] = useState(false);
  const [data, setData] = useState(json ? JSON.parse(json) : { cardName: "Capital Structure", fields: [] });

  const onChangeEdit = () => setEditOpen(!editOpen);

  const { cardName, fields } = data;

  useEffect(() => {
    onChange(data);
  }, [data]);

  const getChartValues = () =>
    fields.map(({ name, value, color }) => ({
      title: name,
      value: +value,
      color,
    }));

  const getTotal = () => {
    let total = 0;
    fields.forEach((e) => {
      total = total + +e.value;
    });
    return total;
  };

  const renderParams = () => {
    return fields.map((el) => {
      return <Parameter item={el} key={el.name} baseCurrency={baseCurrency} total={getTotal()} />;
    });
  };

  return (
    <Card pb="25px">
      <RowCard>
        <HeadingCard>{cardName}</HeadingCard>
        <EditBtn onClick={onChangeEdit}>
          {icons.edit}
          <span>Edit</span>
        </EditBtn>
      </RowCard>
      {fields.length ? (
        <ChartRow>
          <DataBlock>
            {renderParams()}
            <Parameter item={{ name: "total", value: getTotal() }} baseCurrency={baseCurrency} total={getTotal()} />
          </DataBlock>
          <ChartBlock>
            <PieChart data={getChartValues()} />
          </ChartBlock>
        </ChartRow>
      ) : null}

      {editOpen ? (
        <EditFields originalData={data} onBackdropClick={onChangeEdit} onSubmit={setData} baseCurrency={baseCurrency} />
      ) : null}
    </Card>
  );
};

const EditFields = ({ onBackdropClick, originalData, onSubmit, baseCurrency }) => {
  const original = { ...originalData };
  original.fields.forEach((e) => (e.key = Math.round(Math.random() * 1000)));
  const [data, setData] = useState(JSON.parse(JSON.stringify(original)));
  const scrollRef = useRef(null);

  const onRename = (obj) => {
    const { name, index } = obj;
    const _data = { ...data };
    _data.fields[index].name = name;
    setData(_data);
  };

  const onChangeColor = (obj) => {
    const { color, index } = obj;
    const _data = { ...data };
    _data.fields[index].color = color;
    setData(_data);
  };

  const onChangeValue = (obj) => {
    const { val, index } = obj;
    const _data = { ...data };
    _data.fields[index].value = val;
    setData(_data);
  };

  const onCreateNewField = () => {
    const _data = { ...data };
    const _color =
      _data.fields.length < 7 ? colors[_data.fields.length] : "#" + (((1 << 24) * Math.random()) | 0).toString(16);

    _data.fields.push({
      name: `${getUniqName(data.fields, "New field")}`,
      value: "",
      color: _color,
      key: Math.round(Math.random() * 1000),
    });
    new Promise((res) => {
      setData(_data);
      res();
    }).then(() => {
      if (scrollRef && scrollRef.current) {
        scrollRef.current.scroll({
          left: 0,
          top: 1000000000,
          behavior: "smooth",
        });
      }
    });
  };

  const onRemove = (index) => {
    const _data = { ...data };
    _data.fields.splice(index, 1);
    setData(_data);
  };

  const onSave = () => {
    onSubmit(data);
    onBackdropClick();
  };

  const onAbort = () => {
    setData({ ...originalData });
    onBackdropClick();
  };

  const renderFields = () => {
    return data.fields.map((item, i) => {
      return (
        <ParamItem
          key={`item-${item.key}`}
          item={item}
          i={i}
          baseCurrency={baseCurrency}
          onRemove={onRemove}
          onChangeValue={onChangeValue}
          onRename={onRename}
          onChangeColor={onChangeColor}
        />
      );
    });
  };

  const customBehavior = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  useEffect(() => {
    const onEnterPress = (e) => {
      if (e.charCode === 13 && e.target && e.target.nodeName === "BUTTON") e.preventDefault();
    };

    window.addEventListener("keypress", onEnterPress);

    return () => window.removeEventListener("keypress", onEnterPress);
  }, []);

  return ReactDOM.createPortal(
    <Background>
      <Wrapper width={675} onClick={customBehavior}>
        <HeaderModal>
          <Heading>Capital Structure</Heading>
          <span onClick={onBackdropClick}>{icons.close}</span>
        </HeaderModal>
        <ModalText>
          {data.fields.length
            ? "Please fill the name, value and color of item"
            : 'Please click on "Add new" button to create first field '}
        </ModalText>
        <PaddingBox>
          {data.fields.length ? (
            <ContentScroll id="scrollRef" ref={scrollRef}>
              <Content>{renderFields()}</Content>
            </ContentScroll>
          ) : null}
        </PaddingBox>

        <FoooterModal>
          <Btn hide onClick={onAbort}>
            cancel
          </Btn>
          <Btn width={180} transparent margin="0 10px 0 auto" onClick={onCreateNewField}>
            <Plus /> Add new
          </Btn>
          <Btn width={180} onClick={onSave}>
            Save
          </Btn>
        </FoooterModal>
      </Wrapper>
    </Background>,
    document.getElementById("round-modal")
  );
};

const ParamItem = ({
  item: { name, color, value },
  i,
  baseCurrency,
  onRemove,
  onRename,
  onChangeValue,
  onChangeColor,
}) => {
  const onRemoveField = () => {
    onRemove(i);
  };

  const onSetValue = (e) => {
    onChangeValue({ val: e.target.value, index: i });
  };

  const onSetName = (e) => {
    onRename({ name: e.target.value, index: i });
  };

  const onSetColor = (e) => {
    onChangeColor({ color: e.target.value, index: i });
  };

  return (
    <ParamBlock>
      <TextFieldComponent
        width="300px"
        label={`item#${i + 1}`}
        placeholder={name}
        defaultValue={name}
        onChange={onSetName}
      />
      <TextFieldComponentPlaceholder
        width="173px"
        padding="0 50px 0 20px"
        pseudo
        pseudoText={baseCurrency}
        defaultValue={value}
        onChange={onSetValue}
        type="number"
      />
      <ColorPickerComponent defaultValue={color} onChange={onSetColor} />
      <RemoveBlock onClick={onRemoveField}>
        <Cross />
      </RemoveBlock>
    </ParamBlock>
  );
};
