import React, { useState, useEffect, Fragment, useRef } from "react";
import { connect } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";

import Api from "../../../../helpers/api";
import { Wrapper } from "../../main";
import { NewAssetVal } from "./newAssetVal";
import { ErrorMessage, SuccessMessage } from "../../errorMessage";
import { getEvents } from "modules/dataRedux/dataAction";

import { ExisedDeal } from "./ExistedDeal/";
import { NewDeal } from "./NewDeal";

const AssetDetails = ({
  token,
  match: {
    params: { id },
  },
  history,
  socketConnect,
  adminChatThemes,
  unreadMessages,
  role,
  getEvents,
}) => {
  const [deal, setDeal] = useState(null);
  const [newValModalOpen, openNewValModal] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState("");
  const once = useRef(true);

  const changeValModalStatus = () => openNewValModal(!newValModalOpen);

  const getAsset = async () => {
    const _asset = await Api.getAsset(token, id);
    if (_asset) setDeal(_asset);
  };

  const onChangeAssetVal = (result) => {
    if (result) {
      setSuccess("Successfuly updated");
      getAsset();
    }
  };

  useEffect(() => {
    getAsset();
  }, []);

  useEffect(() => {
    if (socketConnect || (socketConnect && unreadMessages)) {
      if(once.current) {
        once.current =false;
        Api.getInvestorsMessages(token);
      }  
    }
  }, [socketConnect, unreadMessages]);

  if (!deal) {
    return (
      <Wrapper width="100%" justifyContent="center" alignItems="center" bg="transparent">
        <CircularProgress />
      </Wrapper>
    );
  } else {
    const existed = deal.assetProperties.dealsType === "EXISTED_DEAL";

    return existed ? (
      <ExisedDeal token={token} deal={deal} history={history} adminChatThemes={adminChatThemes} />
    ) : (
      <Fragment>
        <NewDeal
          token={token}
          deal={deal}
          history={history}
          adminChatThemes={adminChatThemes}
          role={role}
          getEvents={getEvents}
          changeValModalStatus={changeValModalStatus}
        />
        {/* Modal */}
        {newValModalOpen ? (
          <NewAssetVal
            callBack={onChangeAssetVal}
            token={token}
            assetId={id}
            onBackDropClick={changeValModalStatus}
            financial={deal.assetFinancialProperty}
            setErrorMessage={setError}
          />
        ) : null}

        {success ? <SuccessMessage message={success} onClose={() => setSuccess("")} /> : null}
        {error && <ErrorMessage er={error} onClose={() => setError(null)} />}
      </Fragment>
    );
  }
};

export default connect(
  ({
    userReducer: { token, role },
    dataReducer: { assetInfo, assets },
    chatReducer: { unreadMessages, socketConnect, adminChatThemes },
  }) => ({
    token,
    assetInfo,
    assets,
    socketConnect,
    unreadMessages,
    adminChatThemes,
    role,
  }),
  { getEvents }
)(AssetDetails);
