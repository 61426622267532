import React, { useEffect } from "react";

import { ReactComponent as CloseIcon } from "../../../../assets/x-circle.svg";
import { ModalBackground, ModalWrapper, ModalHeader, ModalHeading, Col, Row } from "../Investment/styled";
import { Button } from "../Offerings/styled";

export const ChooseSignType = ({ isOpen, onBackDropClick, onDigitalSign, onDocuSign, signToolOptions = [] }) => {
  useEffect(() => {
    if (isOpen && signToolOptions.length === 1) {
      signToolOptions[0] === "INTERNAL_SIGN" ? onDigitalSign() : onDocuSign();
    }
  });

  return (
    isOpen && (
      <ModalBackground onClick={onBackDropClick}>
        <ModalWrapper onClick={(e) => e.stopPropagation()}>
          <ModalHeader boxShadow="0px 10px 40px rgba(89, 120, 150, 0.08)">
            <ModalHeading>Choose signature type</ModalHeading>
            <CloseIcon onClick={onBackDropClick} />
          </ModalHeader>
          <Col padding="25px 30px" align="center">
            <Row width="100%" justify="space-between">
              <Button
                width={176}
                height={36}
                size={12}
                disabled={!signToolOptions.includes("INTERNAL_SIGN")}
                onClick={signToolOptions.includes("INTERNAL_SIGN") ? onDigitalSign : undefined}
              >
                Digital signature
              </Button>
              <Button
                width={176}
                height={36}
                size={12}
                disabled={!signToolOptions.includes("DOCUSIGN")}
                onClick={signToolOptions.includes("DOCUSIGN") ? onDocuSign : undefined}
              >
                DocuSign
              </Button>
            </Row>
          </Col>
        </ModalWrapper>
      </ModalBackground>
    )
  );
};
