import React, { useState, useEffect, useCallback } from "react";
import { Main, TextSub } from "../styled";
import { TextFieldComponent, SelectComponent } from "../../../../forms";

import { InputRow } from "../Information/styled";
import { icons } from "../../../../../../models/icons";
import { Button, Remove } from "./styled";

export const KycUserType = ({ onChangeKyc, assetKycTemplates, kycForms, kycRoles }) => {
  console.log(assetKycTemplates);
  const [kyc, setKyc] = useState({ ...assetKycTemplates });

  const { institutionTemplateId, professionalTemplateId, otherRolesTemplates } = kyc;
  const dynamicAvailable = Object.keys(otherRolesTemplates).length < kycRoles.length;

  const forms = useCallback(
    () => kycForms.map(({ id, jsonKYCForm }) => ({ id, name: JSON.parse(jsonKYCForm).templateName })),
    [kycForms]
  )();

  const formsNames = useCallback(() => forms.map(({ name }) => name), [forms])();

  const getSelectVal = useCallback((id) => {
    const i = forms.findIndex((f) => f.id === id);
    return i !== -1 ? forms[i].name : "none";
  });

  const onChangeStatic = (e, type) => {
    const _kyc = { ...kyc };
    const index = forms.findIndex((f) => f.name === e.target.value);
    _kyc[type] = forms[index].id;
    setKyc(_kyc);
  };

  const onAddDynamic = () => {
    const _kyc = { ...kyc };
    const uniqIndex = kycRoles.findIndex((r) => !Object.keys(otherRolesTemplates).includes(r));
    console.log(uniqIndex);
    _kyc.otherRolesTemplates[kycRoles[uniqIndex]] = forms[0].id;
    setKyc(_kyc);
  };

  const onRemoveDynamic = (type) => () => {
    const _kyc = { ...kyc };
    delete _kyc.otherRolesTemplates[type];
    setKyc(_kyc);
  };

  const onChangeDynamicType = (e, prevRole) => {
    const _kyc = { ...kyc };
    _kyc.otherRolesTemplates[e.target.value] = _kyc.otherRolesTemplates[prevRole];
    delete _kyc.otherRolesTemplates[prevRole];
    setKyc(_kyc);
  };

  const onChangeDynamicVal = (e, role) => {
    const _kyc = { ...kyc };
    const index = forms.findIndex((f) => f.name === e.target.value);
    _kyc.otherRolesTemplates[role] = forms[index].id;
    setKyc(_kyc);
  };

  const renderExtra = () => {
    const extra = Object.keys(otherRolesTemplates);
    return extra.map((role, i) => (
      <InputRow last={!dynamicAvailable && i === extra.length - 1} key={role} otherStyles="position: relative;">
        <SelectComponent
          padding="0 42px 0 20px"
          label={`USER TYPE #${i + 3}`}
          values={kycRoles}
          value={role}
          onChange={(e) => onChangeDynamicType(e, role)}
          width="calc(50% - 6px)"
          blackList={extra}
        />
        <SelectComponent
          padding="0 42px 0 20px"
          hiddenLabel
          label="USER TYPE #1"
          values={formsNames}
          value={getSelectVal(otherRolesTemplates[role])}
          onChange={(e) => onChangeDynamicVal(e, role)}
          width="calc(50% - 6px)"
        />
        <Remove onClick={onRemoveDynamic(role)}>{icons.crossRed} Remove</Remove>
      </InputRow>
    ));
  };

  useEffect(() => {
    onChangeKyc(kyc);
  }, [kyc]);

  if (kycForms.length && kycRoles.length) {
    return (
      <Main noHidden>
        <TextSub textAlign="left" fontWeight="bold" color="#181818" marginSize="0 0 25px 0">
          KYC
        </TextSub>
        <InputRow>
          <TextFieldComponent label="USER TYPE #1" value="INSTITUTIONAL" width="calc(50% - 6px)" />
          <SelectComponent
            padding="0 42px 0 20px"
            hiddenLabel
            label="USER TYPE #1"
            values={formsNames}
            value={getSelectVal(institutionTemplateId)}
            onChange={(e) => onChangeStatic(e, "institutionTemplateId")}
            width="calc(50% - 6px)"
          />
        </InputRow>
        <InputRow>
          <TextFieldComponent label="USER TYPE #2" value="PROFESSIONAL" width="calc(50% - 6px)" />{" "}
          <SelectComponent
            padding="0 42px 0 20px"
            hiddenLabel
            label="USER TYPE #1"
            values={formsNames}
            value={getSelectVal(professionalTemplateId)}
            onChange={(e) => onChangeStatic(e, "professionalTemplateId")}
            width="calc(50% - 6px)"
          />
        </InputRow>
        {renderExtra()}
        {dynamicAvailable ? (
          <div>
            {/* hot fix */}
            <Button style={{ right: 0, top: 0 }} p="relative" onClick={onAddDynamic}>
              {icons.add}
              <span>ADD USER TYPE</span>
            </Button>
          </div>
        ) : null}
      </Main>
    );
  } else {
    return null;
  }
};
