import React, { useState, useRef } from "react";

import Api from "../../../../helpers/api";
import {
  ModalBg,
  SignSettingsWrapper,
  SignSettingsHeader,
  SignImageUploader,
  SeparatorModal,
  ModalButtonBlock,
  SignImageUploaderLabel,
  ImageUploader,
  ImageUploaderController,
  ControllerLabel,
  Controllers,
  Control,
  SignSettingsContentWrapper,
} from "./styled";
import { Button } from "../Offerings/styled";
import { ReactComponent as Close } from "../../../../assets/x-circle.svg";
import { Loader } from "../../styled/Loader";
import { fileEncodeToBase64 } from "../../../../helpers/";
import { ErrorMessage } from "../../../admin-layout/errorMessage";

export const SignSettings = ({
  token,
  onBackdropClick,
  digitalSignature,
  modifySign,
  isSignatureNone,
}) => {
  const [file, setFile] = useState(digitalSignature);
  const [img, setImg] = useState(digitalSignature);
  const [change, setChange] = useState(false);
  const [error, setError] = useState(null);
  const [wait, setWait] = useState(false);
  const inputRef = useRef(null);

  const onRemoveFile = () => {
    setChange(true);
    setFile(null);
  };

  const onError = (e) => {
    wait && setWait(false);
    setError(e);
  };

  const onOpenFileDialog = (e) => {
    e.stopPropagation();
    if (inputRef && inputRef.current) {
      inputRef.current.click();
    }
  };

  const onAddFile = async (e) => {
    e.stopPropagation();
    if (e.target.files[0]) {
      setChange(true);
      setFile(e.target.files[0]);
      setImg(await fileEncodeToBase64(e.target.files[0]));
    }
  };

  const onSaveChanges = async () => {
    setChange(false);

    let res;
    if (isSignatureNone) {
      res = await Api.addSignature(token, file).catch(onError);
      if (res) modifySign(true);
    } else if (file) {
      res = await Api.replaceSignature(token, file).catch(onError);
      if (res) modifySign(true);
    } else {
      res = await Api.deleteSignature(token).catch(onError);
      if (res) modifySign(false);
    }

    if (res) {
      setChange(true);
      onBackdropClick();
    }
  };

  return (
    <ModalBg zIndex={1000} onClick={onBackdropClick}>
      <SignSettingsWrapper onClick={(e) => e.stopPropagation()}>
        <SignSettingsHeader>
          Sign Settings
          <Close onClick={onBackdropClick} />
        </SignSettingsHeader>
        <SignSettingsContentWrapper>
          <SignImageUploaderLabel fontSize={12}>
            Sign Image
          </SignImageUploaderLabel>
          <SignImageUploader>
            <ImageUploader>{file ? <img src={img} /> : null}</ImageUploader>
            <ImageUploaderController>
              <ControllerLabel
                onClick={file && file.name ? null : onOpenFileDialog}
              >
                {(file && (file.name || "Default sign")) || "Select file..."}
              </ControllerLabel>
              <Controllers>
                <Control onClick={onOpenFileDialog}>
                  {file && file !== "empty" ? "Change image" : "Add image"}
                </Control>
                <Control onClick={onRemoveFile}>Delete</Control>
              </Controllers>
            </ImageUploaderController>
          </SignImageUploader>
          <SeparatorModal />
          <ModalButtonBlock>
            <Button
              size={12}
              width={100}
              height={36}
              hide
              onClick={onBackdropClick}
              tTransform="uppercase"
            >
              Cancel
            </Button>
            <Button
              disabled={!change}
              size={12}
              width={180}
              height={36}
              onClick={onSaveChanges}
              tTransform="uppercase"
            >
              Save changes
            </Button>
          </ModalButtonBlock>
        </SignSettingsContentWrapper>
        <input
          onChange={onAddFile}
          ref={inputRef}
          type="file"
          accept="image/jpeg,image/png"
          style={{ display: "none" }}
        />
      </SignSettingsWrapper>
      {error && <ErrorMessage er={error} onClose={() => setError(null)} />}
    </ModalBg>
  );
};
