import styled from "styled-components";
import React, { useState, useEffect, Fragment } from "react";
import moment from "moment";

import Api from "../../../../helpers/api";
import { ReactComponent as InfoIcon } from "../../../../assets/info.wallet.svg";
import { ReactComponent as LockIcon } from "../../../../assets/lock-wallet.svg";
import { Button } from "../Offerings/styled";

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  width: 100%;
  padding: 0 30px;
  max-width: 1440px;
  box-sizing: border-box;
`;

export const ContentWrapper = styled.div`
  width: 100%;
  padding: 20px 30px;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const InfoCard = styled.div`
  width: ${({ width }) => width};
  background: ${({ bg }) => bg};
  margin: ${({ margin }) => margin};
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  height: 124px;
  border-radius: 4px;
`;

export const InfoCardHeader = styled.div`
  width: 100%;
  height: 49px;
  border-bottom: 1px solid #e5ecf3;
  padding: 0 10px 0 24px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
`;

export const InfoHeading = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 150%;
  text-transform: ${({ upper }) => (upper ? "uppercase" : "none")};
  color: #333333;
  display: flex;
  align-items: center;
`;

export const Row = styled.div`
  display: flex;
  width: ${({ width }) => (width ? width : "100%")};
  align-items: center;
  justify-content: space-between;
  height: ${({ height }) => (height ? `${height}px` : "56px")};
  box-sizing: border-box;
  padding: ${({ padding }) => padding};
  margin: ${({ margin }) => margin};
  border-right: ${({ border }) => (border ? "1px solid #E5ECF3" : "none")};
`;

export const FlexRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: ${({ margin }) => margin};
  position: relative;
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  width: 25%;
  width: ${({ width }) => width};
  padding: ${({ padding }) => padding};
  box-sizing: border-box;
`;

const ColHeading = styled.h6`
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 135%;
  letter-spacing: -0.03em;
  text-transform: uppercase;
  white-space: nowrap;
  margin: 0 0 6px 0;
  color: rgba(24, 24, 24, 0.6);
`;

export const InfoItem = ({ heading, data, width }) => (
  <Col width={width}>
    <ColHeading>{heading}</ColHeading>
    {data}
  </Col>
);

const TD = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 135%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  box-sizing: border-box;
  width: ${({ width }) => width};
  color: rgba(24, 24, 24, 0.6);
  min-width: 100px;
  padding: ${({ padding }) => padding};
  display: flex;
  align-items: center;
  position: relative;
  svg {
    margin-left: 5px;
    cursor: pointer;
  }
`;

export const Tooltip = styled.div`
  position: absolute;
  padding: 16px;
  background: #ffffff;
  border: 1px solid #efefef;
  box-sizing: border-box;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  width: ${({ width }) => (width ? width : "215px")};
  bottom: 28px;
  right: -16px;
  display: flex;
  flex-direction: column;
  z-index: 100;
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 145%;
  letter-spacing: -0.02em;
  color: #181818;
  text-transform: none;
`;

export const Triangle = styled.div`
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 20px 12.5px 0 12.5px;
  border-color: #ffffff transparent transparent transparent;
  position: absolute;
  right: ${({ right }) => (right ? `${right}px` : "-7px")};
  top: ${({ top }) => (top ? `${top}px` : "-23px")};
  z-index: 101;
`;

const DealWrapper = styled.div`
  height: 90px;
  width: 100%;
  box-sizing: border-box;
  background: ${({ white, theme: { mainBgAdmin } }) =>
    white ? "#fff" : mainBgAdmin};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const AssetLogo = styled.img`
  width: 111px;
  height: 60px;
  margin-right: 25px;
  object-fit: cover;
  @media (max-width: 1439px) {
    margin-right: 15px;
    width: 81px;
  }
`;

const NoLogo = styled.div`
  width: 111px;
  height: 60px;
  background: transparent;
  margin-right: 25px;
  @media (max-width: 1439px) {
    margin-right: 15px;
    width: 81px;
  }
`;

export const AssetName = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 150%;
  width: fit-content;
  color: ${({ theme: { primary } }) => primary};
  margin-bottom: ${({ noMargin }) => (noMargin ? 0 : "5px")};
  text-transform: none;
  border-bottom: 1px solid ${({ theme: { blurBg } }) => blurBg};
  text-decoration: none;
  cursor: pointer;
  @media (max-width: 1024px) {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
  }
`;

export const InfoTxt = styled.div`
  font-family: Muli;
  font-style: normal;
  white-space: ${({ wrap }) => wrap};
  text-align: left;
  font-weight: ${({ bold, fWeight }) =>
    bold ? "bold" : fWeight ? fWeight : "normal"};
  font-size: ${({ fSize }) => (fSize ? `${fSize}px` : "10px")};
  line-height: 150%;
  color: ${({ color }) => (color ? color : "#333333")};
  display: flex;
  align-items: center;
  text-transform: ${({ tTransform }) => tTransform};
  margin: ${({ margin }) => margin};

  span {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
  }

  > svg {
    display: ${({ none }) => (none ? "none" : "inline")};
    transform: ${({ isNegative }) => (isNegative ? "rotate(90deg)" : "none")};
    width: 14px;
    height: 14px;
    margin-left: 3px;
    path {
      stroke: ${({ color }) => (color ? color : "#333333")};
    }
  }
  > p {
    margin: 0;
    white-space: normal;
    font-family: Muli;
    text-align: left;
  }
`;

export const ActionButton = styled(Button)`
  width: ${({ width }) => `${width}px`};
  height: 36px;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  box-sizing: border-box;
  line-height: 150%;
  border: 1px solid ${({ theme: { primary } }) => primary};
  text-align: center;
  text-transform: uppercase;
  color: ${({ light, theme: { primary } }) => (light ? primary : "#FFFFFF")};
  background: ${({ light, theme: { primary } }) => (light ? "#FFF" : primary)};
  border-radius: 2px;
  margin: ${({ margin }) => margin};
  cursor: pointer;
  @media (max-width: 1439px) {
    ${({ responsive = "" }) => responsive};
  }
  > svg {
    width: 12px;
    height: 12px;
    margin-right: 7px;
    path {
      stroke: ${({ light, theme: { primary } }) =>
        light ? primary : "#FFFFFF"};
    }
  }
`;

export const CurrencyBlock = styled.div`
  width: ${({ width = "420px" }) => width};
  height: 36px;
  background: ${({ theme: { quoteBgInvestor } }) => quoteBgInvestor};
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 5px 25px;
  box-sizing: border-box;
  @media (max-width: 1439px) {
    ${({ responsive = "" }) => responsive}
  }
  > svg {
    width: 22px;
    height: 22px;
  }
`;

export const Currencies = styled.div`
  width: ${({ width = 420 }) => `${width}px`};
  display: flex;
  flex-direction: column;
  @media (max-width: 1439px) {
    ${({ responsive = "" }) => responsive}
  }
  > div {
    margin-bottom: 12px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const DealItem = ({
  history,
  white,
  token,
  asset: {
    logo,
    assetName,
    currency,
    valuation,
    tokenIdentifier,
    assetId,
    linearId,
    quantityCold,
    totalColdAmount,
    quantityHot,
    totalHotAmount,
    quantityPending,
    totalPendingAmount,
    quantityLocked,
    totalLockedAmount,
    lockedRelesedIn,
    total,
    totalAmount
  },
  onOpenSellShares,
  smallScreen
}) => {
  const [img, setImg] = useState(null);
  const [tooltipOpen, setOpen] = useState(false);

  useEffect(() => {
    if (logo) {
      Api.getAttachment(
        token,
        logo.secureHash,
        logo.fileNameWithExtension
      ).then(blob => setImg(blob));
    }
  }, []);

  const sellInfo = {
    assetName,
    tokenIdentifier,
    linearId,
    valuation,
    currency,
    quantityCold
  };

  return (
    <DealWrapper white={white}>
      <TD padding="0 0 0 20px" width="460px">
        <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
          {img ? <AssetLogo src={img} alt="logo" /> : <NoLogo />}
          <div style={{ width: "calc(100% - 115px)" }}>
            <AssetName
              onClick={() =>
                history.push(`/investor/offerings/companies/${assetId}`)
              }
            >
              {assetName}
            </AssetName>
            <InfoTxt>
              1 {tokenIdentifier} = {valuation + " " + currency}
            </InfoTxt>
          </div>
        </div>
      </TD>
      <TD width="9%">
        <div>
          <InfoTxt fSize={14} color="#8A8A8A" bold>
            {quantityCold}
          </InfoTxt>
          <InfoTxt
            color="#8A8A8A"
            dangerouslySetInnerHTML={{
              __html: `&#8776; ${totalColdAmount.toFixed(2)} ${currency}`
            }}
          />
        </div>
      </TD>
      <TD width="9%">
        <div>
          <InfoTxt fSize={14} bold>
            {quantityLocked}
            <div style={{ position: "relative", display: "flex" }}>
              <LockIcon onClick={() => setOpen(!tooltipOpen)} />
              {lockedRelesedIn.length
                ? tooltipOpen && (
                    <Fragment>
                      <Tooltip width="225px">
                        Will be unlocked at
                        <InfoTxt
                          tTransform="uppercase"
                          fWeight="bold"
                          color="#181818"
                          fSize={14}
                          margin="8px 0 0 0"
                        >
                          {moment(lockedRelesedIn[0].lockUpActivity).format(
                            "DD MMM YYYY hh:mm A"
                          )}
                        </InfoTxt>
                      </Tooltip>
                      <Triangle />
                    </Fragment>
                  )
                : null}
            </div>
          </InfoTxt>
          <InfoTxt
            dangerouslySetInnerHTML={{
              __html: `&#8776; ${totalLockedAmount.toFixed(2)} ${currency}`
            }}
          />
        </div>
      </TD>
      <TD width="9%">
        <div>
          <InfoTxt fSize={14} bold>
            {quantityHot}
          </InfoTxt>
          <InfoTxt
            dangerouslySetInnerHTML={{
              __html: `&#8776; ${totalHotAmount.toFixed(2)} ${currency}`
            }}
          />
        </div>
      </TD>
      <TD width="9%">
        <div>
          <InfoTxt fSize={14} bold>
            {quantityPending}
          </InfoTxt>
          <InfoTxt
            dangerouslySetInnerHTML={{
              __html: `&#8776; ${totalPendingAmount.toFixed(2)} ${currency}`
            }}
          />
        </div>
      </TD>
      <TD width="9%">
        <div>
          <InfoTxt fSize={14} color="#8A8A8A" bold>
            {total}
          </InfoTxt>
          <InfoTxt
            color="#8A8A8A"
            dangerouslySetInnerHTML={{
              __html: `&#8776; ${totalAmount.toFixed(2)} ${currency}`
            }}
          />
        </div>
      </TD>
      <TD width="217px" responsive>
        <ActionButton
          margin="0 20px 0 auto"
          width={172}
          onClick={() => onOpenSellShares(sellInfo)}
        >
          sell {tokenIdentifier} {smallScreen ? "" : "shares"}
        </ActionButton>
      </TD>
    </DealWrapper>
  );
};

const IconCont = styled.div`
  position: relative;
  display: flex;
  > svg {
    path {
      stroke: ${({ theme: { primary } }) => primary};
    }
  }
`;

export const TableHeader = () => {
  const [lockTooltipOpen, setLockTooltipOpen] = useState(false);
  const [pendingTooltipOpen, setPendingTooltipOpen] = useState(false);

  return (
    <Row margin="-20px 0 0 ">
      <TD width="460px">Asset</TD>
      <TD width="9%">In Wallet</TD>
      <TD width="9%">
        Lock up{" "}
        <IconCont>
          <InfoIcon onClick={() => setLockTooltipOpen(!lockTooltipOpen)} />
          {lockTooltipOpen && (
            <Fragment>
              <Tooltip>Hold period that shares can not be sold </Tooltip>
              <Triangle />
            </Fragment>
          )}
        </IconCont>
      </TD>
      <TD width="9%">On sale</TD>
      <TD width="9%">
        Pending
        <IconCont>
          <InfoIcon
            onClick={() => setPendingTooltipOpen(!pendingTooltipOpen)}
          />
          {pendingTooltipOpen && (
            <Fragment>
              <Tooltip>Waiting for transaction to be validated </Tooltip>
              <Triangle right="36px" />
            </Fragment>
          )}
        </IconCont>
      </TD>
      <TD width="9%">Total</TD>
      <TD width="217px"></TD>
    </Row>
  );
};

export const DealNameBlock = styled.div`
  width: 100%;
  height: 42px;
  background: ${({ grey, theme: { documentBgInvestor, disabledField } }) =>
    grey ? disabledField : documentBgInvestor};
  border: 1px solid
    ${({ grey, theme: { documentBgInvestor } }) =>
      grey ? "#EFEFEF" : documentBgInvestor};
  border-radius: 4px;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
`;

const Label = styled.span`
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 135%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: rgba(24, 24, 24, 0.6);
  margin-bottom: 8px;
`;

const InfoBlockTxt = styled.span`
  font-family: Muli;
  font-style: normal;
  font-weight: ${({ grey }) => (grey ? "normal" : 600)};
  font-size: 14px;
  line-height: 135%;
  letter-spacing: -0.02em;
  color: ${({ grey, theme: { primary } }) => (grey ? "#EFEFEF" : primary)};
`;

export const InfoBlock = ({ label, grey, data }) => {
  return (
    <Col width="100%">
      <Label>{label}</Label>
      <DealNameBlock grey={grey}>
        <InfoBlockTxt>{data}</InfoBlockTxt>
      </DealNameBlock>
    </Col>
  );
};

export const BorderBlock = styled.div`
  padding: 18px 0;
  border-top: 1px solid #f6f7fc;
  border-bottom: 1px solid #f6f7fc;
`;

export const CurrencyName = styled.div`
  width: 130px;
  height: 42px;
  border: 1px solid #efefef;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 135%;
  letter-spacing: -0.02em;
  color: #181818;
  margin-top: auto;
`;

export const MaxAmount = styled.div`
  width: 130px;
  height: 42px;
  background: #ffffff;
  border: 1px solid #efefef;
  box-sizing: border-box;
  border-radius: 0 4px 4px 0;
  position: absolute;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 135%;
  text-align: right;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: ${({ active, theme: { primary } }) =>
    active ? "rgba(24, 24, 24, 0.3)" : primary};
  cursor: pointer;
`;

export const ReportBtn = styled(Button)`
  width: 143px;
  height: 32px;
  border: 1px solid ${({ theme: { primary } }) => primary};
  box-sizing: border-box;
  border-radius: 4px;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 12px;
  text-align: center;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  color: ${({ theme: { primary } }) => primary};
  margin: 0 0 0 auto;
  background: transparent;
  cursor: pointer;
  > svg {
    margin-right: 7px;
    path {
      stroke: ${({ theme: { primary } }) => primary};
    }
  }
`;

export const CurrenciesBlock = styled.div`
  display: flex;
  margin-left: auto;
  > button {
    margin-right: 10px;
    width: 50px;
    &:last-child {
      margin-right: 0;
    }
  }
`;

export const EmptyCard = styled.div`
  background: #ffffff;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 25px;
  width: 1380px;
  margin: 0 auto;
  font-weight: 600;
  font-size: 16px;
  line-height: 160%;
  color: #333333;
  text-align: center;
  @media (max-width: 1439px) {
    width: calc(100% - 40px);
  }
`;
