import styled, { css } from "styled-components";
import { scroll } from "components/common/mixins";

const flex = css`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Container = styled.div`
  width: ${({ w = "100%" }) => w};
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: ${({ mBottom }) => (mBottom ? mBottom : "30px")};
`;

export const Border = styled.div`
  ${flex};
  width: ${({ w = "100%" }) => w};
  height: 42px;
  background: #ffffff;
  border: 1px solid ${({ theme: { primary }, edit }) => (edit ? primary : "#efefef")};
  box-sizing: border-box;
  border-radius: ${({ open }) => (open ? "4px 4px 0 0" : "4px")};
  box-sizing: border-box;
  padding: ${({ inProfile }) => (inProfile ? "0px 24px 0px 20px" : "3px 15px")};
  position: relative;
  cursor: pointer;
  position: relative;
  z-index: 1003;
  > svg {
    width: 16px;
    height: 16px;
    transition: all 0.1s linear;
    transform: ${({ open }) => (open ? "rotate(180deg)" : "none")};
    path {
      stroke: ${({ theme: { primary } }) => primary};
    }
  }
`;

export const Text = styled.div`
  font-size: 12px;
  line-height: 135%;
  letter-spacing: -0.02em;
  color: ${({ active = false }) => (active ? "#181818" : "rgba(24, 24, 24, 0.6)")};
  max-width: ${({ maxWidth = "100%" }) => maxWidth};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-transform: uppercase;
`;

export const Options = styled.div`
  ${scroll};
  width: 100%;
  height: ${({ open }) => (open ? "280px" : 0)};
  background: #ffffff;
  border: 1px solid #dadada;
  box-sizing: border-box;
  border-radius: 0 0 4px 4px;
  position: absolute;
  top: 38px;
  left: 0;
  z-index: 1002;
  padding: ${({ open }) => (open ? "17px 12px 5px 0" : "0 15px 0 0")};
  transition: all 0.1s linear;
  overflow-y: scroll;
  &&::-webkit-scrollbar {
    width: 4px;
  }
`;

export const Col = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0 0 0 25px;
`;

export const Row = styled.div`
  ${flex};
  font-size: 14px;
  line-height: 135%;
  letter-spacing: -0.02em;
  color: ${({ active }) => (active ? "#181818" : "rgba(24, 24, 24, 0.6)")};
  margin-bottom: 17px;
  > span {
    display: inline-block;
    margin-right: auto;
    cursor: pointer;
    width: calc(100% - 60px);
    height: 100%;
    &:hover {
      color: ${({ theme: { primary } }) => primary};
    }
  }
  > svg {
    cursor: pointer;
    width: 16px;
    height: 16px;
    transition: all 0.1s linear;
    transform: ${({ open }) => (open ? "rotate(180deg)" : "none")};
    path {
      stroke: ${({ open, theme: { primary } }) => (open ? "#CECECE" : primary)};
    }
  }
`;

export const CheckBox = styled.div`
  ${flex};
  width: 20px;
  height: 20px;
  background: #ffffff;
  border: 1px solid ${({ active, theme: { primary } }) => (active ? primary : " #dadada")};
  box-sizing: border-box;
  border-radius: 2px;
  margin-right: 10px;
  cursor: pointer;
  > * {
    margin: auto;
    width: 14px;
    height: 14px;
  }
  > svg {
    path {
      stroke: ${({ theme: { primary } }) => primary};
    }
    rect {
      fill: ${({ theme: { primary } }) => primary};
    }
  }
`;

export const Layout = styled.section`
  width: 100vw;
  height: 100vh;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1001;
`;

export const MultiplePreview = styled.div`
  width: calc(100% - 30px);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  font-size: 14px;
  line-height: 135%;
  letter-spacing: -0.02em;
  color: #181818;
  text-transform: none;
  cursor: pointer;
`;
