import React, { useState } from "react";
import { connect } from "react-redux";
import { CircularProgress } from "@material-ui/core";

import {
  FlexRow,
  FormCol,
  Heading,
  InputCont,
  Input,
  ForgetPass,
  Submit,
  FormBottom,
  LegalInfo,
  FooterLinks,
  SignUpTxt,
  SignUpLink,
  WaitingWrapper,
  ErrorBlock,
} from "./styled";

import { ReactComponent as PassIcon } from "../../assets/pass.svg";
import { ReactComponent as MailIcon } from "../../assets/mail.svg";
import { ReactComponent as AlertIcon } from "../../assets/alert-red.svg";

import { login, is2FaEnabled, set2Fa, rememberUserToggle } from "../../modules/userRedux/userAction";
import { _2faForm } from "./_2faForm";
import { isGeneral, isAlphier } from "helpers/envChecker";

const SignInForm = ({ login, is2FaEnabled, _2fa, set2Fa }) => {
  const [wait, setWait] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);

  const onEmailChange = (e) => {
    error && setError(false);
    setEmail(e.target.value);
  };
  const onPasswordChange = (e) => {
    error && setError(false);
    setPassword(e.target.value);
  };

  const submit = async () => {
    setWait(true);
    await is2FaEnabled({ email: email, password: password }, async () => {
      await login({ email: email, password: password });
    }).catch(() => {
      setError(true);
      setWait(false);
    });
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      submit();
    }
  };

  if (_2fa) {
    return (
      <_2faForm
        authData={{ email: email, password: password }}
        login={login}
        set2Fa={set2Fa}
        callBack={() => setWait(false)}
      />
    );
  } else {
    return (
      <FormCol>
        <div style={{ margin: "auto 0", position: "relative" }}>
          <Heading>Sign In</Heading>
          <InputCont error={error}>
            <Input
              error={error}
              placeholder="Your e-mail"
              value={email}
              onChange={(e) => onEmailChange(e)}
              onKeyDown={handleKeyPress}
            />
            <MailIcon />
          </InputCont>
          <InputCont error={error}>
            <Input
              error={error}
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => onPasswordChange(e)}
              onKeyDown={handleKeyPress}
            />
            <PassIcon />
            <ForgetPass to="/password-recovery">Forgot password</ForgetPass>
          </InputCont>

          {error ? (
            <ErrorBlock>
              <AlertIcon />
              The e-mail or password you entered is incorrect
            </ErrorBlock>
          ) : null}

          <Submit onClick={submit} disabled={!email || !password}>
            Sign In
          </Submit>
          <SignUpTxt>
            Don’t have an account yet?
            <SignUpLink to="/signup">Sign Up here</SignUpLink>{" "}
          </SignUpTxt>
        </div>
        {isGeneral || isAlphier ? null : (
          <FormBottom>
            <LegalInfo>LEGAL INFORMATION</LegalInfo>
            <FlexRow>
              <FooterLinks to="/info/privacy-policy">Privacy Policy</FooterLinks>
              <FooterLinks to="/info/terms-of-use">Terms and Conditions</FooterLinks>
            </FlexRow>
          </FormBottom>
        )}
        {wait && (
          <WaitingWrapper>
            <CircularProgress color="inherit" />
          </WaitingWrapper>
        )}
      </FormCol>
    );
  }
};

export default connect(
  ({ userReducer: { rememberUser, _2fa } }) => ({
    rememberUser,
    _2fa,
  }),
  { login, is2FaEnabled, set2Fa, rememberUserToggle }
)(SignInForm);
