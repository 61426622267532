import React, { useState, useRef, Fragment, useEffect } from "react";

import Api from "helpers/api";
import {
  SetupWrapper,
  MailColumn,
  Card,
  CardHeader,
  Uploader,
  FileCard,
  CenterContainer,
  FileInfo,
  Waiting,
  SubmitBlock,
  UploadersCol,
  EmptyBlock
} from "./styled";
import { ReactComponent as Info } from "../../../../assets/info.svg";
import { ReactComponent as File } from "../../../../assets/file-text.svg";
import { ReactComponent as Rem } from "../../../../assets/close.svg";
import { ReactComponent as Guide } from "../../../../assets/guides/mailing-guide.svg";
import { Main, TextSub, InputRow } from "../GroupEmails/styled";
import {
  VariablesComponent,
  TextFieldComponent,
  TextEditorComponent,
  FooterComponent,
  TextAreaComponent
} from "../../forms";
import { FileUploader } from "./fileUploader";
import { Btn } from "../../buttons";
import {
  ErrorMessagePortal,
  GuidePortal
} from "components/admin-layout/errorMessage";
import { CircularProgress } from "@material-ui/core";

export const Mailing = ({ token, history }) => {
  const [templateData, setTemplateData] = useState(null);
  const [subject, setSubject] = useState("");
  const [greeting, setGreeting] = useState("");
  const [message, setMessage] = useState("");
  const [uploadersCount, setUploadersCount] = useState(1);
  const [files, setFiles] = useState([]);
  const [result, setResult] = useState(null);
  const [guideOpen, setGuideOpen] = useState(false);
  const [base, setBase] = useState(null);
  const [error, setError] = useState(null);
  const [wait, setWait] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    setUploadersCount(files.length + 1);
  }, [files]);

  const renderUploaders = () => {
    let uploaders = [];
    for (let i = 0; i < uploadersCount; i++) {
      const random = Math.round(Math.random() * 1000);
      uploaders.push(
        <FileUploader
          key={`uploader-${random}`}
          order={i + 1}
          onAddFile={f => onAddFile(f)}
          onDelete={f => onRemoveFile(f)}
          _file={files[i] ? files[i] : null}
        />
      );
    }
    return uploaders;
  };

  const onAddFile = file => {
    const _files = [...files];
    _files.push(file);
    setFiles(_files);
  };

  const onRemoveFile = name => {
    const _files = [...files];
    const index = _files.findIndex(e => e.name === name);
    _files.splice(index, 1);
    setFiles(_files);
  };

  const onChangeFooterText = e => {
    const data = { ...templateData };
    data.customFooterText = e.target.value;
    setTemplateData(data);
  };

  const onChangeCustomFooter = async () => {
    const newData = {
      ...templateData,
      customFooter: !templateData.customFooter
    };
    setTemplateData(newData);
    await Api.updateTemplateSettings(token, newData);
  };

  const onOpenGuide = () => {
    setGuideOpen(!guideOpen);
  };

  const onOpenDialog = () => {
    if (inputRef && inputRef.current) inputRef.current.click();
  };

  const onUploadBase = async e => {
    const base = e.target.files[0];
    if (base) {
      setWait(true);

      const formData = new FormData();
      formData.append("file", base, base.name);

      const res = await Api.checkMailingBase(token, formData).catch(onError);
      if (res) {
        setWait(false);
        setBase(base);
        setResult(res);
      }
    }
  };

  const onRemBase = async e => {
    setBase(null);
  };

  const disable =
    disableSubmit ||
    !base ||
    !subject ||
    !greeting ||
    !message.replace(/(\<(?!img|figure)(\/?[^>]+)>)/g, "");

  const onError = e => {
    setError(e);
    setWait(false);
    setDisableSubmit(false);
  };

  const onSubmit = async () => {
    setDisableSubmit(true);
    const formData = new FormData();
    formData.append("linearId", "");
    if (files.length) {
      for (let i = 0; i < files.length; i++) {
        formData.append(`attachments`, files[i], files[i].name);
      }
    }
    formData.append("additionalFile", base, base.name);
    formData.append("message", message);
    formData.append("subject", subject);
    formData.append("greeting", greeting);

    const res = await Api.sendMailing(token, formData).catch(onError);

    if (res) {
      history.push("/admin/comunication-setup/email-templates");
    }
  };

  useEffect(() => {
    const getInit = async () => {
      const template = await Api.getTemplateSettings(
        token,
        "SEND_EMAIL_TO_USER"
      ).catch(onError);
      if (template) {
        setTemplateData(template);
        setGreeting(template.greeting);
      }
    };
    getInit();
  }, []);

  if (templateData) {
    const {
      variables: { variables },
      customFooter,
      customFooterText
    } = templateData;
    return (
      <SetupWrapper mailing>
        <MailColumn small>
          <Card>
            <CardHeader>
              <h1>Users Database</h1>
              <span onClick={onOpenGuide}>
                <Info />
                Table Guide
              </span>
            </CardHeader>
            {base ? (
              <Uploader transparent>
                {wait ? (
                  <CenterContainer>
                    <CircularProgress color="inherit" />
                  </CenterContainer>
                ) : (
                  <Fragment>
                    <h2>database</h2>
                    <FileCard>
                      <File />
                      <span>{base.name}</span>
                      <Rem onClick={onRemBase} />
                    </FileCard>
                    <FileInfo>
                      <div>
                        <h3>in BASE</h3>
                        <span>{result && result.inBase} USERS</span>
                      </div>
                      <div>
                        <h3>WITH NAMES</h3>
                        <span>{result && result.withNames ? "YES" : "NO"}</span>
                      </div>
                    </FileInfo>
                    <Btn width={298} transparent onClick={onOpenDialog}>
                      REPLACE .XLSX FILE
                    </Btn>
                  </Fragment>
                )}
              </Uploader>
            ) : (
              <Uploader padding="25px 40px">
                {wait ? (
                  <CenterContainer>
                    <CircularProgress color="inherit" />
                  </CenterContainer>
                ) : (
                  <Fragment>
                    <h1>There is no file yet</h1>
                    <p>
                      Please upload your .xlsx file with e-mails. Check our{" "}
                      <span onClick={onOpenGuide}>Table Guide</span> for good
                      result
                    </p>
                    <Btn width={195} onClick={onOpenDialog}>
                      UPLOAD FILE
                    </Btn>
                  </Fragment>
                )}
              </Uploader>
            )}
            <input
              key={`file-${base ? base.name : base}`}
              ref={inputRef}
              type="file"
              style={{ display: "none" }}
              accept=".xlx, .xlsx"
              onChange={onUploadBase}
            />
          </Card>
          <Main
            // noHidden
            // currentHeight="fit-content"
            key={`variables-${variables.length}`}
          >
            <TextSub
              textAlign="left"
              fontWeight="bold"
              color="#181818"
              marginSize="0 0 25px 0"
            >
              Help
            </TextSub>
            <VariablesComponent label="Shortcuts" variables={variables} />
          </Main>
        </MailColumn>
        <MailColumn>
          <Main width="100%">
            <TextSub
              textAlign="left"
              fontWeight="bold"
              color="#181818"
              marginSize="0 0 25px 0"
            >
              E-mail Message
            </TextSub>
            <InputRow>
              <TextFieldComponent
                label="E-mail SUBJECT"
                placeholder="Welcome to site_name"
                defaultValue={subject}
                onChange={event => setSubject(event.target.value)}
              />
            </InputRow>
            <InputRow>
              <TextFieldComponent
                label="E-MAIL greeting"
                placeholder={greeting}
                // defaultValue={greeting}
                value={greeting}
                onChange={event => setGreeting(event.target.value)}
              />
            </InputRow>
            <InputRow>
              <TextEditorComponent
                label="E-MAIL content"
                defaultValue={message}
                onChange={event => setMessage(event)}
              />
            </InputRow>
            <InputRow last={!customFooter}>
              <FooterComponent
                label="e-mail footer"
                name="Global E-mail footer"
                defaultValue={!customFooter}
                onChange={onChangeCustomFooter}
              />
            </InputRow>
            {customFooter && (
              <InputRow last>
                <TextAreaComponent
                  label="custom signature for e-mail"
                  placeholder="Write here custom signature or left this fields if you don’t want to use the signature"
                  defaultValue={customFooterText}
                  onChange={onChangeFooterText}
                />
              </InputRow>
            )}
            <UploadersCol>
              {renderUploaders()}
              <EmptyBlock />
              <EmptyBlock />
            </UploadersCol>
          </Main>
          <Main margin="20px 0 0" padding="15px 25px">
            <SubmitBlock>
              <Btn width={180} onClick={onSubmit} disabled={disable}>
                SEND
              </Btn>
            </SubmitBlock>
          </Main>
        </MailColumn>
        {guideOpen ? (
          <GuidePortal
            onClose={onOpenGuide}
            name="Table Guide"
            header="Please prepare Excel file with extention .xlsx as show below"
            img={<Guide />}
            message={`Unwilling sportsmen he in questions september therefore described so. Attacks may set few believe moments was. Reasonably how possession shy way introduced age inquietude. Missed he engage no exeter of. Still tried means we aware order among on. Eldest father can design tastes did joy settle. Roused future he ye an marked. Arose mr rapid in so vexed words. Gay welcome led add lasting chiefly say looking.
                        <br/><br/>
                    Do greatest at in learning steepest. Breakfast extremity suffering one who all otherwise suspected. He at no nothing forbade up moments. Wholly uneasy at missed be of pretty whence. John way sir high than law who week. Surrounded prosperous introduced it if is up dispatched. Improved so strictly produced answered elegance is.`}
          />
        ) : null}

        {error && (
          <ErrorMessagePortal er={error} onClose={() => setError(null)} />
        )}
      </SetupWrapper>
    );
  } else {
    return <Waiting />;
  }
};
