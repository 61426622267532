import React, { useState, useEffect, Fragment, useRef } from "react";
import { PieChart } from "react-minimal-pie-chart";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";
import styled from "styled-components";

import {
  StatisticsWrapper,
  CardStatistic,
  CardHeading,
  ChartRow,
  ChartCol,
  ViewRow,
  Label,
  Bold,
  BtnsRow,
  Flex,
  Table,
  TRow,
  TCeil,
  UserLink,
  CenterContainer,
  FolderName,
  DocName
} from "./styled";
import { ChartData, User } from "../styled";
import { Btn } from "components/admin-layout/buttons";
import { ReactComponent as Arrow } from "../../../../../asset/arrow.svg";
import DataRoomApi from "../../../../../helpers/api";
import { CircularProgress } from "@material-ui/core";
import { optionCreator } from "../../../../../helpers/optionsCreator";

import {
  FolderClose,
  FolderOpen,
  File,
  Arrow as ArrowFolder
} from "components/common/icons-primary";

import theme from "models/themes";

const chartColors = [
  theme.green,
  "#3F4B78",
  theme.primaryLight,
  theme.primaryMiddle
];

export const Statistic = ({ token, assetId }) => {
  const [statistic, setStatistic] = useState(null);
  const [chart, setChart] = useState(null);
  const [period, setPeriod] = useState(30);
  const [type, setType] = useState("by minutes");
  const [width, setWidth] = useState(0);
  const ref = useRef(null);
  //fix chart render delay
  const [chartKey, setChartKey] = useState("by minutes-30");

  const onChangePeriod = per => () => {
    setPeriod(per);
  };

  const onChangeType = type => () => {
    setType(type);
  };

  const getInitialInfo = async () => {
    const _statistic = await DataRoomApi.getAssetFilesStatistic(token, assetId);
    const _chart = await DataRoomApi.getChartData(token, assetId, period, type);

    if (_statistic) setStatistic(_statistic);
    if (_chart) setChart(_chart);

    if (!width && ref && ref.current) {
      setWidth(ref.current.offsetWidth);
    }
  };

  useEffect(() => {
    getInitialInfo();
  }, []);

  useEffect(() => {
    if (chart) {
      DataRoomApi.getChartData(token, assetId, period, type).then(c => {
        setChart(c);
        setChartKey(`${type}-${period}`);
      });
    }
  }, [period, type]);

  const renderFolders = arr =>
    arr
      .filter(f => {
        if (f.name !== "Unsorted Files") {
          return f;
        }
      })
      .map(f => <Folder key={f.name} folder={f} />);

  if (statistic && chart) {
    const {
      filesViewStatistics: { folders },
      userViewStatistics,
      viewStatistic: { averagePerUser, totalUsers, totalViews },
      viewsByFolders
    } = statistic;

    const renderViewsByFolders = () => {
      const sorted = viewsByFolders.sort((a, b) => {
        if (a.percentage > b.percentage) return a;
      });
      sorted.length = 4;
      let formatedData = {};

      sorted
        .filter(e => e)
        .forEach(({ folderName, percentage }) => {
          formatedData = {
            ...formatedData,
            ...{ [folderName]: percentage.toFixed(2) }
          };
        });

      const pieData = sorted
        .filter(e => e)
        .map(({ folderName, percentage }, i) => ({
          title: folderName,
          value: percentage,
          color: chartColors[i]
        }));

      return (
        <CardStatistic width="calc(100% - 513px)" height="126px">
          <ChartRow>
            <ChartCol m="0 25px 0 0">
              <PieChart
                data={
                  pieData.some(e => e.value)
                    ? pieData
                    : [{ title: "No views", value: 100, color: "#b6b6b699" }]
                }
                style={{
                  width: 86,
                  height: 86
                }}
              />
            </ChartCol>
            <ChartCol w="calc(100% - 106px)">
              <CardHeading>Views by Folders</CardHeading>
              <ChartData data={formatedData} />
            </ChartCol>
          </ChartRow>
        </CardStatistic>
      );
    };

    const renderUsersStatistic = () =>
      userViewStatistics.map(
        ({ fullName, logo, spentTime, totalViews, useName }) => (
          <TRow noHover key={`${fullName}-${useName}-${spentTime}`}>
            <TCeil fixedWidth="280px">
              <User user={{ logo, name: fullName }} token={token} />
            </TCeil>
            <TCeil fixedWidth="82px">
              <Bold fW={400}>{spentTime}</Bold>
            </TCeil>
            <TCeil fixedWidth="82px">
              <Bold fW={400}>{totalViews}</Bold>
            </TCeil>
            <TCeil style={{ padding: 0 }} fixedWidth="49px">
              <UserLink
                to={`/admin/data-room/deal/${assetId}/statistics/${useName}`}
              >
                <Arrow />
              </UserLink>
            </TCeil>
          </TRow>
        )
      );

    return (
      <StatisticsWrapper>
        <CardStatistic width="100%" height="305px">
          <BtnsRow>
            <Flex mb="0" width="306px">
              <Btn transparent={period !== 30} onClick={onChangePeriod(30)}>
                Month
              </Btn>
              <Btn transparent={period !== 183} onClick={onChangePeriod(183)}>
                6 Month
              </Btn>
              <Btn transparent={period !== 365} onClick={onChangePeriod(365)}>
                Year
              </Btn>
            </Flex>
            <Flex mb="0" width="246px">
              <Btn
                width={120}
                transparent={type !== "by minutes"}
                onClick={onChangeType("by minutes")}
              >
                by MINUTES
              </Btn>
              <Btn
                width={120}
                transparent={type !== "by views"}
                onClick={onChangeType("by views")}
              >
                by VIEWS
              </Btn>
            </Flex>
          </BtnsRow>

          <Flex mb="0" width="100%" ref={ref}>
            <HR
              key={chartKey}
              highcharts={Highcharts}
              options={optionCreator(
                chart,
                width,
                false,
                period,
                type === "by minutes" ? "minutes" : "views"
              )}
              constructorType={"stockChart"}
            />
          </Flex>
        </CardStatistic>
        {renderViewsByFolders()}
        <CardStatistic width="493px" height="126px">
          <CardHeading>View Statistics</CardHeading>
          <ViewRow>
            <div>
              <Label>total users</Label>
              <Bold>{totalUsers}</Bold>
            </div>
            <div>
              <Label>total views</Label>
              <Bold>{totalViews}</Bold>
            </div>
            <div>
              <Label>average per user</Label>
              <Bold>{averagePerUser}</Bold>
            </div>
          </ViewRow>
        </CardStatistic>
        <CardStatistic width="calc(100% - 513px)" height="fit-content" p={0}>
          <Table>
            <TRow noHover>
              <TCeil fixedWidth="calc(100% - 246px)">Description</TCeil>
              <TCeil fixedWidth="82px" p={15} style={{ paddingRight: 15 }}>
                % of all time
              </TCeil>
              <TCeil fixedWidth="82px">Average time</TCeil>
              <TCeil fixedWidth="82px">total views</TCeil>
            </TRow>
            {renderFolders(folders)}
          </Table>
        </CardStatistic>
        <CardStatistic width="493px" height="fit-content" p={0}>
          <Table>
            <TRow noHover>
              <TCeil fixedWidth="280px">User</TCeil>
              <TCeil fixedWidth="82px">Spent time</TCeil>
              <TCeil fixedWidth="82px">Total views</TCeil>
              <TCeil fixedWidth="49px"></TCeil>
            </TRow>
            {renderUsersStatistic()}
          </Table>
        </CardStatistic>
      </StatisticsWrapper>
    );
  } else {
    return (
      <CenterContainer>
        <CircularProgress />
      </CenterContainer>
    );
  }
};

export const Folder = ({
  isDeal = false,
  folder: { averageTime = 0, files, name, percentage = 0, section, totalViews }
}) => {
  const [open, setOpen] = useState(false);

  const renderDocs = section => {
    return files.map(
      ({ averageTime, name, percentage, subsection, totalViews }) => (
        <TRow key={`${name}=${subsection}`}>
          <TCeil fixedWidth="calc(100% - 246px)" p={78}>
            <DocName>
              <File />
              <span>
                {section ? `${section}.${subsection}.` : null} {name}
              </span>
            </DocName>
          </TCeil>
          <TCeil fixedWidth="82px" p={15}>
            <Bold fW={400}>
              {percentage ? percentage.toFixed(isDeal ? 0 : 2) + "%" : "-"}
            </Bold>
          </TCeil>
          <TCeil fixedWidth="82px">
            <Bold fW={400}>
              {averageTime ? `${averageTime.toFixed()}min` : "-"}
            </Bold>
          </TCeil>
          <TCeil fixedWidth="82px">
            <Bold fW={400}>{totalViews || "-"}</Bold>
          </TCeil>
        </TRow>
      )
    );
  };

  return (
    <Fragment>
      <TRow onClick={() => setOpen(!open)}>
        <TCeil fixedWidth="calc(100% - 246px)">
          <FolderName open={open}>
            <ArrowFolder />
            {!open ? <FolderClose /> : <FolderOpen />}
            <span>
              {section ? `${section}.` : null} {name}
            </span>
          </FolderName>
        </TCeil>
        <TCeil fixedWidth="82px" p={15}>
          <Bold>
            {percentage ? percentage.toFixed(isDeal ? 0 : 2) + "%" : "-"}
          </Bold>
        </TCeil>
        <TCeil fixedWidth="82px">
          <Bold>{averageTime ? `${averageTime.toFixed()}min` : "-"}</Bold>
        </TCeil>
        <TCeil fixedWidth="82px">
          <Bold>{totalViews || "-"}</Bold>
        </TCeil>
      </TRow>
      {open ? renderDocs(section) : null}
    </Fragment>
  );
};

const HR = styled(HighchartsReact)`
  background: red;
  & .highcharts-range-selector-group {
    display: none !important;
  }
`;
