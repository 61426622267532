import styled from "styled-components";

export const Input = styled.input`
  height: ${({ height }) => (height ? `${height}px` : "42px")};
  width: ${({ width }) => (width ? width : "calc(100% - 300px)")};
  border: 1px solid ${({ error }) => (error ? "#FF4343" : "#efefef")};
  box-sizing: border-box;
  border-radius: 4px;
  outline: none;
  padding: ${({ padding }) => padding || "0 20px"};
  margin: ${({ margin = 0 }) => margin};
  font-family: Muli;
  font-weight: normal;
  font-size: ${({ fontSize }) => (fontSize ? `${fontSize}px` : "12px")};
  line-height: 135%;
  letter-spacing: -0.02em;
  color: #181818;

  ${({ calendar = false }) => {
    if (calendar) {
      return `
      &::-webkit-color-swatch-wrapper {
        padding: 0;
        border-radius: 100%;
      }
      &::-webkit-color-swatch {
        border: none;
        border-radius: 100%;
      }
      cursor:pointer;
      `;
    } else return "";
  }}

  &[type="color"] {
    padding: 0;
    border: 0;
    outline: 0;
    overflow: hidden;
    background: ${({ bg = "transparent" }) => bg};
  }

  &[type="date"]::-webkit-inner-spin-button,
  &[type="date"]::-webkit-calendar-picker-indicator,
  &[type="date"]::-webkit-clear-button,
  &[type="time"]::-webkit-clear-button {
    opacity: 0;
    cursor: pointer;
    -webkit-appearance: none !important;
  }
  &[type="time"]::-webkit-inner-spin-button,
  &[type="time"]::-webkit-calendar-picker-indicator {
    opacity: 0;
    cursor: pointer;
    -webkit-appearance: none;
  }
  &[type="number"]::-webkit-outer-spin-button,
  &[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
  }
  background-image: ${({ icon }) => `url(${icon})`};
  background-position: ${({ bgPos = "right" }) => bgPos};
  background-position-x: ${({ arrowPosition, leftPos }) => {
    if (leftPos) return leftPos;
    return arrowPosition ? `calc(100% - ${arrowPosition}px)` : "calc(100% - 24px)";
  }};

  background-size: 16px;
  background-repeat: no-repeat;
  &::placeholder {
    color: rgba(24, 24, 24, 0.3);
  }
  &:disabled {
    background: ${({ theme: { disabledField } }) => disabledField};
  }
`;
