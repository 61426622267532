import React, { Fragment } from "react";

import { Main, TextSub } from "../../DealForm/Step/styled";
import { ProgressBar, Row, DataColumn, Filter } from "../styled";
import { numberWithCommas } from "helpers";
import theme from "models/themes";
import { CircularProgress } from "@material-ui/core";

export const Financial = ({
  soldAndUnsold,
  deal: {
    subscribers,
    assetFinancialProperty: { baseCurrency, valuation },
    assetProperties: { assetSymbol },
  },
}) => {
  return (
    <Main currentHeight="135px">
      <TextSub textAlign="left" fontWeight="bold" color="#181818" marginSize="0 0 14px 0">
        Financial
      </TextSub>
      {!soldAndUnsold ? (
        <CircularProgress size={24} style={{ margin: "auto" }} />
      ) : (
        <Fragment>
          <Row>
            <DataColumn
              heading="raised amount"
              data={`${numberWithCommas((soldAndUnsold.sold * valuation).toFixed(2))} ${baseCurrency}`}
              circleColor={theme.primaryMiddle}
            />
            <DataColumn
              heading="total"
              data={`${numberWithCommas(
                ((soldAndUnsold.sold + soldAndUnsold.unsold) * valuation).toFixed(2)
              )} ${baseCurrency}`}
              circleColor={theme.primaryLight}
            />
            <DataColumn
              heading="sold shares"
              data={`${
                soldAndUnsold.sold
                  ? ((soldAndUnsold.sold / (soldAndUnsold.sold + soldAndUnsold.unsold)) * 100).toFixed(2)
                  : 0
              } %`}
            />
            <DataColumn heading="Share Price" data={`1 ${assetSymbol} = ${valuation} ${baseCurrency}`} />
            <DataColumn heading="subscribers" data={subscribers} />
            <Filter />
          </Row>
          <ProgressBar sold={soldAndUnsold.sold} unsold={soldAndUnsold.unsold} />
        </Fragment>
      )}
    </Main>
  );
};
