import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import moment from "moment";

import { Wrapper } from "../../main";
import { Header, TextH3 } from "../DealForm/Step/styled";
import { ReactComponent as ArrowLeft } from "../../../../assets/arrowleft.svg";
import { ReactComponent as FileIcon } from "../../../../assets/file-text.svg";
import { Btn } from "../../buttons";
import {
  Row,
  Main,
  TextSub,
  InputRow,
  Col,
  Label,
  Statistic,
  SubmitBlock,
  UploadersCol,
  ContainerFlex,
  UploaderLabel,
} from "../GroupEmails/styled";
import {
  FileNameBlock,
  NameCont,
  Name,
  h3Style,
  Text,
} from "../SendReport/styled";
import {
  TextFieldComponent,
  TextEditorComponent,
  FooterComponent,
  VariablesComponent,
  TextAreaComponent,
} from "../../forms";
import Api from "../../../../helpers/api";
import { getAssets } from "../../../../modules/dataRedux/dataAction";
import { ErrorMessage, SuccessMessage } from "../../errorMessage";

const SendReportsUser = ({
  token,
  match: {
    params: { user },
  },
  history,
}) => {
  const [data, setData] = useState({
    message: "",
    subject: "",
  });
  const [template, setTemplate] = useState(null);
  const [ownFooter, setOwnFooter] = useState(false);
  const [footerText, setFooterText] = useState("");
  const [wait, setWait] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState("");

  const onRedirect = () => {
    history.push(`/admin/users`);
  };

  useEffect(() => {
    const getInitialData = async () => {
      const template = await Api.getTemplateSettings(
        token,
        "SEND_EMAIL_TO_USER"
      );
      if (template) {
        delete template.variables.variables.messages;
        setTemplate(template);
        setOwnFooter(template.customFooter);
      }
    };

    getInitialData();
  }, []);

  const onChange = (e, key) => {
    setData({ ...data, [key]: e.target ? e.target.value : e });
  };

  const onChangeFooter = (e) => {
    setFooterText(e.target.value);
  };

  const onChangeCustomFooter = async () => {
    await Api.updateTemplateSettings(token, {
      ...template,
      customFooter: !ownFooter,
    }).then((_) => setOwnFooter(!ownFooter));
  };

  const onError = (e) => {
    setWait(false);
    setError(e);
  };

  const onSubmit = async () => {
    setWait(true);
    if (ownFooter) {
      await Api.updateTemplateSettings(token, {
        ...template,
        customFooterText: footerText,
      }).catch(onError);
    }
    const res = await Api.sendUserReports(token, user, data).catch(onError);
    if (res) {
      setWait(false);
      setSuccess(res);
    }
  };

  if (template) {
    const {
      variables: { variables },
      customFooter,
    } = template;
    return (
      <Wrapper bg="transparent">
        <Header>
          <Btn transparent width={150} onClick={onRedirect}>
            <ArrowLeft /> back to users
          </Btn>
          <TextH3 marginSize="0 0 0 25px">Send reports to {user}</TextH3>
        </Header>

        <Row>
          <Col width="calc(100% - 383px)">
            <Main width="100%">
              <TextSub {...h3Style}>Message to Users</TextSub>
              <InputRow>
                <TextFieldComponent
                  label="Subject"
                  placeholder="E-mail subject"
                  defaultValue={data.subject}
                  onChange={(e) => onChange(e, "subject")}
                />
              </InputRow>
              <InputRow>
                <TextEditorComponent
                  label="Template content"
                  defaultValue={data.message}
                  onChange={(e) => onChange(e, "message")}
                />
              </InputRow>
              <InputRow last={!ownFooter}>
                <FooterComponent
                  label="e-mail footer"
                  name="Global E-mail footer"
                  defaultValue={!customFooter}
                  onChange={onChangeCustomFooter}
                />
              </InputRow>
              {ownFooter && (
                <InputRow last>
                  <TextAreaComponent
                    label="custom signature for e-mail"
                    placeholder="Write here custom signature or left this fields if you don’t want to use the signature"
                    defaultValue={footerText}
                    onChange={onChangeFooter}
                  />
                </InputRow>
              )}
              <UploadersCol>
                <Text>
                  The latest reports of Investor Assets will be attached to this
                  message
                </Text>
              </UploadersCol>

              <SubmitBlock>
                <Btn width={200} height={42} disabled={wait} onClick={onSubmit}>
                  SEND reports
                </Btn>
              </SubmitBlock>
            </Main>
          </Col>

          <Col width="353px">
            <Main currentHeight="fit-content" margin="0 0 30px">
              <TextSub {...h3Style}>Help</TextSub>
              <VariablesComponent label="Shortcuts" variables={variables} />
            </Main>
          </Col>
        </Row>

        {error && <ErrorMessage er={error} onClose={() => setError(null)} />}
        {success && <SuccessMessage message={success} onClose={onRedirect} />}
      </Wrapper>
    );
  } else {
    return (
      <Wrapper
        width="100%"
        justifyContent="center"
        alignItems="center"
        bg="transparent"
        height="calc(100vh - 130px)"
      >
        <CircularProgress />
      </Wrapper>
    );
  }
};

export default connect(
  ({ userReducer: { token } }) => ({
    token,
  }),
  null
)(SendReportsUser);
