import React, { useState, useEffect } from "react";

import {
  SelectorWrapper,
  NoCompanies,
  SearchBlock,
  SearchInputSel,
  InstCard,
  ScrollContainer
} from "./styled";
import { icons } from "../../../../models/icons";
import { Btn } from "../../buttons";

export const SelectInstitution = ({
  institutions,
  userInstitution,
  onSetInstitution,
  onAddInstitution,
  role
}) => {
  const [selected, setSelected] = useState(userInstitution);
  const [clicked, setClicked] = useState(false);
  const [filter, setFilter] = useState(
    institutions.find(inst => inst.name === userInstitution)
      ? userInstitution
      : ""
  );

  const onUserClick = () => {
    if (!clicked) setSelected("");
    setClicked(true);
  };

  const onSearchInput = e => {
    setSelected(e.target.value);
    if (e.target.value !== userInstitution) {
      setFilter(e.target.value);
    }
  };

  const onSelectInput = name => () => {
    setSelected(name);
  };

  useEffect(() => {
    const institution = institutions.find(inst => inst.name === selected);
    if (institution) {
      onSetInstitution({ ...institution });
    } else onSetInstitution(null);
  }, [selected]);

  const renderInstitutions = () => {
    return institutions
      .filter(({ name }) => name.toLowerCase().includes(filter.toLowerCase()))
      .map(({ name }) => (
        <InstCard active={name === selected} onClick={onSelectInput(name)}>
          {name}
          {name === selected ? icons.check : null}
        </InstCard>
      ));
  };

  return (
    <SelectorWrapper>
      <SearchBlock>
        {icons.search}
        <SearchInputSel
          placeholder="Search by name"
          onInput={e => onSearchInput(e)}
          value={selected}
          onClick={onUserClick}
        />
      </SearchBlock>

      {institutions.find(inst => inst.name === selected) ||
      !selected ||
      clicked ? (
        <ScrollContainer pRight={12}>{renderInstitutions()}</ScrollContainer>
      ) : (
        <NoCompanies>
          <h3>“{selected}” was not found in your companies</h3>
          <p>
            You can create the new institution <br />
            and link this user or select institution below
          </p>
          {role !== "FACILITATOR" ? (
            <Btn width={200} onClick={onAddInstitution}>
              {icons.plusCircle}
              ADD NEW INSTITUTION
            </Btn>
          ) : null}
        </NoCompanies>
      )}
    </SelectorWrapper>
  );
};
