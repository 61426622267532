import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import { setPaymentResponseDataTrade } from "../../../../modules/userRedux/userAction";

import Api from "../../../../helpers/api";

import {
  Wrapper,
  Col,
  Card,
  Header,
  BorderBlock,
  Row,
  Heading,
  Text,
  Line
} from "../Investment/styled.js";
import { Stepper } from "../SignContract/styled";
import { Button } from "../../../investor-layout/pages/Offerings/styled";
import { ReactComponent as BackIcon } from "../../../../assets/arrowleft.svg";
import { Loader } from "../../styled/Loader";
import { TextFieldComponent } from "../../../admin-layout/forms";

import { Price } from "../Investment/Price";
import { PaymentMessage, PayOnlineModal } from "../../ActionMessages";
import { numberWithCommas } from "helpers";

export const PaymentDetailsTrade = ({
  token,
  fullName,
  history,
  soldTrade,
  setPaymentResponseDataTrade,
  txHash,
  id,
  tradePaymentDetails
}) => {
  const [responseData, setResponseData] = useState(null);
  const [bankDetails, setBankDetails] = useState(null);
  const [wait, setWait] = useState(false);
  const [error, setError] = useState(false);
  const [modalOpen, showModal] = useState(false);
  const [payOnlineModalOpen, setPayOlineModalOpen] = useState(false);
  const [smallScreen, setSmallScreen] = useState(window.innerWidth < 1280);

  const getInfo = async () => {
    await Api.getDocuSignInfo(
      token,
      tradePaymentDetails.envelopeId,
      tradePaymentDetails.token
    )
      .then(json => setResponseData(json))
      .catch(_ => history.push("/investor/offerings/users"));
    await Api.getBankDetails(token).then(json =>
      setBankDetails(
        json.find(json => json.accountNumber === soldTrade.activeAccount)
      )
    );
  };

  const changeOnlineModalStatus = () =>
    setPayOlineModalOpen(!payOnlineModalOpen);

  useEffect(() => {
    const onResize = () => {
      setSmallScreen(window.innerWidth < 1280);
    };

    window.addEventListener("resize", onResize);

    if ((!responseData || !bankDetails) && tradePaymentDetails) {
      getInfo();
    }

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  const sendRequest = async () => {
    const date = Date.now();
    setWait(true);
    const {
      docType,
      tokenId,
      seller,
      token: responseToken,
      spaProperties: { currency, quantity, tokenTxHash },
      buyerAccountNumber
    } = responseData;
    if (docType === "BUY_MARKETPLACE_BANK") {
      await Api.buyTradeViaBank(
        tokenTxHash,
        token,
        tokenId,
        currency,
        quantity,
        seller,
        tradePaymentDetails.envelopeId,
        responseToken,
        buyerAccountNumber
      )
        .then(res => {
          setPaymentResponseDataTrade({ ...res, txDate: date });
          showModal(true);
        })
        .catch(() => {
          setError(true);
          setWait(false);
        });
    } else if (docType === "BUY_MARKETPLACE_ONLINE") {
      await Api.buyTradeOnline(
        txHash,
        token,
        tokenId,
        currency,
        quantity,
        seller,
        tradePaymentDetails.envelopeId,
        responseToken
      )
        .then(res => {
          history.push("/investor/transactions/all/all");
          console.log(res);
        })
        .catch(() => {
          setError(true);
          setWait(false);
          setPayOlineModalOpen(false);
        });
    }
    setWait(false);
  };

  const PriceBlock = useCallback(
    (price, currency, assetSymbol, smallScreen) => (
      <Price
        fullHeight={smallScreen}
        convert={price + " " + currency}
        assetSymbol={assetSymbol}
      />
    ),
    [smallScreen]
  );

  const StepperBlock = useCallback(() => <Stepper step={1} />, []);

  const fullSize = "100%";
  const colWidth = 440;

  if ((!responseData || !bankDetails) && soldTrade && tradePaymentDetails) {
    return <Loader />;
  } else if (soldTrade && tradePaymentDetails && bankDetails) {
    const {
      assetName,
      txHash,
      price,
      currency,
      tokenIdentifier,
      owner,
      amount,
      type,
      baseCurrency
    } = soldTrade;
    const { bankName, bic, iban } = bankDetails;

    return (
      <Wrapper>
        {wait && <Loader zIndex={100} />}
        <Header>
          <BorderBlock>
            <Button
              transparent
              height={36}
              size={12}
              width={176}
              onClick={() =>
                history.push(
                  `/investor/offerings/sign-contract-trade/${id}/${txHash}`
                )
              }
            >
              <BackIcon style={{ marginRight: 8 }} />
              Back to Details
            </Button>
          </BorderBlock>
          <Heading>
            Buy shares of {assetName} from {owner}
          </Heading>
        </Header>

        {smallScreen ? (
          <Row
            width={fullSize}
            smallScreen={smallScreen}
            justify="space-between"
            align="start"
          >
            <Col width={`calc(${fullSize} - ${colWidth + 30}px)`} height={130}>
              {PriceBlock(price, currency, tokenIdentifier, smallScreen)}
            </Col>
            <Col width={`${colWidth}px`}>{StepperBlock()}</Col>
          </Row>
        ) : null}

        <Col
          width={
            smallScreen ? fullSize : `calc(${fullSize} - ${colWidth + 30}px)`
          }
        >
          <Card>
            <Row margin="0 0 14px 0">
              <Heading>Bank Transfer to Invest</Heading>
              <Text
                fSize={18}
                color="rgba(24, 24, 24, 0.4)"
                margin="0 0 0 auto"
              >
                2 / 3
              </Text>
            </Row>
            <Row margin="0 0 15px 0">
              <Text lineH="160%" fSize={14} color="#7C7C7C">
                Please transfer the amount of money to purchase the amount of
                shares using the below bank account details you have added to
                your account. The platform admin will recognise your payment by
                its IBAN
              </Text>
            </Row>
            <Row margin="0 0 14px 0" justify="space-between" padding="0">
              <TextFieldComponent
                width="178px"
                label="Amount to tranfser"
                height={42}
                labelFontSize={10}
                fontSize={12}
                isInvestor
                disabled
                defaultValue={`${numberWithCommas(
                  (amount * price).toFixed(2)
                )} ${baseCurrency}`}
              />
              <TextFieldComponent
                width="calc(100% - 202px)"
                label="Account Holder Name"
                height={42}
                labelFontSize={10}
                fontSize={12}
                isInvestor
                disabled
                defaultValue={fullName}
              />
            </Row>
            <Row margin=" 0" justify="space-between" padding="0">
              <TextFieldComponent
                width="178px"
                label="BIC (SWIFT) CODE"
                height={42}
                labelFontSize={10}
                fontSize={12}
                isInvestor
                disabled
                defaultValue={bic}
              />
              <TextFieldComponent
                width="255px"
                label="IBAN"
                height={42}
                labelFontSize={10}
                fontSize={12}
                isInvestor
                disabled
                defaultValue={iban}
              />
              <TextFieldComponent
                width="calc(100% - 482px)"
                label="Beneficiary’s bank"
                height={42}
                labelFontSize={10}
                fontSize={12}
                isInvestor
                disabled
                defaultValue={bankName}
              />
            </Row>
            <Line />
            <Row>
              <Text fSize={18}>
                Total shares:{" "}
                <span>
                  {numberWithCommas(amount)} {tokenIdentifier}
                </span>
              </Text>
            </Row>
          </Card>
          <Card noPadding height={62}>
            <Button
              height={42}
              width={287}
              bold
              size={14}
              margin="10px 0 10px 30px"
              onClick={type === "cash" ? changeOnlineModalStatus : sendRequest}
            >
              CONFIRM & SEND REQUEST
            </Button>
          </Card>
        </Col>

        {smallScreen ? null : (
          <Col width={`${colWidth}px`}>
            {StepperBlock()}
            {PriceBlock(price, currency, tokenIdentifier, smallScreen)}
          </Col>
        )}

        {payOnlineModalOpen ? (
          <PayOnlineModal
            baseCurrency={baseCurrency}
            price={price}
            paymentData={{ ...soldTrade, assetSymbol: tokenIdentifier }}
            onCancel={changeOnlineModalStatus}
            onSubmit={sendRequest}
          />
        ) : null}

        <PaymentMessage
          isOpen={modalOpen}
          isError={error}
          onBackDpopClick={() => showModal(!modalOpen)}
          onClose={() =>
            history.push(
              `/investor/offerings/order-details-trade/${id}/${txHash}`
            )
          }
        />
      </Wrapper>
    );
  } else {
    return <Redirect to="/investor/offerings/users" />;
  }
};

export default connect(
  (
    {
      userReducer: { token, tradePaymentDetails, fullName },
      dataReducer: { soldTrade }
    },
    {
      match: {
        params: { id, txHash }
      }
    }
  ) => {
    return {
      token,
      id,
      soldTrade,
      tradePaymentDetails,
      fullName,
      txHash
    };
  },
  { setPaymentResponseDataTrade }
)(PaymentDetailsTrade);
