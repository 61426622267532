import React, { useState } from "react";
import { ReactComponent as FolderClose } from "../../../../../../assets/folder-close.svg";
import { ReactComponent as Check } from "../../../../../../assets/check.svg";
import { ReactComponent as CloseIcon } from "../../../../../../assets/x.svg";

import { TRow, TCeil, NewFolderBlock, Input, Submit, Close } from "../styled";
import { ErrorMessage } from "../errorMessage";
import DataRoomApi from "../../../../../helpers/api";

export const AddFolder = ({
  callBack,
  assetId,
  token,
  onClose,
  isRename = false,
  folderName = "",
  filesCount = null,
  onAbort,
}) => {
  const [name, setName] = useState(folderName);
  const [wait, setWait] = useState(false);
  const [errorObj, setError] = useState(null);

  const onSubmit = async () => {
    if (folderName === name) {
      onAbort();
      return;
    }

    setWait(true);

    let res;

    if (isRename) {
      res = await DataRoomApi.renameFolder(
        token,
        folderName,
        name,
        assetId
      ).catch((e) => {
        setWait(false);
        setError(e);
      });
    } else {
      res = await DataRoomApi.createNewFolder(token, name, assetId).catch(
        (e) => {
          setWait(false);
          setError(e);
        }
      );
    }

    if (res) {
      setName("");
      setWait(false);
      callBack();
    }
  };

  return (
    <TRow active={1}>
      <TCeil big>
        <NewFolderBlock>
          <FolderClose />
          <Input
            placeholder="New Folder Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <Submit onClick={onSubmit} disabled={wait}>
            <Check />
          </Submit>
          <Close onClick={onClose}>
            <CloseIcon />
          </Close>
        </NewFolderBlock>
      </TCeil>
      <TCeil lower>{filesCount}</TCeil>
      <TCeil></TCeil>
      <TCeil></TCeil>
      <TCeil middle></TCeil>
      {errorObj ? (
        <ErrorMessage er={errorObj} onClose={() => setError(null)} />
      ) : null}
    </TRow>
  );
};
