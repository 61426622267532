import React, { useState } from "react";
import styled from "styled-components";

import { Btn } from "../../buttons";
import { icons } from "../../../../models/icons";
import { ConfirmMessagePortal } from "components/admin-layout/errorMessage";

const Header = styled.div`
  height: 60px;
  background: #ffffff;
  border-radius: 4px;
  width: 100%;
  margin-bottom: 30px;
  padding: 0 25px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  &:last-child {
    margin-bottom: 0;
  }
`;

const Heading = styled.h3`
  font-size: 14px;
  line-height: 135%;
  color: #181818;
  margin: ${({ m = 0 }) => m};
`;

const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ w = "calc(100% - 353px)" }) => w};
  height: fit-content;
  box-sizing: border-box;
`;

const Card = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 20px 25px;
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ mb = 20 }) => `${mb}px`};
  background: #ffffff;
  border-radius: 4px;
  &:last-child {
    margin-bottom: 0;
  }
`;

const Line = styled.div`
  width: 100%;
  margin: 25px 0 20px;
  border-top: 1px solid #efefef;
`;

const Label = styled.h6`
  font-size: 10px;
  line-height: 135%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: rgba(24, 24, 24, 0.6);
  margin: 0 0 6px;
`;

const Val = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  color: #333333;
  margin: 0;
`;

const FieldRow = styled.div`
  width: 100%;
  margin: 0 0 20px;
  border-bottom: 1px solid #efefef;
  display: flex;
  align-items: center;
  padding-bottom: 22px;
  justify-content: space-between;
  &:last-child {
    padding-bottom: 0;
    margin-bottom: 0;
    border: none;
  }
`;

const FieldName = styled.h6`
  margin: 0;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 135%;
  letter-spacing: -0.02em;
  color: ${({ visible }) => (visible ? "#181818" : "rgba(24, 24, 24, 0.7);")};
`;

const CheckBoxesCont = styled.div`
  display: flex;
  width: 165px;
  align-items: center;
  justify-content: space-between;
`;

const CheckBoxCont = styled.div`
  display: flex;
  align-items: center;
  margin-right: 14px;
  width: 80px;
  &:last-child {
    margin-right: 0;
  }
`;

const ShowButton = styled.div`
  width: 32px;
  height: 16px;
  position: relative;
  background: ${({ active, theme: { quoteBgInvestor } }) => (active ? quoteBgInvestor : "#EEEEEE")};
  transition: background linear 0.1s;
  border-radius: 8px;
  cursor: pointer;
  margin-right: 8px;
`;

const Circle = styled.div`
  width: 16px;
  height: 16px;
  background: ${({ active, theme: { primary } }) => (active ? primary : "#BCBCBC")};
  border-radius: 8px;
  position: absolute;
  right: ${({ active }) => (active ? 0 : "16px")};
  transition: right linear 0.1s;
  top: 0;
`;

const ShowText = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 135%;
  letter-spacing: -0.02em;
  color: ${({ active, theme: { primary } }) => (active ? primary : "rgba(24, 24, 24, 0.7)")};
  transition: all linear 0.1s;
`;

const RequiredCheckBox = styled.div`
  overflow: hidden;
  width: 18px;
  height: 18px;
  margin-right: 14px;
  background: ${({ checked }) => (checked ? "transparent" : "#f3f3f3")};
  border: 1px solid ${({ checked, theme: { primary } }) => (checked ? primary : "#EFEFEF")};
  box-sizing: border-box;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  > svg {
    width: 14px;
    height: 14px;
    transform: ${({ checked }) => (checked ? "rotateY(0)" : "rotateY(90deg)")};
    path {
      stroke: ${({ theme: { primary } }) => primary};
    }
    transition: all linear 0.1s;
  }
`;

export const HeaderRow = ({ history, name, modifided }) => {
  const [confirm, setConfirm] = useState(false);

  const onChangeConfirm = () => {
    setConfirm(!confirm);
  };

  const onRedirect = () => history.push(`/admin/kyc/form-settings/forms`);

  return (
    <Header>
      <Btn width={150} transparent onClick={modifided ? onChangeConfirm : onRedirect}>
        {icons.left} back to TEMPLATES
      </Btn>
      <Heading m="0 0 0 25px">{name}</Heading>
      {confirm ? (
        <ConfirmMessagePortal
          heading="Edit template"
          message='Template was modifided. Are you sure to go on "Templates" page without save ?'
          onClose={() => {
            onChangeConfirm();
            window.scrollTo({ left: 0, top: 10000000, behavior: "smooth" });
          }}
          onSubmit={onRedirect}
        />
      ) : null}
    </Header>
  );
};

export const Footer = ({ onSubmit, disabled }) => {
  return (
    <Header>
      <Btn width={180} margin="0 0 0 auto" onClick={onSubmit} disabled={disabled}>
        Save changes
      </Btn>
    </Header>
  );
};

export const Statistic = ({ sections, fields }) => {
  return (
    <Card>
      <Heading>Statistics</Heading>
      <Line />
      <Row>
        <Column w="120px">
          <Label>SECTIONS</Label>
          <Val>{sections}</Val>
        </Column>
        <Column w="120px">
          <Label>Fields</Label>
          <Val>{fields}</Val>
        </Column>
      </Row>
    </Card>
  );
};

export const General = ({ name }) => {
  return (
    <Card>
      <Heading>General Settings</Heading>
      <Line />
      <Label>Template Name</Label>
      <Val>{name}</Val>
    </Card>
  );
};

export const Actions = ({ history, id, onRemove, modifided }) => {
  const [confirm, setConfirm] = useState(false);

  const onChangeConfirm = () => {
    setConfirm(!confirm);
  };

  const onRedirect = () => history.push(`/admin/kyc-form/${id}`);
  return (
    <Card>
      <Heading>Actions</Heading>
      <Line />
      <Btn fullWidth margin="0 0 10px" onClick={modifided ? onChangeConfirm : onRedirect}>
        {icons.edit} EDIT TEMPLATE
      </Btn>
      <Btn fullWidth error onClick={onRemove}>
        {icons.crossRed} REMOVE TEMPLATE
      </Btn>

      {confirm ? (
        <ConfirmMessagePortal
          heading="Edit template"
          message='Template was modifided. Are you sure to go on "Edit" page without save ?'
          onClose={() => {
            onChangeConfirm();
            window.scrollTo({ left: 0, top: 10000000, behavior: "smooth" });
          }}
          onSubmit={onRedirect}
        />
      ) : null}
    </Card>
  );
};

const Field = ({ f: { name, id, required, visibility }, sectionNumber, onChangeVisibility, onChangeRequred }) => {
  const [_required, setRequired] = useState(required);
  const [_visibility, setVisibility] = useState(visibility);

  const _onChangeRequred = () => {
    setRequired(!required);
    onChangeRequred({ id, _required, sectionNumber });
  };

  const _onChangeVisibility = () => {
    setVisibility(!_visibility);
    onChangeVisibility({ id, _visibility, sectionNumber });
  };

  return (
    <FieldRow>
      <FieldName visible={_visibility}>{name}</FieldName>
      <CheckBoxesCont>
        <CheckBoxCont>
          <ShowButton active={_visibility} onClick={_onChangeVisibility}>
            <Circle active={_visibility} />
          </ShowButton>
          <ShowText active={_visibility}>{visibility ? "Show" : "Hide"}</ShowText>
        </CheckBoxCont>
        <CheckBoxCont>
          <RequiredCheckBox checked={_required} onClick={_onChangeRequred}>
            {icons.check}
          </RequiredCheckBox>
          <ShowText active={_required}>Required</ShowText>
        </CheckBoxCont>
      </CheckBoxesCont>
    </FieldRow>
  );
};

export const Section = ({ section: { sectionName, fields }, onChangeVisibility, onChangeRequred, i }) => {
  const renderFields = () => {
    return fields.map((f) => (
      <Field
        f={f}
        key={f.id}
        sectionNumber={i}
        onChangeVisibility={onChangeVisibility}
        onChangeRequred={onChangeRequred}
      />
    ));
  };
  return (
    <Card mb={30}>
      <Heading>{sectionName}</Heading>
      <Line />
      {renderFields()}
    </Card>
  );
};

export const CheckboxComponent = ({ value, onChange, style = {} }) => (
  <RequiredCheckBox style={style} checked={value} onClick={onChange}>
    {icons.check}
  </RequiredCheckBox>
);
