import Api from "../../helpers/api";
import store from "../../index";
import { setCash } from "../../modules/cashRedux/cashAction";

export default class DataRoomApiInvestor {
  static url = Api.url;

  static origin = Api.origin;

  static defaultAvatar = Api.defaultAvatar;

  static getAttachment = Api.getAttachment;

  static getDealsInfo(token) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${DataRoomApiInvestor.url}/api/dataroom/investor/assetsDataroom`, {
        headers: {
          OriginVoC: DataRoomApiInvestor.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });

      if (result.status === 200) res(await result.json());
      else {
        rej(await result.json());
      }
    });
  }

  static getDealDocuments(token, assetId) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${DataRoomApiInvestor.url}/api/dataroom/investor/foldersView/${assetId}`, {
        headers: {
          OriginVoC: DataRoomApiInvestor.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });

      if (result.status === 200) res(await result.json());
      else {
        rej(await result.json());
      }
    });
  }

  static setReviewStatus(token, folderName, filename, assetId, status = false) {
    return new Promise(async (res, rej) => {
      const result = await fetch(
        `${DataRoomApiInvestor.url}/api/dataroom/investor/changeReviewStatus/${folderName}/${filename}?assetId=${assetId}&reviewStatus=${status}`,
        {
          method: "post",
          headers: {
            OriginVoC: DataRoomApiInvestor.origin,
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );

      if (result.status === 200) res(await result.text());
      else {
        rej(await result.json());
      }
    });
  }

  static searchInDealDocuments(token, search, assetId) {
    return new Promise(async (res, rej) => {
      const result = await fetch(
        `${DataRoomApiInvestor.url}/api/dataroom/investor/searchFileAndFolder/${search}?assetId=${assetId}`,
        {
          headers: {
            OriginVoC: DataRoomApiInvestor.origin,
            Authorization: token,
            "Content-Type": "application/json",
          },
        }
      );

      if (result.status === 200) res(await result.json());
      else {
        rej(await result.json());
      }
    });
  }

  static searchDeals(token, search) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${DataRoomApiInvestor.url}/api/dataroom/investor/searchAssetDataroom/${search}`, {
        headers: {
          OriginVoC: DataRoomApiInvestor.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });

      if (result.status === 200) res(await result.json());
      else {
        rej(await result.json());
      }
    });
  }

  static downloadFile = async (token, folderName, fileName, assetId) => {
    const requestFile = await fetch(
      `${DataRoomApiInvestor.url}/api/dataroom/investor/fileLink/${folderName}/${fileName}?assetId=${assetId}`,
      {
        headers: {
          OriginVoC: DataRoomApiInvestor.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    return await requestFile.blob();
  };

  static getStarredFiles(token, assetId) {
    return new Promise(async (res, rej) => {
      const result = await fetch(`${DataRoomApiInvestor.url}/api/dataroom/investor/starredFiles/${assetId}`, {
        headers: {
          OriginVoC: DataRoomApiInvestor.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      });

      if (result.status === 200) res(await result.json());
      else {
        rej(await result.json());
      }
    });
  }

  static getS3Link = async (token, folderName, fileName, assetId) => {
    const requestFile = await fetch(
      `${DataRoomApiInvestor.url}/api/dataroom/investor/fileLinkForDownload/${folderName}/${fileName}?assetId=${assetId}`,
      {
        headers: {
          OriginVoC: DataRoomApiInvestor.origin,
          Authorization: token,
          "Content-Type": "application/json",
        },
      }
    );
    return await requestFile.text();
  };
}
