import React, { useRef, useEffect, useState } from "react";
import { connect } from "react-redux";

import { Loader, ErrorMessage } from "../../styled";
import Api from "../../../../helpers/investorApi";
import { Wrapper, Search, DealsWrapper } from "./styled";
import { DealCard } from "./dealCard";
import { DealCardVertical } from "./dealCardVertical";

import { getInvFoldersView } from "../../../../../modules/dataRoomRedux/dataRoomActions";

const Main = ({ token, folders, getInvFoldersView }) => {
  const [filtered, setFiltered] = useState([]);
  const [search, setSearch] = useState("");
  const [errorObj, setError] = useState(null);
  const timerDebounceRef = useRef(null);
  const [smallScreen, setSmallScreen] = useState(window.innerWidth <= 1280);

  useEffect(() => {
    const handleResize = () => {
      setSmallScreen(window.innerWidth <= 1280);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    getInvFoldersView(token, setError);
  }, []);

  useEffect(() => {
    if (!filtered.length && folders) {
      setFiltered([...folders]);
    }
  }, [folders]);

  const renderDeals = () => {
    const arr = search ? filtered : folders;

    return arr.map(e =>
      smallScreen ? (
        <DealCardVertical deal={e} token={token} key={e.assetId} />
      ) : (
        <DealCard deal={e} token={token} key={e.assetId} />
      )
    );
  };

  useEffect(() => {
    clearTimeout(timerDebounceRef.current);
    timerDebounceRef.current = setTimeout(async () => {
      let searchResult = null;
      if (search) {
        searchResult = await Api.searchDeals(token, search).catch(setError);
      }
      if (searchResult) {
        setFiltered(searchResult);
      }
    }, 500);
  }, [search]);

  const onSearch = s => {
    setSearch(s);
    if (!s) setFiltered([...folders]);
  };

  if (!folders && !errorObj) {
    return <Loader />;
  } else if (errorObj) {
    return <ErrorMessage er={errorObj} onClose={() => setError(null)} />;
  } else if (folders) {
    return (
      <Wrapper>
        <Search
          value={search}
          onChange={onSearch}
          text={`You have ${folders.length} assets now`}
        />
        <DealsWrapper>{renderDeals()}</DealsWrapper>
      </Wrapper>
    );
  } else return null;
};

export default connect(
  ({
    userReducer: { token },
    dataRoomReducer: { foldersViewInvestor: folders }
  }) => ({
    token,
    folders
  }),
  { getInvFoldersView }
)(Main);
