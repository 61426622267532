import React, { useEffect, useState, Fragment } from "react";
import ReactDom from "react-dom";
import { CircularProgress } from "@material-ui/core";

import Api from "../../../../helpers/api";
import { SignSettings } from "../DigitalSignature/SignSettings";
import {
  Row,
  View,
  ReaderWrapper,
  ViewerHeader,
  ViewerDocName,
  SizeDate,
  ExpandBtn,
  ExpandWrapper,
  Col,
  Card,
  CardHeader,
  ModalBg,
} from "../DigitalSignature/styled";
import { Button } from "../Offerings/styled";
import { ReactComponent as Expand } from "../../../../assets/expand.svg";
import { ErrorMessage } from "../../../admin-layout/errorMessage";

export const DigitalSignature = ({
  token,
  assetId,
  onNextStep,
  ready,
  _useSignatureImage,
  _digitalSignature,
  _preSignDocument,
}) => {
  const [expand, setExpand] = useState(false);
  const [openSignSettings, setOpenSignSettings] = useState(false);
  const [preSignDocument, setPreSignDocument] = useState(_preSignDocument);
  const [signDocument, setSignDocument] = useState(null);
  const [useSignatureImage, setUseSignatureImage] = useState(_useSignatureImage);
  const [digitalSignature, setDigitalSignature] = useState(_digitalSignature);
  const [error, setError] = useState(null);
  const [wait, setWait] = useState(false);

  const onChangeExpand = () => setExpand(!expand);

  const onError = (e) => {
    wait && setWait(false);
    setError(e);
  };

  const toggleModalSignSettings = () => setOpenSignSettings(!openSignSettings);

  const modifySign = async (isModify) => {
    setUseSignatureImage(true);
    const signature = await Api.getDigitalSignature(token).catch(onError);

    if (isModify && signature) setDigitalSignature(signature);
    else if (!signature) setDigitalSignature(null);
  };

  const onSign = async () => {
    setWait(true);
    const file = await Api.getSignedDocument(token, assetId, useSignatureImage).catch(onError);

    if (file) {
      setSignDocument(file);
      setPreSignDocument(null);
    }
    setWait(false);
  };

  const onFinish = (print = false) => async () => {
    setWait(true);

    const res = await Api.approveSignedDocument(token, assetId).catch(onError);
    if (res) {
      if (print) window.open(signDocument).print();
      onNextStep();
    }

    setWait(false);
  };

  if (ready) {
    return (
      <Fragment>
        <Row isLayer>
          <Col left>
            {expand ? <ExpandedReader onChange={onChangeExpand} fileURL={preSignDocument || signDocument} /> : null}
            <ReaderWrapper>
              <ViewerHeader>
                <ViewerDocName></ViewerDocName>
                <SizeDate />
                <ExpandBtn onClick={onChangeExpand}>
                  <Expand />
                </ExpandBtn>
              </ViewerHeader>
              <View src={preSignDocument || signDocument} key={`key-${preSignDocument || signDocument}`} />
            </ReaderWrapper>
          </Col>
          <Col>
            <Card>
              <CardHeader>Sign Document</CardHeader>
              <Button disabled={wait} size={12} fullWidth height={36} onClick={preSignDocument ? onSign : onFinish()}>
                {preSignDocument ? "Sign" : "Finish"}
              </Button>
              {preSignDocument ? null : (
                <Button
                  disabled={wait}
                  margin="10px 0 0 0"
                  size={12}
                  fullWidth
                  height={36}
                  transparent
                  onClick={onFinish(true)}
                >
                  Print & Finish
                </Button>
              )}
            </Card>
            {preSignDocument && (
              <Card>
                <CardHeader>Sign Settings</CardHeader>
                <Button disabled={wait} size={12} fullWidth height={36} transparent onClick={toggleModalSignSettings}>
                  Change Sign Image
                </Button>
              </Card>
            )}
          </Col>
        </Row>

        {openSignSettings ? (
          <SignSettings
            isSignatureNone={!useSignatureImage}
            token={token}
            modifySign={modifySign}
            digitalSignature={digitalSignature}
            onBackdropClick={toggleModalSignSettings}
          />
        ) : null}

        {error && <ErrorMessage er={error} onClose={() => setError(null)} />}
        {wait ? (
          <ModalBg zIndex={2}>
            <CircularProgress />
          </ModalBg>
        ) : null}
      </Fragment>
    );
  }
};

const ExpandedReader = ({ fileURL, onChange }) =>
  ReactDom.createPortal(
    <ExpandWrapper>
      <ViewerHeader isExpand>
        <ViewerDocName />
        <SizeDate />
        <ExpandBtn onClick={onChange}>
          <Expand />
        </ExpandBtn>
      </ViewerHeader>
      <View isExpand src={fileURL} />
    </ExpandWrapper>,
    document.getElementById("round-modal")
  );
