import { css } from "styled-components";

export const flexSize = css`
  flex: ${({ flex = 1 }) => flex};
`;

export const border4 = css`
  border-radius: 4px;
`;

export const shadow = css`
  border: 1px solid #f9f9f9;
  box-shadow: 0px 10px 40px rgba(89, 120, 150, 0.06);
`;

export const fullVwVh = css`
  width: 100vw;
  height: 100vh;
`;

export const borderBox = css`
  box-sizing: ${({ boxSizing = "border-box" }) => boxSizing};
`;

export const muliFont = css`
  font-family: Muli;
  font-size: ${({ fontSize = 14 }) => fontSize}px;
  line-height: 135%;
  font-style: normal;
  font-weight: normal;
`;

export const textToDot = css`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const flexCenter = css`
  justify-content: center;
  align-items: center;
`;

export const scroll = css`
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 15px 0 5px;
  &&::-webkit-scrollbar-track {
    background-color: ${({ theme: { primaryLight } }) => primaryLight};
    border-radius: 2px;
  }
  &&::-webkit-scrollbar {
    width: 8px;
    border-radius: 2px;
    background-color: ${({ theme: { primaryLight } }) => primaryLight};
  }
  &&::-webkit-scrollbar-thumb {
    background-color: ${({ theme: { primaryMiddle } }) => primaryMiddle};
    border-radius: 2px;
  }
`;

export const CardBase = css`
  width: 100%;
  display: flex;
  ${(border4, muliFont, borderBox)}
  padding: ${({ p = "20px 25px" }) => p};
  margin-bottom: ${({ mb = "15px" }) => mb};
  height: ${({ h = "60px" }) => h};
  background: #fff;
`;
