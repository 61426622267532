import React, { useEffect, useState } from "react";

import { ReactComponent as Icon } from "assets/chevron-down-slim.svg";
import { ReactComponent as Check } from "assets/check-slim.svg";
import { ReactComponent as Square } from "assets/square.svg";
import { countries } from "./const";
import { MultiplePreview, Layout, CheckBox, Row, Col, Options, Text, Border, Container } from "./styled";
import { objectKeysToArray } from "./helpers";

const Item = ({ item, name, renderOptions, onChoose, selected, parent }) => {
  const [open, setOpen] = useState(false);

  const _stopPropagation = (e) => {
    e.stopPropagation();
  };

  const onOpen = (e) => {
    _stopPropagation(e);
    setOpen(!open);
  };

  const isNested = (Array.isArray(item) && item.length) || (typeof item === "object" && Object.keys(item).length);

  const isSelectedChild = [...parent, ...objectKeysToArray(item)].some((e) => selected[e]);

  return (
    <Col>
      <Row open={open}>
        <CheckBox onClick={onChoose(name, [...parent], item)}>
          {selected[name] || parent.some((e) => selected[e]) ? <Check /> : isSelectedChild ? <Square /> : null}
        </CheckBox>
        <span onClick={onOpen}>{name}</span>
        {isNested ? <Icon onClick={onOpen} /> : null}
      </Row>
      {isNested && open ? renderOptions(item, [...parent, name]) : null}
    </Col>
  );
};

export default ({
  placeholder = "Choose regions from list",
  options = { ...countries },
  onChange = console.log,
  _selected = {},
  mBottom = false,
  inProfile = false,
  edit,
}) => {
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState({ ..._selected });

  const _stopPropagation = (e) => {
    e.stopPropagation();
  };

  const onOpen = (e) => {
    _stopPropagation(e);
    setOpen(!open);
  };

  useEffect(() => {
    console.log(selected);
  }, []);
  // const onToggle = (key, child = [], parents = []) => () => {
  //   if (key in selected) {
  //     delete selected[key];
  //   } else {
  //     if (Array.isArray(child)) {
  //       child.forEach(e => delete selected[e]);
  //     }

  //     if (selected[parents[parents.length - 1]]) {
  //       let keys = null;
  //       for (let i = 0; i < parents.length; i++) {
  //         if (keys) {
  //           keys = keys[parents[i]];
  //         } else {
  //           keys = options[parents[i]];
  //         }
  //       }

  //       if (Array.isArray(keys)) {
  //       } else if (keys && typeof keys === "object") {
  //         const key = Object.keys(keys)[0];
  //         keys = keys[key];
  //         if (Array.isArray(keys)) {
  //         } else if (typeof keys === "object") {
  //           keys = Object.keys(keys);
  //         }
  //       } else {
  //         keys = [keys];
  //       }
  //       keys.forEach(e => (selected[e] = true));
  //       delete selected[key];
  //       key = null;
  //     }

  //     parents.forEach(e => delete selected[e]);
  //     if (key) selected[key] = true;
  //   }

  //   setSelected({ ...selected });
  //   onChange(Object.keys({ ...selected }));
  // };

  const onToggle = (key, parents = [], child = []) => () => {
    let rootOption = {};
    if (parents.length) {
      rootOption[parents[0]] = { ...options[parents[0]] };
    } else {
      rootOption[key] = { ...options[key] };
    }

    if (key in selected) {
      delete selected[key];
    } else if (parents.some((e) => selected[e])) {
      // ------------------------------------------------------------
      let _options = { ...rootOption };
      let lastKey = "";

      for (let i = 0; i < parents.length; i++) {
        lastKey = parents[i];
        _options = _options[lastKey];
        if (selected[lastKey]) {
          break;
        }
      }

      if (!Array.isArray(_options)) _options = Object.keys(_options);
      _options.forEach((e) => (selected[e] = true));

      _options = { ...rootOption };
      lastKey = "";
      for (let i = 0; i < parents.length; i++) {
        lastKey = parents[i];
        _options = _options[lastKey];
      }

      if (!Array.isArray(_options)) _options = Object.keys(_options);
      _options.forEach((e) => (selected[e] = true));

      parents.forEach((e) => delete selected[e]);
      delete selected[key];
      delete selected[lastKey];
    } else if (key) {
      selected[key] = true;

      const _parents = [...parents];
      let option_level = {};
      let lastKey = "";

      while (_parents.length) {
        let _options = { ...rootOption };
        lastKey = "";

        for (let i = 0; i < _parents.length; i++) {
          lastKey = _parents[i];
          _options = _options[lastKey];
        }

        option_level = _options;
        if (!Array.isArray(_options)) _options = Object.keys(_options);
        if (_options.every((e) => selected[e])) {
          _options.forEach((e) => delete selected[e]);
          selected[lastKey] = true;
        }

        _parents.pop();
      }
      objectKeysToArray(child).forEach((e) => {
        if (e !== key) delete selected[e];
      });
    }
    setSelected({ ...selected });
    onChange(Object.keys({ ...selected }));
  };

  const renderOptions = (_options, parent = []) => {
    const isArray = Array.isArray(_options);
    const arr = isArray ? _options : Object.keys(_options);
    return arr.map((el) => {
      return (
        <Item
          key={el}
          name={el}
          item={_options[el]}
          renderOptions={renderOptions}
          onChoose={onToggle}
          selected={selected}
          parent={parent}
        />
      );
    });
  };

  return (
    <Container mBottom={mBottom}>
      <Border edit={edit} onClick={onOpen} open={open} inProfile={inProfile}>
        {Object.keys(selected).length ? (
          <MultiplePreview>{Object.keys(selected).join(", ")}</MultiplePreview>
        ) : (
          <Text active>{placeholder}</Text>
        )}

        <Icon />
      </Border>
      {/* options list */}
      <Options open={open}>{renderOptions(options)}</Options>
      {/* hidden layout  */}
      {open ? <Layout onClick={onOpen} /> : null}
    </Container>
  );
};
