import React, { useState, useRef, useEffect, useLayoutEffect, useImperativeHandle, forwardRef } from "react";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

import { DefaultImage, InputHidder, Img, CropContainer, Card, Row, Col, ActionButton, FileName } from "./styled";
import { defaultPosition } from "./const";
import logo from "assets/logos/yourplatform.png";

export const CropperImg = forwardRef(({ defaultImageDate, previewMode = false, defaultImage = logo }, ref) => {
  const [imageData, setImageData] = useState(defaultImageDate || null);
  const [name, setName] = useState("");

  const inputRef = useRef(null);
  const cropperRef = useRef(null);

  const [cropper, setCropper] = useState(null);
  const [urlData, setUrlData] = useState(null);

  const getCropData = () => {
    try {
      const data = cropperRef.current.getCroppedCanvas().toDataURL();
      previewMode && setUrlData(data);
      return data;
    } catch (err) {}
  };

  useImperativeHandle(ref, () => ({
    getCropData: () => getCropData(),
  }));

  useEffect(() => {
    if (!imageData && inputRef.current) {
      inputRef.current.value = "";
    }
  }, [inputRef, imageData]);

  useLayoutEffect(() => {
    cropperRef.current = cropper;
  }, [cropper]);

  useLayoutEffect(() => {
    if (cropperRef.current) {
      setTimeout(() => cropperRef.current.setCropBoxData(defaultPosition), 100);
    }
  });

  const onDelegate = () => {
    if (inputRef && inputRef.current) {
      inputRef.current.click();
    }
  };

  const onRemove = () => {
    setImageData(null);
    setName("");
  };

  return (
    <Card>
      <InputHidder
        style={{ display: "none" }}
        ref={inputRef}
        onClick={(e) => e.stopPropagation()}
        onChange={async (e) => {
          let img = null;
          if (e.target.files.length) {
            const file = e.target.files[0];
            setName(file.name);
            const reader = new FileReader();
            img = await new Promise((resolve) => {
              reader.onload = () => {
                resolve(reader.result);
              };
              reader.readAsDataURL(file);
            });
          }

          setImageData(img);
        }}
      />
      <Row>
        <CropContainer>
          {imageData ? (
            <Cropper
              style={{ height: 50, width: 220 }}
              src={imageData}
              dragMode="move"
              minCropBoxHeight={50}
              cropBoxMovable={false}
              onInitialized={(instance) => {
                setCropper(instance);
              }}
            />
          ) : (
            <DefaultImage src={defaultImage} />
          )}
        </CropContainer>
        <Col>
          <FileName name={name}>{name || "No Logo Yet"}</FileName>
          <Row h="16px">
            <ActionButton onClick={onDelegate}>Upload Image</ActionButton>
            <ActionButton remove onClick={onRemove}>
              Delete
            </ActionButton>
          </Row>
        </Col>
      </Row>

      {previewMode && (
        <>
          <button onClick={getCropData}>preview test</button>
          <br />
          <Img src={urlData} />
        </>
      )}
    </Card>
  );
});
