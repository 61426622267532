import Api from "helpers/api";

export default class EventApi {
  static url = Api.url;
  static origin = Api.origin;
  static token = localStorage.getItem("auth-token");

  static getEvents() {
    return new Promise(async (res) => {
      const response = await fetch(`${EventApi.url}/api/admin/calendarOfEvents/events`, {
        headers: {
          OriginVoC: Api.origin,
          "Content-Type": "application/json",
          Authorization: EventApi.token,
        },
      });
      if (response.status == 200) {
        res(await response.json());
      } else {
        res([]);
      }
    });
  }

  static createEvents(body) {
    return new Promise(async (res) => {
      const response = await fetch(`${EventApi.url}/api/admin/calendarOfEvents/event`, {
        method: "post",
        headers: {
          OriginVoC: Api.origin,
          "Content-Type": "application/json",
          Authorization: EventApi.token,
        },
        body: JSON.stringify(body),
      });

      res(await response.text());
    });
  }

  static updateEvents(body) {
    return new Promise(async (res) => {
      const response = await fetch(`${EventApi.url}/api/admin/calendarOfEvents/event/${body.id}`, {
        method: "put",
        headers: {
          OriginVoC: Api.origin,
          "Content-Type": "application/json",
          Authorization: EventApi.token,
        },
        body: JSON.stringify(body),
      });

      res(await response.text());
    });
  }

  static deleteEvents(id) {
    return new Promise(async (res) => {
      const response = await fetch(`${EventApi.url}/api/admin/calendarOfEvents/event/${id}`, {
        method: "delete",
        headers: {
          OriginVoC: Api.origin,
          "Content-Type": "application/json",
          Authorization: EventApi.token,
        },
      });
      res(await response.text());
    });
  }

  static getEventsNotification(token) {
    return new Promise(async (res) => {
      const response = await fetch(`${EventApi.url}/api/admin/calendarOfEvents/allNotifications`, {
        headers: {
          OriginVoC: Api.origin,
          "Content-Type": "application/json",
          Authorization: token,
        },
      });
      if (response.status == 200) {
        res(await response.json());
      } else {
        res([]);
      }
    });
  }

  static setEventRead(token, id) {
    return new Promise(async (res, rej) => {
      const response = await fetch(`${EventApi.url}/api/admin/calendarOfEvents/setAsRead/${id}`, {
        method: "put",
        headers: {
          OriginVoC: Api.origin,
          "Content-Type": "application/json",
          Authorization: token,
        },
      });
      if (response.status === 200) {
        res(await response.text());
      } else rej(await response.json());
    });
  }
  static setAllEventsRead(token) {
    return new Promise(async (res, rej) => {
      const response = await fetch(`${EventApi.url}/api/admin/calendarOfEvents/setAllAsRead`, {
        method: "put",
        headers: {
          OriginVoC: Api.origin,
          "Content-Type": "application/json",
          Authorization: token,
        },
      });
      if (response.status === 200) {
        res(await response.text());
      } else rej(await response.json());
    });
  }
}
