import React from "react";

import { Card, CardHead, Document, Col, Layer } from "./styled";

export const DataRoom = ({
  docs,
  token,
  stepName,
  makeSign,
  isPreview = false,
}) => {
  const renderDocs = () => {
    const docNames = Object.keys(docs);
    if (docs && docNames.length) {
      return docNames.map((e, i) => {
        if (e !== "otherDocs") {
          return (
            <Document
              token={token}
              doc={docs[e]}
              key={`${docs[e].secureHash}-${i}-${docs[e].fileNameWithExtension}`}
              stepName={stepName}
              makeSign={makeSign}
            />
          );
        } else {
          if (docs.otherDocs) {
            return docs.otherDocs.map((e, i) => (
              <Document
                token={token}
                doc={e}
                key={`${docs.otherDocs[i].secureHash}-${i}-${docs.otherDocs[i].fileNameWithExtension}`}
                stepName={stepName}
                makeSign={makeSign}
              />
            ));
          }
        }
      });
    }
  };
  return (
    <Card padding="25px">
      {isPreview ? <Layer /> : null}
      <CardHead margin="0 auto 24px 0">Data Room</CardHead>
      <Col>{renderDocs()}</Col>
    </Card>
  );
};
