import React, { useState, useEffect } from "react";

import { Card, Row, ShareProperty } from "./styled";
import Api from "../../../../helpers/api";

export const ShareInfo = ({
  info: { assetSymbol, assetLocation, dealTypeFinancial, currentlyFundraising },
  financialInfo: { baseCurrency, valuation },
  assetId,
  token,
  roundsInfo = [],
  cutFlow,
}) => {
  const [convert, setConvert] = useState(assetId ? "" : "value");

  useEffect(() => {
    if (assetId && !cutFlow) {
      Api.convertTokens(token, assetId, 1, baseCurrency).then((json) => setConvert(json));
    } else if (valuation && baseCurrency) {
      setConvert(valuation + baseCurrency);
    } else if (roundsInfo.length) {
      const { sharePrice, currency } = roundsInfo[roundsInfo.length - 1];
      setConvert(sharePrice + currency);
    }
  }, []);

  return (
    <Card padding="18px 25px">
      <Row>
        <ShareProperty heading="Asset Symbol" data={assetSymbol} />
        {!cutFlow && !currentlyFundraising ? (
          <ShareProperty
            heading="Share Price"
            data={convert.length >= 11 ? convert : `1 ${assetSymbol} = ${convert}`}
          />
        ) : null}
        <ShareProperty heading="Investment structure" data={dealTypeFinancial} />
        <ShareProperty lower heading="Location" data={assetLocation} />
      </Row>
    </Card>
  );
};
