import React, { Fragment } from "react";
import moment from "moment";

import {
  ModalBackground,
  ModalWrapper,
  ModalHeader,
  ModalHeading,
} from "../Offer/styled";
import {
  DataWrapper,
  Row,
  DataBlock,
  Date,
  StatusName,
  AssetLogo,
  DealName,
  DataBlockTransaction,
} from "./styled";
import { DocsSeprateLine } from "../Documents/styled";
import { ReactComponent as CloseIcon } from "../../../../assets/x-circle.svg";
import { ReactComponent as LinkIcon } from "../../../../assets/link.svg";
import { ReactComponent as ArrowUp } from "../../../../assets/arrow-up-right.svg";

export const TransactionInfoModal = ({
  isOpen,
  onBackDropClick,
  action,
  time,
  completed,
  statusName,
  assetLogo,
  assetNameMovementFor,
  paymentMethod,
  paymentId,
  bankAccount,
  quantity,
  sharePrice,
  movementType,
  tokenIdentifier,
  vocTokenQuantity,
}) => {
  return (
    isOpen && (
      <ModalBackground onClick={onBackDropClick}>
        <ModalWrapper width={734} onClick={(e) => e.stopPropagation()}>
          <ModalHeader boxShadow="0px 10px 40px rgba(89, 120, 150, 0.08)">
            <ModalHeading textTransform="none">
              {moment(time).format("DD/MM/YYYY") + " " + action}
            </ModalHeading>
            <CloseIcon onClick={onBackDropClick} />
          </ModalHeader>
          <DataWrapper>
            <Row margin="0 0 20px">
              <DataBlock
                width="310px"
                heading="Date & Time"
                children={
                  <div
                    style={{
                      display: "flex",
                      textTransform: "none",
                      padding: "0 20px",
                    }}
                  >
                    <Date>{moment(time).format("dddd, DD/MM/YYYY, ")}</Date>
                    <Date light>{moment(time).format("hh:mm:ss A")}</Date>
                  </div>
                }
              />
              <DataBlock
                width="310px"
                heading="Status"
                green={
                  completed &&
                  movementType !== "CANCELED" &&
                  movementType !== "REJECTED"
                }
                grey={!completed}
                red={movementType === "CANCELED" || movementType === "REJECTED"}
                children={<StatusName>{statusName}</StatusName>}
              />
            </Row>
            <Row>
              <DataBlock
                width="100%"
                heading="Asset"
                asset
                children={
                  <Fragment>
                    <AssetLogo src={assetLogo} />
                    <DealName>{assetNameMovementFor}</DealName>
                    <LinkIcon />
                  </Fragment>
                }
              />
            </Row>
            <DocsSeprateLine />
            <DealName size={16} margin="0 0 16px 0">
              Transaction Information
            </DealName>
            <Row margin="0 0 25px 0" justify="flex-start">
              <DataBlockTransaction
                red={movementType === "OUTCOME" || movementType === "REJECTED"}
                green={movementType === "INCOME"}
                width="150px"
                heading="Transaction Type"
                data={
                  (
                    <Fragment>
                      {movementType}
                      {movementType === "OUTCOME" ||
                      movementType === "INCOME" ? (
                        <ArrowUp />
                      ) : null}
                    </Fragment>
                  ) || ""
                }
              />

              {paymentMethod ? (
                <DataBlockTransaction
                  width="150px"
                  heading="Payment method"
                  data={paymentMethod}
                />
              ) : null}
              {paymentId ? (
                <DataBlockTransaction
                  width="150px"
                  heading="Payment Id"
                  data={paymentId}
                />
              ) : null}
              {bankAccount ? (
                <DataBlockTransaction
                  width="150px"
                  heading="BANK ACCOUNT"
                  data={bankAccount}
                />
              ) : null}
            </Row>
            <DealName size={16} margin="0 0 16px 0">
              Share Details
            </DealName>
            <Row justify="flex-start">
              {quantity ? (
                <DataBlockTransaction
                  width="150px"
                  heading="Paid Amount"
                  data={quantity.toFixed(2) + " " + tokenIdentifier || ""}
                />
              ) : null}
              {vocTokenQuantity ? (
                <DataBlockTransaction
                  width="150px"
                  heading="shares"
                  data={vocTokenQuantity}
                />
              ) : null}
              {sharePrice ? (
                <DataBlockTransaction
                  width="150px"
                  heading="Share price"
                  data={sharePrice}
                />
              ) : null}
            </Row>
          </DataWrapper>
        </ModalWrapper>
      </ModalBackground>
    )
  );
};
