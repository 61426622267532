import React from "react";

import { Card, CardHead, Text } from "./styled";

export const DynamicBlock = ({ block: { header, text } }) => {
  return (
    <Card padding="25px">
      <CardHead margin="0 auto 17px 0">{header}</CardHead>
      <Text dangerouslySetInnerHTML={{ __html: text }} />
    </Card>
  );
};
