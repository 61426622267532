import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { CircularProgress } from "@material-ui/core";

import { Wrapper } from "../../main";
import { Header, TextH3 } from "../DealForm/Step/styled";
import { ReactComponent as ArrowLeft } from "../../../../assets/arrowleft.svg";
import { Btn } from "../../buttons";
import {
  Row,
  Main,
  TextSub,
  InputRow,
  Col,
  SubmitBlock,
  UploadersCol,
  EmptyBlock,
  Statistic,
  Label
} from "./styled";
import {
  TextFieldComponent,
  TextEditorComponent,
  FooterComponent,
  VariablesComponent,
  TextAreaComponent
} from "../../forms";
import { SendEmailMessage } from "../../actionMessage";
import { FileUploader } from "./fileUploader";
import Api from "../../../../helpers/api";
import { SuccessMessage } from "../../errorMessage";

const InstitutionEmails = ({
  token,
  history,
  match: {
    params: { id }
  }
}) => {
  const [templateData, setTemplateData] = useState(null);
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [uploadersCount, setUploadersCount] = useState(1);
  const [files, setFiles] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [error, setError] = useState(false);
  const [wait, setWait] = useState(false);
  const [institution, setInstitution] = useState([]);

  const renderUploaders = () => {
    let uploaders = [];
    for (let i = 0; i < uploadersCount; i++) {
      const random = Math.round(Math.random() * 1000);
      uploaders.push(
        <FileUploader
          key={`uploader-${random}`}
          order={i + 1}
          onAddFile={f => onAddFile(f)}
          onDelete={f => onRemoveFile(f)}
          _file={files[i] ? files[i] : null}
        />
      );
    }
    return uploaders;
  };

  const onChangeFooterText = e => {
    const data = { ...templateData };
    data.customFooterText = e.target.value;
    setTemplateData(data);
  };

  const onAddFile = file => {
    const _files = [...files];
    _files.push(file);
    setFiles(_files);
  };

  const onRemoveFile = name => {
    const _files = [...files];
    const index = _files.findIndex(e => e.name === name);
    _files.splice(index, 1);
    setFiles(_files);
  };

  useEffect(() => {
    setUploadersCount(files.length + 1);
  }, [files]);

  const getInitialData = async () => {
    const inst = await Api.getInstitutionById(token, id);
    if (inst) setInstitution(inst);

    const template = await Api.getTemplateSettings(token, "SEND_EMAIL_TO_USER");
    if (template) setTemplateData(template);
  };

  useEffect(() => {
    getInitialData();
  }, []);

  const onBackToDeal = () => history.push(`/admin/users/institution/${id}`);
  const onSubmit = async () => {
    setWait(true);
    const formData = new FormData();
    formData.append("linearId", "");
    formData.append("message", message);
    formData.append("subject", subject);

    if (files.length) {
      for (let i = 0; i < files.length; i++) {
        formData.append(`attachments`, files[i], files[i].name);
      }
    }

    const res = await Api.sendMailsToInstitution(token, formData, id).catch(
      () => {
        setError(true);
        setModalOpen(true);
        setWait(false);
      }
    );

    if (res) {
      setModalOpen(true);
      setWait(false);
    }
  };

  const onCloseModal = () => {
    setModalOpen(false);
    error && setError(false);
  };

  const onChangeCustomFooter = async () => {
    const newData = {
      ...templateData,
      customFooter: !templateData.customFooter
    };
    setTemplateData(newData);
    await Api.updateTemplateSettings(token, newData);
  };

  if (templateData) {
    const {
      variables: { variables },
      customFooter,
      customFooterText
    } = templateData;
    return (
      <Wrapper bg="transparent">
        <Header>
          <Btn transparent onClick={onBackToDeal} width={190}>
            <ArrowLeft /> {"back to institution"}
          </Btn>
          <TextH3 marginSize="0 0 0 25px">
            Send e-mail to all representatives in {institution.name}
          </TextH3>
        </Header>

        <Row>
          <Col width="calc(100% - 383px)">
            <Main width="100%">
              <TextSub
                textAlign="left"
                fontWeight="bold"
                color="#181818"
                marginSize="0 0 25px 0"
              >
                Message to Users
              </TextSub>
              <InputRow>
                <TextFieldComponent
                  label="Subject"
                  placeholder="E-mail subject"
                  defaultValue={subject}
                  onChange={event => setSubject(event.target.value)}
                />
              </InputRow>
              <InputRow>
                <TextEditorComponent
                  label="Template content"
                  defaultValue={message}
                  onChange={event => setMessage(event)}
                />
              </InputRow>
              <InputRow last={!customFooter}>
                <FooterComponent
                  label="e-mail footer"
                  name="Global E-mail footer"
                  defaultValue={!customFooter}
                  onChange={onChangeCustomFooter}
                />
              </InputRow>
              {customFooter && (
                <InputRow last>
                  <TextAreaComponent
                    label="custom signature for e-mail"
                    placeholder="Write here custom signature or left this fields if you don’t want to use the signature"
                    defaultValue={customFooterText}
                    onChange={onChangeFooterText}
                  />
                </InputRow>
              )}
              <UploadersCol>
                {renderUploaders()}
                <EmptyBlock />
                <EmptyBlock />
              </UploadersCol>

              <SubmitBlock>
                <Btn width={200} height={42} onClick={onSubmit} disabled={wait}>
                  SEND e-mails ({institution.users.length})
                </Btn>
              </SubmitBlock>
            </Main>
          </Col>

          <Col width="353px">
            <Main margin="0 0 30px" key={`variables-${variables.length}`}>
              <TextSub
                textAlign="left"
                fontWeight="bold"
                color="#181818"
                marginSize="0 0 25px 0"
              >
                Help
              </TextSub>
              <VariablesComponent label="Shortcuts" variables={variables} />
            </Main>
            <Main>
              <TextSub
                textAlign="left"
                fontWeight="bold"
                color="#181818"
                marginSize="0 0 25px 0"
              >
                Statistics
              </TextSub>
              <Label>Total institutions</Label>
              <Statistic>{institution.users.length} institutions</Statistic>
            </Main>
          </Col>
        </Row>
        {modalOpen ? (
          <SuccessMessage
            onClose={onCloseModal}
            message="Your email has been sent successfully to all the users"
          />
        ) : null}
        <SendEmailMessage
          isError={error}
          onBackDpopClick={onCloseModal}
          isOpen={modalOpen && error}
        />
      </Wrapper>
    );
  } else {
    return (
      <Wrapper
        width="100%"
        justifyContent="center"
        alignItems="center"
        bg="transparent"
        height="calc(100vh - 130px)"
      >
        <CircularProgress />
      </Wrapper>
    );
  }
};

export default connect(
  ({ userReducer: { token } }) => ({
    token
  }),
  null
)(InstitutionEmails);
