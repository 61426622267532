import React, { useEffect, Fragment, useState } from 'react';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';

import UserList from '../components/admin-layout/pages/UserList';
import Deals from '../components/admin-layout/pages/Deals';
import DealFormFull from '../components/admin-layout/pages/DealForm';
import DealFormStep from '../components/admin-layout/pages/DealForm/Step';
import CreateUser from '../components/admin-layout/pages/CreateUser/';
import UserInfo from '../components/admin-layout/pages/UserInfo/';
import DashBoard from '../components/admin-layout/pages/DashBoard';
import ComunicationSetup from '../components/admin-layout/pages/ComunicationSetup/';
import TemplateSetup from '../components/admin-layout/pages/TemplateSetup/';
import KYC from '../components/admin-layout/pages/KYC/';
import AssetDetails from '../components/admin-layout/pages/AssetDetails';
import ChooseAssetType from '../components/admin-layout/pages/ChooseAssetType';
import UserKYC from '../components/admin-layout/pages/UserKyc';
import Chat from '../components/admin-layout/pages/Chat';
import Account from '../components/admin-layout/pages/Account';
import GroupEmails from '../components/admin-layout/pages/GroupEmails';
import DataRoomApp from '../data-room-panel/data-room.app';
import NewGroup from '../components/admin-layout/pages/NewGroup';
import EditGroup from '../components/admin-layout/pages/EditGroup';
import GroupInfo from '../components/admin-layout/pages/GroupInfo';
import OneGroupEmail from '../components/admin-layout/pages/OneGroupEmail';
import DataRoomDeal from '../components/admin-layout/pages/DataRoomDeal';
import DeafFormContinue from '../components/admin-layout/pages/DealForm/DealFormContinue';
import DealsStatistic from '../components/admin-layout/pages/DealsStatistic';
import CreateInstitution from '../components/admin-layout/pages/CreateInstitution';
import InstitutionInfo from '../components/admin-layout/pages/InstitutionInfo';
import InstitutionEmails from '../components/admin-layout/pages/InstitutionEmails';
import EditIstitution from '../components/admin-layout/pages/EditInstitution';
import SendReports from '../components/admin-layout/pages/SendReport';
import SendReportsUser from '../components/admin-layout/pages/SendReportUser';
import QAMain from '../components/admin-layout/pages/QAMain';
import QAAsset from '../components/admin-layout/pages/QAAsset';
import QAAnswer from '../components/admin-layout/pages/QAAnswer';
import CreateKycForm from 'components/admin-layout/pages/CreateKycForm';
import ViewKycForm from 'components/admin-layout/pages/ViewKycForm';
import Confirmation from 'components/admin-layout/pages/DealCreateConfirmation';
import AllEvents from 'components/admin-layout/pages/AllEvents';
import SponsorInfo from '../components/admin-layout/pages/SponsorInfo';
import { Connect } from 'components/admin-layout/pages/Connect';
import EditSponsor from 'components/admin-layout/pages/EditSponsor';

import { AdminHeader, HeaderBlock, SearchBar } from '../components/admin-layout/header';
import { ReactComponent as MessageIcon } from '../assets/message-icon.svg';
import { ReactComponent as SearchIcon } from '../assets/search-icon.svg';
import { SidebarFull, Logo, LogoContainer } from '../components/admin-layout/sidebar';

import { Wrapper, MainWrapper } from '../components/admin-layout/main';
import { TextLink, BottomLinksBlock, LinksBlockHeading, BottomLink } from '../components/admin-layout/navigation';
import { AvatarMenu } from '../components/admin-layout/avatar';

import { Navigation, SponsorNavigation, SetupNavigation } from './admin.const';
import { clearStore } from '../helpers/localstore';
import * as asyncDataAction from '../modules/asyncDataRedux/asyncDataAction';
import { getAdminFoldersView } from '../modules/dataRoomRedux/dataRoomActions';
import Api from '../helpers/api';
import ApiNotification from '../helpers/apiNotification';
import { Notifications } from './styled';
import { getEvents, updateAdminNotifications } from 'modules/dataRedux/dataAction';

import './index.css';
import { NotFound } from '../pages/NotFound';

import { requestToken } from '../modules/notification';

import { initConnect } from 'modules/socket/socket.io';
import theme from 'models/themes';

import { persistor } from 'modules/store';

//config
import config from 'config';
const current = process.env.REACT_APP_CURRENT;

const AdminApp = (props) => {
  const {
    location: { pathname = '' },
  } = props;

  const [selectedPage, setSelectedPage] = useState(null);
  const onChangePage = (page = null) => () => setSelectedPage(page);

  const link = Navigation.find((e) => e.link === pathname);
  if (link && !selectedPage) setSelectedPage(link.text);

  useEffect(() => {
    const { history, token, role } = props;
    if (!token || (role && role !== 'AM' && role !== 'ADMIN' && role !== 'SPONSOR')) {
      history.push('/');
    }
  });
  const [waitAsyncData, setWaitAsyncData] = useState(true);

  const getNotifications = async () => {
    const { token, updateAdminNotifications } = props;

    const transactions = await Api.getDashboardTransactions(token);
    const newRequests = await Api.getNewRequests(props.token);
    const assets = await Api.getAssets(token);
    const processingKyc = await Api.getInvestorAnswers(token, 1, 1000, 'PROCESSING');
    const waitingQuestions = await Api.getQuestionAnswer(token);

    const notifications = {
      Dashboard: transactions ? transactions.filter((t) => t.movementType === 'PENDING').length : 0,
      Assets: assets && assets.NEW_DEAL ? assets.NEW_DEAL.filter((e) => !e.finalized).length : 0,
      Users: newRequests ? newRequests.length : 0,
      KYC: processingKyc ? processingKyc.content.length : 0,
      Connect: null,
      'Q&A': waitingQuestions
        ? waitingQuestions.reduce((total, e) => {
            return total + e.waitingQuestions;
          }, 0)
        : 0,
    };
    updateAdminNotifications(notifications);
  };

  useEffect(() => {
    const { token } = props;
    (async function () {
      initConnect(props.token, props.username);

      const acceptedCurrency = await Api.getPossibleCurrencies(token);
      props.setAcceptedCurrency(acceptedCurrency);

      const sponsors = await Api.getSponsors(token);
      props.setSponsors(sponsors.map(({ username }) => username));
      props.setSponsorsFull(sponsors);

      const accesses = await Api.getAccesses(token);
      props.setAccesses(accesses);

      const dealTypes = await Api.getDealTypes(token);
      props.setDealTypes(dealTypes);

      const categories = await Api.getCategory(token);
      props.setCategories(categories.map(({ name }) => name));

      const ruleOptions = await Api.getRuleOptions(token);
      props.setRuleOptions(ruleOptions);

      const countriesOption = await Api.getCountriesOption(token);
      props.setCountriesOption(countriesOption);

      const lockedUpPeriods = await Api.getLockedUpPeriods(token);
      props.setLockUpTimes(lockedUpPeriods);

      const financialTypes = await Api.getFinancialDealTypes(token);
      props.setFinancialTypes(financialTypes);

      await props.getAdminFoldersView(token, console.log);
      setWaitAsyncData(false);

      props.getEvents(props.token);

      getNotifications();

      try {
        const res = await Api.getUserData(token);
        if (res && res.deviceIds) {
          const deviceId = await requestToken();

          if (!res.deviceIds.includes(deviceId)) {
            ApiNotification.saveToken(token, deviceId);
          }
        }
      } catch (err) {
        console.log(err);
      }
    })();
  }, []);

  useEffect(() => {
    if (props.socketConnect) {
      Api.updateUnreadMessages(props.token);
    }
  }, [props.socketConnect]);

  return (
    <Fragment>
      <Wrapper row>
        <SidebarFull>
          <LogoContainer>
            <Logo styles={config[current].logoStyles.admin} src={config[current].logo} />
          </LogoContainer>
          {props.role === 'AM' || props.role === 'ADMIN'
            ? Navigation.map(({ text, link, re }) => (
                <TextLink
                  count={props && props.adminNotifications && props.adminNotifications[text]}
                  key={`text-${link}`}
                  link={link}
                  active={re.test(pathname)}
                  onClick={onChangePage(text)}
                  text={text}
                />
              ))
            : SponsorNavigation.map(({ text, link, re }) => (
                <TextLink
                  count={props.adminNotifications[text]}
                  key={`text-${link}`}
                  link={link}
                  active={re.test(pathname)}
                  onClick={onChangePage(text)}
                  text={text}
                />
              ))}
          {(props.role === 'AM' || props.role === 'ADMIN') && (
            <BottomLinksBlock>
              <LinksBlockHeading>Management</LinksBlockHeading>
              {SetupNavigation.map(({ text, link, re }) => (
                <BottomLink key={link} text={text} link={link} active={re.test(pathname)} />
              ))}
            </BottomLinksBlock>
          )}
        </SidebarFull>

        <Wrapper>
          <AdminHeader>
            <HeaderBlock noBorder leftAlign padding="0 15px 0 30px">
              <SearchIcon />
            </HeaderBlock>
            <SearchBar placeholder="Search among connect" />
            <HeaderBlock active={props.history.location.pathname.includes('/admin/chat') ? 1 : 0} newMess={props.unreadMessages.total}>
              <MessageIcon onClick={() => props.history.push('/admin/chat/')} />
            </HeaderBlock>
            <Notifications
              token={props.token}
              history={props.history}
              events={props.events}
              socketCount={props.todayEventsCount}
              getEvents={props.getEvents}
            />
            <HeaderBlock>
              <AvatarMenu
                userAvatar={props.logo}
                fullName={props.fullName}
                username={props.fullName}
                role={props.role}
                onLogout={async () => {
                  try {
                    requestToken().then((tokenNotification) => {
                      if (tokenNotification) ApiNotification.removeToken(props.token, tokenNotification).catch(console.log);
                    });
                    clearStore();
                    window.location.replace(`${window.location.origin}/`);
                  } catch (error) {}
                }}
                onClick={() => props.history.push('/admin/account')}
              />
            </HeaderBlock>
          </AdminHeader>
          {waitAsyncData ? (
            <div>Loading...</div>
          ) : (
            <MainWrapper bg={theme.mainBgAdmin}>
              <Switch>
                <Route path="/admin/kyc/:type?" component={KYC} />
                <Route exact path="/admin/kyc-user/:id?" component={UserKYC} />
                <Route exact path="/admin/users/:type?" component={UserList} />
                <Route exact path="/admin/user/create" component={CreateUser} />
                <Route
                  exact
                  path="/admin/user/institution/create/:lastName?/:firstName?/:email?/:country?/:companyName?/:phone?"
                  component={CreateInstitution}
                />
                <Route exact path="/admin/user/new-group" component={NewGroup} />
                <Route exact path="/admin/users/edit-group/:id?" component={EditGroup} />
                <Route exact path="/admin/users/edit-sponsor/:userName?" component={EditSponsor} />
                <Route exact path="/admin/users/group/:id?" component={GroupInfo} />
                <Route exact path="/admin/users/group/send-emails/:id?/:fromGroup?" component={OneGroupEmail} />
                {/* path with create new Assets */}
                <Route exact path="/admin/deals/create/full" component={DealFormFull} />
                <Route exact path="/admin/deals/create/step/:dealType" component={DealFormStep} />
                <Route exact path="/admin/deals/create/data-room/:id/:dealType" component={DataRoomDeal} />
                <Route exact path="/admin/deals/create" component={ChooseAssetType} />
                <Route exact path="/admin/deals/create/confirmation/:assetId" component={Confirmation} />
                <Route exact path="/admin/chat/:assetId?/:userName?" component={Chat} />
                <Route exact path="/admin/deals/edit/:id" component={DealFormFull} />
                <Route exact path="/admin/deals/continue/:unfinishedId" component={DeafFormContinue} />
                <Route exact path="/admin/user/:userName" path="/admin/user/:userName?/:id?/:groupId?" component={UserInfo} />
                <Route exact path="/admin/users/institution/:id?" component={InstitutionInfo} />
                <Route exact path="/admin/users/institution/send-emails/:id?" component={InstitutionEmails} />
                <Route exact path="/admin/users/edit-institution/:instId?/:investorId?" component={EditIstitution} />
                <Route exact path="/admin/deals/:type" component={Deals} />
                <Route exact path="/admin/dashboard/:id" component={AssetDetails} />
                <Route exact path="/admin/dashboard/statistic/:id" component={DealsStatistic} />
                <Route exact path="/admin/full-dashboard" component={DashBoard} />
                <Route exact path="/admin/comunication-setup/:type?" component={ComunicationSetup} />
                <Route exact path="/admin/comunication-setup/:type?/:template" component={TemplateSetup} />
                <Route exact path="/admin/account" component={Account} />
                <Route exact path="/admin/send-emails/:id/:extra?" component={GroupEmails} />
                <Route path="/admin/data-room" component={DataRoomApp} />
                <Route exact path="/admin/send-report/:id" component={SendReports} />
                <Route exact path="/admin/send-report-user/:user" component={SendReportsUser} />
                <Route exact path="/admin/question-and-answer/:type?" component={QAMain} />
                <Route exact path="/admin/question-and-answer/asset/:assetId?" component={QAAsset} />
                <Route exact path="/admin/question-and-answer/answer/:assetId?/:questionId?/:edit?" component={QAAnswer} />
                <Route exact path="/admin/question-and-answer/edit-answer/:assetId?/:questionId?/:edit?" component={QAAnswer} />
                <Route path="/admin/kyc-form/:id?" component={CreateKycForm} />
                <Route path="/admin/kyc-form-view/:id" component={ViewKycForm} />
                <Route exact path="/admin/events-list" component={AllEvents} />
                <Route exact path="/admin/connect" component={Connect} />
                <Route exact path="/admin/users/sponsor/:userName?" component={SponsorInfo} />
                <Route exact component={NotFound} />
              </Switch>
            </MainWrapper>
          )}
        </Wrapper>
      </Wrapper>
    </Fragment>
  );
};

export default connect(
  ({
    userReducer: { token, role, logo, fullName, username },
    chatReducer: { unreadMessages, socketConnect },
    dataReducer: { events, todayEventsCount, adminNotifications },
  }) => ({
    token,
    role,
    logo,
    fullName,
    username,
    unreadMessages,
    socketConnect,
    events,
    todayEventsCount,
    adminNotifications,
  }),
  {
    ...asyncDataAction,
    getAdminFoldersView,
    getEvents,
    updateAdminNotifications,
  },
)(AdminApp);
