import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { CircularProgress } from "@material-ui/core";

import { Loader } from "components/common/adminPageLoader";
import { Card, Heading, Button } from "./styled";
import { icons } from "models/icons";
import { EventItem } from "admin-panel/styled";
import EventApi from "components/common/calendar/api";
import { getEvents } from "modules/dataRedux/dataAction";

const OneEvent = ({ e, token, onRedirect, getEvents }) => {
  const [wait, setWait] = useState(false);

  const onSetStatus = (id) => async () => {
    setWait(true);
    await EventApi.setEventRead(token, id)
      .then(() => getEvents(token))
      .catch(() => setWait(false));
    setWait(false);
  };

  return (
    <Card h="105px">
      <EventItem
        future={!moment(e.dateTime).isAfter(new Date(new Date().setHours(0, 0, 0, 0)))}
        mainPage
        token={token}
        ev={e}
      />
      <Button onClick={onRedirect(`/admin/dashboard/${e.assetId}`)} margin="0 0 0 auto">
        GO TO ASSET
      </Button>
      {!e.read && (
        <Button transparent margin="0 0 0 10px" onClick={wait ? undefined : onSetStatus(e.id)}>
          {wait ? <CircularProgress size={14} color="inherit" /> : "SET AS READ"}
        </Button>
      )}
    </Card>
  );
};

const AllEvents = ({ token, history, events, eventsLoaded, getEvents }) => {
  const [wait, setWait] = useState(false);

  const onSetStatus = async () => {
    setWait(true);
    await EventApi.setAllEventsRead(token)
      .then(() => getEvents(token))
      .catch(() => setWait(false));
    setWait(false);
  };

  const onRedirect = (link) => () => {
    link ? history.push(link) : history.goBack();
  };

  const renderEvents = () => {
    const sorted = [...events].reverse();
    return sorted.map((e, i) => {
      return (
        <OneEvent
          key={`${e.dateTime}-${e.assetId}-${i}`}
          e={e}
          token={token}
          onRedirect={onRedirect}
          getEvents={getEvents}
        />
      );
    });
  };

  return (
    <Fragment>
      {eventsLoaded ? (
        <Fragment>
          <Card>
            <Button onClick={onRedirect()} margin="0 25px 0 0" transparent>
              {icons.left} back
            </Button>
            <Heading>All Notifications</Heading>
            <Button transparent margin="0 0 0 auto" onClick={wait ? undefined : onSetStatus}>
              {wait ? <CircularProgress size={14} color="inherit" /> : "SET All as READ"}
            </Button>
          </Card>
          {renderEvents()}
        </Fragment>
      ) : (
        <Loader />
      )}
    </Fragment>
  );
};

export default connect(
  ({ userReducer: { token }, dataReducer: { events, eventsLoaded } }) => ({ token, events, eventsLoaded }),
  { getEvents }
)(AllEvents);
