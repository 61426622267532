import React, { useState } from "react";
import moment from "moment";

import Api from "../../../../helpers/api";
import { RemoveQuestionModal } from "../../../admin-layout/pages/QAAsset/RemoveQuestionModal";
import { ErrorMessage } from "../../../admin-layout/errorMessage";
import {
  Question,
  TopWrapper,
  BottomWrapper,
  TextWrapper,
  QHeader,
  TextBlock,
  ClockBlock,
  RemButton,
  UserAvatar,
  FileBlock,
  FileName,
  FileControls
} from "./styled";
import { Wait } from "../../../admin-layout/pages/DashBoard/styled";
import { ReactComponent as Clock } from "../../../../assets/clock-i.svg";
import { ReactComponent as Rem } from "../../../../assets/x-icon.svg";
import { File } from "components/common/icons-primary";
import { ReactComponent as Eye } from "../../../../assets/eye.svg";
import { ReactComponent as Download } from "../../../../assets/download.svg";
import { getTrackerHTML } from "../../../../data-room-panel/helpers/fileViewer";

export const CardWaiting = ({
  q: {
    assetId,
    category,
    textQuestion,
    userFullName,
    userLogo,
    dateOfQuestion,
    questionId,
    userInvestorType,
    userGender,
    size,
    filename,
    folderName
  },
  token,
  ownQuestion,
  onRefresh
}) => {
  const [wait, setWait] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [error, setError] = useState(null);

  const onClose = () => {
    setModalOpen(!modalOpen);
  };

  const onRemove = () => {
    setModalOpen(true);
  };

  const onSendRequest = async () => {
    setWait(true);
    const res = await Api.deleteQuestionInv(token, questionId).catch(onError);

    if (res) {
      onRefresh();
      setWait(false);
    }
  };

  const onError = e => {
    setError(e);
    setWait(false);
  };

  const onView = async () => {
    getTrackerHTML(
      token,
      filename,
      `${folderName}/${filename}?assetId=${assetId}`,
      false,
      true,
      true,
      false
    );
  };

  const downloadDocument = async () => {
    const fileUrl = await Api.downloadQADocument(
      token,
      folderName,
      filename,
      assetId,
      true
    ).catch(onError);

    if (fileUrl) {
      const link = document.createElement("a");
      link.target = "_blank";
      link.href = fileUrl;
      link.click();
    }
  };

  return (
    <Question>
      <TopWrapper>
        <UserAvatar
          small
          token={token}
          fileName={userLogo ? userLogo.fileNameWithExtension : null}
          hash={userLogo ? userLogo.secureHash : null}
          investorType={userInvestorType}
          gender={userGender}
        />

        <TextBlock>
          <QHeader>
            <h3>{category}</h3>
            {ownQuestion ? <h4>Your question</h4> : null}
            <span>{moment(dateOfQuestion).format("DD.MM.YYYY, hh:mm A")}</span>
          </QHeader>
          <TextWrapper>{textQuestion}</TextWrapper>
          {filename && size ? (
            <FileBlock isAnswered>
              <FileName>
                <File />
                <div>{filename}</div>
                <span>{(size / 1024).toFixed(2)} mb</span>
              </FileName>
              <FileControls>
                <Eye onClick={onView} />
                <Download onClick={downloadDocument} />
              </FileControls>
            </FileBlock>
          ) : null}
        </TextBlock>
      </TopWrapper>
      <BottomWrapper>
        <ClockBlock>
          <Clock />
        </ClockBlock>
        <h3>waiting for answer</h3>
        {ownQuestion ? (
          <RemButton onClick={onRemove}>
            <Rem />
            REMOVE QUESTION
          </RemButton>
        ) : null}
      </BottomWrapper>
      {modalOpen ? (
        <RemoveQuestionModal
          isInvestor
          onAgree={onSendRequest}
          onBackdropClick={onClose}
          userFullName={userFullName}
          dateOfQuestion={dateOfQuestion}
        />
      ) : null}
      {error && <ErrorMessage er={error} onClose={() => setError(null)} />}
      {wait ? <Wait /> : null}
    </Question>
  );
};
