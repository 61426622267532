const current = process.env.REACT_APP_CURRENT;

export const isGeneral = ["FREEMIUM_DEV"].includes(current);
export const isGlafka = ["GLAFKA_EUROPE", "GLAFKA_DEV", "GLAFKA_PROD"].includes(current);
export const isInvesture = ["INVESTURE", "INVESTURE_PROD"].includes(current);
export const isAlphier = ["ALPHIER", "ALPHIER_PROD"].includes(current);

export const customSignUp = ["GLAFKA_EUROPE", "GLAFKA_DEV", "GLAFKA_PROD", "INVESTURE", "INVESTURE_PROD"].includes(
  current
);
