import React from "react";

import { ReactComponent as UK } from "../assets/uk-flag.svg";
import { ReactComponent as EU } from "../assets/eu.svg";
import { ReactComponent as USA } from "../assets/usa.svg";
import { ReactComponent as SZ } from "../assets/swizerland.svg";
import { ReactComponent as UA } from "../assets/ukraine-flag.svg";
import { ReactComponent as RU } from "../assets/russian-flag.svg";
import { ReactComponent as DE } from "../assets/germany-flag.svg";
import { ReactComponent as FR } from "../assets/france-flag.svg";
import { ReactComponent as IT } from "../assets/italy-flag.svg";
import { ReactComponent as ES } from "../assets/spain-flag.svg";
import { ReactComponent as PL } from "../assets/poland-flag.svg";
import { ReactComponent as NL } from "../assets/netherlands-flag.svg";
import { ReactComponent as BY } from "../assets/belarus-flag.svg";
import { ReactComponent as MC } from "../assets/monaco-flag.svg";
import { ReactComponent as IS } from "../assets/iceland-flag.svg";
import { ReactComponent as AD } from "../assets/andorra-flag.svg";
import { ReactComponent as LU } from "../assets/luxembourg-flag.svg";
import { ReactComponent as SI } from "../assets/slovenia-flag.svg";
import { ReactComponent as FI } from "../assets/finland-flag.svg";
import { ReactComponent as BG } from "../assets/bulgaria-flag.svg";
import { ReactComponent as NO } from "../assets/norway-flag.svg";
import { ReactComponent as AB } from "../assets/abkhazia-flag.svg";
import { ReactComponent as AU } from "../assets/australia-flag.svg";
import { ReactComponent as AZ } from "../assets/azerbaijan-flag.svg";
import { ReactComponent as AT } from "../assets/austria-flag.svg";
import { ReactComponent as AL } from "../assets/albania-flag.svg";
import { ReactComponent as DZ } from "../assets/algeria-flag.svg";
import { ReactComponent as AS } from "../assets/samoa-flag.svg";
import { ReactComponent as AI } from "../assets/anguilla-flag.svg";
import { ReactComponent as AO } from "../assets/angola-flag.svg";
import { ReactComponent as AQ } from "../assets/antarctica-flag.svg";
import { ReactComponent as AG } from "../assets/antigua-and-barbuda-flag.svg";
import { ReactComponent as AR } from "../assets/argentine-republic-flag.svg";
import { ReactComponent as AM } from "../assets/armenia-flag.svg";

export const currenciesModel = {
  GBP: {
    symbol: "&#163;",
    icon: <UK />
  },
  EUR: {
    symbol: "&#128;",
    icon: <EU />
  },
  USD: {
    symbol: "&#36;",
    icon: <USA />
  },
  CHF: {
    symbol: "&#8355;",
    icon: <SZ />
  }
};

export const Countries = {
  UA: { flag: <UA />, name: "Ukraine" },
  UK: { flag: <UK />, name: "United Kingdom" },
  US: { flag: <USA />, name: "United States" },
  SZ: { flag: <SZ />, name: "Switzerland" },
  RU: { flag: <RU />, name: "Russian Federation" },
  DE: { flag: <DE />, name: "Germany" },
  FR: { flag: <FR />, name: "France" },
  IT: { flag: <IT />, name: "Italy" },
  ES: { flag: <ES />, name: "Spain" },
  PL: { flag: <PL />, name: "Poland" },
  NL: { flag: <NL />, name: "Netherlands" },
  BY: { flag: <BY />, name: "Belarus" },
  MC: { flag: <MC />, name: "Monaco" },
  IS: { flag: <IS />, name: "Iceland" },
  LU: { flag: <LU />, name: "Luxembourg" },
  SI: { flag: <SI />, name: "Slovenia" },
  FI: { flag: <FI />, name: "Finland" },
  BG: { flag: <BG />, name: "Bulgaria" },
  NO: { flag: <NO />, name: "Norway" },
  AB: { flag: <AB />, name: "Abkhazia" },
  AU: { flag: <AU />, name: "Australia" },
  AZ: { flag: <AZ />, name: "Azerbaijan" },
  AT: { flag: <AT />, name: "Austria" },
  AL: { flag: <AL />, name: "Albania" },
  DZ: { flag: <DZ />, name: "Algeria" },
  AS: { flag: <AS />, name: "American Samoa" },
  AI: { flag: <AI />, name: "Anguilla" },
  AO: { flag: <AO />, name: "Angola" },
  AD: { flag: <AD />, name: "Andorra" },
  AQ: { flag: <AQ />, name: "Antarctica" },
  AG: { flag: <AG />, name: "Antigua and Barbuda" },
  AR: { flag: <AR />, name: "Argentine Republic" },
  AM: { flag: <AM />, name: "Republic of Armenia" }
};
