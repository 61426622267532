import React, { useState, Fragment } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { ReactComponent as Offerins } from "assets/dashboard-icon.svg";
import { ReactComponent as Documents } from "assets/file.svg";
import { ReactComponent as Transactions } from "assets/trending-up.svg";
import { ReactComponent as Wallet } from "assets/credit-card2.svg";
import { ReactComponent as Bell } from "assets/bell-icon.svg";
import { ReactComponent as Basket } from "assets/shopping-cart.svg";
import { ReactComponent as FolderIcon } from "assets/folder.svg";
import { ReactComponent as QAIcon } from "assets/help-circle-inv.svg";
import { ReactComponent as Inst } from "assets/inst-inv.svg";
import { ReactComponent as FB } from "assets/fb-inv.svg";
import { ReactComponent as Google } from "assets/g-inv.svg";
import { ReactComponent as Twitter } from "assets/tw-inv.svg";
import { ReactComponent as LinkedIn } from "assets/linked.svg";
import { ReactComponent as FBInv } from "assets/fb.svg";
import { ReactComponent as Instagram } from "assets/Instagram.svg";
import { ReactComponent as Medium } from "assets/Medium.svg";
import { ReactComponent as TwitterInv } from "assets/Twitter.svg";
import { ExternalSocialLink } from "components/auhtForms/styled";
import moment from "moment";

const current = process.env.REACT_APP_CURRENT;

const Icons = {
  Offerings: <Offerins />,
  Transactions: <Transactions />,
  Documents: <Documents />,
  Wallet: <Wallet />,
  ["Data Room"]: <FolderIcon />,
  ["Q&A"]: <QAIcon />,
};

export const Header = styled.header`
  display: flex;
  align-items: center;
  height: 70px;
  background: ${({ theme: { headerInvestor } }) => headerInvestor};
  box-sizing: border-box;
  padding-right: 20px;
  z-index: 1;
  position: relative;
`;

export const LogoContainer = styled.div`
  width: 237px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid rgba(255, 255, 255, 0.07);
  height: 100%;
  > img {
    width: 177px;
  }
  ${({ styles = "" }) => styles};
`;

export const LinkWrapper = styled.div`
  display: flex;
  padding: 0 20px;
  height: 100%;
`;

const HeaderLink = styled(Link)`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 2px solid ${({ active, theme: { navLinkBorder } }) => (active ? navLinkBorder : "transparent")};
  color: ${({ active, theme: { navLinkActive, navLink } }) => (active ? navLinkActive : navLink)};
  font-family: Muli;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 135%;
  margin-right: 30px;
  text-decoration: none;
  box-sizing: border-box;
  white-space: nowrap;
  position: relative;
  &:last-child {
    margin-right: 0;
  }
  svg {
    width: 18px;
    height: 18px;
    margin-right: 10px;
    path {
      stroke: ${({ active, theme: { navLinkActive, navLink } }) => (active ? navLinkActive : navLink)};
    }
    @media (max-width: 1400px) {
      margin-right: ${({ active }) => (active ? "10px" : 0)};
    }
  }
  > span {
    @media (max-width: 1400px) {
      display: ${({ active }) => (active ? "inline" : "none")};
    }
  }
  &:hover {
    @media (max-width: 1400px) {
      .nav-tooltip {
        display: ${({ active }) => (active ? "none" : "block")};
      }
    }
  }
`;

const NavTooltip = styled.div`
  background: rgba(255, 255, 255, 0.8);
  border: 1px solid #efefef;
  box-sizing: border-box;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  padding: 10px 35px;
  position: absolute;
  display: none;
  bottom: -52px;
  font-weight: 600;
  font-size: 16px;
  line-height: 135%;
  color: #333333;
  z-index: 100;
  &::before {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 20px 12.5px 0px 12.5px;
    border-color: rgba(255, 255, 255, 0.8) transparent transparent transparent;
    position: absolute;
    right: calc(50% - 10px);
    top: -15px;
    z-index: 101;
    transform: rotate(60deg);
  }
`;

export const NavLink = ({ link, text, active }) => {
  return (
    <HeaderLink active={active ? 1 : 0} to={link}>
      {Icons[text]}
      <span>{text}</span>
      <NavTooltip className="nav-tooltip">{text}</NavTooltip>
    </HeaderLink>
  );
};

export const Footer = styled.footer`
  display: flex;
  align-items: center;
  height: 70px;
  background: ${({ theme: { footerInvestor } }) => footerInvestor};
  box-sizing: border-box;
  justify-content: space-between;
  padding: 0 60px;
`;

export const CopyRightBlock = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 160%;
  color: rgba(255, 255, 255, 0.6);
  > span.client-name {
    margin-right: 25px;
    font-weight: bold;
    color: rgba(255, 255, 255, 0.8);
  }
`;

export const Wrapper = styled.section`
  min-height: calc(100vh - 140px);
  padding: 25px 0 60px 0;
  box-sizing: border-box;
  background: #f4f5f6;
  position: relative;
  .MuiCircularProgress-colorPrimary {
    color: ${({ theme: { primary } }) => primary};
  }
`;

const NotificationsBlock = styled.div`
  margin-left: auto;
  margin-right: ${({ noMargin }) => (noMargin ? 0 : "19px")};
  height: 100%;
  width: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 1px solid rgba(255, 255, 255, 0.07);
  border-right: 1px solid rgba(255, 255, 255, 0.07);
  position: relative;
  > svg {
    width: 24px;
    height: 24px;
    cursor: pointer;
    path {
      stroke: #fff;
      opacity: ${({ active, unreadCount }) => (active || unreadCount ? 1 : 0.4)};
    }
  }
  &::after {
    content: "${({ unreadCount }) => unreadCount}";
    display: ${({ unreadCount }) => (unreadCount ? "flex" : "none")};
    height: 16px;
    width: 16px;
    font-size: 10px;
    background: ${({ theme: { primary } }) => primary};
    position: absolute;
    border-radius: 50%;
    color: #fff;
    align-items: center;
    justify-content: center;
    font-family: Muli;
    font-style: normal;
    right: ${({ circleRight }) => (circleRight ? `${circleRight}px` : "27px")};
    top: 20px;
  }
  &::before {
    content: "";
    display: ${({ active }) => (active ? "block" : "none")};
    height: 2px;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background: ${({ theme: { navLinkBorder } }) => navLinkBorder};
  }
`;

const NotifInfo = styled.div`
  position: absolute;
  right: 70px;
  top: ${({ tooltopTop }) => (tooltopTop ? `${tooltopTop}px` : "24px")};
  width: ${({ width }) => (width ? `${width}px` : "250px")};
  min-height: 43px;
  box-sizing: border-box;
  padding: 10px;
  background: #ffffff;
  border: 1px solid #efefef;
  box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  z-index: 900;
  display: flex;
  flex-direction: column;
`;

const InfoTriangle = styled.div`
  display: inline-block;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 10px 0 10px 20px;
  border-color: transparent transparent transparent #fff;
  position: absolute;
  right: 56px;
  z-index: 901;
`;

const LinkToUnread = styled(Link)`
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 160%;
  color: #333333;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-decoration: none;
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0;
  }
`;

const CountCircle = styled.div`
  height: 16px;
  width: 16px;
  font-size: 10px;
  background: ${({ theme: { primary } }) => primary};
  border-radius: 50%;
  color: #fff;
  align-items: center;
  justify-content: center;
  font-family: Muli;
  font-style: normal;
  display: flex;
  margin-left: 7px;
`;

export const Notifications = ({ unread }) => {
  const [open, setOpen] = useState(false);

  const renderAssets = () => {
    return unread.assets.map((e) => {
      const { assetName, assetId, count } = e;
      return (
        <LinkToUnread key={assetId} to={`/investor/offerings/companies/${assetId}`}>
          {assetName}
          <CountCircle>{count}</CountCircle>
        </LinkToUnread>
      );
    });
  };
  return (
    <NotificationsBlock unreadCount={unread.total} onClick={() => setOpen(!open)}>
      <Bell />
      {open && unread && unread.assets && unread.total !== 0 ? (
        <Fragment>
          <NotifInfo>{renderAssets()}</NotifInfo>
          <InfoTriangle />
        </Fragment>
      ) : null}
    </NotificationsBlock>
  );
};

export const UnfinishedOrders = ({ unfinished, onClick, path }) => {
  return (
    <NotificationsBlock
      circleRight={22}
      noMargin
      unreadCount={unfinished}
      onClick={onClick}
      active={path === "/investor/unfinished-orders"}
    >
      <Basket />
    </NotificationsBlock>
  );
};

export const FooterLink = styled(Link)`
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 160%;
  color: ${({ theme: { navLinkActive } }) => navLinkActive};
  margin-right: 30px;
  text-decoration: none;
  &:last-child {
    margin-right: 0;
  }
`;

export const SocialLink = styled(Link)`
  margin-right: 24px;
  transition: opacity 0.3s linear;
  > svg {
    width: 20px;
    height: 20px;
    path {
      fill: ${({ theme: { navLinkActive } }) => navLinkActive};
    }
  }
  &:last-child {
    margin-right: 0;
  }
  &:hover {
    opacity: 0.7;
  }
`;

export const IconsWrapper = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  margin-left: auto;
`;

export const ChatLink = styled(Link)`
  margin-left: auto;
  height: 100%;
  width: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 1px solid rgba(255, 255, 255, 0.07);
  border-right: 1px solid rgba(255, 255, 255, 0.07);
  position: relative;
  > svg {
    width: 24px;
    height: 24px;
    cursor: pointer;
    path {
      stroke: #fff;
      opacity: ${({ active }) => (active ? 1 : 0.4)};
    }
  }
  &::before {
    content: "";
    display: ${({ active }) => (active ? "block" : "none")};
    height: 2px;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background: ${({ theme: { navLinkBorder } }) => navLinkBorder};
  }
  &::after {
    content: "${({ newMess }) => newMess}";
    display: ${({ newMess }) => (newMess ? "flex" : "none")};
    height: 16px;
    width: 16px;
    font-size: 10px;
    background: ${({ theme: { primary } }) => primary};
    position: absolute;
    border-radius: 50%;
    color: #fff;
    align-items: center;
    justify-content: center;
    font-family: Muli;
    font-style: normal;
    right: 18px;
    top: 18px;
  }
`;

const InfoLinks = styled.div`
  width: 236px;
`;

const GlafkaCopy = styled.div`
  width: calc(100% - 250px);
`;

const _investureEnvs = ["INVESTURE", "INVESTURE_PROD"];
const _glafkaEnvs = ["GLAFKA_DEV", "GLAFKA_PROD"];

export const FooterGlobal = () => {
  const renderInvesture = () => {
    return (
      <Fragment>
        <CopyRightBlock>© 2020. INVESTURE — Investor Interface</CopyRightBlock>
        <div>
          <FooterLink to="/investor/info/privacy-policy">Privacy & Cookies</FooterLink>
          <FooterLink to="/investor/info/terms-of-use">Terms & Conditions</FooterLink>
        </div>
        <div>
          <ExternalSocialLink href="https://www.linkedin.com/company/investure-co" target="_blank">
            <LinkedIn />
          </ExternalSocialLink>
          <ExternalSocialLink href="https://www.facebook.com/WeAreInvesture" target="_blank">
            <FBInv />
          </ExternalSocialLink>
          <ExternalSocialLink href="https://medium.com/@investure" target="_blank">
            <Medium />
          </ExternalSocialLink>
          <ExternalSocialLink href="https://www.instagram.com/investure_markets/" target="_blank">
            <Instagram />
          </ExternalSocialLink>
          <ExternalSocialLink href="https://twitter.com/WeAreInvesture" target="_blank">
            <TwitterInv />
          </ExternalSocialLink>
        </div>
      </Fragment>
    );
  };

  const renderValk = () => {
    return (
      <Fragment>
        <CopyRightBlock>© 2020. VALK — Investor Interface</CopyRightBlock>
        <div>
          <FooterLink to="/investor/info/privacy-policy">Privacy & Cookies</FooterLink>
          <FooterLink to="/investor/info/terms-of-use">Terms & Conditions</FooterLink>
        </div>
        <div>
          <SocialLink to="#">
            <FB />
          </SocialLink>
          <SocialLink to="#">
            <Twitter />
          </SocialLink>
          <SocialLink to="#">
            <Inst />
          </SocialLink>
          <SocialLink to="#">
            <Google />
          </SocialLink>
        </div>
      </Fragment>
    );
  };

  const renderGlafka = () => {
    return (
      <Fragment>
        <GlafkaCopy>
          <CopyRightBlock>
            <span className="client-name">Glafka Capital © 2020. Powered by VALK</span>
            Glafka Capital is authorised and regulated by the Financial Conduct Authority (FCA) (Registration Number
            841585
          </CopyRightBlock>
        </GlafkaCopy>
        <InfoLinks>
          <FooterLink to="/investor/info/privacy-policy">Privacy & Cookies</FooterLink>
          <FooterLink to="/investor/info/terms-of-use">Terms & Conditions</FooterLink>
        </InfoLinks>
      </Fragment>
    );
  };
  return (
    <Footer>
      {_investureEnvs.includes(current)
        ? renderInvesture()
        : _glafkaEnvs.includes(current)
        ? renderGlafka()
        : renderValk()}
    </Footer>
  );
};

export const HeaderText = styled.div`
  font-weight: bold;
  font-size: 16px;
  line-height: 135%;
  color: #ffffff;
  margin-left: 25px;
`;
