import React, { Fragment, useRef } from "react";
import styled from "styled-components";
import moment from "moment";
import { Link } from "react-router-dom";

import { Main, TextSub, HeaderCircle } from "../../DealForm/Step/styled";
import { FlexRow } from "../../../dashBoardCard";
import { ScrollContainer } from "../styled";
import { TransactionsHeader, HeaderTD } from "../../../dealTypeCard";

import { EmptyInfo } from "../common/emptyInfo";
import { ReactComponent as Folder } from "../../../../../assets/folder.svg";
import { ReactComponent as FileIcon } from "../../../../../assets/file-text.svg";
import { CircularProgress } from "@material-ui/core";

const Row = styled.div`
  width: 100%;
  height: 66px;
  background: ${({ grey, theme: { adminTableRowGrey } }) => (grey ? adminTableRowGrey : "#fff")};
  border-radius: 2px;
  padding: 15px;
  box-sizing: border-box;
`;

const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const LeftCol = styled.div`
  width: calc(100% - 50px);
  display: flex;
  flex-direction: column;
  > h6 {
    font-size: 12px;
    line-height: 15px;
    letter-spacing: -0.02em;
    color: #333333;
    margin: 0 0 6px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  > p {
    margin: 0;
    font-size: 10px;
    line-height: 13px;
    letter-spacing: -0.02em;
    color: #838383;
  }
`;

const Status = styled.div`
  width: 41px;
  height: 24px;
  background: ${({ sent }) => (sent ? "#55B17C" : "#CACACA")};
  border-radius: 2px;
  font-weight: bold;
  font-size: 10px;
  line-height: 150%;
  text-transform: uppercase;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const Reports = ({ reports, assetId }) => {
  const linkRef = useRef(null);

  const onGoToReports = () => {
    sessionStorage.setItem("Reports", 1);
    if (linkRef && linkRef.current) linkRef.current.click();
  };

  const renderTable = () => {
    return (
      <Fragment>
        <TransactionsHeader translate={-15} height={38}>
          <HeaderTD width="calc(100% - 115px)" translate={15} padding="0 25px 0 0">
            Name & DATE
          </HeaderTD>
          <HeaderTD width="64px" padding="0 10px 0 0">
            STATUS
          </HeaderTD>
        </TransactionsHeader>
        <ScrollContainer>
          {reports.map(({ dateAdded, name, sent }, i) => (
            <Row key={dateAdded} grey={!(i % 2)}>
              <Flex>
                <LeftCol>
                  <h6>{name}</h6>
                  <p>{moment(dateAdded).format("DD.MM.YYYY, hh:mm A")}</p>
                </LeftCol>
                <Status sent={sent}>{sent ? "Sent" : "Pend."}</Status>
              </Flex>
            </Row>
          ))}
        </ScrollContainer>
      </Fragment>
    );
  };
  return (
    <Main currentHeight="415px" width="calc(29.9% - 20px)" padding="20px 25px 25px 25px">
      <FlexRow mBottom="0" align="flex-start">
        <TextSub textAlign="left" fontWeight="bold" color="#181818" marginSize="0 0 25px 0">
          Reports
        </TextSub>

        {reports && reports.length ? (
          <HeaderCircle onClick={onGoToReports}>
            <Folder style={{ width: 14 }} />
          </HeaderCircle>
        ) : null}
      </FlexRow>
      {!reports ? (
        <CircularProgress size={24} style={{ margin: "auto" }} />
      ) : reports && reports.length ? (
        renderTable()
      ) : (
        <EmptyInfo
          heading="No Reports were Added"
          text="Here will be reports list when you will add reports"
          icon={<FileIcon />}
        />
      )}
      <Link ref={linkRef} to={`/admin/data-room/deal/${assetId}/file-manager#asset`} />
    </Main>
  );
};
