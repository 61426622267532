import React, { useEffect, useState, Fragment, useRef } from "react";
import { connect } from "react-redux";
import { CircularProgress } from "@material-ui/core";

import { Header, TextH3, BtnContainer, Separator } from "./Step/styled";
import { dealObjectFactory } from "../../../../models/dealModel";

import { ContainerFlex } from "../../containers/container";
import { Btn } from "../../buttons";
import { getAssets } from "../../../../modules/dataRedux/dataAction";

import Api from "../../../../helpers/api";
import KycApi from "../KycFormsList/api";
import { LeftContainer, RightContainer } from "./helpers";

import { AdditionalInformation } from "./FullFormCards/AdditionalInformation";
import { MainInformation } from "./FullFormCards/MainInformation";
import { Summary } from "./FullFormCards/Summary";
import { Parameters } from "./FullFormCards/Parameters";
import { Images } from "./FullFormCards/Images";
import { DataRoom } from "./FullFormCards/DataRoom";
import { Rules } from "./FullFormCards/Rules";
import { BankAccount } from "./FullFormCards/BankAccount";
import { CurrentRound } from "./FullFormCards/CurrentRound";
import { PreviousRound } from "./FullFormCards/PreviousRounds";
import { DinamicFinancial } from "./FullFormCards/DinamicFinancial";
import { AssetPreview } from "./assetPreview";
import { DinamicCapital } from "./FullFormCards/DynamicCapital";
import { KycUserType } from "./Step/FinancialsAndRules/KycUserType";

import { ErrorMessage, SuccessMessage, ConfirmMessagePortal } from "../../errorMessage";
import { icons } from "../../../../models/icons";

export function preventDefaultCustom(e) {
  e.preventDefault();
  e.stopPropagation();
}

export const DealForm = (props) => {
  const [values, _setValues] = React.useState(
    dealObjectFactory(
      props.acceptedCurrency[0] || "",
      props.sponsors[0] ? [props.sponsors[0]] : [],
      props.accesses[0] || "",
      props.dealTypes[0] || "",
      props.categories[0] || "",
      props.investorWorkflowTypes[0] || ""
    )
  );
  const [error, setError] = useState(null);
  const [linearId, setLinear] = useState("");
  const [saveWait, setSaveWait] = useState(false);
  const [success, setSuccess] = useState("");
  const [groups, setGroups] = useState([]);
  const [users, setUsers] = useState([]);
  const idRef = useRef(null);
  const finishRef = useRef(null);
  const valuesRef = useRef(null);
  const [preview, openPreview] = useState(false);
  const [kycForms, setKycForms] = useState([]);
  const [kycRoles, setKycRoles] = useState([]);
  const [confirmOpen, setConfirmOpen] = useState(false);

  const rulesValuesRef = useRef(null);

  const jsonValueRef = useRef(JSON.stringify(values));
  const setValues = (newValues) => {
    const _newValueJson = JSON.stringify(newValues);
    if (_newValueJson !== jsonValueRef.current) {
      _setValues(newValues);
    }
    jsonValueRef.current = _newValueJson;
  };

  const onChangePreview = () => {
    openPreview(!preview);
  };

  const dealType = values.assetProperties.dealsType;
  const isNewDeal = dealType === "NEW_DEAL";

  useEffect(() => {
    valuesRef.current = values;
  }, [values]);

  const { id } = props.match.params;
  const onBackToAssets = () => {
    if (id && props.history.length <= 2) {
      props.history.push(`/admin/deals/create`);
    } else {
      props.history.goBack();
    }
  };

  if (id && !values.linearId) {
    const editAsset = props.assets.find((e) => e.linearId === id);
    if (editAsset) {
      setValues(
        JSON.parse(
          JSON.stringify({
            ...editAsset,
            visibilityProperty: {
              ...editAsset.visibilityProperty,
              isActive: editAsset.visibilityProperty.active,
            },
            managerSponsors: editAsset.sponsors.map((e) => e.username),
          })
        )
      );
      //save initial access values
      rulesValuesRef.current = {
        countryRestriction: editAsset.visibilityProperty.countryRestriction,
        groups: editAsset.visibilityProperty.groups,
        users: editAsset.visibilityProperty.users,
      };
    }
  }

  useEffect(() => {
    window.scroll(0, 0);
    if (!props.assets.length) props.getAssets(props.token);

    Api.getUserGroups(props.token).then((gs) => setGroups(gs));
    Api.getUsers(props.token, "INVESTOR", 1, 1000, "").then((res) => setUsers(res.content));
    KycApi.getForms(props.token).then((forms) => setKycForms(forms));
    Api.getKycRoles(props.token).then((roles) => setKycRoles(roles));
  }, []);

  useEffect(() => {
    if (finishRef.current && linearId) {
      props.history.push(`/admin/data-room/deal/${linearId}/file-manager`);
    }
  }, [finishRef, linearId]);

  const changeValuesSimpleType = (...keys) => (e) => {
    const value = e && e.target ? e.target.value : e;
    const _values = { ...values };

    try {
      let link = _values;
      for (let i = 0; i < keys.length; i++) {
        if (i === keys.length - 1) {
          link[keys[i]] = value;
        } else {
          link = link[keys[i]];
        }
      }
    } catch (error) {
      //for lock up time only!!!
      _values.visibilityProperty.lockUpPeriod = {};
      let link = _values;
      for (let i = 0; i < keys.length; i++) {
        if (i === keys.length - 1) {
          link[keys[i]] = value;
        } else {
          link = link[keys[i]];
        }
      }
    }

    setValues(_values);
  };

  const onChangeRules = (data) => {
    const _values = { ...values };
    _values.visibilityProperty.activeRulesJson = JSON.stringify(data);
    Object.keys(data).forEach((field) => {
      if (!data[field]) {
        _values.visibilityProperty[field] = field === "countryRestriction" ? { listType: "ALL" } : null;
      }
    });
    setValues(_values);
  };

  const saveAs = (isActive) => async () => {
    setSaveWait(true);
    let res = null,
      val = {
        ...values,
        visibilityProperty: {
          ...values.visibilityProperty,
          isActive,
        },
      };

    //refactoring crash
    if (val.visibilityProperty.lockUpPeriod && !Object.keys(val.visibilityProperty.lockUpPeriod).length)
      val.visibilityProperty.lockUpPeriod = null;

    //format for existing deal
    if (!isNewDeal) {
      if (!val.baseCurrency) {
        const baseCurrency = val.assetFinancialProperty.baseCurrency;
        val.baseCurrency = baseCurrency;
      }
      val.assetFinancialProperty = null;
    }

    //set kyc id
    if (val.assetKycTemplates && !val.assetKycTemplates.id && val.linearId && isNewDeal)
      val.assetKycTemplates.id = val.linearId;
    //conditions
    if (val.linearId) res = await Api.updateAsset(props.token, val).catch(setError);
    else res = await Api.createAsset(props.token, val).catch(setError);

    if (res) {
      finishRef.current = true;
      setLinear(res.assetId);
      if (val.linearId) {
        //check if rules change
        const newRules = {
          countryRestriction: val.visibilityProperty.countryRestriction,
          groups: val.visibilityProperty.groups,
          users: val.visibilityProperty.users,
        };
        if (JSON.stringify(rulesValuesRef.current) !== JSON.stringify(newRules)) {
          setConfirmOpen(true);
        } else {
          setSuccess(true);
        }
      }
    }

    setSaveWait(false);
  };

  const onChangeKyc = (data) => {
    const _values = { ...values };
    _values.assetKycTemplates = { ...data };
    setValues(_values);
  };

  const onCancelConfirm = () => {
    setConfirmOpen(false);
    setSuccess(true);
  };

  const onDraft = () => props.history.push(`/admin/deals/unfinished_deal`);

  const onConfirm = () => props.history.push(`/admin/send-emails/${id}/change-rules-mailing`);

  if (id && !values.linearId) return <CircularProgress style={{ margin: "auto" }} />;
  return (
    <Fragment>
      <Header>
        <Btn transparent onClick={onBackToAssets} width={150}>
          {icons.left} {id ? "back to deals" : "back to option"}
        </Btn>
        <TextH3 marginSize="0 0 0 25px">Edit {values.assetProperties.assetName}</TextH3>
        <Btn width={180} margin="0 0 0 auto" onClick={onChangePreview}>
          {icons.eye} ASSET PREVIEW
        </Btn>
      </Header>
      <ContainerFlex h={preview ? "0px" : "auto"} row justify="space-between" wrap>
        <LeftContainer>
          {/* new markup */}
          <MainInformation
            onError={setError}
            token={props.token}
            onChange={changeValuesSimpleType}
            defaultValues={values}
            categories={props.categories}
            acceptedCurrency={props.acceptedCurrency}
            dealType={dealType}
            countriesOption={props.countriesOption}
            financialTypes={props.financialDealTypes}
            roleName={props.roleName}
            username={props.username}
            fullName={props.fullName}
          />
          <Separator />

          <DinamicFinancial
            _data={values.assetProperties.dynamicFinancialJson}
            onChange={(data) => {
              changeValuesSimpleType("assetProperties", "dynamicFinancialJson")(JSON.stringify(data));
            }}
          />
          <Separator />
          {isNewDeal ? (
            <Fragment>
              <CurrentRound
                currency={values.assetFinancialProperty.baseCurrency}
                defaultValues={values}
                onChange={changeValuesSimpleType}
              />
              <Separator />
            </Fragment>
          ) : null}

          <PreviousRound defaultValues={values} onChange={changeValuesSimpleType("roundsInfo")} />
          <Separator />
          <Summary
            token={props.token}
            onChange={changeValuesSimpleType}
            defaultValues={values}
            roleName={props.roleName}
          />
          <Separator />
          {isNewDeal ? (
            <Fragment>
              <Parameters
                token={props.token}
                onChange={changeValuesSimpleType}
                defaultValues={values}
                acceptedCurrency={props.acceptedCurrency}
                isEdit={values.linearId}
              />
              <Separator />
            </Fragment>
          ) : null}
          <AdditionalInformation
            blocks={values.assetProperties.vocAssetDynamicProperties}
            onChange={changeValuesSimpleType}
            token={props.token}
          />
          <Separator />
          {/* todo view upload files files */}
          <Images token={props.token} onChange={changeValuesSimpleType} defaultValues={values} />
          <Separator />
          {id && !values.newWorkflow ? (
            <DataRoom token={props.token} onChange={changeValuesSimpleType} defaultValues={values} />
          ) : null}
          <Separator />
          <Header between>
            <Btn width={94} white disabled={saveWait} onClick={onBackToAssets}>
              Cancel
            </Btn>
            <BtnContainer>
              <Btn width={180} onClick={onDraft} disabled={saveWait} white>
                save to draft
              </Btn>
              <Btn width={180} onClick={saveAs(true)} disabled={saveWait}>
                Save & Publish
              </Btn>
            </BtnContainer>
          </Header>
        </LeftContainer>

        <RightContainer>
          <Rules
            token={props.token}
            onChange={changeValuesSimpleType}
            defaultValues={values}
            groups={groups}
            ruleOptions={props.ruleOptions}
            countriesOption={props.countriesOption}
            accesses={props.accesses}
            dealTypes={props.dealTypes}
            categories={props.categories}
            lockUpTime={props.lockUpTime}
            onChangeGroups={changeValuesSimpleType("visibilityProperty", "groups")}
            onChangeUsers={changeValuesSimpleType("visibilityProperty", "users")}
            onChangeRules={onChangeRules}
            users={users}
          />
          <Separator />
          {dealType === "NEW_DEAL" ? (
            <Fragment>
              <BankAccount
                onChangeBank={changeValuesSimpleType("assetFinancialProperty", "account")}
                defaultValues={values}
                isEdit
              />
              <Separator />
              <DinamicCapital
                onChange={(e) =>
                  changeValuesSimpleType("assetProperties", "dynamicCapitalStructureJson")(JSON.stringify(e))
                }
                json={values.assetProperties.dynamicCapitalStructureJson}
                baseCurrency={values.assetFinancialProperty.baseCurrency}
              />
              <Separator />
              <KycUserType
                assetKycTemplates={
                  values.assetKycTemplates || {
                    institutionTemplateId: 0,
                    otherRolesTemplates: {},
                    professionalTemplateId: 0,
                  }
                }
                kycForms={kycForms}
                kycRoles={kycRoles}
                onChangeKyc={onChangeKyc}
              />
            </Fragment>
          ) : null}
        </RightContainer>
      </ContainerFlex>

      {error ? <ErrorMessage er={error} onClose={() => setError(null)} /> : null}
      {success ? <SuccessMessage message="Asset has been succesfully updated" onClose={onBackToAssets} /> : null}
      {confirmOpen ? (
        <ConfirmMessagePortal
          heading="Access Changed"
          message="Your Access Rules was changed. Do you want <br/> to e-mail new users about this asset?"
          onClose={onCancelConfirm}
          onSubmit={onConfirm}
          confirmText="SEND NEW E-MAIL"
        />
      ) : null}
      {preview ? (
        <AssetPreview
          onClose={onChangePreview}
          values={values}
          token={props.token}
          assetId={id}
          _sponsors={props.sponsorsFull}
        />
      ) : null}
    </Fragment>
  );
};

export default connect(
  ({
    userReducer: {
      token,
      userInfo: { roleName, username, fullName },
    },
    dataReducer: { assets = [] },
    asyncDataReducer: {
      acceptedCurrency,
      sponsors,
      accesses,
      dealTypes,
      categories,
      investorWorkflowTypes,
      ruleOptions,
      countriesOption,
      lockUpTime,
      sponsorsFull,
      financialDealTypes,
    },
  }) => ({
    token,
    assets,
    roleName,
    username,
    // selects data
    acceptedCurrency,
    sponsors,
    accesses,
    dealTypes,
    categories,
    investorWorkflowTypes,
    ruleOptions,
    countriesOption,
    lockUpTime,
    sponsorsFull,
    financialDealTypes,
    fullName,
  }),
  { getAssets }
)(DealForm);
