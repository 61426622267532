import styled from "styled-components";
import { Flex, Column, FlexCenterAuto } from "../../../common/containers";
import { borderBox, muliFont, textToDot } from "../../../common/mixins";

export const Header = styled(FlexCenterAuto)`
  height: 70px;
  background: #ffffff;
  border-radius: 4px;
  width: 1380px;
  margin-bottom: 30px;
  color: #181818;

  ${muliFont};
  font-weight: 600;

  ${borderBox};
  @media screen and (max-width: 1419px) {
    width: calc(100% - 40px);
  }
`;

export const BackButtonContainer = styled(Flex)`
  width: 266px;
  height: 100%;
  color: ${({ theme: { primary } }) => primary};
  justify-content: center;
  align-items: center;
  border-right: 1px solid #f2f2f2;
  ${borderBox};
`;

export const TextHeaderContainer = styled(Flex)`
  width: 100%;
  align-items: center;
  padding-left: 35px;
  ${borderBox};
`;

export const Row = styled(FlexCenterAuto)`
  width: 1380px;
  justify-content: space-between;
  ${({ isLayer = false }) => (isLayer ? "background: #f4f5f6;z-index:1" : "")};
  height: ${({ height }) => height};
  @media screen and (max-width: 1419px) {
    width: calc(100% - 40px);
  }
`;

export const Col = styled(Column)`
  width: ${({ left }) => (left ? "calc(100% - 340px)" : "310px")};
  align-items: center;
`;

export const View = styled.embed`
  width: 100%;
  min-height: calc(100vh - 265px);
  height: ${({ isExpand = false }) => (isExpand ? "calc(100vh - 50px)" : "auto")};
`;

export const ReaderWrapper = styled(Column)`
  width: 100%;
`;

export const ViewerHeader = styled(Flex)`
  height: 50px;
  background: ${({ theme: { headerInvestor } }) => headerInvestor};
  border-radius: ${({ isExpand = false }) => (isExpand ? 0 : "4px 4px 0 0")};
  width: 100%;
  align-items: center;
  ${borderBox};
  padding: 0 20px;
`;

export const ViewerDocName = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 135%;
  color: #ffffff;
  margin-right: 10px;
`;

export const SizeDate = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 135%;
  color: rgba(255, 255, 255, 0.5);
`;

export const ExpandBtn = styled.button`
  width: 32px;
  height: 32px;
  background: ${({ theme: { headerAdmin } }) => headerAdmin};
  border-radius: 2px;
  outline: none;
  border: none;
  display: flex;
  margin-left: auto;
  cursor: pointer;
  > svg {
    margin: auto;
  }
`;

export const ExpandWrapper = styled.section`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 1000;
`;

export const Card = styled(Column)`
  width: 100%;
  align-items: center;
  padding: 20px 25px;
  background: #ffffff;
  border-radius: 4px;
  margin-bottom: 20px;

  ${muliFont};
  font-weight: 600;

  color: #181818;
  ${borderBox};
`;

export const CardHeader = styled.div`
  margin-bottom: 20px;
  width: 100%;
  font-size: 18px;
`;

export const ModalBg = styled(Flex)`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.15);
  z-index: ${({ zIndex }) => zIndex};
  align-items: center;
  justify-content: center;
`;

export const Center = styled.div`
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  > div {
    color: #181818;
    margin-top: 20px;
    font-size: 14px;
  }
`;

export const SignSettingsWrapper = styled(Column)`
  width: 440px;
  background: #f6f7fc;
  background: #ffffff;
  box-shadow: 0px 10px 40px rgba(89, 120, 150, 0.06);
  border-radius: 4px;
  ${borderBox};
`;

export const SignSettingsHeader = styled(Flex)`
  ${borderBox};
  ${muliFont};
  font-weight: 600;

  padding: 15px 20px;
  color: #181818;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  box-shadow: 0px 10px 40px rgba(89, 120, 150, 0.08);
  > svg {
    width: 26px;
    height: 26px;
    cursor: pointer;
  }
`;

export const SignSettingsContentWrapper = styled.div`
  padding: 0 30px 30px;
  ${borderBox};
`;

export const SignImageUploaderLabel = styled.div`
  ${muliFont};
  font-weight: bold;

  text-transform: uppercase;
  color: rgba(24, 24, 24, 0.6);
  margin-bottom: 12px;
`;

export const SignImageUploader = styled(Flex)`
  width: 100%;
  border: 1px solid #efefef;
  border-radius: 4px;
  padding: 15px;
  margin-bottom: 30px;
  ${borderBox};
`;

export const ImageUploader = styled.div`
  width: 50%;
  height: 55px;
  border: 1px solid #f2f3f7;
  border-radius: 2px;
  background: #e3e3e3;
  ${borderBox};

  > img {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
`;

export const ImageUploaderController = styled(Column)`
  width: calc(100% - 158px);
  height: 55px;
  padding-left: 16px;
  ${borderBox};
`;

export const ControllerLabel = styled.div`
  ${muliFont};
  letter-spacing: -0.02em;
  ${textToDot};

  color: #181818;
  margin-bottom: 10px;
  width: 100%;
`;

export const Controllers = styled(Flex)`
  ${muliFont};
  letter-spacing: -0.02em;
  color: ${({ theme: { primary } }) => primary};
`;

export const Control = styled.div`
  cursor: pointer;
  :last-child {
    margin-left: 10px;
    color: #ff4343;
  }
`;

export const ModalButtonBlock = styled(Flex)`
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const SeparatorModal = styled.div`
  margin-bottom: 24px;
  width: 100%;
  border-top: 1px solid #f6f7fc;
`;
