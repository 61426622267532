import React from "react";

import { ExpandCard } from "../Documents/styled";
import {
  ContentWrapper,
  CurrencyBlock,
  ActionButton,
  InfoTxt,
  Currencies
} from "./styled";
import { ReactComponent as RefreshIcon } from "../../../../assets/refresh-cw.svg";
import { ReactComponent as CardIcon } from "../../../../assets/credit-card.svg";
import { currenciesModel } from "../../../../models/currenciesModel";

export const CurrencyAccount = ({ data }) => {
  const renderCurrencies = () => {
    const currencies = Object.keys(data);

    return currencies.map(c => (
      <CurrencyBlock responsive="width:100%;" key={c}>
        {currenciesModel[c] ? currenciesModel[c].icon : null}
        <InfoTxt fSize={12} fWeight="600" margin="0 auto 0 12px">
          {c}
        </InfoTxt>
        <InfoTxt fSize={12} bold>
          {data[c].toFixed(2)}
        </InfoTxt>
      </CurrencyBlock>
    ));
  };
  if (Object.keys(data).length) {
    return (
      <ExpandCard heading="Currency Account">
        <ContentWrapper>
          <Currencies width={420} responsive="width: calc(33.3% - 15px);">
            {renderCurrencies()}
          </Currencies>
          <ActionButton
            width={420}
            responsive="width: calc(33.3% - 15px);"
            light
          >
            <RefreshIcon />
            withdrawal history
          </ActionButton>
          <ActionButton width={420} responsive="width: calc(33.3% - 15px);">
            <CardIcon /> Withdrawal
          </ActionButton>
        </ContentWrapper>
      </ExpandCard>
    );
  } else return null;
};
