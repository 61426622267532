import moment from "moment";

export const countByCategory = (arr, category, userName) => {
  let count = 0;

  const { own, another } = sortQuestions(arr, userName);

  if (category === "All Categories") {
    count = own.length + another.length;
  } else {
    own.forEach(el => {
      if (el.category === category) {
        count++;
      }
    });
    another.forEach(el => {
      if (el.category === category) {
        count++;
      }
    });
  }

  return count;
};

export const sortQuestions = (arr, userName) => {
  const own = arr
    .filter(q => q.username === userName && q.isWaiting)
    .sort((a, b) =>
      +moment(a.dateOfQuestion).format("x") >
      +moment(b.dateOfQuestion).format("x")
        ? -1
        : 1
    );
  const another = arr
    .filter(
      q =>
        (q.username !== userName && !q.isPrivate) ||
        (q.username === userName && !q.isWaiting)
    )
    .sort((a, b) =>
      +moment(a.dateOfQuestion).format("x") >
      +moment(b.dateOfQuestion).format("x")
        ? -1
        : 1
    );
  return { own, another };
};

export const isHowPage = obj => {
  if (!obj) return false;
  const keys = Object.keys(obj);
  if (!keys.length) return false;
  else {
    return keys.every(key => obj[key].length);
  }
};
