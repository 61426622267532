import React, { useState, useRef, useEffect } from "react";
import {
  DropZone,
  ImagesContainer,
  SingleImgItem,
} from "../../../containers/container";
import { GreyText } from "../../../text";
import { ButtonImg, UploadImg, Btn } from "../../../buttons";
import { ReactComponent as UploadIcon } from "../../../../../assets/upload.svg";
import { ReactComponent as DeleteIcon } from "../../../../../assets/delete-image-icon.svg";
import { ReactComponent as CheckIcon } from "../../../../../assets/check-image-icon.svg";
import { ReactComponent as MainIcon } from "../../../../../assets/main-icon.svg";

import { fileEncodeToBase64 } from "../../../../../helpers";
import { preventDefaultCustom } from "../Step/Information/helpers";
import { ExpandedPanel } from "./ExpandedPanel";
import Api from "../../../../../helpers/api";

export const Images = ({ token, onChange, defaultValues }) => {
  const [images, setImages] = useState(
    defaultValues.assetProperties.images || []
  );
  const inputFile = useRef(null);

  // subscribe to change images
  useEffect(() => {
    onChange("assetProperties", "images")(images);
  }, [images]);

  const onUpload = () => {
    inputFile && inputFile.current && inputFile.current.click();
  };

  const onSetAsMain = (index) => () => {
    const [main] = images.splice(index, 1);
    setImages([main, ...images]);
  };

  const removeImage = (index) => () => {
    const _images = images.filter((_, i) => index !== i);
    setImages(_images);
  };

  const uploadFile = async (file) => {
    if (file) {
      const base64 = await fileEncodeToBase64(file);
      if (base64) {
        setImages([
          ...images,
          {
            file,
            base64,
          },
        ]);
      }
    }
  };
  console.log(images);

  return (
    <ExpandedPanel
      onDragEnter={preventDefaultCustom}
      onDragOver={preventDefaultCustom}
      onDragLeave={preventDefaultCustom}
      onDrop={preventDefaultCustom}
      header="Images"
      key={`key-update-${images.length}`}
    >
      <ImagesContainer>
        {images.map((e, i) => (
          <SingleImgItem key={`key-${e.fileNameWithExtension || i}`}>
            <DeleteIcon onClick={removeImage(i)} style={{ right: 8 }} />
            {i === 0 ? (
              <MainIcon style={{ right: 34 }} />
            ) : (
              <CheckIcon style={{ right: 34 }} onClick={onSetAsMain(i)} />
            )}
            {e.base64 ? (
              <ButtonImg height="100%" src={e.base64} />
            ) : (
              <UploadImg
                height="100px"
                load={() =>
                  Api.getAttachment(
                    token,
                    e.secureHash,
                    e.fileNameWithExtension
                  )
                }
              />
            )}
          </SingleImgItem>
        ))}
      </ImagesContainer>
      <DropZone
        onSubmit={(e) => e.preventDefault()}
        enctype="multipart/form-data"
        action=""
        method="post"
        onDragEnter={preventDefaultCustom}
        onDragOver={preventDefaultCustom}
        onDragLeave={preventDefaultCustom}
        onDrop={(e) => {
          preventDefaultCustom(e);
          uploadFile(e.dataTransfer.files[0]);
        }}
      >
        <GreyText>Drop images here or</GreyText>
        <input
          ref={inputFile}
          type="file"
          accept="image/"
          style={{ display: "none" }}
          id="file-dataAndRoom"
          onChange={(e) => {
            if (e.target.files[0]) uploadFile(e.target.files[0]);
          }}
        />
        <Btn margin="0 0 0 10px" width={125} onClick={onUpload}>
          <UploadIcon />
          upload image
        </Btn>
      </DropZone>
    </ExpandedPanel>
  );
};
