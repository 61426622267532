import styled from "styled-components";
import React, { useState, useEffect } from "react";
import moment from "moment";

import Api from "../../../../helpers/api";
import ApiKyc from "../UserKyc/api";
import { UserIcon } from "components/common/icons-primary";
import { ReactComponent as UserIconFemale } from "../../../../assets/no-avatar-female.svg";
import { ReactComponent as IncomeIcon } from "../../../../assets/arrow-down-right.svg";
import { ReactComponent as OutIcon } from "../../../../assets/arrow-up-right.svg";
import { ReactComponent as RigthIcon } from "../../../../assets/chevron-right.svg";
import { ReactComponent as Eye } from "../../../../assets/eye.svg";
import { ReactComponent as Download } from "../../../../assets/download.svg";
import { File } from "components/common/icons-primary";
import { numberWithCommas } from "../../../../helpers";
import { getTrackerHTML } from "../../../../data-room-panel/helpers/fileViewer";
import { Link } from "react-router-dom";

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => (width ? `${width}%` : "auto")};
`;

const Heading = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 135%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  margin-bottom: 5px;
  color: rgba(24, 24, 24, 0.6);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const Info = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: ${({ bold }) => (bold ? "bold" : "normal")};
  font-size: 14px;
  line-height: 150%;
  color: ${({ bold, email, link, theme: { primary } }) =>
    bold && !link ? "#181818" : email || link ? primary : "#333333"};
  ${({ link }) => (link ? "cursor:pointer; text-decoration:underline;" : "")};
`;

export const FlexRow = styled.div`
  display: flex;
  justify-content: ${({ noSpaceBetween }) =>
    noSpaceBetween ? "" : "space-between"};
  width: ${({ width }) => (width ? `${width}%` : "100%")};
`;

const GreyBlock = styled.div`
  height: 96px;
  background: white;
  border-radius: 4px;
  position: ${({ absolute }) => (absolute ? "absolute" : "relative")};
  > svg {
    width: 102px;
    height: 102px;
    position: absolute;
    z-index: 20;
    top: 30px;
    left: 25px;
  }
`;
const GreyBlockGroup = styled.div`
  height: 96px;
  background: #e2e3e8;
  border-radius: 4px;
  position: relative;
  > svg {
    width: 20px;
    height: 20px;
    position: absolute;
    z-index: 20;
    top: 38px;
    left: 15px;
  }
`;

const Avatar = styled.img`
  width: 102px;
  height: 102px;
  border-radius: 50%;
  position: absolute;
  z-index: 20;
  top: 30px;
  left: 25px;
  object-fit: cover;
`;

const AvatarGroup = styled.img`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: absolute;
  z-index: 20;
  top: 38px;
  left: 15px;
  object-fit: cover;
`;

export const AvatarBlock = ({ token, file, hash, gender, absolute }) => {
  const [avatar, setAvatar] = useState(null);

  useEffect(() => {
    if (!avatar && file && hash) {
      Api.getAttachment(token, hash, file).then(blob =>
        setAvatar(blob || Api.defaultAvatar)
      );
    }
  }, []);
  return (
    <GreyBlock absolute={absolute}>
      {!avatar && file && hash ? null : avatar ? (
        <Avatar src={avatar} alt="avatar" />
      ) : gender && gender === "FEMALE" ? (
        <UserIconFemale />
      ) : (
        <UserIcon />
      )}
    </GreyBlock>
  );
};

export const GroupAvatarBlock = ({ token, file, hash }) => {
  const [avatar, setAvatar] = useState(null);

  useEffect(() => {
    if (!avatar && file && hash) {
      Api.getAttachment(token, hash, file).then(blob =>
        setAvatar(blob || Api.defaultAvatar)
      );
    }
  }, []);
  return (
    <GreyBlockGroup>
      {!avatar && file && hash ? null : avatar ? (
        <AvatarGroup src={avatar} alt="avatar" />
      ) : (
        <UserIcon />
      )}
    </GreyBlockGroup>
  );
};

export const InfoCol = ({
  heading,
  info,
  email = false,
  bold = false,
  width,
  link
}) => {
  return (
    <Column width={width}>
      <Heading>{heading}</Heading>
      <Info email={email} bold={bold} link={link}>
        {info}
      </Info>
    </Column>
  );
};

const NotesField = styled.input`
  height: 42px;
  background: ${({ theme: { disabledField } }) => disabledField};
  border: 1px solid #efefef;
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 7px;
  outline: none;
  padding: 0 20px;
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  color: #181818;
`;

export const Notes = ({
  heading,
  width,
  onChange,
  defaultValue,
  disabled = false
}) => {
  return (
    <Column width={width}>
      <Heading>{heading}</Heading>
      <NotesField
        disabled={disabled}
        onChange={onChange}
        defaultValue={defaultValue}
      />
    </Column>
  );
};

const Count = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 150%;
  display: flex;
  align-items: center;
  color: ${({ movementType }) =>
    movementType === "INCOME"
      ? "#019D52"
      : movementType === "OUTCOME" || movementType === "REJECTED"
      ? "#FF4343"
      : "#333333"};
`;

export const Amount = ({ movementType, quantity, tokenIdentifier }) => {
  return (
    <Count movementType={movementType}>
      {movementType === "OUTCOME" ? "-" : null} {tokenIdentifier}{" "}
      {numberWithCommas(quantity)}{" "}
      {movementType === "INCOME" ? (
        <OutIcon />
      ) : movementType === "OUTCOME" ? (
        <IncomeIcon />
      ) : null}
    </Count>
  );
};

export const Total = styled.div`
  position: absolute;
  top: 20px;
  right: 25px;
  font-weight: 600;
  font-size: 14px;
  line-height: 135%;
  color: rgba(24, 24, 24, 0.6);
`;

const AssetBlock = styled.div`
  width: 100%;
  height: 50px;
  background: #ffffff;
  border: 1px solid #f0f0f0;
  box-sizing: border-box;
  box-shadow: 0px 10px 40px rgba(0, 0, 0, 0.02);
  border-radius: 4px;
  margin-bottom: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  &:last-child {
    margin-bottom: 0;
  }
`;

const LogoBlock = styled.div`
  display: flex;
  align-items: center;
  width: calc(100% - 355px);
  height: 100%;
`;

const NoLogo = styled.div`
  width: 80px;
  height: 100%;
  margin-right: 18px;
`;

const AssetLogo = styled.img`
  width: 80px;
  height: 100%;
  margin-right: 18px;
  object-fit: cover;
`;

const AssetName = styled.h6`
  font-size: 12px;
  line-height: 15px;
  letter-spacing: -0.02em;
  color: ${({ theme: { primary } }) => primary};
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: calc(100% - 120px);
  border-bottom: 0.5px solid rgba(98, 128, 218, 0.2);
  padding-bottom: 4px;
`;

const InvestedCount = styled.div`
  font-weight: bold;
  font-size: 12px;
  line-height: 15px;
  letter-spacing: -0.02em;
  color: #181818;
  width: 150px;
  box-sizing: border-box;
  padding: 0 15px 0 10px;
`;

const InvestedDate = styled.div`
  width: 150px;
  box-sizing: border-box;
  padding: 0 15px 0 10px;
  font-weight: 600;
  font-size: 12px;
  line-height: 150%;
  color: #333333;
`;

const LinkBlock = styled.div`
  width: 55px;
  height: 100%;
  display: flex;
  > a {
    margin: auto;
  }
`;

const AssetLink = styled(Link)`
  width: 24px;
  height: 24px;
  background: ${({ theme: { infoButtonBg } }) => infoButtonBg};
  > svg {
    path {
      stroke: ${({ theme: { primary } }) => primary};
    }
  }
  border-radius: 2px;
  display: flex;
  > svg {
    margin: auto;
    width: 8px;
    height: 8px;
    transform: rotate(180deg);
    path {
      stroke: ${({ theme: { primary } }) => primary};
    }
  }
`;

export const InvestedItem = ({
  asset: { assetLogo, assetName, currency, date, invested, assetId },
  token
}) => {
  const [logo, setLogo] = useState(null);

  useEffect(() => {
    if (!logo && assetLogo) {
      const { fileNameWithExtension, secureHash } = assetLogo;
      Api.getAttachment(token, secureHash, fileNameWithExtension).then(b =>
        setLogo(b)
      );
    }
  }, []);
  return (
    <AssetBlock>
      <LogoBlock>
        {logo ? <AssetLogo src={logo} alt={assetName} /> : <NoLogo />}
        <AssetName>{assetName}</AssetName>
      </LogoBlock>
      <InvestedCount>
        {numberWithCommas(invested.toFixed(2))} {currency}
      </InvestedCount>
      <InvestedDate>{moment(date).format("DD/MM/YYYY")}</InvestedDate>
      <LinkBlock>
        <AssetLink to={`/admin/dashboard/${assetId}`}>
          <RigthIcon />
        </AssetLink>
      </LinkBlock>
    </AssetBlock>
  );
};

export const FullBg = styled.section`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: transparent;
  display: flex;
  > div {
    margin: auto;
  }
`;

export const GroupBlock = styled.div`
  width: 275px;
  height: 40px;
  background: ${({ theme: { quoteBgInvestor } }) => quoteBgInvestor};
  border: 1px solid #f0f0f0;
  box-sizing: border-box;
  border-radius: 4px;
  margin-bottom: 6px;
  display: flex;
  align-items: center;
  overflow: hidden;
  white-space: nowrap;
  > span {
    font-size: 12px;
    line-height: 135%;
    letter-spacing: -0.02em;
    color: #181818;
    display: block;
    width: calc(100% - 60px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  > div {
    margin-right: 45px;
  }
  &:last-child {
    margin-bottom: 0;
  }
`;

export const AnswerWrap = styled.div`
  height: ${({ height }) => height};
  transition: height 0.1s linear;
  overflow: ${({ height }) =>
    height && height !== "fit-content" ? "hidden" : "visible"};
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: ${({ mBottom }) => (mBottom ? mBottom : "20px")};
  > div {
    margin-bottom: ${({ mb = false }) => (mb ? mb : "20px")};
    :last-child {
      margin-bottom: 0;
    }
  }
`;

const FileWrap = styled.div`
  width: 100%;
  height: 40px;
  margin-bottom: 0;
  background: ${({ theme: { quoteBgInvestor } }) => quoteBgInvestor};
  border-radius: 4px;
  border: 1px solid #efefef;
  box-sizing: border-box;
  padding: 13px 19px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const FileName = styled.div`
  display: flex;
  align-items: center;
  width: calc(100% - 50px);
  > h4 {
    width: calc(100% - 40px);
    margin: 0;
    font-family: Muli;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 135%;
    color: #181818;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    > span {
      font-family: Muli;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 135%;
      color: rgba(24, 24, 24, 0.6);
      margin-left: 6px;
    }
  }

  > svg {
    &:first-child {
      width: 24px;
      height: 24px;
      margin-right: 10px;
    }
    width: 16px;
    height: 16px;
    margin-right: 14px;
  }
`;

export const FileControls = styled.div`
  display: flex;
  align-items: center;
  svg {
    cursor: pointer;
    transform: ${({ open, rotate }) =>
      !rotate ? "0deg" : open ? "rotate(270deg)" : "rotate(90deg)"};
    width: 16px;
    height: 16px;
    margin-right: 17px;
    :last-child {
      margin-right: 0;
    }
  }
`;

export const FileHolder = ({ file, token }) => {
  const [wait, setWait] = useState(false);

  const onDownload = async () => {
    setWait(true);
    const link = document.createElement("a");
    const blob = await ApiKyc.downloadFile(
      token,
      file.userId,
      file.uuid,
      file.fileName,
      1111111
    ).catch(() => setWait(false));
    link.href = blob;
    link.download = file.fileName;
    link.click();
    setWait(false);
  };

  const onOpen = () => {
    getTrackerHTML(
      token,
      file.fileName,
      `Attachments~${file.userId}/${file.uuid}_${file.fileName}?assetId=1111111`,
      false,
      true,
      false,
      false
    );
  };
  return (
    <FileWrap>
      <FileName>
        <File />
        <h4>{file.fileName}</h4>
      </FileName>
      <FileControls>
        <Eye onClick={onOpen} />
        <Download onClick={wait ? undefined : onDownload} />
      </FileControls>
    </FileWrap>
  );
};
