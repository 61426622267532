import React from "react";
import { InputRow } from "../Step/Information/styled";
import {
  TextFieldComponent,
  SelectComponent,
  DateTimeFieldComponent
} from "../../../forms";

import { ExpandedPanel } from "./ExpandedPanel";
import { CountingValues } from "../styled";
import { numberWithCommas } from "helpers";

export const Parameters = ({
  onChange,
  defaultValues,
  acceptedCurrency,
  isEdit
}) => {
  const {
    valuation,
    baseCurrency,
    tokenQuantity,
    minInvestment,
    maxInvestment
  } = defaultValues.assetFinancialProperty;

  return (
    <ExpandedPanel header="Parameters">
      <InputRow>
        <InputRow last width="calc(50% - 15px)">
          <DateTimeFieldComponent
            zIndex={1}
            label="Start (MM/DD/YYYY, HH:MM)"
            onChange={onChange("assetFinancialProperty", "timeOfStart")}
            defaultValue={defaultValues.assetFinancialProperty.timeOfStart}
            padding="0 14px"
            bgPos={14}
          />
        </InputRow>
        <InputRow last width="calc(50% - 15px)">
          <DateTimeFieldComponent
            zIndex={1}
            label="END (MM/DD/YYYY, HH:MM)"
            onChange={onChange("assetFinancialProperty", "timeOfEnd")}
            defaultValue={defaultValues.assetFinancialProperty.timeOfEnd}
            padding="0 14px"
            bgPos={14}
          />
        </InputRow>
      </InputRow>
      <InputRow>
        <TextFieldComponent
          width="calc(25% - 30px)"
          label="Share price"
          placeholder="Share price"
          type="number"
          onChange={onChange("assetFinancialProperty", "valuation")}
          defaultValue={defaultValues.assetFinancialProperty.valuation}
        />
        <SelectComponent
          width="calc(25% - 15px)"
          label="Base currency"
          placeholder="Base currency"
          values={acceptedCurrency}
          onChange={onChange("assetFinancialProperty", "baseCurrency")}
          defaultValue={defaultValues.assetFinancialProperty.baseCurrency}
        />
        <SelectComponent
          width="calc(50% - 15px)"
          label="Accepted currency (can be more then one)"
          placeholder="accepted currency (can be more then one)"
          values={acceptedCurrency}
          onChange={e => {
            onChange(
              "assetFinancialProperty",
              "acceptedCurrency"
            )([e.target.value]);
          }}
          defaultValue={defaultValues.assetFinancialProperty.acceptedCurrency}
        />
      </InputRow>
      <InputRow>
        <TextFieldComponent
          width="calc(50% - 15px)"
          label="Soft cap (optional)"
          placeholder="Soft cap (optional)"
          onChange={onChange("assetProperties", "softCap")}
          defaultValue={defaultValues.assetProperties.softCap}
        />
        <TextFieldComponent
          width="calc(50% - 15px)"
          label="Hard cap (optional)"
          placeholder="Hard cap (optional)"
          onChange={onChange("assetProperties", "hardCap")}
          defaultValue={defaultValues.assetProperties.hardCap}
        />
      </InputRow>
      <InputRow last>
        <TextFieldComponent
          width="calc(50% - 15px)"
          label="Share quantity (max of shares)"
          placeholder="Share quantity (max of shares)"
          onChange={onChange("assetFinancialProperty", "tokenQuantity")}
          defaultValue={defaultValues.assetFinancialProperty.tokenQuantity}
          disabled={isEdit}
        />
        <TextFieldComponent
          width="calc(30% - 38.5px)"
          label="Min investment (shares)"
          placeholder="Min investment (shares)"
          onChange={onChange("assetFinancialProperty", "minInvestment")}
          defaultValue={defaultValues.assetFinancialProperty.minInvestment}
        />
        <TextFieldComponent
          width="calc(20% - 7.5px)"
          label="Max investment (shares)"
          placeholder="Max investment (shares)"
          onChange={onChange("assetFinancialProperty", "maxInvestment")}
          defaultValue={defaultValues.assetFinancialProperty.maxInvestment}
        />
      </InputRow>
      <InputRow last>
        <CountingValues w="calc(50% - 15px)">
          {valuation && baseCurrency && tokenQuantity
            ? `~ ${numberWithCommas(
                (valuation * tokenQuantity).toFixed(2)
              )} ${baseCurrency}`
            : null}
        </CountingValues>
        <CountingValues w="calc(30% - 38.5px)">
          {valuation && baseCurrency && minInvestment
            ? `~ ${numberWithCommas(
                (valuation * minInvestment).toFixed(2)
              )} ${baseCurrency}`
            : null}
        </CountingValues>
        <CountingValues w="calc(20% - 7.5px)">
          {valuation && baseCurrency && maxInvestment
            ? `~ ${numberWithCommas(
                (valuation * maxInvestment).toFixed(2)
              )} ${baseCurrency}`
            : null}
        </CountingValues>
      </InputRow>
    </ExpandedPanel>
  );
};
