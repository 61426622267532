import React, { Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import moment from "moment";

import { getQuestionAsset, updateAdminNotifications } from "../../../../modules/dataRedux/dataAction";
import { getAdmDealFiles } from "../../../../modules/dataRoomRedux/dataRoomActions";
import Api from "../../../../helpers/api";
import {
  Header,
  // BackLink,
  Heading,
  ColWrapper,
  Col,
  InfoCard,
  Question,
  Info,
  SubmBlock,
  UserAvatar,
  Sepatator,
  EditSeparator,
  SelectFile,
} from "./styled";
import { BackLink } from "../QAAsset/styled";
import { FileBlock, FileName, FileControls } from "../QAAsset/styled";
import { TextAreaComponent, FooterComponent } from "../../forms";
import { Btn } from "../../../admin-layout/buttons";
import { ReactComponent as ArrowLeft } from "../../../../assets/arrowleft.svg";
import { File } from "components/common/icons-primary";
import { ReactComponent as Eye } from "../../../../assets/eye.svg";
import { ReactComponent as Download } from "../../../../assets/download.svg";
import { ReactComponent as Rem } from "../../../../assets/x-close-red.svg";
import { ReactComponent as Right } from "../../../../assets/chevron-right2.svg";
import { FileSelector } from "./FileSelector";
import { RemoveQuestionModal } from "../QAAsset/RemoveQuestionModal";
import { ErrorMessage } from "../../errorMessage";
import { Wait } from "../DashBoard/styled";

const QAAnswer = ({
  token,
  match: {
    params: { assetId, questionId, edit },
  },
  history,
  dataQA,
  getAdmDealFiles,
  adminDeals,
  updateAdminNotifications,
  getQuestionAsset,
}) => {
  const _question = dataQA[assetId] ? dataQA[assetId].data.find((q) => q.questionId === questionId) : {};
  const {
    textQuestion,
    category,
    dateOfQuestion,
    userFullName,
    isPrivate,
    userGender,
    userInvestorType,
    userLogo,
    textAnswer,
    amfullName,
    dateOfAnswer,
    amlogo,
    aminvestorType,
    amgender,
    size,
    filename,
    folderName,
    amsize,
    amfilename,
    amfolderName,
  } = _question;

  const [modalOpen, setModalOpen] = useState(false);
  const [selectorOpen, setSelectorOpen] = useState(false);
  const [wait, setWait] = useState(false);
  const [error, setError] = useState(null);
  const [cheked, setChecked] = useState(!isPrivate);
  const [answer, setAnswer] = useState(edit ? textAnswer : "");
  const [selectedFile, setSelectedFile] = useState(
    edit
      ? { folderName: amfolderName, fileName: amfilename, fileSize: amsize }
      : { folderName: "", fileName: "", fileSize: 0 }
  );

  const onRemFile = (e) => {
    e.stopPropagation();
    setSelectedFile({ fileName: "", folderName: "", fileSize: 0 });
  };

  const onChangeCheck = () => {
    setChecked(!cheked);
  };

  useEffect(() => {
    if (!dataQA[assetId]) getQuestionAsset(token, assetId);
    getAdmDealFiles(token, assetId, onError);
  }, []);

  const onError = (e) => {
    setError(e);
    setWait(false);
  };

  const onInput = (e) => {
    setAnswer(e.target.value);
  };

  const onAnswer = async () => {
    setWait(true);
    const data = {
      amFolderName: selectedFile.folderName ? selectedFile.folderName : null,
      amFilename: selectedFile.fileName ? selectedFile.fileName : null,
      content: answer,
      isPrivate: !cheked,
      questionId,
    };
    const res = await Api.answerQuestion(token, data).catch(onError);

    if (res) {
      setWait(false);
      const waitingQuestions = await Api.getQuestionAnswer(token);
      if (waitingQuestions) {
        updateAdminNotifications(
          waitingQuestions.reduce((total, e) => {
            return total + e.waitingQuestions;
          }, 0),
          "Q&A"
        );
      }
      history.push(`/admin/question-and-answer/asset/${assetId}`);
    }
  };

  const onSelectFile = () => {
    setSelectorOpen(!selectorOpen);
  };

  const onEditAnswer = async () => {
    setWait(true);
    const data = {
      amFolderName: selectedFile.folderName ? selectedFile.folderName : null,
      amFilename: selectedFile.fileName ? selectedFile.fileName : null,
      content: answer,
      isPrivate: !cheked,
      questionId,
    };
    const res = await Api.updateAnswerQuestion(token, data).catch(onError);

    if (res) {
      setWait(false);
      history.push(`/admin/question-and-answer/asset/${assetId}`);
    }
  };

  const onClose = () => {
    setModalOpen(!modalOpen);
  };

  const onRemove = () => {
    setModalOpen(true);
  };

  const onSendRequest = async () => {
    setWait(true);
    const res = await Api.deleteQuestion(token, questionId).catch(onError);

    if (res) {
      setWait(false);
      history.push(`/admin/question-and-answer/asset/${assetId}`);
    }
  };

  const onView = (folderName, filename) => async () => {
    const fileUrl = await Api.viewQADocument(token, folderName, filename, assetId).catch(onError);

    if (fileUrl) {
      const link = document.createElement("a");
      link.target = "_blank";
      link.href = fileUrl;
      link.click();
    }
  };

  const downloadDocument = (folderName, filename) => async () => {
    const fileUrl = await Api.downloadQADocument(token, folderName, filename, assetId).catch(onError);

    if (fileUrl) {
      const link = document.createElement("a");
      link.target = "_blank";
      link.href = fileUrl;
      link.click();
    }
  };

  if (!dataQA[assetId]) return <Wait />;

  return (
    <Fragment>
      <Header>
        <BackLink to={`/admin/question-and-answer/asset/${assetId}`}>
          <ArrowLeft /> ALL QUESTIONS
        </BackLink>
        <Heading>{edit ? "Edit Answer" : "Answer Question"}</Heading>
        {edit ? (
          <Btn width={180} error onClick={onRemove}>
            REMOVE QUESTION
          </Btn>
        ) : (
          <Btn width={180} hide>
            REMOVE QUESTION
          </Btn>
        )}
      </Header>
      <ColWrapper>
        <Col>
          <InfoCard>
            <h3>Question</h3>
            <Question>{textQuestion}</Question>
            {filename && size ? (
              <Fragment>
                <h3>Linked file</h3>
                <FileBlock isAnswered edit lightgrey>
                  <FileName>
                    <File />
                    <h4>
                      {filename}
                      <span>{(size / 1024).toFixed(2)} mb</span>
                    </h4>
                  </FileName>
                  <FileControls>
                    <Eye onClick={onView(folderName, filename)} />
                    <Download onClick={downloadDocument(folderName, filename)} />
                  </FileControls>
                </FileBlock>
              </Fragment>
            ) : null}
            <TextAreaComponent
              areaHeight={260}
              label="Your Answer"
              placeholder="Your answer here"
              defaultValue={answer}
              onChange={onInput}
            />
            <Sepatator />
            {textAnswer && selectedFile.fileName && amsize ? (
              <Fragment>
                <h3>Linked file</h3>
                <FileBlock isAnswered edit onClick={onSelectFile}>
                  <FileName>
                    <File />
                    <h4>
                      {selectedFile.fileName}
                      <span>{(selectedFile.fileSize / 1024).toFixed(2)} mb</span>
                    </h4>
                  </FileName>
                  <FileControls open={selectorOpen} rotate>
                    <Rem onClick={onRemFile} />
                    <Right />
                  </FileControls>
                </FileBlock>
              </Fragment>
            ) : (
              <Fragment>
                <h3>{selectedFile.fileName ? "LINKED FILE" : "LINK A FILE"}</h3>
                <SelectFile onClick={onSelectFile} open={selectorOpen}>
                  {selectedFile.fileName ? (
                    <Fragment>
                      <FileName>
                        <File />
                        <h4>
                          {selectedFile.fileName}
                          <span>{(selectedFile.fileSize / 1024).toFixed(2)} mb</span>
                        </h4>
                      </FileName>
                      <Rem onClick={onRemFile} />
                    </Fragment>
                  ) : (
                    "Choose a File"
                  )}
                  <Right />
                </SelectFile>
              </Fragment>
            )}
            <FooterComponent
              defaultValue={cheked}
              onChange={onChangeCheck}
              label={`${userFullName} WANT TO SHARE this QUESTION`}
              name="Share this Question & Answer with other investors"
            />
          </InfoCard>
        </Col>
        <Col right>
          <InfoCard>
            <h3>CATEGORY</h3>
            <Info>{category}</Info>
            <h3>Question From</h3>
            <Info from>
              <UserAvatar
                small
                token={token}
                fileName={userLogo ? userLogo.fileNameWithExtension : null}
                hash={userLogo ? userLogo.secureHash : null}
                investorType={userInvestorType}
                gender={userGender}
              />
              {userFullName}
            </Info>
            <h3>DATE, TIME</h3>
            <Info>{moment(dateOfQuestion).format("DD.MM.YYYY, hh:mm A")}</Info>

            {edit ? (
              <Fragment>
                <EditSeparator />
                <h3>ANSWER BY</h3>
                <Info from>
                  <UserAvatar
                    small
                    token={token}
                    fileName={amlogo ? amlogo.fileNameWithExtension : null}
                    hash={amlogo ? amlogo.secureHash : null}
                    investorType={aminvestorType}
                    gender={amgender}
                  />
                  {amfullName}
                </Info>
                <h3>DATE, TIME</h3>
                <Info>{moment(dateOfAnswer).format("DD.MM.YYYY, hh:mm A")}</Info>
              </Fragment>
            ) : null}
          </InfoCard>
          <SubmBlock>
            {edit ? (
              <Btn
                disabled={textAnswer === answer && !isPrivate === cheked && amfilename === selectedFile.fileName}
                width={273}
                onClick={onEditAnswer}
              >
                SAVE CHANGES
              </Btn>
            ) : (
              <Btn width={273} disabled={!answer || wait} onClick={onAnswer}>
                POST ANSWER
              </Btn>
            )}
          </SubmBlock>
        </Col>
      </ColWrapper>
      {modalOpen ? (
        <RemoveQuestionModal
          onAgree={onSendRequest}
          onBackdropClick={onClose}
          userFullName={userFullName}
          dateOfQuestion={dateOfQuestion}
        />
      ) : null}
      {selectorOpen ? (
        <FileSelector
          onBackdropClick={onSelectFile}
          file={selectedFile}
          data={adminDeals}
          assetId={assetId}
          setSelectedFile={setSelectedFile}
        />
      ) : null}
      {wait ? <Wait /> : null}
      {error ? <ErrorMessage er={error} onClose={() => setError(null)} /> : null}
    </Fragment>
  );
};

export default connect(
  ({ userReducer: { token }, dataReducer: { dataQA }, dataRoomReducer: { adminDeals } }) => ({
    token,
    dataQA,
    adminDeals,
  }),
  { getQuestionAsset, getAdmDealFiles, updateAdminNotifications }
)(QAAnswer);
