import React from "react";
import styled from "styled-components";

import { icons } from "../../../../../models/icons";

const Bg = styled.div`
  width: 100%;
  height: ${({ heigth = false }) => (heigth ? heigth : "100%")};
  background: ${({ theme: { emptyCardBg } }) => emptyCardBg};
  border-radius: 2px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${({ padding }) => (padding ? padding : "30px")};
  margin-top: ${({ mTop = false }) => (mTop ? mTop : "")};
  > svg {
    margin-bottom: 32px;
    width: 52px;
    height: 52px;
    path {
      stroke: ${({ theme: { primary } }) => primary};
    }
  }
`;

const Heading = styled.h3`
  font-weight: bold;
  font-size: 14px;
  line-height: 135%;
  text-align: center;
  color: #181818;
  margin: ${({ headingNoMargin }) => (headingNoMargin ? "0px" : "0 0 14px")};
`;

const Text = styled.p`
  font-size: 12px;
  line-height: 135%;
  text-align: center;
  margin: 0;
  color: #181818;
`;

export const EmptyInfo = ({
  heading = "There is no info yet",
  text = "When we’ll have info to show for you we’ll show it here",
  icon = icons.searchBig,
  children,
  mTop,
  heigth,
  headingNoMargin,
  padding,
}) => (
  <Bg mTop={mTop} heigth={heigth} padding={padding}>
    {icon}
    <Heading headingNoMargin={headingNoMargin}>{heading}</Heading>
    <Text>{text}</Text>
    {children}
  </Bg>
);
