import React from "react";

import { Main, TextSub } from "../DealForm/Step/styled";
import { DealBlock, FlexRow, Ceil, AssetName, SignedDocument } from "./styled";
import { EmptyInfo } from "components/admin-layout/pages/AssetDetails/common/emptyInfo";
import { icons } from "models/icons";

export const UploadedDocuments = ({ token, userId, data }) => {
  const renderDocs = () => {
    const assets = Object.keys(data).filter((name) => {
      if (data[name]["Uploaded Files"] && data[name]["Uploaded Files"].length) return name;
    });

    if (assets.length) {
      return assets.map((asset) => {
        const docs = data[asset]["Uploaded Files"];
        return (
          <DealBlock key={`${asset}-${docs.length}`}>
            <FlexRow m="0 0 10px">
              <Ceil w="100%">
                <AssetName fullWidth>{asset}</AssetName>
              </Ceil>
            </FlexRow>
            {docs.map((d) => (
              <SignedDocument
                small
                token={token}
                key={`${asset}-${d.documentName}-${d.date}`}
                file={d}
                userId={userId}
              />
            ))}
          </DealBlock>
        );
      });
    } else
      return (
        <EmptyInfo
          icon={icons.file}
          heading="There are no uploaded documents yet"
          text="When we’ll have documents to show for you we’ll show them here"
        />
      );
  };

  if (!data) return null;
  else
    return (
      <Main margin="30px 0 0">
        <TextSub textAlign="left" fontWeight="bold" color="#181818" marginSize="0 0 25px 0">
          Uploaded Documents
        </TextSub>
        {renderDocs()}
      </Main>
    );
};
