import React, { useEffect } from "react";
import { connect } from "react-redux";

import { getTrades } from "../../../../modules/dataRedux/dataAction";

import { Button } from "../Offerings/styled";
import { Loader } from "../../styled/Loader";
import { Wrapper, Header, BorderBlock } from "../Offer/styled";
import { ReactComponent as BackIcon } from "../../../../assets/arrowleft.svg";

export const MainWrapper = ({ trade, waitData, token, getTrades, history, type, ...props }) => {
  useEffect(() => {
    if (!trade) getTrades(token);
  }, []);
  return waitData || !trade ? (
    <Loader />
  ) : (
    <Wrapper>
      <Header>
        <BorderBlock>
          <Button
            height={36}
            width={157}
            size={12}
            transparent
            onClick={() => history.push("/investor/offerings/users")}
          >
            <BackIcon style={{ marginRight: 8 }} />
            Back to Deals
          </Button>
        </BorderBlock>
      </Header>

      {props.children(trade, type)}
    </Wrapper>
  );
};

export default connect(
  (
    { userReducer: { token }, dataReducer: { trades = [], waitData } },

    {
      match: {
        params: { id, type },
      },
    }
  ) => {
    return {
      token,
      trade: trades.find((e) => e.assetId === id),
      waitData,
      id,
      type,
    };
  },
  { getTrades }
)(MainWrapper);
