import { createAction } from "redux-act";

export const setAcceptedCurrency = createAction("SET_ACCEPTED_CURRENCY_LIST");
export const setSponsors = createAction("SET_SPONSOR_LIST");
export const setAccesses = createAction("SET_ACCESSES_LIST");
export const setDealTypes = createAction("SET_DEAL_TYPES_LIST");
export const setCategories = createAction("SET_CATEGORIES_LIST");
export const setRuleOptions = createAction("SET_RULE_OPTIONS");
export const setCountriesOption = createAction("SET_COUNTRIES_OPTION");
export const setInvestorWorkflowTypes = createAction(
  "SET_INVESTOR_WORK_FLOW_TYPES"
);
export const setLockUpTimes = createAction("SET_LOCK_UP_TIME");
export const setSponsorsFull = createAction("SET_SPONSOR_FULL_LIST");
export const setFinancialTypes = createAction("SET_FINANCIAL_DEAL_TYPES");
