import React, { Fragment, useState, useRef } from "react";
import moment from "moment";

import { Input, Button, GrayButton } from "components/common/form";
import { ModalWrapper } from "components/common/modal";

import { ReactComponent as Remove } from "assets/x-circle.svg";
import { ReactComponent as Delete } from "assets/trash.svg";
import { ReactComponent as Edit } from "assets/edit.svg";
import { ReactComponent as Plus } from "assets/plus-circle.svg";

import {
  SelectComponent,
  DateTimeFieldComponent
} from "components/admin-layout/forms";
import { Separator } from "components/common/separator";

import { Row, Close, EventPoint, EventText } from "../styled";
import {
  Title,
  Events,
  EventRow,
  EventDate,
  IconWrapper,
  TitleField,
  FieldWrapper
} from "./styled";
import { CircularProgress } from "@material-ui/core";

export const SettingsForm = ({
  onClose,
  onSave,
  events,
  onDelete,
  assetName,
  assetNames
}) => {
  const formRef = useRef();
  const [isAction, setIsAction] = useState(false);
  const [editEvent, setEditEvent] = useState(null);
  const [asset, setAsset] = useState(assetName || "");
  const [date, setDate] = useState();

  return (
    <ModalWrapper width={565} onBackgroundClick={onClose} padding="0 20px 15px">
      {isAction ? (
        <CircularProgress
          size={36}
          style={{
            margin: "auto",
            position: "absolute",
            left: "calc(50% - 16px)",
            top: "calc(50% - 16px)",
            zIndex: "99"
          }}
        />
      ) : null}
      <Row height="52px">
        <Title fontSize={16}>Events</Title>
        <Close onClick={onClose}>
          <Remove style={{ width: "24px", heigth: "24px" }} />
        </Close>
      </Row>
      <Separator margin="0 0 22px" />

      {editEvent ? (
        <form ref={formRef} onSubmit={e => e.preventDefault()}>
          <Row>
            <FieldWrapper flex={10} margin="0 20px 0 0">
              <TitleField fontSize={10}>Event Title</TitleField>
              <Input
                width="100%"
                name="name"
                placeholder="Event text"
                defaultValue={editEvent.name || ""}
              />
            </FieldWrapper>
            <FieldWrapper>
              <TitleField fontSize={10}>Color</TitleField>
              <Input
                calendar
                width="24px"
                height={24}
                margin="0 0 0 6px"
                name="color"
                type="color"
                defaultValue={editEvent.color || ""}
              />
            </FieldWrapper>
          </Row>
          <Separator color="transparent" margin="10px 0" />
          <Row>
            <FieldWrapper>
              <TitleField margin="0" fontSize={10}>
                Event for asset
              </TitleField>
              <SelectComponent
                top={50}
                values={assetNames}
                onChange={e => setAsset(e.target.value)}
                placeholder="Selected Asset"
                hiddenLabel
                defaultValue={editEvent.name || asset || ""}
              />
            </FieldWrapper>
          </Row>
          <Separator color="transparent" margin="10px 0" />
          <Row>
            <FieldWrapper flex={3}>
              <TitleField margin="0" fontSize={10}>
                Date
              </TitleField>
            </FieldWrapper>
            <FieldWrapper flex={2}>
              <TitleField margin="0" fontSize={10}>
                Time
              </TitleField>
            </FieldWrapper>
          </Row>
          <Row>
            <FieldWrapper>
              <DateTimeFieldComponent
                defaultValue={
                  editEvent.dateTime
                    ? new Date(editEvent.dateTime)
                    : moment()
                        .set({ minute: 0 })
                        .add(1, "hour")
                }
                onChange={e => setDate(e)}
              />
            </FieldWrapper>
          </Row>
          <Separator color="transparent" margin="6px 0" />
        </form>
      ) : (
        <Events onClick={e => e.stopPropagation()}>
          {Object.keys(events).map(assetName => {
            const _events = (events[assetName] && events[assetName].length
              ? events[assetName]
              : []
            ).filter(
              e => +moment(e.dateTime).format("x") >= moment().format("x")
            );

            return (
              <Fragment>
                <Title className="title">{assetName}</Title>
                <Separator color="transparent" margin="10px 0" />
                {_events.length ? (
                  _events.map((e, i) => (
                    <EventRow key={`date-key-${e.dateTime}-${i}`}>
                      <EventPoint eventColor={e.color} />
                      <EventText>{e.name}</EventText>
                      <EventDate>
                        {moment(e.dateTime).format("DD.MM.YYYY, hh:mm A")}
                      </EventDate>
                      <Row>
                        <IconWrapper
                          onClick={async () => {
                            setIsAction(true);
                            await onDelete(e.id);
                            setIsAction(false);
                          }}
                        >
                          <Delete />
                        </IconWrapper>
                        <IconWrapper onClick={() => setEditEvent(e)}>
                          <Edit />
                        </IconWrapper>
                      </Row>
                    </EventRow>
                  ))
                ) : (
                  <EventRow style={{ height: "24px" }}>
                    <EventText>Not events have been yet.</EventText>
                  </EventRow>
                )}
              </Fragment>
            );
          })}
        </Events>
      )}
      <Separator margin="24px 0 15px" />
      <Row>
        <GrayButton onClick={editEvent ? () => setEditEvent(null) : onClose}>
          {editEvent ? "Cancel" : "Close"}
        </GrayButton>
        {editEvent ? (
          <Button
            onClick={async () => {
              const data = {
                assetName: asset,
                name: formRef.current["name"].value,
                color: formRef.current["color"].value,
                dateAndTime: date
              };
              setIsAction(true);
              if (editEvent.id) {
                data.id = editEvent.id;
              }

              await onSave(data);
              setIsAction(false);
              setEditEvent(null);
            }}
          >
            SAVE
          </Button>
        ) : (
          <Button onClick={() => setEditEvent({})}>
            <Plus /> ADD NEW EVENT
          </Button>
        )}
      </Row>
    </ModalWrapper>
  );
};
