import React from "react";

import { FlexRow, SocialLink } from "./styled";
import { ReactComponent as LinkedIn } from "../assets/linked.svg";
import { ReactComponent as FB } from "../assets/fb.svg";
import { ReactComponent as Google } from "../assets/Google.svg";
import { ReactComponent as Twitter } from "../assets/Twitter.svg";

export const BaseLinks = () => {
  return (
    <FlexRow>
      <SocialLink>
        <LinkedIn />
      </SocialLink>
      <SocialLink>
        <FB />
      </SocialLink>
      <SocialLink>
        <Google />
      </SocialLink>
      <SocialLink>
        <Twitter />
      </SocialLink>
    </FlexRow>
  );
};
