import styled, { keyframes } from "styled-components";
import React from "react";
import { ReactComponent as Valk } from "assets/valk.svg";

const loader1 = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const loader2 = keyframes`  
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }`;

const loader3 = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const LoaderContainer = styled.div`
  width: fit-content;
  height: fit-content;
  position: relative;
`;

const Logo = styled.div`
  position: absolute;
  top: 16px;
  left: 13px;
  > svg {
    width: 26px;
    height: 26px;
    path {
      fill: #1f263e;
    }
  }
`;

const Circle = styled.div`
  display: block;
  height: 52px;
  width: 52px;
  -webkit-animation: ${loader1} 3s linear infinite;
  animation: ${loader1} 3s linear infinite;
  span {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    height: 52px;
    width: 52px;
    clip: rect(26px, 52px, 52px, 0);
    -webkit-animation: ${loader2} 1.5s cubic-bezier(0.77, 0, 0.175, 1) infinite;
    animation: ${loader2} 1.5s cubic-bezier(0.77, 0, 0.175, 1) infinite;
    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      height: 52px;
      width: 52px;
      border: 4px solid transparent;
      border-top: 4px solid #fff;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      border-radius: 50%;
      -webkit-animation: ${loader3} 1.5s cubic-bezier(0.77, 0, 0.175, 1) infinite;
      animation: ${loader3} 1.5s cubic-bezier(0.77, 0, 0.175, 1) infinite;
    }
    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      height: 52px;
      width: 52px;
      border: 4px solid ${({ theme: { primaryMiddle } }) => primaryMiddle};
      border-radius: 50%;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
    }
  }
`;

export const Loader = () => (
  <LoaderContainer>
    <Circle>
      <span></span>
    </Circle>
    <Logo>
      <Valk />
    </Logo>
  </LoaderContainer>
);
