import styled from "styled-components";
import React, { Fragment, useState } from "react";
import moment from "moment";

import { ReactComponent as Check } from "../../../../assets/check.svg";
import { ReactComponent as X } from "../../../../assets/rej.svg";
import { ReactComponent as File } from "../../../../assets/file-slim.svg";
import { ReactComponent as Eye } from "../../../../assets/eye.svg";
import { ReactComponent as Download } from "../../../../assets/download.svg";
import { ReactComponent as Refresh } from "../../../../assets/refresh-cw.svg";
import { ReactComponent as Search } from "../../../../assets/search-icon.svg";

import { camelCaseToString } from "../../../../helpers/";
import Api from "../../../../helpers/api";
import { CircularProgress } from "@material-ui/core";
import { objectUrlEncodeToBase64 } from "../../../../helpers";

import { getTrackerHTML } from "../../../../data-room-panel/helpers/fileViewer";
import ApiKyc from "./api";
import theme from "models/themes";

export const UserName = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 135%;
  color: #181818;
  margin-left: 25px;
`;

export const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: ${({ width }) => (width ? `${width}%` : "100%")};
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Container = styled.div`
  height: 42px;
  display: flex;
  border: 1px solid #efefef;
  box-sizing: border-box;
  border-radius: 4px;
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0;
  }
`;

const Description = styled.div`
  display: flex;
  align-items: center;
  padding: 0 20px;
  width: calc(100% - 71px);
  border-right: 1px solid #efefef;
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 135%;
  letter-spacing: -0.02em;
  color: #181818;
`;

const Value = styled.div`
  width: 72px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Button = styled.div`
  width: 54px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  background: ${({ yes }) => (yes ? "#FF4343" : "#55B17C")};
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 150%;
  /* identical to box height, or 15px */

  text-transform: uppercase;

  color: #ffffff;
  > svg {
    margin-right: 7px;
  }
`;

export const Item = ({ name, value }) => {
  return (
    <Container>
      <Description>
        {camelCaseToString(name).toLowerCase().charAt(0).charAt(0).toUpperCase() +
          camelCaseToString(name).toLowerCase().slice(1)}
        ?
      </Description>
      <Value>
        <Button yes={value}>
          {value ? (
            <Fragment>
              <X /> YES
            </Fragment>
          ) : (
            <Fragment>
              <Check /> NO
            </Fragment>
          )}
        </Button>
      </Value>
    </Container>
  );
};

const DocContainer = styled.div`
  width: 100%;
  height: 42px;
  background: ${({ theme: { quoteBgInvestor } }) => quoteBgInvestor};
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 0 17px;
  box-sizing: border-box;
  margin-bottom: 8px;
  &:last-child {
    margin-bottom: 0;
  }
  > svg {
    cursor: pointer;
    path {
      stroke: ${({ theme: { primary } }) => primary};
    }
    &:first-child {
      margin-right: 6px;
    }
    &:last-child {
      margin-left: ${({ isPdf }) => (isPdf ? "17px" : "auto")};
    }
  }
  > div {
    font-family: Muli;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 135%;
    letter-spacing: -0.02em;
    color: #181818;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 80%;
  }
`;

const DocName = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 135%;
  letter-spacing: -0.02em;
  color: #181818;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 56% !important;
`;

const DocDate = styled.span`
  font-family: Muli;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 135%;
  letter-spacing: -0.02em;
  color: #181818;
  margin-left: auto;
`;

export const Document = ({ file, token, isPdf = false }) => {
  const { fileNameWithExtension, secureHash } = file;

  const downLoadFile = () => {
    Api.getAttachment(token, secureHash, fileNameWithExtension).then((blob) => {
      const link = document.createElement("a");
      link.href = blob;
      link.download = fileNameWithExtension;
      link.click();
    });
  };

  const viewFile = async () => {
    const file = await Api.getAttachment(token, secureHash, fileNameWithExtension);

    const win = window.open("", "_blank");
    const pdf = await objectUrlEncodeToBase64(file);

    setTimeout(() => {
      win.document.write(`
          <html>
            <head>
              <title>${fileNameWithExtension}</title>
            </head>
            <body style="margin:0">
              <embed 
                width="100%" 
                height="100%" 
                src="data:application/pdf;base64,${pdf}" 
                type="application/pdf" />
            </body>
          </html>
        `);
    }, 1000);
  };

  return (
    <DocContainer isPdf={isPdf}>
      <File />
      <div>{fileNameWithExtension}</div>
      {isPdf && <Eye style={{ marginLeft: "auto" }} onClick={viewFile} />}
      <Download onClick={downLoadFile} />
    </DocContainer>
  );
};

export const DocuSignDoc = ({
  file,
  token,
  isPdf = false,
  doc,
  assetId,
  userId,
  newWorkflow,
  onError = console.log,
}) => {
  const { envelopeId, date } = doc;
  const documentName = `${doc.documentName}`.replace(/\~.+$/, ".pdf");

  const downLoadFile = async () => {
    let file;
    if (newWorkflow) {
      file = await Api.getSignedInvestorDocument(token, assetId, userId, doc.documentName).catch(onError);
    } else {
      file = await Api.downloadDocument(token, envelopeId).catch(onError);
    }
    if (file) {
      const link = document.createElement("a");
      link.href = URL.createObjectURL(file);
      link.download = documentName;
      link.click();
    }
  };

  const viewFile = async () => {
    if (newWorkflow) {
      const link = await Api.getSignedDocDirLInk(token, assetId, userId, doc.documentName).catch(onError);
      if (link) {
        window.open(link, "_blank");
        // getTrackerHTML(token, documentName, `Legal Documents/${documentName}?assetId=${assetId}`, false, true, true);
      }
    } else {
      getTrackerHTML(token, documentName, envelopeId, true);
    }
  };

  return (
    <DocContainer isPdf={isPdf}>
      <File />
      <DocName>{documentName}</DocName>
      <DocDate>{moment(date).format("DD.MM.YYYY")}</DocDate>
      {isPdf && <Eye style={{ marginLeft: 17 }} onClick={viewFile} />}
      <Download onClick={downLoadFile} />
    </DocContainer>
  );
};

const statuses = {
  PROCESSING: {
    text: "Processing",
    color: theme.primary,
    icon: <Refresh />,
  },
  MISSING: { text: "Missing", color: "#CACACA", icon: <Search /> },
  APPROVED: { text: "Approved", color: "#55B17C", icon: <Check /> },
  REJECTED: { text: "Rejected", color: "#FF4343", icon: <X /> },
  DRAFT: {
    text: "Draft",
    color: theme.primary,
    icon: <Refresh />,
  },
  ALL: {
    text: "All",
    color: theme.primary,
    icon: <Refresh />,
  },
};

const StatusRow = styled.div`
  display: flex;
  align-items: center;
  font-family: Muli;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  color: ${({ color }) => color};
  > svg {
    width: 10px;
    height: 10px;
    margin-left: 7px;
    path {
      stroke: ${({ color }) => color};
    }
  }
`;

export const Status = ({ status }) => {
  return (
    <StatusRow color={statuses[status].color}>
      {statuses[status].text}
      {statuses[status].icon}
    </StatusRow>
  );
};

const WaitingWrapper = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.1);
  z-index: 1001;
`;

export const Loading = () => {
  return (
    <WaitingWrapper>
      <CircularProgress />
    </WaitingWrapper>
  );
};

export const FieldBlock = styled.div`
  border-bottom: 1px solid #efefef;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 0 0 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  &:last-child {
    margin-bottom: 0;
    padding: 0;
    border-bottom: none;
  }
`;

export const Label = styled.h6`
  font-size: 10px;
  line-height: 135%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: rgba(24, 24, 24, 0.6);
  margin: 0 0 4px;
`;

export const Val = styled.div`
  font-size: 14px;
  line-height: 150%;
  color: #333333;
`;

const AttachWrapper = styled.div`
  height: 42px;
  width: 100%;
  background: ${({ theme: { quoteBgInvestor } }) => quoteBgInvestor};
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 0 17px;
  box-sizing: border-box;
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0;
  }
  > svg {
    cursor: pointer;
    path {
      stroke: ${({ theme: { primary } }) => primary};
    }
    &:first-child {
      width: 24px;
      height: 24px;
      margin-right: 10px;
    }
    &:last-child {
      margin-left: ${({ isPdf }) => (isPdf ? "17px" : "auto")};
      width: 16px;
      height: 16px;
    }
  }
  > div {
    font-family: Muli;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 135%;
    letter-spacing: -0.02em;
    color: #181818;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: calc(100% - 75px);
  }
`;

export const Attachment = ({ file, token, userId }) => {
  const [wait, setWait] = useState(false);

  const onDownload = async () => {
    setWait(true);
    const link = document.createElement("a");
    const blob = await ApiKyc.downloadFile(token, userId, file.uuid, file.fileName, 1111111).catch(() =>
      setWait(false)
    );
    link.href = blob;
    link.download = file.fileName;
    link.click();
    setWait(false);
  };

  const onOpen = () => {
    getTrackerHTML(
      token,
      file.fileName,
      `Attachments~${userId}/${file.uuid}_${file.fileName}?assetId=1111111`,
      false,
      true,
      false,
      false
    );
  };
  return (
    <AttachWrapper>
      <File />
      <div>{file.fileName}</div>
      {(file.fileName.includes("pdf") ||
        file.fileName.includes("png") ||
        file.fileName.includes("jpg") ||
        file.fileName.includes("jpeg")) && <Eye style={{ width: 16, height: 16 }} onClick={onOpen} />}

      <Download onClick={wait ? undefined : onDownload} />
    </AttachWrapper>
  );
};

export const LoadWrapper = styled.div`
  width: calc(100vw - 233px);
  height: calc(100vh - 70px);
  position: fixed;
  background-color: #0000000d;
  top: 71px;
  left: 234px;
  z-index: 900;
  display: flex;
`;

const FieldName = styled.div`
  height: 42px;
  width: 100%;
  background: ${({ theme: { disabledField } }) => disabledField};
  border: 1px solid #efefef;
  box-sizing: border-box;
  border-radius: 4px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const FNumber = styled.div`
  height: 100%;
  width: 42px;
  background: #f5f5f5;
  display: flex;
  box-sizing: border-box;
  > span {
    display: block;
    margin: auto;
    font-size: 12px;
    line-height: 135%;
    letter-spacing: -0.02em;
    color: #333333;
  }
`;

const Type = styled.div`
  height: 100%;
  width: 160px;
  background: #f5f5f5;
  display: flex;
  padding: 0 18px;
  align-items: center;
  box-sizing: border-box;
  > svg {
    margin-right: 10px;
  }
  > span {
    font-size: 12px;
    letter-spacing: -0.02em;
    color: #181818;
  }
`;

const Name = styled.div`
  padding: 0 16px;
  width: calc(100% - 42px);
  box-sizing: border-box;
  font-size: 12px;
  line-height: 135%;
  letter-spacing: -0.02em;
  color: #333333;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const SimpleValue = styled.div`
  min-height: 42px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #efefef;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 10px 16px;
  font-size: 12px;
  line-height: 135%;
  letter-spacing: -0.02em;
  color: #333333;
  display: flex;
  align-items: center;
`;

const Option = styled(SimpleValue)`
  border-color: ${({ correct = false }) => (correct ? "#019D52" : "#FF4343")};
  margin-bottom: 10px;
  > p {
    margin: 0;
    width: calc(100% - 28px);
  }
  > svg {
    width: 16px;
    height: 16px;
    margin-right: 13px;
    path {
      stroke: ${({ correct = false }) => (correct ? "#019D52" : "#FF4343")};
    }
  }
  &:last-child {
    margin-bottom: 0;
  }
`;

export const Field = ({
  field: { name, id, value, visibility, values, options, attachments, type },
  rightAnswers = {},
  token,
  userId,
  i,
}) => {
  return visibility ? (
    <FieldBlock>
      <FieldName>
        <FNumber>
          <span>{i + 1}.</span>
        </FNumber>
        <Name>{name}</Name>
      </FieldName>
      {value ? <SimpleValue>{value}</SimpleValue> : null}
      {attachments.map((file) => (
        <Attachment token={token} userId={userId} key={file.uuid} file={file} />
      ))}
      {values.length
        ? options.map(({ id: optionId, value }) => {
            const correct = rightAnswers[id] && rightAnswers[id].includes(optionId);
            return values.includes(optionId) ? (
              <Option key={optionId} correct={correct}>
                {correct ? <Check /> : <X />}
                <p>{value}</p>
              </Option>
            ) : null;
          })
        : null}
    </FieldBlock>
  ) : null;
};

export const AmName = styled.span`
  font-size: 14px;
  line-height: 150%;
  color: ${({ theme: { primary } }) => primary};
  border-bottom: 0.5px solid rgba(98, 128, 218, 0.2);
  width: fit-content;
  display: inline-block;
`;
