import styled from "styled-components";
import React, { useState, Fragment, useEffect } from "react";

import Api from "../../../../helpers/api";
import { ContainerFlex, DocSelect, DocOptions } from "../../containers/container";
import { ReactComponent as EmailAllUsers } from "../../../../assets/email-all-users.svg";
import { ReactComponent as MoreIcon } from "../../../../assets/more-vertical.svg";
import { UserIcon } from "components/common/icons-primary";

import theme from "models/themes";

export const Header = styled.div`
  width: 100%;
  height: ${({ height = 60 }) => height}px;
  border-radius: 4px 0px 4px 4px;
  background: white;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: ${({ padding }) => (padding ? padding : "0 25px")};
  ${({ between = false }) => (between ? "justify-content: space-between;" : "")}
`;

export const TextH3 = styled.h3`
  text-align: ${({ textAlign = "center" }) => textAlign};
  font-family: "Muli", sans-serif;
  font-size: ${({ size }) => (size ? `${size}px` : "14px")};
  font-weight: bold;
  ${({ marginSize = "" }) => (marginSize ? `margin: ${marginSize};` : "")}
`;

export const Separator = styled.div`
  height: 25px;
`;

export const SeparatorLine = styled.div`
  margin-bottom: ${({ mBottom }) => (mBottom ? mBottom : "0")};
  height: 0px;
  border: 1px solid #f6f7fc;
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const Card = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 135%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: rgba(24, 24, 24, 0.6);
  background: #ffffff;
  border-radius: 4px;
  padding: 20px 25px;
  width: ${({ width }) => (width ? width : "100%")};
  flex-direction: ${({ flexDirection }) => (flexDirection ? flexDirection : "column")};
  display: flex;
  align-items: ${({ alignItems }) => (alignItems ? alignItems : "")};
  justify-content: ${({ justify = "space-between" }) => justify};
  height: ${({ height }) => (height ? height : "72px")};
  box-sizing: border-box;
  padding-bottom: ${({ paddingBottom }) => (paddingBottom ? `${paddingBottom}px` : "20px")};
`;

export const Title = styled.span`
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 135%;
  color: #181818;
  text-transform: none;
`;

export const ColInCard = styled.div`
  overflow: ${({ chart }) => (chart ? "hidden" : "")};
  top: ${({ chart }) => (chart ? "-20px" : "")};
  flex-direction: column;
  display: flex;
  width: 100%;
`;

export const InfoBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: ${({ mRight = 29 }) => `${mRight}px`};
  &:last-child {
    margin-right: 0;
  }
  > span {
    margin-bottom: 4px;
  }
  > h3 {
    display: ${({ disp = "flex" }) => disp};
    width: 65px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin: 0;
    font-size: 14px;
    color: #181818;
    line-height: 135%;
    letter-spacing: 0.05em;
    text-transform: uppercase;
  }
`;

export const InfoHeader = styled.div`
  margin-bottom: 4px;
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 135%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: rgba(24, 24, 24, 0.6);
`;

export const Main = styled.section`
  position: relative;
  height: ${({ isClosed, currentHeight }) => (isClosed ? "57px" : currentHeight)};
  min-height: ${({ minHeight }) => (minHeight ? `${minHeight}px` : 0)};
  width: ${({ width }) => (width ? width : "100%")};
  overflow: ${({ noHidden }) => (noHidden ? "visible" : "hidden")};
  box-sizing: border-box;
  background: white;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  padding: ${({ padding }) => (padding ? padding : "20px 25px 25px 25px")};
  transition: 0.2s height linear;
`;

export const TextSub = styled.p`
  text-transform: none;
  width: ${({ fullWidth }) => (fullWidth ? "100%" : "auto")};
  cursor: ${({ fullWidth }) => (fullWidth ? "pointer" : "auto")};
  text-align: ${({ textAlign = "center" }) => textAlign};
  font-family: "Muli", sans-serif;
  font-size: ${({ fSize }) => (fSize ? `${fSize}px` : "14px")};
  display: inline-block;
  font-weight: ${({ fontWeight = "normal" }) => fontWeight};
  color: ${({ color = "#CACACA" }) => color};
  ${({ marginSize = "" }) => (marginSize ? `margin: ${marginSize};` : "")}
`;

export const FlexRow = styled.div`
  z-index: ${({ zIndex }) => (zIndex ? zIndex : "")};
  position: ${({ relative }) => (relative ? "relative" : "")};
  top: ${({ top }) => (top ? `${top}px` : "")};
  flex-direction: ${({ direction }) => (direction ? direction : "center")};
  width: ${({ width }) => (width ? `${width}px` : "100%")};
  height: ${({ height }) => (height ? `${height}px` : "")};
  display: flex;
  align-items: ${({ align }) => (align ? align : "center")};
  justify-content: ${({ justify }) => (justify ? justify : "space-between")};
  margin-bottom: ${({ mBottom }) => (mBottom ? `${mBottom}px` : "30px")};
`;

export const Stage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: ${({ height = 24 }) => `${height}px`};
  border-radius: 2px;
  box-sizing: border-box;
  padding: 0 10px;
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 150%;
  text-transform: uppercase;
  letter-spacing: 0.1px;
  color: #ffffff;
  background: ${({ type }) =>
    type === "UPSTREAM"
      ? theme.primaryLight
      : type === "STUDY"
      ? theme.primaryMiddle
      : type === "APPROVED"
      ? "#3F4B78"
      : type === "INVESTED"
      ? "#55B17C"
      : "#fff"};
`;

export const ButtonsBlock = styled.div`
  display: flex;
  justify-content: space-between;
  width: 136px;
  padding-right: 20px;
`;

export const Others = styled.div`
  text-transform: none;
  display: flex;
  color: ${({ theme: { primary } }) => primary};
  border-bottom: 1px dashed rgba(98, 128, 218, 0.2);
  cursor: pointer;
  position: relative;
  > svg {
    width: 20px;
    height: 16px;
    margin-right: 5px;
  }
  > .other-modal {
    display: none;
    flex-direction: column;
    width: 235px;
    background: #ffffff;
    border: 1px solid #efefef;
    box-sizing: border-box;
    box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.05);
    border-radius: 4px;
    min-height: 50px;
    position: absolute;
    bottom: 22px;
    left: -50px;
    padding: 12px 14px;
    &:before {
      content: "";
      display: block;
      position: absolute;
      bottom: -7px;
      left: 50%;
      width: 16px;
      height: 16px;
      background: #ffffff;
      border: 1px solid #edebeb;
      box-sizing: border-box;
      box-shadow: 0px 10px 40px rgba(89, 120, 150, 0.06);
      border-radius: 4px;
      transform: rotate(45deg);
      z-index: 3;
    }
    &:after {
      content: "";
      display: block;
      position: absolute;
      width: 25px;
      background: #ffffff;
      bottom: 0;
      height: 12px;
      left: 48%;
      z-index: 4;
    }
  }
  :hover {
    > div {
      display: flex;
    }
  }
`;

export const ValueField = styled.div`
  color: rgba(24, 24, 24, 0.5);
  text-transform: none;
`;

export const CountryRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-family: Muli;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 135%;
  margin-bottom: 10px;
  color: #181818;
  &:last-child {
    margin-bottom: 0;
  }
  > span {
    display: flex;
    align-items: center;
    text-transform: none;
    > svg {
      width: 20px;
      height: 10px;
      margin-right: 5px;
    }
  }
`;

export const FlagWrapper = styled.div`
  display: flex;
`;

export const FlagHolder = styled.div`
  display: inline;
  > svg {
    width: 20px;
    height: 18px;
    padding-right: 5px;
  }
`;

export const ButtonsRow = styled.div`
  width: 166px;
  justify-content: space-between;
  display: flex;
`;

const TextInChart = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 60px;
`;

const ChartRow = styled.div`
  color: ${({ colorText }) => (colorText ? colorText : "#181818")};
  display: flex;
  align-items: center;
  font-family: Muli;
  font-weight: 600;
  font-size: 10px;
  line-height: 135%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  margin-bottom: 8px;
  > span {
    cursor: pointer;
    text-transform: none;
    position: relative;
    &:after {
      content: "";
      bottom: -2px;
      left: 0;
      position: absolute;
      width: 100%;
      border-bottom: 1px dashed rgba(98, 128, 218, 0.2);
    }
  }
  &:last-child {
    margin-bottom: 0;
  }
`;

const Cirlcle = styled.div`
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: ${({ color }) => color};
  margin-left: 6px;
  margin-right: ${({ mRight }) => (mRight ? `${mRight}px` : "0")};
`;

export const ChartData = ({ otherCountries, onlyThreeCountries, firstThreeCountries, colors }) => {
  return (
    <Fragment>
      {onlyThreeCountries.length
        ? onlyThreeCountries.map(({ country, countryCode }, id) => {
            return (
              <ChartRow key={`row-${country}`}>
                <Cirlcle mRight={7} color={colors(id)} />
                {countryCode}
              </ChartRow>
            );
          })
        : firstThreeCountries.map(({ country, countryCode }, id) => {
            return (
              <ChartRow key={`row-${country}`}>
                <Cirlcle mRight={7} color={colors(id)} />
                <TextInChart>{countryCode}</TextInChart>
              </ChartRow>
            );
          })}
      {otherCountries.length ? (
        <ChartRow colorText={theme.primary}>
          <Cirlcle mRight={7} color="#6371A4" />
          <span>Other</span>
        </ChartRow>
      ) : null}
    </Fragment>
  );
};

export const SelectFilter = ({
  onChange,
  width,
  placeholder,
  value,
  values = [],
  multiple = false,
  defaultValue,
  margin,
}) => {
  return (
    <ContainerFlex marginSize={margin} w={width}>
      <DocSelect
        height={32}
        arrowPosition={10}
        padding="0 10px"
        multiple={multiple}
        arrow={`/assets/chevron-down${theme.greyFilter ? "-dark" : ""}.svg`}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        defaultValue={defaultValue}
      >
        {defaultValue ? null : (
          <DocOptions selected disabled>
            none
          </DocOptions>
        )}
        {values.map((e) => (
          <DocOptions key={e} value={e} selected={e === defaultValue}>
            {e}
          </DocOptions>
        ))}
      </DocSelect>
    </ContainerFlex>
  );
};

export const SepareteLine = styled.div`
  height: 20px;
  border-top: 1px solid #f6f7fc;
  width: calc(100% + 50px);
  transform: translateX(-25px);
  margin-top: auto;
`;

export const ScrollContainer = styled.div`
  margin-bottom: ${({ isScroll }) => (isScroll ? "15px" : "5px")};
  max-height: ${({ maxHeight }) => (maxHeight ? `${maxHeight}px` : "500px")};
  min-height: ${({ minHeight }) => (minHeight ? `${minHeight}px` : 0)};
  padding-right: ${({ isScroll }) => (isScroll ? "5px" : "0")};
  box-sizing: border-box;
  margin-left: ${({ noTranslate }) => (noTranslate ? 0 : "-15px")};
  width: calc(100% + 15px);
  overflow-y: ${({ isScroll }) => (isScroll ? "scroll" : "hidden")};
  &&::-webkit-scrollbar-track {
    background-color: ${({ theme: { primaryLight } }) => primaryLight};
    border-radius: 2px;
  }
  &&::-webkit-scrollbar {
    width: 8px;
    border-radius: 2px;
    background-color: ${({ theme: { primaryLight } }) => primaryLight};
  }
  &&::-webkit-scrollbar-thumb {
    background-color: ${({ theme: { primaryMiddle } }) => primaryMiddle};
    border-radius: 2px;
  }
`;

const Triangle = styled.div`
  position: absolute;
  top: ${({ top = -19 }) => `${top}px`};
  /* top: -19px; */
  width: 32px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #edebeb;
  box-sizing: border-box;
  box-shadow: 0px 10px 40px rgba(89, 120, 150, 0.06);
  border-radius: 4px;
  transform: rotate(45deg);
`;

const WhiteBlock = styled.div`
  z-index: ${({ zIndex }) => zIndex};
  top: ${({ top = -24 }) => `${top}px`};
  bottom: 24px;
  width: ${({ width = 43 }) => `${width}px`};
  /* width: 43px; */
  height: ${({ height = 24 }) => `${height}px`};
  background: #fff;
  position: absolute;
`;

const ExtraInfo = styled.div`
  display: none;
  position: absolute;
  z-index: ${({ zIndex }) => zIndex};
  height: ${({ height = 135 }) => `${height}px`};
  width: ${({ width = 300 }) => `${width}px`};
  left: -143px;
  top: ${({ top = -128 }) => `${top}px`};
  bottom: ${({ bottom }) => `${bottom}px`};
  background: #ffffff;
  border: 1px solid #edebeb;
  box-sizing: border-box;
  box-shadow: 0px 10px 40px rgba(89, 120, 150, 0.06);
  border-radius: 4px;
  padding: 12px 20px;
  flex-direction: column;
  box-sizing: border-box;
`;

export const ExtraLink = styled.div`
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 135%;
  display: flex;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  align-items: center;
  color: ${({ red }) => (red ? "rgb(255, 67, 67)" : "#181818")};
  text-decoration: none;
  > svg {
    margin-right: 7px;
    width: 9px;
    height: 9px;
    path {
      stroke: ${({ red, theme: { primary } }) => (red ? "rgb(255,67,67)" : primary)};
    }
  }
`;

export const UserRow = styled.div`
  margin-bottom: 20px;
  display: flex;
  height: 190px;
  width: 100%;
`;

export const UserRowInStat = styled.div`
  margin-bottom: 20px;
  display: flex;
  /* height: 190px; */
  width: 100%;
`;

export const AvatarCol = styled.div`
  /* padding-left:20px; */
  display: flex;
  width: 107px;
`;

export const InfoCol = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: calc(100% - 107px);
  box-sizing: border-box;
  margin-left: 25px;
`;

export const InfoRow = styled.div`
  display: flex;
  justify-content: ${({ content }) => (content ? content : "space-between")};
  font-family: Muli;
  font-style: normal;
  font-weight: bold;
  color: rgba(24, 24, 24, 0.6);
  letter-spacing: 0.05em;
  text-transform: uppercase;
  margin-bottom: 20px;
  width: 273px;
`;

export const AvatarBlock = ({ token, file, hash }) => {
  const [avatar, setAvatar] = useState(null);
  useEffect(() => {
    if (!avatar && file && hash) {
      Api.getAttachment(token, hash, file).then((blob) => setAvatar(blob || Api.defaultAvatar));
    }
  }, []);
  return (
    <GreyBlock>
      {!avatar && file && hash ? null : avatar ? <Avatar src={avatar} alt="avatar" /> : <UserIcon />}
    </GreyBlock>
  );
};

const GreyBlock = styled.div`
  height: 96px;
  background: #e2e3e8;
  border-radius: 4px;
  position: relative;
  > svg {
    width: 62px;
    height: 62px;
    position: absolute;
    z-index: 20;
    top: 0px;
    left: 25px;
  }
`;

const Avatar = styled.img`
  width: 62px;
  height: 62px;
  border-radius: 50%;
  position: absolute;
  z-index: 20;
  top: 0px;
  left: 25px;
  object-fit: cover;
`;

export const UserStatisticModalHeader = styled.div`
  height: ${({ isFooter = false }) => (isFooter ? "auto" : "52px")};
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${({ mBottom = false }) => (mBottom ? mBottom : "0")};
  margin-top: ${({ isFooter = false }) => (isFooter ? "15px" : "0")};
  > svg {
    cursor: pointer;
    height: 24px;
    width: 24px;
  }
`;

export const UserStatisticWraper = styled.div`
  position: relative;
  width: 440px;
  background: #ffffff;
  border: 1px solid #f9f9f9;
  box-sizing: border-box;
  box-shadow: 0px 10px 40px rgba(89, 120, 150, 0.06);
  border-radius: 4px;
  padding: 0px 25px 15px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
`;

export const StatWrapper = styled.div`
  transform: translateX(10px);
  width: 96%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const EmailTD = styled.div`
  text-transform: none;
  text-overflow: ellipsis;
  box-sizing: ${({ boxSizing }) => boxSizing};
  font-family: Muli;
  font-weight: normal;
  font-size: 12px;
  line-height: 150%;
  color: ${({ theme: { primary } }) => primary};
  white-space: nowrap;
  overflow: hidden;
  padding: ${({ padding = "0 25px 0 0" }) => padding};
  width: ${({ width }) => (width ? width : "11%")};
`;

const Btn = styled.button`
  position: relative;
  white-space: nowrap;
  padding-bottom: 0;
  padding-top: 0;
  width: ${({ width, fullWidth }) => (width ? `${width}px` : fullWidth ? "100%" : "94px")};
  border: 1px solid
    ${({ black, hide, error, green, theme: { primary } }) =>
      black ? "#20263C" : hide ? "#CACACA" : error ? "#FF4343" : green ? "#55B17C" : primary};
  height: ${({ height }) => (height ? `${height}px` : "32px")};
  background: ${({ black, white, hide, transparent, error, green, theme: { primary } }) =>
    black ? "#20263C" : white || hide ? "#fff" : transparent || error || green ? "transparent" : primary};
  color: ${({ white, hide, transparent, error, green, theme: { primary } }) =>
    white || transparent ? primary : hide ? "#CACACA" : error ? "#FF4343" : green ? "#55B17C" : "#fff"};
  font-size: 10px;
  line-height: 135%;
  text-align: center;
  font-weight: bold;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  border-radius: 4px;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: ${({ margin }) => (margin ? margin : "0")};
  transition: all 0.3s linear;
  &:hover {
    background: ${({ theme: { primary } }) => primary};
    > svg {
      path {
        stroke: #fff;
      }
    }
    > div {
      display: flex;
      &:before {
        content: "";
        display: block;
        position: absolute;
        bottom: ${({ bottom = "-7px" }) => bottom};
        left: 73%;
        width: 20px;
        height: 20px;
        background: #ffffff;
        border: 1px solid #edebeb;
        box-sizing: border-box;
        box-shadow: 0px 10px 40px rgba(89, 120, 150, 0.06);
        border-radius: 4px;
        transform: rotate(45deg);
        z-index: 3;
      }
      &:after {
        content: "";
        display: block;
        position: absolute;
        width: 30px;
        background: #ffffff;
        bottom: ${({ bottomAfter = "0px" }) => bottomAfter};
        height: 16px;
        left: 70%;
        z-index: 4;
      }
    }
  }
  &:disabled {
    /* opacity: 0.5; */
    background: #e3e3e3 !important;
    color: white !important;
    border: none;
    > svg {
      path {
        stroke: #fff;
      }
    }
  }
  &:focus {
    background: ${({ black, white, hide, transparent, error, green, theme: { primary } }) =>
      black ? "#20263C" : white || hide ? "#fff" : transparent || error || green ? "transparent" : primary};
  }
  > svg {
    margin: ${({ rightIcon }) => (rightIcon ? "0 0 0 6px " : "0 6px 0 0")};
    transform: ${({ transform }) => (transform ? "rotate(180deg)" : "none")};
    transition: all 0.3s linear;

    path {
      stroke: ${({ transparent, error, green, theme: { primary } }) =>
        transparent ? primary : error ? "#FF4343" : green ? "#55B17C" : "#fff"};
    }
  }
`;

export const DotsButton = ({ fullName, email, toggleModalSendEmail, row }) => {
  return (
    <Fragment>
      <Btn
        transparent
        width={26}
        height={26}
        bottom={row === 0 ? "25px" : "-7px"}
        bottomAfter={row === 0 ? "22px" : "0px"}
      >
        <MoreIcon style={{ marginRight: 0 }} />
        <Fragment>
          <ExtraInfo
            height={40}
            zIndex={row === 0 ? 1 : 0}
            top={row === 0 ? 20 : ""}
            bottom={row === 0 ? "" : 20}
            width={200}
            onClick={(e) => e.stopPropagation()}
          >
            <ExtraLink onClick={() => toggleModalSendEmail({ userName: fullName, userEmail: email })}>
              <EmailAllUsers /> Email to user
            </ExtraLink>
          </ExtraInfo>
        </Fragment>
      </Btn>
    </Fragment>
  );
};

export const NoFlag = styled.div`
  width: 20px;
  height: 10px;
  margin-right: 5px;
  background: transparent;
  border: 1px solid grey;
  box-sizing: border-box;
  border-radius: 1px;
`;
