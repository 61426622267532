import { createAction } from "redux-act";
import Api from "../../helpers/api";

export const setChatMessages = createAction("SET CHAT MESSAGES");
export const setUnreadMessages = createAction("SET UNREAD MESSAGES");
export const setCurrentChatId = createAction("SET UNREAD MESSAGES");

export const setToAssetMessage = createAction("GET MESSAGE FROM BACK END");
export const requestToAssetMessage = (token) => async (dispatch) => {
  await Api.requestAssetMessage(token);
};

export const getArchiveMessage = (token, assetId, userName = "") => async (
  dispatch,
  getState
) => {
  let len = (getState().chatReducer.assetMultiChat[assetId] || []).length;
  let total = getState().chatReducer.unreadMessages.total || 0;
  console.log(len);
  await Api.updateChatMessages(token, 1, 10, assetId, userName);

  if (len >= 10) {
    await Api.updateChatMessages(
      token,
      Math.ceil(len / 10) + (len % 10 === 0 ? 1 : 0),
      10,
      assetId,
      userName
    );
  }

  if (total !== 0) {
    await Api.requestAssetMessage(token);
  }
};

export const clearMessages = createAction("CLEAR MESSAGES");

export const setChatThemes = createAction("SET CHAT THEMES");
export const setAdminChatThemes = createAction("SET ADMIN CHAT THEMES");
export const setSocketConnectionStatus = createAction(
  "SET SOCKET CONNECTION STATUS"
);
