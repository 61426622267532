export default {
  pageName: "Terms of Use",
  preText:
    'These Terms of Use constitute a legal agreement exclusively between you, as the user of the Website, and Glafka Capital Limited. This Website is operated under license from VOC Corp Ltd (“VALK” or the “Technology Provider”). Throughout these Terms of Use we will refer to you as "you" or "user", and we will refer to Glafka Capital Limited as "we", "us" or " Glafka Capital ". ',
  content: [
    {
      heading: `<span>1.</span> Use of the Website`,
      text: `These Terms of Use set out the general terms governing your use of the Website. They apply to you as soon as you first use the Website, and you are deemed to have agreed to be bound by them upon your first use of the Website. If you do not wish to continue to be bound by these Terms of Use, please stop using the Website now. 
      <br/>
      <br/>
          Access to the Website is permitted on a temporary basis, and we reserve the right to withdraw or amend the service we provide on this Website without notice. We will not be liable if for any reason our site is unavailable at any time or for any period.
      <br/>
      <br/>    
          We aim to update the Website regularly and may change the content at any time. If the need arises, we may suspend access to the Website, or close it indefinitely.  `,
    },
    {
      heading: `<span>2.</span> Data Protection and Security`,
      text: `We process personal data in accordance with our Privacy Policy. You should read the contents of our Privacy Policy carefully, especially if you have any concerns about your privacy. By using this Website, you consent to such processing and you warrant that all data provided by you is accurate.
      <br/><br/>
        The services offered in the Website may require you to open an account (including setting up a username and password). You are entirely responsible for maintaining the confidentiality of the information you hold for your account, including your password, and for any and all activity that occurs under your account as a result of your failing to keep this information secure and confidential. You agree to notify us immediately of any unauthorised use of your account or password, or any other breach of security. 
      <br/><br/>      
        You may not use anyone else’s account at any time. We cannot and will not be liable for any loss or damage arising from your failure to comply with these obligations.
      <br/><br/>      
        You authorise us to use any of your personal information which is relevant to the provision of our services to you for all reasonable purposes in relation to your use of the Website. We may retain and continue to process your personal information after the termination of this agreement or any other agreement between you and us. Your personal information may be transferred or disclosed to and/or by third parties where necessary and to the extent permitted by law. This enables us to provide services to you and to discharge our obligations to third parties, including relevant stock exchanges, government agencies and regulators.
      <br/><br/>      
        With your consent, your personal information may be used by us in order to provide you with information and marketing materials in relation to our other products and services. By entering into this agreement, you confirm your wish for your personal information to be processed for these additional purposes. If you do not wish to receive marketing information please advise us in writing.`,
    },
    {
      heading: `<span>3.</span> Other Agreements`,
      text: `During the course of your use of the Website, you may choose to agree to be bound by certain other agreements. For example, if you wish to sign up to this Website and invest in a business through the Website, you will need to agree to our Investor Terms and to the relevant Subscription Agreement. We refer to all of these other agreements as the "Website Agreements", because rather than just governing your technical use of the Website, they set out the substantive terms that govern your relationship with us when you make use of the Website. 
      <br/><br/>
        These Terms of Use will continue to apply to you even after you have agreed to the Website Agreements. However, if it turns out that there is a conflict between the Website Agreements and these Terms of Use that you agree to, the Terms of Use will take precedence. `,
    },
    {
      heading: `<span>4.</span> Intellectual Property Rights`,
      text: `We are the owner or the licensee (as the case may be) of all intellectual property rights in the Website, and in the material published on it.
       Those works are protected by copyright laws and treaties around the world. All such rights are reserved. <br/><br/>

      The Website may include at any given time text, graphics, logos, icons, images, sound clips, video clips and data compilations, and together with the
       Website's page layout, underlying code and software we refer to all of these as "Content". <br/><br/>
      
      All of the Content is the property of Glafka Capital Limited and VOC Corp Ltd, our affiliates or third parties with whom we do business,
       and this means that it is protected by United Kingdom and international intellectual property laws and other relevant laws. This means that,
        except as provided by Part 1, Chapter 3 of the UK Copyright, Designs and Patents Act 1988 (which contains what are generally known as the "fair use" 
        provisions for copyrighted materials), you may not reproduce, copy, distribute, store or in any other fashion re-use material from the Website unless 
        otherwise indicated on the Website or unless given express written permission to do so by Glafka Capital Limited and VOC Corp Ltd. For the avoidance
         of doubt, data mining or scraping the Website without the express written permission of Glafka Capital Limited and VOC Corp Ltd is not permitted.
      If you print off, copy or download any part of our site in breach of these Terms of Use, your right to use the Website will cease immediately and you must, 
      at our option, return or destroy any copies of the materials you have made.
      `,
    },
    {
      heading: `<span>5.</span> Links to Other Website`,
      text: `This Website may contain links to other independent third-party sites.
       Unless we expressly say otherwise, you should assume that these other websites are not
        under the control of Glafka Capital or our affiliates. We assume no responsibility for 
        the content of such websites, and we disclaim liability for any and all forms of loss or damage arising out of the use of them. 
        Additionally, even if we include a link to another website on this Website, that does not mean that we endorse that other website or 
        in any way recommend that you should use it, you will need to make your own independent judgment regarding your interaction with these web sites.`,
    },
    {
      heading: `<span>6.</span> Viruses, Hacking and other Offences`,
      text: `You must not misuse this Website by knowingly introducing viruses, trojans, worms, logic bombs or other material which is malicious or technologically harmful (together “Viruses”). You must not attempt to gain unauthorised access to our site, the server on which our site is stored, or any server, computer or database connected to this Website. You must not attack this Website via a denial-of-service attack.
      <br/><br/>
        By breaching this provision, you may commit a criminal offence under the Computer Misuse Act 1990. We will report any such breach to the relevant law enforcement authorities, and we will co-operate with those authorities by disclosing your identity to them. In the event of such a breach, your rights to use this Website will cease immediately.
      <br/><br/>      
        We will not be liable for any loss or damage caused by a denial-of-service attack or Viruses that may infect your computer equipment, computer programs, data or other proprietary material due to your use of this Website or to your downloading of any material posted on it, or on any website linked to it.`,
    },
    {
      heading: `<span>7.</span>Disclaimers`,
      text: `By using this Website, you should be aware that:
      <ul>
      <li>We make no warranty or representation (express or implied) that the Website will meet your requirements, that it will be of satisfactory quality, that it will be fit for a particular purpose, that it will not infringe the rights of third parties, that it will be compatible with all systems, that it will be secure, or that all information provided will be accurate. </li>
      <li>We make no guarantee of any specific results from the use of the Website. </li>
      <li>No part of this Website is intended to constitute advice, and the Content of this Website should not be relied upon when making any decisions or taking any action of any kind. </li>
      <li>The Website is provided "as is" and on an "as available" basis, and we give no warranty that it will be free of defects and / or faults.</li>
      <li>We accept no liability for any disruption or non-availability of the Website resulting from external causes including, but not limited to, ISP equipment failure, host equipment failure, communications network failure, power failure, natural events, acts of war, or legal restrictions and censorship. </li>
      <li>To the maximum extent permitted by law, we accept no liability for any direct or indirect loss or damage, foreseeable or otherwise, including any indirect, consequential, special or exemplary damages arising from your use of the Website or any information contained in it, and you use the Website and its Content at your own risk. This is not intended to exclude or restrict our liability for death or personal injury arising from your use of the Website. </li>
      <li>Users should be aware that they use the Website and its Content at their own risk.</li>
      </ul>`,
    },
    {
      heading: `<span>8.</span>Liability`,
      text: `The material displayed on the Website is provided without any guarantees, conditions or warranties as to its accuracy. To the extent permitted by law, we, other members of our group of companies and third parties connected to us hereby expressly exclude:
      <br/><br/>
      <ul>
      <li>All conditions, warranties and other terms which might otherwise be implied by statute, common law or the law of equity</li>
      <li>Any liability for any direct, indirect or consequential loss or damage incurred by any user in connection with our site or in connection with the use, inability to use, or results or the use of this Website, any websites linked to it and any materials posted on it, including, without limitation any liability for:
      <ul>
        <li>Loss of income or revenue;</li>
        <li>Loss of business;</li>
        <li>Loss of profits or contracts;</li>
        <li>Loss of anticipated savings;</li>
        <li>Loss of data;</li>
        <li> Loss of goodwill;</li>
        <li>Wasted management or office time; and
        for any other loss or damage of any kind, however arising and whether caused by tort (including negligence), breach of contract or otherwise, even if foreseeable.</li>
      </ul>
      </li>
      </ul>
      This does not affect our liability for death or personal injury arising from our negligence, nor our liability for fraud or fraudulent misrepresentation, nor any other liability which cannot be excluded or limited under applicable law.
      `,
    },
    {
      heading: `<span>9.</span>Severability`,
      text: `We have made every effort to ensure that these Terms of Use adhere strictly with the relevant provisions of the UK Unfair Contract Terms Act 1977 and other applicable laws. However, in the event that any of these provisions are found to be unlawful, invalid or otherwise unenforceable, that provision is to be deemed severed from these Terms of Use and shall not affect the validity and enforceability of the remaining Terms of Use. This clause on "Severability" shall apply only within jurisdictions where a particular term is illegal.`,
    },
    {
      heading: `<span>10.</span>Revisions`,
      text: `We have made every effort to ensure that these Terms of Use adhere strictly with the relevant provisions of the UK Unfair Contract Terms Act 1977 and other applicable laws. However, in the event that any of these provisions are found to be unlawful, invalid or otherwise unenforceable, that provision is to be deemed severed from these Terms of Use and shall not affect the validity and enforceability of the remaining Terms of Use. This clause on "Severability" shall apply only within jurisdictions where a particular term is illegal. `,
    },
    {
      heading: `<span>11.</span>Severability`,
      text: `From time to time we may update these Terms of Use to take account of changes in the law or for any other reason. If we update these Terms of Use, we will post a new version on the Website, and as soon as you use the Website after they are posted, you will be deemed to have agreed to the updated version, but you will still be bound by the terms of any previous versions to which you have agreed or been deemed to agree. If there is a conflict between two versions of the Terms of Use to which you have agreed or been deemed to agree, the more recent version shall take precedence unless it is expressly stated otherwise`,
    },
    {
      heading: `<span>12.</span>Communications`,
      text: `If you wish to communicate with us about anything related to these Terms of Use, you may do so by sending an email to <a>info@glafkacapital.com</a>. Please note that the webmaster can only respond to questions about these Terms of Use and any technical issues with use of the Website. Your query may be directed to the appropriate person within our team`,
    },
    {
      heading: `<span>13.</span>Complaints`,
      text: `
        If you wish to make a complaint about Glafka Capital, you may wish do so
        by sending an email to <a>info@glafkacapital.com</a>.
        We will aim to acknowledge your complaint promptly, investigate the
        circumstances and report the results to you. If we do not resolve the
        complaint to your satisfaction, you may have a right to complain directly
        to the Financial Ombudsman Service. Their address is South Quay Plaza, 183
        Marsh Wall, London E14 9SR, and their website is at
        <a href="http://www.financial-ombudsman.org.uk" target="_blank"
          >http://www.financial-ombudsman.org.uk.</a
        >
        In addition, the European Commission's Online Dispute Resolution platform
        is at
        <a href="http://ec.europa.eu/consumers/odr" target="_blank"
          >http://ec.europa.eu/consumers/odr.</a
        >
        `,
    },
    {
      heading: `<span>14.</span>Governing Law and Jurisdiction`,
      text: `You agree that all matters relating to your access to or use of the Website, including all disputes, will be governed by the laws of England and Wales without regard to its conflicts of law provisions. Any claim under these Terms of Use must be submitted to the exclusive jurisdiction of the courts of England and Wales. This means that if you want to bring a legal action against us, or we want to bring a legal action against you, it must be done in one of these courts.`,
    },
  ],
};
