import { createAction } from "redux-act";

import { isGeneral } from "helpers/envChecker";
import config from "config";
import Api from "helpers/api";

const current = process.env.REACT_APP_CURRENT;

export const setCompanyInfo = createAction("SET_COMPANY_INFO");

export const getCompanyInfo = () => async (dispatch) => {
  const link = document.getElementById("favicon");
  link.setAttribute("href", config[current].favicon || "/favicon.png");
  if (!isGeneral) {
    document.title = config[current].title || "Asset Manager";
  } else {
    const company = await Api.getCompanyInfo();
    document.title = company.companyName;
    dispatch(setCompanyInfo(company));
  }
};
