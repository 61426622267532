export const countries = {
  "Pan Africa": {
    "Sub-Saharan Africa": {
      "Eastern Africa": [
        "Burundi",
        "Comoros",
        "Djibouti",
        "Ethiopia",
        "Eritrea",
        "Kenya",
        "Madagascar",
        "Malawi",
        "Mauritius",
        "Mozambique",
        "Réunion",
        "Rwanda",
        "Seychelles",
        "Somalia",
        "Somaliland",
        "Tanzania",
        "Uganda",
        "Zambia",
        "Zimbabwe"
      ],
      "Western Africa": [
        "Benin",
        "Burkina Faso",
        "Cape Verde",
        "Côte D'Ivoire",
        "Gambia",
        "Ghana",
        "Guinea",
        "Guinea-Bissau",
        "Liberia",
        "Mali",
        "Mauritania",
        "Niger",
        "Nigeria",
        "Saint Helena",
        "Senegal",
        "Sierra Leone",
        "Togo"
      ],
      "Southern Africa": [
        "Botswana",
        "Lesotho",
        "Namibia",
        "South Africa",
        "Swaziland"
      ],
      "Northern Africa": [
        "Algeria",
        "Egypt",
        "Libya",
        "Morocco",
        "Sudan",
        "Tunisia",
        "Western Sahara"
      ],
      "Central Africa": [
        "Angola",
        "Cameroon",
        "Central African Republic",
        "Chad",
        "Congo Republic - Brazzaville",
        "Democratic Republic of Congo",
        "Equatorial Guinea",
        "Gabon",
        "São Tomé & Principe"
      ]
    }
  },
  Asia: {
    "Central Asia": [
      "Kazakhstan",
      "Kyrgyzstan",
      "Tajikistan",
      "Turkmenistan",
      "Uzbekistan"
    ],
    "Eastern Asia": [
      "China",
      "Hong Kong",
      "Japan",
      "North Korea",
      "South Korea",
      "Macau",
      "Mongolia",
      "Taiwan"
    ],
    "Southeastern Asia": [
      "Brunei",
      "Myanmar",
      "Cambodia",
      "Indonesia",
      "Laos",
      "Malaysia",
      "Philippines",
      "Singapore",
      "Thailand",
      "Timor-Leste",
      "Vietnam"
    ],
    "Southern Asia": [
      "Afghanistan",
      "Bangladesh",
      "Bhutan",
      "India",
      "Maldives",
      "Nepal",
      "Pakistan",
      "Sri Lanka"
    ],
    "Western Asia": [
      "Bahrain",
      "Cyprus",
      "Iran",
      "Iraq",
      "Israel",
      "Jordan",
      "Kuwait",
      "Lebanon",
      "Oman",
      "State of Palestine",
      "Qatar",
      "Saudi Arabia",
      "Syria",
      "United Arab Emirates",
      "Yemen"
    ]
  },
  "The Caribbean": [
    "Guadeloupe",
    "Haiti",
    "Martinique",
    "Saint Barthelemy",
    "French Guiana",
    "Saint-Martin"
  ],
  "Central America": [
    "Belize",
    "Costa Rica",
    "El Salvador",
    "Guatemala",
    "Honduras",
    "Nicaragua"
  ],
  Europe: {
    "Eastern Europe": {
      "Baltic states": ["Estonia", "Latvia", "Lithuania"],
      Caucasus: [
        "Armenia",
        "Azerbaijan",
        "Georgia",
        "Republic of Abkhazia",
        "Republic of Artsakh",
        "South Ossetia"
      ],
      "Former Soviet states": ["Belarus", "Moldova", "Russia", "Ukraine"],
      "Central Europe": [
        "Austria",
        "Czech Republic",
        "Croatia",
        "Hungary",
        "Poland",
        "Romania",
        "Serbia",
        "Slovakia",
        "Slovenia"
      ]
    },
    "Northern Europe": ["Denmark", "Finland", "Iceland", "Norway", "Sweden"],
    "Southern Europe": [
      "Albania",
      "Bosnia and Herzegovina",
      "Greece",
      "Italy",
      "Malta",
      "Montenegro",
      "North Macedonia",
      "Portugal",
      "Spain",
      "Turkey"
    ],
    "Western Europe": [
      "Andorra",
      "Belgium",
      "France",
      "Germany",
      "Ireland",
      "Liechtenstein",
      "Luxembourg",
      "Monaco",
      "Netherlands",
      "San Marino",
      "Switzerland",
      "United Kingdom"
    ]
  },
  "North America": [
    "Anguilla",
    "Antigua and Barbuda",
    "Aruba",
    "The Bahamas",
    "Barbados",
    "Bermuda",
    "Bonaire",
    "British Virgin Islands",
    "Canada",
    "Cayman Islands",
    "Clipperton Island",
    "Cuba",
    "Curaçao",
    "Dominica",
    "Dominican Republic",
    "Greenland",
    "Grenada",
    "Jamaica",
    "Mexico",
    "Montserrat",
    "Nueva Esparta",
    "Puerto Rico",
    "Saba",
    "San Andrés and Providencia",
    "Saint Barthélemy",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Martin",
    "Saint Pierre and Miquelon",
    "Saint Vincent and the Grenadines",
    "Sint Eustatius",
    "Sint Maarten",
    "Trinidad and Tobago",
    "Turks and Caicos Islands",
    "United States",
    "U.S. Virgin Islands"
  ],
  Oceania: {
    Australasia: [
      "Ashmore and Cartier Islands",
      "Australia",
      "Coral Sea Islands",
      "New Zealand",
      "Norfolk Island"
    ],
    Melanesia: [
      "Fiji",
      "New Caledonia",
      "Papua",
      "West Papua",
      "Papua New Guinea",
      "Solomon Islands",
      "Vanuatu"
    ],
    Micronesia: [
      "Federated States of Micronesia",
      "Guam",
      "Kiribati",
      "Marshall Islands",
      "Nauru",
      "Northern Mariana Islands",
      "Palau",
      "Wake Island"
    ],
    Polynesia: [
      "American Samoa",
      "Cook Islands",
      "Easter Island",
      "French Polynesia",
      "Hawaii",
      "Niue",
      "Pitcairn Islands",
      "Samoa",
      "Tokelau",
      "Tonga",
      "Tuvalu",
      "Wallis and Futuna"
    ]
  },
  "South America": {
    "Gran Colombia": [
      "Colombia",
      "Ecuador",
      "Panama",
      "Venezuela",
      "Northern Peru"
    ],
    "Andean regions": ["Peru", "Northern Chile", "Bolivia"],
    "Gaucho regions": ["Argentina", "Chile", "Paraguay"],
    "Rioplatense region": ["Brazil", "Uruguay"]
  }
};
