export function getFolderName(folders, newFolderName) {
  if (folders.find((e) => e.name == newFolderName)) {
    let j = 0;

    folders.forEach((e) => {
      if (e.name.includes(newFolderName)) {
        const curentIndex = e.name.match(/\d+/);
        if (curentIndex && curentIndex[0] && parseInt(curentIndex[0]) > j) {
          j = parseInt(curentIndex[0]);
        }
      }
    });
    j++;
    return `${newFolderName} (${j})`;
  } else {
    return newFolderName;
  }
}
