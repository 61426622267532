import React, { useEffect, Fragment, useRef, useState } from "react";

import {
  Card,
  ChatHeader,
  ChatHeading,
  HeadingLink,
  Flex,
  SubmitBlock,
  MessageInput,
  ButtonsBlock,
  NoChatSelected,
  MessagesCont,
  Message,
  ManagerMessage,
  IconContainer,
} from "./styled";
import { CustomScrollEvent } from "./CustomScrollEvent";
import { Button } from "../Offerings/styled";

import { ReactComponent as PaperClipIcon } from "../../../../assets/paperclip.svg";
import { ReactComponent as PaperClipIcon2 } from "../../../../assets/paperclip2.svg";
import { ReactComponent as SendIcon } from "../../../../assets/send.svg";

import Api from "../../../../helpers/api";
import { AttachmentsUploader } from "../../../admin-layout/pages/Chat/AttachmentsUploader";

export const ChatBar = ({ token, id, chatThemes, messages, username, getNewMessage = console.log }) => {
  const chatContainer = useRef(null);
  const debounceRef = useRef(null);
  const lastPositionTop = useRef(null);
  const lastMessageId = useRef(null);

  const [avatar, setAvatar] = useState(null);
  const [newMessage, setNewMessage] = useState("");
  const [maxHeight, setMaxHeight] = useState(0);
  const sendButtonRef = useRef(null);
  const [uploaderOpen, setUploaderOpen] = useState(false);
  const [files, setFiles] = useState([]);
  const [wait, setWait] = useState(false);

  const onChangeUploaderStatus = () => {
    setUploaderOpen(!uploaderOpen);
  };

  useEffect(() => {
    if (chatContainer.current) {
      if (maxHeight !== chatContainer.current.scrollHeight - chatContainer.current.clientHeight) {
        setMaxHeight(chatContainer.current.scrollHeight - chatContainer.current.clientHeight);
      }
    }
  });

  const moveToBottom = (top = 0) => {
    clearTimeout(debounceRef.current);
    debounceRef.current = setTimeout(() => {
      if (chatContainer.current) {
        chatContainer.current.scroll({
          top,
        });
      }
    }, 500);
  };

  const chat = chatThemes.find((e) => e.assetId === id) || {};

  useEffect(() => {
    if (!messages[0]) moveToBottom();
    else if (lastMessageId.current !== messages[0].id) {
      moveToBottom();
      lastMessageId.current = messages[0].id;
    }
  }, [messages]);

  useEffect(() => {
    let localDebounce = null;
    function onTop(e) {
      const direction = e.target.scrollTop > lastPositionTop.current;
      if (direction && e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 100) {
        clearTimeout(localDebounce);
        localDebounce = setTimeout(getNewMessage, 250);
      }

      lastPositionTop.current = e.target.scrollTop;
    }

    chatContainer.current && chatContainer.current.addEventListener("scroll", onTop);

    return () => {
      clearTimeout(localDebounce);
      chatContainer.current && chatContainer.current.removeEventListener("scroll", onTop);
    };
  }, [chatContainer.current]);

  useEffect(() => {
    if (chat.logo) {
      const { fileNameWithExtension, secureHash } = chat.logo;
      Api.getAttachment(token, secureHash, fileNameWithExtension).then((b) => setAvatar(b));
    }
  }, [id]);

  const delegateOnEnter = () => {
    sendButtonRef.current && sendButtonRef.current.click();
  };

  const sendMessage = async () => {
    if (!newMessage && !files.length) return;

    setWait(true);
    let attachments = {};
    if (files.length) {
      const formData = new FormData();
      for (let i = 0; i < files.length; i++) {
        formData.append(`files`, files[i], files[i].name);
      }

      const upload = await Api.uploadChatFiles(token, formData);
      if (upload) {
        attachments = { ...upload };
      }
    }

    await Api.sendMessageToAM(token, newMessage, id, attachments).catch(() => {
      setWait(false);
    });
    setNewMessage("");
    setFiles([]);
    setWait(false);
  };

  return (
    <Card height="100%">
      {chat.name ? (
        <Fragment>
          <ChatHeader>
            <Flex>
              <ChatHeading>chat with</ChatHeading> <HeadingLink>{chat.name}</HeadingLink>
            </Flex>
            <Flex>
              <PaperClipIcon />
              <HeadingLink>shared media</HeadingLink>
            </Flex>
          </ChatHeader>

          <MessagesCont ref={chatContainer}>
            <CustomScrollEvent
              key={`rebuild-event-area-${(messages[0] && messages[0].id) || ""}`}
              maxHeight={maxHeight}
              onScroll={(top) => {
                chatContainer.current.scroll({
                  left: 0,
                  top,
                  // behavior: ,
                });
              }}
            />
            {messages.map(({ id, message, date, sender, read, attachments }, i) =>
              sender === username ? (
                <Message
                  offAnimation
                  key={`message-id-${id}`}
                  message={message}
                  date={date}
                  read={read}
                  token={token}
                  attachments={attachments}
                  token={token}
                  messageId={id}
                />
              ) : (
                <ManagerMessage
                  offAnimation
                  key={`message-id-${id}`}
                  logo={avatar}
                  message={message}
                  date={date}
                  attachments={attachments}
                  token={token}
                  messageId={id}
                />
              )
            )}
          </MessagesCont>
          {/* Message, ManagerMessage  */}
          <SubmitBlock>
            <MessageInput
              placeholder="Write your message here"
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              onKeyDown={(e) => e.keyCode === 13 && delegateOnEnter()}
            />
            <ButtonsBlock>
              <IconContainer onClick={onChangeUploaderStatus} count={files.length}>
                <PaperClipIcon2 />
              </IconContainer>
              {/* <ImgIcon /> */}
              {/* <VideoIcon /> */}
              <Button width={36} height={36} onClick={sendMessage} ref={sendButtonRef} disabled={wait}>
                <SendIcon style={{ margin: 0 }} />
              </Button>
            </ButtonsBlock>
          </SubmitBlock>

          {uploaderOpen ? (
            <AttachmentsUploader
              onBackdropClick={onChangeUploaderStatus}
              onSetFilesToMessage={(f) => setFiles(f)}
              filesList={files}
            />
          ) : null}
        </Fragment>
      ) : (
        <NoChatSelected>Please select a chat to start messaging</NoChatSelected>
      )}
    </Card>
  );
};
